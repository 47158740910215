import { IReduxState } from 'app/store';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { IconRadioButton } from '../Icons/IconRadioButton/IconRadioButton';
import { ReportsList } from './ReportsList/ReportsList';
import { IconArrow } from '../Icons/IconArrow/IconArrow';
import { LoginService } from 'login/services/login.service';
import { useFlags } from 'launchdarkly-react-client-sdk';

import './DownloadReports.scss';
import { Text } from '@cyberpion/cyberpion-ui';

export const REPORT_TYPES = {
  EXECUTIVE: 'executive',
  ASM: 'asm',
  EXPOSURE: 'threat-exposure'
};

export function DownloadReports() {
  const [open, setOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);
  const { user } = useSelector((state: IReduxState) => state.login);

  const flags = useFlags();

  useEffect(() => {
    if (user && Object.keys(flags).length) {
      if (isExecutiveEnabled()) {
        setActiveTab(REPORT_TYPES.EXECUTIVE);
      } else if (isAsmEnabled()) {
        setActiveTab(REPORT_TYPES.ASM);
      } else if (isExecutiveEnabled()) {
        setActiveTab(REPORT_TYPES.EXPOSURE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, flags]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  const shouldShowDropDown = (): boolean => {
    return isExecutiveEnabled() || isAsmEnabled();
  };

  const isExecutiveEnabled = (): boolean => {
    if (LoginService.isCyberpionUser(user)) {
      return true;
    }
    if (flags.isExecutiveReports && flags.isOrgScore && LoginService.isMemberOrAbove(user)) {
      return true;
    }

    return false;
  };

  const isThreatExposureEnabled = (): boolean => {
    if (flags.isThreatExposureReport && LoginService.isMemberOrAbove(user)) {
      return true;
    }

    return false;
  };

  const isAsmEnabled = (): boolean => {
    if (LoginService.isCyberpionUser(user)) {
      return true;
    }
    return flags.isAsmReports && !LoginService.isRestrictedMember(user);
  };

  if (!shouldShowDropDown()) {
    return null;
  }

  return (
    <div className="DownloadReports" ref={ref}>
      <header onClick={() => setOpen(!open)}>
        Download Reports <IconArrow toggleRotate={open} color="#3455DD" />
      </header>
      <div className={classNames('reports-wrapper', { open: open })}>
        <div>
          <div style={{ paddingTop: 1.5, paddingBottom: 13, paddingLeft: 16, borderBottom: '1px solid #EAEAEA' }}>
            <Text textSize={13} weight={500}>
              Select Type
            </Text>
          </div>
          <ul style={{ paddingTop: 16, paddingLeft: 16, paddingRight: 16, whiteSpace: 'nowrap' }}>
            <li className={classNames({ disabled: !isExecutiveEnabled() })} onClick={() => setActiveTab('executive')}>
              <IconRadioButton radioSize="little" toggle={activeTab === REPORT_TYPES.EXECUTIVE} /> Executive Report
            </li>
            {isAsmEnabled() && (
              <li onClick={() => setActiveTab('asm')}>
                <IconRadioButton radioSize="little" toggle={activeTab === REPORT_TYPES.ASM} /> ASM Report
              </li>
            )}
            {isThreatExposureEnabled() && (
              <li onClick={() => setActiveTab('threat-exposure')}>
                <IconRadioButton radioSize="little" toggle={activeTab === REPORT_TYPES.EXPOSURE} /> Threat Exposure
                Report
              </li>
            )}
          </ul>
        </div>
        <ReportsList type={activeTab} open={open} />
      </div>
    </div>
  );
}
