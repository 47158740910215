import { ToolTip } from 'common/components/toolTip/toolTip';
import './CustomTagsRenderer.scss';
import { RestApi } from 'common/services/rest-api.service';
import ReactTooltip from 'react-tooltip';

const CustomTagsRenderer = (props: any) => {
  const onDelete = (tag: string, index: number, event: any) => {
    let apiPath = '';
    const source = `${props.source.page}_${props.source.tab}`;
    switch (source) {
      case 'discovery_internalAssets':
        apiPath = 'discovery/org-assets/tags/';
        break;
      case 'actionItems_open':
        apiPath = 'remediation/action-items/tags/';
    }
    const newTagsArray = [...props.data];
    newTagsArray.splice(index, 1);
    props.api.updateRows([{ [props.identifier]: props.id, tags: newTagsArray }]);
    ReactTooltip.hide();
    RestApi.setData(apiPath, { ids: [props.id], tags: [tag] }, 'DELETE').subscribe(
      (response: any) => {},
      error => {}
    );
  };

  return (
    <div>
      <ul className="CustomTagsRenderer">
        {!!props.data &&
          !!props.data.length &&
          props.data.map((item: any, index: number) => {
            return (
              <li key={index}>
                <div className="chip-item">
                  <div>{typeof item === 'string' ? item : item.name}</div>
                  {item.source?.toLowerCase() === 'custom' ? (
                    <span
                      data-tip="Delete Custom Tag"
                      data-for="DELELTE_CUSTOM_TAG"
                      onClick={(e: any) => onDelete(item.name, index, e)}
                    >
                      <img src="/assets/images/x.svg" alt="Delete" />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </li>
            );
          })}
      </ul>
      <ToolTip id="DELELTE_CUSTOM_TAG" place="bottom" theme="dark" effect="solid" />
    </div>
  );
};

export { CustomTagsRenderer };
