import './SubTabsLinks.scss';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function SubTabsLinks(props: any) {
  const flags = useFlags();

  const getSubTabsHTML = (): JSX.Element[] => {
    let _html: JSX.Element[] = [];
    Object.keys(props.tabs).forEach((tab: string) => {
      if (!props.tabs[tab].featureFlag || flags[props.tabs[tab].featureFlag]) {
        _html.push(
          <li className={props.activeSubTab === props.tabs[tab].key ? 'active' : ''}>
            <span className="bottom-left"></span>
            <Link to={props.tabs[tab].link}>{props.tabs[tab].title}</Link>
            <span className="bottom-right"></span>
          </li>
        );
      }
    });
    return _html;
  };

  return (
    <div className="SubTabsLinks">
      <ul>{getSubTabsHTML()}</ul>
    </div>
  );
}
