import { GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { AppDispatch } from 'app/store';
import classNames from 'classnames';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { Grid } from 'common/components/grid/Grid';
// import { IColumnPicker } from 'common/interfaces/interface';
import { RestApi } from 'common/services/rest-api.service';
import { disableURLChange, enableURLChange, setFields, setInitialPath } from 'features/GridSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AlertPopup } from '../AlertPopup';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';
import './AlertMulti.scss';

const EMPTY_PLACEHOLDER = '-';

export function AlertMulti(props: any) {
  // const [columnPicker, setColumnPicker] = useState<IColumnPicker[]>([]);
  const [data] = useState<any>([]);
  const [count] = useState<number>(0);
  const [columns, setColumns] = useState<GridColumns>([]);
  const [singleAlertVisible, setSingleAlertVisible] = useState<boolean>(false);
  const [subAlertId, setSubAlertId] = useState<number>();
  const [singleAlertAnimation, setSingleAlertAnimation] = useState<boolean>(false);
  const [alertData, setAlertData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    RestApi.getData(`assessments/drps/darkweb/alerts/${props.id}/sub_alerts/columns/`).subscribe((response: any) => {
      const fields = ColumnsManage.checkGridColumnsToDisplay(response.data);
      dispatch(setFields(fields));
      dispatch(setInitialPath(`assessments/drps/darkweb/alerts/${props.id}/sub_alerts/`));
      // setColumnPicker(response.data);
      dispatch(setColumnPicker(response.data));
      dispatch(setShowColumnPicker(true));

      setColumns(
        ColumnsManage.createColumns(response.identifier_field, response.data || [], { page: 'assesments', tab: 'drps' })
      );
    });
    RestApi.getData(`assessments/drps/darkweb/alerts/${props.id}/`).subscribe((response: any) => {
      setAlertData(response);
      setLoading(false);
    });
    dispatch(disableURLChange());
    return () => {
      dispatch(enableURLChange());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSingleAlertAnimation(singleAlertVisible);
  }, [singleAlertVisible]);

  const onDoubleClick = (params: GridRowParams) => {
    setSubAlertId(params.row.id);
    setSingleAlertVisible(true);
  };

  return (
    <div className="AlertMulti">
      {loading && <AppLoading />}
      {alertData.title && <div className="notice">{alertData.title}</div>}
      <ul className="notice-multi">
        <li>
          <span className="key">Threats:</span>
          <span className="value">
            {!!alertData.threats?.length ? alertData.threats.join(', ') : EMPTY_PLACEHOLDER}
          </span>
        </li>
        <li>
          <span className="key">Published Date:</span>
          <span className="value">{alertData.published_date || '-'}</span>
        </li>
        <li>
          <span className="key">Source:</span>
          <span className="value">{!!alertData.source?.length ? alertData.source.join(', ') : EMPTY_PLACEHOLDER}</span>
        </li>
        <li>
          <span className="key">Related Assets:</span>
          <span className="value">
            <div className="scrollbar-common">
              <div style={{ height: '100%', overflow: 'auto' }}>
                {!!alertData.hosts?.length ? alertData.hosts.join(', ') : EMPTY_PLACEHOLDER}
              </div>
            </div>
          </span>
        </li>
        <li>
          <span className="key">Related Keywords:</span>
          <span className="value">
            <div className="scrollbar-common">
              <div style={{ height: '100%', overflow: 'auto' }}>
                {!!alertData.related_keywords?.length ? alertData.keywords.join(', ') : EMPTY_PLACEHOLDER}
              </div>
            </div>
          </span>
        </li>
        <li>
          <span className="key">Related BINs:</span>
          <span className="value">
            <div className="scrollbar-common">
              <div style={{ height: '100%', overflow: 'auto' }}>
                {!!alertData.related_bins?.length ? alertData.related_bins.join(', ') : EMPTY_PLACEHOLDER}
              </div>
            </div>
          </span>
        </li>
      </ul>
      <div className={classNames('alert-single-wrapper', { show: singleAlertAnimation })}>
        <div className="dark-layer" onClick={() => setSingleAlertVisible(false)}></div>
        <AlertPopup
          data={{ alertId: props.id, subAlertId: subAlertId, isMultiAlerts: false, hideTitle: true }}
          setVisible={() => setSingleAlertVisible(false)}
        />
      </div>
      <div style={{ height: '100%', zIndex: 1 }}>
        {!!columns.length && (
          <Grid
            columns={columns}
            rows={data}
            count={count}
            path={`assessments/drps/darkweb/alerts/${props.id}/sub_alerts/`}
            // columnPicker={columnPicker}
            // showColumnPicker={true}
            isMoreButton={true}
            isCSVDownloadable={false}
            identifier={'id'}
            isPopup={true}
            onRowDoubleClick={onDoubleClick}
          />
        )}
      </div>
    </div>
  );
}
