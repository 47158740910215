import { IconAnonUser } from 'common/components/Icons/IconAnonUser/IconAnonUser';
import { UserMenu } from '../../../common/components/userMenu/userMenu';
import { useState, useRef } from 'react';
import './user.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
export function User(props: any) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const anchorRef = useRef<HTMLDivElement>(null);
  const { user } = useSelector((state: IReduxState) => state.login);

  const handleToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setMenuOpen(false);
  };

  function createIcon() {
    let _string: string = '';
    if (user && user.first_name && user.last_name) {
      const first = user.first_name.charAt(0).toUpperCase();
      const last = user.last_name.charAt(0).toUpperCase();
      _string = first + last;
    } else if (user && user.email) {
      _string = user.email.charAt(0).toUpperCase();
    }

    if (_string.length) {
      return (
        <div className="user-text" onClick={handleToggle} ref={anchorRef}>
          {_string}
        </div>
      );
    }

    return <IconAnonUser />;
  }

  return (
    <div className={classNames('user-scope')}>
      {createIcon()}
      <UserMenu
        onOpenSettingsPopup={props.onOpenSettingsPopup}
        open={menuOpen}
        anchorRef={anchorRef}
        handleClose={handleClose}
      />
    </div>
  );
}
