import { AppLoading } from 'common/components/appLoading/appLoading';
import { useEffect, useState } from 'react';
import { IntegrationHeader } from '../Integration/IntegrationHeader/IntegrationHeader';
import { TextField } from '@mui/material';
import { RestApi } from 'common/services/rest-api.service';
import { ISentinel, initData } from './config';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { ToolTip } from 'common/components/toolTip/toolTip';
import { SECURE_INPUT_MASK, TimeIntervalOptions } from '../../config';
import { useDispatch } from 'react-redux';
import { loadList } from 'features/IntegrationsSlice';
import { DropDown, Text } from '@cyberpion/cyberpion-ui';
import { showError, showSuccess } from 'features/AlertSlice';
import { IconVisibility, IconVisibilityOff } from '../Integration/Integration';
import PopSideFotter from '../Integration/PopSideFotter/PopSideFotter';

import './Sentinel.scss';
import '../../Integration.scss';

export function Sentinel(props: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [data, setData] = useState<ISentinel>(initData());
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [visibilityKey, setVisibilityKey] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const dispatch = useDispatch<any>();
  const [errors, setErrors] = useState({
    primary_key: false,
    workspace_id: false
  });

  useEffect(() => {
    if (props.isEditMode) {
      RestApi.getData(Paths[PathNames.integrationSentinel] + props.id + '/').subscribe((response: any) => {
        if (response) {
          setData({
            ...response,
            primary_key: SECURE_INPUT_MASK,
            workspace_id: SECURE_INPUT_MASK
          });
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditMode]);

  const setValue = (key: string, value: any) => {
    let copy: ISentinel = { ...data };
    if (key === 'action_items_push_interval') {
      copy = { ...copy, [key]: value === 0 ? 86400 : value, action_items_is_active: value !== 0 };
    } else {
      copy = { ...copy, [key]: value };
    }

    setErrors({ ...errors, [key]: false });
    setData(copy);
    setClickedSubmit(false);
  };

  const submit = () => {
    setClickedSubmit(true);
    if (!isValidated) {
      return;
    }

    setIsSubmiting(true);
    RestApi.setData(
      props.isEditMode ? Paths[PathNames.integrationSentinel] + props.id + '/' : Paths[PathNames.integrationSentinel],
      {
        ...data,
        is_active: true,
        primary_key: data.primary_key === SECURE_INPUT_MASK ? undefined : data.primary_key,
        workspace_id: data.workspace_id === SECURE_INPUT_MASK ? undefined : data.workspace_id
      },
      props.isEditMode ? 'PATCH' : 'POST'
    ).subscribe(
      (response: any) => {
        props.onClose();
        setIsSubmiting(false);
        dispatch(loadList());
        dispatch(showSuccess('Integration Saved Successfully'));
      },
      error => {
        let msg: any;
        let _error = JSON.parse(error.message);
        if (_error?.error_code === '2' && _error.details === 'AZURE_CONNECTION_ERROR') {
          msg = 'Workspace ID field validation failed';
        } else {
          msg = error;
        }
        dispatch(showError(msg));
        setIsSubmiting(false);
      }
    );
  };

  useEffect(() => {
    if (((!props.isEditMode && data.workspace_id.length > 32) || props.isEditMode) && data.primary_key.length > 2) {
      setIsValidated(true);
    } else {
      setIsValidated(false);
    }
  }, [data, props.isEditMode]);

  const deleteBeforeApplyOnClick = (ev: any) => {
    ev.stopPropagation();
    setShowDeletePopup(true);
  };

  const deleteAfterApplyOnClick = () => {
    setShowDeletePopup(false);
    setIsSubmiting(true);
    if (!!props.isEditMode) {
      RestApi.setData(`${Paths[PathNames.integrationSentinel]}${data.id}/`, {}, 'DELETE').subscribe(
        (response: any) => {
          dispatch(showSuccess('Deleted Successfully'));
          setIsSubmiting(false);
          dispatch(loadList());
        },
        error => {
          setIsSubmiting(false);
          dispatch(showError(error));
        }
      );
    }
    props.onClose();
  };

  const handlePopSideOnClick = (ev: any) => {
    ev.stopPropagation();
    setShowDeletePopup(false);
  };

  const checkFieldIsValidation = (field: any) => {
    switch (field) {
      // case 'configuration_name':
      //   return data.configuration_name.length > 2;
      case 'workspace_id':
        return data?.workspace_id?.length > 32;
      case 'primary_key':
        return data.primary_key?.length > 2;
      default:
        return false;
    }
  };

  // const handleOnBlur = (type: string) => () => {
  //   const valid = data[type as keyof typeof data] === '' || checkFieldIsValidation(type);
  //   setErrors({ ...errors, [type]: !valid });
  // };

  if (loading) {
    return <AppLoading />;
  }

  return (
    <div className="Splunk integration" onClick={handlePopSideOnClick}>
      <IntegrationHeader name="Microsoft Azure Sentinel" icon="sentinel" />
      <div className="scrollbar-common">
        <div className="main-integration-wrapper">
          <div style={{ height: '100%', overflow: 'auto' }}>
            <div className="group">
              <Text weight={500} textSize={12} style={{ marginTop: 10 }}>
                General
              </Text>
              {!props.isEditMode ? (
                <div className="text-field-wrapper">
                  <TextField
                    fullWidth
                    label="Workspace ID"
                    className="input"
                    value={data.workspace_id}
                    onChange={e => setValue('workspace_id', e.target.value)}
                    type={'text'}
                    onFocus={(ev: any) => ev.target.select()}
                    inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off'
                      }
                      // onBlur: handleOnBlur('workspace_id')
                    }}
                    error={!!errors.workspace_id || (!checkFieldIsValidation('workspace_id') && clickedSubmit)}
                  />
                </div>
              ) : (
                <div>
                  <Text textSize={12} weight={500} style={{ marginTop: 16 }}>
                    Workspace ID
                  </Text>
                  <Text textColor="#5D6576" family="Rubik" textSize={12} style={{ marginTop: 8 }}>
                    {data.workspace_id}
                  </Text>
                </div>
              )}
              {!props.isEditMode ? (
                <div className="text-field-wrapper">
                  <TextField
                    fullWidth
                    label="Primary Key"
                    className="input primary_key"
                    value={data.primary_key}
                    onChange={e => setValue('primary_key', e.target.value)}
                    type={!!visibilityKey ? 'text' : 'password'}
                    onFocus={(ev: any) => ev.target.select()}
                    inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off'
                      }
                      // onBlur: handleOnBlur('primary_key')
                    }}
                    error={!!errors.primary_key || (!checkFieldIsValidation('primary_key') && clickedSubmit)}
                  />
                  {!!data.primary_key && (
                    <div className="icon-visibility" onClick={() => setVisibilityKey(!visibilityKey)}>
                      {!!visibilityKey ? IconVisibilityOff : IconVisibility}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <Text textSize={12} weight={500} style={{ marginTop: 16 }}>
                    Primary Key
                  </Text>
                  <Text textColor="#5D6576" family="Rubik" textSize={12} style={{ marginTop: 8 }}>
                    {data.primary_key}
                  </Text>
                </div>
              )}
            </div>
            <div className="group">
              <Text weight={500} textSize={12} style={{ marginTop: 10, marginBottom: 20 }}>
                Data Push Frequency
              </Text>
              <DropDown
                options={TimeIntervalOptions}
                onChange={(state: number) => setValue('action_items_push_interval', state)}
                placeholder="Action Items"
                value={!!data.action_items_is_active ? `${data.action_items_push_interval}` : '0'}
                error={false}
              />
            </div>
          </div>
        </div>
      </div>
      <PopSideFotter
        onClose={props.onClose}
        isValidated={true}
        onSave={submit}
        isLoading={isSubmiting}
        deleteBeforeApplyOnClick={deleteBeforeApplyOnClick}
        deleteAfterApplyOnClick={deleteAfterApplyOnClick}
        showDeletePopup={showDeletePopup}
        deleteMsgWithNotificationWarning={false}
        integrationName="Asure Sentinel"
        isMainDeleteDisabled={!data.id}
      />
      <ToolTip theme="dark" place="left" />
    </div>
  );
}
