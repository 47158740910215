export interface ITopAssetsAtRiskProps {
  type?: string;
  url: string;
  apiCall: string;
  title?: string;
  count?: number;
}

export const urgencies: ITopAssetsAtRiskProps[] = [
  {
    type: 'critical',
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"critical"%7D',
    apiCall: 'discovery/org-assets/?limit=1&risk_score__in=critical'
  },
  {
    type: 'high',
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"high"%7D',
    apiCall: 'discovery/org-assets/?limit=1&risk_score__in=high'
  },
  {
    type: 'medium',
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"medium"%7D',
    apiCall: 'discovery/org-assets/?limit=1&risk_score__in=medium'
  },
  {
    type: 'low',
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"low"%7D',
    apiCall: 'discovery/org-assets/?limit=1&risk_score__in=low'
  }
];

export const importances: ITopAssetsAtRiskProps[] = [
  {
    type: 'lowest',
    url: '/pages/discovery/internalAssets?filters=%7B"importance__in"%3A"1"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=1'
  },
  {
    type: 'low',
    url: '/pages/discovery/internalAssets?filters=%7B"importance__in"%3A"2"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=2'
  },
  {
    type: 'medium',
    url: '/pages/discovery/internalAssets?filters=%7B"importance__in"%3A"3"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=3'
  },
  {
    type: 'high',
    url: '/pages/discovery/internalAssets?filters=%7B"importance__in"%3A"4"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=4'
  },
  {
    type: 'highest',
    url: '/pages/discovery/internalAssets?filters=%7B"importance__in"%3A"5"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=5'
  }
];

export const graphData: ITopAssetsAtRiskProps[] = [
  {
    url: '/pages/discovery/internalAssets?&filters=%7B"risk_score__in"%3A"critical"%2C"importance__in"%3A"1"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=1&risk_score__in=critical',
    title: 'Critical / Lowest'
  },
  {
    url: '/pages/discovery/internalAssets?&filters=%7B"risk_score__in"%3A"critical"%2C"importance__in"%3A"2"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=2&risk_score__in=critical',
    title: 'Critical / Low'
  },
  {
    url: '/pages/discovery/internalAssets?&filters=%7B"risk_score__in"%3A"critical"%2C"importance__in"%3A"3"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=3&risk_score__in=critical',
    title: 'Critical / Medium'
  },
  {
    url: '/pages/discovery/internalAssets?&filters=%7B"risk_score__in"%3A"critical"%2C"importance__in"%3A"4"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=4&risk_score__in=critical',
    title: 'Critical / High'
  },
  {
    url: '/pages/discovery/internalAssets?&filters=%7B"risk_score__in"%3A"critical"%2C"importance__in"%3A"5"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=5&risk_score__in=critical',
    title: 'Critical / Highest'
  },
  {
    url: '/pages/discovery/internalAssets?&filters=%7B"risk_score__in"%3A"high"%2C"importance__in"%3A"1"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=1&risk_score__in=high',
    title: 'High / Lowest'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"high"%2C"importance__in"%3A"2"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=2&risk_score__in=high',
    title: 'High / Low'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"high"%2C"importance__in"%3A"3"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=3&risk_score__in=high',
    title: 'High / Medium'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"high"%2C"importance__in"%3A"4"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=4&risk_score__in=high',
    title: 'High / High'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"high"%2C"importance__in"%3A"5"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=5&risk_score__in=high',
    title: 'High / Highest'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"medium"%2C"importance__in"%3A"1"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=1&risk_score__in=medium',
    title: 'Medium / Lowest'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"medium"%2C"importance__in"%3A"2"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=2&risk_score__in=medium',
    title: 'Medium / Low'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"medium"%2C"importance__in"%3A"3"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=3&risk_score__in=medium',
    title: 'Medium / Medium'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"medium"%2C"importance__in"%3A"4"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=4&risk_score__in=medium',
    title: 'Medium / High'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"medium"%2C"importance__in"%3A"5"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=5&risk_score__in=medium',
    title: 'Medium / Highest'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"low"%2C"importance__in"%3A"1"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=1&risk_score__in=low',
    title: 'Low / Lowest'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"low"%2C"importance__in"%3A"2"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=2&risk_score__in=low',
    title: 'Low / Low'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"low"%2C"importance__in"%3A"3"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=3&risk_score__in=low',
    title: 'Low / Medium'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"low"%2C"importance__in"%3A"4"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=4&risk_score__in=low',
    title: 'Low / High'
  },
  {
    url: '/pages/discovery/internalAssets?filters=%7B"risk_score__in"%3A"low"%2C"importance__in"%3A"5"%7D',
    apiCall: 'discovery/org-assets/?limit=1&importance__in=5&risk_score__in=low',
    title: 'Low / Highest'
  }
];
