import { IInput } from 'common/interfaces/interface';
import React, { forwardRef } from 'react';
import { Image } from '../image/Image';
import clsx from 'clsx';
import './input.scss';

interface IErrorValidation {
  error?: string[] | string;
}

const ErrorValidation: React.FC<IErrorValidation> = ({ error }) => {
  if (error) {
    if (typeof error === 'string' && error !== '') {
      return <div className="Input_error-text">{error}</div>;
    }
    if (Array.isArray(error) && error.length > 0) {
      return (
        <div>
          {error.map((line, index) => (
            <div key={index} className="Input_error-text">
              {line}
            </div>
          ))}
        </div>
      );
    }
  }
  return null;
};

const Input = forwardRef((props: IInput, ref?: any) => {
  const [toggleCleanText, setToggleCleanText] = React.useState<boolean>(true);

  const isErrorBlock =
    props.error &&
    ((typeof props.error === 'string' && props.error !== '') || (Array.isArray(props.error) && props.error.length > 0));

  function handleChange(value: string) {
    if (typeof props.onChange === 'function') {
      props.onChange(value);
      if (typeof props.onCleanText === 'function') {
        setToggleCleanText(value === '');
      }
    }
  }

  function keyboardEvent(value: React.KeyboardEvent) {
    if (typeof props.onKeyDown === 'function') {
      props.onKeyDown(value);
    }
  }

  function onCleanText() {
    if (typeof props.onCleanText === 'function' && typeof props.onChange === 'function') {
      props.onChange('');
      props.onCleanText();
      setToggleCleanText(true);
    }
  }

  const customClass = props.className ? props.className : '';

  return (
    <>
      <div className="input-scope">
        {props.icon && <Image className="input-icon" alt={props.icon} icon={props.icon} type="svg" />}
        <input
          ref={ref}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          id={props.id}
          className={clsx('input-common', {
            [customClass]: props.className,
            error: !!isErrorBlock
          })}
          type={props.type}
          value={props.value}
          maxLength={props.maxlength}
          minLength={props.minlength}
          placeholder={props.placeholder}
          onKeyDown={keyboardEvent}
          onChange={e => handleChange(e.target.value)}
        />
        {props.onCleanText && (
          <>
            {!toggleCleanText && (
              <div className="input-cleanText" onClick={onCleanText}>
                +
              </div>
            )}
          </>
        )}
      </div>
      <ErrorValidation error={props.error} />
    </>
  );
});

export { Input };
