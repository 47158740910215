import { IReduxState } from 'app/store';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { LocalGrid } from 'common/components/grid/LocalGrid';
import { IColumnPicker } from 'common/interfaces/interface';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import { addIp } from 'features/GroupsSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BulkUpload } from '../BulkUpload/BulkUpload';
import { IPNetworksActions } from './IPNetworksActions/IPNetworksActions';
import './IPNetworksTab.scss';

type ipsType = {
  [key: string]: string;
};
export function IPNetworksTab(props: any) {
  const [columnPicker, setColumnPicker] = useState<IColumnPicker[]>([]);
  const [identifier, setIdenifier] = useState<string>('');
  const { ips, search } = useSelector((state: IReduxState) => state.groups);

  useEffect(() => {
    RestApi.getData('settings/groups/ip-networks/columns/').subscribe((response: any) => {
      setIdenifier(response.identifier_field);
      setColumnPicker(response.data);
    });
  }, []);

  const filteredRows = ips.filter((item: ipsType) => item.value.includes(search));
  return (
    <div className="IPNetworksTab">
      <BulkUpload validator={Common.validateIp} reduxAction={addIp} placeholder={`Type your IP's here`} />
      {columnPicker.length ? (
        <LocalGrid
          columns={ColumnsManage.createColumns(identifier, columnPicker || [], {
            page: 'settings',
            tab: 'ip-networks'
          })}
          rows={filteredRows}
          addedActions={(props: any) => <IPNetworksActions {...props} />}
          identifier={identifier}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
}
