import './ForgotPasswordFlow.scss';
import { InputField } from 'common/components/InputField/InputField';
import { Button } from 'common/components/Button/Button';
import { Link } from 'react-router-dom';
import { useLogin } from 'login/hooks/useLogin';
import { useEffect, useState } from 'react';
import { Common } from 'common/services/common.service';

export function ForgotPasswordFlow(props: any) {
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const {
    isLoading,
    isValidUser,
    isPasswordSaved,
    validateUser,
    changeUserPassword,
    forgotPasswordValidateToken
  } = useLogin(setConfirmPasswordError);

  useEffect(() => {
    if (props.isNewUser && !isValidUser) {
      const ref = Common.getParamFromUrl('ref');
      if (ref) {
        validateUser(ref);
      }
    }
  }, [isValidUser, validateUser, props.isNewUser]);

  useEffect(() => {
    if (!props.isNewUser) {
      const ref = Common.getParamFromUrl('ref');
      if (ref) {
        forgotPasswordValidateToken(ref);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isNewUser]);

  useEffect(() => {
    setIsButtonDisabled(!(newPassword === confirmPassword && newPassword));
  }, [newPassword, confirmPassword]);

  const submit = (e: any): void => {
    e.preventDefault();
    if (!newPassword) {
      setNewPasswordError('Type your new password');
      return;
    }
    if (!confirmPassword) {
      setConfirmPasswordError(['Retype your new password']);
      return;
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError(['Password do not match']);
      return;
    }

    changeUserPassword(newPassword, props.isNewUser);
  };

  const onChangePassword = (value: string) => {
    setNewPassword(value);
    setNewPasswordError('');
  };

  const onChangeConfirmPassword = (value: string) => {
    setConfirmPassword(value);
    setConfirmPasswordError(['']);
  };

  const onBack = (e: React.MouseEvent): void => {
    e.preventDefault();
    props.onBack();
  };

  return (
    <form className="ForgotPasswordFlow" onSubmit={(e: any) => submit(e)}>
      {isPasswordSaved ? (
        <div>
          <h6>New Password Saved Successfully!</h6>
          <Link to="#" onClick={(e: React.MouseEvent) => onBack(e)}>
            Go To Login
          </Link>
        </div>
      ) : (
        <div>
          <h6>Reset your password</h6>
          <InputField type="password" placeholder="New Password" onChange={onChangePassword} error={newPasswordError} />
          <InputField
            type="password"
            placeholder="Retype New Password"
            onChange={onChangeConfirmPassword}
            error={confirmPasswordError[0]}
          />
          <Button
            size="big"
            text="Submit"
            type="submit"
            buttonStyle="main"
            onClick={(e: any) => submit(e)}
            loading={isLoading}
            disabled={isButtonDisabled}
          />
        </div>
      )}
    </form>
  );
}
