// import { Common } from "common/services/common.service";
import { IconConnectionPieChart } from 'common/components/Icons/IconConnectionPieChart/IconConnectionPieChart';
import { Common } from 'common/services/common.service';
import './pieChartLegend.scss';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';
interface ILegend {
  header: string;
  value: number;
  linkTo?: string;
}

interface IPieChartLegend {
  label: string;
  data: ILegend[];
  colors: string[];
  show_percent_label: boolean;
  show_header_legend: boolean;
  show_color_number: boolean;
  icon: string;
  total: number;
}
export function PieChartLegend(props: IPieChartLegend) {
  return (
    <div className="pieChartLegend-scope">
      {props.show_header_legend && (
        <div className="pieChartLegend-header-scope">
          {props.icon !== '' && <IconConnectionPieChart />}
          <div className="pieChartLegend-header">{props.label} </div>
        </div>
      )}

      {props.data &&
        props.data.map((item: ILegend, index: number) => (
          <div className="pieChartLegend-row-scope" key={index}>
            <div className="pieChartLegend-circle" style={{ background: props.colors[index] }}></div>

            <div className="pieChartLegend-percent">
              <div
                className="pieChartLegend-number"
                style={props.show_color_number ? { color: props.colors[index] } : {}}
              >
                {/* {props.show_percent_label
                ? `${Common.percentage(item.value, props.total)}%`
                : Common.numberWithCommas(item.value)} */}
                {Common.numberWithCommas(item.value)}
              </div>
            </div>

            <div className="pieChartLegend-label" title={item.header}>
              {item.linkTo ? <LinkNewTab to={item.linkTo} text={item.header} /> : item.header}
            </div>
          </div>
        ))}
    </div>
  );
}
