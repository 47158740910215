import React from 'react';
import { DropDown, Text } from '@cyberpion/cyberpion-ui';
import { useSelector, useDispatch } from 'react-redux';
import { setFieldsValue } from 'features/IntegrationsSlice';
import Input from './Input/Input';
import RadioButtons from './RadioButtons/RadioButtons';
import ChipsList from './ChipsList/ChipsList';
import IntegrationLink from './IntegrationLink/IntegrationLink';
import IntegrationScript from './IntegrationScript/IntegrationScript';
import FileUpload from './FileUpload/FileUpload';

interface IWizardContent {
  // setValues?: (values: any) => void;
  // integrationNameOnChange?: (value: string) => void;
}

const WizardContent: React.FC<IWizardContent> = () => {
  const { integrationSchema, currentStep, fieldsValue, errorText } = useSelector((state: any) => state.integrations);
  const dispatch = useDispatch<any>();

  const stepFields = integrationSchema.steps[`step${currentStep}`];
  const fieldsSchema = integrationSchema.fields;

  const handleFieldOnChange = (id: string, value: string) => {
    dispatch(setFieldsValue({ [id]: value }));
  };

  const handleOnLink = (value: string) => {
    window.open(value, '_blank');
  };

  const getFieldProps = (id: string) => {
    const field = fieldsSchema[id];
    switch (field.type) {
      case 'label':
        return {
          id: id
        };
      // TODO: ORI need to change
      case 'string':
      case 'secret':
        return {
          id: id,
          title: field.label,
          setValue: (value: string) => handleFieldOnChange(id, value),
          type: field.type,
          placeholder: field.label,
          value: fieldsValue[id] || '',
          is_clipboard: field.is_clipboard,
          readonly: stepFields.read_only?.includes(id) || false,
          description: field.description,
          isAppApproved: integrationSchema.type.toLowerCase() === 'azure' && id === 'tenant_id' && currentStep > 2
          // isDisabled: id === 'name' && !!fieldsValue[id]
          // is_error: fieldsError[id],
          // errorText: fieldsError[id]
        };
      case 'radio':
        return {
          id: id,
          onSelect: (value: string) => () => handleFieldOnChange(id, value),
          selected: fieldsValue[id],
          readonly: stepFields.read_only?.includes(id) || false
        };
      case 'chip_list':
        return {
          id: id,
          title: field.label,
          valuesList: fieldsValue[id] || [],
          readonly: stepFields.read_only?.includes(id) || false,
          description: field.description
        };
      case 'link':
        return {
          id: id,
          title: field.label,
          value: fieldsValue[id] || '',
          readonly: stepFields.read_only?.includes(id) || false,
          is_clipvoard: true,
          onLink: (value: string) => handleOnLink(value)
        };
      case 'script':
        return {
          id: id,
          title: field.label,
          value: fieldsValue[id] || '',
          is_clipvoard: true
        };
      case 'file':
        return {
          id: id,
          title: field.label,
          setValue: (value: string) => handleFieldOnChange(id, value)
        };
      case 'select': {
        const options = field.choices.map((option: any) => {
          return { value: option.id, label: option.label };
        });
        return {
          options: options,
          value: fieldsValue[id] || '',
          title: field.label,
          onChange: (value: string) => handleFieldOnChange(id, value),
          readonly: stepFields.read_only?.includes(id) || false,
          placeholder: field.label
        };
      }
      default:
        return {};
    }
  };

  const getComponent = (id: string, index: number) => {
    const field = fieldsSchema[id];
    const conditions = field.display_condition;
    if (conditions) {
      const field = conditions.field;
      const value = conditions.value;

      if (fieldsValue[field] !== value) {
        return null;
      }
    }
    const props: any = getFieldProps(id);

    switch (fieldsSchema[id].type) {
      case 'label':
        return (
          <Text key={`${id}-${index}`} textSize={13} weight={500} style={{ marginBottom: 10 }}>
            {fieldsSchema[id].label}
          </Text>
        );
      case 'string':
      case 'secret':
        return (
          <div key={`${id}-${index}`} style={{ marginBottom: 0 }}>
            <Input {...props} />
          </div>
        );
      case 'chip_list':
        return (
          <div key={`${id}-${index}`} style={{ marginBottom: 0 }}>
            <ChipsList {...props} />
          </div>
        );
      case 'link':
        return (
          <div key={`${id}-${index}`} style={{ marginBottom: 0 }}>
            <IntegrationLink {...props} />
          </div>
        );
      case 'radio':
        return (
          <div key={`${id}-${index}`} style={{ marginBottom: 0 }}>
            <RadioButtons {...props} />
          </div>
        );
      case 'script':
        return (
          <div key={`${id}-${index}`} style={{ marginBottom: 0 }}>
            <IntegrationScript {...props} />
          </div>
        );
      case 'file':
        return (
          <div key={`${id}-${index}`} style={{ marginBottom: 0 }}>
            <FileUpload {...props} />
          </div>
        );
      case 'select': {
        if (!!props.readonly) {
          return (
            <div>
              <Text textSize={13} weight={500} style={{ marginBottom: 10 }}>
                {fieldsSchema[id].label}
              </Text>
              <div>
                <Text textColor="#5D6576" family="Rubik" textSize={13} style={{ flexGrow: 1, whiteSpace: 'nowrap' }}>
                  {field.choices.filter((option: any) => option.id === fieldsValue[id])[0]?.label}
                </Text>
              </div>
            </div>
          );
        }
        return (
          <div key={`${id}-${index}`} style={{ marginBottom: 0 }}>
            <DropDown {...props} />
          </div>
        );
      }
      default:
        return <div key={`${id}-${index}`}>Field type not supported</div>;
    }
  };

  const getStepFields = () => {
    const instructions = stepFields.instructions ? (
      <Text textSize={12} weight={400} style={{ marginBottom: 10 }}>
        {stepFields.instructions}
      </Text>
    ) : null;
    const readonlyFields =
      stepFields.read_only?.map((id: string, index: number) => {
        return getComponent(id, index);
      }) || [];
    const inputFields =
      stepFields.input?.map((id: string, index: number) => {
        return getComponent(id, index);
      }) || [];

    return [instructions, ...readonlyFields, ...inputFields];
  };

  return (
    <div className="WizardContent">
      <div className="fields-content">
        {getStepFields()}
        {errorText && (
          <div className="error-text">
            <Text textSize={12} weight={400} style={{ color: '#F46666', marginLeft: 2 }}>
              {errorText}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default WizardContent;
