import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomSwitch } from 'common/components/CustomSwitch/CustomSwitch';
import { Collapse as MuiCollapse } from '@mui/material';
import classNames from 'classnames';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { iconTrash } from '../IntegrationBox';
import { Text } from '@cyberpion/cyberpion-ui';
import IntegrationCompletedContent from '../IntegrationCompletedContent/IntegrationCompletedContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconMenu } from 'common/components/Icons/IconMenu/IconMenu';
import { RestApi } from 'common/services/rest-api.service';
import { showSuccess } from 'features/AlertSlice';
// import { fetchIntegrationsMetadata } from 'features/IntegrationsSlice';

import './IntegrationCompletedItem.scss';

interface IIntegrationCompletedItem {
  integration: any;
  type: string;
  trashOnClick: (ev: any) => void;
  onEdit: (id: string, name: string, step: number, fields: any) => void;
}

const IntegrationCompletedItem: React.FC<IIntegrationCompletedItem> = ({ integration, type, trashOnClick, onEdit }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isActive, setIsActive] = useState(!!integration.is_active);
  const [isOpen, setIsOpen] = useState(true);
  const { integrationSchema } = useSelector((state: any) => state.integrations);

  const options = integrationSchema.menu?.choices || [];
  const dispatch = useDispatch<any>();

  const onChange = (e: React.ChangeEvent, isChecked: boolean) => {
    setIsActive(isChecked);
    const url = `settings/integrations/connections/connection/${integration.id}/`;
    RestApi.setData(url, { is_active: isChecked }, 'PATCH').subscribe(
      (response: any) => {
        dispatch(showSuccess(`Integration ${isChecked ? 'Activated' : 'Deactivated'} Successfully`));
        // dispatch(fetchIntegrationsMetadata());
      },
      error => {
        setIsActive(!isChecked);
        // dispatch(showError('Something went wrong'));
      }
    );
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const handleItemOnClick = (option: any) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
    if (option.id === 'delete') {
      trashOnClick(event);
    } else {
      onEdit(integration.id, integration.name, option.step, integration.fields);
    }
  };

  return (
    <div className={classNames('IntegrationCompletedItem', { disabled: !isActive })}>
      <div className="header">
        <div className="header-clickable" onClick={() => setIsOpen(!isOpen)}>
          <div className="chevron-icon">
            <IconArrow toggleRotate={isOpen} />
          </div>
          <Text textSize={13} weight={500} style={{ paddingLeft: 12 }} isText={true}>
            {integration.name}
          </Text>
        </div>
        <div style={{ flexGrow: 1 }}></div>
        {!!integrationSchema.single_activate && <CustomSwitch checked={isActive} onChange={onChange} />}
        {!options?.length && (
          <div className="delete-icon" onClick={trashOnClick}>
            {iconTrash}
          </div>
        )}
        {!!options.length && (
          <>
            <div onClick={handleMenuClick} className={classNames('icon-menu')}>
              <IconMenu active={false} />
            </div>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              {options.map((option: any, index: number) => {
                return (
                  <div key={index}>
                    <MenuItem
                      key={option.id}
                      disabled={option.id !== 'delete' ? !isActive : false}
                      sx={
                        option.id === 'delete'
                          ? {
                              fontSize: 12,
                              fontFamily: 'Rubik',
                              color: '#f46666',
                              padding: '8px 16px',
                              margin: '0 4px',
                              borderRadius: '4px'
                            }
                          : {
                              fontSize: 12,
                              fontFamily: 'Rubik',
                              padding: '8px 16px',
                              margin: '0 4px',
                              borderRadius: '4px'
                            }
                      }
                      onClick={handleItemOnClick(option)}
                      classes={{ root: 'menu-item' }}
                    >
                      {option.label}
                    </MenuItem>
                  </div>
                );
              })}
            </Menu>
          </>
        )}
      </div>

      <MuiCollapse in={isOpen} timeout="auto" unmountOnExit>
        <div className={classNames('block-content', { active: isActive }, { inActive: !isActive })}>
          <IntegrationCompletedContent integration={integration} />
        </div>
      </MuiCollapse>
    </div>
  );
};

export default IntegrationCompletedItem;
