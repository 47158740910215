import './mapLegend.scss';
import * as d3 from 'd3';
import React from 'react';
export function MapLegend(props: any) {
  let data = [
    {
      label: 'Digital Supply Chain',
      key: 'external',
      color: '#EF8068',
      toggle: true
    },
    {
      label: 'Org Assets',
      key: 'org',
      color: '#4B7AE7',
      toggle: true
    }
  ];
  const [dataLegend, setDataLegend] = React.useState<any>(data);
  function filterGroup(item: any, index: number) {
    let data = [...dataLegend];
    data[index].toggle = !item.toggle;
    d3.selectAll('.group-' + item.key)
      .transition()
      .duration(400)
      .style('opacity', data[index].toggle ? 1 : 0)
      .attr('r', 0);
    setDataLegend(data);
  }

  return (
    <div className="mapLegend-scope">
      {dataLegend.map((item: any, index: number) => (
        <div className="mapLegend-label-scope" key={index}>
          <div
            onClick={() => {
              filterGroup(item, index);
            }}
            className="mapLegend-circle"
            style={{
              background: item.toggle ? item.color : '#fff',
              borderColor: item.color
            }}
          ></div>
          <div className="mapLegend-label">{item.label}</div>
        </div>
      ))}
    </div>
  );
}
