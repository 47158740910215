import { PopupHeader } from 'common/components/PopupHeader/PopupHeader';
import { AlertMulti } from './AlertMulti/AlertMulti';
import { AlertSingle } from './AlertSingle/AlertSingle';
import './AlertPopup.scss';
import classNames from 'classnames';

export function AlertPopup(props: any) {
  const handleClosePopup = () => {
    props.setVisible(false);
  };

  return (
    <div
      className={classNames('AlertPopup', 'popup-inner-wrapper', {
        multi: props.data.isMultiAlerts
      })}
    >
      <PopupHeader text={!props.data.hideTitle ? props.data.name : ''} onClose={handleClosePopup} />
      <div className="scrollbar-common">
        <div className="popup-inner">
          <div style={{ height: '100%', overflow: 'auto' }}>
            {props.data.isMultiAlerts ? (
              <AlertMulti id={props.data.alertId} title={props.data.title} type={props.data.type} />
            ) : (
              <AlertSingle subAlertId={props.data.subAlertId} id={props.data.alertId} onClose={handleClosePopup} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
