// import { ASM_RADAR_CATEGORY_MAP, shouldShowHighlight } from '@cyberpion/cyberpion-ui';
import { ASM_RADAR_CATEGORY_MAP } from '@cyberpion/cyberpion-ui';
import { RestApi } from '../../services/rest-api.service';
import { Common } from 'common/services/common.service';

const shouldShowHighlight = (items: any, item: any) => {
  const hideIfPresent = item.not_present_if_previously_appeared.split(',');
  if (hideIfPresent.length) {
    for (let _cat in items) {
      const match = items[_cat].filter((_item: any) => hideIfPresent.includes(_item.id));
      const hasValue = match.filter((_m: any) => _m.count);
      if (hasValue.length) {
        return false;
      }
    }
  }
  return true;
};

const getSeverity = (row: any) => {
  if (row?.urgency?.urgency) {
    return row.urgency.urgency;
  } else if (row?.risk_score?.risk_score) {
    return Math.floor(row.risk_score.risk_score * 0.1);
  } else {
    return 0;
  }
};

const addAdditionalCounts = (results: any) => {
  const additionalCountResults = results.map((row: any) => {
    if (row.min_count && row.additional_count_id) {
      const additionalCountId = row.additional_count_id;
      const additionalCountItem = results.filter((row: any) => row.id === additionalCountId)[0];
      if (additionalCountItem && additionalCountItem.count + row.min_count > row.count) {
        return { ...row, count: additionalCountItem.count + row.min_count };
      }
    }
    return row;
  });

  return additionalCountResults;
};

export const fetchASMRadar = async (configJson: any, globalFilter: string, shouldAddResults: boolean = false) => {
  let resultJson: any = {};

  const categories: string[] = Object.keys(configJson).sort();
  for (let i = 0; i < categories.length; i++) {
    const urls = configJson[categories[i]]
      .sort((a: any, b: any) => b.priority - a.priority)
      .map((object: any) => {
        if (globalFilter) {
          return object.api_call + `&group=${globalFilter}`;
        } else {
          return object.api_call;
        }
      });
    const promises: any = await RestApi.getPromiseAll(urls);
    const results: any[] = [];
    configJson[categories[i]]
      .sort((a: any, b: any) => b.priority - a.priority)
      .forEach((object: any, index: number) => {
        if (promises[index]) {
          results.push({
            ...object,
            hyperlink: Common.addGlobalFilterIfNeeded(Common.AddMsspAccountIfNeeded(object.hyperlink)),
            count: promises[index].count,
            severity: object.severity === null ? getSeverity(promises[index].results[0]) : object.severity,
            ...(shouldAddResults ? { results: promises[index].results } : {})
          });
          index++;
        }
      });
    resultJson[categories[i]] = addAdditionalCounts(results.filter((row: any) => row.count));
    if (Object.keys(resultJson).length === categories.length) {
      let filteredConfigJson: any = {
        1: [],
        2: [],
        3: [],
        '1.5': [],
        '2.5': []
      };
      for (let category in resultJson) {
        resultJson[category] = resultJson[category].filter((item: any) => {
          return shouldShowHighlight(resultJson, item);
        });
        resultJson[category] = resultJson[category].slice(0, ASM_RADAR_CATEGORY_MAP[category].limit);
        for (let i = 1; resultJson[category].length >= i; i++) {
          filteredConfigJson = {
            ...filteredConfigJson,
            ...(!!filteredConfigJson[category]
              ? { [category]: [...filteredConfigJson[category], resultJson[category][i - 1]] }
              : { [category]: [resultJson[category][i - 1]] })
          };
        }
      }
      console.log('ASM Radar Data', filteredConfigJson);

      return filteredConfigJson;
    }
  }
};
