import React from 'react';
import { ToolTip } from 'common/components/toolTip/toolTip';
import { Progress, Text } from '@cyberpion/cyberpion-ui';
import { useSelector } from 'react-redux';

const WizardHeader = () => {
  const { integrationSchema, fieldsValue, currentStep, totalSteps } = useSelector((state: any) => state.integrations);

  return (
    <div className="WizardHeader">
      {totalSteps !== 1 && (
        <Progress
          step={currentStep}
          total={totalSteps}
          color={'#3659DE'}
          // color={currentStep === totalSteps ? '#1AA884' : '#3659DE'}
          width={75}
          thick={4}
        />
      )}
      <div className="wizard-title">
        <Text textSize={13} weight={400}>
          {integrationSchema.label}
        </Text>
        <div>
          <span data-tip={fieldsValue.name || integrationSchema.label} data-for="INTEGRATION_NAME">
            <Text
              textSize={18}
              weight={500}
              style={{ marginTop: 4, textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 395 }}
              isText={true}
            >
              {fieldsValue.name || integrationSchema.label}
            </Text>
          </span>
        </div>
      </div>
      <ToolTip id="INTEGRATION_NAME" place="bottom" theme="dark" effect="solid" />
    </div>
  );
};

export default WizardHeader;
