import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconHeroku extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.1786 21L9.07146 18.4286L6.1786 15.8571V21ZM16.5929 10.5857C16.0786 10.0714 15.1143 9.42857 13.5072 9.42857C11.7715 9.42857 9.97146 9.87857 8.68575 10.3286V3H6.11432V14.1214L7.91432 13.2857C7.91432 13.2857 10.8715 11.9357 13.4429 11.9357C14.7286 11.9357 15.05 12.6429 15.05 13.2857V21H17.6215V13.2857C17.6857 13.0929 17.6857 11.6786 16.5929 10.5857ZM13.1857 7.17857H15.7572C16.9143 5.82857 17.4929 4.47857 17.6857 3H15.1143C14.8572 4.47857 14.2143 5.82857 13.1857 7.17857Z"
          fill="#8C909A"
        />
      </svg>
    );
  }
}
