// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
// import { addHeaders } from 'common/services/rest-api.service';
// import { env } from 'env';

// export const fetchSummarySubsidiaries = createAsyncThunk(
//   'subsidiaries/fetchSummarySubsidiaries',
//   async (payload, { rejectWithValue }) => {
//     const path = `${env.REACT_APP_BASE_URL}assessments/org_score/summary/subsidiaries/`;
//     try {
//       const response = await fetch(path, {
//         method: 'GET',
//         mode: 'cors',
//         headers: addHeaders('GET'),
//         credentials: 'include'
//       });
//       const data = await response.json();
//       return data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

const ColumnPickerSlice = createSlice({
  name: 'columnPicker',
  initialState: {
    columnPicker: [],
    showColumnPicker: false
  },
  reducers: {
    setColumnPicker(state, action) {
      state.columnPicker = action.payload;
    },
    setShowColumnPicker(state, action) {
      state.showColumnPicker = action.payload;
    },
    resetColumnPicker(state) {
      state.columnPicker = [];
      state.showColumnPicker = false;
    }
  }
});

const { actions, reducer } = ColumnPickerSlice;
export const { setColumnPicker, setShowColumnPicker, resetColumnPicker } = actions;

export default reducer;
