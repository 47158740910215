import React from 'react';
import { IconRadioButton } from 'common/components/Icons/IconRadioButton/IconRadioButton';
import { Grid } from '@mui/material';
import { useNotifications, NotificationsContextType } from 'pages/Settings/Notifications/NotificationsContext';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { REQUIRED_FILTERS } from 'pages/Settings/Notifications/SidePanel/utils';

interface IRadioButtons {
  id: string;
}

const RadioButtons: React.FC<IRadioButtons> = ({ id }) => {
  const { notificationType } = useNotifications() as NotificationsContextType;
  const {
    notificationData,
    changedNotificationData,
    onConfigurationChange,
    onChangeNotificationTypeDropdown
  } = useNotificationConfiguration() as NotificationConfigurationContextType;

  const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
  const data = requiredFields.includes(id) ? notificationData : notificationData.filters;
  const areFilters = !!data && !!data[id];

  let options;
  if (id === 'configuration_id') {
    const channel = changedNotificationData?.channel_type;
    options =
      areFilters &&
      data[id].choices
        .filter((option: any) => option.type === channel)
        .map((option: any) => ({
          ...option,
          is_toggled: !!option.is_toggled
        }));
  } else {
    options =
      areFilters &&
      data[id].choices.map((option: any) => ({
        ...option,
        is_toggled: !!option.is_toggled
      }));
  }

  const handleOnChange = (selectedItem: any) => () => {
    if (id === 'notification_type') {
      onChangeNotificationTypeDropdown(selectedItem.name);
    } else {
      onConfigurationChange(id, selectedItem.name);
    }
  };

  return (
    <Grid container direction="column">
      {!!options &&
        options.map((option: any) => {
          return (
            <Grid
              item
              key={option.name}
              onClick={handleOnChange(option)}
              className="dropdownbox-item"
              data-testid={`dropdown-radio-${id}-${option.name}`}
            >
              <Grid container alignItems="center">
                <Grid item>
                  <IconRadioButton radioSize="little" toggle={option?.is_toggled} />
                </Grid>
                <Grid item sx={{ paddingLeft: 1, fontSize: 12 }}>
                  {option.label}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default RadioButtons;
