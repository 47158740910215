import { RestApi } from 'common/services/rest-api.service';
import './Download.scss';

export function Download(props: any) {
  const prepareLink = () => {
    const subAlertId = props.row.id;
    const parentAlertId = props.row.alert_id;
    RestApi.getData(`assessments/drps/darkweb/alerts/${parentAlertId}/sub_alerts/${subAlertId}/download/`).subscribe(
      (response: any) => {
        window.open(response.link);
      }
    );
  };

  return (
    <div className="Download">
      {props.row && props.row.is_downloadable && (
        <img onClick={prepareLink} src="/assets/images/download-link.svg" alt="download" />
      )}
    </div>
  );
}
