import React, { forwardRef } from 'react';
import { IconImportance, IconRisk, Text } from '@cyberpion/cyberpion-ui';

const MisconfiguredDetails = forwardRef(({ data }: { data: any }, refDetails: any) => {
  const { singleAsset, row } = data;
  const ips = singleAsset?.domain?.ips || singleAsset?.subdomain?.ips;

  return (
    <div ref={refDetails} className="misconfigured-login-details">
      <div>
        <Text weight={600} style={{ marginBottom: 4 }} upper textSize={13}>
          {row?.asset}
        </Text>
        <Text textSize={12}>{row?.login_url}</Text>
      </div>

      <div style={{ display: 'flex', marginTop: 24, alignItems: 'center' }}>
        <div className="risk-icon" style={{ marginRight: 4 }}>
          <IconRisk urgency={singleAsset?.risk_score?.type} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            Asset Risk
          </Text>
          <Text>{singleAsset?.risk_score?.risk_score}</Text>
        </div>
        {singleAsset?.importance && (
          <div
            style={{
              display: 'flex',
              paddingRight: 16,
              paddingLeft: 16,
              alignItems: 'center',
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <div style={{ paddingRight: 8 }}>
              <IconImportance type={singleAsset?.importance?.type} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text weight={600} style={{ whiteSpace: 'nowrap', marginBottom: 4 }}>
                Asset Importance
              </Text>
              <Text>{singleAsset?.importance?.type}</Text>
            </div>
          </div>
        )}
        {row?.login_type && (
          <div style={{ paddingLeft: 16, borderLeft: '1px solid #ECECEC' }}>
            <Text weight={600} style={{ marginBottom: 4 }}>
              Login Type
            </Text>
            <Text>{row.login_type}</Text>
          </div>
        )}
      </div>
      {!!ips && !!ips.length && (
        <div style={{ marginTop: 24 }}>
          <Text textSize={11} weight={600} style={{ marginBottom: 4 }}>
            {`${singleAsset?.type !== 'ip' ? 'IPs' : 'IP Network'}`}
          </Text>
          <Text textColor="#454549">{ips.join(' / ')}</Text>
        </div>
      )}
      <div style={{ display: 'flex', marginTop: 24, alignItems: 'center' }}>
        {!!row.is_valid_certificate !== undefined && (
          <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 16 }}>
            <Text weight={600} style={{ marginBottom: 4 }}>
              Valid Certificate
            </Text>
            <Text style={{ textTransform: 'capitalize' }}>{row.is_valid_certificate.toString()}</Text>
          </div>
        )}
        {!!row.service_type && !!row.service_type.length && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingRight: 16,
              paddingLeft: 16,
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <Text weight={600} style={{ marginBottom: 4 }}>
              Service Type
            </Text>
            <Text>{row.service_type.join(' / ')}</Text>
          </div>
        )}
        {!!row.service && !!row.service.length && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingRight: 16,
              paddingLeft: 16,
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <Text weight={600} style={{ marginBottom: 4 }}>
              Service
            </Text>
            <Text>{row.service.join(' / ')}</Text>
          </div>
        )}
        {!!row.is_over_http !== undefined && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingRight: 16,
              paddingLeft: 16,
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <Text weight={600} style={{ marginBottom: 4 }}>
              Over HTTP
            </Text>
            <Text style={{ textTransform: 'capitalize' }}>{row.is_over_http.toString()}</Text>
          </div>
        )}
        {!!row.is_over_https !== undefined && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingRight: 16,
              paddingLeft: 16,
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <Text weight={600} style={{ marginBottom: 4 }}>
              Over HTTPS
            </Text>
            <Text style={{ textTransform: 'capitalize' }}>{row.is_over_https.toString()}</Text>
          </div>
        )}
      </div>
    </div>
  );
});

export default MisconfiguredDetails;
