import React from 'react';
import Button from '@mui/material/Button';
import classNames from 'classnames';

export const svgCollapse = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.09961 9L9.99988 11.5L13.0996 9" stroke="#5D6576" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

interface ICollapseButton {
  active?: boolean;
}
const CollapseButton: React.FC<ICollapseButton> = ({ active }) => {
  return (
    <Button
      className={classNames('collapse-button', { collapse: !active }, { expand: !!active })}
      startIcon={svgCollapse}
      aria-label="collapse"
      variant="outlined"
      sx={{ minWidth: 20, minHeight: 20 }}
    />
  );
};

export default CollapseButton;
