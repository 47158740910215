import './EditScanFrequency.scss';
import { useState } from 'react';
import { DropDown } from '@cyberpion/cyberpion-ui';
import { IReduxState } from 'app/store';
import { useSelector } from 'react-redux';

export function EditScanFrequency(props: any) {
  const { companyConfig, accountInfo } = useSelector((state: IReduxState) => state.mssp);
  const [value, setValue] = useState<string>(accountInfo.scan_frequency || '');
  return (
    <div className="EditScanFrequency">
      <h5>Scan frequency</h5>
      <DropDown
        options={
          companyConfig.allowed_frequencies?.map((af: string) => {
            return { label: af, value: af };
          }) || []
        }
        onChange={(newVal: string) => {
          setValue(newVal);
          props.onChange({
            scan_frequency: newVal
          });
        }}
        value={value}
        error={false}
      />
    </div>
  );
}
