import React, { useState, useEffect } from 'react';
import { InventoryRouting } from './InventoryRouting';
import { updateHeader } from 'common/services/common.service';
import { Links } from 'common/components/links/Links';
import { TABSET } from './config';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { RestApi } from 'common/services/rest-api.service';
import { useSelector } from 'react-redux';

const PAGE_NAME = 'Discovery';

const Inventory = (props: any) => {
  const [metaData, setMetaData] = useState<any>({});
  const { isLoggedIn } = useSelector((state: any) => state.login);
  const { globalFilter } = useSelector((state: any) => state.general);

  useEffect(() => {
    if (isLoggedIn) {
      updateHeader.sendHeaderText(PAGE_NAME);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    getMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  const getMetaDataUrl = () => {
    return {
      metaData: `${Paths[PathNames.inventoryMetaData]}${globalFilter ? `?group=${globalFilter}` : ''}`
    };
  };

  const getMetaData = () => {
    RestApi.getDataPage(getMetaDataUrl()).subscribe((response: any) => {
      setMetaData(response.metaData);
    });
  };

  return (
    <div className="sub-layout-pages">
      <div>
        <Links page={'Discovery'} selectTab={props.match.params.tab} tabs={TABSET} metaData={metaData} />
      </div>
      <InventoryRouting source={PAGE_NAME} selectTab={props.match.params.tab} />
    </div>
  );
};

export default Inventory;
