import React, { useState, useEffect } from 'react';
import NotificationsHeader from './NotificationsHeader/NotificationsHeader';
import NotificationsList from './NotificationsList/NotificationsList';
import { IconSearchNoResults } from 'common/components/Icons/IconSearchNoResults/IconSearchNoResults';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { Common } from 'common/services/common.service';
import EmptyState from './EmptyState/EmptyState';
import { useNotifications, NotificationsContextType, NOTIFICATIONS_LIMIT } from './NotificationsContext';
import SidePanel from './SidePanel/SidePanel';
import { useDispatch } from 'react-redux';
import { showError } from 'features/AlertSlice';
import { loadList } from 'features/IntegrationsSlice';
import Fade from '@mui/material/Fade';

import classNames from 'classnames';

import './notifications.scss';

const Notifications = () => {
  const {
    allNotifications,
    filteredNotifications,
    isOpen,
    setIsOpen,
    actionMode,
    setActionMode,
    setSelectedNotification,
    isLoading,
    notificationsAmount
  } = useNotifications() as NotificationsContextType;
  const [showMask, setShowMask] = useState(false);
  const dispatch = useDispatch<any>();

  // TODO: check if it is needed
  useEffect(() => {
    dispatch(loadList());
  }, [dispatch]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedNotification(null);
      setActionMode(null);
      setShowMask(false);
    }
    Common.displayHelp(!isOpen);
  }, [isOpen, setSelectedNotification, setActionMode]);

  useEffect(() => {
    if (actionMode === 'add' || actionMode === 'new') {
      setShowMask(true);
      setSelectedNotification(null);
    }
    if (actionMode === 'edit') {
      setShowMask(true);
    }
  }, [actionMode, setSelectedNotification]);

  const onAdd = () => {
    if (!!notificationsAmount || notificationsAmount === 0) {
      if (notificationsAmount < NOTIFICATIONS_LIMIT) {
        setIsOpen(true);
        setActionMode('add');
      } else {
        dispatch(showError('Notifications limit reached'));
      }
    }
  };

  const handleOnPanelClose = () => {
    setIsOpen(false);
  };
  // const handleOnPanelClose = useCallback(() => {
  //   setIsOpen(false);
  // }, []);

  const showOrder = !!allNotifications && allNotifications.length > 1;

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <div
        className={classNames('notifications', showMask && 'disable')}
        style={{
          // marginRight: -drawerWidth,
          flexGrow: 1,
          overflow: 'hidden',
          ...(isOpen && { marginRight: 0 })
        }}
      >
        <NotificationsHeader onAdd={onAdd} showOrder={showOrder} isPanelOpen={showMask} />
        {isLoading && <AppLoading size="big" />}
        {!isLoading && !!filteredNotifications?.length && (
          <Fade in timeout={800}>
            <div className="scrollbar-common" style={{ height: '100%', overflow: 'auto' }}>
              <div className="notifications-list">
                <NotificationsList />
              </div>
            </div>
          </Fade>
        )}
        {!isLoading && allNotifications?.length === 0 && <EmptyState onAdd={onAdd} />}
        {!isLoading && !!allNotifications?.length && !filteredNotifications?.length && (
          <div className="notifications-no-results">
            <IconSearchNoResults />
          </div>
        )}
      </div>
      <SidePanel onPanelClose={handleOnPanelClose} isOpen={isOpen} />
    </div>
  );
};

export default Notifications;
