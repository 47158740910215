import { IconTabExternalAssets } from 'common/components/Icons/IconTabExternalAssets/IconTabExternalAssets';
import { IconTabInternalAssets } from 'common/components/Icons/IconTabInternalAssets/IconTabInternalAssets';
import { IconOrgScore } from 'common/components/Icons/IconOrgScore/iconOrgScore';
import { IconVendorMonitoring } from 'common/components/Icons/IconVendorMonitoring/iconVendorMonitoring';
import { PathNames } from 'common/constants/pathNames';
import { IconTabDRPS } from 'common/components/Icons/IconTabDRPS/IconTabDRPS';
interface ITabset {
  [key: string]: ITab;
}
export interface ITab {
  title: string;
  key: string;
  link: string | [string, string];
  IconComponent: any;
  permissionOpen: boolean;
  active: boolean;
  showSummary: boolean;
  columnPickerPath: string;
  gridPath: string;
  count: number;
  lockedTo?: string[];
  featureFlag?: string | [string, string];
  subTabs?: any;
  defaultView?: string;
  savedViews?: boolean;
}
export const TABSET: ITabset = {
  internalAssets: {
    title: 'Org Assets',
    key: 'internal_assets',
    IconComponent: IconTabInternalAssets,
    link: '/pages/assessments/internalAssets',
    defaultView:
      '/pages/assessments/internalAssets?fields=asset%2Crisk_score%2Ctype%2Copen_ports%2Cnetwork_risk_grade%2Ccloud_risk_grade%2Ccertificates_risk_grade%2Ctls_risk_grade%2Cdns_risk_grade%2Cweb_risk_grade%2Cemail_risk_grade%2Cservice_type%2Cservice%2Ctags%2Cgroups',
    permissionOpen: true,
    active: false,
    showSummary: false,
    columnPickerPath: PathNames.assesmentsInternalAssetsColumns,
    gridPath: PathNames.assesmentsInternalAssets,
    count: 0,
    savedViews: true
  },
  digitalSupplyChain: {
    title: 'Digital Supply Chain',
    key: 'external_assets',
    IconComponent: IconTabExternalAssets,
    link: '/pages/assessments/digitalSupplyChain',
    defaultView:
      '/pages/assessments/digitalSupplyChain?fields=asset%2Crisk_score%2Cconnected_assets%2Cconnections_types%2Ccloud_services%2Ccloud_services_descriptions%2Ctechnologies%2Cvulnerability_count%2Cgroups',
    permissionOpen: true,
    active: false,
    showSummary: false,
    columnPickerPath: PathNames.assesmentsDigitalSupplyChainColumns,
    gridPath: PathNames.assesmentsDigitalSupplyChain,
    count: 0,
    savedViews: true
  },
  drps: {
    title: 'Cyber Threat Intelligence',
    key: 'drps',
    IconComponent: IconTabDRPS,
    link: ['/pages/assessments/drps/compromisedDevices', '/pages/assessments/drps/deepDarkWebMonitoring'],
    featureFlag: ['isCompromisedDevices', 'isDeepAndDarkWebMonitoring'],
    columnPickerPath: '',
    gridPath: '',
    permissionOpen: true,
    active: false,
    showSummary: false,
    count: 0,
    subTabs: {
      compromisedDevices: {
        title: 'Compromised Devices',
        key: 'compromisedDevices',
        link: '/pages/assessments/drps/compromisedDevices',
        columnPickerPath: PathNames.assesmentsLeakedCredentialsColumns,
        gridPath: PathNames.assesmentsLeakedCredentials,
        count: 0,
        featureFlag: 'isCompromisedDevices'
      },
      deepDarkWebMonitoring: {
        title: 'Deep & Dark Web Monitoring',
        key: 'deepDarkWebMonitoring',
        link: '/pages/assessments/drps/deepDarkWebMonitoring',
        columnPickerPath: PathNames.assesmentsDeepDarkMonitoringColumns,
        gridPath: PathNames.assesmentsDeepDarkMonitoring,
        count: 0,
        featureFlag: 'isDeepAndDarkWebMonitoring'
      }
    }
  },
  orgScore: {
    title: 'Attack Surface Risk Score',
    key: 'org_score',
    IconComponent: IconOrgScore,
    link: '/pages/assessments/orgScore',
    permissionOpen: true,
    active: false,
    showSummary: false,
    columnPickerPath: '',
    gridPath: '',
    count: 0,
    // lockedTo: ['crowdstrike','anon500','ionix']
    featureFlag: 'isOrgScore'
  },
  vendorMonitoring: {
    title: 'Vendor Monitoring',
    key: 'vendor_monitoring',
    IconComponent: IconVendorMonitoring,
    link: '/pages/assessments/vendorMonitoring',
    defaultView: '',
    permissionOpen: true,
    active: false,
    showSummary: false,
    columnPickerPath: PathNames.assesmentsVendorMonitoringColumns,
    gridPath: PathNames.assesmentsVendorMonitoring,
    count: 0,
    lockedTo: ['crowdstrike']
  }
};
