import './actionItemsSummar.scss';
import { Sections } from 'common/components/summary/sections/Sections';
import { IconActionItems } from 'common/components/Icons/IconActionItems/IconActionItems';
import { HeaderForm } from '../headerForm/HeaderForm';
import { Common } from 'common/services/common.service';
export function ActionItemsSummar(props: any) {
  return Common.isNotEmpty(props) ? (
    <div className="actionItemsTrend-scope">
      <HeaderForm IconComponent={IconActionItems} {...props} />
      <div className="actionItemsTrend-sections-scope">
        <Sections {...props} />
      </div>
    </div>
  ) : (
    <div className="actionItemsTrend-scope display-center">No Data</div>
  );
}
