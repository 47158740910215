import React from 'react';
import { IIcon } from 'common/interfaces/interface';
export class IconDiscover extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#5D6576';
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 16 16" fill="none">
        <path d="M15.5 5.5L8 0.5L5.65084 2.06611L0.5 5.5L8 10.5L15.5 5.5Z" stroke={color} strokeLinejoin="round" />
        <path d="M8 5.5L11.75 8M8 5.5L4.25 8M8 5.5V3.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.5 5.5V10.5L8 15.5L0.5 10.5V5.5" stroke={color} strokeLinejoin="round" />
        <path d="M8 10V15.5" stroke={color} />
      </svg>
    );
  }
}
