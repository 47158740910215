// @ts-nocheck
import React from 'react';
import Grid from '@mui/material/Grid';
import { Text, getPDFFormatedDate } from '@cyberpion/cyberpion-ui';
import CoverLegend from './CoverLegend';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';

const CoverPage = () => {
  const { PAGE_HEIGHT } = useThreatsReport() as ThreatsReportContextType;
  const date = getPDFFormatedDate();
  const {
    companyName,
    displayCompany,
    logoUrl,
    keepParagraph,
    globalFilter
  } = useThreatsReport() as ThreatsReportContextType;

  return (
    <Grid container className="threats-report-cover-page" maxHeight={PAGE_HEIGHT}>
      <Grid item flex={1}>
        <Grid container flexDirection={'column'} sx={{ marginTop: 7, marginLeft: 5 }}>
          <Grid item marginBottom={1} marginLeft={2}>
            <img
              alt="search"
              src={!!logoUrl ? logoUrl : `/assets/images/logo-ionix-black.svg`}
              style={{ maxWidth: 250 }}
            />
          </Grid>
          <Grid item sx={{ margin: '35px 20px' }}>
            <Text weight={500} textSize={20} textColor="#000000" upper>
              Threat Exposure Report
            </Text>
            <Text weight={500} textSize={18} textColor="#000000" upper style={{ paddingTop: 3 }}>
              {!!displayCompany ? displayCompany : companyName}
            </Text>
            {!!globalFilter && (
              <Text weight={500} textSize={18} textColor="#000000" upper style={{ paddingTop: 3 }}>
                {globalFilter}
              </Text>
            )}
          </Grid>
          <Grid item marginLeft={2.5}>
            {keepParagraph && (
              <Grid container flexDirection="column">
                <Grid item marginBottom={2}>
                  <Text upper textSize={14} weight={600}>
                    Our Mission
                  </Text>
                </Grid>
                <Grid item marginBottom={4}>
                  <Text textSize={13} textColor="#2E2E2F" style={{ width: 400 }}>
                    IONIX is on a mission to transform the way organizations see, manage, and reduce their exposure
                    across their Attack Surface. The explosion of cloud infrastructure and services, consumerization of
                    IT and new and distributed system architectures are creating an everchanging, unknown and
                    uncontrolled attack surface. As enterprises become increasingly Internet exposed, their invisible
                    attack surface poses an unparalleled challenge for security teams. This report provides a view of
                    the top Threat Exposures that were detected by IONIX across the attack surface. 
                  </Text>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item sx={{ marginLeft: 2.5 }}>
            <CoverLegend />
          </Grid>
        </Grid>
      </Grid>
      <Grid item flex={0}>
        <img
          src={
            !!displayCompany
              ? '/assets/images/threat_pdf_cover_logo_custom.svg'
              : '/assets/images/threat_pdf_cover_logo.svg'
          }
          alt="cover page PDF"
          height={1252}
        ></img>
      </Grid>
      <Grid item sx={{ position: 'relative' }}>
        <Text
          textColor="#FFF"
          textSize={11}
          style={{ position: 'absolute', top: 70, right: 50, width: 'max-content' }}
        >{`${!!displayCompany ? displayCompany : companyName}${
          !!globalFilter ? ', ' + globalFilter : ''
        }, ${date}`}</Text>
      </Grid>
    </Grid>
  );
};

export default CoverPage;
