import { useEffect, useState } from 'react';
import { IColumnPicker } from 'common/interfaces/interface';
import { Common } from 'common/services/common.service';
import { Input } from '../Input/Input';
import { DropdownHeader } from './components/dropdownHeader/DropdownHeader';
import { SelectGroup } from './components/selectGroup/SelectGroup';
import { SelectItem } from './components/selectItem/SelectItem';
import { IMultiSelect } from './interface';
import { useComponentVisible } from 'common/hooks/useComponentVisible';
import './multiSelect.scss';
import { ColumnsManage } from '../grid/columnsManage';
import { AppDispatch } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { setFields, getRows } from 'features/GridSlice';
import { SelectAll } from '../SelectAll/SelectAll';
import { IReduxState } from 'app/store';
import { selectView, setIsEdited } from 'features/ViewsSlice';

export function MultiSelect(props: IMultiSelect) {
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const [list, setList] = useState(Common.cloneData(columnPicker));
  const [toggledFields, setToggledFields] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  // const [numOfColumns, setNumOfColumns] = useState<number>(
  //   ColumnsManage.checkGridColumnsToDisplay(props.columnPicker).length
  // );
  const { fields, filters } = useSelector((state: any) => state.grid);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const copy = [...list];
    let addFields: string[] = [];
    copy.forEach((item: any) => {
      if (toggledFields.length && toggledFields.includes(item.name)) {
        item.is_toggled = true;
      } else {
        if (addFields.includes(item.name)) {
          item.is_toggled = true;
        } else if (fields.includes(item.name)) {
          item.is_toggled = true;
          item.choices.forEach((choice: any) => {
            if (choice.add_fields.length && choice.name === 1 && choice.is_toggled) {
              addFields = choice.add_fields;
            }
          });
        } else if (!item.child.length) {
          item.is_toggled = false;
        } else {
          item.child.forEach((childItem: any) => {
            if (fields.includes(childItem.name)) {
              childItem.is_toggled = true;
            }
          });
        }
      }
    });
    setList(copy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, filters, toggledFields]);

  //@param: value = Search input in dropdown
  //@return: new list
  //@description: Search the dropdown list
  function searchText(value: string) {
    setSearchInput(value);
  }

  function toggleList(value: boolean) {
    setIsComponentVisible(value);
    setSearchInput('');
    if (ColumnsManage.fields.length && (props.gridRef as any).current) {
      const copy = [...list];
      copy.forEach((item: any) => {
        if (item.child.length) {
          item.child.forEach((c: any) => {
            c.is_toggled = !(props.gridRef as any).current.getColumn(c.name).hide;
          });
        } else {
          const column = (props.gridRef as any).current.getColumn(item.name);
          if (column) {
            item.is_toggled = !(props.gridRef as any).current.getColumn(item.name).hide;
          } else {
            item.is_toggled = false;
          }
        }
      });
    }
    // setNumOfColumns(fields.length);
  }

  const shouldFetchNewData = (fields: string[], option: IColumnPicker): boolean => {
    // this specific option has is_fetch_new_data flag
    // which means every change triggers new request
    return true;
    // if (option.is_fetch_new_data) {
    //   return true;
    // }

    // if (fields.length < numOfColumns) {
    //   // colomn removal, no need to fetch new data
    //   return false;
    // } else {
    //   // colomn addition, need to fetch for new data
    //   return true;
    // }
  };

  //@param: option = ColumnPicker
  //@description: Hides or shows the columns
  //TODO : I know this is unreadable code. I'll fix it
  function selectItem(option: IColumnPicker) {
    const newList = list.map((item: IColumnPicker) => {
      if (option.type === 'group' && item.label === option.label) {
        return option;
      } else if (item.label === option.label) {
        const updatedItem = {
          ...item,
          is_toggled: item.is_toggled
        };
        return updatedItem;
      } else {
        item.child.forEach((element: IColumnPicker) => {
          if (element.label === option.label) {
            const updatedItem = {
              ...item,
              is_toggled: item.is_toggled
            };
            return updatedItem;
          }
        });
      }
      return item;
    });

    setList(newList);
    let fields = ColumnsManage.checkGridColumnsToDisplay(newList);
    setToggledFields(fields);
    const _shouldFetchNewData: boolean = shouldFetchNewData(fields, option);
    // setNumOfColumns(fields.length);
    ColumnsManage.hideColumns(props.gridRef, props.columns, fields);
    dispatch(setFields(fields));
    dispatch(setIsEdited(true));
    dispatch(selectView(null));
    if (_shouldFetchNewData) {
      dispatch(getRows());
    }
  }

  const isSomeSelected = (): boolean => {
    return list.some((item: IColumnPicker) => {
      return item.is_displayable && item.is_toggled;
    });
  };

  const isAllSelected = (): boolean => {
    return list.every((item: IColumnPicker) => {
      return (item.is_displayable && item.is_toggled) || !item.is_displayable;
    });
  };

  const toggleSelectAll = (toggleValue: boolean) => {
    const copy = list.map((item: IColumnPicker) => {
      let itemCopy = { ...item };
      const lowerCasedLabel = itemCopy.label.toLowerCase();
      if (itemCopy.is_displayable) {
        if (
          (searchInput && lowerCasedLabel.includes(searchInput.toLowerCase())) ||
          itemCopy.child.filter(child => child.label.toLowerCase().includes(searchInput.toLowerCase())).length ||
          !searchInput ||
          !toggleValue
        ) {
          itemCopy.is_toggled = toggleValue;
        }
      }
      if (itemCopy.child.length) {
        itemCopy.child.forEach((subItem: IColumnPicker, index: number) => {
          if (subItem.is_displayable) {
            if (
              (searchInput && subItem.label.toLowerCase().includes(searchInput.toLowerCase())) ||
              lowerCasedLabel.includes(searchInput.toLowerCase()) ||
              !searchInput ||
              !toggleValue
            ) {
              itemCopy.child[index].is_toggled = toggleValue;
            }
          }
        });
      }
      return itemCopy;
    });
    setToggledFields([]);
    setList(copy);
    let fields = ColumnsManage.checkGridColumnsToDisplay(copy);
    // setNumOfColumns(fields.length);
    ColumnsManage.hideColumns(props.gridRef, props.columns, fields);
    dispatch(setFields(fields));
    dispatch(getRows());
  };

  return (
    <div ref={ref} className="multi-select-wrapper">
      <DropdownHeader headerTitle={'Edit Columns'} isListOpen={isComponentVisible} onChange={toggleList} />
      {isComponentVisible && (
        <div className="scrollbar-common ms-options-wrapper">
          <div className="ms-input-scope">
            <Input icon={'search'} type={'text'} onChange={searchText} value={searchInput} placeholder="Search" />
          </div>
          {
            <SelectAll
              selectAll={() => toggleSelectAll(true)}
              unselectAll={() => toggleSelectAll(false)}
              isSomeSelected={isSomeSelected}
              isAllSelected={isAllSelected}
              // disabled={!!searchInput}
            />
          }
          <div className="select-items-scope">
            {list.map((item: IColumnPicker, key: number) => {
              return (
                <div key={key}>
                  {item.child.length === 0 ? (
                    <SelectItem option={item} onChange={selectItem} searchTerm={searchInput} />
                  ) : (
                    <SelectGroup option={item} onChange={selectItem} searchTerm={searchInput} />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
