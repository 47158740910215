import { useState } from 'react';
import './InputField.scss';
import classNames from 'classnames';

interface IInputField {
  type: string;
  placeholder?: string;
  onChange: Function;
  error?: string;
  disabled?: boolean;
}

export function InputField(props: IInputField) {
  const [value, setValue] = useState<string>('');

  const onChange = (e: any) => {
    setValue(e.target.value);
    props.onChange(e.target.value);
  };

  const getMaxLength = (): number => {
    switch (props.type.toLowerCase()) {
      case 'email':
        return 254;
      default:
        return 30;
    }
  };

  return (
    <div className="InputField">
      <input
        disabled={props.disabled}
        className={classNames({ error: !!props.error })}
        type={props.type}
        placeholder={props.placeholder}
        value={value}
        onChange={onChange}
        maxLength={getMaxLength()}
        autoFocus
      />
      {props.error && <div className="InputField-Error">{props.error}</div>}
    </div>
  );
}
