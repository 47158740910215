// @ts-nocheck
import React from 'react';
import { Text } from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';

import './ExposureDescription.scss';

const ExposureDescription = () => {
  return (
    <Grid container flexDirection="column" className="threats-pdf-description">
      <Grid item>
        <Text upper style={{ paddingLeft: 30, paddingTop: 20, fontSize: 14, fontWeight: 600 }}>
          Threat exposure radar
        </Text>
      </Grid>
      <Grid item style={{ paddingLeft: 30, paddingTop: 20, paddingBottom: 20 }}>
        <Grid container flexDirection="rows" width="100%">
          <Grid item flex={1}>
            <Grid container direction="column">
              <Grid item sx={{ paddingBottom: 0.5 }}>
                <Text weight={500} textSize={13}>
                  Digital Supply Chain
                </Text>
              </Grid>
              <Grid item sx={{ marginRight: 3 }}>
                <Text textColor="#6D6D7A" style={{ lineHeight: 1.35 }}>
                  Assets that the organization relies on, but does not own (Mail or DNS servers, Script Inclusion, APIs
                  or others)
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid item flex={1}>
            <Grid container direction="column">
              <Grid item sx={{ paddingBottom: 0.5 }}>
                <Text weight={500} textSize={13}>
                  External Org Assets
                </Text>
              </Grid>
              <Grid item>
                <Text textColor="#6D6D7A" style={{ lineHeight: 1.35 }}>
                  Assets hosted outside of the organizational network (hosting providers, non-controlled cloud
                  environments and others)
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid item flex={1} padding="0 30px">
            <Grid container direction="column">
              <Grid item sx={{ paddingBottom: 0.5 }}>
                <Text weight={500} textSize={13}>
                  Internal Org Assets
                </Text>
              </Grid>
              <Grid item>
                <Text textColor="#6D6D7A" style={{ lineHeight: 1.35 }}>
                  Assets hosted in the organizational network (in their on-premise data centers or controlled cloud
                  environments)
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExposureDescription;
