// @ts-nocheck
import React, { useRef } from 'react';
import Grid from '@mui/material/Grid';
import Contact from '../../Contact/Contact';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';
import usePageSize from '../hooks/usePageSize';

import './lastPage.scss';

const LastPage = () => {
  const { PAGE_HEIGHT, PAGE_WIDTH, displayCompany } = useThreatsReport() as ThreatsReportContextType;
  const refDetails = useRef(null);

  const { minHeight } = usePageSize(refDetails, 'lastPage'); // eslint-disable-line

  return (
    <Grid
      ref={refDetails}
      container
      flexDirection="column"
      minHeight={PAGE_HEIGHT}
      bgcolor="#fff"
      className="threats-report-last-page"
      // sx={{ marginTop: 2 }}
    >
      <Grid id="exposure-report-last-page" item position="relative" display="flex" justifyContent="center">
        <img
          src="/assets/images/threat_pdf_last_page_logo.png"
          alt="end cover page"
          width={PAGE_WIDTH}
          height={PAGE_HEIGHT}
        ></img>
        <div className="content" style={{ display: 'absolute', top: '50%' }}>
          {/* {<Logo src={logo} width="260px" height="50px" />} */}
          {!displayCompany && <img src="/assets/images/logo-ionix-full.svg" alt="end cover logo"></img>}
          {!displayCompany && <Contact textColor="#FFFFFF" style={{ paddingTop: 20 }} />}
        </div>
        {/* <div id="threats-report-last-page"></div> */}
      </Grid>
    </Grid>
  );
};

export default LastPage;
