import './coloredNumber.scss';

export function ColoredNumber(props: any) {
  const getFormattedNumer = (num: number) => {
    if (num > 0) {
      return `+${num}`;
    }
    return num;
  };

  const getClassName = (num: number) => {
    if (props.formattedValue < 0) {
      return 'neg';
    }
    if (props.formattedValue === 0) {
      return '';
    }
    if (props.formattedValue > 0) {
      return 'pos';
    }
  };

  return (
    <div className={`colored-number-scope ${getClassName(props.formattedValue)}`}>
      {getFormattedNumer(props.formattedValue)}
    </div>
  );
}
