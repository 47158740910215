import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { getRows, setDeletePromptParams } from 'features/GridSlice';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import './APITokensActions.scss';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import { Common } from 'common/services/common.service';
import { PopUp } from 'common/components/popUp/PopUp';
import { AddTokenPopup } from './AddTokenPopup/AddTokenPopup';
import { env } from 'env';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';

export function APITokensActions(props: any) {
  const dispatch = useDispatch<any>();
  const ref = useRef<HTMLDivElement>(null);
  const [actionsMenuOpen, setActionsMenuOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [submitting] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<Date | string | null>(new Date());
  const [expirationDateError, setExpirationDateError] = useState<boolean>(false);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setActionsMenuOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onRemove = () => {
    dispatch(
      setDeletePromptParams({
        category: 'tokens',
        index: null,
        ids: props.ids,
        message: 'Are you sure you would like to remove the following tokens?'
      })
    );
  };

  const onAdd = () => {
    if (!validate()) {
      return;
    }

    setPopupVisible(true);
  };

  const validate = () => {
    let hasError = false;
    if (name.length < 1) {
      setNameError(true);
      hasError = true;
    }
    if (
      !Common.isValidDate(expirationDate) ||
      (Common.isValidDate(expirationDate) && Common.isPastDate(expirationDate))
    ) {
      setExpirationDateError(true);
      hasError = true;
    }

    return !hasError;
  };

  const onChange = (val: string) => {
    setName(val);
    setNameError(false);
  };

  const onClosePopup = (reset: boolean) => {
    if (reset) {
      setName('');
      setExpirationDate(new Date());
    }
    setPopupVisible(false);
    dispatch(getRows());
  };

  return (
    <div className="APITokensActions" ref={ref}>
      <div className="link-to-api">
        <LinkNewTab
          to={`${env.REACT_APP_BASE_URL}swagger`}
          text={
            <div>
              <img src="/assets/images/swagger.svg" alt="Swagger Icon" />
              API Documentation
            </div>
          }
        />
      </div>
      <input
        className={classNames({ error: nameError })}
        type="text"
        value={name}
        onChange={e => onChange(e.target.value)}
        placeholder="Token Name"
        maxLength={32}
      />
      <span>Expiration:</span>
      <div
        className={classNames('date-picker-wrapper', {
          error: expirationDateError
        })}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disablePast={true}
            disableHighlightToday={true}
            value={expirationDate}
            onChange={(newValue: any) => {
              setExpirationDate(newValue);
              setExpirationDateError(false);
            }}
            renderInput={(params: any) => <TextField type="text" {...params} />}
          />
        </LocalizationProvider>
      </div>
      <button className={`btn btn-main ${submitting ? 'submitting' : ''}`} onClick={onAdd}>
        Create Token
      </button>
      <span className={`actions-trigger ${actionsMenuOpen ? 'open' : ''}`}>
        <div className="trigger-wrapper" onClick={() => setActionsMenuOpen(!actionsMenuOpen)}>
          Actions
          <IconArrow />
        </div>
        <ul>
          <li className={!props.ids.length ? 'disabled' : ''} onClick={onRemove}>
            Remove
          </li>
        </ul>
      </span>
      <PopUp visible={popupVisible} setVisible={onClosePopup}>
        <AddTokenPopup params={{ name, expirationDate }} onClose={onClosePopup} />
      </PopUp>
    </div>
  );
}
