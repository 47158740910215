interface IIconAnonUser {
  is_black_mode?: boolean;
}
export function IconAnonUser(props: IIconAnonUser) {
  let fill = props.is_black_mode ? '#E8E8E8' : '#454549';
  let stroke = props.is_black_mode ? '#B7BBC5' : '#6D6D7A';
  return (
    <svg
      style={{ margin: '-8px 3px 0 0' }}
      xmlns="http://www.w3.org/2000/svg"
      width="1.3vw"
      height="1.3vw"
      viewBox="0 0 18 19"
      fill="none"
    >
      <circle cx="9" cy="11" r="6.5" fill={fill} stroke={stroke} strokeLinejoin="round" />
      <g filter="url(#filter0_d_3514_10295)">
        <circle cx="9" cy="9" r="2.5" stroke={stroke} strokeLinejoin="round" shapeRendering="crispEdges" />
      </g>
      <mask id="path-3-inside-1_3514_10295" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.91614 15.8119C4.97765 14.1228 6.85771 13 9 13C11.1423 13 13.0223 14.1227 14.0839 15.8119C12.808 17.1594 11.0022 18 8.99999 18C6.99783 18 5.192 17.1594 3.91614 15.8119Z"
        />
      </mask>
      <path
        d="M3.91614 15.8119L3.06944 15.2798C2.82661 15.6663 2.87621 16.1681 3.18999 16.4995L3.91614 15.8119ZM14.0839 15.8119L14.81 16.4995C15.1238 16.168 15.1734 15.6662 14.9306 15.2798L14.0839 15.8119ZM4.76283 16.344C5.64905 14.9338 7.21586 14 9 14V12C6.49956 12 4.30624 13.3117 3.06944 15.2798L4.76283 16.344ZM9 14C10.7841 14 12.3509 14.9338 13.2372 16.344L14.9306 15.2798C13.6938 13.3117 11.5004 12 9 12V14ZM13.3577 15.1244C12.2628 16.2807 10.7162 17 8.99999 17V19C11.2881 19 13.3532 18.0381 14.81 16.4995L13.3577 15.1244ZM8.99999 17C7.28376 17 5.73715 16.2807 4.64228 15.1244L3.18999 16.4995C4.64684 18.0381 6.7119 19 8.99999 19V17Z"
        fill={stroke}
        mask="url(#path-3-inside-1_3514_10295)"
      />
      <defs>
        <filter
          id="filter0_d_3514_10295"
          x="0.285715"
          y="0.285715"
          width="17.4286"
          height="17.4286"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.85714" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0925174 0 0 0 0 0.113682 0 0 0 0 0.304167 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3514_10295" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3514_10295" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
