import React, { useEffect, memo } from 'react';
import Slider from '@mui/material/Slider';
import classNames from 'classnames';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@mui/material';

import './slider-box.scss';

const useStyles = makeStyles({
  rail: {
    height: 3
  },
  track: {
    height: 3
  },
  thumb: {
    height: 17,
    width: 17
  }
});

interface ISlider {
  id: string;
  min?: number;
  max?: number;
  step?: number;
  isActive?: boolean;
}

const minDistance = 0;

const SliderBox: React.FC<ISlider> = ({ id, isActive = true, min = 0, max = 10, step = 0.1 }) => {
  const {
    notificationData,
    onConfigurationChange
  } = useNotificationConfiguration() as NotificationConfigurationContextType;
  const classes = useStyles();
  const sliderOption =
    !!notificationData && !!notificationData.filters[id] && !!notificationData.filters[id].choices?.length
      ? notificationData.filters[id].choices
      : [min, max];
  const [value, setValue] = React.useState<number[]>(sliderOption);

  useEffect(() => {
    if (!!notificationData && !!notificationData.filters[id] && !!notificationData.filters[id].choices?.length) {
      setValue(notificationData.filters[id].choices);
    } else {
      setValue([min, max]);
    }
  }, [notificationData, min, max, id]);

  const handleOnChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    let updatedValues;
    if (activeThumb === 0) {
      updatedValues = [Math.min(newValue[0], value[1] - minDistance), value[1]];
    } else {
      updatedValues = [value[0], Math.max(newValue[1], value[0] + minDistance)];
    }
    setValue(updatedValues);
  };

  const handleSaveChanges = (event: React.SyntheticEvent | Event, values: number | Array<number>) => {
    onConfigurationChange(id, values);
  };

  return (
    <div className={classNames('dropdown-box-slider', { disabled: !isActive })}>
      <Grid container direction="column">
        <Grid item alignSelf="center" className="collapse-image">
          <img
            src="/assets/images/dropdown-box-slider-legend.svg"
            alt="dropdown-box-slider-legend"
            className="dropdown-box-slider-legend"
          />
        </Grid>
        <Grid item>
          {!!value.length && (
            <Grid container alignItems="center" sx={{ padding: 1 }}>
              <Grid item className="slider-text left" data-testid="dropdownbox-slider-from-label">
                {value[0].toFixed(1)}
              </Grid>

              <Grid item flexGrow={1} sx={{ marginLeft: 0.5, marginRight: 0.5 }}>
                <Slider
                  min={min}
                  max={max}
                  step={step}
                  value={value}
                  onChange={handleOnChange}
                  onChangeCommitted={handleSaveChanges}
                  classes={{
                    rail: classes.rail,
                    track: classes.track,
                    thumbColorPrimary: classes.thumb
                  }}
                  disableSwap
                />
              </Grid>

              <Grid item className="slider-text right" data-testid="dropdownbox-slider-to-label">
                {value[1].toFixed(1)}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(SliderBox);
