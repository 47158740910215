import { AppLoading } from 'common/components/appLoading/appLoading';
import { PopupHeader } from 'common/components/PopupHeader/PopupHeader';
import { RestApi } from 'common/services/rest-api.service';
import { getRows } from 'features/GridSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './AddCustomPortsPopUp.scss';
import { tosterService } from 'common/services/tostar.service';
import { Common } from 'common/services/common.service';
import classNames from 'classnames';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';

const LIMIT = 10000;

export function AddCustomPortsPopUp(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');
  const [inputError, setInputError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const dispatch = useDispatch<any>();

  useEffect(() => {
    document.getElementsByClassName('Settings')[0].classList.add('ontop');
    document.getElementsByClassName('settings-wrapper')[0].classList.add('ontop');
    return () => {
      document.getElementsByClassName('Settings')[0].classList.remove('ontop');
      document.getElementsByClassName('settings-wrapper')[0].classList.remove('ontop');
    };
  }, []);

  useEffect(() => {
    setInputError(false);
    setErrorText('');
  }, [input]);

  const validate = (_input: string[]): boolean => {
    let hasError = false;

    const inputError = _input.filter((row: string) => {
      return !Common.validatePort(parseInt(row));
    });

    if (inputError.length) {
      hasError = true;
      setInputError(true);
      setErrorText(`Invalid Input: ${inputError[0]}`);
    }

    return !hasError;
  };

  const submit = () => {
    const inputAsArray = input.split('\n');

    if (inputAsArray.length > LIMIT) {
      setErrorText(`List exceeded the number of items (max: ${LIMIT.toLocaleString()})`);
    }

    if (!validate(inputAsArray)) {
      return false;
    }

    const data = {
      ids: inputAsArray
    };

    setLoading(true);

    RestApi.setData(Paths[PathNames.customPort], data, 'POST').subscribe(
      (response: any) => {
        setLoading(false);
        props.onClose();
        dispatch(getRows());
        tosterService.onShowToster('Custom Ports Added Successfully', 'success', '');
      },
      error => {
        setLoading(false);
        tosterService.showError(error);
      }
    );
  };

  return (
    <div className="AddCustomPortsPopUp popup-inner-wrapper">
      <PopupHeader text="Add Custom Ports" onClose={props.onClose} />
      <div className="popup-inner">
        {loading && <AppLoading />}
        <div className="message">
          <div>Add here additional custom ports.</div>
          <div>* Each item should appear in a seperate line.</div>
        </div>
        <textarea
          className={classNames({ error: inputError })}
          value={input}
          onChange={e => setInput(e.target.value)}
        ></textarea>
        {!!errorText && <div className="red">{errorText}</div>}
        <div className="buttons-wrapper">
          <button className="btn btn-big btn-secondary" onClick={props.onClose}>
            Cancel
          </button>
          <button className={classNames('btn', 'btn-big', 'btn-main', { disabled: !input })} onClick={submit}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
