import { Subject } from 'rxjs';
import Moment from 'moment';
import { IObject } from 'common/interfaces/interface';
import { env } from 'env';
import store from 'app/store';

const subject: any = new Subject();

export const updateHeader = {
  sendHeaderText: (message: any) => subject.next({ text: message }),
  // clearMessages: () => subject.next(),
  getHeaderText: () => subject.asObservable()
};

export const Common = {
  longDateFormat: '',
  //@param: value = date string
  //@description Creates a full date format
  getDateFullTime: (date: string) => {
    var format = `${Moment.localeData().longDateFormat('L')} ${Moment.localeData().longDateFormat('LT')}`;
    return Moment(date).format(format);
  },
  getShortDate: (date: string) => {
    return Moment(date).format('MM/DD/YYYY');
  },
  getDateString: (date: string) => {
    return Moment(date).format('MMMM DD, YYYY');
  },
  getDateMonthYear: (date: string) => {
    return Moment(date).format('MMM YY');
  },
  getDateYear: (date: string) => {
    return Moment(date).format('YYYY');
  },
  getChartDateString: (date: string) => {
    return Moment(date).format('DD MMM, YY');
  },
  //@param: value = date string
  //@description Creates a date format
  getDateFormat: (value: string) => {
    return value !== '' && value !== null && value !== undefined ? Moment(value).format(Common.longDateFormat) : '';
  },
  getLocaleDateFormat: () => {
    let userLanguage = window.navigator.language;
    let locale = Moment.locale(userLanguage);
    return Common.DateFormatChecker(Moment.localeData(locale).longDateFormat('L'));
  },
  isPastDate: (date: Date | string | null) => {
    return Moment(date).isBefore() && !Moment().isSame(date, 'day');
  },
  //Date format checker. Does it contain regular numbers
  DateFormatChecker: (longDateFormat: string) => {
    let date = Moment(new Date()).format(longDateFormat);
    if (/^(?:\d+[-/.]){2}\d+$/.test(date)) {
      return longDateFormat;
    } else {
      // formatDefault = "DD/MM/YYYY"
      let locale = Moment.locale('en');
      return Moment.localeData(locale).longDateFormat('L');
    }
  },
  //@param: data
  //@description A copy of data without reference
  cloneData(data: any) {
    return JSON.parse(JSON.stringify(data));
  },
  //@param: path
  //@description: Clearing a query from the path. for example ?ordering=...etc.
  //@return Only url data
  clearQueryFromPath(path: string) {
    //Does it includes a v1 string
    let include = path.includes('/v1/');
    try {
      return include ? path.split('v1/')[1].split('?')[0] : path.split('?')[0];
    } catch (error) {
      return path;
    }
  },
  //@param: email
  //@return boolean
  emailValidation(email: any): boolean {
    const regex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  },
  splitV1FromPath(path: string) {
    return path.split('v1/')[1] || path;
  },
  //@param: number
  //@description Takes a number and inserts commas in the relevant places
  //If the number is bigger than 1M, display a shorten version of it. (1M, 1.4M, 2B...)
  numberWithCommas(val: number): string {
    const largeNumbers: Array<{ symbol: string; value: number }> = [
      { symbol: 'M', value: 1e6 }, // 1 million
      { symbol: 'B', value: 1e9 } // 1 billion
      // Add here to support larger numbers
    ];

    // in case the number is bigger than the first value in the largeNumbers array,
    // loop through the largeNumbers array and shorten the number
    if (val >= largeNumbers[0].value) {
      for (let i = largeNumbers.length - 1; i >= 0; i--) {
        if (val >= largeNumbers[i].value) {
          const rounded: string = this.to1DecimalPoint(val / largeNumbers[i].value);
          return `${rounded}${largeNumbers[i].symbol}`;
        }
      }
    }

    // default:
    // in case the number is smaller than the first value in the largeNumbers array
    return val?.toLocaleString();
  },
  to1DecimalPoint(num: number): string {
    // shorten a number into 1 decimal points, and remove .0
    return num.toFixed(1).replace(/[.,]0$/, '');
  },
  percentage(partial: number, total: number) {
    return ((partial * 100) / total).toFixed(2);
  },
  getColorByUrgency(key: string = 'no_risk') {
    let urgency: IObject = {
      no_risk: '#1aa884',
      low: '#00bdd7',
      medium: '#e7b70e',
      high: '#e06915',
      critical: '#f46666'
    };
    return urgency[key];
  },
  getColorByTestResult(key: string = 'A') {
    let testResult: IObject = {
      A: '#1aa884',
      B: '#00bdd7',
      C: '#e7b70e',
      D: '#e06915',
      F: '#f46666'
    };
    return testResult[key.toUpperCase()];
  },
  getTextByUrgency(key: string = 'no_risk') {
    let urgency: IObject = {
      no_risk: 'No Risk',
      low: 'Low',
      medium: 'Medium',
      high: 'High',
      critical: 'Critical'
    };
    return urgency[key];
  },
  isNotEmpty(obj: object) {
    return Object.keys(obj).length !== 0;
  },
  convertToClassName(string: string) {
    return string?.toLowerCase().replace(' ', '-');
  },
  getRandomString(length: number, base: number = 36) {
    return (Math.random() + 1).toString(base).substring(length);
  },
  isValidDate(date: any) {
    const _date = Moment(date);
    // make sure date is valid and year is 4 chars
    return _date.isValid() && _date.year().toString().length === 4;
  },
  shortenText(string: string, length: number, suffix: string): string {
    return string.length <= length ? string : `${string.substring(0, length)}${suffix}`;
  },
  camelCaseToTitle(camelString: string): string {
    return camelString
      .replace(/([A-Z])/g, match => ` ${match}`)
      .replace(/^./, match => match.toUpperCase())
      .trim();
  },
  underscoreToCamelCase(featureFlag: string): string {
    return featureFlag
      .split('_')
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
  },
  underscoreToTitle(string: string, shouldUppercaseShortWord: boolean = true): string {
    // #TODO: change this logic
    let _string = string;

    const switchWords = [
      { source: 'pass', dest: 'password' },
      { source: 'internal_url', dest: 'internal_/_external' },
      { source: 'hash', dest: 'hash (SHA 256)' },
      { source: 'stealer_family', dest: 'stealer_family' },
      { source: 'endpoint_os', dest: 'endpoint_OS' },
      { source: 'endpoint_ip', dest: 'endpoint_IP' },
      { source: 'endpoint_antivirus', dest: 'endpoint_antivirus' },
      { source: 'endpoint_device_name', dest: 'endpoint_device_name' },
      { source: 'endpoint_ad_user', dest: "user's_AD_domain" }
    ];

    switchWords.forEach((word: any) => {
      if (_string.toLowerCase().includes(word.source)) {
        _string = _string.replace(word.source, word.dest);
      }
    });

    _string = _string
      .split('_')
      .map(s => ' ' + s.charAt(0).toUpperCase() + s.substring(1))
      .join('')
      .replace(/^./, '');

    if (shouldUppercaseShortWord && _string.length <= 3) {
      _string = _string.toUpperCase();
    }

    return _string;
  },
  validateDomainName(domain: string): boolean {
    const regex = /^(?:\*\.)?(?:[\w-]+\.)*(?:[\w]+)$/;
    return regex.test(domain);
  },
  validateTldr(domain: string): boolean {
    const regex = /^(?:[\w-]+\.)+(?:[\w-]{2,})+$/;
    return regex.test(domain);
  },
  validateLinkedinAccount(url: string): boolean {
    const _url = !url.startsWith('http') ? `https://${url}` : url;
    try {
      const linkedinUrl = new URL(_url);
      const linkedinPathStructure = '/company/';

      if (
        (linkedinUrl.hostname === 'linkedin.com' || linkedinUrl.hostname === 'www.linkedin.com') &&
        linkedinUrl.pathname.startsWith(linkedinPathStructure) &&
        linkedinUrl.pathname.length > linkedinPathStructure.length
      ) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  },
  validateIp(ip: string): boolean {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)((\/[0-9]|\/[12][0-9]|\/3[0-2])?)$/.test(
      ip
    );
  },
  validateMobileApp(app: string): boolean {
    return /^[A-Za-z0-9\\.\\_\-\\/\\%]+$/g.test(app);
  },
  validatePort(value: number): boolean {
    if (value > 0 && value < 65535) {
      return true;
    }
    return false;
  },
  validateURL(string: string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );
    return res !== null;
  },
  getTableHeaderFontProps(): object {
    // These props are used for the calculations of the DataGridPro cells width
    // #TODO: remove this from here and find one source for these props
    return {
      font: 'Rubik',
      fontSize: '1rem'
    };
  },
  getParamFromUrl(param: string): string {
    const decodedURI = decodeURIComponent(window.location.href);
    const url = new URL(decodedURI);
    const value = url.searchParams.get(param);
    return value || '';
  },
  setGridRowsClientIds(rows: []) {
    return rows.map((row: any) => {
      row._id = Common.getRandomString(7);
      return row;
    });
  },
  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  capitalizeFirstLetterOfEachWord(string: string) {
    return string
      .replace(/-/g, ' ')
      .split(' ')
      .map((word: string) => Common.capitalizeFirstLetter(word))
      .join(' ');
  },
  convertToApiPath(string: string) {
    return string.replace(/-/g, '_').toLowerCase();
  },
  validatePhoneNumber(string: string) {
    const format = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    if (string.match(format)) {
      return true;
    }
    return false;
  },
  removeParamFromURL(param: string): void {
    const url = new URL(window.location.href);
    url.searchParams.delete(param);
    window.history.replaceState(null, '', url);
  },
  getSelectMaxHeight(): string {
    const el = document.getElementsByClassName('tab-filter-wrapper')[0];
    let height = 250;
    if (el) {
      height += el.clientHeight;
    }
    return `calc(100vh - ${height}px)`;
  },
  getFileSize(size: number): string {
    return (size / 1024 / 1024).toFixed(2);
  },
  highlightText(text: string): JSX.Element[] {
    const jsx: JSX.Element[] = [];
    if (!text) {
      return [<span></span>];
    }
    const splittedText = text.split(
      /(@cyberpion-start-highlight@|@ionix-start-highlight@|@IONIX-start-highlight@|@cyberpion-end-highlight@|@ionix-end-highlight@|@IONIX-end-highlight@)/g
    );
    let highlight = false;
    splittedText.forEach((part: string) => {
      if (
        part === '@cyberpion-start-highlight@' ||
        part === '@ionix-start-highlight@' ||
        part === '@IONIX-start-highlight@'
      ) {
        highlight = true;
      } else if (
        part === '@cyberpion-end-highlight@' ||
        part === '@ionix-end-highlight@' ||
        part === '@IONIX-end-highlight@'
      ) {
        highlight = false;
      } else {
        if (highlight) {
          jsx.push(<span className="highlight">{part}</span>);
        } else {
          jsx.push(<span>{part}</span>);
        }
      }
    });
    return jsx;
  },
  isDemoEnv() {
    return env.REACT_APP_ENV === 'demo';
  },
  getEmptyValue() {
    return '%00';
  },
  displayHelp(show: boolean) {
    const body = document.body;

    !!show ? body.classList.remove('popside-active') : body.classList.add('popside-active');
  },

  isObject(check: any) {
    return typeof check === 'object' && check !== null && !Array.isArray(check);
  },

  debounce(fn: Function, delay = 300) {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  },
  copyText(string: string): void {
    if (typeof string === 'string') {
      try {
        window.navigator.clipboard.writeText(string);
      } catch {}
    }
  },
  sortAlphabetically(a: any, b: any, sortBy?: string) {
    if (sortBy) {
      return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase());
    } else {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    }
  },
  shuffle(arr: []) {
    let shuffled = arr
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    return shuffled;
  },
  getUrgencyBySeverity(severity: number) {
    let type: string;
    if (severity >= 9) {
      type = 'critical';
    } else if (severity >= 7) {
      type = 'high';
    } else if (severity >= 4) {
      type = 'medium';
    } else {
      type = 'low';
    }
    return { urgency: severity, type };
  },
  updateLinksToMssp() {
    const user: any = store.getState().login.user;
    const account: string = store.getState().login.account || Common.getParamFromUrl('account');
    if (account && user.is_mssp_user) {
      const linksInPage = document.getElementsByTagName('a');
      for (let i = 0; i < linksInPage.length; i++) {
        const link = linksInPage[i].getAttribute('href');
        if (!link?.includes('account=') && !link?.startsWith('http')) {
          linksInPage[i].setAttribute('href', link + `${link?.includes('?') ? '&' : '?'}account=${account}`);
        }
      }
    }
  },
  AddMsspAccountIfNeeded(link: string) {
    const msspAccount = Common.getParamFromUrl('account');
    if (msspAccount && !link.includes('account=')) {
      return link + `${link.includes('?') ? '&' : '?'}account=${msspAccount}`;
    } else {
      return link;
    }
  },
  addGlobalFilterIfNeeded(link: string) {
    const globalFilter = store.getState().general.globalFilter || Common.getParamFromUrl('group');
    // const globalFilter = Common.getParamFromUrl('group');
    if (globalFilter && !link.includes('group=')) {
      return link + `${link.includes('?') ? '&' : '?'}group=${globalFilter}`;
    } else {
      return link;
    }
  },
  getDecodedViewPath(path: string) {
    return decodeURI(path);
  },
  getSubArraysFromArrayBySize(arr: any, size: number) {
    return arr.reduce((all: any, acc: any, i: number) => {
      if (i % size === 0) all.push(arr.slice(i, i + size));
      return all;
    }, []);
  },
  generateRandomId() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 5) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  // highlightText(text: string) {
  //     // Split text on highlight term, include term itself into parts, ignore case
  //     const parts = text.split(new RegExp(`()`, 'gi'));
  //     return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <b>{part}</b> : part)}</span>;
  // }
};
Common.longDateFormat = Common.getLocaleDateFormat();
