import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export const IconActionItems: React.FC<IIcon> = ({ active }) => {
  let color = active ? '#3455DD' : '#6D6D7A';

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 9.5L7.5 14.5L13.5 6.5L9.5 6.5L9.5 1.5L2.5 9.5L7.5 9.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
