// @ts-nocheck
import React, { useRef } from 'react';
import Grid from '@mui/material/Grid';
import { Text, ASM_RADAR_CATEGORY_MAP } from '@cyberpion/cyberpion-ui';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';
import PageFooter from 'common/components/GenerateThreatsReport/pages/PageFooter';
import PageHeader from 'common/components/GenerateThreatsReport/pages/PageHeader';
import usePageSize from '../hooks/usePageSize';
import { Common } from 'common/services/common.service';

import './Highlights.scss';

const iconLink = (
  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.39111 1.45996H5.87859V2.94746"
      stroke="#5D6576"
      stroke-width="0.632363"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.87578 1.45996L3.64453 3.69121"
      stroke="#5D6576"
      stroke-width="0.632363"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.39648 1.14355H2.0462C1.52233 1.14355 1.09766 1.56823 1.09766 2.0921V5.28987C1.09766 5.81374 1.52234 6.23842 2.0462 6.23842H5.24397C5.76784 6.23842 6.19252 5.81374 6.19252 5.28987V3.93789H5.56016V5.28987C5.56016 5.4645 5.4186 5.60606 5.24397 5.60606H2.0462C1.87158 5.60606 1.73002 5.4645 1.73002 5.28987V2.0921C1.73002 1.91748 1.87158 1.77592 2.0462 1.77592H3.39648V1.14355Z"
      fill="#5D6576"
    />
  </svg>
);

const getBackgroundColorByUrgency = (key: string = 'no_risk') => {
  let urgency: IObject = {
    informational: '#1AA8841A',
    no_risk: '#1AA8841A',
    low: '#55BAD41A',
    medium: '#F1C5441A',
    high: '#FF87171A',
    critical: '#FB58581A'
  };
  return urgency[key];
};

const CATEGORIES = {
  action_items: 'Action Items',
  active_protection: 'Protected Assets',
  discovery: 'Discovery',
  cti: 'Cyber Threat Intelligence',
  network_assessment: 'Assessments'
};

const HighlightsTable = ({ rows }: { rows: any }) => {
  return (
    <table className="HighlightsTable" cellPadding="0" cellSpacing="0">
      <thead>
        <tr>
          <td style={{ paddingLeft: 32 }}>Highlights</td>
          <td>Details</td>
          <td>Type</td>
          <td>Operated By</td>
          <td />
        </tr>
      </thead>
      <tbody>
        {!!rows &&
          rows.map((row: any, index: number) => {
            const Icon = row.icon;
            const linkSrc = Common.AddMsspAccountIfNeeded(row.hyperlink);

            return (
              <tr
                key={index}
                style={{ backgroundColor: getBackgroundColorByUrgency(row.severity_label) } as React.CSSProperties}
              >
                <td style={{ paddingLeft: 32 }}>{row.title}</td>
                <td>{row.count > 1 ? row.sub_title_plural.replace('{counter}', row.count) : row.sub_title_single}</td>
                <td>
                  <Grid container alignItems="center">
                    <Grid item>{Icon}</Grid>
                    <Grid item paddingLeft={0.5}>
                      {CATEGORIES[row.section]}
                    </Grid>
                  </Grid>
                </td>
                <td>{ASM_RADAR_CATEGORY_MAP[row.onion_layer].label}</td>
                <td className="link">
                  <a href={linkSrc} target="_blank" rel="noreferrer">
                    {iconLink}
                  </a>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

const Highlights = () => {
  const { highlightsList } = useThreatsReport() as ThreatsReportContextType;
  const refDetails = useRef(null);
  const { minHeight } = usePageSize(refDetails, 'highlights');

  return (
    <Grid
      ref={refDetails}
      container
      flexDirection="column"
      minHeight={minHeight}
      className="threats-report-highlights"
      paddingTop={3}
    >
      <Grid item flex={0} margin="0 32px 32px 32px">
        <PageHeader />
      </Grid>
      <Grid item marginLeft={4} marginTop={1}>
        <Text textColor="#2E2E2F" textSize={14} upper weight={600}>
          List of highlights
        </Text>
      </Grid>
      <Grid item flex={1}>
        <HighlightsTable rows={highlightsList} />
      </Grid>
      <Grid item>
        <PageFooter id="highlights" />
      </Grid>
    </Grid>
  );
};

export default Highlights;
