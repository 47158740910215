import './image.scss';
interface IImage {
  className?: string;
  icon: string;
  type: ImgType;
  alt: string;
}

type ImgType = 'svg' | 'png';

export function Image(props: IImage) {
  return <img className={props.className} alt={props.alt} src={`/assets/images/${props.icon}.${props.type}`} />;
}
