import { useFlags } from 'launchdarkly-react-client-sdk';
import { OldDashboard } from './OldDashboard';
import { NewDashboard } from './NewDashboard';

export function DashboardModule() {
  const flags = useFlags();

  if (flags.isPortalDashboard2) {
    return <NewDashboard />;
  }

  return <OldDashboard />;
}
