import { IconActiveProtection } from 'common/components/Icons/IconActiveProtection/IconActiveProtection';
import { IconRisk } from 'common/components/Icons/IconRisk/IconRisk';
import './riskRenderer.scss';
import { ToolTip } from 'common/components/toolTip/toolTip';
export function RiskRenderer(props: any) {
  if (!props.value) {
    return null;
  }
  return (
    <div className="risk-render-scope">
      <IconRisk urgency={props.value.type} />
      <div className="risk-text-render">
        {isNaN(props.value.risk_score) ? props.value.urgency : props.value.risk_score}
      </div>
      {props.value.is_active_protection && <IconActiveProtection />}
      <ToolTip theme="dark" place="right" />
    </div>
  );
}
