import { Button } from '@mui/material';
import { RestApi } from 'common/services/rest-api.service';
import { tosterService } from 'common/services/tostar.service';
import * as React from 'react';
import './releaseRequest.scss';
export function ReleaseRequest(props: any) {
  const [message, setMessage] = React.useState('');

  function onCancel() {
    props.setVisible(false);
    props.data.apiGrid.current.setSelectionModel([]);
  }

  function onSave() {
    if (props.data.ids.length === 0) {
      return;
    }

    let url = `remediation/active-protection/release-request/`;
    let data = {
      ids: props.data.ids,
      message: message
    };

    RestApi.setData(url, data, 'POST').subscribe(
      (response: any) => {
        onCancel();
        tosterService.onShowToster('OK', 'success', '');
      },
      error => {
        onCancel();
        tosterService.onShowToster('error', 'danger', '');
      }
    );
  }

  function handleChange(e: string) {
    setMessage(e);
  }

  return props.visible ? (
    <div className="releaseRequest popup-inner-wrapper">
      <div className="releaseRequest-header-scope">
        <div className="releaseRequest-header-text">Release Request</div>
        <div className="releaseRequest-remove" onClick={onCancel}>
          +
        </div>
        <img src="/assets/images/background-popup.svg" alt="background-popup" />
      </div>
      <div className="releaseRequest-popup-body">
        <div className="">
          <div className="releaseRequest-headline">You are asking to release the following assets</div>
          <div className="releaseRequest-hosts">
            {props.data.assets.map((asset: string, index: number) => (
              <React.Fragment key={index}>
                <div className="">{asset}</div>
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="releaseRequest-warning-scope">
          <img src="/assets/images/warning.svg" alt="warning" />
          <div className="releaseRequest-warning-header">Are You Sure You Want To Release These Assets?</div>
          <div className="releaseRequest-warning-sub-header">
            if your organization’s assets are still connected to the released assets,you should take over them quickly -
            before attackers do.
          </div>
        </div>

        <textarea
          className="releaseRequest-textarea"
          placeholder="Type your release reason here"
          value={message}
          onChange={e => handleChange(e.target.value)}
        ></textarea>

        <div className="releaseRequest-event-scope">
          <Button>
            <div className="releaseRequest-buttom releaseRequest-cancel" onClick={onCancel}>
              Cancel
            </div>
          </Button>
          <Button>
            <div className="releaseRequest-buttom releaseRequest-save" onClick={onSave}>
              Save
            </div>
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
