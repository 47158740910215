import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export const IconNotificationsPortalAccess: React.FC<IIcon> = ({ active }) => {
  let color = active ? '#3455DD' : '#6D6D7A';

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.75003C5.79599 9.75003 3.97263 11.3798 3.66932 13.4999C2.04363 12.2181 1 10.2309 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 10.2309 13.9564 12.2181 12.3307 13.4999C12.0274 11.3798 10.204 9.75003 8 9.75003ZM8 8.87503C9.44975 8.87503 10.625 7.69978 10.625 6.25003C10.625 4.80028 9.44975 3.62503 8 3.62503C6.55025 3.62503 5.375 4.80028 5.375 6.25003C5.375 7.69978 6.55025 8.87503 8 8.87503Z"
        fill={color}
      />
    </svg>
  );
};
