import { IItemFilterDate } from 'common/components/grid/components/gridUtilities/interface';
import { Common } from 'common/services/common.service';
// import { Common } from "common/services/common.service";
export const FilterDateManage = {
  //@param: numDays = number
  //@description: Calculating days back
  //@return : String date . for example => 2022-05-10
  calculateDaysBack(numDays: number) {
    let d = new Date();
    let date = d.setDate(d.getDate() - numDays);
    return new Date(date); //FilterDateManage.createfromatDateToDB(new Date(date));
  },
  //@description: Creates a date format for DB
  //@return : String date. for example => 2022-05-10
  createfromatDateToDB(date: Date) {
    if (typeof date === 'string') date = new Date(date);
    let utc = FilterDateManage.createfromatDateUTC(date);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(utc);
    let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(utc);
    return `${date.getFullYear()}-${month}-${day}T00:00:00Z`;
    // return utc.toJSON();
  },
  //@description: Creates a date format by UTC
  createfromatDateUTC(date: Date) {
    let utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    return new Date(utc);
  },
  //@description: returns "Format Date" By Label
  getFormatDateByLabel(option: IItemFilterDate) {
    switch (option.label) {
      case 'Last 24 Hours':
        return FilterDateManage.calculateDaysBack(1).toJSON();
      case 'Last 7 Days':
        return FilterDateManage.createfromatDateToDB(FilterDateManage.calculateDaysBack(7));
      case 'Last 30 Days':
        return FilterDateManage.createfromatDateToDB(FilterDateManage.calculateDaysBack(30));
      case 'From':
        return FilterDateManage.createfromatDateToDB(option.value || new Date());
      case 'Until':
        return FilterDateManage.createfromatDateToDB(option.value || new Date());
      case 'Range':
        let date1 = FilterDateManage.createfromatDateToDB(option.child[0].value || new Date());
        let date2 = FilterDateManage.createfromatDateToDB(option.child[1].value || new Date());
        return `${date1},${date2}`;
    }
  },
  checkIsBetween(date: string) {
    let dates: any = date.split(',');
    if (dates.length > 1) return `${Common.getDateFormat(dates[0] || '')},${Common.getDateFormat(dates[1] || '')}`;
    else return Common.getDateFormat(dates[0] || '');
  }
};
