// @ts-nocheck
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addHeaders } from 'common/services/rest-api.service';
import { env } from 'env';

export const checkSession = createAsyncThunk('login/checkSession', async () => {
  const path = `${env.REACT_APP_BASE_URL}auth/session/details/`;
  const res = await fetch(path, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => data.json());
  return res;
});

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    loading: true,
    user: {},
    isLoggedIn: false,
    account: null
  },
  reducers: {
    setUser(state, action) {
      state.user.first_name = action.payload.first_name;
      state.user.last_name = action.payload.last_name;
    },
    setAccount(state, action) {
      state.account = action.payload;
    }
  },
  extraReducers: {
    [checkSession.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.isLoggedIn = true;
      state.loading = false;
    },
    [checkSession.rejected]: (state, error) => {
      state.loading = false;
    }
  }
});

export const selectCompanyDisplayName = state => state.login.user?.company_display_name;

const { actions, reducer } = loginSlice;
export const { setUser, setAccount } = actions;
export default reducer;
