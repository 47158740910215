import { IconChevron } from 'common/components/Icons/IconChevron/IconChevron';
import React from 'react';
import { Form } from './form/Form';
import { Common } from 'common/services/common.service';
import useShowToggle from 'common/hooks/useShowToggle';
import './summary.scss';

export function Summary(props: any) {
  const { isOpen, toggle } = useShowToggle(true);

  function onToggleTab(value: boolean) {
    toggle();
  }

  function isActive() {
    let calssActive: string = 'filter-box-open';
    let calssUactive: string = 'filter-box-close';
    return isOpen ? calssActive : calssUactive;
  }

  // return Common.isNotEmpty(props.dataTabs) ? (
  return props.dataTabs ? (
    <div className="tab-filter-wrapper">
      <div
        className={`tab-filter-scope ${isActive()} ${Common.convertToClassName(
          props.source
        )} ${Common.convertToClassName(props.tab)}`}
      >
        <div className="test-ChartLine">
          {props.dataTabs.length !== 0 &&
            props.dataTabs.map((item: any, index: number) => (
              <React.Fragment key={index}>
                <Form {...item} index={index} source={props.source} />
              </React.Fragment>
            ))}
        </div>
      </div>
      {!props.hidden_arrow && (
        <div className="rounded-tabs">
          <IconChevron toggle={isOpen} onClick={onToggleTab} />
        </div>
      )}
    </div>
  ) : (
    <div className="tab-filter-scope filter-box-open"></div>
  );
}
