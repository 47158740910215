import { ILogin } from './interface';
export const INIT_DATA_LOGIN: ILogin = {
  email: '',
  jwt: {
    is_active: false,
    two_fa: {
      is_active: false,
      disabled: false,
      disabled_options: [],
      options: [],
      selected: []
    }
  },
  password: {
    disabled: false,
    value: '',
    error: ''
  },
  sso: {
    is_active: false,
    options: ['microsoft-graph']
  },
  valid_code: {
    is_active: false,
    value: '',
    error: ''
  }
};
