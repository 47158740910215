import React, { useState, useEffect } from 'react';
import { Button } from 'common/components/Button/Button';
import { Text } from '@cyberpion/cyberpion-ui';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import './PopSideFotter.scss';

const useStyles = makeStyles({
  checkboxRoot: {
    padding: '0px !important',
    width: 'auto !important',
    '& .MuiSvgIcon-root': {
      width: '18px !important',
      height: '18px !important'
    }
  }
});

interface IPopSideFotter {
  onClose: () => void;
  isLoading: boolean;
  integrationName?: string;
  showDeletePopup?: boolean;
  isValidated?: boolean;
  onSave?: () => void;
  deleteBeforeApplyOnClick?: (ev: any) => void;
  deleteAfterApplyOnClick?: () => void;
  deleteOnCancel?: () => void;
  deleteMsgWithNotificationWarning?: boolean;
  selectedDeleteIndex?: number;
  cancelOnly?: boolean;
  isMainDeleteDisabled?: boolean;
}

const PopSideFotter: React.FC<IPopSideFotter> = ({
  showDeletePopup = false,
  integrationName,
  deleteOnCancel,
  onClose,
  onSave,
  isValidated,
  isLoading,
  deleteAfterApplyOnClick,
  deleteBeforeApplyOnClick,
  selectedDeleteIndex,
  deleteMsgWithNotificationWarning,
  cancelOnly = false,
  isMainDeleteDisabled = false
}) => {
  const [showDelete, setShowDelete] = useState(showDeletePopup);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    setIsDeleteDisabled(true);
    setShowDelete(showDeletePopup);
  }, [showDeletePopup]);

  const deleteAllOnClick = (ev: any) => {
    setIsDeleteDisabled(true);
    setShowDelete(true);
    if (deleteBeforeApplyOnClick) {
      deleteBeforeApplyOnClick(ev);
    }
  };

  const handleDeleteOnCancel = () => {
    setShowDelete(false);
    if (deleteOnCancel) {
      deleteOnCancel();
    }
  };

  return (
    <div className={classNames('ButtonsWrapper', { smallHeight: !showDelete }, { bigHeight: showDelete })}>
      {!showDelete && (
        <>
          {!!deleteBeforeApplyOnClick && (
            <div
              className={classNames('delete-button', { inactive: isMainDeleteDisabled })}
              onClick={(ev: any) => {
                if (isMainDeleteDisabled) return;
                deleteAllOnClick(ev);
              }}
            >
              {/* <img alt="delete integration" src="/assets/images/trash.svg" /> */}
              <Text textSize={12} textColor={isMainDeleteDisabled ? '#8c909a' : '#f46666'} style={{ marginLeft: 3 }}>
                Remove configuration
              </Text>
            </div>
          )}
          <div style={{ flex: 1 }} />
          <Button
            buttonStyle="secondary"
            size="small"
            text="Cancel"
            onClick={onClose}
            type="button"
            disabled={isLoading}
          />
          {!cancelOnly && (
            <Button
              buttonStyle="main"
              size="small"
              text="Save"
              onClick={onSave}
              type="button"
              loading={isLoading}
              disabled={!isValidated}
            />
          )}
        </>
      )}
      {showDelete && (
        <div className="popside-delete" onClick={(ev: any) => ev.stopPropagation()}>
          <Text textSize={16} weight={500} style={{ marginBottom: 16, paddingTop: 16 }}>
            {selectedDeleteIndex === 999 ? 'Delete All Integrations?' : 'Delete Integration?'}
          </Text>
          <div style={{ flexGrow: 1 }}>
            {deleteMsgWithNotificationWarning && (
              <>
                <Text style={{ lineHeight: 1.35 }}>
                  {selectedDeleteIndex === 999
                    ? `WARNING! Removing the configuration will remove ALL ${integrationName} instances associated notifications. This action cannot be undone.`
                    : 'WARNING! Deleting the integration will remove all associated notifications. This action cannot be undone.'}
                </Text>

                <Text style={{ lineHeight: 1.35 }}>
                  {selectedDeleteIndex === 999
                    ? `Are you sure you want to remove ALL ${integrationName} integration instances and all associated notifications?`
                    : 'Are you sure you want to remove the integration and all associated notifications?'}
                </Text>
              </>
            )}
            {!deleteMsgWithNotificationWarning && (
              <Text style={{ lineHeight: 1.35 }}>
                {selectedDeleteIndex === 999
                  ? `Are you sure you want to remove ALL ${integrationName} integration instances?`
                  : 'Are you sure you want to remove the integration?'}
              </Text>
            )}
          </div>

          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 16 }}>
            <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              <Checkbox
                onChange={(ev: any) => setIsDeleteDisabled(!ev.target.checked)}
                disableRipple
                classes={{ root: classes.checkboxRoot }}
                sx={{
                  marginRight: 0.75,
                  '&.Mui-checked': {
                    color: '#f46666'
                  }
                }}
              />
              <Text textSize={13} family="Rubik">
                Confirm delete
              </Text>
            </div>
            <Button buttonStyle="secondary" size="small" text="Cancel" onClick={handleDeleteOnCancel} type="button" />
            <Button
              buttonStyle="alert"
              size="small"
              text="Delete"
              onClick={deleteAfterApplyOnClick}
              type="button"
              disabled={isDeleteDisabled}
              // className="footer-button"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PopSideFotter;
