import './LoginBackgroundAnimation.scss';

export function LoginBackgroundAnimation() {
  return (
    <div className="LoginBackgroundAnimation">
      <div className="left"></div>
      <div className="right"></div>
      <div className="top"></div>
      <div className="bottom"></div>
    </div>
  );
}
