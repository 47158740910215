import React, { forwardRef } from 'react';
import { IconImportance, IconRisk, Indicator, Text } from '@cyberpion/cyberpion-ui';
import { TechnicalDetails } from 'common/components/TechnicalDetails/TechnicalDetails';

const AssetTestDetails = forwardRef(({ data }: { data: any }, refDetails: any) => {
  const { singleAsset, test } = data;

  let ips =
    singleAsset?.ip?.ip_network ||
    singleAsset?.domain?.ips ||
    singleAsset?.subdomain?.ips ||
    singleAsset?.managed_domain?.ips ||
    singleAsset?.cloud_asset?.ips ||
    [];
  if (!!ips && !Array.isArray(ips)) {
    ips = [ips];
  }

  return (
    <div ref={refDetails} className="asset-test-details">
      <div style={{ display: 'flex', marginTop: 24, alignItems: 'center' }}>
        {!!test?.grade && (
          <div className="risk-icon" style={{ marginRight: 4 }}>
            <Indicator letter={test?.grade.toUpperCase()} size="m" />
          </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }} upper textSize={13}>
            {test?.name}
          </Text>
          <Text textColor="#8C909A" textSize={12}>
            {test?.category}
          </Text>
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 24, alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 4, paddingRight: 16 }}>
          <Text weight={600} style={{ marginRight: 4, marginBottom: 4, maxWidth: 370, wordBreak: 'break-all' }}>
            Asset Name
          </Text>
          <Text style={{ maxWidth: 370, wordBreak: 'break-all' }}>{singleAsset?.asset}</Text>
        </div>
        {singleAsset?.risk_score?.type && (
          <div
            style={{
              display: 'flex',
              paddingRight: 16,
              paddingLeft: 16,
              alignItems: 'center',
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <div style={{ paddingRight: 8, paddingLeft: 8 }} className="risk-icon">
              <IconRisk urgency={singleAsset?.risk_score?.type} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text weight={600} style={{ whiteSpace: 'nowrap', marginBottom: 4 }}>
                Asset Risk
              </Text>
              <Text>{singleAsset?.risk_score?.risk_score}</Text>
            </div>
          </div>
        )}
        {singleAsset?.importance && (
          <div
            style={{
              display: 'flex',
              paddingRight: 16,
              paddingLeft: 16,
              alignItems: 'center',
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <div style={{ paddingRight: 8 }}>
              <IconImportance type={singleAsset?.importance?.type} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text weight={600} style={{ whiteSpace: 'nowrap', marginBottom: 4 }}>
                Asset Importance
              </Text>
              <Text>{singleAsset?.importance?.type}</Text>
            </div>
          </div>
        )}
      </div>

      {!!ips && !!ips.length && (
        <div style={{ marginTop: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            {`${singleAsset?.type !== 'ip' ? 'IPs' : 'IP Network'}`}
          </Text>
          <Text textColor="#454549">{ips.join(' / ')}</Text>
        </div>
      )}
      {!!test?.info && (
        <div style={{ marginTop: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            Info
          </Text>
          <Text textColor="#454549">{test.info}</Text>
        </div>
      )}
      {!!test?.description && (
        <div style={{ marginTop: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            Description
          </Text>
          <Text textColor="#454549">{test.description}</Text>
        </div>
      )}

      {!!test?.technical_details && Object.keys(test.technical_details).length && (
        <div style={{ marginTop: 16 }}>
          <Text textSize={12} weight={600} style={{ marginBottom: 8 }}>
            Technical Details
          </Text>
          <div className="technical-details-container">
            <TechnicalDetails
              data={test.technical_details ? test.technical_details : {}}
              isActionItemsPDF={true}
              isAllowEmptyJson={true}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default AssetTestDetails;
