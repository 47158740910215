import { ManageNotData } from 'common/components/manageNotData/ManageNotData';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import { RestApi } from 'common/services/rest-api.service';
import { NotFound } from 'notFound/NotFound';
import { useEffect, useState } from 'react';
import { Card } from './Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { setParentCompany, setActiveSubsidiary, setSubsidiaries } from 'features/SubsidiariesSlice';
import { LoginService } from 'login/services/login.service';
import TopPanel from 'common/components/TopPanel/TopPanel';
import TOP_PANEL_PAGES from 'common/constants/topPanelPages';
import useShowToggle from 'common/hooks/useShowToggle';
import { MenuExportCSV } from 'common/components/MenuExportCSV/MenuExportCSV';

import './OrgScore.scss';

export function OrgScore(props: any) {
  const [summary, setSummary] = useState<[]>([]);
  const [cards, setCards] = useState<{ [key: string]: any }[]>([]);
  const [noPermission, setNoPermission] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoadingSummary, setIsLoadingSummary] = useState<boolean>(true);
  const { activeSubsidiary, parentCompany, allSubsidiaries } = useSelector((state: IReduxState) => state.subsidiaries);
  const { user } = useSelector((state: IReduxState) => state.login);
  const { isOpen: isTopPanelOpen, setToggle: setTopPanelToggle } = useShowToggle(true);
  const dispatch = useDispatch<any>();

  const getPath = (basePath: string) => {
    if (
      !!activeSubsidiary &&
      ((Object.keys(activeSubsidiary).length && user.company_display_name !== activeSubsidiary.name) ||
        (LoginService.isRestrictedMember(user) && Object.keys(activeSubsidiary).length))
    ) {
      return basePath + `?subsidiary=${activeSubsidiary.name}`;
    }
    return basePath;
  };

  useEffect(() => {
    return () => {
      dispatch(setActiveSubsidiary({}));
      dispatch(setSubsidiaries({}));
    };
  }, [dispatch]);

  const fetchSubsidiaries = () => {
    setLoading(true);
    RestApi.getData(getPath(Paths[PathNames.assesmentsOrgScoreSubsidiaries]), true).subscribe(
      (response: any) => {
        dispatch(
          setSubsidiaries({
            count: response.count,
            results: response.results
          })
        );

        if (!!response.count) {
          if (LoginService.isRestrictedMember(user)) {
            dispatch(setActiveSubsidiary(response.results[0]));
          }
        }
        if (LoginService.isRestrictedMember(user) && response.count === 0) {
          setLoading(false);
          setIsLoadingSummary(false);
        }
      },
      err => {}
    );
  };

  useEffect(() => {
    if (!!Object.keys(user).length) {
      fetchSubsidiaries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (LoginService.isMemberOrAbove(user)) {
      getSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, activeSubsidiary]);

  useEffect(() => {
    getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubsidiary]);

  useEffect(() => {
    if (!!allSubsidiaries?.count && !!Object.keys(activeSubsidiary).length) {
      getSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubsidiary, allSubsidiaries]);

  useEffect(() => {
    if (LoginService.isMemberOrAbove(user) || (!!activeSubsidiary && !!Object.keys(activeSubsidiary).length)) {
      fetchOrgScores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, activeSubsidiary]);

  const getSummary = () => {
    setIsLoadingSummary(true);
    RestApi.getData(getPath(Paths[PathNames.assesmentsOrgScoreSummary]), true).subscribe(
      (response: any) => {
        if (!Object.keys(parentCompany).length) {
          dispatch(
            setParentCompany({
              name: response.name,
              label: response.overall_rank,
              score: response.overall_score
            })
          );
        }
        setSummary(response);
        setIsLoadingSummary(false);
      },
      err => {
        if (err.status === 403) {
          setNoPermission(true);
        }
      }
    );
  };

  const fetchOrgScores = () => {
    setLoading(true);
    setCards([]);
    RestApi.getData(getPath(Paths[PathNames.assesmentsOrgScoreCards]), true).subscribe((response: any) => {
      setCards(response.results);
      setTimeout(() => setLoading(false), 1000);
    });
  };

  const shouldShowEmptyState = (): boolean => {
    if (loading || cards.filter((card: any) => card[0].sections[0].value !== null).length) {
      return false;
    }
    return true;
  };

  if (noPermission) {
    return <NotFound type="403" />;
  }

  return (
    <div className="OrgScore">
      <TopPanel
        page={TOP_PANEL_PAGES.ASSESSMENTS}
        data={summary}
        isLoading={isLoadingSummary}
        isOpen={isTopPanelOpen}
        setToggle={setTopPanelToggle}
      />
      {loading && (
        <div className="loading-wrapper">
          <AppLoading />
        </div>
      )}
      {shouldShowEmptyState() ? (
        <ManageNotData type="org_score" />
      ) : (
        <>
          {!!cards.length && (
            <div className="org-score-wrapper">
              <div style={{ flexGrow: 1 }} />
              <MenuExportCSV isDropdown={false} />
            </div>
          )}
          <div className="scrollbar-common">
            <ul className="cards">
              {cards.map((c, index) => {
                return <Card key={index} card={c} />;
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
