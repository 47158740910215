import { Link } from 'react-router-dom';
import { IconRadioButton } from '../../Icons/IconRadioButton/IconRadioButton';
import './List.scss';

interface IGroupFilterListProps {
  list: string[];
  filteredList: string[];
  selected: string;
  onSelect: (item: string) => void;
  onSelectType: (type: string) => void;
  title: string;
  link?: string;
}

export const List = (props: IGroupFilterListProps) => {
  const handleOnSelect = (item: string) => () => {
    props.onSelect(item);
    props.onSelectType(props.title);
  };

  return (
    <li className="GroupsList">
      <div>
        <div>{props.list.length ? props.title : `No ${props.title}`}</div>
        {props.link && <Link to={props.link}>Manage</Link>}
      </div>
      <div className="scrollbar-common">
        <ul>
          {props.filteredList.map((item: string) => {
            return (
              <li key={item} onClick={handleOnSelect(item)}>
                <IconRadioButton radioSize="little" toggle={props.selected === item} />
                <span>{item}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </li>
  );
};
