import { Common } from 'common/services/common.service';
import { IconArrowTrend } from '../Icons/IconArrowTrend/IconArrowTrend';
import './score.scss';
import classNames from 'classnames';

export function Score(props: any) {
  let color = !!props.label ? Common.getColorByTestResult(props.label.charAt(0)) : '#555659';

  return (
    <>
      {
        <div className={classNames('score-render', { small: props.size === 'small' })}>
          <div
            className="score-background"
            style={{
              background: color
            }}
          ></div>
          <div
            className="score-border"
            style={{
              border: `solid 1px ${color}`,
              boxShadow: `0 0 .3rem ${color}`
            }}
          ></div>
          <div className="score-text">{props.label}</div>
          {props.is_upward_trajectory && (
            <IconArrowTrend
              className="score-iconArrowTrend"
              active={props.is_upward_trajectory_positive}
              toggleRotate={props.is_upward_trajectory}
            />
          )}
          {props.value !== null && <div className="score-value">{props.value}</div>}
        </div>
      }
    </>
  );
}
