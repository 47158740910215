import React from 'react';
import { IIcon } from 'common/interfaces/interface';
export class IconSettings extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#5D6576';
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" fill="none">
        <path
          d="M17.1875 13.6979V6.30226C17.1875 6.19158 17.1581 6.08288 17.1023 5.98728C17.0466 5.89169 16.9664 5.81262 16.87 5.75816L10.3075 2.04892C10.2137 1.99589 10.1078 1.96802 10 1.96802C9.89223 1.96802 9.78629 1.99589 9.69246 2.04892L3.12996 5.75816C3.03361 5.81262 2.95345 5.89169 2.89767 5.98728C2.84189 6.08288 2.8125 6.19158 2.8125 6.30226V13.6979C2.8125 13.8086 2.84189 13.9173 2.89767 14.0129C2.95345 14.1085 3.03361 14.1876 3.12996 14.242L9.69246 17.9513C9.78629 18.0043 9.89223 18.0322 10 18.0322C10.1078 18.0322 10.2137 18.0043 10.3075 17.9513L16.87 14.242C16.9664 14.1876 17.0466 14.1085 17.1023 14.0129C17.1581 13.9173 17.1875 13.8086 17.1875 13.6979V13.6979Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 12.8125C11.5533 12.8125 12.8125 11.5533 12.8125 10C12.8125 8.4467 11.5533 7.1875 10 7.1875C8.4467 7.1875 7.1875 8.4467 7.1875 10C7.1875 11.5533 8.4467 12.8125 10 12.8125Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
