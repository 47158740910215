// @ts-nocheck
import classNames from 'classnames';
import './CveList.scss';
import { ToolTip } from 'common/components/toolTip/toolTip';
import data from './cwe.json';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';

export function CveList(props: any) {
  const getNistToolTipHtml = (item: any) => {
    return `<div class="Nist-Tooltip">
      <div>CVSS v${item.cvss_version || ''} Severity and Metrics:</div>
      <div>Base Score: <span>${item.base_severity || ''}</span></div>
      <div>Vector: <span>${item.cvss_vector || ''}</span></div>
      <div>Impact Score: <span>${item.impact_score || ''}</span></div>
      <div>Exploitability Score:  <span>${item.exploitability_score || ''}</span></div>
      <div class='divider'></div>
      <div>Attack Vecor (AV):  <span>${item.attack_vector || ''}</span></div>
      <div>Attack Complexity (AC):  <span>${item.attack_complexity || ''}</span></div>
      <div>Privileges Required (PR):  <span>${item.privileges_required || ''}</span></div>
      <div>User Interaction (UI):  <span>${item.user_interaction ? 'Required' : 'NONE'}</span></div>
      <div>Scope (S):  <span>${item.scope}</span></div>
      <div>Confidentiality (C):  <span>${item.confidentiality_impact || ''}</span></div>
      <div>Integrity (I):  <span>${item.integrity_impact || ''}</span></div>
      <div>Availability (A):  <span>${item.availability_impact || ''}</span></div>
    </div>`;
  };

  const getCWEToolTipHtml = (item: any, id: number) => {
    return `<div class="CWE-Tooltip">
      <div>CWE-${id}</div>
      <div><a target='_blank' rel='nofollower' href="${item.link}">${item.name}</a></div>
      <div>${
        item.description && item.description.length > 300 ? item.description.slice(0, 300) + '...' : item.description
      }</div>
    </div>`;
  };

  const prepareHTML = () => {
    let html: JSX.Element[] = [];
    props.data.forEach((item: any, index: number) => {
      if (Object.keys(item).length) {
        html.push(
          <li className="item" key={index}>
            <div className="headline">
              <LinkNewTab to={`https://nvd.nist.gov/vuln/detail/${item.cve}`} text={item.cve} />
              <span>
                CVSS: {item.cvss}
                {!!item.epss && !!item.epss_percentile
                  ? `, (EPSS Score: ${parseFloat(item.epss * 100).toFixed(2)}%,
                Percentile: ${parseFloat(item.epss_percentile * 100).toFixed(2)}%)`
                  : ''}
              </span>
              {item.is_cisa && (
                <LinkNewTab
                  to={'https://www.cisa.gov/known-exploited-vulnerabilities-catalog'}
                  text={'CISA exploited'}
                  className="cisa"
                />
              )}
            </div>
            {!!item.cvss_vector && (
              <div className="sub-headline" data-tip={getNistToolTipHtml(item)} data-for={'CVETT'}>
                NIST CVSS Vector: {item.cvss_vector}
              </div>
            )}
            {!!item.cwe?.length && (
              <ul className="sub-headline">
                {item.cwe.map((_cwe: string | number, index: number) => {
                  return (
                    <li data-tip={data[_cwe] ? getCWEToolTipHtml(data[_cwe], _cwe) : ''} data-for={'CVETT'}>
                      CWE-{_cwe}
                      {index === item.cwe.length - 1 ? '' : ','}
                    </li>
                  );
                })}
              </ul>
            )}
            <div className="description">{item.description}</div>
          </li>
        );
      }
    });
    return html;
  };

  return (
    <div>
      <ul className={classNames('CveList', { visible: props.visible })}>{prepareHTML()}</ul>
      <ToolTip html={true} theme="light" className="stay" place="left" delayHide={300} id="CVETT" effect="solid" />
    </div>
  );
}
