import './connections.scss';

export function Connections(props: any) {
  return (
    <div className="connections">
      <div className="connections-header">CONNECTIONS</div>
      <div className="connections-content">
        <div>
          {props.data.connected_assets.label}
          <span>{props.data.connected_assets.value}</span>
        </div>
        <div>
          {props.data.connection_types.label}
          <span>{props.data.connection_types.value}</span>
        </div>
      </div>
    </div>
  );
}
