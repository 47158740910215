import React, { useEffect, memo } from 'react';
import Drawer from '@mui/material/Drawer';
import AddNotificationTypePanel from 'pages/Settings/Notifications/SidePanel/AddNotificationTypePanel/AddNotificationTypePanel';
import ViewNotificationPanel from 'pages/Settings/Notifications/SidePanel/ViewNotificationPanel/ViewNotificationPanel';
import { useNotifications, NotificationsContextType } from 'pages/Settings/Notifications/NotificationsContext';
import NotificationConfigurations from 'pages/Settings/Notifications/SidePanel/NotificationConfigurations/NotificationConfigurations';
import { NotificationConfigurationProvider } from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import classNames from 'classnames';
import { Common } from '../../../../common/services/common.service';

import './side-panel.scss';

const SidePanelContentByMode = () => {
  const { actionMode } = useNotifications() as NotificationsContextType;

  const mapContentByType = {
    add: <AddNotificationTypePanel />,
    view: <ViewNotificationPanel />,
    edit: <NotificationConfigurations />,
    new: <NotificationConfigurations />
  };
  return !!actionMode ? mapContentByType[actionMode as keyof typeof mapContentByType] : null;
};

interface ISidePanel {
  onPanelClose: () => void;
  isOpen: boolean;
}

const drawerWidth = 500;

const SidePanel: React.FC<ISidePanel> = ({ onPanelClose }) => {
  const { actionMode, isOpen } = useNotifications() as NotificationsContextType;

  useEffect(() => {
    Common.displayHelp(false);

    return () => {
      Common.displayHelp(true);
    };
  }, []);

  return (
    <div className={classNames('SidePanel-wrapper', { open: isOpen })}>
      <Drawer
        // key={new Date().getTime()}
        variant="persistent"
        className="notifications-drawer"
        anchor="right"
        open={isOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth
          }
        }}
      >
        {actionMode !== 'view' && (
          <img
            src="/assets/images/x.svg"
            alt="Close"
            className="close-trigger"
            onClick={onPanelClose}
            data-testid="notification-side-panel-x"
          />
        )}
        {isOpen && (
          <NotificationConfigurationProvider>
            <SidePanelContentByMode />
          </NotificationConfigurationProvider>
        )}
      </Drawer>
    </div>
  );
};

export default memo(SidePanel);
