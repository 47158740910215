import React from 'react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useNotifications, NotificationsContextType } from 'pages/Settings/Notifications/NotificationsContext';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { makeStyles } from '@material-ui/core/styles';
import { REQUIRED_FILTERS } from 'pages/Settings/Notifications/SidePanel/utils';
import { Text } from '@cyberpion/cyberpion-ui';

const useStyles = makeStyles({
  textRoot: {
    width: '100%'
  }
});
interface INumbersListFilter {
  id: string;
}

const NumbersListFilter: React.FC<INumbersListFilter> = ({ id }) => {
  const { notificationType } = useNotifications() as NotificationsContextType;
  const {
    notificationData,
    onConfigurationChange
  } = useNotificationConfiguration() as NotificationConfigurationContextType;
  const classes = useStyles();

  const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
  const data = requiredFields.includes(id) ? notificationData : notificationData.filters;
  const areFilters = !!data && !!data[id];

  const handleTextOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (data[id].type === 'array_number' && value !== '' && !value.match(/^[,0-9]+$/)) {
      return;
    }
    if (value.startsWith(',') || value.endsWith(',,')) {
      return;
    }

    onConfigurationChange(id, event.target.value);
  };

  return areFilters ? (
    <Grid container direction="column" className="visible">
      <Grid item>
        <div style={{ backgroundColor: '#F6F8FF' }}>
          <Text style={{ paddingTop: 10, paddingLeft: 16, paddingBottom: 10 }} textColor="#5D6576">
            Numbers are separated by commas
          </Text>
        </div>
      </Grid>
      <Grid item sx={{ padding: '10px 14px', width: '100%' }}>
        <TextField
          classes={{ root: classes.textRoot }}
          value={!!data[id].choices ? data[id].choices : ''}
          placeholder="Please enter number"
          onChange={handleTextOnChange}
          inputProps={{ 'data-testid': `dropdownbox-NumbersListFilter-input-${id}` }}
        />
      </Grid>
    </Grid>
  ) : null;
};

export default NumbersListFilter;
