import { useState, useEffect } from 'react';
import { Text } from '@cyberpion/cyberpion-ui';
import { useSelector } from 'react-redux';
import { Search } from 'common/components/Search/Search';
import './FqdnsInfoPanel.scss';

const ExcludedNetworksPanel = () => {
  const { infoPanelData } = useSelector((state: any) => state.infoPanel);
  const [searchedData, setSearchedData] = useState<any>([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    setSearchedData(infoPanelData);
    setTitle(`${infoPanelData.length} Excluded Sub-CIDR${infoPanelData.length > 1 ? 's' : ''}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (pattern: string) => {
    if (pattern === '') {
      setSearchedData(infoPanelData);
    } else {
      const filteredData = infoPanelData.filter((item: any) => item.includes(pattern));
      setSearchedData(filteredData);
    }
  };

  return (
    <div className="fqdns-info-panel">
      <>
        <div className="header">
          <Text textSize={13} weight={600}>
            {title}
          </Text>
        </div>
        <div className="info-panel-actions">
          <Search onChange={onSearch} />
        </div>
        <div className="scrollbar-common">
          <div className="info-panel-wrapper">
            {!!searchedData &&
              searchedData.map((item: any) => {
                return (
                  <div className="info-panel-fqdns-item">
                    <Text textSize={13}>{item}</Text>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    </div>
  );
};

export default ExcludedNetworksPanel;
