import { IconAkamai } from 'common/components/Icons/IconAkamai/IconAkamai';
import { IconDomain } from 'common/components/Icons/IconAkamai/IconDomain/IconDomain';
import { IconAWS } from 'common/components/Icons/IconAWS/IconAWS';
import { IconAzure } from 'common/components/Icons/IconAzure/IconAzure';
import { IconCloudFlare } from 'common/components/Icons/IconCloudFlare/IconCloudFlare';
import { IconEdgio } from 'common/components/Icons/IconEdgio/IconEdgio';
import { IconGCP } from 'common/components/Icons/IconGCP/IconGCP';
import { IconHeroku } from 'common/components/Icons/IconHeroku/IconHeroku';
import { IconInCapsula } from 'common/components/Icons/IconInCapsula/IconInCapsula';
import { IconIP } from 'common/components/Icons/IconIP/IconIP';
import { IconNetlify } from 'common/components/Icons/IconNetlify/IconNetlify';
import { IconOther } from 'common/components/Icons/IconOther/IconOther';
import { IconOVH } from 'common/components/Icons/IconOVH/IconOVH';
import { IconSubDomain } from 'common/components/Icons/IconSubDomain/IconSubDomain';
import { IconVerizon } from 'common/components/Icons/IconVerizon/IconVerizon';

export const ICONS: any = {
  azure: IconAzure,
  aws: IconAWS,
  gcp: IconGCP,
  akamai: IconAkamai,
  verizon: IconVerizon,
  heroku: IconHeroku,
  netlify: IconNetlify,
  ovh: IconOVH,
  edgio: IconEdgio,
  incapsula: IconInCapsula,
  cloudflare: IconCloudFlare,
  default: IconIP,
  ip: IconIP,
  domain: IconDomain,
  'sub-domain': IconSubDomain,
  other: IconOther
};
