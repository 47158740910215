import React from 'react';
import { IIcon } from 'common/interfaces/interface';
import { IconsCommon } from '../iconsCommon';
import '../icons.scss';
export class IconArrowTrend extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    let color = this.props.active ? '#24D9AD' : '#F46666';

    return (
      <svg
        className={`${IconsCommon.viewRotateClass(this.props.toggleRotate)} ${this.props.className || ''}`}
        xmlns="http://www.w3.org/2000/svg"
        width="8px"
        height="12px"
        viewBox="0 0 8 11"
        fill="none"
      >
        <path
          d="M0.693714 -6.38736e-07L7.30629 -6.06463e-08C7.64757 -3.08108e-08 7.88855 0.334349 7.78063 0.658113L4.47434 10.577C4.32239 11.0328 3.67761 11.0328 3.52566 10.577L0.219372 0.658113C0.11145 0.334348 0.352435 -6.68571e-07 0.693714 -6.38736e-07Z"
          fill={this.props.color || color}
        />
      </svg>
    );
  }
}
