import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Text } from '@cyberpion/cyberpion-ui';
import {
  useNotifications,
  NotificationsContextType,
  NOTIFICATION_OPTIONS
} from 'pages/Settings/Notifications/NotificationsContext';
import ViewNotificationContent from 'pages/Settings/Notifications/SidePanel/ViewNotificationPanel/ViewNotificationContent/ViewNotificationContent';
import { Button } from 'common/components/Button/Button';
import { IconPen } from 'common/components/Icons/IconPen/IconPen';

import './notification-view-panel.scss';

const PanelHeader = () => {
  const [isActive, setIsActive] = useState<boolean | null>(null);
  const { selectedNotification, setActionMode, activatedIds } = useNotifications() as NotificationsContextType;

  const handleOnEditClick = () => {
    // setNotificationType(selectedNotification?.type);
    setActionMode('edit');
  };

  useEffect(() => {
    if (!!selectedNotification) {
      const isNotificationActive = selectedNotification?.status.status === 0;
      const isIntegrationActive =
        !!selectedNotification.configuration_status && selectedNotification.configuration_status === 'active';

      setIsActive(
        !!selectedNotification.configuration_status
          ? isNotificationActive && isIntegrationActive && !!activatedIds.includes(selectedNotification.id)
          : isNotificationActive && !!activatedIds.includes(selectedNotification.id)
      );
    }
  }, [selectedNotification, activatedIds]);

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container sx={{ paddingBottom: 1.5 }} alignItems="center">
          <Grid item sx={{ height: 16 }}>
            {NOTIFICATION_OPTIONS[selectedNotification?.notification_type as keyof typeof NOTIFICATION_OPTIONS].icon}
          </Grid>
          <Grid item sx={{ paddingLeft: 1, ...(isActive ? { flexGrow: 1 } : {}) }}>
            <Text textSize={12} weight={400}>
              {NOTIFICATION_OPTIONS[selectedNotification?.notification_type as keyof typeof NOTIFICATION_OPTIONS].title}
            </Text>
          </Grid>
          {!isActive && (
            <Grid item sx={{ marginLeft: 1, flexGrow: 1, height: 16 }}>
              <img src="/assets/images/disabled_notification.svg" alt="view-inactive" />
            </Grid>
          )}
          <Grid item>
            <Grid
              container
              className="edit-mode"
              onClick={handleOnEditClick}
              alignItems="center"
              data-testid="notification-side-panel-edit-button"
            >
              <Grid item sx={{ paddingRight: 0.5 }}>
                <IconPen />
              </Grid>
              <Grid item>Edit</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Text textSize={16} weight={500} style={{ overflowWrap: 'break-word', width: 450 }}>
            {selectedNotification?.name}
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PanelFooter = () => {
  const { setIsOpen } = useNotifications() as NotificationsContextType;

  const handleOnCloseClick = () => {
    setIsOpen(false);
  };

  return (
    <Grid
      container
      justifyContent="end"
      sx={{
        position: 'sticky',
        bottom: 0
      }}
    >
      <Grid item>
        <Button
          buttonStyle="secondary"
          type="button"
          size="small"
          onClick={handleOnCloseClick}
          text="Close"
          dataTestId="notification-side-panel-close-button"
        ></Button>
      </Grid>
      <Grid item></Grid>
    </Grid>
  );
};

const ViewNotificationPanel = () => {
  const { selectedNotification } = useNotifications() as NotificationsContextType;
  // const contentByNotificationType = {
  //   action_items: <ViewNotificationContent />
  // };

  return !!selectedNotification && !!selectedNotification.notification_type ? (
    <Grid container direction="column" className="notification-view-panel" flexWrap="nowrap">
      <Grid item flexGrow={0} sx={{ paddingLeft: 3, paddingRight: 2.25 }}>
        <PanelHeader />
      </Grid>

      <div className="scrollbar-common">
        <Grid item flexGrow={1} className="view-panel-content">
          <div style={{ height: '100%' }}>
            {/* {contentByNotificationType[selectedNotification?.type as keyof typeof contentByNotificationType]} */}
            <ViewNotificationContent />
          </div>
        </Grid>
      </div>

      <Grid item flexShrink={0} sx={{ paddingLeft: 3, paddingRight: 2.25, marginTop: 2.5 }}>
        <PanelFooter />
      </Grid>
    </Grid>
  ) : null;
};
export default ViewNotificationPanel;
