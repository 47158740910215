import { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './multiLineChart.scss';
import { Common } from 'common/services/common.service';
import { Legend } from './legend/Legend';

export function MultiLineChart(props: any) {
  const isInitialMount = useRef(true);
  const svgRef = useRef(null);
  let data = props.data;
  useEffect(() => {
    //Tooltip
    let tooltip: any = d3.select(`.ttG-d3-scope`);
    function initData() {
      let listDate: any = [];
      let listValue: any = [];
      data.forEach((item: any) => {
        item.values.forEach((value: any, index: number) => {
          value.isEnd = 2 >= index;
          listDate.push(splitTime(value.date));
          listValue.push(value.value);
        });
      });
      return { listDate, listValue };
    }

    function splitTime(time: string) {
      return time.split('T')[0];
    }

    function createGraph() {
      // set the dimensions and margins of the graph
      const margin = { top: 10, right: 30, bottom: 30, left: 60 },
        width = 1150,
        height = 300;

      let parseTime = d3.timeParse('%m/%d/%Y');

      // append the svg object to the body of the page
      const svg = d3
        .select(svgRef.current)
        .attr('color', '#8C909A')
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      let obj = initData();
      // Add X axis --> it is a date format
      const x: any = d3
        .scaleTime()
        .domain(d3.extent(obj.listDate, (date: string) => parseTime(date)) as [Date, Date])
        .range([0, width]);
      svg
        .append('g')
        .attr('class', 'multiLineChart-x')
        .attr('transform', `translate(0, ${height})`)
        .call(
          d3
            .axisBottom(x)
            .ticks(6)
            .tickSizeOuter(0)
            .tickFormat((date: any) => {
              return d3.timeFormat('%B')(date);
            })
        );

      // Add Y axis
      const y: any = d3
        .scaleLinear()
        .domain(d3.extent(obj.listValue, (value: any) => +value) as [number, number])
        .range([height, obj.listValue.every((val: number) => val === 0) ? height : 0]);
      svg
        .append('g')
        .attr('class', 'multiLineChart-y')
        .call(d3.axisLeft(y).tickSizeOuter(0));

      // Draw the line
      svg
        .selectAll('.line')
        .data(data)
        .join('path')
        .attr('fill', 'none')
        .attr('class', (d: any) => `path-${d.key}`)
        .attr('stroke', (d: any) => Common.getColorByUrgency(d.key))
        .attr('stroke-width', 1.5)
        .style('opacity', (d: any) => (d.is_toggled ? 1 : 0))
        .attr('d', (d: any) => {
          return d3
            .line()
            .curve(d3.curveCatmullRom.alpha(2))
            .x((d: any) => x(parseTime(splitTime(d.date))))
            .y((d: any) => y(+d.value))(d.values);
        });

      let circles = svg
        .selectAll('.circle-x')
        .data(data)
        .enter()
        .append('g');
      let circle = circles
        .selectAll('.circle-scope')
        .data((d: any) => d.values)
        .enter()
        .append('circle')
        .attr('fill', '#ffffff')
        .attr('stroke-width', '3')
        .attr('opacity', function() {
          let that: any = this;
          return that.parentNode.__data__.is_toggled ? 1 : 0;
        })
        .attr('class', function() {
          let that: any = this;
          return `circle-${that.parentNode.__data__.key}`;
        })
        .attr('stroke', function() {
          let that: any = this;
          return Common.getColorByUrgency(that.parentNode.__data__.key);
        })
        .attr('cx', (d: any) => x(parseTime(splitTime(d.date))))
        .attr('cy', (d: any) => y(d.value))
        .attr('r', function() {
          let that: any = this;
          return that.parentNode.__data__.is_toggled ? 4 : 0;
        });
      toggleOpenTooltip(circle);
    }

    function hiddenTooltip() {
      tooltip
        .interrupt() // this stops any ongoing transition
        .transition()
        .style('opacity', 0)
        .style('display', 'none');
    }

    function toggleOpenTooltip(element: any) {
      // #TODO: move this function to a place where we can use it across the app
      element
        .on('mouseover', (e: any, d: any) => {
          tooltip
            .transition()
            .duration(200)
            .style('opacity', 1)
            .style('display', 'block');
          setTimeout(() => {
            let left = d.isEnd ? e.pageX - tooltip.node().offsetWidth : e.pageX + 10;
            let top = d.isEnd ? e.pageY : e.pageY;
            tooltip
              .html(onTooltipTemplate(d))
              .style('left', left + 'px')
              .style('top', top + 'px');
          });
        })
        // wheel event is for hiding the tooltip when user start scrolling
        .on('wheel', hiddenTooltip)
        .on('mouseout', hiddenTooltip);
    }
    if (isInitialMount.current) {
      isInitialMount.current = false;
      createGraph();
    }
  }, [data]);

  function onTooltipTemplate(event: any) {
    return `
    <div class="ttG-d3">
      <div class="ttG-header-scope">
        <div class="">
          <div class="ttG-d3-header">${event.header}</div>
          <div class="ttG-d3-date">${Common.getDateFormat(event.date)}</div>
        </div>
        <div class="ttG-d3-value">${event.value}</div>
      </div>

      ${
        event.added || event.removed
          ? `
          <ul class="last-scan-scope">
             ${
               event.added
                 ? `<li>
                     <span class="last-scan-remove ">${event.added}</span>
                     <span>Action Items Opened </span>
                    </li>`
                 : ''
             }
              ${
                event.removed
                  ? `<li>
                      <span class="last-scan-add">${event.removed}</span>
                      <span>Action Items Closed </span>
                    </li>`
                  : ''
              }
          </ul>
        `
          : ''
      }

     ${event.isEnd ? '' : `<div class="ttG-d3-pointer-left"></div>`}
    </div>
    `;
  }
  return (
    <>
      <svg ref={svgRef} width="100%" height="100%" viewBox={`0 0 1250 330`} preserveAspectRatio="none" />
      <Legend data={props.data} />
    </>
  );
}
