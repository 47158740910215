import React from 'react';
export class IconAddTag extends React.Component {
  render() {
    return (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.625 3H8.07282C8.26483 3 8.44618 3.08826 8.56466 3.23936L10.4269 5.61436C10.6044 5.8408 10.6044 6.1592 10.4269 6.38564L9.94516 7H11.2159C11.6754 6.41142 11.6747 5.58518 11.2138 4.99732L9.3516 2.62232C9.04356 2.22947 8.57204 2 8.07282 2H1.625C0.727537 2 0 2.72754 0 3.625V8.375C0 9.27246 0.727536 10 1.625 10H7V9H1.625C1.27982 9 1 8.72018 1 8.375V3.625C1 3.27982 1.27982 3 1.625 3Z"
          fill="#5D6576"
        />
        <path d="M8 9.5H11" stroke="#5D6576" strokeLinecap="round" />
        <path d="M9.5 11L9.5 8" stroke="#5D6576" strokeLinecap="round" />
        <circle cx="8" cy="6" r="1" stroke="#5D6576" />
      </svg>
    );
  }
}
