import { Checkbox } from 'common/components/Icons/IconCheckbox/IconCheckbox';
import { IReduxState } from 'app/store';
import { RestApi } from 'common/services/rest-api.service';
import { removeServicesString, toggleService } from 'features/GroupsSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ServicesTab.scss';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';

interface IService {
  value: number;
  label: string;
}

export function ServicesTab(props: any) {
  const dispatch = useDispatch();
  const [servicesList, setServicesList] = useState<IService[]>([]);
  const { services } = useSelector((state: IReduxState) => state.groups);

  useEffect(() => {
    RestApi.getData(Paths[PathNames.services]).subscribe((response: any) => {
      setServicesList(response);
      if (services && typeof services[0] === 'string') {
        response.forEach((s: IService) => {
          if (services.includes(s.label)) {
            dispatch(toggleService(s.value));
          }
        });
        dispatch(removeServicesString());
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelect = (val: number) => {
    dispatch(toggleService(val));
  };

  return (
    <div className="ServicesTab">
      <ul>
        {servicesList.map(service => {
          return (
            <li key={service.label} onClick={() => onSelect(service.value)}>
              <Checkbox active={services.includes(service.value)} />
              {service.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
