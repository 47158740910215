import { ActionItemsIcon } from './components/actionItemsIcon/ActionItemsIcon';
import { Technologies } from './components/technologies/Technologies';
import { DefaultIcon } from './components/defaultIcon/DefaultIcon';
import { NoIssuesIcon } from './components/NoIssuesIcon/NoIssuesIcon';
import classNames from 'classnames';

import './manageNotData.scss';
export function ManageNotData(props: any) {
  function switchComponents() {
    switch (props.type) {
      case 'action_items':
        return <ActionItemsIcon />;
      case 'technologies':
        return <Technologies />;
      case 'org_score':
        return <NoIssuesIcon subType={props.subType} />;
      case 'single_asset':
        return <NoIssuesIcon subType={props.subType} text="Asset Removed From Inventory" />;
      default:
        return <DefaultIcon />;
    }
  }

  return (
    <div
      className={classNames(
        { 'manageNotData-scope': props.type !== 'org_score' },
        { 'manageNotData-org-score': props.type === 'org_score' },
        { 'manageNotData-single-asset': props.type === 'single_asset' }
      )}
    >
      <div className="manageNotData-icon">{switchComponents()}</div>
      <div className="manageNotData-text"></div>
    </div>
  );
}
