import { IconArrowTrend } from 'common/components/Icons/IconArrowTrend/IconArrowTrend';
import { IconCircledArrowRight } from 'common/components/Icons/IconCircledArrowRight/IconCircledArrowRight';
import { Common } from 'common/services/common.service';
import './number.scss';

export function Number(props: any) {
  return (
    <div className="formGraph-header-scope">
      <div className="formGraph-label-scope">
        <div className="formGraph-header">{props.label}</div>
        {props.is_redirect && <IconCircledArrowRight className="formGraph-icon" />}
      </div>
      <div className="formGraph-number">
        {props.value && Common.numberWithCommas(props.value)}
        {props.label_number && <span className="formGraph-label-number">{props.label_number}</span>}
        {props.is_upward_trajectory !== null && (
          <IconArrowTrend active={props.is_upward_trajectory_positive} toggleRotate={props.is_upward_trajectory} />
        )}
      </div>
    </div>
  );
}
