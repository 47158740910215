import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export const IconNotificationsAssetManagement: React.FC<IIcon> = ({ active }) => {
  let color = active ? '#3455DD' : '#6D6D7A';

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5698 6.30326V8.43659M13.7032 8.43659H11.5698M11.5698 8.43659L15.8365 4.16992"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.43018 13.6967L8.43018 11.5634M6.29684 11.5634L8.43018 11.5634M8.43018 11.5634L4.16351 15.8301"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.30326 8.42969L8.43659 8.42969M8.43659 6.29635L8.43659 8.42969M8.43659 8.42969L4.16992 4.16302"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6967 11.5703L11.5634 11.5703M11.5634 13.7036L11.5634 11.5703M11.5634 11.5703L15.8301 15.837"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.8667 4.13333L10 2L12.1334 4.13333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.8667 7.86621L18 9.99954L15.8667 12.1329"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1333 15.8667L9.99997 18L7.86663 15.8667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.13333 12.1338L2 10.0005L4.13333 7.86712"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
