import React from 'react';
import Grid from '@mui/material/Grid';
import { Text } from '@cyberpion/cyberpion-ui';
import NotificationConfigurationsContent from 'pages/Settings/Notifications/SidePanel/NotificationConfigurations/NotificationConfigurationsContent/NotificationConfigurationsContent';
import {
  useNotifications,
  NotificationsContextType,
  NOTIFICATION_OPTIONS
} from 'pages/Settings/Notifications/NotificationsContext';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { Button } from 'common/components/Button/Button';
import { AppLoading } from 'common/components/appLoading/appLoading';

import './notification-configurations.scss';

const PanelHeader = () => {
  const { notificationType } = useNotifications() as NotificationsContextType;

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container>
          <Grid item>{NOTIFICATION_OPTIONS[notificationType as keyof typeof NOTIFICATION_OPTIONS].icon}</Grid>
          <Grid item sx={{ paddingLeft: 1, paddingBottom: 1.5, flexGrow: 1 }}>
            <Text textSize={13} weight={400}>
              {NOTIFICATION_OPTIONS[notificationType as keyof typeof NOTIFICATION_OPTIONS].title}
            </Text>
          </Grid>
        </Grid>
        <Grid item sx={{ paddingBottom: 2.5 }}>
          <Text textSize={18} weight={500}>
            {NOTIFICATION_OPTIONS[notificationType as keyof typeof NOTIFICATION_OPTIONS].newTitle}
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PanelFooter = () => {
  const { setIsOpen } = useNotifications() as NotificationsContextType;
  const {
    setClickedSubmit,
    onSaveNotification
  } = useNotificationConfiguration() as NotificationConfigurationContextType;

  const handleOnCloseClick = () => {
    setIsOpen(false);
    setClickedSubmit(false);
  };

  const handleOnCreateClick = () => {
    onSaveNotification();
  };

  return (
    <Grid
      container
      justifyContent="end"
      sx={{
        position: 'sticky',
        bottom: 0
      }}
    >
      <Grid item sx={{ marginRight: 1 }}>
        <Button
          buttonStyle="secondary"
          type="button"
          size="small"
          onClick={handleOnCloseClick}
          text="Cancel"
          dataTestId="notification-side-panel-cancel-button"
        ></Button>
      </Grid>
      <Grid item>
        <Button
          buttonStyle="main"
          type="button"
          size="small"
          onClick={handleOnCreateClick}
          text="Save"
          dataTestId="notification-side-panel-save-button"
        ></Button>
      </Grid>
    </Grid>
  );
};

const NotificationConfigurations = () => {
  const { notificationType } = useNotifications() as NotificationsContextType;
  const { notificationData, isLoading } = useNotificationConfiguration() as NotificationConfigurationContextType;

  return isLoading ? (
    <AppLoading size="big" />
  ) : (
    <Grid container direction="column" className="notification-add-edit-panel" flexWrap="nowrap">
      <Grid item flexGrow={0}>
        <PanelHeader />
      </Grid>
      {!!notificationData && !!notificationType && (
        <div className="scrollbar-common">
          <Grid item flexGrow={1} className="add-panel-content">
            <div style={{ height: '100%', overflowX: 'hidden' }}>
              <NotificationConfigurationsContent />
            </div>
          </Grid>
        </div>
      )}
      <Grid item flexShrink={0} sx={{ marginTop: 2.5 }}>
        <PanelFooter />
      </Grid>
    </Grid>
  );
};

export default NotificationConfigurations;
