import { AppLoading } from 'common/components/appLoading/appLoading';
import { useEffect, useRef, useState } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import './ScanInfo.scss';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';

interface IScanInfo {
  plan: number;
  last_scan_date: string | null;
}

export function ScanInfo() {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IScanInfo>({
    plan: 0,
    last_scan_date: null
  });

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const toggle = () => {
    if (!open) {
      setLoading(true);
      RestApi.getData(Paths[PathNames.scanInfo]).subscribe(
        (response: any) => {
          setData(response);
          setLoading(false);
        },
        error => {}
      );
    }
    setOpen(!open);
  };

  return (
    <div className="ScanInfo" ref={ref}>
      <div>Account data</div>
      <img alt="scan-info" src="/assets/images/scan-info.svg" onClick={toggle} />
      <div className={`scan-info-content ${open ? 'open' : ''}`}>
        {!loading ? (
          <div>
            <ul>
              <li>
                Plan: <span>{data?.plan || '-'}</span>
              </li>
              <li>
                Last Scan Date: <span>{data?.last_scan_date || '-'}</span>
              </li>
            </ul>
            <div className="notice">&bull; Some critical information may be updated regardless of scan cycle</div>
          </div>
        ) : (
          <AppLoading />
        )}
      </div>
    </div>
  );
}
