import './NoFilterOptions.scss';

export function NoFilterOptions(props: any) {
  return (
    <div className="NoFilterOptions">
      <img alt="None" src="/assets/images/cancel.svg" />
      <span>No Filter Options</span>
    </div>
  );
}
