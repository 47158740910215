import './EditPlan.scss';
import { useState } from 'react';
import { DropDown } from '@cyberpion/cyberpion-ui';
import { IReduxState } from 'app/store';
import { useSelector } from 'react-redux';

export function EditPlan(props: any) {
  const { companyConfig, accountInfo } = useSelector((state: IReduxState) => state.mssp);
  const [value, setValue] = useState<string>(accountInfo.plan || '');

  return (
    <div className="EditPlan">
      <h5>Plan</h5>
      <DropDown
        options={
          companyConfig.allowed_plans?.map((ap: string) => {
            return { label: ap.charAt(0).toUpperCase() + ap.substring(1), value: ap };
          }) || []
        }
        onChange={(newVal: string) => {
          setValue(newVal);
          props.onChange({
            plan: newVal
          });
        }}
        value={value}
        error={false}
      />
      {/* <ul>
        {plans.map((plan: PLANS) => {
          return (
            <li
              onClick={() => setSelected(plan)}
              className={classNames(plan.toLowerCase(), { selected: selected === plan })}
            >
              <img
                src={`/assets/images/plans/${
                  ['prospect', 'research'].includes(plan.toLowerCase()) ? 'ionix' : plan.toLowerCase()
                }.svg`}
                alt={`Plan - ${plan}`}
              />
              <p>{plan}</p>
            </li>
          );
        })}
      </ul> */}
    </div>
  );
}
