import { Links } from 'common/components/links/Links';
// import { PathNames } from 'common/constants/pathNames';
// import { Paths } from 'common/constants/paths';
import { updateHeader } from 'common/services/common.service';
// import { RestApi } from 'common/services/rest-api.service';
// import { PathNames } from 'common/constants/pathNames';
// import { Paths } from 'common/constants/paths';
// import { RestApi } from 'common/services/rest-api.service';
import { useEffect, useState } from 'react';
import { AssesmentsRouting } from './AssesmentsRouting';
import { OrgScore } from './OrgScore/OrgScore';
import { TABSET } from './config';
import { RestApi } from 'common/services/rest-api.service';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { IResponse } from 'common/interfaces/interface';
import { SubTabsLinks } from 'common/components/SubTabsLinks/SubTabsLinks';
import { useSelector } from 'react-redux';

const PAGE_NAME = 'Assesments';

export function Assesments(props: any) {
  const [tab, setTab] = useState<string>(props.match.params.tab || 'internalAssets');
  const [subTab, setSubTab] = useState<string | undefined>(props.match.params.subtab || undefined);
  const [metaData, setMetaData] = useState<{}>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { globalFilter } = useSelector((state: any) => state.general);

  useEffect(() => {
    getMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    const newTab = props.match.params.tab;
    setTab(newTab);
    const _subTab = props.match.params.subtab;
    setSubTab(_subTab);
  }, [props.match.params.tab, props.match.params.subtab]);

  useEffect(() => {
    updateHeader.sendHeaderText('Assessments');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMetaDataUrl = () => {
    return {
      metaData: `${Paths[PathNames.assesmentsMetaData]}${globalFilter ? `?group=${globalFilter}` : ''}`
    };
  };

  const getMetaData = () => {
    RestApi.getDataPage(getMetaDataUrl()).subscribe((response: IResponse) => {
      setMetaData(response.metaData);
      setLoading(false);
    });
  };

  return (
    <div className={`sub-layout-pages main-content-area ${tab}`}>
      <div>
        <Links page="Assesments" selectTab={tab} tabs={TABSET} metaData={metaData} hideCounts={true} />
        {subTab && !loading && <SubTabsLinks tabs={TABSET[tab].subTabs} activeSubTab={subTab} />}
      </div>
      {tab === 'orgScore' ? <OrgScore /> : <AssesmentsRouting page={PAGE_NAME} selectTab={tab} subTab={subTab} />}
    </div>
  );
}
