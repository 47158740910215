import { IField, ITimeOption, TimeIntervalOptions } from '../../config';

export interface ISentinel {
  action_items_is_active: boolean;
  action_items_push_interval: number;
  is_active: boolean;
  workspace_id: string;
  primary_key: string;
  id?: number | string;
}

export const FIELDS: IField[] = [
  {
    label: 'Workspace ID',
    field: 'workspace_id'
  },
  {
    label: 'Primary Key',
    field: 'primary_key'
  }
];

export interface IDropDown {
  label: string;
  options: ITimeOption[];
  valueField: string;
  activationField: string;
}

export const DROP_DOWNS: IDropDown[] = [
  {
    label: 'Action Item',
    options: TimeIntervalOptions,
    activationField: 'action_items_is_active',
    valueField: 'action_items_push_interval'
  }
];

export const initData = () => {
  return {
    action_items_is_active: true,
    action_items_push_interval: 86400,
    is_active: false,
    workspace_id: '',
    primary_key: ''
  };
};
