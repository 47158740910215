import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';

export const SECURE_INPUT_MASK = '********************************';

export interface IIntegration {
  type: string;
  is_active: boolean;
}
export const IntegrationsList = [
  {
    type: 'sentinel',
    category: 'SIEM',
    category_label: 'SIEM',
    text: 'Send data directly to Azure Sentinel',
    label: 'Microsoft Azure Sentinel',
    guideUrl: 'https://support.ionix.io/hc/en-us/articles/25282117024411-Microsoft-Sentinel-Integration-Guide',
    show_action: true,
    component: 'Sentinel',
    apiPath: Paths[PathNames.integrationSentinel],
    showToggle: true
  },
  {
    type: 'splunk',
    category: 'SIEM',
    category_label: 'SIEM',
    text: 'Send data directly to Splunk',
    label: 'Splunk',
    guideUrl:
      'https://support.ionix.io/hc/en-us/articles/14180409909403-Streamlining-IONIX-Incident-Management-with-Integration-with-SPLUNK-',
    show_action: true,
    component: 'Splunk',
    apiPath: Paths[PathNames.integrationSplunk],
    showToggle: true
  },
  {
    type: 'jira',
    category: 'Ticketing System',
    category_label: 'Ticketing System',
    text: 'Automatically open Action Items in Jira',
    label: 'Jira',
    guideUrl:
      'https://support.ionix.io/hc/en-us/articles/14180214174491-Streamlining-IONIX-Incident-Management-with-JIRA-Integration',
    show_action: true,
    component: 'Jira',
    apiPath: Paths[PathNames.integrationJira],
    showToggle: false
  },
  {
    component: 'ServiceNow',
    category_label: 'Ticketing System',
    apiPath: Paths[PathNames.integrationServiceNow],
    text: 'Automatically open Action Items in ServiceNow',
    type: 'servicenow',
    category: 'Ticketing System',
    label: 'ServiceNow',
    guideUrl: 'https://support.ionix.io/hc/en-us/articles/21199503746331',
    show_action: true,
    showToggle: true
  }
];

export interface IField {
  label: string;
  field: string;
  isSecure?: boolean; // the field is sending to the server but not fetched from the server
  info?: string;
  isPassword?: boolean;
  errorText?: string;
}

export interface IDropDown {
  label: string;
  options: ITimeOption[];
  valueField: string;
  activationField: string;
}

export interface ITimeOption {
  label: string;
  value: number;
}

export const TimeIntervalOptions: ITimeOption[] = [
  { label: 'Inactive', value: 0 },
  { label: 'Hourly', value: 7200 },
  { label: 'Daily', value: 86400 },
  { label: 'Weekly', value: 604800 },
  { label: 'Bi-weekly', value: 1209600 }
];
