import { IconsCommon } from 'common/components/Icons/iconsCommon';
import './collapse.scss';
interface ICollapse {
  toggle: boolean;
  onClick: (value: boolean) => void;
}
export function Collapse(props: ICollapse) {
  function onToggleArrow() {
    props.onClick(!props.toggle);
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={onToggleArrow}
      width="8"
      height="30"
      viewBox="0 0 8 30"
      fill="none"
    >
      <path
        d="M0 30L0 0C0 1.25903 0.592778 2.44459 1.6 3.20001L6.4 6.8C7.40722 7.55542 8 8.74097 8 10L8 20C8 21.259 7.40722 22.4446 6.4 23.2L1.6 26.8C0.592776 27.5554 0 28.741 0 30Z"
        fill="#2C2C30"
      />
      <g className={`iCollapse_arrows iCollapse_${IconsCommon.viewRotateClass(props.toggle)}`}>
        <path
          opacity="0.34"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 25.667C10.5 25.4648 10.3782 25.2825 10.1913 25.2051C10.0045 25.1277 9.78945 25.1705 9.64645 25.3135L8.31311 26.6468C8.11785 26.842 8.11785 27.1586 8.31311 27.3539L9.64645 28.6872C9.78945 28.8302 10.0045 28.873 10.1913 28.7956C10.3782 28.7182 10.5 28.5359 10.5 28.3337L10.5 25.667Z"
          fill="#D1D2D3"
        />
        <path
          d="M8.33325 24L5.83325 26.9003L8.33325 30"
          stroke="#B7BBC5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
