import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { toggleBulkVisibily } from 'features/GroupsSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import './BulkUpload.scss';

export function BulkUpload(props: any) {
  const dispatch = useDispatch<any>();
  const [errors, setErrors] = useState<number>(0);
  const [input, setInput] = useState<string>('');
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const [numOfRows, setNumOfRows] = useState<number>(0);

  useEffect(() => {
    setDone(false);
  }, [collapsed]);

  const onChange = (val: string) => {
    setInput(val);
    setErrors(0);
  };

  useEffect(() => {
    dispatch(toggleBulkVisibily(collapsed));
  }, [collapsed, dispatch]);

  const validate = (_input: string[]) => {
    let hasError = 0;
    _input.forEach((row: string) => {
      if (!props.validator(row.trim())) {
        hasError += 1;
      }
    });
    if (hasError) {
      setErrors(hasError);
      return false;
    }

    setNumOfRows(_input.length);

    return true;
  };

  const onAdd = () => {
    const inputAsArray = input.split('\n');

    if (!validate(inputAsArray)) {
      return;
    }

    inputAsArray.forEach((item: string) => {
      dispatch(props.reduxAction({ value: item.trim() }));
    });

    setDone(true);
    dispatch(toggleBulkVisibily(false));
    setInput('');
  };

  const onCancel = () => {
    setErrors(0);
    setInput('');
    setCollapsed(false);
  };

  return (
    <div className="BulkUpload">
      <header onClick={() => setCollapsed(!collapsed)}>
        <span>Bulk Upload</span>
        <span className={collapsed ? 'collapsed' : ''}>
          <IconArrow active={true} toggleRotate={false} />
        </span>
      </header>
      {done ? <div className="done">{`${numOfRows} item${numOfRows > 1 ? 's' : ''}`} Successfully Added!</div> : ''}
      <div className={`hiddenArea ${collapsed && !done ? 'visible' : ''}`}>
        <div>
          <textarea
            className={classNames({ error: errors })}
            value={input}
            onChange={e => onChange(e.target.value)}
            placeholder={props.placeholder}
          />
          {errors ? <div className="errorMessage">Invalid item{errors > 1 ? 's' : ''} in list</div> : ''}
          <div className="message">* Each item should appear in a separate line</div>
        </div>
        <div className="buttons-wrapper">
          <button className="btn btn-big btn-secondary" onClick={onCancel}>
            Cancel
          </button>
          <button className={`btn btn-big btn-main`} onClick={onAdd}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
