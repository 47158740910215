import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export const IconNotificationsThreadCenter: React.FC<IIcon> = ({ active }) => {
  let color = active ? '#3455DD' : '#6D6D7A';

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.23398 12.763L7.55976 2.87221C7.74855 2.52161 8.25145 2.52161 8.44023 2.87221L13.766 12.7629C13.9454 13.0961 13.7041 13.5 13.3258 13.5L2.67422 13.5C2.29588 13.5 2.05461 13.0961 2.23398 12.763Z"
        stroke={color}
      />
      <path d="M8 6.5V9.5" stroke={color} />
      <path d="M8 10.5V11.5" stroke={color} />
    </svg>
  );
};
