import React from 'react';
import { IIcon } from 'common/interfaces/interface';
export class IconTabManagedDomains extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#5D6576';
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 21 20" fill="none">
        <path
          d="M10.5 2.5C6.35786 2.5 3 5.85786 3 10C3 14.1421 6.35786 17.5 10.5 17.5C14.6421 17.5 18 14.1421 18 10C18 7.77875 17.0344 5.78303 15.5 4.40973"
          stroke={color}
          strokeLinecap="round"
        />
        <path
          d="M13 10C13 12.1651 12.6701 14.1029 12.1532 15.4813C11.894 16.1723 11.5975 16.6969 11.2936 17.0396C10.9892 17.383 10.7199 17.5 10.5 17.5C10.2801 17.5 10.0108 17.383 9.70639 17.0396C9.4025 16.6969 9.10599 16.1723 8.84684 15.4813C8.32994 14.1029 8 12.1651 8 10C8 7.83494 8.32994 5.89713 8.84684 4.51871C9.10599 3.82765 9.4025 3.30314 9.70639 2.96038C10.0108 2.61704 10.2801 2.5 10.5 2.5C10.7199 2.5 10.9892 2.61704 11.2936 2.96038C11.5975 3.30314 11.894 3.82765 12.1532 4.51871C12.6701 5.89713 13 7.83494 13 10Z"
          stroke={color}
        />
        <path
          d="M10.718 7.50968C12.8748 7.69838 14.7765 8.19595 16.1046 8.83103C16.7704 9.14942 17.2671 9.49051 17.5821 9.82312C17.8976 10.1563 17.9907 10.4348 17.9715 10.6538C17.9524 10.8729 17.8123 11.131 17.4437 11.4043C17.0758 11.6772 16.5274 11.9268 15.8164 12.1248C14.3982 12.5196 12.439 12.6793 10.2822 12.4907C8.12536 12.302 6.22368 11.8044 4.89555 11.1693C4.22971 10.8509 3.73304 10.5098 3.41807 10.1772C3.10257 9.84404 3.00944 9.56553 3.02861 9.3465C3.04777 9.12746 3.18784 8.86936 3.55641 8.59604C3.92435 8.32318 4.4727 8.0735 5.18372 7.87557C6.60194 7.48077 8.56114 7.32098 10.718 7.50968Z"
          stroke="#5D6576"
        />
        <circle cx="14" cy="3.5" r="0.5" fill="#454549" />
      </svg>
    );
  }
}
