import { IReduxState } from 'app/store';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { LocalGrid } from 'common/components/grid/LocalGrid';
import { IColumnPicker } from 'common/interfaces/interface';
import { RestApi } from 'common/services/rest-api.service';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WhoisActions } from './WhoisActions/WhoisActions';
import './WhoisTab.scss';

type WhoisType = {
  [key: string]: string;
};

export function WhoisTab(props: any) {
  const [columnPicker, setColumnPicker] = useState<IColumnPicker[]>([]);
  const [identifier, setIdenifier] = useState<string>('');
  const { whois, search } = useSelector((state: IReduxState) => state.groups);

  useEffect(() => {
    RestApi.getData('settings/groups/whois/columns/').subscribe((response: any) => {
      setIdenifier(response.identifier_field);
      setColumnPicker(response.data);
    });
  }, []);

  const filteredRows = whois.filter((item: WhoisType) => item.whois.includes(search));
  return (
    <div className="WhoisTab">
      {columnPicker.length ? (
        <LocalGrid
          columns={ColumnsManage.createColumns(identifier, columnPicker || [], { page: 'settings', tab: 'whois' })}
          rows={filteredRows}
          addedActions={(props: any) => <WhoisActions {...props} />}
          identifier={identifier}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
}
