import { IconRisk, Indicator } from '@cyberpion/cyberpion-ui';
import './ViewMSSP.scss';
import { MSSPAccountInfoBoxes } from '../../EditAccount/MSSPAccountInfoBoxes/MSSPAccountInfoBoxes';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { AppLoading } from 'common/components/appLoading/appLoading';

export function ViewMSSP(props: any) {
  const { accountInfo, loading } = useSelector((state: IReduxState) => state.mssp);

  return (
    <div className="ViewMSSP">
      {loading && <AppLoading />}
      <ul className="chips-list">
        <li>Plan: {accountInfo.plan || '-'}</li>
        <li>Scan frequency: {accountInfo.scan_frequency || '-'}</li>
        <li>Last scanned: {accountInfo.last_scan_date || '-'}</li>
      </ul>
      <ul className="info-list">
        {accountInfo.risk_score && (
          <li>
            <h5>Risk score</h5>
            <div>
              <Indicator
                type={'score'}
                color={'#000000'}
                letter={accountInfo.risk_score?.grade?.toUpperCase()}
                size="xs"
              />
              <span>{accountInfo.risk_score?.score}</span>
            </div>
          </li>
        )}
        <li>
          <h5>FQDNs</h5>
          <div>
            <span>{accountInfo.fqdns}</span>
          </div>
        </li>
        <li>
          <h5>Critical Action Items</h5>
          <div>
            <IconRisk urgency="critical" />
            <span>{accountInfo.critical_action_items}</span>
          </div>
        </li>
      </ul>
      <MSSPAccountInfoBoxes />
    </div>
  );
}
