import './EditMSSP.scss';
import { EditPlan } from '../EditPlan/EditPlan';
import { EditScanFrequency } from '../EditScanFrequency/EditScanFrequency';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { AppLoading } from 'common/components/appLoading/appLoading';

export function EditMSSP(props: any) {
  const { loading } = useSelector((state: IReduxState) => state.mssp);

  return (
    <div className="EditMSSP">
      {loading && (
        <div className="loading-wrapper">
          <AppLoading />
        </div>
      )}
      <EditPlan onChange={props.onChange} />
      <EditScanFrequency onChange={props.onChange} />
      {/* <MainInputs data={data} onChange={setData} /> */}
      {/* <AdditionalInputs data={data} onChange={setData} /> */}
    </div>
  );
}
