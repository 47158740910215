import { IReduxState } from 'app/store';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { LocalGrid } from 'common/components/grid/LocalGrid';
import { IColumnPicker } from 'common/interfaces/interface';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import { addDomain } from 'features/GroupsSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BulkUpload } from '../BulkUpload/BulkUpload';
import { DomainsActions } from './DomainsActions/DomainsActions';
import './DomainsTab.scss';

type domainType = {
  [key: string]: string;
};
export function DomainsTab(props: any) {
  const [columnPicker, setColumnPicker] = useState<IColumnPicker[]>([]);
  const [identifier, setIdenifier] = useState<string>('');
  const { domains, subsidiaryMode, search } = useSelector((state: IReduxState) => state.groups);

  useEffect(() => {
    RestApi.getData('settings/groups/domains/columns/').subscribe((response: any) => {
      setIdenifier(response.identifier_field);
      setColumnPicker(response.data);
    });
  }, []);

  const bulkValidator = (value: string) => {
    if (value.includes('*')) {
      if (value.includes('..') || value.includes('**')) {
        if ((value.match(/--/g) || []).length > (value.match(/xn--/g) || []).length) {
          return;
        }
      } else if (value.startsWith('.')) {
        return;
      } else if (value.startsWith('-') || value.endsWith('-')) {
        return;
      } else {
        const regex = /^[a-z0-9.*-]{3,}$/;
        if (!regex.test(value)) {
          return;
        }
      }
    } else if (!Common.validateTldr(value)) {
      return;
    }
    return true;
  };

  const filteredRows = domains.filter((item: domainType) => item.value.includes(search));
  return (
    <div className={`DomainsTab ${subsidiaryMode ? 'subsidiary-mode' : ''}`}>
      <BulkUpload validator={bulkValidator} reduxAction={addDomain} placeholder="Type your domains here" />
      {columnPicker.length && identifier ? (
        <LocalGrid
          columns={ColumnsManage.createColumns(identifier, columnPicker || [], { page: 'settings', tab: 'domains' })}
          rows={filteredRows}
          addedActions={(props: any) => <DomainsActions {...props} />}
          identifier={identifier}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
}
