import { IconRisk } from 'common/components/Icons/IconRisk/IconRisk';
import './gradeRenderer.scss';
import { Common } from 'common/services/common.service';

export function GradeRenderer(props: any) {
  if (!props.value) {
    return null;
  }

  if (props.value.risk_grade === null || props.value.risk_grade === undefined) {
    return null;
  }

  const grade = props.value.type.toUpperCase();
  return (
    <div className="grade-render-scope">
      <IconRisk color={Common.getColorByTestResult(grade)} />
      <div className="grade-text-render">{grade}</div>
    </div>
  );
}
