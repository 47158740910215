// import { Link } from 'react-router-dom';
import './SubHeader.scss';
import { useEffect, useState } from 'react';
// import { AdditionalSeedAssets } from './AdditionalSeedAssets/AdditionalSeedAssets';
// import { CIDRWrapper } from './CIDRWrapper/CIDRWrapper';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';

export function SubHeader(props: any) {
  const [showAdditionalDomains /*, setShowAdditionalDomains*/] = useState<boolean>(false);
  const [showCidr /*, setShowCidr*/] = useState<boolean>(false);
  const { user } = useSelector((state: IReduxState) => state.login);

  useEffect(() => {
    props.onPopupOpen(showAdditionalDomains || showCidr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAdditionalDomains, showCidr]);

  return (
    <div className="SubHeader">
      <div>
        <h3>{user.company_display_name}</h3>
        <p>{user.company_main_domain}</p>
      </div>
      {/* <div> */}
      {/* <div className="addional-seed-assets-wrapper">
          <AdditionalSeedAssets visible={showAdditionalDomains} onClose={() => setShowAdditionalDomains(false)} />
          <Link
            to="#"
            onClick={() => {
              setShowAdditionalDomains(!showAdditionalDomains);
            }}
          >
            8 Additional Seed Assets
          </Link>
        </div>
        <div className="cidr-wrapper">
          <CIDRWrapper visible={showCidr} onClose={() => setShowCidr(false)} />
          <Link
            to="#"
            onClick={() => {
              setShowCidr(true);
            }}
          >
            14 CIDRs
          </Link>
        </div> */}
      {/* </div> */}
    </div>
  );
}
