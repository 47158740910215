import './PopSideHeader.scss';

export function PopSideHeader({ name }: { name: string }) {
  return (
    <div className="PopSideHeader">
      <h6>
        <img src="/assets/images/plug.svg" alt="Icon" />
        Integrations & Adapters
      </h6>
      <div>
        <h5 style={{ fontSize: 18 }}>{name}</h5>
      </div>
    </div>
  );
}
