import React from 'react';
import Grid from '@mui/material/Grid';
import { Text } from '@cyberpion/cyberpion-ui';
import Chip from '@mui/material/Chip';

const ChipsBox = ({ title, chips }: { title: string; chips: string[] }) => {
  return (
    <div style={{ paddingLeft: 24 }}>
      <Grid item sx={{ width: '100%', paddingTop: 1.25 }}>
        <Text textSize={12} textColor="#8C909A">
          {`${title}:`}
        </Text>
      </Grid>
      <Grid item className="chips-list">
        {chips.map((chipText: any, index: number) => (
          <Chip key={`${chipText}-${index}`} size="small" label={chipText} sx={{ marginRight: 1, marginBottom: 1 }} />
        ))}
      </Grid>
    </div>
  );
};

export default ChipsBox;
