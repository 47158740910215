import React, { useState } from 'react';
import './CollaspsedDiagram.scss';
import classNames from 'classnames';

const CollaspsedDiagram = ({ children }: any) => {
  const [expand, setExpand] = useState<boolean>(false);

  return (
    <div className={classNames('CollaspsedDiagram', { expand: expand })}>
      <div className="collapsable-area">{children}</div>
      <span onClick={() => setExpand(!expand)}>{expand ? 'Collapse' : 'Show All'}</span>
    </div>
  );
};

export default CollaspsedDiagram;
