import React from 'react';
import ReactDOM from 'react-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './app/store';
import { env } from 'env';

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({ clientSideID: env.REACT_APP_LD_CLIENT_ID });

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <div className="ttG-d3-scope"></div>
        <LDProvider>
          <App />
        </LDProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
