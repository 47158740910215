import { AppLoading } from 'common/components/appLoading/appLoading';
import { useEffect, useState } from 'react';
import { IntegrationHeader } from '../Integration/IntegrationHeader/IntegrationHeader';
import { RestApi } from 'common/services/rest-api.service';
import * as EmailValidator from 'email-validator';
import { Common } from 'common/services/common.service';
import { SECURE_INPUT_MASK } from '../../config';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { ToolTip } from 'common/components/toolTip/toolTip';
import { useDispatch } from 'react-redux';
import { loadList, setIntegrationDeleted } from 'features/IntegrationsSlice';
import PopSideFotter from '../Integration/PopSideFotter/PopSideFotter';
import Fade from '@mui/material/Fade';
import IntegrationEmptyState from '../IntegrationEmptyState/IntegrationEmptyState';
import { Text } from '@cyberpion/cyberpion-ui';
import { showSuccess, showError } from 'features/AlertSlice';
import { Button } from 'common/components/Button/Button';
import classNames from 'classnames';
import JiraBlock from './JiraBlock/JiraBlock';

import './Jira.scss';
import '../../Integration.scss';

const blockData = [
  {
    configuration_name: '',
    configuration: {
      server_url: '',
      username: '',
      password: '',
      project: '',
      issue_type: 'Task'
    },
    status: 'active'
  }
];

const Jira = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [serverError, setServerError] = useState<any>([]);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isEmptyState, setIsEmptyState] = useState(true);
  const [selectedDeleteIndex, setSelectedDeleteIndex] = useState<any>(null);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [deleteMsgWithNotificationWarning, setDeleteMsgWithNotificationWarning] = useState(false);
  const [blockIsOpen, setBlockIsOpen] = useState<any>({});

  const dispatch = useDispatch<any>();
  useEffect(() => {
    if (!!data.length) {
      const valid = data.every((integration: any) => {
        return (
          !!EmailValidator.validate(integration.configuration.username) &&
          !!Common.validateURL(integration?.configuration.server_url) &&
          integration.configuration.project.length > 1 &&
          integration.configuration.password.length > 2 &&
          integration.configuration_name.length > 2
        );
      });
      setIsValidated(valid);
    }
  }, [data]);

  useEffect(() => {
    if (selectedDeleteIndex !== null && !!data.length) {
      if (selectedDeleteIndex === 999) {
        const hasIds = data.some((item: any) => !!item.id);
        setDeleteMsgWithNotificationWarning(hasIds);
      } else {
        setDeleteMsgWithNotificationWarning(!!data[selectedDeleteIndex].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeleteIndex]);

  useEffect(() => {
    setIsEmptyState(!data.length);
  }, [data]);

  useEffect(() => {
    if (props.isEditMode) {
      setIsLoading(true);
      RestApi.getData(Paths[PathNames.integrationJira]).subscribe((response: any) => {
        if (response) {
          const data = response.results.map((item: any) => ({
            id: item.id,
            status: item.status,
            configuration_name: item.configuration_name,
            configuration: {
              server_url: item.configuration.server_url,
              username: item.configuration.username,
              password: SECURE_INPUT_MASK,
              project: item.configuration.project,
              issue_type: 'Task'
            }
          }));
          let isOpen: any = {};
          for (let i = 0; i < data.length; i++) {
            isOpen[i] = true;
          }
          setBlockIsOpen(isOpen);
          setData(data);
          setIsLoading(false);
          setIsEmptyState(false);
        }
      });
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditMode]);

  const setValue = (index: number) => (field: any, value: any, isSecure?: boolean) => {
    let copy: any = [...data];
    copy[index] = {
      ...copy[index],
      ...(field === 'configuration_name'
        ? { [field]: value }
        : { configuration: { ...copy[index].configuration, [field]: value } })
    };
    setData(copy);
    setClickedSubmit(false);
  };

  const openBlocksWithError = () => {
    let copy: any = { ...blockIsOpen };
    data.forEach((integration: any, index: number) => {
      if (
        !EmailValidator.validate(integration.configuration.username) ||
        !Common.validateURL(integration?.configuration.server_url) ||
        integration.configuration.project.length < 1 ||
        integration.configuration.password.length < 2 ||
        integration.configuration_name.length < 2
      ) {
        copy[index] = true;
      }
    });
    setBlockIsOpen(copy);
  };

  const submit = async () => {
    setClickedSubmit(true);
    if (!isValidated) {
      openBlocksWithError();
      return;
    }

    setIsLoading(true);
    let urls: any = [];
    data.forEach((item: any) => {
      if (!!item.id) {
        const payload = {
          configuration: {
            username: item.configuration.username,
            ...(item.configuration.password !== SECURE_INPUT_MASK ? { password: item.configuration.password } : {})
          }
        };
        urls = [...urls, { url: Paths[PathNames.integrationJira] + item.id + '/', data: payload, method: 'PATCH' }];
      } else {
        urls = [...urls, { url: Paths[PathNames.integrationJira], data: item, method: 'POST' }];
      }
    });
    const fetchedData: any = await RestApi.getPromiseAllMixedMethods(urls);
    if (!!fetchedData.filter((item: any) => item.isError).length) {
      let errs: any = [];
      let copy = [...data];
      fetchedData.forEach((item: any, index: number) => {
        if (!item.isError) {
          errs = [...errs, { ok: true }];
          copy.splice(index, 1, { ...item.data });
        } else {
          errs = [
            ...errs,
            {
              ok: false,
              error: {
                ...(item.data.configuration_name ? { ...item.data.configuration_name } : {}),
                ...(item.data.configuration ? { ...item.data.configuration } : {})
              }
            }
          ];
        }
      });
      setData(copy);
      setServerError(errs);
      dispatch(showError('Something Went Wrong'));
    } else {
      dispatch(showSuccess('Integration Saved Successfully'));
      dispatch(loadList());
      props.onClose();
    }
    setIsLoading(false);
  };

  const deleteBeforeApplyOnClick = (index: number) => (ev: any) => {
    ev.stopPropagation();
    setSelectedDeleteIndex(index);
    setShowDeletePopup(true);
  };

  const handleDeleteOnCancel = () => {
    setSelectedDeleteIndex(null);
    setShowDeletePopup(false);
  };

  const deleteAfterApplyOnClick = async () => {
    setShowDeletePopup(false);
    setIsLoading(true);
    let urls: any = [];
    if (selectedDeleteIndex === 999) {
      data.forEach((item: any) => {
        if (!!item.id) {
          urls = [...urls, { url: Paths[PathNames.integrationJira] + item.id + '/', method: 'DELETE' }];
        }
      });
    } else {
      if (data[selectedDeleteIndex].id) {
        urls = [
          ...urls,
          { url: Paths[PathNames.integrationJira] + data[selectedDeleteIndex].id + '/', method: 'DELETE' }
        ];
      }
    }

    if (!!urls.length) {
      const fetchedData: any = await RestApi.getPromiseAllMixedMethods(urls);
      if (!!fetchedData.filter((item: any) => item.isError).length) {
        let copy = [...data];
        fetchedData.forEach((item: any, index: number) => {
          if (!item.isError) {
            copy.splice(index, 1);
          }
        });
        setData(copy);
        dispatch(showError('Something Went Wrong'));
      } else {
        dispatch(showSuccess('Deleted Successfully'));
        if (selectedDeleteIndex === 999) {
          // props.onClose();
          dispatch(loadList());
        } else {
          let copy = [...data];
          copy.splice(selectedDeleteIndex, 1);
          setData(copy);
          dispatch(setIntegrationDeleted(true));
        }
      }
    } else {
      if (selectedDeleteIndex === 999) {
        setData([]);
      } else {
        let copy = [...data];
        copy.splice(selectedDeleteIndex, 1);
        setData(copy);
      }
    }
    setIsLoading(false);
    setSelectedDeleteIndex(null);
  };

  const handlePopSideOnClick = (ev: any) => {
    ev.stopPropagation();
    setSelectedDeleteIndex(null);
    setShowDeletePopup(false);
  };

  const handleAddIntegration = (ev: any) => {
    ev.stopPropagation();
    let copy: any = [...data];
    setBlockIsOpen({ ...blockIsOpen, [data.length]: true });
    setData([...blockData, ...copy]);
    setClickedSubmit(false);
    setIsEmptyState(false);
  };

  const handleActiveOnChange = (status: string, id: string) => {
    let copy: any = [...data];
    const index = copy.findIndex((item: any) => item.id === id);
    copy[index] = { ...copy[index], status };
    setData(copy);
  };

  const handleIsOpen = (index: number) => () => {
    setBlockIsOpen({ ...blockIsOpen, [index]: !blockIsOpen[index] });
  };

  return (
    <div>
      {!!isLoading && <AppLoading />}

      {!isLoading && (
        <div className="Jira integration" onClick={handlePopSideOnClick}>
          <IntegrationHeader name="Jira" icon="jira" />
          <div
            className={classNames({ showDeletePopupHeader: showDeletePopup })}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Text
              textColor="#8C909A"
              style={{ width: 200, marginTop: 5, marginRight: 20, lineHeight: 1.5, flexGrow: 1 }}
            >
              In order to connect Jira first connect the tenant and then configure it in the tenants list.
            </Text>
            <Button
              buttonStyle=""
              size="big"
              text="Add Integration"
              onClick={handleAddIntegration}
              type="button"
              className={`add-button`}
            />
          </div>
          {isEmptyState && (
            <Fade in timeout={800}>
              <div>
                <IntegrationEmptyState text="There are no Jira integrations configured" />
              </div>
            </Fade>
          )}
          {!isEmptyState && (
            <Fade in timeout={800}>
              <div style={{ paddingTop: 20 }}>
                <div className={classNames({ showDeletePopupHeader: showDeletePopup })}>
                  <Text textSize={12} weight={500} family="Rubik" style={{ paddingBottom: 16 }}>
                    {`Integrations (${data.length})`}
                  </Text>
                </div>
                <div className="scrollbar-common">
                  <div className={classNames('main-integration-wrapper', { showDeletePopup: showDeletePopup })}>
                    <div style={{ height: '100%', overflow: 'auto' }}>
                      <div className="group">
                        {!!data &&
                          data.map((integration: any, index: number) => (
                            <div
                              key={index}
                              className={classNames(
                                { 'jira-not-first-block': index > 0 },
                                { 'jira-last-block': data.length - 1 === index }
                              )}
                            >
                              <JiraBlock
                                isOpen={blockIsOpen[index]}
                                setIsOpen={handleIsOpen(index)}
                                integration={{ ...integration }}
                                setValue={setValue(index)}
                                deleteIntegrationOnClick={deleteBeforeApplyOnClick(index)}
                                serverError={serverError[index]}
                                clickedSubmit={clickedSubmit}
                                activeOnChange={handleActiveOnChange}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: 20 }}>
                  <PopSideFotter
                    onClose={props.onClose}
                    integrationName="Jira"
                    isValidated={true}
                    onSave={submit}
                    isLoading={isLoading}
                    deleteOnCancel={handleDeleteOnCancel}
                    showDeletePopup={showDeletePopup}
                    deleteBeforeApplyOnClick={deleteBeforeApplyOnClick(999)}
                    deleteAfterApplyOnClick={deleteAfterApplyOnClick}
                    selectedDeleteIndex={selectedDeleteIndex}
                    deleteMsgWithNotificationWarning={deleteMsgWithNotificationWarning}
                    isMainDeleteDisabled={data.filter((item: any) => !!item.id).length === 0}
                  />
                </div>
                <ToolTip theme="dark" place="left" />
              </div>
            </Fade>
          )}
        </div>
      )}
    </div>
  );
};

export default Jira;
