import './indexGrade.scss';
import { TestResultRenderer } from '../testResultRenderer/TestResultRenderer';

export function IndexGrade(props: any) {
  if (!props.value) {
    return null;
  }

  if (!props.value.type || !props.value.risk_grade) {
    return null;
  }

  return (
    <div className="index-grade-scope">
      <span>{props.value.risk_grade.toFixed(0)}</span>
      <TestResultRenderer value={props.value.type} />
    </div>
  );
}
