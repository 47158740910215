import React from 'react';
export class IconRescan extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.15757 2.44741C2.97606 2.26543 2.68407 2.24889 2.5005 2.42879C1.57453 3.33629 1 4.60105 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C5.41789 1 4.85901 1.09948 4.33944 1.28236C4.00766 1.39914 3.94197 1.81778 4.19037 2.06681C4.34059 2.21741 4.56632 2.2585 4.76873 2.19309C5.1566 2.06773 5.57039 2 6 2C8.20914 2 10 3.79086 10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6C2 5.07564 2.31354 4.22452 2.84007 3.54718L3.55463 4.26173C3.2054 4.75214 3 5.35208 3 6C3 7.65685 4.34315 9 6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C5.5403 3 5.53307 3.54277 5.89518 3.82597L5.922 3.84695C6.05251 3.94902 6.21202 4.0042 6.37481 4.03506C7.30023 4.21052 8 5.02354 8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 5.6291 4.10096 5.28177 4.27691 4.98402L5.29287 5.99998C5.48813 6.19524 5.80471 6.19524 5.99998 5.99998C6.19524 5.80471 6.19524 5.48813 5.99998 5.29287L4.37713 3.67002L4.2619 3.55451C4.26185 3.55455 4.26179 3.55459 4.26173 3.55463L3.54718 2.84007C3.54757 2.83977 3.54796 2.83946 3.54835 2.83916L3.15757 2.44741Z"
          fill="#5D6576"
        />
      </svg>
    );
  }
}
