import { Common } from 'common/services/common.service';
import { ICONS } from './config';
import './CategoriesWithIcons.scss';
interface ILegend {
  header: string;
  name: string;
  value: number;
}

interface IFormHostingProvier {
  data: ILegend[];
  label: string;
  count?: number;
}

export function CategoriesWithIcons(props: IFormHostingProvier) {
  const extractAppName = (appName: string): string => {
    return appName
      .split('(')[0]
      .trim()
      .toLowerCase();
  };

  function createElement() {
    let Component;
    let list: any = [];
    props.data &&
      props.data.forEach((item: ILegend, index: number) => {
        const appName = extractAppName(item.header.toLowerCase());
        Component = ICONS[appName] ? ICONS[appName] : ICONS.default;
        list.push(
          <div key={index}>
            <Component />
            <div className="formHostingProvier_label" data-tip={item.header}>
              {item.header}
            </div>
            <div className="formHostingProvier_count font-number-common">{Common.numberWithCommas(item.value)}</div>
          </div>
        );
      });
    return list;
  }
  return (
    <div className="formHostingProvier-scope">
      {createElement()}
      {/* <ToolTip theme='light' /> */}
    </div>
  );
}
