import React from 'react';
import './tagsRenderer.scss';

interface ITag {
  key: string;
  value: string;
}

export class TagsRenderer extends React.Component {
  public props: any;
  public count: number = 0;
  public widthElement: number = 0;
  public listString: string[] = [];
  componentDidMount() {}

  render() {
    let rows: JSX.Element[] = [];
    this.listString = [];
    this.count = 0;
    if (this.props.value) {
      this.props.value.forEach((item: ITag, index: number) => {
        this.calculate(rows, item, index);
      });
    }

    return (
      <div className="tags-render-scope">
        {rows}
        {this.showCount()}
      </div>
    );
  }

  //Calculate how many tags to display
  calculate(rows: JSX.Element[], item: ITag, index: number) {
    if (this.listString.toString().length >= 20) {
      this.count++;
    } else {
      rows.push(
        <div className="tag-render" key={index}>
          <div className="tag-text-render">{item.key}</div>
          <div>:</div>
          <div className="tag-value-render">{item.value}</div>
        </div>
      );
    }
    this.listString.push(`${item.value} ${item.key}`);
  }

  showCount() {
    let elementCount = <div className="count">+{this.count}</div>;
    if (this.count === 0) {
      return;
    }
    return elementCount;
  }
}
