import { Common } from 'common/services/common.service';
import React, { useEffect } from 'react';
import * as d3 from 'd3';
import './legend.scss';
interface ILegend {
  label: string;
  key?: string;
  is_toggled?: boolean;
}

interface IMultiLineChartLegend {
  data: ILegend[];
}
export function Legend(props: IMultiLineChartLegend) {
  const [dataLegend, setDataLegend] = React.useState<any>([]);

  useEffect(() => {
    let data = [...props.data];
    setDataLegend(data);
  }, [props.data]);

  function filterGroup(item: any, index: number) {
    let data = [...dataLegend];

    data[index].is_toggled = !item.is_toggled;
    d3.selectAll(`.circle-${item.key}`)
      .transition()
      .duration(400)
      .style('opacity', data[index].is_toggled ? 1 : 0)
      .attr('r', data[index].is_toggled ? 4 : 0);

    d3.selectAll(`.path-${item.key}`)
      .transition()
      .duration(400)
      .style('opacity', data[index].is_toggled ? 1 : 0);

    setDataLegend(data);
  }

  return (
    <div className="multiLineChart-scope">
      {dataLegend.map((item: ILegend, index: number) => (
        <div
          onClick={() => {
            filterGroup(item, index);
          }}
          className="multiLineChart-legend-scope"
          key={index}
        >
          <div
            className="multiLineChart-icon"
            style={{
              background: item.is_toggled ? Common.getColorByUrgency(item.key) : '#fff',
              borderColor: Common.getColorByUrgency(item.key)
            }}
          ></div>
          <div className="multiLineChart-label">{item.label}</div>
        </div>
      ))}
    </div>
  );
}
