import classNames from 'classnames';
import { AppLoading } from '../appLoading/appLoading';
import { IconArrow } from '../Icons/IconArrow/IconArrow';
import './Button.scss';

interface IButton {
  text: string;
  type: 'button' | 'submit' | 'reset' | undefined;
  icon?: string;
  loading?: boolean | null;
  onClick: any;
  disabled?: boolean;
  buttonStyle: string;
  size: string;
  dataTestId?: string;
  className?: string;
  addArrow?: boolean;
  nestedJSXElement?: JSX.Element;
}

export function Button(props: IButton) {
  const moreProps = !!props.dataTestId ? { 'data-testid': props.dataTestId } : {};
  return (
    <button
      type={props.type}
      className={classNames('Button', props.className, `size-${props.size}`, props.buttonStyle, {
        loading: props.loading
      })}
      onClick={props.onClick}
      disabled={props.disabled}
      {...moreProps}
    >
      {props.loading && <AppLoading />}
      {props.icon && <img src={`/assets/images/${props.icon}.svg`} alt="Icon" />}
      {props.text}
      {props.nestedJSXElement}
      {props.addArrow ? <IconArrow /> : ''}
    </button>
  );
}
