import { IIcon } from 'common/interfaces/interface';
import React from 'react';

export class IconGeoMap extends React.Component {
  public props: IIcon;
  render() {
    let color = '#3455DD'; //this.props.active ? "#3455DD" : "#6D6D7A";
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <circle cx="8.00004" cy="8.00001" r="6.11111" stroke={color} strokeWidth="1.11111" />
        <path d="M2.5 8H13.5" stroke={color} strokeLinecap="round" />
        <path
          d="M9.75736 3.75736L8 2L6.24264 3.75736C3.8995 6.1005 3.8995 9.8995 6.24264 12.2426L8 14L9.75736 12.2426C12.1005 9.8995 12.1005 6.1005 9.75736 3.75736Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
