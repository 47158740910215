export function IconCircledArrowRight(props: any) {
  return (
    <svg
      className={`${props.className || ''}`}
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.1667 8C14.1667 11.4058 11.4058 14.1667 8.00004 14.1667C4.59428 14.1667 1.83337 11.4058 1.83337 8C1.83337 4.59424 4.59428 1.83333 8.00004 1.83333C11.4058 1.83333 14.1667 4.59424 14.1667 8Z"
        stroke="#3455DD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 10L10 8L8 6" stroke="#3455DD" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 8H10" stroke="#3455DD" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
