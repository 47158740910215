import { AppLoading } from 'common/components/appLoading/appLoading';
import { RestApi } from 'common/services/rest-api.service';
// import { Evidence } from './Evidence/Evidence'
import { useEffect, useState } from 'react';
import { Evidence } from './Evidence/Evidence';
import './UnlinkedAssets.scss';

interface IUnlinkedAssets {
  host: string;
  certainty: number;
  service_type: string;
  related_keywords: string[];
  related_org_fqdns: string[];
  related_fqdns: string[];
  evidences_types: string[];
  evidences: IEvidence[];
}

interface IEvidence {
  [key: string]: any;
}
export function UnlinkedAssets(props: any) {
  const initData = () => {
    return {
      host: '',
      service_type: '',
      certainty: 0,
      evidences: [],
      related_org_fqdns: [],
      related_fqdns: [],
      evidences_types: [],
      related_keywords: []
    };
  };

  const [data, setData] = useState<IUnlinkedAssets>(initData());
  const [loading, setLoading] = useState<boolean>(false);

  function onCancel() {
    props.setVisible(false);
  }

  useEffect(() => {
    setLoading(true);
    RestApi.getData(`discovery/unlinked-assets/${props.data.host}/`).subscribe((response: any) => {
      setLoading(false);
      setData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEvidencesListHTML = (items: IEvidence[]): JSX.Element[] => {
    const _html: JSX.Element[] = [];
    items.forEach((item: IEvidence, index: number) => {
      _html.push(<Evidence data={item} isOpened={index === 0} />);
    });
    return _html;
  };

  return (
    <div className="UnlinkedAssets popup-inner-wrapper">
      {loading && <AppLoading />}
      <div className="UnlinkedAssets-header-scope">
        <div className="UnlinkedAssets-header-text">
          {data.host}
          <span className="small">(Unlinked {data.service_type})</span>
        </div>
        <div className="UnlinkedAssets-remove" onClick={onCancel}>
          +
        </div>
        <img src="/assets/images/background-popup.svg" alt="background-popup" />
      </div>
      <div className="scrollbar-common">
        <div className="UnlinkedAssets-popup-body">
          <div style={{ height: '100%', overflow: 'auto' }}>
            {/* {data.host && (
                        <div className="UnlinkedAssets-field-scope">
                            <div className="UnlinkedAssets-field-header">Asset</div>
                            <div className="UnlinkedAssets-field-value">{data.host}</div>
                        </div>
                        )} */}
            {data.certainty && (
              <div className="UnlinkedAssets-field-scope">
                <div className="UnlinkedAssets-field-header">Certainty</div>
                <div className="UnlinkedAssets-field-value">{data.certainty}</div>
              </div>
            )}
            {data.related_keywords?.length && (
              <div className="UnlinkedAssets-field-scope">
                <div className="UnlinkedAssets-field-header">Keywords</div>
                <div className="UnlinkedAssets-field-value">{data.related_keywords.join(', ')}</div>
              </div>
            )}
            {data.related_org_fqdns?.length && (
              <div className="UnlinkedAssets-field-scope">
                <div className="UnlinkedAssets-field-header">Related Org FQDNs</div>
                <div className="UnlinkedAssets-field-value">{data.related_org_fqdns.join(', ')}</div>
              </div>
            )}
            {data.related_fqdns?.length && (
              <div className="UnlinkedAssets-field-scope">
                <div className="UnlinkedAssets-field-header">Related External FQDNs</div>
                <div className="UnlinkedAssets-field-value">{data.related_fqdns.join(', ')}</div>
              </div>
            )}
            {data.evidences_types?.length && (
              <div className="UnlinkedAssets-field-scope">
                <div className="UnlinkedAssets-field-header">Evidences Types</div>
                <div className="UnlinkedAssets-field-value">{data.evidences_types.join(', ')}</div>
              </div>
            )}
            {data.evidences?.length && (
              <div className="UnlinkedAssets-field-scope">
                <div className="UnlinkedAssets-field-header">Evidence</div>
                <div className="UnlinkedAssets-field-value">
                  <ul className="evidences-list">{getEvidencesListHTML(data.evidences)}</ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
