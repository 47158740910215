import { useState, useRef } from 'react';
import { DataGridPro, GridApi, GridRowData, GridSelectionModel } from '@mui/x-data-grid-pro';
import { ManageNotData } from '../manageNotData/ManageNotData';
import { styled } from '@mui/material/styles';
import './grid.scss';

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  '& .MuiDataGrid-cellCheckbox': {
    '& .MuiSvgIcon-root': {
      width: '18px !important',
      height: '18px !important'
    }
  },
  '& .MuiDataGrid-columnHeader': {
    '& .MuiSvgIcon-root': {
      width: '18px !important',
      height: '18px !important',
      color: '#8C909A90'
    },
    '& .Mui-checked > .MuiSvgIcon-root': {
      color: '#4A79E7'
    }
  },
  '& .MuiDataGrid-row': {
    backgroundColor: '#FFFFFF !important',

    '.MuiCheckbox-root > .MuiSvgIcon-root': {
      color: '#8C909A90'
    },

    '.grid-link-field, .info-panel-count, a > .grade-render-scope': {
      color: '#4A79E7',
      padding: '4px 6px',
      borderRadius: 4,
      backgroundColor: '#F3F5FF !important'
    },

    '&:hover': {
      backgroundColor: '#F7F7F7 !important',

      '& .MuiDataGrid-cell': {
        '.grid-link-field, .info-panel-count, a > .grade-render-scope': {
          backgroundColor: '#F1F1F1 !important',
          '&:hover': {
            backgroundColor: '#EAEAEA !important'
          }
        },
        '& .MuiCheckbox-root': {
          // color: 'blue',
          '&:hover': {
            '& .MuiSvgIcon-root': {
              color: '#5B76D5'
            }
          }
        }
      }
    },

    '&.Mui-selected': {
      backgroundColor: '#F5F6FF !important',

      '.MuiCheckbox-root > .MuiSvgIcon-root': {
        color: '#1876D2'
      },
      '&:hover': {
        backgroundColor: '#E8ECFE !important'
      },
      '& .MuiDataGrid-cell': {
        '.grid-link-field, .info-panel-count, a > .grade-render-scope': {
          backgroundColor: '#E1E6FF !important',
          '&:hover': {
            backgroundColor: '#DAE0FF !important'
          }
        }
      }
    }
  }
}));

export function LocalGrid(props: any) {
  const apiRef: any = useRef<GridApi>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const handleOnSelectionModelChange = (model: GridSelectionModel) => {
    setSelectionModel(model);
  };

  const AddedActions = props.addedActions;

  const getRowId = (row: GridRowData) => {
    if (row.whois) {
      return row[props.identifier] + row.type;
    }
    return row[props.identifier];
  };

  return (
    <div className="grid">
      {props.addedActions ? (
        <div className="utilities-row">
          <AddedActions ids={selectionModel} />
        </div>
      ) : (
        ''
      )}
      <div className="grid-scope">
        <div style={{ height: '100%', width: '100%', background: '#FFFFFF', position: 'relative' }}>
          <StyledDataGridPro
            components={{
              NoRowsOverlay: () => <ManageNotData />
            }}
            apiRef={apiRef}
            rows={props.rows}
            columns={props.columns}
            onSelectionModelChange={handleOnSelectionModelChange}
            selectionModel={selectionModel}
            filterMode="server"
            sortingMode="server"
            disableMultipleColumnsSorting={true}
            disableSelectionOnClick={true}
            disableColumnReorder={true}
            checkboxSelection
            disableColumnMenu={true}
            hideFooter={true}
            getRowId={row => getRowId(row)}
          />
        </div>
      </div>
    </div>
  );
}
