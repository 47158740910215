import { IReduxState } from 'app/store';
import { PopupHeader } from 'common/components/PopupHeader/PopupHeader';
import { RestApi } from 'common/services/rest-api.service';
import { getRows } from 'features/GridSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GroupTabs } from './GroupTabs/GroupTabs';
import { setLabel, inputSearch } from 'features/GroupsSlice';
import { Common } from 'common/services/common.service';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import { showError, showSuccess } from 'features/AlertSlice';

import './CreateGroupPopUp.scss';

export function CreateGroupPopUp(props: any) {
  const [groupNameError, setGroupNameError] = useState<boolean>(false);

  const dispatch = useDispatch<any>();
  const {
    bulkVisible,
    label,
    editMode,
    subsidiaryMode,
    domains,
    ips,
    services,
    whois,
    identifier,
    mobileApps
  } = useSelector((state: IReduxState) => state.groups);

  useEffect(() => {
    document.getElementsByClassName('Settings')[0].classList.add('ontop');
    document.getElementsByClassName('settings-wrapper')[0].classList.add('ontop');

    return () => {
      document.getElementsByClassName('Settings')[0].classList.remove('ontop');
      document.getElementsByClassName('settings-wrapper')[0].classList.remove('ontop');
    };
  }, []);

  const onAdd = () => {
    if (!label) {
      setGroupNameError(true);
      return;
    }
    const _domains = domains.map((d: { value: string }) => {
      return d.value;
    });
    const _ips = ips.map((ip: { value: string }) => {
      return ip.value;
    });
    const _whois = whois.map((w: { whois: string; type: string }) => {
      return { value: w.whois, type: Common.convertToApiPath(w.type) };
    });
    const _mobileApps = mobileApps.map((app: { value: string }) => {
      return app.value;
    });

    RestApi.setData(
      `${Paths[PathNames.groups]}${editMode ? identifier + '/' : ''}`,
      {
        name: label,
        domains: _domains,
        ip_networks: _ips,
        services: services,
        whois: _whois,
        mobile_apps: _mobileApps
      },
      editMode ? 'PUT' : 'POST'
    ).subscribe(
      (response: any) => {
        dispatch(inputSearch(''));
        props.onClose();
        dispatch(getRows());
        dispatch(showSuccess(`Group ${editMode ? 'Saved' : 'Created'} Successfully`));
      },
      (error: any) => {
        dispatch(showError(error));
      }
    );
  };
  const handleClosePopup = () => {
    dispatch(inputSearch(''));
    props.onClose();
  };

  const _setGroupName = (val: string) => {
    dispatch(setLabel(val));
    setGroupNameError(false);
  };

  const isSaveDisabled = () => {
    if (bulkVisible) {
      return true;
    }

    if (!domains.length && !ips.length && !whois.length && !services.length && !mobileApps.length) {
      return true;
    }

    return false;
  };

  return (
    <div className="CreateGroupPopUp popup-inner-wrapper">
      <PopupHeader
        placeholder={label || 'Group Name'}
        text={label}
        onClose={handleClosePopup}
        editable={!subsidiaryMode}
        onChange={_setGroupName}
        isError={groupNameError}
        errorText={'Missing Group Name'}
      />
      <div className="popup-inner">
        <div className="notice">
          Use the tabs below to define your groups.
          <br />
          Creation or changes of groups may take several minutes to apply.
        </div>
        <GroupTabs />
        <div className="buttons-wrapper">
          <button className={`btn btn-secondary btn-big ${bulkVisible ? 'disabled' : ''}`} onClick={handleClosePopup}>
            Cancel
          </button>
          <button className={`btn btn-main btn-big ${isSaveDisabled() ? 'disabled' : ''}`} onClick={onAdd}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
