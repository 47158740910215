import { IColumnPicker } from 'common/interfaces/interface';
import { GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro';
import { TestResultRenderer } from './components/components-renderer/testResultRenderer/TestResultRenderer';
import { RiskRenderer } from './components/components-renderer/riskRenderer/RiskRenderer';
import { GradeRenderer } from './components/components-renderer/gradeRenderer/GradeRenderer';
import { TagsRenderer } from './components/components-renderer/tagsRenderer/TagsRenderer';
// import { ChipsRenderer } from './components/components-renderer/chipsRenderer/ChipsRenderer';
import { IColumnsGrid } from './interface';
import { Common } from 'common/services/common.service';
import { ArrayRenderer } from './components/components-renderer/arrayRenderer/ArrayRenderer';
import { InfoPanelRenderer } from './components/components-renderer/InfoPanelRenderer/InfoPanelRenderer';
import { BooleanRenderer } from './components/components-renderer/booleanRenderer/BooleanRenderer';
import { SpinnerRenderer } from './components/components-renderer/spinnerRenderer/SpinnerRenderer';
import { UrgencyRenderer } from './components/components-renderer/urgencyRenderer/UrgencyRenderer';
import calculateSize from 'calculate-size';
import { LinkRenderer } from './components/components-renderer/LinkRenderer/LinkRenderer';
import { ColoredNumber } from './components/components-renderer/coloredNumber/coloredNumber';
import { IndexGrade } from './components/components-renderer/indexGrade/indexGrade';
import { UserRolesDropdown } from 'pages/Settings/UserManagement/UserRolesDropdown/UserRolesDropdown';
import { RemoveRow } from 'pages/Settings/RemoveRow/RemoveRow';
import { RemoveRowLocal } from 'pages/Settings/RemoveRow/RemoveRowLocal';
import { FilterGroupsDropdown } from 'pages/Settings/FilterGroups/FilterGroupsDropdown/FilterGroupsDropdown';
import { AssesmentsLinkRenderer } from './components/components-renderer/AssesmentsLinkRenderer/AssesmentsLinkRenderer';
import { AssesmentsArrayLinkRenderer } from './components/components-renderer/AssesmentsArrayLinkRenderer/AssesmentsArrayLinkRenderer';
import { DynamicMultiChoiceDropDown } from '../DynamicMultiChoiceDropDown/DynamicMultiChoiceDropDown';
import { setUserGroups } from 'features/GroupsSlice';
import { getGroups } from 'features/GroupsSlice';
import { Flag } from '../Flag/Flag';
import { ConfidenceLevel, getColorByUrgency, Importance } from '@cyberpion/cyberpion-ui';
import { Download } from '../Download/Download';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { EditableColumn } from '../EditableColumn/EditableColumn';
import RowActionMenu from '../RowActionMenu/RowActionMenu';
import Relationship from '../Relationship/Relationship';
import { CustomTagsRenderer } from './components/components-renderer/CustomTagsRenderer/CustomTagsRenderer';
import { MSSPLoginButton } from '../MSSPLoginButton/MSSPLoginButton';
import { Score } from '../score/Score';
import { ScanStatus } from '../ScanStatus/ScanStatus';
import { ScreenshotRenderer } from '../ScreenshotRenderer/ScreenshotRenderer';
import { DropdownWithAction } from '../DropdownWithAction/DropdownWithAction';
import { Comments } from '../Comments/Comments';
import { getSourceImage } from 'pages/action-items/popside/CnappAlertPopside/CnappAlertHeader';

export interface ITypes {
  [key: string]: any;
}

export interface ISource {
  category?: number | string;
  page: string;
  tab: string;
}

const types: ITypes = {
  risk: 0,
  date: 0,
  actions: 0,
  index_grade: 0,
  colored_number: 0,
  result: 0,
  grade: 0,
  string: 1,
  number: 0,
  label: 2,
  array: 3,
  array_string: 3,
  spinner: 4,
  rbac_groups: 4,
  importance: 1,
  boolean: 0,
  subaccount_risk_score: 0,
  ai_rescan_status: 0,
  asset_importance: 0,
  mssp_login_button: 0
};

const typesSizes: ITypes = {
  editable_string: {
    sso_id: 250,
    first_name: 180,
    last_name: 180,
    mobile: 200
  }
};

//@param: label
//@return minWidth
//@description: Sets the minimum width of a column.
const calculateMinimumWidthForColumn = (column: IColumnPicker): number => {
  if (!!column.column_width) {
    return column.column_width;
  }
  //MSSP TABLE CUSTOMIZED SIZES
  if (['active_protection', 'critical_action_items_30', 'critical_action_items'].includes(column.name)) {
    // specifically for a cell in MSSP table
    return 120;
  }
  const smallColumns = [
    'plan',
    'subaccount_risk_score',
    'mssp_company',
    'severity',
    'suggested_new_severity',
    'cloud_provider'
  ];
  if (smallColumns.includes(column.type) || smallColumns.includes(column.name)) {
    return 80;
  }
  if (column.name === 'current_state') {
    return 200;
  }

  const bigColumns = ['title'];
  if (bigColumns.includes(column.type) || bigColumns.includes(column.name)) {
    return 350;
  }
  // New Addition: cells with flex of 0 are cells with fixed width
  if (types[column.type] === 0) {
    const size = calculateSize(column.label, Common.getTableHeaderFontProps());
    return size.width + 50; // safe margin space
  }
  // this is the previous calculations for the rest of the fields
  let length = column.label.length;
  if (typesSizes[column.type]) {
    return typesSizes[column.type][column.name];
  }
  return length < 10 ? 150 : length * 15;
};

//@param: column = item of Column Picker
//@return: number
//@description: Put a column width by types
const columnWidthByTypes = (column: IColumnPicker) => {
  const flexValue = types[column.type];
  if (flexValue === undefined) {
    return 1;
  }
  return flexValue;
};

const getHeaderDescription = (columnName: string) => {
  if (columnName === 'critical_action_items_30') {
    return 'Added in the last 30 days';
  }
  return undefined;
};

//@param: column = item of Column Picker
//@return: Object column
//@description: Creates a grid column
const itemColumn = (column: IColumnPicker): IColumnsGrid => {
  return {
    field: column.name,
    headerName: column.label,
    description: getHeaderDescription(column.name),
    flex: columnWidthByTypes(column),
    minWidth: calculateMinimumWidthForColumn(column),
    typeColumn: column.type,
    hide: !column.is_toggled,
    sortable: column.is_sortable,
    resizable: column.name !== 'actions'
  };
};

//@param: column = item of Column Grid
//@return: Object Updated column grid
//@description: Adding a custom component to grid cell design

function addCustomComponent(
  identifier: string,
  column: IColumnsGrid,
  source: ISource,
  cb?: Function,
  menuItemOnClick?: (rowData: any, option: any, type: string, event: React.MouseEvent<HTMLElement>) => void
) {
  if (source.page === 'assesments') {
    if (column.field === 'web_failed_tests') {
      column.renderCell = (params: GridRenderCellParams) => (
        <AssesmentsArrayLinkRenderer {...params} flexWrap="nowrap" />
      );
      return column;
    }
    if (column.typeColumn === 'grade') {
      column.renderCell = (params: GridRenderCellParams) => <AssesmentsLinkRenderer {...params} source={source} />;
      return column;
    }
  }
  if (
    [
      'fqdn',
      'host',
      'asset',
      'ionix_asset',
      'connected_assets',
      'from_source',
      'to_target',
      'target',
      'hosts',
      'url_org_host',
      'related_asset',
      'related_assets'
    ].includes(column.field) &&
    source.tab !== 'unlinkedAssets'
  ) {
    column.renderCell = (params: GridRenderCellParams) => {
      return ['connected_assets', 'hosts', 'related_assets'].includes(column.field) ? (
        <ArrayRenderer
          data={params.value}
          field={column.field}
          source={source}
          identifier={identifier}
          flexWrap="nowrap"
        />
      ) : (
        <LinkRenderer params={params} source={source} identifier={identifier} />
      );
    };

    return column;
  }

  if (column.field === 'rescan_status') {
    column.renderCell = (params: GridRenderCellParams) => (
      <ScanStatus params={params} key={params.id} source={source} />
    );
    return column;
  }
  if (column.field === 'role') {
    column.renderCell = (params: GridRenderCellParams) => <UserRolesDropdown params={params} key={params.id} />;
    return column;
  }
  if (column.field === 'title') {
    column.renderCell = (params: GridRenderCellParams) => (
      <div
        className="grid-link-field"
        onClick={() => {
          if (cb) {
            cb(params);
          }
        }}
      >
        {params.value?.label || params.value}
      </div>
    );
    return column;
  }
  if (column.field === 'cloud_provider') {
    column.renderCell = (params: GridRenderCellParams) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {['gcp', 'aws', 'azure'].includes(params.value.toLowerCase()) && (
          <img style={{ marginRight: 6 }} src={`/assets/images/${params.value.toLowerCase()}.svg`} alt={params.value} />
        )}
        {params.value}
      </div>
    );
    return column;
  }

  if (column.field === 'source' && source.tab === 'alerts') {
    column.renderCell = (params: GridRenderCellParams) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img alt={params.value} style={{ marginRight: 6 }} src={`/assets/images/${getSourceImage(params.value)}.svg`} />
        {params.value}
      </div>
    );
    return column;
  }

  if (['severity', 'suggested_new_severity'].includes(column.field)) {
    column.renderCell = (params: GridRenderCellParams) =>
      params.value ? (
        <div
          style={{
            backgroundColor: getColorByUrgency(params.value?.toLowerCase()),
            color: '#ffffff',
            padding: '2px 5px',
            borderRadius: 4,
            fontSize: 12
          }}
        >
          {params.value}
        </div>
      ) : (
        ''
      );
    return column;
  }
  if (['sso_id', 'first_name', 'last_name', 'mobile'].includes(column.field)) {
    column.renderCell = (params: GridRenderCellParams) => <EditableColumn params={params} key={params.id} />;
    return column;
  }
  if (column.field === 'group_restrictions') {
    column.renderCell = (params: GridRenderCellParams) => (
      <DynamicMultiChoiceDropDown
        disabled={['admin', 'owner'].includes(params.row.role.toLowerCase())}
        key={params.id}
        path={`${Paths[PathNames.groups]}?fields=name,id`}
        searchPath={`${Paths[PathNames.groups]}?fields=name,id&name__contains=`}
        fieldName="name"
        headerText="None"
        limitHeaderLength={20}
        dispatchSetAction={setUserGroups}
        dispatchGetAction={getGroups}
        {...params}
      />
    );
    return column;
  }
  if (column.field === 'country') {
    column.renderCell = (params: GridRenderCellParams) => <Flag {...params} />;
    return column;
  }
  if (column.field === 'is_downloadable') {
    column.renderCell = (params: GridRenderCellParams) => <Download {...params} />;
    return column;
  }
  switch (column.typeColumn) {
    case 'mssp_login_button':
      // column.renderCell = (params: GridRenderCellParams) => <MSSPLoginButton params={params} />;
      column.renderCell = (params: GridRenderCellParams) => (
        <div style={{ display: 'flex' }}>
          <MSSPLoginButton params={params} />
        </div>
      );
      return column;
    case 'actions':
      if (source.page === 'settings') {
        if (['domains', 'ip-networks', 'whois', 'mobile-apps'].includes(source.tab)) {
          column.renderCell = (params: GridRenderCellParams) => <RemoveRowLocal params={params} source={source} />;
        } else if (source.category === 'groups') {
          column.renderCell = (params: GridRenderCellParams) => (
            <FilterGroupsDropdown identifier={identifier} {...params} />
          );
        } else if ((source.tab === 'subsidiaries' || source.category === 'meta-groups') && !!menuItemOnClick) {
          // column.renderCell = (params: GridRenderCellParams) => <RemoveRow params={params} source={source} />;
          const type = source.tab === 'subsidiaries' ? 'subsidiaries' : 'metaGroups';
          column.renderCell = (params: GridRenderCellParams) => (
            <RowActionMenu rowData={params} itemOnClick={menuItemOnClick} type={type} status={params.row.actions} />
          );
        } else {
          column.renderCell = (params: GridRenderCellParams) => <RemoveRow params={params} source={source} />;
          // column.renderCell = (params: GridRenderCellParams) => return <RowActionMenu anchorEl={anchorEl} onClose={handleMenuClose} itemOnClick={handleMenuItemClick} /> <RemoveRow params={params} source={source} />;
        }
        return column;
      } else {
        column.renderCell = (params: GridRenderCellParams) => (
          <div
            className="pointer"
            onClick={() => {
              cb && cb(source.category, params);
            }}
          >
            Show Details
          </div>
        );
        return column;
      }
    case 'subaccount_risk_score':
      column.renderCell = (params: GridRenderCellParams) => {
        if (params.formattedValue) {
          return (
            <Score
              value={params.formattedValue?.score}
              label={params.formattedValue?.grade.toUpperCase()}
              size="small"
            />
          );
        } else {
          <div></div>;
        }
      };
      return column;
    case 'index_grade':
      column.renderCell = (params: GridRenderCellParams) => <IndexGrade {...params} />;
      return column;
    case 'colored_number':
      column.renderCell = (params: GridRenderCellParams) => <ColoredNumber {...params} />;
      return column;
    case 'result':
      column.renderCell = (params: GridRenderCellParams) => <TestResultRenderer {...params} />;
      return column;
    case 'grade':
      column.renderCell = (params: GridRenderCellParams) => <GradeRenderer {...params} />;
      return column;
    case 'risk':
      column.renderCell = (params: GridRenderCellParams) => <RiskRenderer {...params} />;
      return column;
    case 'ap_risk':
      column.renderCell = (params: GridRenderCellParams) => <UrgencyRenderer {...params} />;
      return column;
    case 'label':
      column.renderCell = (params: GridRenderCellParams) => <TagsRenderer {...params} />;
      return column;
    case 'array_string':
    case 'array_number':
    case 'rbac_groups':
      column.renderCell = (params: GridRenderCellParams) => (
        <ArrayRenderer
          data={params.value}
          field={column.field}
          source={source}
          identifier={identifier}
          flexWrap="nowrap"
        />
      );
      return column;
    case 'screenshot':
      column.renderCell = (params: GridRenderCellParams) => {
        return !!params.row?.screenshot_url ? (
          <ScreenshotRenderer url={params.row?.screenshot_url} className="screenshot-link" text={'Show screenshot'} />
        ) : null;
      };

      return column;
    case 'count_info_panel':
      column.renderCell = (params: GridRenderCellParams) => <InfoPanelRenderer {...params} source={source} />;
      return column;
    case 'confidence_level':
      column.renderCell = (params: GridRenderCellParams) => {
        return params.value?.confidence_level ? (
          <ConfidenceLevel {...params} label={params.value?.type} size="small" showInGrid />
        ) : (
          <div />
        );
      };
      return column;
    case 'array_chips':
    case 'tags':
      column.renderCell = (params: GridRenderCellParams) => (
        <CustomTagsRenderer data={params.value} {...params} source={source} identifier={identifier} />
      );
      return column;
    case 'boolean':
      column.renderCell = (params: GridRenderCellParams) => <BooleanRenderer {...params} />;
      return column;
    case 'user':
      // column.renderCell = (params: GridRenderCellParams) => (
      //   <Assignee
      //     is_black_mode={true}
      //     assignee={params.row.assignee}
      //     id={params.row.id}
      //     isInsideGrid={true}
      //   />
      // );
      return column;
    case 'relationship':
      column.renderCell = (params: GridRenderCellParams) => (
        <Relationship relationship={params.value.relationship} label={params.value.label} />
      );
      return column;
    case 'date':
      column.valueGetter = (params: any) => {
        return Common.getDateFormat(params.value);
      };
      return column;
    case 'datetime':
    case 'published_date':
      column.valueGetter = (params: any) => {
        return !!params.value ? Common.getDateFullTime(params.value) : '';
      };
      return column;
    case 'spinner':
      column.renderCell = (params: GridRenderCellParams) => <SpinnerRenderer {...params} />;
      return column;
    case 'country':
      column.renderCell = (params: GridRenderCellParams) => <Flag {...params} showCountry={true} />;
      return column;
    case 'importance':
    case 'asset_importance':
      column.renderCell = (params: any) => {
        return <Importance {...params} />;
      };
      return column;
    case 'string_with_highlight':
      column.renderCell = (params: any) => {
        return Common.highlightText(params.value);
      };
      return column;
    case 'number':
      column.renderCell = (params: any) => {
        return Common.numberWithCommas(params.value);
      };
      return column;
    case 'dropdown_with_action':
      column.renderCell = (params: GridRenderCellParams) => <DropdownWithAction {...params} column={column} />;
      return column;
    case 'comments_popover':
      column.renderCell = (params: GridRenderCellParams) => <Comments {...params} column={column} />;
      return column;
    default:
      return column;
  }
}
export const ColumnsManage = {
  fields: [] as string[],
  testGridSortModel: [] as GridSortModel,
  //@param: columnPicker = list of Column Picker
  //@return: All columns
  //@description: Creates columns for a grid
  // createColumns: (columnPicker: IColumnPicker[]): GridColDef[] => {
  createColumns: (
    identifier: string,
    columnPicker: IColumnPicker[],
    source: ISource,
    optionals?: any
    // cb?: Function,
    // menuItemOnClick?: (option: any, event: React.MouseEvent<HTMLElement>) => void
  ): GridColDef[] => {
    let columns: GridColDef[] = [];
    columnPicker.forEach(column => {
      try {
        if (column.name !== '' && !column.is_hidden) {
          columns.push(
            addCustomComponent(identifier, itemColumn(column), source, optionals?.cb, optionals?.menuItemOnClick)
          );
        }

        if (column.child.length !== 0) {
          column.child.forEach(child => {
            columns.push(
              addCustomComponent(identifier, itemColumn(child), source, optionals?.cb, optionals?.menuItemOnClick)
            );
          });
        }
      } catch (error) {
        return columns;
      }
    });
    return columns;
  },
  //@param: apiRef = events of material ui
  //@param: columns = columns of grid
  //@param: fields = list of Column fields
  //@return: All columns
  //@description: Hide or Show the columns
  hideColumns: (apiRef: any, columns: GridColDef[], fields: string[]) => {
    columns.forEach((column: GridColDef) => {
      if (fields.indexOf(column.field) !== -1) column.hide = false;
      else column.hide = true;
    });
    if (apiRef.current) {
      return apiRef.current.updateColumns(columns);
    }
  },
  hideSpecificColumns: (apiRef: any, columns: GridColDef[], columnsToHide: string[]) => {
    columns.forEach((column: GridColDef) => {
      if (columnsToHide.includes(column.field)) column.hide = true;
    });
    ColumnsManage.fields = columns.filter(c => !c.hide).map(c => c.field);
    if (apiRef.current) {
      return apiRef.current.updateColumns(columns);
    }
  },
  showSpecificColumns: (apiRef: any, columns: GridColDef[], columnsToShow: string[]) => {
    columns.forEach((column: GridColDef) => {
      if (columnsToShow.includes(column.field)) {
        column.hide = false;
      }
    });
    ColumnsManage.fields = columns.filter(c => !c.hide).map(c => c.field);
    if (apiRef.current) {
      return apiRef.current.updateColumns(columns);
    }
  },
  //@param: columnPicker = list of Column Picker
  //@description: Check which columns to display and Send an updated path for data
  checkGridColumnsToDisplay(columnPicker: IColumnPicker[]): string[] {
    let fields: any = {};
    try {
      columnPicker.forEach(column => {
        if (column.is_toggled && column.name !== '') {
          fields[column.name] = column.name;
        }
        column.child.forEach(child => {
          if (child.is_toggled) {
            fields[child.name] = child.name;
          }
        });
      });
      ColumnsManage.fields = Object.keys(fields);
    } catch (error) {
      return Object.keys(fields);
    }
    return Object.keys(fields);
  },
  //@param: apiRef = The ref object that allows grid manipulation.
  //@param: model =  The model used for sorting the grid.
  //@return: Return the index position of a column.
  getColumnIndex(apiRef: any, model: GridSortModel) {
    if (model.length !== 0) {
      return apiRef.current.getColumnIndex(model[0].field);
    }
  }
};
