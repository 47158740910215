import classNames from 'classnames';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import { useEffect, useState } from 'react';
import './AlertSingle.scss';

export function AlertSingle(props: any) {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    RestApi.getData(
      `assessments/drps/darkweb/alerts/${props.id}/${props.subAlertId ? `sub_alerts/${props.subAlertId}/` : ''}`
    ).subscribe((response: any) => {
      setData(response);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subAlertId]);

  const download = () => {
    RestApi.getData(
      `assessments/drps/darkweb/alerts/${props.id}/${
        props.subAlertId ? `sub_alerts/${props.subAlertId}/` : ''
      }download/`
    ).subscribe((response: any) => {
      window.open(response.link);
    });
  };

  return (
    <div className="AlertSingle">
      {data.title && <div className="notice">{data.title}</div>}
      {loading && <AppLoading />}
      <ul>
        {!!data.threats?.length && (
          <li>
            <h6>Threats</h6>
            <p>{data.threats.join(', ')}</p>
          </li>
        )}
        {data.published_date && (
          <li>
            <h6>Published Date</h6>
            <p>{data.published_date}</p>
          </li>
        )}
        {!!data.keywords?.length && (
          <li>
            <h6>Related Keywords</h6>
            <p>{data.related_keywords.join(', ')}</p>
          </li>
        )}
        {!!data.related_assets?.length && (
          <li>
            <h6>Related Assets</h6>
            <p>{data.related_assets.join(', ')}</p>
          </li>
        )}
        {!!data.ips?.length && (
          <li>
            <h6>IPs</h6>
            <p>{data.ips.join(', ')}</p>
          </li>
        )}
        {!!data.related_bins?.length && (
          <li>
            <h6>Related BINs</h6>
            <p>{data.related_bins.join(', ')}</p>
          </li>
        )}
        {data.source && (
          <li>
            <h6>Source</h6>
            <p>{data.source}</p>
          </li>
        )}
        {data.content && (
          <li>
            <h6>Content Highlights</h6>
            <p className="pre">{Common.highlightText(data.content)}</p>
          </li>
        )}
      </ul>
      <div className="buttons-wrapper">
        {!loading && <span onClick={props.onClose}>Close</span>}
        {!loading && (
          <button
            onClick={download}
            className={classNames('btn', 'btn-main', 'btn-big', { disabled: !data.is_downloadable })}
          >
            Download Findings
          </button>
        )}
      </div>
    </div>
  );
}
