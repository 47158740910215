import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import './dropdownHeader.scss';
interface IDropdownHeader {
  headerTitle: string;
  isListOpen: boolean;
  onChange: (params: boolean) => void;
}

export function DropdownHeader(props: IDropdownHeader) {
  //@description: Updates the parent element. Whether to hide or show
  function handleChange() {
    props.onChange(!props.isListOpen);
  }

  return (
    <div className="DH-header-wrapper" onClick={handleChange}>
      <div className="DH-header">{props.headerTitle}</div>
      <IconArrow active={props.isListOpen} toggleRotate={props.isListOpen} />
    </div>
  );
}
