import React from 'react';
export class IconConnectionPieChart extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M4.25 6.50009C4.25 6.69707 4.2888 6.89201 4.36418 7.074C4.43957 7.25599 4.55006 7.42135 4.68935 7.56064C4.82864 7.69993 4.994 7.81042 5.176 7.8858C5.35799 7.96118 5.55305 7.99997 5.75003 7.99997L10.25 7.99988C10.6478 7.99987 11.0294 7.84183 11.3107 7.56053C11.592 7.27922 11.75 6.8977 11.75 6.49988"
          stroke="#3455DD"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8.00015 8L8 10" stroke="#3455DD" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M8.35355 14.0962C8.15829 14.2915 7.84171 14.2915 7.64645 14.0962L6.23223 12.682C6.03697 12.4867 6.03697 12.1701 6.23223 11.9749L7.64645 10.5607C7.84171 10.3654 8.15829 10.3654 8.35355 10.5607L9.76777 11.9749C9.96303 12.1701 9.96303 12.4867 9.76777 12.682L8.35355 14.0962Z"
          stroke="#3455DD"
        />
        <path
          d="M4.52543 6.09619C4.33017 6.29146 4.01358 6.29146 3.81832 6.09619L2.40411 4.68198C2.20885 4.48672 2.20885 4.17014 2.40411 3.97487L3.81832 2.56066C4.01358 2.3654 4.33017 2.3654 4.52543 2.56066L5.93964 3.97487C6.1349 4.17014 6.1349 4.48672 5.93964 4.68198L4.52543 6.09619Z"
          stroke="#3455DD"
        />
        <path
          d="M12.0254 6.26771C11.8302 6.46297 11.5136 6.46297 11.3183 6.26771L9.90411 4.8535C9.70885 4.65823 9.70885 4.34165 9.90411 4.14639L11.3183 2.73218C11.5136 2.53691 11.8302 2.53691 12.0254 2.73218L13.4396 4.14639C13.6349 4.34165 13.6349 4.65823 13.4396 4.8535L12.0254 6.26771Z"
          stroke="#3455DD"
        />
      </svg>
    );
  }
}
