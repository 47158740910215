// import TOP_PANEL_PAGES from 'common/constants/topPanelPages';
import TOP_PANEL_PAGES from 'common/constants/topPanelPages';

export const topPanelConfig = {
  [TOP_PANEL_PAGES.DASHBOARD]: {
    isExpandable: false
  },
  [TOP_PANEL_PAGES.ACTION_ITEMS]: {
    isExpandable: true
  },
  [TOP_PANEL_PAGES.DISCOVERY]: {
    isExpandable: true
  },
  [TOP_PANEL_PAGES.ACTIVE_PROTECTION]: {
    isExpandable: true
  },
  [TOP_PANEL_PAGES.ASSESSMENTS]: {
    isExpandable: false
  },
  [TOP_PANEL_PAGES.SINGLE_ASSET]: {
    isExpandable: false
  }
};
