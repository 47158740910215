import { Common } from 'common/services/common.service';
import { Link } from 'react-router-dom';

export function LinkNewTab(props: any) {
  if (props.to.startsWith('http')) {
    return (
      <a href={props.to} target="_blank" rel="noreferrer" data-testid={props.testid} className={props.className}>
        {props.text}
      </a>
    );
  }

  const subAccount = Common.getParamFromUrl('account');
  const isQueryString = props.to.includes('?');

  return (
    <Link
      to={`${props.to}${
        subAccount && !props.to.includes('account=') ? `${isQueryString ? '&' : '?'}account=${subAccount}` : ''
      }`}
      onClick={e => e.stopPropagation()}
      target="_blank"
      data-testid={props.testid}
      className={props.className}
    >
      {props.text}
    </Link>
  );
}
