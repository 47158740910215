import './IconRadioButton.scss';

interface IRadioButton {
  toggle: boolean;
  radioSize: RadioSize;
  disabled?: boolean;
  isDarkMode?: boolean;
  onClick?: (value: boolean) => void;
}

type RadioSize = 'little' | 'medium' | 'big';

export function IconRadioButton(props: IRadioButton) {
  let classNameBackground: string = 'RadioButton_normal-mode';
  let classNameRadio: string = 'RadioButton_radio-toggle-normal-mode';
  checkIsDarkMode();
  function handleChange() {
    if (typeof props.onClick === 'function' && !props.disabled) {
      props.onClick(!props.toggle);
    }
  }
  //@description :Check to change the color of a component to dark
  function checkIsDarkMode() {
    if (props.isDarkMode) {
      classNameBackground = 'RadioButton_dark-mode';
      classNameRadio = 'RadioButton_radio-toggle-dark-mode';
    }
  }
  //@description : Size of the outer circle of a radio button
  function sizeBorder() {
    let border = {
      little: '1rem',
      medium: '1.2rem',
      big: '1.4rem'
    };

    return {
      width: border[props.radioSize],
      height: border[props.radioSize]
    };
  }
  //@description : Radio Button inner circle size
  function sizeCircle() {
    let circle = {
      little: '6px',
      medium: '.44rem',
      big: '8px'
    };
    return {
      width: circle[props.radioSize],
      height: circle[props.radioSize]
    };
  }

  return (
    <div style={sizeBorder()} className={`RadioButton_scope ${classNameBackground}`} onClick={handleChange}>
      {props.toggle && <div style={sizeCircle()} className={`radio-toggle ${classNameRadio}`}></div>}
    </div>
  );
}
