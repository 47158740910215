export function IconPen() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.50003 13.5H14.5" stroke="#5D6576" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3175 2.2316L13.4708 2.38496C13.7933 2.70739 13.7841 3.23291 13.4507 3.54393L13.0492 3.91838L11.7556 2.62486L12.1982 2.21211C12.5156 1.91605 13.0105 1.92466 13.3175 2.2316ZM11.0239 3.30733L2.72802 11.0447L2.0606 12.9951L4.04566 12.3158L12.3174 4.60086L11.0239 3.30733ZM11.5161 1.48082C12.2275 0.8173 13.3367 0.836611 14.0246 1.52449L14.1779 1.67786C14.9005 2.40046 14.88 3.57821 14.1327 4.27522L4.71255 13.0612C4.61958 13.1479 4.50934 13.214 4.38906 13.2552L2.19685 14.0054C1.48664 14.2484 0.807254 13.569 1.05029 12.8588L1.78975 10.698C1.83779 10.5576 1.91968 10.4312 2.02817 10.33L11.5161 1.48082Z"
        fill="currentColor"
      />
    </svg>
  );
}
