import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconAkamai extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    let color = this.props.active ? '#8796F0' : '#5D6576';
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M12.7437 19.6351C9.61226 18.6275 7.34295 15.596 7.34295 12.0264C7.34295 8.39719 9.66921 5.34854 12.8495 4.35799C13.1748 4.26406 13.0854 4.03348 12.6949 4.03348C8.47359 4.03348 5.03302 7.60301 5.03302 12.0009C5.03302 16.3987 8.44917 19.9683 12.6949 19.9683C13.0854 19.9938 13.1016 19.7632 12.7437 19.6352V19.6351ZM8.79895 13.871C8.78261 13.6574 8.76635 13.444 8.76635 13.222C8.76635 9.72075 11.4668 6.88569 14.8015 6.88569C17.9493 6.88569 18.909 8.35447 19.0066 8.26904C19.1286 8.15803 17.8598 5.23754 14.1671 5.23754C10.8323 5.23754 8.13198 8.07267 8.13198 11.5738C8.13198 12.3851 8.28649 13.1536 8.53864 13.871C8.64436 14.1784 8.81514 14.1784 8.79895 13.871ZM11.3122 9.31088C12.8657 8.59355 14.8503 8.57648 16.7861 9.27673C18.0875 9.76347 18.8358 10.4296 18.909 10.4039C19.0147 10.3526 18.1526 8.93514 16.599 8.32033C14.7201 7.58594 12.7031 7.96167 11.2309 9.1828C11.0682 9.31088 11.117 9.40481 11.3122 9.31088Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="14" height="16" fill="white" transform="translate(5 4)" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
