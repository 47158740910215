import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { Image } from 'common/components/image/Image';
import { Common } from 'common/services/common.service';
import { ToolTip } from 'common/components/toolTip/toolTip';
import './header.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';

interface IFormHeader {
  count?: number;
  label: string;
  viewArrow: boolean;
  toggleArrow?: boolean;
  description?: string;
  icon?: string | null;
  index?: number;
  source?: string;
}

export function Header(props: IFormHeader) {
  const { user } = useSelector((state: IReduxState) => state.login);

  return (
    <div className="formHeader_header-scope-border">
      <div className="formHeader_header-scope">
        <div className="formHeader_header-text">
          {props.icon && <Image className={'formHeader-icon'} alt={props.icon} type="svg" icon={props.icon} />}
          <div className="formHeader_header-label">
            {props.label}
            {/* {props.index === 0 && props.source === 'org-score-agg' && !!subsidiaries.count && <SubsidiariesDropDown />} */}
          </div>
          {props.description && (
            <span data-tip={props.description} className="description">
              ?
            </span>
          )}
          <ToolTip theme="dark" place="right" className="description-tt" />
        </div>
        {['crowdstrike'].includes(user.company) && props.label === 'Org Score Over Time' ? (
          <Link to="/pages/assessments/orgScore" className="link-to-more">
            Show Details
          </Link>
        ) : (
          <div className="formHeader_count-scope">
            <div className="formHeader_count font-number-common">
              {props.count && Common.numberWithCommas(props.count)}
            </div>
            {props.viewArrow && <IconArrow active={props.toggleArrow} />}
          </div>
        )}
      </div>
    </div>
  );
}
