import { AreaChart } from '../areaChart/AreaChart';
import { ChartLineRisk } from '../chartLineRisk/ChartLineRisk';
import { PieChart } from '../pieChart/PieChart';
import { Score } from '../score/Score';
import { Categories } from './sections/components/categories/Categories';
import { CategoriesWithIcons } from './sections/components/CategoriesWithIcons/CategoriesWithIcons';
import { Number } from './sections/components/number/Number';
import { Text } from './sections/components/text/text';
// import { FormTags } from "./components/formTags/FormTags";
export const ManageComponents = {
  //@param: data = data
  //@description: Add a form by component type
  switchComponents(data: any) {
    switch (data.type) {
      case 'categories_with_icon':
        return <CategoriesWithIcons {...data} />;
      // case "tags":
      //   return <FormTags {...data.data} />;
      case 'risk':
        return <ChartLineRisk data={data.data} count={data.value || 0} viewLegend={true} />;
      case 'graph':
        return <AreaChart {...data} />;

      case 'pie':
        return (
          <PieChart
            show_percent_label={true}
            is_black_mode={true}
            {...data}
            show_color_number={false}
            show_header_legend={false}
          />
        );

      case 'categories':
        return <Categories {...data} />;

      case 'score_arrow':
        return <Score {...data} />;

      case 'number':
        return <Number {...data} />;
      case 'text':
        return <Text {...data} />;
    }
  }
};
