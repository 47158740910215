import './Flag.scss';
import ReactCountryFlag from 'react-country-flag';
import { countriesMap } from 'common/constants/countries';
import { ToolTip } from '../toolTip/toolTip';

export function Flag(props: any) {
  if (!props.value) {
    return null;
  }

  const countryName = countriesMap[props.value.toUpperCase()];

  return (
    <div className="Flag" data-tip={countryName}>
      <span>
        <ReactCountryFlag svg countryCode={props.value} style={{ width: '1.3rem', height: '1.3rem' }} />
        {props.showCountry && <span className="value">{countryName}</span>}
      </span>
      <ToolTip theme="dark" place="left" />
    </div>
  );
}
