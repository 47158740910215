import React from 'react';
import { Text, IconRisk } from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import { IconImportance } from 'common/components/Icons/IconImportance/IconImportance';

import './actionItemsTable.scss';

interface IActionItemsTable {
  actionItems: any;
}

const ActionItemsTable: React.FC<IActionItemsTable> = ({ actionItems }) => {
  return !!actionItems?.length ? (
    <Grid container flexDirection="column" className="action-items-pdf-table">
      <Grid item sx={{ marginBottom: 2.5 }}>
        <Text textSize={13} upper weight={600}>
          summary
        </Text>
      </Grid>
      <Grid item sx={{ marginLeft: -1, fontSize: 11 }}>
        <Grid container sx={{ marginBottom: 1, marginLeft: 1 }}>
          <Grid item sx={{ width: 60 }}>
            Urgency
          </Grid>
          <Grid item sx={{ width: 85, marginRight: 2 }}>
            Type
          </Grid>
          <Grid item sx={{ width: 240, marginRight: 1.5 }}>
            Title
          </Grid>
          <Grid item sx={{ width: 240, marginRight: 1.5 }}>
            Asset
          </Grid>
          <Grid item sx={{ width: 85 }}>
            Group
          </Grid>
        </Grid>
        {actionItems.map(
          ({
            id,
            urgency,
            type,
            asset,
            asset_importance,
            title,
            groups_names
          }: {
            id: number;
            urgency: any;
            type: string;
            asset: string;
            asset_importance: any;
            title: any;
            groups_names: any;
          }) => {
            return (
              <Grid container alignItems="center" key={id} className="table-row">
                <Grid item sx={{ width: 25 }} className="icon-risk">
                  <IconRisk urgency={urgency?.type} />
                </Grid>
                <Grid item sx={{ width: 35, paddingRight: 1 }}>
                  <Text textSize={11}>{urgency?.urgency}</Text>
                </Grid>
                <Grid item className="action-item-type" sx={{ width: 85, marginRight: 1.5 }}>
                  {type}
                </Grid>
                <Grid
                  item
                  sx={{
                    width: 240,
                    marginRight: 1.5,
                    fontSize: 11,
                    padding: '5px 0'
                  }}
                >
                  <a href={`#${id}`}>{typeof title === 'string' ? title : title.label}</a>
                </Grid>
                <Grid item sx={{ width: 240, overflowWrap: 'anywhere' }}>
                  <Grid
                    container
                    className={`${!!asset_importance ? 'action-item-wrapper' : ''}`}
                    sx={{ flexWrap: 'nowrap' }}
                  >
                    {!!asset_importance && (
                      <Grid item className="action-item-importance">
                        <IconImportance type={asset_importance?.type} />
                      </Grid>
                    )}
                    <Grid item className={`${!!asset_importance ? 'action-item-host' : ''}`}>
                      {asset}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ width: 85, overflowWrap: 'anywhere' }} className="action-item-group">
                  {!!groups_names?.length ? groups_names.join(', ') : ''}
                </Grid>
              </Grid>
            );
          }
        )}
      </Grid>
    </Grid>
  ) : null;
};

export default ActionItemsTable;
