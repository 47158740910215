import { AppLoading } from 'common/components/appLoading/appLoading';
import { useEffect, useState } from 'react';
import { IntegrationHeader } from '../Integration/IntegrationHeader/IntegrationHeader';
import { TextField } from '@mui/material';
import { RestApi } from 'common/services/rest-api.service';
import { ISplunk, initData } from './config';
import { Common } from 'common/services/common.service';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { ToolTip } from 'common/components/toolTip/toolTip';
import { SECURE_INPUT_MASK, TimeIntervalOptions } from '../../config';
import { useDispatch } from 'react-redux';
import { loadList } from 'features/IntegrationsSlice';
import { DropDown, Text } from '@cyberpion/cyberpion-ui';
import { IconVisibility, IconVisibilityOff } from '../Integration/Integration';
import { showError, showSuccess } from 'features/AlertSlice';
import PopSideFotter from '../Integration/PopSideFotter/PopSideFotter';

import './Splunk.scss';
import '../../Integration.scss';

export function Splunk(props: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [data, setData] = useState<ISplunk>(initData());
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const dispatch = useDispatch<any>();
  const [errors, setErrors] = useState({
    splunk_server: false,
    hec_token: false
  });

  useEffect(() => {
    if (props.isEditMode) {
      RestApi.getData(Paths[PathNames.integrationSplunk] + props.id + '/').subscribe((response: any) => {
        if (response) {
          setData({ ...response, hec_token: SECURE_INPUT_MASK });
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditMode]);

  const setValue = (key: string, value: any) => {
    let copy: ISplunk = { ...data };
    if (key === 'action_items_push_interval') {
      copy = { ...copy, [key]: value === 0 ? 86400 : value, action_items_is_active: value !== 0 };
    } else if (key === 'unlinked_ips_push_interval') {
      copy = { ...copy, [key]: value === 0 ? 86400 : value, unlinked_ips_is_active: value !== 0 };
    } else {
      copy = { ...copy, [key]: value };
    }
    setErrors({ ...errors, [key]: false });
    setData(copy);
    setClickedSubmit(false);
  };

  const submit = () => {
    setClickedSubmit(true);
    if (!isValidated) {
      return;
    }

    setIsSubmiting(true);
    RestApi.setData(
      props.isEditMode ? Paths[PathNames.integrationSplunk] + props.id + '/' : Paths[PathNames.integrationSplunk],
      {
        ...data,
        is_active: true,
        hec_token: data.hec_token === SECURE_INPUT_MASK ? undefined : data.hec_token
      },
      props.isEditMode ? 'PATCH' : 'POST'
    ).subscribe(
      (response: any) => {
        setIsSubmiting(false);
        props.onClose();
        dispatch(loadList());
        dispatch(showSuccess('Integration Saved Successfully'));
      },
      error => {
        dispatch(showError(error));
        setIsSubmiting(false);
      }
    );
  };

  useEffect(() => {
    if (
      ((!props.isEditMode && data.hec_token.length > 32) || props.isEditMode) &&
      Common.validateURL(data?.splunk_server)
    ) {
      setIsValidated(true);
    } else {
      setIsValidated(false);
    }
  }, [data, props.isEditMode]);

  const deleteBeforeApplyOnClick = (ev: any) => {
    ev.stopPropagation();
    setShowDeletePopup(true);
  };

  const deleteAfterApplyOnClick = () => {
    setShowDeletePopup(false);
    setIsSubmiting(true);
    if (!!props.isEditMode) {
      RestApi.setData(`${Paths[PathNames.integrationSplunk]}${data.id}/`, {}, 'DELETE').subscribe(
        (response: any) => {
          dispatch(showSuccess('Deleted Successfully'));
          setIsSubmiting(false);
          dispatch(loadList());
        },
        error => {
          setIsSubmiting(false);
          dispatch(showError(error));
        }
      );
    }
    props.onClose();
  };

  const handlePopSideOnClick = (ev: any) => {
    ev.stopPropagation();
    setShowDeletePopup(false);
  };

  const checkFieldIsValidation = (field: any) => {
    switch (field) {
      // case 'configuration_name':
      //   return data.configuration_name.length > 2;
      case 'splunk_server':
        return Common.validateURL(data?.splunk_server);
      case 'hec_token':
        return data.hec_token.length > 32;
      default:
        return false;
    }
  };

  // const handleOnBlur = (type: string) => () => {
  //   const valid = data[type as keyof typeof data] === '' || checkFieldIsValidation(type);
  //   setErrors({ ...errors, [type]: !valid });
  // };

  if (loading) {
    return <AppLoading />;
  }

  return (
    <div className="Splunk integration" onClick={handlePopSideOnClick}>
      <IntegrationHeader name="Splunk" icon="splunk" />
      <div className="scrollbar-common">
        <div className="main-integration-wrapper">
          <div style={{ height: '100%', overflow: 'auto' }}>
            <div className="group">
              <Text weight={500} textSize={12} style={{ marginTop: 10 }}>
                General
              </Text>
              {!props.isEditMode ? (
                <div style={{ display: 'flex' }}>
                  <div className="text-field-wrapper" style={{ flexGrow: 1, alignItems: 'center' }}>
                    <TextField
                      fullWidth
                      label="HTTP Event Collector (HEC) Token"
                      className="input hec_token"
                      value={data.hec_token}
                      onChange={e => setValue('hec_token', e.target.value)}
                      type={!!visibility ? 'text' : 'password'}
                      onFocus={(ev: any) => ev.target.select()}
                      inputProps={{
                        autoComplete: 'new-password',
                        form: {
                          autocomplete: 'off'
                        }
                        // onBlur: handleOnBlur('hec_token')
                      }}
                      error={!!errors.hec_token || (!checkFieldIsValidation('hec_token') && clickedSubmit)}
                    />
                    {!!data.hec_token && (
                      <div className="icon-visibility" onClick={() => setVisibility(!visibility)}>
                        {!!visibility ? IconVisibilityOff : IconVisibility}
                      </div>
                    )}
                  </div>
                  {/* {true && <img src="/assets/images/info.svg" className="info" data-tip={'field.info'} alt="Info" />} */}
                </div>
              ) : (
                <div>
                  <Text textSize={12} weight={500} style={{ marginTop: 16 }}>
                    HTTP Event Collector (HEC) Token
                  </Text>
                  <Text textColor="#5D6576" family="Rubik" textSize={12} style={{ marginTop: 8 }}>
                    {data.hec_token}
                  </Text>
                </div>
              )}
              {!props.isEditMode ? (
                <div className="text-field-wrapper">
                  <TextField
                    fullWidth
                    label="Splunk Server"
                    className="input"
                    value={data.splunk_server}
                    onChange={e => setValue('splunk_server', e.target.value)}
                    onFocus={(ev: any) => ev.target.select()}
                    inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off'
                      }
                      // onBlur: handleOnBlur('splunk_server')
                    }}
                    error={!!errors.splunk_server || (!checkFieldIsValidation('splunk_server') && clickedSubmit)}
                  />
                  {/* {field.info && (
                      <img src="/assets/images/info.svg" className="info" data-tip={field.info} alt="Info" />
                    )} */}
                </div>
              ) : (
                <div>
                  <Text textSize={12} weight={500} style={{ marginTop: 16 }}>
                    Splunk Server
                  </Text>
                  <Text textColor="#5D6576" family="Rubik" textSize={12} style={{ marginTop: 8 }}>
                    {data.splunk_server}
                  </Text>
                </div>
              )}
            </div>
            <div className="group">
              <Text weight={500} textSize={12} style={{ marginTop: 10 }}>
                Data Push Frequency
              </Text>

              <div className="drop-down-freq">
                <DropDown
                  options={TimeIntervalOptions}
                  onChange={(state: number) => setValue('action_items_push_interval', state)}
                  placeholder="Action Items"
                  value={!!data.action_items_is_active ? `${data.action_items_push_interval}` : '0'}
                  error={false}
                />
                <DropDown
                  options={TimeIntervalOptions}
                  onChange={(state: number) => setValue('unlinked_ips_push_interval', state)}
                  placeholder="Unlinked IPs"
                  value={!!data.unlinked_ips_is_active ? `${data.unlinked_ips_push_interval}` : '0'}
                  error={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopSideFotter
        onClose={props.onClose}
        isValidated={true}
        onSave={submit}
        isLoading={isSubmiting}
        deleteBeforeApplyOnClick={deleteBeforeApplyOnClick}
        deleteAfterApplyOnClick={deleteAfterApplyOnClick}
        showDeletePopup={showDeletePopup}
        deleteMsgWithNotificationWarning={false}
        integrationName="Splunk"
        isMainDeleteDisabled={!data.id}
      />
      <ToolTip theme="dark" place="left" />
    </div>
  );
}
