import React, { useEffect } from 'react';
import { IChoices } from 'common/interfaces/interface';
import { IFilters } from '../interface';
import { FilterDate } from './filterDate/FilterDate';
import { FilterFreeText } from './filterFreeText/FilterFreeText';
import { FilterMultipleChoice } from './filterMultipleChoice/FilterMultipleChoice';
import { FiltersManage } from './services/FiltersManage';
import { MoreOptions } from './moreOptions/MoreOptions';
import { FilterRadioButton } from './filterRadioButton/FilterRadioButton';
import { AppDispatch } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFields,
  clearAllFilters,
  getRows,
  setfiltersResetDefault,
  setSelectFiltersHard,
  addFilter
} from 'features/GridSlice';
import { selectView, setIsEdited } from 'features/ViewsSlice';
import { GridApi, GridColDef } from '@mui/x-data-grid-pro';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { IReduxState } from 'app/store';
import { setColumnPicker } from 'features/columnPickerSlice';
import { Common } from 'common/services/common.service';

import './filtersWrapper.scss';

interface IFiltersWrapper {
  hideOrShowColumnsGrid: () => void;
  isMoreButton: boolean;
  gridRef?: GridApi;
  columns?: GridColDef[];
}

export function FiltersWrapper(props: IFiltersWrapper) {
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const [options, setOptions] = React.useState<IFilters[]>(FiltersManage.initFilterData(columnPicker));
  const [clearFilters, setClearFilters] = React.useState<boolean>(false);

  //Limit filters to 10
  const [LimitFilters, setLimitFilters] = React.useState<number>(0);
  // Added this state object to store all the active filters in a key-value dictionary
  // #TODO - change this to be the main functionality of this section instead of the current approach
  const [activeFilters, setActiveFilters] = React.useState<{ [key: string]: IFilters }>({});
  const dispatch: AppDispatch = useDispatch();
  const { filters } = useSelector((state: any) => state.grid);
  const { filtersResetDefault, selectFiltersHard } = useSelector((state: IReduxState) => state.grid);

  useEffect(() => {
    if (!!selectFiltersHard.length) {
      setOptions(FiltersManage.initFilterData(columnPicker));
      setLimitFilters(selectFiltersHard.length);

      dispatch(clearAllFilters());
      setDefaultFilter(true);
      selectFiltersHard.forEach((filter: any) => {
        dispatch(addFilter(filter));
      });
      dispatch(addFields(selectFiltersHard.map((filter: any) => Object.keys(filter)[0])));
      dispatch(getRows());
      dispatch(setSelectFiltersHard([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFiltersHard]);

  // getting the default filters and store it into the activeFilters dictionary
  const setDefaultFilter = (hardReset: boolean): void => {
    let _defaultFilter: { [key: string]: IFilters } = { ...activeFilters };
    for (const element of options) {
      if (element.is_default_filter || element.is_toggled) {
        element.sub_label = [];
        // element.is_active_filter = false;
        element.choices.forEach((choice: IChoices) => {
          if (hardReset) {
            choice.is_toggled = false;
          }
        });
        _defaultFilter[element.name] = element;
      } else if (Object.keys(filters).includes(element.name)) {
        element.is_toggled = true;
        element.is_active_filter = true;
        _defaultFilter[element.name] = element;
      } else {
        delete _defaultFilter[element.name];
      }
    }

    if (hardReset) {
      setClearFilters(false);
    } else {
      if (Object.keys(filters).length) {
        setClearFilters(true);
      }
    }

    setActiveFilters(_defaultFilter);
    // FIX: added it to fix the issue of the clear filters, should not display if reset to default
    setTimeout(() => {
      dispatch(setfiltersResetDefault(false));
    }, 500);
  };

  useEffect(() => {
    setDefaultFilter(filtersResetDefault);
    if (filtersResetDefault) {
      setClearFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, filtersResetDefault]);

  useEffect(() => {
    if (!!options.length) {
      const copy = Common.cloneData(options);
      dispatch(setColumnPicker(copy));
    }
  }, [dispatch, options]);

  function onClearFilters() {
    setOptions(FiltersManage.initFilterData(columnPicker));
    setLimitFilters(0);
    // props.setIsStatusFilter({
    //   active: false,
    //   count: 0
    // })

    dispatch(clearAllFilters());
    dispatch(getRows());
    dispatch(setIsEdited(true));
    dispatch(selectView(null));
    setDefaultFilter(true);
    setClearFilters(false);
  }

  //@param: item = item of Filter
  //@description: Update the filter data
  function onUpdateDataFilter(item: IFilters) {
    let data = [...options];
    let _activeFilters = { ...activeFilters };

    for (let index = 0; index < data.length; index++) {
      if (data[index].name === item.name) {
        data[index] = item;
        if (!item.is_default_filter && !item.is_toggled) {
          delete _activeFilters[item.name];
        }
      } else {
        data[index].toggle_dropdown = false;
        _activeFilters[item.name] = item;
      }
    }

    const _addFields = item.choices.filter((choice: any) => choice.add_fields?.length)[0];
    if (!item.is_active_filter && _addFields?.add_fields?.length && props.columns) {
      ColumnsManage.showSpecificColumns(props.gridRef, props.columns, _addFields.add_fields);
      dispatch(addFields(_addFields.add_fields));
    }

    setActiveFilters(_activeFilters);
    setOptions(data);
    isExistsActiveFilter(data);
    // dispatch(setFiltersColumnPicker(data));
  }
  //@description: Check if there is an active filter
  function isExistsActiveFilter(data: IFilters[]) {
    let count = 0;
    let isActiveFilter = false;
    for (const element of data) {
      if (element.is_toggled || element.is_default_filter) {
        count++;
      }
      if (element.is_active_filter && !filtersResetDefault) {
        isActiveFilter = true;
      }
    }

    setLimitFilters(count);
    setClearFilters(isActiveFilter);
    // props.setIsStatusFilter({
    //   active: isExistsActiveFilter,
    //   count: count,
    // });
  }

  //@param: item = item of Filter
  //@description: Replacing Dropdown by filter type
  function replaceDropdown(item: IFilters) {
    switch (item.filter_type) {
      case 'multiple_choice':
        return (
          <FilterMultipleChoice
            item={item}
            onUpdateDataFilter={onUpdateDataFilter}
            activeFilters={activeFilters}
            // onUpdatedFilterGrid={(e: any) => onUpdatedFilterGrid(e, item)}
          />
        );
      case 'date':
        return (
          <FilterDate
            item={item}
            onUpdateDataFilter={onUpdateDataFilter}
            activeFilters={activeFilters}
            // onUpdatedFilterGrid={(e: any) => onUpdatedFilterGrid(e, item)}
          />
        );
      case 'stringAndNumber':
        return (
          <FilterFreeText
            item={item}
            onUpdateDataFilter={onUpdateDataFilter}
            activeFilters={activeFilters}
            // onUpdatedFilterGrid={(e: any) => onUpdatedFilterGrid(e, item)}
          />
        );
      case 'radio':
        return (
          <FilterRadioButton
            activeFilters={activeFilters}
            item={item}
            gridRef={props.gridRef}
            columns={props.columns}
            // columnPicker={props.columnPicker}
            // hideOrShowColumnsGrid={props.hideOrShowColumnsGrid}
            onUpdateDataFilter={onUpdateDataFilter}
            // onUpdatedFilterGrid={(e: any) => onUpdatedFilterGrid(e, item)}
          />
        );
    }
  }

  const onToggleMoreOptions = (): void => {
    let data = [...options];
    for (let index = 0; index < data.length; index++) {
      data[index].toggle_dropdown = false;
    }
    setOptions(data);
  };

  const getFiltersSortedArray = () => {
    const alwaysSortFirst = 'rbac_groups';
    const filters = Object.values(activeFilters).filter(filter => filter.type !== alwaysSortFirst);
    const sortedFirstFilters = Object.values(activeFilters).filter(filter => filter.type === alwaysSortFirst);
    return Object.values([...sortedFirstFilters, ...filters]);
  };

  return (
    <div className="filtersWrapper">
      <div className="filtersWrapper-wrapper-moreOptions">
        <div className="filtersWrapper-wrapper">
          <div id={'dropdownHeader-scope'} className="filtersWrapper-wrapper">
            <div className="active-filters-wrapper">
              {activeFilters &&
                getFiltersSortedArray().map((item: IFilters, index: number) => (
                  <React.Fragment key={item.name}>
                    {(item.is_default_filter || item.is_toggled) && item.is_filterable && (
                      <div className="flex-wrapper">{replaceDropdown(item)}</div>
                    )}
                  </React.Fragment>
                ))}
              {props.isMoreButton && clearFilters && (
                <div className="filtersWrapper-clear-filters" onClick={onClearFilters}>
                  <span className="filtersWrapper-clear-filters-text">Clear Filters</span>
                  <span className="filtersWrapper-clear-filters-event">+</span>
                </div>
              )}
            </div>
            {props.isMoreButton && (
              <MoreOptions
                limit={LimitFilters}
                options={options}
                onChangeData={onUpdateDataFilter}
                onToggle={onToggleMoreOptions}
                gridRef={props.gridRef}
                columns={props.columns || []}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
