import React, { memo } from 'react';
import { Checkbox } from 'common/components/Icons/IconCheckbox/IconCheckbox';
// import Importance from 'common/components/Importance/Importance';
import { Importance } from '@cyberpion/cyberpion-ui';
import { useNotifications, NotificationsContextType } from 'pages/Settings/Notifications/NotificationsContext';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { REQUIRED_FILTERS } from 'pages/Settings/Notifications/SidePanel/utils';

interface ICheckboxImportance {
  id: string;
}

const CheckboxImportance: React.FC<ICheckboxImportance> = ({ id }) => {
  const { notificationType } = useNotifications() as NotificationsContextType;
  const {
    notificationData,
    onConfigurationChange
  } = useNotificationConfiguration() as NotificationConfigurationContextType;

  const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
  const data = requiredFields.includes(id) ? notificationData : notificationData.filters;
  const areFilters = !!data && !!data[id];

  const handleOnChange = (selectedOption: any) => () => {
    const updatedOptions = data[id].choices.map((option: any) => {
      if (option.name === selectedOption.name) {
        return {
          ...option,
          is_toggled: !option.is_toggled
        };
      }
      return option;
    });
    const toggledData = updatedOptions.filter((option: any) => !!option.is_toggled).map((item: any) => item.name);
    onConfigurationChange(id, toggledData);
  };

  return (
    <div className="dropdown-box-checkbox">
      {!!areFilters &&
        data[id].choices.map((option: any) => {
          return (
            <div
              key={option.name}
              className="checkbox-importance"
              onClick={handleOnChange(option)}
              data-testid={`dropdown-checkbox-importance-${id}-${option.name}`}
            >
              <Checkbox active={option.is_toggled} />
              <Importance value={{ type: option.label }} />
            </div>
          );
        })}
    </div>
  );
};

export default memo(CheckboxImportance);
