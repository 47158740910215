import React from 'react';
import IconEmptyState from './IconEmptyState';
import Grid from '@mui/material/Grid';
import { Text } from '@cyberpion/cyberpion-ui';
import { Button } from 'common/components/Button/Button';

interface IEmptyState {
  onAdd: () => void;
}
const EmptyState: React.FC<IEmptyState> = ({ onAdd }) => {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ height: '100%' }} spacing={1}>
      <Grid item>
        <IconEmptyState />
      </Grid>
      <Grid item>
        <Text textSize={20}>No Notifications Set</Text>
      </Grid>
      <Grid item>
        <Text>Click the 'Add Notification' button to add your first notification rule</Text>
      </Grid>
      <Grid item className="empty-state-button" sx={{ marginTop: 1 }}>
        <Button type={'button'} onClick={onAdd} text="Add Notification" buttonStyle="main" size="small" />
      </Grid>
    </Grid>
  );
};

export default EmptyState;
