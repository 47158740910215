import React from 'react';
import ThreatsReport from './pages/ThreatsReport';
import { ThreatsReportProvider } from 'common/components/GenerateThreatsReport/ThreatsReportContext';

import './GenerateThreatsReport.scss';

const GenerateThreatsReport = () => {
  return (
    <ThreatsReportProvider>
      <ThreatsReport />
    </ThreatsReportProvider>
  );
};

export default GenerateThreatsReport;
