import './ForgotPasswordForm.scss';
import { InputField } from 'common/components/InputField/InputField';
import { Button } from 'common/components/Button/Button';
import * as EmailValidator from 'email-validator';
import classNames from 'classnames';
import { useLogin } from 'login/hooks/useLogin';
import { useEffect, useState } from 'react';

export function ForgotPasswordForm(props: any) {
  const { isLoading, isForgotPasswordValidEmail, forgotPasswordValidateEmail } = useLogin();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const validateEmail = (e: any): void => {
    e.preventDefault();
    if (email && EmailValidator.validate(email)) {
      forgotPasswordValidateEmail(email);
    } else {
      setEmailError('Invalid Email');
    }
  };

  const onChangeInput = (value: string) => {
    setEmail(value);
    setEmailError('');
    setIsButtonDisabled(!EmailValidator.validate(value));
  };

  useEffect(() => {
    if (props.visible) {
      setIsVisible(true);
    }
  }, [props.visible]);

  return (
    <form className={classNames('ForgotPasswordForm', { visible: isVisible })} onSubmit={(e: any) => validateEmail(e)}>
      {!isForgotPasswordValidEmail ? (
        <div>
          <h6>Reset Password</h6>
          <p>Enter your account email address and we'll send you a link to reset your password.</p>
          <InputField type="email" placeholder="Email" onChange={onChangeInput} error={emailError} />
          <Button
            size="big"
            disabled={isButtonDisabled}
            loading={isLoading}
            text="Submit"
            buttonStyle="main"
            type="submit"
            onClick={(e: any) => validateEmail(e)}
          />
        </div>
      ) : (
        <h6>Authentication Email sent</h6>
      )}
    </form>
  );
}
