import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconConnectionType extends React.Component {
  public props: IIcon;
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" fill="none">
        <path
          d="M11.7505 9.49991C11.7505 9.30293 11.7117 9.10799 11.6363 8.926C11.5609 8.74401 11.4504 8.57865 11.3111 8.43936C11.1718 8.30007 11.0065 8.18958 10.8245 8.1142C10.6425 8.03882 10.4474 8.00003 10.2505 8.00003L5.75046 8.00012C5.35264 8.00013 4.97112 8.15817 4.68982 8.43947C4.40852 8.72078 4.25049 9.1023 4.25049 9.50012"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8.00034 8L8.00049 6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M7.64694 1.90381C7.8422 1.70854 8.15878 1.70854 8.35404 1.90381L9.76826 3.31802C9.96352 3.51328 9.96352 3.82986 9.76826 4.02513L8.35404 5.43934C8.15878 5.6346 7.8422 5.6346 7.64693 5.43934L6.23272 4.02513C6.03746 3.82986 6.03746 3.51328 6.23272 3.31802L7.64694 1.90381Z"
          stroke="white"
        />
        <path
          d="M11.4751 9.90381C11.6703 9.70854 11.9869 9.70854 12.1822 9.90381L13.5964 11.318C13.7916 11.5133 13.7916 11.8299 13.5964 12.0251L12.1822 13.4393C11.9869 13.6346 11.6703 13.6346 11.4751 13.4393L10.0608 12.0251C9.86558 11.8299 9.86558 11.5133 10.0608 11.318L11.4751 9.90381Z"
          stroke="white"
        />
        <path
          d="M3.97506 9.73229C4.17032 9.53703 4.4869 9.53703 4.68217 9.73229L6.09638 11.1465C6.29164 11.3418 6.29164 11.6583 6.09638 11.8536L4.68217 13.2678C4.4869 13.4631 4.17032 13.4631 3.97506 13.2678L2.56085 11.8536C2.36558 11.6583 2.36558 11.3418 2.56085 11.1465L3.97506 9.73229Z"
          stroke="white"
        />
      </svg>
    );
  }
}
