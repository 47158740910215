import { ConfidenceLevel, DiscoveryEvidenceDiagram, IconArrow } from '@cyberpion/cyberpion-ui';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { EvidenceData } from './EvidenceData/EvidenceData';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { Image } from 'common/components/image/Image';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { addDownloadFileData, setNewDownloads } from 'features/DownloadsSlice';

import './tabDiscoveryEvidence.scss';

interface ITabDiscoveryEvidenceProps {
  asset: string;
}

export function TabDiscoveryEvidence({ asset }: ITabDiscoveryEvidenceProps) {
  const [activeCategory, setActiveCategory] = useState<any>(null);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    RestApi.getData(`discovery/org-assets/${asset}/evidence/summary/`).subscribe((response: any) => {
      setData({
        ...response,
        categories: response.categories.sort((a: any, b: any) => {
          if (b.confidence && a.confidence) {
            return b.confidence.id - a.confidence.id || b.count - a.count;
          } else {
            return b.count - a.count;
          }
        })
      });
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScroll = () => {
    if (!collapsed) {
      setCollapsed(true);
    }
  };

  const handleCsvDownloadOnClick = async () => {
    const response = await RestApi.simpleAsyncGet(`discovery/org-assets/${asset}/evidence/?limit=1000&format=csv`);
    dispatch(addDownloadFileData(response));
    dispatch(setNewDownloads(response?.id));
  };

  return (
    <div className="TabDiscoveryEvidence">
      <div className="TabDiscoveryEvidenceInner">
        <div className={classNames('diagram-wrapper', { loading: loading })}>
          {loading && <AppLoading />}
          <div
            className={classNames('diagram-background', { hide: collapsed })}
            style={{ backgroundImage: `url('/assets/images/pixel.svg')` }}
          ></div>
          <div className={classNames('diagram-gradient', { hide: collapsed })}></div>
          <header>
            <div>
              {!!data.source && (
                <div>
                  <span>Asset Source: </span>
                  {data.source}
                </div>
              )}
              {!!data.derived_from && (
                <div>
                  <span>Derived From: </span>
                  {data.derived_from}
                </div>
              )}
              {!!collapsed && !!data.overall_confidence && (
                <div className="confidence-level">
                  <span>Discovery Confidence Level: </span>
                  <ConfidenceLevel label={data.overall_confidence.label} size="small" />
                </div>
              )}
            </div>
            {!loading && (
              <Tooltip
                title="Download CSV"
                placement="bottom-end"
                arrow={true}
                classes={{ tooltip: 'download-csv-tooltip' }}
              >
                <div className="download-csv-link" onClick={handleCsvDownloadOnClick}>
                  <Image className="gridUtilities-csv" alt="CSV" icon={'csv'} type="svg" />
                </div>
              </Tooltip>
            )}
          </header>
          {!!Object.keys(data).length && (
            <div className="content-switch">
              {!!isDataLoaded && (
                <div className="expand" onClick={() => setCollapsed(!collapsed)}>
                  <IconArrow isOpen={!collapsed} />
                </div>
              )}
              <div className={classNames('expanded', { show: !collapsed })}>
                <DiscoveryEvidenceDiagram
                  data={data}
                  collapsed={false}
                  onClick={(category: string) => {
                    setCollapsed(true);
                    setActiveCategory(category);
                  }}
                  activeCategory={activeCategory}
                />
              </div>
              <div className={classNames('collapsed', { show: collapsed })}>
                <div className="scrollbar-common">
                  <DiscoveryEvidenceDiagram
                    data={data}
                    onClick={(category: string) => {
                      setActiveCategory(category);
                    }}
                    activeCategory={activeCategory}
                    collapsed
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {!!data.categories && (
          <EvidenceData
            asset={asset}
            activeCategory={activeCategory}
            categories={data.categories.filter((cat: any) => cat.count)}
            onScroll={onScroll}
            onload={() => setIsDataLoaded(true)}
          />
        )}
      </div>
    </div>
  );
}
