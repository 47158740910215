import { useState, useEffect } from 'react';
import { useTopPanelContext } from '../../TopPanelContext';
import { Text, DataPanel, IconRisk, LineChart, LINE_CHART_TYPE } from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import { texts, textProps, dataPanelProps } from '../TopPanelDiscovery';
import { getTechIcon, iconByID } from 'common/components/TopPanel/hooks/useTopPanel';
import { Common } from 'common/services/common.service';
import useFilters from 'common/components/TopPanel/hooks/useFilters';

const DiscoveryInternalExpand = () => {
  const [sliceAmount, setSliceAmount] = useState<number | null>(null);
  const { data } = useTopPanelContext();
  const { handleFiltersOnClick } = useFilters();

  useEffect(() => {
    let amount;

    if (window.screen.width < 1600) {
      amount = 7;
    } else if (window.screen.width < 1800) {
      amount = 9;
    } else if (window.screen.width < 2000) {
      amount = 11;
    } else {
      amount = 12;
    }
    setSliceAmount(amount);
  }, []);

  const renderOtherFQDN = () => {
    const other = data.assets_by_service?.data.find((item: any) => item.id === 'other');
    return !!other ? (
      <Grid item key={`${other.id}`} className={`fqdn-item`}>
        <Grid container direction="column">
          <Grid item className="top-panel-tech-icon">
            {iconByID[other.id as keyof typeof iconByID]}
          </Grid>
          <Grid item className="fqdn-data-panel">
            <DataPanel id={`${other.id}`} {...dataPanelProps} title={other.label}>
              {other.value}
            </DataPanel>
          </Grid>
        </Grid>
      </Grid>
    ) : null;
  };

  return (
    <Grid container className="top-panel-discovery-internal-expand-wrapper" sx={{ paddingLeft: 2.5 }}>
      <Grid item>
        <Grid container direction="column" sx={{ paddingTop: 2.5 }}>
          <Grid item className="panel-title">
            <Text textColor="#FFFFFF" textSize={13} upper>
              Active org assets
            </Text>
          </Grid>
          <Grid item>
            <Grid container className="org-assets-wrapper" style={{ flexWrap: 'nowrap' }}>
              {['domain', 'subdomain', 'ip'].map((item: any, index: number) => {
                // const key = Object.keys(item)[0];
                return (
                  <Grid item key={item} className="data-panel-item" sx={index !== 2 ? { paddingRight: 2 } : {}}>
                    <Grid container direction="column">
                      <Grid item className="top-panel-tech-icon">
                        {iconByID[item as keyof typeof iconByID]}
                      </Grid>
                      <Grid item>
                        <DataPanel
                          id={item}
                          {...dataPanelProps}
                          // title={texts[item as keyof typeof texts]}
                          title={Common.underscoreToTitle(item)}
                          // isFiltered={filters.includes(item)}
                          filterOnClick={id => {
                            handleFiltersOnClick(
                              'type',
                              Common.underscoreToTitle(id === 'subdomain' ? 'sub-domain' : id),
                              'discovery-internal'
                            );
                          }}
                        >
                          {!!data.active_org_assets ? data.active_org_assets[item] : ''}
                        </DataPanel>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div className="vertical-devider"></div>
      </Grid>
      <Grid item className="distribution-by-urgency" sx={{ paddingTop: 2.5 }}>
        <Grid container direction="column" className="panel-wrapper">
          <Grid item className="panel-title">
            <Text textColor="#FFFFFF" textSize={13} style={{ paddingLeft: 5 }} upper>
              Risk distribution
            </Text>
          </Grid>
          <Grid item>
            <Grid container className="horizontal-border data-panel-wrapper" style={{ flexWrap: 'nowrap' }}>
              {['critical', 'high', 'medium', 'low', 'no_risk'].map((item: string) => {
                return (
                  <Grid item key={item} className="urgency-wrapper">
                    <Grid container direction="column">
                      <Grid item className="urgency-icon">
                        <IconRisk urgency={item} />
                      </Grid>
                      <Grid item>
                        <DataPanel
                          id={item}
                          {...dataPanelProps}
                          // titleStyle={{ whiteSpace: 'nowrap', display: 'flex' }}
                          // title={texts[item as keyof typeof texts]}
                          title={Common.underscoreToTitle(item, false)}
                          // isFiltered={filters.includes(item)}
                          filterOnClick={id => {
                            handleFiltersOnClick('risk_score', Common.underscoreToTitle(id), 'discovery-internal');
                          }}
                        >
                          {!!data.risk_distribution ? data.risk_distribution[item] : ''}
                        </DataPanel>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div className="vertical-devider"></div>
      </Grid>
      <Grid item className="fqdn-wrapper" sx={{ paddingTop: 2.5 }}>
        <Grid container direction="column" className="panel-wrapper">
          <Grid item className="panel-title">
            <Text textColor="#FFFFFF" textSize={13} style={{ paddingLeft: 5 }}>
              FQDNs HOSTING PROVIDERS
            </Text>
          </Grid>
          <Grid item>
            <Grid container className="fqdn-icons" style={{ flexWrap: 'nowrap', overflow: 'hidden' }}>
              {data.assets_by_service?.data
                .filter((item: any) => item.id !== 'other')
                .slice(0, sliceAmount)
                .map((item: any, index: number) => {
                  return (
                    <Grid item key={`${item.id}-${index}`} className="fqdn-item">
                      <Grid container direction="column">
                        <Grid item className="top-panel-tech-icon">
                          {getTechIcon(item.id)}
                        </Grid>
                        <Grid item className="fqdn-data-panel">
                          <DataPanel
                            id={item.id}
                            {...dataPanelProps}
                            title={item.label}
                            isTooltip
                            filterOnClick={id => {
                              handleFiltersOnClick('hosting_provider', id, 'discovery-internal');
                            }}
                          >
                            {item.value}
                          </DataPanel>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              {renderOtherFQDN()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DiscoveryInternalCollapse = () => {
  const { data } = useTopPanelContext();
  const { active_org_assets, assets_by_service, risk_distribution } = data;
  // const { bar } = risk_distibution;
  // const { count: fqdnCount } = fqdn;
  const totalOrgAssets: any = Object.values(active_org_assets).reduce((acc: any, all: any) => acc + all, 0);
  const totalFQDN: any = assets_by_service.data.reduce((all: number, item: any) => item.value + all, 0);

  return (
    <Grid container className="top-panel-discovery-internal-collapse-wrapper">
      <Grid item className="org-assets-title" style={{ paddingLeft: 16 }}>
        <Text {...textProps} upper>
          org assets
        </Text>
      </Grid>
      <Grid item className="org-assets-value">
        <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
          {totalOrgAssets}
        </Text>
      </Grid>
      {Object.entries(active_org_assets).map(([key, value]) => {
        // const key = Object.keys(item)[0];
        return (
          <>
            <Grid key={`${key}-title`} item className="org-assets-title" style={{ paddingLeft: 16 }}>
              <Text {...textProps} upper={false}>
                {texts[key as keyof typeof texts]}
              </Text>
            </Grid>
            <Grid key={`${key}-value`} item className="org-assets-value">
              <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
                {value}
              </Text>
            </Grid>
          </>
        );
      })}
      <div className="vertical-devider-collapse"></div>
      <Grid item className="org-assets-title">
        <Text {...textProps} upper>
          {texts.risk_distribution}
        </Text>
      </Grid>
      <Grid item className="urgency-line" sx={{ flex: 1 }}>
        <LineChart
          lineChartData={risk_distribution}
          backgroundColor="#2c2c30"
          height={5}
          lineType={LINE_CHART_TYPE.URGENCY}
        />
      </Grid>
      <div className="vertical-devider-collapse"></div>
      <Grid item className="org-assets-title">
        <Text {...textProps}>FQDNs HOSTING PROVIDERS</Text>
      </Grid>
      <Grid item className="org-assets-value" sx={{ paddingRight: 2.5 }}>
        <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
          {totalFQDN}
        </Text>
      </Grid>
    </Grid>
  );
};

export { DiscoveryInternalExpand, DiscoveryInternalCollapse };
