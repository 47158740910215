import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { Common } from 'common/services/common.service';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as EmailValidator from 'email-validator';
import { WhoisOptionsDropdown } from './WhoisOptionsDropdown/WhoisOptionsDropdown';
import { addWhois, removeWhois } from 'features/GroupsSlice';
import classNames from 'classnames';
import GroupSearch from 'pages/Settings/FilterGroups/FilterGroupsActions/CreateGroupPopUp/GroupSearch/GroupSearch';

import './WhoisActions.scss';

export function WhoisActions(props: any) {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [actionsMenuOpen, setActionsMenuOpen] = useState<boolean>(false);
  const [whoisOption, setWhoisOption] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [whoisError, setWhoisError] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setActionsMenuOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onRemove = () => {
    props.ids.forEach((id: string) => {
      dispatch(removeWhois(id));
    });
  };

  const validate = (): boolean => {
    let hasError = false;

    if (whoisOption === 'Name-Server') {
      if (!Common.validateDomainName(input)) {
        setError(true);
        hasError = true;
      }
    }

    if (whoisOption === 'Organization') {
      if (input.length < 2) {
        setError(true);
        hasError = true;
      }
    }

    if (whoisOption === 'Email') {
      if (!EmailValidator.validate(input)) {
        setError(true);
        hasError = true;
      }
    }

    if (!whoisOption) {
      setWhoisError(true);
      hasError = true;
    }

    if (!input) {
      setError(true);
      hasError = true;
    }

    return !hasError;
  };

  const onAdd = () => {
    if (!validate()) {
      return;
    }
    dispatch(addWhois({ whois: input, type: whoisOption }));
    setInput('');
  };

  const onChange = (val: string) => {
    setInput(val);
    setError(false);
  };

  return (
    <div className="WhoisActions" ref={ref}>
      <div className="popup-header-actions-content">
        <GroupSearch />
        <div className="whois-actions-right">
          <WhoisOptionsDropdown onChange={setWhoisOption} error={whoisError} />
          <input
            className={classNames({ error: error })}
            type="text"
            value={input}
            onChange={e => onChange(e.target.value)}
            placeholder="Add Whois"
          />
          <button className={`btn btn-main`} onClick={onAdd}>
            Add Whois
          </button>
          <span className={`actions-trigger ${actionsMenuOpen ? 'open' : ''}`}>
            <div className="trigger-wrapper" onClick={() => setActionsMenuOpen(!actionsMenuOpen)}>
              Actions
              <IconArrow />
            </div>
            <ul>
              <li className={!props.ids.length ? 'disabled' : ''} onClick={onRemove}>
                Remove
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  );
}
