import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { PopUp } from 'common/components/popUp/PopUp';
import { setDeletePromptParams } from 'features/GridSlice';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AddCustomPortsPopUp } from './AddCustomPortsPopUp/AddCustomPortsPopUp';
import './CustomPortsActions.scss';

export function CustomPortsActions(props: any) {
  const dispatch = useDispatch<any>();
  const ref = useRef<HTMLDivElement>(null);
  const [actionsMenuOpen, setActionsMenuOpen] = useState<boolean>(false);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setActionsMenuOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onRemove = () => {
    dispatch(
      setDeletePromptParams({
        category: 'general',
        tab: 'custom-ports',
        index: null,
        ids: props.ids,
        message: 'Are you sure you would like to remove the following ports?'
      })
    );
  };

  return (
    <div className="ManageAssetsActions" ref={ref}>
      <button className={`btn btn-main`} onClick={() => setPopupVisible(true)}>
        Add Custom Ports
      </button>
      <span className={`actions-trigger ${actionsMenuOpen ? 'open' : ''}`}>
        <div className="trigger-wrapper" onClick={() => setActionsMenuOpen(!actionsMenuOpen)}>
          Actions
          <IconArrow />
        </div>
        <ul>
          <li className={!props.ids.length ? 'disabled' : ''} onClick={onRemove}>
            Remove
          </li>
        </ul>
      </span>
      <PopUp visible={popupVisible} setVisible={() => setPopupVisible(false)}>
        <AddCustomPortsPopUp onClose={() => setPopupVisible(false)} />
      </PopUp>
    </div>
  );
}
