import { useEffect, useRef, useState } from 'react';
import './EvidenceData.scss';
import { DiscoveryEvidenceDataFlow } from '@cyberpion/cyberpion-ui';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';

interface IEvidenceDataProps {
  activeCategory: any;
  categories: [];
  asset: string;
  onScroll: () => void;
  onload: () => void;
}

export function EvidenceData({ activeCategory, categories, asset, onScroll, onload }: IEvidenceDataProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const contentRef: any = useRef();
  const { user } = useSelector((state: IReduxState) => state.login);

  useEffect(() => {
    if (activeCategory) {
      document.getElementById(`category_${activeCategory.category.id}`)?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  useEffect(() => {
    RestApi.getData(`discovery/org-assets/${asset}/evidence/?limit=1000`).subscribe((response: any) => {
      const data = categories
        .sort((a: any, b: any) => {
          return b.confidence.id - a.confidence.id || b.count - a.count;
        })
        .map((category: any) => {
          return { ...category, data: response.results.filter((row: any) => row.category === category.category.label) };
        });
      setData(data);
      setLoading(false);
      onload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !Object.keys(user).length) {
    return (
      <div className="EvidenceData">
        <AppLoading />
      </div>
    );
  }

  return (
    <div className="EvidenceData">
      <div className="content-header">
        <span>
          <img src="/assets/images/asset-type.svg" alt="Asset" />
          ASSET
        </span>
        <div className="line"></div>
        <span>
          <img src="/assets/images/reference.svg" alt="Reference" />
          REFERENCE
        </span>
      </div>
      {/* <EvidenceCategoryLabel category={currentDisplayedCategory} collapsed /> */}
      <div className="scrollbar-common">
        <div className="content-area">
          <div style={{ height: '100%', overflow: 'auto' }} ref={contentRef} onScroll={() => onScroll()}>
            {data.map((d: any) => {
              return (
                <DiscoveryEvidenceDataFlow data={d} activeCategory={activeCategory} companyName={user.company_name} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
