import { AppLoading } from 'common/components/appLoading/appLoading';
import { PopupHeader } from 'common/components/PopupHeader/PopupHeader';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import { RestApi } from 'common/services/rest-api.service';
import { useState } from 'react';
import './WhiteListEdit.scss';

export function WhiteListEdit(props: any) {
  const [input, setInput] = useState<string>(props.email.split('@')[1]);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = () => {
    setLoading(true);
    RestApi.setData(
      Paths[PathNames.domainRestriction],
      [
        {
          domain: input,
          is_allowed: true
        }
      ],
      'POST'
    ).subscribe(
      (response: any) => {
        setLoading(false);
        props.onSubmit();
        // props.onClose()
      },
      error => {
        setLoading(false);
      }
    );
  };

  return (
    <div className="WhiteListEdit">
      <PopupHeader text="Domain Not Whitelisted" onClose={props.onClose} />
      <div className="popup-inner">
        {loading && <AppLoading />}
        <div>
          <div>The domain you are trying to add has an email domain that was not whitelisted.</div>
          <div>
            <input type="text" value={input} onChange={e => setInput(e.target.value)} />
            <button className="btn btn-main btn-big" onClick={onSubmit}>
              Add
            </button>
            <button className="btn btn-secondary btn-big" onClick={props.onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
