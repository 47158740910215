import './appLoading.scss';

import Lottie from 'lottie-react-web';
import animation from './lottie-new.json';
import classNames from 'classnames';

export function AppLoading(props: any) {
  const defaultOptions = {
    animationData: animation,
    loop: true
  };
  return (
    <div
      id="AppLoading"
      className={classNames(props.className, { 'full-screen': props.fullscreen, transparent: props.transparent })}
    >
      {/* <Spinner /> */}
      <Lottie options={defaultOptions} height={props.size === 'big' ? '120px' : '80px'} />
      {props.text && <div>{props.text}</div>}
    </div>
  );
}
