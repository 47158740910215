import { Text, getPDFFormatedDate } from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import { selectCompanyDisplayName } from 'features/LoginSlice';
import { useSelector } from 'react-redux';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';

const PageHeader = () => {
  const { displayCompany, globalFilter } = useThreatsReport() as ThreatsReportContextType;
  const date = getPDFFormatedDate();
  const companyName = useSelector(selectCompanyDisplayName);

  return (
    <Grid container alignItems="center">
      <Grid item flex={1}>
        {!!displayCompany ? (
          <div style={{ height: 25, width: 85 }} />
        ) : (
          <img src="/assets/images/logo-ionix-black.svg" alt="ionix-logo-threat-PDF" width={85} />
        )}
      </Grid>
      <Grid item>
        <Text textColor="#222231" textSize={11}>{`${!!displayCompany ? displayCompany : companyName}${
          !!globalFilter ? ', ' + globalFilter : ''
        }, ${date}`}</Text>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
