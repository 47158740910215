// interface IPages {
// 	[key: string]: string;
// }

const TOP_PANEL_PAGES = {
  DASHBOARD: 'dashboard',
  ACTION_ITEMS: 'actionItems',
  DISCOVERY: 'discovery',
  ACTIVE_PROTECTION: 'activeProtection',
  ASSESSMENTS: 'assessments',
  SINGLE_ASSET: 'singleAsset'
};

export default TOP_PANEL_PAGES;
