// @ts-nocheck
import React from 'react';
import {
  Text,
  IconDiscovery,
  IconCertificate,
  IconCTI,
  IconAssesments,
  IconActiveProtection,
  IconActionItems,
  getColorByUrgency
} from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';

import './RadarLegend.scss';

const ICONS = {
  active_protection: {
    icon: <IconActiveProtection color="#3455dd" />,
    label: 'Active Protection'
  },
  cti: {
    icon: <IconCTI color="#3455dd" />,
    label: 'Cyber Threat Intelligence'
  },
  assessment: {
    icon: <IconAssesments color="#3455dd" />,
    label: 'Assesment'
  },
  action_items: {
    icon: <IconActionItems color="#3455dd" />,
    label: 'Action Item'
  },
  certificates: {
    icon: <IconCertificate color="#3455dd" />,
    label: 'Certificates'
  },
  discovery: {
    icon: <IconDiscovery color="#3455dd" />,
    label: 'Discovery'
  }
};

// const SEVERITIES = ['critical', 'high', 'medium', 'low', 'informational'];

const RadarLegend = () => {
  return (
    <Grid
      container
      sx={{ padding: '30px 0 30px 30px', borderTop: '1px solid #dfe1e4' }}
      className="RadarLegendPDF"
      // justifyContent="space-between"
    >
      <Grid item marginRight={9}>
        <Grid container flexDirection="column">
          <Grid item>
            <Text textColor="#6D6D7A" weight={500} textSize={13}>
              Type
            </Text>
          </Grid>
          <Grid item sx={{ paddingTop: 1 }}>
            <Grid container>
              <Grid item>
                <Grid container flexDirection="column">
                  {['assessment', 'discovery', 'action_items'].map((item: any) => (
                    <Grid item key={item}>
                      <Grid container alignItems="center" spacing={1.5} sx={{ paddingTop: 1 }}>
                        <Grid item>{ICONS[item].icon}</Grid>
                        <Grid item>
                          <Text textColor="#6D6D7A">{ICONS[item].label}</Text>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item sx={{ paddingLeft: 5 }}>
                <Grid container flexDirection="column">
                  {['certificates', 'cti', 'active_protection'].map((item: any) => (
                    <Grid item key={item}>
                      <Grid container alignItems="center" spacing={1.5} sx={{ paddingTop: 1 }}>
                        <Grid item>{ICONS[item].icon}</Grid>
                        <Grid item>
                          <Text textColor="#6D6D7A">{ICONS[item].label}</Text>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item marginRight={9}>
        <Grid container flexDirection="column">
          <Grid item>
            <Text textColor="#6D6D7A" weight={500} textSize={13}>
              Severity
            </Text>
          </Grid>
          <Grid item sx={{ paddingTop: 1 }}>
            <Grid container>
              <Grid item>
                <Grid container flexDirection="column" sx={{ paddingTop: 1.5 }}>
                  {['Critical', 'High', 'Medium'].map((item: any, index: number) => (
                    <Grid item key={item}>
                      <Grid container alignItems="center" sx={index !== 2 ? { paddingBottom: 2.25 } : {}}>
                        <Grid
                          item
                          className="circle"
                          style={{ backgroundColor: getColorByUrgency(item.toLowerCase()) }}
                        />
                        <Grid item>
                          <Text>{item}</Text>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item sx={{ paddingLeft: 3 }}>
                <Grid container flexDirection="column" sx={{ paddingTop: 1.5 }}>
                  {['Low', 'Informational'].map((item: any, index: number) => (
                    <Grid item key={item}>
                      <Grid container alignItems="center" sx={index !== 2 ? { paddingBottom: 2.25 } : {}}>
                        <Grid
                          item
                          className="circle"
                          style={{ backgroundColor: getColorByUrgency(item.toLowerCase()) }}
                        />
                        <Grid item>
                          <Text>{item}</Text>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ paddingRight: 3 }}>
        <Grid container flexDirection="column">
          <Grid item>
            <Text textColor="#6D6D7A" weight={500} textSize={13}>
              Shapes
            </Text>
          </Grid>
          <Grid item sx={{ paddingTop: 2 }}>
            <Grid container alignItems="center">
              <Grid item className="circle empty"></Grid>
              <Grid item>
                <Text>General Highlight</Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ paddingTop: 2 }}>
            <Grid container alignItems="center">
              <Grid item className="circle number-of-findings">
                <Text textColor="#737373" textSize={10}>
                  11
                </Text>
              </Grid>
              <Grid item>
                <Text>Number of Findings</Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RadarLegend;
