import { GradeRenderer } from '../gradeRenderer/GradeRenderer';
import './AssesmentsLinkRenderer.scss';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';

const CATEGORIES_MAP = [
  'web_risk_grade',
  'certificates_risk_grade',
  'dns_risk_grade',
  'cloud_risk_grade',
  'network_risk_grade',
  'tls_risk_grade',
  'email_risk_grade'
];

export function AssesmentsLinkRenderer(props: any) {
  const getFilter = () => {
    return JSON.stringify({ category__in: CATEGORIES_MAP.indexOf(props.field).toString() });
  };

  if (CATEGORIES_MAP.indexOf(props.field) < 0) {
    return <GradeRenderer {...props} />;
  }

  return (
    <LinkNewTab
      to={`/pages/singleAsset/${props.source.tab}/${props.row.asset}/tests?filters=${encodeURI(getFilter())}`}
      text={<GradeRenderer {...props} />}
    />
  );
}
