// import React, { useState, useEffect } from 'react';
import ExposureRadar from 'common/components/GenerateThreatsReport/pages/ExposureRadar/ExposureRadar';
import Highlights from 'common/components/GenerateThreatsReport/pages//Highlights/Highlights';
import LastPage from 'common/components/GenerateThreatsReport/pages/LastPage/LastPage';
import CoverPage from 'common/components/GenerateThreatsReport/pages/CoverPage/CoverPage';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';
import { AppLoading } from 'common/components/appLoading/appLoading';
import Examples from 'common/components/GenerateThreatsReport/pages//Examples/Examples';
import OrgScore from './OrgScore/OrgScore';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ThreatsReport = () => {
  const { highlightsList, orgScoreData, isLoading, PAGE_WIDTH } = useThreatsReport() as ThreatsReportContextType;
  const flags = useFlags();

  const isOrgScoreEnabled = (): boolean => {
    if (!!flags.isRiskScoreInExposureReport && !!orgScoreData && !!Object.keys(orgScoreData).length) {
      return true;
    }

    return false;
  };

  return isLoading ? (
    <AppLoading />
  ) : (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: PAGE_WIDTH,
          backgroundColor: '#ffffff'
        }}
      >
        <CoverPage />
        <ExposureRadar />
        {!!highlightsList.length && <Highlights />}
        {!!highlightsList.length && <Examples />}
        {isOrgScoreEnabled() && <OrgScore />}
        <LastPage />
      </div>
    </div>
  );
};

export default ThreatsReport;
