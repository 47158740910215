import classNames from 'classnames';
import './MainInputs.scss';
import { InputWithLabel } from 'common/components/InputWithLabel/InputWithLabel';

export function MainInputs(props: any) {
  const getDomainInputs = () => {
    let html: JSX.Element[] = [];
    // for (let i = 0; i < props.data.main.domains.length; i++) {
    html.push(
      <div className="input-wrapper">
        <div className="divider"></div>
        <InputWithLabel
          value={props.data.main_domain.value}
          className={classNames({
            error: props.data.main_domain.error,
            filled: !!props.data.main_domain.value
          })}
          onChange={(e: any) => {
            const copy = { ...props.data };
            copy.main_domain.error = false;
            copy.main_domain.value = e.target.value;
            props.onChange(copy);
          }}
          label="Domain name (e.g. company.com)"
        />
      </div>
    );
    // }
    return html;
  };

  return (
    <div className="MainInputs">
      <div className="inputs-group">
        <div className="headline">
          <h5>Main Info</h5>
        </div>
        <div>
          <InputWithLabel
            value={props.data.display_name.value}
            className={classNames({
              error: props.data.display_name.error,
              filled: !!props.data.display_name.value
            })}
            onChange={(event: any) => {
              const copy = { ...props.data };
              copy.display_name.error = false;
              copy.display_name.value = event.target.value;
              props.onChange(copy);
            }}
            label="Organization name (full legal name)"
          />
          <div className="inputs-wrapper">
            {getDomainInputs()}
            {/* <div className="input-wrapper">
              <div className="divider"></div>
              <div
                className="add"
                onClick={() =>
                  props.onChange({
                    ...props.data,
                    main: { ...props.data.main, domains: [...props.data.main.domains, initInputObject()] }
                  })
                }
              >
                +
              </div>
            </div> */}
          </div>
        </div>
        <div className="inputs-group">
          <div>
            <InputWithLabel
              value={props.data.linked_in.value}
              className={classNames({
                error: props.data.linked_in.error,
                filled: !!props.data.linked_in.value
              })}
              onChange={(event: any) => {
                const copy = { ...props.data };
                copy.linked_in.error = false;
                copy.linked_in.value = event.target.value;
                props.onChange(copy);
              }}
              label="Linkedin account link (optional)"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
