import { initInputObject } from 'common/components/AddAccount/AddAccount';
import './AdditionalInputs.scss';
import classNames from 'classnames';
import { InputWithLabel } from 'common/components/InputWithLabel/InputWithLabel';

export function AdditionalInputs(props: any) {
  const addDomain = () => {
    const copy = { ...props.data };
    copy.additional_domains.push(initInputObject());
    props.onChange(copy);
  };

  const addName = () => {
    const copy = { ...props.data };
    copy.additional_names.push(initInputObject());
    props.onChange(copy);
  };

  const getAdditionalNames = () => {
    let html = [];
    for (let i = 0; i < props.data.additional_names.length; i++) {
      html.push(
        <div className="input-wrapper">
          <InputWithLabel
            deleteIcon={!!i}
            onDelete={() => {
              const copy = { ...props.data };
              copy.additional_names.splice(i, 1);
              props.onChange(copy);
            }}
            value={props.data.additional_names[i].value}
            className={classNames({
              error: props.data.additional_names[i].error,
              filled: !!props.data.additional_names[i].value
            })}
            onChange={(e: any) => {
              const copy = { ...props.data };
              copy.additional_names[i].error = false;
              copy.additional_names[i].value = e.target.value;
              props.onChange(copy);
            }}
            label="Additional main organization name"
          />
          <div className="line"></div>
          {i === props.data.additional_names.length - 1 && (
            <div className="add" onClick={addName}>
              +
            </div>
          )}
        </div>
      );
    }
    return html;
  };

  const getAdditionalDomains = () => {
    let html = [];
    for (let i = 0; i < props.data.additional_domains.length; i++) {
      html.push(
        <div className="input-wrapper">
          <InputWithLabel
            deleteIcon={!!i}
            onDelete={() => {
              const copy = { ...props.data };
              copy.additional_domains.splice(i, 1);
              props.onChange(copy);
            }}
            value={props.data.additional_domains[i].value}
            className={classNames({
              error: props.data.additional_domains[i].error,
              filled: !!props.data.additional_domains[i].value
            })}
            onChange={(e: any) => {
              const copy = { ...props.data };
              copy.additional_domains[i].error = false;
              copy.additional_domains[i].value = e.target.value;
              props.onChange(copy);
            }}
            label="Additional main organization domain"
          />
          <div className="line"></div>
          {i === props.data.additional_domains.length - 1 && (
            <div className="add" onClick={addDomain}>
              +
            </div>
          )}
        </div>
      );
    }

    return html;
  };

  return (
    <div className="AdditionalInputs">
      <div className="inputs-group">
        <div className="headline">
          <h5>Additional main organization info (optional)</h5>
        </div>
        {props.displayInfo && (
          <div className="info">
            <div>
              Please insert additional information regarding the main organization. Exclude the organization’s
              subsidiaries and brands.
            </div>
          </div>
        )}
        <div className="inputs-wrapper">{getAdditionalNames()}</div>
        <div className="inputs-wrapper">{getAdditionalDomains()}</div>
      </div>
    </div>
  );
}
