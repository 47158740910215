import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { Input } from 'common/components/Input/Input';
import { IResponsePaginatedData } from 'common/interfaces/interface';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './mapCard.scss';
export function MapCard(props: any) {
  const [nextPath, setNextPath] = React.useState<undefined | null | string>(null);
  const [items, setItems] = React.useState<any>([]);
  const [search, setSearch] = React.useState<string>('');

  useEffect(() => {
    setItems(props.data.results);
    setNextPath(props.data.next);
  }, [props.data]);

  //@description: Sends request for more data
  function handleOnRowsScrollEnd() {
    if (nextPath != null) {
      let path = Common.splitV1FromPath(nextPath);
      RestApi.getPaginatedData(path).subscribe((response: IResponsePaginatedData) => {
        setItems([...items, ...response.results]);
        setNextPath(response.next);
      });
    }
  }
  function handleSearch(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      const path = `dashboard/geomap/${props.countryCode}/${props.typeHost}/?search=${search}`;
      RestApi.getPaginatedData(path).subscribe((response: IResponsePaginatedData) => {
        setItems(response.results);
        setNextPath(response.next);
      });
    }
  }

  return (
    <div className="mapCard-scope">
      <div className="mapCard-header-scope">
        <div className="mapCard-header-label-scope">
          <div
            className=""
            onClick={() => {
              props.setShowCard(!props.showCard);
            }}
          >
            <IconArrow
              color="#E8E8E8"
              className="mapCard-iconArrow"
              active={props.showCard}
              toggleRotate={props.showCard}
            />
          </div>

          <div className="mapCard-header-label">{props.country}</div>
        </div>

        <div className="mapCard-header-count">{props.data.length}</div>
      </div>
      <div className="mapCard-input-search-scope">
        <Input
          className="mapCard-input-search"
          icon={'search'}
          type={'text'}
          value={search}
          onKeyDown={handleSearch}
          onChange={e => setSearch(e)}
          placeholder="Search"
        />
      </div>

      <div className="scrollbar-mapCard mapCard-body">
        <div id="assignee-div" style={{ height: '100%', overflow: 'auto' }}>
          <InfiniteScroll
            dataLength={items.length}
            next={handleOnRowsScrollEnd}
            hasMore={true}
            loader={<h4>{nextPath ? 'Loading...' : ''} </h4>}
            scrollableTarget="assignee-div"
          >
            {items.map((item: string[], key: number) => (
              <div className="mapCard-row-scope" key={key}>
                <div className="">{item}</div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
