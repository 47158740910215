import React from 'react';
import Link from '@mui/material/Link';
import { Text } from '@cyberpion/cyberpion-ui';
import Grid from '@mui/material/Grid';

interface ContactProps {
  textColor?: string;
  // style?: React.CSSProperties;
}
const Contact: React.FC<ContactProps> = ({ textColor }) => {
  return (
    <Grid container alignItems="center">
      <Grid item>
        <Text textSize={10} textColor={textColor}>
          Any questions? Contact us at
        </Text>
      </Grid>
      <Link
        sx={{ color: textColor ? textColor : '', fontSize: 10, fontFamily: 'Rubik' }}
        style={{ paddingLeft: 5, paddingBottom: 0 }}
        underline="none"
        variant="body2"
        href={'mailto:support@ionix.io'}
      >
        support@ionix.io
      </Link>
    </Grid>
  );
};

export default Contact;
