import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { Common } from 'common/services/common.service';
import { addMobileApp, removeMobileApp } from 'features/GroupsSlice';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import GroupSearch from 'pages/Settings/FilterGroups/FilterGroupsActions/CreateGroupPopUp/GroupSearch/GroupSearch';

import './mobile-apps-actions.scss';

export function MobileAppsActions(props: any) {
  const dispatch = useDispatch<any>();
  const ref = useRef<HTMLDivElement>(null);
  const [actionsMenuOpen, setActionsMenuOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setActionsMenuOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onRemove = () => {
    props.ids.forEach((id: string) => {
      dispatch(removeMobileApp(id));
    });
  };

  const onAdd = () => {
    if (!Common.validateMobileApp(input)) {
      setError(true);
      return;
    }
    dispatch(addMobileApp({ value: input }));
    setInput('');
  };

  const onChange = (val: string) => {
    setInput(val);
    setError(false);
  };

  return (
    <div className="mobile-app-actions" ref={ref}>
      <div className="popup-header-actions-content">
        <GroupSearch />
        <div>
          <input
            maxLength={256}
            className={classNames({ error: error })}
            type="text"
            value={input}
            onChange={e => onChange(e.target.value)}
            placeholder="Add Mobile Apps"
          />
          <button className={`btn btn-main`} onClick={onAdd}>
            Add Mobile App
          </button>
          <span className={`actions-trigger ${actionsMenuOpen ? 'open' : ''}`}>
            <div className="trigger-wrapper" onClick={() => setActionsMenuOpen(!actionsMenuOpen)}>
              Actions
              <IconArrow />
            </div>
            <ul>
              <li className={!props.ids.length ? 'disabled' : ''} onClick={onRemove}>
                Remove
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  );
}
