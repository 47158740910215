// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const getErrorMsg = (error: any) => {
  if (typeof error === 'string') return error;

  let _error = JSON.parse(error.message);
  if (Array.isArray(_error)) {
    _error = _error[0];
  }
  if (_error.non_field_errors && _error.non_field_errors[0].includes('unique set')) {
    return 'The value you are trying to add is already exists';
  }
  if (!!_error.details && _error.error_code !== undefined) {
    return `Error: ${_error.details} (#${_error.error_code})`;
  }
  _error = _error[Object.keys(_error)[0]];
  if (Array.isArray(_error[0])) {
    _error = _error[0][0];
  } else if (!Array.isArray(_error) && typeof _error === 'object' && _error[0] !== null) {
    _error = Object.values(_error)[0][0];
  }
  return `Error: ${_error}`;
};

const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    variant: '',
    title: '',
    text: '',
    visible: false,
    delay: 6000
  },
  reducers: {
    showError(state, action: any) {
      state.variant = 'error';
      const msg = getErrorMsg(action.payload);
      state.text = msg || 'Something Went Wrong';
      state.visible = true;
    },
    showSuccess(state, action) {
      state.variant = 'success';
      state.text = action.payload;
      state.visible = true;
    },
    showCustomSuccess(state, action) {
      state.variant = 'success';
      state.title = action.payload.title;
      state.text = action.payload.text;
      state.visible = true;
    },
    hideAlert(state) {
      state.visible = false;
    },
    setDelay(state, action) {
      state.delay = action.payload;
    },
    resetDelay(state) {
      state.delay = 6000;
    },
    resetTitle(state) {
      state.title = '';
    }
  }
});

const { actions, reducer } = alertSlice;
export const { showError, showSuccess, hideAlert, resetDelay, setDelay, showCustomSuccess, resetTitle } = actions;
export default reducer;
