import React from 'react';
import { Grid } from '@mui/material';
import { IconRadioButton } from 'common/components/Icons/IconRadioButton/IconRadioButton';
import TextField from '@mui/material/TextField';
import { useNotifications, NotificationsContextType } from 'pages/Settings/Notifications/NotificationsContext';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { makeStyles } from '@material-ui/core/styles';
import { REQUIRED_FILTERS } from 'pages/Settings/Notifications/SidePanel/utils';
import { Text } from '@cyberpion/cyberpion-ui';

const useStyles = makeStyles({
  textRoot: {
    width: '100%'
  }
});
interface IStringsListFilter {
  id: string;
}

const StringsListFilter: React.FC<IStringsListFilter> = ({ id }) => {
  const { notificationType } = useNotifications() as NotificationsContextType;
  const {
    notificationData,
    onConfigurationChange
  } = useNotificationConfiguration() as NotificationConfigurationContextType;
  const classes = useStyles();

  const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
  const data = requiredFields.includes(id) ? notificationData : notificationData.filters;
  const areFilters = !!data && !!data[id];
  const options = areFilters && data[id].choices.filter((f: any) => !!f.is_toggled || !!f.value);

  const handleTextOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (data[id].type === 'array_string' && value !== '' && !value.match(/^[,A-Za-z0-9]+$/)) {
      return;
    }
    if (value.startsWith(',') || value.endsWith(',,')) {
      return;
    }

    // onConfigurationChange(id, event.target.value);
    onConfigurationChange(id, {
      text: event.target.value.trim() !== '' ? event.target.value.trim() : '',
      option: !!options.length && !!options[0].is_toggled ? options[0] : null
    });
  };

  const handleRadioOnChange = (selectedOption: any) => () => {
    onConfigurationChange(id, {
      text: !!options.length ? options[0].value : '',
      option: selectedOption
    });
  };

  return areFilters ? (
    <Grid container direction="column" className="visible">
      <Grid item>
        <div style={{ backgroundColor: '#F6F8FF' }}>
          <Text style={{ paddingTop: 10, paddingLeft: 16, paddingBottom: 10 }} textColor="#5D6576">
            Texts are separated by commas
          </Text>
        </div>
      </Grid>
      <Grid item sx={{ padding: '10px 14px', width: '100%' }}>
        <TextField
          classes={{ root: classes.textRoot }}
          value={!!options.length ? options[0].value : ''}
          placeholder="Please enter text"
          onChange={handleTextOnChange}
          inputProps={{ 'data-testid': `dropdownbox-StringsListFilter-input-${id}` }}
        />
      </Grid>
      {data[id].choices.map((option: any) => {
        return (
          <Grid
            item
            key={option.name}
            onClick={handleRadioOnChange(option)}
            className="dropdownbox-item"
            data-testid={`dropdownbox-textFilter-option-${option.name}`}
          >
            <Grid container alignItems="center">
              <Grid item>
                <IconRadioButton radioSize="little" toggle={option.is_toggled} />
              </Grid>
              <Grid item sx={{ paddingLeft: 1 }}>
                {option.label}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  ) : null;
};

export default StringsListFilter;
