import { IFilters } from 'common/components/grid/components/gridUtilities/interface';
import { IChoices, IColumnPicker } from 'common/interfaces/interface';
import { Common } from 'common/services/common.service';
import {
  FILTER_DATE,
  FILTER_STRING,
  FILTER_NUMBER,
  FILTER_ARRAY_STRING,
  FILTER_IP,
  FILTER_ARRAY_NUMBER
} from '../config';
export const FiltersManage = {
  //@param: list = list of Column Picker
  //@description: Creates the data to component a filter
  initFilterData(list: IColumnPicker[]): IFilters[] {
    list = Common.cloneData(list);
    let options: IFilters[] = [];
    list.forEach((item: IColumnPicker) => {
      if (!item.is_group) {
        options.push(FiltersManage.itemFilter(item));
      } else {
        item.child.forEach((item: IColumnPicker) => {
          options.push(FiltersManage.itemFilter(item));
        });
      }
    });
    return options;
  },

  itemFilter(item: IFilters) {
    FiltersManage.addFilters(item);
    return {
      ...item,
      sub_label: [],
      is_toggled: false,
      clear_selection: false,
      is_active_filter: false,
      toggle_dropdown: false
    };
  },

  //@description: Adding filters by filter type
  addFilters(item: IFilters) {
    if (item.choices_type === 'multi_choice') {
      item.filter_type = 'multiple_choice';
    } else if (['date', 'datetime'].includes(item.type)) {
      item.filter_type = 'date';
      item.choices = Common.cloneData(FILTER_DATE);
    } else if (item.choices_type === 'radio' || item.type === 'radio') {
      item.filter_type = 'radio';
    } else if (item.choices.length === 0) {
      if (item.type === 'string') {
        item.filter_type = 'stringAndNumber';
        item.choices = Common.cloneData(FILTER_STRING);
      } else if (item.type === 'ip') {
        item.filter_type = 'stringAndNumber';
        item.choices = Common.cloneData(FILTER_IP);
      } else if (item.type === 'array_string' || item.type === 'rbac_groups' || item.type === 'count_info_panel') {
        item.filter_type = 'stringAndNumber';
        item.choices = Common.cloneData(FILTER_ARRAY_STRING);
      } else if (item.type === 'array_number') {
        item.filter_type = 'stringAndNumber';
        item.choices = Common.cloneData(FILTER_ARRAY_NUMBER);
      } else if (item.type === 'spinner' || item.type === 'string_with_highlight') {
        item.filter_type = 'stringAndNumber';
        item.choices = Common.cloneData(FILTER_STRING);
      } else {
        item.filter_type = 'stringAndNumber';
        item.choices = Common.cloneData(FILTER_NUMBER);
      }
    }
  },
  //@description: Filter mapping
  //@return An array of labels display in the title of a drop-down menu And an array of names (filter parameter) for BE
  mapMultipleChoiceFilter(data: IChoices[]) {
    let labels: string[] = [];
    let names: string[] = [];
    data
      .filter((param: IChoices) => param.is_toggled)
      .forEach((item: IChoices) => {
        labels.push(item.label);
        names.push(item.name === null ? Common.getEmptyValue() : item.name);
      });
    return { labels, names };
  },
  //@description: Check if an element is a child of a parent dropdownHeader
  contains(child: any) {
    let parent: any = document.querySelector('#dropdownHeader-scope');
    return parent === child || parent.contains(child);
  }
};
