import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import { tosterService } from 'common/services/tostar.service';
import { getRows, setDeletePromptParams } from 'features/GridSlice';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import './IpRestrictionsActions.scss';

export function IpRestrictionsActions(props: any) {
  const dispatch = useDispatch<any>();
  const ref = useRef<HTMLDivElement>(null);
  const [actionsMenuOpen, setActionsMenuOpen] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setActionsMenuOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onRemove = () => {
    dispatch(
      setDeletePromptParams({
        category: 'permissions',
        tab: 'ip-restrictions',
        index: null,
        ids: props.ids,
        message: "Are you sure you would like to remove the following IP's?"
      })
    );
  };

  const onAdd = () => {
    if (!Common.validateIp(input)) {
      setError(true);
      return;
    }
    setSubmitting(true);
    RestApi.setData(
      'settings/permissions/ip-restrictions/',
      [
        {
          ip: input
        }
      ],
      'POST'
    ).subscribe(
      (response: any) => {
        setInput('');
        setSubmitting(false);
        dispatch(getRows());
        tosterService.onShowToster('Added Successfully', 'success', '');
      },
      error => {
        setSubmitting(false);
        tosterService.showError(error);
      }
    );
  };

  const onChange = (val: string) => {
    setInput(val);
    setError(false);
  };

  return (
    <div className="IpRestrictionsActions" ref={ref}>
      <input
        className={classNames({ error: error })}
        type="text"
        value={input}
        onChange={e => onChange(e.target.value)}
        placeholder="Add Ip Restriction"
      />
      <button className={`btn btn-main ${submitting ? 'submitting' : ''}`} onClick={onAdd}>
        Add Ip Restriction
      </button>
      <span className={`actions-trigger ${actionsMenuOpen ? 'open' : ''}`}>
        <div className="trigger-wrapper" onClick={() => setActionsMenuOpen(!actionsMenuOpen)}>
          Actions
          <IconArrow />
        </div>
        <ul>
          <li className={!props.ids.length ? 'disabled' : ''} onClick={onRemove}>
            Remove
          </li>
        </ul>
      </span>
    </div>
  );
}
