import React from 'react';
import * as d3 from 'd3';
import './map.scss';
import { COUNTRIES, DATA_MAP } from './config';
import { MapCard } from './mapCard/MapCard';
import { RestApi } from 'common/services/rest-api.service';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { IconGeoMap } from 'common/components/Icons/IconGeoMap/IconGeoMap';
import { IconExpand } from '../Icons/IconExpand/IconExpand';
import { IconReduce } from '../Icons/IconReduce/IconReduce';
import { MapLegend } from './mapLegend/MapLegend';
export class Map extends React.Component<any> {
  public state: any;
  public props: any;
  public svgRef: any;
  public projection: any;
  public path: any;
  public container: any;
  public svg: any;
  constructor(props: any) {
    super(props);
    this.svgRef = React.createRef();

    this.state = {
      showCard: false,
      dataCard: [],
      country: '',
      toggleExpand: false
    };
  }

  componentDidMount() {
    this.projection = d3.geoEqualEarth();
    this.path = d3.geoPath(this.projection);
    this.container = d3.select('.map-dashboard');
    this.svg = this.container.append('g');
    this.drawMap();
    this.drawCircles();
  }

  drawMap() {
    const data = DATA_MAP;

    this.svg.append('g');

    this.svg
      .selectAll('path')
      .data(data.features)
      .enter()
      .append('path')
      .attr('d', this.path)
      .attr('class', 'map-country');

    this.initZoomMap();
  }

  initZoomMap() {
    let zoomed = (event: any) => {
      const transform = event.transform;
      this.svg.attr('transform', `translate(${transform.x},${transform.y})scale(${transform.k})`);
    };

    let zoom = d3
      .zoom()
      .on('zoom', zoomed)
      .scaleExtent([1, 50]);

    this.container.call(zoom);

    d3.select('.zoomin').on('click', () => {
      zoom.scaleBy(this.container.transition().duration(750), 3);
    });

    d3.select('.zoomout').on('click', () => {
      zoom.scaleBy(this.container.transition().duration(750), 0.4);
    });
  }

  drawCircles() {
    let g = this.svg
      .selectAll('g.countr-name')
      .data(this.props.data)
      .enter()
      .append('g');
    this.pointerMap(g.append('g'), 'org', '#4B7AE7');
    this.pointerMap(g.append('g'), 'external', '#EF8068', 1);
  }

  pointerMap(element: any, key: any, color: any, test: number = 0) {
    let radius = 3;
    element
      .classed('countr-name', true)
      .attr('class', `group-${key}`)
      .attr('cursor', 'pointer')
      .on('click', (event: any, data: any) => {
        this.getDataCountry(data, key);
      })
      .on('mouseover', (event: any, data: any) => {
        d3.select(`#text-circle-${data.id}-${key}`)
          .transition()
          .duration(500)
          .attr('font-size', '5px')
          .attr('visibility', 'visible');
        d3.select(`#circle-${data.id}-${key}`)
          .transition()
          .duration(500)
          .attr('r', radius * 3);
      })
      .on('mouseout', (event: any, data: any) => {
        d3.select(`#text-circle-${data.id}-${key}`)
          .transition()
          .duration(500)
          .attr('font-size', '1.4px')
          .attr('visibility', 'hidden');
        d3.select(`#circle-${data.id}-${key}`)
          .transition()
          .duration(500)
          .attr('r', radius);
      });

    element
      .filter((d: any) => d[key] !== 0 && COUNTRIES[d.id.toUpperCase()])
      .append('circle')
      .attr('id', (event: any) => `circle-${event.id}-${key}`)
      .attr('fill', color)
      .attr('fill-opacity', '0.4')
      .attr('stroke-width', '0.2px')
      .attr('stroke', color)
      .attr('r', radius)
      .attr('cx', (d: any) => {
        return this.projection([
          COUNTRIES[d.id.toUpperCase()].longitude + test,
          COUNTRIES[d.id.toUpperCase()].latitude + test
        ])[0];
      })
      .attr('cy', (d: any) => {
        return this.projection([
          COUNTRIES[d.id.toUpperCase()].longitude + test,
          COUNTRIES[d.id.toUpperCase()].latitude + test
        ])[1];
      });

    element
      .filter((d: any) => d[key] !== 0 && COUNTRIES[d.id.toUpperCase()])
      .append('text')
      .attr('x', (d: any) => {
        return this.projection([
          COUNTRIES[d.id.toUpperCase()].longitude + test,
          COUNTRIES[d.id.toUpperCase()].latitude + test
        ])[0];
      })
      .attr('y', (d: any) => {
        return this.projection([
          COUNTRIES[d.id.toUpperCase()].longitude + test,
          COUNTRIES[d.id.toUpperCase()].latitude + test
        ])[1];
      })
      .attr('id', (event: any) => `text-circle-${event.id}-${key}`)
      .attr('text-anchor', 'middle')
      .attr('font-size', '1.4px')
      .attr('visibility', 'hidden')
      .attr('dy', '.3em')
      .text((event: any) => {
        return event[key];
      });
  }

  getDataCountry(data: any, type: string) {
    if (this.state.toggleExpand) {
      let country = COUNTRIES[data.id.toUpperCase()].country;
      let path = `${Paths[PathNames.geomap]}${data.id}/${type}/?limit=100&offset=0`;
      let object = { ...this.state };
      RestApi.getPaginatedData(path).subscribe((response: any) => {
        this.setState({
          ...object,
          showCard: true,
          dataCard: response,
          country: country,
          countryCode: data.id,
          typeHost: type
        });
      });
    }
  }

  expandOrReduce() {
    let object = { ...this.state };
    this.setState({
      ...object,
      toggleExpand: !object.toggleExpand
    });
    if (this.state.toggleExpand) {
      this.setState({
        ...object,
        toggleExpand: !object.toggleExpand,
        showCard: false
      });
    }
  }

  setShowCard(toggle: boolean) {
    let object = { ...this.state };
    this.setState({
      ...object,
      showCard: toggle
    });
  }

  render() {
    return this.props.data ? (
      <>
        <div
          className={`map-background ${this.state.toggleExpand ? 'visible' : ''}`}
          onClick={this.expandOrReduce.bind(this)}
        ></div>
        <div className={`map-scope ${this.state.toggleExpand ? 'map-reduce' : 'map-expand'}`}>
          <div className="map-header-scope">
            <div className="map-lable-scope">
              <IconGeoMap />
              <div className="map-lable">Geo Map</div>
            </div>
            <div className="map-event-scope">
              <div className="map-zoom zoomout">-</div>
              <div className="map-zoom zoomin">+</div>
              <div className="map-zoom" onClick={this.expandOrReduce.bind(this)}>
                {this.state.toggleExpand ? <IconExpand /> : <IconReduce />}
              </div>
            </div>
          </div>
          <svg
            width="100%"
            height={this.state.toggleExpand ? '100%' : '200'}
            ref={this.svgRef}
            className="map-dashboard"
            viewBox="0 -60 950 550"
          ></svg>
          <MapLegend />
          {this.state.showCard && (
            <MapCard
              showCard={this.state.showCard}
              setShowCard={(e: boolean) => this.setShowCard(e)}
              data={this.state.dataCard}
              countryCode={this.state.countryCode}
              country={this.state.country}
              typeHost={this.state.typeHost}
            />
          )}
        </div>
      </>
    ) : (
      <div className="map-scope map-expand display-center">No Data</div>
    );
  }
}
