import './Integration.scss';
import { useEffect, useState } from 'react';
import { CustomSwitch } from 'common/components/CustomSwitch/CustomSwitch';
import { Button } from 'common/components/Button/Button';
import { PopSide } from 'common/components/PopSide/PopSide';
import { RestApi } from 'common/services/rest-api.service';
import { Sentinel } from '../Sentinel/Sentinel';
import { Splunk } from '../Splunk/Splunk';
import Jira from '../Jira/Jira';
import { useDispatch, useSelector } from 'react-redux';
import ServiceNow from '../ServiceNow/ServiceNow';
import { NotAvailableIntegration } from '../NotAvailableIntegration/NotAvailableIntegration';
import { showError, showSuccess } from 'features/AlertSlice';
import { loadList, resetIntegrationPopup, setIntegrationDeleted } from 'features/IntegrationsSlice';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';
import classNames from 'classnames';
import { Text } from '@cyberpion/cyberpion-ui';

export const IconVisibilityOff = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_10657_27629)">
      <path
        d="M4.94 7.06001C5.07732 7.20739 5.24293 7.32559 5.42692 7.40757C5.61092 7.48956 5.80955 7.53364 6.01095 7.5372C6.21236 7.54075 6.41242 7.5037 6.5992 7.42826C6.78597 7.35282 6.95564 7.24053 7.09808 7.09809C7.24051 6.95565 7.3528 6.78598 7.42825 6.59921C7.50369 6.41243 7.54074 6.21237 7.53718 6.01097C7.53363 5.80956 7.48955 5.61094 7.40756 5.42694C7.32558 5.24294 7.20737 5.07734 7.06 4.94001M3.03 8.97001C3.8847 9.62151 4.92545 9.98244 6 10C9.5 10 11.5 6.00001 11.5 6.00001C10.8781 4.84096 10.0154 3.82832 8.97 3.03001L3.03 8.97001ZM7.05 2.12001C6.70583 2.03945 6.35347 1.99918 6 2.00001C2.5 2.00001 0.5 6.00001 0.5 6.00001C0.80351 6.56782 1.16547 7.10238 1.58 7.59501L7.05 2.12001Z"
        stroke="#5D6576"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M11.5 0.5L0.5 11.5" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_10657_27629">
        <rect width="12" height="12" fill="white" transform="matrix(-1 0 0 1 12 0)" />
      </clipPath>
    </defs>
  </svg>
);

export const IconVisibility = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.499939 6C0.499939 6 2.5 2.5 6 2.5C9.5 2.5 11.4999 6 11.4999 6C11.4999 6 9.5 9.5 6 9.5C2.5 9.5 0.499939 6 0.499939 6Z"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.99994 7.49994C6.82837 7.49994 7.49994 6.82837 7.49994 5.99994C7.49994 5.17151 6.82837 4.49994 5.99994 4.49994C5.17151 4.49994 4.49994 5.17151 4.49994 5.99994C4.49994 6.82837 5.17151 7.49994 5.99994 7.49994Z"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const iconTrash = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M2.625 3.75L3.30432 10.2035C3.3445 10.5852 3.66638 10.875 4.0502 10.875H7.9498C8.33362 10.875 8.6555 10.5852 8.69568 10.2035L9.375 3.75"
      stroke="#5D6576"
      stroke-linecap="round"
    />
    <path d="M4.875 5.625V8.625" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.125 5.625V8.625" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.375 2.875H10.125C10.4011 2.875 10.625 3.09886 10.625 3.375C10.625 3.65114 10.4011 3.875 10.125 3.875H1.875C1.59886 3.875 1.375 3.65114 1.375 3.375C1.375 3.09886 1.59886 2.875 1.875 2.875H3.625L3.625 1.875C3.625 1.18464 4.18464 0.625 4.875 0.625H7.125C7.81536 0.625 8.375 1.18464 8.375 1.875V2.875ZM7.375 2.875V1.875C7.375 1.73693 7.26307 1.625 7.125 1.625L4.875 1.625C4.73693 1.625 4.625 1.73693 4.625 1.875L4.625 2.875H7.375Z"
      fill="#5D6576"
    />
  </svg>
);

export function Integration(props: any) {
  const [isActive, setIsActive] = useState<boolean>(!!props.isActive);
  const [integration, setIntegration] = useState<string>('');
  const { categoryPopup, integrationDeleted, list } = useSelector((state: any) => state.integrations);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    setIsActive(!!props.isActive);
  }, [props.isActive]);

  useEffect(() => {
    if (!!categoryPopup.length && props.data.component.toLowerCase() === categoryPopup.toLowerCase()) {
      setIntegration(props.data.component);
      dispatch(resetIntegrationPopup());
    }
  }, [categoryPopup, props.data.component, dispatch]);

  const showPopSide = () => {
    setIntegration(props.data.component);
  };

  const onClose = () => {
    if (integrationDeleted) {
      dispatch(setIntegrationDeleted(false));
      dispatch(loadList());
    }
    setIntegration('');
  };

  const onHide = () => {
    if (integrationDeleted) {
      dispatch(setIntegrationDeleted(false));
      dispatch(loadList());
    }
    setIntegration('');
  };

  function getComponent(component: string) {
    const components: any = {
      Jira: Jira,
      Splunk: Splunk,
      ServiceNow: ServiceNow,
      Sentinel: Sentinel,
      NotAvailableIntegration: NotAvailableIntegration
    };
    const Component = components[component];
    return <Component id={props.id} onClose={onClose} isEditMode={props.isExists} metadata={props.data} />;
  }

  const onChange = (e: React.ChangeEvent, isChecked: boolean) => {
    setIsActive(isChecked);
    RestApi.setData(`${props.data.apiPath}${props.id}/`, { is_active: !!isChecked }, 'PATCH').subscribe(
      (response: any) => {
        dispatch(showSuccess(`Integration ${isChecked ? 'Activated' : 'Deactivated'} Successfully`));
        dispatch(loadList());
      },
      error => {
        dispatch(showError(error));
        setIsActive(!isChecked);
      }
    );
  };

  const count = list.results?.filter((item: any) => item.type === props.data.type).length || 0;
  const countActive = list.results?.filter((item: any) => item.type === props.data.type && item.is_active).length || 0;

  return (
    <li className={classNames('Integration', { inactive: props.isExists && props.showToggle && !isActive })}>
      <div className="integration-box-top">
        <div>
          <img src={`/assets/images/Integrations/${props.icon}.svg`} alt={props.data.label} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Text textSize={13} weight={500}>
            {props.data.label}
          </Text>
          {!!count && (
            <Text textSize={12} textColor="#8B909B" weight={400} style={{ paddingTop: 2 }}>
              {`Active ${countActive}/${count}`}
            </Text>
          )}
        </div>
        {props.isExists && props.showToggle && (
          <div style={{ alignSelf: 'flex-start' }}>
            <CustomSwitch checked={isActive} onChange={onChange} />
          </div>
        )}
      </div>
      <div className="integration-subtitle">{props.data.text}</div>
      <div className="button-wrapper">
        {props.showConnect && (
          <Button
            buttonStyle="secondary"
            type="button"
            size="small"
            onClick={showPopSide}
            text={props.isExists ? 'Edit' : 'Connect'}
          />
        )}
        {!!props.data.guideUrl && <LinkNewTab to={props.data.guideUrl} text={'User Guide'} />}
      </div>
      {!!integration && <PopSide onHide={onHide}>{getComponent(integration)}</PopSide>}
    </li>
  );
}
