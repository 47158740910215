import React, { useState, memo } from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useNotifications, NotificationsContextType } from 'pages/Settings/Notifications/NotificationsContext';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { REQUIRED_FILTERS } from 'pages/Settings/Notifications/SidePanel/utils';
import * as EmailValidator from 'email-validator';
import classNames from 'classnames';
import { Text } from '@cyberpion/cyberpion-ui';

const useStyles = makeStyles({
  options: {
    fontSize: 12,
    fontFamily: 'Rubik'
  },
  deleteIcon: {
    display: 'none'
  }
});

interface IInputChips {
  id: string;
  placeholder?: string;
}
const AutoCompleteWithChips: React.FC<IInputChips> = ({ id, placeholder }) => {
  const { notificationType } = useNotifications() as NotificationsContextType;
  const {
    notificationData,
    onConfigurationChange
  } = useNotificationConfiguration() as NotificationConfigurationContextType;
  const [isValidMail, setIsValidMail] = useState(true);
  const classes = useStyles();

  const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
  const data = requiredFields.includes(id) ? notificationData : notificationData.filters;
  const selected = !!data && !!data[id] && !!data[id].length ? data[id] : [];

  const handleOnChange = (e: Event, id: string, value: any) => {
    if (isValidMail) {
      onConfigurationChange(id, value);
    } else {
      setIsValidMail(true);
    }
  };

  return (
    <>
      <div style={{ backgroundColor: '#F6F8FF' }}>
        <Text style={{ paddingTop: 10, paddingLeft: 16, paddingBottom: 10 }} textColor="#5D6576">
          Type a valid email and click Enter
        </Text>
      </div>
      <Autocomplete
        multiple
        id="notifications-autocomplete"
        classes={{ option: classes.options, endAdornment: classes.deleteIcon }}
        options={[]}
        // options={!!listOptions.length && listOptions}
        value={selected}
        freeSolo
        onChange={(ev: any, value: any) => handleOnChange(ev, id, value)}
        renderTags={(value: readonly string[], getTagProps) => {
          return value.map((option: string, index: number) => <Chip label={option} {...getTagProps({ index })} />);
        }}
        onKeyDown={(ev: any) => {
          if (ev.key === 'Enter' && !EmailValidator.validate(ev.target.value)) {
            ev.defaultMuiPrevented = true;
            setIsValidMail(false);
          } else {
            setIsValidMail(true);
          }
        }}
        renderInput={params => {
          return (
            <TextField
              className={classNames('notifications-autocomplete-input', { invalid: !isValidMail })}
              {...params}
              variant="outlined"
              // onChange={handleInputOnChange}
              label=""
              placeholder={!!placeholder ? placeholder : ''}
            />
          );
        }}
      />
    </>
  );
};

export default memo(AutoCompleteWithChips);
