import { AppDispatch, IReduxState } from 'app/store';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IResponse, IResponsePaginatedData } from 'common/interfaces/interface';
import { RestApi } from 'common/services/rest-api.service';
import { useEffect, useRef, useState } from 'react';
import { IconArrow } from '../Icons/IconArrow/IconArrow';
import './GroupFilter.scss';
import { getRows, removeAllRows, setFields } from 'features/GridSlice';
import { setGlobalFilter, setGlobalFilterType } from 'features/GeneralSlice';
import { Search } from '../Search/Search';
import { List } from './List/List';
import { Common } from 'common/services/common.service';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { urlToStateConverter } from 'common/services/UrlToStateConverter.service';
import { Button } from '@cyberpion/cyberpion-ui';

interface IGroupFilterProps {
  type: string;
  onChange?: (value: string) => void;
}

export function GroupFilter({ type, onChange }: IGroupFilterProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [subsidiariesList, setSubsidiariesList] = useState<string[]>([]);
  const [filteredSubsidiariesList, setFilteredSubsidiariesList] = useState<string[]>([]);
  const [groupsList, setGroupsList] = useState<string[]>([]);
  const [filteredGroupsList, setFilteredGroupsList] = useState<string[]>([]);
  const [metaGroupsList, setMetaGroupsList] = useState<string[]>([]);
  const [filteredMetaGroupsList, setFilteredMetaGroupsList] = useState<string[]>([]);
  const [preDefinedList, setPreDefinedList] = useState<string[]>([]);
  const [filteredPreDefinedList, setFilteredPreDefinedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('');
  const [nextPath, setNextPath] = useState<string>('');
  const dispatch: AppDispatch = useDispatch();
  const { globalFilter } = useSelector((state: IReduxState) => state.general);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    getGroups(`${Paths[PathNames.groups]}?fields=name,type`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelected(globalFilter);
  }, [globalFilter]);

  useEffect(() => {
    getGroups(nextPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPath]);

  useEffect(() => {
    if (search) {
      const groupsResults: string[] = [];
      const metaGroupsResults: string[] = [];
      const subsidiariesResults: string[] = [];
      const preDefinedResults: string[] = [];
      const lowerCasedSearchInput = search.toLowerCase();

      groupsList.forEach((g: string) => {
        if (g.toLowerCase().includes(lowerCasedSearchInput)) {
          groupsResults.push(g);
        }
      });
      subsidiariesList.forEach((s: string) => {
        if (s.toLowerCase().includes(lowerCasedSearchInput)) {
          subsidiariesResults.push(s);
        }
      });
      metaGroupsList.forEach((g: string) => {
        if (g.toLowerCase().includes(lowerCasedSearchInput)) {
          metaGroupsResults.push(g);
        }
      });
      preDefinedList.forEach((g: string) => {
        if (g.toLowerCase().includes(lowerCasedSearchInput)) {
          preDefinedResults.push(g);
        }
      });
      setFilteredGroupsList(groupsResults);
      setFilteredMetaGroupsList(metaGroupsResults);
      setFilteredSubsidiariesList(subsidiariesResults);
      setFilteredPreDefinedList(preDefinedResults);
    } else {
      setFilteredGroupsList(groupsList);
      setFilteredMetaGroupsList(metaGroupsList);
      setFilteredSubsidiariesList(subsidiariesList);
      setFilteredPreDefinedList(preDefinedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  const getGroups = (path: string) => {
    let _path = Common.splitV1FromPath(path);
    RestApi.getData(_path).subscribe((response: IResponsePaginatedData) => {
      const subsidiaries = response.results
        .filter((row: IResponse) => row.type === 'Subsidiary')
        .map((r: IResponse) => r.name)
        .sort(Common.sortAlphabetically);
      const groups = response.results
        .filter((row: IResponse) => row.type === 'Custom Group')
        .map((r: IResponse) => r.name)
        .sort(Common.sortAlphabetically);
      const metaGroups = response.results
        .filter((row: IResponse) => row.type === 'Meta Group')
        .map((r: IResponse) => r.name)
        .sort(Common.sortAlphabetically);
      const preDefined = response.results
        .filter((row: IResponse) => row.type === 'IONIX Predefined')
        .map((r: IResponse) => r.name)
        .sort(Common.sortAlphabetically);

      setGroupsList([...groupsList, ...groups]);
      setFilteredGroupsList([...groupsList, ...groups]);
      setSubsidiariesList([...subsidiariesList, ...subsidiaries]);
      setFilteredSubsidiariesList([...subsidiariesList, ...subsidiaries]);
      setMetaGroupsList([...metaGroupsList, ...metaGroups]);
      setFilteredMetaGroupsList([...metaGroupsList, ...metaGroups]);
      setPreDefinedList([...preDefinedList, ...preDefined]);
      setFilteredPreDefinedList([...preDefinedList, ...preDefined]);
      if (response.next) {
        setNextPath(response.next);
      }
    });
  };

  const onClear = () => {
    setSelected('');
    setSelectedType(null);
    setOpen(false);
    dispatch(setGlobalFilter(''));

    if (onChange) {
      onChange('');
    }

    if (type === 'grid') {
      dispatch(getRows());
    }
  };

  const onApply = () => {
    if (type === 'dashboard') {
      setOpen(false);
      dispatch(setGlobalFilter(selected));
      dispatch(setGlobalFilterType(selectedType));
    }
    if (type === 'grid') {
      setOpen(false);
      const fields = urlToStateConverter.getFields();
      dispatch(removeAllRows());
      dispatch(setGlobalFilter(selected));
      dispatch(setGlobalFilterType(selectedType));
      dispatch(setFields(fields));
      dispatch(getRows());
    }
    if (onChange) {
      onChange(selected);
    }
  };

  const isDisabledButton = () => {
    return (
      !filteredSubsidiariesList.length &&
      !filteredGroupsList.length &&
      !filteredMetaGroupsList.length &&
      !filteredPreDefinedList.length
    );
  };

  const getHeader = (): string => {
    if (globalFilter) {
      const titleLimit = 15;
      if (globalFilter.length > titleLimit) {
        return `${globalFilter.substring(0, titleLimit)}...`;
      }
      return globalFilter;
    }
    return '';
  };

  return (
    <div className="GroupFilter" ref={ref}>
      <header>
        <img alt="Global Filter" src="/assets/images/group-filter.svg" />
        Global Filter
        <div onClick={() => setOpen(!open)} className="trigger-wrapper">
          {getHeader() || 'All Groups'}
          <IconArrow toggleRotate={open} color="#3455DD" />
        </div>
      </header>
      <div className={classNames({ open: open })}>
        <Search onChange={setSearch} />
        <ul className="parent-list">
          <List
            title="Meta Groups"
            link={`/pages/${Paths[PathNames.metaGroups]}`}
            list={metaGroupsList}
            filteredList={filteredMetaGroupsList}
            selected={selected}
            onSelect={setSelected}
            onSelectType={setSelectedType}
          />
          <List
            title="Custom Groups"
            link={`/pages/${Paths[PathNames.groups]}`}
            list={groupsList}
            filteredList={filteredGroupsList}
            selected={selected}
            onSelect={setSelected}
            onSelectType={setSelectedType}
          />
          <List
            title="Subsidiaries"
            link={`/pages/${Paths[PathNames.subsidiaries]}`}
            list={subsidiariesList}
            filteredList={filteredSubsidiariesList}
            selected={selected}
            onSelect={setSelected}
            onSelectType={setSelectedType}
          />
          <List
            title="IONIX Predefined"
            // link={`/pages/${Paths[PathNames.subsidiaries]}`}
            list={preDefinedList}
            filteredList={filteredPreDefinedList}
            selected={selected}
            onSelect={setSelected}
            onSelectType={setSelectedType}
          />
        </ul>
        <div className="buttons-wrapper">
          <Button
            type="button"
            text="Clear filters"
            onClick={onClear}
            buttonStyle="secondary"
            size="small"
            disabled={isDisabledButton()}
          />
          <Button
            type="button"
            text="Apply"
            onClick={onApply}
            buttonStyle="main"
            size="small"
            disabled={isDisabledButton()}
          />
        </div>
      </div>
    </div>
  );
}
