import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconThreatCenter extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#5D6576';

    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.68242 15.8944L9.33965 3.531C9.62282 3.0051 10.3772 3.0051 10.6604 3.531L17.3176 15.8944C17.5866 16.3941 17.2247 17 16.6572 17H3.34277C2.77526 17 2.41336 16.3941 2.68242 15.8944Z"
          stroke={color}
        />
        <path d="M10 8.125V11.875" stroke={color} />
        <path d="M10 13.125V14.375" stroke={color} />
      </svg>
    );
  }
}
