import { Common } from 'common/services/common.service';
import './rowCount.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
interface IRowCount {
  conut: number;
  typeData: string;
  selectedCount?: number;
  isClusterView?: boolean;
}
export function RowCount(props: IRowCount) {
  const { clusterCount, clusterType } = useSelector((state: IReduxState) => state.grid);

  return (
    <div>
      <div className={classNames('rowCount-wrapper', { isClusterView: props.isClusterView })}>
        <div className="row-count">
          Showing {Common.numberWithCommas(props.conut)} Rows {!!clusterType ? `in ${clusterCount} clusters` : ''}
        </div>
        {props.selectedCount ? <span>&nbsp;({props.selectedCount} Selected)</span> : ''}
      </div>
    </div>
  );
}
