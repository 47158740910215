import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconSubDomain extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="-0.5" x2="16" y2="-0.5" transform="matrix(4.3714e-08 1 1 -4.3714e-08 3 2)" stroke="#8C909A" />
        <rect x="-0.5" y="0.5" width="7" height="4" transform="matrix(-1 0 0 1 12 4)" stroke="#8C909A" />
        <rect x="-0.5" y="0.5" width="11" height="4" transform="matrix(-1 0 0 1 16 11)" stroke="#8C909A" />
      </svg>
    );
  }
}
