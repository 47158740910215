import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconActiveProtect extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#6D6D7A';
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" fill="none">
        <path
          d="M16.5 3.8673V9.35742C16.5 12.0488 15.0579 14.5339 12.721 15.8692L10.2481 17.2824C10.0944 17.3702 9.90565 17.3702 9.75193 17.2824L7.27896 15.8692C4.94215 14.5339 3.5 12.0488 3.5 9.35742V3.8673C3.5 3.61847 3.68296 3.40751 3.92929 3.37232L10 2.50508L16.0707 3.37232C16.317 3.40751 16.5 3.61847 16.5 3.8673Z"
          stroke={color}
          strokeLinejoin="round"
        />
        <path
          d="M10 10L10.3717 9.66552C10.2768 9.56016 10.1418 9.5 10 9.5C9.85826 9.5 9.72318 9.56016 9.62836 9.66552L10 10ZM9.62836 10.3345L14.1284 15.3345L14.8716 14.6655L10.3717 9.66552L9.62836 10.3345ZM5.87165 15.3345L10.3717 10.3345L9.62836 9.66552L5.12835 14.6655L5.87165 15.3345Z"
          fill="#5D6576"
        />
        <path
          d="M12 5L8 5C7.58798 5 7.35279 5.47038 7.6 5.8L9.6 8.46667C9.8 8.73333 10.2 8.73333 10.4 8.46667L12.4 5.8C12.6472 5.47038 12.412 5 12 5Z"
          fill="#5D6576"
        />
      </svg>
    );
  }
}
