import React from 'react';
import * as d3 from 'd3';
import './pieChart.scss';
import { PieChartLegend } from './pieChartLegend/PieChartLegend';
import { Common } from 'common/services/common.service';

const radius = 70;
const width = radius * 2;
const height = radius * 2;
// interface ILegend {
//   header: string;
//   name?: string;
//   date?: string;
//   value: number;
//   tooltip?: string;
//   added?: string;
//   removed?: string;
//   is_added_positive_trend?: boolean;
//   is_removed_positive_trend?: boolean;
// }
// interface IPieChart {
//   data: ILegend[];
//   show_percent_label: boolean;
//   is_black_mode: boolean;
//   show_header_legend: boolean;
//   show_color_number: boolean;
//   size_pie_chart?: string;
// }
export function PieChart(props: any) {
  const svgRef = React.useRef(null);
  let total = 0;
  const colors = toggleColors();
  init();
  function init() {
    total = 0;
    if (!props.data) {
      return;
    }
    props.data.forEach((d: any) => {
      total = total + d.value;
    });
  }

  React.useEffect(() => {
    if (!props.data) {
      return;
    }
    //Tooltip
    let tooltip: any = d3.select(`.ttG-d3-scope`);
    function hiddenTooltip() {
      tooltip.style('opacity', 0);
      tooltip.style('display', 'none');
    }

    const svg = d3
      .select(svgRef.current)
      .attr('width', props.size_pie_chart || '100px')
      .attr('height', props.size_pie_chart || '100px')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .append('g')
      .attr('class', 'group-container')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const color = d3.scaleOrdinal(colors);

    const pie = d3
      .pie()
      .sort(null)
      .value((d: any) => d.value);

    let path: any = d3
      .arc()
      .outerRadius(radius - 10)
      .innerRadius(radius - 20);

    const arc = svg
      .selectAll('.arc')
      .data(pie(props.data))
      .enter()
      .append('g')
      .attr('class', 'arc');

    arc
      .append('path')
      .attr('d', path)
      .attr('id', (d: any, index: any) => {
        d.index = index;
        return '';
      })
      .attr('fill', (d, i: any) => color(i))
      .attr('stroke-width', 2)
      .attr('stroke', props.is_black_mode ? '#454549' : '#e8e8e8')
      .on('mousemove', (e: any, d: any) => {
        tooltip.style('opacity', 1).style('display', 'block');
        tooltip
          .html(onTooltipTemplate(d))
          .style('left', e.pageX + 10 + 'px')
          .style('top', e.pageY + 'px');
      })
      .on('mouseleave', hiddenTooltip)
      .on('mouseout', hiddenTooltip);

    function onTooltipTemplate(event: any) {
      return `
       <div class="ttG-d3">
        <div class="ttG-header-scope">
         <div class="ttG-d3-num" style="color:${colors[event.index]}" >${Common.percentage(event.value, total)}%</div>
         <div class="ttG-d3-header">${event.data.header}</div>

         </div>
       </div>
      `;
    }
  });

  function toggleColors() {
    return props.is_black_mode
      ? ['#A862FF', '#DB42FF', '#7672F9', '#4B7AE7', '#4C8FDB']
      : ['#4B7AE7', '#B5A5F5', '#4A97A8', '#4EABDE', '#A9CDE8'];
  }

  return (
    <div className="pieChart-scope">
      <svg ref={svgRef} />
      <PieChartLegend
        {...props}
        colors={colors}
        total={total}
        show_color_number={props.show_color_number}
        show_header_legend={props.show_header_legend}
        show_percent_label={props.show_percent_label}
      />
    </div>
  );
}
