import { IIcon } from 'common/interfaces/interface';
import React from 'react';

interface IIconNotificationsActiveProtection extends IIcon {
  isFilledColor?: boolean;
}
export class IconNotificationsActiveProtection extends React.Component {
  public props: IIconNotificationsActiveProtection;

  render() {
    return this.props.isFilledColor ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 7.42577V2.52241C14 2.08113 13.6714 1.7089 13.2335 1.65417L8.00004 1L2.74873 1.76581C2.3188 1.82851 2 2.19717 2 2.63165V7.47936C2 9.96904 3.32239 12.2713 5.47292 13.5258L7.55132 14.7382C7.82793 14.8996 8.17059 14.8969 8.44468 14.7313L10.6198 13.4171C12.7179 12.1496 14 9.87696 14 7.42577ZM9.6875 3.12501L6.31251 3.12501C5.96487 3.12501 5.76643 3.52189 5.97502 3.80001L7.66251 6.05C7.83126 6.275 8.16876 6.275 8.33751 6.05L10.025 3.80001C10.2336 3.5219 10.0351 3.12501 9.6875 3.12501ZM8.00005 8L11.499 10.9991C11.7192 11.1878 11.6982 11.5345 11.457 11.6953L8.24268 13.8382C8.09573 13.9362 7.90427 13.9362 7.75732 13.8382L4.54308 11.6953C4.30186 11.5345 4.28093 11.1878 4.50104 10.9991L8.00005 8Z"
          fill="#3455DD"
        />
        <path
          d="M13.5 2.52241V7.42577C13.5 9.70187 12.3094 11.8122 10.3613 12.9892L8.18612 14.3034C8.06865 14.3743 7.9218 14.3755 7.80325 14.3063L5.72485 13.0939C3.72793 11.9291 2.5 9.7912 2.5 7.47936V2.63165C2.5 2.44544 2.63663 2.28744 2.82088 2.26057L8.0052 1.50454L13.1715 2.15031C13.3592 2.17377 13.5 2.33329 13.5 2.52241Z"
          stroke="#3455DD"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 7.42577V2.52241C14 2.08113 13.6714 1.7089 13.2335 1.65417L8.00004 1L2.74873 1.76581C2.3188 1.82851 2 2.19717 2 2.63165V7.47936C2 9.96904 3.32239 12.2713 5.47292 13.5258L7.55132 14.7382C7.82793 14.8996 8.17059 14.8969 8.44468 14.7313L10.6198 13.4171C12.7179 12.1496 14 9.87696 14 7.42577ZM9.6875 3.12501L6.31251 3.12501C5.96487 3.12501 5.76643 3.52189 5.97502 3.80001L7.66251 6.05C7.83126 6.275 8.16876 6.275 8.33751 6.05L10.025 3.80001C10.2336 3.5219 10.0351 3.12501 9.6875 3.12501ZM8.00005 8L11.499 10.9991C11.7192 11.1878 11.6982 11.5345 11.457 11.6953L8.24268 13.8382C8.09573 13.9362 7.90427 13.9362 7.75732 13.8382L4.54308 11.6953C4.30186 11.5345 4.28093 11.1878 4.50104 10.9991L8.00005 8Z"
          fill="#3455DD"
        />
        <path
          d="M13.5 2.52241V7.42577C13.5 9.70187 12.3094 11.8122 10.3613 12.9892L8.18612 14.3034C8.06865 14.3743 7.9218 14.3755 7.80325 14.3063L5.72485 13.0939C3.72793 11.9291 2.5 9.7912 2.5 7.47936V2.63165C2.5 2.44544 2.63663 2.28744 2.82088 2.26057L8.0052 1.50454L13.1715 2.15031C13.3592 2.17377 13.5 2.33329 13.5 2.52241Z"
          stroke="#3455DD"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
