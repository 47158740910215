import { useState } from 'react';
import './header.scss';
import { updateHeader } from '../common/services/common.service';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import classNames from 'classnames';

export function Header(props: any) {
  const [headerText, setHeaderText] = useState<string>('');
  const { user } = useSelector((state: IReduxState) => state.login);

  updateHeader.getHeaderText().subscribe((e: any) => {
    const subAccount = user.sub_account_display_name;
    if (subAccount) {
      setHeaderText(`${subAccount} - ${e.text}`);
    } else if (user.role < 35) {
      setHeaderText(`${user.company_display_name.replace('MSSP ', '')} - ${e.text}`);
    } else {
      setHeaderText(e.text);
    }
  });

  return (
    <div className={classNames('header-container')}>
      <div className="header-text">{headerText}</div>
      <div className="header-action">{/* <GlobalFilter />
        <AllGroups /> */}</div>
    </div>
  );
}
