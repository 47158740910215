import React, { useState, useEffect } from 'react';
import { useTopPanelContext } from '../../TopPanelContext';
import { Text, DataPanel, IconRisk, LineChart, LINE_CHART_TYPE } from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import { textProps, dataPanelProps } from '../TopPanelDiscovery';
import { getTechIcon, iconByID } from 'common/components/TopPanel/hooks/useTopPanel';
import { Common } from 'common/services/common.service';
import useFilters from 'common/components/TopPanel/hooks/useFilters';

const DiscoveryDigitalSupplyExpand = () => {
  const [sliceAmount, setSliceAmount] = useState<number | null>(null);
  const { handleFiltersOnClick } = useFilters();

  const { data } = useTopPanelContext();
  const { risk_distribution, assets_by_service } = data;

  useEffect(() => {
    let amount;
    if (window.screen.width < 1600) {
      amount = 9;
    } else if (window.screen.width < 1800) {
      amount = 11;
    } else if (window.screen.width < 2000) {
      amount = 13;
    } else {
      amount = 14;
    }
    setSliceAmount(amount);
  }, []);

  const renderOtherFQDN = () => {
    const other = assets_by_service.data.find((item: any) => item.id === 'other');
    return !!other ? (
      <Grid item key={`${other.id}`} className={`fqdn-item`}>
        <Grid container direction="column">
          <Grid item className="top-panel-tech-icon">
            {iconByID[other.id as keyof typeof iconByID]}
          </Grid>
          <Grid item className="fqdn-data-panel">
            <DataPanel id={`${other.id}`} {...dataPanelProps} title={other.label}>
              {other.value}
            </DataPanel>
          </Grid>
        </Grid>
      </Grid>
    ) : null;
  };

  return (
    <Grid container className="top-panel-discovery-external-expand-wrapper" sx={{ paddingLeft: 2.5 }}>
      <Grid item className="distribution-by-urgency" sx={{ paddingTop: 2.5 }}>
        <Grid container direction="column" className="panel-wrapper">
          <Grid item className="panel-title">
            <Text textColor="#FFFFFF" textSize={13} upper>
              RISK DISTRIBUTION
            </Text>
          </Grid>
          <Grid item>
            <Grid container className="horizontal-border data-panel-wrapper">
              {['critical', 'high', 'medium', 'low', 'no_risk'].map((item: string) => {
                return (
                  <Grid item key={item} className="urgency-wrapper">
                    <Grid container direction="column">
                      <Grid item className="urgency-icon">
                        <IconRisk urgency={item} />
                      </Grid>
                      <Grid item>
                        <DataPanel
                          id={item}
                          {...dataPanelProps}
                          // title={texts[item as keyof typeof texts]}
                          title={Common.underscoreToTitle(item, false)}
                          // isFiltered={filters.includes(item)}
                          filterOnClick={id => {
                            handleFiltersOnClick(
                              'risk_score',
                              Common.underscoreToTitle(id),
                              'discovery-digital-supply'
                            );
                          }}
                        >
                          {risk_distribution[item]}
                        </DataPanel>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!!assets_by_service && !!assets_by_service.data.length && (
        <>
          <Grid item>
            <div className="vertical-devider"></div>
          </Grid>
          <Grid item className="fqdn-wrapper" sx={{ paddingTop: 2.5 }}>
            <Grid container direction="column" className="panel-wrapper">
              <Grid item className="panel-title">
                <Text textColor="#FFFFFF" textSize={13}>
                  FQDNs HOSTING PROVIDERS
                </Text>
              </Grid>
              <Grid item>
                <Grid container className="fqdn-icons">
                  {assets_by_service.data
                    .filter((item: any) => item.id !== 'other')
                    .slice(0, sliceAmount)
                    .map((item: any, index: number) => {
                      return (
                        <Grid
                          item
                          key={`${item.id}-${index}`}
                          className={`fqdn-item ${item.id === 'other' ? 'fqdn-other' : ''}`}
                        >
                          <Grid container direction="column">
                            <Grid item className="top-panel-tech-icon">
                              {getTechIcon(item.id)}
                            </Grid>
                            <Grid item sx={{ width: 70 }} className="fqdn-data-panel">
                              <DataPanel
                                id={item.id}
                                {...dataPanelProps}
                                title={item.label}
                                isTooltip
                                filterOnClick={id => {
                                  handleFiltersOnClick('hosting_provider', id, 'discovery-digital-supply');
                                }}
                              >
                                {item.value}
                              </DataPanel>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  {renderOtherFQDN()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const DiscoveryDigitalSupplyCollapse = () => {
  const { data } = useTopPanelContext();
  const { risk_distribution, assets_by_service } = data;
  const totalFQDN: any = assets_by_service.data.reduce((all: number, item: any) => item.value + all, 0);

  return (
    <Grid container className="top-panel-discovery-external-collapse-wrapper">
      {!!risk_distribution && !!Object.keys(risk_distribution).length && (
        <>
          <Grid item sx={{ paddingLeft: 2.5 }}>
            <Text {...textProps} upper>
              DIGITAL SUPPLY CHAIN
            </Text>
          </Grid>

          <Grid item className="urgency-line" sx={{ flex: 1 }}>
            <LineChart
              lineChartData={risk_distribution}
              backgroundColor="#2c2c30"
              height={5}
              lineType={LINE_CHART_TYPE.URGENCY}
            />
          </Grid>
        </>
      )}
      {!!assets_by_service && !!assets_by_service.data.length && (
        <>
          <div className="vertical-devider-collapse"></div>
          <Grid item className="external-assets-title">
            <Text {...textProps} upper={false}>
              FQDNs HOSTING PROVIDERS
            </Text>
          </Grid>
          <Grid item className="org-assets-value" sx={{ paddingRight: 2.5 }}>
            <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
              {totalFQDN}
            </Text>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export { DiscoveryDigitalSupplyExpand, DiscoveryDigitalSupplyCollapse };
