import classNames from 'classnames';
import './ErrorMessage.scss';
import { Button } from '@cyberpion/cyberpion-ui';
import { useSelector } from 'react-redux';

export function ErrorMessage(props: any) {
  const { errorCode } = useSelector((state: any) => state.mssp);
  return (
    <div className={classNames('ErrorMessage', { visible: props.visible })}>
      <h6>Error</h6>
      <p>
        {errorCode}
        {/* An error has occurred and we were unable to retrieve the data. Please try again or contact{' '}
        <a href="mailto:support@ionix.io">support@ionix.io</a> {errorCode ? `with error code: ${errorCode}` : ''} */}
      </p>
      <div className="error-action">
        <Button type="button" buttonStyle="secondary" onClick={props.onClose} text="Close" size={'medium'} />
      </div>
    </div>
  );
}
