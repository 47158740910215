import './technologies.scss';
export function Technologies(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="408" height="180" viewBox="0 0 408 180" fill="none">
      <path d="M256.512 35.0134L270.632 10.8076L284.752 35.0134H256.512Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M256.512 83.4255L270.632 59.2197L284.752 83.4255H256.512Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M242.392 59.2195L256.512 35.0137L270.632 59.2195H242.392Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M214.151 59.2195L228.271 35.0137L242.391 59.2195H214.151Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M284.751 35.0134L298.872 10.8076L312.992 35.0134H284.751Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path
        d="M284.751 35.0139L270.631 59.2197L256.511 35.0139L284.751 35.0139Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M270.631 59.219L256.511 83.4248L242.391 59.219L270.631 59.219Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M270.631 10.8079L256.511 35.0137L242.391 10.8079L270.631 10.8079Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M242.392 59.219L228.272 83.4248L214.152 59.219L242.392 59.219Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M312.992 35.0139L298.872 59.2197L284.752 35.0139L312.992 35.0139Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <circle cx="270.631" cy="59.2193" r="3.06212" fill="#E8E8E8" />
      <circle cx="242.555" cy="59.2193" r="3.06212" fill="#E8E8E8" />
      <circle cx="214.142" cy="59.2193" r="3.06212" fill="#E8E8E8" />
      <circle cx="299.044" cy="11.0621" r="3.06212" fill="#E8E8E8" />
      <circle cx="270.967" cy="11.0621" r="3.06212" fill="#E8E8E8" />
      <circle cx="242.555" cy="11.0621" r="3.06212" fill="#E8E8E8" />
      <circle cx="284.597" cy="83.7799" r="3.06212" fill="#E8E8E8" />
      <circle cx="256.521" cy="83.7799" r="3.06212" fill="#E8E8E8" />
      <circle cx="228.108" cy="83.7799" r="3.06212" fill="#E8E8E8" />
      <circle cx="228.108" cy="35.1402" r="3.06212" fill="#E8E8E8" />
      <circle cx="256.384" cy="35.1402" r="3.06212" fill="#E8E8E8" />
      <circle cx="284.661" cy="35.1402" r="3.06212" fill="#E8E8E8" />
      <circle cx="312.938" cy="35.1402" r="3.06212" fill="#E8E8E8" />
      <path
        d="M169.488 144.987L155.368 169.192L141.248 144.987L169.488 144.987Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M169.488 96.5745L155.368 120.78L141.248 96.5745L169.488 96.5745Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M183.608 120.781L169.488 144.986L155.368 120.781L183.608 120.781Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M211.848 120.781L197.728 144.986L183.608 120.781L211.848 120.781Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M141.248 144.987L127.128 169.192L113.008 144.987L141.248 144.987Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M141.248 144.986L155.368 120.78L169.488 144.986L141.248 144.986Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M155.368 120.781L169.488 96.5752L183.608 120.781L155.368 120.781Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M155.368 169.192L169.488 144.986L183.608 169.192L155.368 169.192Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M183.608 120.781L197.728 96.5752L211.848 120.781L183.608 120.781Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M113.008 144.986L127.128 120.78L141.248 144.986L113.008 144.986Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <circle cx="155.368" cy="120.781" r="3.06212" transform="rotate(-180 155.368 120.781)" fill="#E8E8E8" />
      <circle cx="183.445" cy="120.781" r="3.06212" transform="rotate(-180 183.445 120.781)" fill="#E8E8E8" />
      <circle cx="211.857" cy="120.781" r="3.06212" transform="rotate(-180 211.857 120.781)" fill="#E8E8E8" />
      <circle cx="126.955" cy="168.938" r="3.06212" transform="rotate(-180 126.955 168.938)" fill="#E8E8E8" />
      <circle cx="155.032" cy="168.938" r="3.06212" transform="rotate(-180 155.032 168.938)" fill="#E8E8E8" />
      <circle cx="183.445" cy="168.938" r="3.06212" transform="rotate(-180 183.445 168.938)" fill="#E8E8E8" />
      <circle cx="141.402" cy="96.2201" r="3.06212" transform="rotate(-180 141.402 96.2201)" fill="#E8E8E8" />
      <circle cx="169.479" cy="96.2201" r="3.06212" transform="rotate(-180 169.479 96.2201)" fill="#E8E8E8" />
      <circle cx="197.891" cy="96.2201" r="3.06212" transform="rotate(-180 197.891 96.2201)" fill="#E8E8E8" />
      <circle cx="197.891" cy="144.86" r="3.06212" transform="rotate(-180 197.891 144.86)" fill="#E8E8E8" />
      <circle cx="169.615" cy="144.86" r="3.06212" transform="rotate(-180 169.615 144.86)" fill="#E8E8E8" />
      <circle cx="141.338" cy="144.86" r="3.06212" transform="rotate(-180 141.338 144.86)" fill="#E8E8E8" />
      <circle cx="113.062" cy="144.86" r="3.06212" transform="rotate(-180 113.062 144.86)" fill="#E8E8E8" />
      <path d="M8 22H32C34.2091 22 36 23.7909 36 26V50H12C9.79086 50 8 48.2091 8 46V22Z" fill="#F0F2F6" />
      <circle cx="22" cy="36" r="10" fill="#8796F0" fillOpacity="0.2" />
      <path
        d="M39 22.0693H189.172C190.182 22.0693 191 22.8876 191 23.8969V62.0693H42.6552C40.6365 62.0693 39 60.4329 39 58.4142V22.0693Z"
        fill="white"
      />
      <rect x="47" y="30.0693" width="28" height="6" rx="1" fill="#E8E8E8" />
      <rect x="77" y="30.0693" width="16" height="6" rx="1" fill="#F0F2F6" />
      <rect x="47" y="40.0693" width="98" height="6" rx="1" fill="#F0F2F6" />
      <rect x="47" y="48.0693" width="88" height="6" rx="1" fill="#F0F2F6" />
      <path
        d="M176.992 29.3333C176.992 31.9107 174.903 34 172.326 34L147.674 34C145.097 34 143.008 31.9107 143.008 29.3333L143.008 24.0075C143.008 22.8988 142.109 22 141 22L179 22C177.891 22 176.992 22.8988 176.992 24.0075L176.992 29.3333Z"
        fill="#D1D2D3"
      />
      <path
        d="M39 22.0693H189.172C190.182 22.0693 191 22.8876 191 23.8969V62.0693H42.6552C40.6365 62.0693 39 60.4329 39 58.4142V22.0693Z"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <path d="M8 114H32C34.2091 114 36 115.791 36 118V142H12C9.79086 142 8 140.209 8 138V114Z" fill="#F0F2F6" />
      <circle cx="22" cy="128" r="10" fill="#8796F0" fillOpacity="0.2" />
      <path
        d="M39 114.069H189.172C190.182 114.069 191 114.888 191 115.897V154.069H42.6552C40.6365 154.069 39 152.433 39 150.414V114.069Z"
        fill="white"
      />
      <rect x="47" y="122.069" width="28" height="6" rx="1" fill="#E8E8E8" />
      <rect x="77" y="122.069" width="16" height="6" rx="1" fill="#F0F2F6" />
      <rect x="47" y="132.069" width="98" height="6" rx="1" fill="#F0F2F6" />
      <rect x="47" y="140.069" width="88" height="6" rx="1" fill="#F0F2F6" />
      <path
        d="M176.992 121.333C176.992 123.911 174.903 126 172.326 126L147.674 126C145.097 126 143.008 123.911 143.008 121.333L143.008 116.008C143.008 114.899 142.109 114 141 114L179 114C177.891 114 176.992 114.899 176.992 116.008L176.992 121.333Z"
        fill="#D1D2D3"
      />
      <path
        d="M39 114.069H189.172C190.182 114.069 191 114.888 191 115.897V154.069H42.6552C40.6365 154.069 39 152.433 39 150.414V114.069Z"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <path d="M16 68H40C42.2091 68 44 69.7909 44 72V96H20C17.7909 96 16 94.2091 16 92V68Z" fill="#F0F2F6" />
      <circle cx="30" cy="82" r="10" fill="#8796F0" fillOpacity="0.2" />
      <path
        d="M47 68.0693H197.172C198.182 68.0693 199 68.8876 199 69.8969V108.069H50.6552C48.6365 108.069 47 106.433 47 104.414V68.0693Z"
        fill="white"
      />
      <rect x="55" y="76.0693" width="28" height="6" rx="1" fill="#E8E8E8" />
      <rect x="85" y="76.0693" width="16" height="6" rx="1" fill="#F0F2F6" />
      <rect x="55" y="86.0693" width="98" height="6" rx="1" fill="#F0F2F6" />
      <rect x="55" y="94.0693" width="88" height="6" rx="1" fill="#F0F2F6" />
      <path
        d="M47 68.0693H197.172C198.182 68.0693 199 68.8876 199 69.8969V108.069H50.6552C48.6365 108.069 47 106.433 47 104.414V68.0693Z"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <path d="M208 22H232C234.209 22 236 23.7909 236 26V50H212C209.791 50 208 48.2091 208 46V22Z" fill="#F0F2F6" />
      <circle cx="222" cy="36" r="10" fill="#8796F0" fillOpacity="0.2" />
      <path
        d="M239 22.0693H389.172C390.182 22.0693 391 22.8876 391 23.8969V62.0693H242.655C240.636 62.0693 239 60.4329 239 58.4142V22.0693Z"
        fill="white"
      />
      <rect x="247" y="30.0693" width="28" height="6" rx="1" fill="#E8E8E8" />
      <rect x="277" y="30.0693" width="16" height="6" rx="1" fill="#F0F2F6" />
      <rect x="247" y="40.0693" width="98" height="6" rx="1" fill="#F0F2F6" />
      <rect x="247" y="48.0693" width="88" height="6" rx="1" fill="#F0F2F6" />
      <path
        d="M376.992 29.3333C376.992 31.9107 374.903 34 372.326 34L347.674 34C345.097 34 343.008 31.9107 343.008 29.3333L343.008 24.0075C343.008 22.8988 342.109 22 341 22L379 22C377.891 22 376.992 22.8988 376.992 24.0075L376.992 29.3333Z"
        fill="#D1D2D3"
      />
      <path
        d="M239 22.0693H389.172C390.182 22.0693 391 22.8876 391 23.8969V62.0693H242.655C240.636 62.0693 239 60.4329 239 58.4142V22.0693Z"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <path
        d="M208 114H232C234.209 114 236 115.791 236 118V142H212C209.791 142 208 140.209 208 138V114Z"
        fill="#F0F2F6"
      />
      <circle cx="222" cy="128" r="10" fill="#8796F0" fillOpacity="0.2" />
      <path
        d="M239 114.069H389.172C390.182 114.069 391 114.888 391 115.897V154.069H242.655C240.636 154.069 239 152.433 239 150.414V114.069Z"
        fill="white"
      />
      <rect x="247" y="122.069" width="28" height="6" rx="1" fill="#E8E8E8" />
      <rect x="277" y="122.069" width="16" height="6" rx="1" fill="#F0F2F6" />
      <rect x="247" y="132.069" width="98" height="6" rx="1" fill="#F0F2F6" />
      <rect x="247" y="140.069" width="88" height="6" rx="1" fill="#F0F2F6" />
      <path
        d="M239 114.069H389.172C390.182 114.069 391 114.888 391 115.897V154.069H242.655C240.636 154.069 239 152.433 239 150.414V114.069Z"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <path d="M216 68H240C242.209 68 244 69.7909 244 72V96H220C217.791 96 216 94.2091 216 92V68Z" fill="#F0F2F6" />
      <circle cx="230" cy="82" r="10" fill="#8796F0" fillOpacity="0.2" />
      <path
        d="M247 68.0693H397.172C398.182 68.0693 399 68.8876 399 69.8969V108.069H250.655C248.636 108.069 247 106.433 247 104.414V68.0693Z"
        fill="white"
      />
      <rect x="255" y="76.0693" width="28" height="6" rx="1" fill="#E8E8E8" />
      <rect x="285" y="76.0693" width="16" height="6" rx="1" fill="#F0F2F6" />
      <rect x="255" y="86.0693" width="98" height="6" rx="1" fill="#F0F2F6" />
      <rect x="255" y="94.0693" width="88" height="6" rx="1" fill="#F0F2F6" />
      <path
        d="M384.992 75.3333C384.992 77.9107 382.903 80 380.326 80L355.674 80C353.097 80 351.008 77.9107 351.008 75.3333L351.008 70.0075C351.008 68.8988 350.109 68 349 68L387 68C385.891 68 384.992 68.8988 384.992 70.0075L384.992 75.3333Z"
        fill="#D1D2D3"
      />
      <path
        d="M247 68.0693H397.172C398.182 68.0693 399 68.8876 399 69.8969V108.069H250.655C248.636 108.069 247 106.433 247 104.414V68.0693Z"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
    </svg>
  );
}
