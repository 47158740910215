interface IRoles {
  [key: number]: string;
}

const USER_ROLES: IRoles = {
  10: 'Owner',
  20: 'Admin',
  30: 'Member',
  35: 'Restricted Member'
  // 40: 'Viewer'
};

export const USER_ROLES_SWAPPED = swap(USER_ROLES);

function swap(json: IRoles) {
  var ret: {
    [key: string]: number;
  } = {};
  for (var key in json) {
    ret[json[key]] = parseInt(key);
  }
  return ret;
}

export default USER_ROLES;
