import Grid from '@mui/material/Grid';
import { Text } from '@cyberpion/cyberpion-ui';
import classNames from 'classnames';

import './GroupRelationshipTypeBox.scss';

interface IGroupRelationshipTypeBox {
  type: string;
  selected?: boolean;
  onClick: () => void;
}

const RELATIONSHIP_OPTIONS = {
  substractBFromA: {
    icon: '/assets/images/substractBFromA.svg',
    text: 'Substract B from A'
  },
  substractAFromB: {
    icon: '/assets/images/substractAFromB.svg',
    text: 'Substract A from B'
  },
  intersect: {
    icon: '/assets/images/intersect.svg',
    text: 'Intersect'
  },
  combine: {
    icon: '/assets/images/combine.svg',
    text: 'Combine'
  },
  union: {
    icon: '/assets/images/union.svg',
    text: 'Union'
  }
};
const GroupRelationshipTypeBox: React.FC<IGroupRelationshipTypeBox> = ({ type, selected = false, onClick }) => {
  const item = RELATIONSHIP_OPTIONS[type as keyof typeof RELATIONSHIP_OPTIONS];

  return (
    <Grid
      container
      className={classNames('relationship-item-box', { selected: selected })}
      justifyContent="space-between"
      alignItems="center"
      onClick={onClick}
      data-testid="relationship-type-box"
    >
      <Grid item>
        <img alt="search" src={item.icon} />
      </Grid>
      <Grid item sx={{ paddingLeft: 1.25, width: 100 }}>
        <Text textSize={13} weight={500}>
          {item.text}
        </Text>
      </Grid>
    </Grid>
  );
};

export default GroupRelationshipTypeBox;
