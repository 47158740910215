import React from 'react';

import useTopPanel from 'common/components/TopPanel/hooks/useTopPanel';
import { CollapsePanel } from '@cyberpion/cyberpion-ui';
import { TopPanelContext } from './TopPanelContext';

import './top-panel.scss';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ITopPanel {
  data: any;
  page: string;
  isOpen?: boolean;
  setToggle?: (bln: boolean) => void;
  tab?: string;
  isLoading?: boolean;
}

const TopPanel: React.FC<ITopPanel> = ({ data, page, isLoading, tab, isOpen, setToggle }) => {
  const { getTopPanelInContext, isExpandable } = useTopPanel(page);
  const flags = useFlags();
  const additionalProps = isExpandable ? { isExpandable, collapsedSize: 50, isOpen, setToggle } : {};
  const classTab = !!tab ? `-${tab}` : '';

  if (tab === 'evidence' && flags.isDiscoveryEvidenceV2) {
    return null;
  }

  return (
    <div className="top-panel-container">
      <TopPanelContext.Provider
        value={{
          data,
          isOpen,
          page,
          tab,
          isLoading
        }}
        // isLoading={isLoading}
      >
        <CollapsePanel
          contentClassName={`collapse-panel-${page}${classTab} collapse-panel-wrapper`}
          {...additionalProps}
          isLoading={isLoading}
        >
          {getTopPanelInContext()}
        </CollapsePanel>
      </TopPanelContext.Provider>
    </div>
  );
};

export default TopPanel;
