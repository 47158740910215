import { Text } from '@cyberpion/cyberpion-ui';
import './NoIssuesIcon.scss';
import classNames from 'classnames';

const iconMiddle = (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" rx="13.3333" fill="#F0F2F6" />
    <path
      d="M31.9998 59.9989L24.0004 59.9995C22.939 59.9995 21.9218 59.5777 21.1719 58.8277C20.4219 58.0777 20 57.0604 20 55.9991V36.0006H31.9998L31.9998 59.9989ZM55.9995 59.9989L36.0009 59.9995V35.9999H60.0006V56.0002V55.9995C60.0006 57.0609 59.5787 58.078 58.8287 58.828C58.0788 59.578 57.0615 59.9999 56.0002 59.9999L55.9995 59.9989ZM59.9992 31.9998H20.0003V24.0004C20.0003 22.939 20.4222 21.9218 21.1722 21.1719C21.9222 20.4219 22.9394 20 24.0007 20H56.0009H56.0002C57.0616 20 58.0787 20.4219 58.8287 21.1719C59.5787 21.9218 60.0006 22.9391 60.0006 24.0004L59.9992 31.9998Z"
      fill="white"
    />
  </svg>
);

export function NoIssuesIcon(props: any) {
  return (
    <div className="NoIssuesIcon">
      {!props.subType && (
        <svg width="100%" height="100%" viewBox="0 0 1215 438" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.7"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0C0.895508 0 0 0.895386 0 2V96C0 97.1046 0.895508 98 2 98H1213C1214.11 98 1215 97.1046 1215 96V2C1215 0.895386 1214.11 0 1213 0H2ZM2 113C0.895508 113 0 113.895 0 115V209C0 210.105 0.895508 211 2 211H1213C1214.11 211 1215 210.105 1215 209V115C1215 113.895 1214.11 113 1213 113H2ZM0 228C0 226.895 0.895508 226 2 226H1213C1214.11 226 1215 226.895 1215 228V322C1215 323.105 1214.11 324 1213 324H2C0.895508 324 0 323.105 0 322V228ZM2 340C0.895508 340 0 340.895 0 342V436C0 437.105 0.895508 438 2 438H1213C1214.11 438 1215 437.105 1215 436V342C1215 340.895 1214.11 340 1213 340H2Z"
            fill="url(#paint0_linear_3191_206573)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3191_206573"
              x1="607.5"
              y1="0"
              x2="607.5"
              y2="362"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F0F2F6" />
              <stop offset="1" stopColor="#F0F2F6" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      )}
      <div className={classNames({ 'icon-middle': !props.subType }, { 'icon-middle-card': props.subType === 'card' })}>
        <div>{iconMiddle}</div>
        <Text textSize={13} textColor="#8C909A" style={{ marginTop: 12 }}>
          {props.text || 'No information to present'}
        </Text>
      </div>
    </div>
  );
}
