import {
  Text,
  DataPanel,
  TopPanelBarsPanel,
  Chart,
  CHART_TYPES,
  Indicator,
  TOOLTIP_TYPE,
  ChartEmptyState
} from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import { useTopPanelContext } from '../TopPanelContext';
import { TRENDS_COLORS, getChartCategories, getChartSeries, getIsTrendUp } from '../utils';
import classNames from 'classnames';

import './toppanel-dashboard.scss';

const texts = {
  panel_I_title: 'ASSETS DISCOVERED',
  panel_II_title: 'Org risk Score Over Time',
  panel_III_title: 'Assets Under Active Protection',
  org_assets: 'Org Assets',
  external_assets: 'Digital Supply Chain',
  connections: 'Connections',
  overall_score: 'Overall Score',
  current: 'Currently protected',
  added: 'added last 30 days',
  resolved: 'Resolved last 30 days'
};
const dataPanelProps = {
  titleTextSize: 12,
  weight: 500,
  textSize: 34,
  textColor: '#FFFFFF',
  family: 'Rajdhani'
};

const chartOptions = {
  chart: {
    type: CHART_TYPES.AREA,
    backgroundColor: '#2c2c30',
    height: 100,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 10,
    marginBottom: 26
  },
  xAxis: {},
  yAxis: {
    visible: false
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 380
        },
        chartOptions: {
          chart: {
            marginBottom: 25,
            marginTop: 10
          },
          xAxis: {
            visible: false
          }
        }
      },
      {
        condition: {
          minWidth: 381
        },
        chartOptions: {
          xAxis: {
            visible: true
          }
        }
      }
    ]
  }
};

const TopPanelDashboard = ({ links, linkOnClick }: { links?: any; linkOnClick: (link: string) => void }) => {
  const { data, isLoading } = useTopPanelContext();

  if (!!isLoading || !data || !Object.keys(data).length) return null;

  const chartSeries = !!data?.org_risk_score ? getChartSeries(data?.org_risk_score.data) : [];

  let updatedChartOptions;
  let chartColors;
  let isTrendUp = getIsTrendUp(chartSeries);

  if (!!chartSeries.length) {
    updatedChartOptions = {
      ...chartOptions,
      xAxis: {
        ...chartOptions.xAxis,
        categories: getChartCategories(data?.org_risk_score.data, 'm')
      }
    };
    chartColors = isTrendUp ? TRENDS_COLORS.GOOD : TRENDS_COLORS.BAD;
  }
  const dataBars = {
    critical: data?.remediation.critical_action_items,
    high: data?.remediation.high_action_items,
    medium: data?.remediation.medium_action_items,
    low: data?.remediation.low_action_items
  };
  const total = Object.values(dataBars).reduce((acc: number, item: any) => acc + item, 0);

  return !!data && !!Object.keys(data).length ? (
    <Grid container className="top-panel-dashboard" sx={{ paddingLeft: 2.5 }}>
      <Grid item sx={{ flex: 0, paddingTop: 2.5 }}>
        <Grid container direction="column">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <img src={`/assets/images/Discovery.svg`} alt="Icon" />
              </Grid>
              <Grid item sx={{ paddingLeft: 1 }}>
                <Text textColor="#FFFFFF" textSize={13}>
                  Discovery
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container className="action-items-wrapper">
              {['org_assets', 'external_assets', 'connections'].map((item: string) => {
                return (
                  <Grid item key={item} className="data-panel-item">
                    <DataPanel
                      id={item}
                      {...dataPanelProps}
                      title={texts[item as keyof typeof texts]}
                      titleStyle={{ whiteSpace: 'nowrap', display: 'flex', color: '#a6acbe' }}
                      linkOnClick={linkOnClick}
                      link={links[item]}
                    >
                      {data.assets_discovered[item]}
                    </DataPanel>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 0 }}>
        <div className="vertical-devider"></div>
      </Grid>
      <Grid item className="org-risk-score-wrapper " sx={{ flex: 1, paddingTop: 2.5, maxWidth: 700 }}>
        <Grid container direction="column">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <img src={`/assets/images/Assessment.svg`} alt="Icon" />
              </Grid>
              <Grid item sx={{ paddingLeft: 1 }}>
                <Text textColor="#FFFFFF" textSize={13}>
                  Assessment
                </Text>
              </Grid>
            </Grid>
          </Grid>
          {true ? (
            <Grid item sx={{}}>
              <Grid container className="org-risk-score" sx={{ maxWidth: 700 }}>
                <Grid item sx={{ paddingTop: 0 }} className="action-items-wrapper">
                  <DataPanel
                    id="score"
                    {...dataPanelProps}
                    title="Score"
                    titleStyle={{ whiteSpace: 'nowrap', display: 'flex', color: '#a6acbe' }}
                    isTrend
                    isTrendUp={isTrendUp}
                    rightContentClassName="data-panel-trend"
                    linkOnClick={linkOnClick}
                    link={links['score']}
                  >
                    {!!data?.org_risk_score?.overall_score ? data.org_risk_score.overall_score : undefined}
                  </DataPanel>
                </Grid>
                <Grid
                  item
                  className={classNames('org-risk-score-indicator', {
                    'data-panel-na': !data?.org_risk_score?.overall_score
                  })}
                >
                  <Indicator
                    letter={!!data?.org_risk_score?.overall_rank ? data.org_risk_score.overall_rank : null}
                    type="score"
                    size="m"
                    textColor="#FFFFFF"
                    // color="#ffffff"
                  />
                </Grid>
                <Grid item className="chart-size" sx={{ maxWidth: 550 }}>
                  {!!chartSeries.length ? (
                    <Chart
                      chartOptions={updatedChartOptions}
                      chartType="area"
                      colors={chartColors}
                      series={chartSeries}
                      tooltipType={TOOLTIP_TYPE.AREA}
                      isTooltipPointValueGreen={isTrendUp}
                    />
                  ) : (
                    <ChartEmptyState chartOptions={{ chart: { height: 100, width: 500 } }} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <img src={`/assets/images/dashboard-assessment-emptystate.svg`} alt="Icon" />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 0 }}>
        <div className="vertical-devider"></div>ß
      </Grid>
      <Grid item sx={{ paddingTop: 2.5 }}>
        <Grid container direction="column" className="" sx={{ paddingRight: 2.5 }}>
          <Grid item className="panel-title">
            <Grid container alignItems="center">
              <Grid item>
                <img src={`/assets/images/Remediation.svg`} alt="Icon" />
              </Grid>
              <Grid item sx={{ paddingLeft: 1 }}>
                <Text textColor="#FFFFFF" textSize={13}>
                  Remediation
                </Text>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className="action-items-wrapper">
            <DataPanel
              id={'actionItems'}
              {...dataPanelProps}
              title={'Action Items'}
              titleStyle={{ whiteSpace: 'nowrap', display: 'flex', color: '#a6acbe' }}
              linkOnClick={linkOnClick}
              link={links.actionItems}
            >
              {total}
            </DataPanel>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="dashboard-remediation top-panel-section" sx={{ paddingTop: 2.6, paddingLeft: 2.5 }}>
        <Grid container direction="column" className="panel-wrapper panel-wrapper-last" sx={{ paddingRight: 2.5 }}>
          <Grid item className="panel-title">
            <Grid container sx={{ borderLeft: '1px solid #454549' }} alignItems="center">
              <Grid item sx={{ paddingLeft: '9px', paddingRight: 1.5, display: 'flex', width: 14, height: 14 }}>
                <img src={`/assets/images/active_protection.svg`} alt="Icon" />
              </Grid>
              <Grid item sx={{ paddingLeft: 1 }}>
                <Text textColor="#FFFFFF" textSize={12} id="activeProtection" linkOnClick={linkOnClick}>
                  {data?.remediation.active_protection}
                </Text>
              </Grid>
              <Grid item sx={{ paddingLeft: '6px' }}>
                <Text textColor="#8C909A" textSize={12}>
                  Protected Assets
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item sx={{ paddingTop: 2.1 }}>
              <TopPanelBarsPanel panelId="story-toppanel" barsData={dataBars} linkOnClick={linkOnClick} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default TopPanelDashboard;
