class Zendesk {
  private injected;
  private key;
  private id;
  private url;
  private initialized;

  constructor() {
    this.injected = false;
    this.initialized = false;
    this.key = 'a1e3b7cf-06bf-45d0-9cd0-d96dc8699681';
    this.id = 'ze-snippet';
    this.url = 'https://static.zdassets.com/ekr/snippet.js';
  }

  inject() {
    if (this.injected) {
      return;
    }
    this.injected = true;
    const body = document.getElementsByTagName('body')[0];
    const script = document.createElement('script');
    script.setAttribute('src', `${this.url}?key=${this.key}`);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', this.id);
    body.appendChild(script);

    body.addEventListener(
      'DOMNodeInserted',
      event => {
        const target = event.target as HTMLIFrameElement;
        if (target) {
          if (target.id === 'launcher') {
            document.getElementById('ZD_HELP')?.classList.add('visible');
          }
        }
      },
      false
    );
  }
}

export const zendesk = new Zendesk();
