import './links.scss';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { Common } from 'common/services/common.service';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IReduxState } from 'app/store';
import { GroupFilter } from '../GroupFilter/GroupFilter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';
import { selectView } from 'features/ViewsSlice';
import { LoginService } from 'login/services/login.service';
import { ToolTip } from '../toolTip/toolTip';

export function Links(props: any) {
  const { user } = useSelector((state: IReduxState) => state.login);
  const { queryString } = useSelector((state: IReduxState) => state.grid);
  const { defaultViews } = useSelector((state: IReduxState) => state.views);
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const flags = useFlags();

  const getTabByKey = (key: string) => {
    return Object.keys(props.tabs).filter((_tab: string) => props.tabs[_tab].key === key)[0];
  };

  function isActiveTab(key: string) {
    return props.selectTab === getTabByKey(key);
  }

  // const shouldShowLockedTab = (tab: string) => {

  //   if (props.metaData && props.metaData[tab] && !props.metaData[tab].is_available) {
  //     return true
  //   }

  //   return false
  // }

  const buildLink = (tab: any) => {
    let link = tab.link;
    if (Array.isArray(tab.link)) {
      if (flags[tab.featureFlag[0]]) {
        link = link[0];
      } else {
        link = link[1];
      }
    }
    if (queryString[tab.gridPath]) {
      return link + queryString[tab.gridPath];
    }
    return link;
  };

  const checkIfHasDefault = (e: any, tab: any) => {
    if (props.page) {
      const defaultView = defaultViews[props.page] && defaultViews[props.page][tab.gridPath]?.views[0];
      if (defaultView) {
        e.preventDefault();
        history.push(Common.getDecodedViewPath(defaultView.path));
        dispatch(selectView(defaultView.id));
      }
    }
  };

  if (!Object.keys(props.metaData).length) {
    return <div className="links-wrapper"></div>;
  }

  return (
    <div className="links-wrapper">
      <div className={classNames('tabs')}>
        {Object.values(props.tabs).map((tab: any, index: number) => {
          if (tab.lockedTo && !tab.lockedTo.includes(user.company_name)) {
            return <div key={index}></div>;
          }
          if (tab.permissionLevel === 'member' && !LoginService.isMemberOrAbove(user)) {
            return <div key={index}></div>;
          }
          if (tab.featureFlag) {
            if (typeof tab.featureFlag === 'string' && !flags[tab.featureFlag]) {
              return <div key={index}></div>;
            } else if (
              Array.isArray(tab.featureFlag) &&
              !tab.featureFlag.filter((flag: string) => flags[flag]).length
            ) {
              return <div key={index}></div>;
            }
          }
          if (tab.showConditionaly && (!props.metaData[tab.key] || props.metaData[tab.key].count === null)) {
            return <div key={index}></div>;
          }
          const isActive = isActiveTab(tab.key);
          const tabKey = tab.key === 'action-items' ? 'action_items' : tab.key;
          return (
            <div className={classNames('tab-scope', tab.key)} key={index}>
              <Button>
                <Link to={buildLink(tab)} title={tab.title} className="tab" onClick={e => checkIfHasDefault(e, tab)}>
                  {tab.IconComponent && <tab.IconComponent active={isActiveTab(tab.key)} />}
                  <div className={classNames('tab-title', { selected: isActive }, { uselected: !isActive })}>
                    {tab.title}
                    {tab.label && <span className="label">{tab.label}</span>}
                  </div>
                  {!props.hideCounts && (
                    <div className={classNames('tab-title-count', { selected: isActive }, { uselected: !isActive })}>
                      {props.metaData && props.metaData[tabKey]
                        ? Common.numberWithCommas(props.metaData[tabKey].count)
                        : ''}
                      {props.metaData &&
                        !!props.metaData[tabKey]?.rescan_completed_count &&
                        flags.isActionItemRescan && (
                          <img
                            src="/assets/images/rescan.svg"
                            data-tip={props.metaData[tabKey].rescan_completed_count + ' Scans completed'}
                            data-for="RESCAN_UPDATE"
                            alt="Updated"
                          />
                        )}
                      <ToolTip id="RESCAN_UPDATE" place="right" theme="dark" effect="solid" />
                    </div>
                  )}
                </Link>
              </Button>
              <div className={isActiveTab(tab.key) ? 'border-selected' : 'border-uselected'}></div>
            </div>
          );
        })}
      </div>
      {props.selectTab !== 'orgScore' && (
        <div style={{ display: 'flex' }}>
          <GroupFilter type="grid" />
        </div>
      )}
    </div>
  );
}
