import './text.scss';

export function Text(props: any) {
  return (
    <div className="formGraph-header-scope text-section">
      <div className="formGraph-label-scope">
        <div className="formGraph-header">{props.label}</div>
      </div>
      <div className="formGraph-value">{props.value}</div>
    </div>
  );
}
