import { useEffect, useState } from 'react';
import './HelpCenter.scss';
import classNames from 'classnames';
import { LinkNewTab } from '../LinkNewTab/LinkNewTab';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';

export function HelpCenter(props: any) {
  const [visible, setVisible] = useState<boolean>(false);
  const { isGraphFullScreen } = useSelector((state: IReduxState) => state.general);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <LinkNewTab
      className={classNames('HelpCenter', { visible: visible, 'move-to-bottom': isGraphFullScreen })}
      to={'https://ionix.zendesk.com'}
      text={
        <div>
          <img src="/assets/images/help-center.svg" alt="Help Center" />
          Help Center
        </div>
      }
    />
  );
}
