import { IIcon } from 'common/interfaces/interface';
import React from 'react';

export class IconActionOverTimes extends React.Component {
  public props: IIcon;
  render() {
    let color = '#3455DD'; //this.props.active ? "#3455DD" : "#6D6D7A";
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" fill="none">
        <path
          d="M2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14C6.27915 14 4.34012 12.7934 3.30269 11"
          stroke={color}
          strokeLinecap="round"
        />
        <path d="M4 7.5L2.5 9L1 7.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M5.83337 8L8.99988 4.5L7.49988 11.5L11.1667 8"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
