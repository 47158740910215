import Grid from '@mui/material/Grid';
import { Text } from '@cyberpion/cyberpion-ui';
import { NOTIFICATION_OPTIONS } from 'pages/Settings/Notifications/NotificationsContext';
import { svgCollapse } from 'pages/Settings/Notifications/SidePanel/DropDownBox/CollapseButton/CollapseButton';
import { useNotifications, NotificationsContextType } from 'pages/Settings/Notifications/NotificationsContext';

const NotificationTypeBox = ({ type }: { type: string }) => {
  const { setActionMode, setNotificationType } = useNotifications() as NotificationsContextType;
  const item = NOTIFICATION_OPTIONS[type as keyof typeof NOTIFICATION_OPTIONS];

  const handleOnClick = () => {
    setActionMode('new');
    setNotificationType(type);
  };

  return (
    <Grid
      container
      className="add-notification-item-box"
      justifyContent="space-between"
      alignItems="center"
      onClick={handleOnClick}
      data-testid="add-notification-type-box"
    >
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Grid container alignItems="center" height={16}>
              <Grid item>{item.icon}</Grid>
              <Grid item sx={{ paddingLeft: 1.25 }}>
                <Text textSize={13} weight={500}>
                  {item.title}
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="add-notification-item-desc">
            <Text textSize={12} weight={400} textColor="#60646A">
              {item.desc}
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="add-notification-item-img">
        {svgCollapse}
      </Grid>
    </Grid>
  );
};

export default NotificationTypeBox;
