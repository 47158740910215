import React from 'react';
import { Divider, Grid } from '@mui/material';

import './mobilePanelSideBody.scss';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';

export const texts = {
  version: 'Version',
  type: 'Type',
  url: 'Store URL'
};
interface IPanelSideBody {
  panelData: any;
}
const MobilePanelSideBody: React.FC<IPanelSideBody> = ({ panelData }) => {
  const fields = ['version', 'type', 'url'];

  return (
    <Grid container className="panel-body-container">
      <Grid item sm={12} sx={{ paddingTop: 3 }}>
        {fields
          .filter(field => !!panelData[field])
          .map(field => (
            <React.Fragment key={field}>
              <Divider className="panel-body-divider" />

              <Grid container key={field} sx={{ padding: '8px 0' }}>
                <Grid item sm={12} className="panel-body-field">
                  {texts[field as keyof typeof texts]}
                </Grid>
                <Grid item sm={12} className="panel-body-field-text">
                  {field !== 'url' ? panelData[field] : <LinkNewTab to={panelData[field]} text={panelData[field]} />}
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    </Grid>
  );
};

export default MobilePanelSideBody;
