import { Switch, Route, Redirect } from 'react-router-dom';
import { ActiveProtectionModule } from 'pages/active-protection/ActiveProtectionModule';
import { DashboardModule } from 'pages/dashboard/DashboardModule';
import { Assesments } from 'pages/assesments/Assesments';
import { Settings } from 'pages/Settings/Settings';
import { NotFound } from 'notFound/NotFound';
import { SingleAsset } from 'pages/inventory/singleAsset/SingleAsset';
import Inventory from 'pages/inventory/Inventory';
import ActionItems from 'pages/action-items/ActionItems';
import Accounts from 'pages/Accounts/Accounts';
import { Common } from 'common/services/common.service';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import ThreatCenter from 'pages/ThreatCenter/ThreatCenter';

export function PagesRouting() {
  const { user } = useSelector((state: IReduxState) => state.login);

  const shouldRedirectToMssp = () => {
    return (
      user.is_mssp_user &&
      !Common.getParamFromUrl('account') &&
      !window.location.pathname.includes('accounts') &&
      !window.location.pathname.includes('settings')
    );
  };

  if (shouldRedirectToMssp()) {
    return <Redirect to="/pages/accounts" />;
  }

  return (
    <Switch>
      <Route path="/pages/dashboard" component={DashboardModule} />

      <Route path="/pages/accounts" component={Accounts} />

      <Route path="/pages/actionItems/:tab" component={ActionItems} />

      <Route path="/pages/discovery/:tab" component={Inventory} />

      <Route path="/pages/singleAsset/:page/:id/:tab" component={SingleAsset} />

      <Route path="/pages/activeProtection" component={ActiveProtectionModule} />

      <Route path="/pages/threatCenter" component={ThreatCenter} />

      <Route path="/pages/assessments/:tab/:subtab?" component={Assesments} />

      <Route path="/pages/settings/:category/:tab?" component={Settings} />

      <Route path="*" render={() => <NotFound type="404" />} />
    </Switch>
  );
}
