import { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './areaChart.scss';
import { Common } from 'common/services/common.service';
interface Entry {
  date: string;
  value: string;
}

export function AreaChart(props: any) {
  const svgRef = useRef(null);
  let id = Math.random();
  useEffect(() => {
    if (!props.data) {
      return;
    }
    //Tooltip
    let tooltip: any = d3.select(`.ttG-d3-scope`);
    function createGraph() {
      let color = props.is_graph_positive ? '#69b3a2' : '#F46666';
      let parseTime = d3.timeParse('%m/%d/%Y');
      // set the dimensions and margins of the graph
      const margin: any = { top: 10, right: 30, bottom: 30, left: 50 },
        width: any = 650,
        height: any = 60;
      // append the svg object to the body of the page
      const svg = d3.select(svgRef.current);
      svg
        .selectAll('*')
        .remove()
        .attr('transform', `translate(${margin.left},${margin.top})`);

      if (props.is_display_measurement_lines) {
        const scaleLinear = d3
          .scaleLinear()
          .domain(d3.extent(props.data, (d: Entry) => +d.value) as [number, number])
          .rangeRound([height, 0]);
        svg
          .append('g')
          .attr('class', 'y-axis')
          // .attr("strokeDasharray", "7")
          .call(
            d3
              .axisLeft(scaleLinear)
              .ticks(5)
              .tickSizeOuter(0)
              .tickSize(-width)
              .tickFormat(d => '')
          );
      }

      // Add X axis --> it is a date format
      const x: any = d3
        .scaleTime()
        .domain(d3.extent(props.data, (d: Entry) => parseTime(splitTime(d.date))) as [Date, Date])
        .range([0, width]);

      svg
        .append('g')
        .attr('class', props.is_display_x ? 'areaChart-x display-x' : 'areaChart-x udisplay-x')
        .attr('transform', `translate(0,${height})`)
        .call(
          d3
            .axisBottom(x)
            .ticks(6)
            .tickSizeOuter(0)
        );

      // Add Y axis
      const y = d3
        .scaleLinear()
        .domain(d3.extent(props.data, (d: Entry) => +d.value) as [number, number])
        .range([height, 0]);
      svg
        .append('g')
        .attr('class', props.is_display_y ? 'display-y' : 'udisplay-y')
        .attr('transform', 'translate(-5,0)')
        .call(
          d3
            .axisLeft(y)
            .ticks(4)
            .tickSizeOuter(0)
        );

      const lg = svg
        .append('defs')
        .append('linearGradient')
        .attr('id', `mygrad-${id}`)
        .attr('x1', '0%')
        .attr('x2', '0%')
        .attr('y1', '0%')
        .attr('y2', '100%'); //since its a vertical linear gradient
      lg.append('stop')
        .attr('offset', '0%')
        .style('stop-color', color)
        .style('stop-opacity', 1);

      lg.append('stop')
        .attr('offset', '100%')
        .style('stop-color', '#ffffff00')
        .style('stop-opacity', 1);

      // Add the area
      svg
        .append('path')
        .datum(props.data)
        .style('fill', `url(#mygrad-${id})`)
        .attr('class', 'path-chart-c')
        .attr('fill-opacity', 0.3)
        .attr('stroke', 'none')
        .attr(
          'd',
          d3
            .area()
            .x((d: any) => x(parseTime(splitTime(d.date))))
            .y0(height)
            .y1((d: any) => y(d.value))
        );

      // const transitionPath = d3.transition().ease(d3.easeSin).duration(800);
      // Add the line
      svg
        .append('path')
        .attr('class', 'path-chart')
        .datum(props.data)
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', 2)
        // .transition(transitionPath)
        .attr(
          'd',
          d3
            .line()
            .x((d: any) => x(parseTime(splitTime(d.date))))
            .y((d: any) => y(d.value))
        );

      drawRects(svg, x, y, parseTime, height);
      drawCircles(svg, color, x, y, parseTime);
    }

    function splitTime(time: string) {
      return time.split('T')[0];
    }

    function drawRects(svg: any, x: any, y: any, parseTime: any, height: number) {
      if (props.is_display_vertical_lines) {
        let rect = svg
          .selectAll('myrect')
          .data(props.data)
          .enter();
        rect
          .append('rect')
          .attr('x', (e: any) => {
            return x(parseTime(splitTime(e.date)));
          })
          .attr('y', (e: any) => y(e.value))
          .attr('width', '0.01')
          .attr('height', (e: any) => height - y(e.value))
          .attr('stroke', '#B7BBC5')
          .attr('stroke-dasharray', '9')
          .attr('stroke-width', '.06vw');

        let rectHidden = rect
          .append('rect')
          .attr('x', (e: any) => {
            return x(parseTime(splitTime(e.date)));
          })
          .attr('y', (e: any) => y(e.value))
          .attr('width', '6')
          .attr('height', (e: any) => height - y(e.value))
          .attr('stroke', '#ffffff00')
          .attr('fill', '#ffffff00');

        toggleOpenTooltip(rectHidden);
      }
    }

    function drawCircles(svg: any, color: string, x: any, y: any, parseTime: any) {
      // Add the circles
      let circles = svg
        .selectAll('myCircles')
        .data(props.data)
        .enter()
        .filter((data: any, index: number) => (props.is_accentuate_graph_end ? index === 0 : data));

      circles
        .append('circle')
        .attr('fill', 'black')
        .attr('stroke', color)
        .attr('cx', (d: any) => x(parseTime(splitTime(d.date))))
        .attr('cy', (d: any) => y(d.value))
        .attr('r', 4);

      let circleHidden = circles
        .append('circle')
        .attr('id', (d: any, index: any) => {
          d.isEnd = props.data.length - 1 === index || 0 === index;
          return d.index;
        })
        .attr('stroke', '#ffffff00')
        .attr('fill', '#ffffff00')
        .attr('cx', (d: any) => x(parseTime(splitTime(d.date))))
        .attr('cy', (d: any) => y(d.value))
        .attr('r', 10);

      toggleOpenTooltip(circleHidden);
    }

    function hiddenTooltip() {
      tooltip.style('opacity', 0);
      tooltip.style('display', 'none');
    }

    function toggleOpenTooltip(element: any) {
      element
        .on('mouseover', (e: any, d: any, y: any) => {
          tooltip.style('opacity', 1).style('display', 'block');
          setTimeout(() => {
            let left = d.isEnd ? e.pageX - tooltip.node().offsetWidth : e.pageX + 10;
            let top = d.isEnd ? e.pageY : e.pageY;
            tooltip
              .html(onTooltipTemplate(d))
              .style('left', left + 'px')
              .style('top', top + 'px');
          });
        })
        .on('wheel', hiddenTooltip)
        .on('mouseout', hiddenTooltip);
    }

    createGraph();
  }, [props, id]);

  function onTooltipTemplate(event: any) {
    return `
    <div class="ttG-d3">
      <div class="ttG-header-scope">
        <div class="">
          <div class="ttG-d3-header">${event.header}</div>
          <div class="ttG-d3-date">${Common.getDateFormat(event.date)}</div>
        </div>
        <div  class="ttG-d3-value">${event.label}</div>
      </div>

      ${
        event.added || event.removed
          ? `
          <ul class="last-scan-scope">
             ${
               event.added
                 ? `<li>
                  <span class="last-scan-add">${event.added}</span>
                  <span>Added from last scan</span>
                 </li>`
                 : ''
             }
              ${
                event.removed
                  ? `<li>
                  <span class="last-scan-remove">${event.removed}</span>
                  <span>Removed from last scan</span>
                 </li>`
                  : ''
              }
          </ul>
        `
          : ''
      }

         ${event.isEnd ? '' : `<div class="ttG-d3-pointer-left"></div>`}
    </div>
    `;
  }

  return (
    <div className="areaChart-scope">
      <svg ref={svgRef} width="100%" height="100%" viewBox={`-60 -15 750 100`} />
    </div>
  );
}
