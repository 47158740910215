import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RestApi } from 'common/services/rest-api.service';
import { getRows } from 'features/GridSlice';
import { fetchMetaGroupByName, resetMetaGroupsState, getGroups } from 'features/GroupsSlice';
import { fetchAllSubsidiaries } from 'features/SubsidiariesSlice';
import { showSuccess, showError } from 'features/AlertSlice';
import { AppLoading } from 'common/components/appLoading/appLoading';
// import { Common } from 'common/services/common.service';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { Popup, Text, DropdownBox, BlockDivider } from '@cyberpion/cyberpion-ui';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import GroupRelationshipTypeBox from './GroupRelationshipTypeBox/GroupRelationshipTypeBox';
import { IReduxState } from 'app/store';

import './MetaGroupsPopup.scss';

interface IMetaGroupsPopup {
  open: boolean;
  onClose: () => void;
}
interface IMetaGroup {
  name: string;
  collection_a: string[];
  collection_b: string[];
  relationship: number | null;
}

const MetaGroupsPopup: React.FC<IMetaGroupsPopup> = ({ open, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [metaGroupName, setMetaGroupName] = useState('');
  const [collectionA, setCollectionA] = useState<any>([]);
  const [collectionB, setCollectionB] = useState<any>([]);
  const [selectedRelation, setSelectedRelation] = useState<number>(1);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [isErrorA, setIsErrorA] = useState(false);
  const [isErrorB, setIsErrorB] = useState(false);
  const {
    groups,
    metaGroupPopupVisible,
    metaGroupSelectedId,
    metaGroupEditMode,
    selectedMetaGroup,
    statusGroups
  } = useSelector((state: IReduxState) => state.groups);
  const { allSubsidiaries, statusAllSubsidiaries } = useSelector((state: IReduxState) => state.subsidiaries);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    setIsLoading(true);
    dispatch(getGroups({ showGroupsWithoutSubsidiaries: true }));
    dispatch(fetchAllSubsidiaries());
  }, [dispatch]);

  useEffect(() => {
    if (statusGroups === 'succeed' && statusAllSubsidiaries === 'succeed' && !metaGroupEditMode) {
      const optionsGroups = groups.map(({ id, name }: { id: number; name: string }) => ({
        id,
        value: name,
        label: name,
        checked: false,
        disabled: false
      }));
      const optionsSubsidiaries = allSubsidiaries.map(({ id, name }: { id: number; name: string }) => ({
        id,
        value: name,
        label: name,
        checked: false,
        disabled: false
      }));

      setCollectionA([...optionsGroups, ...optionsSubsidiaries].map((opt: any) => ({ ...opt })));
      setCollectionB([...optionsGroups, ...optionsSubsidiaries].map((opt: any) => ({ ...opt })));
      setTimeout(() => setIsLoading(false), 500);
    }
  }, [groups, allSubsidiaries, metaGroupEditMode, statusGroups, statusAllSubsidiaries]);

  useEffect(() => {
    const setMetaGroupData = () => {
      const groupsA = groups.map(({ id, name }: { id: number; name: string }) => ({
        id,
        value: name,
        label: name,
        checked: selectedMetaGroup.collection_a.includes(name),
        disabled: selectedMetaGroup.collection_b.includes(name)
      }));
      const subsidiariesA = allSubsidiaries.map(({ id, name }: { id: number; name: string }) => ({
        id,
        value: name,
        label: name,
        checked: selectedMetaGroup.collection_a.includes(name),
        disabled: selectedMetaGroup.collection_b.includes(name)
      }));
      const groupsB = groups.map(({ id, name }: { id: number; name: string }) => ({
        id,
        value: name,
        label: name,
        checked: selectedMetaGroup.collection_b.includes(name),
        disabled: selectedMetaGroup.collection_a.includes(name)
      }));
      const subsidiariesB = allSubsidiaries.map(({ id, name }: { id: number; name: string }) => ({
        id,
        value: name,
        label: name,
        checked: selectedMetaGroup.collection_b.includes(name),
        disabled: selectedMetaGroup.collection_a.includes(name)
      }));

      setMetaGroupName(selectedMetaGroup.name);
      setCollectionA([...groupsA, ...subsidiariesA]);
      setCollectionB([...groupsB, ...subsidiariesB]);

      setSelectedRelation(selectedMetaGroup?.relationship?.relationship || 1);
      // setIsLoading(false);
      setTimeout(() => setIsLoading(false), 500);
    };

    if (!!selectedMetaGroup && !!Object.keys(selectedMetaGroup).length && metaGroupEditMode) {
      setMetaGroupData();
    }
  }, [selectedMetaGroup, metaGroupEditMode, dispatch, groups, allSubsidiaries]);

  useEffect(() => {
    if (metaGroupPopupVisible && metaGroupEditMode && (!!groups.length || !!allSubsidiaries.length)) {
      setIsLoading(true);
      dispatch(fetchMetaGroupByName(metaGroupSelectedId));
    }
  }, [metaGroupPopupVisible, metaGroupEditMode, metaGroupSelectedId, groups, dispatch, allSubsidiaries]);

  const validate = (): boolean => {
    const toggleA = collectionA.filter((option: any) => option.checked);
    const toggleB = collectionB.filter((option: any) => option.checked);
    setIsErrorA(!toggleA.length);
    setIsErrorB(!toggleB.length);

    return metaGroupName !== '' && !!toggleA.length && !!toggleB.length;
  };

  const onSave = () => {
    setClickedSubmit(true);
    if (!validate()) {
      return false;
    }
    setIsLoading(true);

    const data: IMetaGroup = {
      name: metaGroupName,
      collection_a: collectionA.filter((a: any) => !!a.checked).map((a: any) => a.value),
      collection_b: collectionB.filter((b: any) => !!b.checked).map((b: any) => b.value),
      relationship: selectedRelation
    };

    RestApi.setData(
      `${Paths[PathNames.metaGroups]}${metaGroupEditMode ? metaGroupSelectedId + '/' : ''}`,
      data,
      metaGroupEditMode ? 'PUT' : 'POST'
    ).subscribe(
      (response: any) => {
        // IN ORDER TO SEE THE LOADING ANIMATION (WITHOUT IT LOOKS LIKE WHITE SCREEN)
        setTimeout(() => {
          dispatch(resetMetaGroupsState());
          dispatch(getRows());
          dispatch(showSuccess(`Meta Groups ${metaGroupEditMode ? 'Updated' : 'Created'} Successfully`));
          setIsLoading(false);
        }, 500);
      },
      error => {
        setIsLoading(false);
        dispatch(showError(error));
      }
    );
  };

  const handleNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClickedSubmit(false);
    setMetaGroupName(event.target.value);
  };

  const handleDropdownOnChange = (id: string, selected: any) => {
    let updatedA, updatedB;
    if (id === 'collectionA') {
      updatedA = collectionA.map((option: any) => ({
        ...option,
        checked: selected.includes(option.value)
      }));
      updatedB = collectionB.map((option: any) => ({
        ...option,
        disabled: selected.includes(option.value)
      }));
    } else if (id === 'collectionB') {
      updatedB = collectionB.map((option: any) => ({
        ...option,
        checked: selected.includes(option.value)
      }));
      updatedA = collectionA.map((option: any) => ({
        ...option,
        disabled: selected.includes(option.value)
      }));
    }

    setCollectionA([...updatedA]);
    setCollectionB([...updatedB]);

    setClickedSubmit(false);
  };

  const handleDropdownOnClear = (id: string) => {
    const collectionValues = id === 'collectionA' ? collectionA : collectionB;
    const collectionDisabled = id === 'collectionA' ? collectionB : collectionA;
    const updatedValues = collectionValues.map((option: any) => ({
      ...option,
      checked: false
    }));
    const updatedDisabled = collectionDisabled.map((option: any) => ({
      ...option,
      disabled: false
    }));

    setCollectionA(id === 'collectionA' ? updatedValues : updatedDisabled);
    setCollectionB(id === 'collectionA' ? updatedDisabled : updatedValues);
    setClickedSubmit(false);
  };

  const handleRelationOnClick = (index: number) => () => {
    setClickedSubmit(false);
    setSelectedRelation(index);
    setClickedSubmit(false);
  };

  const handleOnClose = () => {
    dispatch(resetMetaGroupsState());
    onClose();
  };
  const popupFooterActions = {
    onCancel: handleOnClose,
    onSave: onSave
  };

  const relationshipType = ['substractBFromA', 'substractAFromB', 'intersect', 'combine', 'union'];

  return (
    <Popup
      open={open}
      type="simple"
      title="Groups"
      subtitle="Meta Groups"
      onClose={handleOnClose}
      actions={popupFooterActions}
    >
      {isLoading ? (
        <AppLoading />
      ) : (
        <div style={{ padding: '0 30px 15px 20px' }} data-testid="meta-groups-popup-content">
          <Text textColor="#8C909A" style={{ paddingRight: 10, lineHeight: '18px' }}>
            Use the view below to define your Meta group. Creation of changes of the Meta groups may take several
            minutes to apply
          </Text>
          <Grid container direction="column">
            <Grid item>
              <FormControl sx={{ width: '100%' }}>
                <OutlinedInput
                  id="name"
                  className="meta-group-name"
                  placeholder="Meta Group Name"
                  value={metaGroupName}
                  onChange={handleNameOnChange}
                  error={clickedSubmit && metaGroupName === ''}
                  inputProps={{ maxLength: 100 }}
                  disabled={!!metaGroupEditMode}
                />
              </FormControl>
            </Grid>
            <Grid item sx={{ paddingTop: 2.75, paddingBottom: 0.75 }}>
              <BlockDivider headerText="Set groups" />
            </Grid>
            <Grid item sx={{ padding: '20px 0' }}>
              <DropdownBox
                id="collectionA"
                data={collectionA}
                label={'Collection A'}
                type={'checkbox'}
                isOpen={false}
                isClear
                isSearch
                isError={clickedSubmit && isErrorA}
                onClear={handleDropdownOnClear}
                onChange={handleDropdownOnChange}
              />
            </Grid>
            <Grid item>
              <DropdownBox
                id="collectionB"
                data={collectionB}
                label={'Collection B'}
                type={'checkbox'}
                isClear
                isOpen={false}
                isSearch
                isError={clickedSubmit && isErrorB}
                onClear={handleDropdownOnClear}
                onChange={handleDropdownOnChange}
              />
            </Grid>
            <Grid item sx={{ padding: '25px 0' }}>
              <BlockDivider headerText="Set groups relationship" />
            </Grid>
            <Grid item>
              <Grid container>
                {relationshipType.map((type: string, index: number) => (
                  <Grid
                    item
                    key={index}
                    sx={index % 2 === 0 ? { marginRight: 1.25, marginBottom: 2.25 } : {}}
                    flexGrow={index % 2 === 0 && index === relationshipType.length - 1 ? 0 : 1}
                  >
                    <GroupRelationshipTypeBox
                      type={type}
                      selected={selectedRelation === index + 1}
                      onClick={handleRelationOnClick(index + 1)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </Popup>
  );
};

export default memo(MetaGroupsPopup);
