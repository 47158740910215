import React from 'react';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';
import CompromisedDevice from 'common/components/GenerateThreatsReport/pages/Examples/CompromisedDevice/CompromisedDevice';
import AssetTest from 'common/components/GenerateThreatsReport/pages/Examples/AssetTest/AssetTest';
// import HijackableAsset from 'common/components/GenerateThreatsReport/pages/Examples/HijackableAsset/HijackableAsset';
// import DigitalSupplyChain from 'common/components/GenerateThreatsReport/pages/Examples/DigitalSupplyChain/DigitalSupplyChain';
import ActionItems from 'common/components/GenerateThreatsReport/pages/Examples/ActionItems/ActionItems';
import Unknown from 'common/components/GenerateThreatsReport/pages/Examples/Unknown/Unknown';
import Misconfigured from './Misconfigured/Misconfigured';

const Examples = () => {
  const { highlightsExamplesData } = useThreatsReport() as ThreatsReportContextType;

  const getHighlightExamples = () => {
    return highlightsExamplesData.map((example: any) => {
      if (example.pdf_page_type === 'ActionItems') {
        return <ActionItems key={example.id} data={example} />;
      } else if (example.pdf_page_type === 'CompromisedDevice') {
        return <CompromisedDevice key={example.id} data={example} />;
      } else if (example.pdf_page_type === 'AssetTest') {
        return <AssetTest key={example.id} data={example} />;
      } else if (example.pdf_page_type === 'Unknown') {
        return <Unknown key={example.id} data={example} />;
      } else if (example.pdf_page_type === 'Misconfigured') {
        return <Misconfigured key={example.id} data={example} />;
      } else {
        return null;
      }
    });
  };

  return !!highlightsExamplesData && !!highlightsExamplesData.length ? (
    <div className="threats-report-examples">{getHighlightExamples()}</div>
  ) : null;
};

export default Examples;
