import { useState } from 'react';
import './LoginBox.scss';
import { Link } from 'react-router-dom';
import { LoginForm } from './LoginForm/LoginForm';
import { ForgotPasswordForm } from './ForgotPasswordForm/ForgotPasswordForm';
import { ForgotPasswordFlow } from './ForgotPasswordFlow/ForgotPasswordFlow';
import { AppLoading } from 'common/components/appLoading/appLoading';
import classNames from 'classnames';

export function LoginBox() {
  const isForgotPassword = window.location.pathname.includes('forgot-password');
  const isNewUser = window.location.pathname.includes('new-user');
  const [loading, setLoading] = useState<boolean>(false);

  const [isResetPassword, setIsResetPassword] = useState<boolean>(isForgotPassword || isNewUser);

  const toggleForgotPassword = (val: boolean) => {
    setLoading(true);
    const timer = setTimeout(() => {
      clearTimeout(timer);
      setIsResetPassword(val);
      setLoading(false);
    }, 1000);
  };

  return (
    <div className={classNames('LoginBox', { loading: loading })}>
      <img src="/assets/images/logo-ionix-black.svg" alt="Logo" />
      <div className="main-form-area">
        {<AppLoading size="small" />}
        {(isForgotPassword || isNewUser) && isResetPassword ? (
          <ForgotPasswordFlow isNewUser={isNewUser} onBack={() => setIsResetPassword(false)} />
        ) : (
          <>
            {isResetPassword ? (
              <ForgotPasswordForm visible={isResetPassword} onBack={() => setIsResetPassword(false)} />
            ) : (
              <LoginForm visible={!isResetPassword} />
            )}
          </>
        )}
      </div>
      <div className="bottom">
        {!isForgotPassword && !isNewUser && (
          <div className="buttons">
            {!isResetPassword ? (
              <Link to="#" onClick={() => toggleForgotPassword(true)}>
                Lost your password?
              </Link>
            ) : (
              <Link to="#" onClick={() => toggleForgotPassword(false)} className="back-to-login">
                Back to login
              </Link>
            )}
          </div>
        )}
        <div className="support">
          For general assistance please contact our <a href="mailto:support@ionix.io">support team</a>
        </div>
      </div>
    </div>
  );
}
