import React from 'react';
import { topPanelConfig } from '../configs/topPanelConfig';
import TopPanelLinksConfig from '../configs/TopPanelLinksConfig';
import TopPanelActionItems from '../TopPanelActionItems/TopPanelActionItems';
import TopPanelDashboard from '../TopPanelDashboard/TopPanelDashboard';
import TopPanelDiscovery from '../TopPanelDiscovery/TopPanelDiscovery';
import TopPanelActiveProtection from '../TopPanelActiveProtection/TopPanelActiveProtection';
import TOP_PANEL_PAGES from 'common/constants/topPanelPages';
import TopPanelAssessments from '../TopPanelAssessments/TopPanelAssessments';
import TopPanelSingleAsset from '../TopPanelSingleAsset/TopPanelSingleAsset';
import {
  IconAWS,
  IconAzure,
  IconGCP,
  IconAkamai,
  IconIncapsula,
  IconCloudflare,
  IconIP,
  IconOther,
  IconDomain,
  IconSubDomain
} from '@cyberpion/cyberpion-ui';
import { IconHeroku } from 'common/components/Icons/IconHeroku/IconHeroku';
import { IconOVH } from 'common/components/Icons/IconOVH/IconOVH';
import { IconNetlify } from 'common/components/Icons/IconNetlify/IconNetlify';
import { IconEdgio } from 'common/components/Icons/IconEdgio/IconEdgio';
import { IconVerizon } from 'common/components/Icons/IconVerizon/IconVerizon';
import { Common } from 'common/services/common.service';
// import { useHistory } from 'react-router-dom';

export const iconByID = {
  domain: <IconDomain />,
  subdomain: <IconSubDomain />,
  ip: <IconIP />,
  aws: <IconAWS />,
  azure: <IconAzure />,
  akamai: <IconAkamai />,
  gcp: <IconGCP />,
  other: <IconOther />,
  cloudflare: <IconCloudflare />,
  incapsula: <IconIncapsula />,
  googlecloud: <IconIP />,
  heroku: <IconHeroku />,
  ovh: <IconOVH />,
  netlify: <IconNetlify />,
  edgio: <IconEdgio />,
  verizon: <IconVerizon />
};

export const getTechIcon = (id: string) => {
  let iconId = id.indexOf('(') > -1 ? id.split('(')[0] : id;
  iconId = iconId.replace(/\s/g, '');
  return iconByID[iconId as keyof typeof iconByID] ? iconByID[iconId.trim() as keyof typeof iconByID] : <IconIP />;
};

const useTopPanel = (page: string) => {
  const { isExpandable } = topPanelConfig[page as keyof typeof topPanelConfig];
  // const history = useHistory();

  const links = TopPanelLinksConfig[page as keyof typeof topPanelConfig];
  const additionalProps = !!links && !!Object.keys(links) ? { links } : {};

  const handleLinkOnClick = (link: string) => {
    window.open(Common.addGlobalFilterIfNeeded(Common.AddMsspAccountIfNeeded(links[link])), '_blank');
    // history.push(links[link]);
  };

  const getTopPanelInContext = () => {
    switch (page) {
      case TOP_PANEL_PAGES.DASHBOARD:
        return <TopPanelDashboard linkOnClick={handleLinkOnClick} {...additionalProps} />;
      case TOP_PANEL_PAGES.ACTION_ITEMS:
        return <TopPanelActionItems />;
      case TOP_PANEL_PAGES.DISCOVERY:
        return <TopPanelDiscovery />;
      case TOP_PANEL_PAGES.ACTIVE_PROTECTION:
        return <TopPanelActiveProtection />;
      case TOP_PANEL_PAGES.ASSESSMENTS:
        return <TopPanelAssessments />;
      case TOP_PANEL_PAGES.SINGLE_ASSET:
        return <TopPanelSingleAsset />;

      default:
        return null;
    }
  };

  return { getTopPanelInContext, isExpandable };
};

export default useTopPanel;
