import React, { forwardRef } from 'react';
import { ConfidenceLevel, IconImportance, IconRisk, Text } from '@cyberpion/cyberpion-ui';

const UnknownDetails = forwardRef(({ data }: { data: any }, refDetails: any) => {
  const { singleAsset, details, row } = data;
  const ips =
    singleAsset?.ips ||
    singleAsset?.domain?.ips ||
    singleAsset?.subdomain?.ips ||
    singleAsset?.managed_domain?.ips ||
    singleAsset?.cloud_asset?.ips;

  return (
    <div ref={refDetails} className="unknown-details">
      <div>
        <Text weight={600} style={{ marginBottom: 4 }} upper textSize={13}>
          {details.asset}
        </Text>
        <Text textSize={12}>Potentially Unknown</Text>
      </div>

      <div style={{ display: 'flex', marginTop: 24, alignItems: 'center' }}>
        <div className="risk-icon" style={{ marginRight: 4 }}>
          <IconRisk urgency={singleAsset?.risk_score?.type} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            Asset Risk
          </Text>
          <Text>{singleAsset?.risk_score?.risk_score}</Text>
        </div>
        {singleAsset?.importance && (
          <div
            style={{
              display: 'flex',
              paddingRight: 16,
              paddingLeft: 16,
              alignItems: 'center',
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <div>
              <IconImportance type={singleAsset?.importance?.type} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 6 }}>
              <Text weight={600} style={{ marginBottom: 4 }}>
                Asset Importance
              </Text>
              <Text>{singleAsset?.importance?.type}</Text>
            </div>
          </div>
        )}
        {singleAsset?.confidence && (
          <div
            style={{
              display: 'flex',
              paddingRight: 16,
              paddingLeft: 16,
              alignItems: 'center',
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <Text weight={600} style={{ marginBottom: 4 }}>
              Discovery Confidence
            </Text>
            <ConfidenceLevel label={singleAsset?.confidence} size="small" showInGrid />
          </div>
        )}
        {!!row?.maintenance_grade?.type && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: 1,
              paddingLeft: 16,
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <Text weight={600} style={{ marginBottom: 4 }}>
              Maintenance Grade
            </Text>
            <Text upper>{row.maintenance_grade.type}</Text>
          </div>
        )}
      </div>
      {!!row?.bad_maintenance_indicators && (
        <div style={{ marginTop: 24, alignItems: 'center' }}>
          <Text textSize={11} weight={600} style={{ marginBottom: 4 }}>
            Indicators
          </Text>
          <Text textColor="#454549">{row.bad_maintenance_indicators.join(' / ')}</Text>
        </div>
      )}
      {!!ips && !!ips.length && (
        <div style={{ marginTop: 24, alignItems: 'center' }}>
          <Text textSize={11} weight={600} style={{ marginBottom: 4 }}>
            {`${singleAsset?.type !== 'ip' ? 'IPs' : 'IP Network'}`}
          </Text>
          <Text textColor="#454549">{ips.join(' / ')}</Text>
        </div>
      )}
    </div>
  );
});

export default UnknownDetails;
