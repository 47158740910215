import './booleanRenderer.scss';
export function BooleanRenderer(props: any) {
  //@param: value = boolean
  //@return: Capitalize the first letter
  const capitalizeFirstLetter = (value: boolean) => {
    try {
      let str = value.toString();
      return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
    } catch (error) {
      return 'False';
    }
  };

  return <div className="BooleanRenderer-scope">{capitalizeFirstLetter(props.value)}</div>;
}
