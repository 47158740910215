import { useDispatch, useSelector } from 'react-redux';
import { setSelectFiltersHard } from 'features/GridSlice';
import { getFiltersServerFormat } from 'utils/filters';
import { selectView } from 'features/ViewsSlice';

const useFilters = () => {
  const { columnPicker } = useSelector((state: any) => state.columnPicker);
  const dispatch = useDispatch();

  const handleFiltersOnClick = (filterId: string, fieldId: string, page?: string, findValue?: string) => {
    dispatch(selectView(null));

    const selected = columnPicker.find((item: any) => item.name === filterId);
    if (!!selected) {
      const { filter_type: filterType } = selected;
      let values;
      let filter;
      if (filterType === 'stringAndNumber') {
        values = selected.choices.find((item: any) => item.label.toLowerCase() === 'full match');
        const updatedValue = { ...values, value: fieldId };
        filter = getFiltersServerFormat(filterType, selected, updatedValue);
      } else if (filterType === 'radio') {
        values = selected.choices.find(
          (item: any) => item.name.toLowerCase() === (findValue !== undefined ? findValue : 'true')
        );
        filter = getFiltersServerFormat(filterType, selected, values);
      } else {
        values = selected.choices.find((item: any) => item.label.toLowerCase() === fieldId.toLowerCase());
        filter = getFiltersServerFormat(filterType, selected, [values.name]);
      }

      dispatch(setSelectFiltersHard([filter]));
    }
  };

  return { handleFiltersOnClick };
};

export default useFilters;
