import { useEffect, useState } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import { useSelector } from 'react-redux';
import { AppLoading } from 'common/components/appLoading/appLoading';

import './ActionItemsOverTimeContainer.scss';
import { ActionItemsOverTimeWidget } from '@cyberpion/cyberpion-ui';

const ActionItemsOverTimeContainer = (props: any) => {
  const [chartData, setChartData] = useState<any>(null);
  const { globalFilter } = useSelector((state: any) => state.general);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>(null);
  const [selectedRisk, setSelectedRisk] = useState(['critical', 'high', 'medium', 'low']);

  const fetchAll = async () => {
    setIsLoading(true);
    const url = `${Paths[PathNames.action_items_graph]}${!!globalFilter ? `?group=${globalFilter}` : ''}`;
    RestApi.getData(url).subscribe((response: any) => {
      setChartData(response);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  const handleFilterOnClick = (filter: any) => {
    setFilter(filter);
    if (!filter) {
      fetchAll();
      return;
    }

    let url =
      filter.value === 'created' ? Paths[PathNames.action_items_graph_open] : Paths[PathNames.action_items_graph_close];
    if (!!globalFilter) {
      url = `${url}?group=${globalFilter}`;
    }
    setIsLoading(true);
    RestApi.getData(url).subscribe((response: any) => {
      setChartData(response);
      setIsLoading(false);
    });
  };

  return (
    <div className="ActionItemsOverTimeContainer">
      <div className="overtime-wrapper">
        {isLoading ? (
          <AppLoading />
        ) : (
          <ActionItemsOverTimeWidget
            data={chartData}
            filterOnClick={handleFilterOnClick}
            selectedRisk={selectedRisk}
            setSelectedRisk={setSelectedRisk}
            chartOptions={{ chart: { height: 200, backgroundColor: '#FCFCFD' } }}
            filter={filter}
          />
        )}
      </div>
    </div>
  );
};

export default ActionItemsOverTimeContainer;
