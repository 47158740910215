import { PieChart } from 'common/components/pieChart/PieChart';
import { Common } from 'common/services/common.service';
import './pieChartForm.scss';
export function PieChartForm(props: any) {
  return Common.isNotEmpty(props) ? (
    <div className="pieChartForm-scope">
      <PieChart
        show_color_number={true}
        show_percent_label={false}
        show_header_legend={true}
        is_black_mode={false}
        size_pie_chart="150px"
        {...props}
      />
    </div>
  ) : (
    <div className="pieChartForm-scope display-center">No Data</div>
  );
}
