import { AppLoading } from 'common/components/appLoading/appLoading';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { PopupHeader } from 'common/components/PopupHeader/PopupHeader';
import { RestApi } from 'common/services/rest-api.service';
import { getRows } from 'features/GridSlice';
import * as EmailValidator from 'email-validator';
import { WhiteListEdit } from '../../WhiteListEdit/WhiteListEdit';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './AddUserPopUp.scss';
import { tosterService } from 'common/services/tostar.service';
import { IServerErrors } from 'common/interfaces/interface';
import classNames from 'classnames';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { DynamicMultiChoiceDropDown } from 'common/components/DynamicMultiChoiceDropDown/DynamicMultiChoiceDropDown';
import { IReduxState } from 'app/store';
import { getGroups } from 'features/GroupsSlice';
import USER_ROLES from 'common/constants/roles';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { LoginService } from 'login/services/login.service';

type E164Number = string;

export function AddUserPopUp(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [userTypeMenuOpen, setUserTypeMenuOpen] = useState<boolean>(false);
  const [selectedUserType, setSelectedUserType] = useState<number>(0);
  const [selectedUserTypeError, setSelectedUserTypeError] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [ssoId, setSsoId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [mobile, setMobile] = useState<E164Number | undefined>('');
  const [mobileError, setMobileError] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<IServerErrors>({});
  const [whitelistPopupVisible, setWhitelistPopupVisible] = useState<boolean>(false);
  const [groupsRestrictions, setGroupRestriction] = useState<number[]>([]);
  const dispatch = useDispatch<any>();
  const { user } = useSelector((state: IReduxState) => state.login);

  useEffect(() => {
    document.getElementsByClassName('Settings')[0].classList.add('ontop');
    document.getElementsByClassName('settings-wrapper')[0].classList.add('ontop');
    return () => {
      document.getElementsByClassName('Settings')[0].classList.remove('ontop');
      document.getElementsByClassName('settings-wrapper')[0].classList.remove('ontop');
    };
  }, []);

  const selectUserType = (type: number) => {
    setSelectedUserType(type);
    setUserTypeMenuOpen(false);
  };

  useEffect(() => {
    setFirstNameError(false);
  }, [firstName]);
  useEffect(() => {
    setLastNameError(false);
  }, [lastName]);
  useEffect(() => {
    setEmailError(false);
  }, [email]);
  useEffect(() => {
    setSelectedUserTypeError(false);
  }, [selectedUserType]);
  // useEffect(() => { setMobileError(false) }, [mobile])

  const validate = (): boolean => {
    let hasError = false;

    if (firstName.length < 2) {
      setFirstNameError(true);
      hasError = true;
    }
    if (lastName.length < 2) {
      setLastNameError(true);
      hasError = true;
    }
    if (mobile && !isValidPhoneNumber(mobile)) {
      setMobileError(true);
      hasError = true;
    }

    if (!selectedUserType) {
      setSelectedUserTypeError(true);
      hasError = true;
    }
    if (!EmailValidator.validate(email)) {
      setEmailError(true);
      hasError = true;
    }

    return !hasError;
  };

  const submit = () => {
    if (!validate()) {
      return false;
    }

    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      role: selectedUserType,
      mobile: mobile,
      group_restrictions: selectedUserType < 35 ? undefined : groupsRestrictions,
      sso_id: ssoId ? ssoId : undefined
    };
    setLoading(true);
    setServerErrors({});
    RestApi.setData(`${Paths[PathNames.userManagement]}`, data, 'POST').subscribe(
      () => {
        setLoading(false);
        props.onClose();
        setWhitelistPopupVisible(false);
        dispatch(getRows());
        tosterService.onShowToster('User Added Successfully', 'success', '');
      },
      error => {
        setLoading(false);
        const _error = JSON.parse(error.message);
        if (error.toString().includes('whitelist') && LoginService.isCyberpionUser(user)) {
          setWhitelistPopupVisible(true);
        } else if (_error.detail) {
          tosterService.onShowToster(`Error: ${JSON.parse(error.message).detail}`, 'danger', '');
        } else if (error.toString().includes('whitelist')) {
          setServerErrors({ email: _error });
        } else {
          setServerErrors(_error);
        }
      }
    );
  };

  const renderServerError = (error: string[]): string => {
    return error[0];
  };

  const filterRoles = (role: string): boolean => {
    return role > user.role;
  };

  const cleanSelectedGroupsHTML = (): void => {
    const selectedGroupsHeader = document.getElementById('SELECTED_GROUPS');
    if (selectedGroupsHeader) {
      selectedGroupsHeader.innerHTML = 'Group Restriction';
    }
  };

  return (
    <div className="AddUserPopUp popup-inner-wrapper">
      {whitelistPopupVisible && (
        <WhiteListEdit email={email} onClose={() => setWhitelistPopupVisible(false)} onSubmit={submit} />
      )}
      <PopupHeader text="Create New User" onClose={props.onClose} />
      <div className="popup-inner">
        {loading && <AppLoading />}
        <div className="input-wrapper">
          <input
            className={classNames({ error: firstNameError })}
            type="text"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            maxLength={32}
            placeholder="First Name"
          />
        </div>
        <div className="input-wrapper">
          <input
            className={classNames({ error: lastNameError })}
            type="text"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            maxLength={32}
            placeholder="Last Name"
          />
        </div>
        <div className="input-wrapper">
          {serverErrors.email && <div className="server-error">{renderServerError(serverErrors.email)}</div>}
          <input
            className={classNames({ error: emailError })}
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
            maxLength={254}
            placeholder="Email"
          />
        </div>
        <div className={classNames('input-wrapper', { error: mobileError })}>
          {serverErrors.mobile && <div className="server-error">{renderServerError(serverErrors.mobile)}</div>}
          <PhoneInput
            limitMaxLength={true}
            international
            placeholder="Phone Number (Optional)"
            value={mobile}
            onChange={setMobile}
          />
        </div>
        <div className="input-wrapper">
          {serverErrors.sso_id && <div className="server-error">{renderServerError(serverErrors.sso_id)}</div>}
          <input
            type="text"
            value={ssoId}
            onChange={e => setSsoId(e.target.value)}
            maxLength={128}
            placeholder="SSO ID (Optional)"
          />
        </div>
        <div className={classNames('select-wrapper', { error: selectedUserTypeError })}>
          <div className={`header`} onClick={() => setUserTypeMenuOpen(!userTypeMenuOpen)}>
            {selectedUserType ? USER_ROLES[selectedUserType] : 'User Type'} <IconArrow />
          </div>
          <ul className={userTypeMenuOpen ? 'open' : ''}>
            {Object.keys(USER_ROLES)
              .filter(filterRoles)
              .map((role: string) => {
                const _role = parseInt(role);
                return (
                  <li
                    onClick={() => {
                      selectUserType(_role);
                      if (_role !== 35) {
                        cleanSelectedGroupsHTML();
                      }
                    }}
                  >
                    {USER_ROLES[_role]}
                  </li>
                );
              })}
          </ul>
        </div>
        <DynamicMultiChoiceDropDown
          disabled={selectedUserType <= 30}
          path={`${Paths[PathNames.groups]}?fields=name,id`}
          searchPath={`${Paths[PathNames.groups]}?fields=name,id&name__contains=`}
          fieldName="name"
          headerText="Group Restriction"
          dispatchGetAction={getGroups}
          limitHeaderLength={100}
          onChange={(data: number[]) => {
            setGroupRestriction(data);
          }}
        />

        <div className="buttons-wrapper">
          <button className="btn btn-big btn-secondary" onClick={props.onClose}>
            Cancel
          </button>
          <button className={`btn btn-big btn-main`} onClick={submit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
