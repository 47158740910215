import React from 'react';
import { IIcon } from 'common/interfaces/interface';
export class IconVendorMonitoring extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#5D6576';
    return (
      <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 6.0625L10 1" stroke={color} strokeLinecap="round" />
        <path d="M8.57982 13.9375L8.57988 12.25" stroke={color} strokeLinecap="round" />
        <path d="M11.6876 13.9375L11.6875 12.25" stroke={color} strokeLinecap="round" />
        <path
          d="M10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 7.3345 17.8412 4.93964 16 3.29168"
          stroke={color}
        />
        <path
          d="M12.875 8.875V11.75H7.125V8.875C7.125 7.28718 8.41218 6 10 6C11.5878 6 12.875 7.28718 12.875 8.875Z"
          stroke={color}
        />
      </svg>
    );
  }
}
