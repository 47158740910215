import { HierarchyItems, LabelWithConfidence, LabelWithIcons } from '@cyberpion/cyberpion-ui';
import { CONFIDENCE_LEVEL, STATUSES } from './DragDrop';
import { USER_ICON } from '../../SubHeader/AdditionalSeedAssets/AdditionalSeedAssets';

export function DragPreviewElement(props: any) {
  const elementId = props.item.item.id;

  const getElementObj = (id: string) => {
    return props.idsDict[id.replace('{', '').replace('}', '')];
  };

  const getGroupCount = (ids: string[]) => {
    let hasSelectedItems = 0;
    ids.forEach((id: string) => {
      if (props.selectedItems.includes(id)) {
        hasSelectedItems++;
      }
    });

    const count = props.selectedItems.length - hasSelectedItems;

    if (!count) {
      return null;
    }
    return `+ ${props.selectedItems.length - hasSelectedItems} More`;
  };

  const getSingleCount = (id: string) => {
    const count = props.selectedItems.length + (props.selectedItems.includes(id) ? -1 : 0);
    if (!count) {
      return null;
    }
    return `+ ${props.selectedItems.length + (props.selectedItems.includes(id) ? -1 : 0)} More`;
  };

  if (getElementObj(elementId).verdict_source === STATUSES.MANUAL) {
    return (
      <LabelWithIcons label={getElementObj(elementId).domain || getElementObj(elementId).name} icons={[USER_ICON]} />
    );
  }

  if (getElementObj(elementId).source === STATUSES.MANUAL) {
    if (getElementObj(elementId).names.length) {
      return (
        <div style={{ padding: 10, border: '1px solid #ecedff', boxSizing: 'border-box', width: 470 }}>
          <LabelWithIcons label={getElementObj(elementId).names[0].name} icons={[USER_ICON]} />
        </div>
      );
    } else if (getElementObj(elementId).domains.length) {
      return (
        <div style={{ padding: 10, border: '1px solid #ecedff', boxSizing: 'border-box', width: 470 }}>
          <LabelWithIcons label={getElementObj(elementId).domains[0].domain} icons={[USER_ICON]} />
        </div>
      );
    }
  }

  if (getElementObj(elementId).name) {
    return (
      <div>
        <LabelWithConfidence
          label={getElementObj(elementId).name}
          level={CONFIDENCE_LEVEL[getElementObj(elementId).confidence]}
        />
        {!!getSingleCount(elementId) && <div style={{ marginTop: 5, fontSize: 12 }}>{getSingleCount(elementId)}</div>}
      </div>
    );
  }

  if (getElementObj(elementId).domain) {
    return (
      <div>
        <LabelWithConfidence label={getElementObj(elementId).domain} level={''} hideConfidence showIcon />
        {!!getSingleCount(elementId) && <div style={{ marginTop: 5, fontSize: 12 }}>{getSingleCount(elementId)}</div>}
      </div>
    );
  } else {
    const names = getElementObj(elementId).names.filter((name: any) => name.visible && name.verdict === props.source);
    const domains = getElementObj(elementId).domains.filter(
      (domain: any) => domain.visible && domain.verdict === props.source
    );
    return (
      <div>
        <div style={{ padding: 10, border: '1px solid #ecedff', boxSizing: 'border-box', width: 470 }}>
          <HierarchyItems
            data={{
              top: names.map((name: any) => {
                return <LabelWithConfidence label={name.name} level={CONFIDENCE_LEVEL[name.confidence]} />;
              }),
              bottom: domains.map((domain: any) => {
                return <LabelWithConfidence label={domain.domain} level={''} hideConfidence showIcon />;
              })
            }}
            onlyBottomElements={domains.length && !names.length}
          />
        </div>
        {!!getGroupCount([...names, ...domains].map((a: any) => a.id)) && (
          <div style={{ marginTop: 5, fontSize: 12 }}>
            {getGroupCount([...names, ...domains].map((a: any) => a.id))}
          </div>
        )}
      </div>
    );
  }
}
