import { IReduxState } from 'app/store';
import { setDeletePromptParams } from 'features/GridSlice';
import { useDispatch, useSelector } from 'react-redux';
import './RemoveRow.scss';

export function RemoveRow(props: any) {
  const dispatch = useDispatch<any>();
  const { user } = useSelector((state: IReduxState) => state.login);

  const onClick = () => {
    const id = props.params.id;
    const index = props.params.api.getRowIndex(id);
    const category = props.source.category;
    const tab = props.source.tab;

    dispatch(
      setDeletePromptParams({
        category: category,
        tab: tab,
        index: index,
        ids: [id],
        message: getDeleteMessageByCategory()
      })
    );
  };

  const getDeleteMessageByCategory = (): string => {
    switch (props.source.tab) {
      case 'manage-fqdns':
        return "Are you sure you would like to remove this asset and it's subdomains?";
      case 'subsidiaries':
        return 'Are you sure you would like to remove this subsidiary?';
      default:
        return 'Are you sure you would like to remove this item?';
    }
  };

  const isDeleteDisabled = (): boolean => {
    if (user.email === props.params.row.email || !props.params.row?.actions?.is_deletable) {
      return true;
    }
    return false;
  };

  return (
    <div className="RemoveRow">
      <img
        className={isDeleteDisabled() ? 'disabled' : ''}
        alt="delete user"
        src="/assets/images/trash.svg"
        onClick={onClick}
      />
    </div>
  );
}
