// import React, { useState, useMemo } from 'react';
import { useTopPanelContext } from '../TopPanelContext';
import {
  Text,
  DataPanel,
  Chart,
  Legend,
  LineChart,
  CHART_TYPES,
  TOOLTIP_TYPE,
  LINE_CHART_TYPE,
  ChartEmptyState
} from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import { TRENDS_COLORS, getIsTrendUp, getChartSeries, getChartCategories } from 'common/components/TopPanel/utils';
import './toppanel-active-protection.scss';

const colors = ['#A862FF', '#DB42FF', '#7672F9', '#4B7AE7', '#4C8FDB'];

const texts = {
  panel_I_title: 'PROTECTED ASSETS OVER TIME',
  panel_II_title: 'time to resolution',
  currently_protected: 'Currently Protected',
  added_last_month: 'Added Last Month',
  removed_last_month: 'Removed Last Month',
  currently_insecure: 'Currently Insecure',
  days: 'Days',
  average: 'Average of last 5 days' // FIX: number should be parameter
};
const dataPanelProps = {
  titleTextSize: 11,
  weight: 500,
  textSize: 36,
  textColor: '#FFFFFF',
  family: 'Rajdhani'
};

const textProps = {
  textSize: 12,
  textColor: '#FFFFFF',
  upper: true
};

const pieOptions = {
  chart: { height: 100, backgroundColor: '#2c2c30', marginLeft: -20, padding: 0 }
};

const chartProtectedOptions = {
  chart: {
    type: CHART_TYPES.AREA,
    backgroundColor: '#2c2c30',
    height: 100,
    marginLeft: 0,
    marginRight: 25,
    marginTop: 10,
    marginBottom: 26
  },
  xAxis: {
    visible: true,
    tickWidth: 1,
    tickLength: 5,
    tickmarkPlacement: 'on',
    tickColor: '#B7BBC5'
  },
  yAxis: {
    gridLineWidth: 0,
    labels: {
      enabled: false
    }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 420
        },
        chartOptions: {
          chart: {
            marginBottom: 25,
            marginTop: 10
          },
          xAxis: {
            visible: true,
            labels: {
              enabled: false
            }
          }
        }
      },
      {
        condition: {
          minWidth: 421
        },
        chartOptions: {
          xAxis: {
            visible: true,
            labels: {
              enabled: true
            }
          }
        }
      }
    ]
  }
};

const ActiveProtectionExpand = () => {
  const { data } = useTopPanelContext();
  const { protected_assets, protected_assets_by_service, time_to_resolution } = data;
  const { data: protectedChartData, resources, protected_org_assets: protectedOrgAssets } = protected_assets;
  const { current_average: currentlyInsecure, data: resolutionChartData } = time_to_resolution;

  const updatedProtectedSeries = getChartSeries(protectedChartData);
  const updatedResolutionSeries = getChartSeries(resolutionChartData);
  let protectedChartOptions;
  let resolutionChartOptions;
  let protectedColors;
  let resolutionColors;
  let isTrendUp;
  let isTrendUpResolution;

  if (!!updatedProtectedSeries.length) {
    protectedChartOptions = {
      ...chartProtectedOptions,
      xAxis: {
        ...chartProtectedOptions.xAxis,
        categories: getChartCategories(protectedChartData, 'd')
      }
    };
    isTrendUp = getIsTrendUp(updatedProtectedSeries);
    protectedColors = isTrendUp ? TRENDS_COLORS.BAD : TRENDS_COLORS.GOOD;
  }

  if (!!updatedResolutionSeries.length) {
    resolutionChartOptions = {
      ...chartProtectedOptions,
      xAxis: {
        ...chartProtectedOptions.xAxis,
        categories: getChartCategories(resolutionChartData, 'm')
      }
    };
    isTrendUpResolution = getIsTrendUp(updatedResolutionSeries);
    resolutionColors = isTrendUpResolution ? TRENDS_COLORS.BAD : TRENDS_COLORS.GOOD;
  }

  let pieLegendSeries = protected_assets_by_service.data.map((item: any) => ({
    name: item.label,
    title: item.label,
    y: item.value
  }));

  return (
    <Grid container className="top-panel-active-protection-expand top-panel-wrapper">
      <Grid item sx={{ flex: 0 }}>
        <Grid container direction="column" className="panel-protected-assets-wrapper" sx={{ marginTop: 2 }}>
          <Grid item sx={{ flex: 1 }}>
            <Grid container className="panel-protected-assets horizontal-border chart-wrapper" flexWrap="nowrap">
              <Grid item className="protected-data-panel" sx={{ paddingTop: 2 }}>
                <DataPanel
                  {...dataPanelProps}
                  title={'Resources'}
                  titleStyle={{ whiteSpace: 'nowrap', display: 'flex' }}
                >
                  {resources}
                </DataPanel>
              </Grid>
              <Grid item className="protected-data-panel">
                <DataPanel
                  {...dataPanelProps}
                  title={'Protected Org Assets'}
                  titleStyle={{ whiteSpace: 'nowrap', display: 'flex' }}
                >
                  {protectedOrgAssets}
                </DataPanel>
              </Grid>
              <Grid item className="protected-data-panel">
                <DataPanel
                  {...dataPanelProps}
                  title={'Impacted Connections'}
                  titleStyle={{ whiteSpace: 'nowrap', display: 'flex' }}
                >
                  {updatedProtectedSeries[updatedProtectedSeries.length - 1].y}
                </DataPanel>
              </Grid>
              <Grid item sx={{ flex: 1, minWidth: 440 }}>
                {!!updatedProtectedSeries.length ? (
                  <Chart
                    series={updatedProtectedSeries}
                    colors={protectedColors}
                    chartOptions={protectedChartOptions}
                    chartType={CHART_TYPES.AREA}
                    tooltipType={TOOLTIP_TYPE.AREA}
                    isTooltipPointValueGreen={!isTrendUp}
                    isTooltipUpArrowGreen={false}
                    // isLogarithmic
                  />
                ) : (
                  <ChartEmptyState chartOptions={{ chart: { height: 120, width: 500, marginTop: 10 } }} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 0 }}>
        <div className="vertical-devider"></div>
      </Grid>
      <Grid item sx={{ minWidth: 210 }}>
        <Grid container className="active-protected-pie-wrapper" sx={{ marginTop: 0 }}>
          <Grid item>
            <Chart
              series={pieLegendSeries}
              colors={colors}
              chartOptions={pieOptions}
              tooltipType={TOOLTIP_TYPE.PERCENTAGE}
            />
          </Grid>
          <Grid item>
            <Legend series={pieLegendSeries} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 0 }}>
        <div className="vertical-devider"></div>
      </Grid>
      <Grid item className="critical-action-items" sx={{ flex: 1, textWrap: 'nowrap' }}>
        <Grid container direction="column" className="panel-wrapper" sx={{ marginTop: 2 }}>
          <Grid item className="panel-title">
            <Text textColor="#FFFFFF" textSize={13} upper>
              TIME TO RESOLUTION
            </Text>
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            <Grid container className="horizontal-border">
              <Grid item sx={{ flex: 1 }}>
                <Grid container flexWrap="nowrap">
                  <Grid item className="insecure-data-panel">
                    <DataPanel
                      id="currently_insecure"
                      {...dataPanelProps}
                      title={'Current Average'}
                      textSize={!!currentlyInsecure ? 36 : 32}
                      rightText="Days"
                      contentClassName="small-padding"
                      rightContentClassName="insecure-right-text"
                    >
                      {currentlyInsecure}
                    </DataPanel>
                  </Grid>
                  <Grid item sx={{ flex: 1, width: 300 }}>
                    {!!updatedResolutionSeries.length ? (
                      <Chart
                        chartOptions={resolutionChartOptions}
                        series={updatedResolutionSeries}
                        chartType={CHART_TYPES.AREA}
                        colors={resolutionColors}
                        tooltipType={TOOLTIP_TYPE.AREA}
                        // isLogarithmic
                        isTooltipPointValueGreen={!isTrendUpResolution}
                        isTooltipUpArrowGreen={false}
                      />
                    ) : (
                      <ChartEmptyState
                        chartOptions={{
                          responsive: chartProtectedOptions.responsive,
                          chart: { height: 110, marginBottom: 30 }
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ActiveProtectionCollapse = () => {
  const { data } = useTopPanelContext();
  const { protected_assets, protected_assets_by_service, time_to_resolution } = data;
  const { resources, protected_org_assets: protectedOrgAssets } = protected_assets;
  const { current_average: currentlyInsecure } = time_to_resolution;
  const updatedProtectedSeries = getChartSeries(protected_assets.data);

  return (
    <Grid container className="top-panel-active-protection-collapse">
      {/* <Grid item className="active-protected-title">
        <Text {...textProps} upper>
          PROTECTED ASSETS OVER TIME
        </Text>
      </Grid> */}
      <Grid item className="active-protected-title-inner">
        <Text {...textProps} upper={false}>
          Resources
        </Text>
      </Grid>
      <Grid item className="active-protected-value">
        <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
          {resources}
        </Text>
      </Grid>
      <Grid item className="active-protected-title-inner">
        <Text {...textProps} upper={false}>
          Protected Org Assets
        </Text>
      </Grid>
      <Grid item className="active-protected-value">
        <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
          {protectedOrgAssets}
        </Text>
      </Grid>
      <Grid item className="active-protected-title-inner">
        <Text {...textProps} upper={false}>
          Impacted Connections
        </Text>
      </Grid>
      <Grid item className="active-protected-value">
        <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
          {updatedProtectedSeries[updatedProtectedSeries.length - 1].y}
        </Text>
      </Grid>
      {!!protected_assets_by_service && !!protected_assets_by_service.data && (
        <>
          <div className="vertical-devider-collapse"></div>
          <Grid item className="line-chart" sx={{ flex: 1, ...(!!currentlyInsecure ? {} : { paddingRight: 2.5 }) }}>
            <LineChart
              lineChartData={protected_assets_by_service.data}
              backgroundColor="#2c2c30"
              height={6}
              lineType={LINE_CHART_TYPE.CUSTOM}
            />
          </Grid>
        </>
      )}
      {!!currentlyInsecure && (
        <>
          <div className="vertical-devider-collapse"></div>
          <Grid item>
            <Text {...textProps} upper>
              {texts.panel_II_title}
            </Text>
          </Grid>
          <Grid item className="active-protected-title">
            <Text {...textProps} upper={false}>
              Current Average
            </Text>
          </Grid>
          <Grid item className="active-protected-value" sx={{ paddingRight: 2.5 }}>
            <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
              {currentlyInsecure}
            </Text>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const TopPanelActiveProtection = () => {
  const { isOpen, data } = useTopPanelContext();

  if (!data || !Object.keys(data).length) return null;

  return isOpen ? <ActiveProtectionExpand /> : <ActiveProtectionCollapse />;
};

export default TopPanelActiveProtection;
