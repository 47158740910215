import { IReduxState } from 'app/store';
import './MSSPAccountInfoBoxes.scss';
import { useSelector } from 'react-redux';

export function MSSPAccountInfoBoxes(props: any) {
  const { accountInfo } = useSelector((state: IReduxState) => state.mssp);

  return (
    <div className="MSSPAccountInfoBoxes">
      <div className="box">
        <h6>Company details</h6>
        <div className="list">
          <div className="key">Main domain</div>
          <ul className="row-list">
            <li>{accountInfo.main_domain}</li>
            {/* <li>www.organizationsites.net</li> */}
          </ul>
        </div>
        {/* <div className="list">
          <div className="key">LinkedIn account</div>
          <ul className="row-list">
            <li>https://www.linkedin.com/company/ionix-io/mycjkjkjkj</li>
          </ul>
        </div> */}
        {/* <h6>Additional organizations</h6>
        <div className="list">
          <div className="key">Trellis corp</div>
          <ul className="row-list">
            <li>www.organizationsite.com</li>
          </ul>
        </div>
        <div className="list">
          <div className="key">Trellis corp 2</div>
          <ul className="row-list">
            <li>www.organizationsites.net</li>
          </ul>
        </div> */}
      </div>
      <div className="box">
        <h6>Account information</h6>
        <div className="list">
          <ul className="column-list">
            <li>
              <h6>Creation date</h6>
              <div>{accountInfo.created_at}</div>
            </li>
            <li style={{ marginLeft: 20 }}>
              <h6>Created by</h6>
              <div>{accountInfo.created_by}</div>
            </li>
          </ul>
        </div>
        <div className="list">
          <ul className="column-list">
            <li>
              <h6>Active protection</h6>
              <div>{accountInfo.active_protection}</div>
            </li>
            <li>
              <h6>Assets</h6>
              <div>{accountInfo.assets}</div>
            </li>
            <li>
              <h6>Recent critical action items</h6>
              <div>{accountInfo.critical_action_items_30}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
