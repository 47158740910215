import classNames from 'classnames';
import './ActivateAccount.scss';
import { Button } from '@cyberpion/cyberpion-ui';

export function ActivateAccount(props: any) {
  return (
    <div className={classNames('ActivateAccount', { visible: props.visible })}>
      <h6>Are you sure?</h6>
      <p>
        Activating the account will send a message to the IONIX team, who will re-activate it for you and enable your
        login. You will receive an email once the activation is done.
      </p>
      <div className="activate-action">
        <Button type="button" buttonStyle="secondary" onClick={props.onCancel} text="Cancel" size={'medium'} />
        <Button type="button" buttonStyle="main" onClick={props.onCancel} text="Activate Account" size={'medium'} />
      </div>
    </div>
  );
}
