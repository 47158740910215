import React, { useState } from 'react';
import { Button } from 'common/components/Button/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useNotifications, NotificationsContextType, TSort } from '../NotificationsContext';
import { IconSortOrder } from 'common/components/Icons/IconSortOrder/IconSortOrder';
import classNames from 'classnames';
import { Search } from 'common/components/Search/Search';
// import Search from './Search';
import './notifications-header.scss';

interface INotificationsHeader {
  onAdd: () => void;
  showOrder: boolean;
  isPanelOpen: boolean;
}

const NotificationsHeader: React.FC<INotificationsHeader> = ({ onAdd, showOrder, isPanelOpen }) => {
  const [selected, setSelected] = useState('date');
  const { onSearch, onSort, sortBy, filteredNotifications } = useNotifications() as NotificationsContextType;

  const handleSort = (event: React.MouseEvent<HTMLElement>, _sortBy: TSort) => {
    setSelected(!sortBy ? sortBy.by : _sortBy);
    onSort(!_sortBy ? sortBy.by : _sortBy);
  };

  const TOGGLES = [
    { type: 'date', label: 'Last Edited' },
    { type: 'type', label: 'By Type' },
    { type: 'az', label: 'A-Z' }
  ];

  return (
    <div className="notifications-header">
      <div className="notifications-action">
        <div className={classNames('group-action', { 'panel-open': isPanelOpen })}>
          <Search onChange={onSearch} />
          {/* <Search /> */}
          <div style={{ width: 1, borderRight: '1px solid #e8e8e8', height: 18 }}></div>
          <ToggleButtonGroup
            color="primary"
            value={!!filteredNotifications && !!filteredNotifications.length ? selected : null}
            exclusive
            sx={{ width: 265, paddingLeft: 3, ...(isPanelOpen ? { padding: 0 } : {}) }}
            onChange={handleSort}
            aria-label="sort"
            disabled={!filteredNotifications || (!!filteredNotifications && !filteredNotifications.length)}
          >
            {TOGGLES.map((toggle: any) => (
              <ToggleButton key={toggle.type} value={toggle.type} data-testid={`notifications-sort-${toggle.type}`}>
                <span style={{ paddingRight: 4, paddingLeft: 8 }}>{toggle.label}</span>
                <div
                  className={classNames(
                    'order-sort-icon',
                    { active: sortBy.by === toggle.type && !!filteredNotifications && !!filteredNotifications.length },
                    { desc: sortBy.by !== toggle.type || (sortBy.by === toggle.type && sortBy.order === 'desc') },
                    { asc: sortBy.by === toggle.type && sortBy.order === 'asc' }
                  )}
                >
                  <IconSortOrder active={false} />
                </div>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
        <Button
          type={'button'}
          onClick={onAdd}
          text="Add Notification"
          buttonStyle="main"
          size="small"
          dataTestId="add-notification-button"
          className={classNames({ 'panel-open': isPanelOpen })}
        />
      </div>
    </div>
  );
};

export default NotificationsHeader;
