import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import './WhoisOptionsDropdown.scss';

export const WHOIS_OPTIONS: string[] = ['Email', 'Organization', 'Name-Server'];

export function WhoisOptionsDropdown(props: any) {
  const [visible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const onChange = (val: string) => {
    setSelected(val);
    setError(false);
    props.onChange(val);
    setVisible(false);
  };

  return (
    <div className="WhoisOptionsDropdown">
      <header className={classNames({ error: error, visible: visible })} onClick={() => setVisible(!visible)}>
        <span>{selected || 'WHOIS Record Type'}</span> <IconArrow />
      </header>
      <ul className={visible ? 'visible' : ''}>
        {WHOIS_OPTIONS.map((option: string) => {
          return (
            <li key={option} onClick={() => onChange(option)}>
              {option}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
