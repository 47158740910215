import { useState, useEffect } from 'react';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';

const usePageSize = (ref: any, asset: string) => {
  const [minHeight, setMinHeight] = useState<any>(null);
  const { setPagesAmount, PAGE_HEIGHT } = useThreatsReport() as ThreatsReportContextType;

  useEffect(() => {
    if (ref.current !== null && !!asset) {
      const { offsetHeight } = ref.current;

      let pages = Math.floor(offsetHeight / PAGE_HEIGHT);
      if (!!(offsetHeight % PAGE_HEIGHT)) {
        pages += 1;
      }
      setMinHeight(pages * PAGE_HEIGHT);
      setPagesAmount({ [asset]: pages });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current, asset]);

  return { minHeight };
};

export default usePageSize;
