import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInfoPanelAsset, setIsOpenInfoPanel } from 'features/InfoPanelSlice';
import classNames from 'classnames';

import './InfoPanel.scss';
import { Text } from '@cyberpion/cyberpion-ui';
import FqdnsInfoPanel from './components/FqdnsInfoPanel';
import Fade from '@mui/material/Fade';
import ExcludedNetworksPanel from './components/ExcludedNetworksPanel';

const InfoPanel = (props: any) => {
  const { infoPanelContentType, infoPanelAsset } = useSelector((state: any) => state.infoPanel);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    setIsOpen(props.open);
  }, [props.open]);

  const getInfoPanelContent = () => {
    switch (infoPanelContentType) {
      case 'fqdns':
        return <FqdnsInfoPanel />;
      case 'excluded_networks':
        return <ExcludedNetworksPanel />;
      case 'technologies':
        return <Text>technologies</Text>;
      default:
        return <Text>default</Text>;
    }
  };

  const onPanelClose = () => {
    dispatch(setIsOpenInfoPanel(false));
    dispatch(setInfoPanelAsset(''));
  };

  return (
    <div key={infoPanelAsset} className="info-panel-container" style={{ display: 'flex' }}>
      {isOpen && <div className="left-shadow" />}
      <div
        className={classNames('InfoPanel', {
          open: isOpen
        })}
      >
        <div className={classNames({ open: isOpen })} style={{ height: '100%' }}>
          <img
            src="/assets/images/x.svg"
            alt="Close"
            className="close-trigger"
            onClick={onPanelClose}
            data-testid="notification-side-panel-x"
          />

          <div className="info-panel-content">
            <Fade in timeout={800}>
              <div>{getInfoPanelContent()}</div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPanel;
