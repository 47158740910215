import React, { useState, useEffect } from 'react';
import ThreatsList from './ThreatsList/ThreatsList';
import { ThreatsCenterContextType, useThreatsCenter } from '../ThreatsCenterContext';

import './ThreatsContainer.scss';
import { NoIssuesIcon } from 'common/components/manageNotData/components/NoIssuesIcon/NoIssuesIcon';

const ThreatsContainer = () => {
  const [last30Data, setLast30Data] = useState<any>([]);
  const [earlierData, setEarlierData] = useState<any>([]);
  const { filteredData } = useThreatsCenter() as ThreatsCenterContextType;

  useEffect(() => {
    if (!!filteredData) {
      const last30 = filteredData
        .sort((a: any, b: any) => new Date(b.creation_time).getTime() - new Date(a.creation_time).getTime())
        .filter((item: any) => {
          return new Date(item.creation_time).getTime() > Date.now() - 30 * 24 * 60 * 60 * 1000;
        });
      const earlier = filteredData
        .sort((a: any, b: any) => new Date(b.creation_time).getTime() - new Date(a.creation_time).getTime())
        .filter((item: any) => {
          return new Date(item.creation_time).getTime() < Date.now() - 30 * 24 * 60 * 60 * 1000;
        });
      setLast30Data(last30);
      setEarlierData(earlier);
    }
  }, [filteredData]);

  return (
    <div className="threat-center-container">
      {!!filteredData?.length ? (
        <>
          <ThreatsList data={last30Data} title="Last 30 Days" className="threats-list-month" />
          <ThreatsList data={earlierData} title="Earlier" className="threats-list-earlier" />
        </>
      ) : (
        <NoIssuesIcon />
      )}
    </div>
  );
};

export default ThreatsContainer;
