import { IconArrow } from '../Icons/IconArrow/IconArrow';
import classNames from 'classnames';
import './StaticDropDown.scss';
import { useState } from 'react';
import { IListItem } from 'common/interfaces/interface';

export function StaticDropDown(props: any) {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>(props.selected);
  const [searchInput, setSearchInput] = useState<string>('');
  const [list] = useState<IListItem[]>(props.list);
  const [filteredList, setFilteredList] = useState<IListItem[]>(props.list);

  const _setSelected = (item: IListItem) => {
    setSelected(item.value);
    props.onChange(item.key);
    setIsToggled(false);
  };

  const getListHTML = (): JSX.Element[] => {
    const _html: JSX.Element[] = [];
    filteredList.forEach((item: IListItem, index: number) => {
      _html.push(
        <li key={index} onClick={() => _setSelected(item)}>
          {item.value}
        </li>
      );
    });
    return _html;
  };

  const onSearch = (value: string) => {
    setSearchInput(value);
    let copy = [...list];
    if (value) {
      copy = copy.filter((item: IListItem) => item.value.toLowerCase().includes(value.toLowerCase()));
    }
    setFilteredList(copy);
  };

  return (
    <div className="StaticDropDown">
      <header
        onClick={() => setIsToggled(!isToggled)}
        className={classNames({ selected: !!selected }, { disabled: !!props.disabled })}
      >
        <span>{props.selected || props.headerText}</span> <IconArrow toggleRotate={isToggled} />
      </header>
      <div className={classNames({ visible: isToggled })}>
        <div className="search">
          <img alt="search" src={`/assets/images/search.svg`} />
          <input type="text" value={searchInput} onChange={e => onSearch(e.target.value)} />
        </div>
        <div className="scrollbar-common">
          <ul style={{ height: '100%', overflow: 'auto' }}>{getListHTML()}</ul>
        </div>
      </div>
    </div>
  );
}
