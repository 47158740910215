interface ITopPanelLinkConfig {
  [keu: string]: {
    [key: string]: string;
  };
}

const TopPanelLinksConfig: ITopPanelLinkConfig = {};

TopPanelLinksConfig['dashboard'] = {
  critical: `/pages/actionItems/open?fields=id%2Curgency%2Cis_active_protection%2Ctype%2Ctitle%2Casset%2Casset_importance%2Cfirst_opened_at%2Clast_opened_at%2Cis_acknowledged%2Cgroups_names%2Cacknowledged_date%2Cacknowledged_by%2Cacknowledgement_reason&filters=%7B"urgency__in"%3A"critical"%7D`,
  high: `/pages/actionItems/open?fields=id%2Curgency%2Cis_active_protection%2Ctype%2Ctitle%2Casset%2Casset_importance%2Cfirst_opened_at%2Clast_opened_at%2Cis_acknowledged%2Cgroups_names%2Cacknowledged_date%2Cacknowledged_by%2Cacknowledgement_reason&filters=%7B"urgency__in"%3A"high"%7D`,
  medium: `/pages/actionItems/open?fields=id%2Curgency%2Cis_active_protection%2Ctype%2Ctitle%2Casset%2Casset_importance%2Cfirst_opened_at%2Clast_opened_at%2Cis_acknowledged%2Cgroups_names%2Cacknowledged_date%2Cacknowledged_by%2Cacknowledgement_reason&filters=%7B"urgency__in"%3A"medium"%7D`,
  low: `/pages/actionItems/open?fields=id%2Curgency%2Cis_active_protection%2Ctype%2Ctitle%2Casset%2Casset_importance%2Cfirst_opened_at%2Clast_opened_at%2Cis_acknowledged%2Cgroups_names%2Cacknowledged_date%2Cacknowledged_by%2Cacknowledgement_reason&filters=%7B"urgency__in"%3A"low"%7D`,
  actionItems: `/pages/actionItems/open?fields=id%2Curgency%2Cis_active_protection%2Ctype%2Ctitle%2Casset%2Casset_importance%2Cfirst_opened_at%2Clast_opened_at%2Cis_acknowledged%2Cgroups_names%2Cacknowledged_date%2Cacknowledged_by%2Cacknowledgement_reason`,
  score: `/pages/assessments/orgScore`,
  org_assets: `/pages/discovery/internalAssets?fields=id%2Crisk_score%2Casset%2Ctype%2Cimportance%2Chosting_provider%2Ctechnologies%2Cfirst_seen%2Cservice_type%2Cservice%2Cgroups`,
  external_assets: `/pages/discovery/digitalSupplyChain?fields=id%2Crisk_score%2Casset%2Cips%2Cconnected_assets%2Cconnections_types%2Chosting_provider%2Ctechnologies%2Cfirst_seen%2Cgroups`,
  activeProtection:
    '/pages/activeProtection?fields=id%2Cprotected_host%2Casset%2Ctype%2Cprotected_since%2Crelease_date%2Cgroups&filters=%7B%22is_actively_protected%22%3A1%7D'
};

export default TopPanelLinksConfig;
