import { AppLoading } from 'common/components/appLoading/appLoading';
import { useEffect, useState } from 'react';
import { IntegrationHeader } from '../Integration/IntegrationHeader/IntegrationHeader';
import { Button } from 'common/components/Button/Button';
import { TextField } from '@mui/material';
import { RestApi } from 'common/services/rest-api.service';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { ToolTip } from 'common/components/toolTip/toolTip';
import { SECURE_INPUT_MASK } from '../../config';
import { useDispatch } from 'react-redux';
import { Common } from 'common/services/common.service';
import { showError, showSuccess } from 'features/AlertSlice';
import PopSideFotter from '../Integration/PopSideFotter/PopSideFotter';
import classNames from 'classnames';
import { loadList } from 'features/IntegrationsSlice';
import { Text } from '@cyberpion/cyberpion-ui';
import { IconVisibility, IconVisibilityOff } from '../Integration/Integration';

import './ServiceNow.scss';

import '../../Integration.scss';

interface IServiceNow {
  client_id: string;
  client_secret: string;
  url: string;
  user_name: string;
  password: string;
  refresh_token: string;
  configuration_name: string;
}

const ServiceNow = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>({});
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [methods, setMethods] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [visibilityToken, setVisibilityToken] = useState(false);
  const [errors, setErrors] = useState({
    configuration_name: false,
    server_url: false,
    user_name: false,
    password: false,
    project: false,
    client_secret: false,
    client_id: false,
    refresh_token: false
  });

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (props.isEditMode) {
      RestApi.getData(Paths[PathNames.integrationServiceNow]).subscribe((response: any) => {
        if (response) {
          const data = {
            id: response.results[0].id,
            configuration_name: response.results[0].configuration_name,
            server_url: response.results[0].configuration.server_url,
            ...(response.results[0].configuration.authentication.user_name
              ? {
                  user_name: response.results[0].configuration.authentication.user_name,
                  password: SECURE_INPUT_MASK
                }
              : {}),
            ...(response.results[0].configuration.authentication.refresh_token
              ? {
                  refresh_token: response.results[0].configuration.authentication.refresh_token,
                  client_id: response.results[0].configuration.authentication.client_id,
                  client_secret: response.results[0].configuration.authentication.client_secret
                }
              : {})
          };
          setData(data);
          setIsLoading(false);
          setMethods(response.results[0].configuration.authentication.user_name ? 'id_password' : 'refresh_token');
        }
      });
    } else {
      setIsLoading(false);
      setMethods('id_password');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditMode]);

  useEffect(() => {
    if (!!Object.keys(data).length && !!methods && !!methods.length) {
      const valid =
        data?.configuration_name?.length > 2 &&
        data?.server_url?.length > 2 &&
        ((methods === 'id_password' && data?.user_name?.length > 2 && data?.password?.length > 2) ||
          (methods === 'refresh_token' &&
            data?.refresh_token?.length > 2 &&
            data?.client_id?.length > 2 &&
            data?.client_secret?.length > 2));

      setIsValidated(valid);
    }
  }, [data, methods]);

  useEffect(() => {
    if (methods === 'id_password') {
      delete data.refresh_token;
      delete data.client_id;
      delete data.client_secret;
      setErrors({
        ...errors,
        refresh_token: false,
        client_id: false,
        client_secret: false
      });
    } else if (methods === 'refresh_token') {
      setErrors({
        ...errors,
        user_name: false,
        password: false
      });
      delete data.user_name;
      delete data.password;
    }
    setClickedSubmit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods]);

  const setValue = (key: string, value: any) => {
    let copy: IServiceNow = { ...data };
    copy = { ...copy, [key]: value };
    setData(copy);
    setClickedSubmit(false);
  };

  const submit = () => {
    setClickedSubmit(true);
    if (!isValidated) {
      return;
    }

    setIsLoading(true);
    const payload = {
      ...(props.isEditMode ? {} : { configuration_name: data.configuration_name }),
      configuration: {
        ...(props.isEditMode ? {} : { server_url: data.server_url }),
        authentication: {
          ...(methods === 'id_password'
            ? {
                authentication_type: 'basic_auth',
                user_name: data.user_name,
                ...(data.password !== SECURE_INPUT_MASK ? { password: data.password } : {})
              }
            : {}),
          ...(methods === 'refresh_token'
            ? {
                authentication_type: 'refresh_token',
                refresh_token: data.refresh_token,
                client_id: data.client_id,
                client_secret: data.client_secret
              }
            : {})
        }
      }
    };
    RestApi.setData(
      props.isEditMode
        ? Paths[PathNames.integrationServiceNow] + props.id + '/'
        : Paths[PathNames.integrationServiceNow],
      {
        ...payload,
        status: 'active'
      },
      props.isEditMode ? 'PATCH' : 'POST'
    ).subscribe(
      (response: any) => {
        setIsLoading(false);
        props.onClose();
        dispatch(loadList());
        dispatch(showSuccess(`Integration Saved Successfully`));
      },
      error => {
        dispatch(showError(error));
        setIsLoading(false);
      }
    );
  };

  const deleteBeforeApplyOnClick = (ev: any) => {
    ev.stopPropagation();
    setShowDeletePopup(true);
  };

  const deleteAfterApplyOnClick = () => {
    setShowDeletePopup(false);
    setIsLoading(true);
    if (!!data.id) {
      RestApi.setData(`${Paths[PathNames.integrationServiceNow]}${data.id}/`, {}, 'DELETE').subscribe(
        (response: any) => {
          dispatch(showSuccess('Deleted Successfully'));
          dispatch(loadList());
        },
        error => {
          dispatch(showError(error));
        }
      );
    }
    props.onClose();
  };

  const handlePopSideOnClick = (ev: any) => {
    ev.stopPropagation();
    setShowDeletePopup(false);
  };

  const checkFieldIsValidation = (field: any) => {
    if (!data[field]) {
      return true;
    }
    switch (field) {
      case 'configuration_name':
        return data?.configuration_name?.length > 2;
      case 'server_url':
        return Common.validateURL(data?.server_url || '');
      case 'user_name':
        return data?.user_name?.length > 2;
      case 'password':
        return data?.password?.length > 2;
      case 'refresh_token':
        return data?.refresh_token?.length > 2;
      case 'client_id':
        return data?.client_id?.length > 2;
      case 'client_secret':
        return data?.client_secret?.length > 2;
      default:
        return false;
    }
  };

  const handleOnBlur = (type: string) => () => {
    const valid = !data[type] || checkFieldIsValidation(type);
    setErrors({ ...errors, [type]: !valid });
  };

  return isLoading ? (
    <AppLoading />
  ) : (
    <div className="ServiceNow integration" onClick={handlePopSideOnClick}>
      <IntegrationHeader name="ServiceNow" icon="serviceNow" />
      <div className="scrollbar-common">
        <div className={classNames('main-integration-wrapper', { isOpacity: showDeletePopup })}>
          <div style={{ height: '100%', overflow: 'auto' }}>
            <div className="group">
              <div className="group-headline">
                <h6>General</h6>
              </div>
              <div className="text-field-wrapper">
                {!data.id && (
                  <>
                    <TextField
                      fullWidth
                      label="Integration name"
                      id="outlined-start-adornment"
                      className="input"
                      value={data.configuration_name}
                      onChange={e => setValue('configuration_name', e.target.value)}
                      type={'text'}
                      onFocus={(ev: any) => ev.target.select()}
                      sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
                      inputProps={{
                        autoComplete: 'new-password',
                        onBlur: handleOnBlur('configuration_name')
                      }}
                      error={
                        !!errors.configuration_name ||
                        ((!data.configuration_name || !checkFieldIsValidation('configuration_name')) && clickedSubmit)
                      }
                    />
                    <TextField
                      fullWidth
                      label="ServiceNow Server URL"
                      className="input"
                      value={data.server_url}
                      onChange={e => setValue('server_url', e.target.value)}
                      type={'text'}
                      onFocus={(ev: any) => ev.target.select()}
                      sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
                      inputProps={{
                        autoComplete: 'new-password',
                        onBlur: handleOnBlur('server_url')
                      }}
                      error={
                        !!errors.server_url ||
                        ((!data.server_url || !checkFieldIsValidation('server_url')) && clickedSubmit)
                      }
                    />
                  </>
                )}
                {!!data.id && (
                  <>
                    <div style={{ marginBottom: 10 }}>
                      <Text textSize={12} weight={500} style={{ marginTop: 16 }}>
                        Integration name
                      </Text>
                      <Text textColor="#5D6576" family="Rubik" textSize={12} style={{ marginTop: 8 }}>
                        {data.configuration_name}
                      </Text>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                      <Text textSize={12} weight={500} style={{ marginTop: 16 }}>
                        ServiceNow server
                      </Text>
                      <Text textColor="#5D6576" family="Rubik" textSize={12} style={{ marginTop: 8 }}>
                        {data.server_url}
                      </Text>
                    </div>
                  </>
                )}
              </div>
              <div className="group">
                <div className="group-headline">
                  <h6>Integration Methods</h6>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }}>
                  <Button
                    buttonStyle=""
                    size="big"
                    text="ID & Password"
                    onClick={() => setMethods('id_password')}
                    type="button"
                    className={`service-now-button marginRight ${methods === 'id_password' ? 'active' : ''}`}
                    disabled={props.isSubmiting}
                  />
                  <Button
                    buttonStyle=""
                    size="big"
                    text="Refresh Token"
                    onClick={() => setMethods('refresh_token')}
                    className={`service-now-button marginLeft ${methods === 'refresh_token' ? 'active' : ''}`}
                    type="button"
                    disabled={props.isSubmiting}
                  />
                </div>
                {methods === 'id_password' && (
                  <>
                    <TextField
                      fullWidth
                      label="User ID for the intended user"
                      className="input"
                      value={data.user_name}
                      onChange={e => setValue('user_name', e.target.value)}
                      type={'text'}
                      onFocus={(ev: any) => ev.target.select()}
                      sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
                      inputProps={{
                        autoComplete: 'new-password',
                        onBlur: handleOnBlur('user_name')
                      }}
                      error={
                        !!errors.user_name ||
                        ((!data.user_name || !checkFieldIsValidation('user_name')) && clickedSubmit)
                      }
                    />
                    <div className="text-field-wrapper" style={{ position: 'relative' }}>
                      <TextField
                        fullWidth
                        label="Password"
                        className="input"
                        value={data.password}
                        onChange={e => setValue('password', e.target.value)}
                        type={!!visibility ? 'text' : 'password'}
                        onFocus={(ev: any) => ev.target.select()}
                        sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
                        autoComplete="off"
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autocomplete: 'off'
                          },
                          onBlur: handleOnBlur('password')
                        }}
                        error={
                          !!errors.password ||
                          ((!data.password || !checkFieldIsValidation('password')) && clickedSubmit)
                        }
                      />
                      {!data.id && !!data.password && (
                        <div className="icon-visibility" onClick={() => setVisibility(!visibility)}>
                          {!!visibility ? IconVisibilityOff : IconVisibility}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {methods === 'refresh_token' && (
                  <>
                    <TextField
                      fullWidth
                      label="0Auth Client ID"
                      className="input"
                      value={data.client_id}
                      onChange={e => setValue('client_id', e.target.value)}
                      type={'text'}
                      onFocus={(ev: any) => ev.target.select()}
                      sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
                      inputProps={{
                        autoComplete: 'new-password',
                        onBlur: handleOnBlur('client_id')
                      }}
                      error={
                        !!errors.client_id ||
                        ((!data.client_id || !checkFieldIsValidation('client_id')) && clickedSubmit)
                      }
                    />
                    <TextField
                      fullWidth
                      label="0Auth Client Secret"
                      className="input"
                      value={data.client_secret}
                      onChange={e => setValue('client_secret', e.target.value)}
                      type={'text'}
                      onFocus={(ev: any) => ev.target.select()}
                      sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
                      inputProps={{
                        autoComplete: 'new-password',
                        onBlur: handleOnBlur('client_secret')
                      }}
                      error={
                        !!errors.client_secret ||
                        ((!data.client_secret || !checkFieldIsValidation('client_secret')) && clickedSubmit)
                      }
                    />
                    <div className="text-field-wrapper" style={{ position: 'relative' }}>
                      <TextField
                        fullWidth
                        label="Refresh Token"
                        className="input"
                        value={data.refresh_token}
                        onChange={e => setValue('refresh_token', e.target.value)}
                        type={!!visibilityToken ? 'text' : 'password'}
                        onFocus={(ev: any) => ev.target.select()}
                        sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
                        autoComplete="off"
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autocomplete: 'off'
                          },
                          onBlur: handleOnBlur('password')
                        }}
                        error={
                          !!errors.refresh_token ||
                          ((!data.refresh_token || !checkFieldIsValidation('refresh_token')) && clickedSubmit)
                        }
                      />
                      {!data.id && !!data.refresh_token && (
                        <div className="icon-visibility" onClick={() => setVisibilityToken(!visibilityToken)}>
                          {!!visibilityToken ? IconVisibilityOff : IconVisibility}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopSideFotter
        onClose={props.onClose}
        integrationName="ServiceNow"
        isValidated={true}
        onSave={submit}
        isLoading={isLoading}
        deleteBeforeApplyOnClick={deleteBeforeApplyOnClick}
        deleteAfterApplyOnClick={deleteAfterApplyOnClick}
        showDeletePopup={showDeletePopup}
        deleteMsgWithNotificationWarning={!!data.id}
      />

      <ToolTip theme="dark" place="left" />
    </div>
  );
};

export default ServiceNow;
