import { Checkbox } from 'common/components/Icons/IconCheckbox/IconCheckbox';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import { useState } from 'react';
import './acknowledgeReason.scss';
import { tosterService } from 'common/services/tostar.service';
import { removeRowsByIndex, updateRows } from 'features/GridSlice';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { GridRowData, GridRowId } from '@mui/x-data-grid-pro';
import { Button } from '@cyberpion/cyberpion-ui';

export function AcknowledgeReason(props: any) {
  const [message, setMessage] = useState('');
  const [sendFeedback, setSendFeedback] = useState(false);
  const { filters } = useSelector((state: IReduxState) => state.grid);
  const dispatch: AppDispatch = useDispatch();

  function onCancel() {
    setMessage('');
    props.setVisible(false);
    props.apiGrid.current.setSelectionModel([]);
  }

  // function updateRows(rows: any) {
  //   let arr: any = [];
  //   rows.forEach((row: any) => {
  //     arr.push({
  //       ...row,
  //       acknowledged_date: new Date(),
  //       acknowledged_by: LoginService.getUser().first_name,
  //       acknowledgement_reason: message !== "" ? message : row.title.label,
  //     });
  //   });
  //   return arr;
  // }

  function onSave() {
    if (props.ids.length === 0) {
      return;
    }

    let url = `remediation/action-items/acknowledge/`;
    let data: any = {
      ids: props.ids,
      is_acknowledged: true
    };

    if (message.trim() !== '') {
      data.acknowledgement_reason = message;
    }

    RestApi.setData(url, data, 'POST').subscribe(
      (response: any) => {
        onCancel();
        if (filters.is_acknowledged && filters.is_acknowledged.value === 0) {
          removeRowsGrid();
        } else {
          dispatch(updateRows({ rows: response, identifier: props.identifier }));
        }

        // let messageToster, index;
        // if (props.ids.length === 1) {
        //   index = props.rows.findIndex((row: GridRowData) => row[props.identifier] === props.ids[0]);
        // }

        // messageToster = props.ids.length === 1 ? `${props.rows[index].asset}` : 'Action Items Acknowledged';
        // messageToster = 'Action Items Acknowledged';
        // tosterService.onShowToster(messageToster, 'success', '');
        tosterService.onShowToster('Action Items Acknowledged', 'success', '');
      },
      error => {
        onCancel();
        tosterService.onShowToster('Error', 'danger', '');
      }
    );
  }

  function removeRowsGrid() {
    let rows = Common.cloneData(props.rows);
    const indexes: number[] = [];
    props.ids.forEach((id: GridRowId) => {
      let index = rows.findIndex((row: GridRowData) => row[props.identifier] === id);
      indexes.push(index);
    });
    dispatch(removeRowsByIndex(indexes));
  }

  function handleChange(e: string) {
    setMessage(e);
  }

  return props.visible ? (
    <div className="acknowledgeReason popup-inner-wrapper">
      <div className="acknowledgeReason-header-scope">
        <div className="acknowledgeReason-header-text">Acknowledgment Reason (Optional)</div>
        <div className="acknowledgeReason-remove" onClick={onCancel}>
          +
        </div>
        <img src="/assets/images/background-popup.svg" alt="background-popup" />
      </div>
      <div className="acknowledgeReason-popup-body">
        <div className="acknowledgeReason-warning-sub-header">
          Add here a short description that will be seen when viewing your acknowledged action items. This will help
          your team understand why this action item was hidden.
        </div>

        <textarea
          className="acknowledgeReason-textarea"
          placeholder="a short description..."
          value={message}
          onChange={e => handleChange(e.target.value)}
        ></textarea>

        <div className="buttons-wrapper" onClick={() => setSendFeedback(!sendFeedback)}>
          <div>
            <Checkbox active={sendFeedback} />
            <span className="">Send a copy to the Ionix team as feedback</span>
          </div>
          <div className="acknowledgeReason-event-scope">
            <Button onClick={onCancel} text="Cancel" type="button" buttonStyle="secondary" size="medium" />
            <Button onClick={onSave} text="Save" type="button" buttonStyle="main" size="medium" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
