import { IIcon } from 'common/interfaces/interface';
import React from 'react';

export class IconReduce extends React.Component {
  public props: IIcon;
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M6.50183 13.5006H2.50244V9.50122" stroke="#5D6576" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.50244 13.4988L7.00244 8.99884" stroke="#5D6576" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M9.50305 2.49945L13.5024 2.49945L13.5024 6.49884"
          stroke="#5D6576"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M13.5024 2.50122L9.00244 7.00122" stroke="#5D6576" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }
}
