import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import { setDeletePromptParams } from 'features/GridSlice';
import {
  addDomain,
  addIp,
  addWhois,
  addMobileApp,
  setEditMode,
  setIdentifier,
  setLabel,
  setSubsidiaryMode,
  togglePopup,
  toggleService
} from 'features/GroupsSlice';
import RowActionMenu from 'common/components/RowActionMenu/RowActionMenu';

import './FilterGroupsDropdown.scss';

export function FilterGroupsDropdown(props: any) {
  const ref = useRef<HTMLDivElement>(null);
  // const [visible, setVisible] = useState<boolean>(false);
  const [id, setId] = useState<string>(props.id);
  const dispatch = useDispatch<any>();

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      // setVisible(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    const popup = Common.getParamFromUrl('popup');
    if (popup === props.row.name) {
      Common.removeParamFromURL('popup');
      setId(id);
      dispatch(setSubsidiaryMode(true));
      editOnClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteOnClick = () => {
    const id = props.id;
    const index = props.api.getRowIndex(id);

    // setVisible(false);

    dispatch(
      setDeletePromptParams({
        category: 'groups',
        index: index,
        ids: [id],
        message: 'Are you sure you would like to remove this group?'
      })
    );
  };

  const editOnClick = () => {
    RestApi.getData(Paths[PathNames.services]).subscribe((responseServices: any) => {
      RestApi.getData(`${Paths[PathNames.groups]}${props.id}`).subscribe((response: any) => {
        response.whois?.forEach((w: { value: string; type: string }) => {
          dispatch(addWhois({ whois: w.value, type: w.type }));
        });
        response.domains?.forEach((d: string) => {
          dispatch(addDomain({ value: d }));
        });
        response.ip_networks?.forEach((ip: string) => {
          dispatch(addIp({ value: ip }));
        });
        response.mobile_apps?.forEach((app: string) => {
          dispatch(addMobileApp({ value: app }));
        });
        response.services?.forEach((s: string) => {
          const selectedService = responseServices.find((service: any) => service.label === s);
          dispatch(toggleService(selectedService.value));
        });
        dispatch(setLabel(response.name));
        dispatch(setEditMode(true));
        dispatch(setIdentifier(props.row[props.identifier]));
        dispatch(togglePopup(true));
        // if (props.row.type.toLowerCase() === 'subsidiary') {
        //   dispatch(setSubsidiaryMode(true));
        // }
        // setVisible(false);
      });
    });
  };

  const shouldShowDropDown = (): boolean => {
    if (props.row.actions?.is_deletable || props.row.actions?.is_editable) {
      return true;
    }
    return false;
  };

  if (!shouldShowDropDown()) {
    return null;
  }

  const handleMenuItemOnClick = (rowData: any, option: any, type: string, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (option.id === 'delete') {
      deleteOnClick();
    }
    if (option.id === 'edit') {
      editOnClick();
    }
  };

  return (
    <div className="FilterGroupsDropdown" ref={ref}>
      <RowActionMenu rowData={props.row} itemOnClick={handleMenuItemOnClick} type="groups" status={props.row.actions} />
      {/* <header className={visible ? 'visible' : ''} onClick={() => setVisible(!visible)}>
        <span>...</span>
      </header>
      <ul className={visible ? 'visible' : ''}>
        {props.row.actions?.is_editable && <li onClick={editOnClick}>Edit</li>}
        {props.row.actions?.is_deletable && <li onClick={deleteOnClick}>Remove</li>}
      </ul> */}
    </div>
  );
}
