// import { Button } from '@cyberpion/cyberpion-ui';
import './Header.scss';

export function Header(props: any) {
  return (
    <header className="Header">
      <div>
        <img src="/assets/images/seed-data.svg" alt="Seed Icon" />
        <h2>Seed Data Setup</h2>
      </div>
      <div>
        <p>Please select the names that are relevant to the org, and we will use these assets</p>
        {/* <Button
          icon={'info'}
          text="Seed assets workflow"
          type="button"
          onClick={() => {}}
          buttonStyle="secondary"
          size="medium"
        /> */}
      </div>
    </header>
  );
}
