import React from 'react';
import { IIcon } from 'common/interfaces/interface';
export class IconCloudFlare extends React.Component<IIcon> {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#8796F0' : '#5D6576';
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.7759 15.4723C15.9004 15.1061 15.8589 14.7397 15.6515 14.5199C15.444 14.3001 15.1535 14.1537 14.7801 14.117L7.56016 14.0437C7.51867 14.0437 7.47719 14.0072 7.4357 14.0072C7.39422 13.9705 7.39422 13.9339 7.4357 13.8972C7.47719 13.824 7.51867 13.7873 7.60164 13.7873L14.863 13.7141C15.7345 13.6775 16.6473 13.0548 16.9792 12.3222L17.3942 11.3699C17.3942 11.3332 17.4357 11.2966 17.3942 11.2599C16.9377 9.39186 15.0291 8 12.7884 8C10.7137 8 8.92945 9.17207 8.30703 10.8204C7.89211 10.564 7.39422 10.4174 6.81328 10.4542C5.81742 10.5274 5.02906 11.2599 4.90453 12.139C4.86305 12.3588 4.90453 12.5786 4.94609 12.7983C3.32781 12.835 3 14.0071 3 15.4723C3 15.6188 3 15.7287 3.04148 15.8752C3.04148 15.9485 3.12445 15.9851 3.16602 15.9851H15.4855C15.5684 15.9851 15.6515 15.9486 15.6515 15.8752L15.7759 15.4723Z"
          fill={color}
        />
        <path
          d="M17.6406 11H17.4637C17.4284 11 17.393 11.0402 17.3577 11.0806L17.1101 12.0484C17.0041 12.4515 17.0394 12.8549 17.2163 13.0968C17.393 13.3387 17.6406 13.4999 17.9588 13.5404L19.2673 13.621C19.3026 13.621 19.338 13.6612 19.3734 13.6612C19.4086 13.7016 19.4086 13.7419 19.3734 13.7822C19.338 13.8629 19.3026 13.9032 19.2319 13.9032L17.8881 13.9839C17.1455 14.0241 16.3675 14.7097 16.0846 15.5161L16.0139 15.879C15.9785 15.9194 16.0139 16 16.0846 16H20.7525C20.8232 16 20.8586 15.9597 20.8586 15.8791C20.9293 15.5564 21 15.1935 21 14.8306C21 12.7339 19.4794 11 17.6406 11Z"
          fill={color}
        />
      </svg>
    );
  }
}
