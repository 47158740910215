import React from 'react';
import { Grid } from '@mui/material';
import ActionItemHeader from './ActionItemHeader/ActionItemHeader';
import ActionItemDetails from './ActionItemDetails/ActionItemDetails';

interface IActionItemContainer {
  actionItem: any;
  index: number;
}

const ActionItemContainer: React.FC<IActionItemContainer> = ({ actionItem = {}, index }) => {
  const padding = index === 0 ? { paddingTop: 2.5 } : {};
  return !!actionItem ? (
    <Grid container className="action-item-container" sx={padding}>
      <Grid item>
        <ActionItemHeader actionItem={actionItem} />
        <ActionItemDetails actionItem={actionItem} />
      </Grid>
    </Grid>
  ) : null;
};

export default ActionItemContainer;
