import { IReduxState } from 'app/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './popUp.scss';

export function PopUp(props: any) {
  const [animate, setAnimate] = useState<boolean>(false);
  const { allowPopupClose } = useSelector((state: IReduxState) => state.general);
  let timer: any;

  useEffect(() => {
    if (props.visible) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setTimeout(() => {
        setAnimate(true);
      }, 100);
      document.addEventListener('keydown', handleEsc, true);
    } else {
      setAnimate(false);
    }
    return () => {
      clearTimeout(timer);
      document.removeEventListener('keydown', handleEsc, true);
    };
  }, [props.visible]);

  const handleEsc = (e: KeyboardEvent) => {
    if (e.key && e.key.toLowerCase() === 'escape') {
      closePopup();
    }
  };

  const closePopup = () => {
    if (allowPopupClose) {
      props.setVisible(false);
    }
  };

  return (
    props.visible && (
      <div className={`popUp-scope ${animate ? 'animate' : ''}`}>
        <div className="bg-layer" onClick={closePopup}></div>
        {props.children}
      </div>
    )
  );
}
