import { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import './TechnicalDetails.scss';
import { Common } from 'common/services/common.service';

const KEYWORD = 'Raw ';

export function TechnicalDetails(props: any) {
  const [rawData, setRawData] = useState<[]>([]);

  useEffect(() => {
    let data: any = [];
    if (props.data) {
      if (Array.isArray(props.data)) {
        props.data.forEach((td: any) => {
          if (
            ['object', 'string'].includes(typeof td) &&
            Object.keys(td)[0]?.startsWith(KEYWORD) &&
            !props.showTechnicalDetailsOnly
          ) {
            data.push({
              key: Object.keys(td)[0],
              value: Object.values(td)[0]
            });
          }
        });
      } else {
        Object.keys(props.data).forEach((td: any) => {
          if (
            ['object', 'string'].includes(typeof props.data[td]) &&
            td.startsWith(KEYWORD) &&
            !props.showTechnicalDetailsOnly
          ) {
            data.push({
              key: td,
              value: props.data[td]
            });
          }
        });
      }
    }
    setRawData(data);
  }, [props.data, props.showTechnicalDetailsOnly]);

  const getFilteredTechnicalDetails = () => {
    const copy = { ...props.data };
    Object.keys(copy).forEach(item => {
      if (
        ['object', 'string'].includes(typeof copy[item]) &&
        !Array.isArray(copy[item]) &&
        (Object.keys(copy[item])[0]?.startsWith(KEYWORD) || item.startsWith(KEYWORD))
      ) {
        delete copy[item];
      }
    });
    delete copy.screenshot;
    return copy;
  };

  if ((!props.data || !Object.keys(props.data).length) && !props.isAllowEmptyJson) {
    return null;
  }

  const moreProps = props.isActionItemsPDF ? { enableClipboard: false } : {};
  return (
    <div className="TechnicalDetails">
      {props.data && (
        <div className="technical-details-header-scope">
          {!props.isActionItemsPDF && <div className="technical-details-body-header">Technical Details</div>}
          <div className="technical-details">
            <ReactJson
              theme={props.theme || `grayscale${props.isActionItemsPDF ? ':inverted' : ''}`}
              iconStyle="square"
              indentWidth={1}
              name={false}
              displayDataTypes={false}
              displayObjectSize={false}
              src={getFilteredTechnicalDetails()}
              style={props.style || {}}
              {...moreProps}
              enableClipboard={(copy: any) => Common.copyText(copy.src)}
            />
          </div>
        </div>
      )}

      {!!rawData.length &&
        rawData.map((r: any) => {
          return (
            <div className="technical-details-header-scope">
              <div className="technical-details-body-header">{r.key}</div>
              <div className="raw-data-box">
                {!props.isPDF ? <textarea readOnly>{r.value}</textarea> : <div className="div-box">{r.value}</div>}
              </div>
            </div>
          );
        })}

      {!props.showTechnicalDetailsOnly && props.data && props.data.screenshot && (
        <div className="technical-details-header-scope">
          <div className="technical-details-body-header">Screenshot</div>
          <div className="">
            <div
              className="link"
              onClick={e => {
                e.preventDefault();
                const _window = window.open();
                var iframe = `<iframe style='border: 0;' width='100%' height='100%' src='${props.data.screenshot}'></iframe>`;
                if (_window) {
                  _window.document.open();
                  _window.document.write(iframe);
                  _window.document.close();
                }
              }}
            >
              Link to screenshot
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
