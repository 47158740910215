import './InputWithLabel.scss';

export function InputWithLabel(props: any) {
  return (
    <div className="InputWithLabel">
      {props.deleteIcon && (
        <img src="/assets/images/trash_gray.svg" alt="Delete" className="delete" onClick={props.onDelete} />
      )}
      <input type="text" value={props.value} className={props.className} onChange={props.onChange} />
      <label>{props.label}</label>
    </div>
  );
}
