import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import classNames from 'classnames';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import { addDownloadFileData, setNewDownloads } from 'features/DownloadsSlice';
import { Image } from 'common/components/image/Image';

import './MenuExportCSV.scss';
import { Text } from '@cyberpion/cyberpion-ui';

const options = [
  {
    text: 'Risk scores by date',
    value: 'date'
  },
  {
    text: 'Findings by issue type',
    value: 'type'
  },
  {
    text: 'Full list of findings',
    value: 'findings'
  }
];
export function MenuExportCSV({ isDropdown }: { isDropdown: boolean }) {
  const [isOpen, setIsOpen] = useState(false);
  const { activeSubsidiary } = useSelector((state: IReduxState) => state.subsidiaries);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<any>();

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onClick = () => {
    setIsOpen(open => !open);
  };

  const getUrlDropdown = (item: string) => {
    switch (item) {
      case 'date':
        return Common.AddMsspAccountIfNeeded('assessments/attack-surface-risk-score/?format=csv');
      case 'type':
        return Common.AddMsspAccountIfNeeded('assessments/attack-surface-risk-score/details/?format=csv');
      case 'findings':
        return Common.AddMsspAccountIfNeeded('assessments/attack-surface-risk-score/issues/?format=csv');
    }
  };

  const getUrlHeader = (item: string) => {
    const params = !!Object.keys(activeSubsidiary).length
      ? `?${item !== 'findings' ? 'subsidiary' : 'group'}=${activeSubsidiary.name}&format=csv`
      : item !== 'findings'
      ? '?is_main_org=True&format=csv'
      : '?format=csv';

    switch (item) {
      case 'date':
        return Common.AddMsspAccountIfNeeded(`assessments/attack-surface-risk-score/${params}`);
      case 'type':
        return Common.AddMsspAccountIfNeeded(`assessments/attack-surface-risk-score/details/${params}`);
      case 'findings':
        return Common.AddMsspAccountIfNeeded(`assessments/attack-surface-risk-score/issues/${params}`);
    }
  };

  const onChange = async (item: string) => {
    let exportCsvPath;
    if (isDropdown) {
      exportCsvPath = getUrlDropdown(item);
    } else {
      exportCsvPath = getUrlHeader(item);
    }

    if (!!exportCsvPath) {
      const response = await RestApi.simpleAsyncGet(exportCsvPath);
      dispatch(addDownloadFileData(response));
      dispatch(setNewDownloads(response?.id));
    }
    setIsOpen(open => !open);
  };

  return (
    <div className="menu-export-csv-wrapper">
      <div className={classNames('menu-export-csv-icon', { button: !isDropdown })} onClick={onClick}>
        <Image className="gridUtilities-csv" alt="CSV" icon={'csv'} type="svg" />
        {!isDropdown && (
          <Text textSize={13} style={{ paddingLeft: 6 }}>
            Export
          </Text>
        )}
      </div>

      {isOpen && (
        <div
          className={classNames(
            { 'menu-export-csv-body-wrapper': isDropdown },
            { 'menu-export-csv-header': !isDropdown }
          )}
          ref={ref}
        >
          <Text weight={500} style={{ marginTop: 8, whiteSpace: 'nowrap', padding: '8px 8px 5px 8px' }}>
            {isDropdown ? 'Export all subsidiaries' : 'Export CSV'}
          </Text>
          {options.map((item: any, key: number) => {
            return (
              <div className={classNames('menu-export-csv-item-scope')} key={key}>
                <div className={classNames('menu-export-csv-label')} onClick={() => onChange(item.value)}>
                  {item.text}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
