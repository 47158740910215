import { DROPDOWNBOX_TYPE } from 'pages/Settings/Notifications/NotificationsContext';
import {
  NOTIFICATIONS_FILTER_STRING,
  FILTER_NUMBER,
  FILTER_ARRAY_STRING,
  FILTER_IP
} from 'common/components/grid/components/gridUtilities/filtersWrapper/config';

export const REQUIRED_FILTERS = {
  action_items: ['name', 'notification_type', 'recipients', 'channel_type', 'configuration_id'],
  active_protection: ['name', 'notification_type', 'recipients', 'channel_type'],
  discovery: ['name', 'notification_type', 'recipients', 'channel_type'],
  certificates: ['name', 'notification_type', 'recipients', 'channel_type'],
  portal_access: ['name', 'notification_type', 'recipients', 'channel_type'],
  asset_management: ['name', 'notification_type', 'recipients', 'channel_type'],
  threat_center: ['name', 'notification_type', 'recipients', 'channel_type']
};
export const DEFAULT_EVENT = {
  action_items: 1,
  active_protection: 6000,
  discovery: 2000,
  certificates: 3000,
  portal_access: 1000,
  asset_management: 1200,
  threat_center: 100
};

const getDropdownboxTypeByFilter = filter => {
  if (filter.choices_type === 'multi_choice') {
    if (filter.type === 'importance') {
      return DROPDOWNBOX_TYPE.IMPORTANCE;
    }
    if (filter.type === 'slider') {
      return DROPDOWNBOX_TYPE.SLIDER;
    }
    return DROPDOWNBOX_TYPE.CHECKBOX;
  }
  if (filter.choices_type === 'radio') {
    return DROPDOWNBOX_TYPE.RADIO;
  }
  if (filter.type === 'auto_complete') {
    return DROPDOWNBOX_TYPE.INPUT_CHIPS;
  }
  if (filter.type === 'slider') {
    return DROPDOWNBOX_TYPE.SLIDER;
  }
  if (filter.type === 'array_string') {
    return DROPDOWNBOX_TYPE.STRINGS_LIST_FILTER;
  }
  if (filter.choices_type === null && (filter.type === 'string' || filter.type === 'ip')) {
    return DROPDOWNBOX_TYPE.TEXT_FILTER;
  }
  if (filter.choices_type === null && filter.type === 'number') {
    return DROPDOWNBOX_TYPE.NUMBER_FILTER;
  }
  if (filter.choices_type === null && filter.type === 'array_number') {
    return DROPDOWNBOX_TYPE.NUMBERS_LIST_FILTER;
  }
  if (filter.choices_type === null && filter.type === 'date') {
    return DROPDOWNBOX_TYPE.DATES;
  }
};

const getBESuffix = filter => {
  if (filter.choices_type === 'multi_choice') {
    if (filter.type === 'importance') {
      return ['__in'];
    }
    if (filter.type === 'slider') {
      return ['__range'];
    }
    if (filter.type === 'rbac_groups' || filter.type === 'array_string') {
      return ['__overlap'];
    }

    return ['__in'];
  }
  if (filter.type === 'radio') {
    return [''];
  }
  if ((filter.choices_type === null || !filter.choices_type) && filter.type === 'string') {
    return ['', '__contains'];
  }
  if ((filter.choices_type === null || !filter.choices_type) && filter.type === 'number') {
    return ['__gte', '__lte'];
  }
  if ((filter.choices_type === null || !filter.choices_type) && filter.type === 'array_string') {
    return ['__overlap', '__contains'];
  }
  if ((filter.choices_type === null || !filter.choices_type) && filter.type === 'ip') {
    return ['__cidr_contains', '__startswith', '', '__cidr_contained_by'];
  }
  if ((filter.choices_type === null || !filter.choices_type) && filter.type === 'array_number') {
    return ['__overlap'];
  }
  if ((filter.choices_type === null || !filter.choices_type) && filter.type === 'multi_choice') {
    return ['__overlap'];
  }
  if (filter.type === 'slider') {
    return ['__lte', '__gte'];
  }
  if (filter.choices_type === null && filter.type === 'date') {
    return ['__lte', '__gte'];
  }
};

const buildEventFilter = (event, existingData) => {
  const type = existingData.notification_type.notification_type;
  const updatedCoices = event.choices.map(choice => {
    return {
      ...choice,
      is_toggled: choice.name === type
    };
  });
  return {
    [event.name]: {
      ...event,
      choices: updatedCoices,
      dropdownbox_type: getDropdownboxTypeByFilter(event),
      be_setter_key: 'notification_type'
    }
  };
};

const buildChannelFilter = (channel, existingData) => {
  let value = existingData?.channel_type?.channel_type;

  const updatedCoices = channel.choices.map(choice => {
    return {
      ...choice,
      is_toggled: !!value || value === 0 ? choice.name === value : false
    };
  });
  return {
    channel_type: {
      ...channel,
      choices: updatedCoices,
      dropdownbox_type: getDropdownboxTypeByFilter(channel),
      be_setter_key: 'channel_type',
      is_default_filter: true
    }
  };
};

const buildIntegrationsFilter = (integrations, existingData) => {
  let value = existingData.configuration_id;

  const updatedCoices = integrations.choices.map(choice => {
    return {
      ...choice,
      is_toggled: !!value ? choice.name === value : false
    };
  });
  return {
    configuration_id: {
      ...integrations,
      choices: updatedCoices,
      dropdownbox_type: getDropdownboxTypeByFilter(integrations),
      be_setter_key: 'configuration_id',
      is_default_filter: true
    }
  };
};

const buildToggleFilter = (filter, existingFilters, reduceAll) => {
  const id = filter.name;
  const isRadio = filter.type === 'radio';

  const key = isRadio
    ? filter.name
    : ['array_number', 'array_string', 'rbac_groups'].includes(filter.type)
    ? `${filter.name}__overlap`
    : `${filter.name}__in`;
  if (!existingFilters[key] && existingFilters[key] !== 0) {
    return {
      ...reduceAll,
      [id]: { ...filter, is_toggled: !!filter.is_default_filter }
    };
  }
  const updatedCoices = filter.choices.map(choice => {
    const isToggled = isRadio ? existingFilters[key] === choice.name : !!existingFilters[key].includes(choice.name);
    return {
      ...choice,
      is_toggled: !!isToggled
    };
  });
  return {
    ...reduceAll,
    [id]: {
      ...filter,
      choices: updatedCoices,
      is_toggled: !!filter.is_default_filter || !!updatedCoices.find(f => !!f.is_toggled) // SHOULD DISPLAY THIS FILTER
    }
  };
};

const buildFilter = (filter, existingFilters, reduceAll) => {
  const id = filter.name;
  let isToggled;
  const copy = { ...filter };
  const choicesFilter = {
    string: NOTIFICATIONS_FILTER_STRING,
    number: FILTER_NUMBER,
    array_string: FILTER_ARRAY_STRING,
    ip: FILTER_IP
  };
  copy.choices = choicesFilter[filter.type];

  const choices = copy.choices.map(choice => {
    const textFilter = !!choice.name ? `${filter.name}${choice.name}` : filter.name;
    const checkFilter = Object.keys(existingFilters).find(f => f === textFilter);
    if (!!checkFilter) {
      isToggled = true;
      return {
        ...choice,
        value: filter.type === 'array_string' ? existingFilters[textFilter].join(',') : existingFilters[textFilter],
        is_toggled: true
      };
    } else {
      return {
        ...choice,
        value: '',
        is_toggled: false
      };
    }
  });
  return {
    ...reduceAll,
    [id]: { ...copy, choices: choices, is_toggled: !!filter.is_default_filter || isToggled }
  };
};

const buildSliderFilter = (filter, existingFilters, reduceAll) => {
  const id = filter.name;
  const lte = `${filter.name}__lte`;
  const gte = `${filter.name}__gte`;
  let choices = [];

  if (existingFilters[lte] === undefined && existingFilters[gte] === undefined) {
    return {
      ...reduceAll,
      [id]: {
        ...filter,
        choices: [],
        is_toggled: !!filter.is_default_filter
      }
    };
  }
  if (existingFilters[gte] !== undefined) {
    choices = [existingFilters[gte]];
  }
  if (existingFilters[lte] !== undefined) {
    choices = [...choices, existingFilters[lte]];
  }
  return {
    ...reduceAll,
    [id]: {
      ...filter,
      choices,
      is_toggled: true
    }
  };
};

const buildArrayNumbersFilter = (filter, existingFilters, reduceAll) => {
  const id = filter.name;
  const key = `${filter.name}__overlap`;
  let choices = [];

  if (existingFilters[key] === undefined) {
    return {
      ...reduceAll,
      [id]: {
        ...filter,
        choices: [],
        is_toggled: !!filter.is_default_filter
      }
    };
  }
  if (existingFilters[key] !== undefined) {
    choices = existingFilters[key].join(',');
  }
  return {
    ...reduceAll,
    [id]: {
      ...filter,
      choices,
      is_toggled: true
    }
  };
};

const buildDateFilter = (filter, existingFilters, reduceAll) => {
  const id = filter.name;
  const lte = `${filter.name}__lte`;
  const gte = `${filter.name}__gte`;
  let choices = [];

  if (!existingFilters[lte] && !existingFilters[gte]) {
    return {
      ...reduceAll,
      [id]: { ...filter, is_toggled: !!filter.is_default_filter }
    };
  }
  if (existingFilters[gte] !== undefined) {
    choices = [existingFilters[gte]];
  }
  if (existingFilters[lte] !== undefined) {
    choices = [...choices, existingFilters[lte]];
  }
  return {
    ...reduceAll,
    [id]: {
      ...filter,
      choices: { from: choices[0], to: choices[1] },
      is_toggled: true
    }
  };
};

const parseNotification = (existingData, allFilters, type) => {
  try {
    const existingFilters = existingData.filters;
    const existEvent = existingData.notification_type.choices.filter(choice => choice.is_toggled);
    const eventType = !!existEvent.length ? existEvent[0].name : DEFAULT_EVENT[type];

    const updatedData = !allFilters.filters
      ? allFilters[eventType]
      : allFilters.filters[eventType]
          .filter(filter => !REQUIRED_FILTERS[type].includes(filter.name))
          .reduce((all, filter) => {
            if (filter.type === 'radio' || filter.choices_type === 'multi_choice') {
              return buildToggleFilter(filter, existingFilters, all);
            }
            if (
              filter.type === 'ip' ||
              (filter.choices_type === null &&
                (filter.type === 'string' || filter.type === 'number' || filter.type === 'array_string'))
            ) {
              return buildFilter(filter, existingFilters, all);
            }
            if (filter.choices_type === null && filter.type === 'array_number') {
              return buildArrayNumbersFilter(filter, existingFilters, all);
            }
            if (filter.type === 'slider') {
              return buildSliderFilter(filter, existingFilters, all);
            }
            if (filter.choices_type === null && filter.type === 'date') {
              return buildDateFilter(filter, existingFilters, all);
            }

            return {
              ...all,
              [filter.name]: { ...filter, is_toggled: !!filter.is_default_filter }
            };
          }, {});

    return {
      ...existingData,
      filters: {
        ...updatedData
      }
    };
  } catch (err) {}
};

const parseRequiredFields = (existingData, allFilters, notificationType) => {
  try {
    let integrationData = {};
    const type = allFilters?.shared
      ? allFilters?.shared.find(item => item.name === 'notification_type')
      : allFilters.notification_type;
    const eventData = buildEventFilter(type, existingData);

    const channel = allFilters?.shared
      ? allFilters?.shared.find(item => item.name === 'channel_type')
      : allFilters.channel_type;
    const channelData = buildChannelFilter(channel, existingData);

    if (notificationType === 'action_items') {
      const integrations = allFilters?.shared
        ? allFilters?.shared.find(item => item.name === 'configuration_id')
        : allFilters.configuration_id;
      integrationData = integrations ? buildIntegrationsFilter(integrations, existingData) : null;
    }

    return {
      ...existingData,
      ...eventData,
      ...channelData,
      ...integrationData
    };
  } catch (err) {}
};

const enrichNotificationData = (notificationData, notificationType) => {
  try {
    const allFilters = notificationData?.filters;
    const enrichFilters = Object.keys(allFilters).reduce((all, key) => {
      return {
        ...all,
        [key]: {
          ...allFilters[key],
          id: key,
          dropdownbox_type: getDropdownboxTypeByFilter(allFilters[key]),
          is_required: REQUIRED_FILTERS[notificationType].includes(key),
          be_setter_key: getBESuffix(allFilters[key]).map(item => `${key}${item}`)
        }
      };
    }, {});

    return {
      ...notificationData,
      filters: enrichFilters
    };
  } catch (err) {}
};

const getAllLabels = notificationData => {
  try {
    const filters = notificationData?.filters;

    const labels = Object.values(filters).reduce((all, filter) => {
      if (
        [DROPDOWNBOX_TYPE.CHECKBOX, DROPDOWNBOX_TYPE.RADIO, DROPDOWNBOX_TYPE.IMPORTANCE].includes(
          filter.dropdownbox_type
        )
      ) {
        const choices = filter.choices;
        let options = {};
        choices.forEach(choice => {
          options = {
            ...options,
            [choice.name]: choice.label
          };
        });
        return {
          ...all,
          [filter.name]: options
        };
      }
      if ([DROPDOWNBOX_TYPE.TEXT_FILTER, DROPDOWNBOX_TYPE.NUMBER_FILTER].includes(filter.dropdownbox_type)) {
        return {
          ...all,
          [filter.name]: filter.label
        };
      }
      return {
        ...all
      };
    }, {});

    return {
      ...labels,
      recipients: 'Recipients',
      notification_type: 'Type',
      channel_type: 'Channel',
      configuration_id: 'Integration'
    };
  } catch (err) {}
};

export const onChangeNotificationTypeDropdown = (eventName, existingData, allFilters, notificationType) => {
  let data = { ...existingData };
  const eventChoices = data.notification_type.choices.map(choice => ({
    ...choice,
    is_toggled: choice.name === eventName
  }));
  data = {
    ...data,
    filters: {},
    notification_type: {
      ...data.notification_type,
      choices: eventChoices
    },
    recipients: [],
    channel_type: {
      ...data.channel_type,
      choices: data.channel_type.choices.map(choice => ({
        ...choice,
        is_toggled: false
      }))
    },
    ...(!!data.configuration_id
      ? {
          configuration_id: {
            ...data.configuration_id,
            choices: data.configuration_id.choices.map(choice => ({
              ...choice,
              is_toggled: false
            }))
          }
        }
      : {})
  };

  const parsedFilters = parseNotification(data, allFilters, notificationType);
  const enrichedNotificationData = enrichNotificationData(parsedFilters, notificationType);

  const allLabels = getAllLabels(enrichedNotificationData);

  return {
    updatedNotificationData: enrichedNotificationData,
    allLabels: allLabels
  };
};

// builds the whole notification data, add keys: is_toggled per filter (if it will be display),
// is_toggled per option (if it will be toggled - radio, checkboxes), dropdownbox_type, id, is_required
export const parseNotificationData = (existingData, allFilters, notificationType) => {
  const requiredField = parseRequiredFields(existingData, allFilters, notificationType);
  const fullData = parseNotification(requiredField, allFilters, notificationType);
  const enrichedNotificationData = enrichNotificationData(fullData, notificationType);

  const allLabels = getAllLabels(enrichedNotificationData);

  return {
    notificationData: enrichedNotificationData,
    allLabels: allLabels
  };
};

export const initNotificationData = notificationType => {
  return {
    name: '',
    notification_type: { notification_type: DEFAULT_EVENT[notificationType] },
    // configuration_id: -1,
    // recipients: [],
    status: 0,
    // channel_type: -1,
    filters: {}
  };
};
