import { IconsCommon } from '../iconsCommon';
import './IconChevron.scss';
interface IIconChevron {
  toggle: boolean;
  onClick: (value: boolean) => void;
}

export function IconChevron(props: IIconChevron) {
  function onToggleArrow() {
    props.onClick(!props.toggle);
  }

  return (
    <svg
      className="IconChevron_scope"
      onClick={onToggleArrow}
      width="64"
      height="12"
      viewBox="0 0 64 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.51 3.50003C57.7653 1.25851 60.8179 0.00012207 64 0.00012207H0C2.93415 0.00012207 5.81221 1.27826 7.72477 3.50003C8.11114 4.02718 8.50368 4.59048 8.90526 5.16676L8.90591 5.16769L8.90599 5.16781C11.146 8.38229 13.667 12 16.9666 12H44.7433C48.2267 12 51.6569 7.99371 54.5257 4.6431C54.8618 4.25058 55.1902 3.86707 55.51 3.50003Z"
        fill="#2C2C30"
      />
      <g className={`IconChevron_arrows IconChevron_${IconsCommon.viewRotateClass(props.toggle)}`}>
        <path
          opacity="0.34"
          d="M9.68686 9.81315C9.82986 9.95615 9.87264 10.1712 9.79525 10.358C9.71786 10.5449 9.53554 10.6667 9.33331 10.6667H6.66664C6.46441 10.6667 6.28209 10.5449 6.2047 10.358C6.12731 10.1712 6.17009 9.95615 6.31309 9.81315L7.64642 8.47982C7.84168 8.28456 8.15826 8.28456 8.35353 8.47982L9.68686 9.81315Z"
          fill="#D1D2D3"
        />
        <path d="M5.09961 8.5L7.99988 6L11.0996 8.5" stroke="#B7BBC5" />
      </g>
    </svg>
  );
}
