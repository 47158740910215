import React from 'react';
import { IIcon } from 'common/interfaces/interface';
export class IconAssess extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#5D6576';
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" fill="none">
        <path
          d="M16.5 9.5C16.5 13.366 13.366 16.5 9.5 16.5C5.63401 16.5 2.5 13.366 2.5 9.5C2.5 5.63401 5.63401 2.5 9.5 2.5C13.366 2.5 16.5 5.63401 16.5 9.5Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.1464 17.8536C17.3417 18.0488 17.6583 18.0488 17.8536 17.8536C18.0488 17.6583 18.0488 17.3417 17.8536 17.1464L17.1464 17.8536ZM14.8536 14.1464L14.5 13.7929L13.7929 14.5L14.1464 14.8536L14.8536 14.1464ZM17.8536 17.1464L14.8536 14.1464L14.1464 14.8536L17.1464 17.8536L17.8536 17.1464Z"
          fill="#5D6576"
        />
        <path d="M9.5 5.97183L11.6169 9.5H7.3831L9.5 5.97183Z" stroke={color} />
        <path
          d="M10.5657 11L8.43426 11C8.03491 11 7.79671 11.4451 8.01823 11.7773L9.08397 13.376C9.28189 13.6728 9.71811 13.6728 9.91602 13.376L10.9818 11.7774C11.2033 11.4451 10.9651 11 10.5657 11Z"
          fill="#5D6576"
        />
      </svg>
    );
  }
}
