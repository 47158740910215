import React from 'react';
import { Grid } from '@mui/material';
import { Text } from '@cyberpion/cyberpion-ui';

interface IRelationship {
  relationship: number;
  label: string;
}

const Relationship: React.FC<IRelationship> = ({ relationship, label }) => {
  const relationshipIcons = {
    1: '/assets/images/relationship_substract_a.svg',
    2: '/assets/images/relationship_substract_b.svg',
    3: '/assets/images/relationship_intersect.svg',
    4: '/assets/images/relationship_combine.svg',
    5: '/assets/images/relationship_union.svg'
  };
  return (
    <Grid container alignItems="center" data-testid="relationship-container">
      <Grid item sx={{ height: 16 }}>
        <img
          src={relationshipIcons[relationship as keyof typeof relationshipIcons]}
          className=""
          alt="relationship Icon"
          data-testid="relationship-image"
        />
      </Grid>
      <Grid item sx={{ paddingLeft: 0.5 }}>
        <Text>{label}</Text>
      </Grid>
    </Grid>
  );
};

export default Relationship;
