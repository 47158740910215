import './AssesmentsArrayLinkRenderer.scss';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';

export function AssesmentsArrayLinkRenderer(props: any) {
  //@param: index = index of array
  //@return: element circle
  const addCircle = (index: number) => {
    if (props.value.length - 1 !== index) {
      return <div className="arrayRenderer-circle"></div>;
    }
  };

  return (
    <ul className="AssesmentsArrayLinkRenderer" style={{ flexWrap: props.flexWrap }}>
      {props.value &&
        props.value.map((item: string, index: number) => (
          <li key={index}>
            <LinkNewTab
              to={`/pages/singleAsset/internalAssets/${props.row.asset}/tests?popup=${encodeURI(item)}`}
              text={item}
              className="grid-link-field"
            />
            {addCircle(index)}
          </li>
        ))}
    </ul>
  );
}
