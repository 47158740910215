import React, { forwardRef } from 'react';
import { Text } from '@cyberpion/cyberpion-ui';

const CompromisedDeviceDetails = forwardRef(({ data }: { data: any }, refDetails: any) => {
  const { details, row } = data;

  return (
    <div ref={refDetails} className="compromised-devices-details">
      {!!details.username && (
        <div style={{ marginTop: 24, alignItems: 'center' }}>
          <Text textSize={13} upper weight={600} style={{ marginBottom: 4 }}>{`username: ${details.username}`}</Text>
          <Text textSize={12} textColor="#8C909A">
            Employee
          </Text>
        </div>
      )}
      {!!row.url && (
        <div style={{ marginTop: 24, alignItems: 'center' }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            URL
          </Text>
          <Text textColor="#454549">{row.url}</Text>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          marginTop: !!details.date_compromised || !!details.date_added ? 24 : 0,
          alignItems: 'center'
        }}
      >
        {!!details.date_compromised && (
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 4, paddingRight: 16 }}>
            <Text weight={600} style={{ marginRight: 4, marginBottom: 4 }}>
              Date Compromised
            </Text>
            <Text>{details?.date_compromised}</Text>
          </div>
        )}
        {!!details.date_added && (
          <div
            style={{
              paddingLeft: !!details.date_compromised ? 20 : 0,
              alignItems: 'center',
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <Text weight={600} style={{ marginBottom: 4 }}>
              Date Added
            </Text>
            <Text>{details.date_added}</Text>
          </div>
        )}
      </div>

      {!!details.internal_url !== undefined && (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 24, textTransform: 'capitalize' }}>
          <Text weight={600} style={{ marginRight: 4, marginBottom: 4 }}>
            Internal URL
          </Text>
          <Text>{details.internal_url.toString()}</Text>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          marginTop: !!details.related_asset || !!details.endpoint_ad_user ? 24 : 0,
          alignItems: 'center'
        }}
      >
        {!!details.related_asset && (
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 4, paddingRight: 16 }}>
            <Text weight={600} style={{ marginRight: 4, marginBottom: 4 }}>
              Related Asset
            </Text>
            <Text>{details?.related_asset}</Text>
          </div>
        )}
        {!!details.endpoint_ad_user && (
          <div
            style={{
              paddingLeft: !!details.related_asset ? 20 : 0,
              alignItems: 'center',
              borderLeft: !!details.related_asset ? '1px solid #ECECEC' : ''
            }}
          >
            <Text weight={600}>User's AD Domain</Text>
            <Text>{details.endpoint_ad_user}</Text>
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: !!details.endpoint_ip || !!details.endpoint_os ? 24 : 0,
          alignItems: 'center'
        }}
      >
        {!!details.endpoint_ip && (
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 4, paddingRight: 16 }}>
            <Text weight={600} style={{ marginRight: 4, marginBottom: 4 }}>
              Endpoint IP
            </Text>
            <Text>{details?.endpoint_ip}</Text>
          </div>
        )}
        {!!details.endpoint_os && (
          <div
            style={{
              paddingLeft: !!details.endpoint_ip ? 20 : 0,
              alignItems: 'center',
              borderLeft: !!details.endpoint_ip ? '1px solid #ECECEC' : ''
            }}
          >
            <Text weight={600} style={{ marginBottom: 4 }}>
              Endpoint OS
            </Text>
            <Text>{details.endpoint_os}</Text>
          </div>
        )}
      </div>

      {!!details.stealer_family && (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 24, textTransform: 'capitalize' }}>
          <Text weight={600} style={{ marginRight: 4, marginBottom: 4 }}>
            Stealer Family
          </Text>
          <Text>{details.stealer_family}</Text>
        </div>
      )}
      {!!details.malware_path && (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 24, textTransform: 'capitalize' }}>
          <Text weight={600} style={{ marginRight: 4, marginBottom: 4 }}>
            Malware Path
          </Text>
          <Text>{details.malware_path}</Text>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          marginTop: !!details.password_strength || !!details.password_hash ? 24 : 0,
          alignItems: 'center'
        }}
      >
        {!!details.password_strength && (
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 4, paddingRight: 16 }}>
            <Text weight={600} style={{ marginRight: 4, marginBottom: 4 }}>
              Password Strength
            </Text>
            <Text>{details?.password_strength}</Text>
          </div>
        )}
        {!!details.password_hash && (
          <div
            style={{
              paddingLeft: !!details.password_strength ? 20 : 0,
              alignItems: 'center',
              borderLeft: !!details.password_strength ? '1px solid #ECECEC' : ''
            }}
          >
            <Text weight={600} style={{ marginBottom: 4 }}>
              Password Hash (SHA 256)
            </Text>
            <Text>{details.password_hash}</Text>
          </div>
        )}
      </div>

      {!!details.groups && (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 24, textTransform: 'capitalize' }}>
          <Text weight={600} style={{ marginRight: 4, marginBottom: 4 }}>
            Groups
          </Text>
          <Text>{details.groups}</Text>
        </div>
      )}
    </div>
  );
});

export default CompromisedDeviceDetails;
