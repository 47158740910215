import { IntegrationHeader } from '../Integration/IntegrationHeader/IntegrationHeader';
import { Button } from 'common/components/Button/Button';
import './NotAvailableIntegration.scss';

export function NotAvailableIntegration(props: any) {
  return (
    <div className="NotAvailable integration">
      <IntegrationHeader name={props.metadata.label} />
      <div className="not-available-wrapper">
        <img src="/assets/images/Integrations/icon-notavailable.svg" alt="Icon" />
        <p>In order to proceed with this integration please contact IONIX service desk</p>
        <div className="button-wrapper">
          <Button text="Close This Window" type="button" onClick={props.onClose} buttonStyle="secondary" size="small" />
        </div>
      </div>
    </div>
  );
}
