import React, { createContext, useContext, useState, useEffect } from 'react';

import { RestApi } from 'common/services/rest-api.service';
// import { showError, showSuccess } from 'features/AlertSlice';
import { useSelector } from 'react-redux';
import { fetchASMRadar } from '../ASMWrapper/sevice.api';
import { selectCompanyDisplayName } from 'features/LoginSlice';
import { getASMTableRowsBySevirity } from '@cyberpion/cyberpion-ui';
import { fetchHightlightData } from './rules';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Common } from 'common/services/common.service';

export type ThreatsReportContextType = {
  allThreats: any;
  highlightsList: any;
  highlightsExamplesData: any;
  aggregationData: any;
  topExamplesIDs: any;
  companyName: string;
  pagesCount: any;
  pagesOrder: any;
  setPagesAmount: (data: any) => void;
  isLoading: boolean;
  totalPages: number;
  PAGE_HEIGHT: number;
  PAGE_WIDTH: number;
  logoUrl: string | null;
  displayCompany: string | null;
  showAllRights: boolean;
  keepParagraph: boolean;
  globalFilter: string | null;
  orgScoreData: any;
};

interface ThreatsReportProviderProps {
  children: JSX.Element | JSX.Element[];
}

const MAX_PER_CLASS = 2;
const NUMBER_OF_EXAMPLES = 4;
const ThreatsReportContext = createContext<ThreatsReportContextType | null>(null);
const fixedPages = ['cover', 'radar', 'highlights'];
export let lookupHighlights: any = {
  reports: 0,
  assets: [],
  classes: {},
  network: {},
  used_action_items_titles: [],
  used_tests: []
};

export const ThreatsReportProvider: React.FC<ThreatsReportProviderProps> = ({ children }) => {
  const [allThreats, setAllThreats] = useState<any>(null);
  const [highlightsList, setHighlightsList] = useState<any>(null);
  const [highlightsExamplesData, setHighlightsExamplesData] = useState<any>(null);
  const [aggregationData, setAggregationData] = useState<any>(null);
  const [orgScoreData, setOrgScoreData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pagesCount, setPagesCount] = useState({ cover: 1, radar: 1, org_score: 1 });
  const [pagesOrder, setPagesOrder] = useState<string[]>(fixedPages);
  const [totalPages, setTotalPages] = useState(0);
  const [topExamplesIDs, setTopExamplesIDs] = useState<number[]>([]);
  const companyName = useSelector(selectCompanyDisplayName);
  const [keepParagraph, setKeepParagraph] = useState(true);
  const [showAllRights, setShowAllRights] = useState(true);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [displayCompany, setDisplayCompany] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);

  const location = useLocation();
  const params = queryString.parse(location.search);

  const PAGE_HEIGHT = 1252; // 1056
  const PAGE_WIDTH = 970; // 816

  const filterOutClassesAndSeverities = (data: any) => {
    let copy: any = [];
    let mapClasses = new Map();
    data.forEach((item: any) => {
      if ((!mapClasses.has(item.class) || mapClasses.get(item.class) < 4) && item.severity !== '0') {
        mapClasses.set(item.class, mapClasses.get(item.class) + 1 || 1);
        copy = [...copy, item];
      }
    });
    return copy;
  };

  const findNextResultRow = (highlight: any) => {
    const { class: className, pdf_should_display } = highlight;
    let nextRow: any;

    if (lookupHighlights.classes[className] >= MAX_PER_CLASS || !pdf_should_display) {
      return null;
    }
    highlight.results.every((row: any) => {
      let shouldReturnRow: boolean =
        !lookupHighlights.assets.includes(row.asset) && !lookupHighlights.assets.includes(`www.${row.asset}`);
      if (highlight.section === 'action_items') {
        const title = row?.title?.label;
        shouldReturnRow = shouldReturnRow && !lookupHighlights.used_action_items_titles.includes(title);
        if (shouldReturnRow) {
          lookupHighlights.used_action_items_titles.push(title);
        }
      } else if (highlight.section === 'network_assessment') {
        if (!!highlight.rules) {
          const networkFailed = !!row.network_failed_tests
            ? row.network_failed_tests
                .join()
                .toLowerCase()
                .split(',')
            : [];
          const rules = highlight.rules.split(':');
          if (rules[0] === 'includes') {
            shouldReturnRow =
              shouldReturnRow &&
              !!networkFailed.length &&
              rules[1].split(',').some((item: string) => {
                const isOk = networkFailed.includes(item) && !lookupHighlights.used_tests.includes(item);
                if (isOk) {
                  row.selected_failed_test = item;
                }
                return isOk;
              });
          } else if (rules[0] === 'not_includes') {
            shouldReturnRow =
              shouldReturnRow &&
              !!networkFailed.length &&
              networkFailed.some((item: string) => {
                const isOk = !rules[1].split(',').includes(item) && !lookupHighlights.used_tests.includes(item);
                if (isOk) {
                  row.selected_failed_test = item;
                }
                return isOk;
              });
          } else if (rules === 'lowest') {
            shouldReturnRow =
              shouldReturnRow &&
              !!row.network_failed_tests &&
              row.network_failed_tests.some((item: string) => rules[1].includes(item.toLowerCase()));
          }
        }
      }
      if (shouldReturnRow) {
        nextRow = row;
        return false;
      }
      return true;
    });
    return nextRow;
  };

  const findHighlightsExamples = async (jsonFilteredData: any) => {
    let index = 0;
    let stop;
    let examples: any = [];

    while (!stop) {
      const jsonHighlightData = jsonFilteredData[index];
      // console.log('XXX: jsonFilteredData: ', jsonFilteredData[index].title, jsonFilteredData[index], lookupHighlights);
      const nextRow = findNextResultRow(jsonHighlightData);

      // console.log('XXX: nextRow: ', nextRow);

      if (!!nextRow) {
        setPagesOrder((pages: any) => [...pages, jsonHighlightData.id]);
        const fetchedExampleData = await fetchHightlightData(jsonHighlightData, nextRow);

        examples = [...examples, { ...fetchedExampleData, order: index + 1 }];
        lookupHighlights.classes = {
          ...lookupHighlights.classes,
          [jsonHighlightData.class]: !!lookupHighlights.classes[jsonHighlightData.class]
            ? lookupHighlights.classes[jsonHighlightData.class] + 1
            : 1
        };
        lookupHighlights.assets.push(nextRow.asset);
        lookupHighlights.reports++;
        if (fetchedExampleData?.test?.name) {
          lookupHighlights.network[fetchedExampleData.test.name] = true;
        }
      }
      index++;
      stop = lookupHighlights.reports >= NUMBER_OF_EXAMPLES || index >= jsonFilteredData.length;
    }

    setPagesOrder((pages: any) => [...pages, ...['org_score', 'lastPage']]);
    console.log('Highlights: ', examples);
    // console.log('XXX: findRule', lookupHighlights);
    return examples;
  };

  const getHighlightsThreats = async (data: any) => {
    let updatedData: any;
    updatedData = Object.values(data)
      .flat()
      .sort((a: any, b: any) => b.priority - a.priority);

    updatedData = filterOutClassesAndSeverities(updatedData);
    // console.log('XXX: AFTER FILTER', updatedData);

    const examplesData = !!updatedData.length ? await findHighlightsExamples(updatedData) : [];
    const topIDs = examplesData
      .map((item: any) => parseInt(item.id))
      .slice(0, NUMBER_OF_EXAMPLES > 4 ? 4 : NUMBER_OF_EXAMPLES);
    setTopExamplesIDs(topIDs);
    setHighlightsExamplesData(examplesData);
    setIsLoading(false);
  };

  const increaseLimitApisCall = (response: any) => {
    return Object.entries(response).reduce((all: any, [key, value]: any) => {
      return {
        ...all,
        [key]: value.map((item: any) => ({
          ...item,
          api_call: item.api_call.replace('limit=1', 'limit=50')
        }))
      };
    }, {});
  };

  useEffect(() => {
    if (!!params.all_right_reserved) {
      setShowAllRights(params.all_right_reserved === 'true');
    }
    if (params.company_logo_url && params.company_logo_url !== '') {
      const url = encodeURI(params.company_logo_url.toString());
      setLogoUrl(url.toString());
    }
    if (!!params.keep_paragraph) {
      setKeepParagraph(params.keep_paragraph === 'true');
    }
    if (!!params.company_name) {
      setDisplayCompany(params.company_name.toString());
    }

    setGlobalFilter(!!params.group ? params.group.toString() : '');
  }, [params]);

  useEffect(() => {
    if (!!pagesCount) {
      const pageCount: number = Object.values(pagesCount).reduce((acc: number, item: number) => acc + item, 0);
      setTotalPages(pageCount);
    }
  }, [pagesCount]);

  useEffect(() => {
    async function fetchAndEnrichedASMRadar(configJson: any, globalFilter: string) {
      const allThreatsData = await fetchASMRadar(configJson, globalFilter, true);

      setAllThreats(allThreatsData);
      getHighlightsThreats(allThreatsData);
    }

    async function fetchAggregationData() {
      const aggregationData: any = await RestApi.getPromiseAll({
        aggregation: !!globalFilter ? `dashboard/summary/?group=${globalFilter}` : 'dashboard/summary/'
      });

      setAggregationData(aggregationData.aggregation);
    }

    async function fetchOrgScoreData() {
      const url = !!globalFilter
        ? Common.AddMsspAccountIfNeeded(
            `assessments/attack-surface-risk-score/?ordering=-date&subsidiary=${globalFilter}`
          )
        : Common.AddMsspAccountIfNeeded('assessments/attack-surface-risk-score/?ordering=-date&is_main_org=True');

      const orgScore: any = await RestApi.getPromiseAll({
        orgScore: url
      });

      const results = !!orgScore.orgScore?.results?.length ? orgScore.orgScore.results[0] : null;
      setOrgScoreData(results);
    }

    if (globalFilter !== null) {
      RestApi.getData('configuration/asm/').subscribe((response: any) => {
        const updatedData = increaseLimitApisCall(response);
        fetchAggregationData();
        fetchOrgScoreData();
        fetchAndEnrichedASMRadar(updatedData, globalFilter);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    if (!!allThreats) {
      const rows = [
        ...getASMTableRowsBySevirity(allThreats, 'critical'),
        ...getASMTableRowsBySevirity(allThreats, 'high'),
        ...getASMTableRowsBySevirity(allThreats, 'medium'),
        ...getASMTableRowsBySevirity(allThreats, 'low'),
        ...getASMTableRowsBySevirity(allThreats, 'informational')
      ];
      setHighlightsList(rows);
    }
  }, [allThreats]);

  const setPagesAmount = (data: any) => {
    setPagesCount((count: any) => ({ ...count, ...data }));
  };

  return (
    <ThreatsReportContext.Provider
      value={{
        allThreats,
        highlightsList,
        highlightsExamplesData,
        aggregationData,
        pagesCount,
        setPagesAmount,
        topExamplesIDs,
        pagesOrder,
        companyName,
        isLoading,
        totalPages,
        PAGE_HEIGHT,
        PAGE_WIDTH,
        logoUrl,
        displayCompany,
        showAllRights,
        keepParagraph,
        globalFilter,
        orgScoreData
      }}
    >
      {children}
    </ThreatsReportContext.Provider>
  );
};

export const useThreatsReport = () => {
  return useContext(ThreatsReportContext);
};
