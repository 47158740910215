import { useState } from 'react';
import classNames from 'classnames';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import './Evidence.scss';
import { TechnicalDetails } from 'common/components/TechnicalDetails/TechnicalDetails';

export function Evidence(props: any) {
  const [visible, setVisible] = useState<boolean>(props.isOpened);

  const convertTitle = (title: string): string | JSX.Element => {
    if (title === 'url') {
      return title.toUpperCase();
    }
    if (title.includes('fqdn')) {
      return title.replace('fqdn', 'FQDN');
    }

    return <span style={{ textTransform: 'capitalize' }}>{title.replace(/_/g, ' ')}</span>;
  };

  const getItem = (item: any, title: string): JSX.Element => {
    if (title === 'url') {
      return <div>{item}</div>;
    }
    if (title === 'screenshot') {
      return <img src={item} alt="Screenshot" />;
    }
    if (title.startsWith('raw')) {
      return <textarea value={item} />;
    }
    if (typeof item === 'object' && !Array.isArray(item)) {
      return (
        <TechnicalDetails
          data={item}
          theme={'grayscale:inverted'}
          style={{ borderRadius: '4px', border: '1px solid #EAEAEA', fontSize: '1.2rem' }}
        />
      );
    }
    return <div>{item}</div>;
  };

  const getEvidenceItems = (): JSX.Element[] => {
    const _html: JSX.Element[] = [];
    Object.keys(props.data).forEach((item: any) => {
      if (item !== 'port') {
        _html.push(
          <li>
            <header>{convertTitle(item)}</header>
            <div>{getItem(props.data[item], item)}</div>
          </li>
        );
      }
    });
    return _html;
  };

  return (
    <li className="Evidence">
      <header onClick={() => setVisible(!visible)}>
        <IconArrow active={visible} toggleRotate={visible} />
        Port {props.data.port}
      </header>
      <ul className={classNames('evidence-list', { visible: visible })}>{getEvidenceItems()}</ul>
    </li>
  );
}
