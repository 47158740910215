import { Text } from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';

const PageFooter = ({ id, order }: { id: string; order?: number }) => {
  const { totalPages, pagesCount, pagesOrder, showAllRights } = useThreatsReport() as ThreatsReportContextType;
  const year = new Date().getUTCFullYear();
  let currentPage = 0;

  for (let i = 0; i < pagesOrder.length; i++) {
    if (pagesCount[pagesOrder[i]]) {
      currentPage += pagesCount[pagesOrder[i]];
    }
    if (id === pagesOrder[i]) break;
  }

  return (
    <Grid container sx={{ margin: '12px 0px' }} justifyContent="space-between">
      <Grid item marginLeft={4}>
        {showAllRights && (
          <Text textSize={10} textColor="#2E2E2F">
            {`©${year} IONIX Inc. All rights reserved.`}
          </Text>
        )}
      </Grid>
      <Grid item marginRight={4}>
        <Text textSize={10} textColor="#2E2E2F">
          {`${currentPage} of ${totalPages}`}
        </Text>
      </Grid>
    </Grid>
  );
};

export default PageFooter;
