import { PopUp } from 'common/components/popUp/PopUp';
import { MENU_ITEMS } from 'common/components/sidebar-menu/config';
import { SidebarMenu } from 'common/components/sidebar-menu/sidebar-menu';
import { UserSettings } from 'common/components/UserSettings/UserSettings';
import { Logo } from 'header/components/logo/Logo';
import { User } from 'header/components/user/User';
import { Header } from 'header/header';
import { useEffect, useState } from 'react';
import { PagesRouting } from './PagesRouting';
import { Help } from 'common/components/Help/Help';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import classNames from 'classnames';
import { Common } from 'common/services/common.service';
import { HelpCenter } from 'common/components/HelpCenter/HelpCenter';

export function AppRouting(props: any) {
  const [settingsPopupVisible, setSettingsPopupVisible] = useState<boolean>(false);
  const { user, account } = useSelector((state: IReduxState) => state.login);
  const { isGraphFullScreen } = useSelector((state: IReduxState) => state.general);
  const history = useHistory();

  useEffect(() => {
    Common.updateLinksToMssp();
    window.addEventListener(
      'DOMNodeInserted',
      () => {
        Common.updateLinksToMssp();
      },
      false
    );
    history.listen(() => {
      if (account) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set('account', account);
        var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="layout-container">
      <HelpCenter />
      <div
        className={classNames('layout-sidebar', {
          'flex-start': user.is_mssp_user && !Common.getParamFromUrl('account'),
          'move-to-bottom': isGraphFullScreen
        })}
      >
        <Logo />
        <SidebarMenu data={MENU_ITEMS} pageName={props.match.params.page} />
        <div>
          <Help />
          <User onOpenSettingsPopup={() => setSettingsPopupVisible(true)} />
        </div>
        <PopUp setVisible={setSettingsPopupVisible} visible={settingsPopupVisible}>
          <UserSettings onClose={() => setSettingsPopupVisible(false)} />
        </PopUp>
      </div>

      <div className="layout-content">
        <Header />
        <PagesRouting />
      </div>
    </div>
  );
}
