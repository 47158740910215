import { useEffect, useRef, useState } from 'react';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { setDeletePromptParams } from 'features/GridSlice';
import { useDispatch } from 'react-redux';
import { PopUp } from 'common/components/popUp/PopUp';
import { AddAssetsPopUp } from 'pages/Settings/ManageAssets/ManageAssetsActions/AddAssetsPopUp/AddAssetsPopUp';
import './IpRangesActions.scss';

export function IpRangesActions(props: any) {
  const dispatch = useDispatch<any>();
  const ref = useRef<HTMLDivElement>(null);
  const [actionsMenuOpen, setActionsMenuOpen] = useState<boolean>(false);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setActionsMenuOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onRemove = () => {
    dispatch(
      setDeletePromptParams({
        category: 'organization-management',
        tab: 'ip-ranges',
        index: null,
        ids: props.ids,
        message: "Are you sure you would like to remove the following IP's?"
      })
    );
  };

  return (
    <div className="IpRestrictionsActions" ref={ref}>
      <button className={`btn btn-main`} onClick={() => setPopupVisible(true)}>
        Add Additional IPs
      </button>
      <span className={`actions-trigger ${actionsMenuOpen ? 'open' : ''}`}>
        <div className="trigger-wrapper" onClick={() => setActionsMenuOpen(!actionsMenuOpen)}>
          Actions
          <IconArrow />
        </div>
        <ul>
          <li className={!props.ids.length ? 'disabled' : ''} onClick={onRemove}>
            Remove
          </li>
        </ul>
      </span>
      <PopUp visible={popupVisible} setVisible={() => setPopupVisible(false)}>
        <AddAssetsPopUp onClose={() => setPopupVisible(false)} type="ip" />
      </PopUp>
    </div>
  );
}
