import React, { useState } from 'react';
import classNames from 'classnames';
import { TextField } from '@mui/material';
import { ToolTip } from 'common/components/toolTip/toolTip';
import Button from '@mui/material/Button';
import { Text } from '@cyberpion/cyberpion-ui';
import { setFieldsValue } from 'features/IntegrationsSlice';
import { useDispatch, useSelector } from 'react-redux';

import './ChipsList.scss';
import { setDelay, showSuccess } from 'features/AlertSlice';

const iconClipboard = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <rect
      x="1.125"
      y="3.375"
      width="7.5"
      height="7.5"
      rx="0.75"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.375 3.375V1.875C3.375 1.46079 3.71079 1.125 4.125 1.125H10.125C10.5392 1.125 10.875 1.46079 10.875 1.875V7.875C10.875 8.28921 10.5392 8.625 10.125 8.625H8.625"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const iconInfo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="5.5" transform="rotate(-180 8 8)" stroke="#8C909A" />
    <path d="M8 10.5L8 7.5" stroke="#8C909A" stroke-linecap="round" />
    <circle cx="8" cy="5.5" r="0.5" transform="rotate(-180 8 5.5)" fill="#8C909A" />
  </svg>
);

const iconDelete = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3516 2.82031L2.99219 15.1797"
      stroke="#60646A"
      stroke-width="1.47135"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.3516 15.1797L2.99219 2.82031"
      stroke="#60646A"
      stroke-width="1.47135"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface ChipsListProps {
  id: string;
  title: string;
  valuesList: string[];
  // setValueItem: (value: string) => void;
  // valueItem: string;
  // setValueList: (value: string) => void;
  readonly: boolean;
  is_clipboard?: boolean;
  placeholder?: string;
  description?: string;
  onDelete?: (value: string) => void;
  onClipboardCopy?: (value: string) => void;
  onAddItem?: (value: string) => void;
  className?: string;
  // is_error?: boolean;
  // errorText?: string;
}

const ChipsList: React.FC<ChipsListProps> = ({
  id,
  title,
  valuesList,
  placeholder,
  description,
  readonly,
  is_clipboard = !!readonly ? true : false,
  className,
  onClipboardCopy,
  onAddItem,
  onDelete
  // setValueItem
  // setValueList,
  // valueItem = ''
  // is_error,
  // errorText
}) => {
  const [showClipboardIndex, setShowClipboardIndex] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState('');
  const { fieldsValue } = useSelector((state: any) => state.integrations);
  const dispatch = useDispatch<any>();

  // const valuesList = fieldsValue[id] || [];

  // const [inputValue, setInputValue] = useState(value);
  const onClipboard = (value: string) => () => {
    navigator.clipboard.writeText(value);
    dispatch(setDelay(2000));
    dispatch(showSuccess('Copied to clipboard'));
    if (onClipboardCopy) {
      onClipboardCopy(value);
    }
  };

  const addItem = () => {
    // setValueItem('');
    // setValueList(valueItem);
    setInputValue('');
    dispatch(setFieldsValue({ [id]: [inputValue, ...valuesList] }));
    if (onAddItem) {
      onAddItem(inputValue);
    }
  };

  const handleOnDelete = (value: string) => () => {
    const newValuesList = fieldsValue[id].filter((item: string) => item !== value);
    dispatch(setFieldsValue({ [id]: newValuesList }));
    if (onDelete) {
      onDelete(value);
    }
  };

  const onMouseEnter = (index: number) => () => {
    setShowClipboardIndex(index);
  };

  const onMouseLeave = () => {
    setShowClipboardIndex(null);
  };

  return (
    <div className={classNames('chips-list', { [`${className}`]: !!className })}>
      {!!readonly ? (
        <div>
          {!!title && !!valuesList.length && (
            <Text textSize={13} weight={500} style={{ marginBottom: 10 }}>
              {title}
            </Text>
          )}
          <div className="chips-list-readonly">
            {!!valuesList.length &&
              valuesList.map((item: string, index: number) => (
                <div className="chip-item-row" onMouseEnter={onMouseEnter(index)} onMouseLeave={onMouseLeave}>
                  <div key={`${item}-${index}`} className="chip-item">
                    <Text textColor="#5D6576" family="Rubik" textSize={12} style={{ whiteSpace: 'nowrap' }}>
                      {item}
                    </Text>
                  </div>
                  {showClipboardIndex === index && is_clipboard && (
                    <div className="clipboard" onClick={onClipboard(item)}>
                      {iconClipboard}
                    </div>
                  )}
                </div>
              ))}
            {/* {is_clipboard && <div className="clipboard">{iconClipboard}</div>} */}
          </div>
        </div>
      ) : (
        <div className="chips-list-container">
          {!!title && (
            <Text textSize={13} weight={500}>
              {title}
            </Text>
          )}
          <div className="input-field">
            <TextField
              fullWidth
              // label={title}
              className={classNames('input', { 'has-value': !!inputValue })}
              value={!!inputValue ? inputValue : ''}
              onChange={e => setInputValue(e.target.value)}
              type={'text'}
              onFocus={(ev: any) => ev.target.select()}
              sx={{
                '& .Mui-error': { color: 'red' },
                '& .MuiFormHelperText-root': { fontSize: 12 },
                '& .MuiInputBase-input': {
                  padding: '8px 12px',
                  marginRight: is_clipboard && description ? 12 : is_clipboard || description ? 9 : 5
                }
              }}
              autoComplete="off"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off'
                }
              }}
              // error={isError}
              // helperText={errorText}
            />
            {description && (
              <>
                <span data-tip={description} data-for={`INTEGRATION_CHIP_LIST_DESCRIPTION_${id}`}>
                  <div
                    className={classNames({ 'icon-help-only': !is_clipboard }, { 'icon-help-both': !!is_clipboard })}
                  >
                    {iconInfo}
                  </div>
                </span>
                <ToolTip
                  id={`INTEGRATION_CHIP_LIST_DESCRIPTION_${id}`}
                  place="bottom"
                  theme="dark"
                  offset={{ left: 20, bottom: 10 }}
                />
              </>
            )}
            {is_clipboard && (
              <div className="icon-clipboard" onClick={onClipboard(inputValue)}>
                {iconClipboard}
              </div>
            )}
            <div className="add-item">
              <Button
                variant="text"
                onClick={addItem}
                disableRipple
                // classes={{ root: classes.root }}
                sx={{ fontSize: 12, fontWeight: 500, textTransform: 'none' }}
                data-testid="input-chips-list-button"
                disabled={!inputValue || (!!inputValue && valuesList.includes(inputValue))}
              >
                + Add
              </Button>
            </div>
            <div className="input-list">
              {!!valuesList.length &&
                valuesList.map((item: string, index: number) => (
                  <div key={`${item}-${index}`} className="chip-item">
                    <Text textColor="#5D6576" family="Rubik" textSize={12} style={{ whiteSpace: 'nowrap' }}>
                      {item}
                    </Text>
                    <div className="chip-item-close-trigger" onClick={handleOnDelete(item)}>
                      {iconDelete}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChipsList;
