import React, { useState, useEffect, useRef, memo } from 'react';
import Grid from '@mui/material/Grid';
import DropDownBox from 'pages/Settings/Notifications/SidePanel/DropDownBox/DropDownBox';
import BlockDivider from 'pages/Settings/Notifications/SidePanel/BlockDivider/BlockDevider';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import {
  useNotifications,
  NotificationsContextType,
  DROPDOWNBOX_TYPE,
  NOTIFICATIONS_TYPE
} from 'pages/Settings/Notifications/NotificationsContext';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import FiltersMenu from 'pages/Settings/Notifications/SidePanel/FiltersMenu/FiltersMenu';
import { Text } from '@cyberpion/cyberpion-ui';

const FilterItem = ({ filter }: { filter: any }) => {
  const { onDeleteFIlter } = useNotificationConfiguration() as NotificationConfigurationContextType;

  const handleFilterDelete = (id: string) => () => {
    onDeleteFIlter([id]);
  };

  return (
    <Grid container alignItems="center" className="filter-item" sx={{ marginBottom: 2, flexWrap: 'nowrap' }}>
      <Grid item flexGrow={1} sx={{ paddingRight: 2 }}>
        <DropDownBox id={filter.id} label={filter.label} type={filter.dropdownbox_type} isClear />
      </Grid>
      <Grid item className="trash" alignSelf="flex-start" sx={{ marginTop: 1.25 }}>
        <img
          alt="delete filter"
          src="/assets/images/trash.svg"
          onClick={handleFilterDelete(filter.id)}
          data-testid={`notification-dropdowbox-trash-button`}
        />
      </Grid>
    </Grid>
  );
};

const NotificationConfigurationsContent = () => {
  const [toggledFilters, setToggledFilters] = useState<any>(null);
  const [origNotificationName, setOrigNotificationName] = useState<string | null>(null);
  const { notificationType, actionMode } = useNotifications() as NotificationsContextType;
  const [notificationName, setNotificationName] = useState('');
  const [channelDropdown, setChannelDropdown] = useState<any>([]);
  const {
    notificationData,
    changedNotificationData,
    onConfigurationChange,
    clickedSubmit,
    setClickedSubmit
  } = useNotificationConfiguration() as NotificationConfigurationContextType;
  const refName = useRef(null);

  const handleNotificationNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationName(event.target.value);
    setClickedSubmit(false);
    onConfigurationChange('name', event.target.value);
  };

  useEffect(() => {
    if (!!notificationData) {
      if (!!Object.keys(notificationData.filters).length) {
        const toggled = Object.values(notificationData.filters).filter((filter: any) => filter.is_toggled);
        setToggledFilters(toggled);
      } else {
        setToggledFilters(null);
      }
      if (notificationData.name !== '') {
        setNotificationName(notificationData.name);
      }
    }
  }, [notificationData]);

  useEffect(() => {
    if (!!changedNotificationData) {
      setNotificationName(changedNotificationData?.name || '');
      if (!origNotificationName) {
        setOrigNotificationName(changedNotificationData?.name || '');
      }
    }
  }, [changedNotificationData, origNotificationName]);

  useEffect(() => {
    if (notificationType === 'action_items' && !!Object.keys(changedNotificationData).length) {
      setChannelDropdown([]);
      if (changedNotificationData.channel_type?.channel_type === 0 || changedNotificationData.channel_type === 0) {
        //EMAIL
        const comp = (
          <Grid item>
            <DropDownBox id="recipients" label="Recipients" type={DROPDOWNBOX_TYPE.INPUT_CHIPS} isClear isError />
          </Grid>
        );
        setChannelDropdown(comp);
        return;
      }
      if (
        changedNotificationData.channel_type?.channel_type === 3 ||
        changedNotificationData.channel_type?.channel_type === 4 ||
        changedNotificationData.channel_type === 3 ||
        changedNotificationData.channel_type === 4
      ) {
        // JIRA OR SERVICENOW
        const comp = (
          <Grid item>
            <DropDownBox id="configuration_id" label="Integrations" type={DROPDOWNBOX_TYPE.RADIO} isError />
          </Grid>
        );
        setChannelDropdown(comp);
        return;
      }
    }
  }, [notificationType, changedNotificationData]);

  return (
    <Grid container className="notification-properties" direction="column" spacing={2}>
      <Grid item sx={{ paddingBottom: 1 }}>
        <BlockDivider headerText="General (Required)" />
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Grid container direction="column">
          <Grid item>
            <FormControl sx={{ width: '100%' }}>
              <OutlinedInput
                id="name"
                ref={refName}
                className="notification-name"
                placeholder="Notification Name"
                value={notificationName}
                onChange={handleNotificationNameOnChange}
                error={clickedSubmit && notificationName === ''}
                inputProps={{ maxLength: 100 }}
                disabled={notificationType === NOTIFICATIONS_TYPE.THREAT_CENTER && actionMode === 'edit'}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <DropDownBox id="notification_type" label="Event Type" type={DROPDOWNBOX_TYPE.RADIO} isError />
      </Grid>
      {notificationType === 'action_items' && changedNotificationData.notification_type === 1 && (
        <>
          <Grid item>
            <DropDownBox id="channel_type" label="Channel" type={DROPDOWNBOX_TYPE.RADIO} isError />
          </Grid>
          {channelDropdown}
        </>
      )}
      {(notificationType !== 'action_items' ||
        (notificationType === 'action_items' && changedNotificationData.notification_type !== 1)) && (
        <Grid item>
          <DropDownBox id="recipients" label="Recipients" type={DROPDOWNBOX_TYPE.INPUT_CHIPS} isClear isError />
        </Grid>
      )}
      <Grid item sx={{ marginBottom: 1, marginTop: 1 }}>
        <BlockDivider headerText="Scope" />
      </Grid>
      {!!notificationData && !Object.keys(notificationData.filters).length && (
        <Text textSize={13} textColor="#8C909A" style={{ paddingLeft: 30, paddingTop: 3 }}>
          No Filters
        </Text>
      )}
      {!!toggledFilters && !!toggledFilters.length && (
        <Grid item>
          <Grid container direction="column" className="notification-filter-wrapper">
            <Grid item>
              {toggledFilters.map((filter: any) => (
                <FilterItem key={filter.id} filter={filter} />
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      {!!notificationData && !!Object.keys(notificationData.filters).length && (
        <Grid item className="filter-menu-wrapper">
          <FiltersMenu />
        </Grid>
      )}
    </Grid>
  );
};

export default memo(NotificationConfigurationsContent);
