import { useEffect, useState } from 'react';
import './Help.scss';

declare const window: any;
const ZD_WIDGET_ID = 'webWidget';

export function Help() {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.addEventListener(
      'DOMNodeInserted',
      event => {
        const target = event.target as HTMLIFrameElement;
        if (target) {
          if (target.id === ZD_WIDGET_ID) {
            document.getElementById(ZD_WIDGET_ID)?.classList.add('show');
          }
        }
      },
      false
    );
  }, []);
  return (
    <div
      className="Help"
      id="ZD_HELP"
      onClick={() => {
        if (open) {
          document.getElementById(ZD_WIDGET_ID)?.classList.remove('show');
          setOpen(false);
        } else {
          window.zE.activate();
          document.getElementById(ZD_WIDGET_ID)?.classList.add('show');
          setOpen(true);
        }
      }}
    >
      ?
    </div>
  );
}
