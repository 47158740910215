import classNames from 'classnames';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './SubMenu.scss';

export function SubMenu(props: any) {
  const [open, setOpen] = useState(true);

  const shouldDisplayTitle = (menuItem: any) => {
    const hasViewableItems: boolean = Object.keys(menuItem).some((item: any) => {
      return props.canView(menuItem[item]);
    });
    return hasViewableItems;
  };

  const isActive = (path: string) => {
    return window.location.href.includes(path);
  };

  return (
    <li key={props.text} className={classNames('SubMenu', { open: open })}>
      {shouldDisplayTitle(props.items) && (
        <div className="sub-menu-header" onClick={() => setOpen(!open)}>
          <span>
            {props.text} ({Object.keys(props.items).length})
          </span>
          <IconArrow toggleRotate={!open} color="#ffffff" />
        </div>
      )}
      {shouldDisplayTitle(props.items) && (
        <ul>
          {Object.keys(props.items).map((sub_item: string, i) => {
            if (!props.canView(props.items[sub_item])) {
              return <li key={i}></li>;
            }
            return (
              <li key={i}>
                <Link
                  className={classNames({
                    disabled: !props.items[sub_item].path?.length,
                    active: isActive(props.items[sub_item].path)
                  })}
                  to={props.items[sub_item].path}
                >
                  {sub_item}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
}
