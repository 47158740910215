import { useRef, useState } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import { Button } from '../Button/Button';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { AppLoading } from '../appLoading/appLoading';
import { LoginService } from 'login/services/login.service';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { USER_ROLES_SWAPPED } from 'common/constants/roles';
import { showError, showSuccess } from 'features/AlertSlice';

import './EditableColumn.scss';

const MAX_INPUT_SIZE: any = {
  sso_id: 28,
  first_name: 16,
  last_name: 16,
  mobile: 20
};

export function EditableColumn(props: any) {
  const [value, setValue] = useState<string>(props.params.value);
  const [previousValue, setPreviousValue] = useState<string>(props.params.value);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useSelector((state: IReduxState) => state.login);
  const dispatch = useDispatch<any>();
  const ref = useRef<HTMLInputElement>(null);

  const onChange = (value: string) => {
    setValue(value);
  };

  const onStartEdit = () => {
    if (ref.current) {
      ref.current.select();
      ref.current.focus();
    }
    setEditMode(true);
  };

  const onSubmit = () => {
    if ((props.params.field === 'first_name' || props.params.field === 'last_name') && !value.length) {
      setEditMode(false);
      setValue(previousValue);
      return;
    } else {
      setLoading(true);
      const payload = {
        ...(!!props.params.field ? { [props.params.field]: !!value.length ? value : null } : {})
      };
      RestApi.setData(`${Paths[PathNames.userManagement]}${props.params.row.id}/`, payload, 'PATCH').subscribe(
        () => {
          setPreviousValue(value);
          setLoading(false);
          setEditMode(false);
          dispatch(showSuccess('User updated successfully'));
        },
        error => {
          setLoading(false);
          setValue(previousValue);
          dispatch(showError(error));
        }
      );
    }
  };

  const onCancel = () => {
    setEditMode(false);
    setValue(previousValue);
  };

  if (loading) {
    return (
      <div className="EditableColumn loading">
        <AppLoading />
      </div>
    );
  }

  if (!(LoginService.isAdminOrAbove(user) && user.role < USER_ROLES_SWAPPED[props.params.row.role])) {
    return <div className="EditableColumn">{value}</div>;
  }

  return (
    <div className="EditableColumn">
      <div onClick={() => setEditMode(true)}>
        <input
          className={editMode ? 'edit-mode' : ''}
          ref={ref}
          value={value || ''}
          size={MAX_INPUT_SIZE[props.params.field]}
          onChange={e => onChange(e.target.value)}
          maxLength={128}
        />
      </div>
      {editMode ? (
        <div className="buttons-wrapper">
          <Button type="button" icon="check-gray" size="tiny" text="" buttonStyle="secondary" onClick={onSubmit} />
          <Button type="button" icon="x" size="tiny" text="" buttonStyle="secondary" onClick={onCancel} />
        </div>
      ) : (
        <img src="/assets/images/edit-dark.svg" onClick={onStartEdit} alt="Edit" />
      )}
    </div>
  );
}
