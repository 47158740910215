import './ScreenshotRenderer.scss';

export function ScreenshotRenderer(props: any) {
  const handleOnClick = () => {
    const win = window.open(props.url, '_blank');
    if (!!win) {
      win.focus();
    }
  };

  return (
    <div onClick={handleOnClick} className="ScreenshotRenderer grid-link-field">
      {props.text}
    </div>
  );
}
