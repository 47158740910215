import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { Button } from '@mui/material';
import './dropdownHeader.scss';
import { IFilters } from 'common/components/grid/components/gridUtilities/interface';
import {
  FILTER_DATE,
  FILTER_STRING,
  FILTER_NUMBER,
  FILTER_ARRAY_STRING,
  FILTER_IP,
  FILTER_ARRAY_NUMBER
} from 'common/components/grid/components/gridUtilities/filtersWrapper/config';
import { Common } from 'common/services/common.service';
import { AppDispatch } from 'app/store';
import { useDispatch } from 'react-redux';
import { getRows, removeFilter } from 'features/GridSlice';
import { selectView, setIsEdited } from 'features/ViewsSlice';

interface IDropdownHeader {
  item: IFilters;
  isToggled: boolean;
  onToggle: () => void;
  // onUpdateFilterGrid: (fields: string[], item: IFilters | boolean) => void;
  onChangeData: (params: IFilters) => void;
}

export function DropdownHeader(props: IDropdownHeader) {
  /**
   *
   * @param choices
   * @description Checks whether columns should be added after filter cleaning
   * @returns array fields
   */

  const dispatch: AppDispatch = useDispatch();

  // function onAddColumns(choices: IChoices[]) {
  //   let fields: any = {};
  //   // queryGrid.fields.split(",").forEach((field: string) => {
  //   //   fields[field] = field;
  //   // });

  // function onAddColumns(choices: IChoices[]) {
  //   let fields: any = {};
  //   // queryGrid.fields.split(",").forEach((field: string) => {
  //   //   fields[field] = field;
  //   // });

  //   choices.forEach((choice: IChoices) => {
  //     // #TODO:
  //     // Added '?' - this fix a bug where add_fields is undefined after clearing a filter
  //     // there is also an option to remove the call to onAddColumns from onClearSelection
  //     // but i'm not sure about it right now
  //     choice.add_fields?.forEach((field: string) => {
  //       fields[field] = field;
  //     });
  //   });

  //@description: Clears the selections and deletes the drop-down menu (just in case it is not the default). And updates the filter data
  function onClearSelection() {
    const option: IFilters = { ...props.item };
    const isFilterActive = option.is_active_filter;
    option.is_toggled = !option.is_toggled;
    option.clear_selection = true;
    option.is_active_filter = false;
    option.toggle_dropdown = false;
    option.sub_label = [];
    clearSelectionByTypeFilter(option);
    dispatch(removeFilter(option.name));
    dispatch(setIsEdited(true));
    dispatch(selectView(null));
    props.onChangeData(option);
    if (isFilterActive) {
      dispatch(getRows());
    }
  }

  //@description: Clearing the "choices" field by filter type
  function clearSelectionByTypeFilter(option: IFilters) {
    switch (option.filter_type) {
      case 'multiple_choice':
        option.choices.forEach((element: any) => {
          element.is_toggled = false;
        });
        break;
      case 'date':
        option.choices = Common.cloneData(FILTER_DATE);
        break;
      case 'stringAndNumber':
        if (option.type === 'ip') {
          option.choices = Common.cloneData(FILTER_IP);
        } else if (option.type === 'string' || option.type === 'spinner' || option.type === 'string_with_highlight')
          option.choices = Common.cloneData(FILTER_STRING);
        else if (option.type === 'array_string' || option.type === 'rbac_groups' || option.type === 'count_info_panel')
          option.choices = Common.cloneData(FILTER_ARRAY_STRING);
        else if (option.type === 'array_number') option.choices = Common.cloneData(FILTER_ARRAY_NUMBER);
        else option.choices = Common.cloneData(FILTER_NUMBER);
        break;
      case 'radio':
        option.choices.forEach((element: any) => {
          element.is_toggled = false;
        });
        break;
    }
  }

  //@description: Opens or closes the drop-down
  function onToggleDropdown() {
    // const item = { ...props.item };
    // item.toggle_dropdown = !item.toggle_dropdown;
    props.onToggle();
  }

  //@description: Add a design by value
  function addClassNameByType(value: string | string[] | undefined) {
    if (Array.isArray(value)) {
      if (value.length !== 0) {
        return displaySubLable(value);
      }
    } else {
      return <div className="flex-wrapper align-center">{value}</div>;
    }
  }

  //@description:Displays an array of labels in the title
  function displaySubLable(value: string[]) {
    if (value.length === 1) {
      return (
        <div className="flex-wrapper align-center">
          :<span className="dropdownHeader_one-value">{value[0]}</span>
        </div>
      );
    } else {
      return (
        <div className="flex-wrapper align-center">
          :<span className="dropdownHeader_one-value">{value[0]}</span>
          <span className="dropdownHeader_sub-lable">+{value.length - 1}</span>
        </div>
      );
    }
  }

  function addClassNameByStatus() {
    return `${props.isToggled && ' dropdownHeader_toggle-dropdown'}
      ${props.item.is_active_filter && ' dropdownHeader_active-filter'}`;
  }

  function addClassNameToFilterClean() {
    return `${props.item.toggle_dropdown && 'dropdownHeader_toggle-dropdown-and-filter-clean'}`;
  }

  return (
    <Button>
      <div className={`dropdownHeader_scope ${addClassNameByStatus()}`}>
        <div className="dropdownHeader_dropdown-label" onClick={onToggleDropdown}>
          <div className="dropdownHeader_label" data-testid={props.item.label}>
            {props.item.label}
          </div>
          {addClassNameByType(props.item.sub_label)}
          <IconArrow color={props.isToggled && '#ffffff'} active={props.isToggled} toggleRotate={props.isToggled} />
        </div>
        {(!props.item.is_default_filter || (props.item.is_default_filter && props.item.is_active_filter)) && (
          <div className={`dropdownHeader_filter-clean ${addClassNameToFilterClean()}`} onClick={onClearSelection}>
            +
          </div>
        )}
      </div>
    </Button>
  );
}
