import { useEffect, useRef, useState } from 'react';
import { Button, Text } from '@cyberpion/cyberpion-ui';
import { SCAN_STATUS, ScanStatus } from 'common/components/ScanStatus/ScanStatus';
import { Common } from 'common/services/common.service';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PopSideFooter = ({
  data,
  onHide,
  setRequiredHeight
}: {
  data: any;
  onHide: (bln: boolean) => void;
  setRequiredHeight: (height: number) => void;
}) => {
  const [params, setParams] = useState({
    id: data.id,
    fromPopside: true,
    row: {
      last_rescan_time: data.last_rescan_time,
      last_rescan_by: data.last_rescan_by,
      rescan_status: data.rescan_status,
      last_closed_at: data.last_closed_at
    }
  });
  const flags = useFlags();
  const requiredRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!requiredRef.current && !!requiredRef.current.clientHeight) {
      setRequiredHeight(requiredRef.current.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredRef]);

  const handleGoToAsset = () => {
    window.open(Common.AddMsspAccountIfNeeded(`/pages/singleAsset/internalAssets/${data.asset}/actionItems`), '_blank');
  };

  const handleScanOnClick = () => {
    setParams({ ...params, row: { ...params.row, rescan_status: SCAN_STATUS.IN_PROGRESS } });
  };

  const isSingleAsset = window.location.pathname.includes('/singleAsset');
  return (
    <div className="ActionItemsPopSideFooter">
      <div ref={requiredRef}>
        {data.required_action && (
          <div className="required-action">
            <Text textColor="#5D6576" textSize={13} style={{ paddingBottom: 6 }}>
              Required action
            </Text>
            <Text textSize={16} weight={500} textColor="#000" style={{ lineHeight: 1.25 }}>
              {data.required_action}
            </Text>
          </div>
        )}
      </div>
      <div className="actions-buttons">
        {flags.isActionItemRescan && (
          <div onClick={handleScanOnClick}>
            <ScanStatus params={params} />
          </div>
        )}
        <div style={{ flexGrow: 1 }} />
        <Button
          buttonStyle="secondary"
          // disabled={isPrevDisabled}
          type="button"
          size="small"
          onClick={() => onHide(false)}
          text="Close"
        />
        {!isSingleAsset && (
          <Button
            buttonStyle="main"
            // disabled={false}
            type="button"
            size="medium"
            onClick={handleGoToAsset}
            text="Go to Asset"
            className="btn-next"
          />
        )}
      </div>
    </div>
  );
};

export default PopSideFooter;
