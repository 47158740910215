import React, { useEffect } from 'react';
import { Input } from 'common/components/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { getRows, inputSearch } from 'features/GridSlice';
import { selectView, setIsEdited } from 'features/ViewsSlice';

import { AppDispatch } from 'app/store';

export function GlobalSearch() {
  const { search } = useSelector((state: any) => state.grid);
  const [searchText, setSearchText] = React.useState<string>(search || '');
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (search) {
      setSearchText(search);
    } else {
      setSearchText('');
    }
  }, [search]);

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      dispatch(inputSearch(searchText.trim()));
      dispatch(getRows());
      dispatch(setIsEdited(true));
      dispatch(selectView(null));
    }
  }

  function cleanText() {
    setSearchText('');
    dispatch(inputSearch(''));
    dispatch(getRows());
  }

  function handleChange(value: string) {
    setSearchText(value);
    if (value === '') {
      cleanText();
    }
  }

  return (
    <Input
      icon={'search'}
      type={'text'}
      value={searchText}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onCleanText={cleanText}
    />
  );
}
