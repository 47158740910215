import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';
import { LinkRenderer } from '../LinkRenderer/LinkRenderer';
import './arrayRenderer.scss';

interface IArrayRenderer {
  data: any;
  flexWrap: Type;
  field?: string;
  source?: any;
  identifier?: any;
}

type Type = 'wrap' | 'nowrap';

const ArrayRenderer: React.FC<IArrayRenderer> = ({ data, flexWrap, field, source, identifier }) => {
  //@param: index = index of array
  //@return: element circle
  const addCircle = (index: number) => {
    if (data.length - 1 !== index) {
      return <div className="arrayRenderer-circle"></div>;
    }
  };

  return (
    <ul className="arrayRenderer-scope" style={{ flexWrap: flexWrap }}>
      {data &&
        Array.isArray(data) &&
        data.map((item: any, index: number) => {
          const renderedItem =
            field && ['connected_assets', 'hosts', 'related_assets'].includes(field) ? (
              <LinkRenderer params={{ value: item, field: field }} source={source} identifier={identifier} />
            ) : field === 'cves' ? (
              <LinkNewTab to={`https://nvd.nist.gov/vuln/detail/${item}`} text={item} className="grid-link-field" />
            ) : (
              item
            );
          return (
            <li key={index}>
              <div className="arrayRenderer-text">
                {typeof item === 'string' || field === 'cves'
                  ? renderedItem
                  : typeof item === 'number'
                  ? item
                  : typeof item === 'object' && Object.values(item)[0]}
              </div>
              {addCircle(index)}
            </li>
          );
        })}
    </ul>
  );
};

export { ArrayRenderer };
