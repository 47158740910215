import React from 'react';
import { IIcon } from 'common/interfaces/interface';
export class IconOrgScore extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#5D6576';
    return (
      <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="8.5" stroke={color} />
        <circle cx="9" cy="9" r="1.5" stroke={color} />
        <path
          d="M13.5 13.5C14.39 12.61 14.9961 11.476 15.2417 10.2415C15.4872 9.00704 15.3612 7.72746 14.8795 6.5646C14.3979 5.40173 13.5822 4.40782 12.5356 3.70853C11.4891 3.00925 10.2587 2.63601 9 2.63601C7.74133 2.63601 6.51092 3.00925 5.46437 3.70853C4.41782 4.40782 3.60213 5.40173 3.12046 6.5646C2.63879 7.72746 2.51276 9.00704 2.75831 10.2415C3.00387 11.476 3.60998 12.61 4.49999 13.5"
          stroke="#5D6576"
        />
        <path d="M13.5 12.8L15.3536 14.6464" stroke={color} />
        <path d="M4.85357 13.2L2.99998 14.75" stroke={color} />
        <path d="M15.5 9H17.4749" stroke={color} />
        <path d="M1 9H2.9749" stroke={color} />
        <path d="M13.5 4.5L15 3" stroke={color} />
        <path d="M10.5 8L13 6" stroke={color} strokeLinecap="round" />
        <path d="M4.5 4.5L3 3" stroke={color} />
        <path d="M9 2.5L9 0.5" stroke={color} />
      </svg>
    );
  }
}
