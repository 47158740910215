import { IconConnectionType } from 'common/components/Icons/IconConnectionType/IconConnectionType';
import { RiskScoreByCategory } from 'common/components/quickViewPanel/components/riskScoreByCategory/RiskScoreByCategory';
import { TopActionItems } from 'common/components/quickViewPanel/components/topActionItems/TopActionItems';
import './quickViewPanel.scss';
import { GeneralScore } from './components/generalScore/GeneralScore';
import { Connections } from './components/connections/Connections';
import { LinkNewTab } from '../LinkNewTab/LinkNewTab';

interface IQuickViewPanel {
  data: any;
  nameTab: string;
  setVisible: (params: boolean) => void;
  visible: boolean;
  identifier: string;
}

export function QuickViewPanel(props: IQuickViewPanel) {
  function onClose() {
    props.setVisible(false);
  }

  return (
    (props.visible && (
      <div className="quickViewPanel">
        <div>
          <div className="quickViewPanel-head">
            <div className="quickViewPanel-header-scope">
              <IconConnectionType />
              <span className="quickViewPanel-header-text">{props.data.asset}</span>
            </div>
            <div className="qvp-view-single-asset-scope">
              <div className="qvp-view-single-asset-link-scope">
                <LinkNewTab
                  to={`/pages/singleAsset/${props.nameTab}/${props.data[props.identifier]}/${
                    props.nameTab === 'internalAssets' ? 'actionItems' : 'technologies'
                  }`}
                  text={'View single asset'}
                  className="qvp-view-single-asset-link"
                />
              </div>
              <div className="quickViewPanel-close-popup" onClick={onClose}>
                +
              </div>
            </div>
          </div>
          <div className="quickViewPanel-body">
            <div className="quickViewPanel-header-general-score">
              {props.data.risk_score && (
                <GeneralScore
                  label={props.data.risk_score.label}
                  urgency={props.data.risk_score.type}
                  risk_score={props.data.risk_score.risk_score}
                />
              )}
              <RiskScoreByCategory {...props.data.risk_score_by_category} />
            </div>
            <Connections data={props.data} />
            <TopActionItems data={props.data.action_items} />
          </div>
        </div>

        <div className="quickViewPanel-foot">
          {/* <div className="quickViewPanel-d">
            <Image
              className="quickViewPanel-connection-map"
              icon="connection-map"
              type="png"
              alt="connection-map"
            />
          </div> */}
        </div>
      </div>
    )) || <span></span>
  );
}
