import React from 'react';
import { Input } from 'common/components/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { inputSearch } from 'features/GroupsSlice';
import { AppDispatch } from 'app/store';
import { IGroupSearchProps } from 'common/interfaces/interface';
import './groupSearch.scss';

const GroupSearch: React.FC<IGroupSearchProps> = ({ autoFocus }) => {
  const { search } = useSelector((state: any) => state.groups);
  const [searchText, setSearchText] = React.useState<string>(search);
  const dispatch: AppDispatch = useDispatch();

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      dispatch(inputSearch(searchText));
    }
  }

  function cleanText() {
    setSearchText('');
    dispatch(inputSearch(''));
  }

  function handleChange(value: string) {
    setSearchText(value);
    if (value === '') {
      cleanText();
    }
  }

  return (
    <div className="group-search-wrapper">
      <Input
        autoFocus={autoFocus}
        className="group-search-input"
        icon={'search'}
        type={'text'}
        value={searchText}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onCleanText={cleanText}
        placeholder="Search"
      />
    </div>
  );
};

export default GroupSearch;
