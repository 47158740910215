import { IReduxState } from 'app/store';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { PopUp } from 'common/components/popUp/PopUp';
import { setDeletePromptParams } from 'features/GridSlice';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddAssetsPopUp } from './AddAssetsPopUp/AddAssetsPopUp';
import './ManageAssetsActions.scss';
import { LoginService } from 'login/services/login.service';

export function ManageAssetsActions(props: any) {
  const dispatch = useDispatch<any>();
  const ref = useRef<HTMLDivElement>(null);
  const [actionsMenuOpen, setActionsMenuOpen] = useState<boolean>(false);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const { user } = useSelector((state: IReduxState) => state.login);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setActionsMenuOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onRemove = () => {
    dispatch(
      setDeletePromptParams({
        category: 'organization-management',
        tab: 'manage-fqdns',
        index: null,
        ids: props.ids,
        message: 'Are you sure you would like to remove the following assets and their subdomains?'
      })
    );
  };

  if (LoginService.isMemberOrBelow(user)) {
    return <div></div>;
  }

  return (
    <div className="ManageAssetsActions" ref={ref}>
      <button className={`btn btn-main`} onClick={() => setPopupVisible(true)}>
        Add Additional Domains
      </button>
      <span className={`actions-trigger ${actionsMenuOpen ? 'open' : ''}`}>
        <div className="trigger-wrapper" onClick={() => setActionsMenuOpen(!actionsMenuOpen)}>
          Actions
          <IconArrow />
        </div>
        <ul>
          <li className={!props.ids.length ? 'disabled' : ''} onClick={onRemove}>
            Remove
          </li>
        </ul>
      </span>
      <PopUp visible={popupVisible} setVisible={() => setPopupVisible(false)}>
        <AddAssetsPopUp onClose={() => setPopupVisible(false)} type="domain" />
      </PopUp>
    </div>
  );
}
