import React, { useState, useEffect, memo } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { Grid } from '@mui/material';
import { Text } from '@cyberpion/cyberpion-ui';

import './dates.scss';

interface IDates {
  id: string;
}

const Dates: React.FC<IDates> = ({ id }) => {
  const {
    notificationData,
    onConfigurationChange,
    onClear
  } = useNotificationConfiguration() as NotificationConfigurationContextType;
  const [isError, setIsError] = useState(false);

  const options =
    !!notificationData &&
    !!notificationData.filters &&
    !!notificationData.filters[id] &&
    notificationData.filters[id].choices
      ? notificationData.filters[id].choices
      : { from: null, to: null };

  const [fromDate, setFromDate] = useState<Date | null>(options.from);
  const [toDate, setToDate] = useState<Date | null>(options.to);

  useEffect(() => {
    if (!!fromDate && !!toDate) {
      const from = new Date(fromDate).toISOString().split('T')[0];
      const to = new Date(toDate).toISOString().split('T')[0];
      if (new Date(from) <= new Date(to)) {
        setIsError(false);
        onConfigurationChange(id, { from: new Date(fromDate).toISOString(), to: new Date(toDate).toISOString() });
      } else {
        setIsError(true);
        onClear([id]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  return (
    <Grid container className="dropdownbox-date-picker">
      <Grid item className="from">
        <Text textSize={12}>From</Text>
      </Grid>
      <Grid item className="from-date" flexGrow={1}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            value={fromDate}
            onChange={newValue => {
              setFromDate(newValue);
            }}
            renderInput={params => (
              <TextField {...params} error={isError} inputProps={{ 'data-testid': `dropdownbox-date-from-${id}` }} />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item className="to">
        <Text textSize={12}>To</Text>
      </Grid>
      <Grid item className="to-date" flexGrow={1}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            value={toDate}
            onChange={newValue => {
              setToDate(newValue);
            }}
            renderInput={params => (
              <TextField {...params} error={isError} inputProps={{ 'data-testid': `dropdownbox-to-from-${id}` }} />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default memo(Dates);
