import Grid from '@mui/material/Grid';
import { Text } from '@cyberpion/cyberpion-ui';
import { NOTIFICATIONS_TYPE } from 'pages/Settings/Notifications/NotificationsContext';
import NotificationTypeBox from 'pages/Settings/Notifications/SidePanel/NotificationTypeBox/NotificationTypeBox';

const AddNotificationTypePanel = () => {
  return (
    <Grid container direction="column" sx={{ padding: 2 }}>
      <Grid item sx={{ paddingBottom: 2 }}>
        <Text textSize={15} weight={500}>
          Add Notification
        </Text>
      </Grid>
      <Grid item sx={{ padding: '0 20px 25px 0' }}>
        <Text textSize={13} textColor="#8C909A">
          In order to add a notification please select the notification type.
        </Text>
      </Grid>
      {Object.values(NOTIFICATIONS_TYPE).map(type => {
        if (type !== 'threat_center') {
          return (
            <Grid item key={type}>
              <NotificationTypeBox type={type} />
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
};
export default AddNotificationTypePanel;
