import { IReduxState } from 'app/store';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { IResponsePaginatedData } from 'common/interfaces/interface';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DownloadReportRow } from '../DownloadReportRow/DownloadReportRow';
import { REPORT_TYPES } from '../DownloadReports';
import { setDelay, showCustomSuccess, showError } from 'features/AlertSlice';
import { Button, Text } from '@cyberpion/cyberpion-ui';

import './ReportsList.scss';

interface IReportFile {
  size: number;
  name: string;
  date: string;
}
export function ReportsList(props: any) {
  const [list, setList] = useState<IReportFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [loadingText, setLoadingText] = useState<string>('');
  const [next, setNext] = useState<string | null>();
  const { user } = useSelector((state: IReduxState) => state.login);
  const { globalFilter } = useSelector((state: IReduxState) => state.general);

  const dispatch = useDispatch<any>();

  let reachedEnd = false;

  useEffect(() => {
    if (props.open && props.type) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, props.open]);

  const getList = () => {
    setLoading(true);
    RestApi.getData(`reports/${props.type}/`).subscribe((response: any) => {
      setList(response.results);
      setLoading(false);
      setType(props.type);
      setNext(response.next);
    });
  };

  const generate = () => {
    setLoading(true);
    setLoadingText('Creating a report, this might take a few minutes...');

    const group = !!globalFilter ? `?group=${globalFilter}` : '';
    const url = props.type === REPORT_TYPES.EXECUTIVE ? 'reports/executive/' : 'reports/threat-exposure/';
    const reportPath =
      props.type === REPORT_TYPES.EXECUTIVE
        ? Common.AddMsspAccountIfNeeded(
            `${window.location.origin}/settings/generate-executive-report?company=${user.company_name}&reportType=poc&logo=show&panel=hide`
          )
        : Common.AddMsspAccountIfNeeded(`${window.location.origin}/generate-threats-report/${group}`);
    RestApi.setData(
      url,
      {
        report: reportPath
      },
      'POST'
    ).subscribe(
      (response: any) => {
        setLoadingText('');
        setLoading(false);
        dispatch(setDelay(8000));
        dispatch(
          showCustomSuccess({
            title: 'Report generated successfully',
            text:
              'The report will be available for download shortly. Please refresh the page to view the download link.'
          })
        );
        getList();
      },
      error => {
        setLoadingText('');
        setLoading(false);
        dispatch(showError('There was a problem generating the report, please try again later.'));
      }
    );
  };

  const shouldShowGenerateButton = () => {
    return props.type === REPORT_TYPES.EXECUTIVE || props.type === REPORT_TYPES.EXPOSURE;
  };

  const onScrollList = (event: any) => {
    if (!next) {
      return;
    }
    if (event.target.scrollTop + event.target.clientHeight > event.target.scrollHeight - 20 && !reachedEnd) {
      RestApi.getData(Common.splitV1FromPath(next)).subscribe((response: IResponsePaginatedData) => {
        setList([...list, ...response.results]);
        setNext(response.next);
        reachedEnd = false;
      });
      reachedEnd = true;
    }
  };

  let title = '';
  if (type === 'threat-exposure') {
    title = 'Exposure Reports';
  } else if (type === 'asm') {
    title = 'ASM Reports';
  } else {
    title = 'Executive Reports';
  }

  return (
    <div className="ReportsList">
      {loading && <AppLoading text={loadingText} />}
      <div className="report-list-top">
        <Text textSize={13} style={{ paddingLeft: 16 }} weight={500}>
          {title}
        </Text>
        {shouldShowGenerateButton() && (
          <Button text="Generate Report" onClick={generate} type="button" buttonStyle="main" size="small" />
        )}
      </div>
      {!list.length && (
        <div className="empty">
          <img src="/assets/images/download.svg" alt="Download" />
          <div>No reports to download</div>
        </div>
      )}
      {!!list.length && (
        <div className="scrollbar-common">
          <div className="list-wrapper" onScroll={(e: any) => onScrollList(e)}>
            <div style={{ height: '100%', overflow: 'auto' }}>
              <ul>
                {list.map((item, i) => (
                  <DownloadReportRow key={i} data={item} type={props.type} isLatest={i === 0} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
