import { DataGridPro, GridColDef, GridRowData, GridSortItem, GridSortModel } from '@mui/x-data-grid-pro';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { queryBuilder } from 'common/components/grid/query-builder.service';
import { ManageNotData } from 'common/components/manageNotData/ManageNotData';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import { IResponse } from 'common/interfaces/interface';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Image } from 'common/components/image/Image';
import { addDownloadFileData, setNewDownloads } from 'features/DownloadsSlice';

import './OrgScorePopUp.scss';

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  '& .MuiDataGrid-row': {
    backgroundColor: '#FFFFFF !important',

    '.grid-link-field': {
      color: '#4A79E7',
      padding: '4px 6px',
      borderRadius: 4,
      backgroundColor: '#F9FAFF !important'
    },

    '&:hover': {
      backgroundColor: '#F7F7F7 !important',

      '& .MuiDataGrid-cell': {
        '.grid-link-field': {
          backgroundColor: '#F1F1F1 !important',
          '&:hover': {
            backgroundColor: '#F0ECEC !important'
          }
        }
      }
    }
  }
}));

interface IFlexValues {
  [key: string]: number;
}
const FLEX_VALUES: IFlexValues = { asset_importance: 1, asset: 2, description: 6 };

export function OrgScorePopUp(props: any) {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [sort, setSort] = useState<GridSortItem>();
  const [fields, setFields] = useState<string[]>([]);
  const [nextPath, setNextPath] = useState<string>('');
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [csvPath, setCsvPath] = useState<string>('');

  const dispatch = useDispatch<any>();

  let mouseUpEventTimer: ReturnType<typeof setTimeout>;
  const { activeSubsidiary } = useSelector((state: IReduxState) => state.subsidiaries);

  const getPath = (path: string): string => {
    let _path = path.replace(':category_id', props.category.toString());
    if (Object.keys(activeSubsidiary).length && !activeSubsidiary.is_main_company) {
      _path = _path + `${_path.includes('?') ? '&' : '?'}subsidiary=${activeSubsidiary.name}`;
    }
    return _path;
  };

  useEffect(() => {
    RestApi.getData(`${getPath(Paths[PathNames.assesmentsOrgScoreIssuesColumns])}`).subscribe((response: IResponse) => {
      const results = response.data;
      const fields = ColumnsManage.checkGridColumnsToDisplay(results);
      const columns = ColumnsManage.createColumns(response.identifier_field, results, { page: 'orgScore', tab: '' });
      setColumns(columns);
      setFields(fields);
    });
    document.addEventListener('mouseup', setMouseUpEvent);

    return () => {
      document.removeEventListener('mouseup', setMouseUpEvent);
      clearTimeout(mouseUpEventTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fields.length) {
      setLoading(true);
      getRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, sort]);

  const setMouseUpEvent = (): void => {
    mouseUpEventTimer = setTimeout(() => {
      setIsResizing(false);
      clearTimeout(mouseUpEventTimer);
    }, 100);
  };

  const getRows = () => {
    queryBuilder.setBaseURL(Paths[PathNames.assesmentsOrgScoreIssuesData], false);
    queryBuilder.buildQuery({
      additionalFields: ['id'],
      fields: fields,
      order: sort ? (sort.sort === 'asc' ? `-${sort.field}` : sort.field) : undefined,
      filters: { md5: { type: '', value: props.md5 } }
    });
    const path = getPath(queryBuilder.getQuery());
    RestApi.getData(path).subscribe((response: IResponse) => {
      setNextPath(response.next);
      setRows(response.results);
      setLoading(false);
      setCsvPath(path.replace('id,', '').replace(',type', ''));
    });
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (isResizing) {
      return;
    }
    const order = model[0];
    if (order) {
      setSort(order);
    }
  };

  const handleOnRowsScrollEnd = () => {
    if (nextPath) {
      let path = Common.splitV1FromPath(nextPath);
      RestApi.getData(path).subscribe((response: IResponse) => {
        setNextPath(response.next);
        setRows([...rows, ...response.results]);
      });
    }
  };

  const handleCsvDownloadOnClick = async () => {
    const msspAccount = Common.getParamFromUrl('account');
    const exportCsvPath = `${csvPath}&format=csv${`&title=${props.issueName || props.title}`}${
      msspAccount ? `&account=${msspAccount}` : ''
    }`;
    const response = await RestApi.simpleAsyncGet(exportCsvPath);
    dispatch(addDownloadFileData(response));
    dispatch(setNewDownloads(response?.id));
  };

  const renderTable = () => {
    const _columns: any = columns.map((_column, index) => {
      return { ..._column, flex: FLEX_VALUES[_column.field], minWidth: 0 };
    });

    return (
      <>
        {!!rows?.length && (
          <div style={{ display: 'flex', marginRight: 20, marginTop: 10 }} data-tip="Download CSV">
            <div style={{ flexGrow: 1 }}></div>
            <Tooltip
              title="Download CSV"
              placement="bottom-end"
              arrow={true}
              classes={{ tooltip: 'download-csv-tooltip' }}
            >
              <div className="download-csv-link" onClick={handleCsvDownloadOnClick}>
                <Image className="gridUtilities-csv" alt="CSV" icon={'csv'} type="svg" />
              </div>
            </Tooltip>
          </div>
        )}
        <StyledDataGridPro
          components={{
            NoRowsOverlay: () => <ManageNotData />
          }}
          rows={rows}
          columns={_columns}
          onSortModelChange={handleOnSortModelChange}
          onRowsScrollEnd={handleOnRowsScrollEnd}
          filterMode="server"
          sortingMode="server"
          disableMultipleColumnsSorting={true}
          disableSelectionOnClick={true}
          disableColumnReorder={true}
          checkboxSelection={false}
          disableColumnMenu={true}
          hideFooter={true}
        />
      </>
    );
  };

  return (
    <div className="OrgScorePopUp popup-inner-wrapper">
      <header className="OrgScorePopUp-header">
        <div className="text">{props.issueName || props.title}</div>
        <div className="close" onClick={props.onClose}>
          +
        </div>
        <img src="/assets/images/background-popup.svg" alt="background-popup" />
      </header>
      <div className="table">
        {renderTable()}
        {loading && <AppLoading />}
      </div>
    </div>
  );
}
