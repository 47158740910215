import React from 'react';
import { IIcon } from 'common/interfaces/interface';
export class IconTabExternalAssets extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#5D6576';
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 21 20" fill="none">
        <path
          d="M17.3367 5.30319L17.3367 3.16986M15.2033 3.16986L17.3367 3.16986M17.3367 3.16986L13.07 7.43652"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.66333 14.6968V16.8301M5.79666 16.8301H3.66333M3.66333 16.8301L7.93 12.5635"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.80319 3.16309L3.66986 3.16309M3.66986 5.29642L3.66986 3.16309M3.66986 3.16309L7.93652 7.42975"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1968 16.8369L17.3301 16.8369M17.3301 14.7036L17.3301 16.8369M17.3301 16.8369L13.0635 12.5702"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8.3667 4.13333L10.5 2L12.6334 4.13333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.3667 7.8667L18.5 10L16.3667 12.1334" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M12.6333 15.8667L10.5 18L8.36663 15.8667"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.63333 12.1333L2.5 9.99997L4.63333 7.86663"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask id="path-9-inside-1_2691_72175" fill="white">
          <rect x="10.5" y="7" width="4.24264" height="4.24264" rx="0.75" transform="rotate(45 10.5 7)" />
        </mask>
        <rect
          x="10.5"
          y="7"
          width="4.24264"
          height="4.24264"
          rx="0.75"
          transform="rotate(45 10.5 7)"
          stroke={color}
          strokeWidth="2"
          mask="url(#path-9-inside-1_2691_72175)"
        />
      </svg>
    );
  }
}
