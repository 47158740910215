import './SideAlert.scss';
import { useEffect, useState } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { Common } from 'common/services/common.service';
import { AppLoading } from 'common/components/appLoading/appLoading';

const ALERT_FIELDS = [
  { title: 'Incident ID', key: 'incident_id' },
  { title: 'URL', key: 'url' },
  { title: 'Device Association', key: 'device_association' },
  { title: 'Date Compromised', key: 'date_compromised' },
  { title: 'Date Added', key: 'date_added' },
  { title: 'Internal / External URL', key: 'internal_url' },
  { title: 'Org Email Domain', key: 'org_email_domain' }
];

interface IAlertData {
  [key: string]: [] | string | boolean;
}

export function SideAlert(props: any) {
  const [data, setData] = useState<IAlertData>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.id) {
      RestApi.getData(`${Paths[PathNames.assesmentsLeakedCredentials]}${props.id}/`).subscribe((response: any) => {
        setData(response);
        setLoading(false);
      });
    }
  }, [props.id]);

  const renderRestOfFields = () => {
    let _html: JSX.Element[] = [];
    const copy: IAlertData = { ...data };
    delete copy.id;
    delete copy.username;
    delete copy.url_org_host;
    ALERT_FIELDS.forEach((field: any) => {
      delete copy[field.key];
    });
    Object.keys(copy).forEach((_field: string) => {
      if (copy[_field] !== null) {
        _html.push(
          <li>
            <h6>{Common.underscoreToTitle(_field)}</h6>
            <p>{getValue(copy[_field])}</p>
          </li>
        );
      }
    });
    return _html;
  };

  const convertUrlExposure = (val: string | string[] | boolean) => {
    if (val) {
      return 'Internal';
    }
    return 'External';
  };

  const renderFields = () => {
    let _html: JSX.Element[] = [];
    ALERT_FIELDS.forEach((field: any) => {
      if (data[field.key] !== null) {
        _html.push(
          <li>
            <h6>{field.title}</h6>
            <p>
              {field.title === 'Internal / External URL'
                ? convertUrlExposure(data[field.key])
                : getValue(data[field.key])}
            </p>
          </li>
        );
      }
    });
    return _html;
  };

  const getValue = (val: string | string[] | boolean) => {
    if (Array.isArray(val)) {
      return val.join(', ');
    }
    return val;
  };

  return (
    <div className="SideAlert">
      {loading && <AppLoading />}
      <header>
        <div>
          <img src="/assets/images/lightning.svg" alt="Icon" />
          <h5>Compromised devices</h5>
        </div>
        <h3>{data.url_org_host}</h3>
        <p>
          <span>Username:</span> {data.username}
        </p>
      </header>
      <div className="scrollbar-common">
        <div className="main-data-area">
          <div style={{ height: '100%', overflow: 'auto' }}>
            <ul>
              {renderFields()}
              {renderRestOfFields()}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
