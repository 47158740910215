import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconInCapsula extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    let color = this.props.active ? '#8C909A' : '#5D6576';
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.3572 8.09932L6.4137 5V9.38145L10.8456 11.0169L15.6713 9.83575C17.0039 9.46222 17.2866 8.6041 17.3572 8.09932Z"
          fill={color}
        />
        <path
          d="M18.3058 8.37194L17.9424 8.27098C17.9121 8.57385 17.8818 9.8055 16.1656 10.2901L5.9893 12.9452L4.99994 12.4303V19.3962L5.9893 19.9111C5.9893 19.9111 16.953 15.0047 16.9631 14.9946C17.3669 14.7826 18.6895 14.187 18.6895 12.7332V9.54302C18.6895 9.16948 18.7399 8.55366 18.3058 8.37194Z"
          fill={color}
        />
      </svg>
    );
  }
}
