import React from 'react';
import { IIcon } from 'common/interfaces/interface';
export class IconTabInternalAssets extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#5D6576';
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 20 20" fill="none">
        <path
          d="M11.5701 6.30326V8.43659M13.7034 8.43659H11.5701M11.5701 8.43659L15.8367 4.16992"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.42993 13.6967L8.42993 11.5634M6.2966 11.5634L8.42993 11.5634M8.42993 11.5634L4.16326 15.8301"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.30326 8.43018L8.43659 8.43018M8.43659 6.29684L8.43659 8.43018M8.43659 8.43018L4.16992 4.16351"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6967 11.5698L11.5634 11.5698M11.5634 13.7032L11.5634 11.5698M11.5634 11.5698L15.8301 15.8365"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M7.8667 4.13333L10 2L12.1334 4.13333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.8667 7.8667L18 10L15.8667 12.1334" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M12.1333 15.8667L9.99997 18L7.86663 15.8667"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.13333 12.1333L2 9.99997L4.13333 7.86663"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
