import React from 'react';
import { IIcon } from 'common/interfaces/interface';
import { IconsCommon } from '../iconsCommon';
import '../icons.scss';
export class IconArrow extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    let color = this.props.active ? '#3455DD' : '#5D6576';
    if (this.props.color) {
      color = this.props.color;
    }
    return (
      <svg
        className={`pointer ${IconsCommon.viewRotateClass(this.props.toggleRotate)} ${this.props.className || ''}`}
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.09972 7L7.99998 9.5L11.0997 7"
          stroke={this.props.color || color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
