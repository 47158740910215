import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WizardHeader from './WizardHeader';
import WizardContent from './WizardContent';
import WizardFooter from './WizardFooter';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from 'common/components/appLoading/appLoading';
import {
  deleteFieldsValue,
  fetchIntegrationList,
  setCurrentStep,
  setErrorText,
  setFieldsValue
} from 'features/IntegrationsSlice';
import { showError, showSuccess } from 'features/AlertSlice';

import './IntegrationWizard.scss';

interface IIntegrationWizard {
  onFinish: () => void;
  type: string;
}

export const IntegrationWizard: React.FC<IIntegrationWizard> = ({ onFinish, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { integrationSchema, currentStep, totalSteps, fieldsValue } = useSelector((state: any) => state.integrations);

  const stepFields = integrationSchema.steps[`step${currentStep}`];
  const dispatch = useDispatch<any>();

  const deleteFilesDataFromFieldsValueWhenError = () => {
    const filesIds = stepFields.output?.filter((id: string) => integrationSchema.fields[id].type === 'file');

    if (!!filesIds.length) {
      dispatch(deleteFieldsValue(filesIds));
    }
  };

  const handleOnNext = () => {
    setIsLoading(true);
    dispatch(setErrorText(''));

    const payload =
      stepFields.output?.reduce(
        (acc: any, id: string) => ({
          ...acc,
          [id]: fieldsValue[id]
        }),
        {}
      ) || {};

    const url = 'settings/integrations/connections/connection/';
    RestApi.setData(
      url,
      {
        ...payload,
        // name: fieldsValue.name,
        id: fieldsValue.id,
        step: currentStep,
        connection_type: integrationSchema.type
      },
      'POST'
    ).subscribe(
      (response: any) => {
        setIsLoading(currentStep === totalSteps);
        const id = !!response.id ? { id: response.id } : {};
        dispatch(setFieldsValue({ ...id, ...response.fields }));
        if (currentStep === totalSteps) {
          dispatch(showSuccess(`Integration Saved Successfully`));
        }
        if (currentStep === totalSteps) {
          dispatch(fetchIntegrationList(type));
          onFinish();
        } else {
          dispatch(setCurrentStep(response.current_step));
        }
      },
      error => {
        setIsLoading(false);
        dispatch(showError(error));
        deleteFilesDataFromFieldsValueWhenError();
      }
    );
  };

  const handleOnPrev = () => {
    dispatch(setCurrentStep(currentStep - 1));
  };

  return (
    <div className="IntegrationWizard">
      {isLoading && <AppLoading />}
      {!isLoading && (
        <>
          <WizardHeader />
          <WizardContent />
          <WizardFooter onNext={handleOnNext} onPrev={handleOnPrev} />
        </>
      )}
    </div>
  );
};
