import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { PopupHeader } from 'common/components/PopupHeader/PopupHeader';
import { RestApi } from 'common/services/rest-api.service';
import { Common } from 'common/services/common.service';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import { GroupTabs } from 'pages/Settings/FilterGroups/FilterGroupsActions/CreateGroupPopUp/GroupTabs/GroupTabs';
import classNames from 'classnames';
import { DynamicDropDown } from 'common/components/DynamicDropDown/DynamicDropDown';
import { StaticDropDown } from 'common/components/StaticDropDown/StaticDropDown';
import countries, { countriesMap } from 'common/constants/countries';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { Text } from '@cyberpion/cyberpion-ui';
import { fetchSubsidiaryByName, resetSubsidiaryState } from 'features/SubsidiariesSlice';
import { showSuccess, showError } from 'features/AlertSlice';
import { resetGroupsState, addDomain, addIp, addWhois, addMobileApp, toggleService } from 'features/GroupsSlice';
import { getRows } from 'features/GridSlice';

import './AddSubsidiaryPopup.scss';

interface ISubsidiary {
  name: string;
  main_domain: string;
  direct_parent?: string;
  country?: string;
}

const AddSubsidiaryPopup = (props: any) => {
  const [subsidiaryName, setSubsidiaryName] = useState<string>('');
  const [subsidiaryNameError, setSubsidiaryNameError] = useState<boolean>(false);
  const [subsidiaryDomain, setSubsidiaryDomain] = useState<string>('');
  const [parentCompany, setParentCompany] = useState<string>('');
  const [subsidiaryDomainError, setSubsidiaryDomainError] = useState<boolean>(false);
  const [country, setCountry] = useState<string | undefined>();
  const [countryValue, setCountryValue] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const { bulkVisible, domains, ips, services, whois, mobileApps } = useSelector((state: IReduxState) => state.groups);
  const { subsidiariesPopupVisible, subsidiarySelectedId, subsidiariesEditMode, selectedSubsidiary } = useSelector(
    (state: IReduxState) => state.subsidiaries
  );

  useEffect(() => {
    if (subsidiariesEditMode && !!country) {
      setCountryValue(countriesMap[country.toUpperCase()]);
    }
  }, [subsidiariesEditMode, country]);

  useEffect(() => {
    const setSubsidiaryData = () => {
      setSubsidiaryName(selectedSubsidiary.name);
      setSubsidiaryDomain(selectedSubsidiary.main_domain);
      setCountry(selectedSubsidiary.country);
      setParentCompany(selectedSubsidiary.direct_parent);
    };

    const setGroupsData = () => {
      RestApi.getData(Paths[PathNames.services]).subscribe((responseServices: any) => {
        selectedSubsidiary.whois?.forEach((w: { value: string; type: string }) => {
          dispatch(addWhois({ whois: w.value, type: w.type }));
        });
        selectedSubsidiary.domains?.forEach((d: string) => {
          dispatch(addDomain({ value: d }));
        });
        selectedSubsidiary.ip_networks?.forEach((ip: string) => {
          dispatch(addIp({ value: ip }));
        });
        selectedSubsidiary.mobile_apps?.forEach((app: string) => {
          dispatch(addMobileApp({ value: app }));
        });
        selectedSubsidiary.services?.forEach((s: string) => {
          const selectedService = responseServices.find((service: any) => service.label === s);
          dispatch(toggleService(selectedService.value));
        });
      });
    };

    if (!!selectedSubsidiary && !!Object.keys(selectedSubsidiary).length && subsidiariesEditMode) {
      setSubsidiaryData();
      setGroupsData();
    }
  }, [selectedSubsidiary, subsidiariesEditMode, dispatch]);

  useEffect(() => {
    if (subsidiariesPopupVisible && subsidiariesEditMode) {
      dispatch(fetchSubsidiaryByName(subsidiarySelectedId));
    }
  }, [subsidiariesPopupVisible, subsidiariesEditMode, subsidiarySelectedId, dispatch]);

  useEffect(() => {
    document.getElementsByClassName('Settings')[0].classList.add('ontop');
    document.getElementsByClassName('settings-wrapper')[0].classList.add('ontop');

    return () => {
      document.getElementsByClassName('Settings')[0].classList.remove('ontop');
      document.getElementsByClassName('settings-wrapper')[0].classList.remove('ontop');
    };
  }, []);

  const validate = (): boolean => {
    let isError;

    setSubsidiaryDomainError(!Common.validateTldr(subsidiaryDomain));
    setSubsidiaryNameError(!subsidiaryName.length);

    isError = !Common.validateTldr(subsidiaryDomain) || !subsidiaryName.length;
    return !isError;
  };

  const getGroupsPayload = () => {
    const _domains = domains.map((d: { value: string }) => {
      return d.value;
    });
    const _ips = ips.map((ip: { value: string }) => {
      return ip.value;
    });
    const _whois = whois.map((w: { whois: string; type: string }) => {
      return { value: w.whois, type: Common.convertToApiPath(w.type) };
    });
    const _mobileApps = mobileApps.map((app: { value: string }) => {
      return app.value;
    });

    return {
      domains: _domains,
      ip_networks: _ips,
      services: services,
      whois: _whois,
      mobile_apps: _mobileApps
    };
  };

  const saveOnClick = async () => {
    if (!validate()) return false;

    setLoading(true);

    const subsidiaryPayload: ISubsidiary = {
      name: subsidiaryName,
      main_domain: subsidiaryDomain,
      country: country,
      ...(parentCompany ? { direct_parent: parentCompany } : {})
    };

    const groupsPayload = getGroupsPayload();

    RestApi.setData(
      `${Paths[PathNames.subsidiaries]}${subsidiariesEditMode ? subsidiarySelectedId + '/' : ''}`,
      subsidiariesEditMode ? { ...groupsPayload } : { ...subsidiaryPayload, ...groupsPayload },
      subsidiariesEditMode ? 'PUT' : 'POST'
    ).subscribe(
      (response: any) => {
        dispatch(resetSubsidiaryState());
        dispatch(resetGroupsState());

        dispatch(getRows());
        dispatch(showSuccess(`Subsidiary ${subsidiariesEditMode ? 'Saved' : 'Created'} Successfully`));

        props.onClose();
      },
      (error: any) => {
        dispatch(showError(error));
        setLoading(false);
      }
    );
  };

  const handleClosePopup = () => {
    dispatch(resetSubsidiaryState());
    dispatch(resetGroupsState());

    props.onClose();
  };

  const handleNameOnChange = (value: string) => {
    setSubsidiaryName(value);
  };

  return (
    <div className="AddSubsidiaryPopup popup-inner-wrapper">
      <PopupHeader
        placeholder={'Subsidiary Name'}
        text={subsidiaryName}
        onClose={handleClosePopup}
        editable={!subsidiariesEditMode}
        onChange={handleNameOnChange}
        isError={subsidiaryNameError}
        errorText={'Mandatory'}
      />
      <div className="popup-inner">
        {loading ? (
          <AppLoading />
        ) : (
          <>
            <div className="message">
              <Text textColor="#8C909A" textSize={13}>
                the subsidiary information below. Creation or changes of groups may take several minutes to apply.
              </Text>
            </div>
            <div className={`${subsidiariesEditMode ? 'disabled' : ''}`}>
              <div style={{ display: 'flex', width: '100%', marginBottom: 10, alignItems: 'flex-start' }}>
                <div className="input-wrapper" style={{ flexGrow: 1 }}>
                  <input
                    value={subsidiaryDomain}
                    onChange={e => setSubsidiaryDomain(e.target.value)}
                    className={classNames({ error: subsidiaryDomainError })}
                    type="text"
                    placeholder="Main Domain"
                    style={{ height: 35, width: 415, color: '#6d6d7a' }}
                  />
                </div>
                <div className="input-wrapper dropdown" style={{ zIndex: 2 }}>
                  <StaticDropDown
                    list={countries}
                    selected={countryValue}
                    headerText="Country"
                    onChange={(country: string) => {
                      setCountryValue(countriesMap[country.toUpperCase()]);
                      setCountry(country);
                    }}
                    disabled={!!subsidiariesEditMode}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', marginBottom: 10 }}>
                <div className="input-wrapper dropdown" style={{ zIndex: 1 }}>
                  <DynamicDropDown
                    path={`${Paths[PathNames.subsidiaries]}?fields=name`}
                    searchPath={`${Paths[PathNames.subsidiaries]}?fields=name&name__contains=`}
                    selected={parentCompany}
                    field="name"
                    headerText="Parent company (optional)"
                    onChange={(company: string) => setParentCompany(company)}
                  />
                  {/* <span>Country:</span> */}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="notice">
          Use the tabs below to define your groups.
          <br />
          Creation or changes of groups may take several minutes to apply.
        </div>
        <GroupTabs />
        <div className="buttons-wrapper">
          <button className={`btn btn-secondary btn-big ${bulkVisible ? 'disabled' : ''}`} onClick={handleClosePopup}>
            Cancel
          </button>
          <button className={`btn btn-main btn-big`} onClick={saveOnClick}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export { AddSubsidiaryPopup };
