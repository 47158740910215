import ReactTooltip from 'react-tooltip';
import './toolTip.scss';

interface Colors {
  text: string;
  background: string;
  arrow: string;
  effect?: string;
  delayHide?: number;
  offset?: { left: number; top: number };
}

export function ToolTip(props: any) {
  let colors: Colors = {
    text: '',
    background: '',
    arrow: ''
  };

  switch (props.theme) {
    case 'light':
      colors.text = '#5d6576';
      colors.background = '#f0f2f6';
      colors.arrow = '#f0f2f6';
      break;
    case 'dark':
      colors.text = '#f0f2f6';
      colors.background = '#333333';
      colors.arrow = '#333333';
      break;
  }

  return (
    <ReactTooltip
      html={props.html}
      className={props.className}
      place={props.place}
      textColor={colors.text}
      backgroundColor={colors.background}
      arrowColor={colors.arrow}
      id={props.id}
      effect={props.effect || 'float'}
      delayHide={props.delayHide || 0}
      offset={props.offset}
    />
  );
}
