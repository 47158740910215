import { AppLoading } from 'common/components/appLoading/appLoading';
import { PopupHeader } from 'common/components/PopupHeader/PopupHeader';
import { RestApi } from 'common/services/rest-api.service';
import { getRows } from 'features/GridSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './AddAssetsPopUp.scss';
import { Common } from 'common/services/common.service';
import classNames from 'classnames';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { showSuccess, showError } from 'features/AlertSlice';

const LIMIT = 10000;

export function AddAssetsPopUp(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [consent, setConsent] = useState<boolean>(false);
  const [consentError, setConsentError] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');
  const [inputError, setInputError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const dispatch = useDispatch<any>();

  useEffect(() => {
    document.getElementsByClassName('Settings')[0].classList.add('ontop');
    document.getElementsByClassName('settings-wrapper')[0].classList.add('ontop');
    return () => {
      document.getElementsByClassName('Settings')[0].classList.remove('ontop');
      document.getElementsByClassName('settings-wrapper')[0].classList.remove('ontop');
    };
  }, []);

  useEffect(() => {
    setConsentError(false);
  }, [consent]);
  useEffect(() => {
    setInputError(false);
    setErrorText('');
  }, [input]);

  const validate = (_input: string[]): boolean => {
    let hasError = false;

    if (!consent) {
      setConsentError(true);
      hasError = true;
    }

    const inputError = _input.filter((row: string) => {
      if (props.type === 'domain') {
        return !Common.validateTldr(row);
      } else if (props.type === 'ip') {
        return !Common.validateIp(row);
      }
      return true;
    });

    if (inputError.length) {
      hasError = true;
      setInputError(true);
      setErrorText(`Invalid Input: ${inputError[0]}`);
    }

    return !hasError;
  };

  const submit = () => {
    const inputAsArray = input.split('\n');

    if (inputAsArray.length > LIMIT) {
      setErrorText(`List exceeded the number of items (max: ${LIMIT.toLocaleString()})`);
    }

    if (!validate(inputAsArray)) {
      return false;
    }

    const data = inputAsArray.map((item: string) => {
      return props.type === 'domain' ? { asset: item } : { ip_network: item };
    });

    setLoading(true);
    const url = props.type === 'domain' ? Paths[PathNames.manageFqdns] : Paths[PathNames.ipRanges];
    RestApi.setData(url, data, 'POST').subscribe(
      (response: any) => {
        setLoading(false);
        props.onClose();
        dispatch(getRows());
        const msg = props.type === 'domain' ? 'Assets Added Successfully' : 'IPs added successfully';
        dispatch(showSuccess(msg));
      },
      error => {
        setLoading(false);
        // tosterService.showError(error);
        dispatch(showError(error));
      }
    );
  };

  const popupHeaderText = props.type === 'domain' ? 'Add Additional Domains' : 'Add Additional IPs';
  const description =
    props.type === 'domain'
      ? 'Add here domains, subdomains of existing domains, URLs, and managed domains.'
      : 'Add here IPs and IP Ranges.';
  const checkboxText =
    props.type === 'domain'
      ? 'I confirm that all domains, URLs and managed domains are part or associated with my organization.'
      : 'I confirm that all IPs and IP ranges are part of or associated with my organization.';

  return (
    <div className="AddAssetsPopUp popup-inner-wrapper">
      <PopupHeader text={popupHeaderText} onClose={props.onClose} />
      <div className="popup-inner">
        {loading && <AppLoading />}
        <div className="message">
          <div>{description}</div>
          <div>Each item should appear in a separate line.</div>
        </div>
        <textarea
          className={classNames({ error: inputError })}
          value={input}
          onChange={e => setInput(e.target.value)}
        ></textarea>
        {!!errorText && <div className="red">{errorText}</div>}
        <div className={classNames('consent', { error: consentError })} onClick={() => setConsent(!consent)}>
          <input type="checkbox" checked={consent} /> {checkboxText}
        </div>
        <div className="buttons-wrapper">
          <button className="btn btn-big btn-secondary" onClick={props.onClose}>
            Cancel
          </button>
          <button
            className={classNames('btn', 'btn-big', 'btn-main', { disabled: !input || !consent })}
            onClick={submit}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
