import { IconArrow } from '../Icons/IconArrow/IconArrow';
import classNames from 'classnames';
import './DynamicDropDown.scss';
import { useEffect, useState } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from '../appLoading/appLoading';

export function DynamicDropDown(props: any) {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>(props.selected);
  const [data, setData] = useState<[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    RestApi.getData(props.path).subscribe((response: any) => {
      setData(response.results);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _setSelected = (item: string) => {
    setSelected(item);
    props.onChange(item);
    setIsToggled(false);
  };

  const _setIsToggled = () => {
    if (data.length) {
      setIsToggled(!isToggled);
    }
  };

  const getListHTML = (): JSX.Element[] => {
    if (!data.length) {
      return [];
    }

    return data.map((item: any, index: number) => {
      return (
        <li key={item.name ? item.name : index} onClick={() => _setSelected(item[props.field])}>
          {item[props.field]}
        </li>
      );
    });
  };

  const onSubmitSearch = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setLoading(true);
      RestApi.getData(props.searchPath + searchInput).subscribe((response: any) => {
        setData(response.results);
        setLoading(false);
      });
    }
  };

  return !!data && !!data.length ? (
    <div className="DynamicDropDown">
      <header onClick={_setIsToggled} className={classNames({ selected: !!selected, empty: !data?.length })}>
        <span>{props.selected || props.headerText}</span> <IconArrow toggleRotate={isToggled} />
      </header>
      <div className={classNames({ visible: isToggled })}>
        {loading ? (
          <AppLoading />
        ) : (
          <>
            <div className="search">
              <img alt="search" src={`/assets/images/search.svg`} />
              <input
                type="text"
                value={searchInput}
                onChange={e => setSearchInput(e.target.value)}
                onKeyDown={onSubmitSearch}
              />
            </div>
            <div className="scrollbar-common">
              <ul style={{ height: '100%', overflow: 'auto' }}>{getListHTML()}</ul>
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
}
