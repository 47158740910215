import './filterRadioButton.scss';
import { IFilters } from 'common/components/grid/components/gridUtilities/interface';
import { useRef, useEffect, useState } from 'react';
import { IconRadioButton } from 'common/components/Icons/IconRadioButton/IconRadioButton';
import { IChoices } from 'common/interfaces/interface';
import { DropdownHeader } from '../dropdownHeader/DropdownHeader';
import { AppDispatch } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { addFields, addFilter, getRows, removeFields } from 'features/GridSlice';
import { NoFilterOptions } from 'common/components/NoFilterOptions/NoFilterOptions';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { getFiltersServerFormat } from 'utils/filters';
import { selectView, setIsEdited } from 'features/ViewsSlice';

interface IFilterRadioButton {
  item: IFilters;
  // columnPicker: IColumnPicker[];
  // hideOrShowColumnsGrid: (params: any) => void;
  onUpdateDataFilter: (item: IFilters) => void;
  gridRef?: any;
  columns?: any;
  activeFilters: any;
  // onUpdatedFilterGrid: (params: any) => void;
}

export function FilterRadioButton(props: IFilterRadioButton) {
  const ref = useRef<HTMLDivElement>(null);
  const [item, setItem] = useState<IFilters>(props.item);
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const { filters } = useSelector((state: any) => state.grid);
  //@param: event
  //@description: Hides the component
  const handleClickOutside = (event: Event) => {
    // debugger
    if (
      ref.current &&
      !ref.current.contains(event.target as Node)
      // !FiltersManage.contains(event.target)
    ) {
      const option = { ...item };
      option.toggle_dropdown = false;
      setItem(option);
      setIsToggled(false);
      // props.onUpdateDataFilter(option);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    const copy = { ...props.item };
    if (filters[copy.name]) {
      let index: number = -1;
      copy.is_active_filter = true;
      copy.choices.forEach((c: IChoices, i: number) => {
        if (c.name === filters[copy.name].value) {
          c.is_toggled = true;
          index = i;
        } else {
          c.is_toggled = false;
        }
      });
      if (index > -1) {
        copy.sub_label = `: ${copy.choices[index].label}`;
      }
    } else if (props.activeFilters[copy.name].is_active_filter) {
      copy.is_active_filter = false;
      copy.is_toggled = false;
      copy.choices.forEach((choice: IChoices) => {
        choice.is_toggled = false;
      });
      copy.sub_label = '';
      props.onUpdateDataFilter(copy);
    }
    setItem(copy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //     props.item.choices.forEach((c: IChoices, i: number) => {
  //       if (c.is_toggled) {
  //         onSelected(i)
  //         return true
  //       }
  //     })
  //   }
  // })

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  function onSelected(index: number) {
    let selected: any;
    let copy = { ...item };
    copy.choices.forEach((option: IChoices, i: number) => {
      if (index !== i) {
        option.is_toggled = false;
      } else {
        selected = option;
        option.is_toggled = true;
      }
    });
    copy.sub_label = `: ${selected.label}`;
    copy.is_active_filter = true;
    // const filter = {
    //   [copy.name]: {
    //     type: '',
    //     value: selected.name
    //   }
    // };
    const filter = getFiltersServerFormat('radio', copy, selected);
    if (props.columns) {
      if (selected.remove_fields.length && selected.name === 0) {
        dispatch(removeFields(selected.remove_fields));
        ColumnsManage.hideSpecificColumns(props.gridRef, props.columns, selected.remove_fields);
      } else {
        dispatch(addFields(selected.add_fields));
        ColumnsManage.showSpecificColumns(props.gridRef, props.columns, selected.add_fields);
      }
    }
    setItem(copy);
    dispatch(addFilter(filter));
    dispatch(addFields([copy.name]));
    dispatch(getRows());
    dispatch(selectView(null));
    dispatch(setIsEdited(true));
    props.onUpdateDataFilter(copy);
  }

  return (
    <>
      <DropdownHeader
        item={item}
        isToggled={isToggled}
        onToggle={() => setIsToggled(!isToggled)}
        onChangeData={props.onUpdateDataFilter}
      />
      {isToggled && (
        <div ref={ref} className="scrollbar-common filterRadioButton-scope">
          <div className="filterRadioButton-body-scope">
            {item.choices.length ? (
              item.choices.map((option: IChoices, key: number) => (
                <div className="filterRadioButton-option-scope" key={key}>
                  <div className="filterRadioButton-RadioButton">
                    <IconRadioButton
                      radioSize="little"
                      toggle={option.is_toggled}
                      onClick={e => {
                        onSelected(key);
                      }}
                    />
                  </div>
                  <div
                    className="filterRadioButton-label"
                    onClick={e => {
                      onSelected(key);
                    }}
                  >
                    {option.label}
                  </div>
                </div>
              ))
            ) : (
              <NoFilterOptions />
            )}
          </div>
        </div>
      )}
    </>
  );
}
