// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const generalSlice = createSlice({
  name: 'general',
  initialState: {
    allowPopupClose: true,
    globalFilter: '',
    globalFilterType: null,
    isGraphFullScreen: false
  },
  reducers: {
    toggleAllowPopupClose(state, action) {
      state.allowPopupClose = action.payload;
    },
    setGlobalFilter(state, action) {
      state.globalFilter = action.payload;
    },
    setGlobalFilterType(state, action) {
      state.globalFilterType = action.payload;
    },
    setGraphFullScreen(state, action) {
      state.isGraphFullScreen = action.payload;
    }
  }
});

const { actions, reducer } = generalSlice;
export const { toggleAllowPopupClose, setGlobalFilter, setGlobalFilterType, setGraphFullScreen } = actions;
export default reducer;
