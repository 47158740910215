import './actionItemsIcon.scss';
export function ActionItemsIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="408" height="180" viewBox="0 0 408 180" fill="none">
      <ellipse cx="204" cy="152" rx="36" ry="8" fill="#F0F2F6" />
      <path d="M346.511 51.0134L360.632 26.8076L374.752 51.0134H346.511Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M346.511 99.4255L360.632 75.2197L374.752 99.4255H346.511Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M332.391 75.2195L346.511 51.0137L360.631 75.2195H332.391Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M304.151 75.2195L318.271 51.0137L332.391 75.2195H304.151Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M374.752 51.0134L388.872 26.8076L402.992 51.0134H374.752Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path
        d="M374.752 51.0139L360.632 75.2197L346.512 51.0139L374.752 51.0139Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M360.632 75.219L346.512 99.4248L332.391 75.219L360.632 75.219Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M360.632 26.8079L346.512 51.0137L332.391 26.8079L360.632 26.8079Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M332.391 75.219L318.271 99.4248L304.151 75.219L332.391 75.219Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M402.991 51.0139L388.871 75.2197L374.751 51.0139L402.991 51.0139Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <circle cx="360.631" cy="75.2194" r="3.06212" fill="#E8E8E8" />
      <circle cx="332.555" cy="75.2194" r="3.06212" fill="#E8E8E8" />
      <circle cx="304.142" cy="75.2194" r="3.06212" fill="#E8E8E8" />
      <circle cx="389.044" cy="27.0621" r="3.06212" fill="#E8E8E8" />
      <circle cx="360.968" cy="27.0621" r="3.06212" fill="#E8E8E8" />
      <circle cx="332.555" cy="27.0621" r="3.06212" fill="#E8E8E8" />
      <circle cx="374.597" cy="99.7799" r="3.06212" fill="#E8E8E8" />
      <circle cx="346.521" cy="99.7799" r="3.06212" fill="#E8E8E8" />
      <circle cx="318.108" cy="99.7799" r="3.06212" fill="#E8E8E8" />
      <circle cx="318.108" cy="51.1402" r="3.06212" fill="#E8E8E8" />
      <circle cx="346.384" cy="51.1402" r="3.06212" fill="#E8E8E8" />
      <circle cx="374.661" cy="51.1402" r="3.06212" fill="#E8E8E8" />
      <circle cx="402.937" cy="51.1402" r="3.06212" fill="#E8E8E8" />
      <path d="M47.4314 112.013L61.5514 87.8076L75.6715 112.013H47.4314Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M47.4314 160.426L61.5514 136.22L75.6715 160.426H47.4314Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M33.3113 136.219L47.4313 112.014L61.5514 136.219H33.3113Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M5.07129 136.219L19.1913 112.014L33.3114 136.219H5.07129Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M75.6716 112.013L89.7917 87.8076L103.912 112.013H75.6716Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path
        d="M75.6716 112.014L61.5516 136.22L47.4315 112.014L75.6716 112.014Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M61.5515 136.219L47.4315 160.425L33.3114 136.219L61.5515 136.219Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M61.5515 87.8079L47.4315 112.014L33.3114 87.8079L61.5515 87.8079Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M33.3113 136.219L19.1912 160.425L5.07118 136.219L33.3113 136.219Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M103.911 112.014L89.7913 136.22L75.6713 112.014L103.911 112.014Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <circle cx="61.5514" cy="136.219" r="3.06212" fill="#E8E8E8" />
      <circle cx="33.475" cy="136.219" r="3.06212" fill="#E8E8E8" />
      <circle cx="5.06212" cy="136.219" r="3.06212" fill="#E8E8E8" />
      <circle cx="89.9642" cy="88.0621" r="3.06212" fill="#E8E8E8" />
      <circle cx="61.8878" cy="88.0621" r="3.06212" fill="#E8E8E8" />
      <circle cx="33.475" cy="88.0621" r="3.06212" fill="#E8E8E8" />
      <circle cx="75.517" cy="160.78" r="3.06212" fill="#E8E8E8" />
      <circle cx="47.4405" cy="160.78" r="3.06212" fill="#E8E8E8" />
      <circle cx="19.0277" cy="160.78" r="3.06212" fill="#E8E8E8" />
      <circle cx="19.0277" cy="112.14" r="3.06212" fill="#E8E8E8" />
      <circle cx="47.3043" cy="112.14" r="3.06212" fill="#E8E8E8" />
      <circle cx="75.5807" cy="112.14" r="3.06212" fill="#E8E8E8" />
      <circle cx="103.857" cy="112.14" r="3.06212" fill="#E8E8E8" />
      <path
        d="M105 12C105 10.8954 105.895 10 107 10H303C304.105 10 305 10.8954 305 12V28.5215C305 31.5472 302.547 34 299.521 34H107C105.895 34 105 33.1046 105 32V12Z"
        fill="#F0F2F6"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <path
        d="M103 14.6848C103 14.3066 103.307 14 103.685 14H106.315C106.693 14 107 14.3066 107 14.6848V29.3152C107 29.6934 106.693 30 106.315 30H103.685C103.307 30 103 29.6934 103 29.3152V14.6848Z"
        fill="#D1D2D3"
      />
      <path
        d="M121 46C121 44.8954 121.895 44 123 44H319C320.105 44 321 44.8954 321 46V62.5215C321 65.5472 318.547 68 315.521 68H123C121.895 68 121 67.1046 121 66V46Z"
        fill="#F0F2F6"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <path
        d="M119 48.6848C119 48.3066 119.307 48 119.685 48H122.315C122.693 48 123 48.3066 123 48.6848V63.3152C123 63.6934 122.693 64 122.315 64H119.685C119.307 64 119 63.6934 119 63.3152V48.6848Z"
        fill="#D1D2D3"
      />
      <path
        d="M89 80C89 78.8954 89.8954 78 91 78H287C288.105 78 289 78.8954 289 80V96.5215C289 99.5472 286.547 102 283.521 102H91C89.8954 102 89 101.105 89 100V80Z"
        fill="#F0F2F6"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <path
        d="M87 82.6848C87 82.3066 87.3066 82 87.6848 82H90.3152C90.6934 82 91 82.3066 91 82.6848V97.3152C91 97.6934 90.6934 98 90.3152 98H87.6848C87.3066 98 87 97.6934 87 97.3152V82.6848Z"
        fill="#D1D2D3"
      />
      <path
        d="M121 114C121 112.895 121.895 112 123 112H319C320.105 112 321 112.895 321 114V130.521C321 133.547 318.547 136 315.521 136H123C121.895 136 121 135.105 121 134V114Z"
        fill="#F0F2F6"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <path
        d="M119 116.685C119 116.307 119.307 116 119.685 116H122.315C122.693 116 123 116.307 123 116.685V131.315C123 131.693 122.693 132 122.315 132H119.685C119.307 132 119 131.693 119 131.315V116.685Z"
        fill="#D1D2D3"
      />
      <path
        d="M105 148C105 146.895 105.895 146 107 146H303C304.105 146 305 146.895 305 148V164.521C305 167.547 302.547 170 299.521 170H107C105.895 170 105 169.105 105 168V148Z"
        fill="#F0F2F6"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 4"
      />
      <path
        d="M103 150.685C103 150.307 103.307 150 103.685 150H106.315C106.693 150 107 150.307 107 150.685V165.315C107 165.693 106.693 166 106.315 166H103.685C103.307 166 103 165.693 103 165.315V150.685Z"
        fill="#D1D2D3"
      />
    </svg>
  );
}
