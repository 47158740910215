// @ts-nocheck
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import {
//   azureList_Mock,
//   azure_schema_Mock,
//   integrations_Mock,
//   integrations_metadata_Mock,
//   setting_integrations_Mock,
//   aws_schema_Mock,
//   aws_List_Mock
// } from 'common/mocks/integrations_mock';
import { addHeaders } from 'common/services/rest-api.service';
import { env } from 'env';

// TODO: neet to remove this API call
export const loadList = createAsyncThunk('integrations/loadList', async (_, thunkAPI) => {
  const path = `${env.REACT_APP_BASE_URL}settings/integrations/`;
  const res = await fetch(path, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => {
    if (!data.ok) {
      return thunkAPI.rejectWithValue({
        status: data.status
      });
    }
    return data.json();
  });
  return res;
});

export const fetchIntegrationsList = createAsyncThunk('integrations/fetchIntegrationsList', async (_, thunkAPI) => {
  const path = `${env.REACT_APP_BASE_URL}settings/integrations/connections/schema/?summary=true`;
  const res = await fetch(path, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => {
    if (!data.ok) {
      return thunkAPI.rejectWithValue({
        status: data.status
      });
    }
    return data.json();
  });
  return res;
});

export const fetchIntegrationsMetadata = createAsyncThunk(
  'integrations/fetchIntegrationsMetadata',
  async (_, thunkAPI) => {
    const path = `${env.REACT_APP_BASE_URL}settings/integrations/connections/connection_metadata/`;
    const res = await fetch(path, {
      method: 'GET',
      mode: 'cors',
      headers: addHeaders('GET'),
      credentials: 'include'
    }).then(data => {
      if (!data.ok) {
        return thunkAPI.rejectWithValue({
          status: data.status
        });
      }
      return data.json();
    });
    return res;
  }
);

export const fetchIntegrationList = createAsyncThunk(
  'integrations/fetchIntegration',
  async (type: string, thunkAPI) => {
    const path = `${env.REACT_APP_BASE_URL}settings/integrations/connections/connection/?integration_type=${type}`;
    const res = await fetch(path, {
      method: 'GET',
      mode: 'cors',
      headers: addHeaders('GET'),
      credentials: 'include'
    }).then(data => {
      if (!data.ok) {
        return thunkAPI.rejectWithValue({
          status: data.status
        });
      }
      return data.json();
    });
    return res;
  }
);

export const fetchIntegrationSchema = createAsyncThunk(
  'integrations/fetchIntegrationSchema',
  async (type: string, thunkAPI) => {
    const path = `${env.REACT_APP_BASE_URL}settings/integrations/connections/schema/?integration_type=${type}`;
    const res = await fetch(path, {
      method: 'GET',
      mode: 'cors',
      headers: addHeaders('GET'),
      credentials: 'include'
    }).then(data => {
      if (!data.ok) {
        return thunkAPI.rejectWithValue({
          status: data.status
        });
      }
      return data.json();
    });
    return res;
  }
);

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: {
    component: '',
    visible: false,
    loading: false,
    list: [],
    categoryPopup: '',
    integrationDeleted: false,
    statusIntegrationsList: 'idle',
    statusIntegrationsMetadata: 'idle',
    statusIntegrationList: 'idle',
    statusIntegrationSchema: 'idle',
    allIntegrations: [],
    integrationsMetadata: null,
    integrationList: null,
    integrationSchema: null,
    totalSteps: null,
    currentStep: null,
    fieldsValue: {},
    fieldsError: {},
    errorText: ''
  },
  reducers: {
    showIntegration(state, action) {
      state.component = action.payload;
      state.visible = true;
    },
    hideIntegration(state) {
      state.component = '';
      state.visible = false;
    },
    showCategoryPopup: (state, action) => {
      state.categoryPopup = action.payload;
    },
    resetIntegrationPopup: state => {
      state.categoryPopup = '';
      state.integrationDeleted = false;
    },
    setIntegrationDeleted: (state, action) => {
      state.integrationDeleted = action.payload;
    },
    resetIntegrationWizard: state => {
      state.totalSteps = null;
      state.currentStep = null;
    },
    resetIntegrationPopside: state => {
      state.totalSteps = null;
      state.currentStep = null;
      state.integrationList = null;
      state.integrationSchema = null;
    },
    setFieldsValue: (state, { payload }) => {
      state.fieldsValue = { ...state.fieldsValue, ...payload };
    },
    deleteFieldsValue: (state, { payload }) => {
      const updatedFieldsValue = { ...state.fieldsValue };
      payload.forEach((id: string) => {
        delete updatedFieldsValue[id];
      });
      state.fieldsValue = { ...updatedFieldsValue };
    },
    resetFieldsValue: state => {
      state.fieldsValue = {};
    },
    setFieldsError: (state, { payload }) => {
      state.fieldsError = { ...state.fieldsError, ...payload };
    },
    setCurrentStep: (state, { payload }) => {
      state.currentStep = payload;
    },
    addIntegrationsToList: (state, { payload }) => {
      state.allIntegrations = [...payload, ...state.allIntegrations];
    },
    setErrorText: (state, { payload }) => {
      state.errorText = payload;
    }
  },
  extraReducers: {
    [loadList.pending]: state => {
      state.loading = true;
    },
    [loadList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.list = payload;
      // state.list = setting_integrations_Mock;
      state.integrationDeleted = false;
    },
    [loadList.rejected]: state => {
      state.loading = false;
    },
    [fetchIntegrationsList.pending]: state => {
      state.statusIntegrationsList = 'loading';
    },
    [fetchIntegrationsList.fulfilled]: (state, { payload }) => {
      state.statusIntegrationsList = 'succeed';
      state.allIntegrations = payload.sort((a, b) => a.category.localeCompare(b.category));
      // state.allIntegrations = integrations_Mock.sort((a, b) => a.category.localeCompare(b.category));
      state.integrationDeleted = false;
    },
    [fetchIntegrationsList.rejected]: state => {
      state.statusIntegrationsList = 'failed';
    },
    [fetchIntegrationsMetadata.pending]: state => {
      state.statusIntegrationsMetadata = 'loading';
    },
    [fetchIntegrationsMetadata.fulfilled]: (state, { payload }) => {
      state.statusIntegrationsMetadata = 'succeed';
      state.integrationsMetadata = payload;
      // state.integrationsMetadata = integrations_metadata_Mock;
    },
    [fetchIntegrationsMetadata.rejected]: state => {
      state.statusIntegrationsMetadata = 'failed';
    },
    [fetchIntegrationList.pending]: state => {
      state.statusIntegrationList = 'loading';
    },
    [fetchIntegrationList.fulfilled]: (state, { payload }) => {
      state.statusIntegrationList = 'succeed';
      state.integrationList = payload;
      // state.integrationList = aws_List_Mock;
    },
    [fetchIntegrationList.rejected]: state => {
      state.statusIntegrationList = 'failed';
    },
    [fetchIntegrationSchema.pending]: state => {
      state.statusIntegrationSchema = 'loading';
    },
    [fetchIntegrationSchema.fulfilled]: (state, { payload }) => {
      state.statusIntegrationSchema = 'succeed';
      state.integrationSchema = Object.values(payload)[0];
      state.totalSteps = Object.values(payload)[0]?.total_steps;
      // state.integrationSchema = Object.values(azure_schema_Mock)[0];
      // state.totalSteps = Object.values(azure_schema_Mock)[0]?.total_steps;
    },
    [fetchIntegrationSchema.rejected]: state => {
      state.statusIntegrationSchema = 'failed';
    }
  }
});

const { actions, reducer } = integrationsSlice;
export const {
  showIntegration,
  hideIntegration,
  showCategoryPopup,
  resetIntegrationPopup,
  setIntegrationDeleted,
  resetIntegrationWizard,
  resetIntegrationPopside,
  setCurrentStep,
  setFieldsValue,
  resetFieldsValue,
  setFieldsError,
  addIntegrationsToList,
  setErrorText,
  deleteFieldsValue
} = actions;
export default reducer;
