import { Image } from 'common/components/image/Image';
import './logo.scss';

const NEW_COMP_NAME = 'ionix';

export function Logo() {
  const shouldShowIonixLogo = (): boolean => {
    return true;
  };

  const getLogo = () => {
    return `logo${shouldShowIonixLogo() ? `-${NEW_COMP_NAME}` : ''}`;
  };

  return (
    <div className="logo-container">
      <Image className="logo" icon={getLogo()} alt="Logo" type="svg" />
    </div>
  );
}
