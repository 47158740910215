import { Button } from '@cyberpion/cyberpion-ui';
import './SaveCustomView.scss';
import { useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { addUserCustomView, fetchUserCreatedViews } from 'features/ViewsSlice';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { setIsOpenSaveViews } from 'features/ViewsSlice';
import { useClickOutside } from 'hooks/useClickOutside';

export function SaveCustomView(props: any) {
  const [name, setName] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const ref = useRef<any>(null);

  useClickOutside(ref, props.onClose);

  const submit = async () => {
    if (name.length > 1) {
      setLoading(true);
      await dispatch(
        addUserCustomView({
          path: window.location.pathname + window.location.search,
          label: name
        })
      );
      await dispatch(fetchUserCreatedViews());
      setLoading(false);
      setName('');
      props.onClose();
      dispatch(setIsOpenSaveViews(true));
    } else {
      setError(true);
    }
  };

  return (
    <div className="SaveCustomView" ref={ref}>
      {loading && <AppLoading text="Adding your custom view..." />}
      <input
        className={error ? 'error' : ''}
        placeholder="Name your view..."
        maxLength={80}
        value={name}
        onChange={(e: any) => {
          setError(false);
          setName(e.target.value);
        }}
      />
      <div>
        <Button type="button" text="Cancel" size="small" buttonStyle="secondary" onClick={props.onClose} />
        <Button type="button" text="Create" size="small" buttonStyle="main" onClick={submit} />
      </div>
    </div>
  );
}
