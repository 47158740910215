import { useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import { Text } from '@cyberpion/cyberpion-ui';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { makeStyles } from '@material-ui/core/styles';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import classNames from 'classnames';
import { RestApi } from 'common/services/rest-api.service';
import { showSuccess } from 'features/AlertSlice';
import { Common } from 'common/services/common.service';
import { setDropDownWithAction, setShowAddCommentPopup, updateRows } from 'features/GridSlice';
import { setCommentsTabCount } from 'features/popSideSlice';

import './DropdownWithAction.scss';

const useStyles = makeStyles({
  popoverRoot: {
    marginTop: '10px',
    maxHeight: 400,
    minHeight: 105,
    padding: '6px',
    border: '1px solid #ecedff',
    '& .option-item': {
      color: '#5D6576',
      padding: '8px 10px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f6f8ff'
      },
      '&.selected': {
        backgroundColor: '#f6f8ff80 !important'
      },
      '& .cyberpion-ui-text': {
        borderBottom: 'none'
      }
    }
  }
});

export function DropdownWithAction(props: any) {
  const [optionSelected, setOptionSelected] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const { rows } = useSelector((state: IReduxState) => state.grid);
  const { user } = useSelector((state: IReduxState) => state.login);
  const { commentsTabCount } = useSelector((state: IReduxState) => state.popSide);
  const dispatch = useDispatch<any>();

  const classes = useStyles();

  const options = !!columnPicker?.length
    ? columnPicker
        .find((col: any) => col.type === 'dropdown_with_action')
        .choices.filter(
          (item: any) =>
            (props.row.type !== 'Vulnerable Component' && item.name !== 'security_patch_applied') ||
            props.row.type === 'Vulnerable Component'
        )
        .map((item: any) => ({ label: item.label, name: item.name, is_toggled: item.is_toggled }))
    : [];

  useEffect(() => {
    if (!!props.row?.current_state) {
      const dropdown_with_action = columnPicker.find((col: any) => col.type === 'dropdown_with_action');
      if (dropdown_with_action) {
        const item = dropdown_with_action.choices.find((item: any) => item.label === props.row.current_state);
        if (!!item) {
          setOptionSelected(item);
        }
      }
    }
  }, [columnPicker, props.row.current_state]);

  useEffect(() => {
    if (!!rows && !!rows.length) {
      const row = rows.find((row: any) => row.id === props.row.id);
      if (!!row?.current_state) {
        const item = columnPicker
          .find((col: any) => col.type === 'dropdown_with_action')
          .choices.find((item: any) => item.label === row.current_state);
        if (!!item) {
          setOptionSelected(item);
        }
      }
    }
  }, [rows, props.row.id, columnPicker]);

  const handleOnClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemOnClick = (option: any) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (option.name !== 'security_patch_applied') {
      const url = Common.AddMsspAccountIfNeeded('remediation/action-items/state/');
      const payload = {
        ids: [props.row.id],
        state: option.name
      };
      RestApi.setData(url, payload, 'POST').subscribe(
        (response: any) => {
          setAnchorEl(null);
          setOptionSelected(option);

          const row = rows.find((row: any) => row.id === props.row.id);
          const updatedRow = [
            {
              ...row,
              current_state: option.label,
              state_update_user: user.email,
              state_update_timestamp: new Date().toISOString()
            }
          ];
          dispatch(updateRows({ rows: updatedRow, identifier: 'id' }));
          dispatch(setCommentsTabCount(commentsTabCount + 1));
          dispatch(showSuccess('Status Updated Successfully'));
        },
        error => {
          setAnchorEl(null);
        }
      );
    } else {
      setAnchorEl(null);
      dispatch(
        setDropDownWithAction({
          listLastStatusOption: { [props.row.id]: optionSelected },
          nextStatusOption: option
        })
      );

      const row = rows.find((row: any) => row.id === props.row.id);
      const updatedRow = [
        {
          ...row,
          current_state: 'Security Patch Applied'
        }
      ];
      dispatch(updateRows({ rows: updatedRow, identifier: 'id' }));
      setOptionSelected(option);
      dispatch(setShowAddCommentPopup(true));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className="DropdownWithAction">
      <div className="dropdown" onClick={handleOnClick}>
        <div>{optionSelected?.label}</div>
        <div className="chevron-icon">
          <IconArrow toggleRotate={open} />
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: classes.popoverRoot }}
      >
        {options.map((option: any) => (
          <div
            key={option.name}
            className={classNames('option-item', { selected: option.label === optionSelected?.label })}
            onClick={handleItemOnClick(option)}
          >
            <Text textColor="#5D6576" textSize={13}>
              {option.label}
            </Text>
          </div>
        ))}
      </Popover>
    </div>
  );
}
