import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import './DownloadReportRow.scss';

export function DownloadReportRow(props: any) {
  const getLink = (e: any) => {
    e.preventDefault();
    RestApi.setData(`reports/${props.type}/${props.data.name}/link/`, {}, 'POST').subscribe((response: any) => {
      window.open(response.link);
    });
  };

  return (
    <li className="DownloadReportRow" key={props.data.name}>
      <div>
        {props.isLatest && <span className="latest">Latest</span>}
        <span style={{ fontSize: 12 }}>
          {Common.getDateString(props.data.date)} ({Common.getFileSize(props.data.size)}MB)
        </span>
      </div>
      <button onClick={getLink}>Download</button>
    </li>
  );
}
