import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { Checkbox } from 'common/components/Icons/IconCheckbox/IconCheckbox';
import { IOptionSelectItem } from 'common/components/multiSelect/interface';
import './selectItem.scss';

export function SelectItem(props: IOptionSelectItem) {
  //Sends true or false. To hide or show a column
  function handleChange() {
    props.option.is_toggled = !props.option.is_toggled;
    props.onChange(props.option);
  }

  //Opens the dropdown and rotate the arrow of the dropdown
  function handleChangeArrow() {
    //I added the condition because of the interface
    if (props.onChangeArrow) props.onChangeArrow(props.toggleArrow);
  }

  const getClassName = (label: string): string => {
    if (props.searchTerm && !label.toLowerCase().includes(props.searchTerm.toLowerCase())) {
      return 'hide';
    }
    return '';
  };

  return (
    <>
      {props.option.is_displayable && !props.option.is_hidden && (
        <div className={`option-wrapper ${getClassName(props.option.label)}`}>
          <div className="option" onClick={handleChange}>
            <Checkbox active={props.option.is_toggled} />
            <div className="MGS-option-text">{props.option.label}</div>
          </div>

          {props.showArrow && (
            <div onClick={handleChangeArrow}>
              <IconArrow active={props.toggleArrow} toggleRotate={props.toggleArrow} />
            </div>
          )}
        </div>
      )}
    </>
  );
}
