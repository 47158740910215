import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Grid from '@mui/material/Grid';
import { setIsRowMenuActive } from 'features/GridSlice';
import { IconPen } from 'common/components/Icons/IconPen/IconPen';
import { IconMenu } from 'common/components/Icons/IconMenu/IconMenu';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

interface IRowActionMenu {
  rowData: any;
  itemOnClick: (rowId: string, option: any, type: string, event: React.MouseEvent<HTMLElement>) => void;
  type: string;
  status?: any;
  classes?: string;
}

const itemMenu = [
  { id: 'edit', label: 'Edit', icon: <IconPen /> },
  { id: 'delete', label: 'Delete', icon: <img alt="delete notification" src="/assets/images/trash.svg" /> }
];

const RowActionMenu: React.FC<IRowActionMenu> = ({ rowData, itemOnClick, type, status, classes = '' }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch<any>();

  // const handleHoverClasses = () => {
  //   try {
  //     const actionRows = document
  //       .getElementsByClassName('MuiDataGrid-virtualScroller')[0]
  //       .getElementsByClassName('MuiDataGrid-pinnedColumns')[0]
  //       .getElementsByClassName('Mui-hovered');
  //     if (!!actionRows.length) {
  //       for (let i = 0; i < actionRows.length; i++) {
  //         actionRows[i].classList.remove('Mui-hovered');
  //       }
  //     }
  //     const hoveredRows = document
  //       .getElementsByClassName('MuiDataGrid-virtualScroller')[0]
  //       .getElementsByClassName('MuiDataGrid-virtualScrollerRenderZone')[0]
  //       .getElementsByClassName('Mui-hovered');
  //     if (!!hoveredRows.length) {
  //       for (let i = 0; i < hoveredRows.length; i++) {
  //         hoveredRows[i].classList.remove('Mui-hovered');
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    // handleHoverClasses();
    dispatch(setIsRowMenuActive(true));
    event.stopPropagation();
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    // handleHoverClasses();
    dispatch(setIsRowMenuActive(false));
    // event.stopPropagation();
  };

  const handleItemOnClick = (option: any) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
    itemOnClick(rowData, option, type, event);
  };

  return (
    <Grid item alignSelf="center" sx={{ marginTop: '2px' }} className={classNames(classes, 'row-item-menu')}>
      <div
        onClick={handleMenuClick}
        className="icon-menu"
        data-testid={`row-action-menu-${rowData.id ? rowData.id : ''}`}
      >
        <IconMenu active={false} />
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            width: 100
          }
        }}
      >
        {itemMenu.map((option, index) => {
          const isDisabled =
            !!status &&
            ((option.id === 'edit' && !status?.is_editable) || (option.id === 'delete' && !status?.is_deletable));

          return (
            <div key={index}>
              <MenuItem
                key={option.id}
                disabled={isDisabled}
                sx={option.id === 'delete' ? { color: '#f46666' } : {}}
                onClick={handleItemOnClick(option)}
                data-testid={`row-action-menu-${option.id}`}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                {option.label}
              </MenuItem>
              {index === 0 && <Divider />}
            </div>
          );
        })}
      </Menu>
    </Grid>
  );
};

export default RowActionMenu;
