import './generalScore.scss';
import { Common } from 'common/services/common.service';
interface IGeneralScore {
  label: string;
  risk_score: string;
  urgency: string;
}
export function GeneralScore(props: IGeneralScore) {
  return (
    <div className="quickViewPanel-risk-score-scope">
      <div className="quickViewPanel-risk-score-header">{props.label}</div>
      <div
        className="quickViewPanel-risk-score-level font-number-common"
        style={{ color: Common.getColorByUrgency(props.urgency) }}
      >
        {props.risk_score}
      </div>
    </div>
  );
}
