export const cwes: any = {
  '1004': {
    name: "Sensitive Cookie Without 'HttpOnly' Flag",
    link: 'https://cwe.mitre.org/data/definitions/1004.html',
    description:
      "The HttpOnly flag directs compatible browsers to prevent client-side script from accessing cookies. Including the HttpOnly flag in the Set-Cookie HTTP response header helps mitigate the risk associated with Cross-Site Scripting (XSS) where an attacker's script code might attempt to read the contents of a cookie and exfiltrate information obtained. When set, browsers that support the flag will not reveal the contents of the cookie to a third party via client-side script executed via XSS."
  },
  '1007': {
    name: 'Insufficient Visual Distinction of Homoglyphs Presented to User',
    link: 'https://cwe.mitre.org/data/definitions/1007.html',
    description: ''
  },
  '102': {
    name: 'Struts: Duplicate Validation Forms',
    link: 'https://cwe.mitre.org/data/definitions/102.html',
    description:
      "If two validation forms have the same name, the Struts Validator arbitrarily chooses one of the forms to use for input validation and discards the other. This decision might not correspond to the programmer's expectations, possibly leading to resultant weaknesses. Moreover, it indicates that the validation logic is not up-to-date, and can indicate that other, more subtle validation errors are present."
  },
  '1021': {
    name: 'Improper Restriction of Rendered UI Layers or Frames',
    link: 'https://cwe.mitre.org/data/definitions/1021.html',
    description:
      'A web application is expected to place restrictions on whether it is allowed to be rendered within frames, iframes, objects, embed or applet elements. Without the restrictions, users can be tricked into interacting with the application when they were not intending to.'
  },
  '1022': {
    name: 'Use of Web Link to Untrusted Target with window.opener Access',
    link: 'https://cwe.mitre.org/data/definitions/1022.html',
    description:
      'When a user clicks a link to an external site ("target"), the target="_blank" attribute causes the target site\'s contents to be opened in a new window or tab, which runs in the same process as the original page. The window.opener object records information about the original page that offered the link.  If an attacker can run script on the target page, then they could read or modify certain properties of the window.opener object, including the location property - even if the original and target site are not the same origin.  An attacker can modify the location property to automatically redirect the user to a malicious site, e.g. as part of a phishing attack. Since this redirect happens in the original window/tab - which is not necessarily visible, since the browser is focusing the display on the new target page - the user might not notice any suspicious redirection.'
  },
  '1023': {
    name: 'Incomplete Comparison with Missing Factors',
    link: 'https://cwe.mitre.org/data/definitions/1023.html',
    description:
      '\n\tAn incomplete comparison can lead to resultant weaknesses, e.g., by operating on the wrong object or making a security decision without considering a required factor.\n      '
  },
  '1024': {
    name: 'Comparison of Incompatible Types',
    link: 'https://cwe.mitre.org/data/definitions/1024.html',
    description:
      'In languages that are strictly typed but support casting/conversion, such as C or C++, the programmer might assume that casting one entity to the same type as another entity will ensure that the comparison will be performed correctly, but this cannot be guaranteed.  In languages that are not strictly typed, such as PHP or JavaScript, there may be implicit casting/conversion to a type that the programmer is unaware of, causing unexpected results; for example, the string "123" might be converted to a number type.  See examples.'
  },
  '1025': {
    name: 'Comparison Using Wrong Factors',
    link: 'https://cwe.mitre.org/data/definitions/1025.html',
    description:
      'This can lead to incorrect results and resultant weaknesses.  For example, the code might inadvertently compare references to objects, instead of the relevant contents of those objects, causing two "equal" objects to be considered unequal.'
  },
  '103': {
    name: 'Struts: Incomplete validate() Method Definition',
    link: 'https://cwe.mitre.org/data/definitions/103.html',
    description:
      'If the code does not call super.validate(), the Validation Framework cannot check the contents of the form against a validation form. In other words, the validation framework will be disabled for the given form.'
  },
  '1037': {
    name: 'Processor Optimization Removal or Modification of Security-critical Code',
    link: 'https://cwe.mitre.org/data/definitions/1037.html',
    description:
      'The developer builds a security-critical protection mechanism into the software, but the processor optimizes the execution of the program such that the mechanism is removed or modified.'
  },
  '1038': {
    name: 'Insecure Automated Optimizations',
    link: 'https://cwe.mitre.org/data/definitions/1038.html',
    description:
      'The product uses a mechanism that automatically optimizes code, e.g. to improve a characteristic such as performance, but the optimizations can have an unintended side effect that might violate an intended security assumption.'
  },
  '1039': {
    name: 'Automated Recognition Mechanism with Inadequate Detection or Handling of Adversarial Input Perturbations',
    link: 'https://cwe.mitre.org/data/definitions/1039.html',
    description: '\n            '
  },
  '104': {
    name: 'Struts: Form Bean Does Not Extend Validation Class',
    link: 'https://cwe.mitre.org/data/definitions/104.html',
    description:
      'If a form bean does not extend an ActionForm subclass of the Validator framework, it can expose the application to other weaknesses related to insufficient input validation.'
  },
  '1041': {
    name: 'Use of Redundant Code',
    link: 'https://cwe.mitre.org/data/definitions/1041.html',
    description:
      'The product has multiple functions, methods, procedures, macros, etc. that\n\t\t\t\t\tcontain the same code.'
  },
  '1042': {
    name: 'Static Member Data Element outside of a Singleton Class Element',
    link: 'https://cwe.mitre.org/data/definitions/1042.html',
    description:
      "The code contains a member element that is declared as static (but not final), in which\n\t\t\t\t\tits parent class element\n\t\t\t\t\tis not a singleton class - that is, a class element that can be used only once in\n\t\t\t\t\tthe 'to' association of a Create action."
  },
  '1043': {
    name: 'Data Element Aggregating an Excessively Large Number of Non-Primitive Elements',
    link: 'https://cwe.mitre.org/data/definitions/1043.html',
    description:
      'The product uses a data element that has an excessively large\n\t\t\t\t\tnumber of sub-elements with non-primitive data types such as structures or aggregated objects.'
  },
  '1044': {
    name: 'Architecture with Number of Horizontal Layers Outside of Expected Range',
    link: 'https://cwe.mitre.org/data/definitions/1044.html',
    description: "The product's architecture contains too many - or too few -\n\t\t\t\t\thorizontal layers."
  },
  '1045': {
    name: 'Parent Class with a Virtual Destructor and a Child Class without a Virtual Destructor',
    link: 'https://cwe.mitre.org/data/definitions/1045.html',
    description:
      'A parent class has a virtual destructor method, but the parent has a child class that does not have a virtual destructor.'
  },
  '1046': {
    name: 'Creation of Immutable Text Using String Concatenation',
    link: 'https://cwe.mitre.org/data/definitions/1046.html',
    description: 'The product creates an immutable text string using string concatenation operations.'
  },
  '1047': {
    name: 'Modules with Circular Dependencies',
    link: 'https://cwe.mitre.org/data/definitions/1047.html',
    description:
      'The product contains modules in which one module has references that cycle back to itself, i.e., there are circular dependencies.'
  },
  '1048': {
    name: 'Invokable Control Element with Large Number of Outward Calls',
    link: 'https://cwe.mitre.org/data/definitions/1048.html',
    description:
      'The code contains callable control elements that\n         contain an excessively large number of references to other\n         application objects external to the context of the callable,\n         i.e. a Fan-Out value that is excessively large.'
  },
  '1049': {
    name: 'Excessive Data Query Operations in a Large Data Table',
    link: 'https://cwe.mitre.org/data/definitions/1049.html',
    description:
      'The product performs a data query with a large number of joins\n\t\t\t\t\tand sub-queries on a large data table.'
  },
  '105': {
    name: 'Struts: Form Field Without Validator',
    link: 'https://cwe.mitre.org/data/definitions/105.html',
    description:
      'Omitting validation for even a single input field may give attackers the leeway they need to compromise the product. Although J2EE applications are not generally susceptible to memory corruption attacks, if a J2EE application interfaces with native code that does not perform array bounds checking, an attacker may be able to use an input validation mistake in the J2EE application to launch a buffer overflow attack.'
  },
  '1050': {
    name: 'Excessive Platform Resource Consumption within a Loop',
    link: 'https://cwe.mitre.org/data/definitions/1050.html',
    description:
      'The product has a loop body or loop condition that contains a control element that directly or\n\t\t\t\t\tindirectly consumes platform resources, e.g. messaging, sessions, locks, or file\n\t\t\t\t\tdescriptors.'
  },
  '1051': {
    name: 'Initialization with Hard-Coded Network Resource Configuration Data',
    link: 'https://cwe.mitre.org/data/definitions/1051.html',
    description: 'The product initializes data using hard-coded values that act as network resource identifiers.'
  },
  '1052': {
    name: 'Excessive Use of Hard-Coded Literals in Initialization',
    link: 'https://cwe.mitre.org/data/definitions/1052.html',
    description:
      'The product initializes a data element using a hard-coded\n\t\t\t\t\tliteral that is not a simple integer or static constant element.'
  },
  '1053': {
    name: 'Missing Documentation for Design',
    link: 'https://cwe.mitre.org/data/definitions/1053.html',
    description: 'The product does not have documentation that represents how it is designed.'
  },
  '1054': {
    name: 'Invocation of a Control Element at an Unnecessarily Deep Horizontal Layer',
    link: 'https://cwe.mitre.org/data/definitions/1054.html',
    description:
      'The code at one architectural layer invokes code that resides\n\t\t\t\t\tat a deeper layer than the adjacent layer, i.e., the invocation skips at least one\n\t\t\t\t\tlayer, and the invoked code is not part of a vertical utility layer that can be referenced from any horizontal layer.'
  },
  '1055': {
    name: 'Multiple Inheritance from Concrete Classes',
    link: 'https://cwe.mitre.org/data/definitions/1055.html',
    description: 'The product contains a class with inheritance from more than\n\t\t\t\t\tone concrete class.'
  },
  '1056': {
    name: 'Invokable Control Element with Variadic Parameters',
    link: 'https://cwe.mitre.org/data/definitions/1056.html',
    description:
      'A named-callable or method control element has a signature that\n\t\t\t\t\tsupports a variable (variadic) number of parameters or arguments.'
  },
  '1057': {
    name: 'Data Access Operations Outside of Expected Data Manager Component',
    link: 'https://cwe.mitre.org/data/definitions/1057.html',
    description:
      'The product uses a dedicated, central data manager component as required by design, but it contains code that performs data-access operations that do not use this data manager.'
  },
  '1058': {
    name: 'Invokable Control Element in Multi-Thread Context with non-Final Static Storable or Member Element',
    link: 'https://cwe.mitre.org/data/definitions/1058.html',
    description:
      'The code contains a function or method that\n\t\t operates in a multi-threaded environment but owns an unsafe non-final\n\t\t                     static storable or member data element.'
  },
  '1059': {
    name: 'Insufficient Technical Documentation',
    link: 'https://cwe.mitre.org/data/definitions/1059.html',
    description: '\n\t   '
  },
  '106': {
    name: 'Struts: Plug-in Framework not in Use',
    link: 'https://cwe.mitre.org/data/definitions/106.html',
    description: '\n            '
  },
  '1060': {
    name: 'Excessive Number of Inefficient Server-Side Data Accesses',
    link: 'https://cwe.mitre.org/data/definitions/1060.html',
    description:
      'The product performs too many data queries without using efficient data processing functionality such as stored procedures.'
  },
  '1061': {
    name: 'Insufficient Encapsulation',
    link: 'https://cwe.mitre.org/data/definitions/1061.html',
    description:
      'The product does not sufficiently hide the internal representation and implementation details of data or methods, which might allow external components or modules to modify data unexpectedly, invoke unexpected functionality, or introduce dependencies that the programmer did not intend.'
  },
  '1062': {
    name: 'Parent Class with References to Child Class',
    link: 'https://cwe.mitre.org/data/definitions/1062.html',
    description: 'The code has a parent class that contains references to a child class, its methods, or its members.'
  },
  '1063': {
    name: 'Creation of Class Instance within a Static Code Block',
    link: 'https://cwe.mitre.org/data/definitions/1063.html',
    description: 'A static code block creates an instance of a class.'
  },
  '1064': {
    name: 'Invokable Control Element with Signature Containing an Excessive Number of Parameters',
    link: 'https://cwe.mitre.org/data/definitions/1064.html',
    description:
      'The product contains a function, subroutine, or method whose signature has an unnecessarily large number of\n\t\t\t\t\tparameters/arguments.'
  },
  '1065': {
    name: 'Runtime Resource Management Control Element in a Component Built to Run on Application Servers',
    link: 'https://cwe.mitre.org/data/definitions/1065.html',
    description:
      'The product uses deployed components from application servers, but it also uses low-level functions/methods for management of resources, instead of the API provided by the application server.'
  },
  '1066': {
    name: 'Missing Serialization Control Element',
    link: 'https://cwe.mitre.org/data/definitions/1066.html',
    description:
      'The product contains a serializable data element that does not\n\t\t\t\t\thave an associated serialization method.'
  },
  '1067': {
    name: 'Excessive Execution of Sequential Searches of Data Resource',
    link: 'https://cwe.mitre.org/data/definitions/1067.html',
    description:
      'The product contains a data query against an SQL table or view\n\t\t\t\t\tthat is configured in a way that does not utilize an index and may cause\n\t\t\t\t\tsequential searches to be performed.'
  },
  '1068': {
    name: 'Inconsistency Between Implementation and Documented Design',
    link: 'https://cwe.mitre.org/data/definitions/1068.html',
    description:
      'The implementation of the product is not consistent with the\n\t\t\t\t\tdesign as described within the relevant documentation.'
  },
  '1069': {
    name: 'Empty Exception Block',
    link: 'https://cwe.mitre.org/data/definitions/1069.html',
    description:
      'An invokable code block contains an exception handling block that does not contain any code, i.e. is empty.'
  },
  '107': {
    name: 'Struts: Unused Validation Form',
    link: 'https://cwe.mitre.org/data/definitions/107.html',
    description:
      'It is easy for developers to forget to update validation logic when they remove or rename action form mappings. One indication that validation logic is not being properly maintained is the presence of an unused validation form.'
  },
  '1070': {
    name: 'Serializable Data Element Containing non-Serializable Item Elements',
    link: 'https://cwe.mitre.org/data/definitions/1070.html',
    description:
      'The product contains a serializable, storable data element such as a field or member,\n\t\t\t\t\tbut the data element contains member elements that are not\n\t\t\t\t\tserializable.'
  },
  '1071': {
    name: 'Empty Code Block',
    link: 'https://cwe.mitre.org/data/definitions/1071.html',
    description: 'The source code contains a block that does not contain any code, i.e., the block is empty.'
  },
  '1072': {
    name: 'Data Resource Access without Use of Connection Pooling',
    link: 'https://cwe.mitre.org/data/definitions/1072.html',
    description:
      'The product accesses a data resource through a database without using a\n\t\t\t\t\tconnection pooling capability.'
  },
  '1073': {
    name: 'Non-SQL Invokable Control Element with Excessive Number of Data Resource Accesses',
    link: 'https://cwe.mitre.org/data/definitions/1073.html',
    description:
      'The product contains a client with a function or method that contains a large number of data accesses/queries that are sent through a data manager, i.e., does not use efficient database capabilities.'
  },
  '1074': {
    name: 'Class with Excessively Deep Inheritance',
    link: 'https://cwe.mitre.org/data/definitions/1074.html',
    description:
      'A class has an inheritance level that is too high, i.e., it\n\t\t\t\t\thas a large number of parent classes.'
  },
  '1075': {
    name: 'Unconditional Control Flow Transfer outside of Switch Block',
    link: 'https://cwe.mitre.org/data/definitions/1075.html',
    description:
      'The product performs unconditional control transfer (such as a\n\t\t\t\t\t"goto") in code outside of a branching structure such as a switch\n\t\t\t\t\tblock.'
  },
  '1076': {
    name: 'Insufficient Adherence to Expected Conventions',
    link: 'https://cwe.mitre.org/data/definitions/1076.html',
    description:
      "The product's architecture, source code, design, documentation,\n\t\t\t\t\tor other artifact does not follow required conventions."
  },
  '1077': {
    name: 'Floating Point Comparison with Incorrect Operator',
    link: 'https://cwe.mitre.org/data/definitions/1077.html',
    description: '\n\t   '
  },
  '1078': {
    name: 'Inappropriate Source Code Style or Formatting',
    link: 'https://cwe.mitre.org/data/definitions/1078.html',
    description:
      'The source code does not follow\n\t\t\t\tdesired style or formatting for indentation, white\n\t\t\t\tspace, comments, etc.'
  },
  '1079': {
    name: 'Parent Class without Virtual Destructor Method',
    link: 'https://cwe.mitre.org/data/definitions/1079.html',
    description:
      'A parent class contains one or more child classes, but the parent class does not have a virtual destructor method.'
  },
  '108': {
    name: 'Struts: Unvalidated Action Form',
    link: 'https://cwe.mitre.org/data/definitions/108.html',
    description:
      'If a Struts Action Form Mapping specifies a form, it must have a validation form defined under the Struts Validator.'
  },
  '1080': {
    name: 'Source Code File with Excessive Number of Lines of Code',
    link: 'https://cwe.mitre.org/data/definitions/1080.html',
    description: 'A source code file has too many lines of\n\t\t\t\t\tcode.'
  },
  '1082': {
    name: 'Class Instance Self Destruction Control Element',
    link: 'https://cwe.mitre.org/data/definitions/1082.html',
    description: 'The code contains a class instance that calls the method or function to delete or destroy itself.'
  },
  '1083': {
    name: 'Data Access from Outside Expected Data Manager Component',
    link: 'https://cwe.mitre.org/data/definitions/1083.html',
    description:
      'The product is intended to manage data access through a particular data manager component such as a relational or non-SQL database, but it contains code that performs data access operations without using that component.'
  },
  '1084': {
    name: 'Invokable Control Element with Excessive File or Data Access Operations',
    link: 'https://cwe.mitre.org/data/definitions/1084.html',
    description:
      'A function or method contains too many\n\t\t\t\t\toperations that utilize a data manager or file resource.'
  },
  '1085': {
    name: 'Invokable Control Element with Excessive Volume of Commented-out Code',
    link: 'https://cwe.mitre.org/data/definitions/1085.html',
    description:
      'A function, method, procedure, etc. contains an excessive amount of code that has been\n\t\t\t\t\tcommented out within its body.'
  },
  '1086': {
    name: 'Class with Excessive Number of Child Classes',
    link: 'https://cwe.mitre.org/data/definitions/1086.html',
    description: 'A class contains an unnecessarily large number of\n\t\t\t\t\tchildren.'
  },
  '1087': {
    name: 'Class with Virtual Method without a Virtual Destructor',
    link: 'https://cwe.mitre.org/data/definitions/1087.html',
    description: 'A class contains a virtual method, but the method does not have an associated virtual destructor.'
  },
  '1088': {
    name: 'Synchronous Access of Remote Resource without Timeout',
    link: 'https://cwe.mitre.org/data/definitions/1088.html',
    description:
      'The code has a synchronous call to a remote resource, but there is no timeout for the call, or the timeout is set to infinite.'
  },
  '1089': {
    name: 'Large Data Table with Excessive Number of Indices',
    link: 'https://cwe.mitre.org/data/definitions/1089.html',
    description: 'The product uses a large data table that contains an excessively large number of\n\t\t\t\t\tindices.'
  },
  '109': {
    name: 'Struts: Validator Turned Off',
    link: 'https://cwe.mitre.org/data/definitions/109.html',
    description:
      'Automatic filtering via a Struts bean has been turned off, which disables the Struts Validator and custom validation logic. This exposes the application to other weaknesses related to insufficient input validation.'
  },
  '1090': {
    name: 'Method Containing Access of a Member Element from Another Class',
    link: 'https://cwe.mitre.org/data/definitions/1090.html',
    description:
      'A method for a class performs an operation that directly\n\t\t\t\t\taccesses a member element from another class.'
  },
  '1091': {
    name: 'Use of Object without Invoking Destructor Method',
    link: 'https://cwe.mitre.org/data/definitions/1091.html',
    description:
      "The product contains a method that accesses an object but does not later invoke\n\t\t\t\t\tthe element's associated finalize/destructor method."
  },
  '1092': {
    name: 'Use of Same Invokable Control Element in Multiple Architectural Layers',
    link: 'https://cwe.mitre.org/data/definitions/1092.html',
    description: 'The product uses the same control element across multiple\n\t\t\t\t\tarchitectural layers.'
  },
  '1093': {
    name: 'Excessively Complex Data Representation',
    link: 'https://cwe.mitre.org/data/definitions/1093.html',
    description:
      'The product uses an unnecessarily complex internal representation for its data structures or interrelationships between those structures.'
  },
  '1094': {
    name: 'Excessive Index Range Scan for a Data Resource',
    link: 'https://cwe.mitre.org/data/definitions/1094.html',
    description:
      'The product contains an index range scan for a large data table,\n\t\t\t\t\tbut the scan can cover a large number of rows.'
  },
  '1095': {
    name: 'Loop Condition Value Update within the Loop',
    link: 'https://cwe.mitre.org/data/definitions/1095.html',
    description:
      'The product uses a loop with a control flow condition based on\n\t\t\t\t\ta value that is updated within the body of the loop.'
  },
  '1096': {
    name: 'Singleton Class Instance Creation without Proper Locking or Synchronization',
    link: 'https://cwe.mitre.org/data/definitions/1096.html',
    description:
      'The product implements a Singleton design pattern but does not use appropriate locking or other synchronization mechanism to ensure that the singleton class is only instantiated once.'
  },
  '1097': {
    name: 'Persistent Storable Data Element without Associated Comparison Control Element',
    link: 'https://cwe.mitre.org/data/definitions/1097.html',
    description:
      'The product uses a storable data element that does not have\n\t\t\t\t\tall of the associated functions or methods that are necessary to support\n\t\t\t\t\tcomparison.'
  },
  '1098': {
    name: 'Data Element containing Pointer Item without Proper Copy Control Element',
    link: 'https://cwe.mitre.org/data/definitions/1098.html',
    description:
      'The code contains a data element with a pointer that does not have an associated copy or constructor method.'
  },
  '1099': {
    name: 'Inconsistent Naming Conventions for Identifiers',
    link: 'https://cwe.mitre.org/data/definitions/1099.html',
    description:
      "The product's code, documentation, or other artifacts do not\n\t\t\t\t\tconsistently use the same naming conventions for variables, callables, groups of\n\t\t\t\t\trelated callables, I/O capabilities, data types, file names, or similar types of\n\t\t\t\t\telements."
  },
  '11': {
    name: 'ASP.NET Misconfiguration: Creating Debug Binary',
    link: 'https://cwe.mitre.org/data/definitions/11.html',
    description:
      'ASP .NET applications can be configured to produce debug binaries. These binaries give detailed debugging messages and should not be used in production environments. Debug binaries are meant to be used in a development or testing environment and can pose a security risk if they are deployed to production.'
  },
  '110': {
    name: 'Struts: Validator Without Form Field',
    link: 'https://cwe.mitre.org/data/definitions/110.html',
    description: '\n            '
  },
  '1100': {
    name: 'Insufficient Isolation of System-Dependent Functions',
    link: 'https://cwe.mitre.org/data/definitions/1100.html',
    description:
      'The product or code does not isolate system-dependent\n\t\t\t\t\tfunctionality into separate standalone modules.'
  },
  '1101': {
    name: 'Reliance on Runtime Component in Generated Code',
    link: 'https://cwe.mitre.org/data/definitions/1101.html',
    description:
      'The product uses automatically-generated code that cannot be\n\t\t\t\t\texecuted without a specific runtime support component.'
  },
  '1102': {
    name: 'Reliance on Machine-Dependent Data Representation',
    link: 'https://cwe.mitre.org/data/definitions/1102.html',
    description:
      'The code uses a data representation that relies on low-level\n\t\t\t\t\tdata representation or constructs that may vary across different processors,\n\t\t\t\t\tphysical machines, OSes, or other physical components.'
  },
  '1103': {
    name: 'Use of Platform-Dependent Third Party Components',
    link: 'https://cwe.mitre.org/data/definitions/1103.html',
    description:
      'The product relies on third-party components that do\n\t\t\t\t\tnot provide equivalent functionality across all desirable\n\t\t\t\t\tplatforms.'
  },
  '1104': {
    name: 'Use of Unmaintained Third Party Components',
    link: 'https://cwe.mitre.org/data/definitions/1104.html',
    description:
      'The product relies on third-party components that are not\n\t\t\t\t\tactively supported or maintained by the original developer or a trusted proxy\n\t\t\t\t\tfor the original developer.'
  },
  '1105': {
    name: 'Insufficient Encapsulation of Machine-Dependent Functionality',
    link: 'https://cwe.mitre.org/data/definitions/1105.html',
    description:
      'The product or code uses machine-dependent functionality, but\n\t\t\t\t\tit does not sufficiently encapsulate or isolate this functionality from\n\t\t\t\t\tthe rest of the code.'
  },
  '1106': {
    name: 'Insufficient Use of Symbolic Constants',
    link: 'https://cwe.mitre.org/data/definitions/1106.html',
    description:
      'The source code uses literal constants that may need to change\n\t\t\t\t\tor evolve over time, instead of using symbolic constants.'
  },
  '1107': {
    name: 'Insufficient Isolation of Symbolic Constant Definitions',
    link: 'https://cwe.mitre.org/data/definitions/1107.html',
    description:
      'The source code uses symbolic constants, but it does not\n\t\t\t\t\tsufficiently place the definitions of these constants into a more centralized or\n\t\t\t\t\tisolated location.'
  },
  '1108': {
    name: 'Excessive Reliance on Global Variables',
    link: 'https://cwe.mitre.org/data/definitions/1108.html',
    description:
      'The code is structured in a way that relies too much on using\n\t\t\t\t\tor setting global variables throughout various points in the code, instead of\n\t\t\t\t\tpreserving the associated information in a narrower, more local\n\t\t\t\t\tcontext.'
  },
  '1109': {
    name: 'Use of Same Variable for Multiple Purposes',
    link: 'https://cwe.mitre.org/data/definitions/1109.html',
    description:
      'The code contains a callable, block, or other code element in\n\t\t\t\t\twhich the same variable is used to control more than one unique task or store\n\t\t\t\t\tmore than one instance of data.'
  },
  '111': {
    name: 'Direct Use of Unsafe JNI',
    link: 'https://cwe.mitre.org/data/definitions/111.html',
    description:
      'Many safety features that programmers may take for granted do not apply for native code, so you must carefully review all such code for potential problems. The languages used to implement native code may be more susceptible to buffer overflows and other attacks. Native code is unprotected by the security features enforced by the runtime environment, such as strong typing and array bounds checking.'
  },
  '1110': {
    name: 'Incomplete Design Documentation',
    link: 'https://cwe.mitre.org/data/definitions/1110.html',
    description:
      "The product's design documentation does not adequately describe\n\t\t\t\t\tcontrol flow, data flow, system initialization, relationships between tasks,\n\t\t\t\t\tcomponents, rationales, or other important aspects of the\n\t\t\t\t\tdesign."
  },
  '1111': {
    name: 'Incomplete I/O Documentation',
    link: 'https://cwe.mitre.org/data/definitions/1111.html',
    description:
      "The product's documentation does not adequately define inputs,\n\t\t\t\t\toutputs, or system/software interfaces."
  },
  '1112': {
    name: 'Incomplete Documentation of Program Execution',
    link: 'https://cwe.mitre.org/data/definitions/1112.html',
    description:
      'The document does not fully define all mechanisms that are used\n\t\t\t\t\tto control or influence how product-specific programs are\n\t\t\t\t\texecuted.'
  },
  '1113': {
    name: 'Inappropriate Comment Style',
    link: 'https://cwe.mitre.org/data/definitions/1113.html',
    description:
      'The source code uses comment styles or formats that are\n\t\t\t\t\tinconsistent or do not follow expected standards for the\n\t\t\t\t\tproduct.'
  },
  '1114': {
    name: 'Inappropriate Whitespace Style',
    link: 'https://cwe.mitre.org/data/definitions/1114.html',
    description:
      'The source code contains whitespace that is inconsistent across\n\t\t\t\t\tthe code or does not follow expected standards for the\n\t\t\t\t\tproduct.'
  },
  '1115': {
    name: 'Source Code Element without Standard Prologue',
    link: 'https://cwe.mitre.org/data/definitions/1115.html',
    description:
      'The source code contains elements such as source files\n\t\t\t\t\tthat do not consistently provide a prologue or header that has been\n\t\t\t\t\tstandardized for the project.'
  },
  '1116': {
    name: 'Inaccurate Comments',
    link: 'https://cwe.mitre.org/data/definitions/1116.html',
    description:
      'The source code contains comments that do not accurately\n\t\t\t\t\tdescribe or explain aspects of the portion of the code with which the comment is\n\t\t\t\t\tassociated.'
  },
  '1117': {
    name: 'Callable with Insufficient Behavioral Summary',
    link: 'https://cwe.mitre.org/data/definitions/1117.html',
    description:
      "The code contains a function or method whose signature and/or associated\n\t\t\t\t\tinline documentation does not sufficiently describe the callable's inputs, outputs,\n\t\t\t\t\tside effects, assumptions, or return codes."
  },
  '1118': {
    name: 'Insufficient Documentation of Error Handling Techniques',
    link: 'https://cwe.mitre.org/data/definitions/1118.html',
    description:
      'The documentation does not sufficiently describe the techniques\n\t\t\t\t\tthat are used for error handling, exception processing, or similar\n\t\t\t\t\tmechanisms.'
  },
  '1119': {
    name: 'Excessive Use of Unconditional Branching',
    link: 'https://cwe.mitre.org/data/definitions/1119.html',
    description: 'The code uses too many unconditional branches (such as\n\t\t\t\t\t"goto").'
  },
  '112': {
    name: 'Missing XML Validation',
    link: 'https://cwe.mitre.org/data/definitions/112.html',
    description:
      "Most successful attacks begin with a violation of the programmer's assumptions. By accepting an XML document without validating it against a DTD or XML schema, the programmer leaves a door open for attackers to provide unexpected, unreasonable, or malicious input."
  },
  '1120': {
    name: 'Excessive Code Complexity',
    link: 'https://cwe.mitre.org/data/definitions/1120.html',
    description: 'The code is too complex, as calculated using a well-defined,\n\t\t\t\t\tquantitative measure.'
  },
  '1121': {
    name: 'Excessive McCabe Cyclomatic Complexity',
    link: 'https://cwe.mitre.org/data/definitions/1121.html',
    description: 'The code contains McCabe cyclomatic complexity that exceeds a\n\tdesirable maximum.'
  },
  '1122': {
    name: 'Excessive Halstead Complexity',
    link: 'https://cwe.mitre.org/data/definitions/1122.html',
    description:
      'The code is structured in a way that a Halstead complexity\n\t\t\t\t\tmeasure exceeds a desirable maximum.'
  },
  '1123': {
    name: 'Excessive Use of Self-Modifying Code',
    link: 'https://cwe.mitre.org/data/definitions/1123.html',
    description: 'The product uses too much self-modifying\n\t\t\t\t\tcode.'
  },
  '1124': {
    name: 'Excessively Deep Nesting',
    link: 'https://cwe.mitre.org/data/definitions/1124.html',
    description:
      'The code contains a callable or other code grouping in which\n\t\t\t\t\tthe nesting / branching is too deep.'
  },
  '1125': {
    name: 'Excessive Attack Surface',
    link: 'https://cwe.mitre.org/data/definitions/1125.html',
    description:
      'The product has an attack surface whose quantitative\n\t\t\t\t\tmeasurement exceeds a desirable maximum.'
  },
  '1126': {
    name: 'Declaration of Variable with Unnecessarily Wide Scope',
    link: 'https://cwe.mitre.org/data/definitions/1126.html',
    description:
      'The source code declares a variable in one scope, but the\n\t\t\t\t\tvariable is only used within a narrower scope.'
  },
  '1127': {
    name: 'Compilation with Insufficient Warnings or Errors',
    link: 'https://cwe.mitre.org/data/definitions/1127.html',
    description:
      'The code is compiled without sufficient warnings enabled, which\n\t\t\t\t\tmay prevent the detection of subtle bugs or quality\n\t\t\t\t\tissues.'
  },
  '113': {
    name: "Improper Neutralization of CRLF Sequences in HTTP Headers ('HTTP Request/Response Splitting')",
    link: 'https://cwe.mitre.org/data/definitions/113.html',
    description: '\n\t   '
  },
  '114': {
    name: 'Process Control',
    link: 'https://cwe.mitre.org/data/definitions/114.html',
    description: 'Process control vulnerabilities take two forms:\n\t '
  },
  '115': {
    name: 'Misinterpretation of Input',
    link: 'https://cwe.mitre.org/data/definitions/115.html',
    description:
      'The product misinterprets an input, whether from an attacker or another product, in a security-relevant fashion.'
  },
  '116': {
    name: 'Improper Encoding or Escaping of Output',
    link: 'https://cwe.mitre.org/data/definitions/116.html',
    description: '\n            '
  },
  '1164': {
    name: 'Irrelevant Code',
    link: 'https://cwe.mitre.org/data/definitions/1164.html',
    description:
      'The product contains code that is not essential for execution,\n\t     i.e. makes no state changes and has no side effects that alter\n\t     data or control flow, such that removal of the code would have no impact\n\t     to functionality or correctness.'
  },
  '117': {
    name: 'Improper Output Neutralization for Logs',
    link: 'https://cwe.mitre.org/data/definitions/117.html',
    description: '\n            '
  },
  '1173': {
    name: 'Improper Use of Validation Framework',
    link: 'https://cwe.mitre.org/data/definitions/1173.html',
    description:
      'Many modern coding languages provide developers with input validation frameworks to make the task of input validation easier and less error-prone. These frameworks will automatically check all input against specified criteria and direct execution to error handlers when invalid input is received. The improper use (i.e., an incorrect implementation or missing altogether) of these frameworks is not directly exploitable, but can lead to an exploitable condition if proper input validation is not performed later in the product. Not using provided input validation frameworks can also hurt the maintainability of code as future developers may not recognize the downstream input validation being used in the place of the validation framework.'
  },
  '1174': {
    name: 'ASP.NET Misconfiguration: Improper Model Validation',
    link: 'https://cwe.mitre.org/data/definitions/1174.html',
    description: 'The ASP.NET application does not use, or incorrectly uses, the model validation framework.'
  },
  '1176': {
    name: 'Inefficient CPU Computation',
    link: 'https://cwe.mitre.org/data/definitions/1176.html',
    description:
      'The product performs CPU computations using\n         algorithms that are not as efficient as they could be for the\n         needs of the developer, i.e., the computations can be\n         optimized further.'
  },
  '1177': {
    name: 'Use of Prohibited Code',
    link: 'https://cwe.mitre.org/data/definitions/1177.html',
    description:
      'The product uses a function, library, or third party component\n\t     that has been explicitly prohibited, whether by the developer or\n\t     the customer.'
  },
  '118': {
    name: "Incorrect Access of Indexable Resource ('Range Error')",
    link: 'https://cwe.mitre.org/data/definitions/118.html',
    description:
      'The product does not restrict or incorrectly restricts operations within the boundaries of a resource that is accessed using an index or pointer, such as memory or files.'
  },
  '1187': {
    name: 'DEPRECATED: Use of Uninitialized Resource',
    link: 'https://cwe.mitre.org/data/definitions/1187.html',
    description:
      'This entry has been deprecated because it was a duplicate of CWE-908. All content has been transferred to CWE-908.'
  },
  '1188': {
    name: 'Initialization of a Resource with an Insecure Default',
    link: 'https://cwe.mitre.org/data/definitions/1188.html',
    description:
      'The product initializes or sets a resource with a default that is intended to be changed by the administrator, but the default is not secure.'
  },
  '1189': {
    name: 'Improper Isolation of Shared Resources on System-on-a-Chip (SoC)',
    link: 'https://cwe.mitre.org/data/definitions/1189.html',
    description: '\n            '
  },
  '119': {
    name: 'Improper Restriction of Operations within the Bounds of a Memory Buffer',
    link: 'https://cwe.mitre.org/data/definitions/119.html',
    description: '\n            '
  },
  '1190': {
    name: 'DMA Device Enabled Too Early in Boot Phase',
    link: 'https://cwe.mitre.org/data/definitions/1190.html',
    description: '\n            '
  },
  '1191': {
    name: 'On-Chip Debug and Test Interface With Improper Access Control',
    link: 'https://cwe.mitre.org/data/definitions/1191.html',
    description: '\n      '
  },
  '1192': {
    name: 'System-on-Chip (SoC) Using Components without Unique, Immutable Identifiers',
    link: 'https://cwe.mitre.org/data/definitions/1192.html',
    description: '\n\t   '
  },
  '1193': {
    name: 'Power-On of Untrusted Execution Core Before Enabling Fabric Access Control',
    link: 'https://cwe.mitre.org/data/definitions/1193.html',
    description: '\n\t  '
  },
  '12': {
    name: 'ASP.NET Misconfiguration: Missing Custom Error Page',
    link: 'https://cwe.mitre.org/data/definitions/12.html',
    description:
      "An ASP .NET application must enable custom error pages in order to prevent attackers from mining information from the framework's built-in responses."
  },
  '120': {
    name: "Buffer Copy without Checking Size of Input ('Classic Buffer Overflow')",
    link: 'https://cwe.mitre.org/data/definitions/120.html',
    description:
      'A buffer overflow condition exists when a product attempts to put more data in a buffer than it can hold, or when it attempts to put data in a memory area outside of the boundaries of a buffer. The simplest type of error, and the most common cause of buffer overflows, is the "classic" case in which the product copies the buffer without restricting how much is copied. Other variants exist, but the existence of a classic overflow strongly suggests that the programmer is not considering even the most basic of security protections.'
  },
  '1204': {
    name: 'Generation of Weak Initialization Vector (IV)',
    link: 'https://cwe.mitre.org/data/definitions/1204.html',
    description:
      '\n\t\t\t  By design, some cryptographic primitives\n\t\t\t  (such as block ciphers) require that IVs\n\t\t\t  must have certain properties for the\n\t\t\t  uniqueness and/or unpredictability of an\n\t\t\t  IV. Primitives may vary in how important\n\t\t\t  these properties are. If these properties\n\t\t\t  are not maintained, e.g. by a bug in the\n\t\t\t  code, then the cryptography may be weakened\n\t\t\t  or broken by attacking the IVs themselves.\n\t\t\t'
  },
  '1209': {
    name: 'Failure to Disable Reserved Bits',
    link: 'https://cwe.mitre.org/data/definitions/1209.html',
    description: '\n                '
  },
  '121': {
    name: 'Stack-based Buffer Overflow',
    link: 'https://cwe.mitre.org/data/definitions/121.html',
    description:
      'A stack-based buffer overflow condition is a condition where the buffer being overwritten is allocated on the stack (i.e., is a local variable or, rarely, a parameter to a function).'
  },
  '122': {
    name: 'Heap-based Buffer Overflow',
    link: 'https://cwe.mitre.org/data/definitions/122.html',
    description:
      'A heap overflow condition is a buffer overflow, where the buffer that can be overwritten is allocated in the heap portion of memory, generally meaning that the buffer was allocated using a routine such as malloc().'
  },
  '1220': {
    name: 'Insufficient Granularity of Access Control',
    link: 'https://cwe.mitre.org/data/definitions/1220.html',
    description: '\n                '
  },
  '1221': {
    name: 'Incorrect Register Defaults or Module Parameters',
    link: 'https://cwe.mitre.org/data/definitions/1221.html',
    description: '\n                '
  },
  '1222': {
    name: 'Insufficient Granularity of Address Regions Protected by Register Locks',
    link: 'https://cwe.mitre.org/data/definitions/1222.html',
    description: '\n                '
  },
  '1223': {
    name: 'Race Condition for Write-Once Attributes',
    link: 'https://cwe.mitre.org/data/definitions/1223.html',
    description: '\n                '
  },
  '1224': {
    name: 'Improper Restriction of Write-Once Bit Fields',
    link: 'https://cwe.mitre.org/data/definitions/1224.html',
    description: '\n                '
  },
  '1229': {
    name: 'Creation of Emergent Resource',
    link: 'https://cwe.mitre.org/data/definitions/1229.html',
    description: '\n                '
  },
  '123': {
    name: 'Write-what-where Condition',
    link: 'https://cwe.mitre.org/data/definitions/123.html',
    description:
      'Any condition where the attacker has the ability to write an arbitrary value to an arbitrary location, often as the result of a buffer overflow.'
  },
  '1230': {
    name: 'Exposure of Sensitive Information Through Metadata',
    link: 'https://cwe.mitre.org/data/definitions/1230.html',
    description: '\n\t\t\t  '
  },
  '1231': {
    name: 'Improper Prevention of Lock Bit Modification',
    link: 'https://cwe.mitre.org/data/definitions/1231.html',
    description: '\n\t\t\t  '
  },
  '1232': {
    name: 'Improper Lock Behavior After Power State Transition',
    link: 'https://cwe.mitre.org/data/definitions/1232.html',
    description: '\n        '
  },
  '1233': {
    name: 'Security-Sensitive Hardware Controls with Missing Lock Bit Protection',
    link: 'https://cwe.mitre.org/data/definitions/1233.html',
    description: '\n         \t'
  },
  '1234': {
    name: 'Hardware Internal or Debug Modes Allow Override of Locks',
    link: 'https://cwe.mitre.org/data/definitions/1234.html',
    description: '\n     \t'
  },
  '1235': {
    name: 'Incorrect Use of Autoboxing and Unboxing for Performance Critical Operations',
    link: 'https://cwe.mitre.org/data/definitions/1235.html',
    description: '\n            '
  },
  '1236': {
    name: 'Improper Neutralization of Formula Elements in a CSV File',
    link: 'https://cwe.mitre.org/data/definitions/1236.html',
    description:
      "User-provided data is often saved to traditional databases.  This data can be exported to a CSV file, which allows users to read the data using spreadsheet software such as Excel, Numbers, or Calc.  This software interprets entries beginning with '=' as formulas, which are then executed by the spreadsheet software.  The software's formula language often allows methods to access hyperlinks or the local command line, and frequently allows enough characters to invoke an entire script. Attackers can populate data fields which, when saved to a CSV file, may attempt information exfiltration or other malicious activity when automatically executed by the spreadsheet software."
  },
  '1239': {
    name: 'Improper Zeroization of Hardware Register',
    link: 'https://cwe.mitre.org/data/definitions/1239.html',
    description:
      'Hardware logic operates on data stored in registers local to the hardware block. Most hardware IPs, including cryptographic accelerators, rely on registers to buffer I/O, store intermediate values, and interface with software. The result of this is that sensitive information, such as passwords or encryption keys, can exist in locations not transparent to the user of the hardware logic. When a different entity obtains access to the IP due to a change in operating mode or conditions, the new entity can extract information belonging to the previous user if no mechanisms are in place to clear register contents. It is important to clear information stored in the hardware if a physical attack on the product is detected, or if the user of the hardware block changes. The process of clearing register contents in a hardware IP is referred to as zeroization in standards for cryptographic hardware modules such as FIPS-140-2 [REF-267].'
  },
  '124': {
    name: "Buffer Underwrite ('Buffer Underflow')",
    link: 'https://cwe.mitre.org/data/definitions/124.html',
    description:
      'This typically occurs when a pointer or its index is decremented to a position before the buffer, when pointer arithmetic results in a position before the beginning of the valid memory location, or when a negative index is used.'
  },
  '1240': {
    name: 'Use of a Cryptographic Primitive with a Risky Implementation',
    link: 'https://cwe.mitre.org/data/definitions/1240.html',
    description: '\n\t      '
  },
  '1241': {
    name: 'Use of Predictable Algorithm in Random Number Generator',
    link: 'https://cwe.mitre.org/data/definitions/1241.html',
    description: '\n\t\t\t\t'
  },
  '1242': {
    name: 'Inclusion of Undocumented Features or Chicken Bits',
    link: 'https://cwe.mitre.org/data/definitions/1242.html',
    description: '\n                '
  },
  '1243': {
    name: 'Sensitive Non-Volatile Information Not Protected During Debug',
    link: 'https://cwe.mitre.org/data/definitions/1243.html',
    description: '\n                '
  },
  '1244': {
    name: 'Internal Asset Exposed to Unsafe Debug Access Level or State',
    link: 'https://cwe.mitre.org/data/definitions/1244.html',
    description: '\n\t  '
  },
  '1245': {
    name: 'Improper Finite State Machines (FSMs) in Hardware Logic',
    link: 'https://cwe.mitre.org/data/definitions/1245.html',
    description: '\n                '
  },
  '1246': {
    name: 'Improper Write Handling in Limited-write Non-Volatile Memories',
    link: 'https://cwe.mitre.org/data/definitions/1246.html',
    description: '\n                '
  },
  '1247': {
    name: 'Improper Protection Against Voltage and Clock Glitches',
    link: 'https://cwe.mitre.org/data/definitions/1247.html',
    description: '\n        '
  },
  '1248': {
    name: 'Semiconductor Defects in Hardware Logic with Security-Sensitive Implications',
    link: 'https://cwe.mitre.org/data/definitions/1248.html',
    description: '\n                '
  },
  '1249': {
    name: 'Application-Level Admin Tool with Inconsistent View of Underlying Operating System',
    link: 'https://cwe.mitre.org/data/definitions/1249.html',
    description: '\n                '
  },
  '125': {
    name: 'Out-of-bounds Read',
    link: 'https://cwe.mitre.org/data/definitions/125.html',
    description:
      'Typically, this can allow attackers to read sensitive information from other memory locations or cause a crash.  A crash can occur when the code reads a variable amount of data and assumes that a sentinel exists to stop the read operation, such as a NUL in a string.  The expected sentinel might not be located in the out-of-bounds memory, causing excessive data to be read, leading to a segmentation fault or a buffer overflow.  The product may modify an index or perform pointer arithmetic that references a memory location that is outside of the boundaries of the buffer.  A subsequent read operation then produces undefined or unexpected results.'
  },
  '1250': {
    name: 'Improper Preservation of Consistency Between Independent Representations of Shared State',
    link: 'https://cwe.mitre.org/data/definitions/1250.html',
    description: '\n                '
  },
  '1251': {
    name: 'Mirrored Regions with Different Values',
    link: 'https://cwe.mitre.org/data/definitions/1251.html',
    description: '\n\t\t\t\t'
  },
  '1252': {
    name: 'CPU Hardware Not Configured to Support Exclusivity of Write and Execute Operations',
    link: 'https://cwe.mitre.org/data/definitions/1252.html',
    description: '\n                '
  },
  '1253': {
    name: 'Incorrect Selection of Fuse Values',
    link: 'https://cwe.mitre.org/data/definitions/1253.html',
    description: '\n      '
  },
  '1254': {
    name: 'Incorrect Comparison Logic Granularity',
    link: 'https://cwe.mitre.org/data/definitions/1254.html',
    description: '\n            '
  },
  '1255': {
    name: 'Comparison Logic is Vulnerable to Power Side-Channel Attacks',
    link: 'https://cwe.mitre.org/data/definitions/1255.html',
    description: '\n        '
  },
  '1256': {
    name: 'Improper Restriction of Software Interfaces to Hardware Features',
    link: 'https://cwe.mitre.org/data/definitions/1256.html',
    description: '\n              '
  },
  '1257': {
    name: 'Improper Access Control Applied to Mirrored or Aliased Memory Regions',
    link: 'https://cwe.mitre.org/data/definitions/1257.html',
    description: '\n\t\t\t\t'
  },
  '1258': {
    name: 'Exposure of Sensitive System Information Due to Uncleared Debug Information',
    link: 'https://cwe.mitre.org/data/definitions/1258.html',
    description: '\n            '
  },
  '1259': {
    name: 'Improper Restriction of Security Token Assignment',
    link: 'https://cwe.mitre.org/data/definitions/1259.html',
    description:
      "Systems-On-A-Chip (Integrated circuits and hardware engines) implement Security Tokens to differentiate and identify which actions originated from which agent. These actions may be one of the directives: 'read', 'write', 'program', 'reset', 'fetch', 'compute', etc. Security Tokens are assigned to every agent in the System that is capable of generating an action or receiving an action from another agent. Multiple Security Tokens may be assigned to an agent and may be unique based on the agent's trust level or allowed privileges. Since the Security Tokens are integral for the maintenance of security in an SoC, they need to be protected properly. A common weakness afflicting Security Tokens is improperly restricting the assignment to trusted components. Consequently, an improperly protected Security Token may be able to be programmed by a malicious agent (i.e., the Security Token is mutable) to spoof the action as if it originated from a trusted agent.\n\t\t\t"
  },
  '126': {
    name: 'Buffer Over-read',
    link: 'https://cwe.mitre.org/data/definitions/126.html',
    description:
      'This typically occurs when the pointer or its index is incremented to a position beyond the bounds of the buffer or when pointer arithmetic results in a position outside of the valid memory location to name a few. This may result in exposure of sensitive information or possibly a crash.'
  },
  '1260': {
    name: 'Improper Handling of Overlap Between Protected Memory Ranges',
    link: 'https://cwe.mitre.org/data/definitions/1260.html',
    description: '\n            '
  },
  '1261': {
    name: 'Improper Handling of Single Event Upsets',
    link: 'https://cwe.mitre.org/data/definitions/1261.html',
    description: '\n            '
  },
  '1262': {
    name: 'Improper Access Control for Register Interface',
    link: 'https://cwe.mitre.org/data/definitions/1262.html',
    description: '\n\t\t\t\t'
  },
  '1263': {
    name: 'Improper Physical Access Control',
    link: 'https://cwe.mitre.org/data/definitions/1263.html',
    description:
      'Sections of a product intended to have restricted access may be inadvertently or intentionally rendered accessible when the implemented physical protections are insufficient. The specific requirements around how robust the design of the physical protection mechanism needs to be depends on the type of product being protected. Selecting the correct physical protection mechanism and properly enforcing it through implementation and manufacturing are critical to the overall physical security of the product.\n\t\t\t'
  },
  '1264': {
    name: 'Hardware Logic with Insecure De-Synchronization between Control and Data Channels',
    link: 'https://cwe.mitre.org/data/definitions/1264.html',
    description: '\n            '
  },
  '1265': {
    name: 'Unintended Reentrant Invocation of Non-reentrant Code Via Nested Calls',
    link: 'https://cwe.mitre.org/data/definitions/1265.html',
    description:
      'In a complex product, a single function call may lead to many different possible code paths, some of which may involve deeply nested calls. It may be difficult to foresee all possible code paths that could emanate from a given function call. In some systems, an external actor can manipulate inputs to the system and thereby achieve a wide range of possible control flows. This is frequently a concern in products that execute scripts from untrusted sources. Examples of such products are web browsers and PDF readers. A weakness is present when one of the possible code paths resulting from a function call alters program state that the original caller assumes to be unchanged during the call.'
  },
  '1266': {
    name: 'Improper Scrubbing of Sensitive Data from Decommissioned Device',
    link: 'https://cwe.mitre.org/data/definitions/1266.html',
    description: '\n            '
  },
  '1267': {
    name: 'Policy Uses Obsolete Encoding',
    link: 'https://cwe.mitre.org/data/definitions/1267.html',
    description: '\n    '
  },
  '1268': {
    name: 'Policy Privileges are not Assigned Consistently Between Control and Data Agents',
    link: 'https://cwe.mitre.org/data/definitions/1268.html',
    description: '\n\t\t\t\t'
  },
  '1269': {
    name: 'Product Released in Non-Release Configuration',
    link: 'https://cwe.mitre.org/data/definitions/1269.html',
    description: '\n\t\t\t\t'
  },
  '127': {
    name: 'Buffer Under-read',
    link: 'https://cwe.mitre.org/data/definitions/127.html',
    description:
      'This typically occurs when the pointer or its index is decremented to a position before the buffer, when pointer arithmetic results in a position before the beginning of the valid memory location, or when a negative index is used. This may result in exposure of sensitive information or possibly a crash.'
  },
  '1270': {
    name: 'Generation of Incorrect Security Tokens',
    link: 'https://cwe.mitre.org/data/definitions/1270.html',
    description: '\n\t\t\t\t'
  },
  '1271': {
    name: 'Uninitialized Value on Reset for Registers Holding Security Settings',
    link: 'https://cwe.mitre.org/data/definitions/1271.html',
    description: '\n\t\t\t\t'
  },
  '1272': {
    name: 'Sensitive Information Uncleared Before Debug/Power State Transition',
    link: 'https://cwe.mitre.org/data/definitions/1272.html',
    description: '\n\t\t\t\t'
  },
  '1273': {
    name: 'Device Unlock Credential Sharing',
    link: 'https://cwe.mitre.org/data/definitions/1273.html',
    description: '\n\t\t\t\t'
  },
  '1274': {
    name: 'Improper Access Control for Volatile Memory Containing Boot Code',
    link: 'https://cwe.mitre.org/data/definitions/1274.html',
    description: '\n\t\t\t  '
  },
  '1275': {
    name: 'Sensitive Cookie with Improper SameSite Attribute',
    link: 'https://cwe.mitre.org/data/definitions/1275.html',
    description:
      "The SameSite attribute controls how cookies are sent for cross-domain requests. This attribute may have three values: 'Lax', 'Strict', or 'None'. If the 'None' value is used, a website may create a cross-domain POST HTTP request to another website, and the browser automatically adds cookies to this request. This may lead to Cross-Site-Request-Forgery (CSRF) attacks if there are no additional protections in place (such as Anti-CSRF tokens)."
  },
  '1276': {
    name: 'Hardware Child Block Incorrectly Connected to Parent System',
    link: 'https://cwe.mitre.org/data/definitions/1276.html',
    description: '\n\t\t\t\t'
  },
  '1277': {
    name: 'Firmware Not Updateable',
    link: 'https://cwe.mitre.org/data/definitions/1277.html',
    description:
      'Without the ability to\n\t\t\tpatch or update firmware, consumers will be\n\t\t\tleft vulnerable to exploitation of any known\n\t\t\tvulnerabilities, or any vulnerabilities that\n\t\t\tare discovered in the future. This can expose\n\t\t\tconsumers to permanent risk throughout the\n\t\t\tentire lifetime of the device, which could be\n\t\t\tyears or decades. Some external protective\n\t\t\tmeasures and mitigations might be employed to\n\t\t\taid in preventing or reducing the risk of\n\t\t\tmalicious attack, but the root weakness cannot\n\t\t\tbe corrected.'
  },
  '1278': {
    name: 'Missing Protection Against Hardware Reverse Engineering Using Integrated Circuit (IC) Imaging Techniques',
    link: 'https://cwe.mitre.org/data/definitions/1278.html',
    description: '\n\t\t\t\t'
  },
  '1279': {
    name: 'Cryptographic Operations are run Before Supporting Units are Ready',
    link: 'https://cwe.mitre.org/data/definitions/1279.html',
    description:
      'Many cryptographic hardware units depend upon other hardware units to supply information to them to produce a securely encrypted result. For example, a cryptographic unit that depends on an external random-number-generator (RNG) unit for entropy must wait until the RNG unit is producing random numbers. If a cryptographic unit retrieves a private encryption key from a fuse unit, the fuse unit must be up and running before a key may be supplied.\n\t\t\t'
  },
  '128': {
    name: 'Wrap-around Error',
    link: 'https://cwe.mitre.org/data/definitions/128.html',
    description:
      'Wrap around errors occur whenever a value is incremented past the maximum value for its type and therefore "wraps around" to a very small, negative, or undefined value.'
  },
  '1280': {
    name: 'Access Control Check Implemented After Asset is Accessed',
    link: 'https://cwe.mitre.org/data/definitions/1280.html',
    description: '\n\t\t\t\t'
  },
  '1281': {
    name: 'Sequence of Processor Instructions Leads to Unexpected Behavior',
    link: 'https://cwe.mitre.org/data/definitions/1281.html',
    description: '\n\t\t\t\t'
  },
  '1282': {
    name: 'Assumed-Immutable Data is Stored in Writable Memory',
    link: 'https://cwe.mitre.org/data/definitions/1282.html',
    description: '\n\t\t\t\t'
  },
  '1283': {
    name: 'Mutable Attestation or Measurement Reporting Data',
    link: 'https://cwe.mitre.org/data/definitions/1283.html',
    description: '\n            '
  },
  '1284': {
    name: 'Improper Validation of Specified Quantity in Input',
    link: 'https://cwe.mitre.org/data/definitions/1284.html',
    description: '\n\t\t\t\t'
  },
  '1285': {
    name: 'Improper Validation of Specified Index, Position, or Offset in Input',
    link: 'https://cwe.mitre.org/data/definitions/1285.html',
    description: '\n\t\t\t\t'
  },
  '1286': {
    name: 'Improper Validation of Syntactic Correctness of Input',
    link: 'https://cwe.mitre.org/data/definitions/1286.html',
    description: '\n\t\t\t\t'
  },
  '1287': {
    name: 'Improper Validation of Specified Type of Input',
    link: 'https://cwe.mitre.org/data/definitions/1287.html',
    description: '\n\t\t\t\t'
  },
  '1288': {
    name: 'Improper Validation of Consistency within Input',
    link: 'https://cwe.mitre.org/data/definitions/1288.html',
    description: '\n\t\t\t\t'
  },
  '1289': {
    name: 'Improper Validation of Unsafe Equivalence in Input',
    link: 'https://cwe.mitre.org/data/definitions/1289.html',
    description: '\n\t\t\t  '
  },
  '129': {
    name: 'Improper Validation of Array Index',
    link: 'https://cwe.mitre.org/data/definitions/129.html',
    description:
      'The product uses untrusted input when calculating or using an array index, but the product does not validate or incorrectly validates the index to ensure the index references a valid position within the array.'
  },
  '1290': {
    name: 'Incorrect Decoding of Security Identifiers ',
    link: 'https://cwe.mitre.org/data/definitions/1290.html',
    description: '\n\t\t\t\t'
  },
  '1291': {
    name: 'Public Key Re-Use for Signing both Debug and Production Code',
    link: 'https://cwe.mitre.org/data/definitions/1291.html',
    description: '\n\t\t\t\t'
  },
  '1292': {
    name: 'Incorrect Conversion of Security Identifiers',
    link: 'https://cwe.mitre.org/data/definitions/1292.html',
    description: '\n\t\t\t\t'
  },
  '1293': {
    name: 'Missing Source Correlation of Multiple Independent Data',
    link: 'https://cwe.mitre.org/data/definitions/1293.html',
    description: '\n\t\t\t\t'
  },
  '1294': {
    name: 'Insecure Security Identifier Mechanism',
    link: 'https://cwe.mitre.org/data/definitions/1294.html',
    description: '\n                '
  },
  '1295': {
    name: 'Debug Messages Revealing Unnecessary Information',
    link: 'https://cwe.mitre.org/data/definitions/1295.html',
    description: '\n\t\t\t\t'
  },
  '1296': {
    name: 'Incorrect Chaining or Granularity of Debug Components',
    link: 'https://cwe.mitre.org/data/definitions/1296.html',
    description: '\n\t\t\t\t'
  },
  '1297': {
    name: 'Unprotected Confidential Information on Device is Accessible by OSAT Vendors',
    link: 'https://cwe.mitre.org/data/definitions/1297.html',
    description: '\n\t\t\t\t'
  },
  '1298': {
    name: 'Hardware Logic Contains Race Conditions',
    link: 'https://cwe.mitre.org/data/definitions/1298.html',
    description: '\n\t\t\t\t'
  },
  '1299': {
    name: 'Missing Protection Mechanism for Alternate Hardware Interface',
    link: 'https://cwe.mitre.org/data/definitions/1299.html',
    description: '\n                '
  },
  '13': {
    name: 'ASP.NET Misconfiguration: Password in Configuration File',
    link: 'https://cwe.mitre.org/data/definitions/13.html',
    description:
      'Storing a plaintext password in a configuration file allows anyone who can read the file access to the password-protected resource making them an easy target for attackers.'
  },
  '130': {
    name: 'Improper Handling of Length Parameter Inconsistency',
    link: 'https://cwe.mitre.org/data/definitions/130.html',
    description:
      'If an attacker can manipulate the length parameter associated with an input such that it is inconsistent with the actual length of the input, this can be leveraged to cause the target application to behave in unexpected, and possibly, malicious ways. One of the possible motives for doing so is to pass in arbitrarily large input to the application. Another possible motivation is the modification of application state by including invalid data for subsequent properties of the application. Such weaknesses commonly lead to attacks such as buffer overflows and execution of arbitrary code.'
  },
  '1300': {
    name: 'Improper Protection of Physical Side Channels',
    link: 'https://cwe.mitre.org/data/definitions/1300.html',
    description: '\n\t  '
  },
  '1301': {
    name: 'Insufficient or Incomplete Data Removal within Hardware Component',
    link: 'https://cwe.mitre.org/data/definitions/1301.html',
    description: '\n\t\t\t\t'
  },
  '1302': {
    name: 'Missing Security Identifier',
    link: 'https://cwe.mitre.org/data/definitions/1302.html',
    description: '\n\t\t\t\t'
  },
  '1303': {
    name: 'Non-Transparent Sharing of Microarchitectural Resources',
    link: 'https://cwe.mitre.org/data/definitions/1303.html',
    description: '\n\t\t\t\t'
  },
  '1304': {
    name: 'Improperly Preserved Integrity of Hardware Configuration State During a Power Save/Restore Operation',
    link: 'https://cwe.mitre.org/data/definitions/1304.html',
    description: '\n                '
  },
  '131': {
    name: 'Incorrect Calculation of Buffer Size',
    link: 'https://cwe.mitre.org/data/definitions/131.html',
    description:
      'The product does not correctly calculate the size to be used when allocating a buffer, which could lead to a buffer overflow.'
  },
  '1310': {
    name: 'Missing Ability to Patch ROM Code',
    link: 'https://cwe.mitre.org/data/definitions/1310.html',
    description: '\n\t\t\t\t'
  },
  '1311': {
    name: 'Improper Translation of Security Attributes by Fabric Bridge',
    link: 'https://cwe.mitre.org/data/definitions/1311.html',
    description: '\n\t\t\t\t'
  },
  '1312': {
    name: 'Missing Protection for Mirrored Regions in On-Chip Fabric Firewall',
    link: 'https://cwe.mitre.org/data/definitions/1312.html',
    description: '\n\t\t\t\t'
  },
  '1313': {
    name: 'Hardware Allows Activation of Test or Debug Logic at Runtime',
    link: 'https://cwe.mitre.org/data/definitions/1313.html',
    description: '\n\t\t\t\t'
  },
  '1314': {
    name: 'Missing Write Protection for Parametric Data Values',
    link: 'https://cwe.mitre.org/data/definitions/1314.html',
    description: '\n\t\t\t\t'
  },
  '1315': {
    name: 'Improper Setting of Bus Controlling Capability in Fabric End-point',
    link: 'https://cwe.mitre.org/data/definitions/1315.html',
    description: '\n\t\t\t\t'
  },
  '1316': {
    name: 'Fabric-Address Map Allows Programming of Unwarranted Overlaps of Protected and Unprotected Ranges',
    link: 'https://cwe.mitre.org/data/definitions/1316.html',
    description: '\n\t\t\t\t'
  },
  '1317': {
    name: 'Improper Access Control in Fabric Bridge',
    link: 'https://cwe.mitre.org/data/definitions/1317.html',
    description: '\n\t\t\t\t'
  },
  '1318': {
    name: 'Missing Support for Security Features in On-chip Fabrics or Buses',
    link: 'https://cwe.mitre.org/data/definitions/1318.html',
    description: '\n\t\t\t\t'
  },
  '1319': {
    name: 'Improper Protection against Electromagnetic Fault Injection (EM-FI)',
    link: 'https://cwe.mitre.org/data/definitions/1319.html',
    description: '\n\t\t\t\t'
  },
  '132': {
    name: 'DEPRECATED: Miscalculated Null Termination',
    link: 'https://cwe.mitre.org/data/definitions/132.html',
    description:
      'This entry has been deprecated because it was a duplicate of CWE-170. All content has been transferred to CWE-170.'
  },
  '1320': {
    name: 'Improper Protection for Outbound Error Messages and Alert Signals',
    link: 'https://cwe.mitre.org/data/definitions/1320.html',
    description: '\n\t\t\t\t'
  },
  '1321': {
    name: "Improperly Controlled Modification of Object Prototype Attributes ('Prototype Pollution')",
    link: 'https://cwe.mitre.org/data/definitions/1321.html',
    description: '\n\t\t\t\t'
  },
  '1322': {
    name: 'Use of Blocking Code in Single-threaded, Non-blocking Context',
    link: 'https://cwe.mitre.org/data/definitions/1322.html',
    description: '\n\t\t\t\t'
  },
  '1323': {
    name: 'Improper Management of Sensitive Trace Data',
    link: 'https://cwe.mitre.org/data/definitions/1323.html',
    description: '\n                '
  },
  '1324': {
    name: 'DEPRECATED: Sensitive Information Accessible by Physical Probing of JTAG Interface',
    link: 'https://cwe.mitre.org/data/definitions/1324.html',
    description:
      'This entry has been deprecated because it was at a lower level of abstraction than supported by CWE. All relevant content has been integrated into CWE-319.'
  },
  '1325': {
    name: 'Improperly Controlled Sequential Memory Allocation',
    link: 'https://cwe.mitre.org/data/definitions/1325.html',
    description: '\n\t\t\t  '
  },
  '1326': {
    name: 'Missing Immutable Root of Trust in Hardware',
    link: 'https://cwe.mitre.org/data/definitions/1326.html',
    description: '\n\t\t\t\t'
  },
  '1327': {
    name: 'Binding to an Unrestricted IP Address',
    link: 'https://cwe.mitre.org/data/definitions/1327.html',
    description: '\n\t\t\t\t'
  },
  '1328': {
    name: 'Security Version Number Mutable to Older Versions',
    link: 'https://cwe.mitre.org/data/definitions/1328.html',
    description: '\n\t\t\t\t'
  },
  '1329': {
    name: 'Reliance on Component That is Not Updateable',
    link: 'https://cwe.mitre.org/data/definitions/1329.html',
    description: '\n\t\t\t  '
  },
  '1330': {
    name: 'Remanent Data Readable after Memory Erase',
    link: 'https://cwe.mitre.org/data/definitions/1330.html',
    description: '\n\t\t\t\t'
  },
  '1331': {
    name: 'Improper Isolation of Shared Resources in Network On Chip (NoC)',
    link: 'https://cwe.mitre.org/data/definitions/1331.html',
    description: '\n\t\t\t  '
  },
  '1332': {
    name: 'Improper Handling of Faults that Lead to Instruction Skips',
    link: 'https://cwe.mitre.org/data/definitions/1332.html',
    description: '\n              '
  },
  '1333': {
    name: 'Inefficient Regular Expression Complexity',
    link: 'https://cwe.mitre.org/data/definitions/1333.html',
    description:
      'Some regular expression engines have a feature called "backtracking". If the token cannot match, the engine "backtracks" to a position that may result in a different token that can match.'
  },
  '1334': {
    name: 'Unauthorized Error Injection Can Degrade Hardware Redundancy',
    link: 'https://cwe.mitre.org/data/definitions/1334.html',
    description: '\n\t\t\t\t'
  },
  '1335': {
    name: 'Incorrect Bitwise Shift of Integer',
    link: 'https://cwe.mitre.org/data/definitions/1335.html',
    description: '\n\t\t\t\t'
  },
  '1336': {
    name: 'Improper Neutralization of Special Elements Used in a Template Engine',
    link: 'https://cwe.mitre.org/data/definitions/1336.html',
    description: '\n\t\t\t\t'
  },
  '1338': {
    name: 'Improper Protections Against Hardware Overheating',
    link: 'https://cwe.mitre.org/data/definitions/1338.html',
    description: '\n\t\t\t\t'
  },
  '1339': {
    name: 'Insufficient Precision or Accuracy of a Real Number',
    link: 'https://cwe.mitre.org/data/definitions/1339.html',
    description:
      "The product processes a real number with an implementation in which the number's representation does not preserve required accuracy and precision in its fractional part, causing an incorrect result."
  },
  '134': {
    name: 'Use of Externally-Controlled Format String',
    link: 'https://cwe.mitre.org/data/definitions/134.html',
    description: '\n            '
  },
  '1341': {
    name: 'Multiple Releases of Same Resource or Handle',
    link: 'https://cwe.mitre.org/data/definitions/1341.html',
    description: '\n\t\t\t  '
  },
  '1342': {
    name: 'Information Exposure through Microarchitectural State after Transient Execution',
    link: 'https://cwe.mitre.org/data/definitions/1342.html',
    description: '\n\t\t\t\t'
  },
  '135': {
    name: 'Incorrect Calculation of Multi-Byte String Length',
    link: 'https://cwe.mitre.org/data/definitions/135.html',
    description:
      'The product does not correctly calculate the length of strings that can contain wide or multi-byte characters.'
  },
  '1351': {
    name: 'Improper Handling of Hardware Behavior in Exceptionally Cold Environments',
    link: 'https://cwe.mitre.org/data/definitions/1351.html',
    description: '\n                '
  },
  '1357': {
    name: 'Reliance on Insufficiently Trustworthy Component',
    link: 'https://cwe.mitre.org/data/definitions/1357.html',
    description: '\n\t\t'
  },
  '138': {
    name: 'Improper Neutralization of Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/138.html',
    description:
      'Most languages and protocols have their own special elements such as characters and reserved words. These special elements can carry control implications. If product does not prevent external control or influence over the inclusion of such special elements, the control flow of the program may be altered from what was intended. For example, both Unix and Windows interpret the symbol < ("less than") as meaning "read input from a file".'
  },
  '1384': {
    name: 'Improper Handling of Physical or Environmental Conditions',
    link: 'https://cwe.mitre.org/data/definitions/1384.html',
    description: '\n      '
  },
  '1385': {
    name: 'Missing Origin Validation in WebSockets',
    link: 'https://cwe.mitre.org/data/definitions/1385.html',
    description: '\n\t'
  },
  '1386': {
    name: 'Insecure Operation on Windows Junction / Mount Point',
    link: 'https://cwe.mitre.org/data/definitions/1386.html',
    description: '\n\t\t\t  '
  },
  '1389': {
    name: 'Incorrect Parsing of Numbers with Different Radices',
    link: 'https://cwe.mitre.org/data/definitions/1389.html',
    description: '\n\t\t\t\t'
  },
  '1390': {
    name: 'Weak Authentication',
    link: 'https://cwe.mitre.org/data/definitions/1390.html',
    description: '\n\t'
  },
  '1391': {
    name: 'Use of Weak Credentials',
    link: 'https://cwe.mitre.org/data/definitions/1391.html',
    description: '\n\t'
  },
  '1392': {
    name: 'Use of Default Credentials',
    link: 'https://cwe.mitre.org/data/definitions/1392.html',
    description:
      "\n\tIt is common practice for products to be designed to use\n\tdefault keys, passwords, or other mechanisms for\n\tauthentication.  The rationale is to simplify the\n\tmanufacturing process or the system administrator's task of\n\tinstallation and deployment into an enterprise. However, if\n\tadmins do not change the defaults, it is easier for attackers\n\tto bypass authentication quickly across multiple\n\torganizations.\n      "
  },
  '1393': {
    name: 'Use of Default Password',
    link: 'https://cwe.mitre.org/data/definitions/1393.html',
    description:
      "\n\tIt is common practice for products to be designed to use\n\tdefault passwords for authentication.  The rationale is to\n\tsimplify the manufacturing process or the system\n\tadministrator's task of installation and deployment into an\n\tenterprise. However, if admins do not change the defaults,\n\tthen it makes it easier for attackers to quickly bypass\n\tauthentication across multiple organizations. There are many\n\tlists of default passwords and default-password scanning tools\n\tthat are easily available from the World Wide Web.\n      "
  },
  '1394': {
    name: 'Use of Default Cryptographic Key',
    link: 'https://cwe.mitre.org/data/definitions/1394.html',
    description:
      "\n\tIt is common practice for products to be designed to use\n\tdefault keys.  The rationale is to simplify the manufacturing\n\tprocess or the system administrator's task of installation and\n\tdeployment into an enterprise. However, if admins do not\n\tchange the defaults, it is easier for attackers to bypass\n\tauthentication quickly across multiple organizations.\n      "
  },
  '1395': {
    name: 'Dependency on Vulnerable Third-Party Component',
    link: 'https://cwe.mitre.org/data/definitions/1395.html',
    description: '\n\t      '
  },
  '14': {
    name: 'Compiler Removal of Code to Clear Buffers',
    link: 'https://cwe.mitre.org/data/definitions/14.html',
    description: '\n            '
  },
  '140': {
    name: 'Improper Neutralization of Delimiters',
    link: 'https://cwe.mitre.org/data/definitions/140.html',
    description: 'The product does not neutralize or incorrectly neutralizes delimiters.'
  },
  '141': {
    name: 'Improper Neutralization of Parameter/Argument Delimiters',
    link: 'https://cwe.mitre.org/data/definitions/141.html',
    description:
      'As data is parsed, an injected/absent/malformed delimiter may cause the process to take unexpected actions.'
  },
  '1419': {
    name: 'Incorrect Initialization of Resource',
    link: 'https://cwe.mitre.org/data/definitions/1419.html',
    description: '\n\t\t\t\t'
  },
  '142': {
    name: 'Improper Neutralization of Value Delimiters',
    link: 'https://cwe.mitre.org/data/definitions/142.html',
    description:
      'As data is parsed, an injected/absent/malformed delimiter may cause the process to take unexpected actions.'
  },
  '143': {
    name: 'Improper Neutralization of Record Delimiters',
    link: 'https://cwe.mitre.org/data/definitions/143.html',
    description:
      'As data is parsed, an injected/absent/malformed delimiter may cause the process to take unexpected actions.'
  },
  '144': {
    name: 'Improper Neutralization of Line Delimiters',
    link: 'https://cwe.mitre.org/data/definitions/144.html',
    description:
      'As data is parsed, an injected/absent/malformed delimiter may cause the process to take unexpected actions.'
  },
  '145': {
    name: 'Improper Neutralization of Section Delimiters',
    link: 'https://cwe.mitre.org/data/definitions/145.html',
    description: '\n            '
  },
  '146': {
    name: 'Improper Neutralization of Expression/Command Delimiters',
    link: 'https://cwe.mitre.org/data/definitions/146.html',
    description:
      'As data is parsed, an injected/absent/malformed delimiter may cause the process to take unexpected actions.'
  },
  '147': {
    name: 'Improper Neutralization of Input Terminators',
    link: 'https://cwe.mitre.org/data/definitions/147.html',
    description:
      'For example, a "." in SMTP signifies the end of mail message data, whereas a null character can be used for the end of a string.'
  },
  '148': {
    name: 'Improper Neutralization of Input Leaders',
    link: 'https://cwe.mitre.org/data/definitions/148.html',
    description:
      'The product does not properly handle when a leading character or sequence ("leader") is missing or malformed, or if multiple leaders are used when only one should be allowed.'
  },
  '149': {
    name: 'Improper Neutralization of Quoting Syntax',
    link: 'https://cwe.mitre.org/data/definitions/149.html',
    description:
      'Quotes injected into a product can be used to compromise a system. As data are parsed, an injected/absent/duplicate/malformed use of quotes may cause the process to take unexpected actions.'
  },
  '15': {
    name: 'External Control of System or Configuration Setting',
    link: 'https://cwe.mitre.org/data/definitions/15.html',
    description:
      'Allowing external control of system settings can disrupt service or cause an application to behave in unexpected, and potentially malicious ways.'
  },
  '150': {
    name: 'Improper Neutralization of Escape, Meta, or Control Sequences',
    link: 'https://cwe.mitre.org/data/definitions/150.html',
    description:
      'As data is parsed, an injected/absent/malformed delimiter may cause the process to take unexpected actions.'
  },
  '151': {
    name: 'Improper Neutralization of Comment Delimiters',
    link: 'https://cwe.mitre.org/data/definitions/151.html',
    description:
      'The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as comment delimiters when they are sent to a downstream component.'
  },
  '152': {
    name: 'Improper Neutralization of Macro Symbols',
    link: 'https://cwe.mitre.org/data/definitions/152.html',
    description:
      'The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as macro symbols when they are sent to a downstream component.'
  },
  '153': {
    name: 'Improper Neutralization of Substitution Characters',
    link: 'https://cwe.mitre.org/data/definitions/153.html',
    description:
      'The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as substitution characters when they are sent to a downstream component.'
  },
  '154': {
    name: 'Improper Neutralization of Variable Name Delimiters',
    link: 'https://cwe.mitre.org/data/definitions/154.html',
    description:
      'As data is parsed, an injected delimiter may cause the process to take unexpected actions that result in an attack. Example: "$" for an environment variable.'
  },
  '155': {
    name: 'Improper Neutralization of Wildcards or Matching Symbols',
    link: 'https://cwe.mitre.org/data/definitions/155.html',
    description: 'As data is parsed, an injected element may cause the process to take unexpected actions.'
  },
  '156': {
    name: 'Improper Neutralization of Whitespace',
    link: 'https://cwe.mitre.org/data/definitions/156.html',
    description: 'This can include space, tab, etc.'
  },
  '157': {
    name: 'Failure to Sanitize Paired Delimiters',
    link: 'https://cwe.mitre.org/data/definitions/157.html',
    description: '\n            '
  },
  '158': {
    name: 'Improper Neutralization of Null Byte or NUL Character',
    link: 'https://cwe.mitre.org/data/definitions/158.html',
    description:
      'As data is parsed, an injected NUL character or null byte may cause the product to believe the input is terminated earlier than it actually is, or otherwise cause the input to be misinterpreted. This could then be used to inject potentially dangerous input that occurs after the null byte or otherwise bypass validation routines and other protection mechanisms.'
  },
  '159': {
    name: 'Improper Handling of Invalid Use of Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/159.html',
    description:
      'The product does not properly filter, remove, quote, or otherwise manage the invalid use of special elements in user-controlled input, which could cause adverse effect on its behavior and integrity.'
  },
  '160': {
    name: 'Improper Neutralization of Leading Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/160.html',
    description:
      'As data is parsed, improperly handled leading special elements may cause the process to take unexpected actions that result in an attack.'
  },
  '161': {
    name: 'Improper Neutralization of Multiple Leading Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/161.html',
    description:
      'As data is parsed, improperly handled multiple leading special elements may cause the process to take unexpected actions that result in an attack.'
  },
  '162': {
    name: 'Improper Neutralization of Trailing Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/162.html',
    description:
      'As data is parsed, improperly handled trailing special elements may cause the process to take unexpected actions that result in an attack.'
  },
  '163': {
    name: 'Improper Neutralization of Multiple Trailing Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/163.html',
    description:
      'As data is parsed, improperly handled multiple trailing special elements may cause the process to take unexpected actions that result in an attack.'
  },
  '164': {
    name: 'Improper Neutralization of Internal Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/164.html',
    description:
      'As data is parsed, improperly handled internal special elements may cause the process to take unexpected actions that result in an attack.'
  },
  '165': {
    name: 'Improper Neutralization of Multiple Internal Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/165.html',
    description:
      'As data is parsed, improperly handled multiple internal special elements may cause the process to take unexpected actions that result in an attack.'
  },
  '166': {
    name: 'Improper Handling of Missing Special Element',
    link: 'https://cwe.mitre.org/data/definitions/166.html',
    description:
      'The product receives input from an upstream component, but it does not handle or incorrectly handles when an expected special element is missing.'
  },
  '167': {
    name: 'Improper Handling of Additional Special Element',
    link: 'https://cwe.mitre.org/data/definitions/167.html',
    description:
      'The product receives input from an upstream component, but it does not handle or incorrectly handles when an additional unexpected special element is provided.'
  },
  '168': {
    name: 'Improper Handling of Inconsistent Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/168.html',
    description:
      'An example of this problem would be if paired characters appear in the wrong order, or if the special characters are not properly nested.'
  },
  '170': {
    name: 'Improper Null Termination',
    link: 'https://cwe.mitre.org/data/definitions/170.html',
    description:
      'Null termination errors frequently occur in two different ways. An off-by-one error could cause a null to be written out of bounds, leading to an overflow. Or, a program could use a strncpy() function call incorrectly, which prevents a null terminator from being added at all. Other scenarios are possible.'
  },
  '172': {
    name: 'Encoding Error',
    link: 'https://cwe.mitre.org/data/definitions/172.html',
    description: 'The product does not properly encode or decode the data, resulting in unexpected values.'
  },
  '173': {
    name: 'Improper Handling of Alternate Encoding',
    link: 'https://cwe.mitre.org/data/definitions/173.html',
    description:
      'The product does not properly handle when an input uses an alternate encoding that is valid for the control sphere to which the input is being sent.'
  },
  '174': {
    name: 'Double Decoding of the Same Data',
    link: 'https://cwe.mitre.org/data/definitions/174.html',
    description:
      'The product decodes the same input twice, which can limit the effectiveness of any protection mechanism that occurs in between the decoding operations.'
  },
  '175': {
    name: 'Improper Handling of Mixed Encoding',
    link: 'https://cwe.mitre.org/data/definitions/175.html',
    description: 'The product does not properly handle when the same input uses several different (mixed) encodings.'
  },
  '176': {
    name: 'Improper Handling of Unicode Encoding',
    link: 'https://cwe.mitre.org/data/definitions/176.html',
    description: 'The product does not properly handle when an input contains Unicode encoding.'
  },
  '177': {
    name: 'Improper Handling of URL Encoding (Hex Encoding)',
    link: 'https://cwe.mitre.org/data/definitions/177.html',
    description: 'The product does not properly handle when all or part of an input has been URL encoded.'
  },
  '178': {
    name: 'Improper Handling of Case Sensitivity',
    link: 'https://cwe.mitre.org/data/definitions/178.html',
    description: '\n            '
  },
  '179': {
    name: 'Incorrect Behavior Order: Early Validation',
    link: 'https://cwe.mitre.org/data/definitions/179.html',
    description:
      'Product needs to validate data at the proper time, after data has been canonicalized and cleansed. Early validation is susceptible to various manipulations that result in dangerous inputs that are produced by canonicalization and cleansing.'
  },
  '180': {
    name: 'Incorrect Behavior Order: Validate Before Canonicalize',
    link: 'https://cwe.mitre.org/data/definitions/180.html',
    description:
      'This can be used by an attacker to bypass the validation and launch attacks that expose weaknesses that would otherwise be prevented, such as injection.'
  },
  '181': {
    name: 'Incorrect Behavior Order: Validate Before Filter',
    link: 'https://cwe.mitre.org/data/definitions/181.html',
    description:
      'This can be used by an attacker to bypass the validation and launch attacks that expose weaknesses that would otherwise be prevented, such as injection.'
  },
  '182': {
    name: 'Collapse of Data into Unsafe Value',
    link: 'https://cwe.mitre.org/data/definitions/182.html',
    description:
      'The product filters data in a way that causes it to be reduced or "collapsed" into an unsafe value that violates an expected security property.'
  },
  '183': {
    name: 'Permissive List of Allowed Inputs',
    link: 'https://cwe.mitre.org/data/definitions/183.html',
    description:
      'The product implements a protection mechanism that relies on a list of inputs (or properties of inputs) that are explicitly allowed by policy because the inputs are assumed to be safe, but the list is too permissive - that is, it allows an input that is unsafe, leading to resultant weaknesses.'
  },
  '184': {
    name: 'Incomplete List of Disallowed Inputs',
    link: 'https://cwe.mitre.org/data/definitions/184.html',
    description:
      'Developers often try to protect their products against malicious input by performing tests against inputs that are known to be bad, such as special characters that can invoke new commands.  However, such lists often only account for the most well-known bad inputs. Attackers may be able to find other malicious inputs that were not expected by the developer, allowing them to bypass the intended protection mechanism.'
  },
  '185': {
    name: 'Incorrect Regular Expression',
    link: 'https://cwe.mitre.org/data/definitions/185.html',
    description:
      'When the regular expression is used in protection mechanisms such as filtering or validation, this may allow an attacker to bypass the intended restrictions on the incoming data.'
  },
  '186': {
    name: 'Overly Restrictive Regular Expression',
    link: 'https://cwe.mitre.org/data/definitions/186.html',
    description:
      'This weakness is not about regular expression complexity. Rather, it is about a regular expression that does not match all values that are intended. Consider the use of a regexp to identify acceptable values or to spot unwanted terms. An overly restrictive regexp misses some potentially security-relevant values leading to either false positives *or* false negatives, depending on how the regexp is being used within the code. Consider the expression /[0-8]/ where the intention was /[0-9]/.  This expression is not "complex" but the value "9" is not matched when maybe the programmer planned to check for it.'
  },
  '187': {
    name: 'Partial String Comparison',
    link: 'https://cwe.mitre.org/data/definitions/187.html',
    description:
      'For example, an attacker might succeed in authentication by providing a small password that matches the associated portion of the larger, correct password.'
  },
  '188': {
    name: 'Reliance on Data/Memory Layout',
    link: 'https://cwe.mitre.org/data/definitions/188.html',
    description: '\n            '
  },
  '190': {
    name: 'Integer Overflow or Wraparound',
    link: 'https://cwe.mitre.org/data/definitions/190.html',
    description:
      'An integer overflow or wraparound occurs when an integer value is incremented to a value that is too large to store in the associated representation. When this occurs, the value may wrap to become a very small or negative number. While this may be intended behavior in circumstances that rely on wrapping, it can have security consequences if the wrap is unexpected. This is especially the case if the integer overflow can be triggered using user-supplied inputs. This becomes security-critical when the result is used to control looping, make a security decision, or determine the offset or size in behaviors such as memory allocation, copying, concatenation, etc.'
  },
  '191': {
    name: 'Integer Underflow (Wrap or Wraparound)',
    link: 'https://cwe.mitre.org/data/definitions/191.html',
    description: 'This can happen in signed and unsigned cases.'
  },
  '192': {
    name: 'Integer Coercion Error',
    link: 'https://cwe.mitre.org/data/definitions/192.html',
    description:
      'Several flaws fall under the category of integer coercion errors. For the most part, these errors in and of themselves result only in availability and data integrity issues. However, in some circumstances, they may result in other, more complicated security related flaws, such as buffer overflow conditions.'
  },
  '193': {
    name: 'Off-by-one Error',
    link: 'https://cwe.mitre.org/data/definitions/193.html',
    description:
      'A product calculates or uses an incorrect maximum or minimum value that is 1 more, or 1 less, than the correct value.'
  },
  '194': {
    name: 'Unexpected Sign Extension',
    link: 'https://cwe.mitre.org/data/definitions/194.html',
    description:
      'The product performs an operation on a number that causes it to be sign extended when it is transformed into a larger data type. When the original number is negative, this can produce unexpected values that lead to resultant weaknesses.'
  },
  '195': {
    name: 'Signed to Unsigned Conversion Error',
    link: 'https://cwe.mitre.org/data/definitions/195.html',
    description: '\n            '
  },
  '196': {
    name: 'Unsigned to Signed Conversion Error',
    link: 'https://cwe.mitre.org/data/definitions/196.html',
    description:
      'Although less frequent an issue than signed-to-unsigned conversion, unsigned-to-signed conversion can be the perfect precursor to dangerous buffer underwrite conditions that allow attackers to move down the stack where they otherwise might not have access in a normal buffer overflow condition. Buffer underwrites occur frequently when large unsigned values are cast to signed values, and then used as indexes into a buffer or for pointer arithmetic.'
  },
  '197': {
    name: 'Numeric Truncation Error',
    link: 'https://cwe.mitre.org/data/definitions/197.html',
    description:
      'When a primitive is cast to a smaller primitive, the high order bits of the large value are lost in the conversion, potentially resulting in an unexpected value that is not equal to the original value. This value may be required as an index into a buffer, a loop iterator, or simply necessary state data. In any case, the value cannot be trusted and the system will be in an undefined state. While this method may be employed viably to isolate the low bits of a value, this usage is rare, and truncation usually implies that an implementation error has occurred.'
  },
  '198': {
    name: 'Use of Incorrect Byte Ordering',
    link: 'https://cwe.mitre.org/data/definitions/198.html',
    description:
      'The product receives input from an upstream component, but it does not account for byte ordering (e.g. big-endian and little-endian) when processing the input, causing an incorrect number or value to be used.'
  },
  '20': {
    name: 'Improper Input Validation',
    link: 'https://cwe.mitre.org/data/definitions/20.html',
    description: '\n\t   '
  },
  '200': {
    name: 'Exposure of Sensitive Information to an Unauthorized Actor',
    link: 'https://cwe.mitre.org/data/definitions/200.html',
    description: '\n\t   '
  },
  '201': {
    name: 'Insertion of Sensitive Information Into Sent Data',
    link: 'https://cwe.mitre.org/data/definitions/201.html',
    description:
      'Sensitive information could include data that is sensitive in and of itself (such as credentials or private messages), or otherwise useful in the further exploitation of the system (such as internal file system structure).\n\t '
  },
  '202': {
    name: 'Exposure of Sensitive Information Through Data Queries',
    link: 'https://cwe.mitre.org/data/definitions/202.html',
    description:
      'In situations where data should not be tied to individual users, but a large number of users should be able to make queries that "scrub" the identity of users, it may be possible to get information about a user -- e.g., by specifying search terms that are known to be unique to that user.'
  },
  '203': {
    name: 'Observable Discrepancy',
    link: 'https://cwe.mitre.org/data/definitions/203.html',
    description:
      "Discrepancies can take many forms, and variations may be detectable in timing, control flow, communications such as replies or requests, or general behavior. These discrepancies can reveal information about the product's operation or internal state to an unauthorized actor. In some cases, discrepancies can be used by attackers to form a side channel."
  },
  '204': {
    name: 'Observable Response Discrepancy',
    link: 'https://cwe.mitre.org/data/definitions/204.html',
    description:
      'This issue frequently occurs during authentication, where a difference in failed-login messages could allow an attacker to determine if the username is valid or not. These exposures can be inadvertent (bug) or intentional (design).'
  },
  '205': {
    name: 'Observable Behavioral Discrepancy',
    link: 'https://cwe.mitre.org/data/definitions/205.html',
    description:
      '\n\t   Ideally, a product should provide as little information about its internal operations as possible.  Otherwise, attackers could use knowledge of these internal operations to simplify or optimize their attack.  In some cases, behavioral discrepancies can be used by attackers to form a side channel.'
  },
  '206': {
    name: 'Observable Internal Behavioral Discrepancy',
    link: 'https://cwe.mitre.org/data/definitions/206.html',
    description:
      '\n\t   Ideally, a product should provide as little information as possible to an attacker.  Any hints that the attacker may be making progress can then be used to simplify or optimize the attack.  For example, in a login procedure that requires a username and password, ultimately there is only one decision: success or failure.  However, internally, two separate actions are performed: determining if the username exists, and checking if the password is correct.  If the product behaves differently based on whether the username exists or not, then the attacker only needs to concentrate on the password.\n\t '
  },
  '207': {
    name: 'Observable Behavioral Discrepancy With Equivalent Products',
    link: 'https://cwe.mitre.org/data/definitions/207.html',
    description:
      '\n\t   For many kinds of products, multiple products may be available that perform the same functionality, such as a web server, network interface, or intrusion detection system.  Attackers often perform "fingerprinting," which uses discrepancies in order to identify which specific product is in use.  Once the specific product has been identified, the attacks can be made more customized and efficient.  Often, an organization might intentionally allow the specific product to be identifiable.  However, in some environments, the ability to identify a distinct product is unacceptable, and it is expected that every product would behave in exactly the same way.  In these more restricted environments, a behavioral difference might pose an unacceptable risk if it makes it easier to identify the product\'s vendor, model, configuration, version, etc.\n\t '
  },
  '208': {
    name: 'Observable Timing Discrepancy',
    link: 'https://cwe.mitre.org/data/definitions/208.html',
    description:
      "\n\t   In security-relevant contexts, even small variations in timing can be exploited by attackers to indirectly infer certain details about the product's internal operations.  For example, in some cryptographic algorithms, attackers can use timing differences to infer certain properties about a private key, making the key easier to guess.  Timing discrepancies effectively form a timing side channel.\n\t "
  },
  '209': {
    name: 'Generation of Error Message Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/209.html',
    description:
      'The product generates an error message that includes sensitive information about its environment, users, or associated data.'
  },
  '210': {
    name: 'Self-generated Error Message Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/210.html',
    description:
      'The product identifies an error condition and creates its own diagnostic or error messages that contain sensitive information.'
  },
  '211': {
    name: 'Externally-Generated Error Message Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/211.html',
    description:
      'The product performs an operation that triggers an external diagnostic or error message that is not directly generated or controlled by the product, such as an error generated by the programming language interpreter that a software application uses. The error can contain sensitive system information.'
  },
  '212': {
    name: 'Improper Removal of Sensitive Information Before Storage or Transfer',
    link: 'https://cwe.mitre.org/data/definitions/212.html',
    description: '\n            '
  },
  '213': {
    name: 'Exposure of Sensitive Information Due to Incompatible Policies',
    link: 'https://cwe.mitre.org/data/definitions/213.html',
    description: '\n\t   '
  },
  '214': {
    name: 'Invocation of Process Using Visible Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/214.html',
    description:
      'Many operating systems allow a user to list information about processes that are owned by other users. Other users could see information such as command line arguments or environment variable settings. When this data contains sensitive information such as credentials, it might allow other users to launch an attack against the product or related resources.'
  },
  '215': {
    name: 'Insertion of Sensitive Information Into Debugging Code',
    link: 'https://cwe.mitre.org/data/definitions/215.html',
    description:
      'When debugging, it may be necessary to report detailed information to the programmer.  However, if the debugging code is not disabled when the product is operating in a production environment, then this sensitive information may be exposed to attackers.\n\t '
  },
  '216': {
    name: 'DEPRECATED: Containment Errors (Container Errors)',
    link: 'https://cwe.mitre.org/data/definitions/216.html',
    description:
      'This entry has been deprecated, as it was not effective as a weakness and was structured more like a category. In addition, the name is inappropriate, since the "container" term is widely understood by developers in different ways than originally intended by PLOVER, the original source for this entry.'
  },
  '217': {
    name: 'DEPRECATED: Failure to Protect Stored Data from Modification',
    link: 'https://cwe.mitre.org/data/definitions/217.html',
    description:
      'This entry has been deprecated because it incorporated and confused multiple weaknesses. The issues formerly covered in this entry can be found at CWE-766 and CWE-767.'
  },
  '218': {
    name: 'DEPRECATED: Failure to provide confidentiality for stored data',
    link: 'https://cwe.mitre.org/data/definitions/218.html',
    description:
      'This weakness has been deprecated because it was a duplicate of CWE-493. All content has been transferred to CWE-493.'
  },
  '219': {
    name: 'Storage of File with Sensitive Data Under Web Root',
    link: 'https://cwe.mitre.org/data/definitions/219.html',
    description:
      '\n\t   Besides public-facing web pages and code, products may store sensitive data, code that is not directly invoked, or other files under the web document root of the web server.  If the server is not configured or otherwise used to prevent direct access to those files, then attackers may obtain this sensitive data.\n\t '
  },
  '22': {
    name: "Improper Limitation of a Pathname to a Restricted Directory ('Path Traversal')",
    link: 'https://cwe.mitre.org/data/definitions/22.html',
    description: '\n            '
  },
  '220': {
    name: 'Storage of File With Sensitive Data Under FTP Root',
    link: 'https://cwe.mitre.org/data/definitions/220.html',
    description:
      'The product stores sensitive data under the FTP server root with insufficient access control, which might make it accessible to untrusted parties.'
  },
  '221': {
    name: 'Information Loss or Omission',
    link: 'https://cwe.mitre.org/data/definitions/221.html',
    description:
      'This can be resultant, e.g. a buffer overflow might trigger a crash before the product can log the event.'
  },
  '222': {
    name: 'Truncation of Security-relevant Information',
    link: 'https://cwe.mitre.org/data/definitions/222.html',
    description:
      'The product truncates the display, recording, or processing of security-relevant information in a way that can obscure the source or nature of an attack.'
  },
  '223': {
    name: 'Omission of Security-relevant Information',
    link: 'https://cwe.mitre.org/data/definitions/223.html',
    description:
      'The product does not record or display information that would be important for identifying the source or nature of an attack, or determining if an action is safe.'
  },
  '224': {
    name: 'Obscured Security-relevant Information by Alternate Name',
    link: 'https://cwe.mitre.org/data/definitions/224.html',
    description:
      'The product records security-relevant information according to an alternate name of the affected entity, instead of the canonical name.'
  },
  '225': {
    name: 'DEPRECATED: General Information Management Problems',
    link: 'https://cwe.mitre.org/data/definitions/225.html',
    description: 'This weakness can be found at CWE-199.'
  },
  '226': {
    name: 'Sensitive Information in Resource Not Removed Before Reuse',
    link: 'https://cwe.mitre.org/data/definitions/226.html',
    description: '\n\t\t\t '
  },
  '228': {
    name: 'Improper Handling of Syntactically Invalid Structure',
    link: 'https://cwe.mitre.org/data/definitions/228.html',
    description:
      'The product does not handle or incorrectly handles input that is not syntactically well-formed with respect to the associated specification.'
  },
  '229': {
    name: 'Improper Handling of Values',
    link: 'https://cwe.mitre.org/data/definitions/229.html',
    description:
      'The product does not properly handle when the expected number of values for parameters, fields, or arguments is not provided in input, or if those values are undefined.'
  },
  '23': {
    name: 'Relative Path Traversal',
    link: 'https://cwe.mitre.org/data/definitions/23.html',
    description:
      'This allows attackers to traverse the file system to access files or directories that are outside of the restricted directory.'
  },
  '230': {
    name: 'Improper Handling of Missing Values',
    link: 'https://cwe.mitre.org/data/definitions/230.html',
    description:
      'The product does not handle or incorrectly handles when a parameter, field, or argument name is specified, but the associated value is missing, i.e. it is empty, blank, or null.'
  },
  '231': {
    name: 'Improper Handling of Extra Values',
    link: 'https://cwe.mitre.org/data/definitions/231.html',
    description: 'The product does not handle or incorrectly handles when more values are provided than expected.'
  },
  '232': {
    name: 'Improper Handling of Undefined Values',
    link: 'https://cwe.mitre.org/data/definitions/232.html',
    description:
      'The product does not handle or incorrectly handles when a value is not defined or supported for the associated parameter, field, or argument name.'
  },
  '233': {
    name: 'Improper Handling of Parameters',
    link: 'https://cwe.mitre.org/data/definitions/233.html',
    description:
      'The product does not properly handle when the expected number of parameters, fields, or arguments is not provided in input, or if those parameters are undefined.'
  },
  '234': {
    name: 'Failure to Handle Missing Parameter',
    link: 'https://cwe.mitre.org/data/definitions/234.html',
    description:
      'If too few arguments are sent to a function, the function will still pop the expected number of arguments from the stack. Potentially, a variable number of arguments could be exhausted in a function as well.'
  },
  '235': {
    name: 'Improper Handling of Extra Parameters',
    link: 'https://cwe.mitre.org/data/definitions/235.html',
    description:
      'The product does not handle or incorrectly handles when the number of parameters, fields, or arguments with the same name exceeds the expected amount.'
  },
  '236': {
    name: 'Improper Handling of Undefined Parameters',
    link: 'https://cwe.mitre.org/data/definitions/236.html',
    description:
      'The product does not handle or incorrectly handles when a particular parameter, field, or argument name is not defined or supported by the product.'
  },
  '237': {
    name: 'Improper Handling of Structural Elements',
    link: 'https://cwe.mitre.org/data/definitions/237.html',
    description: 'The product does not handle or incorrectly handles inputs that are related to complex structures.'
  },
  '238': {
    name: 'Improper Handling of Incomplete Structural Elements',
    link: 'https://cwe.mitre.org/data/definitions/238.html',
    description:
      'The product does not handle or incorrectly handles when a particular structural element is not completely specified.'
  },
  '239': {
    name: 'Failure to Handle Incomplete Element',
    link: 'https://cwe.mitre.org/data/definitions/239.html',
    description: 'The product does not properly handle when a particular element is not completely specified.'
  },
  '24': {
    name: "Path Traversal: '../filedir'",
    link: 'https://cwe.mitre.org/data/definitions/24.html',
    description: '\n            '
  },
  '240': {
    name: 'Improper Handling of Inconsistent Structural Elements',
    link: 'https://cwe.mitre.org/data/definitions/240.html',
    description:
      'The product does not handle or incorrectly handles when two or more structural elements should be consistent, but are not.'
  },
  '241': {
    name: 'Improper Handling of Unexpected Data Type',
    link: 'https://cwe.mitre.org/data/definitions/241.html',
    description:
      'The product does not handle or incorrectly handles when a particular element is not the expected type, e.g. it expects a digit (0-9) but is provided with a letter (A-Z).'
  },
  '242': {
    name: 'Use of Inherently Dangerous Function',
    link: 'https://cwe.mitre.org/data/definitions/242.html',
    description:
      'Certain functions behave in dangerous ways regardless of how they are used. Functions in this category were often implemented without taking security concerns into account. The gets() function is unsafe because it does not perform bounds checking on the size of its input. An attacker can easily send arbitrarily-sized input to gets() and overflow the destination buffer. Similarly, the >> operator is unsafe to use when reading into a statically-allocated character array because it does not perform bounds checking on the size of its input. An attacker can easily send arbitrarily-sized input to the >> operator and overflow the destination buffer.'
  },
  '243': {
    name: 'Creation of chroot Jail Without Changing Working Directory',
    link: 'https://cwe.mitre.org/data/definitions/243.html',
    description:
      "Improper use of chroot() may allow attackers to escape from the chroot jail. The chroot() function call does not change the process's current working directory, so relative paths may still refer to file system resources outside of the chroot jail after chroot() has been called."
  },
  '244': {
    name: "Improper Clearing of Heap Memory Before Release ('Heap Inspection')",
    link: 'https://cwe.mitre.org/data/definitions/244.html',
    description:
      'When sensitive data such as a password or an encryption key is not removed from memory, it could be exposed to an attacker using a "heap inspection" attack that reads the sensitive data using memory dumps or other methods. The realloc() function is commonly used to increase the size of a block of allocated memory. This operation often requires copying the contents of the old memory block into a new and larger block. This operation leaves the contents of the original block intact but inaccessible to the program, preventing the program from being able to scrub sensitive data from memory. If an attacker can later examine the contents of a memory dump, the sensitive data could be exposed.'
  },
  '245': {
    name: 'J2EE Bad Practices: Direct Management of Connections',
    link: 'https://cwe.mitre.org/data/definitions/245.html',
    description:
      "The J2EE standard forbids the direct management of connections. It requires that applications use the container's resource management facilities to obtain connections to resources. Every major web application container provides pooled database connection management as part of its resource management framework. Duplicating this functionality in an application is difficult and error prone, which is part of the reason it is forbidden under the J2EE standard."
  },
  '246': {
    name: 'J2EE Bad Practices: Direct Use of Sockets',
    link: 'https://cwe.mitre.org/data/definitions/246.html',
    description: '\n            '
  },
  '247': {
    name: 'DEPRECATED: Reliance on DNS Lookups in a Security Decision',
    link: 'https://cwe.mitre.org/data/definitions/247.html',
    description:
      'This entry has been deprecated because it was a duplicate of CWE-350. All content has been transferred to CWE-350.'
  },
  '248': {
    name: 'Uncaught Exception',
    link: 'https://cwe.mitre.org/data/definitions/248.html',
    description: 'When an exception is not caught, it may cause the program to crash or expose sensitive information.'
  },
  '249': {
    name: 'DEPRECATED: Often Misused: Path Manipulation',
    link: 'https://cwe.mitre.org/data/definitions/249.html',
    description: '\n        '
  },
  '25': {
    name: "Path Traversal: '/../filedir'",
    link: 'https://cwe.mitre.org/data/definitions/25.html',
    description: '\n            '
  },
  '250': {
    name: 'Execution with Unnecessary Privileges',
    link: 'https://cwe.mitre.org/data/definitions/250.html',
    description: '\n            '
  },
  '252': {
    name: 'Unchecked Return Value',
    link: 'https://cwe.mitre.org/data/definitions/252.html',
    description:
      'Two common programmer assumptions are "this function call can never fail" and "it doesn\'t matter if this function call fails". If an attacker can force the function to fail or otherwise return a value that is not expected, then the subsequent program logic could lead to a vulnerability, because the product is not in a state that the programmer assumes. For example, if the program calls a function to drop privileges but does not check the return code to ensure that privileges were successfully dropped, then the program will continue to operate with the higher privileges.'
  },
  '253': {
    name: 'Incorrect Check of Function Return Value',
    link: 'https://cwe.mitre.org/data/definitions/253.html',
    description:
      'Important and common functions will return some value about the success of its actions. This will alert the program whether or not to handle any errors caused by that function.'
  },
  '256': {
    name: 'Plaintext Storage of a Password',
    link: 'https://cwe.mitre.org/data/definitions/256.html',
    description:
      "Password management issues occur when a password is stored in plaintext in an application's properties, configuration file, or memory. Storing a plaintext password in a configuration file allows anyone who can read the file access to the password-protected resource. In some contexts, even storage of a plaintext password in memory is considered a security risk if the password is not cleared immediately after it is used."
  },
  '257': {
    name: 'Storing Passwords in a Recoverable Format',
    link: 'https://cwe.mitre.org/data/definitions/257.html',
    description:
      'The storage of passwords in a recoverable format makes them subject to password reuse attacks by malicious users. In fact, it should be noted that recoverable encrypted passwords provide no significant benefit over plaintext passwords since they are subject not only to reuse by malicious attackers but also by malicious insiders. If a system administrator can recover a password directly, or use a brute force search on the available information, the administrator can use the password on other accounts.'
  },
  '258': {
    name: 'Empty Password in Configuration File',
    link: 'https://cwe.mitre.org/data/definitions/258.html',
    description: 'Using an empty string as a password is insecure.'
  },
  '259': {
    name: 'Use of Hard-coded Password',
    link: 'https://cwe.mitre.org/data/definitions/259.html',
    description: '\n            '
  },
  '26': {
    name: "Path Traversal: '/dir/../filename'",
    link: 'https://cwe.mitre.org/data/definitions/26.html',
    description: '\n            '
  },
  '260': {
    name: 'Password in Configuration File',
    link: 'https://cwe.mitre.org/data/definitions/260.html',
    description:
      'This can result in compromise of the system for which the password is used. An attacker could gain access to this file and learn the stored password or worse yet, change the password to one of their choosing.'
  },
  '261': {
    name: 'Weak Encoding for Password',
    link: 'https://cwe.mitre.org/data/definitions/261.html',
    description:
      "Password management issues occur when a password is stored in plaintext in an application's properties or configuration file. A programmer can attempt to remedy the password management problem by obscuring the password with an encoding function, such as base 64 encoding, but this effort does not adequately protect the password."
  },
  '262': {
    name: 'Not Using Password Aging',
    link: 'https://cwe.mitre.org/data/definitions/262.html',
    description: '\n\t '
  },
  '263': {
    name: 'Password Aging with Long Expiration',
    link: 'https://cwe.mitre.org/data/definitions/263.html',
    description: '\n\t   '
  },
  '266': {
    name: 'Incorrect Privilege Assignment',
    link: 'https://cwe.mitre.org/data/definitions/266.html',
    description:
      'A product incorrectly assigns a privilege to a particular actor, creating an unintended sphere of control for that actor.'
  },
  '267': {
    name: 'Privilege Defined With Unsafe Actions',
    link: 'https://cwe.mitre.org/data/definitions/267.html',
    description:
      'A particular privilege, role, capability, or right can be used to perform unsafe actions that were not intended, even when it is assigned to the correct entity.'
  },
  '268': {
    name: 'Privilege Chaining',
    link: 'https://cwe.mitre.org/data/definitions/268.html',
    description:
      'Two distinct privileges, roles, capabilities, or rights can be combined in a way that allows an entity to perform unsafe actions that would not be allowed without that combination.'
  },
  '269': {
    name: 'Improper Privilege Management',
    link: 'https://cwe.mitre.org/data/definitions/269.html',
    description:
      'The product does not properly assign, modify, track, or check privileges for an actor, creating an unintended sphere of control for that actor.'
  },
  '27': {
    name: "Path Traversal: 'dir/../../filename'",
    link: 'https://cwe.mitre.org/data/definitions/27.html',
    description: '\n            '
  },
  '270': {
    name: 'Privilege Context Switching Error',
    link: 'https://cwe.mitre.org/data/definitions/270.html',
    description:
      'The product does not properly manage privileges while it is switching between different contexts that have different privileges or spheres of control.'
  },
  '271': {
    name: 'Privilege Dropping / Lowering Errors',
    link: 'https://cwe.mitre.org/data/definitions/271.html',
    description:
      'In some contexts, a system executing with elevated permissions will hand off a process/file/etc. to another process or user. If the privileges of an entity are not reduced, then elevated privileges are spread throughout a system and possibly to an attacker.'
  },
  '272': {
    name: 'Least Privilege Violation',
    link: 'https://cwe.mitre.org/data/definitions/272.html',
    description:
      'The elevated privilege level required to perform operations such as chroot() should be dropped immediately after the operation is performed.'
  },
  '273': {
    name: 'Improper Check for Dropped Privileges',
    link: 'https://cwe.mitre.org/data/definitions/273.html',
    description:
      'If the drop fails, the product will continue to run with the raised privileges, which might provide additional access to unprivileged users.'
  },
  '274': {
    name: 'Improper Handling of Insufficient Privileges',
    link: 'https://cwe.mitre.org/data/definitions/274.html',
    description:
      'The product does not handle or incorrectly handles when it has insufficient privileges to perform an operation, leading to resultant weaknesses.'
  },
  '276': {
    name: 'Incorrect Default Permissions',
    link: 'https://cwe.mitre.org/data/definitions/276.html',
    description: 'During installation, installed file permissions are set to allow anyone to modify those files.'
  },
  '277': {
    name: 'Insecure Inherited Permissions',
    link: 'https://cwe.mitre.org/data/definitions/277.html',
    description:
      'A product defines a set of insecure permissions that are inherited by objects that are created by the program.'
  },
  '278': {
    name: 'Insecure Preserved Inherited Permissions',
    link: 'https://cwe.mitre.org/data/definitions/278.html',
    description:
      'A product inherits a set of insecure permissions for an object, e.g. when copying from an archive file, without user awareness or involvement.'
  },
  '279': {
    name: 'Incorrect Execution-Assigned Permissions',
    link: 'https://cwe.mitre.org/data/definitions/279.html',
    description:
      'While it is executing, the product sets the permissions of an object in a way that violates the intended permissions that have been specified by the user.'
  },
  '28': {
    name: "Path Traversal: '..\\filedir'",
    link: 'https://cwe.mitre.org/data/definitions/28.html',
    description: '\n            '
  },
  '280': {
    name: 'Improper Handling of Insufficient Permissions or Privileges ',
    link: 'https://cwe.mitre.org/data/definitions/280.html',
    description:
      'The product does not handle or incorrectly handles when it has insufficient privileges to access resources or functionality as specified by their permissions. This may cause it to follow unexpected code paths that may leave the product in an invalid state.'
  },
  '281': {
    name: 'Improper Preservation of Permissions',
    link: 'https://cwe.mitre.org/data/definitions/281.html',
    description:
      'The product does not preserve permissions or incorrectly preserves permissions when copying, restoring, or sharing objects, which can cause them to have less restrictive permissions than intended.'
  },
  '282': {
    name: 'Improper Ownership Management',
    link: 'https://cwe.mitre.org/data/definitions/282.html',
    description:
      'The product assigns the wrong ownership, or does not properly verify the ownership, of an object or resource.'
  },
  '283': {
    name: 'Unverified Ownership',
    link: 'https://cwe.mitre.org/data/definitions/283.html',
    description: 'The product does not properly verify that a critical resource is owned by the proper entity.'
  },
  '284': {
    name: 'Improper Access Control',
    link: 'https://cwe.mitre.org/data/definitions/284.html',
    description: '\n            '
  },
  '285': {
    name: 'Improper Authorization',
    link: 'https://cwe.mitre.org/data/definitions/285.html',
    description: '\n            '
  },
  '286': {
    name: 'Incorrect User Management',
    link: 'https://cwe.mitre.org/data/definitions/286.html',
    description:
      'Users can be assigned to the wrong group (class) of permissions resulting in unintended access rights to sensitive objects.'
  },
  '287': {
    name: 'Improper Authentication',
    link: 'https://cwe.mitre.org/data/definitions/287.html',
    description:
      'When an actor claims to have a given identity, the product does not prove or insufficiently proves that the claim is correct.'
  },
  '288': {
    name: 'Authentication Bypass Using an Alternate Path or Channel',
    link: 'https://cwe.mitre.org/data/definitions/288.html',
    description:
      'A product requires authentication, but the product has an alternate path or channel that does not require authentication.'
  },
  '289': {
    name: 'Authentication Bypass by Alternate Name',
    link: 'https://cwe.mitre.org/data/definitions/289.html',
    description:
      'The product performs authentication based on the name of a resource being accessed, or the name of the actor performing the access, but it does not properly check all possible names for that resource or actor.'
  },
  '29': {
    name: "Path Traversal: '\\..\\filename'",
    link: 'https://cwe.mitre.org/data/definitions/29.html',
    description: '\n            '
  },
  '290': {
    name: 'Authentication Bypass by Spoofing',
    link: 'https://cwe.mitre.org/data/definitions/290.html',
    description:
      'This attack-focused weakness is caused by incorrectly implemented authentication schemes that are subject to spoofing attacks.'
  },
  '291': {
    name: 'Reliance on IP Address for Authentication',
    link: 'https://cwe.mitre.org/data/definitions/291.html',
    description:
      'IP addresses can be easily spoofed. Attackers can forge the source IP address of the packets they send, but response packets will return to the forged IP address. To see the response packets, the attacker has to sniff the traffic between the victim machine and the forged IP address. In order to accomplish the required sniffing, attackers typically attempt to locate themselves on the same subnet as the victim machine. Attackers may be able to circumvent this requirement by using source routing, but source routing is disabled across much of the Internet today. In summary, IP address verification can be a useful part of an authentication scheme, but it should not be the single factor required for authentication.'
  },
  '292': {
    name: 'DEPRECATED: Trusting Self-reported DNS Name',
    link: 'https://cwe.mitre.org/data/definitions/292.html',
    description:
      'This entry has been deprecated because it was a duplicate of CWE-350. All content has been transferred to CWE-350.'
  },
  '293': {
    name: 'Using Referer Field for Authentication',
    link: 'https://cwe.mitre.org/data/definitions/293.html',
    description:
      'The referer field in HTTP requests can be easily modified and, as such, is not a valid means of message integrity checking.'
  },
  '294': {
    name: 'Authentication Bypass by Capture-replay',
    link: 'https://cwe.mitre.org/data/definitions/294.html',
    description:
      'Capture-replay attacks are common and can be difficult to defeat without cryptography. They are a subset of network injection attacks that rely on observing previously-sent valid commands, then changing them slightly if necessary and resending the same commands to the server.'
  },
  '295': {
    name: 'Improper Certificate Validation',
    link: 'https://cwe.mitre.org/data/definitions/295.html',
    description:
      'When a certificate is invalid or malicious, it might allow an attacker to spoof a trusted entity by interfering in the communication path between the host and client. The product might connect to a malicious host while believing it is a trusted host, or the product might be deceived into accepting spoofed data that appears to originate from a trusted host.'
  },
  '296': {
    name: "Improper Following of a Certificate's Chain of Trust",
    link: 'https://cwe.mitre.org/data/definitions/296.html',
    description: '\n            '
  },
  '297': {
    name: 'Improper Validation of Certificate with Host Mismatch',
    link: 'https://cwe.mitre.org/data/definitions/297.html',
    description: '\n            '
  },
  '298': {
    name: 'Improper Validation of Certificate Expiration',
    link: 'https://cwe.mitre.org/data/definitions/298.html',
    description:
      'When the expiration of a certificate is not taken into account, no trust has necessarily been conveyed through it. Therefore, the validity of the certificate cannot be verified and all benefit of the certificate is lost.'
  },
  '299': {
    name: 'Improper Check for Certificate Revocation',
    link: 'https://cwe.mitre.org/data/definitions/299.html',
    description:
      'An improper check for certificate revocation is a far more serious flaw than related certificate failures. This is because the use of any revoked certificate is almost certainly malicious. The most common reason for certificate revocation is compromise of the system in question, with the result that no legitimate servers will be using a revoked certificate, unless they are sorely out of sync.'
  },
  '30': {
    name: "Path Traversal: '\\dir\\..\\filename'",
    link: 'https://cwe.mitre.org/data/definitions/30.html',
    description: '\n            '
  },
  '300': {
    name: 'Channel Accessible by Non-Endpoint',
    link: 'https://cwe.mitre.org/data/definitions/300.html',
    description:
      'In order to establish secure communication between two parties, it is often important to adequately verify the identity of entities at each end of the communication channel. Inadequate or inconsistent verification may result in insufficient or incorrect identification of either communicating entity. This can have negative consequences such as misplaced trust in the entity at the other end of the channel. An attacker can leverage this by interposing between the communicating entities and masquerading as the original entity. In the absence of sufficient verification of identity, such an attacker can eavesdrop and potentially modify the communication between the original entities.'
  },
  '301': {
    name: 'Reflection Attack in an Authentication Protocol',
    link: 'https://cwe.mitre.org/data/definitions/301.html',
    description: '\n\t\t   '
  },
  '302': {
    name: 'Authentication Bypass by Assumed-Immutable Data',
    link: 'https://cwe.mitre.org/data/definitions/302.html',
    description:
      'The authentication scheme or implementation uses key data elements that are assumed to be immutable, but can be controlled or modified by the attacker.'
  },
  '303': {
    name: 'Incorrect Implementation of Authentication Algorithm',
    link: 'https://cwe.mitre.org/data/definitions/303.html',
    description: 'This incorrect implementation may allow authentication to be bypassed.'
  },
  '304': {
    name: 'Missing Critical Step in Authentication',
    link: 'https://cwe.mitre.org/data/definitions/304.html',
    description:
      'Authentication techniques should follow the algorithms that define them exactly, otherwise authentication can be bypassed or more easily subjected to brute force attacks.'
  },
  '305': {
    name: 'Authentication Bypass by Primary Weakness',
    link: 'https://cwe.mitre.org/data/definitions/305.html',
    description:
      'The authentication algorithm is sound, but the implemented mechanism can be bypassed as the result of a separate weakness that is primary to the authentication error.'
  },
  '306': {
    name: 'Missing Authentication for Critical Function',
    link: 'https://cwe.mitre.org/data/definitions/306.html',
    description: '\n\t   '
  },
  '307': {
    name: 'Improper Restriction of Excessive Authentication Attempts',
    link: 'https://cwe.mitre.org/data/definitions/307.html',
    description:
      'The product does not implement sufficient measures to prevent multiple failed authentication attempts within a short time frame, making it more susceptible to brute force attacks.'
  },
  '308': {
    name: 'Use of Single-factor Authentication',
    link: 'https://cwe.mitre.org/data/definitions/308.html',
    description:
      'While the use of multiple authentication schemes is simply piling on more complexity on top of authentication, it is inestimably valuable to have such measures of redundancy. The use of weak, reused, and common passwords is rampant on the internet. Without the added protection of multiple authentication schemes, a single mistake can result in the compromise of an account. For this reason, if multiple schemes are possible and also easy to use, they should be implemented and required.'
  },
  '309': {
    name: 'Use of Password System for Primary Authentication',
    link: 'https://cwe.mitre.org/data/definitions/309.html',
    description:
      'The use of password systems as the primary means of authentication may be subject to several flaws or shortcomings, each reducing the effectiveness of the mechanism.'
  },
  '31': {
    name: "Path Traversal: 'dir\\..\\..\\filename'",
    link: 'https://cwe.mitre.org/data/definitions/31.html',
    description: '\n            '
  },
  '311': {
    name: 'Missing Encryption of Sensitive Data',
    link: 'https://cwe.mitre.org/data/definitions/311.html',
    description:
      'The lack of proper data encryption passes up the guarantees of confidentiality, integrity, and accountability that properly implemented encryption conveys.'
  },
  '312': {
    name: 'Cleartext Storage of Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/312.html',
    description: '\n\t '
  },
  '313': {
    name: 'Cleartext Storage in a File or on Disk',
    link: 'https://cwe.mitre.org/data/definitions/313.html',
    description:
      'The sensitive information could be read by attackers with access to the file, or with physical or administrator access to the raw disk. Even if the information is encoded in a way that is not human-readable, certain techniques could determine which encoding is being used, then decode the information.'
  },
  '314': {
    name: 'Cleartext Storage in the Registry',
    link: 'https://cwe.mitre.org/data/definitions/314.html',
    description:
      'Attackers can read the information by accessing the registry key. Even if the information is encoded in a way that is not human-readable, certain techniques could determine which encoding is being used, then decode the information.'
  },
  '315': {
    name: 'Cleartext Storage of Sensitive Information in a Cookie',
    link: 'https://cwe.mitre.org/data/definitions/315.html',
    description:
      'Attackers can use widely-available tools to view the cookie and read the sensitive information. Even if the information is encoded in a way that is not human-readable, certain techniques could determine which encoding is being used, then decode the information.'
  },
  '316': {
    name: 'Cleartext Storage of Sensitive Information in Memory',
    link: 'https://cwe.mitre.org/data/definitions/316.html',
    description: '\n            '
  },
  '317': {
    name: 'Cleartext Storage of Sensitive Information in GUI',
    link: 'https://cwe.mitre.org/data/definitions/317.html',
    description:
      'An attacker can often obtain data from a GUI, even if hidden, by using an API to directly access GUI objects such as windows and menus. Even if the information is encoded in a way that is not human-readable, certain techniques could determine which encoding is being used, then decode the information.'
  },
  '318': {
    name: 'Cleartext Storage of Sensitive Information in Executable',
    link: 'https://cwe.mitre.org/data/definitions/318.html',
    description:
      'Attackers can reverse engineer binary code to obtain secret data. This is especially easy when the cleartext is plain ASCII. Even if the information is encoded in a way that is not human-readable, certain techniques could determine which encoding is being used, then decode the information.'
  },
  '319': {
    name: 'Cleartext Transmission of Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/319.html',
    description: '\n\t '
  },
  '32': {
    name: "Path Traversal: '...' (Triple Dot)",
    link: 'https://cwe.mitre.org/data/definitions/32.html',
    description: '\n            '
  },
  '321': {
    name: 'Use of Hard-coded Cryptographic Key',
    link: 'https://cwe.mitre.org/data/definitions/321.html',
    description:
      'The use of a hard-coded cryptographic key significantly increases the possibility that encrypted data may be recovered.'
  },
  '322': {
    name: 'Key Exchange without Entity Authentication',
    link: 'https://cwe.mitre.org/data/definitions/322.html',
    description:
      "Performing a key exchange will preserve the integrity of the information sent between two entities, but this will not guarantee that the entities are who they claim they are. This may enable an attacker to impersonate an actor by modifying traffic between the two entities.  Typically, this involves a victim client that contacts a malicious server that is impersonating a trusted server. If the client skips authentication or ignores an authentication failure, the malicious server may request authentication information from the user. The malicious server can then use this authentication information to log in to the trusted server using the victim's credentials, sniff traffic between the victim and trusted server, etc."
  },
  '323': {
    name: 'Reusing a Nonce, Key Pair in Encryption',
    link: 'https://cwe.mitre.org/data/definitions/323.html',
    description: 'Nonces should be used for the present occasion and only once.'
  },
  '324': {
    name: 'Use of a Key Past its Expiration Date',
    link: 'https://cwe.mitre.org/data/definitions/324.html',
    description:
      'While the expiration of keys does not necessarily ensure that they are compromised, it is a significant concern that keys which remain in use for prolonged periods of time have a decreasing probability of integrity. For this reason, it is important to replace keys within a period of time proportional to their strength.'
  },
  '325': {
    name: 'Missing Cryptographic Step',
    link: 'https://cwe.mitre.org/data/definitions/325.html',
    description:
      'The product does not implement a required step in a cryptographic algorithm, resulting in weaker encryption than advertised by the algorithm.'
  },
  '326': {
    name: 'Inadequate Encryption Strength',
    link: 'https://cwe.mitre.org/data/definitions/326.html',
    description:
      'A weak encryption scheme can be subjected to brute force attacks that have a reasonable chance of succeeding using current attack methods and resources.'
  },
  '327': {
    name: 'Use of a Broken or Risky Cryptographic Algorithm',
    link: 'https://cwe.mitre.org/data/definitions/327.html',
    description: '\n\t   '
  },
  '328': {
    name: 'Use of Weak Hash',
    link: 'https://cwe.mitre.org/data/definitions/328.html',
    description: '\n\t   '
  },
  '329': {
    name: 'Generation of Predictable IV with CBC Mode',
    link: 'https://cwe.mitre.org/data/definitions/329.html',
    description: '\n\t   '
  },
  '33': {
    name: "Path Traversal: '....' (Multiple Dot)",
    link: 'https://cwe.mitre.org/data/definitions/33.html',
    description: '\n            '
  },
  '330': {
    name: 'Use of Insufficiently Random Values',
    link: 'https://cwe.mitre.org/data/definitions/330.html',
    description:
      'When product generates predictable values in a context requiring unpredictability, it may be possible for an attacker to guess the next value that will be generated, and use this guess to impersonate another user or access sensitive information.'
  },
  '331': {
    name: 'Insufficient Entropy',
    link: 'https://cwe.mitre.org/data/definitions/331.html',
    description:
      'The product uses an algorithm or scheme that produces insufficient entropy, leaving patterns or clusters of values that are more likely to occur than others.'
  },
  '332': {
    name: 'Insufficient Entropy in PRNG',
    link: 'https://cwe.mitre.org/data/definitions/332.html',
    description:
      'The lack of entropy available for, or used by, a Pseudo-Random Number Generator (PRNG) can be a stability and security threat.'
  },
  '333': {
    name: 'Improper Handling of Insufficient Entropy in TRNG',
    link: 'https://cwe.mitre.org/data/definitions/333.html',
    description:
      'The rate at which true random numbers can be generated is limited. It is important that one uses them only when they are needed for security.'
  },
  '334': {
    name: 'Small Space of Random Values',
    link: 'https://cwe.mitre.org/data/definitions/334.html',
    description:
      'The number of possible random values is smaller than needed by the product, making it more susceptible to brute force attacks.'
  },
  '335': {
    name: 'Incorrect Usage of Seeds in Pseudo-Random Number Generator (PRNG)',
    link: 'https://cwe.mitre.org/data/definitions/335.html',
    description: '\n\t\t   '
  },
  '336': {
    name: 'Same Seed in Pseudo-Random Number Generator (PRNG)',
    link: 'https://cwe.mitre.org/data/definitions/336.html',
    description:
      'Given the deterministic nature of PRNGs, using the same seed for each initialization will lead to the same output in the same order. If an attacker can guess (or knows) the seed, then the attacker may be able to determine the random numbers that will be produced from the PRNG.'
  },
  '337': {
    name: 'Predictable Seed in Pseudo-Random Number Generator (PRNG)',
    link: 'https://cwe.mitre.org/data/definitions/337.html',
    description:
      'The use of predictable seeds significantly reduces the number of possible seeds that an attacker would need to test in order to predict which random numbers will be generated by the PRNG.'
  },
  '338': {
    name: 'Use of Cryptographically Weak Pseudo-Random Number Generator (PRNG)',
    link: 'https://cwe.mitre.org/data/definitions/338.html',
    description: '\n            '
  },
  '339': {
    name: 'Small Seed Space in PRNG',
    link: 'https://cwe.mitre.org/data/definitions/339.html',
    description:
      '\n\t\t   PRNGs are entirely deterministic once seeded, so it should be extremely difficult to guess the seed. If an attacker can collect the outputs of a PRNG and then brute force the seed by trying every possibility to see which seed matches the observed output, then the attacker will know the output of any subsequent calls to the PRNG. A small seed space implies that the attacker will have far fewer possible values to try to exhaust all possibilities.\n\t\t '
  },
  '34': {
    name: "Path Traversal: '....//'",
    link: 'https://cwe.mitre.org/data/definitions/34.html',
    description: '\n            '
  },
  '340': {
    name: 'Generation of Predictable Numbers or Identifiers',
    link: 'https://cwe.mitre.org/data/definitions/340.html',
    description:
      'The product uses a scheme that generates numbers or identifiers that are more predictable than required.'
  },
  '341': {
    name: 'Predictable from Observable State',
    link: 'https://cwe.mitre.org/data/definitions/341.html',
    description:
      'A number or object is predictable based on observations that the attacker can make about the state of the system or network, such as time, process ID, etc.'
  },
  '342': {
    name: 'Predictable Exact Value from Previous Values',
    link: 'https://cwe.mitre.org/data/definitions/342.html',
    description: 'An exact value or random number can be precisely predicted by observing previous values.'
  },
  '343': {
    name: 'Predictable Value Range from Previous Values',
    link: 'https://cwe.mitre.org/data/definitions/343.html',
    description:
      'The output of a random number generator should not be predictable based on observations of previous values. In some cases, an attacker cannot predict the exact value that will be produced next, but can narrow down the possibilities significantly. This reduces the amount of effort to perform a brute force attack. For example, suppose the product generates random numbers between 1 and 100, but it always produces a larger value until it reaches 100. If the generator produces an 80, then the attacker knows that the next value will be somewhere between 81 and 100. Instead of 100 possibilities, the attacker only needs to consider 20.'
  },
  '344': {
    name: 'Use of Invariant Value in Dynamically Changing Context',
    link: 'https://cwe.mitre.org/data/definitions/344.html',
    description:
      'The product uses a constant value, name, or reference, but this value can (or should) vary across different environments.'
  },
  '345': {
    name: 'Insufficient Verification of Data Authenticity',
    link: 'https://cwe.mitre.org/data/definitions/345.html',
    description:
      'The product does not sufficiently verify the origin or authenticity of data, in a way that causes it to accept invalid data.'
  },
  '346': {
    name: 'Origin Validation Error',
    link: 'https://cwe.mitre.org/data/definitions/346.html',
    description: 'The product does not properly verify that the source of data or communication is valid.'
  },
  '347': {
    name: 'Improper Verification of Cryptographic Signature',
    link: 'https://cwe.mitre.org/data/definitions/347.html',
    description: 'The product does not verify, or incorrectly verifies, the cryptographic signature for data.'
  },
  '348': {
    name: 'Use of Less Trusted Source',
    link: 'https://cwe.mitre.org/data/definitions/348.html',
    description:
      'The product has two different sources of the same data or information, but it uses the source that has less support for verification, is less trusted, or is less resistant to attack.'
  },
  '349': {
    name: 'Acceptance of Extraneous Untrusted Data With Trusted Data',
    link: 'https://cwe.mitre.org/data/definitions/349.html',
    description:
      'The product, when processing trusted data, accepts any untrusted data that is also included with the trusted data, treating the untrusted data as if it were trusted.'
  },
  '35': {
    name: "Path Traversal: '.../...//'",
    link: 'https://cwe.mitre.org/data/definitions/35.html',
    description: '\n            '
  },
  '350': {
    name: 'Reliance on Reverse DNS Resolution for a Security-Critical Action',
    link: 'https://cwe.mitre.org/data/definitions/350.html',
    description: '\n            '
  },
  '351': {
    name: 'Insufficient Type Distinction',
    link: 'https://cwe.mitre.org/data/definitions/351.html',
    description:
      'The product does not properly distinguish between different types of elements in a way that leads to insecure behavior.'
  },
  '352': {
    name: 'Cross-Site Request Forgery (CSRF)',
    link: 'https://cwe.mitre.org/data/definitions/352.html',
    description:
      'When a web server is designed to receive a request from a client without any mechanism for verifying that it was intentionally sent, then it might be possible for an attacker to trick a client into making an unintentional request to the web server which will be treated as an authentic request. This can be done via a URL, image load, XMLHttpRequest, etc. and can result in exposure of data or unintended code execution.'
  },
  '353': {
    name: 'Missing Support for Integrity Check',
    link: 'https://cwe.mitre.org/data/definitions/353.html',
    description:
      'If integrity check values or "checksums" are omitted from a protocol, there is no way of determining if data has been corrupted in transmission. The lack of checksum functionality in a protocol removes the first application-level check of data that can be used. The end-to-end philosophy of checks states that integrity checks should be performed at the lowest level that they can be completely implemented. Excluding further sanity checks and input validation performed by applications, the protocol\'s checksum is the most important level of checksum, since it can be performed more completely than at any previous level and takes into account entire messages, as opposed to single packets.'
  },
  '354': {
    name: 'Improper Validation of Integrity Check Value',
    link: 'https://cwe.mitre.org/data/definitions/354.html',
    description:
      'Improper validation of checksums before use results in an unnecessary risk that can easily be mitigated. The protocol specification describes the algorithm used for calculating the checksum. It is then a simple matter of implementing the calculation and verifying that the calculated checksum and the received checksum match. Improper verification of the calculated checksum and the received checksum can lead to far greater consequences.'
  },
  '356': {
    name: 'Product UI does not Warn User of Unsafe Actions',
    link: 'https://cwe.mitre.org/data/definitions/356.html',
    description:
      "Product systems should warn users that a potentially dangerous action may occur if the user proceeds. For example, if the user downloads a file from an unknown source and attempts to execute the file on their machine, then the application's GUI can indicate that the file is unsafe."
  },
  '357': {
    name: 'Insufficient UI Warning of Dangerous Operations',
    link: 'https://cwe.mitre.org/data/definitions/357.html',
    description:
      'The user interface provides a warning to a user regarding dangerous or sensitive operations, but the warning is not noticeable enough to warrant attention.'
  },
  '358': {
    name: 'Improperly Implemented Security Check for Standard',
    link: 'https://cwe.mitre.org/data/definitions/358.html',
    description:
      'The product does not implement or incorrectly implements one or more security-relevant checks as specified by the design of a standardized algorithm, protocol, or technique.'
  },
  '359': {
    name: 'Exposure of Private Personal Information to an Unauthorized Actor',
    link: 'https://cwe.mitre.org/data/definitions/359.html',
    description: '\n            '
  },
  '36': {
    name: 'Absolute Path Traversal',
    link: 'https://cwe.mitre.org/data/definitions/36.html',
    description:
      'This allows attackers to traverse the file system to access files or directories that are outside of the restricted directory.'
  },
  '360': {
    name: 'Trust of System Event Data',
    link: 'https://cwe.mitre.org/data/definitions/360.html',
    description:
      'Events are a messaging system which may provide control data to programs listening for events. Events often do not have any type of authentication framework to allow them to be verified from a trusted source. Any application, in Windows, on a given desktop can send a message to any window on the same desktop. There is no authentication framework for these messages. Therefore, any message can be used to manipulate any process on the desktop if the process does not check the validity and safeness of those messages.'
  },
  '362': {
    name: "Concurrent Execution using Shared Resource with Improper Synchronization ('Race Condition')",
    link: 'https://cwe.mitre.org/data/definitions/362.html',
    description: '\n            '
  },
  '363': {
    name: 'Race Condition Enabling Link Following',
    link: 'https://cwe.mitre.org/data/definitions/363.html',
    description:
      'While developers might expect that there is a very narrow time window between the time of check and time of use, there is still a race condition. An attacker could cause the product to slow down (e.g. with memory consumption), causing the time window to become larger. Alternately, in some situations, the attacker could win the race by performing a large number of attacks.'
  },
  '364': {
    name: 'Signal Handler Race Condition',
    link: 'https://cwe.mitre.org/data/definitions/364.html',
    description: '\n            '
  },
  '365': {
    name: 'DEPRECATED: Race Condition in Switch',
    link: 'https://cwe.mitre.org/data/definitions/365.html',
    description:
      'It is likely that this entry was initially created based on a misinterpretation of the original source material. The original source intended to explain how switches could be unpredictable when using threads, if the control expressions used data or variables that could change between execution of different threads. That weakness is already covered by CWE-367. Despite the ambiguity in the documentation for some languages and compilers, in practice, they all evaluate the switch control expression only once. If future languages state that the code explicitly evaluates the control expression more than once, then this would not be a weakness, but the language performing as designed.'
  },
  '366': {
    name: 'Race Condition within a Thread',
    link: 'https://cwe.mitre.org/data/definitions/366.html',
    description:
      'If two threads of execution use a resource simultaneously, there exists the possibility that resources may be used while invalid, in turn making the state of execution undefined.'
  },
  '367': {
    name: 'Time-of-check Time-of-use (TOCTOU) Race Condition',
    link: 'https://cwe.mitre.org/data/definitions/367.html',
    description:
      'This weakness can be security-relevant when an attacker can influence the state of the resource between check and use. This can happen with shared resources such as files, memory, or even variables in multithreaded programs.'
  },
  '368': {
    name: 'Context Switching Race Condition',
    link: 'https://cwe.mitre.org/data/definitions/368.html',
    description:
      'This is commonly seen in web browser vulnerabilities in which the attacker can perform certain actions while the browser is transitioning from a trusted to an untrusted domain, or vice versa, and the browser performs the actions on one domain using the trust level and resources of the other domain.'
  },
  '369': {
    name: 'Divide By Zero',
    link: 'https://cwe.mitre.org/data/definitions/369.html',
    description:
      'This weakness typically occurs when an unexpected value is provided to the product, or if an error occurs that is not properly detected. It frequently occurs in calculations involving physical dimensions such as size, length, width, and height.'
  },
  '37': {
    name: "Path Traversal: '/absolute/pathname/here'",
    link: 'https://cwe.mitre.org/data/definitions/37.html',
    description:
      "The product accepts input in the form of a slash absolute path ('/absolute/pathname/here') without appropriate validation, which can allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '370': {
    name: 'Missing Check for Certificate Revocation after Initial Check',
    link: 'https://cwe.mitre.org/data/definitions/370.html',
    description:
      'If the revocation status of a certificate is not checked before each action that requires privileges, the system may be subject to a race condition. If a certificate is revoked after the initial check, all subsequent actions taken with the owner of the revoked certificate will lose all benefits guaranteed by the certificate. In fact, it is almost certain that the use of a revoked certificate indicates malicious activity.'
  },
  '372': {
    name: 'Incomplete Internal State Distinction',
    link: 'https://cwe.mitre.org/data/definitions/372.html',
    description:
      'The product does not properly determine which state it is in, causing it to assume it is in state X when in fact it is in state Y, causing it to perform incorrect operations in a security-relevant manner.'
  },
  '373': {
    name: 'DEPRECATED: State Synchronization Error',
    link: 'https://cwe.mitre.org/data/definitions/373.html',
    description:
      'This entry was deprecated because it overlapped the same concepts as race condition (CWE-362) and Improper Synchronization (CWE-662).'
  },
  '374': {
    name: 'Passing Mutable Objects to an Untrusted Method',
    link: 'https://cwe.mitre.org/data/definitions/374.html',
    description:
      'The function or method that has been called can alter or delete the mutable data. This could violate assumptions that the calling function has made about its state. In situations where unknown code is called with references to mutable data, this external code could make changes to the data sent. If this data was not previously cloned, the modified data might not be valid in the context of execution.'
  },
  '375': {
    name: 'Returning a Mutable Object to an Untrusted Caller',
    link: 'https://cwe.mitre.org/data/definitions/375.html',
    description:
      'In situations where functions return references to mutable data, it is possible that the external code which called the function may make changes to the data sent. If this data was not previously cloned, the class will then be using modified data which may violate assumptions about its internal state.'
  },
  '377': {
    name: 'Insecure Temporary File',
    link: 'https://cwe.mitre.org/data/definitions/377.html',
    description:
      'Creating and using insecure temporary files can leave application and system data vulnerable to attack.'
  },
  '378': {
    name: 'Creation of Temporary File With Insecure Permissions',
    link: 'https://cwe.mitre.org/data/definitions/378.html',
    description:
      'Opening temporary files without appropriate measures or controls can leave the file, its contents and any function that it impacts vulnerable to attack.'
  },
  '379': {
    name: 'Creation of Temporary File in Directory with Insecure Permissions',
    link: 'https://cwe.mitre.org/data/definitions/379.html',
    description:
      "On some operating systems, the fact that the temporary file exists may be apparent to any user with sufficient privileges to access that directory. Since the file is visible, the application that is using the temporary file could be known. If one has access to list the processes on the system, the attacker has gained information about what the user is doing at that time. By correlating this with the applications the user is running, an attacker could potentially discover what a user's actions are. From this, higher levels of security could be breached."
  },
  '38': {
    name: "Path Traversal: '\\absolute\\pathname\\here'",
    link: 'https://cwe.mitre.org/data/definitions/38.html',
    description:
      "The product accepts input in the form of a backslash absolute path ('\\absolute\\pathname\\here') without appropriate validation, which can allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '382': {
    name: 'J2EE Bad Practices: Use of System.exit()',
    link: 'https://cwe.mitre.org/data/definitions/382.html',
    description:
      'It is never a good idea for a web application to attempt to shut down the application container. Access to a function that can shut down the application is an avenue for Denial of Service (DoS) attacks.'
  },
  '383': {
    name: 'J2EE Bad Practices: Direct Use of Threads',
    link: 'https://cwe.mitre.org/data/definitions/383.html',
    description:
      'Thread management in a web application is forbidden by the J2EE standard in some circumstances and is always highly error prone. Managing threads is difficult and is likely to interfere in unpredictable ways with the behavior of the application container. Even without interfering with the container, thread management usually leads to bugs that are hard to detect and diagnose like deadlock, race conditions, and other synchronization errors.'
  },
  '384': {
    name: 'Session Fixation',
    link: 'https://cwe.mitre.org/data/definitions/384.html',
    description: '\n            '
  },
  '385': {
    name: 'Covert Timing Channel',
    link: 'https://cwe.mitre.org/data/definitions/385.html',
    description: '\n            '
  },
  '386': {
    name: 'Symbolic Name not Mapping to Correct Object',
    link: 'https://cwe.mitre.org/data/definitions/386.html',
    description:
      'A constant symbolic reference to an object is used, even though the reference can resolve to a different object over time.'
  },
  '39': {
    name: "Path Traversal: 'C:dirname'",
    link: 'https://cwe.mitre.org/data/definitions/39.html',
    description:
      "The product accepts input that contains a drive letter or Windows volume letter ('C:dirname') that potentially redirects access to an unintended location or arbitrary file."
  },
  '390': {
    name: 'Detection of Error Condition Without Action',
    link: 'https://cwe.mitre.org/data/definitions/390.html',
    description: 'The product detects a specific error, but takes no actions to handle the error.'
  },
  '391': {
    name: 'Unchecked Error Condition',
    link: 'https://cwe.mitre.org/data/definitions/391.html',
    description:
      '[PLANNED FOR DEPRECATION. SEE MAINTENANCE NOTES AND CONSIDER CWE-252, CWE-248, OR CWE-1069.] Ignoring exceptions and other error conditions may allow an attacker to induce unexpected behavior unnoticed.'
  },
  '392': {
    name: 'Missing Report of Error Condition',
    link: 'https://cwe.mitre.org/data/definitions/392.html',
    description:
      'The product encounters an error but does not provide a status code or return value to indicate that an error has occurred.'
  },
  '393': {
    name: 'Return of Wrong Status Code',
    link: 'https://cwe.mitre.org/data/definitions/393.html',
    description:
      'This can lead to unpredictable behavior. If the function is used to make security-critical decisions or provide security-critical information, then the wrong status code can cause the product to assume that an action is safe, even when it is not.'
  },
  '394': {
    name: 'Unexpected Status Code or Return Value',
    link: 'https://cwe.mitre.org/data/definitions/394.html',
    description:
      'The product does not properly check when a function or operation returns a value that is legitimate for the function, but is not expected by the product.'
  },
  '395': {
    name: 'Use of NullPointerException Catch to Detect NULL Pointer Dereference',
    link: 'https://cwe.mitre.org/data/definitions/395.html',
    description: '\n            '
  },
  '396': {
    name: 'Declaration of Catch for Generic Exception',
    link: 'https://cwe.mitre.org/data/definitions/396.html',
    description:
      'Multiple catch blocks can get ugly and repetitive, but "condensing" catch blocks by catching a high-level class like Exception can obscure exceptions that deserve special treatment or that should not be caught at this point in the program. Catching an overly broad exception essentially defeats the purpose of a language\'s typed exceptions, and can become particularly dangerous if the program grows and begins to throw new types of exceptions. The new exception types will not receive any attention.'
  },
  '397': {
    name: 'Declaration of Throws for Generic Exception',
    link: 'https://cwe.mitre.org/data/definitions/397.html',
    description:
      "Declaring a method to throw Exception or Throwable makes it difficult for callers to perform proper error handling and error recovery. Java's exception mechanism, for example, is set up to make it easy for callers to anticipate what can go wrong and write code to handle each specific exceptional circumstance. Declaring that a method throws a generic form of exception defeats this system."
  },
  '40': {
    name: "Path Traversal: '\\\\UNC\\share\\name\\' (Windows UNC Share)",
    link: 'https://cwe.mitre.org/data/definitions/40.html',
    description:
      "The product accepts input that identifies a Windows UNC share ('\\\\UNC\\share\\name') that potentially redirects access to an unintended location or arbitrary file."
  },
  '400': {
    name: 'Uncontrolled Resource Consumption',
    link: 'https://cwe.mitre.org/data/definitions/400.html',
    description: '\n            '
  },
  '401': {
    name: 'Missing Release of Memory after Effective Lifetime',
    link: 'https://cwe.mitre.org/data/definitions/401.html',
    description:
      'This is often triggered by improper handling of malformed data or unexpectedly interrupted sessions.  In some languages, developers are responsible for tracking memory allocation and releasing the memory.  If there are no more pointers or references to the memory, then it can no longer be tracked and identified for release.'
  },
  '402': {
    name: "Transmission of Private Resources into a New Sphere ('Resource Leak')",
    link: 'https://cwe.mitre.org/data/definitions/402.html',
    description:
      'The product makes resources available to untrusted parties when those resources are only intended to be accessed by the product.'
  },
  '403': {
    name: "Exposure of File Descriptor to Unintended Control Sphere ('File Descriptor Leak')",
    link: 'https://cwe.mitre.org/data/definitions/403.html',
    description:
      'When a new process is forked or executed, the child process inherits any open file descriptors. When the child process has fewer privileges than the parent process, this might introduce a vulnerability if the child process can access the file descriptor but does not have the privileges to access the associated file.'
  },
  '404': {
    name: 'Improper Resource Shutdown or Release',
    link: 'https://cwe.mitre.org/data/definitions/404.html',
    description:
      'When a resource is created or allocated, the developer is responsible for properly releasing the resource as well as accounting for all potential paths of expiration or invalidation, such as a set period of time or revocation.'
  },
  '405': {
    name: 'Asymmetric Resource Consumption (Amplification)',
    link: 'https://cwe.mitre.org/data/definitions/405.html',
    description:
      'This can lead to poor performance due to "amplification" of resource consumption, typically in a non-linear fashion.  This situation is worsened if the product allows malicious users or attackers to consume more resources than their access level permits.'
  },
  '406': {
    name: 'Insufficient Control of Network Message Volume (Network Amplification)',
    link: 'https://cwe.mitre.org/data/definitions/406.html',
    description:
      "In the absence of a policy to restrict asymmetric resource consumption, the application or system cannot distinguish between legitimate transmissions and traffic intended to serve as an amplifying attack on target systems. Systems can often be configured to restrict the amount of traffic sent out on behalf of a client, based on the client's origin or access level. This is usually defined in a resource allocation policy. In the absence of a mechanism to keep track of transmissions, the system or application can be easily abused to transmit asymmetrically greater traffic than the request or client should be permitted to."
  },
  '407': {
    name: 'Inefficient Algorithmic Complexity',
    link: 'https://cwe.mitre.org/data/definitions/407.html',
    description:
      'An algorithm in a product has an inefficient worst-case computational complexity that may be detrimental to system performance and can be triggered by an attacker, typically using crafted manipulations that ensure that the worst case is being reached.'
  },
  '408': {
    name: 'Incorrect Behavior Order: Early Amplification',
    link: 'https://cwe.mitre.org/data/definitions/408.html',
    description:
      'The product allows an entity to perform a legitimate but expensive operation before authentication or authorization has taken place.'
  },
  '409': {
    name: 'Improper Handling of Highly Compressed Data (Data Amplification)',
    link: 'https://cwe.mitre.org/data/definitions/409.html',
    description:
      'An example of data amplification is a "decompression bomb," a small ZIP file that can produce a large amount of data when it is decompressed.'
  },
  '41': {
    name: 'Improper Resolution of Path Equivalence',
    link: 'https://cwe.mitre.org/data/definitions/41.html',
    description:
      'Path equivalence is usually employed in order to circumvent access controls expressed using an incomplete set of file name or file path representations. This is different from path traversal, wherein the manipulations are performed to generate a name for a different object.'
  },
  '410': {
    name: 'Insufficient Resource Pool',
    link: 'https://cwe.mitre.org/data/definitions/410.html',
    description: 'Frequently the consequence is a "flood" of connection or sessions.'
  },
  '412': {
    name: 'Unrestricted Externally Accessible Lock',
    link: 'https://cwe.mitre.org/data/definitions/412.html',
    description:
      'This prevents the product from acting on associated resources or performing other behaviors that are controlled by the presence of the lock. Relevant locks might include an exclusive lock or mutex, or modifying a shared resource that is treated as a lock. If the lock can be held for an indefinite period of time, then the denial of service could be permanent.'
  },
  '413': {
    name: 'Improper Resource Locking',
    link: 'https://cwe.mitre.org/data/definitions/413.html',
    description:
      "When a resource is not properly locked, an attacker could modify the resource while it is being operated on by the product. This might violate the product's assumption that the resource will not change, potentially leading to unexpected behaviors."
  },
  '414': {
    name: 'Missing Lock Check',
    link: 'https://cwe.mitre.org/data/definitions/414.html',
    description:
      'A product does not check to see if a lock is present before performing sensitive operations on a resource.'
  },
  '415': {
    name: 'Double Free',
    link: 'https://cwe.mitre.org/data/definitions/415.html',
    description:
      "When a program calls free() twice with the same argument, the program's memory management data structures become corrupted. This corruption can cause the program to crash or, in some circumstances, cause two later calls to malloc() to return the same pointer. If malloc() returns the same value twice and the program later gives the attacker control over the data that is written into this doubly-allocated memory, the program becomes vulnerable to a buffer overflow attack."
  },
  '416': {
    name: 'Use After Free',
    link: 'https://cwe.mitre.org/data/definitions/416.html',
    description: '\n            '
  },
  '419': {
    name: 'Unprotected Primary Channel',
    link: 'https://cwe.mitre.org/data/definitions/419.html',
    description:
      'The product uses a primary channel for administration or restricted functionality, but it does not properly protect the channel.'
  },
  '42': {
    name: "Path Equivalence: 'filename.' (Trailing Dot)",
    link: 'https://cwe.mitre.org/data/definitions/42.html',
    description:
      "The product accepts path input in the form of trailing dot ('filedir.') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '420': {
    name: 'Unprotected Alternate Channel',
    link: 'https://cwe.mitre.org/data/definitions/420.html',
    description:
      'The product protects a primary channel, but it does not use the same level of protection for an alternate channel.'
  },
  '421': {
    name: 'Race Condition During Access to Alternate Channel',
    link: 'https://cwe.mitre.org/data/definitions/421.html',
    description:
      'This creates a race condition that allows an attacker to access the channel before the authorized user does.'
  },
  '422': {
    name: "Unprotected Windows Messaging Channel ('Shatter')",
    link: 'https://cwe.mitre.org/data/definitions/422.html',
    description:
      'The product does not properly verify the source of a message in the Windows Messaging System while running at elevated privileges, creating an alternate channel through which an attacker can directly send a message to the product.'
  },
  '423': {
    name: 'DEPRECATED: Proxied Trusted Channel',
    link: 'https://cwe.mitre.org/data/definitions/423.html',
    description:
      'This entry has been deprecated because it was a duplicate of CWE-441. All content has been transferred to CWE-441.'
  },
  '424': {
    name: 'Improper Protection of Alternate Path',
    link: 'https://cwe.mitre.org/data/definitions/424.html',
    description:
      'The product does not sufficiently protect all possible paths that a user can take to access restricted functionality or resources.'
  },
  '425': {
    name: "Direct Request ('Forced Browsing')",
    link: 'https://cwe.mitre.org/data/definitions/425.html',
    description:
      'Web applications susceptible to direct request attacks often make the false assumption that such resources can only be reached through a given navigation path and so only apply authorization at certain points in the path.'
  },
  '426': {
    name: 'Untrusted Search Path',
    link: 'https://cwe.mitre.org/data/definitions/426.html',
    description: '\n            '
  },
  '427': {
    name: 'Uncontrolled Search Path Element',
    link: 'https://cwe.mitre.org/data/definitions/427.html',
    description: '\n            '
  },
  '428': {
    name: 'Unquoted Search Path or Element',
    link: 'https://cwe.mitre.org/data/definitions/428.html',
    description:
      'If a malicious individual has access to the file system, it is possible to elevate privileges by inserting such a file as "C:\\Program.exe" to be run by a privileged program making use of WinExec.'
  },
  '43': {
    name: "Path Equivalence: 'filename....' (Multiple Trailing Dot)",
    link: 'https://cwe.mitre.org/data/definitions/43.html',
    description:
      "The product accepts path input in the form of multiple trailing dot ('filedir....') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '430': {
    name: 'Deployment of Wrong Handler',
    link: 'https://cwe.mitre.org/data/definitions/430.html',
    description:
      'An example of deploying the wrong handler would be calling a servlet to reveal source code of a .JSP file, or automatically "determining" type of the object even if it is contradictory to an explicitly specified type.'
  },
  '431': {
    name: 'Missing Handler',
    link: 'https://cwe.mitre.org/data/definitions/431.html',
    description:
      'When an exception is thrown and not caught, the process has given up an opportunity to decide if a given failure or event is worth a change in execution.'
  },
  '432': {
    name: 'Dangerous Signal Handler not Disabled During Sensitive Operations',
    link: 'https://cwe.mitre.org/data/definitions/432.html',
    description:
      'During the execution of a signal handler, it can be interrupted by another handler when a different signal is sent. If the two handlers share state - such as global variables - then an attacker can corrupt the state by sending another signal before the first handler has completed execution.'
  },
  '433': {
    name: 'Unparsed Raw Web Content Delivery',
    link: 'https://cwe.mitre.org/data/definitions/433.html',
    description:
      'If code is stored in a file with an extension such as ".inc" or ".pl", and the web server does not have a handler for that extension, then the server will likely send the contents of the file directly to the requester without the pre-processing that was expected. When that file contains sensitive information such as database credentials, this may allow the attacker to compromise the application or associated components.'
  },
  '434': {
    name: 'Unrestricted Upload of File with Dangerous Type',
    link: 'https://cwe.mitre.org/data/definitions/434.html',
    description:
      "The product allows the attacker to upload or transfer files of dangerous types that can be automatically processed within the product's environment."
  },
  '435': {
    name: 'Improper Interaction Between Multiple Correctly-Behaving Entities',
    link: 'https://cwe.mitre.org/data/definitions/435.html',
    description:
      'When a system or process combines multiple independent components, this often produces new, emergent behaviors at the system level.  However, if the interactions between these components are not fully accounted for, some of the emergent behaviors can be incorrect or even insecure.'
  },
  '436': {
    name: 'Interpretation Conflict',
    link: 'https://cwe.mitre.org/data/definitions/436.html',
    description:
      'This is generally found in proxies, firewalls, anti-virus software, and other intermediary devices that monitor, allow, deny, or modify traffic based on how the client or server is expected to behave.'
  },
  '437': {
    name: 'Incomplete Model of Endpoint Features',
    link: 'https://cwe.mitre.org/data/definitions/437.html',
    description:
      "A product acts as an intermediary or monitor between two or more endpoints, but it does not have a complete model of an endpoint's features, behaviors, or state, potentially causing the product to perform incorrect actions based on this incomplete model."
  },
  '439': {
    name: 'Behavioral Change in New Version or Environment',
    link: 'https://cwe.mitre.org/data/definitions/439.html',
    description:
      "A's behavior or functionality changes with a new version of A, or a new environment, which is not known (or manageable) by B."
  },
  '44': {
    name: "Path Equivalence: 'file.name' (Internal Dot)",
    link: 'https://cwe.mitre.org/data/definitions/44.html',
    description:
      "The product accepts path input in the form of internal dot ('file.ordir') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '440': {
    name: 'Expected Behavior Violation',
    link: 'https://cwe.mitre.org/data/definitions/440.html',
    description: 'A feature, API, or function does not perform according to its specification.'
  },
  '441': {
    name: "Unintended Proxy or Intermediary ('Confused Deputy')",
    link: 'https://cwe.mitre.org/data/definitions/441.html',
    description: '\n            '
  },
  '443': {
    name: 'DEPRECATED: HTTP response splitting',
    link: 'https://cwe.mitre.org/data/definitions/443.html',
    description: 'This weakness can be found at CWE-113.'
  },
  '444': {
    name: "Inconsistent Interpretation of HTTP Requests ('HTTP Request/Response Smuggling')",
    link: 'https://cwe.mitre.org/data/definitions/444.html',
    description: '\n\t   '
  },
  '446': {
    name: 'UI Discrepancy for Security Feature',
    link: 'https://cwe.mitre.org/data/definitions/446.html',
    description:
      'When the user interface does not properly reflect what the user asks of it, then it can lead the user into a false sense of security. For example, the user might check a box to enable a security option to enable encrypted communications, but the product does not actually enable the encryption. Alternately, the user might provide a "restrict ALL" access control rule, but the product only implements "restrict SOME".'
  },
  '447': {
    name: 'Unimplemented or Unsupported Feature in UI',
    link: 'https://cwe.mitre.org/data/definitions/447.html',
    description:
      'A UI function for a security feature appears to be supported and gives feedback to the user that suggests that it is supported, but the underlying functionality is not implemented.'
  },
  '448': {
    name: 'Obsolete Feature in UI',
    link: 'https://cwe.mitre.org/data/definitions/448.html',
    description: 'A UI function is obsolete and the product does not warn the user.'
  },
  '449': {
    name: 'The UI Performs the Wrong Action',
    link: 'https://cwe.mitre.org/data/definitions/449.html',
    description: "The UI performs the wrong action with respect to the user's request."
  },
  '45': {
    name: "Path Equivalence: 'file...name' (Multiple Internal Dot)",
    link: 'https://cwe.mitre.org/data/definitions/45.html',
    description:
      "The product accepts path input in the form of multiple internal dot ('file...dir') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '450': {
    name: 'Multiple Interpretations of UI Input',
    link: 'https://cwe.mitre.org/data/definitions/450.html',
    description:
      'The UI has multiple interpretations of user input but does not prompt the user when it selects the less secure interpretation.'
  },
  '451': {
    name: 'User Interface (UI) Misrepresentation of Critical Information',
    link: 'https://cwe.mitre.org/data/definitions/451.html',
    description: '\n            '
  },
  '453': {
    name: 'Insecure Default Variable Initialization',
    link: 'https://cwe.mitre.org/data/definitions/453.html',
    description:
      'The product, by default, initializes an internal variable with an insecure or less secure value than is possible.'
  },
  '454': {
    name: 'External Initialization of Trusted Variables or Data Stores',
    link: 'https://cwe.mitre.org/data/definitions/454.html',
    description:
      'A product system should be reluctant to trust variables that have been initialized outside of its trust boundary, especially if they are initialized by users. The variables may have been initialized incorrectly. If an attacker can initialize the variable, then they can influence what the vulnerable system will do.'
  },
  '455': {
    name: 'Non-exit on Failed Initialization',
    link: 'https://cwe.mitre.org/data/definitions/455.html',
    description:
      'The product does not exit or otherwise modify its operation when security-relevant errors occur during initialization, such as when a configuration file has a format error or a hardware security module (HSM) cannot be activated, which can cause the product to execute in a less secure fashion than intended by the administrator.'
  },
  '456': {
    name: 'Missing Initialization of a Variable',
    link: 'https://cwe.mitre.org/data/definitions/456.html',
    description:
      'The product does not initialize critical variables, which causes the execution environment to use unexpected values.'
  },
  '457': {
    name: 'Use of Uninitialized Variable',
    link: 'https://cwe.mitre.org/data/definitions/457.html',
    description:
      'In some languages such as C and C++, stack variables are not initialized by default. They generally contain junk data with the contents of stack memory before the function was invoked. An attacker can sometimes control or read these contents. In other languages or conditions, a variable that is not explicitly initialized can be given a default value that has security implications, depending on the logic of the program. The presence of an uninitialized variable can sometimes indicate a typographic error in the code.'
  },
  '458': {
    name: 'DEPRECATED: Incorrect Initialization',
    link: 'https://cwe.mitre.org/data/definitions/458.html',
    description:
      'This weakness has been deprecated because its name and description did not match. The description duplicated CWE-454, while the name suggested a more abstract initialization problem. Please refer to CWE-665 for the more abstract problem.'
  },
  '459': {
    name: 'Incomplete Cleanup',
    link: 'https://cwe.mitre.org/data/definitions/459.html',
    description:
      'The product does not properly "clean up" and remove temporary or supporting resources after they have been used.'
  },
  '46': {
    name: "Path Equivalence: 'filename ' (Trailing Space)",
    link: 'https://cwe.mitre.org/data/definitions/46.html',
    description:
      "The product accepts path input in the form of trailing space ('filedir ') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '460': {
    name: 'Improper Cleanup on Thrown Exception',
    link: 'https://cwe.mitre.org/data/definitions/460.html',
    description:
      'Often, when functions or loops become complicated, some level of resource cleanup is needed throughout execution. Exceptions can disturb the flow of the code and prevent the necessary cleanup from happening.'
  },
  '462': {
    name: 'Duplicate Key in Associative List (Alist)',
    link: 'https://cwe.mitre.org/data/definitions/462.html',
    description:
      'A duplicate key entry -- if the alist is designed properly -- could be used as a constant time replace function. However, duplicate key entries could be inserted by mistake. Because of this ambiguity, duplicate key entries in an association list are not recommended and should not be allowed.'
  },
  '463': {
    name: 'Deletion of Data Structure Sentinel',
    link: 'https://cwe.mitre.org/data/definitions/463.html',
    description:
      'Often times data-structure sentinels are used to mark structure of the data structure. A common example of this is the null character at the end of strings. Another common example is linked lists which may contain a sentinel to mark the end of the list. It is dangerous to allow this type of control data to be easily accessible. Therefore, it is important to protect from the deletion or modification outside of some wrapper interface which provides safety.'
  },
  '464': {
    name: 'Addition of Data Structure Sentinel',
    link: 'https://cwe.mitre.org/data/definitions/464.html',
    description:
      'Data-structure sentinels are often used to mark the structure of data. A common example of this is the null character at the end of strings or a special sentinel to mark the end of a linked list. It is dangerous to allow this type of control data to be easily accessible. Therefore, it is important to protect from the addition or modification of sentinels.'
  },
  '466': {
    name: 'Return of Pointer Value Outside of Expected Range',
    link: 'https://cwe.mitre.org/data/definitions/466.html',
    description:
      'A function can return a pointer to memory that is outside of the buffer that the pointer is expected to reference.'
  },
  '467': {
    name: 'Use of sizeof() on a Pointer Type',
    link: 'https://cwe.mitre.org/data/definitions/467.html',
    description:
      'The use of sizeof() on a pointer can sometimes generate useful information. An obvious case is to find out the wordsize on a platform. More often than not, the appearance of sizeof(pointer) indicates a bug.'
  },
  '468': {
    name: 'Incorrect Pointer Scaling',
    link: 'https://cwe.mitre.org/data/definitions/468.html',
    description:
      'In C and C++, one may often accidentally refer to the wrong memory due to the semantics of when math operations are implicitly scaled.'
  },
  '469': {
    name: 'Use of Pointer Subtraction to Determine Size',
    link: 'https://cwe.mitre.org/data/definitions/469.html',
    description:
      'The product subtracts one pointer from another in order to determine size, but this calculation can be incorrect if the pointers do not exist in the same memory chunk.'
  },
  '47': {
    name: "Path Equivalence: ' filename' (Leading Space)",
    link: 'https://cwe.mitre.org/data/definitions/47.html',
    description:
      "The product accepts path input in the form of leading space (' filedir') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '470': {
    name: "Use of Externally-Controlled Input to Select Classes or Code ('Unsafe Reflection')",
    link: 'https://cwe.mitre.org/data/definitions/470.html',
    description:
      "If the product uses external inputs to determine which class to instantiate or which method to invoke, then an attacker could supply values to select unexpected classes or methods. If this occurs, then the attacker could create control flow paths that were not intended by the developer. These paths could bypass authentication or access control checks, or otherwise cause the product to behave in an unexpected manner. This situation becomes a doomsday scenario if the attacker can upload files into a location that appears on the product's classpath (CWE-427) or add new entries to the product's classpath (CWE-426). Under either of these conditions, the attacker can use reflection to introduce new, malicious behavior into the product."
  },
  '471': {
    name: 'Modification of Assumed-Immutable Data (MAID)',
    link: 'https://cwe.mitre.org/data/definitions/471.html',
    description:
      'This occurs when a particular input is critical enough to the functioning of the application that it should not be modifiable at all, but it is. Certain resources are often assumed to be immutable when they are not, such as hidden form fields in web applications, cookies, and reverse DNS lookups.'
  },
  '472': {
    name: 'External Control of Assumed-Immutable Web Parameter',
    link: 'https://cwe.mitre.org/data/definitions/472.html',
    description: '\n            '
  },
  '473': {
    name: 'PHP External Variable Modification',
    link: 'https://cwe.mitre.org/data/definitions/473.html',
    description:
      'A PHP application does not properly protect against the modification of variables from external sources, such as query parameters or cookies. This can expose the application to numerous weaknesses that would not exist otherwise.'
  },
  '474': {
    name: 'Use of Function with Inconsistent Implementations',
    link: 'https://cwe.mitre.org/data/definitions/474.html',
    description: '\n            '
  },
  '475': {
    name: 'Undefined Behavior for Input to API',
    link: 'https://cwe.mitre.org/data/definitions/475.html',
    description: 'The behavior of this function is undefined unless its control parameter is set to a specific value.'
  },
  '476': {
    name: 'NULL Pointer Dereference',
    link: 'https://cwe.mitre.org/data/definitions/476.html',
    description:
      'NULL pointer dereference issues can occur through a number of flaws, including race conditions, and simple programming omissions.'
  },
  '477': {
    name: 'Use of Obsolete Function',
    link: 'https://cwe.mitre.org/data/definitions/477.html',
    description: '\n            '
  },
  '478': {
    name: 'Missing Default Case in Multiple Condition Expression',
    link: 'https://cwe.mitre.org/data/definitions/478.html',
    description:
      'If a multiple-condition expression (such as a switch in C) omits the default case but does not consider or handle all possible values that could occur, then this might lead to complex logical errors and resultant weaknesses. Because of this, further decisions are made based on poor information, and cascading failure results. This cascading failure may result in any number of security issues, and constitutes a significant failure in the system.'
  },
  '479': {
    name: 'Signal Handler Use of a Non-reentrant Function',
    link: 'https://cwe.mitre.org/data/definitions/479.html',
    description: '\n            '
  },
  '48': {
    name: "Path Equivalence: 'file name' (Internal Whitespace)",
    link: 'https://cwe.mitre.org/data/definitions/48.html',
    description:
      "The product accepts path input in the form of internal space ('file(SPACE)name') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '480': {
    name: 'Use of Incorrect Operator',
    link: 'https://cwe.mitre.org/data/definitions/480.html',
    description: 'These types of errors are generally the result of a typo by the programmer.'
  },
  '481': {
    name: 'Assigning instead of Comparing',
    link: 'https://cwe.mitre.org/data/definitions/481.html',
    description:
      'In many languages the compare statement is very close in appearance to the assignment statement and are often confused. This bug is generally the result of a typo and usually causes obvious problems with program execution. If the comparison is in an if statement, the if statement will usually evaluate the value of the right-hand side of the predicate.'
  },
  '482': {
    name: 'Comparing instead of Assigning',
    link: 'https://cwe.mitre.org/data/definitions/482.html',
    description:
      'In many languages, the compare statement is very close in appearance to the assignment statement; they are often confused.'
  },
  '483': {
    name: 'Incorrect Block Delimitation',
    link: 'https://cwe.mitre.org/data/definitions/483.html',
    description:
      'In some languages, braces (or other delimiters) are optional for blocks. When the delimiter is omitted, it is possible to insert a logic error in which a statement is thought to be in a block but is not. In some cases, the logic error can have security implications.'
  },
  '484': {
    name: 'Omitted Break Statement in Switch',
    link: 'https://cwe.mitre.org/data/definitions/484.html',
    description: 'This can lead to critical code executing in situations where it should not.'
  },
  '486': {
    name: 'Comparison of Classes by Name',
    link: 'https://cwe.mitre.org/data/definitions/486.html',
    description:
      'If the decision to trust the methods and data of an object is based on the name of a class, it is possible for malicious users to send objects of the same name as trusted classes and thereby gain the trust afforded to known classes and types.'
  },
  '487': {
    name: 'Reliance on Package-level Scope',
    link: 'https://cwe.mitre.org/data/definitions/487.html',
    description:
      'The purpose of package scope is to prevent accidental access by other parts of a program. This is an ease-of-software-development feature but not a security feature.'
  },
  '488': {
    name: 'Exposure of Data Element to Wrong Session',
    link: 'https://cwe.mitre.org/data/definitions/488.html',
    description: '\n            '
  },
  '489': {
    name: 'Active Debug Code',
    link: 'https://cwe.mitre.org/data/definitions/489.html',
    description:
      'A common development practice is to add "back door" code specifically designed for debugging or testing purposes that is not intended to be shipped or deployed with the product. These back door entry points create security risks because they are not considered during design or testing and fall outside of the expected operating conditions of the product.'
  },
  '49': {
    name: "Path Equivalence: 'filename/' (Trailing Slash)",
    link: 'https://cwe.mitre.org/data/definitions/49.html',
    description:
      "The product accepts path input in the form of trailing slash ('filedir/') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '491': {
    name: "Public cloneable() Method Without Final ('Object Hijack')",
    link: 'https://cwe.mitre.org/data/definitions/491.html',
    description:
      'A class has a cloneable() method that is not declared final, which allows an object to be created without calling the constructor. This can cause the object to be in an unexpected state.'
  },
  '492': {
    name: 'Use of Inner Class Containing Sensitive Data',
    link: 'https://cwe.mitre.org/data/definitions/492.html',
    description:
      'Inner classes quietly introduce several security concerns because of the way they are translated into Java bytecode. In Java source code, it appears that an inner class can be declared to be accessible only by the enclosing class, but Java bytecode has no concept of an inner class, so the compiler must transform an inner class declaration into a peer class with package level access to the original outer class. More insidiously, since an inner class can access private fields in its enclosing class, once an inner class becomes a peer class in bytecode, the compiler converts private fields accessed by the inner class into protected fields.'
  },
  '493': {
    name: 'Critical Public Variable Without Final Modifier',
    link: 'https://cwe.mitre.org/data/definitions/493.html',
    description:
      'If a field is non-final and public, it can be changed once the value is set by any function that has access to the class which contains the field. This could lead to a vulnerability if other parts of the program make assumptions about the contents of that field.'
  },
  '494': {
    name: 'Download of Code Without Integrity Check',
    link: 'https://cwe.mitre.org/data/definitions/494.html',
    description:
      'An attacker can execute malicious code by compromising the host server, performing DNS spoofing, or modifying the code in transit.'
  },
  '495': {
    name: 'Private Data Structure Returned From A Public Method',
    link: 'https://cwe.mitre.org/data/definitions/495.html',
    description:
      'The product has a method that is declared public, but returns a reference to a private data structure, which could then be modified in unexpected ways.'
  },
  '496': {
    name: 'Public Data Assigned to Private Array-Typed Field',
    link: 'https://cwe.mitre.org/data/definitions/496.html',
    description: 'Assigning public data to a private array is equivalent to giving public access to the array.'
  },
  '497': {
    name: 'Exposure of Sensitive System Information to an Unauthorized Control Sphere',
    link: 'https://cwe.mitre.org/data/definitions/497.html',
    description:
      'The product does not properly prevent sensitive system-level information from being accessed by unauthorized actors who do not have the same level of access to the underlying system as the product does.'
  },
  '498': {
    name: 'Cloneable Class Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/498.html',
    description:
      'Cloneable classes are effectively open classes, since data cannot be hidden in them. Classes that do not explicitly deny cloning can be cloned by any other class without running the constructor.'
  },
  '499': {
    name: 'Serializable Class Containing Sensitive Data',
    link: 'https://cwe.mitre.org/data/definitions/499.html',
    description:
      'Serializable classes are effectively open classes since data cannot be hidden in them. Classes that do not explicitly deny serialization can be serialized by any other class, which can then in turn use the data stored inside it.'
  },
  '5': {
    name: 'J2EE Misconfiguration: Data Transmission Without Encryption',
    link: 'https://cwe.mitre.org/data/definitions/5.html',
    description:
      'Information sent over a network can be compromised while in transit. An attacker may be able to read or modify the contents if the data are sent in plaintext or are weakly encrypted.'
  },
  '50': {
    name: "Path Equivalence: '//multiple/leading/slash'",
    link: 'https://cwe.mitre.org/data/definitions/50.html',
    description:
      "The product accepts path input in the form of multiple leading slash ('//multiple/leading/slash') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '500': {
    name: 'Public Static Field Not Marked Final',
    link: 'https://cwe.mitre.org/data/definitions/500.html',
    description:
      'Public static variables can be read without an accessor and changed without a mutator by any classes in the application.'
  },
  '501': {
    name: 'Trust Boundary Violation',
    link: 'https://cwe.mitre.org/data/definitions/501.html',
    description:
      'A trust boundary can be thought of as line drawn through a program. On one side of the line, data is untrusted. On the other side of the line, data is assumed to be trustworthy. The purpose of validation logic is to allow data to safely cross the trust boundary - to move from untrusted to trusted. A trust boundary violation occurs when a program blurs the line between what is trusted and what is untrusted. By combining trusted and untrusted data in the same data structure, it becomes easier for programmers to mistakenly trust unvalidated data.'
  },
  '502': {
    name: 'Deserialization of Untrusted Data',
    link: 'https://cwe.mitre.org/data/definitions/502.html',
    description: '\n            '
  },
  '506': {
    name: 'Embedded Malicious Code',
    link: 'https://cwe.mitre.org/data/definitions/506.html',
    description:
      "Malicious flaws have acquired colorful names, including Trojan horse, trapdoor, timebomb, and logic-bomb. A developer might insert malicious code with the intent to subvert the security of a product or its host system at some time in the future. It generally refers to a program that performs a useful service but exploits rights of the program's user in a way the user does not intend."
  },
  '507': {
    name: 'Trojan Horse',
    link: 'https://cwe.mitre.org/data/definitions/507.html',
    description:
      'The product appears to contain benign or useful functionality, but it also contains code that is hidden from normal operation that violates the intended security policy of the user or the system administrator.'
  },
  '508': {
    name: 'Non-Replicating Malicious Code',
    link: 'https://cwe.mitre.org/data/definitions/508.html',
    description:
      'Non-replicating malicious code only resides on the target system or product that is attacked; it does not attempt to spread to other systems.'
  },
  '509': {
    name: 'Replicating Malicious Code (Virus or Worm)',
    link: 'https://cwe.mitre.org/data/definitions/509.html',
    description:
      'Replicating malicious code, including viruses and worms, will attempt to attack other systems once it has successfully compromised the target system or the product.'
  },
  '51': {
    name: "Path Equivalence: '/multiple//internal/slash'",
    link: 'https://cwe.mitre.org/data/definitions/51.html',
    description:
      "The product accepts path input in the form of multiple internal slash ('/multiple//internal/slash/') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '510': {
    name: 'Trapdoor',
    link: 'https://cwe.mitre.org/data/definitions/510.html',
    description:
      'A trapdoor is a hidden piece of code that responds to a special input, allowing its user access to resources without passing through the normal security enforcement mechanism.'
  },
  '511': {
    name: 'Logic/Time Bomb',
    link: 'https://cwe.mitre.org/data/definitions/511.html',
    description:
      'When the time bomb or logic bomb is detonated, it may perform a denial of service such as crashing the system, deleting critical data, or degrading system response time. This bomb might be placed within either a replicating or non-replicating Trojan horse.'
  },
  '512': {
    name: 'Spyware',
    link: 'https://cwe.mitre.org/data/definitions/512.html',
    description:
      '"Spyware" is a commonly used term with many definitions and interpretations. In general, it is meant to refer to products that collect information or install functionality that human users might not allow if they were fully aware of the actions being taken by the software. For example, a user might expect that tax software would collect a social security number and include it when filing a tax return, but that same user would not expect gaming software to obtain the social security number from that tax software\'s data.'
  },
  '514': {
    name: 'Covert Channel',
    link: 'https://cwe.mitre.org/data/definitions/514.html',
    description:
      'Typically the system has not given authorization for the transmission and has no knowledge of its occurrence.'
  },
  '515': {
    name: 'Covert Storage Channel',
    link: 'https://cwe.mitre.org/data/definitions/515.html',
    description:
      'Covert storage channels occur when out-of-band data is stored in messages for the purpose of memory reuse. Covert channels are frequently classified as either storage or timing channels. Examples would include using a file intended to hold only audit information to convey user passwords--using the name of a file or perhaps status bits associated with it that can be read by all users to signal the contents of the file. Steganography, concealing information in such a manner that no one but the intended recipient knows of the existence of the message, is a good example of a covert storage channel.'
  },
  '516': {
    name: 'DEPRECATED: Covert Timing Channel',
    link: 'https://cwe.mitre.org/data/definitions/516.html',
    description: 'This weakness can be found at CWE-385.'
  },
  '52': {
    name: "Path Equivalence: '/multiple/trailing/slash//'",
    link: 'https://cwe.mitre.org/data/definitions/52.html',
    description:
      "The product accepts path input in the form of multiple trailing slash ('/multiple/trailing/slash//') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '520': {
    name: '.NET Misconfiguration: Use of Impersonation',
    link: 'https://cwe.mitre.org/data/definitions/520.html',
    description:
      '.NET server applications can optionally execute using the identity of the user authenticated to the client. The intention of this functionality is to bypass authentication and access control checks within the .NET application code. Authentication is done by the underlying web server (Microsoft Internet Information Service IIS), which passes the authenticated token, or unauthenticated anonymous token, to the .NET application. Using the token to impersonate the client, the application then relies on the settings within the NTFS directories and files to control access. Impersonation enables the application, on the server running the .NET application, to both execute code and access resources in the context of the authenticated and authorized user.'
  },
  '521': {
    name: 'Weak Password Requirements',
    link: 'https://cwe.mitre.org/data/definitions/521.html',
    description:
      'Authentication mechanisms often rely on a memorized secret (also known as a password) to provide an assertion of identity for a user of a system. It is therefore important that this password be of sufficient complexity and impractical for an adversary to guess. The specific requirements around how complex a password needs to be depends on the type of system being protected. Selecting the correct password requirements and enforcing them through implementation are critical to the overall success of the authentication mechanism.'
  },
  '522': {
    name: 'Insufficiently Protected Credentials',
    link: 'https://cwe.mitre.org/data/definitions/522.html',
    description:
      'The product transmits or stores authentication credentials, but it uses an insecure method that is susceptible to unauthorized interception and/or retrieval.'
  },
  '523': {
    name: 'Unprotected Transport of Credentials',
    link: 'https://cwe.mitre.org/data/definitions/523.html',
    description:
      'Login pages do not use adequate measures to protect the user name and password while they are in transit from the client to the server.'
  },
  '524': {
    name: 'Use of Cache Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/524.html',
    description:
      '\n\t   Applications may use caches to improve efficiency when communicating with remote entities or performing intensive calculations.  A cache maintains a pool of objects, threads, connections, pages, financial data, passwords, or other resources to minimize the time it takes to initialize and access these resources.  If the cache is accessible to unauthorized actors, attackers can read the cache and obtain this sensitive information.\n\t '
  },
  '525': {
    name: 'Use of Web Browser Cache Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/525.html',
    description:
      'The web application does not use an appropriate caching policy that specifies the extent to which each web page and associated form fields should be cached.'
  },
  '526': {
    name: 'Cleartext Storage of Sensitive Information in an Environment Variable',
    link: 'https://cwe.mitre.org/data/definitions/526.html',
    description:
      "Information stored in an environment variable can be accessible by other processes with the execution context, including child processes that dependencies are executed in, or serverless functions in cloud environments. An environment variable's contents can also be inserted into messages, headers, log files, or other outputs. Often these other dependencies have no need to use the environment variable in question. A weakness that discloses environment variables could expose this information."
  },
  '527': {
    name: 'Exposure of Version-Control Repository to an Unauthorized Control Sphere',
    link: 'https://cwe.mitre.org/data/definitions/527.html',
    description:
      'Version control repositories such as CVS or git store version-specific metadata and other details within subdirectories. If these subdirectories are stored on a web server or added to an archive, then these could be used by an attacker. This information may include usernames, filenames, path root, IP addresses, and detailed "diff" data about how files have been changed - which could reveal source code snippets that were never intended to be made public.'
  },
  '528': {
    name: 'Exposure of Core Dump File to an Unauthorized Control Sphere',
    link: 'https://cwe.mitre.org/data/definitions/528.html',
    description:
      'The product generates a core dump file in a directory, archive, or other resource that is stored, transferred, or otherwise made accessible to unauthorized actors.'
  },
  '529': {
    name: 'Exposure of Access Control List Files to an Unauthorized Control Sphere',
    link: 'https://cwe.mitre.org/data/definitions/529.html',
    description:
      'Exposure of these access control list files may give the attacker information about the configuration of the site or system. This information may then be used to bypass the intended security policy or identify trusted systems from which an attack can be launched.'
  },
  '53': {
    name: "Path Equivalence: '\\multiple\\\\internal\\backslash'",
    link: 'https://cwe.mitre.org/data/definitions/53.html',
    description:
      "The product accepts path input in the form of multiple internal backslash ('\\multiple\\trailing\\\\slash') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '530': {
    name: 'Exposure of Backup File to an Unauthorized Control Sphere',
    link: 'https://cwe.mitre.org/data/definitions/530.html',
    description:
      'Often, older backup files are renamed with an extension such as .~bk to distinguish them from production files. The source code for old files that have been renamed in this manner and left in the webroot can often be retrieved. This renaming may have been performed automatically by the web server, or manually by the administrator.'
  },
  '531': {
    name: 'Inclusion of Sensitive Information in Test Code',
    link: 'https://cwe.mitre.org/data/definitions/531.html',
    description:
      'Accessible test applications can pose a variety of security risks. Since developers or administrators rarely consider that someone besides themselves would even know about the existence of these applications, it is common for them to contain sensitive information or functions.'
  },
  '532': {
    name: 'Insertion of Sensitive Information into Log File',
    link: 'https://cwe.mitre.org/data/definitions/532.html',
    description: '\n            '
  },
  '533': {
    name: 'DEPRECATED: Information Exposure Through Server Log Files',
    link: 'https://cwe.mitre.org/data/definitions/533.html',
    description: 'This entry has been deprecated because its abstraction was too low-level.  See CWE-532.'
  },
  '534': {
    name: 'DEPRECATED: Information Exposure Through Debug Log Files',
    link: 'https://cwe.mitre.org/data/definitions/534.html',
    description: 'This entry has been deprecated because its abstraction was too low-level.  See CWE-532.'
  },
  '535': {
    name: 'Exposure of Information Through Shell Error Message',
    link: 'https://cwe.mitre.org/data/definitions/535.html',
    description:
      'A command shell error message indicates that there exists an unhandled exception in the web application code. In many cases, an attacker can leverage the conditions that cause these errors in order to gain unauthorized access to the system.'
  },
  '536': {
    name: 'Servlet Runtime Error Message Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/536.html',
    description:
      'A servlet error message indicates that there exists an unhandled exception in your web application code and may provide useful information to an attacker.'
  },
  '537': {
    name: 'Java Runtime Error Message Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/537.html',
    description:
      'In many cases, an attacker can leverage the conditions that cause unhandled exception errors in order to gain unauthorized access to the system.'
  },
  '538': {
    name: 'Insertion of Sensitive Information into Externally-Accessible File or Directory',
    link: 'https://cwe.mitre.org/data/definitions/538.html',
    description:
      'The product places sensitive information into files or directories that are accessible to actors who are allowed to have access to the files, but not to the sensitive information.'
  },
  '539': {
    name: 'Use of Persistent Cookies Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/539.html',
    description:
      "Cookies are small bits of data that are sent by the web application but stored locally in the browser. This lets the application use the cookie to pass information between pages and store variable information. The web application controls what information is stored in a cookie and how it is used. Typical types of information stored in cookies are session identifiers, personalization and customization information, and in rare cases even usernames to enable automated logins. There are two different types of cookies: session cookies and persistent cookies. Session cookies just live in the browser's memory and are not stored anywhere, but persistent cookies are stored on the browser's hard drive.   This can cause security and privacy issues depending on the information stored in the cookie and how it is accessed."
  },
  '54': {
    name: "Path Equivalence: 'filedir\\' (Trailing Backslash)",
    link: 'https://cwe.mitre.org/data/definitions/54.html',
    description:
      "The product accepts path input in the form of trailing backslash ('filedir\\') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '540': {
    name: 'Inclusion of Sensitive Information in Source Code',
    link: 'https://cwe.mitre.org/data/definitions/540.html',
    description:
      'There are situations where it is critical to remove source code from an area or server. For example, obtaining Perl source code on a system allows an attacker to understand the logic of the script and extract extremely useful information such as code bugs or logins and passwords.'
  },
  '541': {
    name: 'Inclusion of Sensitive Information in an Include File',
    link: 'https://cwe.mitre.org/data/definitions/541.html',
    description:
      'If an include file source is accessible, the file can contain usernames and passwords, as well as sensitive information pertaining to the application and system.'
  },
  '542': {
    name: 'DEPRECATED: Information Exposure Through Cleanup Log Files',
    link: 'https://cwe.mitre.org/data/definitions/542.html',
    description: 'This entry has been deprecated because its abstraction was too low-level.  See CWE-532.'
  },
  '543': {
    name: 'Use of Singleton Pattern Without Synchronization in a Multithreaded Context',
    link: 'https://cwe.mitre.org/data/definitions/543.html',
    description: 'The use of a singleton pattern may not be thread-safe.'
  },
  '544': {
    name: 'Missing Standardized Error Handling Mechanism',
    link: 'https://cwe.mitre.org/data/definitions/544.html',
    description:
      'If the product handles error messages individually, on a one-by-one basis, this is likely to result in inconsistent error handling. The causes of errors may be lost. Also, detailed information about the causes of an error may be unintentionally returned to the user.'
  },
  '545': {
    name: 'DEPRECATED: Use of Dynamic Class Loading',
    link: 'https://cwe.mitre.org/data/definitions/545.html',
    description:
      'This weakness has been deprecated because it partially overlaps CWE-470, it describes legitimate programmer behavior, and other portions will need to be integrated into other entries.'
  },
  '546': {
    name: 'Suspicious Comment',
    link: 'https://cwe.mitre.org/data/definitions/546.html',
    description:
      'Many suspicious comments, such as BUG, HACK, FIXME, LATER, LATER2, TODO, in the code indicate missing security functionality and checking. Others indicate code problems that programmers should fix, such as hard-coded variables, error handling, not using stored procedures, and performance issues.'
  },
  '547': {
    name: 'Use of Hard-coded, Security-relevant Constants',
    link: 'https://cwe.mitre.org/data/definitions/547.html',
    description:
      'If the developer does not find all occurrences of the hard-coded constants, an incorrect policy decision may be made if one of the constants is not changed. Making changes to these values will require code changes that may be difficult or impossible once the system is released to the field. In addition, these hard-coded values may become available to attackers if the code is ever disclosed.'
  },
  '548': {
    name: 'Exposure of Information Through Directory Listing',
    link: 'https://cwe.mitre.org/data/definitions/548.html',
    description:
      'A directory listing provides an attacker with the complete index of all the resources located inside of the directory. The specific risks and consequences vary depending on which files are listed and accessible.'
  },
  '549': {
    name: 'Missing Password Field Masking',
    link: 'https://cwe.mitre.org/data/definitions/549.html',
    description:
      'The product does not mask passwords during entry, increasing the potential for attackers to observe and capture passwords.'
  },
  '55': {
    name: "Path Equivalence: '/./' (Single Dot Directory)",
    link: 'https://cwe.mitre.org/data/definitions/55.html',
    description:
      "The product accepts path input in the form of single dot directory exploit ('/./') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '550': {
    name: 'Server-generated Error Message Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/550.html',
    description:
      'While error messages in and of themselves are not dangerous, per se, it is what an attacker can glean from them that might cause eventual problems.'
  },
  '551': {
    name: 'Incorrect Behavior Order: Authorization Before Parsing and Canonicalization',
    link: 'https://cwe.mitre.org/data/definitions/551.html',
    description:
      'For instance, the character strings /./ and / both mean current directory. If /SomeDirectory is a protected directory and an attacker requests /./SomeDirectory, the attacker may be able to gain access to the resource if /./ is not converted to / before the authorization check is performed.'
  },
  '552': {
    name: 'Files or Directories Accessible to External Parties',
    link: 'https://cwe.mitre.org/data/definitions/552.html',
    description: '\n\t   '
  },
  '553': {
    name: 'Command Shell in Externally Accessible Directory',
    link: 'https://cwe.mitre.org/data/definitions/553.html',
    description:
      'A possible shell file exists in /cgi-bin/ or other accessible directories. This is extremely dangerous and can be used by an attacker to execute commands on the web server.'
  },
  '554': {
    name: 'ASP.NET Misconfiguration: Not Using Input Validation Framework',
    link: 'https://cwe.mitre.org/data/definitions/554.html',
    description: 'The ASP.NET application does not use an input validation framework.'
  },
  '555': {
    name: 'J2EE Misconfiguration: Plaintext Password in Configuration File',
    link: 'https://cwe.mitre.org/data/definitions/555.html',
    description:
      'Storing a plaintext password in a configuration file allows anyone who can read the file to access the password-protected resource, making it an easy target for attackers.'
  },
  '556': {
    name: 'ASP.NET Misconfiguration: Use of Identity Impersonation',
    link: 'https://cwe.mitre.org/data/definitions/556.html',
    description:
      'The use of impersonated credentials allows an ASP.NET application to run with either the privileges of the client on whose behalf it is executing or with arbitrary privileges granted in its configuration.'
  },
  '558': {
    name: 'Use of getlogin() in Multithreaded Application',
    link: 'https://cwe.mitre.org/data/definitions/558.html',
    description:
      'The getlogin() function returns a pointer to a string that contains the name of the user associated with the calling process. The function is not reentrant, meaning that if it is called from another process, the contents are not locked out and the value of the string can be changed by another process. This makes it very risky to use because the username can be changed by other processes, so the results of the function cannot be trusted.'
  },
  '56': {
    name: "Path Equivalence: 'filedir*' (Wildcard)",
    link: 'https://cwe.mitre.org/data/definitions/56.html',
    description:
      "The product accepts path input in the form of asterisk wildcard ('filedir*') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
  },
  '560': {
    name: 'Use of umask() with chmod-style Argument',
    link: 'https://cwe.mitre.org/data/definitions/560.html',
    description:
      'The product calls umask() with an incorrect argument that is specified as if it is an argument to chmod().'
  },
  '561': {
    name: 'Dead Code',
    link: 'https://cwe.mitre.org/data/definitions/561.html',
    description:
      'Dead code is code that can never be executed in a running program. The surrounding code makes it impossible for a section of code to ever be executed.'
  },
  '562': {
    name: 'Return of Stack Variable Address',
    link: 'https://cwe.mitre.org/data/definitions/562.html',
    description:
      "Because local variables are allocated on the stack, when a program returns a pointer to a local variable, it is returning a stack address. A subsequent function call is likely to re-use this same stack address, thereby overwriting the value of the pointer, which no longer corresponds to the same variable since a function's stack frame is invalidated when it returns. At best this will cause the value of the pointer to change unexpectedly. In many cases it causes the program to crash the next time the pointer is dereferenced."
  },
  '563': {
    name: 'Assignment to Variable without Use',
    link: 'https://cwe.mitre.org/data/definitions/563.html',
    description:
      'After the assignment, the variable is either assigned another value or goes out of scope. It is likely that the variable is simply vestigial, but it is also possible that the unused variable points out a bug.'
  },
  '564': {
    name: 'SQL Injection: Hibernate',
    link: 'https://cwe.mitre.org/data/definitions/564.html',
    description:
      "Using Hibernate to execute a dynamic SQL statement built with user-controlled input can allow an attacker to modify the statement's meaning or to execute arbitrary SQL commands."
  },
  '565': {
    name: 'Reliance on Cookies without Validation and Integrity Checking',
    link: 'https://cwe.mitre.org/data/definitions/565.html',
    description:
      'Attackers can easily modify cookies, within the browser or by implementing the client-side code outside of the browser. Reliance on cookies without detailed validation and integrity checking can allow attackers to bypass authentication, conduct injection attacks such as SQL injection and cross-site scripting, or otherwise modify inputs in unexpected ways.'
  },
  '566': {
    name: 'Authorization Bypass Through User-Controlled SQL Primary Key',
    link: 'https://cwe.mitre.org/data/definitions/566.html',
    description: '\n            '
  },
  '567': {
    name: 'Unsynchronized Access to Shared Data in a Multithreaded Context',
    link: 'https://cwe.mitre.org/data/definitions/567.html',
    description: '\n            '
  },
  '568': {
    name: 'finalize() Method Without super.finalize()',
    link: 'https://cwe.mitre.org/data/definitions/568.html',
    description:
      'The Java Language Specification states that it is a good practice for a finalize() method to call super.finalize().'
  },
  '57': {
    name: "Path Equivalence: 'fakedir/../realdir/filename'",
    link: 'https://cwe.mitre.org/data/definitions/57.html',
    description:
      "The product contains protection mechanisms to restrict access to 'realdir/filename', but it constructs pathnames using external input in the form of 'fakedir/../realdir/filename' that are not handled by those mechanisms. This allows attackers to perform unauthorized actions against the targeted file."
  },
  '570': {
    name: 'Expression is Always False',
    link: 'https://cwe.mitre.org/data/definitions/570.html',
    description: 'The product contains an expression that will always evaluate to false.'
  },
  '571': {
    name: 'Expression is Always True',
    link: 'https://cwe.mitre.org/data/definitions/571.html',
    description: 'The product contains an expression that will always evaluate to true.'
  },
  '572': {
    name: 'Call to Thread run() instead of start()',
    link: 'https://cwe.mitre.org/data/definitions/572.html',
    description:
      "In most cases a direct call to a Thread object's run() method is a bug. The programmer intended to begin a new thread of control, but accidentally called run() instead of start(), so the run() method will execute in the caller's thread of control."
  },
  '573': {
    name: 'Improper Following of Specification by Caller',
    link: 'https://cwe.mitre.org/data/definitions/573.html',
    description:
      'When leveraging external functionality, such as an API, it is important that the caller does so in accordance with the requirements of the external functionality or else unintended behaviors may result, possibly leaving the system vulnerable to any number of exploits.'
  },
  '574': {
    name: 'EJB Bad Practices: Use of Synchronization Primitives',
    link: 'https://cwe.mitre.org/data/definitions/574.html',
    description:
      'The Enterprise JavaBeans specification requires that every bean provider follow a set of programming guidelines designed to ensure that the bean will be portable and behave consistently in any EJB container. In this case, the product violates the following EJB guideline: "An enterprise bean must not use thread synchronization primitives to synchronize execution of multiple instances." The specification justifies this requirement in the following way: "This rule is required to ensure consistent runtime semantics because while some EJB containers may use a single JVM to execute all enterprise bean\'s instances, others may distribute the instances across multiple JVMs."'
  },
  '575': {
    name: 'EJB Bad Practices: Use of AWT Swing',
    link: 'https://cwe.mitre.org/data/definitions/575.html',
    description:
      'The Enterprise JavaBeans specification requires that every bean provider follow a set of programming guidelines designed to ensure that the bean will be portable and behave consistently in any EJB container. In this case, the product violates the following EJB guideline: "An enterprise bean must not use the AWT functionality to attempt to output information to a display, or to input information from a keyboard." The specification justifies this requirement in the following way: "Most servers do not allow direct interaction between an application program and a keyboard/display attached to the server system."'
  },
  '576': {
    name: 'EJB Bad Practices: Use of Java I/O',
    link: 'https://cwe.mitre.org/data/definitions/576.html',
    description:
      'The Enterprise JavaBeans specification requires that every bean provider follow a set of programming guidelines designed to ensure that the bean will be portable and behave consistently in any EJB container. In this case, the product violates the following EJB guideline: "An enterprise bean must not use the java.io package to attempt to access files and directories in the file system." The specification justifies this requirement in the following way: "The file system APIs are not well-suited for business components to access data. Business components should use a resource manager API, such as JDBC, to store data."'
  },
  '577': {
    name: 'EJB Bad Practices: Use of Sockets',
    link: 'https://cwe.mitre.org/data/definitions/577.html',
    description:
      'The Enterprise JavaBeans specification requires that every bean provider follow a set of programming guidelines designed to ensure that the bean will be portable and behave consistently in any EJB container. In this case, the product violates the following EJB guideline: "An enterprise bean must not attempt to listen on a socket, accept connections on a socket, or use a socket for multicast." The specification justifies this requirement in the following way: "The EJB architecture allows an enterprise bean instance to be a network socket client, but it does not allow it to be a network server. Allowing the instance to become a network server would conflict with the basic function of the enterprise bean-- to serve the EJB clients."'
  },
  '578': {
    name: 'EJB Bad Practices: Use of Class Loader',
    link: 'https://cwe.mitre.org/data/definitions/578.html',
    description:
      'The Enterprise JavaBeans specification requires that every bean provider follow a set of programming guidelines designed to ensure that the bean will be portable and behave consistently in any EJB container. In this case, the product violates the following EJB guideline: "The enterprise bean must not attempt to create a class loader; obtain the current class loader; set the context class loader; set security manager; create a new security manager; stop the JVM; or change the input, output, and error streams." The specification justifies this requirement in the following way: "These functions are reserved for the EJB container. Allowing the enterprise bean to use these functions could compromise security and decrease the container\'s ability to properly manage the runtime environment."'
  },
  '579': {
    name: 'J2EE Bad Practices: Non-serializable Object Stored in Session',
    link: 'https://cwe.mitre.org/data/definitions/579.html',
    description:
      'A J2EE application can make use of multiple JVMs in order to improve application reliability and performance. In order to make the multiple JVMs appear as a single application to the end user, the J2EE container can replicate an HttpSession object across multiple JVMs so that if one JVM becomes unavailable another can step in and take its place without disrupting the flow of the application. This is only possible if all session data is serializable, allowing the session to be duplicated between the JVMs.'
  },
  '58': {
    name: 'Path Equivalence: Windows 8.3 Filename',
    link: 'https://cwe.mitre.org/data/definitions/58.html',
    description:
      'On later Windows operating systems, a file can have a "long name" and a short name that is compatible with older Windows file systems, with up to 8 characters in the filename and 3 characters for the extension. These "8.3" filenames, therefore, act as an alternate name for files with long names, so they are useful pathname equivalence manipulations.'
  },
  '580': {
    name: 'clone() Method Without super.clone()',
    link: 'https://cwe.mitre.org/data/definitions/580.html',
    description:
      "All implementations of clone() should obtain the new object by calling super.clone(). If a class does not follow this convention, a subclass's clone() method will return an object of the wrong type."
  },
  '581': {
    name: 'Object Model Violation: Just One of Equals and Hashcode Defined',
    link: 'https://cwe.mitre.org/data/definitions/581.html',
    description:
      'Java objects are expected to obey a number of invariants related to equality. One of these invariants is that equal objects must have equal hashcodes. In other words, if a.equals(b) == true then a.hashCode() == b.hashCode().'
  },
  '582': {
    name: 'Array Declared Public, Final, and Static',
    link: 'https://cwe.mitre.org/data/definitions/582.html',
    description:
      'Because arrays are mutable objects, the final constraint requires that the array object itself be assigned only once, but makes no guarantees about the values of the array elements. Since the array is public, a malicious program can change the values stored in the array. As such, in most cases an array declared public, final and static is a bug.'
  },
  '583': {
    name: 'finalize() Method Declared Public',
    link: 'https://cwe.mitre.org/data/definitions/583.html',
    description:
      'A product should never call finalize explicitly, except to call super.finalize() inside an implementation of finalize(). In mobile code situations, the otherwise error prone practice of manual garbage collection can become a security threat if an attacker can maliciously invoke a finalize() method because it is declared with public access.'
  },
  '584': {
    name: 'Return Inside Finally Block',
    link: 'https://cwe.mitre.org/data/definitions/584.html',
    description:
      'The code has a return statement inside a finally block, which will cause any thrown exception in the try block to be discarded.'
  },
  '585': {
    name: 'Empty Synchronized Block',
    link: 'https://cwe.mitre.org/data/definitions/585.html',
    description:
      'An empty synchronized block does not actually accomplish any synchronization and may indicate a troubled section of code. An empty synchronized block can occur because code no longer needed within the synchronized block is commented out without removing the synchronized block.'
  },
  '586': {
    name: 'Explicit Call to Finalize()',
    link: 'https://cwe.mitre.org/data/definitions/586.html',
    description:
      "While the Java Language Specification allows an object's finalize() method to be called from outside the finalizer, doing so is usually a bad idea. For example, calling finalize() explicitly means that finalize() will be called more than once: the first time will be the explicit call and the last time will be the call that is made after the object is garbage collected."
  },
  '587': {
    name: 'Assignment of a Fixed Address to a Pointer',
    link: 'https://cwe.mitre.org/data/definitions/587.html',
    description:
      'Using a fixed address is not portable, because that address will probably not be valid in all environments or platforms.'
  },
  '588': {
    name: 'Attempt to Access Child of a Non-structure Pointer',
    link: 'https://cwe.mitre.org/data/definitions/588.html',
    description:
      'Casting a non-structure type to a structure type and accessing a field can lead to memory access errors or data corruption.'
  },
  '589': {
    name: 'Call to Non-ubiquitous API',
    link: 'https://cwe.mitre.org/data/definitions/589.html',
    description:
      'Some functions that offer security features supported by the OS are not available on all versions of the OS in common use. Likewise, functions are often deprecated or made obsolete for security reasons and should not be used.'
  },
  '59': {
    name: "Improper Link Resolution Before File Access ('Link Following')",
    link: 'https://cwe.mitre.org/data/definitions/59.html',
    description:
      'The product attempts to access a file based on the filename, but it does not properly prevent that filename from identifying a link or shortcut that resolves to an unintended resource.'
  },
  '590': {
    name: 'Free of Memory not on the Heap',
    link: 'https://cwe.mitre.org/data/definitions/590.html',
    description:
      "When free() is called on an invalid pointer, the program's memory management data structures may become corrupted. This corruption can cause the program to crash or, in some circumstances, an attacker may be able to cause free() to operate on controllable memory locations to modify critical program variables or execute code."
  },
  '591': {
    name: 'Sensitive Data Storage in Improperly Locked Memory',
    link: 'https://cwe.mitre.org/data/definitions/591.html',
    description:
      'On Windows systems the VirtualLock function can lock a page of memory to ensure that it will remain present in memory and not be swapped to disk. However, on older versions of Windows, such as 95, 98, or Me, the VirtualLock() function is only a stub and provides no protection. On POSIX systems the mlock() call ensures that a page will stay resident in memory but does not guarantee that the page will not appear in the swap. Therefore, it is unsuitable for use as a protection mechanism for sensitive data. Some platforms, in particular Linux, do make the guarantee that the page will not be swapped, but this is non-standard and is not portable. Calls to mlock() also require supervisor privilege. Return values for both of these calls must be checked to ensure that the lock operation was actually successful.'
  },
  '592': {
    name: 'DEPRECATED: Authentication Bypass Issues',
    link: 'https://cwe.mitre.org/data/definitions/592.html',
    description: 'This weakness has been deprecated because it covered redundant concepts already described in CWE-287.'
  },
  '593': {
    name: 'Authentication Bypass: OpenSSL CTX Object Modified after SSL Objects are Created',
    link: 'https://cwe.mitre.org/data/definitions/593.html',
    description:
      'If the program modifies the SSL_CTX object after creating SSL objects from it, there is the possibility that older SSL objects created from the original context could all be affected by that change.'
  },
  '594': {
    name: 'J2EE Framework: Saving Unserializable Objects to Disk',
    link: 'https://cwe.mitre.org/data/definitions/594.html',
    description:
      'In heavy load conditions, most J2EE application frameworks flush objects to disk to manage memory requirements of incoming requests. For example, session scoped objects, and even application scoped objects, are written to disk when required. While these application frameworks do the real work of writing objects to disk, they do not enforce that those objects be serializable, thus leaving the web application vulnerable to crashes induced by serialization failure. An attacker may be able to mount a denial of service attack by sending enough requests to the server to force the web application to save objects to disk.'
  },
  '595': {
    name: 'Comparison of Object References Instead of Object Contents',
    link: 'https://cwe.mitre.org/data/definitions/595.html',
    description:
      "For example, in Java, comparing objects using == usually produces deceptive results, since the == operator compares object references rather than values; often, this means that using == for strings is actually comparing the strings' references, not their values."
  },
  '596': {
    name: 'DEPRECATED: Incorrect Semantic Object Comparison',
    link: 'https://cwe.mitre.org/data/definitions/596.html',
    description:
      'This weakness has been deprecated.  It was poorly described and difficult to distinguish from other entries.  It was also inappropriate to assign a separate ID solely because of domain-specific considerations.  Its closest equivalent is CWE-1023.'
  },
  '597': {
    name: 'Use of Wrong Operator in String Comparison',
    link: 'https://cwe.mitre.org/data/definitions/597.html',
    description:
      'In Java, using == or != to compare two strings for equality actually compares two objects for equality rather than their string values for equality. Chances are good that the two references will never be equal. While this weakness often only affects program correctness, if the equality is used for a security decision, the unintended comparison result could be leveraged to affect program security.'
  },
  '598': {
    name: 'Use of GET Request Method With Sensitive Query Strings',
    link: 'https://cwe.mitre.org/data/definitions/598.html',
    description:
      "The query string for the URL could be saved in the browser's history, passed through Referers to other web sites, stored in web logs, or otherwise recorded in other sources.  If the query string contains sensitive information such as session identifiers, then attackers can use this information to launch further attacks.\n\t "
  },
  '599': {
    name: 'Missing Validation of OpenSSL Certificate',
    link: 'https://cwe.mitre.org/data/definitions/599.html',
    description:
      'This could allow an attacker to use an invalid certificate to claim to be a trusted host, use expired certificates, or conduct other attacks that could be detected if the certificate is properly validated.'
  },
  '6': {
    name: 'J2EE Misconfiguration: Insufficient Session-ID Length',
    link: 'https://cwe.mitre.org/data/definitions/6.html',
    description:
      "If an attacker can guess or steal a session ID, then they may be able to take over the user's session (called session hijacking). The number of possible session IDs increases with increased session ID length, making it more difficult to guess or steal a session ID."
  },
  '600': {
    name: 'Uncaught Exception in Servlet ',
    link: 'https://cwe.mitre.org/data/definitions/600.html',
    description:
      'When a Servlet throws an exception, the default error response the Servlet container sends back to the user typically includes debugging information. This information is of great value to an attacker. For example, a stack trace might show the attacker a malformed SQL query string, the type of database being used, and the version of the application container. This information enables the attacker to target known vulnerabilities in these components.'
  },
  '601': {
    name: "URL Redirection to Untrusted Site ('Open Redirect')",
    link: 'https://cwe.mitre.org/data/definitions/601.html',
    description:
      'An http parameter may contain a URL value and could cause the web application to redirect the request to the specified URL. By modifying the URL value to a malicious site, an attacker may successfully launch a phishing scam and steal user credentials. Because the server name in the modified link is identical to the original site, phishing attempts have a more trustworthy appearance. Whether this issue poses a vulnerability will be subject to the intended behavior of the application. For example, a search engine might intentionally provide redirects to arbitrary URLs.'
  },
  '602': {
    name: 'Client-Side Enforcement of Server-Side Security',
    link: 'https://cwe.mitre.org/data/definitions/602.html',
    description:
      'When the server relies on protection mechanisms placed on the client side, an attacker can modify the client-side behavior to bypass the protection mechanisms, resulting in potentially unexpected interactions between the client and server. The consequences will vary, depending on what the mechanisms are trying to protect.'
  },
  '603': {
    name: 'Use of Client-Side Authentication',
    link: 'https://cwe.mitre.org/data/definitions/603.html',
    description:
      'Client-side authentication is extremely weak and may be breached easily. Any attacker may read the source code and reverse-engineer the authentication mechanism to access parts of the application which would otherwise be protected.'
  },
  '605': {
    name: 'Multiple Binds to the Same Port',
    link: 'https://cwe.mitre.org/data/definitions/605.html',
    description:
      'On most systems, a combination of setting the SO_REUSEADDR socket option, and a call to bind() allows any process to bind to a port to which a previous process has bound with INADDR_ANY. This allows a user to bind to the specific address of a server bound to INADDR_ANY on an unprivileged port, and steal its UDP packets/TCP connection.'
  },
  '606': {
    name: 'Unchecked Input for Loop Condition',
    link: 'https://cwe.mitre.org/data/definitions/606.html',
    description:
      'The product does not properly check inputs that are used for loop conditions, potentially leading to a denial of service or other consequences because of excessive looping.'
  },
  '607': {
    name: 'Public Static Final Field References Mutable Object',
    link: 'https://cwe.mitre.org/data/definitions/607.html',
    description:
      'A public or protected static final field references a mutable object, which allows the object to be changed by malicious code, or accidentally from another package.'
  },
  '608': {
    name: 'Struts: Non-private Field in ActionForm Class',
    link: 'https://cwe.mitre.org/data/definitions/608.html',
    description:
      'An ActionForm class contains a field that has not been declared private, which can be accessed without using a setter or getter.'
  },
  '609': {
    name: 'Double-Checked Locking',
    link: 'https://cwe.mitre.org/data/definitions/609.html',
    description:
      'Double-checked locking refers to the situation where a programmer checks to see if a resource has been initialized, grabs a lock, checks again to see if the resource has been initialized, and then performs the initialization if it has not occurred yet. This should not be done, as it is not guaranteed to work in all languages and on all architectures. In summary, other threads may not be operating inside the synchronous block and are not guaranteed to see the operations execute in the same order as they would appear inside the synchronous block.'
  },
  '61': {
    name: 'UNIX Symbolic Link (Symlink) Following',
    link: 'https://cwe.mitre.org/data/definitions/61.html',
    description:
      'A product that allows UNIX symbolic links (symlink) as part of paths whether in internal code or through user input can allow an attacker to spoof the symbolic link and traverse the file system to unintended locations or access arbitrary files. The symbolic link can permit an attacker to read/write/corrupt a file that they originally did not have permissions to access.'
  },
  '610': {
    name: 'Externally Controlled Reference to a Resource in Another Sphere',
    link: 'https://cwe.mitre.org/data/definitions/610.html',
    description:
      'The product uses an externally controlled name or reference that resolves to a resource that is outside of the intended control sphere.'
  },
  '611': {
    name: 'Improper Restriction of XML External Entity Reference',
    link: 'https://cwe.mitre.org/data/definitions/611.html',
    description: '\n            '
  },
  '612': {
    name: 'Improper Authorization of Index Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/612.html',
    description:
      "Web sites and other document repositories may apply an indexing routine against a group of private documents to facilitate search.  If the index's results are available to parties who do not have access to the documents being indexed, then attackers could obtain portions of the documents by conducting targeted searches and reading the results.  The risk is especially dangerous if search results include surrounding text that was not part of the search query. This issue can appear in search engines that are not configured (or implemented) to ignore critical files that should remain hidden; even without permissions to download these files directly, the remote user could read them."
  },
  '613': {
    name: 'Insufficient Session Expiration',
    link: 'https://cwe.mitre.org/data/definitions/613.html',
    description:
      'According to WASC, "Insufficient Session Expiration is when a web site permits an attacker to reuse old session credentials or session IDs for authorization."'
  },
  '614': {
    name: "Sensitive Cookie in HTTPS Session Without 'Secure' Attribute",
    link: 'https://cwe.mitre.org/data/definitions/614.html',
    description:
      'The Secure attribute for sensitive cookies in HTTPS sessions is not set, which could cause the user agent to send those cookies in plaintext over an HTTP session.'
  },
  '615': {
    name: 'Inclusion of Sensitive Information in Source Code Comments',
    link: 'https://cwe.mitre.org/data/definitions/615.html',
    description:
      "An attacker who finds these comments can map the application's structure and files, expose hidden parts of the site, and study the fragments of code to reverse engineer the application, which may help develop further attacks against the site."
  },
  '616': {
    name: 'Incomplete Identification of Uploaded File Variables (PHP)',
    link: 'https://cwe.mitre.org/data/definitions/616.html',
    description:
      'These global variables could be overwritten by POST requests, cookies, or other methods of populating or overwriting these variables. This could be used to read or process arbitrary files by providing values such as "/etc/passwd".'
  },
  '617': {
    name: 'Reachable Assertion',
    link: 'https://cwe.mitre.org/data/definitions/617.html',
    description: '\n            '
  },
  '618': {
    name: 'Exposed Unsafe ActiveX Method',
    link: 'https://cwe.mitre.org/data/definitions/618.html',
    description:
      'ActiveX controls can exercise far greater control over the operating system than typical Java or javascript. Exposed methods can be subject to various vulnerabilities, depending on the implemented behaviors of those methods, and whether input validation is performed on the provided arguments. If there is no integrity checking or origin validation, this method could be invoked by attackers.'
  },
  '619': {
    name: "Dangling Database Cursor ('Cursor Injection')",
    link: 'https://cwe.mitre.org/data/definitions/619.html',
    description:
      "For example, an improper dangling cursor could arise from unhandled exceptions. The impact of the issue depends on the cursor's role, but SQL injection attacks are commonly possible."
  },
  '62': {
    name: 'UNIX Hard Link',
    link: 'https://cwe.mitre.org/data/definitions/62.html',
    description:
      'Failure for a system to check for hard links can result in vulnerability to different types of attacks. For example, an attacker can escalate their privileges if a file used by a privileged program is replaced with a hard link to a sensitive file (e.g. /etc/passwd). When the process opens the file, the attacker can assume the privileges of that process.'
  },
  '620': {
    name: 'Unverified Password Change',
    link: 'https://cwe.mitre.org/data/definitions/620.html',
    description:
      'This could be used by an attacker to change passwords for another user, thus gaining the privileges associated with that user.'
  },
  '621': {
    name: 'Variable Extraction Error',
    link: 'https://cwe.mitre.org/data/definitions/621.html',
    description: '\n            '
  },
  '622': {
    name: 'Improper Validation of Function Hook Arguments',
    link: 'https://cwe.mitre.org/data/definitions/622.html',
    description:
      'Such hooks can be used in defensive software that runs with privileges, such as anti-virus or firewall, which hooks kernel calls. When the arguments are not validated, they could be used to bypass the protection scheme or attack the product itself.'
  },
  '623': {
    name: 'Unsafe ActiveX Control Marked Safe For Scripting',
    link: 'https://cwe.mitre.org/data/definitions/623.html',
    description:
      "This might allow attackers to use dangerous functionality via a web page that accesses the control, which can lead to different resultant vulnerabilities, depending on the control's behavior."
  },
  '624': {
    name: 'Executable Regular Expression Error',
    link: 'https://cwe.mitre.org/data/definitions/624.html',
    description:
      'Case (2) is possible in the PHP preg_replace() function, and possibly in other languages when a user-controlled input is inserted into a string that is later parsed as a regular expression.'
  },
  '625': {
    name: 'Permissive Regular Expression',
    link: 'https://cwe.mitre.org/data/definitions/625.html',
    description: '\n            '
  },
  '626': {
    name: 'Null Byte Interaction Error (Poison Null Byte)',
    link: 'https://cwe.mitre.org/data/definitions/626.html',
    description: '\n            '
  },
  '627': {
    name: 'Dynamic Variable Evaluation',
    link: 'https://cwe.mitre.org/data/definitions/627.html',
    description:
      'The resultant vulnerabilities depend on the behavior of the application, both at the crossover point and in any control/data flow that is reachable by the related variables or functions.'
  },
  '628': {
    name: 'Function Call with Incorrectly Specified Arguments',
    link: 'https://cwe.mitre.org/data/definitions/628.html',
    description: '\n            '
  },
  '636': {
    name: "Not Failing Securely ('Failing Open')",
    link: 'https://cwe.mitre.org/data/definitions/636.html',
    description:
      'By entering a less secure state, the product inherits the weaknesses associated with that state, making it easier to compromise. At the least, it causes administrators to have a false sense of security. This weakness typically occurs as a result of wanting to "fail functional" to minimize administration and support costs, instead of "failing safe."'
  },
  '637': {
    name: "Unnecessary Complexity in Protection Mechanism (Not Using 'Economy of Mechanism')",
    link: 'https://cwe.mitre.org/data/definitions/637.html',
    description:
      'Security mechanisms should be as simple as possible. Complex security mechanisms may engender partial implementations and compatibility problems, with resulting mismatches in assumptions and implemented security. A corollary of this principle is that data specifications should be as simple as possible, because complex data specifications result in complex validation code. Complex tasks and systems may also need to be guarded by complex security checks, so simple systems should be preferred.'
  },
  '638': {
    name: 'Not Using Complete Mediation',
    link: 'https://cwe.mitre.org/data/definitions/638.html',
    description:
      "The product does not perform access checks on a resource every time the resource is accessed by an entity, which can create resultant weaknesses if that entity's rights or privileges change over time."
  },
  '639': {
    name: 'Authorization Bypass Through User-Controlled Key',
    link: 'https://cwe.mitre.org/data/definitions/639.html',
    description: '\n            '
  },
  '64': {
    name: 'Windows Shortcut Following (.LNK)',
    link: 'https://cwe.mitre.org/data/definitions/64.html',
    description:
      'The shortcut (file with the .lnk extension) can permit an attacker to read/write a file that they originally did not have permissions to access.'
  },
  '640': {
    name: 'Weak Password Recovery Mechanism for Forgotten Password',
    link: 'https://cwe.mitre.org/data/definitions/640.html',
    description: '\n            '
  },
  '641': {
    name: 'Improper Restriction of Names for Files and Other Resources',
    link: 'https://cwe.mitre.org/data/definitions/641.html',
    description:
      "This may produce resultant weaknesses. For instance, if the names of these resources contain scripting characters, it is possible that a script may get executed in the client's browser if the application ever displays the name of the resource on a dynamically generated web page. Alternately, if the resources are consumed by some application parser, a specially crafted name can exploit some vulnerability internal to the parser, potentially resulting in execution of arbitrary code on the server machine. The problems will vary based on the context of usage of such malformed resource names and whether vulnerabilities are present in or assumptions are made by the targeted technology that would make code execution possible."
  },
  '642': {
    name: 'External Control of Critical State Data',
    link: 'https://cwe.mitre.org/data/definitions/642.html',
    description: '\n            '
  },
  '643': {
    name: "Improper Neutralization of Data within XPath Expressions ('XPath Injection')",
    link: 'https://cwe.mitre.org/data/definitions/643.html',
    description:
      'The net effect is that the attacker will have control over the information selected from the XML database and may use that ability to control application flow, modify logic, retrieve unauthorized data, or bypass important checks (e.g. authentication).'
  },
  '644': {
    name: 'Improper Neutralization of HTTP Headers for Scripting Syntax',
    link: 'https://cwe.mitre.org/data/definitions/644.html',
    description: '\n            '
  },
  '645': {
    name: 'Overly Restrictive Account Lockout Mechanism',
    link: 'https://cwe.mitre.org/data/definitions/645.html',
    description:
      "Account lockout is a security feature often present in applications as a countermeasure to the brute force attack on the password based authentication mechanism of the system. After a certain number of failed login attempts, the users' account may be disabled for a certain period of time or until it is unlocked by an administrator. Other security events may also possibly trigger account lockout. However, an attacker may use this very security feature to deny service to legitimate system users. It is therefore important to ensure that the account lockout security mechanism is not overly restrictive."
  },
  '646': {
    name: 'Reliance on File Name or Extension of Externally-Supplied File',
    link: 'https://cwe.mitre.org/data/definitions/646.html',
    description:
      'An application might use the file name or extension of a user-supplied file to determine the proper course of action, such as selecting the correct process to which control should be passed, deciding what data should be made available, or what resources should be allocated. If the attacker can cause the code to misclassify the supplied file, then the wrong action could occur. For example, an attacker could supply a file that ends in a ".php.gif" extension that appears to be a GIF image, but would be processed as PHP code. In extreme cases, code execution is possible, but the attacker could also cause exhaustion of resources, denial of service, exposure of debug or system data (including application source code), or being bound to a particular server side process. This weakness may be due to a vulnerability in any of the technologies used by the web and application servers, due to misconfiguration, or resultant from another flaw in the application itself.'
  },
  '647': {
    name: 'Use of Non-Canonical URL Paths for Authorization Decisions',
    link: 'https://cwe.mitre.org/data/definitions/647.html',
    description: '\n            '
  },
  '648': {
    name: 'Incorrect Use of Privileged APIs',
    link: 'https://cwe.mitre.org/data/definitions/648.html',
    description: '\n            '
  },
  '649': {
    name: 'Reliance on Obfuscation or Encryption of Security-Relevant Inputs without Integrity Checking',
    link: 'https://cwe.mitre.org/data/definitions/649.html',
    description:
      'When an application relies on obfuscation or incorrectly applied / weak encryption to protect client-controllable tokens or parameters, that may have an effect on the user state, system state, or some decision made on the server. Without protecting the tokens/parameters for integrity, the application is vulnerable to an attack where an adversary traverses the space of possible values of the said token/parameter in order to attempt to gain an advantage. The goal of the attacker is to find another admissible value that will somehow elevate their privileges in the system, disclose information or change the behavior of the system in some way beneficial to the attacker. If the application does not protect these critical tokens/parameters for integrity, it will not be able to determine that these values have been tampered with. Measures that are used to protect data for confidentiality should not be relied upon to provide the integrity service.'
  },
  '65': {
    name: 'Windows Hard Link',
    link: 'https://cwe.mitre.org/data/definitions/65.html',
    description:
      'Failure for a system to check for hard links can result in vulnerability to different types of attacks. For example, an attacker can escalate their privileges if a file used by a privileged program is replaced with a hard link to a sensitive file (e.g. AUTOEXEC.BAT). When the process opens the file, the attacker can assume the privileges of that process, or prevent the program from accurately processing data.'
  },
  '650': {
    name: 'Trusting HTTP Permission Methods on the Server Side',
    link: 'https://cwe.mitre.org/data/definitions/650.html',
    description:
      'The HTTP GET method and some other methods are designed to retrieve resources and not to alter the state of the application or resources on the server side. Furthermore, the HTTP specification requires that GET requests (and other requests) should not have side effects. Believing that it will be enough to prevent unintended resource alterations, an application may disallow the HTTP requests to perform DELETE, PUT and POST operations on the resource representation. However, there is nothing in the HTTP protocol itself that actually prevents the HTTP GET method from performing more than just query of the data. Developers can easily code programs that accept a HTTP GET request that do in fact create, update or delete data on the server. For instance, it is a common practice with REST based Web Services to have HTTP GET requests modifying resources on the server side. However, whenever that happens, the access control needs to be properly enforced in the application. No assumptions should be made that only HTTP DELETE, PUT, POST, and other methods have the power to alter the representation of the resource being accessed in the request.'
  },
  '651': {
    name: 'Exposure of WSDL File Containing Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/651.html',
    description: '\n            '
  },
  '652': {
    name: "Improper Neutralization of Data within XQuery Expressions ('XQuery Injection')",
    link: 'https://cwe.mitre.org/data/definitions/652.html',
    description:
      'The net effect is that the attacker will have control over the information selected from the XML database and may use that ability to control application flow, modify logic, retrieve unauthorized data, or bypass important checks (e.g. authentication).'
  },
  '653': {
    name: 'Improper Isolation or Compartmentalization',
    link: 'https://cwe.mitre.org/data/definitions/653.html',
    description:
      'When a weakness occurs in functionality that is accessible by lower-privileged users, then without strong boundaries, an attack might extend the scope of the damage to higher-privileged users.'
  },
  '654': {
    name: 'Reliance on a Single Factor in a Security Decision',
    link: 'https://cwe.mitre.org/data/definitions/654.html',
    description:
      'A protection mechanism relies exclusively, or to a large extent, on the evaluation of a single condition or the integrity of a single object or entity in order to make a decision about granting access to restricted resources or functionality.'
  },
  '655': {
    name: 'Insufficient Psychological Acceptability',
    link: 'https://cwe.mitre.org/data/definitions/655.html',
    description:
      'The product has a protection mechanism that is too difficult or inconvenient to use, encouraging non-malicious users to disable or bypass the mechanism, whether by accident or on purpose.'
  },
  '656': {
    name: 'Reliance on Security Through Obscurity',
    link: 'https://cwe.mitre.org/data/definitions/656.html',
    description:
      'This reliance on "security through obscurity" can produce resultant weaknesses if an attacker is able to reverse engineer the inner workings of the mechanism. Note that obscurity can be one small part of defense in depth, since it can create more work for an attacker; however, it is a significant risk if used as the primary means of protection.'
  },
  '657': {
    name: 'Violation of Secure Design Principles',
    link: 'https://cwe.mitre.org/data/definitions/657.html',
    description:
      'This can introduce resultant weaknesses or make it easier for developers to introduce related weaknesses during implementation. Because code is centered around design, it can be resource-intensive to fix design problems.'
  },
  '66': {
    name: 'Improper Handling of File Names that Identify Virtual Resources',
    link: 'https://cwe.mitre.org/data/definitions/66.html',
    description:
      'Virtual file names are represented like normal file names, but they are effectively aliases for other resources that do not behave like normal files. Depending on their functionality, they could be alternate entities. They are not necessarily listed in directories.'
  },
  '662': {
    name: 'Improper Synchronization',
    link: 'https://cwe.mitre.org/data/definitions/662.html',
    description: '\n\t   '
  },
  '663': {
    name: 'Use of a Non-reentrant Function in a Concurrent Context',
    link: 'https://cwe.mitre.org/data/definitions/663.html',
    description:
      'The product calls a non-reentrant function in a concurrent context in which a competing code sequence (e.g. thread or signal handler) may have an opportunity to call the same function or otherwise influence its state.'
  },
  '664': {
    name: 'Improper Control of a Resource Through its Lifetime',
    link: 'https://cwe.mitre.org/data/definitions/664.html',
    description: '\n            '
  },
  '665': {
    name: 'Improper Initialization',
    link: 'https://cwe.mitre.org/data/definitions/665.html',
    description:
      'This can have security implications when the associated resource is expected to have certain properties or values, such as a variable that determines whether a user has been authenticated or not.'
  },
  '666': {
    name: 'Operation on Resource in Wrong Phase of Lifetime',
    link: 'https://cwe.mitre.org/data/definitions/666.html',
    description:
      "A resource's lifecycle includes several phases: initialization, use, and release. For each phase, it is important to follow the specifications outlined for how to operate on the resource and to ensure that the resource is in the expected phase. Otherwise, if a resource is in one phase but the operation is not valid for that phase (i.e., an incorrect phase of the resource's lifetime), then this can produce resultant weaknesses. For example, using a resource before it has been fully initialized could cause corruption or incorrect data to be used."
  },
  '667': {
    name: 'Improper Locking',
    link: 'https://cwe.mitre.org/data/definitions/667.html',
    description: '\n\t   '
  },
  '668': {
    name: 'Exposure of Resource to Wrong Sphere',
    link: 'https://cwe.mitre.org/data/definitions/668.html',
    description: '\n            '
  },
  '669': {
    name: 'Incorrect Resource Transfer Between Spheres',
    link: 'https://cwe.mitre.org/data/definitions/669.html',
    description:
      'The product does not properly transfer a resource/behavior to another sphere, or improperly imports a resource/behavior from another sphere, in a manner that provides unintended control over that resource.'
  },
  '67': {
    name: 'Improper Handling of Windows Device Names',
    link: 'https://cwe.mitre.org/data/definitions/67.html',
    description:
      'Not properly handling virtual filenames (e.g. AUX, CON, PRN, COM1, LPT1) can result in different types of vulnerabilities. In some cases an attacker can request a device via injection of a virtual filename in a URL, which may cause an error that leads to a denial of service or an error page that reveals sensitive information. A product that allows device names to bypass filtering runs the risk of an attacker injecting malicious code in a file with the name of a device.'
  },
  '670': {
    name: 'Always-Incorrect Control Flow Implementation',
    link: 'https://cwe.mitre.org/data/definitions/670.html',
    description:
      'This weakness captures cases in which a particular code segment is always incorrect with respect to the algorithm that it is implementing. For example, if a C programmer intends to include multiple statements in a single block but does not include the enclosing braces (CWE-483), then the logic is always incorrect. This issue is in contrast to most weaknesses in which the code usually behaves correctly, except when it is externally manipulated in malicious ways.'
  },
  '671': {
    name: 'Lack of Administrator Control over Security',
    link: 'https://cwe.mitre.org/data/definitions/671.html',
    description:
      "If the product's administrator does not have the ability to manage security-related decisions at all times, then protecting the product from outside threats - including the product's developer - can become impossible. For example, a hard-coded account name and password cannot be changed by the administrator, thus exposing that product to attacks that the administrator can not prevent."
  },
  '672': {
    name: 'Operation on a Resource after Expiration or Release',
    link: 'https://cwe.mitre.org/data/definitions/672.html',
    description:
      'The product uses, accesses, or otherwise operates on a resource after that resource has been expired, released, or revoked.'
  },
  '673': {
    name: 'External Influence of Sphere Definition',
    link: 'https://cwe.mitre.org/data/definitions/673.html',
    description:
      "Typically, a product defines its control sphere within the code itself, or through configuration by the product's administrator. In some cases, an external party can change the definition of the control sphere. This is typically a resultant weakness."
  },
  '674': {
    name: 'Uncontrolled Recursion',
    link: 'https://cwe.mitre.org/data/definitions/674.html',
    description:
      'The product does not properly control the amount of recursion that takes place,  consuming excessive resources, such as allocated memory or the program stack.'
  },
  '675': {
    name: 'Multiple Operations on Resource in Single-Operation Context',
    link: 'https://cwe.mitre.org/data/definitions/675.html',
    description:
      'The product performs the same operation on a resource two or more times, when the operation should only be applied once.'
  },
  '676': {
    name: 'Use of Potentially Dangerous Function',
    link: 'https://cwe.mitre.org/data/definitions/676.html',
    description:
      'The product invokes a potentially dangerous function that could introduce a vulnerability if it is used incorrectly, but the function can also be used safely.'
  },
  '680': {
    name: 'Integer Overflow to Buffer Overflow',
    link: 'https://cwe.mitre.org/data/definitions/680.html',
    description:
      'The product performs a calculation to determine how much memory to allocate, but an integer overflow can occur that causes less memory to be allocated than expected, leading to a buffer overflow.'
  },
  '681': {
    name: 'Incorrect Conversion between Numeric Types',
    link: 'https://cwe.mitre.org/data/definitions/681.html',
    description:
      'When converting from one data type to another, such as long to integer, data can be omitted or translated in a way that produces unexpected values. If the resulting values are used in a sensitive context, then dangerous behaviors may occur.'
  },
  '682': {
    name: 'Incorrect Calculation',
    link: 'https://cwe.mitre.org/data/definitions/682.html',
    description:
      'When product performs a security-critical calculation incorrectly, it might lead to incorrect resource allocations, incorrect privilege assignments, or failed comparisons among other things. Many of the direct results of an incorrect calculation can lead to even larger problems such as failed protection mechanisms or even arbitrary code execution.'
  },
  '683': {
    name: 'Function Call With Incorrect Order of Arguments',
    link: 'https://cwe.mitre.org/data/definitions/683.html',
    description:
      'While this weakness might be caught by the compiler in some languages, it can occur more frequently in cases in which the called function accepts variable numbers or types of arguments, such as format strings in C. It also can occur in languages or environments that do not enforce strong typing.'
  },
  '684': {
    name: 'Incorrect Provision of Specified Functionality',
    link: 'https://cwe.mitre.org/data/definitions/684.html',
    description:
      'When providing functionality to an external party, it is important that the product behaves in accordance with the details specified. When requirements of nuances are not documented, the functionality may produce unintended behaviors for the caller, possibly leading to an exploitable state.'
  },
  '685': {
    name: 'Function Call With Incorrect Number of Arguments',
    link: 'https://cwe.mitre.org/data/definitions/685.html',
    description:
      'The product calls a function, procedure, or routine, but the caller specifies too many arguments, or too few arguments, which may lead to undefined behavior and resultant weaknesses.'
  },
  '686': {
    name: 'Function Call With Incorrect Argument Type',
    link: 'https://cwe.mitre.org/data/definitions/686.html',
    description:
      'This weakness is most likely to occur in loosely typed languages, or in strongly typed languages in which the types of variable arguments cannot be enforced at compilation time, or where there is implicit casting.'
  },
  '687': {
    name: 'Function Call With Incorrectly Specified Argument Value',
    link: 'https://cwe.mitre.org/data/definitions/687.html',
    description:
      'The product calls a function, procedure, or routine, but the caller specifies an argument that contains the wrong value, which may lead to resultant weaknesses.'
  },
  '688': {
    name: 'Function Call With Incorrect Variable or Reference as Argument',
    link: 'https://cwe.mitre.org/data/definitions/688.html',
    description:
      'The product calls a function, procedure, or routine, but the caller specifies the wrong variable or reference as one of the arguments, which may lead to undefined behavior and resultant weaknesses.'
  },
  '689': {
    name: 'Permission Race Condition During Resource Copy',
    link: 'https://cwe.mitre.org/data/definitions/689.html',
    description:
      "The product, while copying or cloning a resource, does not set the resource's permissions or access control until the copy is complete, leaving the resource exposed to other spheres while the copy is taking place."
  },
  '69': {
    name: 'Improper Handling of Windows ::DATA Alternate Data Stream',
    link: 'https://cwe.mitre.org/data/definitions/69.html',
    description:
      "An attacker can use an ADS to hide information about a file (e.g. size, the name of the process) from a system or file browser tools such as Windows Explorer and 'dir' at the command line utility. Alternately, the attacker might be able to bypass intended access restrictions for the associated data fork."
  },
  '690': {
    name: 'Unchecked Return Value to NULL Pointer Dereference',
    link: 'https://cwe.mitre.org/data/definitions/690.html',
    description:
      'While unchecked return value weaknesses are not limited to returns of NULL pointers (see the examples in CWE-252), functions often return NULL to indicate an error status. When this error condition is not checked, a NULL pointer dereference can occur.'
  },
  '691': {
    name: 'Insufficient Control Flow Management',
    link: 'https://cwe.mitre.org/data/definitions/691.html',
    description:
      'The code does not sufficiently manage its control flow during execution, creating conditions in which the control flow can be modified in unexpected ways.'
  },
  '692': {
    name: 'Incomplete Denylist to Cross-Site Scripting',
    link: 'https://cwe.mitre.org/data/definitions/692.html',
    description:
      'While XSS might seem simple to prevent, web browsers vary so widely in how they parse web pages, that a denylist cannot keep track of all the variations. The "XSS Cheat Sheet" [REF-714] contains a large number of attacks that are intended to bypass incomplete denylists.'
  },
  '693': {
    name: 'Protection Mechanism Failure',
    link: 'https://cwe.mitre.org/data/definitions/693.html',
    description:
      'This weakness covers three distinct situations. A "missing" protection mechanism occurs when the application does not define any mechanism against a certain class of attack. An "insufficient" protection mechanism might provide some defenses - for example, against the most common attacks - but it does not protect against everything that is intended. Finally, an "ignored" mechanism occurs when a mechanism is available and in active use within the product, but the developer has not applied it in some code path.'
  },
  '694': {
    name: 'Use of Multiple Resources with Duplicate Identifier',
    link: 'https://cwe.mitre.org/data/definitions/694.html',
    description:
      'If the product assumes that each resource has a unique identifier, the product could operate on the wrong resource if attackers can cause multiple resources to be associated with the same identifier.'
  },
  '695': {
    name: 'Use of Low-Level Functionality',
    link: 'https://cwe.mitre.org/data/definitions/695.html',
    description:
      'The use of low-level functionality can violate the specification in unexpected ways that effectively disable built-in protection mechanisms, introduce exploitable inconsistencies, or otherwise expose the functionality to attack.'
  },
  '696': {
    name: 'Incorrect Behavior Order',
    link: 'https://cwe.mitre.org/data/definitions/696.html',
    description:
      'The product performs multiple related behaviors, but the behaviors are performed in the wrong order in ways which may produce resultant weaknesses.'
  },
  '697': {
    name: 'Incorrect Comparison',
    link: 'https://cwe.mitre.org/data/definitions/697.html',
    description: '\n            '
  },
  '698': {
    name: 'Execution After Redirect (EAR)',
    link: 'https://cwe.mitre.org/data/definitions/698.html',
    description:
      'The web application sends a redirect to another location, but instead of exiting, it executes additional code.'
  },
  '7': {
    name: 'J2EE Misconfiguration: Missing Custom Error Page',
    link: 'https://cwe.mitre.org/data/definitions/7.html',
    description: '\n            '
  },
  '703': {
    name: 'Improper Check or Handling of Exceptional Conditions',
    link: 'https://cwe.mitre.org/data/definitions/703.html',
    description:
      'The product does not properly anticipate or handle exceptional conditions that rarely occur during normal operation of the product.'
  },
  '704': {
    name: 'Incorrect Type Conversion or Cast',
    link: 'https://cwe.mitre.org/data/definitions/704.html',
    description:
      'The product does not correctly convert an object, resource, or structure from one type to a different type.'
  },
  '705': {
    name: 'Incorrect Control Flow Scoping',
    link: 'https://cwe.mitre.org/data/definitions/705.html',
    description:
      'The product does not properly return control flow to the proper location after it has completed a task or detected an unusual condition.'
  },
  '706': {
    name: 'Use of Incorrectly-Resolved Name or Reference',
    link: 'https://cwe.mitre.org/data/definitions/706.html',
    description:
      'The product uses a name or reference to access a resource, but the name/reference resolves to a resource that is outside of the intended control sphere.'
  },
  '707': {
    name: 'Improper Neutralization',
    link: 'https://cwe.mitre.org/data/definitions/707.html',
    description: '\n            '
  },
  '708': {
    name: 'Incorrect Ownership Assignment',
    link: 'https://cwe.mitre.org/data/definitions/708.html',
    description: 'This may allow the resource to be manipulated by actors outside of the intended control sphere.'
  },
  '71': {
    name: "DEPRECATED: Apple '.DS_Store'",
    link: 'https://cwe.mitre.org/data/definitions/71.html',
    description:
      'This entry has been deprecated as it represents a specific observed example of a UNIX Hard Link weakness type rather than its own individual weakness type. Please refer to CWE-62.'
  },
  '710': {
    name: 'Improper Adherence to Coding Standards',
    link: 'https://cwe.mitre.org/data/definitions/710.html',
    description:
      'The product does not follow certain coding rules for development, which can lead to resultant weaknesses or increase the severity of the associated vulnerabilities.'
  },
  '72': {
    name: 'Improper Handling of Apple HFS+ Alternate Data Stream Path',
    link: 'https://cwe.mitre.org/data/definitions/72.html',
    description:
      'If the product chooses actions to take based on the file name, then if an attacker provides the data or resource fork, the product may take unexpected actions. Further, if the product intends to restrict access to a file, then an attacker might still be able to bypass intended access restrictions by requesting the data or resource fork for that file.'
  },
  '73': {
    name: 'External Control of File Name or Path',
    link: 'https://cwe.mitre.org/data/definitions/73.html',
    description: '\n            '
  },
  '732': {
    name: 'Incorrect Permission Assignment for Critical Resource',
    link: 'https://cwe.mitre.org/data/definitions/732.html',
    description:
      'When a resource is given a permission setting that provides access to a wider range of actors than required, it could lead to the exposure of sensitive information, or the modification of that resource by unintended parties. This is especially dangerous when the resource is related to program configuration, execution, or sensitive user data. For example, consider a misconfigured storage account for the cloud that can be read or written by a public or anonymous user.'
  },
  '733': {
    name: 'Compiler Optimization Removal or Modification of Security-critical Code',
    link: 'https://cwe.mitre.org/data/definitions/733.html',
    description:
      'The developer builds a security-critical protection mechanism into the software, but the compiler optimizes the program such that the mechanism is removed or modified.'
  },
  '74': {
    name: "Improper Neutralization of Special Elements in Output Used by a Downstream Component ('Injection')",
    link: 'https://cwe.mitre.org/data/definitions/74.html',
    description:
      'Software or other automated logic has certain assumptions about what constitutes data and control respectively. It is the lack of verification of these assumptions for user-controlled input that leads to injection problems. Injection problems encompass a wide variety of issues -- all mitigated in very different ways and usually attempted in order to alter the control flow of the process. For this reason, the most effective way to discuss these weaknesses is to note the distinct features that classify them as injection weaknesses. The most important issue to note is that all injection problems share one thing in common -- i.e., they allow for the injection of control plane data into the user-controlled data plane. This means that the execution of the process may be altered by sending code in through legitimate data channels, using no other mechanism. While buffer overflows, and many other flaws, involve the use of some further issue to gain execution, injection problems need only for the data to be parsed.'
  },
  '749': {
    name: 'Exposed Dangerous Method or Function',
    link: 'https://cwe.mitre.org/data/definitions/749.html',
    description: '\n            '
  },
  '75': {
    name: 'Failure to Sanitize Special Elements into a Different Plane (Special Element Injection)',
    link: 'https://cwe.mitre.org/data/definitions/75.html',
    description:
      'The product does not adequately filter user-controlled input for special elements with control implications.'
  },
  '754': {
    name: 'Improper Check for Unusual or Exceptional Conditions',
    link: 'https://cwe.mitre.org/data/definitions/754.html',
    description: '\n            '
  },
  '755': {
    name: 'Improper Handling of Exceptional Conditions',
    link: 'https://cwe.mitre.org/data/definitions/755.html',
    description: 'The product does not handle or incorrectly handles an exceptional condition.'
  },
  '756': {
    name: 'Missing Custom Error Page',
    link: 'https://cwe.mitre.org/data/definitions/756.html',
    description: 'The product does not return custom error pages to the user, possibly exposing sensitive information.'
  },
  '757': {
    name: "Selection of Less-Secure Algorithm During Negotiation ('Algorithm Downgrade')",
    link: 'https://cwe.mitre.org/data/definitions/757.html',
    description:
      'When a security mechanism can be forced to downgrade to use a less secure algorithm, this can make it easier for attackers to compromise the product by exploiting weaker algorithm. The victim might not be aware that the less secure algorithm is being used. For example, if an attacker can force a communications channel to use cleartext instead of strongly-encrypted data, then the attacker could read the channel by sniffing, instead of going through extra effort of trying to decrypt the data using brute force techniques.'
  },
  '758': {
    name: 'Reliance on Undefined, Unspecified, or Implementation-Defined Behavior',
    link: 'https://cwe.mitre.org/data/definitions/758.html',
    description:
      'This can lead to resultant weaknesses when the required properties change, such as when the product is ported to a different platform or if an interaction error (CWE-435) occurs.'
  },
  '759': {
    name: 'Use of a One-Way Hash without a Salt',
    link: 'https://cwe.mitre.org/data/definitions/759.html',
    description: '\n            '
  },
  '76': {
    name: 'Improper Neutralization of Equivalent Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/76.html',
    description:
      'The product may have a fixed list of special characters it believes is complete. However, there may be alternate encodings, or representations that also have the same meaning. For example, the product may filter out a leading slash (/) to prevent absolute path names, but does not account for a tilde (~) followed by a user name, which on some *nix systems could be expanded to an absolute pathname. Alternately, the product might filter a dangerous "-e" command-line switch when calling an external program, but it might not account for "--exec" or other switches that have the same semantics.'
  },
  '760': {
    name: 'Use of a One-Way Hash with a Predictable Salt',
    link: 'https://cwe.mitre.org/data/definitions/760.html',
    description: '\n            '
  },
  '761': {
    name: 'Free of Pointer not at Start of Buffer',
    link: 'https://cwe.mitre.org/data/definitions/761.html',
    description: '\n            '
  },
  '762': {
    name: 'Mismatched Memory Management Routines',
    link: 'https://cwe.mitre.org/data/definitions/762.html',
    description: '\n            '
  },
  '763': {
    name: 'Release of Invalid Pointer or Reference',
    link: 'https://cwe.mitre.org/data/definitions/763.html',
    description: '\n            '
  },
  '764': {
    name: 'Multiple Locks of a Critical Resource',
    link: 'https://cwe.mitre.org/data/definitions/764.html',
    description:
      "When a product is operating in a concurrent environment and repeatedly locks a critical resource, the consequences will vary based on the type of lock, the lock's implementation, and the resource being protected. In some situations such as with semaphores, the resources are pooled and extra locking calls will reduce the size of the total available pool, possibly leading to degraded performance or a denial of service. If this can be triggered by an attacker, it will be similar to an unrestricted lock (CWE-412). In the context of a binary lock, it is likely that any duplicate locking attempts will never succeed since the lock is already held and progress may not be possible."
  },
  '765': {
    name: 'Multiple Unlocks of a Critical Resource',
    link: 'https://cwe.mitre.org/data/definitions/765.html',
    description:
      "When the product is operating in a concurrent environment and repeatedly unlocks a critical resource, the consequences will vary based on the type of lock, the lock's implementation, and the resource being protected. In some situations such as with semaphores, the resources are pooled and extra calls to unlock will increase the count for the number of available resources, likely resulting in a crash or unpredictable behavior when the system nears capacity."
  },
  '766': {
    name: 'Critical Data Element Declared Public',
    link: 'https://cwe.mitre.org/data/definitions/766.html',
    description:
      'The product declares a critical variable, field, or member to be public when intended security policy requires it to be private.'
  },
  '767': {
    name: 'Access to Critical Private Variable via Public Method',
    link: 'https://cwe.mitre.org/data/definitions/767.html',
    description:
      'If an attacker modifies the variable to contain unexpected values, this could violate assumptions from other parts of the code. Additionally, if an attacker can read the private variable, it may expose sensitive information or make it easier to launch further attacks.'
  },
  '768': {
    name: 'Incorrect Short Circuit Evaluation',
    link: 'https://cwe.mitre.org/data/definitions/768.html',
    description: '\n            '
  },
  '769': {
    name: 'DEPRECATED: Uncontrolled File Descriptor Consumption',
    link: 'https://cwe.mitre.org/data/definitions/769.html',
    description:
      'This entry has been deprecated because it was a duplicate of CWE-774. All content has been transferred to CWE-774.'
  },
  '77': {
    name: "Improper Neutralization of Special Elements used in a Command ('Command Injection')",
    link: 'https://cwe.mitre.org/data/definitions/77.html',
    description: '\n            '
  },
  '770': {
    name: 'Allocation of Resources Without Limits or Throttling',
    link: 'https://cwe.mitre.org/data/definitions/770.html',
    description:
      'The product allocates a reusable resource or group of resources on behalf of an actor without imposing any restrictions on the size or number of resources that can be allocated, in violation of the intended security policy for that actor.'
  },
  '771': {
    name: 'Missing Reference to Active Allocated Resource',
    link: 'https://cwe.mitre.org/data/definitions/771.html',
    description:
      'This does not necessarily apply in languages or frameworks that automatically perform garbage collection, since the removal of all references may act as a signal that the resource is ready to be reclaimed.'
  },
  '772': {
    name: 'Missing Release of Resource after Effective Lifetime',
    link: 'https://cwe.mitre.org/data/definitions/772.html',
    description:
      'When a resource is not released after use, it can allow attackers to cause a denial of service by causing the allocation of resources without triggering their release. Frequently-affected resources include memory, CPU, disk space, power or battery, etc.'
  },
  '773': {
    name: 'Missing Reference to Active File Descriptor or Handle',
    link: 'https://cwe.mitre.org/data/definitions/773.html',
    description:
      'This can cause the product to consume all available file descriptors or handles, which can prevent other processes from performing critical file processing operations.'
  },
  '774': {
    name: 'Allocation of File Descriptors or Handles Without Limits or Throttling',
    link: 'https://cwe.mitre.org/data/definitions/774.html',
    description:
      'This can cause the product to consume all available file descriptors or handles, which can prevent other processes from performing critical file processing operations.'
  },
  '775': {
    name: 'Missing Release of File Descriptor or Handle after Effective Lifetime',
    link: 'https://cwe.mitre.org/data/definitions/775.html',
    description:
      'When a file descriptor or handle is not released after use (typically by explicitly closing it), attackers can cause a denial of service by consuming all available file descriptors/handles, or otherwise preventing other system processes from obtaining their own file descriptors/handles.'
  },
  '776': {
    name: "Improper Restriction of Recursive Entity References in DTDs ('XML Entity Expansion')",
    link: 'https://cwe.mitre.org/data/definitions/776.html',
    description:
      'If the DTD contains a large number of nested or recursive entities, this can lead to explosive growth of data when parsed, causing a denial of service.'
  },
  '777': {
    name: 'Regular Expression without Anchors',
    link: 'https://cwe.mitre.org/data/definitions/777.html',
    description:
      'When performing tasks such as validating against a set of allowed inputs (allowlist), data is examined and possibly modified to ensure that it is well-formed and adheres to a list of safe values. If the regular expression is not anchored, malicious or malformed data may be included before or after any string matching the regular expression. The type of malicious data that is allowed will depend on the context of the application and which anchors are omitted from the regular expression.'
  },
  '778': {
    name: 'Insufficient Logging',
    link: 'https://cwe.mitre.org/data/definitions/778.html',
    description: '\n\t '
  },
  '779': {
    name: 'Logging of Excessive Data',
    link: 'https://cwe.mitre.org/data/definitions/779.html',
    description:
      "While logging is a good practice in general, and very high levels of logging are appropriate for debugging stages of development, too much logging in a production environment might hinder a system administrator's ability to detect anomalous conditions. This can provide cover for an attacker while attempting to penetrate a system, clutter the audit trail for forensic analysis, or make it more difficult to debug problems in a production environment."
  },
  '78': {
    name: "Improper Neutralization of Special Elements used in an OS Command ('OS Command Injection')",
    link: 'https://cwe.mitre.org/data/definitions/78.html',
    description: '\n            '
  },
  '780': {
    name: 'Use of RSA Algorithm without OAEP',
    link: 'https://cwe.mitre.org/data/definitions/780.html',
    description:
      'Padding schemes are often used with cryptographic algorithms to make the plaintext less predictable and complicate attack efforts. The OAEP scheme is often used with RSA to nullify the impact of predictable common text.'
  },
  '781': {
    name: 'Improper Address Validation in IOCTL with METHOD_NEITHER I/O Control Code',
    link: 'https://cwe.mitre.org/data/definitions/781.html',
    description:
      'When an IOCTL uses the METHOD_NEITHER option for I/O control, it is the responsibility of the IOCTL to validate the addresses that have been supplied to it. If validation is missing or incorrect, attackers can supply arbitrary memory addresses, leading to code execution or a denial of service.'
  },
  '782': {
    name: 'Exposed IOCTL with Insufficient Access Control',
    link: 'https://cwe.mitre.org/data/definitions/782.html',
    description: '\n            '
  },
  '783': {
    name: 'Operator Precedence Logic Error',
    link: 'https://cwe.mitre.org/data/definitions/783.html',
    description:
      'While often just a bug, operator precedence logic errors can have serious consequences if they are used in security-critical code, such as making an authentication decision.'
  },
  '784': {
    name: 'Reliance on Cookies without Validation and Integrity Checking in a Security Decision',
    link: 'https://cwe.mitre.org/data/definitions/784.html',
    description:
      'Attackers can easily modify cookies, within the browser or by implementing the client-side code outside of the browser. Attackers can bypass protection mechanisms such as authorization and authentication by modifying the cookie to contain an expected value.'
  },
  '785': {
    name: 'Use of Path Manipulation Function without Maximum-sized Buffer',
    link: 'https://cwe.mitre.org/data/definitions/785.html',
    description:
      'Passing an inadequately-sized output buffer to a path manipulation function can result in a buffer overflow. Such functions include realpath(), readlink(), PathAppend(), and others.'
  },
  '786': {
    name: 'Access of Memory Location Before Start of Buffer',
    link: 'https://cwe.mitre.org/data/definitions/786.html',
    description:
      'This typically occurs when a pointer or its index is decremented to a position before the buffer, when pointer arithmetic results in a position before the beginning of the valid memory location, or when a negative index is used.'
  },
  '787': {
    name: 'Out-of-bounds Write',
    link: 'https://cwe.mitre.org/data/definitions/787.html',
    description:
      'Typically, this can result in corruption of data, a crash, or code execution.  The product may modify an index or perform pointer arithmetic that references a memory location that is outside of the boundaries of the buffer.  A subsequent write operation then produces undefined or unexpected results.'
  },
  '788': {
    name: 'Access of Memory Location After End of Buffer',
    link: 'https://cwe.mitre.org/data/definitions/788.html',
    description:
      'This typically occurs when a pointer or its index is incremented to a position after the buffer; or when pointer arithmetic results in a position after the buffer.'
  },
  '789': {
    name: 'Memory Allocation with Excessive Size Value',
    link: 'https://cwe.mitre.org/data/definitions/789.html',
    description:
      'The product allocates memory based on an untrusted, large size value, but it does not ensure that the size is within expected limits, allowing arbitrary amounts of memory to be allocated.'
  },
  '79': {
    name: "Improper Neutralization of Input During Web Page Generation ('Cross-site Scripting')",
    link: 'https://cwe.mitre.org/data/definitions/79.html',
    description: '\n            '
  },
  '790': {
    name: 'Improper Filtering of Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/790.html',
    description:
      'The product receives data from an upstream component, but does not filter or incorrectly filters special elements before sending it to a downstream component.'
  },
  '791': {
    name: 'Incomplete Filtering of Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/791.html',
    description:
      'The product receives data from an upstream component, but does not completely filter special elements before sending it to a downstream component.'
  },
  '792': {
    name: 'Incomplete Filtering of One or More Instances of Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/792.html',
    description: '\n            '
  },
  '793': {
    name: 'Only Filtering One Instance of a Special Element',
    link: 'https://cwe.mitre.org/data/definitions/793.html',
    description:
      'Incomplete filtering of this nature may be location-dependent, as in only the first or last element is filtered.'
  },
  '794': {
    name: 'Incomplete Filtering of Multiple Instances of Special Elements',
    link: 'https://cwe.mitre.org/data/definitions/794.html',
    description: '\n            '
  },
  '795': {
    name: 'Only Filtering Special Elements at a Specified Location',
    link: 'https://cwe.mitre.org/data/definitions/795.html',
    description: '\n            '
  },
  '796': {
    name: 'Only Filtering Special Elements Relative to a Marker',
    link: 'https://cwe.mitre.org/data/definitions/796.html',
    description:
      'The product receives data from an upstream component, but only accounts for special elements positioned relative to a marker (e.g. "at the beginning/end of a string; the second argument"), thereby missing remaining special elements that may exist before sending it to a downstream component.'
  },
  '797': {
    name: 'Only Filtering Special Elements at an Absolute Position',
    link: 'https://cwe.mitre.org/data/definitions/797.html',
    description:
      'The product receives data from an upstream component, but only accounts for special elements at an absolute position (e.g. "byte number 10"), thereby missing remaining special elements that may exist before sending it to a downstream component.'
  },
  '798': {
    name: 'Use of Hard-coded Credentials',
    link: 'https://cwe.mitre.org/data/definitions/798.html',
    description: '\n            '
  },
  '799': {
    name: 'Improper Control of Interaction Frequency',
    link: 'https://cwe.mitre.org/data/definitions/799.html',
    description:
      'This can allow the actor to perform actions more frequently than expected. The actor could be a human or an automated process such as a virus or bot. This could be used to cause a denial of service, compromise program logic (such as limiting humans to a single vote), or other consequences. For example, an authentication routine might not limit the number of times an attacker can guess a password. Or, a web site might conduct a poll but only expect humans to vote a maximum of once a day.'
  },
  '8': {
    name: 'J2EE Misconfiguration: Entity Bean Declared Remote',
    link: 'https://cwe.mitre.org/data/definitions/8.html',
    description:
      "When an application exposes a remote interface for an entity bean, it might also expose methods that get or set the bean's data. These methods could be leveraged to read sensitive information, or to change data in ways that violate the application's expectations, potentially leading to other vulnerabilities."
  },
  '80': {
    name: 'Improper Neutralization of Script-Related HTML Tags in a Web Page (Basic XSS)',
    link: 'https://cwe.mitre.org/data/definitions/80.html',
    description:
      "This may allow such characters to be treated as control characters, which are executed client-side in the context of the user's session. Although this can be classified as an injection problem, the more pertinent issue is the improper conversion of such special characters to respective context-appropriate entities before displaying them to the user."
  },
  '804': {
    name: 'Guessable CAPTCHA',
    link: 'https://cwe.mitre.org/data/definitions/804.html',
    description: '\n            '
  },
  '805': {
    name: 'Buffer Access with Incorrect Length Value',
    link: 'https://cwe.mitre.org/data/definitions/805.html',
    description: 'When the length value exceeds the size of the destination, a buffer overflow could occur.'
  },
  '806': {
    name: 'Buffer Access Using Size of Source Buffer',
    link: 'https://cwe.mitre.org/data/definitions/806.html',
    description:
      'When the size of the destination is smaller than the size of the source, a buffer overflow could occur.'
  },
  '807': {
    name: 'Reliance on Untrusted Inputs in a Security Decision',
    link: 'https://cwe.mitre.org/data/definitions/807.html',
    description: '\n            '
  },
  '81': {
    name: 'Improper Neutralization of Script in an Error Message Web Page',
    link: 'https://cwe.mitre.org/data/definitions/81.html',
    description: '\n            '
  },
  '82': {
    name: 'Improper Neutralization of Script in Attributes of IMG Tags in a Web Page',
    link: 'https://cwe.mitre.org/data/definitions/82.html',
    description:
      "Attackers can embed XSS exploits into the values for IMG attributes (e.g. SRC) that is streamed and then executed in a victim's browser. Note that when the page is loaded into a user's browsers, the exploit will automatically execute."
  },
  '820': {
    name: 'Missing Synchronization',
    link: 'https://cwe.mitre.org/data/definitions/820.html',
    description:
      'If access to a shared resource is not synchronized, then the resource may not be in a state that is expected by the product. This might lead to unexpected or insecure behaviors, especially if an attacker can influence the shared resource.'
  },
  '821': {
    name: 'Incorrect Synchronization',
    link: 'https://cwe.mitre.org/data/definitions/821.html',
    description:
      'If access to a shared resource is not correctly synchronized, then the resource may not be in a state that is expected by the product. This might lead to unexpected or insecure behaviors, especially if an attacker can influence the shared resource.'
  },
  '822': {
    name: 'Untrusted Pointer Dereference',
    link: 'https://cwe.mitre.org/data/definitions/822.html',
    description: '\n            '
  },
  '823': {
    name: 'Use of Out-of-range Pointer Offset',
    link: 'https://cwe.mitre.org/data/definitions/823.html',
    description: '\n            '
  },
  '824': {
    name: 'Access of Uninitialized Pointer',
    link: 'https://cwe.mitre.org/data/definitions/824.html',
    description: '\n            '
  },
  '825': {
    name: 'Expired Pointer Dereference',
    link: 'https://cwe.mitre.org/data/definitions/825.html',
    description:
      'When a product releases memory, but it maintains a pointer to that memory, then the memory might be re-allocated at a later time. If the original pointer is accessed to read or write data, then this could cause the product to read or modify data that is in use by a different function or process. Depending on how the newly-allocated memory is used, this could lead to a denial of service, information exposure, or code execution.'
  },
  '826': {
    name: 'Premature Release of Resource During Expected Lifetime',
    link: 'https://cwe.mitre.org/data/definitions/826.html',
    description: '\n            '
  },
  '827': {
    name: 'Improper Control of Document Type Definition',
    link: 'https://cwe.mitre.org/data/definitions/827.html',
    description: '\n            '
  },
  '828': {
    name: 'Signal Handler with Functionality that is not Asynchronous-Safe',
    link: 'https://cwe.mitre.org/data/definitions/828.html',
    description: '\n            '
  },
  '829': {
    name: 'Inclusion of Functionality from Untrusted Control Sphere',
    link: 'https://cwe.mitre.org/data/definitions/829.html',
    description: '\n            '
  },
  '83': {
    name: 'Improper Neutralization of Script in Attributes in a Web Page',
    link: 'https://cwe.mitre.org/data/definitions/83.html',
    description:
      'The product does not neutralize or incorrectly neutralizes "javascript:" or other URIs from dangerous attributes within tags, such as onmouseover, onload, onerror, or style.'
  },
  '830': {
    name: 'Inclusion of Web Functionality from an Untrusted Source',
    link: 'https://cwe.mitre.org/data/definitions/830.html',
    description: '\n            '
  },
  '831': {
    name: 'Signal Handler Function Associated with Multiple Signals',
    link: 'https://cwe.mitre.org/data/definitions/831.html',
    description: '\n            '
  },
  '832': {
    name: 'Unlock of a Resource that is not Locked',
    link: 'https://cwe.mitre.org/data/definitions/832.html',
    description:
      'Depending on the locking functionality, an unlock of a non-locked resource might cause memory corruption or other modification to the resource (or its associated metadata that is used for tracking locks).'
  },
  '833': {
    name: 'Deadlock',
    link: 'https://cwe.mitre.org/data/definitions/833.html',
    description:
      'The product contains multiple threads or executable segments that are waiting for each other to release a necessary lock, resulting in deadlock.'
  },
  '834': {
    name: 'Excessive Iteration',
    link: 'https://cwe.mitre.org/data/definitions/834.html',
    description:
      'If the iteration can be influenced by an attacker, this weakness could allow attackers to consume excessive resources such as CPU or memory. In many cases, a loop does not need to be infinite in order to cause enough resource consumption to adversely affect the product or its host system; it depends on the amount of resources consumed per iteration.'
  },
  '835': {
    name: "Loop with Unreachable Exit Condition ('Infinite Loop')",
    link: 'https://cwe.mitre.org/data/definitions/835.html',
    description:
      'If the loop can be influenced by an attacker, this weakness could allow attackers to consume excessive resources such as CPU or memory.'
  },
  '836': {
    name: 'Use of Password Hash Instead of Password for Authentication',
    link: 'https://cwe.mitre.org/data/definitions/836.html',
    description: '\n            '
  },
  '837': {
    name: 'Improper Enforcement of a Single, Unique Action',
    link: 'https://cwe.mitre.org/data/definitions/837.html',
    description:
      'In various applications, a user is only expected to perform a certain action once, such as voting, requesting a refund, or making a purchase. When this restriction is not enforced, sometimes this can have security implications. For example, in a voting application, an attacker could attempt to "stuff the ballot box" by voting multiple times. If these votes are counted separately, then the attacker could directly affect who wins the vote. This could have significant business impact depending on the purpose of the product.'
  },
  '838': {
    name: 'Inappropriate Encoding for Output Context',
    link: 'https://cwe.mitre.org/data/definitions/838.html',
    description: '\n            '
  },
  '839': {
    name: 'Numeric Range Comparison Without Minimum Check',
    link: 'https://cwe.mitre.org/data/definitions/839.html',
    description: '\n            '
  },
  '84': {
    name: 'Improper Neutralization of Encoded URI Schemes in a Web Page',
    link: 'https://cwe.mitre.org/data/definitions/84.html',
    description:
      'The web application improperly neutralizes user-controlled input for executable script disguised with URI encodings.'
  },
  '841': {
    name: 'Improper Enforcement of Behavioral Workflow',
    link: 'https://cwe.mitre.org/data/definitions/841.html',
    description: '\n            '
  },
  '842': {
    name: 'Placement of User into Incorrect Group',
    link: 'https://cwe.mitre.org/data/definitions/842.html',
    description:
      'If the incorrect group has more access or privileges than the intended group, the user might be able to bypass intended security policy to access unexpected resources or perform unexpected actions. The access-control system might not be able to detect malicious usage of this group membership.'
  },
  '843': {
    name: "Access of Resource Using Incompatible Type ('Type Confusion')",
    link: 'https://cwe.mitre.org/data/definitions/843.html',
    description: '\n            '
  },
  '85': {
    name: 'Doubled Character XSS Manipulations',
    link: 'https://cwe.mitre.org/data/definitions/85.html',
    description:
      'The web application does not filter user-controlled input for executable script disguised using doubling of the involved characters.'
  },
  '86': {
    name: 'Improper Neutralization of Invalid Characters in Identifiers in Web Pages',
    link: 'https://cwe.mitre.org/data/definitions/86.html',
    description:
      'Some web browsers may remove these sequences, resulting in output that may have unintended control implications. For example, the product may attempt to remove a "javascript:" URI scheme, but a "java%00script:" URI may bypass this check and still be rendered as active javascript by some browsers, allowing XSS or other attacks.'
  },
  '862': {
    name: 'Missing Authorization',
    link: 'https://cwe.mitre.org/data/definitions/862.html',
    description: '\n            '
  },
  '863': {
    name: 'Incorrect Authorization',
    link: 'https://cwe.mitre.org/data/definitions/863.html',
    description: '\n            '
  },
  '87': {
    name: 'Improper Neutralization of Alternate XSS Syntax',
    link: 'https://cwe.mitre.org/data/definitions/87.html',
    description:
      'The product does not neutralize or incorrectly neutralizes user-controlled input for alternate script syntax.'
  },
  '88': {
    name: "Improper Neutralization of Argument Delimiters in a Command ('Argument Injection')",
    link: 'https://cwe.mitre.org/data/definitions/88.html',
    description: '\n            '
  },
  '89': {
    name: "Improper Neutralization of Special Elements used in an SQL Command ('SQL Injection')",
    link: 'https://cwe.mitre.org/data/definitions/89.html',
    description: '\n            '
  },
  '9': {
    name: 'J2EE Misconfiguration: Weak Access Permissions for EJB Methods',
    link: 'https://cwe.mitre.org/data/definitions/9.html',
    description:
      'If the EJB deployment descriptor contains one or more method permissions that grant access to the special ANYONE role, it indicates that access control for the application has not been fully thought through or that the application is structured in such a way that reasonable access control restrictions are impossible.'
  },
  '90': {
    name: "Improper Neutralization of Special Elements used in an LDAP Query ('LDAP Injection')",
    link: 'https://cwe.mitre.org/data/definitions/90.html',
    description:
      'The product constructs all or part of an LDAP query using externally-influenced input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could modify the intended LDAP query when it is sent to a downstream component.'
  },
  '908': {
    name: 'Use of Uninitialized Resource',
    link: 'https://cwe.mitre.org/data/definitions/908.html',
    description:
      'When a resource has not been properly initialized, the product may behave unexpectedly. This may lead to a crash or invalid memory access, but the consequences vary depending on the type of resource and how it is used within the product.'
  },
  '909': {
    name: 'Missing Initialization of Resource',
    link: 'https://cwe.mitre.org/data/definitions/909.html',
    description:
      'Many resources require initialization before they can be properly used. If a resource is not initialized, it could contain unpredictable or expired data, or it could be initialized to defaults that are invalid. This can have security implications when the resource is expected to have certain properties or values.'
  },
  '91': {
    name: 'XML Injection (aka Blind XPath Injection)',
    link: 'https://cwe.mitre.org/data/definitions/91.html',
    description:
      'Within XML, special elements could include reserved words or characters such as "<", ">", """, and "&", which could then be used to add new data or modify XML syntax.'
  },
  '910': {
    name: 'Use of Expired File Descriptor',
    link: 'https://cwe.mitre.org/data/definitions/910.html',
    description:
      'After a file descriptor for a particular file or device has been released, it can be reused. The code might not write to the original file, since the reused file descriptor might reference a different file or device.'
  },
  '911': {
    name: 'Improper Update of Reference Count',
    link: 'https://cwe.mitre.org/data/definitions/911.html',
    description:
      'Reference counts can be used when tracking how many objects contain a reference to a particular resource, such as in memory management or garbage collection. When the reference count reaches zero, the resource can be de-allocated or reused because there are no more objects that use it. If the reference count accidentally reaches zero, then the resource might be released too soon, even though it is still in use. If all objects no longer use the resource, but the reference count is not zero, then the resource might not ever be released.'
  },
  '912': {
    name: 'Hidden Functionality',
    link: 'https://cwe.mitre.org/data/definitions/912.html',
    description:
      'Hidden functionality can take many forms, such as intentionally malicious code, "Easter Eggs" that contain extraneous functionality such as games, developer-friendly shortcuts that reduce maintenance or support costs such as hard-coded accounts, etc. From a security perspective, even when the functionality is not intentionally malicious or damaging, it can increase the product\'s attack surface and expose additional weaknesses beyond what is already exposed by the intended functionality. Even if it is not easily accessible, the hidden functionality could be useful for attacks that modify the control flow of the application.'
  },
  '913': {
    name: 'Improper Control of Dynamically-Managed Code Resources',
    link: 'https://cwe.mitre.org/data/definitions/913.html',
    description:
      'Many languages offer powerful features that allow the programmer to dynamically create or modify existing code, or resources used by code such as variables and objects. While these features can offer significant flexibility and reduce development time, they can be extremely dangerous if attackers can directly influence these code resources in unexpected ways.'
  },
  '914': {
    name: 'Improper Control of Dynamically-Identified Variables',
    link: 'https://cwe.mitre.org/data/definitions/914.html',
    description:
      'Many languages offer powerful features that allow the programmer to access arbitrary variables that are specified by an input string. While these features can offer significant flexibility and reduce development time, they can be extremely dangerous if attackers can modify unintended variables that have security implications.'
  },
  '915': {
    name: 'Improperly Controlled Modification of Dynamically-Determined Object Attributes',
    link: 'https://cwe.mitre.org/data/definitions/915.html',
    description: '\n            '
  },
  '916': {
    name: 'Use of Password Hash With Insufficient Computational Effort',
    link: 'https://cwe.mitre.org/data/definitions/916.html',
    description: '\n            '
  },
  '917': {
    name:
      "Improper Neutralization of Special Elements used in an Expression Language Statement ('Expression Language Injection')",
    link: 'https://cwe.mitre.org/data/definitions/917.html',
    description:
      'Frameworks such as Java Server Page (JSP) allow a developer to insert executable expressions within otherwise-static content. When the developer is not aware of the executable nature of these expressions and/or does not disable them, then if an attacker can inject expressions, this could lead to code execution or other unexpected behaviors.'
  },
  '918': {
    name: 'Server-Side Request Forgery (SSRF)',
    link: 'https://cwe.mitre.org/data/definitions/918.html',
    description:
      'By providing URLs to unexpected hosts or ports, attackers can make it appear that the server is sending the request, possibly bypassing access controls such as firewalls that prevent the attackers from accessing the URLs directly. The server can be used as a proxy to conduct port scanning of hosts in internal networks, use other URLs such as that can access documents on the system (using file://), or use other protocols such as gopher:// or tftp://, which may provide greater control over the contents of requests.'
  },
  '92': {
    name: 'DEPRECATED: Improper Sanitization of Custom Special Characters',
    link: 'https://cwe.mitre.org/data/definitions/92.html',
    description:
      'This entry has been deprecated. It originally came from PLOVER, which sometimes defined "other" and "miscellaneous" categories in order to satisfy exhaustiveness requirements for taxonomies. Within the context of CWE, the use of a more abstract entry is preferred in mapping situations. CWE-75 is a more appropriate mapping.'
  },
  '920': {
    name: 'Improper Restriction of Power Consumption',
    link: 'https://cwe.mitre.org/data/definitions/920.html',
    description: '\n            '
  },
  '921': {
    name: 'Storage of Sensitive Data in a Mechanism without Access Control',
    link: 'https://cwe.mitre.org/data/definitions/921.html',
    description: '\n            '
  },
  '922': {
    name: 'Insecure Storage of Sensitive Information',
    link: 'https://cwe.mitre.org/data/definitions/922.html',
    description:
      'If read access is not properly restricted, then attackers can steal the sensitive information. If write access is not properly restricted, then attackers can modify and possibly delete the data, causing incorrect results and possibly a denial of service.'
  },
  '923': {
    name: 'Improper Restriction of Communication Channel to Intended Endpoints',
    link: 'https://cwe.mitre.org/data/definitions/923.html',
    description: '\n            '
  },
  '924': {
    name: 'Improper Enforcement of Message Integrity During Transmission in a Communication Channel',
    link: 'https://cwe.mitre.org/data/definitions/924.html',
    description:
      'Attackers might be able to modify the message and spoof the endpoint by interfering with the data as it crosses the network or by redirecting the connection to a system under their control.'
  },
  '925': {
    name: 'Improper Verification of Intent by Broadcast Receiver',
    link: 'https://cwe.mitre.org/data/definitions/925.html',
    description:
      'Certain types of Intents, identified by action string, can only be broadcast by the operating system itself, not by third-party applications. However, when an application registers to receive these implicit system intents, it is also registered to receive any explicit intents. While a malicious application cannot send an implicit system intent, it can send an explicit intent to the target application, which may assume that any received intent is a valid implicit system intent and not an explicit intent from another application. This may lead to unintended behavior.'
  },
  '926': {
    name: 'Improper Export of Android Application Components',
    link: 'https://cwe.mitre.org/data/definitions/926.html',
    description: '\n            '
  },
  '927': {
    name: 'Use of Implicit Intent for Sensitive Communication',
    link: 'https://cwe.mitre.org/data/definitions/927.html',
    description: '\n            '
  },
  '93': {
    name: "Improper Neutralization of CRLF Sequences ('CRLF Injection')",
    link: 'https://cwe.mitre.org/data/definitions/93.html',
    description:
      'The product uses CRLF (carriage return line feeds) as a special element, e.g. to separate lines or records, but it does not neutralize or incorrectly neutralizes CRLF sequences from inputs.'
  },
  '939': {
    name: 'Improper Authorization in Handler for Custom URL Scheme',
    link: 'https://cwe.mitre.org/data/definitions/939.html',
    description:
      "Mobile platforms and other architectures allow the use of custom URL schemes to facilitate communication between applications. In the case of iOS, this is the only method to do inter-application communication. The implementation is at the developer's discretion which may open security flaws in the application. An example could be potentially dangerous functionality such as modifying files through a custom URL scheme."
  },
  '94': {
    name: "Improper Control of Generation of Code ('Code Injection')",
    link: 'https://cwe.mitre.org/data/definitions/94.html',
    description: '\n            '
  },
  '940': {
    name: 'Improper Verification of Source of a Communication Channel',
    link: 'https://cwe.mitre.org/data/definitions/940.html',
    description:
      'When an attacker can successfully establish a communication channel from an untrusted origin, the attacker may be able to gain privileges and access unexpected functionality.'
  },
  '941': {
    name: 'Incorrectly Specified Destination in a Communication Channel',
    link: 'https://cwe.mitre.org/data/definitions/941.html',
    description: '\n            '
  },
  '942': {
    name: 'Permissive Cross-domain Policy with Untrusted Domains',
    link: 'https://cwe.mitre.org/data/definitions/942.html',
    description: '\n            '
  },
  '943': {
    name: 'Improper Neutralization of Special Elements in Data Query Logic',
    link: 'https://cwe.mitre.org/data/definitions/943.html',
    description: '\n            '
  },
  '95': {
    name: "Improper Neutralization of Directives in Dynamically Evaluated Code ('Eval Injection')",
    link: 'https://cwe.mitre.org/data/definitions/95.html',
    description: 'This may allow an attacker to execute arbitrary code, or at least modify what code can be executed.'
  },
  '96': {
    name: "Improper Neutralization of Directives in Statically Saved Code ('Static Code Injection')",
    link: 'https://cwe.mitre.org/data/definitions/96.html',
    description:
      'The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes code syntax before inserting the input into an executable resource, such as a library, configuration file, or template.'
  },
  '97': {
    name: 'Improper Neutralization of Server-Side Includes (SSI) Within a Web Page',
    link: 'https://cwe.mitre.org/data/definitions/97.html',
    description:
      'The product generates a web page, but does not neutralize or incorrectly neutralizes user-controllable input that could be interpreted as a server-side include (SSI) directive.'
  },
  '98': {
    name: "Improper Control of Filename for Include/Require Statement in PHP Program ('PHP Remote File Inclusion')",
    link: 'https://cwe.mitre.org/data/definitions/98.html',
    description:
      'In certain versions and configurations of PHP, this can allow an attacker to specify a URL to a remote location from which the product will obtain the code to execute. In other cases in association with path traversal, the attacker can specify a local file that may contain executable statements that can be parsed by PHP.'
  },
  '99': {
    name: "Improper Control of Resource Identifiers ('Resource Injection')",
    link: 'https://cwe.mitre.org/data/definitions/99.html',
    description: '\n            '
  }
};
