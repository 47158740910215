export function IonixLogo() {
  return (
    <svg width="222" height="63" viewBox="0 0 74 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1370_203)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.25958C4.26702 3.25958 7.40311 6.30978 7.40311 9.69587H9.28146H10.6627H12.541C12.541 6.30978 15.6771 3.25958 19.9442 3.25958V0C18.3625 0 16.839 0.320091 15.4628 0.900324C11.6851 2.57755 10.0953 6.44589 9.97208 8.88058C9.84889 6.44574 8.25875 2.577 4.48054 0.899968C3.10453 0.319961 1.58134 0 0 0V3.25958ZM9.5237e-06 17.5686C4.26704 17.5686 7.40314 14.5184 7.40314 11.1323H9.28146H12.541C12.541 14.5184 15.6771 17.5686 19.9442 17.5686V20.8282C18.3622 20.8282 16.8385 20.508 15.4621 19.9276C11.6854 18.2504 10.0956 14.383 9.97211 11.9485C9.84859 14.3833 8.2584 18.2512 4.48074 19.9281C3.1047 20.5082 1.58143 20.8282 9.23875e-06 20.8282L9.5237e-06 17.5686Z"
          fill="white"
        />
        <path d="M28.5993 4.86926V17.1441H26.7473V4.86926H28.5993Z" fill="white" />
        <path
          d="M42.4655 11.0067C42.4655 12.3173 42.2256 13.4441 41.7463 14.3871C41.2667 15.3261 40.6095 16.0493 39.7744 16.5568C38.9433 17.0603 37.9982 17.312 36.9395 17.312C35.8765 17.312 34.9274 17.0603 34.0923 16.5568C33.2612 16.0493 32.606 15.3241 32.1264 14.3811C31.6471 13.4381 31.4072 12.3133 31.4072 11.0067C31.4072 9.69615 31.6471 8.57135 32.1264 7.63235C32.606 6.68936 33.2612 5.96612 34.0923 5.46266C34.9274 4.9552 35.8765 4.70148 36.9395 4.70148C37.9982 4.70148 38.9433 4.9552 39.7744 5.46266C40.6095 5.96612 41.2667 6.68936 41.7463 7.63235C42.2256 8.57135 42.4655 9.69615 42.4655 11.0067ZM40.6313 11.0067C40.6313 10.0078 40.4695 9.16671 40.146 8.48345C39.8263 7.79617 39.3867 7.27672 38.8273 6.9251C38.272 6.56949 37.6426 6.39167 36.9395 6.39167C36.2321 6.39167 35.6007 6.56949 35.0454 6.9251C34.49 7.27672 34.0504 7.79617 33.7267 8.48345C33.4072 9.16671 33.2474 10.0078 33.2474 11.0067C33.2474 12.0057 33.4072 12.8488 33.7267 13.536C34.0504 14.2193 34.49 14.7388 35.0454 15.0944C35.6007 15.446 36.2321 15.6218 36.9395 15.6218C37.6426 15.6218 38.272 15.446 38.8273 15.0944C39.3867 14.7388 39.8263 14.2193 40.146 13.536C40.4695 12.8488 40.6313 12.0057 40.6313 11.0067Z"
          fill="white"
        />
        <path
          d="M55.1882 4.86926V17.1441H53.4861L47.2466 8.14175H47.1327V17.1441H45.2808V4.86926H46.9949L53.2401 13.8836H53.3541V4.86926H55.1882Z"
          fill="white"
        />
        <path d="M60.2482 4.86926V17.1441H58.3962V4.86926H60.2482Z" fill="white" />
        <path
          d="M64.7405 4.86926L67.6772 9.67013H67.7731L70.7099 4.86926H72.8557L69.0318 11.0067L72.8798 17.1441H70.7219L67.7731 12.4092H67.6772L64.7284 17.1441H62.5706L66.4845 11.0067L62.5946 4.86926H64.7405Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1370_203">
          <rect width="73.0241" height="20.8282" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
