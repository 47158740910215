import React from 'react';
import { IconRadioButton } from 'common/components/Icons/IconRadioButton/IconRadioButton';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useNotifications, NotificationsContextType } from 'pages/Settings/Notifications/NotificationsContext';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { makeStyles } from '@material-ui/core/styles';
import { REQUIRED_FILTERS } from 'pages/Settings/Notifications/SidePanel/utils';

const useStyles = makeStyles({
  textRoot: {
    width: '100%'
  }
});
interface INumberFilter {
  id: string;
}

const NumberFilter: React.FC<INumberFilter> = ({ id }) => {
  const { notificationType } = useNotifications() as NotificationsContextType;
  const {
    notificationData,
    onConfigurationChange
  } = useNotificationConfiguration() as NotificationConfigurationContextType;
  const classes = useStyles();

  const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
  const data = requiredFields.includes(id) ? notificationData : notificationData.filters;
  const areFilters = !!data && !!data[id];
  const options = areFilters && data[id].choices.filter((f: any) => !!f.is_toggled || !!f.value);

  const handleRadioOnChange = (selectedOption: any) => () => {
    onConfigurationChange(id, {
      text: !!options.length ? options[0].value : '',
      option: selectedOption
    });
  };

  const handleTextOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onConfigurationChange(id, {
      text: !!event.target.value ? event.target.value : '',
      option: !!options.length && !!options[0].is_toggled ? options[0] : null
    });
  };

  return areFilters ? (
    <Grid container direction="column" className="visible">
      <Grid item sx={{ padding: '10px 14px', width: '100%' }}>
        <TextField
          classes={{ root: classes.textRoot }}
          value={!!options.length ? options[0].value : ''}
          placeholder="Please enter Number"
          onChange={handleTextOnChange}
          inputProps={{ 'data-testid': `dropdownbox-NumberFilter-input-${id}` }}
          type="number"
        />
      </Grid>
      {data[id].choices.map((option: any) => {
        return (
          <Grid
            item
            key={option.name}
            onClick={handleRadioOnChange(option)}
            className="dropdownbox-item"
            data-testid={`dropdownbox-NumberFilter-option-${option.name}`}
          >
            <Grid container alignItems="center">
              <Grid item>
                <IconRadioButton radioSize="little" toggle={option.is_toggled} />
              </Grid>
              <Grid item sx={{ paddingLeft: 1 }}>
                {option.label}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  ) : null;
};

export default NumberFilter;
