import { IconActiveProtection } from 'common/components/Icons/IconActiveProtection/IconActiveProtection';
import { IconRisk } from 'common/components/Icons/IconRisk/IconRisk';
import { Common } from 'common/services/common.service';
import './urgencyRenderer.scss';

export function UrgencyRenderer(props: any) {
  return (
    <div className="risk-render-scope">
      <IconRisk urgency={props.value.type} />
      <div className="urgencyRendere-text">{Common.getTextByUrgency(props.value.type)}</div>
      {props.value.is_active_protection && <IconActiveProtection />}
    </div>
  );
}
