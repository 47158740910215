import classNames from 'classnames';
import './DeactivateAccount.scss';
import { Button } from '@cyberpion/cyberpion-ui';
import { useState } from 'react';

export function DeactivateAccount(props: any) {
  const [confirmed, setConfirmed] = useState<boolean>(false);

  return (
    <div className={classNames('DeactivateAccount', { visible: props.visible })}>
      <h6>Are you sure?</h6>
      <p>Deactivating the account will unravel the structure of reality and will bring an end to all life on earth</p>
      <div className="deactivate-action">
        <div>
          <input type="checkbox" checked={confirmed} onChange={() => setConfirmed(!confirmed)} /> Click here to confirm
          deactivation
        </div>
        <div>
          <Button type="button" buttonStyle="secondary" onClick={props.onCancel} text="Cancel" size={'medium'} />
          <Button
            type="button"
            buttonStyle="main"
            onClick={props.onCancel}
            text="Deacticate Account"
            size={'medium'}
            disabled={!confirmed}
          />
        </div>
      </div>
    </div>
  );
}
