import { useRef, useState } from 'react';
import './EditSavedView.scss';

import { setEditMode, updateLabel, updateUserView } from 'features/ViewsSlice';
import { useDispatch } from 'react-redux';
import { useClickOutside } from 'hooks/useClickOutside';

export function EditSavedView(props: any) {
  const dispatch = useDispatch<any>();
  const ref = useRef(null);
  const [value, setValue] = useState<string>(props.view.label);
  useClickOutside(ref, () => {
    dispatch(setEditMode(null));
  });

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (value.length > 0) {
        dispatch(
          updateUserView({
            id: props.view.id,
            label: value
          })
        );
        dispatch(
          updateLabel({
            id: props.view.id,
            label: value
          })
        );
        dispatch(setEditMode(null));
      }
    }
  };

  return (
    <input
      ref={ref}
      className="EditSavedView"
      type="text"
      value={value}
      maxLength={80}
      onChange={(e: any) => {
        setValue(e.target.value);
      }}
      onKeyDown={handleKeyDown}
      autoFocus
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    />
  );
}
