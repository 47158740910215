import { useState } from 'react';

const useShowToggle = (initialValue = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialValue);

  const toggle = () => {
    setIsOpen(toggle => !toggle);
  };

  const setToggle = (value: boolean) => {
    setIsOpen(value);
  };

  return { isOpen, toggle, setToggle };
};

export default useShowToggle;
