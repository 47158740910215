import { Common } from 'common/services/common.service';
import './testResultRenderer.scss';

export function TestResultRenderer(props: any) {
  if (!props.value) {
    return null;
  }

  let color = Common.getColorByTestResult(props.value);

  return (
    <div className="test-result-render">
      <div
        className="test-result-background"
        style={{
          background: color
        }}
      ></div>
      <div
        className="test-result-border"
        style={{
          border: `solid 1px ${color}`,
          boxShadow: `0 0 .3rem ${color}`
        }}
      ></div>
      <div className="test-result-text">{props.value.toUpperCase()}</div>
    </div>
  );
}
