import classNames from 'classnames';
import './style.scss';
import { HierarchyItems, LabelWithIcons } from '@cyberpion/cyberpion-ui';
import { useEffect, useRef } from 'react';

export const USER_ICON: any = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="3" fill="#3455DD" />
    <circle cx="7.99841" cy="6.07263" r="1.57263" stroke="white" />
    <mask id="path-3-inside-1_4716_113596" fill="white">
      <path d="M10.902 12.1956C11.3598 12.1956 11.7402 11.8199 11.6393 11.3733C11.4851 10.691 11.1408 10.06 10.6383 9.55755C9.93865 8.8579 8.98973 8.46484 8.00027 8.46484C7.01082 8.46484 6.06189 8.8579 5.36224 9.55755C4.85978 10.06 4.51544 10.691 4.36126 11.3733C4.26033 11.8199 4.64071 12.1956 5.09858 12.1956L8.00027 12.1956H10.902Z" />
    </mask>
    <path
      d="M10.902 12.1956C11.3598 12.1956 11.7402 11.8199 11.6393 11.3733C11.4851 10.691 11.1408 10.06 10.6383 9.55755C9.93865 8.8579 8.98973 8.46484 8.00027 8.46484C7.01082 8.46484 6.06189 8.8579 5.36224 9.55755C4.85978 10.06 4.51544 10.691 4.36126 11.3733C4.26033 11.8199 4.64071 12.1956 5.09858 12.1956L8.00027 12.1956H10.902Z"
      stroke="white"
      stroke-width="2"
      mask="url(#path-3-inside-1_4716_113596)"
    />
  </svg>
);

const GLOBE_ICON: any = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="5.5" stroke="#5D6576" />
    <path d="M1.04932 6H10.9493" stroke="#5D6576" stroke-linecap="round" />
    <path
      d="M6.30885 0.908854L5.99961 0.599609L5.69036 0.908854C2.87882 3.7204 2.87882 8.27882 5.69036 11.0904L5.99961 11.3996L6.30885 11.0904C9.1204 8.27882 9.1204 3.7204 6.30885 0.908854Z"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export function AdditionalSeedAssets(props: any) {
  const ref: any = useRef(null);
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      props.onClose();
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div ref={ref} className={classNames('AdditionalSeedAssets', { visible: props.visible })}>
      <div className="scrollbar-common">
        <ul className="main-list">
          <li>
            <HierarchyItems
              data={{
                top: [<LabelWithIcons icons={[GLOBE_ICON, USER_ICON]} label="Infosys" />],
                bottom: [<LabelWithIcons icons={[GLOBE_ICON, USER_ICON]} label="infosys.com" />]
              }}
            />
          </li>
          <li>
            <HierarchyItems
              data={{
                top: [<LabelWithIcons icons={[GLOBE_ICON, USER_ICON]} label="Infosys UK" />],
                bottom: [
                  <LabelWithIcons icons={[GLOBE_ICON, USER_ICON]} label="infosys.com" />,
                  <LabelWithIcons icons={[GLOBE_ICON, USER_ICON]} label="infosys.org" />
                ]
              }}
            />
          </li>
          <li>
            <HierarchyItems
              data={{
                top: [<LabelWithIcons icons={[GLOBE_ICON, USER_ICON]} label="Infosys EU" />],
                bottom: [
                  <LabelWithIcons icons={[GLOBE_ICON, USER_ICON]} label="infosys.com" />,
                  <LabelWithIcons icons={[GLOBE_ICON, USER_ICON]} label="infosys.org" />
                ]
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}
