import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconEdgio extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.2372 3L17.5721 6.17456H9.45722V10.0169H16.1508L15.3383 13.1897H9.45779V17.1814H15.7131L17.4594 20.3542H6V3H19.2372Z"
          fill="#8C909A"
        />
      </svg>
    );
  }
}
