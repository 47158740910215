import { IIcon } from 'common/interfaces/interface';

const DEFAULT_COLORS = {
  ACTIVE: '#2455DD',
  INACTIVE: '#D1D2D3'
};

interface IIconImportance extends IIcon {
  type: string;
  colors?: {
    ACTIVE: string;
    INACTIVE: string;
  };
}

const IMPORTANCE_BY_TYPE = {
  'Very Low': 1,
  Low: 2,
  Medium: 3,
  High: 4,
  Highest: 5
};
const IconImportance = ({ type, colors = DEFAULT_COLORS }: IIconImportance) => {
  return (
    <svg width="58" height="25" viewBox="0 0 48 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 17.5L4.09973 15L1 17.5"
        stroke={colors.ACTIVE}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 14L4.09973 11.5L1 14"
        stroke={IMPORTANCE_BY_TYPE[type as keyof typeof IMPORTANCE_BY_TYPE] >= 2 ? colors.ACTIVE : colors.INACTIVE}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.5L4.09973 8L1 10.5"
        stroke={IMPORTANCE_BY_TYPE[type as keyof typeof IMPORTANCE_BY_TYPE] >= 3 ? colors.ACTIVE : colors.INACTIVE}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7L4.09973 4.5L1 7"
        stroke={IMPORTANCE_BY_TYPE[type as keyof typeof IMPORTANCE_BY_TYPE] >= 4 ? colors.ACTIVE : colors.INACTIVE}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 3.5L4.09973 1L1 3.5"
        stroke={IMPORTANCE_BY_TYPE[type as keyof typeof IMPORTANCE_BY_TYPE] === 5 ? colors.ACTIVE : colors.INACTIVE}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { IconImportance };
