import { Score } from 'common/components/score/Score';
import classNames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import './SubsidiaryRow.scss';

const useStyles = makeStyles({
  tooltip: {
    fontSize: 12,
    fontFamily: 'Rubik',
    fontWeight: 400,
    backgroundColor: '#1A1A1A'
  }
});

export function SubsidiaryRow(props: any) {
  const classes = useStyles();

  const handleOnClick = () => {
    if (!!props.score && props.onClick) {
      props.onClick();
    }
  };

  return (
    <div className={classNames('SubsidiaryRow', { 'with-cursor': !!props.score })} onClick={handleOnClick}>
      <div className={classNames('row-data', { 'no-events': !props.score })}>
        <Score label={props.label} />
        <div style={{ marginRight: 10, marginLeft: 15 }}>{props.score}</div>
        <div>{props.name}</div>
      </div>
      {!props.score && (
        <Tooltip
          title={
            'Inactive subsidiaries can be either pending a new scan cycle for initial information or they do not have enough assets to run an analysis'
          }
          placement="bottom-start"
          classes={{ tooltip: classes.tooltip }}
        >
          <div className="no-data">
            <img src="/assets/images/info.svg" className="info" alt="subsidiary with no data" />
          </div>
        </Tooltip>
      )}
    </div>
  );
}
