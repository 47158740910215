// import React, { useState, useMemo } from 'react';
import { Text, IconConfidence } from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import { useTopPanelContext } from '../TopPanelContext';
import { Common } from 'common/services/common.service';

import './toppanel-single-asset.scss';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';

const textProps = {
  textSize: 36,
  textColor: '#FFFFFF',
  family: 'Rajdhani',
  upper: true
};

const texts = { critical: 'Critical', high: 'High', medium: 'Medium', low: 'Low', no_risk: 'No Risk' };
const CONFIDENCE = { 10: 'Low', 20: 'Medium', 30: 'High' };

const TopPanelConnections = () => {
  const { data } = useTopPanelContext();
  const { connections_by_severity } = Array.isArray(data) ? data[0] : data;
  const total = Object.values(connections_by_severity).reduce((all: number, curr: any) => all + curr, 0);

  return (
    <Grid container alignItems="center">
      <Grid item sx={{ paddingLeft: 2.5 }}>
        <Text {...textProps} textSize={12} family="Rubik" upper>
          Connections
        </Text>
      </Grid>
      <Grid item sx={{ paddingLeft: 0.5 }}>
        <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
          {total}
        </Text>
      </Grid>
      <div className="vertical-devider-collapse"></div>
      <Grid item className="top-panel-title" sx={{ paddingRight: 1 }}>
        <Text {...textProps} textSize={12} family="Rubik" upper>
          SEVERITY
        </Text>
      </Grid>
      {Object.entries(connections_by_severity).map(([key, value]) => (
        <>
          <Grid item key={key} className="top-panel-indicator" sx={{ paddingRight: 0.5 }}>
            <Text {...textProps} textSize={12} family="Rubik" upper={false}>
              {texts[key as keyof typeof texts]}
            </Text>
          </Grid>
          <Grid item key={key} className="top-panel-indicator" sx={{ paddingRight: 1.5 }}>
            <Text {...textProps} textSize={14} family="Rubik" textColor={Common.getColorByUrgency(key)}>
              {value}
            </Text>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

const TopPanelEvidence = () => {
  const { data } = useTopPanelContext();
  const { overall_confidence, derived_from, source } = data;

  return (
    <Grid container alignItems="center">
      <Grid item sx={{ paddingLeft: 2.5 }}>
        <Text {...textProps} textSize={12} family="Rubik" upper>
          ASSET SOURCE
        </Text>
      </Grid>
      <Grid item sx={{ paddingLeft: 0.5 }}>
        <Text {...textProps} textSize={13} weight={500} family="Rubik">
          {source}
        </Text>
      </Grid>
      <div className="vertical-devider-collapse"></div>
      <Grid item sx={{ paddingLeft: 0 }}>
        <Text {...textProps} textSize={12} family="Rubik" upper>
          confidence level
        </Text>
      </Grid>
      <Grid item sx={{ paddingLeft: 0.5 }}>
        <Grid container alignItems="center">
          <Grid item>
            <IconConfidence level={overall_confidence.overall_confidence} darkBG />
          </Grid>
          <Grid item>
            <Text textColor="#FFFFFF" upper>
              {CONFIDENCE[overall_confidence.overall_confidence as keyof typeof CONFIDENCE]}
            </Text>
          </Grid>
        </Grid>
      </Grid>
      {!!derived_from && (
        <>
          <div className="vertical-devider-collapse"></div>
          <Grid item>
            <Text {...textProps} textSize={12} family="Rubik" upper>
              EVIDENCE DERIVED FROM
            </Text>
          </Grid>
          <Grid item sx={{ paddingLeft: 0.5 }}>
            <LinkNewTab
              to={`/pages/singleAsset/internalAssets/${derived_from}/actionItems`}
              text={<Text textColor="#FFFFFF">{derived_from}</Text>}
              className="ssa-header-text-link"
            />
          </Grid>
        </>
      )}
    </Grid>

    //   <div
    //   className={`${!derived_from ? 'ssa-header-scope-no-border' : 'ssa-header-scope'}`}
    //   style={{
    //     display: 'flex',
    //     alignItems: 'center',
    //     ...(!derived_from ? { flexGrow: 1, justifyContent: 'start' } : {})
    //   }}
    // >
    //   <div className="ssa-header-text" style={{ color: '#d1d2d3' }}>
    //     CONFIDENCE LEVEL
    //   </div>
    //   <div
    //     className={'ssa-header-text'}
    //     style={{
    //       display: 'flex',
    //       paddingLeft: 4
    //     }}
    //   >
    //     <IconConfidence level={confidence} darkBG />
    //     <Text textColor="#FFFFFF" style={{ paddingTop: 3, paddingLeft: 2 }}>
    //       {confidence}
    //     </Text>
    //   </div>
    // </div>

    //   {!!derived_from && (
    //     <div className="ssa-header-scope-no-border" style={{ flexGrow: 1, justifyContent: 'end' }}>
    //       <div className="ssa-header-text" style={{ color: '#d1d2d3' }}>
    //         EVIDENCE DERIVED FROM
    //       </div>

    //       <Link
    //         target="_blank"
    //         className="ssa-header-text-link"
    //         to={`/pages/singleAsset/internalAssets/${derived_from}/actionItems`}
    //       >
    //         <Text textColor="#FFFFFF">{derived_from}</Text>
    //       </Link>
    //     </div>
    //   )}
    // </Grid>
  );
};

const TopPanelSingleAsset = () => {
  const { data, tab } = useTopPanelContext();

  if (!data || !Object.keys(data).length) return null;
  return tab === 'connections' ? <TopPanelConnections /> : <TopPanelEvidence />;
};

export default TopPanelSingleAsset;
