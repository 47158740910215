export function IconCluster({ active }: { active: boolean }) {
  let color = active ? '#ffffff' : '#5D6576';

  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="10.9487" y="1.04883" width="4" height="4" rx="1" transform="rotate(90 10.9487 1.04883)" stroke={color} />
      <rect x="5.05127" y="1.04883" width="4" height="4" rx="1" transform="rotate(90 5.05127 1.04883)" stroke={color} />
      <rect x="5.05127" y="6.94727" width="4" height="4" rx="1" transform="rotate(90 5.05127 6.94727)" stroke={color} />
      <rect x="10.9487" y="6.94727" width="4" height="4" rx="1" transform="rotate(90 10.9487 6.94727)" stroke={color} />
    </svg>
  );
}
