import { GridRowData } from '@mui/x-data-grid-pro';
import { IReduxState } from 'app/store';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { RestApi } from 'common/services/rest-api.service';
import { getRows } from 'features/GridSlice';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSuccess, showError } from 'features/AlertSlice';

import './DeletePromptPopup.scss';

export function DeletePromptPopup(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const { deletePromptParams, rows } = useSelector((state: IReduxState) => state.grid);

  const onApprove = () => {
    setLoading(true);
    const url = !!props.path
      ? props.path
      : `settings/${deletePromptParams.category}/${deletePromptParams.tab ? `${deletePromptParams.tab}/` : ''}`;
    RestApi.setData(
      url,
      {
        ids: deletePromptParams.ids
      },
      'DELETE'
    ).subscribe(
      (response: any) => {
        dispatch(showSuccess('Deleted Successfully'));
        dispatch(getRows());

        setLoading(false);
        props.onClose();
      },
      error => {
        dispatch(showError(error));
        setLoading(false);
        props.onClose();
      }
    );
  };

  const getListHTML = (): JSX.Element[] => {
    const _html: JSX.Element[] = [];
    const listMaxItems = 3;
    if (typeof deletePromptParams.ids[0] === 'string') {
      deletePromptParams.ids.forEach((id: string, index: number) => {
        if (index === listMaxItems) {
          _html.push(<li key={id}>And {deletePromptParams.ids.length - listMaxItems} more...</li>);
        } else if (index < listMaxItems) {
          _html.push(<li key={id}>{id}</li>);
        }
      });
    } else {
      const filteredRows = rows.filter((row: GridRowData) => deletePromptParams.ids.includes(row.id));
      filteredRows.forEach((row: GridRowData, index: number) => {
        if (index === listMaxItems) {
          _html.push(<li key={row.name}>And {filteredRows.length - listMaxItems} more...</li>);
        } else if (index < listMaxItems) {
          _html.push(<li key={row.name}>{row.name}</li>);
        }
      });
    }
    return _html;
  };

  return (
    <div className="DeletePromptPopup popup-inner-wrapper">
      {loading && <AppLoading />}
      <div className="popup-inner">
        <h2>Are you sure?</h2>
        <h3>{deletePromptParams.message}</h3>
        <ul>{getListHTML()}</ul>
        <div className="buttons-wrapper">
          <button className="btn btn-big btn-secondary" onClick={props.onClose}>
            Cancel
          </button>
          <button className={`btn btn-big btn-main`} onClick={onApprove}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
