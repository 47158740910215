import React, { useState, useEffect } from 'react';
import { Button } from 'common/components/Button/Button';
import { Text } from '@cyberpion/cyberpion-ui';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@mui/material/Fade';

import './DeletePopSide.scss';

const useStyles = makeStyles({
  checkboxRoot: {
    padding: '0px !important',
    width: 'auto !important',
    '& .MuiSvgIcon-root': {
      width: '18px !important',
      height: '18px !important'
    }
  }
});

interface IDeletePopSide {
  onDelete?: () => void;
  onCancel?: () => void;
  deleteMsgWithNotificationWarning?: boolean;
  selectedDeleteIndex?: number;
}

const DeletePopSide: React.FC<IDeletePopSide> = ({ onCancel, onDelete, deleteMsgWithNotificationWarning }) => {
  const [checkValue, setCheckValue] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    setIsDeleteDisabled(!checkValue);
  }, [checkValue]);

  const handleCheckboxOnClick = () => {
    setCheckValue(checked => !checked);
  };

  return (
    <Fade in timeout={800}>
      <div className="DeletePopSide" onClick={(ev: any) => ev.stopPropagation()}>
        <Text textSize={16} weight={500} style={{ marginBottom: 16 }}>
          {'Delete Integration?'}
        </Text>
        <div style={{ flexGrow: 1 }}>
          {deleteMsgWithNotificationWarning && (
            <>
              <Text style={{ lineHeight: 1.35 }}>
                WARNING! Deleting the integration will remove all associated notifications. This action cannot be
                undone.
              </Text>

              <Text style={{ lineHeight: 1.35 }}>
                Are you sure you want to remove the integration and all associated notifications?
              </Text>
            </>
          )}
          {!deleteMsgWithNotificationWarning && (
            <Text style={{ lineHeight: 1.35 }}>Are you sure you want to remove the integration?</Text>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="delete-checkbox" onClick={handleCheckboxOnClick}>
            <Checkbox
              checked={checkValue}
              disableRipple
              classes={{ root: classes.checkboxRoot }}
              sx={{
                marginRight: 0.75,
                '&.Mui-checked': {
                  color: '#f46666'
                }
              }}
            />
            <Text textSize={13} family="Rubik">
              Confirm delete
            </Text>
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <Button buttonStyle="secondary" size="small" text="Cancel" onClick={onCancel} type="button" />
          <Button
            buttonStyle="alert"
            size="small"
            text="Delete"
            onClick={onDelete}
            type="button"
            disabled={isDeleteDisabled}
          />
        </div>
      </div>
    </Fade>
  );
};

export default DeletePopSide;
