import { useRef, useState } from 'react';
import './SavedViewOptionsMenu.scss';
import { useDispatch } from 'react-redux';
import {
  deleteUserView,
  fetchDefaultViews,
  fetchUserCreatedViews,
  setDefaultView,
  removeDefaultView
} from 'features/ViewsSlice';

import classNames from 'classnames';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { setEditMode } from 'features/ViewsSlice';
import { useClickOutside } from 'hooks/useClickOutside';

export function SavedViewOptionsMenu(props: any) {
  const [loading, setLoading] = useState<string | null>(null);
  const dispatch = useDispatch<any>();
  const ref: any = useRef(null);
  // const { defaultViews } = useSelector((state: any) => state.views);

  useClickOutside(ref, props.onClose);

  return (
    <div ref={ref} className="SavedViewOptionsMenu">
      {loading && <AppLoading text={loading} />}
      <ul>
        <li
          className={classNames('default')}
          onClick={async () => {
            if (props.isDefault) {
              setLoading('Removing default...');
              await dispatch(removeDefaultView(props.view.id));
            } else {
              setLoading('Setting as default...');
              await dispatch(setDefaultView({ ...props.view, type: props.type === 'user' ? 1 : 0 }));
            }

            await dispatch(fetchDefaultViews());
            props.onClose();
            setLoading(null);
          }}
        >
          <img src={`/assets/images/star${props.isDefault ? '-yellow' : ''}.svg`} alt="Default" />
          {props.isDefault ? 'Remove' : 'Make'} Default
        </li>
        <li
          className={classNames('edit', { 'grayed-out': props.type !== 'user' })}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setEditMode(props.view.id));
            props.onClose();
          }}
        >
          <img src="/assets/images/edit-gray.svg" alt="Edit" />
          Edit
        </li>
        <li
          className={classNames('delete', { 'grayed-out': props.type !== 'user' })}
          onClick={async () => {
            setLoading('Deleting View...');
            await dispatch(deleteUserView(props.view.id));
            await dispatch(fetchUserCreatedViews());
            props.onClose();
            setLoading(null);
          }}
        >
          <img src="/assets/images/trash.svg" alt="Edit" />
          Delete
        </li>
      </ul>
    </div>
  );
}
