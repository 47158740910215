// @ts-nocheck
import React, { useRef } from 'react';
import { InfoPanel, DataPanel, Indicator } from '@cyberpion/cyberpion-ui';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';
import usePageSize from '../hooks/usePageSize';
import classNames from 'classnames';
import PageFooter from '../PageFooter';
import PageHeader from '../PageHeader';

import './orgScore.scss';

const imageLegend =
  'https://res.cloudinary.com/cyberpion/image/upload/v1678785860/executive_report/legend_white_mezgys.svg';

const texts = {
  cloud_score: 'cloud',
  network_score: 'network',
  dns_score: 'dns',
  pki_score: 'pki',
  web_score: 'web',
  email_score: 'email',
  vulnerable_components_score: 'vulnerable components',
  hijacked_assets_score: 'hijacked assets',
  unknown_unmanaged_score: 'potentially unmanaged assets',
  connections_score: 'external connections',
  login_assets_score: 'login assets',
  tls_score: 'tls'
};
const OrgScore = () => {
  const refPage = useRef(null);
  const { minHeight } = usePageSize(refPage, 'org_score');
  const { orgScoreData } = useThreatsReport() as ThreatsReportContextType;

  return (
    <div ref={refPage} className="ExposureReportOrgScore" style={{ minHeight: minHeight }}>
      <div style={{ display: 'flex', flexDirection: 'column', padding: 32 }}>
        <PageHeader />
      </div>
      <div className="overall_score">
        <div>
          <Indicator letter={orgScoreData.overall_score.label} size={'b'} />
        </div>
        <div style={{ flexGrow: 1, paddingLeft: 40 }}>
          <DataPanel
            titleUpper
            titleWeight={600}
            textSize={40}
            weight={600}
            titleTextSize={13}
            title="OVERALL SCORE"
            family="Rajdhani"
          >
            {orgScoreData.overall_score.score}
          </DataPanel>
        </div>
        <div style={{ marginLeft: 24 }}>
          <img src={imageLegend} alt="Org Score legend" height="95px"></img>
        </div>
      </div>
      <div className="all-scores">
        {[
          'cloud_score',
          'network_score',
          'dns_score',
          'pki_score',
          'web_score',
          'email_score',
          'vulnerable_components_score',
          'hijacked_assets_score',
          'unknown_unmanaged_score',
          'connections_score',
          'login_assets_score',
          'tls_score'
        ].map((item: any, index) => (
          <div
            key={item}
            className={classNames(
              'org-score-item',
              { 'vertical-border': index % 2 !== 1 },
              { 'top-border': index > 1 },
              { padding: index % 2 === 1 }
            )}
          >
            <Indicator letter={orgScoreData[item].label} />
            <div style={{ paddingLeft: 24 }}>
              <DataPanel
                titleUpper
                titleTextSize={12}
                titleWeight={600}
                upper
                textSize={36}
                title={texts[item]}
                family="Rajdhani"
              >
                {orgScoreData[item].score}
              </DataPanel>
            </div>
          </div>
          // {index % 2 === 1 && Object.keys(all).length !== index + 1 && (
          //   <Grid item sm={12} md={12}>
          //     <Divider />
          //   </Grid>
          // )}
        ))}
      </div>
      <div style={{ marginLeft: 110, marginBottom: 35, marginTop: 40 }}>
        <InfoPanel
          width={680}
          bgColor="linear-gradient(180deg, #D63E3E 0%, #B41111 100%)"
          borderRadius="10px"
          style={{
            padding: '20px 20px 20px 25px'
          }}
        >
          Attack Surface Risk present the detailed assessment of the organizational Attack Surface Discovery. Risk
          analysis is performed on organizational assets, digital supply chain, partners, and service providers, and
          takes the scale of the organization, the asset importance and the security issue into account, when defining
          the Risk score. The above graph presents the organizational Risk score against the organizational proposed
          Risk score goal, to verify progress in reducing the risk, while the organization environment is changing and
          evolving.
        </InfoPanel>
      </div>
      <PageFooter id="org_score" />
    </div>
  );
};

export default OrgScore;
