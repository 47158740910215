import { Text } from '@cyberpion/cyberpion-ui';
import './IntegrationEmptyState.scss';

const IntegrationEmptyState = ({ text }: { text: string }) => {
  return (
    <div className="Jira-empty-state-wrapper">
      <img src="/assets/images/empty-state-panel.svg" className="info" alt="integration empty state" />
      <Text textColor="#8C909A" style={{ width: 200, textAlign: 'center', marginTop: 15, lineHeight: 1.5 }}>
        {text}
      </Text>
    </div>
  );
};

export default IntegrationEmptyState;
