import React, { useState, useEffect } from 'react';
import NotificationItem from './NotificationItem';
import {
  useNotifications,
  NotificationsContextType,
  SORT_TYPE,
  NOTIFICATION_OPTIONS
} from 'pages/Settings/Notifications/NotificationsContext';
import Grid from '@mui/material/Grid';
import { Common } from 'common/services/common.service';
import { Text } from '@cyberpion/cyberpion-ui';

const NotificationsList = () => {
  const [groupedNotifications, setGroupedNotifications] = useState<any>(null);
  const { filteredNotifications, sortBy } = useNotifications() as NotificationsContextType;

  const groupByNotofocationsType = (list: any) => {
    return list.reduce((all: any, curr: any) => {
      // const type = getCategoryFromType(curr.type.type);
      return {
        ...all,
        [curr.notification_type]: !!all[curr.notification_type] ? [...all[curr.notification_type], curr] : [curr]
      };
    }, {});
  };

  useEffect(() => {
    const sortObject = (obj: any) => {
      const dir = sortBy.order === 'asc' ? 1 : -1;
      return !!obj
        ? Object.keys(obj)
            .sort((a, b) => (a > b ? dir * 1 : dir * -1))
            .reduce(function(result: any, key) {
              result[key as keyof typeof result] = obj[key];
              return result;
            }, {})
        : {};
    };

    if (sortBy.by === SORT_TYPE.TYPE && !!sortBy.order && !!filteredNotifications?.length) {
      const groupedTypes = groupByNotofocationsType(filteredNotifications);
      const updated = sortObject(groupedTypes);
      setGroupedNotifications(updated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, filteredNotifications]);

  return (
    <>
      {(sortBy.by === SORT_TYPE.AZ || sortBy.by === SORT_TYPE.DATE) &&
        filteredNotifications.map((notification: any) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      {sortBy.by === SORT_TYPE.TYPE &&
        Common.isObject(groupedNotifications) &&
        Object.keys(groupedNotifications).map((type: string) => (
          <Grid container key={type} direction="column">
            <Grid item>
              <Grid container alignItems="center" className="notification-group">
                <Grid item sx={{ marginTop: 0.25 }}>
                  {NOTIFICATION_OPTIONS[type as keyof typeof NOTIFICATION_OPTIONS]?.icon}
                </Grid>
                <Grid item sx={{ paddingLeft: 1.5 }}>
                  <Text textSize={14} weight={600}>
                    {`${NOTIFICATION_OPTIONS[type as keyof typeof NOTIFICATION_OPTIONS]?.title} (${
                      groupedNotifications[type]?.length
                    })`}
                  </Text>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="column">
                {groupedNotifications[type].map((notification: any) => (
                  <Grid item key={notification.id}>
                    <NotificationItem notification={notification} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ))}
    </>
  );
};

export default NotificationsList;
