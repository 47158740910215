import { Snackbar } from '@mui/material/';
import './Alert.scss';
import React, { useEffect } from 'react';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlert, resetDelay, resetTitle } from '../../../features/AlertSlice';
import { Common } from '../../services/common.service';

export function Alert() {
  const dispatch: AppDispatch = useDispatch();
  const { variant, title, text, visible, delay } = useSelector((state: IReduxState) => state.alert);

  const handleClose = () => {
    dispatch(hideAlert());
    dispatch(resetDelay());
    dispatch(resetTitle());
  };

  useEffect(() => {
    if (visible) {
      Common.displayHelp(false);
    }
    //#TEMP TO SOLVE 'HELP' SHOWN AGAIN WHEN NOTIFICATION IS ACTIVE
    //  else {
    //   // Common.displayHelp(true);
    // }
  }, [visible]);

  return (
    <Snackbar
      open={visible}
      autoHideDuration={delay}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      onClose={handleClose}
    >
      <div className="Alert">
        <div>
          <img src={`/assets/images/${variant}.svg`} className="icon" alt="Icon" />
        </div>
        <div>
          <h6>{title || variant}</h6>
          <p>{text}</p>
        </div>
        <img src="/assets/images/x.svg" className="close" onClick={handleClose} alt="Close" />
      </div>
    </Snackbar>
  );
}
