import React from 'react';
import { IIcon } from 'common/interfaces/interface';
import './IconCheckbox.scss';

export class Checkbox extends React.Component<any> {
  public props: IIcon;

  getState() {
    if (this.props.active) {
      return <img src="/assets/images/check.svg" alt="checkbox" />;
    } else if (this.props.semiActive) {
      return '-';
    }
  }

  render() {
    return (
      <span className="Checkbox" data-testid="checkbox">
        {this.getState()}
      </span>
    );
  }
}
