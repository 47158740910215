import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import { IconRadioButton } from 'common/components/Icons/IconRadioButton/IconRadioButton';
import { Common } from 'common/services/common.service';
import React from 'react';
import { Button } from '@mui/material';
import './filterDate.scss';
import { IItemFilterDate, IChildFilterDate, IFilters } from 'common/components/grid/components/gridUtilities/interface';
import { useRef, useEffect, useState } from 'react';
import { DropdownHeader } from '../dropdownHeader/DropdownHeader';
import { FilterDateManage } from '../services/FilterDateManage';
import { AppDispatch } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { addFields, addFilter, getRows } from 'features/GridSlice';
import { IChoices } from 'common/interfaces/interface';
import { getFiltersServerFormat } from 'utils/filters';
import { selectView, setIsEdited } from 'features/ViewsSlice';

interface IFilterDate {
  item: IFilters;
  activeFilters: any;
  onUpdateDataFilter: (item: IFilters) => void;
  // onUpdatedFilterGrid: (params: IItemFilterDate) => void;
}

export function FilterDate(props: IFilterDate) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpenDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [item, setItem] = useState<IFilters>(props.item);
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const { filters } = useSelector((state: any) => state.grid);

  //@param: event
  //@description: Hides the component
  const handleClickOutside = (event: Event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      !isOpenDatePicker
      // !FiltersManage.contains(event.target)
    ) {
      onClose();
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (props.item) {
      const copy = { ...props.item };
      if (filters[copy.name] && filters[copy.name].type === '__index') {
        copy.sub_label = `: ${props.item.choices[filters[copy.name].value].label}`;
        copy.choices[props.item.choices[filters[copy.name].value].id].is_toggle = true;
        copy.is_active_filter = true;
      } else if (filters[copy.name]) {
        copy.choices[copy.choices.length - 1].is_toggle = true;
        const dates = filters[copy.name].value.split(',');
        const date1 = new Date(dates[0]);
        const date2 = new Date(dates[1]);
        const date1String = `${date1.getMonth() + 1}/${date1.getDate()}/${date1.getFullYear()}`;
        const date2String = `${date2.getMonth() + 1}/${date2.getDate()}/${date2.getFullYear()}`;
        copy.choices[copy.choices.length - 1].child[0].value = date1String;
        copy.choices[copy.choices.length - 1].child[1].value = date2String;
        copy.sub_label = `: Range "${FilterDateManage.checkIsBetween(filters[copy.name].value || '')}"`;
        copy.is_active_filter = true;
      } else if (props.activeFilters[copy.name].is_active_filter) {
        copy.is_active_filter = false;
        copy.is_toggled = false;
        copy.choices.forEach((choice: IChoices) => {
          choice.is_toggled = false;
        });
        copy.sub_label = '';
        props.onUpdateDataFilter(copy);
        //
      }
      setItem(copy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  //@param: newDate = Date entered by the user
  //@param: index = Position of an item in an array
  //@param: numDays = Position a child in an array
  //@description: Updates the date of that item in the data
  function setValueDatePicker(newDate: Date | undefined | null, index: number, childIndex: number = 0) {
    if (Common.isValidDate(newDate)) {
      let copy = onSelected(true, index);
      if (index !== 5 && newDate) {
        copy.choices[index].value = newDate;
        copy.choices[index].error = false;
      } else {
        copy.choices[index].child[childIndex].value = newDate;
        copy.choices[index].child[childIndex].error = false;
      }
      setItem(copy);
    }
  }

  //@param: value = The boolean value I get from the "IconRadioButton" component
  //@param: index = Position of an item in an array
  //@description : Updates the data selection
  function onSelected(value: boolean, index: number) {
    let copy = { ...item };
    copy.choices.forEach((option: IItemFilterDate, i: number) => {
      if (index !== i) option.is_toggle = false;
      else option.is_toggle = value;
    });
    return copy;
  }

  function onChangeSelected(isToggle: boolean, index: number) {
    // return
    let copy = onSelected(isToggle, index);
    // let chosen = copy.choices[index]
    // let date = FilterDateManage.getFormatDateByLabel(chosen);
    // // let key = `${item.name}${chosen.name}`;
    // const filter = {
    //   [item.name]: {
    //     type: chosen.name,
    //     value: date
    //   }
    // }
    // let values = date
    // // filter = `${item.name}${data_dropdown.name}=${date}`;
    // if (chosen.label.startsWith('Last')) {
    //   // Last filters needs to send range (CP-1126)
    //   const now = new Date().toJSON()
    //   filter[item.name].value = `${date},${now}`
    //   // key = `${item.name}__range`
    //   // values = `${date},${now}`
    //   // filter = `${item.name}__range=${date},${now}`;
    // }
    // // queryGrid.updateMoreQueryChain(item.name, filter);
    // item.sub_label = `: ${chosen.label} "${FilterDateManage.checkIsBetween(date || "")}"`;
    setItem(copy);
  }

  //@description Closes the drop-down menu
  function onClose() {
    let copy = { ...item };
    copy.toggle_dropdown = false;
    copy.choices.forEach((option: IItemFilterDate, i: number) => {
      option.is_toggle = false;
      option.error = false;
    });
    setItem(copy);
    setIsToggled(false);
    // props.onUpdateDataFilter(copy);
  }

  //@description : Check if there is a selection and send a filter request
  function onApply() {
    let option: any;
    let copy = { ...item };
    for (const key in copy.choices) {
      if (copy.choices[key].is_toggle) {
        option = copy.choices[key];
        break;
      }
    }

    if (option) {
      if (!validationInputDate(option, copy)) {
        copy.toggle_dropdown = false;
        let date = FilterDateManage.getFormatDateByLabel(option);
        // const filter = {
        //   [item.name]: {
        //     type: option.name,
        //     value: date
        //   }
        // };
        // if (option.label.startsWith('Last')) {
        //   filter[item.name].type = '__index';
        //   filter[item.name].value = option.id;
        // }
        const filter = getFiltersServerFormat('date', copy, date, option);
        // queryGrid.updateMoreQueryChain(item.name, filter);
        copy.sub_label = `: ${option.label} "${FilterDateManage.checkIsBetween(date || '')}"`;
        copy.is_active_filter = true;
        setItem(copy);
        setIsToggled(false);
        dispatch(addFilter(filter));
        dispatch(addFields([item.name]));
        dispatch(getRows());
        dispatch(selectView(null));
        dispatch(setIsEdited(true));
        props.onUpdateDataFilter(copy);
        // props.onUpdatedFilterGrid(option);
      }
    }
  }

  //@param: option = Selected item
  //@param: options = All data
  //@description : Validation on the same selected item
  function validationInputDate(option: IItemFilterDate, item: any) {
    if (option.id !== 5 && !Common.isValidDate(option.value) && option.show_data_picker) {
      item.choices[option.id].error = !option.value;
      setItem(item);
      return item.choices[option.id].error;
    }
    if (option.id === 5 && (!Common.isValidDate(option.child[1].value) || !Common.isValidDate(option.child[0].value))) {
      item.choices[option.id].child[0].error = true;
      item.choices[option.id].child[1].error = true;
      setItem(item);
      return true;
    }

    if (
      option.id === 5 &&
      option.child[1].value &&
      Common.isValidDate(option.child[1].value) &&
      option.child[0].value &&
      Common.isValidDate(option.child[0].value)
    ) {
      if (option.child[0].value > option.child[1].value) {
        item.choices[option.id].child[0].error = true;
        item.choices[option.id].child[1].error = true;
        setItem(item);
        return true;
      }
    }
    return false;
  }

  function showDatePicker(option: any, index: number, childIndex: number | undefined = undefined) {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          onOpen={() => {
            setOpenDatePicker(true);
          }}
          onClose={() => {
            setOpenDatePicker(false);
          }}
          value={option.value}
          onChange={newValue => {
            setValueDatePicker(newValue, index, childIndex);
          }}
          renderInput={params => <TextField {...params} error={option.error} />}
        />
      </LocalizationProvider>
    );
  }

  function showIconRadioButton(option: IItemFilterDate, index: number) {
    return (
      <div className="filterDate_RadioButton">
        <IconRadioButton
          radioSize="little"
          toggle={option.is_toggle}
          onClick={e => {
            onChangeSelected(e, index);
          }}
        />
      </div>
    );
  }

  return (
    <>
      <DropdownHeader
        item={item}
        onToggle={() => setIsToggled(!isToggled)}
        onChangeData={props.onUpdateDataFilter}
        isToggled={isToggled}
        // onUpdateFilterGrid={props.onUpdateFilterGrid}
      />
      {isToggled && (
        <div ref={ref} className="filterDate_scope">
          {item.choices.map((option: IItemFilterDate, index: number) => (
            <React.Fragment key={index}>
              {option.child.length === 0 && (
                <div className="filterDate-item-scope">
                  {showIconRadioButton(option, index)}
                  <div className="filterDate-label">{option.label}</div>
                  {option.show_data_picker && (
                    <div className="filterDate_datePicker-scope">{showDatePicker(option, index)}</div>
                  )}
                </div>
              )}
              {option.child.length !== 0 && (
                <div className="filterDate-item-scope">
                  {showIconRadioButton(option, index)}
                  {option.child.map((c: IChildFilterDate, childKey: number) => (
                    <div className="filterDate_range-scope" key={childKey}>
                      <div className="filterDate-label">{c.label}</div>
                      {c.show_data_picker && showDatePicker(c, index, childKey)}
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
          <div className="filterDate_button-scope">
            <Button>
              <div className="filterDate_button" onClick={onClose}>
                Cancel
              </div>
            </Button>
            <Button>
              <div className="filterDate_button filterDate_button-apply" onClick={onApply}>
                Apply
              </div>
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
