// @ts-nocheck
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addHeaders } from 'common/services/rest-api.service';
import { env } from 'env';

export const fetchDefaultViews = createAsyncThunk('views/fetchDefaultViews', async () => {
  const path = `${env.REACT_APP_BASE_URL}configuration/default-views/summary/`;
  const res = await fetch(path, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => data.json());
  return res;
});

export const fetchGenericViews = createAsyncThunk('views/fetchGenericViews', async () => {
  const path = `${env.REACT_APP_BASE_URL}configuration/generic-views/summary/`;
  const res = await fetch(path, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => data.json());
  return res;
});

export const fetchUserCreatedViews = createAsyncThunk('views/fetchUserCreatedViews', async () => {
  const path = `${env.REACT_APP_BASE_URL}configuration/user-views/summary/`;
  const res = await fetch(path, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => data.json());
  return res;
});

export const addUserCustomView = createAsyncThunk('views/addUserCustomView', async (data: any) => {
  const path = `${env.REACT_APP_BASE_URL}configuration/user-views/`;
  const res = await fetch(path, {
    method: 'POST',
    mode: 'cors',
    headers: addHeaders('POST'),
    credentials: 'include',
    body: JSON.stringify(data)
  }).then(data => data.json());
  return res;
});

export const setDefaultView = createAsyncThunk('views/setDefaultView', async (view: any) => {
  const path = `${env.REACT_APP_BASE_URL}configuration/default-views/`;
  const res = await fetch(path, {
    method: 'POST',
    mode: 'cors',
    headers: addHeaders('POST'),
    credentials: 'include',
    body: JSON.stringify({
      view_id: view.id,
      source: view.type
    })
  }).then(data => data.json());
  return res;
});

export const updateUserView = createAsyncThunk('views/updateUserView', async (data: any) => {
  const path = `${env.REACT_APP_BASE_URL}configuration/user-views/${data.id}/`;
  const res = await fetch(path, {
    method: 'PATCH',
    mode: 'cors',
    headers: addHeaders('PATCH'),
    credentials: 'include',
    body: JSON.stringify({
      label: data.label
    })
  }).then(data => data.json());
  return res;
});

export const deleteUserView = createAsyncThunk('views/deleteUserView', async (id: string) => {
  const path = `${env.REACT_APP_BASE_URL}configuration/user-views/${id}/`;
  const res = await fetch(path, {
    method: 'DELETE',
    mode: 'cors',
    headers: addHeaders('DELETE'),
    credentials: 'include'
  }).then(data => data.json());
  return res;
});

export const removeDefaultView = createAsyncThunk('views/removeDefaultView', async (id: string) => {
  const path = `${env.REACT_APP_BASE_URL}configuration/default-views/${id}/`;
  const res = await fetch(path, {
    method: 'DELETE',
    mode: 'cors',
    headers: addHeaders('DELETE'),
    credentials: 'include'
  }).then(data => data.json());
  return res;
});

const viewsSlice = createSlice({
  name: 'views',
  initialState: {
    defaultViews: {},
    genericViews: {},
    userCreatedViews: {},
    viewSelected: null,
    isShowSavedViews: false,
    isEdited: false,
    editModeId: null,
    entryPoint: null
  },
  reducers: {
    setIsOpenSaveViews(state, action) {
      state.isShowSavedViews = action.payload;
    },
    setIsEdited(state, action) {
      state.isEdited = action.payload;
    },
    setEditMode(state, action) {
      state.editModeId = action.payload;
    },
    selectView(state, action) {
      state.viewSelected = action.payload;
    },
    toggleSavedViews(state) {
      state.isShowSavedViews = !state.isShowSavedViews;
    },
    updateLabel(state, action) {
      const copy = JSON.parse(JSON.stringify(state.userCreatedViews));
      for (let section in copy) {
        for (let tab in copy[section]) {
          copy[section][tab].views.forEach((view, index) => {
            if (view.id === action.payload.id) {
              copy[section][tab].views[index].label = action.payload.label;
            }
          });
        }
      }
      state.userCreatedViews = copy;
    },
    setEntryPoint(state, action) {
      state.entryPoint = action.payload;
    }
    // setUser(state, action) {
    //   state.user.first_name = action.payload.first_name;
    //   state.user.last_name = action.payload.last_name;
    // }
  },
  extraReducers: {
    [fetchDefaultViews.fulfilled]: (state, { payload }) => {
      state.defaultViews = payload;
    },
    [fetchGenericViews.fulfilled]: (state, { payload }) => {
      state.genericViews = payload;
    },
    [fetchUserCreatedViews.fulfilled]: (state, { payload }) => {
      state.userCreatedViews = payload;
    },
    [addUserCustomView.fulfilled]: (state, { payload }) => {
      state.viewSelected = payload.id;
      state.isEdited = false;
    }
  }
});

const { actions, reducer } = viewsSlice;
export const { selectView, setIsOpenSaveViews, setIsEdited, toggleSavedViews, setEditMode, updateLabel } = actions;
export default reducer;
