import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconOther extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    let color = this.props.active ? '#8C909A' : '#5D6576';
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 12.9375C12.5178 12.9375 12.9375 12.5178 12.9375 12C12.9375 11.4822 12.5178 11.0625 12 11.0625C11.4822 11.0625 11.0625 11.4822 11.0625 12C11.0625 12.5178 11.4822 12.9375 12 12.9375Z"
          fill={color}
        />
        <path
          d="M12 7.625C12.5178 7.625 12.9375 7.20527 12.9375 6.6875C12.9375 6.16973 12.5178 5.75 12 5.75C11.4822 5.75 11.0625 6.16973 11.0625 6.6875C11.0625 7.20527 11.4822 7.625 12 7.625Z"
          fill={color}
        />
        <path
          d="M12 18.25C12.5178 18.25 12.9375 17.8303 12.9375 17.3125C12.9375 16.7947 12.5178 16.375 12 16.375C11.4822 16.375 11.0625 16.7947 11.0625 17.3125C11.0625 17.8303 11.4822 18.25 12 18.25Z"
          fill={color}
        />
        <path
          d="M6.6875 12.9375C7.20527 12.9375 7.625 12.5178 7.625 12C7.625 11.4822 7.20527 11.0625 6.6875 11.0625C6.16973 11.0625 5.75 11.4822 5.75 12C5.75 12.5178 6.16973 12.9375 6.6875 12.9375Z"
          fill={color}
        />
        <path
          d="M6.6875 7.625C7.20527 7.625 7.625 7.20527 7.625 6.6875C7.625 6.16973 7.20527 5.75 6.6875 5.75C6.16973 5.75 5.75 6.16973 5.75 6.6875C5.75 7.20527 6.16973 7.625 6.6875 7.625Z"
          fill={color}
        />
        <path
          d="M6.6875 18.25C7.20527 18.25 7.625 17.8303 7.625 17.3125C7.625 16.7947 7.20527 16.375 6.6875 16.375C6.16973 16.375 5.75 16.7947 5.75 17.3125C5.75 17.8303 6.16973 18.25 6.6875 18.25Z"
          fill={color}
        />
        <path
          d="M17.3125 12.9375C17.8303 12.9375 18.25 12.5178 18.25 12C18.25 11.4822 17.8303 11.0625 17.3125 11.0625C16.7947 11.0625 16.375 11.4822 16.375 12C16.375 12.5178 16.7947 12.9375 17.3125 12.9375Z"
          fill={color}
        />
        <path
          d="M17.3125 7.625C17.8303 7.625 18.25 7.20527 18.25 6.6875C18.25 6.16973 17.8303 5.75 17.3125 5.75C16.7947 5.75 16.375 6.16973 16.375 6.6875C16.375 7.20527 16.7947 7.625 17.3125 7.625Z"
          fill={color}
        />
        <path
          d="M17.3125 18.25C17.8303 18.25 18.25 17.8303 18.25 17.3125C18.25 16.7947 17.8303 16.375 17.3125 16.375C16.7947 16.375 16.375 16.7947 16.375 17.3125C16.375 17.8303 16.7947 18.25 17.3125 18.25Z"
          fill={color}
        />
      </svg>
    );
  }
}
