import React, { useEffect, useState } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import ActionItemsPDFHeader from 'common/components/GenerateActionItemsPDF/components/ActionItemsPDFHeader/ActionItemsPDFHeader';
import ActionItemsTable from 'common/components/GenerateActionItemsPDF/components/ActionItemsTable/ActionItemsTable';
import ActionItemContainer from 'common/components/GenerateActionItemsPDF/components/ActionItemPDF/ActionItemContainer';
import { Text } from '@cyberpion/cyberpion-ui';
import { useLocation } from 'react-router-dom';
import { Common } from '../../services/common.service';
import queryString from 'query-string';

import './generateActionItemsPDF.scss';

const GenerateActionItemsPDF = () => {
  const [runTimer, setRunTimer] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean | null>(null);
  const [actionItemsData, setActionItemsData] = useState<any | null>(null);
  const location = useLocation();
  const params = queryString.parse(location.search);

  //IN ORDER TO RUN ONLY ONCE
  useEffect(() => {
    if (runTimer) {
      const timer = setTimeout(() => {
        // const helpIframe = document.getElementById('launcher');
        // if (!!helpIframe) {
        //   helpIframe.style.visibility = 'hidden';
        // }
        // const body = document.body;
        Common.displayHelp(false);

        window.print();
        window.parent.postMessage('ActionItemsPDFPrintReady', '*');
        setRunTimer(false);
      }, 3000);
      return () => {
        clearTimeout(timer);
        // const body = document.body;
        Common.displayHelp(true);
      };
    }
  }, [runTimer]);

  //THE ELEMENTS STILL NOT PAINTED TO THE DOM
  useEffect(() => {
    if (actionItemsData && !!actionItemsData.length) {
      setRunTimer(true);
    }
  }, [actionItemsData]);

  useEffect(() => {
    if (location?.search !== '' && actionItemsData === null) {
      const selected: any = params['selectedModel'] || '';
      const url = location.search.replace('?fetchUrl=', '').replace(`&selectedModel=${selected}`, '');

      setIsOpen(url.includes('/open') ? true : false);
      if (url !== '') {
        RestApi.getData(`${url}&limit=1000`).subscribe((response: any) => {
          if (!!selected) {
            const results = response.results.filter((item: any) => selected.split(',').includes(item.id));
            setActionItemsData(results);
          } else {
            setActionItemsData(response.results);
          }
        });
      }
    }
  }, [location?.search, actionItemsData, params]);

  return (
    <div style={{ display: 'flex' }} className="action-items-pdf-wrapper">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 816,
          backgroundColor: '#FFFFFF'
        }}
      >
        <ActionItemsPDFHeader prefix={isOpen ? 'Open' : 'Close'} />
        <div style={{ padding: '30px 35px' }}>
          <ActionItemsTable actionItems={actionItemsData} />
          <div className="divider"></div>
          <Text upper textSize={13} weight={600}>
            action items details
          </Text>
          {!!actionItemsData?.length &&
            actionItemsData.map((item: any, index: number) => (
              <div key={index}>
                {index !== 0 && <div className="divider"></div>}
                <ActionItemContainer actionItem={item} index={index} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default GenerateActionItemsPDF;
