import classNames from 'classnames';
import './PopupHeader.scss';

export function PopupHeader(props: any) {
  return (
    <header className={classNames('PopupHeader', { error: props.isError })}>
      <div className="text">
        {props.editable ? (
          <div className="input-wrapper">
            <input
              type="text"
              value={props.text}
              placeholder={props.placeholder}
              onChange={e => props.onChange(e.target.value)}
            />
            <img src="/assets/images/edit.svg" alt="edit" />
            {props.isError && <div className="error">{props.errorText}</div>}
          </div>
        ) : (
          props.text
        )}
      </div>
      <div className="close" onClick={props.onClose}>
        +
      </div>
      <img src="/assets/images/background-popup.svg" alt="background-popup" />
    </header>
  );
}
