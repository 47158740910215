import { useSelector, useDispatch } from 'react-redux';
import {
  setInfoPanelAsset,
  setInfoPanelContentType,
  setInfoPanelData,
  setIsOpenInfoPanel
} from 'features/InfoPanelSlice';
import classNames from 'classnames';

import './InfoPanelRenderer.scss';

const InfoPanelRenderer = (props: any) => {
  const dispatch = useDispatch<any>();
  const { isOpenInfoPanel, infoPanelAsset } = useSelector((state: any) => state.infoPanel);
  const { value: data, row, field } = props;

  const handleInfoPanelOnClick = (ev: any) => {
    ev.stopPropagation();
    dispatch(setInfoPanelContentType(field));

    const show = !isOpenInfoPanel || (!!isOpenInfoPanel && infoPanelAsset !== row.asset);
    dispatch(setIsOpenInfoPanel(show));
    if (row.ip_network) {
      dispatch(setInfoPanelData(row.excluded_networks?.excluded_networks));
    }
    dispatch(setInfoPanelAsset(show ? row.asset || row.ip_network : ''));
  };

  return !!data && !!data.count ? (
    <div
      onClick={handleInfoPanelOnClick}
      className={classNames('info-panel-count', { selected: infoPanelAsset === row.asset || row.ip_network })}
    >{`${data.count}`}</div>
  ) : null;
};

export { InfoPanelRenderer };
