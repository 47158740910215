import React, { useState, useEffect } from 'react';
import {
  useNotifications,
  NotificationsContextType,
  DROPDOWNBOX_TYPE
} from 'pages/Settings/Notifications/NotificationsContext';
import { Common } from 'common/services/common.service';
import { REQUIRED_FILTERS } from 'pages/Settings/Notifications/SidePanel/utils';
import Typography from '@material-ui/core/Typography';

const useDropDownBoxMetadata = (notificationData: any) => {
  const [dropdownBoxHeaderText, setDropdownBoxHeaderText] = useState<any>(null);
  const [dropdownBoxHeaderTooltip, setDropdownBoxHeaderTooltip] = useState<any>(null);
  const { notificationType } = useNotifications() as NotificationsContextType;
  const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];

  const getToggledFilters = (field: any) => {
    const toggled = field.choices.filter((f: any) => !!f.is_toggled);

    return toggled;
  };

  const getRequiredHeadersText = () => {
    return {
      name: notificationData.name,
      recipients:
        !!notificationData.recipients && !!notificationData.recipients.length
          ? `${notificationData.recipients.length} Selected`
          : '',
      notification_type: !!notificationData.notification_type.choices
        ? notificationData.notification_type.choices.filter((f: any) => f.is_toggled).map((f: any) => f.label)
        : [],
      channel_type: !!notificationData.channel_type.choices
        ? notificationData.channel_type.choices.filter((f: any) => f.is_toggled).map((f: any) => f.label)
        : [],
      configuration_id:
        !!notificationData.configuration_id && !!notificationData.configuration_id.choices
          ? notificationData.configuration_id.choices.filter((f: any) => f.is_toggled).map((f: any) => f.label)
          : []
    };
  };

  const getRequiredHeadersTooltip = () => {
    const toggledEvent = !!notificationData.notification_type.choices
      ? notificationData.notification_type.choices.find((f: any) => f.is_toggled)
      : null;
    const toggleChannel = !!notificationData.channel_type.choices
      ? notificationData.channel_type.choices.find((f: any) => f.is_toggled)
      : null;
    const toggleIntegration =
      !!notificationData.configuration_id && !!notificationData.configuration_id.choices
        ? notificationData.configuration_id.choices.find((f: any) => f.is_toggled)
        : null;

    return {
      name: notificationData.name,
      recipients:
        !!notificationData.recipients && !!notificationData.recipients.length
          ? notificationData.recipients.map((email: string, index: number) => (
              <Typography key={index} style={{ fontSize: 11, padding: 2 }}>
                {email}
              </Typography>
            ))
          : '',
      notification_type:
        !!notificationData.notification_type.choices && !!toggledEvent
          ? notificationData.notification_type.choices
              .filter((f: any) => f.is_toggled)
              .map((f: any, index: number) => (
                <Typography key={index} style={{ fontSize: 11, padding: 2 }}>
                  {f.label}
                </Typography>
              ))
          : '',
      channel_type:
        !!notificationData.channel_type.choices && !!toggleChannel
          ? notificationData.channel_type.choices
              .filter((f: any) => f.is_toggled)
              .map((f: any, index: number) => (
                <Typography key={index} style={{ fontSize: 11, padding: 2 }}>
                  {f.label}
                </Typography>
              ))
          : '',
      configuration_id:
        !!notificationData.configuration_id && !!notificationData.configuration_id.choices && !!toggleIntegration
          ? notificationData.configuration_id.choices
              .filter((f: any) => f.is_toggled)
              .map((f: any, index: number) => (
                <Typography key={index} style={{ fontSize: 11, padding: 2 }}>
                  {f.label}
                </Typography>
              ))
          : ''
    };
  };

  const getCheckboxHeadersMetadata = (field: any) => {
    const toggled = getToggledFilters(field);
    return {
      text: !!toggled.length ? `${toggled.length} Selected` : '',
      tooltip: !!toggled.length
        ? toggled.map((item: any, index: number) => (
            <Typography key={index} style={{ fontSize: 11, padding: 2 }}>
              {item.label}
            </Typography>
          ))
        : ''
    };
  };

  const getRadioHeadersMetadata = (field: any) => {
    const toggled = getToggledFilters(field);
    return {
      text: !!toggled.length ? toggled[0].label : '',
      tooltip: !!toggled.length ? <Typography style={{ fontSize: 11, padding: 2 }}>{toggled[0].label}</Typography> : ''
    };
  };

  const getInputChipsHeadersMetadata = (field: any) => {
    return {
      text: !!field && !!field.length ? `${field.length} Selected` : '',
      tooltip:
        !!field && !!field.length
          ? field.map((item: any, index: number) => (
              <Typography key={index} style={{ fontSize: 11, padding: 2 }}>
                {item}
              </Typography>
            ))
          : ''
    };
  };

  const getDatesHeadersMetadata = (field: any) => {
    return {
      text:
        !!field && !!field.choices
          ? `${Common.getDateString(field.choices.from)} - ${Common.getDateString(field.choices.to)}`
          : '',
      tooltip:
        !!field && !!field.choices ? (
          <Typography style={{ fontSize: 11, padding: 2 }}>{`${Common.getDateString(
            field.choices.from
          )} - ${Common.getDateString(field.choices.to)}`}</Typography>
        ) : (
          ''
        )
    };
  };

  const getTextFilterHeadersMetadata = (field: any) => {
    const toggled = getToggledFilters(field);
    return {
      text: !!toggled.length && !!toggled[0].value ? `${toggled[0].label} ${toggled[0].value}` : '',
      tooltip:
        !!toggled.length && !!toggled[0].value ? (
          <Typography style={{ fontSize: 11, padding: 2 }}>{`${toggled[0].label} ${toggled[0].value}`}</Typography>
        ) : (
          ''
        )
    };
  };

  const getSliderHeadersMetadata = (field: any) => {
    return {
      text:
        !!field && !!field.choices && !!field.choices.length ? `From ${field.choices[0]} To ${field.choices[1]}` : '',
      tooltip:
        !!field && !!field.choices && !!field.choices.length ? (
          <Typography
            style={{ fontSize: 11, padding: 2 }}
          >{`From ${field.choices[0]} To ${field.choices[1]}`}</Typography>
        ) : (
          ''
        )
    };
  };

  const getNumberListHeadersMetadata = (field: any) => {
    const isValue = !!field && !!field.choices && !!field.choices.length;
    const value = isValue ? (field.choices.slice(-1) === ',' ? field.choices.slice(0, -1) : field.choices) : '';
    return {
      text: isValue ? value : '',
      tooltip: isValue
        ? value.split(',').map((item: string, index: number) => {
            return !!item ? (
              <Typography key={index} style={{ fontSize: 11, padding: 2 }}>
                {item}
              </Typography>
            ) : null;
          })
        : ''
    };
  };

  const getStringListHeadersMetadata = (field: any) => {
    const isValue =
      !!field && !!field.choices && !!field.choices.length && !!field.choices.find((f: any) => f.is_toggled);

    const toggled = isValue ? field.choices.find((f: any) => f.is_toggled) : null;
    const value =
      isValue && !!toggled && !!toggled.value
        ? toggled.value.slice(-1) === ','
          ? `${toggled.label} ${toggled.value.slice(0, -1)}`
          : `${toggled.label} ${toggled.value}`
        : '';
    return {
      text: isValue ? value : '',
      tooltip: isValue
        ? value.split(',').map((item: string) => {
            return !!item ? <Typography style={{ fontSize: 11, padding: 2 }}>{item}</Typography> : null;
          })
        : ''
    };
  };

  useEffect(() => {
    const areFilters = !!notificationData && !!notificationData.filters;
    if (areFilters) {
      let headers: any = getRequiredHeadersText();
      let tooltips: any = getRequiredHeadersTooltip();

      const filters = notificationData.filters;
      Object.keys(filters).forEach((key: any) => {
        let metadata: any = { text: '', tooltip: '' };
        const field = requiredFields.includes(key) ? notificationData[key] : notificationData.filters[key];
        if (!!notificationData.filters[key]) {
          const dropdownType = notificationData.filters[key].dropdownbox_type;
          if ([DROPDOWNBOX_TYPE.CHECKBOX, DROPDOWNBOX_TYPE.IMPORTANCE].includes(dropdownType)) {
            metadata = getCheckboxHeadersMetadata(field);
          } else if (dropdownType === DROPDOWNBOX_TYPE.INPUT_CHIPS) {
            metadata = getInputChipsHeadersMetadata(field);
          } else if (dropdownType === DROPDOWNBOX_TYPE.RADIO) {
            metadata = getRadioHeadersMetadata(field);
          } else if (dropdownType === DROPDOWNBOX_TYPE.DATES) {
            metadata = getDatesHeadersMetadata(field);
          } else if (dropdownType === DROPDOWNBOX_TYPE.TEXT_FILTER || dropdownType === DROPDOWNBOX_TYPE.NUMBER_FILTER) {
            metadata = getTextFilterHeadersMetadata(field);
          } else if (dropdownType === DROPDOWNBOX_TYPE.SLIDER) {
            metadata = getSliderHeadersMetadata(field);
          } else if (dropdownType === DROPDOWNBOX_TYPE.NUMBERS_LIST_FILTER) {
            metadata = getNumberListHeadersMetadata(field);
          } else if (dropdownType === DROPDOWNBOX_TYPE.STRINGS_LIST_FILTER) {
            metadata = getStringListHeadersMetadata(field);
          }
          headers = {
            ...headers,
            [key]: metadata.text
          };
          tooltips = {
            ...tooltips,
            [key]: metadata.tooltip
          };
        }
      });
      setDropdownBoxHeaderText(headers);
      setDropdownBoxHeaderTooltip(tooltips);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData]);

  return [dropdownBoxHeaderText, dropdownBoxHeaderTooltip];
};

export default useDropDownBoxMetadata;
