import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconAccounts extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#6D6D7A';
    return (
      <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.0508 11.5952C19.0472 11.35 18.988 11.1081 18.8765 10.8831C18.7651 10.6581 18.6035 10.4545 18.401 10.284C18.1986 10.1135 17.9593 9.97936 17.6967 9.88929C17.4341 9.79923 17.1534 9.75498 16.8707 9.75908L6.10533 9.86102C5.53432 9.8693 4.98956 10.074 4.59087 10.43C4.19219 10.7861 3.97224 11.2644 3.97941 11.7597"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M11.3333 11.5952L11.3333 7.5" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M12.8095 2.5C13.3486 2.5 13.7857 2.93705 13.7857 3.47619L13.7857 6.42857C13.7857 6.96771 13.3486 7.40476 12.8095 7.40476L9.8571 7.40476C9.31797 7.40476 8.88091 6.96771 8.88091 6.42857L8.88091 3.47619C8.88091 2.93705 9.31797 2.5 9.8571 2.5L12.8095 2.5Z"
          stroke={color}
        />
        <path
          d="M12.8096 12.0951C13.3487 12.0951 13.7858 12.5322 13.7858 13.0713L13.7858 16.0237C13.7858 16.5628 13.3487 16.9999 12.8096 16.9999L9.8572 16.9999C9.31806 16.9999 8.881 16.5628 8.881 16.0237L8.881 13.0713C8.881 12.5322 9.31806 12.0951 9.85719 12.0951L12.8096 12.0951Z"
          stroke={color}
        />
        <path
          d="M20.1907 12.0951C20.7298 12.0951 21.1669 12.5322 21.1669 13.0713L21.1669 16.0237C21.1669 16.5628 20.7298 16.9999 20.1907 16.9999L17.2383 16.9999C16.6992 16.9999 16.2621 16.5628 16.2621 16.0237L16.2621 13.0713C16.2621 12.5322 16.6992 12.0951 17.2383 12.0951L20.1907 12.0951Z"
          stroke={color}
        />
        <path
          d="M5.42859 12.0952C5.96773 12.0952 6.40479 12.5323 6.40479 13.0714L6.40478 16.0238C6.40478 16.5629 5.96773 17 5.42859 17L2.47622 17C1.93708 17 1.50002 16.5629 1.50002 16.0238L1.50002 13.0714C1.50002 12.5323 1.93708 12.0952 2.47621 12.0952L5.42859 12.0952Z"
          stroke={color}
        />
      </svg>
    );
  }
}
