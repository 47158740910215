import { IIcon } from 'common/interfaces/interface';
import { Common } from 'common/services/common.service';
interface IIconRisk extends IIcon {
  iconSize?: IconSize;
  color?: string;
}
type IconSize = 'little' | 'medium';

export function IconRisk(props: IIconRisk) {
  let color = props.color || Common.getColorByUrgency(props.urgency);

  //@description : Resize Icon
  function resizeIcon() {
    let size = {
      little: (
        <svg className={props.className || ''} width="16px" height="16px" viewBox={`0 0 16 16`} fill="none">
          <path
            d="M14.3241 8.35266C14.5182 8.1576 14.5182 7.8424 14.3241 7.64734L8.35445 1.64734C8.26076 1.55318 8.13346 1.50017 8.00063 1.5C7.86781 1.49983 7.74037 1.55252 7.64645 1.64645L1.64645 7.64645C1.55268 7.74021 1.5 7.86739 1.5 8C1.5 8.13261 1.55268 8.25978 1.64645 8.35355L7.64645 14.3536C7.74037 14.4475 7.86781 14.5002 8.00063 14.5C8.13346 14.4998 8.26076 14.4468 8.35445 14.3527L14.3241 8.35266Z"
            stroke={color}
            strokeLinejoin="round"
          />
          <path
            d="M10.0489 8.35355L10.4024 8L10.0489 7.64645L8.35691 5.9545L8.00336 5.60094L7.64981 5.9545L5.95786 7.64645L5.6043 8L5.95786 8.35355L7.64981 10.0455L8.00336 10.3991L8.35691 10.0455L10.0489 8.35355Z"
            stroke={color}
          />
          <path
            d="M4.5 7.5H4V8.5H4.5V7.5ZM5.5 8.5C5.77614 8.5 6 8.27614 6 8C6 7.72386 5.77614 7.5 5.5 7.5V8.5ZM4.5 8.5H5.5V7.5H4.5V8.5Z"
            fill={color}
          />
          <path d="M8 4.5L8 5.5" stroke={color} strokeLinecap="square" />
          <path d="M10.5 8H11.5" stroke={color} strokeLinecap="square" />
          <path d="M8 10.5L8 11.5" stroke={color} strokeLinecap="square" />
        </svg>
      ),
      medium: (
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none">
          <path
            d="M21.309 12.3527C21.503 12.1577 21.503 11.8425 21.309 11.6474L12.3544 2.6474C12.2608 2.55324 12.1335 2.50022 12.0006 2.50005C11.8678 2.49989 11.7404 2.55258 11.6464 2.6465L2.64645 11.6465C2.55268 11.7403 2.5 11.8674 2.5 12.0001C2.5 12.1327 2.55268 12.2598 2.64645 12.3536L11.6464 21.3536C11.7404 21.4475 11.8678 21.5002 12.0006 21.5001C12.1335 21.4999 12.2608 21.4469 12.3544 21.3527L21.309 12.3527Z"
            stroke={color}
            strokeLinejoin="round"
          />
          <path
            d="M14.8965 12.3536L15.2501 12.0001L14.8965 11.6465L12.3586 9.10857L12.005 8.75502L11.6515 9.10857L9.11356 11.6465L8.76001 12.0001L9.11356 12.3536L11.6515 14.8915L12.005 15.2451L12.3586 14.8915L14.8965 12.3536Z"
            stroke={color}
          />
          <path
            d="M6.75 11.5001H6.25V12.5001H6.75V11.5001ZM8.25 12.5001C8.52614 12.5001 8.75 12.2762 8.75 12.0001C8.75 11.7239 8.52614 11.5001 8.25 11.5001V12.5001ZM6.75 12.5001H8.25V11.5001H6.75V12.5001Z"
            fill={color}
          />
          <path d="M12 6.75005L12 8.25005" stroke={color} strokeLinecap="square" />
          <path d="M15.75 12.0001H17.25" stroke={color} strokeLinecap="square" />
          <path d="M12 15.7501L12 17.2501" stroke={color} strokeLinecap="square" />
        </svg>
      )
    };
    return size[props.iconSize || 'little'];
  }
  return resizeIcon();
}
