import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconNetlify extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9135 21.9254V16.9046L16.0182 16.7998H17.0641L17.1688 16.9046V21.9254L17.0641 22.0301H16.0182L15.9135 21.9254Z"
          fill="#8C909A"
        />
        <path
          d="M15.9135 7.22509V2.20456L16.0182 2.09985H17.0641L17.1688 2.20456V7.22509L17.0641 7.3298H16.0182L15.9135 7.22509Z"
          fill="#8C909A"
        />
        <path
          d="M11.3667 18.3059H11.2188L10.4793 17.566V17.4181L12.2061 15.6917L12.9892 15.692L13.0943 15.7963V16.5794L11.3667 18.3059Z"
          fill="#8C909A"
        />
        <path
          d="M11.366 5.8241H11.2181L10.4786 6.56397V6.71187L12.2054 8.43833L12.9885 8.43797L13.0935 8.33363V7.55058L11.366 5.8241Z"
          fill="#8C909A"
        />
        <path
          d="M4.77273 11.4374H11.8855L11.9902 11.5421V12.588L11.8855 12.6927H4.77273L4.66803 12.588V11.5421L4.77273 11.4374Z"
          fill="#8C909A"
        />
        <path
          d="M17.6389 14.7094L17.5343 14.6047L17.535 11.572C17.535 11.0489 17.3293 10.6433 16.6977 10.6301C16.373 10.6217 16.0015 10.6293 15.6046 10.6462L15.5453 10.707L15.546 14.6047L15.4413 14.7094H14.1856L14.0809 14.6047V9.52486L14.1856 9.42016L17.0114 9.39453C18.4271 9.39453 19.0001 10.3672 19.0001 11.4651V14.6047L18.8954 14.7094H17.6389Z"
          fill="#8C909A"
        />
      </svg>
    );
  }
}
