import Grid from '@mui/material/Grid';
import ChipsBox from 'pages/Settings/Notifications/SidePanel/ViewNotificationPanel/ChipsBox/ChipsBox';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { REQUIRED_FILTERS } from 'pages/Settings/Notifications/SidePanel/utils';
import {
  useNotifications,
  NotificationsContextType,
  DROPDOWNBOX_TYPE
} from 'pages/Settings/Notifications/NotificationsContext';
import { Common } from 'common/services/common.service';
import { AppLoading } from 'common/components/appLoading/appLoading';

const ViewNotificationContent = () => {
  const { notificationType } = useNotifications() as NotificationsContextType;
  const {
    notificationData,
    changedNotificationData,
    allLabels,
    isLoading
  } = useNotificationConfiguration() as NotificationConfigurationContextType;

  const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];

  const getSectionTitle = (field: any) => {
    const data = requiredFields.includes(field) ? notificationData : notificationData?.filters;
    return !!data ? data[field]?.label : '';
  };

  const getRequiredContent = (id: string) => {
    if (!!notificationData && !!notificationData[id]) {
      const selectedField = notificationData[id];
      if (id === 'recipients') return selectedField;

      const dropdownType = selectedField.dropdownbox_type;
      switch (dropdownType) {
        case DROPDOWNBOX_TYPE.RADIO:
        case DROPDOWNBOX_TYPE.CHECKBOX:
        case DROPDOWNBOX_TYPE.IMPORTANCE:
          return selectedField.choices.filter((f: any) => f.is_toggled).map((item: any) => item.label);
        case DROPDOWNBOX_TYPE.DATES:
          return [
            `${Common.getDateString(selectedField.choices[0])} - ${Common.getDateString(selectedField.choices[1])}`
          ];
        case DROPDOWNBOX_TYPE.TEXT_FILTER:
        case DROPDOWNBOX_TYPE.NUMBER_FILTER:
          return selectedField.choices
            .filter((f: any) => f.is_toggled)
            .map((item: any) => `${item.label} ${item.value}`);
        case DROPDOWNBOX_TYPE.SLIDER: {
          const choices = notificationData[id].choices;
          return [`From ${choices[0]} To ${choices[1]}`];
        }
      }
    }
    return [];
  };
  const getSectionContent = (id: string) => {
    const data = notificationData?.filters;
    const dropdownType = !!data ? data[id].dropdownbox_type : '';
    const beKeys = data[id].be_setter_key;

    // NO SAVED DATA, RETURN []
    const keyFieldSaved = Object.keys(changedNotificationData).find((key: string) => beKeys.includes(key));
    if (!keyFieldSaved) return [];

    switch (dropdownType) {
      case DROPDOWNBOX_TYPE.INPUT_CHIPS:
        return data[id];
      case DROPDOWNBOX_TYPE.RADIO:
      case DROPDOWNBOX_TYPE.CHECKBOX:
        return data[id].choices.filter((f: any) => f.is_toggled).map((item: any) => item.label);
      case DROPDOWNBOX_TYPE.IMPORTANCE:
        return data[id].choices.filter((f: any) => f.is_toggled).map((item: any) => item.label);
      case DROPDOWNBOX_TYPE.DATES:
        return [`${Common.getDateString(data[id].from)} - ${Common.getDateString(data[id].to)}`];
      case DROPDOWNBOX_TYPE.TEXT_FILTER:
      case DROPDOWNBOX_TYPE.NUMBER_FILTER:
        return data[id].choices.filter((f: any) => f.is_toggled).map((item: any) => `${item.label} ${item.value}`);
      case DROPDOWNBOX_TYPE.SLIDER:
        return [`From ${data[id].choices[0]} To ${data[id].choices[1]}`];
      case DROPDOWNBOX_TYPE.NUMBERS_LIST_FILTER:
        return data[id].choices.split(',');
      case DROPDOWNBOX_TYPE.STRINGS_LIST_FILTER: {
        const toggled = data[id].choices.find((item: any) => item.is_toggled);
        return !!toggled ? [`${toggled.label}:`, ...toggled.value.split(',')] : [];
      }
      default:
        return [];
    }
  };

  const allFilters =
    !!notificationData && !!notificationData?.filters
      ? Object.keys(notificationData.filters).filter((key: any) => !!getSectionContent(key).length)
      : [];

  return isLoading ? (
    <AppLoading size="big" />
  ) : (
    <Grid container className="notification-properties" direction="column">
      <Grid item>
        <ChipsBox title={!!allLabels ? allLabels['channel_type'] : ''} chips={getRequiredContent('channel_type')} />
      </Grid>
      {!!notificationData?.recipients && !!notificationData.recipients.length && (
        <>
          <Grid item className="divider" />
          <Grid item>
            <ChipsBox title={!!allLabels ? allLabels['recipients'] : ''} chips={getRequiredContent('recipients')} />
          </Grid>
        </>
      )}
      {!!notificationData?.configuration_id &&
        !!notificationData.configuration_id.choices.filter((item: any) => item.is_toggled).length && (
          <>
            <Grid item className="divider" />
            <Grid item>
              <ChipsBox
                title={!!allLabels ? allLabels['configuration_id'] : ''}
                chips={getRequiredContent('configuration_id')}
              />
            </Grid>
          </>
        )}
      <Grid item className="divider" />
      <Grid item>
        <ChipsBox title={getSectionTitle('notification_type')} chips={getRequiredContent('notification_type')} />
      </Grid>
      <Grid item className="divider" />
      <Grid item>
        {allFilters.map((field: any, index) => (
          <Grid container key={`${field}-${index}`} direction="column" sx={{ flexWrap: 'nowrap' }}>
            {index !== 0 && <Grid item className="divider" />}
            <Grid item>
              <ChipsBox title={getSectionTitle(field)} chips={getSectionContent(field)} />{' '}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ViewNotificationContent;
