import { IconRisk } from 'common/components/Icons/IconRisk/IconRisk';
import React from 'react';
import { Common } from 'common/services/common.service';
import './riskScoreByCategory.scss';
interface ILegend {
  label: string;
  name: string;
  type: string;
  risk_grade: number | null;
  risk_score: number | null;
}

interface IChartLineLegend {
  label: string;
  categories: ILegend[];
}

export function RiskScoreByCategory(props: IChartLineLegend) {
  const getTotalRiskScoreItem = (): ILegend => {
    return props.categories.filter(item => item.name === 'risk_score')[0];
  };

  const getIconColor = (type: string): string => {
    return Common.getColorByTestResult(type);
  };

  const totalRiskScore: ILegend = getTotalRiskScoreItem();

  return (
    <div className="riskScoreByCategory">
      <div>
        <h6>{totalRiskScore.label}</h6>
        <div className="riskScoreByCategory-total" style={{ color: Common.getColorByUrgency(totalRiskScore.type) }}>
          {totalRiskScore.risk_score}
        </div>
      </div>
      <div>
        <h6>{props.label}</h6>
        <div className="riskScoreByCategory-categories">
          {props.categories
            .filter(item => item.name !== 'risk_score')
            .map((item: ILegend, index: number) => (
              <React.Fragment key={index}>
                {item.risk_grade !== null && (
                  <div className="riskScoreByCategory-label-scope">
                    <IconRisk iconSize="medium" color={getIconColor(item.type)} />
                    <div className="riskScoreByCategory-label">{item.label}</div>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
}
