import { AppLoading } from 'common/components/appLoading/appLoading';
import {
  loadList,
  showCategoryPopup,
  fetchIntegrationsList,
  fetchIntegrationsMetadata,
  addIntegrationsToList
} from 'features/IntegrationsSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntegrationBox } from './IntegrationNew/Integration/IntegrationBox';
import { NotFound } from 'notFound/NotFound';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Common } from 'common/services/common.service';
import Fade from '@mui/material/Fade';
import { IntegrationsList } from './config';
import { Integration } from './IntegrationOld/Integration/Integration';

import './Integrations.scss';

export function Integrations(props: any) {
  const dispatch = useDispatch<any>();
  const [noPermission, setNoPermission] = useState<boolean>(false);
  const { list, allIntegrations, statusIntegrationsList, statusIntegrationsMetadata } = useSelector(
    (state: any) => state.integrations
  );

  const flags = useFlags();
  const history = useHistory();

  useEffect(() => {
    const func = async () => {
      try {
        await dispatch(loadList()).unwrap();
        await dispatch(fetchIntegrationsList()).unwrap();
        await dispatch(fetchIntegrationsMetadata()).unwrap();

        const category = Common.getParamFromUrl('openCategory');

        if (category?.length) {
          dispatch(showCategoryPopup(category));
        }
      } catch (err) {
        const _err: any = err;
        switch (_err.status) {
          case 403:
            setNoPermission(true);
            break;
          case 401:
            history.replace('/');
            break;
        }
      }
    };
    func();
  }, [dispatch, history]);

  useEffect(() => {
    if (statusIntegrationsList === 'succeed') {
      dispatch(addIntegrationsToList(IntegrationsList));
    }
  }, [statusIntegrationsList, dispatch]);

  const renderIntegrationsList = (): any => {
    let categories: any = new Set();
    const containersLabel: any = {};
    allIntegrations.forEach((integration: any) => {
      categories.add(integration.category);
      containersLabel[integration.category] = integration.category_label;
    });

    let _html: any = [];
    for (let category of categories) {
      const integrationsByCategory = allIntegrations.filter((integration: any) => integration.category === category);
      let showTitle = false;
      for (let integration of integrationsByCategory) {
        const ff = !!integration.feature_flag ? Common.underscoreToCamelCase(integration.feature_flag) : null;

        if (!ff || !!flags[ff]) {
          showTitle = true;
          break;
        }
      }
      if (showTitle) {
        _html.push(<h2 key={`${category}-title`}>{containersLabel[category] || ''}</h2>);
        _html.push(
          <ul key={`${category}-container`} className="integrations-list">
            {renderIntegrationsBoxes(integrationsByCategory)}
          </ul>
        );
      }
    }

    return _html;
  };

  const renderIntegrationsBoxes = (integrations: any) => {
    return integrations.map((integration: any, index: number) => {
      const integ = list?.results?.filter((_app: any) => _app.type === integration.type)[0];
      const ff = !!integration.feature_flag ? Common.underscoreToCamelCase(integration.feature_flag) : null;
      return !['sentinel', 'splunk', 'jira', 'servicenow', 'xsoar'].includes(integration.type) ? (
        !ff || !!flags[ff] ? (
          <IntegrationBox data={integration} key={index} />
        ) : null
      ) : (
        <Integration
          data={integration}
          icon={`icon-${integration.type}`}
          id={integ?.id}
          isActive={integ?.is_active}
          isExists={!!integ}
          key={integration.label}
          showToggle={integration.showToggle}
          showConnect={integration.show_action}
        />
      );
    });
  };

  if (noPermission) {
    return (
      <div className="Integrations">
        <NotFound type="403" />
      </div>
    );
  }

  return (
    <div className="Integrations">
      {statusIntegrationsList !== 'succeed' || statusIntegrationsMetadata !== 'succeed' ? (
        <AppLoading />
      ) : (
        <Fade in timeout={800}>
          <div>{renderIntegrationsList()}</div>
        </Fade>
      )}
    </div>
  );
}
