import { IconArrow } from '../Icons/IconArrow/IconArrow';
import classNames from 'classnames';
import './DynamicMultiChoiceDropDown.scss';
import { useEffect, useRef, useState } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from '../appLoading/appLoading';
import { Checkbox } from 'common/components/Icons/IconCheckbox/IconCheckbox';
import { useDispatch, useSelector } from 'react-redux';
// import { toggleAllowPopupClose } from 'features/GeneralSlice';
import { Common } from 'common/services/common.service';

export function DynamicMultiChoiceDropDown(props: any) {
  const ref = useRef<HTMLDivElement>(null);
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [data, setData] = useState<[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { groups } = useSelector((state: any) => state.groups);
  const dispatch = useDispatch<any>();

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsToggled(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(toggleAllowPopupClose(!isToggled));
  //   }, 100);
  //   return () => {
  //     setTimeout(() => {
  //       dispatch(toggleAllowPopupClose(true));
  //     }, 100);
  //   };
  // }, [isToggled, dispatch]);

  useEffect(() => {
    setData(groups);
  }, [groups]);

  useEffect(() => {
    if (props.row && ['admin', 'owner'].includes(props.row.role.toLowerCase())) {
      setSelected([]);
    }
  }, [props.row]);

  useEffect(() => {
    if (!props.row || !props.api.getRowIndex(props.id)) {
      dispatch(props.dispatchGetAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.row) {
      if (props.row.group_restrictions && typeof props.row.group_restrictions[0] === 'number') {
        const arr = props.row.group_restrictions.map((group_id: number) => {
          return groups.filter((g: any) => group_id === g.id)[0].name;
        });
        setSelected(arr);
      } else {
        setSelected(props.row.group_restrictions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.row]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const _setSelected = async (item: string) => {
    let copy = [...selected];
    if (selected.includes(item)) {
      copy.splice(copy.indexOf(item), 1);
      setSelected(copy);
    } else {
      copy = [item, ...copy];
      setSelected([...selected, item]);
    }
    const items = getItemsIds(copy);
    if (props.dispatchSetAction) {
      await dispatch(
        props.dispatchSetAction({
          items: items,
          row: props.row,
          apiRef: props.api
        })
      );
    } else {
      props.onChange(items);
    }
  };

  const getItemsIds = (_selectedCopy: string[]): number[] => {
    const arr: number[] = [];
    data.forEach((row: any) => {
      if (_selectedCopy.includes(row.name)) {
        arr.push(row.id);
      }
    });
    return arr;
  };

  const _setIsToggled = () => {
    if (data.length) {
      setIsToggled(!isToggled);
    }
  };

  const getListHTML = (): JSX.Element[] => {
    if (!data.length) {
      return [];
    }
    const copy = [...data];
    return copy
      .sort((a, b) => Common.sortAlphabetically(a, b, 'name'))
      .map((item: any) => {
        return (
          <li key={item[props.fieldName]} onClick={() => _setSelected(item[props.fieldName])}>
            <Checkbox active={selected.includes(item[props.fieldName])} />
            {item[props.fieldName]}
          </li>
        );
      });
  };

  const onSubmitSearch = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setLoading(true);
      RestApi.getData(props.searchPath + searchInput).subscribe((response: any) => {
        setData(response.results);
        setLoading(false);
      });
    }
  };

  const renderSelectedHeader = (): string => {
    const selectedString = selected.join(', ');
    if (selectedString.length > props.limitHeaderLength) {
      return selectedString.substring(0, props.limitHeaderLength - 1) + '...';
    }
    return selectedString;
  };

  const onClear = async () => {
    setSelected([]);
    if (props.dispatchSetAction) {
      await dispatch(
        props.dispatchSetAction({
          items: [],
          row: props.row,
          apiRef: props.api
        })
      );
    } else {
      props.onChange([]);
    }
  };

  return (
    <div
      className={classNames('DynamicMultiChoiceDropDown', {
        [`role-${props.row && props.row.role.toLowerCase()}`]: props.row,
        disabled: props.disabled
      })}
      ref={ref}
    >
      <header onClick={_setIsToggled} className={classNames({ selected: !!selected, empty: !data.length })}>
        <span id="SELECTED_GROUPS">{selected.length ? renderSelectedHeader() : props.headerText}</span>{' '}
        <IconArrow toggleRotate={isToggled} />
      </header>
      <div className={classNames({ visible: isToggled })}>
        {loading && <AppLoading />}
        <div className="search">
          <img alt="search" src={`/assets/images/search.svg`} />
          <input
            type="text"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            onKeyDown={onSubmitSearch}
          />
        </div>
        <div className="scrollbar-common">
          <ul style={{ height: '100%', overflow: 'auto' }}>{data.length && getListHTML()}</ul>
          <div className={classNames('clear', { active: selected.length })} onClick={onClear}>
            Clear Selection
            <span>x</span>
          </div>
        </div>
      </div>
    </div>
  );
}
