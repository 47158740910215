import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@cyberpion/cyberpion-ui';

interface IWizardFooter {
  onNext: () => void;
  onPrev: () => void;
}

const WizardFooter: React.FC<IWizardFooter> = ({ onNext, onPrev }) => {
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const { integrationSchema, currentStep, fieldsValue } = useSelector((state: any) => state.integrations);
  const isPrevDisplay = currentStep > 1;
  const isPrevDisabled =
    integrationSchema.steps[`step${currentStep}`]?.is_previous === undefined
      ? false
      : !integrationSchema.steps[`step${currentStep}`]?.is_previous;

  useEffect(() => {
    const stepFields = integrationSchema.steps[`step${currentStep}`];
    const fields = integrationSchema.fields;

    const isNext = !!stepFields.output
      ? stepFields.output.every((id: string) => {
          const field = fields[id];
          if (!field.required) {
            return true;
          }

          let isEnabled = false;
          const conditions = field.display_condition;
          if (conditions) {
            const field = conditions.field;
            const value = conditions.value;

            isEnabled = fieldsValue[field] !== value || (fieldsValue[field] === value && !!fieldsValue[id]);
          } else {
            isEnabled = Array.isArray(fieldsValue[id]) ? !!fieldsValue[id].length : !!fieldsValue[id];
          }

          return isEnabled;
        })
      : true;
    setIsNextEnabled(isNext);
  }, [integrationSchema, currentStep, fieldsValue]);

  return (
    <div className="WizardFooter">
      {isPrevDisplay && (
        <Button
          buttonStyle="main"
          disabled={isPrevDisabled}
          type="button"
          size="small"
          onClick={onPrev}
          text="Previous"
        />
      )}
      <Button
        buttonStyle="main"
        disabled={!isNextEnabled}
        type="button"
        size="small"
        onClick={onNext}
        text="Next"
        className="btn-next"
      />
    </div>
  );
};

export default WizardFooter;
