import React, { useState, useEffect } from 'react';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
// import Divider from '@mui/material/Divider';
import { Checkbox } from 'common/components/Icons/IconCheckbox/IconCheckbox';
import { Text } from '@cyberpion/cyberpion-ui';

import './filters-menu.scss';

const useStyles = makeStyles({
  root: {
    minWidth: 40,
    fontSize: 12,
    fontWeight: 400
  },
  popoverRoot: {
    maxHeight: 400,
    minHeight: 105,
    padding: '15px 15px 0 15px',
    '& .checkbox': {
      display: 'flex',
      flexDirection: 'row',
      padding: '8px 0'
      // alignItems: 'center'
    }
  }
});

const FiltersMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { notificationData, onApplyFilters } = useNotificationConfiguration() as NotificationConfigurationContextType;
  const [selectedFiltersIds, setSelectedFiltersIds] = useState<any>([]);
  const areFilters = !!notificationData && !!notificationData.filters;
  const classes = useStyles();

  useEffect(() => {
    if (!!notificationData && !!notificationData.filters) {
      const selectedIds = Object.values(notificationData.filters)
        .filter((filter: any) => filter.is_toggled)
        .map((filter: any) => filter.id);

      setSelectedFiltersIds(selectedIds);
    }
  }, [notificationData]);

  const filterOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (selectedOption: any) => () => {
    const updatedIds = Object.values(notificationData.filters).reduce((all: any, filter: any) => {
      if (filter.id === selectedOption.id && !selectedFiltersIds.includes(filter.id)) {
        return [...all, filter.id];
      }
      if (filter.id !== selectedOption.id && selectedFiltersIds.includes(filter.id)) {
        return [...all, filter.id];
      }
      return all;
    }, []);
    setSelectedFiltersIds(updatedIds);
  };

  const applyOnClick = () => {
    setAnchorEl(null);
    onApplyFilters(selectedFiltersIds);
  };

  return (
    <div className="add-filter-menu">
      <Button
        variant="text"
        onClick={filterOnClick}
        classes={{ root: classes.root }}
        data-testid="notification-side-panel-add-filter-button"
      >
        + Add Filter
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: classes.popoverRoot }}
      >
        <Grid container direction="column">
          {/* <Grid item>Search</Grid>
          <Grid item sx={{ padding: '10px 0' }}>
            <Divider />
          </Grid> */}
          <Grid item flexGrow={1}>
            {!!areFilters &&
              Object.values(notificationData.filters).map((filter: any) => (
                <Grid key={filter.id} container alignItems="center" direction="row">
                  <Grid item key={filter.id}>
                    <div
                      key={filter.label}
                      className="checkbox"
                      onClick={handleOnChange(filter)}
                      data-testid={`notification-add-filter-${filter.id}`}
                    >
                      <Checkbox active={selectedFiltersIds.includes(filter.id)} />
                      <div className="MGS-option-text" style={{ fontSize: 12 }}>
                        {filter.label}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            sx={{
              marginTop: 1,
              position: 'sticky !important',
              bottom: 0,
              padding: '10px 0',
              backgroundColor: '#FFFFFF'
            }}
          >
            <Grid container alignItems="center">
              <Grid item flexGrow={1} sx={{ width: 70 }}>
                {selectedFiltersIds && !!selectedFiltersIds.length && (
                  <Text textColor="#5D6576" textSize={12} family="Rubik">
                    {`${selectedFiltersIds.length} Selected`}
                  </Text>
                )}
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  onClick={applyOnClick}
                  classes={{ root: classes.root }}
                  data-testid="notification-add-filter-apply-button"
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

export default FiltersMenu;
