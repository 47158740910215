import React, { useState } from 'react';
import { Text } from '@cyberpion/cyberpion-ui';
import { useSelector } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { Base64 } from 'js-base64';

import './FileUpload.scss';

const iconTrash = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M2.625 3.75L3.30432 10.2035C3.3445 10.5852 3.66638 10.875 4.0502 10.875H7.9498C8.33362 10.875 8.6555 10.5852 8.69568 10.2035L9.375 3.75"
      stroke="#5D6576"
      stroke-linecap="round"
    />
    <path d="M4.875 5.625V8.625" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.125 5.625V8.625" stroke="#5D6576" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.375 2.875H10.125C10.4011 2.875 10.625 3.09886 10.625 3.375C10.625 3.65114 10.4011 3.875 10.125 3.875H1.875C1.59886 3.875 1.375 3.65114 1.375 3.375C1.375 3.09886 1.59886 2.875 1.875 2.875H3.625L3.625 1.875C3.625 1.18464 4.18464 0.625 4.875 0.625H7.125C7.81536 0.625 8.375 1.18464 8.375 1.875V2.875ZM7.375 2.875V1.875C7.375 1.73693 7.26307 1.625 7.125 1.625L4.875 1.625C4.73693 1.625 4.625 1.73693 4.625 1.875L4.625 2.875H7.375Z"
      fill="#5D6576"
    />
  </svg>
);

interface FileUploadProps {
  id: string;
  title: string;
  setValue: (value: any) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ id, title, setValue }) => {
  const [fileName, setFileName] = useState<any>(null);
  const { integrationSchema } = useSelector((state: any) => state.integrations);

  const fileSchema = integrationSchema.fields[id];
  const formatsList = fileSchema.limit?.formats || ['*.*'];

  const fileOnChange = (file: any) => {
    setFileName(file?.name);
    let reader = new FileReader();

    reader.onload = function(event) {
      const base64Text = Base64.btoa(event?.target?.result as string);
      setValue(base64Text);
    };
    reader.readAsBinaryString(file);
  };

  const handleOnDelete = () => {
    setFileName(null);
    setValue(null);
  };

  return (
    <div id={id} className="FileUpload">
      {!!title && (
        <Text textSize={13} weight={500} style={{ marginBottom: 16 }}>
          {title}
        </Text>
      )}

      <FileUploader
        multiple={false}
        handleChange={fileOnChange}
        name="file"
        types={formatsList}
        hoverTitle=" "
        children={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 90,
              backgroundColor: '#F9FAFF',
              borderRadius: 8,
              border: '1px dashed #ECEDFF'
            }}
          >
            <Text textColor="#5D6576">
              Drag and drop area or
              <span className="file-browse" style={{ color: 'blue', paddingLeft: 3 }}>
                Browse
              </span>
            </Text>
            <Text style={{ textTransform: 'uppercase', paddingLeft: 4 }}>[{formatsList.join(', ')}]</Text>
          </div>
        }
      />
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <Text
          textColor="#5D6576"
          family="Rubik"
          style={{
            marginTop: 10,
            width: '100%',
            height: 35,
            backgroundColor: '#FFF',
            border: '1px solid #ECEDFF',
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 10
          }}
        >
          {fileName ? fileName : 'No files uploaded yet'}
        </Text>
        {!!fileName && (
          <div className="trash-icon" onClick={handleOnDelete}>
            {iconTrash}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
