// import { Assignee } from "common/components/assignee/Assignee";
import { Common } from 'common/services/common.service';
import './topActionItems.scss';
interface ILegend {
  id: number;
  title: string;
  summary: string;
  type: string;
  urgency: number;
  first_seen: string;
  assignee: string | null;
}

interface ITopActionItems {
  data: ILegend[];
}
export function TopActionItems(props: ITopActionItems) {
  return props.data ? (
    <div className="topActionItems">
      {props.data[0] && <div className="topActionItems-header">TOP ACTION ITEMS</div>}
      {props.data.map((ai: any, index: number) => (
        <div className="topActionItems-card-scope" key={index}>
          <div
            className="topActionItems-border-left"
            style={{
              background: Common.getColorByUrgency(ai.urgency.type)
            }}
          ></div>
          <div className="topActionItems-sub-header">
            <div className="topActionItems-header-main">
              <div>{ai.title.label}</div>
              {ai.type && <div className="uppercase">{ai.type}</div>}
              {ai.first_opened_at && <div>{Common.getDateFormat(ai.first_opened_at)}</div>}
            </div>
            <div className="topActionItems-body-scope">
              <div className="topActionItems-body">
                <div className="topActionItems-summary topActionItems-cut-text">
                  {Common.shortenText(ai.summary, 100, '...')}
                </div>
              </div>
            </div>
            <div className="topActionItems-assignee-scope">{/* <Assignee assignee={ai.assignee} id={ai.id} /> */}</div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div></div>
  );
}
