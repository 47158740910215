import './NewTopAssetsAtRisk.scss';
import { CriticalActionsGraph } from '@cyberpion/cyberpion-ui';
import { urgencies, importances, graphData, ITopAssetsAtRiskProps } from './config';
import { useEffect, useState } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import { Common } from 'common/services/common.service';

export function NewTopAssetsAtRisk(props: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const [enrichedUrgencies, setEnrichedUrgencies] = useState<ITopAssetsAtRiskProps[]>([]);
  const [enrichedImportances, setEnrichedImportances] = useState<ITopAssetsAtRiskProps[]>([]);
  const [enrichedGraphData, setEnrichedGraphData] = useState<ITopAssetsAtRiskProps[]>([]);

  useEffect(() => {
    if (enrichedUrgencies.length && enrichedImportances.length && enrichedGraphData.length) {
      setLoading(false);
    }
  }, [enrichedUrgencies, enrichedImportances, enrichedGraphData]);

  useEffect(() => {
    const fetch = async () => {
      let _enrichedUrgencies: ITopAssetsAtRiskProps[] = [...urgencies];
      const urgenciesResults: any = await RestApi.getPromiseAll(
        _enrichedUrgencies.map(item => Common.AddMsspAccountIfNeeded(Common.addGlobalFilterIfNeeded(item.apiCall)))
      );
      _enrichedUrgencies = _enrichedUrgencies.map((item: any, index: number) => {
        return {
          ...item,
          count: urgenciesResults[index].count,
          url: Common.AddMsspAccountIfNeeded(Common.addGlobalFilterIfNeeded(item.url))
        };
      });
      setEnrichedUrgencies(_enrichedUrgencies);

      let _enrichedImportances: ITopAssetsAtRiskProps[] = [...importances];
      const importancesResults: any = await RestApi.getPromiseAll(
        _enrichedImportances.map(item => Common.AddMsspAccountIfNeeded(Common.addGlobalFilterIfNeeded(item.apiCall)))
      );
      _enrichedImportances = _enrichedImportances.map((item: any, index: number) => {
        return {
          ...item,
          count: importancesResults[index].count,
          url: Common.AddMsspAccountIfNeeded(Common.addGlobalFilterIfNeeded(item.url))
        };
      });
      setEnrichedImportances(_enrichedImportances);

      let _enrichedGraphData: ITopAssetsAtRiskProps[] = [...graphData];
      const graphResults: any = await RestApi.getPromiseAll(
        _enrichedGraphData.map(item => Common.AddMsspAccountIfNeeded(Common.addGlobalFilterIfNeeded(item.apiCall)))
      );
      _enrichedGraphData = _enrichedGraphData.map((item: any, index: number) => {
        return {
          ...item,
          count: graphResults[index].count,
          url: Common.AddMsspAccountIfNeeded(Common.addGlobalFilterIfNeeded(item.url))
        };
      });
      setEnrichedGraphData(_enrichedGraphData);
    };
    fetch();
  }, [props.globalFilter]);

  if (loading) {
    return <div className="NewTopAssetsAtRisk">No Data</div>;
  }

  return (
    <div className="NewTopAssetsAtRisk">
      <CriticalActionsGraph
        urgencies={enrichedUrgencies}
        importances={enrichedImportances}
        circles={enrichedGraphData}
      />
    </div>
  );
}
