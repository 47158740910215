import React from 'react';
import { Divider, Grid } from '@mui/material';
import { Common } from 'common/services/common.service';
import { Importance } from '@cyberpion/cyberpion-ui';
import './panelSideHeader.scss';

interface IPanelSideHeader {
  panelData: any;
}

const PanelSideHeader: React.FC<IPanelSideHeader> = ({ panelData }) => {
  const { asset, first_seen: discoveryDate, risk_score: riskScore, importance } = panelData;
  const color = Common.getColorByUrgency(riskScore?.type);

  const isRiskScore =
    !!panelData.risk_score && (panelData.risk_score.risk_score === 0 || !!panelData.risk_score.risk_score);

  return (
    <Grid container className="panel-header-container">
      <Grid item className="panel-header-host">
        {asset}
      </Grid>
      {discoveryDate && (
        <Grid sm={12} item className="panel-header-discovery" sx={!isRiskScore ? { paddingBottom: 1.5 } : {}}>
          {`Discovery Date: ${discoveryDate}`}{' '}
        </Grid>
      )}
      {isRiskScore && (
        <>
          <Grid item sm={12}>
            <Divider className="panel-header-divider" />
          </Grid>
          <Grid item sm={12}>
            <Grid container flexDirection="row">
              <Grid item sm={4}>
                <Grid container flexDirection="column">
                  <Grid item className="panel-header-text">
                    Asset Risk
                  </Grid>
                  <Grid item className="panel-header-score" sx={{ color: color, fontSize: 32, fontWeight: 400 }}>
                    {riskScore?.risk_score}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={8}>
                {importance && importance.importance && (
                  <Grid container flexDirection="column">
                    <Grid item className="panel-header-text">
                      Asset Importance
                    </Grid>
                    <Grid item className="panel-header-importance">
                      <Importance value={importance} darkBG />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PanelSideHeader;
