import { useEffect, useRef, useState } from 'react';
import { Grid } from 'common/components/grid/Grid';
import { RestApi } from 'common/services/rest-api.service';
import { IRootColumnPicker } from 'common/interfaces/interface';
import { Paths } from 'common/constants/paths';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { PathNames } from 'common/constants/pathNames';
import { ReleaseRequest } from './components/releaseRequest/ReleaseRequest';
import { ISelectionRowsChange } from 'common/components/grid/interface';
import { removeAllRows, resetQueryString, setFields, setInitialPath } from 'features/GridSlice';
import { AppDispatch } from 'app/store';
import { PopUp } from 'common/components/popUp/PopUp';
import TopPanel from 'common/components/TopPanel/TopPanel';
import TOP_PANEL_PAGES from 'common/constants/topPanelPages';
import useShowToggle from 'common/hooks/useShowToggle';
import { Grid as MUIGrid } from '@mui/material';
import { TopPanelLoadingState } from 'common/components/TopPanel/TopPanelLoadingState/TopPanelLoadingState';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';

import '../pages.scss';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';
export interface IActiveProtect {
  rows?: any;
  path?: string;
  // columnPicker: IColumnPicker[];
  // showColumnPicker: boolean;
  isMoreButton: boolean;
  isCSVDownloadable: boolean;
  count: number;
  columns: Array<any>;
  identifier: string;
}
export function ActiveProtectionRouting(props: any) {
  const isInitialMount = useRef(true);
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [activeProtectData, setActiveProtectData] = useState<IActiveProtect>(initData());
  const [popupData, setPopupData] = useState<any>([]);
  const [summaryData, setSummaryData] = useState<any>(null);
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [abortControllerGrid, setAbortControllerGrid] = useState<AbortController>();
  const [abortControllerTopPanel, setAbortControllerTopPanel] = useState<AbortController>();
  const { isOpen: isTopPanelOpne, setToggle: setTopPanelToggle } = useShowToggle(true);
  const { globalFilter } = useSelector((state: IReduxState) => state.general);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchGridData();
      fetchTopPanelData();
    }
  });

  useEffect(() => {
    dispatch(resetQueryString());
    return () => {
      dispatch(removeAllRows());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTopPanelData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  function initData(): IActiveProtect {
    return {
      rows: [],
      // columnPicker: [],
      // showColumnPicker: false,
      isMoreButton: true,
      isCSVDownloadable: false,
      count: 0,
      columns: [],
      identifier: ''
    };
  }

  const fetchTopPanelData = () => {
    if (abortControllerTopPanel) {
      abortControllerTopPanel.abort();
    }
    const abort_controller = new AbortController();
    setAbortControllerTopPanel(abort_controller);

    const url = !!globalFilter
      ? `${Paths[PathNames.activeProtectionSummary]}?group=${globalFilter}`
      : Paths[PathNames.activeProtectionSummary];
    setIsSummaryLoading(true);
    RestApi.getData(url, true, abort_controller.signal).subscribe((response: any) => {
      setSummaryData({ ...response, inContext: 'activeProtection' });
      setIsSummaryLoading(false);
    });
  };

  function fetchGridData() {
    if (abortControllerGrid) {
      abortControllerGrid.abort();
    }
    const abort_controller = new AbortController();
    setAbortControllerGrid(abort_controller);

    RestApi.getData(Paths[PathNames.activeProtectionColumns]).subscribe((columns: IRootColumnPicker) => {
      let fields = ColumnsManage.checkGridColumnsToDisplay(columns.data);
      dispatch(setFields(fields));
      dispatch(setInitialPath(Paths[PathNames.activeProtection]));
      dispatch(setColumnPicker(columns.data));
      dispatch(setShowColumnPicker(columns.is_displayable));

      setActiveProtectData({
        rows: [],
        // columnPicker: columns.data || [],
        // showColumnPicker: columns.is_displayable,
        isMoreButton: columns.is_more_button,
        isCSVDownloadable: columns.is_csv_downloadable,
        count: 0,
        identifier: columns.identifier_field,
        columns: ColumnsManage.createColumns(columns.identifier_field, columns.data || [], {
          page: 'activeProtection',
          tab: props.selectTab
        })
      });
      setShowGrid(true);
    });
  }

  function onSelectionChange(params: ISelectionRowsChange) {
    let assets: string[] = [];
    if (params.ids.length !== 0) {
      params.ids.forEach(id => {
        for (const key in params.rows) {
          if (params.rows[key][activeProtectData.identifier] === id) {
            assets.push(params.rows[key].protected_host);
            break;
          }
        }
      });

      setPopupData({
        assets: assets,
        ids: params.ids,
        apiGrid: params.apiGrid
      });
      setShowPopup(true);
    }
  }

  const showSummary = !!summaryData && summaryData.inContext === 'activeProtection';

  return (
    <>
      {!isSummaryLoading && showSummary ? (
        <TopPanel
          page={TOP_PANEL_PAGES.ACTIVE_PROTECTION}
          data={summaryData}
          isLoading={isSummaryLoading}
          isOpen={isTopPanelOpne}
          setToggle={setTopPanelToggle}
        />
      ) : (
        <div>
          <MUIGrid container direction="column">
            <MUIGrid item className="top-panel-loading-state">
              <TopPanelLoadingState page={TOP_PANEL_PAGES.ACTION_ITEMS} isOpen={isTopPanelOpne} />
            </MUIGrid>
            <MUIGrid item sx={{ height: 10 }}></MUIGrid>
          </MUIGrid>
        </div>
      )}
      {showGrid ? (
        <>
          <div style={{ height: '100%', zIndex: 1 }}>
            <Grid
              onSelectionRowsChange={onSelectionChange}
              buttonSelectionText={'Send Release Request'}
              columns={activeProtectData.columns}
              rows={activeProtectData.rows}
              count={activeProtectData.count}
              path={activeProtectData.path}
              isMoreButton={activeProtectData.isMoreButton}
              isCSVDownloadable={activeProtectData.isCSVDownloadable}
              // columnPicker={activeProtectData.columnPicker}
              // showColumnPicker={activeProtectData.showColumnPicker}
              identifier={activeProtectData.identifier}
              page={props.page}
            />
          </div>
          {showPopup && (
            <PopUp setVisible={setShowPopup} visible={showPopup}>
              <ReleaseRequest setVisible={setShowPopup} visible={showPopup} data={popupData} />
            </PopUp>
          )}
        </>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}
