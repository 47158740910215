import { Image } from 'common/components/image/Image';
import './SSO.scss';
import { env } from 'env';

interface IKeys {
  [key: string]: string;
}

interface IRadioButton {
  toggle?: boolean;
  list: string[];
  samlIdp?: string;
}

export function SSO(props: IRadioButton) {
  let isExsit: any = [];

  //@description: Adding text by icon type
  function textBySSOIcon(key: string) {
    let headersKey: IKeys = {
      'microsoft-graph': 'Continue With Microsoft',
      saml: 'Continue With SAML'
    };
    return headersKey[key];
  }

  const getNextPath = () => {
    const nextPath = window.location.href.split('next=')[1];
    if (nextPath && nextPath.includes('http')) {
      return nextPath;
    }
    const nextLocation = window.location.origin + nextPath;
    return nextLocation;
  };

  const getLink = (sso: string) => {
    switch (sso) {
      case 'microsoft-graph':
        return `${env.REACT_APP_BASE_URL}auth/social/login/${sso}/?next=${encodeURIComponent(getNextPath())}`;
      case 'saml':
        return `${env.REACT_APP_BASE_URL}auth/social/login/${sso}/?idp=${props.samlIdp}&next=${encodeURIComponent(
          getNextPath()
        )}`;
    }
  };

  return (
    <>
      {props.list.length !== 0 &&
        props.list.map((key_sso: string, index: number) => {
          if (isExsit.indexOf(key_sso) === -1) {
            isExsit.push(key_sso);
            return (
              <div key={index} className="SSO-button-wrapper">
                {textBySSOIcon(key_sso) && (
                  <a className="SSO_button-scope" href={getLink(key_sso)}>
                    {key_sso === 'microsoft-graph' && <Image icon={key_sso} alt={key_sso} type="svg" />}
                    <div className="SSO_text">{textBySSOIcon(key_sso)}</div>
                  </a>
                )}
              </div>
            );
          }
          return '';
        })}
    </>
  );
}
