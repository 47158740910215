import { useEffect, useState } from 'react';
import { updateHeader } from 'common/services/common.service';
import { Grid } from 'common/components/grid/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { removeAllRows, setFields, setInitialPath } from 'features/GridSlice';
import {
  getAccountInfo,
  togglEditModal,
  toggleActivateModal,
  toggleDeactivateModal,
  toggleErrorModal
} from 'features/MSSPSlice';
import { RestApi } from 'common/services/rest-api.service';
import { IRootColumnPicker } from 'common/interfaces/interface';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';
import { PopSide } from 'common/components/PopSide/PopSide';
import { AddAccount } from 'common/components/AddAccount/AddAccount';
import { AddAccountButton } from 'common/components/AddAccountButton/AddAccountButton';
import { EditAccount } from 'common/components/EditAccount/EditAccount';
import { DeactivateAccount } from 'common/components/EditAccount/DeactivateAccount/DeactivateAccount';
import { ErrorMessage } from 'common/components/AddAccount/ErrorMessage/ErrorMessage';
import { ActivateAccount } from 'common/components/EditAccount/ActivateAccount/ActivateAccount';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { IReduxState } from 'app/store';

const PAGE_NAME = 'Accounts';

const Accounts = (props: any) => {
  const [data, setData] = useState<any>(initData());
  const [showAddAccountModal, setShowAddAccountModal] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const ldClient = useLDClient();
  const { isLoggedIn } = useSelector((state: any) => state.login);
  const { showEditModal, showDeactivateModal, showErrorModal, showActivationModal } = useSelector(
    (state: any) => state.mssp
  );
  const { user } = useSelector((state: IReduxState) => state.login);

  const flags = useFlags();

  useEffect(() => {
    if (ldClient) {
      if (user.mssp_company_name && user.mssp_company_hash) {
        ldClient.identify({ key: user.mssp_company_name }, user.mssp_company_hash);
      } else if (user.company_name && user.company_hash) {
        ldClient.identify({ key: user.company_name }, user.company_hash);
      }
    }
    return () => {
      if (ldClient && user.company_name && user.company_hash) {
        ldClient.identify({ key: user.company_name }, user.company_hash);
      }
    };
  }, [user, ldClient]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      updateHeader.sendHeaderText(PAGE_NAME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  function initData(): any {
    return {
      rows: [],
      // columnPicker: [],
      // showColumnPicker: false,
      isMoreButton: true,
      isCSVDownloadable: false,
      count: 0,
      columns: [],
      selectTab: '',
      rootPath: '',
      identifier: ''
    };
  }

  const getData = () => {
    setData(initData());
    dispatch(removeAllRows());

    RestApi.getData('mssp/sub-account/columns/').subscribe((columns: IRootColumnPicker) => {
      let fields = ColumnsManage.checkGridColumnsToDisplay(columns.data);
      dispatch(setFields(fields));
      dispatch(setInitialPath('mssp/sub-account/'));
      dispatch(setColumnPicker(columns.data));
      dispatch(setShowColumnPicker(columns.is_displayable));

      setData({
        rows: [],
        showColumnPicker: columns.is_displayable,
        isMoreButton: columns.is_more_button,
        isCSVDownloadable: columns.is_csv_downloadable,
        count: 0,
        selectTab: '',
        identifier: columns.identifier_field,
        columns: ColumnsManage.createColumns(columns.identifier_field, columns.data || [], {
          page: 'mssp',
          category: '',
          tab: ''
        }),
        rootPath: 'mssp/sub-account/'
      });
    });
  };

  const onRowDoubleClick = (params: GridRowParams) => {
    dispatch(getAccountInfo(params.row.name));
    dispatch(togglEditModal());
  };

  return (
    <div className="sub-layout-pages">
      <div style={{ height: '100%', zIndex: 1 }}>
        {!!data.columns.length && (
          <Grid
            elementSelect={undefined}
            columns={data.columns}
            rows={data.rows}
            count={data.count}
            path={data.rootPath}
            onRowDoubleClick={onRowDoubleClick}
            addedActions={(props: any) => {
              if (!flags.isMsspAddAccount) {
                return null;
              } else {
                return (
                  <AddAccountButton
                    onClick={() => {
                      setShowAddAccountModal(true);
                    }}
                  />
                );
              }
            }}
            isMoreButton={data.isMoreButton}
            isCSVDownloadable={data.isCSVDownloadable}
            identifier={data.identifier}
            initialState={{
              pinnedColumns: { right: ['mssp_login_button'] }
            }}
          />
        )}
      </div>
      {showAddAccountModal && (
        <PopSide visible={showAddAccountModal} onHide={() => setShowAddAccountModal(false)}>
          <AddAccount onClose={() => setShowAddAccountModal(false)} />
        </PopSide>
      )}
      {showEditModal && (
        <PopSide visible={showEditModal} onHide={() => dispatch(togglEditModal())} className="edit-account">
          <EditAccount onClose={() => dispatch(togglEditModal())} />
        </PopSide>
      )}
      <DeactivateAccount visible={showDeactivateModal} onCancel={() => dispatch(toggleDeactivateModal())} />
      <ErrorMessage visible={showErrorModal} onClose={() => dispatch(toggleErrorModal(''))} />
      <ActivateAccount visible={showActivationModal} onCancel={() => dispatch(toggleActivateModal())} />
    </div>
  );
};

export default Accounts;
