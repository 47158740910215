import React from 'react';
import { ManageComponents } from 'common/components/summary/manageComponents';
import './sections.scss';

export function Sections(props: any) {
  return (
    <>
      {props.sections.length !== 0 &&
        props.sections.map((item: any, index: number) => (
          <React.Fragment key={index}>{ManageComponents.switchComponents(item)}</React.Fragment>
        ))}
    </>
  );
}
