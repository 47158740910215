import { useEffect, useRef, useState } from 'react';
import { ASMWidget, ASMLegend, ASMTableView, Text } from '@cyberpion/cyberpion-ui';
import classNames from 'classnames';
import { IconExpand } from '../Icons/IconExpand/IconExpand';
import { IconReduce } from '../Icons/IconReduce/IconReduce';
import { RestApi } from '../../services/rest-api.service';
import { AppLoading } from '../appLoading/appLoading';
import { Button } from '../Button/Button';
import { ToggleButtons } from '../ToggleButtons/ToggleButtons';
import { fetchASMRadar } from './sevice.api';

import './ASMWrapper.scss';
import { useSelector } from 'react-redux';

export function ASMWrapper(props: any) {
  const ref = useRef<HTMLDivElement>(null);
  const [expand, setExpand] = useState<boolean>(props.isExpand);
  const [showLegend, setShowLegend] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<string>('graph'); // graph | table
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const { globalFilter } = useSelector((state: any) => state.general);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    if (expand) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [expand]);

  useEffect(() => {
    setLoading(true);
    async function fetchAndEnrichedASMRadar(configJson: any) {
      const data = await fetchASMRadar(configJson, globalFilter);
      setTimeout(() => {
        setData(data);
      }, 1000);
    }

    RestApi.getData('configuration/asm/').subscribe((response: any) => {
      fetchAndEnrichedASMRadar(response);
    });
  }, [globalFilter]);

  useEffect(() => {
    if (!!data && Object.keys(data).length) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [data]);

  const onToggle = (selectedOption: string) => {
    setCurrentView(selectedOption.split(' ')[0].toLowerCase());
  };

  const handleClickOutside = (event: Event) => {
    const clickedElement: Node | any = event.target;
    if (ref.current && !ref.current.contains(clickedElement) && !clickedElement.closest('.Button')) {
      setShowLegend(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  return (
    <div className={classNames('asm-wrapper', currentView, { expand: expand })}>
      <div className={classNames('asm-legend-wrapper', { show: showLegend })} ref={ref}>
        <ASMLegend />
      </div>
      <header>
        <Text textSize={13} textColor="#5D6576" weight={500}>
          Threat Exposure Radar
        </Text>
        <div className="actions">
          <ToggleButtons options={['Graph View', 'Table View']} onToggle={onToggle} />
          <Button
            text="Legend"
            type="button"
            buttonStyle="secondary"
            size="small"
            onClick={() => setShowLegend(!showLegend)}
            addArrow
          />
          <div className="size-change" onClick={() => setExpand(!expand)}>
            {expand ? <IconExpand /> : <IconReduce />}
          </div>
        </div>
      </header>
      <div className={currentView} style={{ backgroundImage: `url('/assets/images/pixel.svg')` }}>
        <div className={classNames('gradient', { show: !loading })}></div>
        <div className={classNames('top-gradient', { show: !loading })}></div>
        {!loading ? (
          <div className="content-wrapper">
            <div className={classNames('widget-wrapper', { show: currentView === 'graph' })}>
              <ASMWidget items={data} />
            </div>

            <div className="scrollbar-common">
              <div className={classNames('table-wrapper', { show: currentView === 'table' })}>
                <div style={{ height: '100%', overflow: 'auto' }}>
                  <ASMTableView items={data} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <AppLoading />
        )}
      </div>
    </div>
  );
}
