import { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { RestApi } from 'common/services/rest-api.service';
import * as EmailValidator from 'email-validator';
import { Common } from 'common/services/common.service';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { useDispatch } from 'react-redux';
import { CustomSwitch } from 'common/components/CustomSwitch/CustomSwitch';
import { showSuccess, showError } from 'features/AlertSlice';
import { Collapse as MuiCollapse } from '@mui/material';
import classNames from 'classnames';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { IconVisibility, IconVisibilityOff, iconTrash } from '../../Integration/Integration';
import { Text } from '@cyberpion/cyberpion-ui';
import { loadList } from 'features/IntegrationsSlice';

interface IJiraBlock {
  integration: any;
  isOpen: boolean;
  setIsOpen: () => void;
  setValue: (key: string, value: any) => void;
  deleteIntegrationOnClick: (ev: any) => void;
  clickedSubmit: boolean;
  activeOnChange: (active: string, id: string) => void;
  serverError?: any;
}

const JiraBlock: React.FC<IJiraBlock> = ({
  integration,
  setValue,
  isOpen,
  setIsOpen,
  deleteIntegrationOnClick,
  clickedSubmit,
  activeOnChange,
  serverError
}) => {
  const [isActive, setIsActive] = useState(integration.status === 'active');
  const [visibility, setVisibility] = useState(false);
  const [errors, setErrors] = useState({
    configuration_name: false,
    server_url: false,
    username: false,
    password: false,
    project: false
  });

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (!!integration) {
      setIsActive(integration.status === 'active');
    }
  }, [integration]);

  const onChange = (e: React.ChangeEvent, isChecked: boolean) => {
    setIsActive(isChecked);
    RestApi.setData(
      `${Paths[PathNames.integrationJira]}${integration.id}/`,
      { status: isChecked ? 'active' : 'inactive' },
      'PATCH'
    ).subscribe(
      (response: any) => {
        activeOnChange(isChecked ? 'active' : 'inactive', integration.id);
        dispatch(loadList());
        dispatch(showSuccess(`Integration ${isChecked ? 'Activated' : 'Deactivated'} Successfully`));
      },
      error => {
        setIsActive(!isChecked);
        dispatch(showError('Something went wrong'));
      }
    );
  };

  const deleteBeforeApplyOnClick = (ev: any) => {
    deleteIntegrationOnClick(ev);
  };

  const checkFieldIsValidation = (field: any) => {
    switch (field) {
      case 'configuration_name':
        return integration.configuration_name.length > 2;
      case 'server_url':
        return Common.validateURL(integration?.configuration.server_url);
      case 'username':
        return EmailValidator.validate(integration.configuration.username);
      case 'password':
        return integration.configuration.password.length > 2;
      case 'project':
        return integration.configuration.project.length > 1;
      default:
        return false;
    }
  };

  const handleOnBlur = (type: string) => () => {
    const valid =
      (type === 'configuration_name' ? integration[type] === '' : integration.configuration[type] === '') ||
      checkFieldIsValidation(type);
    setErrors({ ...errors, [type]: !valid });
  };

  return (
    <div className="jira-group">
      <div className="jira-group-headline">
        <div style={{ position: 'relative', top: 2 }} onClick={setIsOpen} className="chevron-icon">
          <IconArrow toggleRotate={isOpen} />
        </div>
        {integration.configuration_name !== '' ? (
          <Text textSize={13} weight={500} style={{ flexGrow: 1, paddingLeft: 8 }}>
            {integration.configuration_name}
          </Text>
        ) : (
          <Text textSize={13} weight={500} textColor="#8C909A" style={{ flexGrow: 1, paddingLeft: 8 }}>
            New Integration
          </Text>
        )}
        {!!integration.id && <CustomSwitch checked={isActive} onChange={onChange} />}
        <div className="delete-button-block" onClick={deleteBeforeApplyOnClick}>
          {iconTrash}
        </div>
      </div>

      <MuiCollapse in={isOpen} timeout="auto" unmountOnExit>
        <div className={classNames('text-field-wrapper', { active: isActive }, { inActive: !isActive })}>
          {!integration.id && (
            <>
              <TextField
                fullWidth
                label="Integration name"
                id="outlined-start-adornment"
                className="input"
                value={integration.configuration_name}
                onChange={e => setValue('configuration_name', e.target.value)}
                type={'text'}
                onFocus={(ev: any) => ev.target.select()}
                sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
                inputProps={{
                  autoComplete: 'new-password',
                  onBlur: handleOnBlur('configuration_name')
                }}
                error={!!errors.configuration_name || (!checkFieldIsValidation('configuration_name') && clickedSubmit)}
              />
              <TextField
                fullWidth
                label="JIRA Server Base URL"
                className="input"
                value={integration.configuration.server_url}
                onChange={e => setValue('server_url', e.target.value)}
                type={'text'}
                onFocus={(ev: any) => ev.target.select()}
                sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
                inputProps={{
                  autoComplete: 'new-password',
                  onBlur: handleOnBlur('server_url')
                }}
                error={!!errors.server_url || (!checkFieldIsValidation('server_url') && clickedSubmit)}
              />
            </>
          )}
          {!!integration.id && (
            <div style={{ marginBottom: 10 }}>
              <Text textSize={12} weight={500} style={{ marginTop: 20 }}>
                Jira server
              </Text>
              <Text textColor="#5D6576" family="Rubik" textSize={12} style={{ marginTop: 8 }}>
                {integration.configuration.server_url}
              </Text>
            </div>
          )}
          <TextField
            fullWidth
            label="JIRA Email"
            className="input"
            value={integration.configuration.username}
            onChange={e => setValue('username', e.target.value)}
            type={'text'}
            onFocus={(ev: any) => ev.target.select()}
            sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
            inputProps={{
              autoComplete: 'new-password',
              onBlur: handleOnBlur('username')
            }}
            error={!!errors.username || (!checkFieldIsValidation('username') && clickedSubmit)}
          />
          <div style={{ position: 'relative' }}>
            <TextField
              fullWidth
              label="JIRA API Token"
              className="input"
              value={integration.configuration.password}
              onChange={e => setValue('password', e.target.value)}
              type={!!visibility ? 'text' : 'password'}
              onFocus={(ev: any) => ev.target.select()}
              sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
              autoComplete="off"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off'
                },
                onBlur: handleOnBlur('password')
              }}
              error={!!errors.password || (!checkFieldIsValidation('password') && clickedSubmit)}
            />
            {!integration.id && !!integration.configuration.password && (
              <div className="icon-visibility" onClick={() => setVisibility(!visibility)}>
                {!!visibility ? IconVisibilityOff : IconVisibility}
              </div>
            )}
          </div>
          {!integration.id && (
            <TextField
              fullWidth
              label="JIRA Project Key"
              className="input"
              value={integration.configuration.project}
              onChange={e => setValue('project', e.target.value)}
              type={'text'}
              onFocus={(ev: any) => ev.target.select()}
              sx={{ '& .Mui-error': { color: 'red' }, '& .MuiFormHelperText-root': { fontSize: 11 } }}
              inputProps={{
                autoComplete: 'new-password',
                onBlur: handleOnBlur('project')
              }}
              error={!!errors.project || (!checkFieldIsValidation('project') && clickedSubmit)}
            />
          )}
          {!!integration.id && (
            <div style={{ marginBottom: 10 }}>
              <Text textSize={12} weight={500} style={{ marginTop: 20 }}>
                Jira project key
              </Text>
              <Text textColor="#5D6576" family="Rubik" textSize={12} style={{ marginTop: 8 }}>
                {integration.configuration.project}
              </Text>
            </div>
          )}
        </div>
      </MuiCollapse>
    </div>
  );
};

export default JiraBlock;
