export function IconSortOrder({ active }: { active: boolean }) {
  let color = active ? '#3455DD' : '#5D6576';

  return (
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.0451 3.77148L4.31693 0.499664L7.58875 3.77148"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.3184 0.501257L4.3184 7.86398" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
