import React, { useState } from 'react';
import './CollapsedList.scss';
import classNames from 'classnames';

const CollapsedList = ({ list, className }: any) => {
  const [expand, setExpand] = useState<boolean>(false);
  const printKeyValue = (obj: any) => {
    const _html: JSX.Element[] = [];
    Object.keys(obj).forEach((item: string) => {
      _html.push(
        <li>
          <span>{item}:</span> {obj[item]}
        </li>
      );
    });
    return _html;
  };

  return (
    <div className={classNames('CollapsedList', className)}>
      <ul className={classNames({ expand: expand })}>{printKeyValue(list)}</ul>
      {Object.keys(list).length > 2 ? (
        <span onClick={() => setExpand(!expand)}>{expand ? 'Collapse' : 'Show All'}</span>
      ) : (
        ''
      )}
    </div>
  );
};

export default CollapsedList;
