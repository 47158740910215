import { useState, useCallback } from 'react';
import { RestApi, addHeaders } from 'common/services/rest-api.service';
import { env } from 'env';

export const useLogin = (setConfirmError?: any) => {
  const [isValidUser, setIsValidUser] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [isPasswordSaved, setIsPasswordChanged] = useState<boolean>(false);
  const [isForgotPasswordValidEmail, setIsForgotPasswordValidEmail] = useState<boolean>(false);
  const [userToken, setUserToken] = useState<string | null>(null);

  const validateUser = useCallback((token: string) => {
    setIsLoading(true);
    const url = `auth/activate_user/${token}/`;
    RestApi.getData(url).subscribe(
      (response: any) => {
        setUserToken(token);
        setIsValidUser(true);
        setIsLoading(false);
      },
      error => {
        setUserToken(token);
        setIsValidUser(false);
        setIsLoading(false);
      }
    );
  }, []);

  const manageError = async (response: any) => {
    if (!response.ok && (response.status === 400 || response.status === 401)) {
      const errors = await response.json();
      const responseError = {
        statusText: response.status,
        errors: errors
      };
      throw responseError;
    }
    return response;
  };

  const changeUserPassword = async (password: string, isNewUser: boolean) => {
    const path = isNewUser
      ? `${env.REACT_APP_BASE_URL}auth/activate_user/${userToken}/`
      : `${env.REACT_APP_BASE_URL}auth/forgot_password/${userToken}/`;

    await fetch(path, {
      method: 'PATCH',
      mode: 'cors',
      headers: addHeaders('PATCH'),
      credentials: 'include',
      body: JSON.stringify({
        password
      })
    })
      .then(manageError)
      .then(response => {
        setConfirmError([]);
        setIsPasswordChanged(true);
      })
      .catch(({ errors }) => {
        setConfirmError(errors);
        setIsPasswordChanged(false);
      });
  };

  const forgotPasswordValidateEmail = async (email: string) => {
    setIsLoading(true);

    let url = `auth/forgot_password/`;
    RestApi.setData(url, { email: email }, 'POST').subscribe(
      (response: any) => {
        setIsForgotPasswordValidEmail(true);
        setIsLoading(false);
      },
      error => {
        setIsForgotPasswordValidEmail(true);
        setIsLoading(false);
      }
    );
  };

  const forgotPasswordValidateToken = async (token: string) => {
    const url = `${env.REACT_APP_BASE_URL}auth/forgot_password/${token}`;
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: addHeaders('GET'),
      credentials: 'include'
    })
      .then(manageError)
      .then(response => {
        setUserToken(token);
        setIsValidUser(true);
      })
      .catch(({ errors }) => {
        setUserToken(token);
        setIsValidUser(false);
      });
    return res;
  };

  return {
    isLoading,
    isValidUser,
    isPasswordSaved,
    isForgotPasswordValidEmail,
    validateUser,
    changeUserPassword,
    forgotPasswordValidateEmail,
    forgotPasswordValidateToken
  } as const;
};
