import './headerForm.scss';
export function HeaderForm(props: any) {
  return (
    <div className="headerForm-dashboard-header-scope">
      <div className="headerForm-dashboard-label-scope">
        {props.IconComponent && <props.IconComponent active={true} />}
        <div className="headerForm-dashboard-label">{props.label}</div>
      </div>
      {/* <div className="">{props.filter.label}</div> */}
    </div>
  );
}
