import './ConfirmedMessage.scss';
import { Button } from '@cyberpion/cyberpion-ui';

export function ConfirmedMessage(props: any) {
  return (
    <div className="ConfirmedMessage">
      <img src="/assets/images/seed.svg" alt={'Confirmed'} />
      <p>
        We are fetching the account seed data. It might take up to 60 minutes. We will need your input once the data is
        in place
      </p>
      <Button text={'Close this window'} onClick={props.onClose} buttonStyle="secondary" size="medium" type="button" />
    </div>
  );
}
