import './AddAccountButton.scss';
import { Button } from '@cyberpion/cyberpion-ui';

export function AddAccountButton(props: any) {
  return (
    <div className="AddAccountButton">
      <Button text="Add Account" type="button" size="small" buttonStyle="main" onClick={props.onClick}></Button>
    </div>
  );
}
