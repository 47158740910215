// declare type Satus = 'primary' | 'success' | 'warning' | 'danger' | 'info';
declare type Satus = 'success' | 'warning' | 'danger';

class TosterService {
  public stack: any;
  public toastr: any;
  constructor() {
    this.stack = document.querySelector('.cp-toastr-stack');
  }

  onShowToster(message: any, satus: Satus, _code = '') {
    let toastr = document.createElement('DIV');
    toastr.className = 'cp-toastr';

    let card = document.createElement('DIV');
    card.className = `card-toastr ${satus}`;

    let iconSatusTosterScope = document.createElement('DIV');
    iconSatusTosterScope.className = 'img-toastr';

    let iconSatus = new Image();
    iconSatus.src = `/assets/images/toster-${satus}.svg`;
    iconSatusTosterScope.appendChild(iconSatus);

    let bodyToastr = document.createElement('DIV');
    bodyToastr.className = 'card-body-toastr';

    let sectionTextToastr = document.createElement('DIV');
    sectionTextToastr.className = 'section-text-toastr';
    sectionTextToastr.innerText = message;

    setTimeout(() => {
      toastr.classList.add('cp-show-toastr');
    }, 0);

    let code = document.createElement('DIV');
    code.className = 'cp-code-toastr';
    code.innerText = _code;

    bodyToastr.appendChild(sectionTextToastr);
    card.appendChild(iconSatusTosterScope);
    card.appendChild(bodyToastr);

    card.appendChild(code);

    let tosterBackground = new Image();
    tosterBackground.src = `/assets/images/toster-background.svg`;
    tosterBackground.className = 'cp-toster-background';
    card.appendChild(tosterBackground);

    let timer = this.remove(toastr);

    let close = document.createElement('DIV');
    close.className = 'close-toastr';
    close.innerText = '+';
    close.onclick = () => {
      clearTimeout(timer);
      toastr.remove();
    };

    toastr.onmouseover = () => {
      clearTimeout(timer);
    };

    toastr.onmouseout = () => {
      timer = this.remove(toastr);
    };

    card.appendChild(close);
    toastr.appendChild(card);
    this.stack.appendChild(toastr);
  }

  remove(el: any) {
    return setTimeout(() => {
      el.remove();
    }, 3000);
  }

  showError(error: any) {
    let _error = JSON.parse(error.message);
    if (Array.isArray(_error)) {
      _error = _error[0];
    }
    if (_error.non_field_errors && _error.non_field_errors[0].includes('unique set')) {
      this.onShowToster(`The value you are trying to add is already exists`, 'danger', '');
      return;
    }
    _error = _error[Object.keys(_error)[0]];
    if (Array.isArray(_error[0])) {
      _error = _error[0][0];
    }
    this.onShowToster(`Error: ${_error}`, 'danger', '');
  }
}

export const tosterService = new TosterService();
