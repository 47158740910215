import { IDropDown, IField, TimeIntervalOptions } from '../../config';

export interface ISplunk {
  action_items_is_active: boolean;
  action_items_push_interval: number;
  is_active: boolean;
  splunk_server: string;
  unlinked_ips_is_active: boolean;
  unlinked_ips_push_interval: number;
  hec_token: string;
  id?: number | string;
}

export const FIELDS: IField[] = [
  {
    label: 'HEC (HTTP Event Collector) Token',
    field: 'hec_token'
  },
  {
    label: 'Splunk Server',
    field: 'splunk_server'
  }
];

export const DROP_DOWNS: IDropDown[] = [
  {
    label: 'Action Item',
    options: TimeIntervalOptions,
    activationField: 'action_items_is_active',
    valueField: 'action_items_push_interval'
  },
  {
    label: 'Unlinked Assets',
    options: TimeIntervalOptions,
    activationField: 'unlinked_ips_is_active',
    valueField: 'unlinked_ips_push_interval'
  }
];

export const initData = () => {
  return {
    action_items_is_active: true,
    action_items_push_interval: 86400,
    is_active: false,
    hec_token: '',
    splunk_server: '',
    unlinked_ips_is_active: true,
    unlinked_ips_push_interval: 86400
  };
};
