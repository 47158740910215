import React from 'react';
export class IconExportCSV extends React.Component {
  render() {
    return (
      <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5784 14H3.52145C3.38801 14 3.26003 13.9473 3.16568 13.8536C3.07132 13.7598 3.01831 13.6326 3.01831 13.5V2.5C3.01831 2.36739 3.07132 2.24021 3.16568 2.14645C3.26003 2.05268 3.38801 2 3.52145 2H9.55955L13.0815 5.5V13.5C13.0815 13.5657 13.0685 13.6307 13.0432 13.6913C13.0179 13.752 12.9809 13.8071 12.9341 13.8536C12.8874 13.9 12.8319 13.9368 12.7709 13.9619C12.7099 13.9871 12.6444 14 12.5784 14Z"
          stroke="#5D6576"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9.55956 2V5.5H13.082" stroke="#5D6576" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.03761 8.5H10.0627" stroke="#5D6576" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.03761 10.5H10.0627" stroke="#5D6576" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }
}
