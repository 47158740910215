import React, { useEffect } from 'react';
import { Text, getColorByUrgency } from '@cyberpion/cyberpion-ui';
import { Common } from 'common/services/common.service';
import { ThreatsCenterContextType, useThreatsCenter } from 'pages/ThreatCenter/ThreatsCenterContext';
import { HashLink } from 'react-router-hash-link';
import classNames from 'classnames';
import Findings from './Findings';
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';

import './ThreatItem.scss';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';

const linkIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M8.86373 6.47737L9.57964 5.76146C10.5022 4.83889 10.5022 3.34311 9.57964 2.42055V2.42055C8.65707 1.49798 7.16129 1.49798 6.23872 2.42055L5.52281 3.13646"
      stroke="#3455DD"
      strokeLinecap="round"
    />
    <path
      d="M3.1364 5.52281L2.42049 6.23872C1.49792 7.16129 1.49792 8.65707 2.42049 9.57964V9.57964C3.34305 10.5022 4.83883 10.5022 5.7614 9.57964L6.47731 8.86373"
      stroke="#3455DD"
      strokeLinecap="round"
    />
    <line x1="4.79805" y1="4.56821" x2="7.43185" y2="7.20201" stroke="#3455DD" strokeLinecap="round" />
  </svg>
);

const ThreatItem = ({ data }: any) => {
  const {
    setSearch,
    setShowOnlyFindings,
    allData,
    timelineSelected,
    dependentData
  } = useThreatsCenter() as ThreatsCenterContextType;
  const location = useLocation();

  useEffect(() => {
    if (location.hash === `#${data.uuid}`) {
      document.getElementById(data.uuid)?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    }
  }, [location, data.uuid]);

  const textStyle = { lineHeight: 1.35 };
  const urgency = Common.getUrgencyBySeverity(data.severity);
  const urgencyColor = getColorByUrgency(urgency.type);
  const closed =
    !!dependentData && !!dependentData[data.uuid] && dependentData[data.uuid]?.closed !== 'err'
      ? dependentData[data.uuid]?.closed
      : 0;
  const tags =
    !!dependentData && !!dependentData[data.uuid] && dependentData[data.uuid]?.tags !== 'err'
      ? dependentData[data.uuid]?.tags
      : [];
  let references: string = '';
  if (!!data.references && !!data.references.length) {
    references = '<div class="references"><b>References</b></div><ul class="list">';
    data.references.forEach(
      (ref: any) => (references += `<li><a href=${ref.url} target="__blank">${ref.name}</a></li>`)
    );
    references += '</ul>';
  }
  const readMore = !!data.read_more ? `<a href=${data.read_more} target="_blank">Read More</a>` : '';

  let html = `<div>${data.description} ${readMore}</div>
    ${!!references ? references : ''}
  `;

  const handleArtifactOnClicked = () => {
    setSearch('');
    setShowOnlyFindings(false);
  };

  return (
    <div
      className={classNames('threat-center-item', {
        [`${urgency.type.toLowerCase()}`]: data?.uuid === timelineSelected
      })}
      id={data?.uuid}
    >
      <div
        className={classNames('left-indicator-line', {
          [`${urgency.type.toLowerCase()}`]: data?.uuid === timelineSelected
        })}
        style={{ backgroundColor: urgencyColor }}
      >
        <div className="round-indicator-outer">
          <div
            className={classNames('round-indicator-inner', {
              [`${urgency.type.toLowerCase()}`]: data?.uuid === timelineSelected
            })}
            style={{ border: `3px solid ${urgencyColor}` }}
          />
        </div>
      </div>
      <div
        className={classNames('threat-center-item-content', {
          [`${urgency.type.toLowerCase()}`]: data?.uuid === timelineSelected
        })}
      >
        <div className="content-top-info" style={{ padding: '0 30px' }}>
          <div className="with-bullets">
            <div className="header-bullet" style={{ display: 'flex' }}>
              <Text textColor="#60646A" style={{ display: 'flex', alignItems: 'center' }}>
                Created
              </Text>
              <Text textColor="#60646A" weight={500} style={{ display: 'flex', alignItems: 'center' }}>
                {Common.getShortDate(data.creation_time)}
              </Text>
            </div>
            <div className="header-bullet" style={{ display: 'flex' }}>
              <Text textColor="#60646A" style={{ display: 'flex', alignItems: 'center' }}>
                Last Updated
              </Text>
              <Text textColor="#60646A" weight={500} style={{ display: 'flex', alignItems: 'center' }}>
                {Common.getShortDate(data.update_time)}
              </Text>
            </div>
            <div className="header-bullet" style={{ display: 'flex' }}>
              <Text textColor="#60646A" style={{ display: 'flex', alignItems: 'center' }}>
                Source
              </Text>
              <Text textColor="#60646A" weight={500} style={{ display: 'flex', alignItems: 'center' }}>
                {data.source}
              </Text>
            </div>

            {!!closed && !data.findings_ui_url && (
              <div className={'action-items-chip'}>
                <Text textColor="#FFF">{`${closed} Action Item${closed !== 1 ? 's' : ''} Closed`}</Text>
              </div>
            )}
            {!!closed && !!data.findings_ui_url && (
              <LinkNewTab
                className={classNames('action-items-chip', { hover: !!data.findings_ui_url })}
                to={data.findings_ui_url.replace('/open?', '/closed?')}
                text={<Text textColor="#FFF">{`${closed} Action Item${closed !== 1 ? 's' : ''} Closed`}</Text>}
              />
            )}
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <div className="tags-chip-wrapper">
            {!!tags &&
              !!tags.length &&
              tags.slice(0, 3).map((tag: string, index: number) => (
                <div key={`${tag}-${index}`} className="item-tags-chip">
                  <Text textColor="#6D6D7A">{tag}</Text>
                </div>
              ))}
            {!!tags && !!tags.length && tags.length > 3 && (
              <div>
                <Tooltip
                  title={
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '5px' }}>
                      {tags.map((tag: string, index: number) => (
                        <div
                          key={`${tag}-${index}`}
                          style={{
                            width: 'fit-content',
                            marginRight: 8,
                            padding: '4px 6px',
                            borderRadius: 4,
                            border: '1px solid #dfe1e4',
                            backgroundColor: '#fff',
                            ...(tags.length !== index + 1 ? { marginBottom: 8 } : {})
                          }}
                        >
                          <Text textColor="#6D6D7A">{tag}</Text>
                        </div>
                      ))}
                    </div>
                  }
                  placement="top-start"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#FCFCFC',
                        color: '#999BA4',
                        padding: '5px 8px',
                        lineHeight: '16px',
                        fontSize: 13,
                        '& .MuiTooltip-arrow': {
                          color: '#999BA4'
                        },
                        border: '1px solid #60646A50',
                        borderRadius: 1
                      }
                    }
                  }}
                >
                  <div className="chips-tooltip">
                    <Text
                      textColor="#6D6D7A"
                      style={{
                        padding: '3px 6px',
                        backgroundColor: '#FFF',
                        borderRadius: 4,
                        border: '1px solid #e8e8e8'
                      }}
                    >
                      +{`${tags.length - 3}`}
                    </Text>
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!!data.image && (
            <div style={{ marginLeft: 30, marginRight: 0, alignSelf: 'start', marginTop: 20 }}>
              <img
                style={{ width: 55, height: 55, padding: 2, border: '1px solid #e8e8e8' }}
                src={`data:image/svg+xml;base64,${data.image}`}
                alt="icon"
              />
            </div>
          )}
          <div style={{ flexGrow: 1 }}>
            <div className="content-main">
              <div className="content-main-left" style={{ margin: '20px 40px 6px 30px' }}>
                <Text textSize={14} weight={600} upper style={{ ...textStyle, marginBottom: 6 }}>
                  {data.title}
                </Text>
                <div className={'threat-center-item-description'} dangerouslySetInnerHTML={{ __html: html }}></div>
              </div>
            </div>
            <div className="threat-center-item-artifacts-wrapper" style={{ margin: '0 32px 20px 30px' }}>
              {!!data.previous_connected_posts &&
                !!data.previous_connected_posts.length &&
                data.previous_connected_posts.map((artifact: string, index: number) => {
                  const item = allData.find((item: any) => item.uuid === artifact);
                  return !!item ? (
                    <div key={`${artifact}-${index}`} onClick={handleArtifactOnClicked}>
                      <HashLink
                        to={`#${artifact}`}
                        style={{ textDecoration: 'none' }}
                        smooth={true}
                        scroll={el => {
                          el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
                        }}
                      >
                        <div className="threat-center-item-artifacts">
                          {linkIcon}
                          <Text
                            textColor="#3455DD"
                            style={{
                              marginLeft: 5,
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              maxWidth: 240
                            }}
                          >
                            {` ${Common.getShortDate(data.creation_time)} ${item.title}`}
                          </Text>
                        </div>
                      </HashLink>
                    </div>
                  ) : null;
                })}
            </div>
          </div>
          <Findings data={data} />
        </div>
      </div>
    </div>
  );
};

export default ThreatItem;
