import React, { FC } from 'react';
import { Button } from 'common/components/Button/Button';
import { Text } from '@cyberpion/cyberpion-ui';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldsValue } from 'features/IntegrationsSlice';

import './RadioButtons.scss';

interface IRadioButtons {
  id: string;
  selected: string;
  onSelect?: (value: string) => void;
  readonly?: boolean;
}

const RadioButtons: FC<IRadioButtons> = ({ id, selected, onSelect, readonly }) => {
  const { integrationSchema } = useSelector((state: any) => state.integrations);
  const dispatch = useDispatch<any>();

  const radioButtons = integrationSchema.fields[id];
  const options = radioButtons?.choices || [];

  const handleOnSelect = (value: string) => () => {
    dispatch(setFieldsValue({ [id]: value }));
    if (onSelect) onSelect(value);
  };

  return (
    <div className="RadioButtons">
      {!!readonly ? (
        <div>
          <Text textSize={13} weight={500} style={{ marginBottom: 10 }}>
            {radioButtons?.label}
          </Text>
          <div className="input-readonly">
            <Text textColor="#5D6576" family="Rubik" textSize={13} style={{ flexGrow: 1, whiteSpace: 'nowrap' }}>
              {options.filter((option: any) => option.id === selected)[0]?.label}
            </Text>
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
          <Text weight={500} textSize={13} style={{ marginBottom: 16 }}>
            {radioButtons?.label}
          </Text>
          <div className="radio-buttons">
            {options.map((option: any, index: number) => (
              <Button
                key={`${option.id}-${index}`}
                buttonStyle=""
                size="big"
                text={option.label}
                onClick={handleOnSelect(option.id)}
                type="button"
                className={`radio-button ${index === 0 ? 'marginRight' : 'marginLeft'} ${
                  selected === option.id ? 'active' : ''
                }`}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RadioButtons;
