import React from 'react';
import { Text } from '@cyberpion/cyberpion-ui';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setDelay, showError, showSuccess } from 'features/AlertSlice';

import './IntegrationLink.scss';

const iconClipboard = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <rect
      x="1.125"
      y="3.375"
      width="7.5"
      height="7.5"
      rx="0.75"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.375 3.375V1.875C3.375 1.46079 3.71079 1.125 4.125 1.125H10.125C10.5392 1.125 10.875 1.46079 10.875 1.875V7.875C10.875 8.28921 10.5392 8.625 10.125 8.625H8.625"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface IntegrationLinkProps {
  id: string;
  title: string;
  value: string;
  is_clipboard?: boolean;
  onLink: (url: string) => void;
  onClipboardCopy?: (value: string) => void;
  className?: string;
}

const IntegrationLink: React.FC<IntegrationLinkProps> = ({
  id,
  title,
  value,
  className,
  is_clipboard = true,
  onClipboardCopy,
  onLink
}) => {
  const dispatch = useDispatch<any>();

  const onClipboard = () => {
    dispatch(setDelay(2000));
    try {
      navigator.clipboard.writeText(value);
      dispatch(showSuccess('Copied to clipboard'));
    } catch (error) {
      dispatch(showError('Failed Copy to clipboard'));
    }
    if (onClipboardCopy) {
      onClipboardCopy(value);
    }
  };

  return (
    <div id={id} className={classNames('integration-link', { [`${className}`]: !!className })}>
      {!!title && (
        <Text textSize={13} weight={500} style={{ marginBottom: 16 }}>
          {title}
        </Text>
      )}
      <div className={classNames('link-field')}>
        <div className="link" onClick={() => onLink(value)}>
          <Text textColor="#3659DE" family="Rubik" textSize={13}>
            {value}
          </Text>
        </div>
        {!!is_clipboard && (
          <div className="clipboard" onClick={onClipboard}>
            {iconClipboard}
          </div>
        )}
      </div>
      {/* {!!errorText && (
        <div className="error-text">
          <Text textSize={12} textColor="#FF0000">
            {errorText}
          </Text>
        </div>
      )} */}
    </div>
  );
};

export default IntegrationLink;
