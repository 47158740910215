import { Sections } from '../sections/Sections';
import './form.scss';
import { Header } from './header/Header';

export function Form(props: any) {
  let isNotCountAndNotLabel = props.count === null && props.label === null;

  const getSummaryClassName = (label: string): string => {
    let _className: string = isNotCountAndNotLabel ? 'summary-from-body1' : 'summary-from-body';

    if (label && label.toLowerCase() === 'org score over time') {
      _className += ' graph-container';
    }

    return _className;
  };

  return (
    <div className={isNotCountAndNotLabel ? 'summary-from-scope1' : 'summary-from-scope'}>
      {(props.count || props.label) && <Header {...props} viewArrow={false} />}
      <div className={getSummaryClassName(props.label)}>
        <Sections {...props} />
      </div>
    </div>
  );
}
