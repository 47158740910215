import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconVerizon extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7834 4L10.2955 16.0881L8.23184 11.5079H6L9.39363 19H11.1975L18 4H15.7834Z" fill="#8C909A" />
      </svg>
    );
  }
}
