import { IReduxState } from 'app/store';
import { removeDomain, removeIp, removeMobileApp, removeWhois } from 'features/GroupsSlice';
import { useDispatch, useSelector } from 'react-redux';
import './RemoveRow.scss';

export function RemoveRowLocal(props: any) {
  const { subsidiaryMode, domains } = useSelector((state: IReduxState) => state.groups);

  const dispatch = useDispatch<any>();

  const onClick = () => {
    const id = props.params.id;
    setTimeout(() => {
      switch (props.source.tab) {
        case 'domains':
          dispatch(removeDomain(id));
          break;
        case 'whois':
          dispatch(removeWhois(id));
          break;
        case 'ip-networks':
          dispatch(removeIp(id));
          break;
        case 'mobile-apps':
          dispatch(removeMobileApp(id));
          break;
      }
    }, 100);
  };

  const canDelete = (): boolean => {
    const tab = props.source.tab;
    if (
      subsidiaryMode &&
      tab === 'domains' &&
      (domains[0].value === props.params.row.value || domains[1].value === props.params.row.value)
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className="RemoveRow">
      {canDelete() && <img alt="delete user" src="/assets/images/trash.svg" onClick={onClick} />}
    </div>
  );
}
