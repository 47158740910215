import React, { FC } from 'react';
import { Text } from '@cyberpion/cyberpion-ui';
import ThreatItem from '../ThreatItem/ThreatItem';

import './ThreatsList.scss';

interface IThreatsList {
  data: any;
  title: string;
  className: string;
}

const ThreatsList: FC<IThreatsList> = ({ data, title, className }) => {
  return !!data && !!data.length ? (
    <div className={`ThreatsList ${className}`}>
      <Text
        className="threat-center-block"
        textColor="#454549"
        textSize={15}
        weight={600}
      >{`${title} (${data.length})`}</Text>
      {data.map((thread: any, index: number) => (
        <div key={index} className="threat-item">
          <ThreatItem data={thread} />
        </div>
      ))}
    </div>
  ) : null;
};

export default ThreatsList;
