import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Text } from '@cyberpion/cyberpion-ui';
import { CustomSwitch } from 'common/components/CustomSwitch/CustomSwitch';
import {
  useNotifications,
  NotificationsContextType,
  SORT_TYPE,
  NOTIFICATION_OPTIONS
} from 'pages/Settings/Notifications/NotificationsContext';
import classNames from 'classnames';
import { Common } from 'common/services/common.service';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Fade from '@mui/material/Fade';
import { Button } from 'common/components/Button/Button';
import { makeStyles } from '@material-ui/core/styles';
import RowActionMenu from 'common/components/RowActionMenu/RowActionMenu';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';

import './notification-item.scss';
// import { ToolTip } from 'common/components/toolTip/toolTip';

const useStyles = makeStyles({
  paper: {
    width: 500
  },
  contentText: {
    fontSize: 12,
    fontFamily: 'Rubik'
  },
  dialogTitle: {
    fontSize: 22,
    fontFamily: 'Rubik'
  },
  deletedNotification: {
    fontSize: 12,
    fontFamily: 'Rubik',
    fontWeight: 600
  },
  tooltip: {
    fontSize: 12,
    fontFamily: 'Rubik'
  }
});

const infoIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="5.5" transform="rotate(-180 8 8)" stroke="#FFFFFF" />
    <path d="M8 10.5L8 7.5" stroke="#FFFFFF" strokeLinecap="round" />
    <circle cx="8" cy="5.5" r="0.5" transform="rotate(-180 8 5.5)" fill="#FFFFFF" />
  </svg>
);
interface INotificationItem {
  notification: any;
}
const NotificationItem: React.FC<INotificationItem> = ({ notification }) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isActive, setIsActive] = useState<boolean | null>(null);
  const [integrationUrl, setIntegrationUrl] = useState<any>(null);

  const {
    sortBy,
    selectedNotification,
    isOpen,
    onSwitchNotificationItem,
    onDeleteNotification,
    setIsOpen,
    onNotificationClick,
    onNotificationItemMenuClick
  } = useNotifications() as NotificationsContextType;
  const { list } = useSelector((state: IReduxState) => state.integrations);

  const classes = useStyles();

  useEffect(() => {
    if (!!notification) {
      const isNotificationActive = notification?.status.status === 0;
      const isIntegrationActive = !!notification.configuration_status && notification.configuration_status === 'active';

      setIsActive(
        !!notification.configuration_status ? isNotificationActive && isIntegrationActive : isNotificationActive
      );
    }
  }, [notification]);

  useEffect(() => {
    if (!!list?.results?.length && !!notification?.configuration_id) {
      const integration = list.results.find((integration: any) => integration.id === notification.configuration_id);
      if (integration) {
        setIntegrationUrl(`/pages/settings/integrations/?openCategory=${integration.type}`);
      }
    }
  }, [list, notification]);

  const handleSwitch = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    onSwitchNotificationItem(notification.id, !isActive);

    if (selectedNotification?.id === notification.id && isOpen) {
      setIsOpen(false);
    }
    setIsActive(active => !active);
  };

  const handleSwitchWrapper = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleMenuItemClick = (rowData: any, option: any, type: string, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (option.id === 'delete') {
      setShowDeletePopup(true);
    }
    if (option.id === 'edit') {
      onNotificationItemMenuClick(rowData, 'edit');
    }
  };

  const handleNotificationClick = (ev: any) => {
    onNotificationClick(notification);
  };

  const handleDialogOnClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowDeletePopup(false);
  };

  const handleDialogOnDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowDeletePopup(false);
    onDeleteNotification(notification.id);
  };

  // const itemMenu = [
  //   { id: 'edit', label: 'Edit', icon: <IconPen /> },
  //   { id: 'delete', label: 'Delete', icon: <img alt="delete notification" src="/assets/images/trash.svg" /> }
  // ];

  const deleteDialog = (
    <Fade in={showDeletePopup}>
      <Dialog
        open={showDeletePopup}
        keepMounted
        onClose={handleDialogOnClose}
        aria-describedby="alert-dialog-delete-notification"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle classes={{ root: classes.dialogTitle }}>{'Delete Notification'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-delete-notification" classes={{ root: classes.contentText }}>
            Clicking 'Delete' button will permanently delete the notification
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-delete-notification"
            classes={{ root: classes.deletedNotification }}
            sx={{ paddingTop: 1 }}
          >
            {notification.label}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type={'button'}
            onClick={handleDialogOnClose}
            text="Cancel"
            buttonStyle="main"
            size="small"
            dataTestId="delete-notification-dialog-cancel"
          />
          <Button
            type={'button'}
            onClick={handleDialogOnDelete}
            text="Delete"
            buttonStyle="main"
            size="small"
            dataTestId="delete-notification-dialog-delete"
          />
        </DialogActions>
      </Dialog>
    </Fade>
  );

  return (
    <Grid
      container
      className={classNames('notification-item', { active: isActive })}
      sx={{
        height: !!notification.configuration_status && notification.configuration_status === 'inactive' ? 105 : 75,
        alignItems: 'center'
      }}
      onClick={handleNotificationClick}
      data-testid={`notification-item-${notification.id}`}
    >
      <Grid item>
        <Grid container sx={{ height: 75, alignItems: 'center', flexWrap: 'nowrap' }}>
          <Grid item>
            <Grid
              item
              className={classNames(
                'selected-notification-item',
                {
                  active: selectedNotification?.id === notification?.id
                },
                {
                  'integration-type':
                    !!notification.configuration_status && notification.configuration_status === 'inactive'
                }
              )}
            />
          </Grid>
          <Grid item>
            <Grid container flexDirection="column">
              {!!notification.configuration_status && notification.configuration_status === 'inactive' && (
                <Grid item className="channel-disabled">
                  <Grid container alignItems="center">
                    <Grid item>
                      <Text textSize={12} textColor="#FFFFFF">
                        Notification channel is disabled
                      </Text>
                    </Grid>
                    <Grid item sx={{ marginLeft: 1 }}>
                      <Tooltip
                        title={
                          <div>
                            <div style={{ marginBottom: 10 }}>
                              This notification is currently disabled and will not be sent until reactivation of the
                              related integration
                            </div>
                            <Link to={integrationUrl} className="tooltip-integrations">
                              Go to integrations
                            </Link>
                          </div>
                        }
                        placement="bottom-start"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: '#202022',
                              color: '#999BA4',
                              padding: '5px 8px',
                              lineHeight: '16px',
                              fontSize: 13,
                              '& .MuiTooltip-arrow': {
                                color: '#999BA4'
                              },
                              border: '2px solid black',
                              borderRadius: 1
                            }
                          }
                        }}
                      >
                        <div style={{ paddingLeft: 3 }}>{infoIcon}</div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item sx={{ flexWrap: 'nowrap', ...(isActive ? { opacity: 1 } : { opacity: 0.5 }) }}>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container>
                      {sortBy.by !== SORT_TYPE.TYPE && (
                        <Grid item>
                          {
                            NOTIFICATION_OPTIONS[notification.notification_type as keyof typeof NOTIFICATION_OPTIONS]
                              ?.icon
                          }
                        </Grid>
                      )}
                      <Grid
                        item
                        sx={sortBy.by !== SORT_TYPE.TYPE ? { paddingLeft: 1.5 } : {}}
                        className={classNames(
                          { 'notification-item-name-open': isOpen },
                          { 'notification-item-name-close': !isOpen }
                        )}
                      >
                        <Tooltip title={notification.name} placement="top-start" classes={{ tooltip: classes.tooltip }}>
                          <div>
                            <Text textSize={13} weight={500}>
                              {notification.name}
                            </Text>
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ paddingTop: 1 }}>
                    {isOpen && (
                      <Grid container sx={{ flexWrap: 'nowrap' }}>
                        <Grid item sx={{ paddingRight: 2, borderRight: '1px solid #8C909A', whiteSpace: 'nowrap' }}>
                          <Text>{`${notification.created_by}`}</Text>
                        </Grid>
                        <Grid item sx={{ padding: '0 16px', borderRight: '1px solid #8C909A', whiteSpace: 'nowrap' }}>
                          <Tooltip
                            title={`Creation date:  ${Common.getShortDate(notification.created_at)}`}
                            placement="top-start"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <div>
                              <Text>{`${Common.getShortDate(notification.created_at)}`}</Text>
                            </div>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            padding: '0 16px'
                          }}
                        >
                          <Tooltip
                            title={`Last edited: ${Common.getShortDate(notification.updated_at)}`}
                            placement="top-start"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <div>
                              <Text>{`${Common.getShortDate(notification.updated_at)}`}</Text>
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )}
                    {!isOpen && (
                      <Grid container sx={{ flexWrap: 'nowrap' }}>
                        <Grid item sx={{ paddingRight: 1.5, borderRight: '1px solid #8C909A', whiteSpace: 'nowrap' }}>
                          <Text>{`Created by: ${notification.created_by}`}</Text>
                        </Grid>
                        <Grid item sx={{ padding: '0 12px', borderRight: '1px solid #8C909A', whiteSpace: 'nowrap' }}>
                          <Text>{`Creation date:  ${Common.getShortDate(notification.created_at)}`}</Text>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            padding: '0 12px'
                          }}
                        >
                          <Text>{`Last edited: ${Common.getShortDate(notification.updated_at)}`}</Text>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="sticky-action" sx={{ backgroundColor: '#FFFFFF' }}>
        <Grid container alignItems="center" sx={{ flexWrap: 'nowrap' }}>
          <Grid
            item
            sx={{ paddingRight: 0.5 }}
            className={classNames({
              'switch-disabled': !!notification.configuration_status && notification.configuration_status === 'inactive'
            })}
          >
            <div onClick={handleSwitchWrapper} data-testid={`notification-item-switch-${notification.id}`}>
              <CustomSwitch checked={isActive} onChange={handleSwitch} id={notification.id} />
            </div>
          </Grid>
          <RowActionMenu
            rowData={notification}
            itemOnClick={handleMenuItemClick}
            classes="notification-item-menu"
            type="notification"
          />
        </Grid>
      </Grid>
      {deleteDialog}
    </Grid>
  );
};

export default NotificationItem;
