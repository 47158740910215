import { useEffect, useState } from 'react';
import './Search.scss';

interface ISearchProps {
  onChange: (input: string) => void;
  placeholder?: boolean;
}

export function Search(props: ISearchProps) {
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    props.onChange(search.trim().toLowerCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="Search">
      <img alt="Search" src="/assets/images/search.svg" />
      <input
        id="input-search"
        type="text"
        placeholder={props.placeholder ? 'Search' : ''}
        maxLength={30}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
    </div>
  );
}
