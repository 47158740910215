export const pathManageService = {
  //@description: Checks if there is a path that needs to be repeated
  checkPathHistory() {
    let page = window.location.href.replace(window.location.origin, '');
    if (window.location.pathname === '/login') {
      return true;
    }
    if (!window.location.href.includes('next')) window.location.href = `/login/?next=${page}`;
  },
  //@description: Checks if there is an "next" parameter
  nextPath() {
    let next = window.location.href.split('next=')[1];
    let defaultPath = '/pages/dashboard';

    try {
      return next === undefined || window.location.pathname === '/login' ? defaultPath : next;
    } catch (error) {
      return defaultPath;
    }
  }
};
