import React, { useState, memo } from 'react';
import { Grid } from '@mui/material';
import CollapseButton from 'pages/Settings/Notifications/SidePanel/DropDownBox/CollapseButton/CollapseButton';
import RadioButtons from 'pages/Settings/Notifications/SidePanel/DropDownBox/RadioButtons/RadioButtons';
import Checkbox from 'pages/Settings/Notifications/SidePanel/DropDownBox/Checkbox/Checkbox';
import CheckboxImportance from 'pages/Settings/Notifications/SidePanel/DropDownBox/CheckboxImportance/CheckboxImportance';
import SliderBox from 'pages/Settings/Notifications/SidePanel/DropDownBox/SliderBox/SliderBox';
import Dates from 'pages/Settings/Notifications/SidePanel/DropDownBox/Dates/Dates';
import TextFilter from 'pages/Settings/Notifications/SidePanel/DropDownBox/TextFilter/TextFilter';
import NumberFilter from 'pages/Settings/Notifications/SidePanel/DropDownBox/NumberFilter/NumberFilter';
import NumbersListFilter from 'pages/Settings/Notifications/SidePanel/DropDownBox/NumbersListFilter/NumbersListFilter';
import StringsListFilter from 'pages/Settings/Notifications/SidePanel/DropDownBox/StringsListFilter/StringsListFilter';
import AutoCompleteWithChips from 'pages/Settings/Notifications/SidePanel/DropDownBox/AutoCompleteWithChips/AutoCompleteWithChips';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { useNotifications, NotificationsContextType } from 'pages/Settings/Notifications/NotificationsContext';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { Text } from '@cyberpion/cyberpion-ui';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
// import { REQUIRED_FILTERS } from 'pages/Settings/Notifications/SidePanel/utils';

import './DropDownBox.scss';

const useStyles = makeStyles({
  root: {
    minWidth: 30
  },
  textPrimary: {
    fontSize: 12,
    fontWeight: 400
  }
});

interface IDropdownBox {
  id: string;
  label: string;
  type: string;
  isOpen?: boolean;
  isActive?: boolean;
  isClear?: boolean;
  isError?: boolean;
}

const DropDownBox: React.FC<IDropdownBox> = ({ id, label, type, isOpen, isActive, isClear, isError }) => {
  const [open, setOpen] = useState(isOpen);
  const [isHovered, setIsHovered] = useState(false);
  const { notificationType, actionMode } = useNotifications() as NotificationsContextType;
  const {
    changedNotificationData,
    onClear,
    clickedSubmit,
    dropdownBoxHeaderText,
    dropdownBoxHeaderTooltip
  } = useNotificationConfiguration() as NotificationConfigurationContextType;

  // const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
  const isHeaderText = !!dropdownBoxHeaderText && !!dropdownBoxHeaderText[id] && !!dropdownBoxHeaderText[id].length;
  const classes = useStyles();

  const handleDropDownClick = () => {
    setOpen(show => !show);
  };

  const handleClearOnClick = (ev: any) => {
    onClear([id]);
    ev.stopPropagation();
  };

  const contentByTypes = {
    radio: <RadioButtons id={id} />,
    checkbox: <Checkbox id={id} />,
    importance: <CheckboxImportance id={id} />,
    slider: <SliderBox id={id} />,
    textFilter: <TextFilter id={id} />,
    numberFilter: <NumberFilter id={id} />,
    numbersListFilter: <NumbersListFilter id={id} />,
    stringsListFilter: <StringsListFilter id={id} />,
    inputChips: <AutoCompleteWithChips id={id} placeholder="Add Recipients" />,
    dates: <Dates id={id} />
  };

  const showClear = isClear && isHovered && isHeaderText && id !== 'type';

  let isRequired = !!clickedSubmit && !!isError;
  if (id === 'notification_type' || id === 'name') {
    isRequired = isRequired && !changedNotificationData[id];
  } else if (id === 'recipients') {
    isRequired =
      isRequired &&
      (!changedNotificationData['recipients'] || !changedNotificationData['recipients'].length) &&
      (changedNotificationData.channel_type === 0 || notificationType !== 'action_items');
  } else {
    isRequired = isRequired && changedNotificationData[id] === undefined;
  }

  return (
    <>
      <Grid
        container
        className={classNames('dropdownbox', {
          'header-selected': !!dropdownBoxHeaderText && !!dropdownBoxHeaderText[id],
          'is-required': isRequired
        })}
        direction="column"
      >
        <Grid
          item
          onClick={handleDropDownClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className={classNames('dropdownbox-header', {
            'label-wrapper': !!open
          })}
          data-testid="notifications-dropdownbox-container"
        >
          <Grid
            container
            alignItems="center"
            className={classNames('dropdownbox-label')}
            data-testid="notifications-dropdownbox-label"
          >
            <Tooltip
              interactive
              title={!!dropdownBoxHeaderTooltip && !!dropdownBoxHeaderTooltip[id] ? dropdownBoxHeaderTooltip[id] : ''}
              placement="top-start"
            >
              <Grid item sx={{ flexGrow: 1 }}>
                <Text
                  textSize={13}
                  textColor="#5D6576"
                  style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: 310 }}
                >
                  {!!dropdownBoxHeaderText && !!dropdownBoxHeaderText[id]
                    ? `${label}: ${dropdownBoxHeaderText[id]}`
                    : label}
                </Text>
              </Grid>
            </Tooltip>
            {showClear && (
              <Grid item className="dropdownbox-clear" sx={{ marginRight: 1, fontSize: 13 }}>
                <Button
                  size="small"
                  variant="text"
                  onClick={handleClearOnClick}
                  classes={{ root: classes.root, textPrimary: classes.textPrimary }}
                  data-testid="notifications-dropdownbox-clear"
                >
                  Clear
                </Button>
              </Grid>
            )}
            <Grid item>
              <CollapseButton active={open} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className={classNames('dropdownbox-content', {
            disabled: id === 'notification_type' && actionMode === 'edit'
          })}
        >
          {open && contentByTypes[type as keyof typeof contentByTypes]}
        </Grid>
      </Grid>
    </>
  );
};

export default memo(DropDownBox);
