import { Integrations } from './Integrations/Integrations';
import Notifications from './Notifications/Notifications';
import { NotificationsProvider } from 'pages/Settings/Notifications/NotificationsContext';
import classNames from 'classnames';

import './customSettingsPages.scss';
import { SeedData } from './SeedData/SeedData';

export function CustomSettingsPages(props: any) {
  const getCustomPageComponent = () => {
    switch (props.page) {
      case 'integrations':
        return <Integrations />;
      case 'seed-data':
        return <SeedData />;
      case 'notifications':
        return (
          <NotificationsProvider>
            <Notifications />
          </NotificationsProvider>
        );
    }
  };

  return <div className={classNames('CustomSettingsPages', props.page)}>{getCustomPageComponent()}</div>;
}
