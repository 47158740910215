import { useEffect, useRef, useState } from 'react';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import classNames from 'classnames';
import { SubsidiaryRow } from './SubsidiaryRow/SubsidiaryRow';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSubsidiary } from 'features/SubsidiariesSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IReduxState } from 'app/store';
import { LoginService } from 'login/services/login.service';
import { Text } from '@cyberpion/cyberpion-ui';
import { Grid, Tooltip } from '@mui/material';
import { MenuExportCSV } from '../MenuExportCSV/MenuExportCSV';

import './SubsidiariesDropDown.scss';

interface subsidiaryItem {
  label: string;
  score: number;
  name: string;
}

export function SubsidiariesDropDown({ name, domain }: { name: string; domain: string }) {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [sortBy, setSoryBy] = useState<string>('score');
  const [search, setSearch] = useState<string>('');

  const dispatch = useDispatch<any>();
  const { parentCompany, allSubsidiaries } = useSelector((state: IReduxState) => state.subsidiaries);
  const { user } = useSelector((state: IReduxState) => state.login);

  const flags = useFlags();
  const showHeader = !LoginService.isRestrictedMember(user);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const getFilteredItems = (item: any) => {
    if (search) {
      return item.name.toLowerCase().includes(search.toLowerCase());
    }
    return true;
  };

  if (!flags.isSubsidiaryAttackSurfaceRiskScore) {
    return null;
  }

  const tooltipTitle = (
    <Grid container direction="column" alignItems="center" spacing={1}>
      <Grid item>
        <Text weight={400} textColor="#B7BBC5" textSize={12}>
          {name}
        </Text>
      </Grid>
      <Grid item>
        <Text textSize={13} weight={600} textColor="#B7BBC5">
          {domain}
        </Text>
      </Grid>
    </Grid>
  );

  let isActive;
  if (LoginService.isRestrictedMember(user)) {
    isActive = !!allSubsidiaries?.count && allSubsidiaries.count > 1;
  } else {
    isActive = !!allSubsidiaries?.count;
  }
  const style = isActive ? { border: '2px solid #3f404599' } : {};

  const itemOnClick = (item: any) => () => {
    if (!!item.score) {
      setOpen(false);
      dispatch(setActiveSubsidiary(item));
    }
  };

  const headerOnClick = () => {
    if (!!parentCompany.score) {
      setOpen(false);
      dispatch(setActiveSubsidiary({}));
    }
  };

  return (
    <div className={`SubsidiariesDropDown ${!isActive ? 'inactive' : ''}`} style={{ ...style }} ref={ref}>
      <div
        onClick={() => setOpen(!open)}
        style={{
          display: 'flex',
          height: '100%',
          justifyContent: 'space-between',
          ...(isActive ? { padding: '0 10px' } : { paddingTop: 10 })
        }}
      >
        <Tooltip
          arrow
          title={tooltipTitle}
          placement="top"
          enterDelay={0}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#4D4C53',
                color: '#FFFFFF',
                padding: '3px 6px',
                '& .MuiTooltip-arrow': {
                  color: '#4D4C53'
                },
                border: '2px solid black',
                borderRadius: 1
              }
            }
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 8 }}>
            <Text
              textColor={!!open ? '#909EF1' : '#FFFFFF'}
              textSize={18}
              style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 205, whiteSpace: 'nowrap' }}
            >
              {name}
            </Text>
            <Text
              textColor={!!open ? '#909EF1' : '#FFFFFF'}
              textSize={13}
              style={{
                paddingTop: 8,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: 205,
                whiteSpace: 'nowrap'
              }}
            >
              {domain}
            </Text>
          </div>
        </Tooltip>
        {isActive && (
          <div className={classNames('arrow-wrapper', { rotate: open })} style={{ alignSelf: 'center' }}>
            <IconArrow color="#FFF" />
          </div>
        )}
      </div>

      <div className={classNames('SubsidiariesDropDown-inner', { open: open })}>
        {showHeader && (
          <>
            <SubsidiaryRow {...parentCompany} onClick={headerOnClick} />

            <Text textSize={13} style={{ paddingLeft: 20 }}>{`Subsidiaries (${
              !!allSubsidiaries?.count ? allSubsidiaries.count : 0
            })`}</Text>
          </>
        )}
        <div className="utility-row">
          <img src="/assets/images/search.svg" alt="Search" />
          <input type="text" onChange={(e: any) => setSearch(e.target.value)} value={search}></input>
          <div className="buttons-wrapper">
            <div className="buttons">
              <button onClick={() => setSoryBy('score')} className={classNames({ active: sortBy === 'score' })}>
                By Risk
              </button>
              <button onClick={() => setSoryBy('name')} className={classNames({ active: sortBy === 'name' })}>
                A-Z
              </button>
            </div>

            <MenuExportCSV isDropdown={true} />
          </div>
        </div>
        <div className={`scrollbar-common ${showHeader ? '' : 'no-header'}`}>
          <div style={{ height: '100%', overflow: 'auto' }}>
            {!!allSubsidiaries?.count ? (
              <ul>
                {[...allSubsidiaries.results]
                  .sort((a: subsidiaryItem, b: subsidiaryItem) =>
                    sortBy === 'score' ? b.score - a.score : a.name.localeCompare(b.name)
                  )
                  .filter(getFilteredItems)
                  .map((item: subsidiaryItem) => {
                    return (
                      <li key={item.name.replace(' ', '')} onClick={itemOnClick(item)}>
                        <SubsidiaryRow {...item} />
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <div className="empty">No subsidiaries to show here...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
