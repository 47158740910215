import React, { useState } from 'react';
import { Collapse, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { addHeaders } from 'common/services/rest-api.service';
// import { Spinner } from "common/components/spinner/Spinner";
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { texts } from '../PanelSideBody/PanelSideBody';
import { env } from 'env';

import './collapseFields.scss';
import { AppLoading } from 'common/components/appLoading/appLoading';
interface ICollapseFields {
  collapseItem: string;
}

const CollapseFields: React.FC<ICollapseFields> = ({ collapseItem }) => {
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [collapseItemData, setCollapseItemData] = useState<any>(null);
  const location = useLocation();
  const isInternal = location.pathname.includes('internalAssets');

  const fetchCollapseItem = async () => {
    try {
      const url = `${env.REACT_APP_BASE_URL}discovery/${
        isInternal ? 'org-assets' : 'digital-supply-chain'
      }/${collapseItem}/ip-info/`;
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: addHeaders('GET'),
        credentials: 'include'
      });
      const json = await response.json();

      if (response.ok) {
        setCollapseItemData(json);
      } else {
        setCollapseItemData({ error: 'N/A' });
      }
      setisLoading(false);
      setIsCollapse(toggle => !toggle);
    } catch (err) {
      setisLoading(false);
    }
  };
  const handleToggle = () => {
    if (!isCollapse) {
      setisLoading(true);
      fetchCollapseItem();
    } else {
      setIsCollapse(toggle => !toggle);
    }
  };
  return (
    <Grid container sx={{ padding: '8px 0' }} alignItems="center" className="CollapseFields">
      <Grid item sm={10} sx={{ marginBottom: 1.5 }}>
        <Grid container>
          <Grid item sm={12} className="collapse-fields-field">
            IP
          </Grid>
          <Grid item sm={12} className="collapse-fields-text">
            {collapseItem}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={2} onClick={handleToggle} className={isLoading ? 'loading' : ''} style={{ position: 'relative' }}>
        {isLoading ? <AppLoading size="small" /> : <IconArrow toggleRotate={isCollapse} />}
      </Grid>
      <Collapse in={isCollapse} timeout="auto" unmountOnExit sx={{ paddingBottom: 1.25 }}>
        {!isLoading &&
          collapseItemData &&
          Object.keys(collapseItemData).length > 0 &&
          Object.keys(collapseItemData)
            .filter(
              key =>
                (!Array.isArray(collapseItemData[key]) && !!collapseItemData[key] && key !== 'fqdns') ||
                (Array.isArray(collapseItemData[key]) && collapseItemData[key].length > 0)
            )
            .map((key, index) => (
              <Grid container key={key}>
                <Grid
                  item
                  sm={12}
                  className="collapse-fields-inner-field"
                  sx={{
                    paddingBottom: index + 1 !== Object.keys(collapseItemData).length ? 0.5 : 0
                  }}
                >
                  {texts[key as keyof typeof texts]}
                </Grid>
                <Grid
                  item
                  sm={12}
                  className="collapse-fields-inner-text"
                  sx={{
                    paddingBottom: index + 1 !== Object.keys(collapseItemData).length ? 1.25 : 0
                  }}
                >
                  {Array.isArray(collapseItemData[key]) ? collapseItemData[key].join(', ') : collapseItemData[key]}
                </Grid>
              </Grid>
            ))}
      </Collapse>
    </Grid>
  );
};

export default CollapseFields;
