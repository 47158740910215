import React, { useEffect } from 'react';
import { updateHeader } from 'common/services/common.service';
import Timeline from './Timeline/Timeline';
import ThreatCenterTopBar from './ThreatCenterTopBar/ThreatCenterTopBar';
import ThreatsContainer from './Threats/ThreatsContainer';
import { ThreatsCenterContextType, ThreatsCenterProvider, useThreatsCenter } from './ThreatsCenterContext';
import { AppLoading } from 'common/components/appLoading/appLoading';
// import { useFlags } from 'launchdarkly-react-client-sdk';
// import { useHistory } from 'react-router-dom';

import './ThreatCenter.scss';
import ThreatCenterHeader from './ThreatCenterHeader/ThreatCenterHeader';

const ThreatCenterModule = () => {
  const { isLoading } = useThreatsCenter() as ThreatsCenterContextType;

  useEffect(() => {
    updateHeader.sendHeaderText('Threat Center');
  }, []);

  return !isLoading ? (
    <div className="ThreatCenter">
      <div className="scrollbar-common">
        <ThreatCenterHeader />
        <ThreatCenterTopBar />
        <div className="threat-center-main">
          <Timeline />
          <ThreatsContainer />
        </div>
      </div>
    </div>
  ) : (
    <AppLoading size="big" />
  );
};

const ThreatCenter = () => {
  // const flags = useFlags();
  // const history = useHistory();

  // if (!flags.isIonixThreatCenter) {
  //   history.push('/');
  // }

  return (
    <ThreatsCenterProvider>
      <ThreatCenterModule />
    </ThreatsCenterProvider>
  );
};

export default ThreatCenter;
