import { IconCircledArrowRight } from 'common/components/Icons/IconCircledArrowRight/IconCircledArrowRight';
import { IconTabManagedDomains } from 'common/components/Icons/IconTabManagedDomains/IconTabManagedDomains';
import { Common } from 'common/services/common.service';
import { Link } from 'react-router-dom';
import { HeaderForm } from '../headerForm/HeaderForm';
import './topAssetsAtRisk.scss';

export function TopAssetsAtRisk(props: any) {
  return Common.isNotEmpty(props) ? (
    <div className="topAssetsAtRisk-scope">
      <HeaderForm IconComponent={IconTabManagedDomains} {...props} />
      {props.data && props.data.length !== 0 && (
        <div className="topAssetsAtRisk-items-scope">
          {props.data.map((item: any, index: number) => (
            <div className="topAssetsAtRisk-item-scope" key={index}>
              <div className="">{item}</div>
              <Link
                className="topAssetsAtRisk-link"
                to={`/pages/singleAsset/internalAssets/${item}/actionItems`}
                title={''}
              >
                <IconCircledArrowRight className="topAssetsAtRisk-icon" />
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    <div className="topAssetsAtRisk-scope display-center">No Data</div>
  );
}
