import { useEffect, useState } from 'react';
import { ILoginToken } from './interface';
import './login.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { LoginBackgroundAnimation } from './components/LoginBackgroundAnimation/LoginBackgroundAnimation';
import { LoginBox } from './components/LoginBox/LoginBox';
import { useHistory } from 'react-router-dom';

export function Login(props: ILoginToken) {
  // const [email, setEmail] = useState<IEmail>({ value: "", error: "" });
  // const [lostPassword, setLostPassword] = useState<boolean>(false);
  // const [dataLogin, setDataLogin] = useState<ILogin>(
  //   Common.cloneData(INIT_DATA_LOGIN)
  // );
  // const [showLoginEmail, setShowLoginEmail] = useState<boolean>(true);
  let history = useHistory();
  const [animation, setAnimation] = useState<boolean>(false);
  const { loading, isLoggedIn } = useSelector((state: IReduxState) => state.login);

  //@description: Displays an email error
  // function errorValidEmail() {
  //   setEmail({
  //     value: email.value,
  //     error: "Invalid Input",
  //   });
  // }

  //@description: Email verification check. If it is authentication sends to BE
  // function emailValid() {
  //   if (EmailValidator.validate(email.value)) {
  //     nextLogin();
  //   } else {
  //     errorValidEmail();
  //   }
  // }

  // //@description: event Submit
  // const handleSubmit = async (e: React.FormEvent) => {
  //   emailValid();
  //   e.preventDefault();
  // };

  //@description: Sending verification email at BE
  // function nextLogin() {
  //   let url = `auth/login_methods/`;
  //   RestApi.setData(url, {email: email.value}, 'POST').subscribe(
  //     (response: any) => {
  //       let merge = _.merge(Object.assign({}, dataLogin), response);
  //       setDataLogin(merge);
  //       // setShowLoginEmail(false);
  //     },
  //     (error) => {
  //       errorValidEmail();
  //     }
  //   );
  // }

  // function keyboardEvent(event: React.KeyboardEvent) {
  //   if (event.key === "Enter") {
  //     emailValid();
  //     event.preventDefault();
  //   }
  // }

  // const handleLostPassword = () => {
  //   setEmail({ value: "", error: "" });
  //   setLostPassword((lost) => !lost)
  // }

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setAnimation(true);
      }, 1000);
    }
  }, [loading]);

  useEffect(() => {
    if (isLoggedIn) {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div className={classNames('Login', { animation: animation })}>
      <LoginBackgroundAnimation />
      <LoginBox />
    </div>
  );
}
