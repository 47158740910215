import React from 'react';
import { IconImportance, IconRisk, Text, CveList } from '@cyberpion/cyberpion-ui';
import { TechnicalDetails } from 'common/components/TechnicalDetails/TechnicalDetails';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';

const AMMOUNT_OF_CVES_TO_SHOW = 15;

const ActionItemsDetails = ({ data }: { data: any }) => {
  const { singleAsset, details, row } = data;
  const ips =
    singleAsset?.ips ||
    singleAsset?.domain?.ips ||
    singleAsset?.subdomain?.ips ||
    singleAsset?.managed_domain?.ips ||
    singleAsset?.cloud_asset?.ips ||
    [];

  return (
    <div className="action-items-details">
      <div style={{ display: 'flex', marginTop: 24, alignItems: 'center' }}>
        <div className="risk-icon" style={{ marginRight: 4 }}>
          <IconRisk urgency={row?.urgency?.type} />
        </div>
        <Text textSize={30} weight={400} family="Rajdhani">
          {row?.urgency?.urgency}
        </Text>
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10 }}>
          <Text textSize={13} weight={600} style={{ marginBottom: 4 }} upper>
            {details?.title}
          </Text>
          <Text textColor="#8C909A">{details?.type}</Text>
        </div>
      </div>

      <div style={{ display: 'flex', marginTop: 24, alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 4, paddingRight: 16 }}>
          <Text weight={600} style={{ marginRight: 4, marginBottom: 4 }}>
            Asset Name
          </Text>
          <Text style={{ maxWidth: 370, wordBreak: 'break-all' }}>{details?.asset}</Text>
        </div>
        {singleAsset?.risk_score?.type && (
          <div
            style={{
              display: 'flex',
              paddingRight: 16,
              paddingLeft: 16,
              alignItems: 'center',
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <div style={{ paddingRight: 8 }}>
              <IconRisk urgency={singleAsset?.risk_score?.type} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text weight={600} style={{ whiteSpace: 'nowrap', marginBottom: 4 }}>
                Asset Risk
              </Text>
              <Text>{singleAsset?.risk_score?.risk_score}</Text>
            </div>
          </div>
        )}
        {singleAsset?.importance && (
          <div
            style={{
              display: 'flex',
              paddingRight: 16,
              paddingLeft: 16,
              alignItems: 'center',
              borderLeft: '1px solid #ECECEC'
            }}
          >
            <div style={{ paddingRight: 8 }}>
              <IconImportance type={singleAsset?.importance?.type} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text weight={600} style={{ whiteSpace: 'nowrap', marginBottom: 4 }}>
                Asset Importance
              </Text>
              <Text>{singleAsset?.importance?.type}</Text>
            </div>
          </div>
        )}
      </div>

      {!!ips && !!ips.length && (
        <div style={{ marginTop: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            {`${singleAsset?.type !== 'ip' ? 'IPs' : 'IP Network'}`}
          </Text>
          <Text textColor="#454549">{ips.join(' / ')}</Text>
        </div>
      )}
      {!!details?.summary && (
        <div style={{ marginTop: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            Summary
          </Text>
          <Text textColor="#454549" style={{ lineHeight: 1.35 }}>
            {details.summary}
          </Text>
        </div>
      )}
      {!!details?.description && (
        <div style={{ marginTop: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            Description
          </Text>
          <Text textColor="#454549" style={{ lineHeight: 1.35 }}>
            {details.description}
          </Text>
        </div>
      )}
      {!!details?.impact && (
        <div style={{ marginTop: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            Impact
          </Text>
          <Text textColor="#454549">{details.impact}</Text>
        </div>
      )}
      {!!details?.references && (
        <div style={{ marginTop: 16 }}>
          <Text textSize={12} weight={600}>
            References
          </Text>
          <div>
            <ul>
              {Object.entries(details.references).map(([key, value]: any, index): any => (
                <li
                  key={index}
                  style={
                    index !== Object.keys(details.references).length - 1
                      ? { marginBottom: 4, fontSize: 12 }
                      : { fontSize: 12 }
                  }
                >
                  <LinkNewTab to={value} text={key} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {!!details?.required_action && (
        <div style={{ marginTop: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            Required Action
          </Text>
          <Text textColor="#454549" style={{ lineHeight: 1.35 }}>
            {details.required_action}
          </Text>
        </div>
      )}
      {!!details?.cves && (
        <div style={{ marginTop: 16 }}>
          <Text weight={600} style={{ marginBottom: 4 }}>
            {`CVE List (${
              details.cves.length < AMMOUNT_OF_CVES_TO_SHOW ? details.cves.length : AMMOUNT_OF_CVES_TO_SHOW
            })`}
          </Text>
          <CveList data={details.cves} visible={true} maxRows={AMMOUNT_OF_CVES_TO_SHOW} />
        </div>
      )}
      {!!details?.technical_details && !!Object.keys(details.technical_details).length && (
        <div style={{ marginTop: 16 }}>
          <Text textSize={12} weight={600} style={{ marginBottom: 8 }}>
            Technical Details
          </Text>
          <div className="technical-details-container">
            <TechnicalDetails
              data={details.technical_details ? details.technical_details : {}}
              isActionItemsPDF={true}
              isAllowEmptyJson={true}
              isPDF={true}
            />
          </div>
        </div>
      )}
      {!!details?.raw_request && (
        <div style={{ marginTop: 16 }}>
          <Text textSize={11} weight={600}>
            Raw Request (Used to inject the payload)
          </Text>
          <Text textColor="#454549">{details.raw_request}</Text>
        </div>
      )}
    </div>
  );
};

export default ActionItemsDetails;
