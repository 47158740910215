import './defaultIcon.scss';
export function DefaultIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="408" height="180" viewBox="0 0 408 180" fill="none">
      <ellipse cx="269" cy="149.5" rx="21" ry="3.5" fill="#F0F2F6" />
      <path d="M292.431 54.0135L306.551 29.8077L320.671 54.0135H292.431Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M292.431 102.425L306.551 78.2192L320.671 102.425H292.431Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M278.311 78.2192L292.431 54.0134L306.551 78.2192H278.311Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M250.071 78.2192L264.191 54.0134L278.311 78.2192H250.071Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M320.672 54.0135L334.792 29.8077L348.912 54.0135H320.672Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path
        d="M320.672 54.0134L306.552 78.2192L292.432 54.0134L320.672 54.0134Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M306.552 78.2193L292.431 102.425L278.311 78.2192L306.552 78.2193Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M306.552 29.8076L292.431 54.0134L278.311 29.8076L306.552 29.8076Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M278.311 78.2193L264.191 102.425L250.071 78.2192L278.311 78.2193Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M348.911 54.0134L334.791 78.2192L320.671 54.0134L348.911 54.0134Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <circle cx="306.551" cy="78.2194" r="3.06212" fill="#E8E8E8" />
      <circle cx="278.475" cy="78.2194" r="3.06212" fill="#E8E8E8" />
      <circle cx="250.062" cy="78.2194" r="3.06212" fill="#E8E8E8" />
      <circle cx="334.964" cy="30.0622" r="3.06212" fill="#E8E8E8" />
      <circle cx="334.964" cy="78.0622" r="3.06212" fill="#E8E8E8" />
      <circle cx="306.888" cy="30.0622" r="3.06212" fill="#E8E8E8" />
      <circle cx="278.475" cy="30.0622" r="3.06212" fill="#E8E8E8" />
      <circle cx="320.517" cy="102.779" r="3.06212" fill="#E8E8E8" />
      <circle cx="292.441" cy="102.779" r="3.06212" fill="#E8E8E8" />
      <circle cx="264.028" cy="102.78" r="3.06212" fill="#E8E8E8" />
      <circle cx="264.028" cy="54.1407" r="3.06212" fill="#E8E8E8" />
      <circle cx="292.304" cy="54.1407" r="3.06212" fill="#E8E8E8" />
      <circle cx="320.581" cy="54.1407" r="3.06212" fill="#E8E8E8" />
      <circle cx="348.857" cy="54.1407" r="3.06212" fill="#E8E8E8" />
      <path d="M101.431 76.5135L115.551 52.3077L129.671 76.5135H101.431Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M101.431 124.925L115.551 100.719L129.671 124.925H101.431Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M87.3114 100.719L101.431 76.5134L115.551 100.719H87.3114Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M59.0712 100.719L73.1912 76.5134L87.3113 100.719H59.0712Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path d="M129.672 76.5135L143.792 52.3077L157.912 76.5135H129.672Z" stroke="#E8E8E8" strokeLinejoin="bevel" />
      <path
        d="M129.672 76.5134L115.551 100.719L101.431 76.5134L129.672 76.5134Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M115.552 100.719L101.431 124.925L87.3114 100.719L115.552 100.719Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M115.552 52.3076L101.431 76.5134L87.3114 52.3076L115.552 52.3076Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M87.3114 100.719L73.1914 124.925L59.0713 100.719L87.3114 100.719Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <path
        d="M157.911 76.5134L143.791 100.719L129.671 76.5134L157.911 76.5134Z"
        stroke="#E8E8E8"
        strokeLinejoin="bevel"
      />
      <circle cx="115.551" cy="100.719" r="3.06212" fill="#E8E8E8" />
      <circle cx="87.4748" cy="100.719" r="3.06212" fill="#E8E8E8" />
      <circle cx="59.0621" cy="100.719" r="3.06212" fill="#E8E8E8" />
      <circle cx="143.964" cy="52.5622" r="3.06212" fill="#E8E8E8" />
      <circle cx="115.888" cy="52.5622" r="3.06212" fill="#E8E8E8" />
      <circle cx="87.4748" cy="52.5622" r="3.06212" fill="#E8E8E8" />
      <circle cx="129.517" cy="125.279" r="3.06212" fill="#E8E8E8" />
      <circle cx="101.44" cy="125.279" r="3.06212" fill="#E8E8E8" />
      <circle cx="73.0277" cy="125.28" r="3.06212" fill="#E8E8E8" />
      <circle cx="73.0277" cy="76.6407" r="3.06212" fill="#E8E8E8" />
      <circle cx="101.304" cy="76.6407" r="3.06212" fill="#E8E8E8" />
      <circle cx="129.581" cy="76.6407" r="3.06212" fill="#E8E8E8" />
      <circle cx="157.857" cy="76.6407" r="3.06212" fill="#E8E8E8" />
      <ellipse cx="183.667" cy="149" rx="44" ry="7" fill="#F0F2F6" />
      <path
        d="M230.078 73.1115L181.587 40.7842L166.399 50.9098L133.096 73.1115V105.439L181.587 137.826L230.078 105.439V73.1115Z"
        fill="#F0F2F6"
        stroke="#D1D2D3"
        strokeLinejoin="round"
      />
      <path
        d="M206.225 89.2753L181.587 73.3333L173.87 78.3267L156.949 89.2753L181.587 105.217L206.225 89.2753Z"
        fill="#E8E8E8"
      />
      <path
        d="M230.078 73.1115L181.587 40.7842L166.399 50.9098L133.096 73.1115L181.587 105.439L230.078 73.1115Z"
        stroke="#D1D2D3"
        strokeLinejoin="round"
      />
      <path
        d="M181.587 73.1116L205.832 89.2753M181.587 73.1116L157.341 89.2753M181.587 73.1116V60.1807"
        stroke="#D1D2D3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M230.078 73.1116V105.439L181.587 137.766L133.096 105.439V73.1116"
        stroke="#D1D2D3"
        strokeLinejoin="round"
      />
      <path d="M181.587 105.105V137.766" stroke="#D1D2D3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M247.926 109.238C248.707 108.457 249.974 108.457 250.755 109.238L273.581 132.064C274.362 132.845 274.362 134.111 273.581 134.893C272.8 135.674 271.533 135.674 270.752 134.893L247.926 112.066C247.145 111.285 247.145 110.019 247.926 109.238Z"
        fill="#D1D2D3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258 93.0001C258 107.912 245.912 120 231 120C216.088 120 204 107.912 204 93.0001C204 78.0884 216.088 66.0001 231 66.0001C245.912 66.0001 258 78.0884 258 93.0001ZM250.8 93.0003C250.8 103.936 241.935 112.8 231 112.8C220.065 112.8 211.2 103.936 211.2 93.0003C211.2 82.065 220.065 73.2003 231 73.2003C241.935 73.2003 250.8 82.065 250.8 93.0003Z"
        fill="#F0F2F6"
      />
      <path
        d="M231 113C242.046 113 251 104.046 251 93.0001C251 81.9544 242.046 73.0001 231 73.0001C219.954 73.0001 211 81.9544 211 93.0001C211 104.046 219.954 113 231 113Z"
        fill="white"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="125.22 46.96"
      />
      <path
        d="M231 120.5C246.188 120.5 258.5 108.188 258.5 93.0001C258.5 77.8122 246.188 65.5001 231 65.5001C215.812 65.5001 203.5 77.8122 203.5 93.0001C203.5 108.188 215.812 120.5 231 120.5Z"
        stroke="#B7BBC5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="254.424"
        y="121.269"
        width="7.82609"
        height="23.2306"
        rx="1.95652"
        transform="rotate(-45 254.424 121.269)"
        fill="#E7EAFC"
        stroke="#B7BBC5"
      />
    </svg>
  );
}
