import classNames from 'classnames';
import './ClusterDropdown.scss';
import { useRef, useState } from 'react';
import { IconCluster } from 'common/components/Icons/IconMenu/IconCluster/IconCluster';
import { useClickOutside } from 'hooks/useClickOutside';
import { setIsEdited } from 'features/ViewsSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/store';
import { setClusterSort } from 'features/GridSlice';

export function ClusterDropdown(props: any) {
  const [visible, setVisible] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const ref = useRef(null);

  useClickOutside(ref, () => {
    setVisible(false);
  });

  const onClick = (type: string) => {
    if (type === props.type) {
      props.onToggleCluster('');
    } else {
      props.onToggleCluster(type);
    }
    dispatch(setIsEdited(true));
    dispatch(setClusterSort(''));
    setVisible(false);
  };

  return (
    <div
      ref={ref}
      className={classNames('cluster-trigger', { active: !!props.type })}
      onClick={() => setVisible(!visible)}
    >
      <IconCluster active={!!props.type} />
      {!!props.type ? (
        <img
          src="/assets/images/x-white.svg"
          alt="Cancel"
          onClick={() => {
            dispatch(setIsEdited(true));
            dispatch(setClusterSort(''));
            setVisible(false);
            props.onToggleCluster('');
          }}
        />
      ) : (
        ''
      )}
      <ul className={classNames({ visible: visible })}>
        <li>Cluster By</li>
        <li className={classNames({ active: props.type === 'primary_ai' })} onClick={() => onClick('primary_ai')}>
          Root Issue
        </li>
        <li
          className={classNames({ active: props.type === 'handle_together_group' })}
          onClick={() => onClick('handle_together_group')}
        >
          Joint remediation
        </li>
        <li className={classNames({ active: props.type === 'current_state' })} onClick={() => onClick('current_state')}>
          Statuses
        </li>
        <li className={classNames({ active: props.type === 'title' })} onClick={() => onClick('title')}>
          Title
        </li>
        <li className={classNames({ active: props.type === 'asset' })} onClick={() => onClick('asset')}>
          Asset name
        </li>
        <li
          className={classNames({ active: props.type === 'asset_importance' })}
          onClick={() => onClick('asset_importance')}
        >
          Asset importance
        </li>
        <li className={classNames({ active: props.type === 'urgency' })} onClick={() => onClick('urgency')}>
          Urgency
        </li>
        <li className={classNames({ active: props.type === 'type' })} onClick={() => onClick('type')}>
          Type
        </li>
      </ul>
    </div>
  );
}
