import { RestApi } from 'common/services/rest-api.service';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useState, RefObject } from 'react';
import './userMenu.scss';
import { AppLoading } from '../appLoading/appLoading';

export function UserMenu(props: {
  open: boolean;
  anchorRef: RefObject<HTMLDivElement>;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  onOpenSettingsPopup: () => void;
}) {
  const [isLogginOut, setIsLogginOut] = useState<boolean>(false);

  const logOutClicked = () => {
    setIsLogginOut(true);
    RestApi.logout(Paths[PathNames.logout]);
  };

  const linkTo = (url: string): void => {
    window.location.href = url;
  };

  return (
    <Popper open={props.open} anchorEl={props.anchorRef.current} placement="top-start" transition disablePortal>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={props.handleClose}>
              <MenuList>
                <MenuItem onClick={props.onOpenSettingsPopup}>Profile Settings</MenuItem>
                <MenuItem onClick={() => linkTo('https://ionix.zendesk.com')}>Help Center</MenuItem>
                <MenuItem onClick={logOutClicked} className={isLogginOut ? 'active' : ''}>
                  Log out
                  {isLogginOut && <AppLoading />}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
