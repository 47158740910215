import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export const IconNotificationsCertificates: React.FC<IIcon> = ({ active }) => {
  let color = active ? '#3455DD' : '#6D6D7A';

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1.875C1 1.39175 1.39175 1 1.875 1H14.125C14.6082 1 15 1.39175 15 1.875V14.125C15 14.6082 14.6082 15 14.125 15H1.875C1.39175 15 1 14.6082 1 14.125V1.875ZM3.1875 8.875C3.1875 8.63338 3.38338 8.4375 3.625 8.4375H4.9375C5.17912 8.4375 5.375 8.63338 5.375 8.875C5.375 9.11662 5.17912 9.3125 4.9375 9.3125H3.625C3.38338 9.3125 3.1875 9.11662 3.1875 8.875ZM3.625 6.6875C3.38338 6.6875 3.1875 6.88338 3.1875 7.125C3.1875 7.36662 3.38338 7.5625 3.625 7.5625H7.125C7.36662 7.5625 7.5625 7.36662 7.5625 7.125C7.5625 6.88338 7.36662 6.6875 7.125 6.6875H3.625ZM3.1875 5.375C3.1875 5.13338 3.38338 4.9375 3.625 4.9375H8.875C9.11662 4.9375 9.3125 5.13338 9.3125 5.375C9.3125 5.61662 9.11662 5.8125 8.875 5.8125H3.625C3.38338 5.8125 3.1875 5.61662 3.1875 5.375ZM3.625 3.1875C3.38338 3.1875 3.1875 3.38338 3.1875 3.625C3.1875 3.86662 3.38338 4.0625 3.625 4.0625H11.5C11.7416 4.0625 11.9375 3.86662 11.9375 3.625C11.9375 3.38338 11.7416 3.1875 11.5 3.1875H3.625ZM10.1875 10.443V11.5575L10.8198 11.136L11.0625 10.9742L11.3052 11.136L11.9375 11.5575V10.443C11.6695 10.5601 11.3736 10.625 11.0625 10.625C10.7514 10.625 10.4555 10.5601 10.1875 10.443ZM12.8125 9.75021V12.375V13.1925L12.1323 12.739L11.0625 12.0258L9.99268 12.739L9.3125 13.1925V12.375V9.75021C9.03779 9.38458 8.875 8.93005 8.875 8.4375C8.875 7.22938 9.85438 6.25 11.0625 6.25C12.2706 6.25 13.25 7.22938 13.25 8.4375C13.25 8.93005 13.0872 9.38458 12.8125 9.75021ZM12.375 8.4375C12.375 9.16237 11.7874 9.75 11.0625 9.75C10.3376 9.75 9.75 9.16237 9.75 8.4375C9.75 7.71263 10.3376 7.125 11.0625 7.125C11.7874 7.125 12.375 7.71263 12.375 8.4375Z"
        fill={color}
      />
    </svg>
  );
};
