import { IReduxState } from 'app/store';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { LocalGrid } from 'common/components/grid/LocalGrid';
import { IColumnPicker } from 'common/interfaces/interface';
import { RestApi } from 'common/services/rest-api.service';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MobileAppsActions } from 'pages/Settings/FilterGroups/FilterGroupsActions/CreateGroupPopUp/GroupTabs/MobileApps/MobileAppsActions/MobileAppsActions';

import './mobile-apps-tab.scss';

type ipsType = {
  [key: string]: string;
};
const MobileAppsTab = (props: any) => {
  const [columnPicker, setColumnPicker] = useState<IColumnPicker[]>([]);
  const [identifier, setIdenifier] = useState<string>('');
  const { search, mobileApps } = useSelector((state: IReduxState) => state.groups);

  useEffect(() => {
    RestApi.getData('settings/groups/mobile-apps/columns/').subscribe((response: any) => {
      setIdenifier(response.identifier_field);
      setColumnPicker(response.data);
    });
  }, []);

  const filteredRows = mobileApps.filter((item: ipsType) => item.value.includes(search));
  return (
    <div className="mobile-apps-tab">
      {columnPicker.length && identifier ? (
        <LocalGrid
          columns={ColumnsManage.createColumns(identifier, columnPicker || [], {
            page: 'settings',
            tab: 'mobile-apps'
          })}
          rows={filteredRows}
          addedActions={(props: any) => <MobileAppsActions {...props} />}
          identifier={identifier}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default MobileAppsTab;
