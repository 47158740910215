import React, { useRef } from 'react';
import { ASMWidget, Text } from '@cyberpion/cyberpion-ui';
import PageFooter from '../../PageFooter';
import PageHeader from '../../PageHeader';
import ActionItemsDetails from './ActionItemsDetails';
import usePageSize from '../../hooks/usePageSize';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';

import './ActionItems.scss';

const ActionItems = ({ data }: { data: any }) => {
  const refDetails = useRef(null);
  const { minHeight } = usePageSize(refDetails, data.id);
  const { allThreats } = useThreatsReport() as ThreatsReportContextType;

  return (
    <div ref={refDetails} style={{ minHeight: minHeight, display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column', padding: 32 }}>
        <PageHeader />
      </div>
      <div style={{ margin: '0px 30px', flexGrow: 1 }}>
        <Text textSize={14} upper weight={600}>
          Top exposure examples
        </Text>
        <div
          style={{ height: 220, position: 'relative', overflow: 'hidden', marginTop: 20, border: '2px solid #ECECEC' }}
        >
          <ASMWidget items={allThreats} isPDF={true} highlight={data.id} />
        </div>
        <div style={{ marginTop: 20, border: '2px solid #ECECEC', flex: 1 }}>
          <ActionItemsDetails data={data} />
        </div>
      </div>
      <div>
        <PageFooter id={data.id} order={data.order} />
      </div>
    </div>
  );
};

export default ActionItems;
