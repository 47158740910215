import { IReduxState } from 'app/store';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import USER_ROLES from 'common/constants/roles';
import { RestApi } from 'common/services/rest-api.service';
import { tosterService } from 'common/services/tostar.service';
import { getRows, setConfirmationParams } from 'features/GridSlice';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './UserRolesDropdown.scss';

export function UserRolesDropdown(props: any) {
  const ref = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [currentUserRole, setCurrentUserRole] = useState<any>('');
  const {
    user: { role: loggedInRole }
  } = useSelector((state: IReduxState) => state.login);
  const dispatch = useDispatch<any>();
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    setCurrentUserRole(props.params.row.role);
  }, [props.params.row.role]);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setVisible(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  const onChange = (role: number) => {
    setVisible(false);
    if (currentUserRole === USER_ROLES[35]) {
      // When changing a member to admin
      dispatch(
        setConfirmationParams({
          message: (
            <div className="popup-main">
              <div>
                Changing {currentUserRole} role to {USER_ROLES[role]} will <span>REMOVE</span> any group restrictions
                that were set.
              </div>
              <div>Users with {USER_ROLES[role]} restrictions can not be restricted.</div>
            </div>
          ),
          visible: true,
          endpoint: `${Paths[PathNames.userManagement]}${props.params.row.id}/`,
          data: {
            role: role
          },
          successMessage: 'User Role Changed Successfully',
          callback: () => {
            setCurrentUserRole(USER_ROLES[role]);
            dispatch(getRows());
          }
        })
      );
    } else {
      RestApi.setData(
        `${Paths[PathNames.userManagement]}${props.params.row.id}/`,
        {
          role: role
        },
        'PATCH'
      ).subscribe(
        () => {
          setCurrentUserRole(USER_ROLES[role]);
          dispatch(getRows());
        },
        error => {
          tosterService.showError(error);
        }
      );
    }
  };

  const canEdit = (): boolean => {
    const items = filteredItemsRoles();
    return props.params.row.actions?.is_editable && items.length;
  };

  const filteredItemsRoles = () => {
    const filtered = Object.keys(USER_ROLES).filter((role: string) => {
      const _role: number = parseInt(role);
      let obj: { [key: string]: number } = {};
      for (const [key, value] of Object.entries(USER_ROLES)) {
        obj = { ...obj, [value]: parseInt(key) };
      }
      if (USER_ROLES[_role] === currentUserRole) {
        return false;
      }
      if (_role === 35) {
        return false;
      }
      return _role > loggedInRole;
    });
    return filtered.map((role: string, index) => {
      const _role = parseInt(role);
      return (
        <li key={index} onClick={() => onChange(parseInt(role))}>
          {USER_ROLES[_role]}
        </li>
      );
    });
  };

  return (
    <div className="UserRolesDropdown-wrapper">
      <h6>{currentUserRole}</h6>
      <div className={`UserRolesDropdown ${canEdit() ? '' : 'disabled'}`} ref={ref}>
        <header className={visible ? 'visible' : ''} onClick={() => setVisible(!visible)}>
          Edit Role: <IconArrow />
        </header>
        <ul className={visible ? 'visible' : ''}>{filteredItemsRoles()}</ul>
      </div>
    </div>
  );
}
