import { IconRisk } from 'common/components/Icons/IconRisk/IconRisk';
import { Common } from 'common/services/common.service';
import './chartLineLegend.scss';
interface ILegend {
  header: string;
  name: string;
  value: number;
}

interface IChartLineLegend {
  data: ILegend[];
  isIconRisk?: boolean;
  hideNumber?: boolean;
}

export function ChartLineLegend(props: IChartLineLegend) {
  return (
    <div className="ChartLineLegend-scope">
      {props.data &&
        props.data.map((item: ILegend, index: number) => (
          <div className="" key={index}>
            <div className="ChartLineLegend-label-scope">
              <IconRisk urgency={item.name} />
              <div className="ChartLineLegend-label">{item.header}</div>
            </div>
            {!props.hideNumber && (
              <div className="ChartLineLegend-count font-number-common">{Common.numberWithCommas(item.value || 0)}</div>
            )}
          </div>
        ))}
    </div>
  );
}
