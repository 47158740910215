import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconOVH extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7118 7.62717C21.6985 9.24787 22.1343 11.0993 21.9639 12.9462C21.7936 14.7931 21.0247 16.5522 19.755 18H14.27L15.9585 15.2791H13.7251L16.3576 11.0521H18.6038L20.7118 7.63184V7.62717ZM9.88001 18H4.28758C2.99419 16.5585 2.20944 14.7942 2.0364 12.9388C1.86335 11.0834 2.31011 9.22364 3.31799 7.60386L6.94565 13.3463L10.9443 7H16.8283L9.88257 17.9953L9.88001 18Z"
          fill="#8C909A"
        />
      </svg>
    );
  }
}
