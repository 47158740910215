import './IntegrationHeader.scss';

export function IntegrationHeader(props: any) {
  return (
    <div className="IntegrationHeader">
      <h6>
        <img src="/assets/images/plug.svg" alt="Icon" />
        Integrations & Adapters
      </h6>
      <div>
        {/* <img src={`/assets/images/integrations/${props.icon}.svg`} alt={props.name} /> */}
        <h5 style={{ fontSize: 18 }}>{props.name} Integration</h5>
      </div>
    </div>
  );
}
