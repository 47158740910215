export const DATA_MAP: any = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Belize",
        sov_a3: "BLZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Belize",
        adm0_a3: "BLZ",
        geou_dif: 0,
        geounit: "Belize",
        gu_a3: "BLZ",
        su_dif: 0,
        subunit: "Belize",
        su_a3: "BLZ",
        brk_diff: 0,
        name: "Belize",
        name_long: "Belize",
        brk_a3: "BLZ",
        brk_name: "Belize",
        brk_group: null,
        abbrev: "Belize",
        postal: "BZ",
        formal_en: "Belize",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Belize",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 5,
        mapcolor13: 7,
        pop_est: 307899,
        gdp_md_est: 2536,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BZ",
        iso_a3: "BLZ",
        iso_n3: "084",
        un_a3: "084",
        wb_a2: "BZ",
        wb_a3: "BLZ",
        woe_id: -99,
        adm0_a3_is: "BLZ",
        adm0_a3_us: "BLZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "BLZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.14308041050332, 17.80831899664932],
            [-89.15090938999553, 17.95546763760042],
            [-89.02985734735182, 18.001511338772488],
            [-88.84834387892661, 17.883198147040233],
            [-88.49012285027935, 18.486830552641603],
            [-88.3000310940937, 18.4999822046599],
            [-88.29633622918482, 18.35327281338327],
            [-88.10681291375437, 18.348673610909287],
            [-88.1234785631685, 18.07667470954101],
            [-88.2853549873228, 17.644142971258034],
            [-88.19786678745265, 17.489475409408456],
            [-88.30264075392444, 17.131693630435663],
            [-88.23951799187991, 17.036066392479555],
            [-88.35542822951057, 16.530774237529627],
            [-88.55182451043585, 16.265467434143147],
            [-88.73243364129594, 16.233634751851355],
            [-88.93061275913527, 15.887273464415074],
            [-89.22912167026928, 15.88693756760517],
            [-89.15080603713095, 17.015576687075836],
            [-89.14308041050332, 17.80831899664932],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Canada",
        sov_a3: "CAN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Canada",
        adm0_a3: "CAN",
        geou_dif: 0,
        geounit: "Canada",
        gu_a3: "CAN",
        su_dif: 0,
        subunit: "Canada",
        su_a3: "CAN",
        brk_diff: 0,
        name: "Canada",
        name_long: "Canada",
        brk_a3: "CAN",
        brk_name: "Canada",
        brk_group: null,
        abbrev: "Can.",
        postal: "CA",
        formal_en: "Canada",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Canada",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 33487208,
        gdp_md_est: 1300000,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CA",
        iso_a3: "CAN",
        iso_n3: "124",
        un_a3: "124",
        wb_a2: "CA",
        wb_a3: "CAN",
        woe_id: -99,
        adm0_a3_is: "CAN",
        adm0_a3_us: "CAN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Northern America",
        region_wb: "North America",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "CAN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-63.6645, 46.55001],
              [-62.9393, 46.41587],
              [-62.01208, 46.44314],
              [-62.50391, 46.03339],
              [-62.87433, 45.96818],
              [-64.1428, 46.39265],
              [-64.39261, 46.72747],
              [-64.01486, 47.03601],
              [-63.6645, 46.55001],
            ],
          ],
          [
            [
              [-61.806305, 49.10506],
              [-62.29318, 49.08717],
              [-63.58926, 49.40069],
              [-64.51912, 49.87304],
              [-64.17322, 49.95718],
              [-62.85829, 49.70641],
              [-61.835585, 49.28855],
              [-61.806305, 49.10506],
            ],
          ],
          [
            [
              [-123.51000158755114, 48.51001089130344],
              [-124.0128907883995, 48.370846259141416],
              [-125.65501277733837, 48.8250045843385],
              [-125.95499446679275, 49.179995835967645],
              [-126.85000443587187, 49.53000031188043],
              [-127.0299934495444, 49.81499583597008],
              [-128.05933630436624, 49.9949590114266],
              [-128.44458410710217, 50.539137681676124],
              [-128.35841365625544, 50.770648098343685],
              [-127.3085810960299, 50.55257355407195],
              [-126.69500097721232, 50.400903225295394],
              [-125.75500667382319, 50.29501821552938],
              [-125.4150015875588, 49.95000051533261],
              [-124.92076818911934, 49.475274970083404],
              [-123.92250870832102, 49.06248362893581],
              [-123.51000158755114, 48.51001089130344],
            ],
          ],
          [
            [
              [-56.13403581401712, 50.6870097926793],
              [-56.795881720595276, 49.81230866149096],
              [-56.1431050278843, 50.150117499382844],
              [-55.471492275602934, 49.93581533466846],
              [-55.82240108908093, 49.58712860777911],
              [-54.935142584845664, 49.31301097268684],
              [-54.47377539734378, 49.55669118915918],
              [-53.476549445191324, 49.24913890237405],
              [-53.78601375997124, 48.51678050393363],
              [-53.086133999226256, 48.687803656603535],
              [-52.958648240762244, 48.157164211614486],
              [-52.64809872090419, 47.5355484075755],
              [-53.069158291218336, 46.65549876564495],
              [-53.52145626485304, 46.61829173439483],
              [-54.17893551290254, 46.80706574155701],
              [-53.961868659060485, 47.62520701760192],
              [-54.24048214376214, 47.75227936460763],
              [-55.4007730780115, 46.884993801453135],
              [-55.99748084168584, 46.9197203639533],
              [-55.29121904155278, 47.389562486351],
              [-56.25079871278052, 47.63254507098739],
              [-57.3252292547771, 47.572807115258],
              [-59.26601518414677, 47.603347886742505],
              [-59.419494188053704, 47.899453843774864],
              [-58.796586473207405, 48.25152537697949],
              [-59.231624518456535, 48.52318838153781],
              [-58.39180497906523, 49.12558055276418],
              [-57.35868974468604, 50.718274034215845],
              [-56.73865007183201, 51.28743825947853],
              [-55.870976935435294, 51.632094224649194],
              [-55.406974249886616, 51.58827261006573],
              [-55.60021826844209, 51.31707469339793],
              [-56.13403581401712, 50.6870097926793],
            ],
          ],
          [
            [
              [-133.1800040417117, 54.16997549093531],
              [-132.71000788443132, 54.040009315423525],
              [-131.74998958400326, 54.12000438090922],
              [-132.049480347351, 52.984621487024526],
              [-131.1790425218266, 52.180432847698285],
              [-131.57782954982292, 52.18237071390925],
              [-132.18042842677855, 52.639707139692405],
              [-132.54999243231387, 53.100014960332146],
              [-133.05461117875552, 53.41146881775537],
              [-133.2396644827927, 53.8510802272624],
              [-133.1800040417117, 54.16997549093531],
            ],
          ],
          [
            [
              [-79.26582, 62.158675],
              [-79.65752, 61.63308],
              [-80.09956, 61.7181],
              [-80.36215, 62.01649],
              [-80.315395, 62.085565],
              [-79.92939, 62.3856],
              [-79.52002, 62.36371],
              [-79.26582, 62.158675],
            ],
          ],
          [
            [
              [-81.89825, 62.7108],
              [-83.06857, 62.15922],
              [-83.77462, 62.18231],
              [-83.99367, 62.4528],
              [-83.25048, 62.91409],
              [-81.87699, 62.90458],
              [-81.89825, 62.7108],
            ],
          ],
          [
            [
              [-85.16130794954985, 65.65728465439281],
              [-84.97576371940596, 65.217518215589],
              [-84.4640120104195, 65.37177236598018],
              [-83.88262630891975, 65.10961782496355],
              [-82.78757687043877, 64.76669302027469],
              [-81.64201371939254, 64.45513580998696],
              [-81.55344031444425, 63.979609280037145],
              [-80.81736121287886, 64.05748566350101],
              [-80.10345130076661, 63.725981350348604],
              [-80.99101986359568, 63.41124603947497],
              [-82.54717810741701, 63.65172231714524],
              [-83.10879757356506, 64.10187571883972],
              [-84.10041663281388, 63.56971181909802],
              [-85.52340471061902, 63.05237905542409],
              [-85.86676876498237, 63.637252916103556],
              [-87.22198320183674, 63.541238104905226],
              [-86.35275977247127, 64.03583323837071],
              [-86.22488644076513, 64.82291697860826],
              [-85.88384782585487, 65.73877838811705],
              [-85.16130794954985, 65.65728465439281],
            ],
          ],
          [
            [
              [-75.86588, 67.14886],
              [-76.98687, 67.09873],
              [-77.2364, 67.58809],
              [-76.81166, 68.14856],
              [-75.89521, 68.28721],
              [-75.1145, 68.01036],
              [-75.10333, 67.58202],
              [-75.21597, 67.44425],
              [-75.86588, 67.14886],
            ],
          ],
          [
            [
              [-95.64768120380052, 69.10769035832178],
              [-96.2695212038006, 68.75704035832175],
              [-97.61740120380057, 69.0600303583218],
              [-98.43180120380052, 68.9507003583218],
              [-99.79740120380053, 69.4000303583218],
              [-98.91740120380055, 69.7100303583218],
              [-98.2182612038005, 70.14354035832176],
              [-97.15740120380056, 69.86003035832181],
              [-96.55740120380054, 69.68003035832176],
              [-96.25740120380053, 69.49003035832177],
              [-95.64768120380052, 69.10769035832178],
            ],
          ],
          [
            [
              [-90.5471, 69.49766],
              [-90.55151, 68.47499],
              [-89.21515, 69.25873],
              [-88.01966, 68.61508],
              [-88.31749, 67.87338],
              [-87.35017, 67.19872],
              [-86.30607, 67.92146],
              [-85.57664, 68.78456],
              [-85.52197, 69.88211],
              [-84.10081, 69.80539],
              [-82.62258, 69.65826],
              [-81.28043, 69.16202],
              [-81.2202, 68.66567],
              [-81.96436, 68.13253],
              [-81.25928, 67.59716],
              [-81.38653, 67.11078],
              [-83.34456, 66.41154],
              [-84.73542, 66.2573],
              [-85.76943, 66.55833],
              [-86.0676, 66.05625],
              [-87.03143, 65.21297],
              [-87.32324, 64.77563],
              [-88.48296, 64.09897],
              [-89.91444, 64.03273],
              [-90.70398, 63.61017],
              [-90.77004, 62.96021],
              [-91.93342, 62.83508],
              [-93.15698, 62.02469],
              [-94.24153, 60.89865],
              [-94.62931, 60.11021],
              [-94.6846, 58.94882],
              [-93.21502, 58.78212],
              [-92.76462, 57.84571],
              [-92.29703, 57.08709],
              [-90.89769, 57.28468],
              [-89.03953, 56.85172],
              [-88.03978, 56.47162],
              [-87.32421, 55.99914],
              [-86.07121, 55.72383],
              [-85.01181, 55.3026],
              [-83.36055, 55.24489],
              [-82.27285, 55.14832],
              [-82.4362, 54.28227],
              [-82.12502, 53.27703],
              [-81.40075, 52.15788],
              [-79.91289, 51.20842],
              [-79.14301, 51.53393],
              [-78.60191, 52.56208],
              [-79.12421, 54.14145],
              [-79.82958, 54.66772],
              [-78.22874, 55.13645],
              [-77.0956, 55.83741],
              [-76.54137, 56.53423],
              [-76.62319, 57.20263],
              [-77.30226, 58.05209],
              [-78.51688, 58.80458],
              [-77.33676, 59.85261],
              [-77.77272, 60.75788],
              [-78.10687, 62.31964],
              [-77.41067, 62.55053],
              [-75.69621, 62.2784],
              [-74.6682, 62.18111],
              [-73.83988, 62.4438],
              [-72.90853, 62.10507],
              [-71.67708, 61.52535],
              [-71.37369, 61.13717],
              [-69.59042, 61.06141],
              [-69.62033, 60.22125],
              [-69.2879, 58.95736],
              [-68.37455, 58.80106],
              [-67.64976, 58.21206],
              [-66.20178, 58.76731],
              [-65.24517, 59.87071],
              [-64.58352, 60.33558],
              [-63.80475, 59.4426],
              [-62.50236, 58.16708],
              [-61.39655, 56.96745],
              [-61.79866, 56.33945],
              [-60.46853, 55.77548],
              [-59.56962, 55.20407],
              [-57.97508, 54.94549],
              [-57.3332, 54.6265],
              [-56.93689, 53.78032],
              [-56.15811, 53.64749],
              [-55.75632, 53.27036],
              [-55.68338, 52.14664],
              [-56.40916, 51.7707],
              [-57.12691, 51.41972],
              [-58.77482, 51.0643],
              [-60.03309, 50.24277],
              [-61.72366, 50.08046],
              [-63.86251, 50.29099],
              [-65.36331, 50.2982],
              [-66.39905, 50.22897],
              [-67.23631, 49.51156],
              [-68.51114, 49.06836],
              [-69.95362, 47.74488],
              [-71.10458, 46.82171],
              [-70.25522, 46.98606],
              [-68.65, 48.3],
              [-66.55243, 49.1331],
              [-65.05626, 49.23278],
              [-64.17099, 48.74248],
              [-65.11545, 48.07085],
              [-64.79854, 46.99297],
              [-64.47219, 46.23849],
              [-63.17329, 45.73902],
              [-61.52072, 45.88377],
              [-60.51815, 47.00793],
              [-60.4486, 46.28264],
              [-59.80287, 45.9204],
              [-61.03988, 45.26525],
              [-63.25471, 44.67014],
              [-64.24656, 44.26553],
              [-65.36406, 43.54523],
              [-66.1234, 43.61867],
              [-66.16173, 44.46512],
              [-64.42549, 45.29204],
              [-66.02605, 45.25931],
              [-67.13741, 45.13753],
              [-67.79134, 45.70281],
              [-67.79046, 47.06636],
              [-68.23444, 47.35486],
              [-68.905, 47.185],
              [-69.237216, 47.447781],
              [-69.99997, 46.69307],
              [-70.305, 45.915],
              [-70.66, 45.46],
              [-71.08482, 45.30524],
              [-71.405, 45.255],
              [-71.50506, 45.0082],
              [-73.34783, 45.00738],
              [-74.867, 45.00048],
              [-75.31821, 44.81645],
              [-76.375, 44.09631],
              [-76.5, 44.01845889375872],
              [-76.82003414580558, 43.628784288093755],
              [-77.7378850979577, 43.629055589363304],
              [-78.72027991404238, 43.625089423184875],
              [-79.17167355011188, 43.46633942318422],
              [-79.01, 43.27],
              [-78.92, 42.965],
              [-78.9393621487437, 42.86361135514804],
              [-80.24744767934794, 42.36619985612259],
              [-81.27774654816716, 42.20902598730686],
              [-82.43927771679162, 41.675105088867156],
              [-82.69008928092018, 41.675105088867156],
              [-83.02981014680694, 41.83279572200584],
              [-83.14199968131256, 41.975681057292825],
              [-83.12, 42.08],
              [-82.9, 42.43],
              [-82.43, 42.98],
              [-82.1376423815039, 43.571087551439916],
              [-82.33776312543108, 44.44],
              [-82.55092464875818, 45.347516587905375],
              [-83.59285071484308, 45.81689362241237],
              [-83.46955074739463, 45.99468638771259],
              [-83.61613094759059, 46.11692698829907],
              [-83.89076534700575, 46.11692698829907],
              [-84.09185126416148, 46.275418606138174],
              [-84.14211951367338, 46.51222585711574],
              [-84.3367, 46.40877],
              [-84.6049, 46.4396],
              [-84.54374874544587, 46.538684190449146],
              [-84.77923824739992, 46.637101955749046],
              [-84.87607988151485, 46.90008331968238],
              [-85.65236324740343, 47.22021881773051],
              [-86.46199083122826, 47.55333801939204],
              [-87.43979262330024, 47.94],
              [-88.37811418328673, 48.302917588893735],
              [-89.27291744663668, 48.019808254582664],
              [-89.6, 48.01],
              [-90.83, 48.27],
              [-91.64, 48.14],
              [-92.61, 48.45],
              [-93.63087, 48.60926],
              [-94.32914, 48.67074],
              [-94.64, 48.84],
              [-94.81758, 49.38905],
              [-95.15609, 49.38425],
              [-95.15906950917204, 49],
              [-97.22872000000481, 49.0007],
              [-100.65, 49],
              [-104.04826, 48.99986],
              [-107.05, 49],
              [-110.05, 49],
              [-113, 49],
              [-116.04818, 49],
              [-117.03121, 49],
              [-120, 49],
              [-122.84, 49],
              [-122.97421, 49.0025377777778],
              [-124.91024, 49.98456],
              [-125.62461, 50.41656],
              [-127.43561, 50.83061],
              [-127.99276, 51.71583],
              [-127.85032, 52.32961],
              [-129.12979, 52.75538],
              [-129.30523, 53.56159],
              [-130.51497, 54.28757],
              [-130.53611, 54.80278],
              [-129.98, 55.285],
              [-130.00778, 55.91583],
              [-131.70781, 56.55212],
              [-132.73042, 57.69289],
              [-133.35556, 58.41028],
              [-134.27111, 58.86111],
              [-134.945, 59.27056],
              [-135.47583, 59.78778],
              [-136.47972, 59.46389],
              [-137.4525, 58.905],
              [-138.34089, 59.56211],
              [-139.039, 60],
              [-140.013, 60.27682],
              [-140.99778, 60.30639],
              [-140.9925, 66.00003],
              [-140.986, 69.712],
              [-139.12052, 69.47102],
              [-137.54636, 68.99002],
              [-136.50358, 68.89804],
              [-135.62576, 69.31512],
              [-134.41464, 69.62743],
              [-132.92925, 69.50534],
              [-131.43136, 69.94451],
              [-129.79471, 70.19369],
              [-129.10773, 69.77927],
              [-128.36156, 70.01286],
              [-128.13817, 70.48384],
              [-127.44712, 70.37721],
              [-125.75632, 69.48058],
              [-124.42483, 70.1584],
              [-124.28968, 69.39969],
              [-123.06108, 69.56372],
              [-122.6835, 69.85553],
              [-121.47226, 69.79778],
              [-119.94288, 69.37786],
              [-117.60268, 69.01128],
              [-116.22643, 68.84151],
              [-115.2469, 68.90591],
              [-113.89794, 68.3989],
              [-115.30489, 67.90261],
              [-113.49727, 67.68815],
              [-110.798, 67.80612],
              [-109.94619, 67.98104],
              [-108.8802, 67.38144],
              [-107.79239, 67.88736],
              [-108.81299, 68.31164],
              [-108.16721, 68.65392],
              [-106.95, 68.7],
              [-106.15, 68.8],
              [-105.34282, 68.56122],
              [-104.33791, 68.018],
              [-103.22115, 68.09775],
              [-101.45433, 67.64689],
              [-99.90195, 67.80566],
              [-98.4432, 67.78165],
              [-98.5586, 68.40394],
              [-97.66948, 68.57864],
              [-96.11991, 68.23939],
              [-96.12588, 67.29338],
              [-95.48943, 68.0907],
              [-94.685, 68.06383],
              [-94.23282, 69.06903],
              [-95.30408, 69.68571],
              [-96.47131, 70.08976],
              [-96.39115, 71.19482],
              [-95.2088, 71.92053],
              [-93.88997, 71.76015],
              [-92.87818, 71.31869],
              [-91.51964, 70.19129],
              [-92.40692, 69.69997],
              [-90.5471, 69.49766],
            ],
          ],
          [
            [
              [-114.1671699999999, 73.12145],
              [-114.66634, 72.65277],
              [-112.44101999999988, 72.95540000000011],
              [-111.05039, 72.4504],
              [-109.92034999999989, 72.96113],
              [-109.00654, 72.63335],
              [-108.18835, 71.65089],
              [-107.68599, 72.06548],
              [-108.39639, 73.08953000000011],
              [-107.51645, 73.23598],
              [-106.52259, 73.07601],
              [-105.40246, 72.67259],
              [-104.77484, 71.6984],
              [-104.46475999999984, 70.99297],
              [-102.78537, 70.49776],
              [-100.9807799999999, 70.02432],
              [-101.08929, 69.58447000000012],
              [-102.73116, 69.50402],
              [-102.09329, 69.11962000000011],
              [-102.43024, 68.75282],
              [-104.24, 68.91],
              [-105.96, 69.18000000000015],
              [-107.12254, 69.11922],
              [-109, 68.78],
              [-111.53414887520013, 68.63005915681794],
              [-113.3132, 68.53554],
              [-113.85495999999983, 69.00744000000012],
              [-115.22, 69.28],
              [-116.10794, 69.16821],
              [-117.34, 69.96000000000012],
              [-116.67472999999988, 70.06655],
              [-115.13112, 70.2373],
              [-113.72141, 70.19237],
              [-112.4161, 70.36638],
              [-114.35, 70.6],
              [-116.48684, 70.52045],
              [-117.9048, 70.54056000000014],
              [-118.43238, 70.9092],
              [-116.11311, 71.30918],
              [-117.65568, 71.2952],
              [-119.40199, 71.55859],
              [-118.56267, 72.30785],
              [-117.86642, 72.70594],
              [-115.18909, 73.31459000000012],
              [-114.1671699999999, 73.12145],
            ],
          ],
          [
            [
              [-104.5, 73.42],
              [-105.38, 72.76],
              [-106.94, 73.46],
              [-106.6, 73.6],
              [-105.26, 73.64],
              [-104.5, 73.42],
            ],
          ],
          [
            [
              [-76.34, 73.10268498995302],
              [-76.25140380859375, 72.82638549804688],
              [-77.31443786621091, 72.85554504394527],
              [-78.39167022705081, 72.87665557861328],
              [-79.48625183105466, 72.74220275878909],
              [-79.77583312988284, 72.80290222167974],
              [-80.87609863281253, 73.3331832885742],
              [-80.83388519287105, 73.69318389892578],
              [-80.35305786132812, 73.75971984863278],
              [-78.06443786621094, 73.65193176269534],
              [-76.34, 73.10268498995302],
            ],
          ],
          [
            [
              [-86.56217851433414, 73.15744700793846],
              [-85.77437130404454, 72.53412588163383],
              [-84.85011247428824, 73.34027822538712],
              [-82.31559017610098, 73.75095083281059],
              [-80.60008765330764, 72.71654368762421],
              [-80.7489416165244, 72.06190664335077],
              [-78.77063859731078, 72.35217316353416],
              [-77.82462398955958, 72.74961660429105],
              [-75.60584469267573, 72.24367849393741],
              [-74.22861609566499, 71.7671442735579],
              [-74.09914079455771, 71.33084015571765],
              [-72.24222571479766, 71.5569245469945],
              [-71.20001542833519, 70.92001251899723],
              [-68.7860542466849, 70.52502370877426],
              [-67.91497046575694, 70.12194753689761],
              [-66.96903337265417, 69.18608734809189],
              [-68.80512285020055, 68.72019847276442],
              [-66.44986609563387, 68.06716339789202],
              [-64.86231441919522, 67.84753856065163],
              [-63.42493445499676, 66.92847321234066],
              [-61.85198137068058, 66.86212067327784],
              [-62.1631768459423, 66.16025136988961],
              [-63.918444383384184, 64.99866852483284],
              [-65.14886023625363, 65.42603261988668],
              [-66.72121904159854, 66.3880410834322],
              [-68.01501603867396, 66.26272573512439],
              [-68.14128740097917, 65.68978913030438],
              [-67.08964616562339, 65.108455105237],
              [-65.73208045109976, 64.64840566675863],
              [-65.32016760930128, 64.38273712834606],
              [-64.66940629744968, 63.39292674422748],
              [-65.01380388045891, 62.67418508569599],
              [-66.27504472519047, 62.945098781986076],
              [-68.78318620469273, 63.74567007105181],
              [-67.36968075221304, 62.883965562584876],
              [-66.3282972886672, 62.280074774822054],
              [-66.16556820338016, 61.93089712182589],
              [-68.87736650254465, 62.33014923771282],
              [-71.02343705919384, 62.91070811629584],
              [-72.235378587519, 63.39783600529517],
              [-71.8862784491713, 63.67998932560885],
              [-73.37830624051838, 64.19396312118383],
              [-74.8344189114226, 64.67907562932379],
              [-74.81850257027673, 64.38909332951798],
              [-77.70997982452005, 64.22954234481679],
              [-78.55594885935417, 64.57290639918014],
              [-77.89728105336192, 65.30919220647479],
              [-76.0182742987972, 65.32696889918316],
              [-73.95979529488272, 65.45476471624089],
              [-74.29388342964964, 65.8117713487294],
              [-73.94491248238265, 66.31057811142672],
              [-72.65116716173941, 67.28457550726387],
              [-72.92605994331609, 67.72692576768239],
              [-73.31161780464575, 68.06943716091291],
              [-74.84330725777681, 68.55462718370129],
              [-76.86910091826674, 68.89473562283027],
              [-76.22864905465735, 69.14776927354742],
              [-77.28736996123712, 69.76954010688328],
              [-78.1686339993266, 69.82648753526891],
              [-78.95724219431673, 70.16688019477542],
              [-79.49245500356366, 69.87180776638891],
              [-81.30547095409176, 69.74318512641435],
              [-84.94470618359847, 69.9666340196444],
              [-87.06000342481789, 70.26000112576537],
              [-88.6817132230015, 70.41074127876081],
              [-89.51341956252304, 70.76203766548099],
              [-88.46772111688075, 71.21818553332133],
              [-89.8881512112875, 71.22255219184996],
              [-90.20516028518202, 72.2350743679608],
              [-89.43657670770494, 73.12946421985237],
              [-88.40824154331281, 73.53788890247121],
              [-85.82615108920092, 73.80381582304521],
              [-86.56217851433414, 73.15744700793846],
            ],
          ],
          [
            [
              [-100.35642, 73.84389],
              [-99.16387, 73.63339],
              [-97.38, 73.76],
              [-97.12, 73.47],
              [-98.05359, 72.99052],
              [-96.54, 72.56],
              [-96.72, 71.66],
              [-98.35966, 71.27285],
              [-99.32286, 71.35639],
              [-100.01482, 71.73827],
              [-102.5, 72.51],
              [-102.48, 72.83],
              [-100.43836, 72.70588],
              [-101.54, 73.36],
              [-100.35642, 73.84389],
            ],
          ],
          [
            [
              [-93.19629553910022, 72.77199249947336],
              [-94.26904659704726, 72.02459625923598],
              [-95.40985551632266, 72.06188080513459],
              [-96.03374508338246, 72.94027680123182],
              [-96.01826799191099, 73.4374299180958],
              [-95.49579342322403, 73.86241689726418],
              [-94.50365759965234, 74.1349067247392],
              [-92.42001217321177, 74.10002513294219],
              [-90.50979285354259, 73.85673248971203],
              [-92.0039652168299, 72.9662442084585],
              [-93.19629553910022, 72.77199249947336],
            ],
          ],
          [
            [
              [-120.46, 71.38360179308759],
              [-123.09219, 70.90164],
              [-123.62, 71.34],
              [-125.92894873747335, 71.86868846301141],
              [-125.5, 72.29226081179502],
              [-124.80729, 73.02256],
              [-123.9399999999999, 73.68000000000015],
              [-124.91775, 74.29275000000013],
              [-121.53788, 74.44893],
              [-120.10978, 74.24135],
              [-117.55563999999987, 74.18577],
              [-116.58442, 73.89607],
              [-115.51081, 73.47519],
              [-116.76793999999988, 73.22292],
              [-119.22, 72.52],
              [-120.46, 71.82],
              [-120.46, 71.38360179308759],
            ],
          ],
          [
            [
              [-93.61275590694049, 74.97999726022445],
              [-94.15690873897384, 74.59234650338686],
              [-95.60868058956561, 74.66686391875177],
              [-96.82093217648458, 74.92762319609658],
              [-96.2885874092298, 75.37782827422335],
              [-94.85081987178913, 75.6472175157609],
              [-93.97774654821794, 75.29648956979597],
              [-93.61275590694049, 74.97999726022445],
            ],
          ],
          [
            [
              [-98.5, 76.72],
              [-97.735585, 76.25656],
              [-97.704415, 75.74344],
              [-98.16, 75],
              [-99.80874, 74.89744],
              [-100.88366, 75.05736],
              [-100.86292, 75.64075],
              [-102.50209, 75.5638],
              [-102.56552, 76.3366],
              [-101.48973, 76.30537],
              [-99.98349, 76.64634],
              [-98.57699, 76.58859],
              [-98.5, 76.72],
            ],
          ],
          [
            [
              [-108.21141, 76.20168],
              [-107.81943, 75.84552],
              [-106.92893, 76.01282],
              [-105.881, 75.9694],
              [-105.70498, 75.47951],
              [-106.31347, 75.00527],
              [-109.7, 74.85],
              [-112.22307, 74.41696],
              [-113.74381, 74.39427],
              [-113.87135, 74.72029],
              [-111.79421, 75.1625],
              [-116.31221, 75.04343],
              [-117.7104, 75.2222],
              [-116.34602, 76.19903],
              [-115.40487, 76.47887],
              [-112.59056, 76.14134],
              [-110.81422, 75.54919],
              [-109.0671, 75.47321],
              [-110.49726, 76.42982],
              [-109.5811, 76.79417],
              [-108.54859, 76.67832],
              [-108.21141, 76.20168],
            ],
          ],
          [
            [
              [-94.68408586299947, 77.09787832305838],
              [-93.57392106807313, 76.77629588490609],
              [-91.60502315953661, 76.77851797149461],
              [-90.74184587274922, 76.44959747995681],
              [-90.96966142450799, 76.07401317005946],
              [-89.82223792189927, 75.84777374948563],
              [-89.18708289259979, 75.61016551380763],
              [-87.83827633334963, 75.56618886992723],
              [-86.37919226758868, 75.48242137318218],
              [-84.78962521029061, 75.69920400664651],
              [-82.75344458691006, 75.78431509063125],
              [-81.12853084992437, 75.71398346628203],
              [-80.05751095245915, 75.33684886341588],
              [-79.83393286814832, 74.92312734648719],
              [-80.45777075877584, 74.65730377877779],
              [-81.94884253612554, 74.44245901152433],
              [-83.22889360221143, 74.56402781849096],
              [-86.0974523587333, 74.41003205026115],
              [-88.15035030796022, 74.39230703398499],
              [-89.76472205275837, 74.51555532500115],
              [-92.42244096552943, 74.837757880341],
              [-92.7682854886428, 75.38681997344216],
              [-92.88990597204173, 75.88265534128266],
              [-93.893824022176, 76.31924367950054],
              [-95.96245744503582, 76.44138092722247],
              [-97.12137895382949, 76.75107778594761],
              [-96.74512285031236, 77.16138865834515],
              [-94.68408586299947, 77.09787832305838],
            ],
          ],
          [
            [
              [-116.19858659550734, 77.64528677032621],
              [-116.33581336145838, 76.87696157501055],
              [-117.10605058476878, 76.53003184681913],
              [-118.04041215703813, 76.4811717800871],
              [-119.89931758688569, 76.05321340606199],
              [-121.4999950771265, 75.9000186225328],
              [-122.85492448615896, 76.11654287383568],
              [-122.8549252936032, 76.11654287383568],
              [-121.15753536032825, 76.86450755482835],
              [-119.10393897182104, 77.51221995717464],
              [-117.57013078496597, 77.4983189968881],
              [-116.19858659550734, 77.64528677032621],
            ],
          ],
          [
            [
              [-93.84000301794399, 77.5199972602345],
              [-94.29560828324526, 77.4913426785287],
              [-96.16965410031008, 77.5551113959769],
              [-96.43630449093612, 77.83462921824362],
              [-94.42257727738638, 77.82000478790499],
              [-93.72065629756588, 77.63433136668033],
              [-93.84000301794399, 77.5199972602345],
            ],
          ],
          [
            [
              [-110.18693803591297, 77.6970148790503],
              [-112.05119116905848, 77.40922882761686],
              [-113.53427893761906, 77.73220652944116],
              [-112.72458675825384, 78.05105011668195],
              [-111.26444332563085, 78.15295604116156],
              [-109.8544518705471, 77.99632477488484],
              [-110.18693803591297, 77.6970148790503],
            ],
          ],
          [
            [
              [-109.66314571820259, 78.60197256134569],
              [-110.88131425661886, 78.40691986766001],
              [-112.54209143761517, 78.4079017198735],
              [-112.5258908760916, 78.55055451121522],
              [-111.5000103422334, 78.84999359813057],
              [-110.96366065147602, 78.80444082306522],
              [-109.66314571820259, 78.60197256134569],
            ],
          ],
          [
            [
              [-95.83029496944934, 78.05694122996326],
              [-97.30984290239799, 77.85059723582178],
              [-98.12428931353396, 78.08285696075758],
              [-98.55286780474664, 78.4581053738451],
              [-98.63198442258552, 78.87193024363839],
              [-97.33723141151262, 78.83198436147677],
              [-96.75439876990879, 78.765812689927],
              [-95.55927792029458, 78.41831452098029],
              [-95.83029496944934, 78.05694122996326],
            ],
          ],
          [
            [
              [-100.06019182005214, 78.3247543403159],
              [-99.67093909381362, 77.9075446642074],
              [-101.30394019245301, 78.01898489044481],
              [-102.94980872273305, 78.34322866486022],
              [-105.17613277873154, 78.38033234324574],
              [-104.21042945027716, 78.6774201524918],
              [-105.41958045125854, 78.91833567983645],
              [-105.49228919149316, 79.30159393992919],
              [-103.52928239623793, 79.16534902619165],
              [-100.82515804726881, 78.80046173777869],
              [-100.06019182005214, 78.3247543403159],
            ],
          ],
          [
            [
              [-87.02, 79.66],
              [-85.81435, 79.3369],
              [-87.18756, 79.0393],
              [-89.03535, 78.28723],
              [-90.80436, 78.21533],
              [-92.87669, 78.34333],
              [-93.95116, 78.75099],
              [-93.93574, 79.11373],
              [-93.14524, 79.3801],
              [-94.974, 79.37248],
              [-96.07614, 79.70502],
              [-96.70972, 80.15777],
              [-96.01644, 80.60233],
              [-95.32345, 80.90729],
              [-94.29843, 80.97727],
              [-94.73542, 81.20646],
              [-92.40984, 81.25739],
              [-91.13289, 80.72345],
              [-89.45, 80.50932203389829],
              [-87.81, 80.32],
              [-87.02, 79.66],
            ],
          ],
          [
            [
              [-68.5, 83.10632151676575],
              [-65.82735, 83.02801],
              [-63.68, 82.9],
              [-61.85, 82.6286],
              [-61.89388, 82.36165],
              [-64.334, 81.92775],
              [-66.75342, 81.72527],
              [-67.65755, 81.50141],
              [-65.48031, 81.50657],
              [-67.84, 80.9],
              [-69.4697, 80.61683],
              [-71.18, 79.8],
              [-73.2428, 79.63415],
              [-73.88, 79.43016220480207],
              [-76.90773, 79.32309],
              [-75.52924, 79.19766],
              [-76.22046, 79.01907],
              [-75.39345, 78.52581],
              [-76.34354, 78.18296],
              [-77.88851, 77.89991],
              [-78.36269, 77.50859],
              [-79.75951, 77.20968],
              [-79.61965, 76.98336],
              [-77.91089, 77.022045],
              [-77.88911, 76.777955],
              [-80.56125, 76.17812],
              [-83.17439, 76.45403],
              [-86.11184, 76.29901],
              [-87.6, 76.42],
              [-89.49068, 76.47239],
              [-89.6161, 76.95213],
              [-87.76739, 77.17833],
              [-88.26, 77.9],
              [-87.65, 77.97022222222222],
              [-84.97634, 77.53873],
              [-86.34, 78.18],
              [-87.96192, 78.37181],
              [-87.15198, 78.75867],
              [-85.37868, 78.9969],
              [-85.09495, 79.34543],
              [-86.50734, 79.73624],
              [-86.93179, 80.25145],
              [-84.19844, 80.20836],
              [-83.40869565217383, 80.1],
              [-81.84823, 80.46442],
              [-84.1, 80.58],
              [-87.59895, 80.51627],
              [-89.36663, 80.85569],
              [-90.2, 81.26],
              [-91.36786, 81.5531],
              [-91.58702, 81.89429],
              [-90.1, 82.085],
              [-88.93227, 82.11751],
              [-86.97024, 82.27961],
              [-85.5, 82.65227345805704],
              [-84.260005, 82.6],
              [-83.18, 82.32],
              [-82.42, 82.86],
              [-81.1, 83.02],
              [-79.30664, 83.13056],
              [-76.25, 83.1720588235294],
              [-75.71878, 83.06404],
              [-72.83153, 83.23324],
              [-70.665765, 83.16978075838284],
              [-68.5, 83.10632151676575],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "The Bahamas",
        sov_a3: "BHS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "The Bahamas",
        adm0_a3: "BHS",
        geou_dif: 0,
        geounit: "The Bahamas",
        gu_a3: "BHS",
        su_dif: 0,
        subunit: "The Bahamas",
        su_a3: "BHS",
        brk_diff: 0,
        name: "Bahamas",
        name_long: "Bahamas",
        brk_a3: "BHS",
        brk_name: "Bahamas",
        brk_group: null,
        abbrev: "Bhs.",
        postal: "BS",
        formal_en: "Commonwealth of the Bahamas",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Bahamas, The",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 309156,
        gdp_md_est: 9093,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BS",
        iso_a3: "BHS",
        iso_n3: "044",
        un_a3: "044",
        wb_a2: "BS",
        wb_a3: "BHS",
        woe_id: -99,
        adm0_a3_is: "BHS",
        adm0_a3_us: "BHS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "BHS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-77.53466, 23.75975],
              [-77.78, 23.71],
              [-78.03405, 24.28615],
              [-78.40848, 24.57564],
              [-78.19087, 25.2103],
              [-77.89, 25.17],
              [-77.54, 24.34],
              [-77.53466, 23.75975],
            ],
          ],
          [
            [
              [-77.82, 26.58],
              [-78.91, 26.42],
              [-78.98, 26.79],
              [-78.51, 26.87],
              [-77.85, 26.84],
              [-77.82, 26.58],
            ],
          ],
          [
            [
              [-77, 26.59],
              [-77.17255, 25.87918],
              [-77.35641, 26.00735],
              [-77.34, 26.53],
              [-77.78802, 26.92516],
              [-77.79, 27.04],
              [-77, 26.59],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Costa Rica",
        sov_a3: "CRI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Costa Rica",
        adm0_a3: "CRI",
        geou_dif: 0,
        geounit: "Costa Rica",
        gu_a3: "CRI",
        su_dif: 0,
        subunit: "Costa Rica",
        su_a3: "CRI",
        brk_diff: 0,
        name: "Costa Rica",
        name_long: "Costa Rica",
        brk_a3: "CRI",
        brk_name: "Costa Rica",
        brk_group: null,
        abbrev: "C.R.",
        postal: "CR",
        formal_en: "Republic of Costa Rica",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Costa Rica",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 2,
        pop_est: 4253877,
        gdp_md_est: 48320,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CR",
        iso_a3: "CRI",
        iso_n3: "188",
        un_a3: "188",
        wb_a2: "CR",
        wb_a3: "CRI",
        woe_id: -99,
        adm0_a3_is: "CRI",
        adm0_a3_us: "CRI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "CRI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.96578304719736, 8.225027980985985],
            [-83.50843726269431, 8.446926581247283],
            [-83.71147396516908, 8.656836249216866],
            [-83.59631303580665, 8.830443223501419],
            [-83.63264156770784, 9.051385809765321],
            [-83.90988562695372, 9.29080272057358],
            [-84.30340165885636, 9.487354030795714],
            [-84.64764421256866, 9.615537421095707],
            [-84.71335079622777, 9.908051866083852],
            [-84.97566036654133, 10.086723130733006],
            [-84.91137488477024, 9.795991522658923],
            [-85.11092342806532, 9.55703969974131],
            [-85.33948828809227, 9.83454214114866],
            [-85.66078650586698, 9.933347479690724],
            [-85.79744483106285, 10.134885565629034],
            [-85.79170874707843, 10.439337266476613],
            [-85.65931372754666, 10.75433095951172],
            [-85.94172543002176, 10.895278428587801],
            [-85.7125404528073, 11.088444932494824],
            [-85.56185197624418, 11.217119248901597],
            [-84.90300330273895, 10.952303371621896],
            [-84.67306901725627, 11.082657172078143],
            [-84.35593075228104, 10.999225572142905],
            [-84.19017859570485, 10.793450018756674],
            [-83.89505449088595, 10.726839097532446],
            [-83.65561174186158, 10.938764146361422],
            [-83.40231970898296, 10.395438137244652],
            [-83.01567664257517, 9.992982082555555],
            [-82.54619625520348, 9.566134751824677],
            [-82.93289099804358, 9.476812038608173],
            [-82.92715491405916, 9.074330145702916],
            [-82.71918311230053, 8.925708726431495],
            [-82.86865719270477, 8.807266343618522],
            [-82.82977067740516, 8.62629547773237],
            [-82.91317643912421, 8.42351715741907],
            [-82.96578304719736, 8.225027980985985],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Cuba",
        sov_a3: "CUB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Cuba",
        adm0_a3: "CUB",
        geou_dif: 0,
        geounit: "Cuba",
        gu_a3: "CUB",
        su_dif: 0,
        subunit: "Cuba",
        su_a3: "CUB",
        brk_diff: 0,
        name: "Cuba",
        name_long: "Cuba",
        brk_a3: "CUB",
        brk_name: "Cuba",
        brk_group: null,
        abbrev: "Cuba",
        postal: "CU",
        formal_en: "Republic of Cuba",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Cuba",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 4,
        pop_est: 11451652,
        gdp_md_est: 108200,
        pop_year: -99,
        lastcensus: 2002,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CU",
        iso_a3: "CUB",
        iso_n3: "192",
        un_a3: "192",
        wb_a2: "CU",
        wb_a3: "CUB",
        woe_id: -99,
        adm0_a3_is: "CUB",
        adm0_a3_us: "CUB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "CUB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.26815121125706, 23.188610744717703],
            [-81.40445716014683, 23.11727142993878],
            [-80.6187686835812, 23.105980129483],
            [-79.67952368846025, 22.76530324959883],
            [-79.28148596873207, 22.399201565027056],
            [-78.34743445505649, 22.512166246017088],
            [-77.99329586456028, 22.277193508385935],
            [-77.14642249216105, 21.657851467367834],
            [-76.52382483590856, 21.206819566324373],
            [-76.19462012399319, 21.220565497314013],
            [-75.59822241891267, 21.016624457274133],
            [-75.67106035022806, 20.735091254148],
            [-74.9338960435845, 20.693905137611385],
            [-74.17802486845126, 20.28462779385974],
            [-74.29664811877724, 20.05037852628068],
            [-74.96159461129294, 19.92343537035569],
            [-75.63468014189459, 19.873774318923196],
            [-76.323656175426, 19.95289093676206],
            [-77.75548092315306, 19.855480861891873],
            [-77.08510840524674, 20.413353786698792],
            [-77.49265458851661, 20.67310537361389],
            [-78.13729224314159, 20.73994883878343],
            [-78.48282670766119, 21.02861338956585],
            [-78.71986650258401, 21.598113511638434],
            [-79.28499996612794, 21.5591753199065],
            [-80.21747534861865, 21.827324327069036],
            [-80.51753455272141, 22.03707896574176],
            [-81.82094336620318, 22.19205658618507],
            [-82.16999182811864, 22.38710927987075],
            [-81.79500179719267, 22.636964830001958],
            [-82.77589799674084, 22.688150336187064],
            [-83.49445878775936, 22.16851797127613],
            [-83.90880042187563, 22.154565334557333],
            [-84.05215084505326, 21.910575059491254],
            [-84.54703019889638, 21.801227728761642],
            [-84.97491105827311, 21.89602814380109],
            [-84.44706214062776, 22.204949856041907],
            [-84.23035702181178, 22.565754706303764],
            [-83.7782399156902, 22.788118394455694],
            [-83.26754757356575, 22.983041897060644],
            [-82.51043616405751, 23.078746649665188],
            [-82.26815121125706, 23.188610744717703],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Dominican Republic",
        sov_a3: "DOM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Dominican Republic",
        adm0_a3: "DOM",
        geou_dif: 0,
        geounit: "Dominican Republic",
        gu_a3: "DOM",
        su_dif: 0,
        subunit: "Dominican Republic",
        su_a3: "DOM",
        brk_diff: 0,
        name: "Dominican Rep.",
        name_long: "Dominican Republic",
        brk_a3: "DOM",
        brk_name: "Dominican Rep.",
        brk_group: null,
        abbrev: "Dom. Rep.",
        postal: "DO",
        formal_en: "Dominican Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Dominican Republic",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 7,
        pop_est: 9650054,
        gdp_md_est: 78000,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "DO",
        iso_a3: "DOM",
        iso_n3: "214",
        un_a3: "214",
        wb_a2: "DO",
        wb_a3: "DOM",
        woe_id: -99,
        adm0_a3_is: "DOM",
        adm0_a3_us: "DOM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 14,
        long_len: 18,
        abbrev_len: 9,
        tiny: -99,
        homepart: 1,
        filename: "DOM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.71236141629296, 19.714455878167357],
            [-71.58730445014663, 19.8849105900821],
            [-70.80670610216174, 19.880285549391985],
            [-70.21436499701613, 19.62288524014616],
            [-69.95081519232758, 19.647999986240006],
            [-69.76925004747008, 19.293267116772437],
            [-69.22212582057988, 19.313214219637103],
            [-69.25434607611385, 19.015196234609874],
            [-68.80941199408083, 18.979074408437853],
            [-68.31794328476897, 18.612197577381693],
            [-68.68931596543452, 18.205142320218613],
            [-69.16494584824892, 18.42264842373511],
            [-69.62398759629764, 18.380712998930246],
            [-69.95293392605154, 18.42830699307106],
            [-70.1332329983179, 18.245915025296895],
            [-70.51713721381422, 18.184290879788833],
            [-70.66929846869763, 18.426885891183034],
            [-70.99995012071719, 18.283328762276213],
            [-71.4002099270339, 17.5985643579766],
            [-71.65766191271202, 17.7575727401387],
            [-71.70830481635805, 18.04499705654609],
            [-71.68773759630587, 18.31666006110447],
            [-71.94511206733556, 18.61690013272026],
            [-71.70130265978248, 18.78541697842405],
            [-71.62487321642283, 19.169837958243306],
            [-71.71236141629296, 19.714455878167357],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Denmark",
        sov_a3: "DN1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        admin: "Greenland",
        adm0_a3: "GRL",
        geou_dif: 0,
        geounit: "Greenland",
        gu_a3: "GRL",
        su_dif: 0,
        subunit: "Greenland",
        su_a3: "GRL",
        brk_diff: 0,
        name: "Greenland",
        name_long: "Greenland",
        brk_a3: "GRL",
        brk_name: "Greenland",
        brk_group: null,
        abbrev: "Grlnd.",
        postal: "GL",
        formal_en: "Greenland",
        formal_fr: null,
        note_adm0: "Den.",
        note_brk: null,
        name_sort: "Greenland",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 12,
        pop_est: 57600,
        gdp_md_est: 1100,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GL",
        iso_a3: "GRL",
        iso_n3: "304",
        un_a3: "304",
        wb_a2: "GL",
        wb_a3: "GRL",
        woe_id: -99,
        adm0_a3_is: "GRL",
        adm0_a3_us: "GRL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Northern America",
        region_wb: "Europe & Central Asia",
        name_len: 9,
        long_len: 9,
        abbrev_len: 6,
        tiny: -99,
        homepart: -99,
        filename: "GRL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.76379, 82.62796],
            [-43.40644, 83.22516],
            [-39.89753, 83.18018],
            [-38.62214, 83.54905],
            [-35.08787, 83.64513],
            [-27.10046, 83.51966],
            [-20.84539, 82.72669],
            [-22.69182, 82.34165],
            [-26.51753, 82.29765],
            [-31.9, 82.2],
            [-31.39646, 82.02154],
            [-27.85666, 82.13178],
            [-24.84448, 81.78697],
            [-22.90328, 82.09317],
            [-22.07175, 81.73449],
            [-23.16961, 81.15271],
            [-20.62363, 81.52462],
            [-15.76818, 81.91245],
            [-12.77018, 81.71885],
            [-12.20855, 81.29154],
            [-16.28533, 80.58004],
            [-16.85, 80.35],
            [-20.04624, 80.17708],
            [-17.73035, 80.12912],
            [-18.9, 79.4],
            [-19.70499, 78.75128],
            [-19.67353, 77.63859],
            [-18.47285, 76.98565],
            [-20.03503, 76.94434],
            [-21.67944, 76.62795],
            [-19.83407, 76.09808],
            [-19.59896, 75.24838],
            [-20.66818, 75.15585],
            [-19.37281, 74.29561],
            [-21.59422, 74.22382],
            [-20.43454, 73.81713],
            [-20.76234, 73.46436],
            [-22.17221, 73.30955],
            [-23.56593, 73.30663],
            [-22.31311, 72.62928],
            [-22.29954, 72.18409],
            [-24.27834, 72.59788],
            [-24.79296, 72.3302],
            [-23.44296, 72.08016],
            [-22.13281, 71.46898],
            [-21.75356, 70.66369],
            [-23.53603, 70.471],
            [-24.30702, 70.85649],
            [-25.54341, 71.43094],
            [-25.20135, 70.75226],
            [-26.36276, 70.22646],
            [-23.72742, 70.18401],
            [-22.34902, 70.12946],
            [-25.02927, 69.2588],
            [-27.74737, 68.47046],
            [-30.67371, 68.12503],
            [-31.77665, 68.12078],
            [-32.81105, 67.73547],
            [-34.20196, 66.67974],
            [-36.35284, 65.9789],
            [-37.04378, 65.93768],
            [-38.37505, 65.69213],
            [-39.81222, 65.45848],
            [-40.66899, 64.83997],
            [-40.68281, 64.13902],
            [-41.1887, 63.48246],
            [-42.81938, 62.68233],
            [-42.41666, 61.90093],
            [-42.86619, 61.07404],
            [-43.3784, 60.09772],
            [-44.7875, 60.03676],
            [-46.26364, 60.85328],
            [-48.26294, 60.85843],
            [-49.23308, 61.40681],
            [-49.90039, 62.38336],
            [-51.63325, 63.62691],
            [-52.14014, 64.27842],
            [-52.27659, 65.1767],
            [-53.66166, 66.09957],
            [-53.30161, 66.8365],
            [-53.96911, 67.18899],
            [-52.9804, 68.35759],
            [-51.47536, 68.72958],
            [-51.08041, 69.14781],
            [-50.87122, 69.9291],
            [-52.013585, 69.574925],
            [-52.55792, 69.42616],
            [-53.45629, 69.283625],
            [-54.68336, 69.61003],
            [-54.75001, 70.28932],
            [-54.35884, 70.821315],
            [-53.431315, 70.835755],
            [-51.39014, 70.56978],
            [-53.10937, 71.20485],
            [-54.00422, 71.54719],
            [-55, 71.40653696727257],
            [-55.83468, 71.65444],
            [-54.71819, 72.58625],
            [-55.32634, 72.95861],
            [-56.12003, 73.64977],
            [-57.32363, 74.71026],
            [-58.59679, 75.09861],
            [-58.58516, 75.51727],
            [-61.26861, 76.10238],
            [-63.39165, 76.1752],
            [-66.06427, 76.13486],
            [-68.50438, 76.06141],
            [-69.66485, 76.37975],
            [-71.40257, 77.00857],
            [-68.77671, 77.32312],
            [-66.76397, 77.37595],
            [-71.04293, 77.63595],
            [-73.297, 78.04419],
            [-73.15938, 78.43271],
            [-69.37345, 78.91388],
            [-65.7107, 79.39436],
            [-65.3239, 79.75814],
            [-68.02298, 80.11721],
            [-67.15129, 80.51582],
            [-63.68925, 81.21396],
            [-62.23444, 81.3211],
            [-62.65116, 81.77042],
            [-60.28249, 82.03363],
            [-57.20744, 82.19074],
            [-54.13442, 82.19962],
            [-53.04328, 81.88833],
            [-50.39061, 82.43883],
            [-48.00386, 82.06481],
            [-46.59984, 81.985945],
            [-44.523, 81.6607],
            [-46.9007, 82.19979],
            [-46.76379, 82.62796],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Guatemala",
        sov_a3: "GTM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Guatemala",
        adm0_a3: "GTM",
        geou_dif: 0,
        geounit: "Guatemala",
        gu_a3: "GTM",
        su_dif: 0,
        subunit: "Guatemala",
        su_a3: "GTM",
        brk_diff: 0,
        name: "Guatemala",
        name_long: "Guatemala",
        brk_a3: "GTM",
        brk_name: "Guatemala",
        brk_group: null,
        abbrev: "Guat.",
        postal: "GT",
        formal_en: "Republic of Guatemala",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Guatemala",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 6,
        pop_est: 13276517,
        gdp_md_est: 68580,
        pop_year: -99,
        lastcensus: 2002,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GT",
        iso_a3: "GTM",
        iso_n3: "320",
        un_a3: "320",
        wb_a2: "GT",
        wb_a3: "GTM",
        woe_id: -99,
        adm0_a3_is: "GTM",
        adm0_a3_us: "GTM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 5,
        tiny: 4,
        homepart: 1,
        filename: "GTM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-90.09555457229098, 13.735337632700734],
            [-90.60862403030085, 13.909771429901951],
            [-91.23241024449605, 13.927832342987957],
            [-91.68974667027912, 14.126218166556455],
            [-92.22775000686983, 14.538828640190928],
            [-92.20322953974731, 14.830102850804069],
            [-92.08721594925207, 15.06458466232844],
            [-92.22924862340628, 15.25144664149586],
            [-91.74796017125591, 16.066564846251723],
            [-90.46447262242265, 16.069562079324655],
            [-90.43886695022204, 16.410109768128095],
            [-90.60084672724092, 16.47077789963876],
            [-90.71182186558772, 16.687483018454728],
            [-91.08167009150065, 16.918476670799404],
            [-91.45392127151516, 17.252177232324172],
            [-91.0022692532842, 17.25465770107418],
            [-91.00151994501596, 17.81759491624571],
            [-90.06793351923098, 17.819326076727474],
            [-89.14308041050332, 17.80831899664932],
            [-89.15080603713095, 17.015576687075836],
            [-89.22912167026928, 15.88693756760517],
            [-88.93061275913527, 15.887273464415074],
            [-88.60458614780583, 15.70638011317736],
            [-88.51836402052686, 15.855389105690975],
            [-88.22502275262202, 15.727722479713902],
            [-88.68067969435563, 15.346247056535304],
            [-89.15481096063357, 15.06641917567481],
            [-89.22522009963127, 14.874286200413621],
            [-89.14553504103718, 14.678019110569084],
            [-89.35332597528279, 14.424132798719116],
            [-89.58734269891654, 14.362586167859488],
            [-89.53421932652051, 14.244815578666305],
            [-89.72193396682073, 14.134228013561694],
            [-90.0646779039966, 13.881969509328924],
            [-90.09555457229098, 13.735337632700734],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Honduras",
        sov_a3: "HND",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Honduras",
        adm0_a3: "HND",
        geou_dif: 0,
        geounit: "Honduras",
        gu_a3: "HND",
        su_dif: 0,
        subunit: "Honduras",
        su_a3: "HND",
        brk_diff: 0,
        name: "Honduras",
        name_long: "Honduras",
        brk_a3: "HND",
        brk_name: "Honduras",
        brk_group: null,
        abbrev: "Hond.",
        postal: "HN",
        formal_en: "Republic of Honduras",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Honduras",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 7792854,
        gdp_md_est: 33720,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "HN",
        iso_a3: "HND",
        iso_n3: "340",
        un_a3: "340",
        wb_a2: "HN",
        wb_a3: "HND",
        woe_id: -99,
        adm0_a3_is: "HND",
        adm0_a3_us: "HND",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "HND.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.31665442579549, 12.984685777229004],
            [-87.48940873894713, 13.297534898323931],
            [-87.79311113152653, 13.384480495655168],
            [-87.72350297722932, 13.785050360565606],
            [-87.85951534702161, 13.893312486217097],
            [-88.06534257684012, 13.96462596277979],
            [-88.50399797234962, 13.845485948130943],
            [-88.54123084181595, 13.980154730683523],
            [-88.84307288283276, 14.140506700085211],
            [-89.05851192905766, 14.340029405164215],
            [-89.35332597528281, 14.424132798719086],
            [-89.14553504103719, 14.678019110569153],
            [-89.22522009963124, 14.874286200413678],
            [-89.15481096063353, 15.066419175674866],
            [-88.6806796943556, 15.34624705653539],
            [-88.22502275262195, 15.72772247971403],
            [-88.12115312371537, 15.688655096901359],
            [-87.90181250685241, 15.864458319558196],
            [-87.61568010125234, 15.8787985295192],
            [-87.52292090528846, 15.797278957578783],
            [-87.36776241733213, 15.84694000901129],
            [-86.90319129102818, 15.756712958229569],
            [-86.44094560417739, 15.78283539475319],
            [-86.11923397494434, 15.893448798073962],
            [-86.00195431185784, 16.00540578863439],
            [-85.68331743034628, 15.953651841693953],
            [-85.44400387240256, 15.885749009662446],
            [-85.18244361035721, 15.90915843349063],
            [-84.98372188997882, 15.995923163308701],
            [-84.52697974316715, 15.857223619037427],
            [-84.36825558138258, 15.835157782448732],
            [-84.06305457226682, 15.648244126849136],
            [-83.77397661002612, 15.42407176356687],
            [-83.41038123242036, 15.270902818253774],
            [-83.14721900097413, 14.99582916916421],
            [-83.48998877636602, 15.016267198135663],
            [-83.62858496777288, 14.880073960830371],
            [-83.97572140169359, 14.749435939996486],
            [-84.22834164095241, 14.74876414637663],
            [-84.4493359036486, 14.621614284722511],
            [-84.64958207877963, 14.666805324761867],
            [-84.8200367906943, 14.81958669683263],
            [-84.92450069857233, 14.790492865452336],
            [-85.05278744173688, 14.551541042534723],
            [-85.14875057650288, 14.560196844943619],
            [-85.16536454948482, 14.35436961512505],
            [-85.51441301140028, 14.079011745657908],
            [-85.69866533073696, 13.960078436738002],
            [-85.8012947252685, 13.836054999237604],
            [-86.09626380079061, 14.038187364147234],
            [-86.31214209668985, 13.771356106008225],
            [-86.52070817741992, 13.778487453664468],
            [-86.75508663607962, 13.75484548589094],
            [-86.73382178419149, 13.263092556201398],
            [-86.88055701368438, 13.254204209847217],
            [-87.00576900912743, 13.025794379117258],
            [-87.31665442579549, 12.984685777229004],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Haiti",
        sov_a3: "HTI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Haiti",
        adm0_a3: "HTI",
        geou_dif: 0,
        geounit: "Haiti",
        gu_a3: "HTI",
        su_dif: 0,
        subunit: "Haiti",
        su_a3: "HTI",
        brk_diff: 0,
        name: "Haiti",
        name_long: "Haiti",
        brk_a3: "HTI",
        brk_name: "Haiti",
        brk_group: null,
        abbrev: "Haiti",
        postal: "HT",
        formal_en: "Republic of Haiti",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Haiti",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 9035536,
        gdp_md_est: 11500,
        pop_year: -99,
        lastcensus: 2003,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "HT",
        iso_a3: "HTI",
        iso_n3: "332",
        un_a3: "332",
        wb_a2: "HT",
        wb_a3: "HTI",
        woe_id: -99,
        adm0_a3_is: "HTI",
        adm0_a3_us: "HTI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "HTI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.18979061551762, 19.915683905511912],
            [-72.57967281766362, 19.871500555902358],
            [-71.71236141629296, 19.714455878167357],
            [-71.62487321642283, 19.169837958243306],
            [-71.70130265978248, 18.78541697842405],
            [-71.94511206733556, 18.61690013272026],
            [-71.68773759630587, 18.31666006110447],
            [-71.70830481635805, 18.04499705654609],
            [-72.37247616238935, 18.21496084235406],
            [-72.84441118029488, 18.14561107021836],
            [-73.45455481636503, 18.217906398994696],
            [-73.92243323433566, 18.030992743395004],
            [-74.45803361682478, 18.342549953682706],
            [-74.36992529976713, 18.66490753831941],
            [-73.44954220243272, 18.526052964751145],
            [-72.69493709989064, 18.445799465401862],
            [-72.334881557897, 18.668421535715254],
            [-72.79164954292489, 19.10162506761803],
            [-72.78410478381028, 19.48359141690341],
            [-73.41502234566175, 19.639550889560283],
            [-73.18979061551762, 19.915683905511912],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Jamaica",
        sov_a3: "JAM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Jamaica",
        adm0_a3: "JAM",
        geou_dif: 0,
        geounit: "Jamaica",
        gu_a3: "JAM",
        su_dif: 0,
        subunit: "Jamaica",
        su_a3: "JAM",
        brk_diff: 0,
        name: "Jamaica",
        name_long: "Jamaica",
        brk_a3: "JAM",
        brk_name: "Jamaica",
        brk_group: null,
        abbrev: "Jam.",
        postal: "J",
        formal_en: "Jamaica",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Jamaica",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 10,
        pop_est: 2825928,
        gdp_md_est: 20910,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "JM",
        iso_a3: "JAM",
        iso_n3: "388",
        un_a3: "388",
        wb_a2: "JM",
        wb_a3: "JAM",
        woe_id: -99,
        adm0_a3_is: "JAM",
        adm0_a3_us: "JAM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "JAM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.56960079619921, 18.490525417550487],
            [-76.89661861846211, 18.400866807524082],
            [-76.36535905628554, 18.160700588447597],
            [-76.19965857614164, 17.886867173732966],
            [-76.9025614081757, 17.868237819891746],
            [-77.20634131540348, 17.70111623785982],
            [-77.76602291534061, 17.86159739834224],
            [-78.33771928578561, 18.225967922432233],
            [-78.21772661000388, 18.454532782459193],
            [-77.79736467152563, 18.524218451404778],
            [-77.56960079619921, 18.490525417550487],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Mexico",
        sov_a3: "MEX",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Mexico",
        adm0_a3: "MEX",
        geou_dif: 0,
        geounit: "Mexico",
        gu_a3: "MEX",
        su_dif: 0,
        subunit: "Mexico",
        su_a3: "MEX",
        brk_diff: 0,
        name: "Mexico",
        name_long: "Mexico",
        brk_a3: "MEX",
        brk_name: "Mexico",
        brk_group: null,
        abbrev: "Mex.",
        postal: "MX",
        formal_en: "United Mexican States",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Mexico",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 111211789,
        gdp_md_est: 1563000,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "4. Emerging region: MIKT",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "MX",
        iso_a3: "MEX",
        iso_n3: "484",
        un_a3: "484",
        wb_a2: "MX",
        wb_a3: "MEX",
        woe_id: -99,
        adm0_a3_is: "MEX",
        adm0_a3_us: "MEX",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "MEX.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-97.14000830767071, 25.869997463478395],
            [-97.52807247596655, 24.992144069920297],
            [-97.70294552284223, 24.272343044526735],
            [-97.77604183631905, 22.932579860927657],
            [-97.87236670611111, 22.44421173755336],
            [-97.69904395220419, 21.898689480064263],
            [-97.38895952023677, 21.411018988525825],
            [-97.18933346229329, 20.635433254473128],
            [-96.52557552772032, 19.890930894444068],
            [-96.29212724484177, 19.320371405509547],
            [-95.90088497595995, 18.82802419684873],
            [-94.83906348344271, 18.562717393462208],
            [-94.4257295397562, 18.144370835843347],
            [-93.5486512926824, 18.423836981677937],
            [-92.7861138577835, 18.52483856859226],
            [-92.0373481920904, 18.704569200103432],
            [-91.40790340855926, 18.87608327888023],
            [-90.77186987991087, 19.28412038825678],
            [-90.53358985061305, 19.8674181177513],
            [-90.45147599970124, 20.707521877520435],
            [-90.27861833368489, 20.99985545499555],
            [-89.60132117385149, 21.26172577563449],
            [-88.54386633986284, 21.49367544197662],
            [-87.65841651075772, 21.458845526611977],
            [-87.05189022494807, 21.543543199138295],
            [-86.81198238803296, 21.331514797444754],
            [-86.84590796583262, 20.849864610268355],
            [-87.38329118523586, 20.25540477139873],
            [-87.62105445021075, 19.64655304613592],
            [-87.43675045444176, 19.47240346931227],
            [-87.58656043165593, 19.04013011319074],
            [-87.83719112827151, 18.25981598558343],
            [-88.09066402866318, 18.51664785407405],
            [-88.30003109409364, 18.49998220466],
            [-88.4901228502793, 18.48683055264172],
            [-88.84834387892658, 17.883198147040332],
            [-89.02985734735176, 18.00151133877256],
            [-89.15090938999549, 17.955467637600407],
            [-89.14308041050333, 17.808318996649405],
            [-90.0679335192309, 17.81932607672752],
            [-91.00151994501596, 17.817594916245696],
            [-91.00226925328417, 17.25465770107428],
            [-91.45392127151511, 17.252177232324186],
            [-91.0816700915006, 16.91847667079952],
            [-90.71182186558764, 16.687483018454767],
            [-90.60084672724093, 16.47077789963879],
            [-90.438866950222, 16.41010976812811],
            [-90.46447262242265, 16.069562079324726],
            [-91.74796017125595, 16.066564846251765],
            [-92.2292486234063, 15.251446641495873],
            [-92.08721594925203, 15.064584662328512],
            [-92.20322953974727, 14.83010285080411],
            [-92.22775000686983, 14.538828640190957],
            [-93.35946387406176, 15.615429592343672],
            [-93.87516883011851, 15.940164292865914],
            [-94.69165646033014, 16.200975246642884],
            [-95.25022701697304, 16.128318182840644],
            [-96.05338212765331, 15.752087917539596],
            [-96.55743404822829, 15.65351512294279],
            [-97.26359249549665, 15.917064927631316],
            [-98.01302995480961, 16.107311713113912],
            [-98.94767574745651, 16.566043402568763],
            [-99.69739742714705, 16.70616404872817],
            [-100.82949886758131, 17.17107107184205],
            [-101.66608862995446, 17.649026394109626],
            [-101.91852800170022, 17.916090196193977],
            [-102.47813208698891, 17.975750637275098],
            [-103.50098954955808, 18.29229462327885],
            [-103.91752743204682, 18.74857168220001],
            [-104.9920096504755, 19.316133938061682],
            [-105.49303849976144, 19.946767279535436],
            [-105.73139604370766, 20.434101874264115],
            [-105.39777299683135, 20.531718654863425],
            [-105.50066077352443, 20.81689504646613],
            [-105.27075232625793, 21.07628489835514],
            [-105.26581722697402, 21.42210358325235],
            [-105.6031609769754, 21.871145941652568],
            [-105.69341386597313, 22.269080308516152],
            [-106.02871639689897, 22.773752346278627],
            [-106.90998043498837, 23.767774359628902],
            [-107.91544877809139, 24.54891531015295],
            [-108.40190487347098, 25.17231395110593],
            [-109.26019873740665, 25.58060944264406],
            [-109.44408932171734, 25.824883938087677],
            [-109.29164384645627, 26.442934068298428],
            [-109.80145768923182, 26.676175645447927],
            [-110.3917317370857, 27.16211497650454],
            [-110.64101884646163, 27.859876003525528],
            [-111.17891883018785, 27.941240546169066],
            [-111.75960689985163, 28.46795258230395],
            [-112.2282346260904, 28.95440867768349],
            [-112.27182369672869, 29.266844387320074],
            [-112.80959448937398, 30.021113593052345],
            [-113.16381059451868, 30.78688080496943],
            [-113.14866939985717, 31.17096588797892],
            [-113.87188106978186, 31.567608344035193],
            [-114.2057366606035, 31.52404511161313],
            [-114.77645117883503, 31.799532172161147],
            [-114.93669979537212, 31.3934846054276],
            [-114.77123185917351, 30.913617255165267],
            [-114.67389929895177, 30.162681179315992],
            [-114.33097449426292, 29.75043244070741],
            [-113.58887508833544, 29.061611436473015],
            [-113.42405310754054, 28.82617361095123],
            [-113.27196936730553, 28.7547826197399],
            [-113.14003943566439, 28.411289374295958],
            [-112.9622983467965, 28.42519033458251],
            [-112.76158708377488, 27.780216783147523],
            [-112.45791052941166, 27.52581370697476],
            [-112.2449519519368, 27.17172679291076],
            [-111.6164890206192, 26.662817287700477],
            [-111.28467464887302, 25.732589830014433],
            [-110.98781938357239, 25.294606228124564],
            [-110.71000688357134, 24.82600434010186],
            [-110.65504899782887, 24.298594672131117],
            [-110.17285620811343, 24.265547593680424],
            [-109.77184709352855, 23.811182562754198],
            [-109.4091043770557, 23.36467234953625],
            [-109.43339230023292, 23.1855876734287],
            [-109.85421932660171, 22.818271592698068],
            [-110.03139197471444, 22.823077500901206],
            [-110.29507097048366, 23.43097321216669],
            [-110.94950130902805, 24.00096426034599],
            [-111.67056840701268, 24.484423122652515],
            [-112.18203589562147, 24.738412787367167],
            [-112.14898881717085, 25.47012523040405],
            [-112.3007108223797, 26.012004299416613],
            [-112.77729671919155, 26.32195954030317],
            [-113.46467078332194, 26.768185533143424],
            [-113.59672990604383, 26.639459540304472],
            [-113.84893673384424, 26.90006378835244],
            [-114.46574662968003, 27.142090358991368],
            [-115.055142178185, 27.72272675222291],
            [-114.98225257043741, 27.798200181585116],
            [-114.57036556685495, 27.74148529714489],
            [-114.19932878299925, 28.115002549750553],
            [-114.16201839888463, 28.566111965442303],
            [-114.93184221073663, 29.279479275015486],
            [-115.518653937627, 29.556361599235398],
            [-115.88736528202958, 30.180793768834178],
            [-116.25835038945293, 30.83646434175358],
            [-116.72152625208498, 31.635743720012044],
            [-117.12775999999985, 32.53534],
            [-115.99135, 32.61239000000012],
            [-114.72139, 32.72083],
            [-114.815, 32.52528],
            [-113.30498, 32.03914],
            [-111.02361, 31.33472],
            [-109.035, 31.341940000000136],
            [-108.24194, 31.34222],
            [-108.24, 31.75485371816637],
            [-106.50759, 31.75452],
            [-106.1429, 31.39995],
            [-105.63159, 31.08383],
            [-105.03737, 30.64402],
            [-104.70575, 30.12173],
            [-104.4569699999999, 29.57196],
            [-103.94, 29.27],
            [-103.11, 28.97],
            [-102.48, 29.76],
            [-101.6624, 29.7793],
            [-100.9576, 29.380710000000132],
            [-100.45584, 28.696120000000118],
            [-100.11, 28.110000000000127],
            [-99.52, 27.54],
            [-99.3, 26.84],
            [-99.02, 26.37],
            [-98.24, 26.06],
            [-97.53, 25.84],
            [-97.14000830767071, 25.869997463478395],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Panama",
        sov_a3: "PAN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Panama",
        adm0_a3: "PAN",
        geou_dif: 0,
        geounit: "Panama",
        gu_a3: "PAN",
        su_dif: 0,
        subunit: "Panama",
        su_a3: "PAN",
        brk_diff: 0,
        name: "Panama",
        name_long: "Panama",
        brk_a3: "PAN",
        brk_name: "Panama",
        brk_group: null,
        abbrev: "Pan.",
        postal: "PA",
        formal_en: "Republic of Panama",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Panama",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 3360474,
        gdp_md_est: 38830,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "PA",
        iso_a3: "PAN",
        iso_n3: "591",
        un_a3: "591",
        wb_a2: "PA",
        wb_a3: "PAN",
        woe_id: -99,
        adm0_a3_is: "PAN",
        adm0_a3_us: "PAN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "PAN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.88157141794525, 7.223771267114785],
            [-78.21493608266012, 7.512254950384161],
            [-78.42916073272607, 8.052041123888927],
            [-78.18209570993864, 8.319182440621773],
            [-78.4354652574657, 8.38770538984079],
            [-78.62212053090394, 8.718124497915028],
            [-79.12030717641375, 8.996092027213022],
            [-79.55787736684519, 8.932374986197146],
            [-79.76057817251004, 8.5845150822244],
            [-80.16448116730334, 8.333315944853595],
            [-80.38265906443961, 8.298408514840432],
            [-80.4806892564973, 8.09030752200107],
            [-80.00368994822716, 7.547524115423371],
            [-80.276670701809, 7.419754136581715],
            [-80.42115800649708, 7.271571966984764],
            [-80.8864009264208, 7.220541490096537],
            [-81.05954281281473, 7.817921047390596],
            [-81.18971574575795, 7.647905585150339],
            [-81.51951473664468, 7.706610012233909],
            [-81.72131120474445, 8.108962714058435],
            [-82.13144120962892, 8.175392767769635],
            [-82.39093441438257, 8.29236237226229],
            [-82.82008134635042, 8.290863755725823],
            [-82.85095801464482, 8.073822740099956],
            [-82.96578304719736, 8.225027980985985],
            [-82.91317643912421, 8.42351715741907],
            [-82.82977067740516, 8.62629547773237],
            [-82.86865719270477, 8.807266343618522],
            [-82.71918311230053, 8.925708726431495],
            [-82.92715491405916, 9.074330145702916],
            [-82.93289099804358, 9.476812038608173],
            [-82.54619625520348, 9.566134751824677],
            [-82.18712256542341, 9.20744863528678],
            [-82.20758643261095, 8.9955752628901],
            [-81.80856686066929, 8.950616766796173],
            [-81.71415401887204, 9.031955471223583],
            [-81.43928707551154, 8.786234035675719],
            [-80.94730160187676, 8.858503526235905],
            [-80.52190121125008, 9.111072089062432],
            [-79.91459977895599, 9.31276520429762],
            [-79.57330278188431, 9.611610012241526],
            [-79.02119177927793, 9.552931423374105],
            [-79.05845048696037, 9.454565334506526],
            [-78.50088762074719, 9.420458889193881],
            [-78.05592770049802, 9.2477304142583],
            [-77.72951351592641, 8.946844387238869],
            [-77.35336076527385, 8.67050466555807],
            [-77.47472286651133, 8.524286200388218],
            [-77.24256649444008, 7.935278225125444],
            [-77.43110795765699, 7.638061224798735],
            [-77.75341386586139, 7.709839789252142],
            [-77.88157141794525, 7.223771267114785],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "United States of America",
        sov_a3: "US1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        admin: "Puerto Rico",
        adm0_a3: "PRI",
        geou_dif: 0,
        geounit: "Puerto Rico",
        gu_a3: "PRI",
        su_dif: 0,
        subunit: "Puerto Rico",
        su_a3: "PRI",
        brk_diff: 0,
        name: "Puerto Rico",
        name_long: "Puerto Rico",
        brk_a3: "PRI",
        brk_name: "Puerto Rico",
        brk_group: null,
        abbrev: "P.R.",
        postal: "PR",
        formal_en: "Commonwealth of Puerto Rico",
        formal_fr: null,
        note_adm0: "Commonwealth of U.S.A.",
        note_brk: null,
        name_sort: "Puerto Rico",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 1,
        pop_est: 3971020,
        gdp_md_est: 70230,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "PR",
        iso_a3: "PRI",
        iso_n3: "630",
        un_a3: "630",
        wb_a2: "PR",
        wb_a3: "PRI",
        woe_id: -99,
        adm0_a3_is: "PRI",
        adm0_a3_us: "PRI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: -99,
        filename: "PRI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.28243445500821, 18.514761664295364],
            [-65.7713028632093, 18.426679185453878],
            [-65.59100379094295, 18.228034979723915],
            [-65.84716386581377, 17.97590566657186],
            [-66.59993445500949, 17.981822618069273],
            [-67.18416236028527, 17.946553453030077],
            [-67.24242753769435, 18.374460150622937],
            [-67.10067908391774, 18.52060110114435],
            [-66.28243445500821, 18.514761664295364],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Nicaragua",
        sov_a3: "NIC",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Nicaragua",
        adm0_a3: "NIC",
        geou_dif: 0,
        geounit: "Nicaragua",
        gu_a3: "NIC",
        su_dif: 0,
        subunit: "Nicaragua",
        su_a3: "NIC",
        brk_diff: 0,
        name: "Nicaragua",
        name_long: "Nicaragua",
        brk_a3: "NIC",
        brk_name: "Nicaragua",
        brk_group: null,
        abbrev: "Nic.",
        postal: "NI",
        formal_en: "Republic of Nicaragua",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Nicaragua",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 9,
        pop_est: 5891199,
        gdp_md_est: 16790,
        pop_year: -99,
        lastcensus: 2005,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "NI",
        iso_a3: "NIC",
        iso_n3: "558",
        un_a3: "558",
        wb_a2: "NI",
        wb_a3: "NIC",
        woe_id: -99,
        adm0_a3_is: "NIC",
        adm0_a3_us: "NIC",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "NIC.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.7125404528073, 11.088444932494824],
            [-86.05848832878526, 11.403438625529944],
            [-86.52584998243296, 11.806876532432597],
            [-86.74599158399633, 12.143961900272487],
            [-87.16751624220116, 12.458257961471656],
            [-87.66849341505471, 12.909909979702633],
            [-87.5574666002756, 13.064551703336065],
            [-87.39238623731923, 12.914018256069838],
            [-87.31665442579549, 12.984685777228975],
            [-87.00576900912756, 13.025794379117157],
            [-86.88055701368437, 13.254204209847245],
            [-86.7338217841916, 13.263092556201443],
            [-86.7550866360797, 13.754845485890913],
            [-86.5207081774199, 13.77848745366444],
            [-86.31214209668993, 13.77135610600817],
            [-86.0962638007906, 14.038187364147248],
            [-85.80129472526859, 13.83605499923759],
            [-85.69866533073693, 13.960078436738087],
            [-85.51441301140025, 14.079011745657837],
            [-85.1653645494848, 14.354369615125078],
            [-85.14875057650296, 14.560196844943619],
            [-85.05278744173692, 14.551541042534723],
            [-84.9245006985724, 14.790492865452352],
            [-84.82003679069435, 14.819586696832669],
            [-84.64958207877962, 14.666805324761754],
            [-84.4493359036486, 14.621614284722495],
            [-84.22834164095241, 14.748764146376658],
            [-83.97572140169359, 14.749435939996461],
            [-83.62858496777292, 14.880073960830302],
            [-83.48998877636612, 15.016267198135536],
            [-83.14721900097413, 14.99582916916411],
            [-83.23323442252394, 14.899866034398102],
            [-83.2841615465476, 14.6766238468972],
            [-83.18212643098728, 14.31070302983845],
            [-83.41249996614445, 13.970077826386557],
            [-83.51983191601468, 13.567699286345883],
            [-83.55220720084554, 13.127054348193086],
            [-83.49851538769427, 12.869292303921227],
            [-83.47332312695198, 12.419087225794428],
            [-83.62610449902292, 12.320850328007566],
            [-83.71961300325506, 11.893124497927726],
            [-83.65085751009072, 11.629032090700118],
            [-83.8554703437504, 11.373311265503787],
            [-83.80893571647155, 11.103043524617274],
            [-83.65561174186158, 10.938764146361422],
            [-83.89505449088595, 10.726839097532446],
            [-84.19017859570485, 10.793450018756674],
            [-84.35593075228104, 10.999225572142905],
            [-84.67306901725627, 11.082657172078143],
            [-84.90300330273895, 10.952303371621896],
            [-85.56185197624418, 11.217119248901597],
            [-85.7125404528073, 11.088444932494824],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "El Salvador",
        sov_a3: "SLV",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "El Salvador",
        adm0_a3: "SLV",
        geou_dif: 0,
        geounit: "El Salvador",
        gu_a3: "SLV",
        su_dif: 0,
        subunit: "El Salvador",
        su_a3: "SLV",
        brk_diff: 0,
        name: "El Salvador",
        name_long: "El Salvador",
        brk_a3: "SLV",
        brk_name: "El Salvador",
        brk_group: null,
        abbrev: "El. S.",
        postal: "SV",
        formal_en: "Republic of El Salvador",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "El Salvador",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 6,
        mapcolor13: 8,
        pop_est: 7185218,
        gdp_md_est: 43630,
        pop_year: -99,
        lastcensus: 2007,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SV",
        iso_a3: "SLV",
        iso_n3: "222",
        un_a3: "222",
        wb_a2: "SV",
        wb_a3: "SLV",
        woe_id: -99,
        adm0_a3_is: "SLV",
        adm0_a3_us: "SLV",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Central America",
        region_wb: "Latin America & Caribbean",
        name_len: 11,
        long_len: 11,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "SLV.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.79311113152657, 13.384480495655055],
            [-87.90411210808952, 13.149016831917137],
            [-88.48330156121682, 13.163951320849492],
            [-88.8432279121297, 13.259733588102478],
            [-89.2567427233293, 13.458532823129303],
            [-89.81239356154767, 13.520622056527998],
            [-90.09555457229098, 13.735337632700734],
            [-90.0646779039966, 13.881969509328924],
            [-89.72193396682073, 14.134228013561694],
            [-89.53421932652051, 14.244815578666305],
            [-89.58734269891654, 14.362586167859488],
            [-89.35332597528279, 14.424132798719116],
            [-89.05851192905766, 14.340029405164085],
            [-88.84307288283284, 14.140506700085169],
            [-88.541230841816, 13.980154730683479],
            [-88.50399797234971, 13.845485948130857],
            [-88.06534257684012, 13.964625962779778],
            [-87.8595153470216, 13.893312486216983],
            [-87.72350297722939, 13.785050360565506],
            [-87.79311113152657, 13.384480495655055],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Trinidad and Tobago",
        sov_a3: "TTO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Trinidad and Tobago",
        adm0_a3: "TTO",
        geou_dif: 0,
        geounit: "Trinidad and Tobago",
        gu_a3: "TTO",
        su_dif: 0,
        subunit: "Trinidad and Tobago",
        su_a3: "TTO",
        brk_diff: 0,
        name: "Trinidad and Tobago",
        name_long: "Trinidad and Tobago",
        brk_a3: "TTO",
        brk_name: "Trinidad and Tobago",
        brk_group: null,
        abbrev: "Tr.T.",
        postal: "TT",
        formal_en: "Republic of Trinidad and Tobago",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Trinidad and Tobago",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 1310000,
        gdp_md_est: 29010,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "TT",
        iso_a3: "TTO",
        iso_n3: "780",
        un_a3: "780",
        wb_a2: "TT",
        wb_a3: "TTO",
        woe_id: -99,
        adm0_a3_is: "TTO",
        adm0_a3_us: "TTO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Caribbean",
        region_wb: "Latin America & Caribbean",
        name_len: 19,
        long_len: 19,
        abbrev_len: 5,
        tiny: 2,
        homepart: 1,
        filename: "TTO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.68, 10.76],
            [-61.105, 10.89],
            [-60.895, 10.855],
            [-60.935, 10.11],
            [-61.77, 10],
            [-61.95, 10.09],
            [-61.66, 10.365],
            [-61.68, 10.76],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "United States of America",
        sov_a3: "US1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        admin: "United States of America",
        adm0_a3: "USA",
        geou_dif: 0,
        geounit: "United States of America",
        gu_a3: "USA",
        su_dif: 0,
        subunit: "United States of America",
        su_a3: "USA",
        brk_diff: 0,
        name: "United States",
        name_long: "United States",
        brk_a3: "USA",
        brk_name: "United States",
        brk_group: null,
        abbrev: "U.S.A.",
        postal: "US",
        formal_en: "United States of America",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "United States of America",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 1,
        pop_est: 313973000,
        gdp_md_est: 15094000,
        pop_year: 0,
        lastcensus: 2010,
        gdp_year: 0,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        wikipedia: 0,
        fips_10: null,
        iso_a2: "US",
        iso_a3: "USA",
        iso_n3: "840",
        un_a3: "840",
        wb_a2: "US",
        wb_a3: "USA",
        woe_id: -99,
        adm0_a3_is: "USA",
        adm0_a3_us: "USA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "North America",
        region_un: "Americas",
        subregion: "Northern America",
        region_wb: "North America",
        name_len: 13,
        long_len: 13,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "USA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-155.54211, 19.08348],
              [-155.68817, 18.91619],
              [-155.93665, 19.05939],
              [-155.90806, 19.33888],
              [-156.07347, 19.70294],
              [-156.02368, 19.81422],
              [-155.85008, 19.97729],
              [-155.91907, 20.17395],
              [-155.86108, 20.26721],
              [-155.78505, 20.2487],
              [-155.40214, 20.07975],
              [-155.22452, 19.99302],
              [-155.06226, 19.8591],
              [-154.80741, 19.50871],
              [-154.83147, 19.45328],
              [-155.22217, 19.23972],
              [-155.54211, 19.08348],
            ],
          ],
          [
            [
              [-156.07926, 20.64397],
              [-156.41445, 20.57241],
              [-156.58673, 20.783],
              [-156.70167, 20.8643],
              [-156.71055, 20.92676],
              [-156.61258, 21.01249],
              [-156.25711, 20.91745],
              [-155.99566, 20.76404],
              [-156.07926, 20.64397],
            ],
          ],
          [
            [
              [-156.75824, 21.17684],
              [-156.78933, 21.06873],
              [-157.32521, 21.09777],
              [-157.25027, 21.21958],
              [-156.75824, 21.17684],
            ],
          ],
          [
            [
              [-157.65283, 21.32217],
              [-157.70703, 21.26442],
              [-157.7786, 21.27729],
              [-158.12667, 21.31244],
              [-158.2538, 21.53919],
              [-158.29265, 21.57912],
              [-158.0252, 21.71696],
              [-157.94161, 21.65272],
              [-157.65283, 21.32217],
            ],
          ],
          [
            [
              [-159.34512, 21.982],
              [-159.46372, 21.88299],
              [-159.80051, 22.06533],
              [-159.74877, 22.1382],
              [-159.5962, 22.23618],
              [-159.36569, 22.21494],
              [-159.34512, 21.982],
            ],
          ],
          [
            [
              [-94.81758, 49.38905],
              [-94.63999999999987, 48.84000000000012],
              [-94.32914, 48.67074000000011],
              [-93.63087, 48.60926],
              [-92.61, 48.45],
              [-91.64, 48.14],
              [-90.82999999999986, 48.27],
              [-89.6, 48.010000000000105],
              [-89.27291744663668, 48.01980825458284],
              [-88.37811418328653, 48.30291758889382],
              [-87.43979262330024, 47.94],
              [-86.46199083122815, 47.55333801939204],
              [-85.65236324740323, 47.22021881773051],
              [-84.87607988151485, 46.90008331968238],
              [-84.77923824739983, 46.63710195574913],
              [-84.54374874544567, 46.53868419044923],
              [-84.6049, 46.4396],
              [-84.3367, 46.40877000000011],
              [-84.1421195136733, 46.51222585711574],
              [-84.09185126416148, 46.27541860613826],
              [-83.89076534700567, 46.116926988299156],
              [-83.6161309475905, 46.116926988299156],
              [-83.46955074739463, 45.99468638771259],
              [-83.59285071484308, 45.81689362241255],
              [-82.55092464875818, 45.34751658790545],
              [-82.33776312543108, 44.44],
              [-82.13764238150397, 43.57108755144],
              [-82.43, 42.9800000000001],
              [-82.89999999999989, 42.43000000000015],
              [-83.11999999999989, 42.08],
              [-83.14199968131256, 41.975681057293],
              [-83.02981014680694, 41.83279572200601],
              [-82.69008928092018, 41.675105088867326],
              [-82.43927771679162, 41.675105088867326],
              [-81.27774654816707, 42.20902598730686],
              [-80.24744767934784, 42.36619985612267],
              [-78.9393621487437, 42.86361135514812],
              [-78.92, 42.965],
              [-79.00999999999988, 43.27],
              [-79.17167355011188, 43.46633942318431],
              [-78.72027991404238, 43.62508942318496],
              [-77.73788509795762, 43.62905558936339],
              [-76.82003414580558, 43.628784288093755],
              [-76.5, 44.018458893758606],
              [-76.375, 44.09631],
              [-75.31821, 44.816450000000174],
              [-74.867, 45.000480000000124],
              [-73.34783, 45.00738],
              [-71.50505999999987, 45.0082000000001],
              [-71.405, 45.25500000000014],
              [-71.08482, 45.30524000000017],
              [-70.6599999999998, 45.46],
              [-70.305, 45.915],
              [-69.99997, 46.69307],
              [-69.237216, 47.447781],
              [-68.905, 47.185],
              [-68.23444, 47.35486],
              [-67.79046, 47.06636],
              [-67.79134, 45.70281000000014],
              [-67.13741, 45.13753],
              [-66.96466, 44.80970000000016],
              [-68.03252, 44.3252],
              [-69.05999999999989, 43.98],
              [-70.11617, 43.684050000000155],
              [-70.645475633411, 43.09023834896405],
              [-70.81489, 42.8653],
              [-70.825, 42.335],
              [-70.495, 41.805],
              [-70.08, 41.78],
              [-70.185, 42.145],
              [-69.88497, 41.92283000000012],
              [-69.96503, 41.63717000000017],
              [-70.64, 41.475],
              [-71.12039, 41.49445000000017],
              [-71.85999999999984, 41.32],
              [-72.295, 41.27],
              [-72.87643, 41.22065],
              [-73.71, 40.93110235165449],
              [-72.24126, 41.11948000000015],
              [-71.94499999999982, 40.93],
              [-73.345, 40.63],
              [-73.982, 40.628],
              [-73.952325, 40.75075],
              [-74.25671, 40.47351],
              [-73.96244, 40.42763],
              [-74.17838, 39.70926],
              [-74.90604, 38.93954],
              [-74.98041, 39.1964],
              [-75.20002, 39.248450000000105],
              [-75.52805, 39.4985],
              [-75.32, 38.96],
              [-75.0718347647898, 38.78203223017928],
              [-75.05673, 38.40412000000012],
              [-75.37747, 38.01551],
              [-75.94023, 37.21689],
              [-76.03127, 37.2566],
              [-75.72204999999978, 37.93705000000011],
              [-76.23287, 38.319215],
              [-76.35, 39.15],
              [-76.542725, 38.71761500000011],
              [-76.32933, 38.08326],
              [-76.98999793161354, 38.23999176691339],
              [-76.30162, 37.917945],
              [-76.25874, 36.96640000000011],
              [-75.9718, 36.89726],
              [-75.86803999999984, 36.55125],
              [-75.72749, 35.55074000000013],
              [-76.36318, 34.80854000000013],
              [-77.39763499999988, 34.51201],
              [-78.05496, 33.92547],
              [-78.55434999999983, 33.86133000000012],
              [-79.06067, 33.49395],
              [-79.20357, 33.15839],
              [-80.301325, 32.509355],
              [-80.86498, 32.0333],
              [-81.33629, 31.44049],
              [-81.49042, 30.72999000000013],
              [-81.31371, 30.035520000000105],
              [-80.98, 29.180000000000117],
              [-80.53558499999988, 28.47213],
              [-80.5299999999998, 28.040000000000106],
              [-80.05653928497756, 26.880000000000138],
              [-80.088015, 26.205765],
              [-80.13155999999987, 25.816775],
              [-80.38103, 25.20616],
              [-80.67999999999988, 25.08],
              [-81.17213, 25.201260000000133],
              [-81.33, 25.64],
              [-81.70999999999981, 25.87],
              [-82.24, 26.730000000000132],
              [-82.70515, 27.49504],
              [-82.85526, 27.88624],
              [-82.65, 28.550000000000153],
              [-82.92999999999988, 29.100000000000136],
              [-83.70959, 29.93656],
              [-84.1, 30.090000000000117],
              [-85.10882, 29.63615],
              [-85.28784, 29.68612000000013],
              [-85.7731, 30.152610000000124],
              [-86.39999999999988, 30.40000000000012],
              [-87.53036, 30.27433],
              [-88.41782, 30.3849],
              [-89.18048999999984, 30.31598],
              [-89.59383117841978, 30.15999400483685],
              [-89.413735, 29.89419],
              [-89.43, 29.48864],
              [-89.21767, 29.29108],
              [-89.40823, 29.15961],
              [-89.77928, 29.307140000000143],
              [-90.15463, 29.11743],
              [-90.880225, 29.148535000000123],
              [-91.62678499999987, 29.67700000000013],
              [-92.49906, 29.5523],
              [-93.22637, 29.78375],
              [-93.84842, 29.71363],
              [-94.69, 29.480000000000132],
              [-95.60026, 28.73863],
              [-96.59404, 28.30748],
              [-97.13999999999982, 27.83],
              [-97.37, 27.38],
              [-97.37999999999987, 26.69],
              [-97.33, 26.21000000000012],
              [-97.13999999999982, 25.87],
              [-97.52999999999989, 25.84],
              [-98.24, 26.060000000000116],
              [-99.01999999999988, 26.37],
              [-99.3, 26.84],
              [-99.51999999999987, 27.54],
              [-100.11, 28.110000000000127],
              [-100.45584, 28.696120000000118],
              [-100.9576, 29.380710000000132],
              [-101.6624, 29.779300000000116],
              [-102.48, 29.76],
              [-103.11, 28.97],
              [-103.94, 29.27],
              [-104.45696999999984, 29.57196],
              [-104.70575, 30.12173],
              [-105.03737, 30.64402],
              [-105.63159, 31.08383000000012],
              [-106.1429, 31.39995],
              [-106.50758999999982, 31.75452],
              [-108.24, 31.7548537181664],
              [-108.24194, 31.34222],
              [-109.035, 31.34194000000016],
              [-111.02361, 31.33472],
              [-113.30498, 32.03914],
              [-114.815, 32.52528],
              [-114.72138999999986, 32.72083],
              [-115.9913499999999, 32.61239000000014],
              [-117.12775999999978, 32.53534],
              [-117.29593769127388, 33.04622461520389],
              [-117.944, 33.621236431201396],
              [-118.41060227589749, 33.740909223124504],
              [-118.51989482279971, 34.02778157757575],
              [-119.081, 34.078],
              [-119.43884064201669, 34.3484771782843],
              [-120.36778, 34.44711],
              [-120.62286, 34.60855],
              [-120.74433, 35.15686000000011],
              [-121.71456999999988, 36.16153],
              [-122.54747, 37.551760000000115],
              [-122.51201, 37.78339000000013],
              [-122.95319, 38.11371000000011],
              [-123.7272, 38.95166000000012],
              [-123.86517, 39.76699000000013],
              [-124.39807, 40.3132],
              [-124.17886, 41.142020000000116],
              [-124.2137, 41.99964000000014],
              [-124.53284, 42.7659900000001],
              [-124.14214, 43.70838],
              [-124.020535, 44.615895],
              [-123.89893, 45.52341],
              [-124.079635, 46.86475],
              [-124.39567, 47.72017000000011],
              [-124.68721008300783, 48.18443298339855],
              [-124.56610107421876, 48.3797149658204],
              [-123.12, 48.04],
              [-122.58736, 47.096],
              [-122.34, 47.36],
              [-122.5, 48.18],
              [-122.84, 49.000000000000114],
              [-120, 49.000000000000114],
              [-117.03121, 49.000000000000114],
              [-116.04818, 49.000000000000114],
              [-113, 49.000000000000114],
              [-110.04999999999983, 49.000000000000114],
              [-107.05, 49.000000000000114],
              [-104.04826, 48.99986],
              [-100.65, 49.000000000000114],
              [-97.22872000000471, 49.00070000000011],
              [-95.15906950917196, 49.000000000000114],
              [-95.15609, 49.38425],
              [-94.81758, 49.38905],
            ],
          ],
          [
            [
              [-153.0063140533369, 57.11584219016589],
              [-154.0050902984581, 56.73467682558106],
              [-154.5164027577701, 56.9927489284467],
              [-154.67099280497115, 57.46119578717249],
              [-153.76277950744148, 57.81657461204377],
              [-153.2287294179211, 57.968968410872435],
              [-152.56479061583514, 57.901427313866975],
              [-152.1411472239063, 57.59105866152199],
              [-153.0063140533369, 57.11584219016589],
            ],
          ],
          [
            [
              [-165.57916419173358, 59.90998688418755],
              [-166.19277014876727, 59.754440822988975],
              [-166.848337368822, 59.94140615502096],
              [-167.45527706609008, 60.21306915957938],
              [-166.46779212142462, 60.38416982689778],
              [-165.67442969466367, 60.293606879306246],
              [-165.57916419173358, 59.90998688418755],
            ],
          ],
          [
            [
              [-171.7316568675394, 63.78251536727592],
              [-171.1144335602452, 63.592191067144995],
              [-170.4911124339407, 63.69497549097352],
              [-169.68250545965358, 63.431115627691156],
              [-168.6894394603007, 63.2975062120006],
              [-168.7719408844546, 63.18859813094545],
              [-169.52943986720504, 62.9769314642779],
              [-170.29055620021597, 63.194437567794466],
              [-170.67138566799088, 63.37582184513897],
              [-171.55306311753867, 63.317789211675084],
              [-171.7911106028912, 63.405845852300494],
              [-171.7316568675394, 63.78251536727592],
            ],
          ],
          [
            [
              [-155.06779029032424, 71.1477763943237],
              [-154.34416520894123, 70.6964085964702],
              [-153.90000627339262, 70.8899885118357],
              [-152.2100060699353, 70.82999217394485],
              [-152.27000240782615, 70.60000621202985],
              [-150.73999243874454, 70.43001658800571],
              [-149.72000301816752, 70.53001048449045],
              [-147.61336157935708, 70.2140349392418],
              [-145.6899898002253, 70.12000967068676],
              [-144.92001095907642, 69.9899917670405],
              [-143.5894461804252, 70.15251414659832],
              [-142.07251034871342, 69.85193817817265],
              [-140.98598752156073, 69.71199839952638],
              [-140.9859883290049, 69.71199839952638],
              [-140.9924987520294, 66.00002859156868],
              [-140.99776974812312, 60.30639679629861],
              [-140.0129978161531, 60.27683787702759],
              [-139.03900042031586, 60.000007229240026],
              [-138.34089, 59.56211000000016],
              [-137.4525, 58.905000000000115],
              [-136.4797200000001, 59.46389],
              [-135.47583, 59.78778],
              [-134.945, 59.27056000000013],
              [-134.27111, 58.86111],
              [-133.35554888220722, 58.410285142645165],
              [-132.73042, 57.69289000000011],
              [-131.70780999999988, 56.55212],
              [-130.00778, 55.91583],
              [-129.9799942633583, 55.28499787049722],
              [-130.53611018946725, 54.8027534043494],
              [-131.08581823797215, 55.17890615500204],
              [-131.9672114671423, 55.49777558045906],
              [-132.25001074285947, 56.36999624289746],
              [-133.53918108435641, 57.17888743756214],
              [-134.07806292029605, 58.1230675319669],
              [-135.03821103227907, 58.18771474876393],
              [-136.62806230995466, 58.21220937767046],
              [-137.80000627968604, 58.49999542910379],
              [-139.867787041413, 59.53776154238915],
              [-140.82527381713305, 59.727517401765084],
              [-142.57444353556446, 60.08444651960499],
              [-143.9588809948799, 59.9991804063234],
              [-145.92555681682785, 60.45860972761429],
              [-147.11437394914668, 60.88465607364463],
              [-148.22430620012767, 60.672989406977166],
              [-148.01806555885076, 59.97832896589363],
              [-148.5708225168609, 59.914172675203304],
              [-149.72785783587585, 59.70565827090556],
              [-150.60824337461645, 59.36821116803949],
              [-151.71639278868332, 59.15582103131999],
              [-151.85943315326716, 59.744984035879604],
              [-151.4097190012472, 60.72580272077939],
              [-150.34694149473253, 61.03358755150986],
              [-150.62111080625698, 61.284424953854455],
              [-151.89583919981686, 60.72719798445129],
              [-152.5783298410956, 60.06165721296429],
              [-154.01917212625762, 59.35027944603428],
              [-153.28751135965317, 58.8647276882198],
              [-154.2324924387585, 58.14637360293054],
              [-155.30749142151024, 57.72779450136633],
              [-156.3083347239231, 57.42277435976365],
              [-156.55609737854633, 56.979984849670636],
              [-158.11721655986776, 56.46360809999419],
              [-158.43332129619716, 55.99415355083855],
              [-159.60332739971744, 55.56668610292012],
              [-160.2897196116342, 55.643580634170576],
              [-161.2230476552578, 55.364734605523495],
              [-162.23776607974108, 55.02418691672011],
              [-163.06944658104638, 54.68973704692717],
              [-164.7855692210272, 54.40417308208217],
              [-164.94222632552004, 54.57222483989534],
              [-163.84833960676568, 55.03943146424612],
              [-162.87000139061593, 55.348043117893205],
              [-161.80417497459604, 55.89498647727043],
              [-160.56360470278116, 56.00805451112504],
              [-160.0705598622845, 56.41805532492876],
              [-158.68444291891944, 57.01667511659787],
              [-158.46109737855394, 57.21692129172888],
              [-157.7227703521839, 57.57000051536306],
              [-157.55027442119356, 58.32832632103023],
              [-157.041674974577, 58.91888458926172],
              [-158.19473120830548, 58.61580231386984],
              [-158.5172179840231, 58.78778148053732],
              [-159.05860612692874, 58.424186102931685],
              [-159.71166704001735, 58.93139028587634],
              [-159.9812888255002, 58.57254914004164],
              [-160.35527116599653, 59.07112335879364],
              [-161.35500342511506, 58.670837714260756],
              [-161.96889360252635, 58.67166453717738],
              [-162.05498653872468, 59.26692536074745],
              [-161.87417070213536, 59.6336213242906],
              [-162.5180590484921, 59.98972361921391],
              [-163.81834143782015, 59.79805573184339],
              [-164.66221757714646, 60.26748444278265],
              [-165.34638770247483, 60.50749563256241],
              [-165.35083187565186, 61.07389516869751],
              [-166.12137915755596, 61.500019029376226],
              [-165.73445187077053, 62.074996853271806],
              [-164.91917863671785, 62.63307648380793],
              [-164.56250790103934, 63.14637848576305],
              [-163.75333248599702, 63.21944896102377],
              [-163.0672244944579, 63.05945872664802],
              [-162.26055538638172, 63.54193573674117],
              [-161.5344498362486, 63.455816962326764],
              [-160.77250668032113, 63.766108100023274],
              [-160.95833513084256, 64.22279857040277],
              [-161.5180684072122, 64.40278758407531],
              [-160.77777767641476, 64.78860382756642],
              [-161.39192623598763, 64.77723501246234],
              [-162.45305009666885, 64.55944468856822],
              [-162.7577860178941, 64.33860545516882],
              [-163.5463942128843, 64.5591604681905],
              [-164.96082984114517, 64.44694509546885],
              [-166.42528825586447, 64.68667206487072],
              [-166.84500423893905, 65.08889557561453],
              [-168.11056006576717, 65.66999705673675],
              [-166.70527116602196, 66.0883177761394],
              [-164.4747096425755, 66.5766600612975],
              [-163.65251176659564, 66.5766600612975],
              [-163.78860165103617, 66.07720734319668],
              [-161.67777442121016, 66.11611969671242],
              [-162.48971452538, 66.73556509059512],
              [-163.71971696679108, 67.1163945583701],
              [-164.4309913808565, 67.6163382025778],
              [-165.39028683170676, 68.04277212185025],
              [-166.76444068099602, 68.35887685817968],
              [-166.20470740462662, 68.88303091091618],
              [-164.4308105133435, 68.91553538682774],
              [-163.16861365461452, 69.3711148139129],
              [-162.93056616926202, 69.85806183539927],
              [-161.90889726463553, 70.33332998318764],
              [-160.9347965159337, 70.44768992784958],
              [-159.03917578838715, 70.89164215766894],
              [-158.11972286683397, 70.82472117785105],
              [-156.58082455139805, 71.35776357694175],
              [-155.06779029032424, 71.1477763943237],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Argentina",
        sov_a3: "ARG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Argentina",
        adm0_a3: "ARG",
        geou_dif: 0,
        geounit: "Argentina",
        gu_a3: "ARG",
        su_dif: 0,
        subunit: "Argentina",
        su_a3: "ARG",
        brk_diff: 0,
        name: "Argentina",
        name_long: "Argentina",
        brk_a3: "ARG",
        brk_name: "Argentina",
        brk_group: null,
        abbrev: "Arg.",
        postal: "AR",
        formal_en: "Argentine Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Argentina",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 13,
        pop_est: 40913584,
        gdp_md_est: 573900,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "AR",
        iso_a3: "ARG",
        iso_n3: "032",
        un_a3: "032",
        wb_a2: "AR",
        wb_a3: "ARG",
        woe_id: -99,
        adm0_a3_is: "ARG",
        adm0_a3_us: "ARG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "ARG.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-65.5, -55.2],
              [-66.45, -55.25],
              [-66.95992, -54.89681],
              [-67.56244, -54.87001],
              [-68.63335, -54.8695],
              [-68.63401022758316, -52.63637045887445],
              [-68.25, -53.1],
              [-67.75, -53.85],
              [-66.45, -54.45],
              [-65.05, -54.7],
              [-65.5, -55.2],
            ],
          ],
          [
            [
              [-64.96489213729458, -22.075861504812348],
              [-64.37702104354227, -22.798091322523547],
              [-63.98683814152247, -21.993644301035957],
              [-62.846468471921554, -22.034985446869456],
              [-62.6850571356579, -22.249029229422405],
              [-60.84656470400994, -23.8807125790383],
              [-60.02896603050399, -24.032796319273245],
              [-58.80712846539495, -24.771459242453275],
              [-57.77721716981796, -25.16233977630904],
              [-57.63366004091114, -25.60365650808167],
              [-58.61817359071972, -27.12371876394712],
              [-57.60975969097615, -27.395898532828426],
              [-56.48670162619299, -27.54849903738625],
              [-55.6958455063982, -27.38783700939082],
              [-54.78879492859505, -26.621785577096087],
              [-54.625290696823555, -25.739255466415486],
              [-54.13004960795441, -25.54763925547725],
              [-53.62834896504873, -26.124865004177437],
              [-53.648735317587885, -26.92347258881611],
              [-54.49072526713553, -27.474756768505767],
              [-55.1622863429846, -27.88191537853342],
              [-56.2908996242391, -28.852760512000852],
              [-57.62513342958291, -30.216294854454244],
              [-57.87493730328191, -31.016556084926165],
              [-58.14244035504075, -32.04450367607619],
              [-58.13264767112142, -33.040566908502015],
              [-58.34961117209883, -33.263188978815435],
              [-58.42707414410438, -33.90945444105755],
              [-58.49544206402654, -34.43148976007011],
              [-57.225829637263644, -35.28802662530789],
              [-57.362358771378744, -35.977390232081504],
              [-56.73748735210546, -36.41312590916658],
              [-56.78828528504834, -36.901571547189334],
              [-57.74915686708343, -38.183870538079915],
              [-59.231857062401865, -38.720220228837206],
              [-61.23744523786561, -38.928424574541154],
              [-62.33595699731015, -38.82770720800437],
              [-62.12576310896293, -39.424104913084875],
              [-62.330530971919444, -40.17258635840032],
              [-62.14599443220524, -40.67689666113674],
              [-62.745802781816984, -41.02876148861209],
              [-63.77049475773253, -41.166789239263665],
              [-64.73208980981971, -40.802677097335135],
              [-65.11803524439159, -41.06431487402888],
              [-64.97856055363584, -42.05800099056932],
              [-64.30340796574248, -42.359016208669495],
              [-63.75594784204235, -42.04368661882451],
              [-63.45805904809589, -42.563138116222355],
              [-64.3788038804563, -42.87355844499964],
              [-65.1818039618397, -43.495380954767796],
              [-65.32882341171013, -44.501366062193696],
              [-65.5652689276616, -45.03678557716979],
              [-66.50996578638936, -45.03962778094584],
              [-67.29379391139244, -45.5518962542552],
              [-67.58054643418009, -46.30177296324254],
              [-66.59706641301726, -47.03392465595381],
              [-65.64102657740145, -47.236134535511894],
              [-65.98508826360074, -48.13328907653114],
              [-67.16617896184766, -48.697337334996945],
              [-67.81608761256646, -49.86966887797042],
              [-68.72874508327317, -50.26421843851887],
              [-69.1385391913478, -50.7325102679478],
              [-68.81556148952353, -51.771104011594105],
              [-68.14999487982041, -52.3499834061277],
              [-68.57154537624135, -52.29944385534626],
              [-69.49836218939609, -52.14276091263725],
              [-71.91480383979635, -52.009022305865926],
              [-72.32940385607404, -51.42595631287241],
              [-72.30997351753237, -50.677009779666356],
              [-72.97574683296463, -50.74145029073431],
              [-73.32805091011448, -50.37878508890987],
              [-73.41543575712004, -49.31843637471296],
              [-72.64824744331494, -48.87861825947679],
              [-72.33116085477195, -48.244238376661826],
              [-72.44735531278027, -47.73853281025353],
              [-71.91725847033021, -46.8848381487918],
              [-71.55200944689125, -45.56073292417713],
              [-71.65931555854533, -44.97368865334144],
              [-71.22277889675973, -44.784242852559416],
              [-71.32980078803621, -44.40752166115169],
              [-71.79362260607195, -44.20717213315611],
              [-71.46405615913051, -43.78761117937833],
              [-71.91542395698391, -43.40856454851742],
              [-72.14889807807853, -42.25488819760139],
              [-71.74680375841547, -42.051386407235995],
              [-71.91573401557756, -40.83233936947073],
              [-71.68076127794646, -39.80816415787807],
              [-71.41351660834904, -38.916022230791114],
              [-70.81466427273472, -38.55299529394074],
              [-71.11862504747543, -37.5768274879472],
              [-71.1218806627098, -36.65812387466234],
              [-70.36476925320167, -36.005088799789945],
              [-70.38804948594908, -35.16968759535944],
              [-69.81730912950147, -34.193571465798286],
              [-69.81477698431921, -33.27388600029985],
              [-70.07439938015364, -33.09120981214803],
              [-70.53506893581945, -31.365010267870286],
              [-69.91900834825192, -30.336339206668313],
              [-70.01355038112987, -29.36792286551855],
              [-69.65613033718314, -28.459141127233693],
              [-69.00123491074828, -27.52121388113613],
              [-68.2955415513704, -26.89933969493579],
              [-68.59479977077268, -26.506908868111267],
              [-68.38600114609736, -26.185016371365233],
              [-68.41765296087613, -24.518554782816878],
              [-67.32844295924414, -24.02530323659091],
              [-66.98523393417764, -22.98634856536283],
              [-67.10667355006362, -22.7359245744764],
              [-66.27333940292485, -21.832310479420684],
              [-64.96489213729458, -22.075861504812348],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Bolivia",
        sov_a3: "BOL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Bolivia",
        adm0_a3: "BOL",
        geou_dif: 0,
        geounit: "Bolivia",
        gu_a3: "BOL",
        su_dif: 0,
        subunit: "Bolivia",
        su_a3: "BOL",
        brk_diff: 0,
        name: "Bolivia",
        name_long: "Bolivia",
        brk_a3: "BOL",
        brk_name: "Bolivia",
        brk_group: null,
        abbrev: "Bolivia",
        postal: "BO",
        formal_en: "Plurinational State of Bolivia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Bolivia",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 3,
        pop_est: 9775246,
        gdp_md_est: 43270,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BO",
        iso_a3: "BOL",
        iso_n3: "068",
        un_a3: "068",
        wb_a2: "BO",
        wb_a3: "BOL",
        woe_id: -99,
        adm0_a3_is: "BOL",
        adm0_a3_us: "BOL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        filename: "BOL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.846468471921554, -22.03498544686945],
            [-63.98683814152247, -21.99364430103595],
            [-64.37702104354226, -22.79809132252354],
            [-64.9648921372946, -22.075861504812327],
            [-66.27333940292485, -21.83231047942072],
            [-67.1066735500636, -22.735924574476414],
            [-67.82817989772273, -22.872918796482175],
            [-68.21991309271128, -21.494346612231865],
            [-68.75716712103375, -20.372657972904463],
            [-68.44222510443092, -19.40506845467143],
            [-68.96681840684187, -18.981683444904107],
            [-69.10024695501949, -18.260125420812674],
            [-69.59042375352405, -17.580011895419332],
            [-68.9596353827533, -16.50069793057127],
            [-69.38976416693471, -15.660129082911654],
            [-69.16034664577495, -15.323973890853019],
            [-69.33953467474701, -14.953195489158832],
            [-68.9488866848366, -14.453639418193283],
            [-68.92922380234954, -13.602683607643007],
            [-68.88007951523997, -12.899729099176653],
            [-68.66507971868961, -12.561300144097173],
            [-69.52967810736496, -10.951734307502194],
            [-68.78615759954948, -11.03638030359628],
            [-68.27125362819326, -11.01452117273682],
            [-68.04819230820539, -10.712059014532485],
            [-67.17380123561074, -10.30681243249961],
            [-66.64690833196279, -9.931331475466862],
            [-65.33843522811642, -9.76198780684639],
            [-65.44483700220539, -10.511451104375432],
            [-65.32189876978302, -10.895872084194679],
            [-65.40228146021303, -11.566270440317153],
            [-64.3163529120316, -12.461978041232191],
            [-63.19649878605057, -12.627032565972433],
            [-62.80306026879638, -13.000653171442686],
            [-62.127080857986385, -13.198780612849724],
            [-61.71320431176078, -13.489202162330052],
            [-61.08412126325565, -13.479383640194598],
            [-60.503304002511136, -13.775954685117659],
            [-60.45919816755003, -14.354007256734555],
            [-60.26432634137736, -14.64597909918364],
            [-60.251148851142936, -15.077218926659322],
            [-60.542965664295146, -15.093910414289596],
            [-60.158389655179036, -16.258283786690082],
            [-58.24121985536669, -16.299573256091293],
            [-58.38805843772404, -16.877109063385276],
            [-58.28080400250226, -17.271710300366017],
            [-57.734558274961, -17.55246835700777],
            [-57.498371141170985, -18.174187513911292],
            [-57.67600887717431, -18.961839694904025],
            [-57.949997321185826, -19.40000416430682],
            [-57.85380164247451, -19.969995212486186],
            [-58.166392381408045, -20.176700941653678],
            [-58.183471442280506, -19.868399346600363],
            [-59.115042487206104, -19.356906019775398],
            [-60.04356462262649, -19.342746677327426],
            [-61.786326463453776, -19.633736667562964],
            [-62.26596126977079, -20.513734633061276],
            [-62.29117936872922, -21.05163461678739],
            [-62.685057135657885, -22.249029229422387],
            [-62.846468471921554, -22.03498544686945],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Brazil",
        sov_a3: "BRA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Brazil",
        adm0_a3: "BRA",
        geou_dif: 0,
        geounit: "Brazil",
        gu_a3: "BRA",
        su_dif: 0,
        subunit: "Brazil",
        su_a3: "BRA",
        brk_diff: 0,
        name: "Brazil",
        name_long: "Brazil",
        brk_a3: "BRA",
        brk_name: "Brazil",
        brk_group: null,
        abbrev: "Brazil",
        postal: "BR",
        formal_en: "Federative Republic of Brazil",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Brazil",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 7,
        pop_est: 198739269,
        gdp_md_est: 1993000,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "3. Emerging region: BRIC",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BR",
        iso_a3: "BRA",
        iso_n3: "076",
        un_a3: "076",
        wb_a2: "BR",
        wb_a3: "BRA",
        woe_id: -99,
        adm0_a3_is: "BRA",
        adm0_a3_us: "BRA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "BRA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.62513342958296, -30.216294854454258],
            [-56.29089962423908, -28.85276051200089],
            [-55.16228634298457, -27.881915378533463],
            [-54.490725267135524, -27.47475676850579],
            [-53.648735317587885, -26.923472588816086],
            [-53.62834896504874, -26.124865004177472],
            [-54.13004960795439, -25.547639255477254],
            [-54.625290696823576, -25.73925546641551],
            [-54.42894609233059, -25.162184747012166],
            [-54.29347632507745, -24.570799655863965],
            [-54.29295956075451, -24.02101409271073],
            [-54.652834235235126, -23.83957813893396],
            [-55.02790178080954, -24.001273695575225],
            [-55.40074723979542, -23.956935316668805],
            [-55.517639329639636, -23.571997572526634],
            [-55.610682745981144, -22.655619398694842],
            [-55.79795813660691, -22.356929620047822],
            [-56.47331743022939, -22.086300144135283],
            [-56.8815095689029, -22.28215382252148],
            [-57.937155727761294, -22.090175876557172],
            [-57.87067399761779, -20.73268767668195],
            [-58.166392381408045, -20.176700941653678],
            [-57.85380164247451, -19.969995212486186],
            [-57.949997321185826, -19.40000416430682],
            [-57.67600887717431, -18.961839694904025],
            [-57.498371141170985, -18.174187513911292],
            [-57.734558274961, -17.55246835700777],
            [-58.28080400250226, -17.271710300366017],
            [-58.38805843772404, -16.877109063385276],
            [-58.24121985536669, -16.299573256091293],
            [-60.158389655179036, -16.258283786690082],
            [-60.542965664295146, -15.093910414289596],
            [-60.251148851142936, -15.077218926659322],
            [-60.26432634137736, -14.64597909918364],
            [-60.45919816755003, -14.354007256734555],
            [-60.503304002511136, -13.775954685117659],
            [-61.08412126325565, -13.479383640194598],
            [-61.71320431176078, -13.489202162330052],
            [-62.127080857986385, -13.198780612849724],
            [-62.80306026879638, -13.000653171442686],
            [-63.19649878605057, -12.627032565972433],
            [-64.3163529120316, -12.461978041232191],
            [-65.40228146021303, -11.566270440317153],
            [-65.32189876978302, -10.895872084194679],
            [-65.44483700220539, -10.511451104375432],
            [-65.33843522811642, -9.76198780684639],
            [-66.64690833196279, -9.931331475466862],
            [-67.17380123561074, -10.30681243249961],
            [-68.04819230820539, -10.712059014532485],
            [-68.27125362819326, -11.01452117273682],
            [-68.78615759954948, -11.03638030359628],
            [-69.52967810736496, -10.951734307502194],
            [-70.0937522040469, -11.123971856331012],
            [-70.54868567572841, -11.009146823778465],
            [-70.48189388699117, -9.490118096558845],
            [-71.30241227892154, -10.079436130415374],
            [-72.18489071316984, -10.053597914269432],
            [-72.56303300646564, -9.520193780152717],
            [-73.22671342639016, -9.462212823121234],
            [-73.01538265653254, -9.03283334720806],
            [-73.57105933296707, -8.424446709835834],
            [-73.98723548042966, -7.523829847853064],
            [-73.7234014553635, -7.340998630404414],
            [-73.72448666044164, -6.91859547285064],
            [-73.1200274319236, -6.629930922068239],
            [-73.21971126981461, -6.089188734566078],
            [-72.96450720894119, -5.741251315944893],
            [-72.89192765978726, -5.274561455916981],
            [-71.74840572781655, -4.593982842633011],
            [-70.92884334988358, -4.401591485210368],
            [-70.7947688463023, -4.251264743673303],
            [-69.89363521999663, -4.298186944194327],
            [-69.44410193548961, -1.556287123219818],
            [-69.42048580593223, -1.122618503426409],
            [-69.5770653957766, -0.549991957200163],
            [-70.02065589057005, -0.185156345219539],
            [-70.0155657619893, 0.541414292804205],
            [-69.45239600287246, 0.706158758950693],
            [-69.25243404811906, 0.602650865070075],
            [-69.21863766140018, 0.985676581217433],
            [-69.80459672715773, 1.089081122233466],
            [-69.81697323269162, 1.714805202639624],
            [-67.86856502955884, 1.692455145673392],
            [-67.53781002467468, 2.03716278727633],
            [-67.25999752467358, 1.719998684084956],
            [-67.0650481838525, 1.130112209473225],
            [-66.87632585312258, 1.253360500489336],
            [-66.32576514348496, 0.724452215982012],
            [-65.54826738143757, 0.78925446207603],
            [-65.35471330428837, 1.0952822941085],
            [-64.61101192895985, 1.328730576987042],
            [-64.19930579289051, 1.49285492594602],
            [-64.08308549666609, 1.91636912679408],
            [-63.36878801131166, 2.200899562993129],
            [-63.42286739770512, 2.411067613124174],
            [-64.26999915226578, 2.497005520025567],
            [-64.40882788761792, 3.126786200366624],
            [-64.36849443221409, 3.797210394705246],
            [-64.81606401229402, 4.056445217297423],
            [-64.62865943058755, 4.14848094320925],
            [-63.88834286157416, 4.020530096854571],
            [-63.0931975978991, 3.770571193858785],
            [-62.804533047116706, 4.006965033377952],
            [-62.08542965355914, 4.162123521334308],
            [-60.96689327660153, 4.536467596856639],
            [-60.60117916527194, 4.91809804933213],
            [-60.73357418480372, 5.200277207861901],
            [-60.21368343773133, 5.244486395687602],
            [-59.98095862490488, 5.014061184098139],
            [-60.11100236676737, 4.574966538914083],
            [-59.767405768458715, 4.423502915866607],
            [-59.53803992373123, 3.958802598481938],
            [-59.81541317405786, 3.606498521332085],
            [-59.97452490908456, 2.755232652188056],
            [-59.71854570172674, 2.24963043864436],
            [-59.64604366722126, 1.786893825686789],
            [-59.03086157900265, 1.317697658692722],
            [-58.5400129868783, 1.268088283692521],
            [-58.42947709820596, 1.463941962078721],
            [-58.11344987652502, 1.507195135907025],
            [-57.66097103537737, 1.682584947105639],
            [-57.335822923396904, 1.94853770589576],
            [-56.78270423036083, 1.863710842288654],
            [-56.539385748914555, 1.899522609866921],
            [-55.99569800477175, 1.817667141116601],
            [-55.905600145070885, 2.02199575439866],
            [-56.0733418442903, 2.220794989425499],
            [-55.973322109589375, 2.510363877773017],
            [-55.569755011606, 2.421506252447131],
            [-55.09758744975514, 2.523748073736613],
            [-54.52475419779971, 2.311848863123785],
            [-54.08806250671724, 2.105556545414629],
            [-53.77852067728892, 2.376702785650082],
            [-53.55483924011354, 2.334896551925951],
            [-53.4184651352953, 2.053389187015981],
            [-52.939657151894956, 2.124857692875636],
            [-52.55642473001842, 2.504705308437053],
            [-52.249337531123956, 3.241094468596245],
            [-51.65779741067888, 4.156232408053029],
            [-51.31714636901086, 4.203490505383954],
            [-51.069771287629656, 3.650397650564031],
            [-50.508875291533656, 1.901563828942457],
            [-49.97407589374506, 1.736483465986069],
            [-49.947100796088705, 1.046189683431223],
            [-50.699251268096916, 0.222984117021682],
            [-50.38821082213214, -0.078444512536819],
            [-48.62056677915631, -0.235489190271821],
            [-48.58449662941659, -1.237805271005001],
            [-47.824956427590635, -0.5816179337628],
            [-46.566583624851226, -0.941027520352776],
            [-44.905703090990414, -1.551739597178134],
            [-44.417619187993665, -2.137750339367976],
            [-44.58158850765578, -2.691308282078524],
            [-43.418791266440195, -2.383110039889793],
            [-41.47265682632825, -2.912018324397116],
            [-39.97866533055404, -2.873054294449041],
            [-38.50038347019657, -3.700652357603395],
            [-37.2232521225352, -4.820945733258917],
            [-36.45293738457639, -5.109403578312153],
            [-35.59779578301047, -5.149504489770648],
            [-35.23538896334756, -5.464937432480247],
            [-34.89602983248683, -6.738193047719711],
            [-34.729993455533034, -7.343220716992966],
            [-35.12821204277422, -8.996401462442286],
            [-35.636966518687714, -9.649281508017815],
            [-37.046518724097, -11.040721123908801],
            [-37.68361161960736, -12.171194756725823],
            [-38.42387651218844, -13.038118584854288],
            [-38.673887091616514, -13.057652276260619],
            [-38.953275722802545, -13.793369642800023],
            [-38.88229814304965, -15.667053724838768],
            [-39.16109249526431, -17.208406670808472],
            [-39.2673392400564, -17.867746270420483],
            [-39.58352149103423, -18.262295830968938],
            [-39.76082333022764, -19.59911345792741],
            [-40.77474077001034, -20.904511814052423],
            [-40.94475623225061, -21.937316989837807],
            [-41.754164191238225, -22.370675551037458],
            [-41.98828426773655, -22.970070489190892],
            [-43.07470374202475, -22.96769337330547],
            [-44.64781185563781, -23.351959323827842],
            [-45.35213578955991, -23.796841729428582],
            [-46.47209326840554, -24.08896860117454],
            [-47.64897233742066, -24.885199069927722],
            [-48.4954581365777, -25.877024834905654],
            [-48.64100480812774, -26.623697605090932],
            [-48.47473588722865, -27.17591196056189],
            [-48.661520351747626, -28.186134535435716],
            [-48.88845740415739, -28.674115085567884],
            [-49.587329474472675, -29.224469089476337],
            [-50.696874152211485, -30.984465020472957],
            [-51.576226162306156, -31.77769825615321],
            [-52.256081305538046, -32.24536996839466],
            [-52.712099982297694, -33.19657805759118],
            [-53.373661668498244, -33.768377780900764],
            [-53.6505439927181, -33.20200408298183],
            [-53.209588995971544, -32.727666110974724],
            [-53.787951626182185, -32.047242526987624],
            [-54.57245154480512, -31.494511407193748],
            [-55.601510179249345, -30.853878676071393],
            [-55.97324459494093, -30.883075860316303],
            [-56.97602576356473, -30.109686374636127],
            [-57.62513342958296, -30.216294854454258],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Chile",
        sov_a3: "CHL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Chile",
        adm0_a3: "CHL",
        geou_dif: 0,
        geounit: "Chile",
        gu_a3: "CHL",
        su_dif: 0,
        subunit: "Chile",
        su_a3: "CHL",
        brk_diff: 0,
        name: "Chile",
        name_long: "Chile",
        brk_a3: "CHL",
        brk_name: "Chile",
        brk_group: null,
        abbrev: "Chile",
        postal: "CL",
        formal_en: "Republic of Chile",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Chile",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 5,
        mapcolor13: 9,
        pop_est: 16601707,
        gdp_md_est: 244500,
        pop_year: -99,
        lastcensus: 2002,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CL",
        iso_a3: "CHL",
        iso_n3: "152",
        un_a3: "152",
        wb_a2: "CL",
        wb_a3: "CHL",
        woe_id: -99,
        adm0_a3_is: "CHL",
        adm0_a3_us: "CHL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "CHL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-68.63401022758316, -52.63637045887437],
              [-68.6333499999999, -54.8695],
              [-67.56244, -54.87001],
              [-66.95992, -54.89681],
              [-67.29102999999989, -55.30124],
              [-68.14862999999986, -55.61183],
              [-68.63999081081181, -55.58001799908689],
              [-69.2321, -55.49906],
              [-69.95809, -55.19843],
              [-71.00568, -55.05383],
              [-72.2639, -54.49514],
              [-73.2852, -53.95751999999989],
              [-74.66253, -52.83749],
              [-73.8381, -53.04743],
              [-72.43418, -53.7154],
              [-71.10773, -54.07433],
              [-70.59177999999979, -53.61583],
              [-70.26748, -52.93123],
              [-69.34564999999989, -52.5183],
              [-68.63401022758316, -52.63637045887437],
            ],
          ],
          [
            [
              [-68.21991309271124, -21.494346612231837],
              [-67.82817989772266, -22.87291879648218],
              [-67.10667355006362, -22.7359245744764],
              [-66.98523393417764, -22.98634856536283],
              [-67.32844295924414, -24.02530323659091],
              [-68.41765296087613, -24.518554782816878],
              [-68.38600114609736, -26.185016371365233],
              [-68.59479977077268, -26.506908868111267],
              [-68.2955415513704, -26.89933969493579],
              [-69.00123491074828, -27.52121388113613],
              [-69.65613033718314, -28.459141127233693],
              [-70.01355038112987, -29.36792286551855],
              [-69.91900834825192, -30.336339206668313],
              [-70.53506893581945, -31.365010267870286],
              [-70.07439938015364, -33.09120981214803],
              [-69.81477698431921, -33.27388600029985],
              [-69.81730912950147, -34.193571465798286],
              [-70.38804948594908, -35.16968759535944],
              [-70.36476925320167, -36.005088799789945],
              [-71.1218806627098, -36.65812387466234],
              [-71.11862504747543, -37.5768274879472],
              [-70.81466427273472, -38.55299529394074],
              [-71.41351660834904, -38.916022230791114],
              [-71.68076127794646, -39.80816415787807],
              [-71.91573401557756, -40.83233936947073],
              [-71.74680375841547, -42.051386407235995],
              [-72.14889807807853, -42.25488819760139],
              [-71.91542395698391, -43.40856454851742],
              [-71.46405615913051, -43.78761117937833],
              [-71.79362260607195, -44.20717213315611],
              [-71.32980078803621, -44.40752166115169],
              [-71.22277889675973, -44.784242852559416],
              [-71.65931555854533, -44.97368865334144],
              [-71.55200944689125, -45.56073292417713],
              [-71.91725847033021, -46.8848381487918],
              [-72.44735531278027, -47.73853281025353],
              [-72.33116085477195, -48.244238376661826],
              [-72.64824744331494, -48.87861825947679],
              [-73.41543575712004, -49.31843637471296],
              [-73.32805091011448, -50.37878508890987],
              [-72.97574683296463, -50.74145029073431],
              [-72.30997351753237, -50.677009779666356],
              [-72.32940385607404, -51.42595631287241],
              [-71.91480383979635, -52.009022305865926],
              [-69.49836218939609, -52.14276091263725],
              [-68.57154537624135, -52.29944385534626],
              [-69.46128434922664, -52.29195077266393],
              [-69.94277950710614, -52.53793059037325],
              [-70.84510169135453, -52.899200528525725],
              [-71.00633216010525, -53.83325204220135],
              [-71.42979468452094, -53.85645476030039],
              [-72.55794287788486, -53.531410001184454],
              [-73.70275672066288, -52.83506926860725],
              [-73.70275672066288, -52.8350700760515],
              [-74.94676347522515, -52.26275358841903],
              [-75.2600260077785, -51.629354750373224],
              [-74.9766324530898, -51.04339568461569],
              [-75.4797541978835, -50.37837167745156],
              [-75.60801510283196, -48.6737728818718],
              [-75.18276974150213, -47.71191944762316],
              [-74.1265809801047, -46.9392534319951],
              [-75.64439531116545, -46.64764332457203],
              [-74.69215369332306, -45.76397633238098],
              [-74.35170935738427, -44.103044122087894],
              [-73.2403560045152, -44.454960625995625],
              [-72.71780392117978, -42.383355808278985],
              [-73.38889990913825, -42.11753224056957],
              [-73.70133561877486, -43.365776462579745],
              [-74.33194312203258, -43.22495818458441],
              [-74.01795711942717, -41.794812920906836],
              [-73.67709937202997, -39.942212823243125],
              [-73.21759253609068, -39.258688653318515],
              [-73.50555945503706, -38.28288258235107],
              [-73.58806087919109, -37.156284681956016],
              [-73.1667170884993, -37.12378020604435],
              [-72.55313696968173, -35.508840020491036],
              [-71.86173214383257, -33.90909270603153],
              [-71.43845048692992, -32.41889942803083],
              [-71.66872066922244, -30.92064462659252],
              [-71.37008256700773, -30.095682061485004],
              [-71.48989437527646, -28.861442152625912],
              [-70.90512386746158, -27.6403797340012],
              [-70.72495398627598, -25.70592416758721],
              [-70.40396582709505, -23.628996677344542],
              [-70.09124589708067, -21.393319187101223],
              [-70.16441972520599, -19.756468194256186],
              [-70.37257239447774, -18.347975355708883],
              [-69.85844356960581, -18.092693780187034],
              [-69.590423753524, -17.58001189541929],
              [-69.10024695501943, -18.260125420812656],
              [-68.96681840684184, -18.981683444904093],
              [-68.44222510443095, -19.405068454671422],
              [-68.75716712103372, -20.372657972904477],
              [-68.21991309271124, -21.494346612231837],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Colombia",
        sov_a3: "COL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Colombia",
        adm0_a3: "COL",
        geou_dif: 0,
        geounit: "Colombia",
        gu_a3: "COL",
        su_dif: 0,
        subunit: "Colombia",
        su_a3: "COL",
        brk_diff: 0,
        name: "Colombia",
        name_long: "Colombia",
        brk_a3: "COL",
        brk_name: "Colombia",
        brk_group: null,
        abbrev: "Col.",
        postal: "CO",
        formal_en: "Republic of Colombia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Colombia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 1,
        pop_est: 45644023,
        gdp_md_est: 395400,
        pop_year: -99,
        lastcensus: 2006,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CO",
        iso_a3: "COL",
        iso_n3: "170",
        un_a3: "170",
        wb_a2: "CO",
        wb_a3: "COL",
        woe_id: -99,
        adm0_a3_is: "COL",
        adm0_a3_us: "COL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "COL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.37322323271385, -0.15203175212045],
            [-75.80146582711659, 0.084801337073202],
            [-76.29231441924097, 0.416047268064119],
            [-76.57637976754938, 0.256935533037435],
            [-77.4249843004304, 0.395686753741117],
            [-77.66861284047044, 0.825893052570961],
            [-77.85506140817952, 0.809925034992773],
            [-78.85525875518871, 1.380923773601822],
            [-78.99093522817103, 1.691369940595251],
            [-78.61783138702371, 1.766404120283056],
            [-78.66211808949785, 2.267355454920477],
            [-78.42761043975732, 2.629555568854215],
            [-77.93154252797149, 2.696605739752926],
            [-77.51043128122501, 3.325016994638247],
            [-77.12768978545526, 3.849636135265357],
            [-77.49627193877703, 4.087606105969428],
            [-77.3076012844794, 4.667984117039452],
            [-77.53322058786573, 5.582811997902496],
            [-77.31881507028675, 5.84535411216136],
            [-77.47666073272228, 6.691116441266303],
            [-77.88157141794525, 7.223771267114785],
            [-77.75341386586139, 7.709839789252142],
            [-77.43110795765699, 7.638061224798735],
            [-77.24256649444008, 7.935278225125444],
            [-77.47472286651133, 8.524286200388218],
            [-77.35336076527385, 8.67050466555807],
            [-76.83667395700357, 8.638749497914716],
            [-76.08638383655786, 9.336820583529487],
            [-75.67460018584005, 9.443248195834599],
            [-75.66470414905618, 9.774003200718738],
            [-75.48042599150335, 10.618990383339309],
            [-74.90689510771197, 11.083044745320322],
            [-74.27675269234489, 11.102035834187587],
            [-74.1972226630477, 11.310472723836865],
            [-73.41476396350029, 11.22701528568548],
            [-72.62783525255963, 11.731971543825523],
            [-72.23819495307892, 11.955549628136326],
            [-71.75409013536864, 12.437303168177309],
            [-71.3998223537917, 12.376040757695293],
            [-71.13746110704588, 12.112981879113505],
            [-71.3315836249503, 11.776284084515808],
            [-71.97392167833829, 11.60867157637712],
            [-72.22757544624294, 11.10870209395324],
            [-72.61465776232521, 10.821975409381778],
            [-72.9052860175347, 10.450344346554772],
            [-73.02760413276957, 9.736770331252444],
            [-73.30495154488005, 9.151999823437606],
            [-72.7887298245004, 9.085027167187334],
            [-72.6604947577681, 8.625287787302682],
            [-72.43986223009796, 8.405275376820029],
            [-72.36090064155596, 8.002638454617895],
            [-72.47967892117885, 7.632506008327354],
            [-72.44448727078807, 7.423784898300481],
            [-72.19835242378188, 7.340430813013682],
            [-71.96017574734864, 6.991614895043538],
            [-70.67423356798152, 7.087784735538719],
            [-70.09331295437242, 6.96037649172311],
            [-69.38947994655712, 6.099860541198836],
            [-68.98531856960236, 6.206804917826858],
            [-68.26505245631823, 6.153268133972475],
            [-67.69508724635502, 6.267318020040647],
            [-67.34143958196557, 6.095468044454023],
            [-67.52153194850275, 5.556870428891969],
            [-67.74469662135522, 5.221128648291668],
            [-67.82301225449355, 4.503937282728899],
            [-67.62183590358127, 3.839481716319994],
            [-67.33756384954368, 3.542342230641722],
            [-67.30317318385345, 3.31845408773718],
            [-67.8099381171237, 2.820655015469569],
            [-67.44709204778631, 2.600280869960869],
            [-67.18129431829307, 2.250638129074062],
            [-66.87632585312258, 1.253360500489336],
            [-67.0650481838525, 1.130112209473225],
            [-67.25999752467358, 1.719998684084956],
            [-67.53781002467468, 2.03716278727633],
            [-67.86856502955884, 1.692455145673392],
            [-69.81697323269162, 1.714805202639624],
            [-69.80459672715773, 1.089081122233466],
            [-69.21863766140018, 0.985676581217433],
            [-69.25243404811906, 0.602650865070075],
            [-69.45239600287246, 0.706158758950693],
            [-70.0155657619893, 0.541414292804205],
            [-70.02065589057005, -0.185156345219539],
            [-69.5770653957766, -0.549991957200163],
            [-69.42048580593223, -1.122618503426409],
            [-69.44410193548961, -1.556287123219818],
            [-69.89363521999663, -4.298186944194327],
            [-70.39404395209499, -3.766591485207825],
            [-70.69268205430971, -3.742872002785859],
            [-70.04770850287485, -2.725156345229699],
            [-70.81347571479196, -2.256864515800743],
            [-71.41364579942979, -2.342802422702128],
            [-71.7747607082854, -2.169789727388938],
            [-72.32578650581365, -2.434218031426454],
            [-73.07039221870724, -2.308954359550953],
            [-73.6595035468346, -1.260491224781134],
            [-74.12239518908906, -1.002832533373848],
            [-74.44160051135597, -0.530820000819887],
            [-75.10662451852008, -0.05720549886486],
            [-75.37322323271385, -0.15203175212045],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Ecuador",
        sov_a3: "ECU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Ecuador",
        adm0_a3: "ECU",
        geou_dif: 0,
        geounit: "Ecuador",
        gu_a3: "ECU",
        su_dif: 0,
        subunit: "Ecuador",
        su_a3: "ECU",
        brk_diff: 0,
        name: "Ecuador",
        name_long: "Ecuador",
        brk_a3: "ECU",
        brk_name: "Ecuador",
        brk_group: null,
        abbrev: "Ecu.",
        postal: "EC",
        formal_en: "Republic of Ecuador",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Ecuador",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 14573101,
        gdp_md_est: 107700,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "EC",
        iso_a3: "ECU",
        iso_n3: "218",
        un_a3: "218",
        wb_a2: "EC",
        wb_a3: "ECU",
        woe_id: -99,
        adm0_a3_is: "ECU",
        adm0_a3_us: "ECU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "ECU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-80.30256059438722, -3.404856459164713],
            [-79.77029334178093, -2.65751189535964],
            [-79.98655921092241, -2.220794366061014],
            [-80.36878394236925, -2.685158786635788],
            [-80.96776546906436, -2.246942640800704],
            [-80.76480628123804, -1.965047702648533],
            [-80.9336590237517, -1.057454522306358],
            [-80.58337032746127, -0.906662692878683],
            [-80.39932471385376, -0.283703301600141],
            [-80.02089820018037, 0.360340074053468],
            [-80.09060970734211, 0.768428859862397],
            [-79.54276201039978, 0.982937730305963],
            [-78.85525875518871, 1.380923773601822],
            [-77.85506140817952, 0.809925034992773],
            [-77.66861284047044, 0.825893052570961],
            [-77.4249843004304, 0.395686753741117],
            [-76.57637976754938, 0.256935533037435],
            [-76.29231441924097, 0.416047268064119],
            [-75.80146582711659, 0.084801337073202],
            [-75.37322323271385, -0.15203175212045],
            [-75.23372270374193, -0.911416924649529],
            [-75.54499569365204, -1.56160979574588],
            [-76.63539425322672, -2.608677666843818],
            [-77.83790483265861, -3.003020521663103],
            [-78.45068396677564, -3.873096612161376],
            [-78.63989722361234, -4.547784112164074],
            [-79.20528906931771, -4.959128513207389],
            [-79.62497921417618, -4.454198093283494],
            [-80.02890804718561, -4.346090996928893],
            [-80.44224199087216, -4.425724379090674],
            [-80.46929460317695, -4.059286797708999],
            [-80.18401485870967, -3.821161797708044],
            [-80.30256059438722, -3.404856459164713],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        admin: "Falkland Islands",
        adm0_a3: "FLK",
        geou_dif: 0,
        geounit: "Falkland Islands",
        gu_a3: "FLK",
        su_dif: 0,
        subunit: "Falkland Islands",
        su_a3: "FLK",
        brk_diff: 1,
        name: "Falkland Is.",
        name_long: "Falkland Islands",
        brk_a3: "B12",
        brk_name: "Falkland Is.",
        brk_group: null,
        abbrev: "Flk. Is.",
        postal: "FK",
        formal_en: "Falkland Islands",
        formal_fr: null,
        note_adm0: "U.K.",
        note_brk: "Admin. by U.K.; Claimed by Argentina",
        name_sort: "Falkland Islands",
        name_alt: "Islas Malvinas",
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 3140,
        gdp_md_est: 105.1,
        pop_year: -99,
        lastcensus: -99,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "FK",
        iso_a3: "FLK",
        iso_n3: "238",
        un_a3: "238",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -99,
        adm0_a3_is: "FLK",
        adm0_a3_us: "FLK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 12,
        long_len: 16,
        abbrev_len: 8,
        tiny: -99,
        homepart: -99,
        filename: "FLK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.2, -51.85],
            [-60, -51.25],
            [-59.15, -51.5],
            [-58.55, -51.1],
            [-57.75, -51.55],
            [-58.05, -51.9],
            [-59.4, -52.2],
            [-59.85, -51.85],
            [-60.7, -52.3],
            [-61.2, -51.85],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Guyana",
        sov_a3: "GUY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Guyana",
        adm0_a3: "GUY",
        geou_dif: 0,
        geounit: "Guyana",
        gu_a3: "GUY",
        su_dif: 0,
        subunit: "Guyana",
        su_a3: "GUY",
        brk_diff: 0,
        name: "Guyana",
        name_long: "Guyana",
        brk_a3: "GUY",
        brk_name: "Guyana",
        brk_group: null,
        abbrev: "Guy.",
        postal: "GY",
        formal_en: "Co-operative Republic of Guyana",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Guyana",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 772298,
        gdp_md_est: 2966,
        pop_year: -99,
        lastcensus: 2002,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GY",
        iso_a3: "GUY",
        iso_n3: "328",
        un_a3: "328",
        wb_a2: "GY",
        wb_a3: "GUY",
        woe_id: -99,
        adm0_a3_is: "GUY",
        adm0_a3_us: "GUY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "GUY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-59.758284878159195, 8.367034816924047],
            [-59.101684129458654, 7.999201971870492],
            [-58.482962205628056, 7.347691351750697],
            [-58.45487606467743, 6.832787380394463],
            [-58.07810319683737, 6.809093736188643],
            [-57.542218593970645, 6.321268215353356],
            [-57.14743648947688, 5.973149929219161],
            [-57.307245856339506, 5.073566595882227],
            [-57.91428890647214, 4.812626451024414],
            [-57.86020952007869, 4.57680105226045],
            [-58.04469438336068, 4.060863552258382],
            [-57.60156897645786, 3.334654649260685],
            [-57.2814334784097, 3.333491929534119],
            [-57.150097825739905, 2.768926906745406],
            [-56.539385748914555, 1.899522609866921],
            [-56.78270423036083, 1.863710842288654],
            [-57.335822923396904, 1.94853770589576],
            [-57.66097103537737, 1.682584947105639],
            [-58.11344987652502, 1.507195135907025],
            [-58.42947709820596, 1.463941962078721],
            [-58.5400129868783, 1.268088283692521],
            [-59.03086157900265, 1.317697658692722],
            [-59.64604366722126, 1.786893825686789],
            [-59.71854570172674, 2.24963043864436],
            [-59.97452490908456, 2.755232652188056],
            [-59.81541317405786, 3.606498521332085],
            [-59.53803992373123, 3.958802598481938],
            [-59.767405768458715, 4.423502915866607],
            [-60.11100236676737, 4.574966538914083],
            [-59.98095862490488, 5.014061184098139],
            [-60.21368343773133, 5.244486395687602],
            [-60.73357418480372, 5.200277207861901],
            [-61.410302903881956, 5.959068101419618],
            [-61.13941504580795, 6.234296779806144],
            [-61.15933631045648, 6.696077378766319],
            [-60.54399919294098, 6.856584377464883],
            [-60.29566809756239, 7.043911444522919],
            [-60.637972785063766, 7.414999904810855],
            [-60.55058793805819, 7.779602972846178],
            [-59.758284878159195, 8.367034816924047],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Peru",
        sov_a3: "PER",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Peru",
        adm0_a3: "PER",
        geou_dif: 0,
        geounit: "Peru",
        gu_a3: "PER",
        su_dif: 0,
        subunit: "Peru",
        su_a3: "PER",
        brk_diff: 0,
        name: "Peru",
        name_long: "Peru",
        brk_a3: "PER",
        brk_name: "Peru",
        brk_group: null,
        abbrev: "Peru",
        postal: "PE",
        formal_en: "Republic of Peru",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Peru",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 11,
        pop_est: 29546963,
        gdp_md_est: 247300,
        pop_year: -99,
        lastcensus: 2007,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "PE",
        iso_a3: "PER",
        iso_n3: "604",
        un_a3: "604",
        wb_a2: "PE",
        wb_a3: "PER",
        woe_id: -99,
        adm0_a3_is: "PER",
        adm0_a3_us: "PER",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "PER.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.59042375352405, -17.580011895419332],
            [-69.85844356960587, -18.092693780187012],
            [-70.37257239447771, -18.34797535570887],
            [-71.37525021023691, -17.773798516513857],
            [-71.46204077827112, -17.363487644116383],
            [-73.44452958850042, -16.359362888252996],
            [-75.23788265654144, -15.265682875227782],
            [-76.00920508492995, -14.649286390850321],
            [-76.42346920439775, -13.82318694423243],
            [-76.25924150257416, -13.535039157772943],
            [-77.10619238962184, -12.22271615972082],
            [-78.09215287953464, -10.377712497604065],
            [-79.03695309112695, -8.386567884965892],
            [-79.44592037628485, -7.93083342858386],
            [-79.76057817251004, -7.194340915560083],
            [-80.53748165558608, -6.541667575713717],
            [-81.24999630402642, -6.136834405139183],
            [-80.92634680858244, -5.690556735866565],
            [-81.41094255239946, -4.736764825055459],
            [-81.09966956248937, -4.036394138203697],
            [-80.30256059438722, -3.404856459164713],
            [-80.18401485870967, -3.821161797708044],
            [-80.46929460317695, -4.059286797708999],
            [-80.44224199087216, -4.425724379090674],
            [-80.02890804718561, -4.346090996928893],
            [-79.62497921417618, -4.454198093283494],
            [-79.20528906931771, -4.959128513207389],
            [-78.63989722361234, -4.547784112164074],
            [-78.45068396677564, -3.873096612161376],
            [-77.83790483265861, -3.003020521663103],
            [-76.63539425322672, -2.608677666843818],
            [-75.54499569365204, -1.56160979574588],
            [-75.23372270374193, -0.911416924649529],
            [-75.37322323271385, -0.15203175212045],
            [-75.10662451852008, -0.05720549886486],
            [-74.44160051135597, -0.530820000819887],
            [-74.12239518908906, -1.002832533373848],
            [-73.6595035468346, -1.260491224781134],
            [-73.07039221870724, -2.308954359550953],
            [-72.32578650581365, -2.434218031426454],
            [-71.7747607082854, -2.169789727388938],
            [-71.41364579942979, -2.342802422702128],
            [-70.81347571479196, -2.256864515800743],
            [-70.04770850287485, -2.725156345229699],
            [-70.69268205430971, -3.742872002785859],
            [-70.39404395209499, -3.766591485207825],
            [-69.89363521999663, -4.298186944194327],
            [-70.7947688463023, -4.251264743673303],
            [-70.92884334988358, -4.401591485210368],
            [-71.74840572781655, -4.593982842633011],
            [-72.89192765978726, -5.274561455916981],
            [-72.96450720894119, -5.741251315944893],
            [-73.21971126981461, -6.089188734566078],
            [-73.1200274319236, -6.629930922068239],
            [-73.72448666044164, -6.91859547285064],
            [-73.7234014553635, -7.340998630404414],
            [-73.98723548042966, -7.523829847853064],
            [-73.57105933296707, -8.424446709835834],
            [-73.01538265653254, -9.03283334720806],
            [-73.22671342639016, -9.462212823121234],
            [-72.56303300646564, -9.520193780152717],
            [-72.18489071316984, -10.053597914269432],
            [-71.30241227892154, -10.079436130415374],
            [-70.48189388699117, -9.490118096558845],
            [-70.54868567572841, -11.009146823778465],
            [-70.0937522040469, -11.123971856331012],
            [-69.52967810736496, -10.951734307502194],
            [-68.66507971868961, -12.561300144097173],
            [-68.88007951523997, -12.899729099176653],
            [-68.92922380234954, -13.602683607643007],
            [-68.9488866848366, -14.453639418193283],
            [-69.33953467474701, -14.953195489158832],
            [-69.16034664577495, -15.323973890853019],
            [-69.38976416693471, -15.660129082911654],
            [-68.9596353827533, -16.50069793057127],
            [-69.59042375352405, -17.580011895419332],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Paraguay",
        sov_a3: "PRY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Paraguay",
        adm0_a3: "PRY",
        geou_dif: 0,
        geounit: "Paraguay",
        gu_a3: "PRY",
        su_dif: 0,
        subunit: "Paraguay",
        su_a3: "PRY",
        brk_diff: 0,
        name: "Paraguay",
        name_long: "Paraguay",
        brk_a3: "PRY",
        brk_name: "Paraguay",
        brk_group: null,
        abbrev: "Para.",
        postal: "PY",
        formal_en: "Republic of Paraguay",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Paraguay",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 6,
        mapcolor13: 2,
        pop_est: 6995655,
        gdp_md_est: 28890,
        pop_year: -99,
        lastcensus: 2002,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "PY",
        iso_a3: "PRY",
        iso_n3: "600",
        un_a3: "600",
        wb_a2: "PY",
        wb_a3: "PRY",
        woe_id: -99,
        adm0_a3_is: "PRY",
        adm0_a3_us: "PRY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "PRY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.685057135657885, -22.249029229422387],
            [-62.29117936872922, -21.05163461678739],
            [-62.26596126977079, -20.513734633061276],
            [-61.786326463453776, -19.633736667562964],
            [-60.04356462262649, -19.342746677327426],
            [-59.115042487206104, -19.356906019775398],
            [-58.183471442280506, -19.868399346600363],
            [-58.166392381408045, -20.176700941653678],
            [-57.87067399761779, -20.73268767668195],
            [-57.937155727761294, -22.090175876557172],
            [-56.8815095689029, -22.28215382252148],
            [-56.47331743022939, -22.086300144135283],
            [-55.79795813660691, -22.356929620047822],
            [-55.610682745981144, -22.655619398694842],
            [-55.517639329639636, -23.571997572526634],
            [-55.40074723979542, -23.956935316668805],
            [-55.02790178080954, -24.001273695575225],
            [-54.652834235235126, -23.83957813893396],
            [-54.29295956075451, -24.02101409271073],
            [-54.29347632507745, -24.570799655863965],
            [-54.42894609233059, -25.162184747012166],
            [-54.625290696823576, -25.73925546641551],
            [-54.78879492859505, -26.621785577096134],
            [-55.69584550639816, -27.38783700939086],
            [-56.48670162619299, -27.548499037386293],
            [-57.60975969097615, -27.395898532828387],
            [-58.61817359071974, -27.123718763947096],
            [-57.633660040911124, -25.60365650808164],
            [-57.77721716981794, -25.16233977630904],
            [-58.80712846539498, -24.77145924245331],
            [-60.02896603050402, -24.032796319273274],
            [-60.84656470400991, -23.880712579038292],
            [-62.685057135657885, -22.249029229422387],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Suriname",
        sov_a3: "SUR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Suriname",
        adm0_a3: "SUR",
        geou_dif: 0,
        geounit: "Suriname",
        gu_a3: "SUR",
        su_dif: 0,
        subunit: "Suriname",
        su_a3: "SUR",
        brk_diff: 0,
        name: "Suriname",
        name_long: "Suriname",
        brk_a3: "SUR",
        brk_name: "Suriname",
        brk_group: null,
        abbrev: "Sur.",
        postal: "SR",
        formal_en: "Republic of Suriname",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Suriname",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 7,
        mapcolor13: 6,
        pop_est: 481267,
        gdp_md_est: 4254,
        pop_year: -99,
        lastcensus: 2004,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SR",
        iso_a3: "SUR",
        iso_n3: "740",
        un_a3: "740",
        wb_a2: "SR",
        wb_a3: "SUR",
        woe_id: -99,
        adm0_a3_is: "SUR",
        adm0_a3_us: "SUR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "SUR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.14743648947688, 5.973149929219161],
            [-55.9493184067898, 5.772877915872002],
            [-55.841779751190415, 5.953125311706059],
            [-55.033250291551774, 6.025291449401664],
            [-53.958044603070896, 5.756548163267765],
            [-54.47863298197923, 4.896755682795586],
            [-54.399542202356514, 4.212611395683466],
            [-54.00693050801901, 3.620037746592558],
            [-54.181726040246275, 3.189779771330421],
            [-54.2697051662232, 2.732391669115046],
            [-54.52475419779971, 2.311848863123785],
            [-55.09758744975514, 2.523748073736613],
            [-55.569755011606, 2.421506252447131],
            [-55.973322109589375, 2.510363877773017],
            [-56.0733418442903, 2.220794989425499],
            [-55.905600145070885, 2.02199575439866],
            [-55.99569800477175, 1.817667141116601],
            [-56.539385748914555, 1.899522609866921],
            [-57.150097825739905, 2.768926906745406],
            [-57.2814334784097, 3.333491929534119],
            [-57.60156897645786, 3.334654649260685],
            [-58.04469438336068, 4.060863552258382],
            [-57.86020952007869, 4.57680105226045],
            [-57.91428890647214, 4.812626451024414],
            [-57.307245856339506, 5.073566595882227],
            [-57.14743648947688, 5.973149929219161],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Uruguay",
        sov_a3: "URY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Uruguay",
        adm0_a3: "URY",
        geou_dif: 0,
        geounit: "Uruguay",
        gu_a3: "URY",
        su_dif: 0,
        subunit: "Uruguay",
        su_a3: "URY",
        brk_diff: 0,
        name: "Uruguay",
        name_long: "Uruguay",
        brk_a3: "URY",
        brk_name: "Uruguay",
        brk_group: null,
        abbrev: "Ury.",
        postal: "UY",
        formal_en: "Oriental Republic of Uruguay",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Uruguay",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 10,
        pop_est: 3494382,
        gdp_md_est: 43160,
        pop_year: -99,
        lastcensus: 2004,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "UY",
        iso_a3: "URY",
        iso_n3: "858",
        un_a3: "858",
        wb_a2: "UY",
        wb_a3: "URY",
        woe_id: -99,
        adm0_a3_is: "URY",
        adm0_a3_us: "URY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "URY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.62513342958296, -30.216294854454258],
            [-56.97602576356473, -30.109686374636127],
            [-55.97324459494093, -30.883075860316303],
            [-55.601510179249345, -30.853878676071393],
            [-54.57245154480512, -31.494511407193748],
            [-53.787951626182185, -32.047242526987624],
            [-53.209588995971544, -32.727666110974724],
            [-53.6505439927181, -33.20200408298183],
            [-53.373661668498244, -33.768377780900764],
            [-53.806425950726535, -34.396814874002224],
            [-54.93586605489773, -34.952646579733624],
            [-55.67408972840329, -34.75265878676407],
            [-56.21529700379607, -34.85983570733742],
            [-57.139685024633096, -34.430456231424245],
            [-57.8178606838155, -34.4625472958775],
            [-58.42707414410439, -33.90945444105757],
            [-58.34961117209887, -33.26318897881541],
            [-58.13264767112144, -33.040566908502015],
            [-58.14244035504076, -32.044503676076154],
            [-57.87493730328188, -31.016556084926208],
            [-57.62513342958296, -30.216294854454258],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Venezuela",
        sov_a3: "VEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Venezuela",
        adm0_a3: "VEN",
        geou_dif: 0,
        geounit: "Venezuela",
        gu_a3: "VEN",
        su_dif: 0,
        subunit: "Venezuela",
        su_a3: "VEN",
        brk_diff: 0,
        name: "Venezuela",
        name_long: "Venezuela",
        brk_a3: "VEN",
        brk_name: "Venezuela",
        brk_group: null,
        abbrev: "Ven.",
        postal: "VE",
        formal_en: "Bolivarian Republic of Venezuela",
        formal_fr: "República Bolivariana de Venezuela",
        note_adm0: null,
        note_brk: null,
        name_sort: "Venezuela, RB",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 26814843,
        gdp_md_est: 357400,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "VE",
        iso_a3: "VEN",
        iso_n3: "862",
        un_a3: "862",
        wb_a2: "VE",
        wb_a3: "VEN",
        woe_id: -99,
        adm0_a3_is: "VEN",
        adm0_a3_us: "VEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "South America",
        region_un: "Americas",
        subregion: "South America",
        region_wb: "Latin America & Caribbean",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "VEN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.3315836249503, 11.776284084515808],
            [-71.36000566271082, 11.53999359786121],
            [-71.94704993354651, 11.423282375530022],
            [-71.62086829292019, 10.969459947142795],
            [-71.63306393094108, 10.446494452349027],
            [-72.07417395698451, 9.865651353388373],
            [-71.69564409044654, 9.072263088411248],
            [-71.26455929226773, 9.137194525585983],
            [-71.03999935574339, 9.859992784052407],
            [-71.35008378771079, 10.211935126176215],
            [-71.40062333849224, 10.968969021036015],
            [-70.1552988349065, 11.37548167566004],
            [-70.29384334988103, 11.846822414594214],
            [-69.94324459499683, 12.162307033736099],
            [-69.58430009629747, 11.459610907431212],
            [-68.88299923366445, 11.443384507691563],
            [-68.23327145045873, 10.885744126829946],
            [-68.19412655299763, 10.554653225135922],
            [-67.29624854192633, 10.54586823164631],
            [-66.227864142508, 10.648626817258688],
            [-65.65523759628175, 10.200798855017323],
            [-64.89045223657817, 10.0772146671913],
            [-64.32947872583374, 10.38959870039568],
            [-64.31800655786495, 10.64141795495398],
            [-63.07932247582874, 10.7017243514386],
            [-61.880946010980196, 10.715625311725104],
            [-62.73011898461641, 10.420268662960906],
            [-62.388511928950976, 9.94820445397464],
            [-61.58876746280193, 9.873066921422264],
            [-60.83059668643172, 9.38133982994894],
            [-60.67125240745973, 8.580174261911878],
            [-60.15009558779618, 8.602756862823426],
            [-59.758284878159195, 8.367034816924047],
            [-60.55058793805819, 7.779602972846178],
            [-60.637972785063766, 7.414999904810855],
            [-60.29566809756239, 7.043911444522919],
            [-60.54399919294098, 6.856584377464883],
            [-61.15933631045648, 6.696077378766319],
            [-61.13941504580795, 6.234296779806144],
            [-61.410302903881956, 5.959068101419618],
            [-60.73357418480372, 5.200277207861901],
            [-60.60117916527194, 4.91809804933213],
            [-60.96689327660153, 4.536467596856639],
            [-62.08542965355914, 4.162123521334308],
            [-62.804533047116706, 4.006965033377952],
            [-63.0931975978991, 3.770571193858785],
            [-63.88834286157416, 4.020530096854571],
            [-64.62865943058755, 4.14848094320925],
            [-64.81606401229402, 4.056445217297423],
            [-64.36849443221409, 3.797210394705246],
            [-64.40882788761792, 3.126786200366624],
            [-64.26999915226578, 2.497005520025567],
            [-63.42286739770512, 2.411067613124174],
            [-63.36878801131166, 2.200899562993129],
            [-64.08308549666609, 1.91636912679408],
            [-64.19930579289051, 1.49285492594602],
            [-64.61101192895985, 1.328730576987042],
            [-65.35471330428837, 1.0952822941085],
            [-65.54826738143757, 0.78925446207603],
            [-66.32576514348496, 0.724452215982012],
            [-66.87632585312258, 1.253360500489336],
            [-67.18129431829307, 2.250638129074062],
            [-67.44709204778631, 2.600280869960869],
            [-67.8099381171237, 2.820655015469569],
            [-67.30317318385345, 3.31845408773718],
            [-67.33756384954368, 3.542342230641722],
            [-67.62183590358127, 3.839481716319994],
            [-67.82301225449355, 4.503937282728899],
            [-67.74469662135522, 5.221128648291668],
            [-67.52153194850275, 5.556870428891969],
            [-67.34143958196557, 6.095468044454023],
            [-67.69508724635502, 6.267318020040647],
            [-68.26505245631823, 6.153268133972475],
            [-68.98531856960236, 6.206804917826858],
            [-69.38947994655712, 6.099860541198836],
            [-70.09331295437242, 6.96037649172311],
            [-70.67423356798152, 7.087784735538719],
            [-71.96017574734864, 6.991614895043538],
            [-72.19835242378188, 7.340430813013682],
            [-72.44448727078807, 7.423784898300481],
            [-72.47967892117885, 7.632506008327354],
            [-72.36090064155596, 8.002638454617895],
            [-72.43986223009796, 8.405275376820029],
            [-72.6604947577681, 8.625287787302682],
            [-72.7887298245004, 9.085027167187334],
            [-73.30495154488005, 9.151999823437606],
            [-73.02760413276957, 9.736770331252444],
            [-72.9052860175347, 10.450344346554772],
            [-72.61465776232521, 10.821975409381778],
            [-72.22757544624294, 11.10870209395324],
            [-71.97392167833829, 11.60867157637712],
            [-71.3315836249503, 11.776284084515808],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Afghanistan",
        sov_a3: "AFG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Afghanistan",
        adm0_a3: "AFG",
        geou_dif: 0,
        geounit: "Afghanistan",
        gu_a3: "AFG",
        su_dif: 0,
        subunit: "Afghanistan",
        su_a3: "AFG",
        brk_diff: 0,
        name: "Afghanistan",
        name_long: "Afghanistan",
        brk_a3: "AFG",
        brk_name: "Afghanistan",
        brk_group: null,
        abbrev: "Afg.",
        postal: "AF",
        formal_en: "Islamic State of Afghanistan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Afghanistan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 8,
        mapcolor13: 7,
        pop_est: 28400000,
        gdp_md_est: 22270,
        pop_year: -99,
        lastcensus: 1979,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "AF",
        iso_a3: "AFG",
        iso_n3: "004",
        un_a3: "004",
        wb_a2: "AF",
        wb_a3: "AFG",
        woe_id: -99,
        adm0_a3_is: "AFG",
        adm0_a3_us: "AFG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "AFG.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [61.21081709172574, 35.650072333309225],
            [62.230651483005886, 35.270663967422294],
            [62.9846623065766, 35.40404083916762],
            [63.19353844590035, 35.857165635718914],
            [63.9828959491587, 36.0079574651466],
            [64.5464791197339, 36.31207326918427],
            [64.7461051776774, 37.111817735333304],
            [65.58894778835784, 37.30521678318564],
            [65.74563073106681, 37.66116404881207],
            [66.21738488145932, 37.39379018813392],
            [66.51860680528867, 37.36278432875879],
            [67.07578209825962, 37.35614390720929],
            [67.82999962755952, 37.14499400486468],
            [68.13556237170138, 37.0231151393043],
            [68.85944583524594, 37.344335842430596],
            [69.19627282092438, 37.15114350030743],
            [69.51878543485796, 37.60899669041341],
            [70.11657840361033, 37.58822276463209],
            [70.27057417184014, 37.735164699854025],
            [70.3763041523093, 38.13839590102752],
            [70.80682050973289, 38.486281643216415],
            [71.34813113799026, 38.258905341132156],
            [71.23940392444817, 37.95326508234188],
            [71.54191775908478, 37.905774441065645],
            [71.44869347523024, 37.06564484308051],
            [71.8446382994506, 36.73817129164692],
            [72.1930408059624, 36.948287665345674],
            [72.63688968291729, 37.047558091778356],
            [73.26005577992501, 37.495256862939],
            [73.9486959166465, 37.4215662704908],
            [74.98000247589542, 37.419990139305895],
            [75.15802778514092, 37.13303091078912],
            [74.57589277537298, 37.02084137628346],
            [74.06755171091783, 36.83617564548845],
            [72.92002485544447, 36.72000702569632],
            [71.84629194528392, 36.50994232842986],
            [71.26234826038575, 36.074387518857804],
            [71.49876793812109, 35.650563259416],
            [71.6130762063507, 35.153203436822864],
            [71.11501875192162, 34.733125718722235],
            [71.15677330921346, 34.34891144463215],
            [70.8818030129884, 33.98885590263851],
            [69.9305432473596, 34.02012014417511],
            [70.3235941913716, 33.35853261975839],
            [69.68714725126485, 33.105498969041236],
            [69.26252200712256, 32.5019440780883],
            [69.31776411324255, 31.90141225842444],
            [68.92667687365767, 31.620189113892064],
            [68.55693200060932, 31.713310044882018],
            [67.79268924344478, 31.58293040620963],
            [67.68339358914747, 31.30315420178142],
            [66.93889122911847, 31.304911200479353],
            [66.38145755398602, 30.73889923758645],
            [66.34647260932442, 29.887943427036177],
            [65.0468620136161, 29.472180691031905],
            [64.35041873561852, 29.560030625928093],
            [64.14800215033125, 29.340819200145972],
            [63.55026085801117, 29.468330796826162],
            [62.54985680527278, 29.31857249604431],
            [60.874248488208785, 29.829238999952604],
            [61.781221551363444, 30.735850328081234],
            [61.699314406180825, 31.37950613049267],
            [60.94194461451113, 31.548074652628753],
            [60.863654819588966, 32.18291962333443],
            [60.536077915290775, 32.98126882581157],
            [60.963700392506006, 33.52883230237625],
            [60.52842980331158, 33.676446031218006],
            [60.80319339380745, 34.40410187431986],
            [61.21081709172574, 35.650072333309225],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "United Arab Emirates",
        sov_a3: "ARE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "United Arab Emirates",
        adm0_a3: "ARE",
        geou_dif: 0,
        geounit: "United Arab Emirates",
        gu_a3: "ARE",
        su_dif: 0,
        subunit: "United Arab Emirates",
        su_a3: "ARE",
        brk_diff: 0,
        name: "United Arab Emirates",
        name_long: "United Arab Emirates",
        brk_a3: "ARE",
        brk_name: "United Arab Emirates",
        brk_group: null,
        abbrev: "U.A.E.",
        postal: "AE",
        formal_en: "United Arab Emirates",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "United Arab Emirates",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 3,
        pop_est: 4798491,
        gdp_md_est: 184300,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "AE",
        iso_a3: "ARE",
        iso_n3: "784",
        un_a3: "784",
        wb_a2: "AE",
        wb_a3: "ARE",
        woe_id: -99,
        adm0_a3_is: "ARE",
        adm0_a3_us: "ARE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 20,
        long_len: 20,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "ARE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [51.57951867046327, 24.245497137951105],
            [51.757440626844186, 24.29407298430547],
            [51.794389275932865, 24.019826158132506],
            [52.57708051942561, 24.177439276622707],
            [53.404006788960146, 24.15131684009917],
            [54.00800092958758, 24.121757920828212],
            [54.69302371604863, 24.79789236093509],
            [55.43902469261414, 25.43914520924494],
            [56.07082075381456, 26.055464178973978],
            [56.261041701080956, 25.71460643157677],
            [56.396847365144005, 24.924732163995486],
            [55.88623253766801, 24.920830593357444],
            [55.804118686756226, 24.269604193615265],
            [55.981213820220454, 24.130542914317825],
            [55.52863162620823, 23.933604030853502],
            [55.525841098864475, 23.524869289640932],
            [55.234489373602884, 23.11099274341532],
            [55.20834109886319, 22.708329982997046],
            [55.0068030129249, 22.496947536707136],
            [52.000733270074335, 23.00115448657894],
            [51.61770755392698, 24.014219265228828],
            [51.57951867046327, 24.245497137951105],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Armenia",
        sov_a3: "ARM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Armenia",
        adm0_a3: "ARM",
        geou_dif: 0,
        geounit: "Armenia",
        gu_a3: "ARM",
        su_dif: 0,
        subunit: "Armenia",
        su_a3: "ARM",
        brk_diff: 0,
        name: "Armenia",
        name_long: "Armenia",
        brk_a3: "ARM",
        brk_name: "Armenia",
        brk_group: null,
        abbrev: "Arm.",
        postal: "ARM",
        formal_en: "Republic of Armenia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Armenia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 10,
        pop_est: 2967004,
        gdp_md_est: 18770,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "AM",
        iso_a3: "ARM",
        iso_n3: "051",
        un_a3: "051",
        wb_a2: "AM",
        wb_a3: "ARM",
        woe_id: -99,
        adm0_a3_is: "ARM",
        adm0_a3_us: "ARM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "ARM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.582745802592726, 41.09214325618257],
            [44.97248009621808, 41.248128567055595],
            [45.17949588397934, 40.98535390885141],
            [45.56035118997045, 40.812289537105926],
            [45.35917483905817, 40.56150381119346],
            [45.89190717955509, 40.21847565364],
            [45.61001224140293, 39.89999380142518],
            [46.034534132680676, 39.628020738273065],
            [46.48349897643246, 39.464154771475535],
            [46.50571984231797, 38.770605373686294],
            [46.14362308124881, 38.74120148371222],
            [45.73537926614301, 39.31971914321974],
            [45.73997846861698, 39.47399913182713],
            [45.298144972521456, 39.471751207022436],
            [45.00198733905674, 39.740003567049555],
            [44.79398969908195, 39.71300263117705],
            [44.4000085792887, 40.00500031184228],
            [43.65643639504094, 40.253563951166186],
            [43.75265791196841, 40.74020091405876],
            [43.582745802592726, 41.09214325618257],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Azerbaijan",
        sov_a3: "AZE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Azerbaijan",
        adm0_a3: "AZE",
        geou_dif: 0,
        geounit: "Azerbaijan",
        gu_a3: "AZE",
        su_dif: 0,
        subunit: "Azerbaijan",
        su_a3: "AZE",
        brk_diff: 0,
        name: "Azerbaijan",
        name_long: "Azerbaijan",
        brk_a3: "AZE",
        brk_name: "Azerbaijan",
        brk_group: null,
        abbrev: "Aze.",
        postal: "AZ",
        formal_en: "Republic of Azerbaijan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Azerbaijan",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 8,
        pop_est: 8238672,
        gdp_md_est: 77610,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "AZ",
        iso_a3: "AZE",
        iso_n3: "031",
        un_a3: "031",
        wb_a2: "AZ",
        wb_a3: "AZE",
        woe_id: -99,
        adm0_a3_is: "AZE",
        adm0_a3_us: "AZE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "AZE.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [45.0019873390568, 39.7400035670496],
              [45.29814497252144, 39.471751207022436],
              [45.739978468617004, 39.473999131827156],
              [45.73537926614309, 39.3197191432198],
              [46.14362308124881, 38.74120148371222],
              [45.457721795438744, 38.874139105783115],
              [44.95268802265028, 39.33576467544643],
              [44.793989699082005, 39.713002631177034],
              [45.0019873390568, 39.7400035670496],
            ],
          ],
          [
            [
              [47.373315464066216, 41.219732367511256],
              [47.81566572448472, 41.151416124021345],
              [47.98728315612604, 41.40581920019423],
              [48.58435265482629, 41.808869533854676],
              [49.11026370626067, 41.282286688800525],
              [49.6189148293096, 40.57292430272997],
              [50.0848295428531, 40.52615713150578],
              [50.39282107931271, 40.256561184239104],
              [49.5692021014448, 40.17610097916071],
              [49.39525923035043, 39.39948171646225],
              [49.223228387250714, 39.04921885838792],
              [48.85653242370759, 38.81548635513178],
              [48.88324913920255, 38.32024526626264],
              [48.634375441284845, 38.27037750910094],
              [48.010744256386516, 38.794014797514535],
              [48.355529412637935, 39.28876496027689],
              [48.06009524922527, 39.582235419262446],
              [47.685079380083124, 39.50836395930119],
              [46.50571984231797, 38.770605373686266],
              [46.48349897643246, 39.464154771475535],
              [46.034534132680704, 39.62802073827305],
              [45.61001224140293, 39.89999380142518],
              [45.89190717955515, 40.218475653639985],
              [45.35917483905817, 40.56150381119349],
              [45.560351189970476, 40.81228953710595],
              [45.1794958839794, 40.98535390885143],
              [44.972480096218156, 41.24812856705562],
              [45.21742638528164, 41.41145193131405],
              [45.962600538930445, 41.1238725856098],
              [46.501637404166985, 41.06444468847411],
              [46.637908156120574, 41.181672675128226],
              [46.14543175637899, 41.72280243587264],
              [46.404950799348825, 41.86067515722735],
              [46.68607059101666, 41.827137152669906],
              [47.373315464066216, 41.219732367511256],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Bangladesh",
        sov_a3: "BGD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Bangladesh",
        adm0_a3: "BGD",
        geou_dif: 0,
        geounit: "Bangladesh",
        gu_a3: "BGD",
        su_dif: 0,
        subunit: "Bangladesh",
        su_a3: "BGD",
        brk_diff: 0,
        name: "Bangladesh",
        name_long: "Bangladesh",
        brk_a3: "BGD",
        brk_name: "Bangladesh",
        brk_group: null,
        abbrev: "Bang.",
        postal: "BD",
        formal_en: "People's Republic of Bangladesh",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Bangladesh",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 4,
        mapcolor9: 7,
        mapcolor13: 7,
        pop_est: 156050883,
        gdp_md_est: 224000,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BD",
        iso_a3: "BGD",
        iso_n3: "050",
        un_a3: "050",
        wb_a2: "BD",
        wb_a3: "BGD",
        woe_id: -99,
        adm0_a3_is: "BGD",
        adm0_a3_us: "BGD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "BGD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [92.67272098182556, 22.041238918541254],
            [92.65225711463799, 21.324047552978485],
            [92.30323449093868, 21.47548533780982],
            [92.36855350135562, 20.670883287025347],
            [92.08288618364612, 21.19219513598577],
            [92.02521528520839, 21.701569729086767],
            [91.83489098507744, 22.18293569588556],
            [91.41708702999766, 22.76501902922122],
            [90.49600630082728, 22.80501658781513],
            [90.58695682166098, 22.392793687422866],
            [90.27297081905554, 21.83636770272011],
            [89.84746707556428, 22.039146023033425],
            [89.70204959509493, 21.857115790285306],
            [89.41886274613549, 21.9661789006373],
            [89.03196129756623, 22.055708319582976],
            [88.87631188350309, 22.879146429937826],
            [88.52976972855377, 23.631141872649163],
            [88.69994022009092, 24.23371491138856],
            [88.08442223506242, 24.501657212821925],
            [88.30637251175602, 24.866079413344206],
            [88.93155398962308, 25.238692328384776],
            [88.2097892598025, 25.76806570078271],
            [88.56304935094977, 26.44652558034272],
            [89.35509402868729, 26.014407253518073],
            [89.83248091019962, 25.96508209889548],
            [89.92069258012185, 25.26974986419218],
            [90.8722107279121, 25.132600612889547],
            [91.79959598182207, 25.147431748957317],
            [92.37620161333481, 24.976692816664965],
            [91.91509280799443, 24.13041372323711],
            [91.46772993364367, 24.072639471934792],
            [91.15896325069971, 23.50352692310439],
            [91.70647505083211, 22.985263983649183],
            [91.86992760617132, 23.624346421802784],
            [92.14603478390681, 23.627498684172593],
            [92.67272098182556, 22.041238918541254],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Brunei",
        sov_a3: "BRN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Brunei",
        adm0_a3: "BRN",
        geou_dif: 0,
        geounit: "Brunei",
        gu_a3: "BRN",
        su_dif: 0,
        subunit: "Brunei",
        su_a3: "BRN",
        brk_diff: 0,
        name: "Brunei",
        name_long: "Brunei Darussalam",
        brk_a3: "BRN",
        brk_name: "Brunei",
        brk_group: null,
        abbrev: "Brunei",
        postal: "BN",
        formal_en: "Negara Brunei Darussalam",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Brunei",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 12,
        pop_est: 388190,
        gdp_md_est: 20250,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BN",
        iso_a3: "BRN",
        iso_n3: "096",
        un_a3: "096",
        wb_a2: "BN",
        wb_a3: "BRN",
        woe_id: -99,
        adm0_a3_is: "BRN",
        adm0_a3_us: "BRN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 6,
        long_len: 17,
        abbrev_len: 6,
        tiny: 2,
        homepart: 1,
        filename: "BRN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [114.20401655482837, 4.525873928236805],
            [114.59996137904872, 4.900011298029966],
            [115.45071048386981, 5.447729803891534],
            [115.4057003113436, 4.955227565933839],
            [115.34746097215067, 4.316636053887009],
            [114.8695573263154, 4.348313706881925],
            [114.65959598191353, 4.007636826997754],
            [114.20401655482837, 4.525873928236805],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Bhutan",
        sov_a3: "BTN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Bhutan",
        adm0_a3: "BTN",
        geou_dif: 0,
        geounit: "Bhutan",
        gu_a3: "BTN",
        su_dif: 0,
        subunit: "Bhutan",
        su_a3: "BTN",
        brk_diff: 0,
        name: "Bhutan",
        name_long: "Bhutan",
        brk_a3: "BTN",
        brk_name: "Bhutan",
        brk_group: null,
        abbrev: "Bhutan",
        postal: "BT",
        formal_en: "Kingdom of Bhutan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Bhutan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 691141,
        gdp_md_est: 3524,
        pop_year: -99,
        lastcensus: 2005,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BT",
        iso_a3: "BTN",
        iso_n3: "064",
        un_a3: "064",
        wb_a2: "BT",
        wb_a3: "BTN",
        woe_id: -99,
        adm0_a3_is: "BTN",
        adm0_a3_us: "BTN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "BTN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [91.69665652869668, 27.77174184825166],
            [92.10371178585973, 27.452614040633208],
            [92.03348351437509, 26.83831045176356],
            [91.21751264848643, 26.808648179628022],
            [90.37327477413407, 26.87572418874288],
            [89.74452762243884, 26.719402981059957],
            [88.83564253128938, 27.098966376243762],
            [88.81424848832054, 27.29931590423936],
            [89.47581017452111, 28.042758897406397],
            [90.01582889197118, 28.296438503527217],
            [90.7305139505678, 28.064953925075756],
            [91.25885379431992, 28.040614325466294],
            [91.69665652869668, 27.77174184825166],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "China",
        sov_a3: "CH1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        admin: "China",
        adm0_a3: "CHN",
        geou_dif: 0,
        geounit: "China",
        gu_a3: "CHN",
        su_dif: 0,
        subunit: "China",
        su_a3: "CHN",
        brk_diff: 0,
        name: "China",
        name_long: "China",
        brk_a3: "CHN",
        brk_name: "China",
        brk_group: null,
        abbrev: "China",
        postal: "CN",
        formal_en: "People's Republic of China",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "China",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 3,
        pop_est: 1338612970,
        gdp_md_est: 7973000,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "3. Emerging region: BRIC",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CN",
        iso_a3: "CHN",
        iso_n3: "156",
        un_a3: "156",
        wb_a2: "CN",
        wb_a3: "CHN",
        woe_id: -99,
        adm0_a3_is: "CHN",
        adm0_a3_us: "CHN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "CHN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [110.33918786015154, 18.678395087147607],
              [109.4752095886637, 18.197700913968614],
              [108.65520796105616, 18.5076819930714],
              [108.62621748254045, 19.367887885001977],
              [109.11905561730802, 19.821038519769385],
              [110.21159874882285, 20.101253973872076],
              [110.78655073450223, 20.07753449145008],
              [111.01005130416465, 19.695929877190736],
              [110.57064660038682, 19.255879218009312],
              [110.33918786015154, 18.678395087147607],
            ],
          ],
          [
            [
              [127.6574072612624, 49.76027049417294],
              [129.39781782442046, 49.44060008401544],
              [130.5822933289824, 48.72968740497612],
              [130.98728152885386, 47.790132351261406],
              [132.50667199109952, 47.78896963153488],
              [133.37359581922803, 48.18344167743493],
              [135.02631147678673, 48.47822988544391],
              [134.50081383681064, 47.57843984637785],
              [134.11236209527263, 47.21246735288673],
              [133.7696439963129, 46.11692698829907],
              [133.09712690646646, 45.14406647397217],
              [131.8834542176596, 45.32116160743644],
              [131.0252120301561, 44.96795319272157],
              [131.28855512911557, 44.111519680348266],
              [131.14468794161488, 42.92998973242695],
              [130.6338664084098, 42.90301463477056],
              [130.64001590385246, 42.39500946712528],
              [129.99426720593326, 42.98538686784379],
              [129.5966687358795, 42.42498179785459],
              [128.05221520397234, 41.99428457291799],
              [128.20843305879075, 41.46677155208254],
              [127.34378299368305, 41.50315176041596],
              [126.86908328664988, 41.81656932226616],
              [126.18204511932946, 41.10733612727637],
              [125.07994184784062, 40.569823716792456],
              [124.26562462778534, 39.92849335383414],
              [122.86757042856104, 39.63778758397626],
              [122.13138797413094, 39.17045176854464],
              [121.05455447803288, 38.89747101496291],
              [121.5859949077225, 39.36085358332414],
              [121.37675703337267, 39.750261338859524],
              [122.16859500538104, 40.422442531896046],
              [121.64035851449356, 40.94638987890332],
              [120.76862877816197, 40.5933881699176],
              [119.63960208544907, 39.89805593521421],
              [119.02346398323304, 39.2523330755111],
              [118.04274865119793, 39.20427399347969],
              [117.53270226447708, 38.7376358098841],
              [118.0596985209897, 38.06147553156105],
              [118.87814985562838, 37.8973253443859],
              [118.91163618375353, 37.44846385349874],
              [119.70280236214207, 37.15638865818508],
              [120.82345747282366, 37.87042776137798],
              [121.71125857959797, 37.48112335870718],
              [122.35793745329848, 37.45448415786069],
              [122.51999474496584, 36.930614325501836],
              [121.10416385303303, 36.65132904718044],
              [120.6370089051146, 36.111439520811125],
              [119.6645618022461, 35.609790554337735],
              [119.1512081238586, 34.909859117160465],
              [120.22752485563375, 34.36033193616862],
              [120.6203690939166, 33.37672272392513],
              [121.22901411345023, 32.46031871187719],
              [121.90814578663006, 31.69217438407469],
              [121.89191938689035, 30.949351508095102],
              [121.26425744027331, 30.67626740164872],
              [121.50351932178475, 30.142914943964257],
              [122.09211388558911, 29.832520453403163],
              [121.93842817595308, 29.018022365834806],
              [121.68443851123847, 28.225512600206685],
              [121.12566124886645, 28.135673122667185],
              [120.39547326058234, 27.053206895449392],
              [119.58549686083958, 25.740780544532612],
              [118.65687137255453, 24.547390855400238],
              [117.28160647997086, 23.624501451099718],
              [115.89073530483515, 22.782873236578098],
              [114.76382734584624, 22.66807404224167],
              [114.15254682826568, 22.223760077396207],
              [113.80677981980075, 22.54833974862143],
              [113.24107791550162, 22.05136749927047],
              [111.84359215703248, 21.550493679281512],
              [110.78546552942414, 21.397143866455334],
              [110.44403934127169, 20.341032619706397],
              [109.88986128137357, 20.282457383703445],
              [109.62765506392466, 21.008227037026728],
              [109.86448815311834, 21.395050970947523],
              [108.52281294152444, 21.71521230721183],
              [108.050180291783, 21.552379869060104],
              [107.04342003787266, 21.811898912029903],
              [106.56727339073537, 22.218204860924743],
              [106.7254032735485, 22.79426788989838],
              [105.81124718630521, 22.976892401617903],
              [105.32920942588666, 23.352063300056983],
              [104.4768583516645, 22.81915009204692],
              [103.50451460166053, 22.70375661873922],
              [102.70699222210018, 22.708795070887703],
              [102.17043582561355, 22.464753119389343],
              [101.65201785686158, 22.31819875740956],
              [101.80311974488292, 21.174366766845054],
              [101.27002566936002, 21.20165192309517],
              [101.18000532430759, 21.43657298429406],
              [101.15003299357826, 21.84998444262902],
              [100.41653771362738, 21.558839423096657],
              [99.98348921102158, 21.74293671313646],
              [99.24089887898722, 22.118314317304566],
              [99.53199222208744, 22.949038804612595],
              [98.89874922078283, 23.142722072842588],
              [98.6602624857558, 24.063286037690006],
              [97.60471967976203, 23.897404690033056],
              [97.72460900267916, 25.083637193293043],
              [98.67183800658924, 25.918702500913497],
              [98.71209394734458, 26.74353587494025],
              [98.68269005737054, 27.50881216075066],
              [98.24623091023338, 27.74722138112918],
              [97.91198774616944, 28.335945136014374],
              [97.32711388549004, 28.26158274994634],
              [96.24883344928784, 28.411030992134467],
              [96.58659061074755, 28.83097951915437],
              [96.11767866413103, 29.452802028922516],
              [95.40480228066465, 29.031716620392164],
              [94.56599043170294, 29.277438055939964],
              [93.41334760943268, 28.640629380807237],
              [92.50311893104364, 27.89687632904645],
              [91.6966565286967, 27.771741848251622],
              [91.25885379431989, 28.04061432546635],
              [90.73051395056783, 28.064953925075738],
              [90.01582889197121, 28.296438503527185],
              [89.47581017452116, 28.042758897406372],
              [88.8142484883206, 27.299315904239393],
              [88.73032596227856, 28.08686473236756],
              [88.12044070836994, 27.876541652939576],
              [86.95451704300065, 27.97426178640353],
              [85.82331994013154, 28.20357595469875],
              [85.01163821812307, 28.642773952747376],
              [84.23457970575018, 28.839893703724698],
              [83.89899295444675, 29.32022614187764],
              [83.33711510613719, 29.463731594352193],
              [82.32751264845089, 30.115268052688208],
              [81.5258044778748, 30.422716986608663],
              [81.11125613802928, 30.18348094331341],
              [79.72136681510712, 30.882714748654735],
              [78.73889448437401, 31.51590607352705],
              [78.45844648632604, 32.61816437431273],
              [79.17612877799556, 32.483779812137755],
              [79.20889163606856, 32.99439463961374],
              [78.81108646028574, 33.506198025032404],
              [78.91226891471322, 34.32193634697577],
              [77.83745079947462, 35.49400950778781],
              [76.19284834178572, 35.89840342868786],
              [75.89689741405019, 36.66680613865188],
              [75.158027785141, 37.13303091078916],
              [74.98000247589542, 37.419990139305895],
              [74.82998579295216, 37.99000702570146],
              [74.8648157083168, 38.3788463404816],
              [74.2575142760227, 38.60650686294349],
              [73.9288521666464, 38.50581533462272],
              [73.67537926625485, 39.43123688410557],
              [73.96001305531846, 39.660008449861714],
              [73.82224368682833, 39.89397349706314],
              [74.77686242055606, 40.36642527929163],
              [75.46782799673073, 40.56207225194868],
              [76.52636803579745, 40.42794607193513],
              [76.90448449087712, 41.06648590754966],
              [78.18719689322606, 41.185315863604814],
              [78.54366092317528, 41.58224254003871],
              [80.11943037305142, 42.123940741538235],
              [80.25999026888533, 42.34999929459909],
              [80.18015018099439, 42.92006785742686],
              [80.86620649610123, 43.180362046881015],
              [79.96610639844144, 44.91751699480462],
              [81.9470707539181, 45.31702749285316],
              [82.45892581576905, 45.539649563166506],
              [83.18048383986054, 47.33003123635075],
              [85.16429039911324, 47.00095571551611],
              [85.7204838398707, 47.45296946877309],
              [85.76823286330838, 48.4557506373969],
              [86.59877648310336, 48.54918162698061],
              [87.35997033076269, 49.21498078062916],
              [87.75126427607668, 49.29719798440547],
              [88.0138322285517, 48.5994627956006],
              [88.85429772334678, 48.069081732773014],
              [90.28082563676392, 47.693549099307916],
              [90.97080936072499, 46.888146063822944],
              [90.58576826371834, 45.7197160914875],
              [90.94553958533433, 45.28607330991025],
              [92.13389082231825, 45.115075995456436],
              [93.48073367714133, 44.975472113620015],
              [94.68892866412537, 44.35233185482846],
              [95.30687544147153, 44.24133087826547],
              [95.7624548685567, 43.31944916439462],
              [96.34939578652782, 42.72563528092866],
              [97.451757440178, 42.74888967546008],
              [99.51581749878002, 42.524691473961695],
              [100.8458655131083, 42.663804429691425],
              [101.83304039917995, 42.51487295182628],
              [103.31227827353482, 41.90746816666763],
              [104.52228193564903, 41.90834666601663],
              [104.96499393109346, 41.59740957291635],
              [106.12931562706169, 42.1343277044289],
              [107.744772576938, 42.481515814781915],
              [109.24359581913146, 42.51944631608416],
              [110.4121033061153, 42.87123362891103],
              [111.12968224492023, 43.40683401140018],
              [111.8295878438814, 43.74311839453949],
              [111.66773725794323, 44.07317576758771],
              [111.34837690637946, 44.45744171811006],
              [111.87330610560028, 45.10207937273512],
              [112.43606245325887, 45.01164561622426],
              [113.46390669154422, 44.80889313412711],
              [114.46033165899607, 45.33981679949389],
              [115.98509647020013, 45.72723501238602],
              [116.71786828009888, 46.388202419615254],
              [117.42170128791425, 46.67273285581421],
              [118.87432579963873, 46.80541209572365],
              [119.66326989143877, 46.69267995867895],
              [119.77282392789756, 47.04805878355015],
              [118.86657433479498, 47.74706004494621],
              [118.06414269416675, 48.06673045510374],
              [117.29550744025747, 47.6977090521074],
              [116.30895267137325, 47.853410142602826],
              [115.74283735561575, 47.72654450132629],
              [115.48528201707305, 48.135382595403456],
              [116.19180219936761, 49.13459809019906],
              [116.67880089728621, 49.888531399121405],
              [117.87924441942639, 49.51098338479696],
              [119.28846072802585, 50.14288279886205],
              [119.27936567594239, 50.582907619827296],
              [120.18204959521695, 51.64356639261803],
              [120.738191359542, 51.964115302124554],
              [120.725789015792, 52.516226304730814],
              [120.1770886577169, 52.75388621684121],
              [121.00308475147024, 53.25140106873124],
              [122.24574791879289, 53.43172597921369],
              [123.57150678924087, 53.45880442973464],
              [125.06821129771045, 53.161044826868846],
              [125.94634891164618, 52.79279857035695],
              [126.56439904185699, 51.7842554795327],
              [126.93915652883769, 51.3538941514059],
              [127.28745568248493, 50.73979726826545],
              [127.6574072612624, 49.76027049417294],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Northern Cyprus",
        sov_a3: "CYN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Northern Cyprus",
        adm0_a3: "CYN",
        geou_dif: 0,
        geounit: "Northern Cyprus",
        gu_a3: "CYN",
        su_dif: 0,
        subunit: "Northern Cyprus",
        su_a3: "CYN",
        brk_diff: 1,
        name: "N. Cyprus",
        name_long: "Northern Cyprus",
        brk_a3: "B20",
        brk_name: "N. Cyprus",
        brk_group: null,
        abbrev: "N. Cy.",
        postal: "CN",
        formal_en: "Turkish Republic of Northern Cyprus",
        formal_fr: null,
        note_adm0: "Self admin.",
        note_brk: "Self admin.; Claimed by Cyprus",
        name_sort: "Cyprus, Northern",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 265100,
        gdp_md_est: 3600,
        pop_year: -99,
        lastcensus: -99,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "-99",
        iso_a3: "-99",
        iso_n3: "-99",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -99,
        adm0_a3_is: "CYP",
        adm0_a3_us: "CYP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 9,
        long_len: 15,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "CYN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.73178022637745, 35.14002594658844],
            [32.80247358575275, 35.14550364841138],
            [32.946960890440806, 35.3867033961337],
            [33.667227003724946, 35.37321584730551],
            [34.57647382990046, 35.67159556735879],
            [33.900804477684204, 35.245755927057616],
            [33.97361657078346, 35.058506374648],
            [33.86643965021011, 35.09359467217419],
            [33.675391880027064, 35.01786286065045],
            [33.5256852556775, 35.03868846286407],
            [33.475817498515845, 35.000344550103506],
            [33.45592207208347, 35.10142365166641],
            [33.3838334490363, 35.16271190036457],
            [33.19097700372305, 35.17312470147138],
            [32.919572381326134, 35.08783274997364],
            [32.73178022637745, 35.14002594658844],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Cyprus",
        sov_a3: "CYP",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Cyprus",
        adm0_a3: "CYP",
        geou_dif: 0,
        geounit: "Cyprus",
        gu_a3: "CYP",
        su_dif: 0,
        subunit: "Cyprus",
        su_a3: "CYP",
        brk_diff: 0,
        name: "Cyprus",
        name_long: "Cyprus",
        brk_a3: "CYP",
        brk_name: "Cyprus",
        brk_group: null,
        abbrev: "Cyp.",
        postal: "CY",
        formal_en: "Republic of Cyprus",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Cyprus",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 7,
        pop_est: 531640,
        gdp_md_est: 22700,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CY",
        iso_a3: "CYP",
        iso_n3: "196",
        un_a3: "196",
        wb_a2: "CY",
        wb_a3: "CYP",
        woe_id: -99,
        adm0_a3_is: "CYP",
        adm0_a3_us: "CYP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "CYP.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.97361657078346, 35.058506374648],
            [34.00488081232004, 34.97809784600186],
            [32.97982710137845, 34.57186941175544],
            [32.49029625827753, 34.701654771456475],
            [32.25666710788596, 35.10323232679663],
            [32.73178022637745, 35.14002594658844],
            [32.919572381326134, 35.08783274997364],
            [33.19097700372305, 35.17312470147138],
            [33.3838334490363, 35.16271190036457],
            [33.45592207208347, 35.10142365166641],
            [33.475817498515845, 35.000344550103506],
            [33.5256852556775, 35.03868846286407],
            [33.675391880027064, 35.01786286065045],
            [33.86643965021011, 35.09359467217419],
            [33.97361657078346, 35.058506374648],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Georgia",
        sov_a3: "GEO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Georgia",
        adm0_a3: "GEO",
        geou_dif: 0,
        geounit: "Georgia",
        gu_a3: "GEO",
        su_dif: 0,
        subunit: "Georgia",
        su_a3: "GEO",
        brk_diff: 0,
        name: "Georgia",
        name_long: "Georgia",
        brk_a3: "GEO",
        brk_name: "Georgia",
        brk_group: null,
        abbrev: "Geo.",
        postal: "GE",
        formal_en: "Georgia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Georgia",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 2,
        pop_est: 4615807,
        gdp_md_est: 21510,
        pop_year: -99,
        lastcensus: 2002,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GE",
        iso_a3: "GEO",
        iso_n3: "268",
        un_a3: "268",
        wb_a2: "GE",
        wb_a3: "GEO",
        woe_id: -99,
        adm0_a3_is: "GEO",
        adm0_a3_us: "GEO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "GEO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.55408410011065, 41.53565623632757],
            [41.70317060727271, 41.96294281673292],
            [41.45347008643839, 42.64512339941794],
            [40.87546919125379, 43.013628038091284],
            [40.32139448422032, 43.128633938156845],
            [39.955008579270924, 43.43499766699922],
            [40.07696495947977, 43.55310415300231],
            [40.922184686045625, 43.38215851498079],
            [42.39439456560882, 43.22030792904263],
            [43.75601688006739, 42.74082815202249],
            [43.931199985536836, 42.55497386328477],
            [44.537622918481986, 42.71199270280363],
            [45.47027916848572, 42.50278066666998],
            [45.77641035338277, 42.09244395605636],
            [46.404950799348825, 41.860675157227305],
            [46.14543175637902, 41.72280243587258],
            [46.63790815612058, 41.181672675128226],
            [46.50163740416693, 41.06444468847411],
            [45.96260053893039, 41.123872585609774],
            [45.217426385281584, 41.41145193131405],
            [44.97248009621808, 41.248128567055595],
            [43.582745802592726, 41.09214325618257],
            [42.61954878110449, 41.58317271581994],
            [41.55408410011065, 41.53565623632757],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Indonesia",
        sov_a3: "IDN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Indonesia",
        adm0_a3: "IDN",
        geou_dif: 0,
        geounit: "Indonesia",
        gu_a3: "IDN",
        su_dif: 0,
        subunit: "Indonesia",
        su_a3: "IDN",
        brk_diff: 0,
        name: "Indonesia",
        name_long: "Indonesia",
        brk_a3: "IDN",
        brk_name: "Indonesia",
        brk_group: null,
        abbrev: "Indo.",
        postal: "INDO",
        formal_en: "Republic of Indonesia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Indonesia",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 11,
        pop_est: 240271522,
        gdp_md_est: 914600,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "4. Emerging region: MIKT",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "ID",
        iso_a3: "IDN",
        iso_n3: "360",
        un_a3: "360",
        wb_a2: "ID",
        wb_a3: "IDN",
        woe_id: -99,
        adm0_a3_is: "IDN",
        adm0_a3_us: "IDN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 9,
        long_len: 9,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "IDN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [120.71560875863044, -10.239581394087864],
              [120.2950142762069, -10.258649997603525],
              [118.96780846565471, -9.557969252158031],
              [119.90030968636161, -9.361340427287516],
              [120.42575564990543, -9.665921319215798],
              [120.77550174365675, -9.969675388227456],
              [120.71560875863044, -10.239581394087864],
            ],
          ],
          [
            [
              [124.43595014861941, -10.140000909061442],
              [123.57998172413673, -10.359987481327963],
              [123.45998904835503, -10.239994805546175],
              [123.55000939340746, -9.90001555749798],
              [123.98000898650811, -9.290026950724695],
              [124.96868248911622, -8.892790215697048],
              [125.07001997284064, -9.089987481322837],
              [125.08852013560109, -9.393173109579322],
              [124.43595014861941, -10.140000909061442],
            ],
          ],
          [
            [
              [117.90001834520777, -8.095681247594925],
              [118.2606164897405, -8.362383314653329],
              [118.87845991422215, -8.28068287519983],
              [119.12650678922309, -8.705824883665073],
              [117.9704016459893, -8.906639499551261],
              [117.27773074754903, -9.040894870645559],
              [116.74014082241663, -9.03293670007264],
              [117.08373742072533, -8.457157891476541],
              [117.63202436734215, -8.449303073768192],
              [117.90001834520777, -8.095681247594925],
            ],
          ],
          [
            [
              [122.90353722543611, -8.094234307490737],
              [122.75698286345632, -8.64980763106064],
              [121.25449059457013, -8.933666273639943],
              [119.9243909038096, -8.810417982623875],
              [119.92092858284613, -8.444858900591072],
              [120.71509199430757, -8.236964613480865],
              [121.34166873584658, -8.536739597206022],
              [122.00736453663043, -8.460620212440162],
              [122.90353722543611, -8.094234307490737],
            ],
          ],
          [
            [
              [108.62347863162894, -6.777673841990676],
              [110.53922732955331, -6.877357679881683],
              [110.75957563684594, -6.465186455921752],
              [112.61481123255638, -6.946035658397591],
              [112.97876834518812, -7.59421314863458],
              [114.47893517462117, -7.776527601760279],
              [115.70552697150109, -8.370806573116866],
              [114.56451134649652, -8.751816908404834],
              [113.4647335144609, -8.348947442257426],
              [112.55967247930103, -8.376180922075164],
              [111.52206139531248, -8.302128594600957],
              [110.58614953007432, -8.122604668819022],
              [109.4276672709552, -7.740664157749761],
              [108.69365522668133, -7.641600437046221],
              [108.27776329959632, -7.766657403192581],
              [106.45410200401615, -7.354899590690947],
              [106.28062422081231, -6.924899997590202],
              [105.36548628135554, -6.85141611087117],
              [106.05164594932707, -5.8959188777945],
              [107.2650085795402, -5.954985039904059],
              [108.0720910990747, -6.345762220895239],
              [108.48684614464926, -6.421984958525768],
              [108.62347863162894, -6.777673841990676],
            ],
          ],
          [
            [
              [134.72462446506668, -6.214400730009287],
              [134.21013390516893, -6.895237725454706],
              [134.112775506731, -6.142467136259014],
              [134.2903357280858, -5.783057549669039],
              [134.49962527886788, -5.445042006047899],
              [134.72700158095213, -5.73758228925216],
              [134.72462446506668, -6.214400730009287],
            ],
          ],
          [
            [
              [127.24921512258892, -3.45906503663889],
              [126.87492272349888, -3.79098276124958],
              [126.18380211802733, -3.607376397316556],
              [125.98903364471929, -3.177273451351325],
              [127.00065148326499, -3.12931772218441],
              [127.24921512258892, -3.45906503663889],
            ],
          ],
          [
            [
              [130.4713440288518, -3.09376433676762],
              [130.8348360535928, -3.858472181822762],
              [129.99054650280814, -3.446300957862817],
              [129.15524865124243, -3.362636813982249],
              [128.59068362845366, -3.428679294451257],
              [127.89889122936236, -3.393435967628193],
              [128.1358793478528, -2.843650404474914],
              [129.37099775606092, -2.802154229344552],
              [130.4713440288518, -3.09376433676762],
            ],
          ],
          [
            [
              [134.1433679546478, -1.151867364103595],
              [134.42262739475305, -2.769184665542383],
              [135.4576029806947, -3.367752780779114],
              [136.2933142437188, -2.30704233155609],
              [137.44073774632753, -1.703513278819372],
              [138.3297274110448, -1.70268645590265],
              [139.18492068904297, -2.051295668143638],
              [139.92668419816042, -2.409051608900284],
              [141.00021040259188, -2.600151055515624],
              [141.01705691951904, -5.859021905138022],
              [141.0338517600139, -9.117892754760419],
              [140.14341515519257, -8.297167657100957],
              [139.12776655492812, -8.096042982620942],
              [138.88147667862498, -8.380935153846096],
              [137.61447391169284, -8.411682631059762],
              [138.0390991558352, -7.597882175327356],
              [138.6686214540148, -7.320224704623072],
              [138.40791385310237, -6.232849216337484],
              [137.92783979711086, -5.393365573756],
              [135.98925011611348, -4.546543877789048],
              [135.16459760959972, -4.462931410340772],
              [133.6628804871979, -3.538853448097527],
              [133.3677047059468, -4.024818617370315],
              [132.98395551974735, -4.112978610860281],
              [132.756940952689, -3.74628264731713],
              [132.75378869031923, -3.311787204607072],
              [131.9898043153162, -2.820551039240456],
              [133.0668445171435, -2.460417982598443],
              [133.78003095920351, -2.47984832114021],
              [133.69621178602614, -2.214541517753688],
              [132.23237348849423, -2.212526136894326],
              [131.8362219585447, -1.617161960459597],
              [130.94283979708283, -1.432522067880797],
              [130.51955814018007, -0.937720228686075],
              [131.86753787651364, -0.695461114101818],
              [132.3801164084168, -0.369537855636977],
              [133.98554813042844, -0.780210463060442],
              [134.1433679546478, -1.151867364103595],
            ],
          ],
          [
            [
              [125.24050052297159, 1.419836127117605],
              [124.43703535369737, 0.427881171058971],
              [123.68550499887672, 0.235593166500877],
              [122.72308312387288, 0.431136786293337],
              [121.0567248881891, 0.381217352699451],
              [120.18308312386276, 0.23724681233422],
              [120.04086958219548, -0.519657891444851],
              [120.93590538949073, -1.408905938323372],
              [121.4758207540762, -0.955962009285116],
              [123.34056481332848, -0.615672702643081],
              [123.2583992859845, -1.076213067228338],
              [122.82271528533161, -0.930950616055881],
              [122.38852990121539, -1.516858005381124],
              [121.50827355355548, -1.904482924002423],
              [122.4545723816843, -3.186058444840882],
              [122.27189619353257, -3.529500013852697],
              [123.17096276254657, -4.683693129091708],
              [123.16233279835379, -5.340603936385961],
              [122.62851525277871, -5.634591159694494],
              [122.23639448454806, -5.282933037948283],
              [122.71956912647707, -4.46417164471579],
              [121.73823367725439, -4.8513314754465],
              [121.48946333220127, -4.574552504091216],
              [121.61917117725388, -4.188477878438674],
              [120.89818159391771, -3.602105401222829],
              [120.97238895068877, -2.62764291749491],
              [120.30545291552991, -2.931603692235726],
              [120.39004723519176, -4.097579034037224],
              [120.43071658740539, -5.528241062037779],
              [119.79654341031952, -5.673400160345651],
              [119.36690555224496, -5.379878024927805],
              [119.65360639860013, -4.459417412944958],
              [119.49883548388597, -3.49441171632651],
              [119.078344354327, -3.487021986508765],
              [118.7677689962529, -2.801999200047689],
              [119.18097374885869, -2.147103773612798],
              [119.32339399625508, -1.353147067880471],
              [119.82599897672586, 0.154254462073496],
              [120.03570193896637, 0.566477362465804],
              [120.8857792501677, 1.309222723796836],
              [121.666816847827, 1.013943589681077],
              [122.92756676645185, 0.875192368977466],
              [124.07752241424285, 0.917101955566139],
              [125.06598921112183, 1.643259182131558],
              [125.24050052297159, 1.419836127117605],
            ],
          ],
          [
            [
              [128.68824873262074, 1.132385972494106],
              [128.63595218314137, 0.258485826006179],
              [128.12016971243617, 0.356412665199286],
              [127.96803429576887, -0.252077325037533],
              [128.37999881399972, -0.780003757331286],
              [128.10001590384232, -0.899996433112975],
              [127.69647464407504, -0.266598402511505],
              [127.39949018769377, 1.011721503092573],
              [127.60051150930907, 1.810690822757181],
              [127.93237755748751, 2.174596258956555],
              [128.00415612194084, 1.628531398928331],
              [128.59455936087548, 1.540810655112864],
              [128.68824873262074, 1.132385972494106],
            ],
          ],
          [
            [
              [117.87562706916603, 1.827640692548911],
              [118.99674726773819, 0.902219143066048],
              [117.81185835171779, 0.784241848143722],
              [117.47833865770608, 0.102474676917026],
              [117.52164350796662, -0.803723239753211],
              [116.56004845587952, -1.487660821136231],
              [116.53379682827519, -2.483517347832901],
              [116.14808393764864, -4.012726332214015],
              [116.0008577820491, -3.657037448749008],
              [114.86480309454454, -4.106984144714417],
              [114.46865156459509, -3.495703627133821],
              [113.75567182826413, -3.43916961020652],
              [113.25699425664757, -3.118775729996855],
              [112.06812625534067, -3.478392022316072],
              [111.70329064336002, -2.994442233902632],
              [111.04824018762824, -3.049425957861189],
              [110.223846063276, -2.934032484553484],
              [110.07093550012436, -1.592874037282414],
              [109.57194786991406, -1.314906507984489],
              [109.09187381392253, -0.459506524257051],
              [108.95265750532816, 0.415375474444346],
              [109.06913618371404, 1.341933905437642],
              [109.66326012577375, 2.006466986494985],
              [109.83022667850886, 1.338135687664192],
              [110.51406090702713, 0.773131415200993],
              [111.15913781132659, 0.976478176269509],
              [111.79754845586044, 0.904441229654651],
              [112.38025190638368, 1.410120957846758],
              [112.8598091980522, 1.497790025229946],
              [113.80584964401956, 1.217548732911041],
              [114.6213554220175, 1.430688177898887],
              [115.13403730678523, 2.821481838386219],
              [115.51907840379201, 3.169238389494396],
              [115.86551720587677, 4.306559149590157],
              [117.01521447150637, 4.306094061699469],
              [117.88203494677019, 4.137551377779488],
              [117.31323245653354, 3.234428208830579],
              [118.04832970588538, 2.287690131027361],
              [117.87562706916603, 1.827640692548911],
            ],
          ],
          [
            [
              [105.81765506390936, -5.852355645372413],
              [104.71038414919151, -5.873284600450646],
              [103.86821333213074, -5.037314955264975],
              [102.58426069540693, -4.220258884298204],
              [102.15617313030103, -3.614146009946765],
              [101.39911339722508, -2.799777113459172],
              [100.90250288290017, -2.05026213949786],
              [100.14198082886062, -0.650347588710957],
              [99.26373986206025, 0.183141587724663],
              [98.97001102091333, 1.042882391764536],
              [98.60135135294311, 1.823506577965617],
              [97.6995976094499, 2.453183905442117],
              [97.1769421732499, 3.30879059489861],
              [96.42401655475734, 3.868859768077911],
              [95.38087609251347, 4.970782172053673],
              [95.29302615761733, 5.479820868344817],
              [95.93686282754176, 5.439513251157109],
              [97.4848820332771, 5.246320909034011],
              [98.36916914265569, 4.268370266126368],
              [99.14255862833582, 3.590349636240916],
              [99.69399783732243, 3.174328518075157],
              [100.64143354696168, 2.099381211755798],
              [101.65801232300734, 2.083697414555189],
              [102.49827111207324, 1.398700466310217],
              [103.07684044801303, 0.561361395668854],
              [103.83839603069835, 0.104541734208667],
              [103.43764529827497, -0.711945896002845],
              [104.01078860882402, -1.059211521004229],
              [104.3699914896849, -1.084843031421016],
              [104.53949018760218, -1.782371514496716],
              [104.88789269411402, -2.340425306816655],
              [105.622111444117, -2.42884368246807],
              [106.10859337771271, -3.06177662517895],
              [105.85744591677414, -4.305524997579724],
              [105.81765506390936, -5.852355645372413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "India",
        sov_a3: "IND",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "India",
        adm0_a3: "IND",
        geou_dif: 0,
        geounit: "India",
        gu_a3: "IND",
        su_dif: 0,
        subunit: "India",
        su_a3: "IND",
        brk_diff: 0,
        name: "India",
        name_long: "India",
        brk_a3: "IND",
        brk_name: "India",
        brk_group: null,
        abbrev: "India",
        postal: "IND",
        formal_en: "Republic of India",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "India",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 1166079220,
        gdp_md_est: 3297000,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "3. Emerging region: BRIC",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "IN",
        iso_a3: "IND",
        iso_n3: "356",
        un_a3: "356",
        wb_a2: "IN",
        wb_a3: "IND",
        woe_id: -99,
        adm0_a3_is: "IND",
        adm0_a3_us: "IND",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "IND.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.83745079947457, 35.494009507787766],
            [78.91226891471322, 34.32193634697579],
            [78.81108646028574, 33.50619802503242],
            [79.20889163606857, 32.994394639613716],
            [79.17612877799553, 32.48377981213771],
            [78.45844648632601, 32.61816437431273],
            [78.73889448437401, 31.515906073527063],
            [79.7213668151071, 30.882714748654728],
            [81.11125613802932, 30.183480943313402],
            [80.4767212259174, 29.72986522065534],
            [80.08842451367627, 28.79447011974014],
            [81.05720258985203, 28.416095282499043],
            [81.99998742058497, 27.925479234319994],
            [83.30424889519955, 27.36450572357556],
            [84.6750179381738, 27.234901231387536],
            [85.25177859898338, 26.72619843190634],
            [86.02439293817918, 26.63098460540857],
            [87.22747195836628, 26.397898057556077],
            [88.06023766474982, 26.41461538340249],
            [88.17480431514092, 26.81040517832595],
            [88.04313276566123, 27.445818589786825],
            [88.12044070836987, 27.876541652939594],
            [88.73032596227856, 28.086864732367516],
            [88.81424848832054, 27.29931590423936],
            [88.83564253128938, 27.098966376243762],
            [89.74452762243884, 26.719402981059957],
            [90.37327477413407, 26.87572418874288],
            [91.21751264848643, 26.808648179628022],
            [92.03348351437509, 26.83831045176356],
            [92.10371178585973, 27.452614040633208],
            [91.69665652869668, 27.77174184825166],
            [92.50311893104364, 27.89687632904645],
            [93.41334760943268, 28.640629380807226],
            [94.56599043170294, 29.277438055939985],
            [95.40480228066464, 29.03171662039213],
            [96.11767866413103, 29.452802028922466],
            [96.58659061074749, 28.830979519154344],
            [96.24883344928779, 28.41103099213444],
            [97.32711388549004, 28.26158274994634],
            [97.40256147663612, 27.88253611908544],
            [97.0519885599681, 27.69905894623315],
            [97.1339990580153, 27.083773505149964],
            [96.41936567585097, 27.264589341739224],
            [95.12476769407496, 26.5735720891323],
            [95.1551534362626, 26.001307277932085],
            [94.60324913938538, 25.162495428970402],
            [94.55265791217164, 24.675238348890332],
            [94.10674197792505, 23.85074087167348],
            [93.3251876159428, 24.078556423432204],
            [93.28632693885928, 23.043658352139005],
            [93.06029422401463, 22.70311066333557],
            [93.16612755734836, 22.278459580977103],
            [92.67272098182556, 22.041238918541254],
            [92.14603478390681, 23.627498684172593],
            [91.86992760617132, 23.624346421802784],
            [91.70647505083211, 22.985263983649183],
            [91.15896325069971, 23.50352692310439],
            [91.46772993364367, 24.072639471934792],
            [91.91509280799443, 24.13041372323711],
            [92.37620161333481, 24.976692816664965],
            [91.79959598182207, 25.147431748957317],
            [90.8722107279121, 25.132600612889547],
            [89.92069258012185, 25.26974986419218],
            [89.83248091019962, 25.96508209889548],
            [89.35509402868729, 26.014407253518073],
            [88.56304935094977, 26.44652558034272],
            [88.2097892598025, 25.76806570078271],
            [88.93155398962308, 25.238692328384776],
            [88.30637251175602, 24.866079413344206],
            [88.08442223506242, 24.501657212821925],
            [88.69994022009092, 24.23371491138856],
            [88.52976972855377, 23.631141872649163],
            [88.87631188350309, 22.879146429937826],
            [89.03196129756623, 22.055708319582976],
            [88.88876590368542, 21.690588487224748],
            [88.20849734899521, 21.703171698487807],
            [86.97570438024027, 21.49556163175521],
            [87.03316857294887, 20.743307806882413],
            [86.49935102737378, 20.151638495356607],
            [85.0602657409097, 19.4785788029711],
            [83.94100589390001, 18.302009792549725],
            [83.18921715691785, 17.67122142177898],
            [82.19279218946592, 17.016636053937813],
            [82.19124189649719, 16.556664130107848],
            [81.69271935417748, 16.310219224507904],
            [80.79199913933014, 15.951972357644491],
            [80.32489586784388, 15.899184882058348],
            [80.02506920768644, 15.136414903214147],
            [80.2332735533904, 13.835770778859981],
            [80.28629357292186, 13.006260687710833],
            [79.8625468281285, 12.056215318240888],
            [79.85799930208682, 10.35727509199711],
            [79.340511509116, 10.30885427493962],
            [78.88534549348918, 9.546135972527722],
            [79.18971967968828, 9.216543687370148],
            [78.2779407083305, 8.933046779816934],
            [77.94116539908435, 8.252959092639742],
            [77.53989790233794, 7.965534776232333],
            [76.59297895702167, 8.89927623131419],
            [76.13006147655108, 10.299630031775521],
            [75.74646731964849, 11.308250637248307],
            [75.39610110870957, 11.781245022015824],
            [74.86481570831681, 12.741935736537897],
            [74.61671715688354, 13.99258291264968],
            [74.44385949086723, 14.617221787977696],
            [73.5341992532334, 15.99065216721496],
            [73.11990929554943, 17.928570054592498],
            [72.82090945830865, 19.208233547436166],
            [72.8244751321368, 20.419503282141534],
            [72.6305334817454, 21.356009426351008],
            [71.17527347197395, 20.757441311114235],
            [70.4704586119451, 20.877330634031384],
            [69.16413008003883, 22.0892980005727],
            [69.64492760608239, 22.450774644454338],
            [69.34959679553435, 22.84317963306269],
            [68.1766451353734, 23.69196503345671],
            [68.84259931831878, 24.35913361256094],
            [71.04324018746823, 24.3565239527302],
            [70.84469933460284, 25.21510203704352],
            [70.28287316272558, 25.72222870533983],
            [70.16892662952202, 26.491871649678842],
            [69.51439293811312, 26.940965684511372],
            [70.61649620960193, 27.989196275335868],
            [71.77766564320032, 27.913180243434525],
            [72.8237516620847, 28.961591701772054],
            [73.45063846221743, 29.97641347911987],
            [74.42138024282026, 30.979814764931177],
            [74.40592898956501, 31.69263947196528],
            [75.25864179881322, 32.2711054550405],
            [74.45155927927871, 32.7648996038055],
            [74.10429365427734, 33.44147329358685],
            [73.74994835805195, 34.31769887952785],
            [74.24020267120497, 34.74888703057125],
            [75.75706098826834, 34.50492259372132],
            [76.87172163280403, 34.65354401299274],
            [77.83745079947457, 35.494009507787766],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Iran",
        sov_a3: "IRN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Iran",
        adm0_a3: "IRN",
        geou_dif: 0,
        geounit: "Iran",
        gu_a3: "IRN",
        su_dif: 0,
        subunit: "Iran",
        su_a3: "IRN",
        brk_diff: 0,
        name: "Iran",
        name_long: "Iran",
        brk_a3: "IRN",
        brk_name: "Iran",
        brk_group: null,
        abbrev: "Iran",
        postal: "IRN",
        formal_en: "Islamic Republic of Iran",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Iran, Islamic Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 13,
        pop_est: 66429284,
        gdp_md_est: 841700,
        pop_year: -99,
        lastcensus: 2006,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "IR",
        iso_a3: "IRN",
        iso_n3: "364",
        un_a3: "364",
        wb_a2: "IR",
        wb_a3: "IRN",
        woe_id: -99,
        adm0_a3_is: "IRN",
        adm0_a3_us: "IRN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "Middle East & North Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "IRN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [53.92159793479556, 37.19891836196126],
            [54.800303989486565, 37.392420762678185],
            [55.51157840355191, 37.96411713312317],
            [56.18037479027333, 37.93512665460742],
            [56.61936608259282, 38.121394354803485],
            [57.33043379092898, 38.02922943781094],
            [58.4361544126782, 37.522309475243794],
            [59.23476199731681, 37.412987982730336],
            [60.37763797388387, 36.52738312432837],
            [61.123070509694145, 36.49159719496624],
            [61.21081709172574, 35.650072333309225],
            [60.80319339380745, 34.40410187431986],
            [60.52842980331158, 33.676446031218006],
            [60.963700392506006, 33.52883230237625],
            [60.536077915290775, 32.98126882581157],
            [60.863654819588966, 32.18291962333443],
            [60.94194461451113, 31.548074652628753],
            [61.699314406180825, 31.37950613049267],
            [61.781221551363444, 30.735850328081234],
            [60.874248488208785, 29.829238999952604],
            [61.36930870956494, 29.303276272085924],
            [61.77186811711863, 28.699333807890795],
            [62.72783043808598, 28.25964488373539],
            [62.755425652929866, 27.378923448184985],
            [63.233897739520295, 27.21704702403071],
            [63.31663170761959, 26.756532497661667],
            [61.87418745305655, 26.239974880472104],
            [61.49736290878419, 25.0782370061185],
            [59.61613406763084, 25.380156561783778],
            [58.5257613462723, 25.60996165618573],
            [57.397251417882394, 25.73990204518364],
            [56.970765822177555, 26.96610626882136],
            [56.492138706290206, 27.143304755150197],
            [55.72371015811006, 26.96463349050104],
            [54.71508955263727, 26.480657863871514],
            [53.49309695823135, 26.81236888275305],
            [52.48359785340961, 27.580849107365495],
            [51.52076256694741, 27.865689602158298],
            [50.85294803243954, 28.814520575469384],
            [50.115008579311585, 30.147772528599717],
            [49.576850213423995, 29.985715236932407],
            [48.94133344909855, 30.317090359004037],
            [48.567971225789755, 29.926778265903522],
            [48.0145683123761, 30.452456773392598],
            [48.004698113808324, 30.985137437457244],
            [47.68528608581227, 30.98485321707963],
            [47.8492037290421, 31.70917593029867],
            [47.3346614927119, 32.469155381799105],
            [46.10936160663932, 33.017287299119005],
            [45.41669070819904, 33.967797756479584],
            [45.64845950702809, 34.748137722303014],
            [46.15178795755093, 35.09325877536429],
            [46.0763403664048, 35.67738332777549],
            [45.4206181170532, 35.97754588474282],
            [44.77267, 37.17045],
            [44.22575564960053, 37.97158437758935],
            [44.421402622257546, 38.28128123631454],
            [44.10922529478234, 39.4281362981681],
            [44.79398969908195, 39.71300263117705],
            [44.95268802265031, 39.33576467544637],
            [45.45772179543877, 38.87413910578306],
            [46.14362308124881, 38.74120148371222],
            [46.50571984231797, 38.770605373686294],
            [47.685079380083096, 39.508363959301214],
            [48.06009524922524, 39.58223541926246],
            [48.35552941263788, 39.28876496027691],
            [48.01074425638648, 38.79401479751452],
            [48.63437544128481, 38.27037750910097],
            [48.88324913920249, 38.32024526626262],
            [49.19961225769334, 37.58287425388988],
            [50.14777143738462, 37.37456655532134],
            [50.84235436381971, 36.8728142359834],
            [52.264024692601424, 36.7004216578577],
            [53.82578982932642, 36.965030829408235],
            [53.92159793479556, 37.19891836196126],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Iraq",
        sov_a3: "IRQ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Iraq",
        adm0_a3: "IRQ",
        geou_dif: 0,
        geounit: "Iraq",
        gu_a3: "IRQ",
        su_dif: 0,
        subunit: "Iraq",
        su_a3: "IRQ",
        brk_diff: 0,
        name: "Iraq",
        name_long: "Iraq",
        brk_a3: "IRQ",
        brk_name: "Iraq",
        brk_group: null,
        abbrev: "Iraq",
        postal: "IRQ",
        formal_en: "Republic of Iraq",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Iraq",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 3,
        mapcolor13: 1,
        pop_est: 31129225,
        gdp_md_est: 103900,
        pop_year: -99,
        lastcensus: 1997,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "IQ",
        iso_a3: "IRQ",
        iso_n3: "368",
        un_a3: "368",
        wb_a2: "IQ",
        wb_a3: "IRQ",
        woe_id: -99,
        adm0_a3_is: "IRQ",
        adm0_a3_us: "IRQ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "IRQ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [45.4206181170532, 35.97754588474282],
            [46.0763403664048, 35.67738332777549],
            [46.15178795755093, 35.09325877536429],
            [45.64845950702809, 34.748137722303014],
            [45.41669070819904, 33.967797756479584],
            [46.10936160663932, 33.017287299119005],
            [47.3346614927119, 32.469155381799105],
            [47.8492037290421, 31.70917593029867],
            [47.68528608581227, 30.98485321707963],
            [48.004698113808324, 30.985137437457244],
            [48.0145683123761, 30.452456773392598],
            [48.567971225789755, 29.926778265903522],
            [47.974519077349896, 29.9758192001485],
            [47.30262210469096, 30.05906993257072],
            [46.568713413281756, 29.09902517345229],
            [44.70949873228474, 29.178891099559383],
            [41.889980910007836, 31.190008653278365],
            [40.399994337736246, 31.889991766887935],
            [39.19546837744497, 32.16100881604267],
            [38.792340529136084, 33.378686428352225],
            [41.006158888519934, 34.41937226006212],
            [41.383965285005814, 35.628316555314356],
            [41.289707472505455, 36.35881460219227],
            [41.83706424334096, 36.605853786763575],
            [42.34959109881177, 37.2298725449041],
            [42.77912560402182, 37.385263576805755],
            [43.9422587420473, 37.25622752537295],
            [44.29345177590286, 37.0015143906063],
            [44.772699008977696, 37.170444647768434],
            [45.4206181170532, 35.97754588474282],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Israel",
        sov_a3: "ISR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Israel",
        adm0_a3: "ISR",
        geou_dif: 0,
        geounit: "Israel",
        gu_a3: "ISR",
        su_dif: 0,
        subunit: "Israel",
        su_a3: "ISR",
        brk_diff: 0,
        name: "Israel",
        name_long: "Israel",
        brk_a3: "ISR",
        brk_name: "Israel",
        brk_group: null,
        abbrev: "Isr.",
        postal: "IS",
        formal_en: "State of Israel",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Israel",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 9,
        pop_est: 7233701,
        gdp_md_est: 201400,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "IL",
        iso_a3: "ISR",
        iso_n3: "376",
        un_a3: "376",
        wb_a2: "IL",
        wb_a3: "ISR",
        woe_id: -99,
        adm0_a3_is: "ISR",
        adm0_a3_us: "ISR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "ISR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.71991824722275, 32.709192409794866],
            [35.54566531753454, 32.393992011030576],
            [35.183930291491436, 32.53251068778894],
            [34.97464074070933, 31.86658234305972],
            [35.22589155451242, 31.754341132121766],
            [34.970506626125996, 31.616778469360806],
            [34.92740848159457, 31.353435370401414],
            [35.397560662586045, 31.48908600516758],
            [35.420918409981965, 31.100065822874356],
            [34.92260257339142, 29.501326198844524],
            [34.26543338393568, 31.219360866820153],
            [34.55637169773891, 31.548823960896996],
            [34.48810713068136, 31.60553884533732],
            [34.752587111151165, 32.07292633720117],
            [34.95541710789677, 32.82737641044638],
            [35.098457472480675, 33.080539252244265],
            [35.126052687324545, 33.09090037691878],
            [35.460709262846706, 33.08904002535628],
            [35.55279666519081, 33.26427480725802],
            [35.82110070165024, 33.2774264592763],
            [35.836396925608625, 32.86812327730851],
            [35.700797967274745, 32.71601369885738],
            [35.71991824722275, 32.709192409794866],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Jordan",
        sov_a3: "JOR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Jordan",
        adm0_a3: "JOR",
        geou_dif: 0,
        geounit: "Jordan",
        gu_a3: "JOR",
        su_dif: 0,
        subunit: "Jordan",
        su_a3: "JOR",
        brk_diff: 0,
        name: "Jordan",
        name_long: "Jordan",
        brk_a3: "JOR",
        brk_name: "Jordan",
        brk_group: null,
        abbrev: "Jord.",
        postal: "J",
        formal_en: "Hashemite Kingdom of Jordan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Jordan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 4,
        pop_est: 6342948,
        gdp_md_est: 31610,
        pop_year: -99,
        lastcensus: 2004,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "JO",
        iso_a3: "JOR",
        iso_n3: "400",
        un_a3: "400",
        wb_a2: "JO",
        wb_a3: "JOR",
        woe_id: -99,
        adm0_a3_is: "JOR",
        adm0_a3_us: "JOR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "JOR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.54566531753454, 32.393992011030576],
            [35.71991824722275, 32.709192409794866],
            [36.834062127435544, 32.312937526980775],
            [38.792340529136084, 33.378686428352225],
            [39.19546837744497, 32.16100881604267],
            [39.00488569515255, 32.01021698661498],
            [37.00216556168101, 31.508412990844743],
            [37.998848911294374, 30.50849986421313],
            [37.66811974462638, 30.3386652694859],
            [37.503581984209035, 30.003776150018403],
            [36.74052778498725, 29.86528331147619],
            [36.50121422704358, 29.5052536076987],
            [36.06894087092206, 29.197494615184457],
            [34.95603722508426, 29.35655467377884],
            [34.92260257339142, 29.501326198844524],
            [35.420918409981965, 31.100065822874356],
            [35.397560662586045, 31.48908600516758],
            [35.5452519060762, 31.78250478772084],
            [35.54566531753454, 32.393992011030576],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Japan",
        sov_a3: "JPN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Japan",
        adm0_a3: "JPN",
        geou_dif: 0,
        geounit: "Japan",
        gu_a3: "JPN",
        su_dif: 0,
        subunit: "Japan",
        su_a3: "JPN",
        brk_diff: 0,
        name: "Japan",
        name_long: "Japan",
        brk_a3: "JPN",
        brk_name: "Japan",
        brk_group: null,
        abbrev: "Japan",
        postal: "J",
        formal_en: "Japan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Japan",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 5,
        mapcolor13: 4,
        pop_est: 127078679,
        gdp_md_est: 4329000,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "JP",
        iso_a3: "JPN",
        iso_n3: "392",
        un_a3: "392",
        wb_a2: "JP",
        wb_a3: "JPN",
        woe_id: -99,
        adm0_a3_is: "JPN",
        adm0_a3_us: "JPN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "JPN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [134.63842817600388, 34.14923371025642],
              [134.7663790223585, 33.80633474378368],
              [134.20341596897086, 33.20117788342964],
              [133.7929500672765, 33.5219851750976],
              [133.28026818250888, 33.28957042086495],
              [133.01485802625788, 32.70456736910478],
              [132.3631148621927, 32.98938202568137],
              [132.37117638563018, 33.46364248304007],
              [132.9243725933148, 34.06029857028204],
              [133.49296837782222, 33.9446208765967],
              [133.90410607313638, 34.36493113864262],
              [134.63842817600388, 34.14923371025642],
            ],
          ],
          [
            [
              [140.9763875673053, 37.14207428644016],
              [140.59976972876214, 36.343983466124534],
              [140.77407433488264, 35.84287710219024],
              [140.25327925024513, 35.13811391859365],
              [138.97552778539622, 34.66760000257611],
              [137.21759891169123, 34.60628591566186],
              [135.7929830262689, 33.46480520276663],
              [135.12098270074543, 33.84907115328906],
              [135.07943484918272, 34.59654490817482],
              [133.340316196832, 34.37593821872076],
              [132.15677086805132, 33.90493337659652],
              [130.98614464734348, 33.88576142021628],
              [132.00003624891005, 33.149992377244615],
              [131.33279015515737, 31.450354519164843],
              [130.68631798718596, 31.029579169228242],
              [130.20241987520498, 31.418237616495418],
              [130.44767622286216, 32.319474595665724],
              [129.8146916037189, 32.61030955660439],
              [129.40846316947258, 33.29605581311759],
              [130.35393517468466, 33.6041507024417],
              [130.87845096244715, 34.232742824840045],
              [131.88422936414392, 34.74971385348791],
              [132.61767296766251, 35.43339305270942],
              [134.6083008159778, 35.73161774346582],
              [135.67753787652893, 35.527134100886826],
              [136.72383060114245, 37.30498423924038],
              [137.3906116070045, 36.827390651998826],
              [138.85760216690628, 37.82748464614346],
              [139.4264046571429, 38.21596222589764],
              [140.0547900738121, 39.438807481436385],
              [139.88337934789988, 40.563312486323696],
              [140.30578250545372, 41.19500519465956],
              [141.3689734234267, 41.37855988216029],
              [141.91426313697048, 39.99161611587868],
              [141.884600864835, 39.180864569651504],
              [140.9594893739458, 38.17400096287658],
              [140.9763875673053, 37.14207428644016],
            ],
          ],
          [
            [
              [143.9101619813795, 44.17409983985373],
              [144.61342654843963, 43.960882880217525],
              [145.3208252300831, 44.38473297787544],
              [145.54313724180278, 43.262088324550604],
              [144.0596618999999, 42.98835826270056],
              [143.18384972551732, 41.9952147486992],
              [141.61149092017249, 42.67879059505608],
              [141.06728641170665, 41.58459381770799],
              [139.95510623592108, 41.569555975911044],
              [139.81754357315995, 42.5637588567744],
              [140.31208703019323, 43.33327261003265],
              [141.38054894426003, 43.388824774746496],
              [141.67195234595394, 44.77212535255148],
              [141.967644891528, 45.55148346616135],
              [143.14287031470982, 44.510358384776964],
              [143.9101619813795, 44.17409983985373],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Kazakhstan",
        sov_a3: "KAZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Kazakhstan",
        adm0_a3: "KAZ",
        geou_dif: 0,
        geounit: "Kazakhstan",
        gu_a3: "KAZ",
        su_dif: 0,
        subunit: "Kazakhstan",
        su_a3: "KAZ",
        brk_diff: 0,
        name: "Kazakhstan",
        name_long: "Kazakhstan",
        brk_a3: "KAZ",
        brk_name: "Kazakhstan",
        brk_group: null,
        abbrev: "Kaz.",
        postal: "KZ",
        formal_en: "Republic of Kazakhstan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Kazakhstan",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 1,
        pop_est: 15399437,
        gdp_md_est: 175800,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "KZ",
        iso_a3: "KAZ",
        iso_n3: "398",
        un_a3: "398",
        wb_a2: "KZ",
        wb_a3: "KAZ",
        woe_id: -99,
        adm0_a3_is: "KAZ",
        adm0_a3_us: "KAZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "KAZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.96231489449929, 42.26615428320554],
            [70.3889648782208, 42.081307684897524],
            [69.07002729683524, 41.38424428971234],
            [68.63248294462005, 40.66868073176687],
            [68.25989586779565, 40.6623245305949],
            [67.98585574735182, 41.135990708982206],
            [66.7140470722166, 41.168443508461564],
            [66.51064863471572, 41.987644151368556],
            [66.02339155463562, 41.99464630794404],
            [66.0980123228652, 42.99766002051308],
            [64.90082441595933, 43.728080552742654],
            [63.185786981056594, 43.650074978198006],
            [62.01330040878628, 43.50447663021566],
            [61.0583199400325, 44.40581696225058],
            [60.23997195825847, 44.784036770194746],
            [58.6899890480958, 45.50001373959873],
            [58.50312706892844, 45.586804307632974],
            [55.92891727074118, 44.99585846615918],
            [55.968191359283026, 41.30864166926938],
            [55.45525109235381, 41.25985911718584],
            [54.75534549339267, 42.04397146256662],
            [54.07941775901497, 42.32410940202084],
            [52.94429324729174, 42.11603424739758],
            [52.50245975119628, 41.78331553808647],
            [52.44633914572722, 42.027150783855575],
            [52.692112257707265, 42.44389537207337],
            [52.50142622255032, 42.7922978785852],
            [51.342427199108215, 43.132974758469345],
            [50.89129194520024, 44.03103363705378],
            [50.339129266161365, 44.284015611338475],
            [50.305642938036264, 44.609835516938915],
            [51.278503452363225, 44.51485423438646],
            [51.316899041556034, 45.2459982366679],
            [52.16738976421573, 45.40839142514511],
            [53.0408764992452, 45.25904653582177],
            [53.220865512917726, 46.234645901059935],
            [53.042736850807785, 46.853006089864486],
            [52.04202273947561, 46.80463694923924],
            [51.191945428274266, 47.048704738953916],
            [50.03408328634248, 46.60898997658222],
            [49.10116000000011, 46.399330000000134],
            [48.593241001180495, 46.56103424741547],
            [48.694733514201744, 47.07562816017793],
            [48.05725304544927, 47.74375275327952],
            [47.31523115417024, 47.715847479841955],
            [46.46644575377627, 48.39415233010493],
            [47.043671502476506, 49.152038886097614],
            [46.75159630716274, 49.35600576435377],
            [47.5494804217493, 50.454698391311126],
            [48.57784142435752, 49.87475962991567],
            [48.70238162618102, 50.60512848571284],
            [50.76664839051215, 51.6927623561599],
            [52.32872358583097, 51.718652248738124],
            [54.532878452376224, 51.02623973245932],
            [55.716940545479815, 50.62171662047853],
            [56.777961053296565, 51.04355133727705],
            [58.36329064314674, 51.06365346943858],
            [59.6422823423706, 50.545442206415714],
            [59.93280724471549, 50.842194118851864],
            [61.337424350840934, 50.79907013610426],
            [61.58800337102417, 51.272658799843214],
            [59.96753380721554, 51.9604204372157],
            [60.92726850774027, 52.44754832621504],
            [60.73999311711459, 52.71998647725775],
            [61.6999861998006, 52.97999644633427],
            [60.97806644068316, 53.66499339457914],
            [61.436591424409066, 54.00626455343479],
            [65.17853356309593, 54.35422781027211],
            [65.666875848254, 54.60126699484345],
            [68.16910037625883, 54.97039175070432],
            [69.06816694527288, 55.38525014914353],
            [70.86526655465514, 55.169733588270105],
            [71.18013105660941, 54.133285224008254],
            [72.22415001820218, 54.376655381886735],
            [73.5085160663844, 54.035616766976595],
            [73.42567874542043, 53.489810289109755],
            [74.38484500519007, 53.54686107036008],
            [76.89110029491343, 54.49052440044193],
            [76.52517947785473, 54.177003485727134],
            [77.80091556184425, 53.404414984747575],
            [80.03555952344169, 50.86475088154725],
            [80.56844689323549, 51.38833649352847],
            [81.94598554883993, 50.81219594990637],
            [83.38300377801238, 51.069182847693924],
            [83.93511478061885, 50.88924551045358],
            [84.41637739455308, 50.311399644565824],
            [85.11555952346203, 50.11730296487763],
            [85.54126997268247, 49.69285858824816],
            [86.82935672398963, 49.82667470966817],
            [87.35997033076268, 49.21498078062916],
            [86.59877648310339, 48.54918162698061],
            [85.7682328633083, 48.45575063739698],
            [85.72048383987072, 47.45296946877312],
            [85.16429039911338, 47.00095571551611],
            [83.18048383986047, 47.330031236350855],
            [82.45892581576913, 45.539649563166506],
            [81.94707075391813, 45.31702749285324],
            [79.96610639844141, 44.91751699480466],
            [80.86620649610137, 43.18036204688104],
            [80.1801501809943, 42.92006785742694],
            [80.25999026888536, 42.349999294599115],
            [79.64364546094015, 42.496682847659656],
            [79.1421773619798, 42.856092434249604],
            [77.6583919615832, 42.960685533208334],
            [76.00035363149857, 42.98802236589063],
            [75.6369649596221, 42.87789988867678],
            [74.21286583852259, 43.29833934180351],
            [73.64530358266092, 43.09127187760987],
            [73.48975752146237, 42.50089447689129],
            [71.84463829945065, 42.845395412765185],
            [71.18628055205227, 42.70429291439223],
            [70.96231489449929, 42.26615428320554],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Kyrgyzstan",
        sov_a3: "KGZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Kyrgyzstan",
        adm0_a3: "KGZ",
        geou_dif: 0,
        geounit: "Kyrgyzstan",
        gu_a3: "KGZ",
        su_dif: 0,
        subunit: "Kyrgyzstan",
        su_a3: "KGZ",
        brk_diff: 0,
        name: "Kyrgyzstan",
        name_long: "Kyrgyzstan",
        brk_a3: "KGZ",
        brk_name: "Kyrgyzstan",
        brk_group: null,
        abbrev: "Kgz.",
        postal: "KG",
        formal_en: "Kyrgyz Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Kyrgyz Republic",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 7,
        mapcolor9: 7,
        mapcolor13: 6,
        pop_est: 5431747,
        gdp_md_est: 11610,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "KG",
        iso_a3: "KGZ",
        iso_n3: "417",
        un_a3: "417",
        wb_a2: "KG",
        wb_a3: "KGZ",
        woe_id: -99,
        adm0_a3_is: "KGZ",
        adm0_a3_us: "KGZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "KGZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.96231489449914, 42.26615428320549],
            [71.18628055205212, 42.70429291439214],
            [71.8446382994506, 42.84539541276509],
            [73.48975752146237, 42.50089447689132],
            [73.64530358266092, 43.09127187760982],
            [74.21286583852256, 43.29833934180337],
            [75.636964959622, 42.87789988867668],
            [76.00035363149846, 42.98802236589067],
            [77.6583919615832, 42.96068553320826],
            [79.14217736197978, 42.85609243424952],
            [79.64364546094012, 42.49668284765953],
            [80.2599902688853, 42.34999929459906],
            [80.11943037305139, 42.12394074153825],
            [78.54366092317531, 41.58224254003869],
            [78.18719689322597, 41.18531586360481],
            [76.90448449087708, 41.06648590754964],
            [76.52636803579745, 40.42794607193512],
            [75.4678279967307, 40.56207225194867],
            [74.77686242055606, 40.36642527929163],
            [73.8222436868283, 39.893973497063186],
            [73.96001305531843, 39.660008449861735],
            [73.6753792662548, 39.4312368841056],
            [71.784693637992, 39.27946320246437],
            [70.54916181832562, 39.6041979029865],
            [69.46488691597753, 39.5266832545487],
            [69.55960981636852, 40.10321137141298],
            [70.64801883329997, 39.93575389257117],
            [71.01419803252017, 40.24436554621823],
            [71.77487511585656, 40.14584442805378],
            [73.05541710804917, 40.866033026689465],
            [71.87011478057047, 41.392900092121266],
            [71.1578585142916, 41.14358714452912],
            [70.42002241402821, 41.51999827734314],
            [71.25924767444822, 42.16771067968946],
            [70.96231489449914, 42.26615428320549],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Cambodia",
        sov_a3: "KHM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Cambodia",
        adm0_a3: "KHM",
        geou_dif: 0,
        geounit: "Cambodia",
        gu_a3: "KHM",
        su_dif: 0,
        subunit: "Cambodia",
        su_a3: "KHM",
        brk_diff: 0,
        name: "Cambodia",
        name_long: "Cambodia",
        brk_a3: "KHM",
        brk_name: "Cambodia",
        brk_group: null,
        abbrev: "Camb.",
        postal: "KH",
        formal_en: "Kingdom of Cambodia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Cambodia",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 6,
        mapcolor13: 5,
        pop_est: 14494293,
        gdp_md_est: 27940,
        pop_year: -99,
        lastcensus: 2008,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "KH",
        iso_a3: "KHM",
        iso_n3: "116",
        un_a3: "116",
        wb_a2: "KH",
        wb_a3: "KHM",
        woe_id: -99,
        adm0_a3_is: "KHM",
        adm0_a3_us: "KHM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "KHM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [103.4972799011397, 10.632555446815928],
            [103.09068973186724, 11.153660590047165],
            [102.5849324890267, 12.186594956913282],
            [102.348099399833, 13.394247341358223],
            [102.98842207236163, 14.225721136934467],
            [104.28141808473661, 14.416743068901367],
            [105.21877689007887, 14.273211778210694],
            [106.04394616091552, 13.881091009979954],
            [106.49637332563087, 14.570583807834282],
            [107.38272749230109, 14.202440904186972],
            [107.61454796756243, 13.535530707244206],
            [107.49140302941089, 12.337205918827946],
            [105.81052371625313, 11.567614650921227],
            [106.24967003786946, 10.961811835163587],
            [105.19991499229235, 10.889309800658097],
            [104.33433475140347, 10.48654368737523],
            [103.4972799011397, 10.632555446815928],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Kuwait",
        sov_a3: "KWT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Kuwait",
        adm0_a3: "KWT",
        geou_dif: 0,
        geounit: "Kuwait",
        gu_a3: "KWT",
        su_dif: 0,
        subunit: "Kuwait",
        su_a3: "KWT",
        brk_diff: 0,
        name: "Kuwait",
        name_long: "Kuwait",
        brk_a3: "KWT",
        brk_name: "Kuwait",
        brk_group: null,
        abbrev: "Kwt.",
        postal: "KW",
        formal_en: "State of Kuwait",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Kuwait",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 2691158,
        gdp_md_est: 149100,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "KW",
        iso_a3: "KWT",
        iso_n3: "414",
        un_a3: "414",
        wb_a2: "KW",
        wb_a3: "KWT",
        woe_id: -99,
        adm0_a3_is: "KWT",
        adm0_a3_us: "KWT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "KWT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [47.974519077349896, 29.9758192001485],
            [48.18318851094448, 29.534476630159766],
            [48.09394331237642, 29.306299343375002],
            [48.416094191283946, 28.55200429942667],
            [47.708850538937384, 28.526062730416143],
            [47.45982181172283, 29.002519436147224],
            [46.568713413281756, 29.09902517345229],
            [47.30262210469096, 30.05906993257072],
            [47.974519077349896, 29.9758192001485],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "South Korea",
        sov_a3: "KOR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "South Korea",
        adm0_a3: "KOR",
        geou_dif: 0,
        geounit: "South Korea",
        gu_a3: "KOR",
        su_dif: 0,
        subunit: "South Korea",
        su_a3: "KOR",
        brk_diff: 0,
        name: "Korea",
        name_long: "Republic of Korea",
        brk_a3: "KOR",
        brk_name: "Republic of Korea",
        brk_group: null,
        abbrev: "S.K.",
        postal: "KR",
        formal_en: "Republic of Korea",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Korea, Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 5,
        pop_est: 48508972,
        gdp_md_est: 1335000,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "4. Emerging region: MIKT",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "KR",
        iso_a3: "KOR",
        iso_n3: "410",
        un_a3: "410",
        wb_a2: "KR",
        wb_a3: "KOR",
        woe_id: -99,
        adm0_a3_is: "KOR",
        adm0_a3_us: "KOR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 5,
        long_len: 17,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "KOR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [128.34971642467661, 38.61224294692785],
            [129.21291954968007, 37.43239248305595],
            [129.46044966035817, 36.78418915460282],
            [129.4683044780665, 35.63214061130395],
            [129.0913765809296, 35.082484239231434],
            [128.1858504578791, 34.89037710218639],
            [127.38651940318839, 34.47567373304412],
            [126.48574751190874, 34.39004588473648],
            [126.37391971242913, 34.934560451795946],
            [126.5592313986278, 35.6845405136479],
            [126.11739790253229, 36.72548472751926],
            [126.86014326386339, 36.893924058574626],
            [126.17475874237624, 37.74968577732804],
            [126.23733890188176, 37.84037791600028],
            [126.68371992401892, 37.80477285415118],
            [127.07330854706737, 38.2561148137884],
            [127.780035435091, 38.30453563084589],
            [128.20574588431145, 38.37039724380189],
            [128.34971642467661, 38.61224294692785],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Laos",
        sov_a3: "LAO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Laos",
        adm0_a3: "LAO",
        geou_dif: 0,
        geounit: "Laos",
        gu_a3: "LAO",
        su_dif: 0,
        subunit: "Laos",
        su_a3: "LAO",
        brk_diff: 0,
        name: "Lao PDR",
        name_long: "Lao PDR",
        brk_a3: "LAO",
        brk_name: "Laos",
        brk_group: null,
        abbrev: "Laos",
        postal: "LA",
        formal_en: "Lao People's Democratic Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Lao PDR",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 9,
        pop_est: 6834942,
        gdp_md_est: 13980,
        pop_year: -99,
        lastcensus: 2005,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "LA",
        iso_a3: "LAO",
        iso_n3: "418",
        un_a3: "418",
        wb_a2: "LA",
        wb_a3: "LAO",
        woe_id: -99,
        adm0_a3_is: "LAO",
        adm0_a3_us: "LAO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "LAO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [105.21877689007887, 14.273211778210694],
            [105.54433841351769, 14.723933620660416],
            [105.58903852745016, 15.570316066952858],
            [104.7793205098688, 16.44186493577145],
            [104.7169470560925, 17.42885895433008],
            [103.95647667848529, 18.24095408779688],
            [103.20019209189373, 18.309632066312773],
            [102.9987056823877, 17.9616946476916],
            [102.41300499879162, 17.932781683824288],
            [102.11359175009248, 18.109101670804165],
            [101.05954756063517, 17.51249725999449],
            [101.03593143107777, 18.408928330961615],
            [101.2820146016517, 19.462584947176765],
            [100.60629357300316, 19.508344427971224],
            [100.54888105672688, 20.109237982661128],
            [100.11598758341783, 20.417849636308187],
            [100.32910119018952, 20.786121731036232],
            [101.18000532430754, 21.436572984294024],
            [101.27002566935997, 21.201651923095184],
            [101.80311974488292, 21.17436676684507],
            [101.65201785686152, 22.318198757409547],
            [102.17043582561358, 22.464753119389304],
            [102.75489627483466, 21.675137233969465],
            [103.20386111858645, 20.766562201413745],
            [104.43500044150805, 20.75873322192153],
            [104.8225736836971, 19.886641750563882],
            [104.18338789267894, 19.62466807706022],
            [103.8965320170267, 19.265180975821806],
            [105.09459842328152, 18.66697459561108],
            [105.92576216026403, 17.48531545660896],
            [106.55600792849569, 16.604283962464805],
            [107.3127059265456, 15.90853831630318],
            [107.5645251811039, 15.202173163305558],
            [107.38272749230109, 14.202440904186972],
            [106.49637332563087, 14.570583807834282],
            [106.04394616091552, 13.881091009979954],
            [105.21877689007887, 14.273211778210694],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Lebanon",
        sov_a3: "LBN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Lebanon",
        adm0_a3: "LBN",
        geou_dif: 0,
        geounit: "Lebanon",
        gu_a3: "LBN",
        su_dif: 0,
        subunit: "Lebanon",
        su_a3: "LBN",
        brk_diff: 0,
        name: "Lebanon",
        name_long: "Lebanon",
        brk_a3: "LBN",
        brk_name: "Lebanon",
        brk_group: null,
        abbrev: "Leb.",
        postal: "LB",
        formal_en: "Lebanese Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Lebanon",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 12,
        pop_est: 4017095,
        gdp_md_est: 44060,
        pop_year: -99,
        lastcensus: 1970,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "LB",
        iso_a3: "LBN",
        iso_n3: "422",
        un_a3: "422",
        wb_a2: "LB",
        wb_a3: "LBN",
        woe_id: -99,
        adm0_a3_is: "LBN",
        adm0_a3_us: "LBN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: 4,
        homepart: 1,
        filename: "LBN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.82110070165024, 33.2774264592763],
            [35.55279666519081, 33.26427480725802],
            [35.460709262846706, 33.08904002535628],
            [35.126052687324545, 33.09090037691878],
            [35.48220665868013, 33.90545014091944],
            [35.9795923194894, 34.61005829521913],
            [35.99840254084364, 34.644914048800004],
            [36.4481942075121, 34.59393524834407],
            [36.61175011571589, 34.201788641897174],
            [36.066460402172055, 33.82491242119255],
            [35.82110070165024, 33.2774264592763],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Sri Lanka",
        sov_a3: "LKA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Sri Lanka",
        adm0_a3: "LKA",
        geou_dif: 0,
        geounit: "Sri Lanka",
        gu_a3: "LKA",
        su_dif: 0,
        subunit: "Sri Lanka",
        su_a3: "LKA",
        brk_diff: 0,
        name: "Sri Lanka",
        name_long: "Sri Lanka",
        brk_a3: "LKA",
        brk_name: "Sri Lanka",
        brk_group: null,
        abbrev: "Sri L.",
        postal: "LK",
        formal_en: "Democratic Socialist Republic of Sri Lanka",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Sri Lanka",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 21324791,
        gdp_md_est: 91870,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "LK",
        iso_a3: "LKA",
        iso_n3: "144",
        un_a3: "144",
        wb_a2: "LK",
        wb_a3: "LKA",
        woe_id: -99,
        adm0_a3_is: "LKA",
        adm0_a3_us: "LKA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 9,
        long_len: 9,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "LKA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [81.7879590188914, 7.523055324733164],
            [81.63732221876059, 6.481775214051921],
            [81.21801964714433, 6.197141424988288],
            [80.34835696810441, 5.968369859232155],
            [79.87246870312853, 6.76346344647493],
            [79.69516686393513, 8.200843410673386],
            [80.14780073437964, 9.824077663609557],
            [80.83881798698656, 9.268426825391188],
            [81.30431928907177, 8.56420624433369],
            [81.7879590188914, 7.523055324733164],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Myanmar",
        sov_a3: "MMR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Myanmar",
        adm0_a3: "MMR",
        geou_dif: 0,
        geounit: "Myanmar",
        gu_a3: "MMR",
        su_dif: 0,
        subunit: "Myanmar",
        su_a3: "MMR",
        brk_diff: 0,
        name: "Myanmar",
        name_long: "Myanmar",
        brk_a3: "MMR",
        brk_name: "Myanmar",
        brk_group: null,
        abbrev: "Myan.",
        postal: "MM",
        formal_en: "Republic of the Union of Myanmar",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Myanmar",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 13,
        pop_est: 48137741,
        gdp_md_est: 55130,
        pop_year: -99,
        lastcensus: 1983,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "MM",
        iso_a3: "MMR",
        iso_n3: "104",
        un_a3: "104",
        wb_a2: "MM",
        wb_a3: "MMR",
        woe_id: -99,
        adm0_a3_is: "MMR",
        adm0_a3_us: "MMR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "MMR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [99.54330936075931, 20.186597601802063],
            [98.95967573445488, 19.752980658440947],
            [98.25372399291561, 19.708203029860044],
            [97.7977828308044, 18.627080389881755],
            [97.37589643757354, 18.445437730375815],
            [97.85912275593486, 17.567946071843664],
            [98.49376102091135, 16.83783559820793],
            [98.90334842325676, 16.177824204976115],
            [98.53737592976572, 15.308497422746084],
            [98.1920740091914, 15.12370250087035],
            [98.43081912637987, 14.622027696180835],
            [99.09775516153876, 13.827502549693278],
            [99.21201175333609, 13.269293728076464],
            [99.19635379435167, 12.80474843998867],
            [99.58728600463972, 11.892762762901697],
            [99.03812055867398, 10.960545762572437],
            [98.55355065307305, 9.932959906448545],
            [98.45717410684871, 10.67526601810515],
            [98.76454552612077, 11.441291612183749],
            [98.42833865762985, 12.032986761925683],
            [98.50957400919268, 13.122377631070677],
            [98.1036039571077, 13.640459703012851],
            [97.77773237507517, 14.837285874892642],
            [97.59707156778276, 16.10056793869977],
            [97.1645398294998, 16.928734442609336],
            [96.505768670643, 16.42724050543285],
            [95.3693522481124, 15.7143899601826],
            [94.80840457558412, 15.80345429123764],
            [94.18880415240454, 16.037936102762018],
            [94.53348595579135, 17.277240301985728],
            [94.32481652219674, 18.2135139022499],
            [93.54098839719364, 19.36649262133002],
            [93.66325483599621, 19.726961574781996],
            [93.07827762245219, 19.855144965081976],
            [92.36855350135562, 20.670883287025347],
            [92.30323449093868, 21.47548533780982],
            [92.65225711463799, 21.324047552978485],
            [92.67272098182556, 22.041238918541254],
            [93.16612755734836, 22.278459580977103],
            [93.06029422401463, 22.70311066333557],
            [93.28632693885928, 23.043658352139005],
            [93.3251876159428, 24.078556423432204],
            [94.10674197792505, 23.85074087167348],
            [94.55265791217164, 24.675238348890332],
            [94.60324913938538, 25.162495428970402],
            [95.1551534362626, 26.001307277932085],
            [95.12476769407496, 26.5735720891323],
            [96.41936567585097, 27.264589341739224],
            [97.1339990580153, 27.083773505149964],
            [97.0519885599681, 27.69905894623315],
            [97.40256147663612, 27.88253611908544],
            [97.32711388549004, 28.26158274994634],
            [97.91198774616944, 28.335945136014345],
            [98.2462309102333, 27.74722138112918],
            [98.68269005737046, 27.50881216075062],
            [98.71209394734451, 26.743535874940264],
            [98.67183800658916, 25.918702500913525],
            [97.72460900267914, 25.083637193293],
            [97.60471967976198, 23.897404690033042],
            [98.66026248575577, 24.063286037689966],
            [98.89874922078276, 23.14272207284253],
            [99.5319922220874, 22.94903880461258],
            [99.24089887898725, 22.11831431730458],
            [99.98348921102149, 21.7429367131364],
            [100.41653771362738, 21.558839423096614],
            [101.15003299357825, 21.84998444262902],
            [101.18000532430754, 21.436572984294024],
            [100.32910119018952, 20.786121731036232],
            [100.11598758341783, 20.417849636308187],
            [99.54330936075931, 20.186597601802063],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Mongolia",
        sov_a3: "MNG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Mongolia",
        adm0_a3: "MNG",
        geou_dif: 0,
        geounit: "Mongolia",
        gu_a3: "MNG",
        su_dif: 0,
        subunit: "Mongolia",
        su_a3: "MNG",
        brk_diff: 0,
        name: "Mongolia",
        name_long: "Mongolia",
        brk_a3: "MNG",
        brk_name: "Mongolia",
        brk_group: null,
        abbrev: "Mong.",
        postal: "MN",
        formal_en: "Mongolia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Mongolia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 5,
        mapcolor13: 6,
        pop_est: 3041142,
        gdp_md_est: 9476,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "MN",
        iso_a3: "MNG",
        iso_n3: "496",
        un_a3: "496",
        wb_a2: "MN",
        wb_a3: "MNG",
        woe_id: -99,
        adm0_a3_is: "MNG",
        adm0_a3_us: "MNG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "MNG.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.7512642760767, 49.29719798440548],
            [88.80556684769552, 49.47052073831242],
            [90.71366743364067, 50.33181183532109],
            [92.23471154171968, 50.80217072204172],
            [93.10421919146269, 50.49529022887643],
            [94.14756635943561, 50.48053660745709],
            [94.81594933469873, 50.01343333597085],
            [95.81402794798399, 49.977466539095715],
            [97.25972781778141, 49.72606069599574],
            [98.23176150919156, 50.422400621128745],
            [97.8257397806743, 51.01099518493318],
            [98.86149051310034, 52.047366034546684],
            [99.98173221232354, 51.63400625264399],
            [100.88948042196262, 51.51685578063832],
            [102.06522260946733, 51.259920559283124],
            [102.25590864462431, 50.51056061461868],
            [103.67654544476022, 50.089966132195116],
            [104.6215523620817, 50.275329494826074],
            [105.88659142458675, 50.406019192092224],
            [106.88880415245534, 50.27429596618023],
            [107.86817589725094, 49.793705145865815],
            [108.47516727095127, 49.28254771585074],
            [109.40244917199666, 49.29296051695755],
            [110.66201053267876, 49.13012807880587],
            [111.58123091028662, 49.37796824807769],
            [112.89773969935439, 49.54356537535699],
            [114.36245649623527, 50.24830272073741],
            [114.96210981655018, 50.140247300815126],
            [115.48569542853141, 49.805177313834605],
            [116.67880089728618, 49.88853139912139],
            [116.19180219936757, 49.134598090199106],
            [115.48528201707305, 48.13538259540344],
            [115.74283735561578, 47.72654450132629],
            [116.30895267137323, 47.85341014260284],
            [117.29550744025741, 47.69770905210743],
            [118.06414269416672, 48.06673045510368],
            [118.86657433479495, 47.74706004494617],
            [119.7728239278975, 47.048058783550125],
            [119.66326989143874, 46.69267995867892],
            [118.87432579963873, 46.80541209572365],
            [117.42170128791419, 46.67273285581426],
            [116.71786828009886, 46.38820241961521],
            [115.98509647020008, 45.727235012386004],
            [114.46033165899607, 45.339816799493825],
            [113.46390669154417, 44.80889313412711],
            [112.43606245325881, 45.01164561622429],
            [111.87330610560029, 45.10207937273506],
            [111.34837690637946, 44.45744171811009],
            [111.66773725794323, 44.07317576758771],
            [111.82958784388137, 43.743118394539515],
            [111.12968224492022, 43.40683401140015],
            [110.41210330611528, 42.87123362891103],
            [109.24359581913146, 42.5194463160841],
            [107.74477257693795, 42.48151581478187],
            [106.12931562706169, 42.13432770442891],
            [104.96499393109347, 41.59740957291635],
            [104.52228193564899, 41.908346666016556],
            [103.31227827353482, 41.9074681666676],
            [101.83304039917994, 42.51487295182628],
            [100.84586551310827, 42.66380442969145],
            [99.51581749878004, 42.52469147396172],
            [97.45175744017801, 42.74888967546002],
            [96.34939578652781, 42.725635280928685],
            [95.76245486855669, 43.319449164394605],
            [95.30687544147153, 44.24133087826547],
            [94.68892866412533, 44.352331854828414],
            [93.4807336771413, 44.975472113619965],
            [92.13389082231822, 45.11507599545646],
            [90.9455395853343, 45.28607330991028],
            [90.58576826371828, 45.71971609148753],
            [90.97080936072501, 46.88814606382293],
            [90.28082563676392, 47.69354909930793],
            [88.85429772334676, 48.06908173277296],
            [88.01383222855173, 48.599462795600616],
            [87.7512642760767, 49.29719798440548],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Malaysia",
        sov_a3: "MYS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Malaysia",
        adm0_a3: "MYS",
        geou_dif: 0,
        geounit: "Malaysia",
        gu_a3: "MYS",
        su_dif: 0,
        subunit: "Malaysia",
        su_a3: "MYS",
        brk_diff: 0,
        name: "Malaysia",
        name_long: "Malaysia",
        brk_a3: "MYS",
        brk_name: "Malaysia",
        brk_group: null,
        abbrev: "Malay.",
        postal: "MY",
        formal_en: "Malaysia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Malaysia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 4,
        mapcolor9: 3,
        mapcolor13: 6,
        pop_est: 25715819,
        gdp_md_est: 384300,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "MY",
        iso_a3: "MYS",
        iso_n3: "458",
        un_a3: "458",
        wb_a2: "MY",
        wb_a3: "MYS",
        woe_id: -99,
        adm0_a3_is: "MYS",
        adm0_a3_us: "MYS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "MYS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [101.07551557821333, 6.204867051615892],
              [101.15421878459384, 5.691384182147715],
              [101.81428185425804, 5.810808417174228],
              [102.14118696493645, 6.221636053894656],
              [102.37114708863524, 6.12820506431096],
              [102.9617053568667, 5.524495144061078],
              [103.38121463421217, 4.855001125503748],
              [103.4385754740562, 4.181605536308382],
              [103.33212202353488, 3.726697902842971],
              [103.42942874554055, 3.38286876058902],
              [103.50244754436889, 2.791018581550205],
              [103.85467410687036, 2.515454006353763],
              [104.24793175661151, 1.631141058759056],
              [104.22881147666354, 1.293048000489534],
              [103.51970747275443, 1.226333726400682],
              [102.57361535035479, 1.967115383304744],
              [101.39063846232918, 2.760813706875624],
              [101.27353966675585, 3.270291652841181],
              [100.6954354187067, 3.93913971599487],
              [100.55740766805509, 4.76728038168828],
              [100.19670617065773, 5.31249258058368],
              [100.30626020711652, 6.040561835143876],
              [100.08575687052709, 6.46448944745029],
              [100.25959638875692, 6.642824815289572],
              [101.07551557821333, 6.204867051615892],
            ],
          ],
          [
            [
              [118.61832075406485, 4.478202419447541],
              [117.88203494677019, 4.137551377779488],
              [117.01521447150637, 4.306094061699469],
              [115.86551720587677, 4.306559149590157],
              [115.51907840379201, 3.169238389494396],
              [115.13403730678523, 2.821481838386219],
              [114.6213554220175, 1.430688177898887],
              [113.80584964401956, 1.217548732911041],
              [112.8598091980522, 1.497790025229946],
              [112.38025190638368, 1.410120957846758],
              [111.79754845586044, 0.904441229654651],
              [111.15913781132659, 0.976478176269509],
              [110.51406090702713, 0.773131415200993],
              [109.83022667850886, 1.338135687664192],
              [109.66326012577375, 2.006466986494985],
              [110.39613528853707, 1.663774725751395],
              [111.1688529805975, 1.850636704918784],
              [111.3700810079421, 2.697303371588873],
              [111.79692833867287, 2.885896511238073],
              [112.99561486211527, 3.102394924324869],
              [113.71293541875873, 3.893509426281128],
              [114.20401655482843, 4.52587392823682],
              [114.65959598191355, 4.00763682699781],
              [114.8695573263154, 4.348313706881952],
              [115.34746097215069, 4.316636053887009],
              [115.40570031134362, 4.955227565933825],
              [115.45071048386981, 5.447729803891561],
              [116.22074100145099, 6.143191229675621],
              [116.72510298061978, 6.924771429873998],
              [117.12962609260049, 6.928052883324567],
              [117.64339318244633, 6.422166449403306],
              [117.68907514859237, 5.987490139180181],
              [118.3476912781522, 5.708695786965464],
              [119.18190392463994, 5.407835598162251],
              [119.11069380094172, 5.016128241389865],
              [118.43972700406411, 4.96651886638962],
              [118.61832075406485, 4.478202419447541],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Nepal",
        sov_a3: "NPL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Nepal",
        adm0_a3: "NPL",
        geou_dif: 0,
        geounit: "Nepal",
        gu_a3: "NPL",
        su_dif: 0,
        subunit: "Nepal",
        su_a3: "NPL",
        brk_diff: 0,
        name: "Nepal",
        name_long: "Nepal",
        brk_a3: "NPL",
        brk_name: "Nepal",
        brk_group: null,
        abbrev: "Nepal",
        postal: "NP",
        formal_en: "Nepal",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Nepal",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 12,
        pop_est: 28563377,
        gdp_md_est: 31080,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "NP",
        iso_a3: "NPL",
        iso_n3: "524",
        un_a3: "524",
        wb_a2: "NP",
        wb_a3: "NPL",
        woe_id: -99,
        adm0_a3_is: "NPL",
        adm0_a3_us: "NPL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "NPL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.12044070836987, 27.876541652939594],
            [88.04313276566123, 27.445818589786825],
            [88.17480431514092, 26.81040517832595],
            [88.06023766474982, 26.41461538340249],
            [87.22747195836628, 26.397898057556077],
            [86.02439293817918, 26.63098460540857],
            [85.25177859898338, 26.72619843190634],
            [84.6750179381738, 27.234901231387536],
            [83.30424889519955, 27.36450572357556],
            [81.99998742058497, 27.925479234319994],
            [81.05720258985203, 28.416095282499043],
            [80.08842451367627, 28.79447011974014],
            [80.4767212259174, 29.72986522065534],
            [81.11125613802932, 30.183480943313402],
            [81.52580447787474, 30.42271698660863],
            [82.32751264845088, 30.115268052688133],
            [83.33711510613719, 29.463731594352193],
            [83.89899295444673, 29.320226141877658],
            [84.23457970575015, 28.839893703724698],
            [85.01163821812304, 28.642773952747344],
            [85.82331994013151, 28.203575954698703],
            [86.9545170430006, 27.974261786403517],
            [88.12044070836987, 27.876541652939594],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Oman",
        sov_a3: "OMN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Oman",
        adm0_a3: "OMN",
        geou_dif: 0,
        geounit: "Oman",
        gu_a3: "OMN",
        su_dif: 0,
        subunit: "Oman",
        su_a3: "OMN",
        brk_diff: 0,
        name: "Oman",
        name_long: "Oman",
        brk_a3: "OMN",
        brk_name: "Oman",
        brk_group: null,
        abbrev: "Oman",
        postal: "OM",
        formal_en: "Sultanate of Oman",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Oman",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 6,
        pop_est: 3418085,
        gdp_md_est: 66980,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "OM",
        iso_a3: "OMN",
        iso_n3: "512",
        un_a3: "512",
        wb_a2: "OM",
        wb_a3: "OMN",
        woe_id: -99,
        adm0_a3_is: "OMN",
        adm0_a3_us: "OMN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "OMN.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [58.86114139184659, 21.114034532144302],
              [58.48798587426696, 20.42898590746711],
              [58.034318475176605, 20.48143748624335],
              [57.82637251163411, 20.24300242764863],
              [57.665762160070955, 19.736004950433113],
              [57.788700392493375, 19.06757029873765],
              [57.69439090356068, 18.944709580963803],
              [57.234263950433814, 18.947991034414258],
              [56.609650913321985, 18.57426707607948],
              [56.512189162019496, 18.087113348863937],
              [56.28352094912801, 17.87606679938395],
              [55.6614917336307, 17.88412832282154],
              [55.2699394061552, 17.632309068263197],
              [55.274900343655105, 17.228354397037663],
              [54.79100223167413, 16.950696926333364],
              [54.239252964093765, 17.044980577049984],
              [53.570508253804604, 16.707662665264678],
              [53.10857262554751, 16.65105113368898],
              [52.78218427919207, 17.349742336491232],
              [52.00000980002224, 19.000003363516072],
              [54.99998172386242, 19.99999400479612],
              [55.66665937685988, 22.00000112557231],
              [55.2083410988632, 22.70832998299701],
              [55.234489373602884, 23.11099274341535],
              [55.5258410988645, 23.524869289640918],
              [55.52863162620829, 23.933604030853502],
              [55.98121382022052, 24.130542914317854],
              [55.80411868675625, 24.269604193615294],
              [55.886232537668064, 24.920830593357493],
              [56.396847365144, 24.924732163995515],
              [56.84514041527606, 24.241673081961494],
              [57.40345258975744, 23.87859446867884],
              [58.13694786970834, 23.74793060962884],
              [58.72921146020544, 23.565667832935418],
              [59.18050174341036, 22.99239533130546],
              [59.45009769067703, 22.6602709009656],
              [59.80806033716286, 22.533611965418203],
              [59.8061483091681, 22.31052480721419],
              [59.44219119653641, 21.714540513592084],
              [59.282407667889885, 21.433885809814882],
              [58.86114139184659, 21.114034532144302],
            ],
          ],
          [
            [
              [56.39142133975341, 25.89599070892126],
              [56.26104170108093, 25.71460643157675],
              [56.07082075381456, 26.05546417897395],
              [56.36201744977936, 26.395934353128947],
              [56.48567915225382, 26.309117946878672],
              [56.39142133975341, 25.89599070892126],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Pakistan",
        sov_a3: "PAK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Pakistan",
        adm0_a3: "PAK",
        geou_dif: 0,
        geounit: "Pakistan",
        gu_a3: "PAK",
        su_dif: 0,
        subunit: "Pakistan",
        su_a3: "PAK",
        brk_diff: 0,
        name: "Pakistan",
        name_long: "Pakistan",
        brk_a3: "PAK",
        brk_name: "Pakistan",
        brk_group: null,
        abbrev: "Pak.",
        postal: "PK",
        formal_en: "Islamic Republic of Pakistan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Pakistan",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 11,
        pop_est: 176242949,
        gdp_md_est: 427300,
        pop_year: -99,
        lastcensus: 1998,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "PK",
        iso_a3: "PAK",
        iso_n3: "586",
        un_a3: "586",
        wb_a2: "PK",
        wb_a3: "PAK",
        woe_id: -99,
        adm0_a3_is: "PAK",
        adm0_a3_us: "PAK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Southern Asia",
        region_wb: "South Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "PAK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [75.15802778514092, 37.13303091078912],
            [75.89689741405013, 36.666806138651836],
            [76.19284834178569, 35.89840342868782],
            [77.83745079947457, 35.494009507787766],
            [76.87172163280403, 34.65354401299274],
            [75.75706098826834, 34.50492259372132],
            [74.24020267120497, 34.74888703057125],
            [73.74994835805195, 34.31769887952785],
            [74.10429365427734, 33.44147329358685],
            [74.45155927927871, 32.7648996038055],
            [75.25864179881322, 32.2711054550405],
            [74.40592898956501, 31.69263947196528],
            [74.42138024282026, 30.979814764931177],
            [73.45063846221743, 29.97641347911987],
            [72.8237516620847, 28.961591701772054],
            [71.77766564320032, 27.913180243434525],
            [70.61649620960193, 27.989196275335868],
            [69.51439293811312, 26.940965684511372],
            [70.16892662952202, 26.491871649678842],
            [70.28287316272558, 25.72222870533983],
            [70.84469933460284, 25.21510203704352],
            [71.04324018746823, 24.3565239527302],
            [68.84259931831878, 24.35913361256094],
            [68.1766451353734, 23.69196503345671],
            [67.44366661974547, 23.94484365487699],
            [67.14544192898907, 24.663611151624647],
            [66.37282758979326, 25.425140896093847],
            [64.53040774929113, 25.23703868255143],
            [62.9057007180346, 25.21840932871021],
            [61.49736290878419, 25.0782370061185],
            [61.87418745305655, 26.239974880472104],
            [63.31663170761959, 26.756532497661667],
            [63.233897739520295, 27.21704702403071],
            [62.755425652929866, 27.378923448184985],
            [62.72783043808598, 28.25964488373539],
            [61.77186811711863, 28.699333807890795],
            [61.36930870956494, 29.303276272085924],
            [60.874248488208785, 29.829238999952604],
            [62.54985680527278, 29.31857249604431],
            [63.55026085801117, 29.468330796826162],
            [64.14800215033125, 29.340819200145972],
            [64.35041873561852, 29.560030625928093],
            [65.0468620136161, 29.472180691031905],
            [66.34647260932442, 29.887943427036177],
            [66.38145755398602, 30.73889923758645],
            [66.93889122911847, 31.304911200479353],
            [67.68339358914747, 31.30315420178142],
            [67.79268924344478, 31.58293040620963],
            [68.55693200060932, 31.713310044882018],
            [68.92667687365767, 31.620189113892064],
            [69.31776411324255, 31.90141225842444],
            [69.26252200712256, 32.5019440780883],
            [69.68714725126485, 33.105498969041236],
            [70.3235941913716, 33.35853261975839],
            [69.9305432473596, 34.02012014417511],
            [70.8818030129884, 33.98885590263851],
            [71.15677330921346, 34.34891144463215],
            [71.11501875192162, 34.733125718722235],
            [71.6130762063507, 35.153203436822864],
            [71.49876793812109, 35.650563259416],
            [71.26234826038575, 36.074387518857804],
            [71.84629194528392, 36.50994232842986],
            [72.92002485544447, 36.72000702569632],
            [74.06755171091783, 36.83617564548845],
            [74.57589277537298, 37.02084137628346],
            [75.15802778514092, 37.13303091078912],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Philippines",
        sov_a3: "PHL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Philippines",
        adm0_a3: "PHL",
        geou_dif: 0,
        geounit: "Philippines",
        gu_a3: "PHL",
        su_dif: 0,
        subunit: "Philippines",
        su_a3: "PHL",
        brk_diff: 0,
        name: "Philippines",
        name_long: "Philippines",
        brk_a3: "PHL",
        brk_name: "Philippines",
        brk_group: null,
        abbrev: "Phil.",
        postal: "PH",
        formal_en: "Republic of the Philippines",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Philippines",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 8,
        pop_est: 97976603,
        gdp_md_est: 317500,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "PH",
        iso_a3: "PHL",
        iso_n3: "608",
        un_a3: "608",
        wb_a2: "PH",
        wb_a3: "PHL",
        woe_id: -99,
        adm0_a3_is: "PHL",
        adm0_a3_us: "PHL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 11,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "PHL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [126.37681359263748, 8.414706325713354],
              [126.4785128113879, 7.750354112168978],
              [126.53742394420063, 7.189380601424574],
              [126.19677290253256, 6.27429433840004],
              [125.83142052622911, 7.293715318221857],
              [125.3638521668523, 6.786485297060992],
              [125.68316084198372, 6.049656887227258],
              [125.39651167206064, 5.58100332277229],
              [124.21978763234236, 6.161355495626182],
              [123.93871951710695, 6.885135606306122],
              [124.24366214406135, 7.360610459823661],
              [123.61021243702757, 7.833527329942754],
              [123.2960714051252, 7.418875637232787],
              [122.82550581267542, 7.457374579290217],
              [122.08549930225577, 6.899424139834849],
              [121.91992801319263, 7.192119452336072],
              [122.31235884001714, 8.034962063016508],
              [122.94239790251966, 8.316236883981174],
              [123.48768761606352, 8.693009751821194],
              [123.84115441293984, 8.240324204944386],
              [124.60146976125023, 8.514157619659017],
              [124.76461225799564, 8.96040945071546],
              [125.47139082245157, 8.986996975129642],
              [125.41211795461278, 9.760334784377548],
              [126.22271447154318, 9.28607432701885],
              [126.3066369975851, 8.782487494334575],
              [126.37681359263748, 8.414706325713354],
            ],
          ],
          [
            [
              [123.98243777882583, 10.278778591345812],
              [123.62318322153278, 9.950090643753299],
              [123.30992068897936, 9.318268744336676],
              [122.99588300994164, 9.0221886255204],
              [122.38005496631948, 9.713360907424203],
              [122.5860889018671, 9.981044826696104],
              [122.83708133350873, 10.261156927934238],
              [122.94741051645192, 10.881868394408029],
              [123.49884972543848, 10.940624497923949],
              [123.33777428598475, 10.267383938025446],
              [124.07793582570125, 11.23272553145371],
              [123.98243777882583, 10.278778591345812],
            ],
          ],
          [
            [
              [118.50458092659035, 9.31638255455809],
              [117.1742745301007, 8.367499904814665],
              [117.66447716682138, 9.066888739452935],
              [118.38691369026175, 9.684499619989225],
              [118.98734215706108, 10.376292019080509],
              [119.51149620979756, 11.369668077027214],
              [119.68967654833992, 10.554291490109875],
              [119.029458449379, 10.003653265823871],
              [118.50458092659035, 9.31638255455809],
            ],
          ],
          [
            [
              [121.88354780485913, 11.89175507247198],
              [122.48382124236147, 11.582187404827508],
              [123.12021650603597, 11.58366018314787],
              [123.10083784392647, 11.16593374271649],
              [122.6377136577267, 10.741308498574227],
              [122.00261030485957, 10.441016750526087],
              [121.96736697803655, 10.905691229694623],
              [122.03837039600555, 11.41584096928004],
              [121.88354780485913, 11.89175507247198],
            ],
          ],
          [
            [
              [125.50255171112352, 12.162694606978349],
              [125.78346479706218, 11.046121934447768],
              [125.01188398651229, 11.31145457605038],
              [125.03276126515814, 10.975816148314706],
              [125.27744917206027, 10.358722032101312],
              [124.80181928924573, 10.134678859899893],
              [124.76016808481849, 10.837995103392302],
              [124.45910119028606, 10.889929917845635],
              [124.30252160044172, 11.495370998577227],
              [124.8910128113816, 11.415582587118593],
              [124.87799035044398, 11.79418996830499],
              [124.26676150929572, 12.557760931849685],
              [125.22711632700785, 12.535720933477194],
              [125.50255171112352, 12.162694606978349],
            ],
          ],
          [
            [
              [121.52739383350351, 13.06959015548452],
              [121.26219038298157, 12.205560207564403],
              [120.83389611214656, 12.70449616134242],
              [120.3234363139675, 13.46641347905387],
              [121.18012820850217, 13.429697373910443],
              [121.52739383350351, 13.06959015548452],
            ],
          ],
          [
            [
              [121.32130822152358, 18.504064642811016],
              [121.9376013530364, 18.218552354398383],
              [122.24600630095429, 18.478949896717097],
              [122.336956821788, 18.224882717354177],
              [122.1742794129332, 17.810282701076375],
              [122.51565392465336, 17.093504746971973],
              [122.2523108256939, 16.262444362854126],
              [121.66278608610828, 15.931017564350128],
              [121.5050696147534, 15.124813544164622],
              [121.72882856657728, 14.328376369682246],
              [122.25892540902734, 14.218202216035976],
              [122.70127566944566, 14.33654124598442],
              [123.95029503794026, 13.78213064214107],
              [123.85510704965863, 13.237771104378467],
              [124.1812886902849, 12.997527370653472],
              [124.07741906137825, 12.536676947474575],
              [123.29803510955227, 13.027525539598981],
              [122.92865197152993, 13.552919826710408],
              [122.67135501514869, 13.185836289925135],
              [122.03464969288055, 13.784481919810347],
              [121.1263847189186, 13.63668732345556],
              [120.62863732308331, 13.857655747935652],
              [120.67938357959385, 14.271015529838323],
              [120.99181928923055, 14.525392767795083],
              [120.69333621631272, 14.756670640517285],
              [120.564145135583, 14.396279201713822],
              [120.0704285014664, 14.970869452367097],
              [119.92092858284613, 15.406346747290739],
              [119.88377322802826, 16.363704331929966],
              [120.28648766487882, 16.03462881109533],
              [120.39004723519176, 17.59908112229951],
              [120.71586714079191, 18.50522736253754],
              [121.32130822152358, 18.504064642811016],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "North Korea",
        sov_a3: "PRK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "North Korea",
        adm0_a3: "PRK",
        geou_dif: 0,
        geounit: "North Korea",
        gu_a3: "PRK",
        su_dif: 0,
        subunit: "North Korea",
        su_a3: "PRK",
        brk_diff: 0,
        name: "Dem. Rep. Korea",
        name_long: "Dem. Rep. Korea",
        brk_a3: "PRK",
        brk_name: "Dem. Rep. Korea",
        brk_group: null,
        abbrev: "N.K.",
        postal: "KP",
        formal_en: "Democratic People's Republic of Korea",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Korea, Dem. Rep.",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 22665345,
        gdp_md_est: 40000,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "KP",
        iso_a3: "PRK",
        iso_n3: "408",
        un_a3: "408",
        wb_a2: "KP",
        wb_a3: "PRK",
        woe_id: -99,
        adm0_a3_is: "PRK",
        adm0_a3_us: "PRK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 15,
        long_len: 15,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "PRK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [130.6400159038524, 42.39500946712528],
            [130.78000735893113, 42.22000722916885],
            [130.40003055228902, 42.28000356705971],
            [129.96594852103726, 41.94136790625105],
            [129.66736209525482, 41.60110443782523],
            [129.70518924369247, 40.88282786718433],
            [129.18811486218, 40.66180776627199],
            [129.01039961152821, 40.485436102859815],
            [128.63336836152672, 40.18984691015031],
            [127.96741417858135, 40.02541250259756],
            [127.53343550019417, 39.7568500839767],
            [127.5021195822253, 39.32393077245153],
            [127.38543419811029, 39.213472398427655],
            [127.78334272675772, 39.05089834243742],
            [128.34971642467661, 38.61224294692785],
            [128.20574588431145, 38.37039724380189],
            [127.780035435091, 38.30453563084589],
            [127.07330854706737, 38.2561148137884],
            [126.68371992401892, 37.80477285415118],
            [126.23733890188176, 37.84037791600028],
            [126.17475874237624, 37.74968577732804],
            [125.6891036316972, 37.940010077459014],
            [125.56843916229569, 37.75208873142962],
            [125.2753304383362, 37.669070542952724],
            [125.24008711151315, 37.85722443292744],
            [124.98103315643398, 37.94882090916478],
            [124.71216067921938, 38.10834605564979],
            [124.98599409393398, 38.54847422947968],
            [125.2219486837787, 38.66585724543067],
            [125.13285851450752, 38.84855927179859],
            [125.3865897970606, 39.387957872061165],
            [125.3211157573468, 39.5513845891842],
            [124.7374821310424, 39.66034434667162],
            [124.26562462778531, 39.928493353834156],
            [125.07994184784063, 40.56982371679245],
            [126.18204511932943, 41.10733612727637],
            [126.86908328664985, 41.81656932226619],
            [127.34378299368302, 41.50315176041597],
            [128.20843305879066, 41.46677155208249],
            [128.0522152039723, 41.99428457291795],
            [129.59666873587952, 42.42498179785456],
            [129.99426720593323, 42.985386867843786],
            [130.6400159038524, 42.39500946712528],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Israel",
        sov_a3: "ISR",
        adm0_dif: 1,
        level: 2,
        type: "Disputed",
        admin: "Palestine",
        adm0_a3: "PSX",
        geou_dif: 0,
        geounit: "Palestine",
        gu_a3: "PSX",
        su_dif: 0,
        subunit: "Palestine",
        su_a3: "PSX",
        brk_diff: 0,
        name: "Palestine",
        name_long: "Palestine",
        brk_a3: "PSX",
        brk_name: "Palestine",
        brk_group: null,
        abbrev: "Pal.",
        postal: "PAL",
        formal_en: "West Bank and Gaza",
        formal_fr: null,
        note_adm0: "Partial self-admin.",
        note_brk: "Partial self-admin.",
        name_sort: "Palestine (West Bank and Gaza)",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 8,
        pop_est: 4119083,
        gdp_md_est: 11950.77,
        pop_year: -99,
        lastcensus: 2007,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "PS",
        iso_a3: "PSE",
        iso_n3: "275",
        un_a3: "275",
        wb_a2: "GZ",
        wb_a3: "WBG",
        woe_id: -99,
        adm0_a3_is: "PSE",
        adm0_a3_us: "PSX",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: -99,
        filename: "PSE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.54566531753454, 32.393992011030576],
            [35.5452519060762, 31.78250478772084],
            [35.397560662586045, 31.48908600516758],
            [34.92740848159457, 31.353435370401414],
            [34.970506626125996, 31.616778469360806],
            [35.22589155451242, 31.754341132121766],
            [34.97464074070933, 31.86658234305972],
            [35.183930291491436, 32.53251068778894],
            [35.54566531753454, 32.393992011030576],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Qatar",
        sov_a3: "QAT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Qatar",
        adm0_a3: "QAT",
        geou_dif: 0,
        geounit: "Qatar",
        gu_a3: "QAT",
        su_dif: 0,
        subunit: "Qatar",
        su_a3: "QAT",
        brk_diff: 0,
        name: "Qatar",
        name_long: "Qatar",
        brk_a3: "QAT",
        brk_name: "Qatar",
        brk_group: null,
        abbrev: "Qatar",
        postal: "QA",
        formal_en: "State of Qatar",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Qatar",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 4,
        pop_est: 833285,
        gdp_md_est: 91330,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "QA",
        iso_a3: "QAT",
        iso_n3: "634",
        un_a3: "634",
        wb_a2: "QA",
        wb_a3: "QAT",
        woe_id: -99,
        adm0_a3_is: "QAT",
        adm0_a3_us: "QAT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "QAT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [50.81010827006958, 24.754742539971378],
            [50.74391076030369, 25.482424221289396],
            [51.01335167827349, 26.00699168548419],
            [51.28646162293606, 26.11458201751587],
            [51.58907881043726, 25.80111277923338],
            [51.60670047384881, 25.21567047779874],
            [51.38960778179063, 24.62738597258806],
            [51.11241539897702, 24.556330878186724],
            [50.81010827006958, 24.754742539971378],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Thailand",
        sov_a3: "THA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Thailand",
        adm0_a3: "THA",
        geou_dif: 0,
        geounit: "Thailand",
        gu_a3: "THA",
        su_dif: 0,
        subunit: "Thailand",
        su_a3: "THA",
        brk_diff: 0,
        name: "Thailand",
        name_long: "Thailand",
        brk_a3: "THA",
        brk_name: "Thailand",
        brk_group: null,
        abbrev: "Thai.",
        postal: "TH",
        formal_en: "Kingdom of Thailand",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Thailand",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 8,
        mapcolor13: 1,
        pop_est: 65905410,
        gdp_md_est: 547400,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "TH",
        iso_a3: "THA",
        iso_n3: "764",
        un_a3: "764",
        wb_a2: "TH",
        wb_a3: "THA",
        woe_id: -99,
        adm0_a3_is: "THA",
        adm0_a3_us: "THA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "THA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [102.5849324890267, 12.186594956913282],
            [101.68715783081996, 12.645740057826572],
            [100.83180952352487, 12.627084865769206],
            [100.9784672383692, 13.412721665902566],
            [100.0977974792511, 13.406856390837433],
            [100.01873253784456, 12.307001044153354],
            [99.47892052612363, 10.846366685423547],
            [99.15377241414316, 9.963061428258554],
            [99.22239871622676, 9.239255479362427],
            [99.87383182169813, 9.20786204674512],
            [100.27964684448622, 8.295152899606052],
            [100.45927412313276, 7.429572658717177],
            [101.01732791545273, 6.856868597842478],
            [101.62307905477806, 6.74062246340192],
            [102.14118696493638, 6.221636053894628],
            [101.81428185425798, 5.810808417174242],
            [101.15421878459387, 5.691384182147715],
            [101.07551557821336, 6.204867051615921],
            [100.25959638875696, 6.642824815289543],
            [100.0857568705271, 6.46448944745029],
            [99.69069054565574, 6.848212795433597],
            [99.51964155476963, 7.34345388430276],
            [98.9882528015123, 7.907993068875327],
            [98.503786248776, 8.382305202666288],
            [98.339661899817, 7.794511623562386],
            [98.15000939330581, 8.350007432483878],
            [98.25915001830624, 8.973922837759801],
            [98.55355065307305, 9.932959906448545],
            [99.03812055867398, 10.960545762572437],
            [99.58728600463972, 11.892762762901697],
            [99.19635379435167, 12.80474843998867],
            [99.21201175333609, 13.269293728076464],
            [99.09775516153876, 13.827502549693278],
            [98.43081912637987, 14.622027696180835],
            [98.1920740091914, 15.12370250087035],
            [98.53737592976572, 15.308497422746084],
            [98.90334842325676, 16.177824204976115],
            [98.49376102091135, 16.83783559820793],
            [97.85912275593486, 17.567946071843664],
            [97.37589643757354, 18.445437730375815],
            [97.7977828308044, 18.627080389881755],
            [98.25372399291561, 19.708203029860044],
            [98.95967573445488, 19.752980658440947],
            [99.54330936075931, 20.186597601802063],
            [100.11598758341783, 20.417849636308187],
            [100.54888105672688, 20.109237982661128],
            [100.60629357300316, 19.508344427971224],
            [101.2820146016517, 19.462584947176765],
            [101.03593143107777, 18.408928330961615],
            [101.05954756063517, 17.51249725999449],
            [102.11359175009248, 18.109101670804165],
            [102.41300499879162, 17.932781683824288],
            [102.9987056823877, 17.9616946476916],
            [103.20019209189373, 18.309632066312773],
            [103.95647667848529, 18.24095408779688],
            [104.7169470560925, 17.42885895433008],
            [104.7793205098688, 16.44186493577145],
            [105.58903852745016, 15.570316066952858],
            [105.54433841351769, 14.723933620660416],
            [105.21877689007887, 14.273211778210694],
            [104.28141808473661, 14.416743068901367],
            [102.98842207236163, 14.225721136934467],
            [102.348099399833, 13.394247341358223],
            [102.5849324890267, 12.186594956913282],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Saudi Arabia",
        sov_a3: "SAU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Saudi Arabia",
        adm0_a3: "SAU",
        geou_dif: 0,
        geounit: "Saudi Arabia",
        gu_a3: "SAU",
        su_dif: 0,
        subunit: "Saudi Arabia",
        su_a3: "SAU",
        brk_diff: 0,
        name: "Saudi Arabia",
        name_long: "Saudi Arabia",
        brk_a3: "SAU",
        brk_name: "Saudi Arabia",
        brk_group: null,
        abbrev: "Saud.",
        postal: "SA",
        formal_en: "Kingdom of Saudi Arabia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Saudi Arabia",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 7,
        pop_est: 28686633,
        gdp_md_est: 576500,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SA",
        iso_a3: "SAU",
        iso_n3: "682",
        un_a3: "682",
        wb_a2: "SA",
        wb_a3: "SAU",
        woe_id: -99,
        adm0_a3_is: "SAU",
        adm0_a3_us: "SAU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "SAU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.77933230975097, 16.347891343648683],
            [42.649572788266084, 16.774635321514964],
            [42.347989129410706, 17.075805568912003],
            [42.270887892431226, 17.474721787989125],
            [41.75438195167396, 17.833046169500975],
            [41.22139122901558, 18.671599636301206],
            [40.93934126156654, 19.486485297111756],
            [40.247652215339826, 20.17463450772649],
            [39.80168460466095, 20.338862209550054],
            [39.139399448408284, 21.29190481209293],
            [39.023695916506796, 21.986875311770195],
            [39.06632897314759, 22.57965566659027],
            [38.49277225114008, 23.688451036060854],
            [38.02386030452362, 24.07868561451293],
            [37.483634881344386, 24.285494696545015],
            [37.154817742671185, 24.85848297779731],
            [37.209491408036, 25.084541530858104],
            [36.93162723160259, 25.602959499610176],
            [36.63960371272122, 25.82622752532722],
            [36.249136590323815, 26.57013560638488],
            [35.64018151219639, 27.37652049408342],
            [35.13018680190788, 28.06335195567472],
            [34.63233605320798, 28.058546047471566],
            [34.787778761541944, 28.6074272730597],
            [34.832220493312946, 28.957483425404845],
            [34.95603722508426, 29.35655467377884],
            [36.06894087092206, 29.197494615184457],
            [36.50121422704358, 29.5052536076987],
            [36.74052778498725, 29.86528331147619],
            [37.503581984209035, 30.003776150018403],
            [37.66811974462638, 30.3386652694859],
            [37.998848911294374, 30.50849986421313],
            [37.00216556168101, 31.508412990844743],
            [39.00488569515255, 32.01021698661498],
            [39.19546837744497, 32.16100881604267],
            [40.399994337736246, 31.889991766887935],
            [41.889980910007836, 31.190008653278365],
            [44.70949873228474, 29.178891099559383],
            [46.568713413281756, 29.09902517345229],
            [47.45982181172283, 29.002519436147224],
            [47.708850538937384, 28.526062730416143],
            [48.416094191283946, 28.55200429942667],
            [48.80759484232718, 27.689627997339883],
            [49.29955447774582, 27.46121816660981],
            [49.470913527225655, 27.10999929453808],
            [50.15242231629088, 26.689663194275997],
            [50.212935418504685, 26.277026882425375],
            [50.11330325704594, 25.943972276304248],
            [50.239858839728754, 25.608049628190926],
            [50.52738650900073, 25.3278083358721],
            [50.66055667501689, 24.99989553476402],
            [50.81010827006958, 24.754742539971378],
            [51.11241539897702, 24.556330878186724],
            [51.38960778179063, 24.62738597258806],
            [51.57951867046327, 24.245497137951105],
            [51.61770755392698, 24.014219265228828],
            [52.000733270074335, 23.00115448657894],
            [55.0068030129249, 22.496947536707136],
            [55.20834109886319, 22.708329982997046],
            [55.666659376859826, 22.00000112557234],
            [54.99998172386236, 19.999994004796108],
            [52.00000980002224, 19.000003363516058],
            [49.11667158386487, 18.616667588774945],
            [48.18334354024134, 18.166669216377315],
            [47.46669477721763, 17.116681626854884],
            [47.000004917189756, 16.949999294497445],
            [46.74999433776165, 17.283338120996177],
            [46.366658563020536, 17.233315334537636],
            [45.39999922056875, 17.333335069238558],
            [45.21665123879718, 17.43332896572333],
            [44.06261315285508, 17.410358791569593],
            [43.79151858905192, 17.31997671149111],
            [43.380794305196105, 17.57998668056767],
            [43.11579756040335, 17.088440456607373],
            [43.21837527850275, 16.66688996018641],
            [42.77933230975097, 16.347891343648683],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Syria",
        sov_a3: "SYR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Syria",
        adm0_a3: "SYR",
        geou_dif: 0,
        geounit: "Syria",
        gu_a3: "SYR",
        su_dif: 0,
        subunit: "Syria",
        su_a3: "SYR",
        brk_diff: 0,
        name: "Syria",
        name_long: "Syria",
        brk_a3: "SYR",
        brk_name: "Syria",
        brk_group: null,
        abbrev: "Syria",
        postal: "SYR",
        formal_en: "Syrian Arab Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Syrian Arab Republic",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 6,
        pop_est: 20178485,
        gdp_md_est: 98830,
        pop_year: -99,
        lastcensus: 2004,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SY",
        iso_a3: "SYR",
        iso_n3: "760",
        un_a3: "760",
        wb_a2: "SY",
        wb_a3: "SYR",
        woe_id: -99,
        adm0_a3_is: "SYR",
        adm0_a3_us: "SYR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "SYR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.792340529136084, 33.378686428352225],
            [36.834062127435544, 32.312937526980775],
            [35.71991824722275, 32.709192409794866],
            [35.700797967274745, 32.71601369885738],
            [35.836396925608625, 32.86812327730851],
            [35.82110070165024, 33.2774264592763],
            [36.066460402172055, 33.82491242119255],
            [36.61175011571589, 34.201788641897174],
            [36.4481942075121, 34.59393524834407],
            [35.99840254084364, 34.644914048800004],
            [35.905023227692226, 35.410009467097325],
            [36.149762811026534, 35.82153473565367],
            [36.417550083163036, 36.04061697035506],
            [36.6853890317318, 36.25969920505646],
            [36.7394942563414, 36.81752045343109],
            [37.06676110204583, 36.62303620050062],
            [38.1677274920242, 36.90121043552777],
            [38.6998913917659, 36.71292735447234],
            [39.52258019385255, 36.71605377862599],
            [40.67325931169569, 37.09127635349729],
            [41.21208947120305, 37.074352321921694],
            [42.34959109881177, 37.2298725449041],
            [41.83706424334096, 36.605853786763575],
            [41.289707472505455, 36.35881460219227],
            [41.383965285005814, 35.628316555314356],
            [41.006158888519934, 34.41937226006212],
            [38.792340529136084, 33.378686428352225],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Tajikistan",
        sov_a3: "TJK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Tajikistan",
        adm0_a3: "TJK",
        geou_dif: 0,
        geounit: "Tajikistan",
        gu_a3: "TJK",
        su_dif: 0,
        subunit: "Tajikistan",
        su_a3: "TJK",
        brk_diff: 0,
        name: "Tajikistan",
        name_long: "Tajikistan",
        brk_a3: "TJK",
        brk_name: "Tajikistan",
        brk_group: null,
        abbrev: "Tjk.",
        postal: "TJ",
        formal_en: "Republic of Tajikistan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Tajikistan",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 7349145,
        gdp_md_est: 13160,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "TJ",
        iso_a3: "TJK",
        iso_n3: "762",
        un_a3: "762",
        wb_a2: "TJ",
        wb_a3: "TJK",
        woe_id: -99,
        adm0_a3_is: "TJK",
        adm0_a3_us: "TJK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "TJK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.01419803252017, 40.24436554621823],
            [70.64801883329997, 39.93575389257117],
            [69.55960981636852, 40.10321137141298],
            [69.46488691597753, 39.5266832545487],
            [70.54916181832562, 39.6041979029865],
            [71.784693637992, 39.27946320246437],
            [73.6753792662548, 39.4312368841056],
            [73.92885216664644, 38.50581533462274],
            [74.25751427602273, 38.60650686294345],
            [74.86481570831681, 38.3788463404816],
            [74.8299857929521, 37.9900070257014],
            [74.98000247589542, 37.419990139305895],
            [73.9486959166465, 37.4215662704908],
            [73.26005577992501, 37.495256862939],
            [72.63688968291729, 37.047558091778356],
            [72.1930408059624, 36.948287665345674],
            [71.8446382994506, 36.73817129164692],
            [71.44869347523024, 37.06564484308051],
            [71.54191775908478, 37.905774441065645],
            [71.23940392444817, 37.95326508234188],
            [71.34813113799026, 38.258905341132156],
            [70.80682050973289, 38.486281643216415],
            [70.3763041523093, 38.13839590102752],
            [70.27057417184014, 37.735164699854025],
            [70.11657840361033, 37.58822276463209],
            [69.51878543485796, 37.60899669041341],
            [69.19627282092438, 37.15114350030743],
            [68.85944583524594, 37.344335842430596],
            [68.13556237170138, 37.0231151393043],
            [67.82999962755952, 37.14499400486468],
            [68.39203250516596, 38.157025254868735],
            [68.17602501818592, 38.901553453113905],
            [67.44221967964131, 39.140143541005486],
            [67.70142866401736, 39.58047842056453],
            [68.53641645698941, 39.53345286717894],
            [69.0116329283455, 40.08615814875666],
            [69.32949466337283, 40.72782440852485],
            [70.66662234892505, 40.96021332454141],
            [70.45815962105962, 40.49649485937029],
            [70.60140669137269, 40.21852733007229],
            [71.01419803252017, 40.24436554621823],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Turkmenistan",
        sov_a3: "TKM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Turkmenistan",
        adm0_a3: "TKM",
        geou_dif: 0,
        geounit: "Turkmenistan",
        gu_a3: "TKM",
        su_dif: 0,
        subunit: "Turkmenistan",
        su_a3: "TKM",
        brk_diff: 0,
        name: "Turkmenistan",
        name_long: "Turkmenistan",
        brk_a3: "TKM",
        brk_name: "Turkmenistan",
        brk_group: null,
        abbrev: "Turkm.",
        postal: "TM",
        formal_en: "Turkmenistan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Turkmenistan",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 9,
        pop_est: 4884887,
        gdp_md_est: 29780,
        pop_year: -99,
        lastcensus: 1995,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "TM",
        iso_a3: "TKM",
        iso_n3: "795",
        un_a3: "795",
        wb_a2: "TM",
        wb_a3: "TKM",
        woe_id: -99,
        adm0_a3_is: "TKM",
        adm0_a3_us: "TKM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 12,
        long_len: 12,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "TKM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [61.21081709172574, 35.650072333309225],
            [61.123070509694145, 36.49159719496624],
            [60.37763797388387, 36.52738312432837],
            [59.23476199731681, 37.412987982730336],
            [58.4361544126782, 37.522309475243794],
            [57.33043379092898, 38.02922943781094],
            [56.61936608259282, 38.121394354803485],
            [56.18037479027333, 37.93512665460742],
            [55.51157840355191, 37.96411713312317],
            [54.800303989486565, 37.392420762678185],
            [53.92159793479556, 37.19891836196126],
            [53.73551110211252, 37.90613617609169],
            [53.880928582581845, 38.95209300389536],
            [53.101027866432894, 39.29057363540713],
            [53.35780805849123, 39.97528636327445],
            [52.69397260926982, 40.03362905533197],
            [52.915251092343624, 40.87652334244473],
            [53.858139275941134, 40.63103445084218],
            [54.73684533063215, 40.95101491959346],
            [54.008310988181314, 41.55121084244742],
            [53.72171349469059, 42.12319143327003],
            [52.916749708880076, 41.86811656347733],
            [52.81468875510361, 41.13537059179471],
            [52.50245975119615, 41.78331553808637],
            [52.944293247291654, 42.11603424739759],
            [54.07941775901495, 42.32410940202083],
            [54.75534549339263, 42.043971462566574],
            [55.45525109235377, 41.25985911718584],
            [55.96819135928291, 41.30864166926936],
            [57.0963912290791, 41.32231008561057],
            [56.932215203687804, 41.826026109375604],
            [57.78652998233708, 42.17055288346552],
            [58.62901085799146, 42.75155101172305],
            [59.97642215356978, 42.22308197689021],
            [60.08334069198167, 41.425146185871405],
            [60.46595299667069, 41.22032664648255],
            [61.54717898951356, 41.266370347654615],
            [61.88271406438469, 41.084856879229406],
            [62.374260288345, 40.05388621679039],
            [63.51801476426103, 39.36325653742564],
            [64.17022301621677, 38.892406724598246],
            [65.2159989765074, 38.40269501398429],
            [66.54615034370022, 37.97468496352687],
            [66.51860680528867, 37.36278432875879],
            [66.21738488145932, 37.39379018813392],
            [65.74563073106681, 37.66116404881207],
            [65.58894778835784, 37.30521678318564],
            [64.7461051776774, 37.111817735333304],
            [64.5464791197339, 36.31207326918427],
            [63.9828959491587, 36.0079574651466],
            [63.19353844590035, 35.857165635718914],
            [62.9846623065766, 35.40404083916762],
            [62.230651483005886, 35.270663967422294],
            [61.21081709172574, 35.650072333309225],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "East Timor",
        sov_a3: "TLS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "East Timor",
        adm0_a3: "TLS",
        geou_dif: 0,
        geounit: "East Timor",
        gu_a3: "TLS",
        su_dif: 0,
        subunit: "East Timor",
        su_a3: "TLS",
        brk_diff: 0,
        name: "Timor-Leste",
        name_long: "Timor-Leste",
        brk_a3: "TLS",
        brk_name: "Timor-Leste",
        brk_group: null,
        abbrev: "T.L.",
        postal: "TL",
        formal_en: "Democratic Republic of Timor-Leste",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Timor-Leste",
        name_alt: "East Timor",
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 3,
        pop_est: 1131612,
        gdp_md_est: 2520,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "TL",
        iso_a3: "TLS",
        iso_n3: "626",
        un_a3: "626",
        wb_a2: "TP",
        wb_a3: "TMP",
        woe_id: -99,
        adm0_a3_is: "TLS",
        adm0_a3_us: "TLS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "TLS.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [124.96868248911622, -8.892790215697083],
            [125.08624637258026, -8.65688730228468],
            [125.94707238169826, -8.432094821815035],
            [126.64470421763855, -8.398246758663852],
            [126.95724328013982, -8.273344821814398],
            [127.33592817597464, -8.397316582882603],
            [126.96799197805655, -8.668256117388893],
            [125.9258850444586, -9.106007175333351],
            [125.08852013560109, -9.393173109579294],
            [125.07001997284061, -9.089987481322872],
            [124.96868248911622, -8.892790215697083],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Turkey",
        sov_a3: "TUR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Turkey",
        adm0_a3: "TUR",
        geou_dif: 0,
        geounit: "Turkey",
        gu_a3: "TUR",
        su_dif: 0,
        subunit: "Turkey",
        su_a3: "TUR",
        brk_diff: 0,
        name: "Turkey",
        name_long: "Turkey",
        brk_a3: "TUR",
        brk_name: "Turkey",
        brk_group: null,
        abbrev: "Tur.",
        postal: "TR",
        formal_en: "Republic of Turkey",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Turkey",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 8,
        mapcolor13: 4,
        pop_est: 76805524,
        gdp_md_est: 902700,
        pop_year: -99,
        lastcensus: 2000,
        gdp_year: -99,
        economy: "4. Emerging region: MIKT",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "TR",
        iso_a3: "TUR",
        iso_n3: "792",
        un_a3: "792",
        wb_a2: "TR",
        wb_a3: "TUR",
        woe_id: -99,
        adm0_a3_is: "TUR",
        adm0_a3_us: "TUR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "TUR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [36.91312706884215, 41.335358384764305],
              [38.34766482926452, 40.94858612727572],
              [39.51260664242025, 41.102762763018575],
              [40.373432651538245, 41.01367259374734],
              [41.554084100110714, 41.53565623632761],
              [42.619548781104555, 41.58317271581993],
              [43.58274580259271, 41.09214325618257],
              [43.7526579119685, 40.74020091405882],
              [43.65643639504096, 40.25356395116617],
              [44.400008579288766, 40.00500031184231],
              [44.793989699082005, 39.713002631177034],
              [44.109225294782355, 39.428136298168056],
              [44.4214026222576, 38.28128123631453],
              [44.22575564960053, 37.97158437758935],
              [44.77269900897775, 37.17044464776845],
              [44.29345177590286, 37.00151439060635],
              [43.94225874204736, 37.256227525372935],
              [42.77912560402186, 37.38526357680581],
              [42.34959109881177, 37.22987254490411],
              [41.21208947120303, 37.07435232192173],
              [40.673259311695716, 37.09127635349736],
              [39.52258019385252, 36.71605377862602],
              [38.69989139176593, 36.71292735447233],
              [38.16772749202416, 36.90121043552779],
              [37.06676110204583, 36.62303620050062],
              [36.739494256341374, 36.817520453431115],
              [36.68538903173183, 36.259699205056506],
              [36.41755008316309, 36.0406169703551],
              [36.14976281102659, 35.82153473565367],
              [35.782084995269855, 36.274995429014915],
              [36.160821567537056, 36.650605577128374],
              [35.55093631362834, 36.56544281671134],
              [34.714553256984374, 36.795532131490916],
              [34.02689497247647, 36.21996002862397],
              [32.5091581560641, 36.1075637883892],
              [31.699595167779567, 36.64427521417261],
              [30.62162479017107, 36.677864895162315],
              [30.39109622571712, 36.26298065850698],
              [29.699975620245567, 36.144357408181],
              [28.73290286633539, 36.67683136651644],
              [27.64118655773737, 36.658822129862756],
              [27.048767937943296, 37.65336090753601],
              [26.318218214633045, 38.208133246405396],
              [26.804700148228733, 38.98576019953356],
              [26.17078535330438, 39.463612168936464],
              [27.280019972449395, 40.42001373957831],
              [28.819977654747216, 40.46001129817221],
              [29.24000369641558, 41.21999074967269],
              [31.145933872204434, 41.08762156835706],
              [32.34797936374579, 41.73626414648464],
              [33.51328291192752, 42.018960069337304],
              [35.16770389175187, 42.04022492122544],
              [36.91312706884215, 41.335358384764305],
            ],
          ],
          [
            [
              [27.192376743282406, 40.690565700842455],
              [26.35800906749779, 40.15199392349649],
              [26.04335127127254, 40.61775360774317],
              [26.056942172965336, 40.82412344010075],
              [26.29460208507569, 40.93626129817417],
              [26.604195590936282, 41.56211456966102],
              [26.117041863720825, 41.82690460872456],
              [27.135739373490505, 42.14148489030131],
              [27.996720411905414, 42.00735871028777],
              [28.115524529744444, 41.622886054036286],
              [28.988442824018787, 41.29993419042819],
              [28.80643842948675, 41.05496206314854],
              [27.61901736828412, 40.99982330989312],
              [27.192376743282406, 40.690565700842455],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Taiwan",
        sov_a3: "TWN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Taiwan",
        adm0_a3: "TWN",
        geou_dif: 0,
        geounit: "Taiwan",
        gu_a3: "TWN",
        su_dif: 0,
        subunit: "Taiwan",
        su_a3: "TWN",
        brk_diff: 1,
        name: "Taiwan",
        name_long: "Taiwan",
        brk_a3: "B77",
        brk_name: "Taiwan",
        brk_group: null,
        abbrev: "Taiwan",
        postal: "TW",
        formal_en: null,
        formal_fr: null,
        note_adm0: null,
        note_brk: "Self admin.; Claimed by China",
        name_sort: "Taiwan",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 22974347,
        gdp_md_est: 712000,
        pop_year: -99,
        lastcensus: -99,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "TW",
        iso_a3: "TWN",
        iso_n3: "158",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -99,
        adm0_a3_is: "TWN",
        adm0_a3_us: "TWN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "TWN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [121.77781782438993, 24.3942735865194],
            [121.17563235889274, 22.790857245367167],
            [120.74707970589621, 21.970571397382113],
            [120.22008344938367, 22.81486094816674],
            [120.1061885926124, 23.556262722258236],
            [120.69467980355225, 24.538450832613737],
            [121.49504438688876, 25.295458889257386],
            [121.95124393116144, 24.997595933527034],
            [121.77781782438993, 24.3942735865194],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Uzbekistan",
        sov_a3: "UZB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Uzbekistan",
        adm0_a3: "UZB",
        geou_dif: 0,
        geounit: "Uzbekistan",
        gu_a3: "UZB",
        su_dif: 0,
        subunit: "Uzbekistan",
        su_a3: "UZB",
        brk_diff: 0,
        name: "Uzbekistan",
        name_long: "Uzbekistan",
        brk_a3: "UZB",
        brk_name: "Uzbekistan",
        brk_group: null,
        abbrev: "Uzb.",
        postal: "UZ",
        formal_en: "Republic of Uzbekistan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Uzbekistan",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 5,
        mapcolor13: 4,
        pop_est: 27606007,
        gdp_md_est: 71670,
        pop_year: -99,
        lastcensus: 1989,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "UZ",
        iso_a3: "UZB",
        iso_n3: "860",
        un_a3: "860",
        wb_a2: "UZ",
        wb_a3: "UZB",
        woe_id: -99,
        adm0_a3_is: "UZB",
        adm0_a3_us: "UZB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Central Asia",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: 5,
        homepart: 1,
        filename: "UZB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.51860680528867, 37.36278432875879],
            [66.54615034370022, 37.97468496352687],
            [65.2159989765074, 38.40269501398429],
            [64.17022301621677, 38.892406724598246],
            [63.51801476426103, 39.36325653742564],
            [62.374260288345, 40.05388621679039],
            [61.88271406438469, 41.084856879229406],
            [61.54717898951356, 41.266370347654615],
            [60.46595299667069, 41.22032664648255],
            [60.08334069198167, 41.425146185871405],
            [59.97642215356978, 42.22308197689021],
            [58.62901085799146, 42.75155101172305],
            [57.78652998233708, 42.17055288346552],
            [56.932215203687804, 41.826026109375604],
            [57.0963912290791, 41.32231008561057],
            [55.96819135928291, 41.30864166926936],
            [55.928917270741096, 44.99585846615911],
            [58.50312706892847, 45.586804307632825],
            [58.689989048095896, 45.50001373959862],
            [60.23997195825833, 44.78403677019473],
            [61.05831994003245, 44.40581696225051],
            [62.01330040878625, 43.50447663021565],
            [63.18578698105657, 43.650074978198006],
            [64.90082441595928, 43.72808055274258],
            [66.09801232286509, 42.99766002051309],
            [66.02339155463562, 41.99464630794398],
            [66.51064863471572, 41.987644151368436],
            [66.7140470722165, 41.1684435084615],
            [67.98585574735182, 41.13599070898222],
            [68.25989586779562, 40.6623245305949],
            [68.63248294462001, 40.66868073176681],
            [69.07002729683532, 41.38424428971237],
            [70.3889648782208, 42.08130768489745],
            [70.96231489449914, 42.26615428320549],
            [71.25924767444822, 42.16771067968946],
            [70.42002241402821, 41.51999827734314],
            [71.1578585142916, 41.14358714452912],
            [71.87011478057047, 41.392900092121266],
            [73.05541710804917, 40.866033026689465],
            [71.77487511585656, 40.14584442805378],
            [71.01419803252017, 40.24436554621823],
            [70.60140669137269, 40.21852733007229],
            [70.45815962105962, 40.49649485937029],
            [70.66662234892505, 40.96021332454141],
            [69.32949466337283, 40.72782440852485],
            [69.0116329283455, 40.08615814875666],
            [68.53641645698941, 39.53345286717894],
            [67.70142866401736, 39.58047842056453],
            [67.44221967964131, 39.140143541005486],
            [68.17602501818592, 38.901553453113905],
            [68.39203250516596, 38.157025254868735],
            [67.82999962755952, 37.14499400486468],
            [67.07578209825962, 37.35614390720929],
            [66.51860680528867, 37.36278432875879],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Vietnam",
        sov_a3: "VNM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Vietnam",
        adm0_a3: "VNM",
        geou_dif: 0,
        geounit: "Vietnam",
        gu_a3: "VNM",
        su_dif: 0,
        subunit: "Vietnam",
        su_a3: "VNM",
        brk_diff: 0,
        name: "Vietnam",
        name_long: "Vietnam",
        brk_a3: "VNM",
        brk_name: "Vietnam",
        brk_group: null,
        abbrev: "Viet.",
        postal: "VN",
        formal_en: "Socialist Republic of Vietnam",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Vietnam",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 4,
        pop_est: 86967524,
        gdp_md_est: 241700,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "VN",
        iso_a3: "VNM",
        iso_n3: "704",
        un_a3: "704",
        wb_a2: "VN",
        wb_a3: "VNM",
        woe_id: -99,
        adm0_a3_is: "VNM",
        adm0_a3_us: "VNM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "South-Eastern Asia",
        region_wb: "East Asia & Pacific",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: 2,
        homepart: 1,
        filename: "VNM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [108.05018029178294, 21.55237986906012],
            [106.7150679870901, 20.69685069425202],
            [105.88168216351903, 19.752050482659698],
            [105.66200564984631, 19.05816518806057],
            [106.426816847766, 18.004120998603227],
            [107.36195356651974, 16.69745656988705],
            [108.26949507042963, 16.07974233648615],
            [108.87710656131748, 15.27669057867044],
            [109.33526981001721, 13.426028347217722],
            [109.20013593957398, 11.666859239137764],
            [108.36612999881545, 11.008320624226272],
            [107.22092858279524, 10.364483954301832],
            [106.40511274620343, 9.53083974856932],
            [105.15826378786511, 8.599759629750494],
            [104.79518517458237, 9.241038316276502],
            [105.0762016133856, 9.918490505406808],
            [104.33433475140347, 10.48654368737523],
            [105.19991499229235, 10.889309800658097],
            [106.24967003786946, 10.961811835163587],
            [105.81052371625313, 11.567614650921227],
            [107.49140302941089, 12.337205918827946],
            [107.61454796756243, 13.535530707244206],
            [107.38272749230109, 14.202440904186972],
            [107.5645251811039, 15.202173163305558],
            [107.3127059265456, 15.90853831630318],
            [106.55600792849569, 16.604283962464805],
            [105.92576216026403, 17.48531545660896],
            [105.09459842328152, 18.66697459561108],
            [103.8965320170267, 19.265180975821806],
            [104.18338789267894, 19.62466807706022],
            [104.8225736836971, 19.886641750563882],
            [104.43500044150805, 20.75873322192153],
            [103.20386111858645, 20.766562201413745],
            [102.75489627483466, 21.675137233969465],
            [102.17043582561358, 22.464753119389304],
            [102.7069922221001, 22.708795070887675],
            [103.50451460166056, 22.70375661873921],
            [104.47685835166448, 22.819150092046968],
            [105.32920942588663, 23.352063300056912],
            [105.81124718630521, 22.976892401617903],
            [106.72540327354845, 22.79426788989842],
            [106.56727339073532, 22.21820486092477],
            [107.04342003787262, 21.811898912029914],
            [108.05018029178294, 21.55237986906012],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Yemen",
        sov_a3: "YEM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Yemen",
        adm0_a3: "YEM",
        geou_dif: 0,
        geounit: "Yemen",
        gu_a3: "YEM",
        su_dif: 0,
        subunit: "Yemen",
        su_a3: "YEM",
        brk_diff: 0,
        name: "Yemen",
        name_long: "Yemen",
        brk_a3: "YEM",
        brk_name: "Yemen",
        brk_group: null,
        abbrev: "Yem.",
        postal: "YE",
        formal_en: "Republic of Yemen",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Yemen, Rep.",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 11,
        pop_est: 23822783,
        gdp_md_est: 55280,
        pop_year: -99,
        lastcensus: 2004,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "YE",
        iso_a3: "YEM",
        iso_n3: "887",
        un_a3: "887",
        wb_a2: "RY",
        wb_a3: "YEM",
        woe_id: -99,
        adm0_a3_is: "YEM",
        adm0_a3_us: "YEM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Asia",
        region_un: "Asia",
        subregion: "Western Asia",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "YEM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [53.10857262554751, 16.65105113368895],
            [52.38520592632588, 16.382411200419654],
            [52.19172936382509, 15.93843313238402],
            [52.1681649107, 15.597420355689948],
            [51.172515089732485, 15.175249742081492],
            [49.57457645040315, 14.70876658778275],
            [48.67923058451416, 14.00320241948566],
            [48.23894738138742, 13.948089504446372],
            [47.938914015500785, 14.007233181204427],
            [47.354453566279716, 13.592219753468383],
            [46.717076450391744, 13.39969920496502],
            [45.87759280781027, 13.347764390511685],
            [45.62505008319987, 13.290946153206763],
            [45.406458774605255, 13.026905422411433],
            [45.14435591002086, 12.95393830001531],
            [44.98953331887441, 12.699586900274708],
            [44.49457645038285, 12.721652736863346],
            [44.175112745954486, 12.585950425664876],
            [43.48295861183713, 12.636800035040084],
            [43.22287112811213, 13.220950425667425],
            [43.25144819516953, 13.767583726450852],
            [43.08794396339806, 14.06263031662131],
            [42.89224531430872, 14.802249253798749],
            [42.60487267433362, 15.213335272680595],
            [42.80501549660005, 15.261962795467255],
            [42.70243777850066, 15.718885809791999],
            [42.823670688657415, 15.911742255105265],
            [42.77933230975097, 16.347891343648683],
            [43.21837527850275, 16.66688996018641],
            [43.11579756040335, 17.088440456607373],
            [43.380794305196105, 17.57998668056767],
            [43.79151858905192, 17.31997671149111],
            [44.06261315285508, 17.410358791569593],
            [45.21665123879718, 17.43332896572333],
            [45.39999922056875, 17.333335069238558],
            [46.366658563020536, 17.233315334537636],
            [46.74999433776165, 17.283338120996177],
            [47.000004917189756, 16.949999294497445],
            [47.46669477721763, 17.116681626854884],
            [48.18334354024134, 18.166669216377315],
            [49.11667158386487, 18.616667588774945],
            [52.00000980002224, 19.000003363516058],
            [52.78218427919205, 17.349742336491232],
            [53.10857262554751, 16.65105113368895],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Angola",
        sov_a3: "AGO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Angola",
        adm0_a3: "AGO",
        geou_dif: 0,
        geounit: "Angola",
        gu_a3: "AGO",
        su_dif: 0,
        subunit: "Angola",
        su_a3: "AGO",
        brk_diff: 0,
        name: "Angola",
        name_long: "Angola",
        brk_a3: "AGO",
        brk_name: "Angola",
        brk_group: null,
        abbrev: "Ang.",
        postal: "AO",
        formal_en: "People's Republic of Angola",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Angola",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 6,
        mapcolor13: 1,
        pop_est: 12799293,
        gdp_md_est: 110300,
        pop_year: -99,
        lastcensus: 1970,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "AO",
        iso_a3: "AGO",
        iso_n3: "024",
        un_a3: "024",
        wb_a2: "AO",
        wb_a3: "AGO",
        woe_id: -99,
        adm0_a3_is: "AGO",
        adm0_a3_us: "AGO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "AGO.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [16.326528354567046, -5.877470391466218],
              [16.57317996589614, -6.622644545115094],
              [16.86019087084523, -7.222297865429979],
              [17.08999596524717, -7.545688978712476],
              [17.472970004962292, -8.068551120641656],
              [18.13422163256905, -7.987677504104866],
              [18.464175652752687, -7.847014255406477],
              [19.01675174324967, -7.98824594486014],
              [19.166613396896082, -7.738183688999725],
              [19.417502475673217, -7.155428562044278],
              [20.037723016040218, -7.11636117923166],
              [20.09162153492062, -6.943090101756951],
              [20.601822950938327, -6.939317722199689],
              [20.51474816252653, -7.299605808138665],
              [21.728110792739756, -7.290872491081316],
              [21.746455926203367, -7.920084730667114],
              [21.94913089365204, -8.305900974158305],
              [21.801801385187957, -8.908706556842986],
              [21.875181919042404, -9.523707777548566],
              [22.208753289486424, -9.89479623783653],
              [22.155268182064333, -11.084801120653779],
              [22.402798292742432, -10.99307545333569],
              [22.83734541188477, -11.017621758674338],
              [23.456790805767465, -10.867863457892483],
              [23.912215203555746, -10.926826267137542],
              [24.017893507592614, -11.237298272347118],
              [23.90415368011824, -11.722281589406336],
              [24.079905226342902, -12.191296888887308],
              [23.930922072045377, -12.565847670138822],
              [24.016136508894704, -12.911046237848552],
              [21.933886346125945, -12.898437188369357],
              [21.887842644953878, -16.080310153876894],
              [22.56247846852429, -16.898451429921835],
              [23.215048455506093, -17.523116143465952],
              [21.377176141045595, -17.93063648851971],
              [18.95618696460363, -17.789094740472237],
              [18.26330936043422, -17.309950860262006],
              [14.209706658595051, -17.353100681225712],
              [14.058501417709039, -17.423380629142656],
              [13.462362094789967, -16.97121184658874],
              [12.814081251688407, -16.941342868724078],
              [12.215461460019384, -17.111668389558062],
              [11.734198846085148, -17.3018893368245],
              [11.64009606288161, -16.67314218512921],
              [11.778537224991567, -15.79381601325069],
              [12.123580763404448, -14.878316338767931],
              [12.175618930722266, -14.449143568583892],
              [12.500095249083017, -13.547699883684402],
              [12.738478631245442, -13.137905775609935],
              [13.312913852601838, -12.483630466362513],
              [13.633721144269828, -12.038644707897191],
              [13.738727654686926, -11.297863050993143],
              [13.686379428775297, -10.731075941615842],
              [13.38732791510216, -10.373578383020728],
              [13.120987583069875, -9.766897067914115],
              [12.875369500386569, -9.166933689005488],
              [12.929061313537801, -8.959091078327575],
              [13.236432732809874, -8.562629489784342],
              [12.933040398824316, -7.596538588087753],
              [12.72829837408392, -6.927122084178805],
              [12.227347039446443, -6.294447523629372],
              [12.322431674863566, -6.100092461779653],
              [12.735171339578699, -5.965682061388478],
              [13.02486941900699, -5.984388929878108],
              [13.375597364971895, -5.864241224799557],
              [16.326528354567046, -5.877470391466218],
            ],
          ],
          [
            [
              [12.436688266660923, -5.684303887559224],
              [12.18233686692028, -5.789930515163803],
              [11.914963006242116, -5.037986748884734],
              [12.318607618873926, -4.606230157086158],
              [12.62075971848455, -4.438023369976121],
              [12.995517205465205, -4.781103203961919],
              [12.631611769265845, -4.991271254092936],
              [12.468004184629763, -5.248361504744992],
              [12.436688266660923, -5.684303887559224],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Burundi",
        sov_a3: "BDI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Burundi",
        adm0_a3: "BDI",
        geou_dif: 0,
        geounit: "Burundi",
        gu_a3: "BDI",
        su_dif: 0,
        subunit: "Burundi",
        su_a3: "BDI",
        brk_diff: 0,
        name: "Burundi",
        name_long: "Burundi",
        brk_a3: "BDI",
        brk_name: "Burundi",
        brk_group: null,
        abbrev: "Bur.",
        postal: "BI",
        formal_en: "Republic of Burundi",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Burundi",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 8,
        pop_est: 8988091,
        gdp_md_est: 3102,
        pop_year: -99,
        lastcensus: 2008,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BI",
        iso_a3: "BDI",
        iso_n3: "108",
        un_a3: "108",
        wb_a2: "BI",
        wb_a3: "BDI",
        woe_id: -99,
        adm0_a3_is: "BDI",
        adm0_a3_us: "BDI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "BDI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.339997592900346, -4.499983412294092],
            [29.276383904749053, -3.293907159034063],
            [29.024926385216787, -2.839257907730158],
            [29.632176141078588, -2.917857761246097],
            [29.938359002407942, -2.348486830254238],
            [30.469696079232985, -2.413857517103458],
            [30.527677036264464, -2.807631931167535],
            [30.7430127296247, -3.034284763199686],
            [30.75226281100495, -3.35932952231557],
            [30.505559523243566, -3.568567396665365],
            [30.116332635221173, -4.090137627787243],
            [29.753512404099922, -4.452389418153281],
            [29.339997592900346, -4.499983412294092],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Benin",
        sov_a3: "BEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Benin",
        adm0_a3: "BEN",
        geou_dif: 0,
        geounit: "Benin",
        gu_a3: "BEN",
        su_dif: 0,
        subunit: "Benin",
        su_a3: "BEN",
        brk_diff: 0,
        name: "Benin",
        name_long: "Benin",
        brk_a3: "BEN",
        brk_name: "Benin",
        brk_group: null,
        abbrev: "Benin",
        postal: "BJ",
        formal_en: "Republic of Benin",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Benin",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 8791832,
        gdp_md_est: 12830,
        pop_year: -99,
        lastcensus: 2002,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BJ",
        iso_a3: "BEN",
        iso_n3: "204",
        un_a3: "204",
        wb_a2: "BJ",
        wb_a3: "BEN",
        woe_id: -99,
        adm0_a3_is: "BEN",
        adm0_a3_us: "BEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "BEN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [2.691701694356254, 6.258817246928629],
            [1.865240512712319, 6.142157701029731],
            [1.618950636409238, 6.832038072126237],
            [1.664477573258381, 9.12859039960938],
            [1.46304284018467, 9.334624335157088],
            [1.425060662450136, 9.825395412633],
            [1.077795037448738, 10.175606594275024],
            [0.772335646171484, 10.470808213742359],
            [0.899563022474069, 10.99733938236426],
            [1.243469679376489, 11.110510769083461],
            [1.447178175471066, 11.547719224488858],
            [1.935985548519881, 11.641150214072553],
            [2.154473504249921, 11.940150051313337],
            [2.49016360841793, 12.233052069543675],
            [2.848643019226671, 12.235635891158267],
            [3.611180454125559, 11.660167141155966],
            [3.572216424177469, 11.327939357951518],
            [3.797112257511714, 10.734745591673105],
            [3.600070021182801, 10.332186184119408],
            [3.705438266625919, 10.063210354040208],
            [3.220351596702101, 9.4441525333997],
            [2.912308383810256, 9.13760793704432],
            [2.723792758809509, 8.50684540448971],
            [2.74906253420022, 7.870734361192888],
            [2.691701694356254, 6.258817246928629],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Burkina Faso",
        sov_a3: "BFA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Burkina Faso",
        adm0_a3: "BFA",
        geou_dif: 0,
        geounit: "Burkina Faso",
        gu_a3: "BFA",
        su_dif: 0,
        subunit: "Burkina Faso",
        su_a3: "BFA",
        brk_diff: 0,
        name: "Burkina Faso",
        name_long: "Burkina Faso",
        brk_a3: "BFA",
        brk_name: "Burkina Faso",
        brk_group: null,
        abbrev: "B.F.",
        postal: "BF",
        formal_en: "Burkina Faso",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Burkina Faso",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 5,
        mapcolor13: 11,
        pop_est: 15746232,
        gdp_md_est: 17820,
        pop_year: -99,
        lastcensus: 2006,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BF",
        iso_a3: "BFA",
        iso_n3: "854",
        un_a3: "854",
        wb_a2: "BF",
        wb_a3: "BFA",
        woe_id: -99,
        adm0_a3_is: "BFA",
        adm0_a3_us: "BFA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "BFA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.827496303712707, 9.642460842319778],
            [-3.511898972986273, 9.90032623945622],
            [-3.980449184576685, 9.8623440617217],
            [-4.330246954760383, 9.61083486575714],
            [-4.779883592131966, 9.821984768101743],
            [-4.954653286143099, 10.152713934769736],
            [-5.404341599946974, 10.370736802609146],
            [-5.470564947929006, 10.951269842976048],
            [-5.197842576508648, 11.37514577885014],
            [-5.220941941743121, 11.713858954307227],
            [-4.427166103523803, 12.542645575404295],
            [-4.28040503581488, 13.228443508349741],
            [-4.006390753587226, 13.472485459848116],
            [-3.522802700199861, 13.337661647998615],
            [-3.10370683431276, 13.541266791228594],
            [-2.967694464520577, 13.79815033615151],
            [-2.191824510090385, 14.246417548067356],
            [-2.001035122068771, 14.559008287000891],
            [-1.066363491205664, 14.973815009007765],
            [-0.515854458000348, 15.116157741755726],
            [-0.26625729003058, 14.924308986872148],
            [0.374892205414682, 14.928908189346132],
            [0.295646396495101, 14.444234930880654],
            [0.429927605805517, 13.988733018443924],
            [0.993045688490071, 13.335749620003824],
            [1.024103224297477, 12.851825669806574],
            [2.177107781593776, 12.625017808477534],
            [2.154473504249921, 11.940150051313337],
            [1.935985548519881, 11.641150214072553],
            [1.447178175471066, 11.547719224488858],
            [1.243469679376489, 11.110510769083461],
            [0.899563022474069, 10.99733938236426],
            [0.023802524423701, 11.018681748900804],
            [-0.438701544588582, 11.09834096927872],
            [-0.761575893548183, 10.936929633015055],
            [-1.203357713211431, 11.009819240762738],
            [-2.940409308270461, 10.962690334512558],
            [-2.963896246747112, 10.395334784380083],
            [-2.827496303712707, 9.642460842319778],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Botswana",
        sov_a3: "BWA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Botswana",
        adm0_a3: "BWA",
        geou_dif: 0,
        geounit: "Botswana",
        gu_a3: "BWA",
        su_dif: 0,
        subunit: "Botswana",
        su_a3: "BWA",
        brk_diff: 0,
        name: "Botswana",
        name_long: "Botswana",
        brk_a3: "BWA",
        brk_name: "Botswana",
        brk_group: null,
        abbrev: "Bwa.",
        postal: "BW",
        formal_en: "Republic of Botswana",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Botswana",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 5,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 1990876,
        gdp_md_est: 27060,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BW",
        iso_a3: "BWA",
        iso_n3: "072",
        un_a3: "072",
        wb_a2: "BW",
        wb_a3: "BWA",
        woe_id: -99,
        adm0_a3_is: "BWA",
        adm0_a3_us: "BWA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "BWA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.649163445750162, -18.53602589281899],
            [25.85039147309473, -18.714412937090536],
            [26.164790887158485, -19.29308562589494],
            [27.296504754350508, -20.391519870691],
            [27.724747348753255, -20.499058526290387],
            [27.72722781750326, -20.851801853114715],
            [28.021370070108617, -21.485975030200585],
            [28.794656202924212, -21.63945403410745],
            [29.43218834810904, -22.091312758067588],
            [28.01723595552525, -22.827753594659075],
            [27.119409620886245, -23.574323011979775],
            [26.786406691197413, -24.240690606383485],
            [26.4857532081233, -24.616326592713104],
            [25.94165205252216, -24.69637338633322],
            [25.76584882986521, -25.174845472923675],
            [25.66466637543772, -25.486816094669713],
            [25.025170525825786, -25.7196700985769],
            [24.211266717228792, -25.670215752873574],
            [23.73356977712271, -25.390129489851613],
            [23.312096795350186, -25.26868987396572],
            [22.8242712745149, -25.500458672794768],
            [22.57953169118059, -25.979447523708146],
            [22.105968865657868, -26.280256036079138],
            [21.60589603036939, -26.726533705351756],
            [20.88960900237174, -26.828542982695915],
            [20.66647016773544, -26.477453301704923],
            [20.758609246511835, -25.86813648855145],
            [20.165725538827186, -24.917961928000768],
            [19.895767856534434, -24.767790215760588],
            [19.89545779794068, -21.84915699634787],
            [20.881134067475866, -21.814327080983148],
            [20.910641310314535, -18.252218926672022],
            [21.655040317478978, -18.219146010005225],
            [23.1968583513393, -17.869038181227786],
            [23.579005568137717, -18.28126108162006],
            [24.217364536239213, -17.88934701911849],
            [24.520705193792537, -17.887124932529936],
            [25.08444339366457, -17.661815687737374],
            [25.264225701608012, -17.736539808831417],
            [25.649163445750162, -18.53602589281899],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Central African Republic",
        sov_a3: "CAF",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Central African Republic",
        adm0_a3: "CAF",
        geou_dif: 0,
        geounit: "Central African Republic",
        gu_a3: "CAF",
        su_dif: 0,
        subunit: "Central African Republic",
        su_a3: "CAF",
        brk_diff: 0,
        name: "Central African Rep.",
        name_long: "Central African Republic",
        brk_a3: "CAF",
        brk_name: "Central African Rep.",
        brk_group: null,
        abbrev: "C.A.R.",
        postal: "CF",
        formal_en: "Central African Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Central African Republic",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 9,
        pop_est: 4511488,
        gdp_md_est: 3198,
        pop_year: -99,
        lastcensus: 2003,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CF",
        iso_a3: "CAF",
        iso_n3: "140",
        un_a3: "140",
        wb_a2: "CF",
        wb_a3: "CAF",
        woe_id: -99,
        adm0_a3_is: "CAF",
        adm0_a3_us: "CAF",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 20,
        long_len: 24,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "CAF.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.279460483469109, 7.421924546737969],
            [16.10623172370677, 7.497087917506505],
            [16.290561557691888, 7.754307359239306],
            [16.456184523187346, 7.734773667832968],
            [16.705988396886255, 7.508327541529979],
            [17.964929640380888, 7.890914008002866],
            [18.38955488452322, 8.281303615751824],
            [18.911021762780507, 8.630894680206353],
            [18.81200971850927, 8.982914536978598],
            [19.09400800952602, 9.07484691002584],
            [20.05968549976427, 9.012706000194854],
            [21.000868361096167, 9.475985215691509],
            [21.723821648859452, 10.567055568885976],
            [22.231129184668788, 10.97188873946051],
            [22.864165480244225, 11.142395127807546],
            [22.97754357269261, 10.71446259199854],
            [23.554304233502194, 10.089255275915308],
            [23.557249790142826, 9.681218166538684],
            [23.394779087017184, 9.265067857292223],
            [23.459012892355986, 8.954285793488893],
            [23.805813429466752, 8.666318874542426],
            [24.567369012152085, 8.229187933785468],
            [25.11493248871679, 7.825104071479174],
            [25.124130893664727, 7.500085150579436],
            [25.79664798351118, 6.979315904158071],
            [26.213418409945117, 6.546603298362072],
            [26.46590945812323, 5.94671743410187],
            [27.21340905122517, 5.550953477394557],
            [27.37422610851749, 5.233944403500061],
            [27.04406538260471, 5.127852688004836],
            [26.402760857862543, 5.150874538590871],
            [25.650455356557472, 5.256087754737123],
            [25.278798455514302, 5.170408229997192],
            [25.12883344900328, 4.927244777847789],
            [24.805028924262416, 4.89724660890235],
            [24.410531040146253, 5.108784084489129],
            [23.29721398285014, 4.609693101414223],
            [22.841479526468106, 4.710126247573484],
            [22.70412356943629, 4.633050848810157],
            [22.405123732195538, 4.029160061047321],
            [21.659122755630023, 4.22434194581372],
            [20.927591180106276, 4.322785549329737],
            [20.290679152108936, 4.691677761245288],
            [19.467783644293146, 5.03152781821278],
            [18.93231245288476, 4.709506130385975],
            [18.54298221199778, 4.201785183118318],
            [18.45306521980993, 3.504385891123349],
            [17.809900343505262, 3.56019643799857],
            [17.133042433346304, 3.728196519379452],
            [16.537058139724135, 3.198254706226279],
            [16.012852410555354, 2.267639675298085],
            [15.907380812247652, 2.557389431158612],
            [15.86273237474748, 3.013537298998983],
            [15.405395948964383, 3.33530060466434],
            [15.036219516671252, 3.851367295747124],
            [14.950953403389661, 4.210389309094921],
            [14.478372430080467, 4.732605495620447],
            [14.558935988023505, 5.03059764243153],
            [14.459407179429348, 5.4517605656103],
            [14.536560092841112, 6.22695872642069],
            [14.776545444404576, 6.408498033062045],
            [15.279460483469109, 7.421924546737969],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Ivory Coast",
        sov_a3: "CIV",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Ivory Coast",
        adm0_a3: "CIV",
        geou_dif: 0,
        geounit: "Ivory Coast",
        gu_a3: "CIV",
        su_dif: 0,
        subunit: "Ivory Coast",
        su_a3: "CIV",
        brk_diff: 0,
        name: "Côte d'Ivoire",
        name_long: "Côte d'Ivoire",
        brk_a3: "CIV",
        brk_name: "Côte d'Ivoire",
        brk_group: null,
        abbrev: "I.C.",
        postal: "CI",
        formal_en: "Republic of Ivory Coast",
        formal_fr: "Republic of Cote D'Ivoire",
        note_adm0: null,
        note_brk: null,
        name_sort: "Côte d'Ivoire",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 3,
        mapcolor13: 3,
        pop_est: 20617068,
        gdp_md_est: 33850,
        pop_year: -99,
        lastcensus: 1998,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CI",
        iso_a3: "CIV",
        iso_n3: "384",
        un_a3: "384",
        wb_a2: "CI",
        wb_a3: "CIV",
        woe_id: -99,
        adm0_a3_is: "CIV",
        adm0_a3_us: "CIV",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 13,
        long_len: 13,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "CIV.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.856125047202397, 4.994475816259509],
            [-3.311084357100071, 4.984295559098015],
            [-4.008819545904942, 5.179813340674315],
            [-4.649917364917911, 5.168263658057086],
            [-5.834496222344526, 4.993700669775137],
            [-6.528769090185847, 4.705087795425015],
            [-7.518941209330436, 4.338288479017308],
            [-7.71215938966975, 4.364565944837722],
            [-7.63536821128403, 5.188159084489456],
            [-7.539715135111762, 5.313345241716519],
            [-7.570152553731688, 5.707352199725904],
            [-7.993692592795881, 6.126189683451543],
            [-8.311347622094019, 6.193033148621083],
            [-8.60288021486862, 6.46756419517166],
            [-8.385451626000574, 6.911800645368742],
            [-8.48544552248535, 7.39520783124307],
            [-8.439298468448698, 7.686042792181738],
            [-8.280703497744938, 7.687179673692156],
            [-8.221792364932199, 8.123328762235573],
            [-8.299048631208564, 8.316443589710303],
            [-8.20349890790088, 8.455453192575447],
            [-7.832100389019188, 8.575704250518626],
            [-8.079113735374348, 9.376223863152035],
            [-8.30961646161225, 9.789531968622441],
            [-8.229337124046822, 10.1290202905639],
            [-8.029943610048619, 10.206534939001713],
            [-7.899589809592372, 10.297382106970828],
            [-7.622759161804809, 10.147236232946796],
            [-6.850506557635057, 10.138993841996239],
            [-6.666460944027548, 10.430810655148447],
            [-6.493965013037267, 10.411302801958271],
            [-6.205222947606431, 10.524060777219134],
            [-6.050452032892267, 10.096360785355444],
            [-5.816926235365287, 10.222554633012194],
            [-5.404341599946974, 10.370736802609146],
            [-4.954653286143099, 10.152713934769736],
            [-4.779883592131966, 9.821984768101743],
            [-4.330246954760383, 9.61083486575714],
            [-3.980449184576685, 9.8623440617217],
            [-3.511898972986273, 9.90032623945622],
            [-2.827496303712707, 9.642460842319778],
            [-2.562189500326241, 8.219627793811483],
            [-2.983584967450327, 7.379704901555512],
            [-3.244370083011262, 6.250471503113502],
            [-2.81070146321784, 5.38905121502411],
            [-2.856125047202397, 4.994475816259509],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Republic of Congo",
        sov_a3: "COG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Republic of Congo",
        adm0_a3: "COG",
        geou_dif: 0,
        geounit: "Republic of Congo",
        gu_a3: "COG",
        su_dif: 0,
        subunit: "Republic of Congo",
        su_a3: "COG",
        brk_diff: 0,
        name: "Congo",
        name_long: "Republic of Congo",
        brk_a3: "COG",
        brk_name: "Republic of Congo",
        brk_group: null,
        abbrev: "Rep. Congo",
        postal: "CG",
        formal_en: "Republic of Congo",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Congo, Rep.",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 10,
        pop_est: 4012809,
        gdp_md_est: 15350,
        pop_year: -99,
        lastcensus: 2007,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CG",
        iso_a3: "COG",
        iso_n3: "178",
        un_a3: "178",
        wb_a2: "CG",
        wb_a3: "COG",
        woe_id: -99,
        adm0_a3_is: "COG",
        adm0_a3_us: "COG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 17,
        abbrev_len: 10,
        tiny: -99,
        homepart: 1,
        filename: "COG.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.995517205465177, -4.781103203961884],
            [12.620759718484491, -4.438023369976136],
            [12.318607618873926, -4.606230157086188],
            [11.91496300624209, -5.037986748884791],
            [11.093772820691925, -3.978826592630547],
            [11.855121697648114, -3.426870619321051],
            [11.478038771214303, -2.765618991714241],
            [11.820963575903193, -2.514161472181982],
            [12.495702752338161, -2.391688327650243],
            [12.575284458067642, -1.948511244315135],
            [13.109618767965628, -2.428740329603514],
            [13.99240726080771, -2.4708049454891],
            [14.299210239324564, -1.998275648612214],
            [14.425455763413593, -1.333406670744971],
            [14.316418491277743, -0.552627455247048],
            [13.843320753645655, 0.038757635901149],
            [14.276265903386957, 1.196929836426619],
            [14.026668735417218, 1.395677395021153],
            [13.282631463278818, 1.31418366129688],
            [13.003113641012078, 1.83089630778332],
            [13.075822381246752, 2.267097072759015],
            [14.33781253424658, 2.227874660649491],
            [15.146341993885244, 1.964014797367184],
            [15.940918816805064, 1.727672634280295],
            [16.012852410555354, 2.267639675298085],
            [16.537058139724135, 3.198254706226279],
            [17.133042433346304, 3.728196519379452],
            [17.809900343505262, 3.56019643799857],
            [18.45306521980993, 3.504385891123349],
            [18.39379235197114, 2.90044342692822],
            [18.094275750407434, 2.365721543788055],
            [17.898835483479587, 1.741831976728278],
            [17.774191928791566, 0.855658677571085],
            [17.826540154703252, 0.288923244626105],
            [17.66355268725468, -0.058083998213817],
            [17.638644646889986, -0.424831638189247],
            [17.523716261472856, -0.743830254726987],
            [16.865306837642123, -1.225816338713287],
            [16.407091912510054, -1.740927015798682],
            [15.972803175529151, -2.712392266453612],
            [16.0062895036543, -3.535132744972529],
            [15.75354007331475, -3.855164890156096],
            [15.170991652088444, -4.343507175314301],
            [14.58260379401318, -4.97023894615014],
            [14.209034864975223, -4.793092136253598],
            [14.144956088933299, -4.510008640158715],
            [13.600234816144678, -4.50013844159097],
            [13.258240187237048, -4.882957452009165],
            [12.995517205465177, -4.781103203961884],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Cameroon",
        sov_a3: "CMR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Cameroon",
        adm0_a3: "CMR",
        geou_dif: 0,
        geounit: "Cameroon",
        gu_a3: "CMR",
        su_dif: 0,
        subunit: "Cameroon",
        su_a3: "CMR",
        brk_diff: 0,
        name: "Cameroon",
        name_long: "Cameroon",
        brk_a3: "CMR",
        brk_name: "Cameroon",
        brk_group: null,
        abbrev: "Cam.",
        postal: "CM",
        formal_en: "Republic of Cameroon",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Cameroon",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 3,
        pop_est: 18879301,
        gdp_md_est: 42750,
        pop_year: -99,
        lastcensus: 2005,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CM",
        iso_a3: "CMR",
        iso_n3: "120",
        un_a3: "120",
        wb_a2: "CM",
        wb_a3: "CMR",
        woe_id: -99,
        adm0_a3_is: "CMR",
        adm0_a3_us: "CMR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "CMR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.075822381246752, 2.267097072759015],
            [12.951333855855609, 2.32161570882694],
            [12.359380323952221, 2.19281220133945],
            [11.75166548019979, 2.326757513839993],
            [11.276449008843713, 2.261050930180872],
            [9.649158155972628, 2.283866075037736],
            [9.795195753629457, 3.073404445809117],
            [9.404366896206, 3.734526882335202],
            [8.948115675501072, 3.904128933117136],
            [8.744923943729418, 4.35221527751996],
            [8.488815545290889, 4.495617377129918],
            [8.500287713259695, 4.771982937026849],
            [8.757532993208628, 5.479665839047911],
            [9.233162876023044, 6.444490668153334],
            [9.522705926154401, 6.453482367372117],
            [10.118276808318257, 7.038769639509879],
            [10.497375115611417, 7.055357774275564],
            [11.05878787603035, 6.644426784690594],
            [11.74577436691851, 6.981382961449753],
            [11.839308709366803, 7.397042344589436],
            [12.063946160539558, 7.799808457872302],
            [12.218872104550599, 8.305824082874324],
            [12.753671502339214, 8.717762762888995],
            [12.955467970438974, 9.417771714714704],
            [13.167599724997103, 9.640626328973411],
            [13.308676385153918, 10.160362046748928],
            [13.572949659894562, 10.798565985553566],
            [14.415378859116686, 11.572368882692075],
            [14.468192172918975, 11.904751695193411],
            [14.577177768622533, 12.085360826053503],
            [14.181336297266792, 12.483656927943116],
            [14.213530714584634, 12.802035427293347],
            [14.495787387762844, 12.85939626713733],
            [14.893385857816526, 12.219047756392584],
            [14.9601518083376, 11.555574042197224],
            [14.923564894274959, 10.891325181517473],
            [15.467872755605269, 9.98233673750343],
            [14.909353875394716, 9.99212942142273],
            [14.62720055508106, 9.920919297724538],
            [14.171466098699028, 10.021378282099931],
            [13.954218377344006, 9.549494940626685],
            [14.544466586981768, 8.965861314322268],
            [14.97999555833769, 8.796104234243472],
            [15.120865512765334, 8.382150173369423],
            [15.436091749745769, 7.692812404811973],
            [15.279460483469109, 7.421924546737969],
            [14.776545444404576, 6.408498033062045],
            [14.536560092841112, 6.22695872642069],
            [14.459407179429348, 5.4517605656103],
            [14.558935988023505, 5.03059764243153],
            [14.478372430080467, 4.732605495620447],
            [14.950953403389661, 4.210389309094921],
            [15.036219516671252, 3.851367295747124],
            [15.405395948964383, 3.33530060466434],
            [15.86273237474748, 3.013537298998983],
            [15.907380812247652, 2.557389431158612],
            [16.012852410555354, 2.267639675298085],
            [15.940918816805064, 1.727672634280295],
            [15.146341993885244, 1.964014797367184],
            [14.33781253424658, 2.227874660649491],
            [13.075822381246752, 2.267097072759015],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Democratic Republic of the Congo",
        sov_a3: "COD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Democratic Republic of the Congo",
        adm0_a3: "COD",
        geou_dif: 0,
        geounit: "Democratic Republic of the Congo",
        gu_a3: "COD",
        su_dif: 0,
        subunit: "Democratic Republic of the Congo",
        su_a3: "COD",
        brk_diff: 0,
        name: "Dem. Rep. Congo",
        name_long: "Democratic Republic of the Congo",
        brk_a3: "COD",
        brk_name: "Democratic Republic of the Congo",
        brk_group: null,
        abbrev: "D.R.C.",
        postal: "DRC",
        formal_en: "Democratic Republic of the Congo",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Congo, Dem. Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 7,
        pop_est: 68692542,
        gdp_md_est: 20640,
        pop_year: -99,
        lastcensus: 1984,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CD",
        iso_a3: "COD",
        iso_n3: "180",
        un_a3: "180",
        wb_a2: "ZR",
        wb_a3: "ZAR",
        woe_id: -99,
        adm0_a3_is: "COD",
        adm0_a3_us: "COD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 15,
        long_len: 32,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "COD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.833859897593808, 3.509165961110341],
            [30.773346795380043, 2.339883327642127],
            [31.174149204235814, 2.204465236821264],
            [30.852670118948055, 1.849396470543809],
            [30.468507521290295, 1.58380544677972],
            [30.086153598762703, 1.062312730306289],
            [29.875778842902495, 0.597379868976304],
            [29.819503208136638, -0.205310153813372],
            [29.58783776217217, -0.587405694179481],
            [29.579466180140884, -1.341313164885626],
            [29.29188683443661, -1.620055840667987],
            [29.25483483248334, -2.215109958508911],
            [29.117478875451553, -2.292211195488385],
            [29.024926385216787, -2.839257907730158],
            [29.276383904749053, -3.293907159034063],
            [29.339997592900346, -4.499983412294092],
            [29.519986606572925, -5.419978936386314],
            [29.419992710088167, -5.939998874539433],
            [29.62003217949001, -6.520015150583426],
            [30.199996779101696, -7.079980970898163],
            [30.740015496551788, -8.340007419470915],
            [30.346086053190813, -8.238256524288218],
            [29.002912225060467, -8.407031752153472],
            [28.7348665707625, -8.526559340044578],
            [28.449871046672826, -9.164918308146085],
            [28.67368167492893, -9.605924981324932],
            [28.49606977714177, -10.789883721564044],
            [28.372253045370428, -11.793646742401393],
            [28.642417433392353, -11.971568698782315],
            [29.34154788586909, -12.360743910372413],
            [29.61600141777123, -12.178894545137311],
            [29.69961388521949, -13.257226657771831],
            [28.934285922976837, -13.248958428605135],
            [28.523561639121027, -12.698604424696683],
            [28.155108676879987, -12.272480564017897],
            [27.38879886242378, -12.132747491100666],
            [27.164419793412463, -11.608748467661075],
            [26.553087599399618, -11.924439792532127],
            [25.752309604604733, -11.784965101776358],
            [25.418118116973204, -11.330935967659961],
            [24.78316979340295, -11.238693536018964],
            [24.31451622894795, -11.26282642989927],
            [24.25715538910399, -10.951992689663657],
            [23.912215203555718, -10.926826267137514],
            [23.45679080576744, -10.867863457892483],
            [22.83734541188474, -11.01762175867433],
            [22.402798292742375, -10.99307545333569],
            [22.155268182064308, -11.084801120653772],
            [22.208753289486395, -9.894796237836509],
            [21.875181919042348, -9.523707777548566],
            [21.8018013851879, -8.908706556842978],
            [21.949130893652043, -8.305900974158277],
            [21.74645592620331, -7.920084730667149],
            [21.7281107927397, -7.290872491081302],
            [20.514748162526498, -7.299605808138629],
            [20.6018229509383, -6.939317722199682],
            [20.091621534920648, -6.943090101756993],
            [20.037723016040218, -7.116361179231646],
            [19.417502475673157, -7.155428562044298],
            [19.16661339689611, -7.738183688999754],
            [19.01675174324967, -7.988245944860132],
            [18.464175652752687, -7.847014255406444],
            [18.13422163256905, -7.987677504104922],
            [17.472970004962235, -8.0685511206417],
            [17.08999596524717, -7.545688978712526],
            [16.8601908708452, -7.222297865429986],
            [16.57317996589614, -6.622644545115087],
            [16.326528354567046, -5.877470391466267],
            [13.375597364971895, -5.864241224799549],
            [13.02486941900696, -5.984388929878157],
            [12.735171339578699, -5.965682061388499],
            [12.32243167486351, -6.10009246177966],
            [12.182336866920252, -5.789930515163839],
            [12.436688266660868, -5.684303887559246],
            [12.468004184629736, -5.248361504745005],
            [12.63161176926579, -4.991271254092936],
            [12.995517205465177, -4.781103203961884],
            [13.258240187237048, -4.882957452009165],
            [13.600234816144678, -4.50013844159097],
            [14.144956088933299, -4.510008640158715],
            [14.209034864975223, -4.793092136253598],
            [14.58260379401318, -4.97023894615014],
            [15.170991652088444, -4.343507175314301],
            [15.75354007331475, -3.855164890156096],
            [16.0062895036543, -3.535132744972529],
            [15.972803175529151, -2.712392266453612],
            [16.407091912510054, -1.740927015798682],
            [16.865306837642123, -1.225816338713287],
            [17.523716261472856, -0.743830254726987],
            [17.638644646889986, -0.424831638189247],
            [17.66355268725468, -0.058083998213817],
            [17.826540154703252, 0.288923244626105],
            [17.774191928791566, 0.855658677571085],
            [17.898835483479587, 1.741831976728278],
            [18.094275750407434, 2.365721543788055],
            [18.39379235197114, 2.90044342692822],
            [18.45306521980993, 3.504385891123349],
            [18.54298221199778, 4.201785183118318],
            [18.93231245288476, 4.709506130385975],
            [19.467783644293146, 5.03152781821278],
            [20.290679152108936, 4.691677761245288],
            [20.927591180106276, 4.322785549329737],
            [21.659122755630023, 4.22434194581372],
            [22.405123732195538, 4.029160061047321],
            [22.70412356943629, 4.633050848810157],
            [22.841479526468106, 4.710126247573484],
            [23.29721398285014, 4.609693101414223],
            [24.410531040146253, 5.108784084489129],
            [24.805028924262416, 4.89724660890235],
            [25.12883344900328, 4.927244777847789],
            [25.278798455514302, 5.170408229997192],
            [25.650455356557472, 5.256087754737123],
            [26.402760857862543, 5.150874538590871],
            [27.04406538260471, 5.127852688004836],
            [27.37422610851749, 5.233944403500061],
            [27.979977247842807, 4.408413397637375],
            [28.428993768026913, 4.287154649264494],
            [28.696677687298802, 4.455077215996937],
            [29.1590784034465, 4.389267279473231],
            [29.71599531425602, 4.600804755060025],
            [29.953500197069474, 4.173699042167683],
            [30.833859897593808, 3.509165961110341],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Djibouti",
        sov_a3: "DJI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Djibouti",
        adm0_a3: "DJI",
        geou_dif: 0,
        geounit: "Djibouti",
        gu_a3: "DJI",
        su_dif: 0,
        subunit: "Djibouti",
        su_a3: "DJI",
        brk_diff: 0,
        name: "Djibouti",
        name_long: "Djibouti",
        brk_a3: "DJI",
        brk_name: "Djibouti",
        brk_group: null,
        abbrev: "Dji.",
        postal: "DJ",
        formal_en: "Republic of Djibouti",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Djibouti",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 516055,
        gdp_md_est: 1885,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "DJ",
        iso_a3: "DJI",
        iso_n3: "262",
        un_a3: "262",
        wb_a2: "DJ",
        wb_a3: "DJI",
        woe_id: -99,
        adm0_a3_is: "DJI",
        adm0_a3_us: "DJI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "DJI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.08122602720015, 12.699638576707116],
            [43.31785241066467, 12.390148423711025],
            [43.286381463398925, 11.974928290245884],
            [42.715873650896526, 11.735640570518342],
            [43.14530480324214, 11.462039699748857],
            [42.77685184100096, 10.92687856693442],
            [42.55493000000013, 11.105110000000195],
            [42.31414000000012, 11.0342],
            [41.755570000000205, 11.050910000000101],
            [41.73959000000019, 11.355110000000138],
            [41.66176000000013, 11.6312],
            [42.000000000000114, 12.100000000000136],
            [42.35156000000012, 12.542230000000131],
            [42.77964236834475, 12.455415757695675],
            [43.08122602720015, 12.699638576707116],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Egypt",
        sov_a3: "EGY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Egypt",
        adm0_a3: "EGY",
        geou_dif: 0,
        geounit: "Egypt",
        gu_a3: "EGY",
        su_dif: 0,
        subunit: "Egypt",
        su_a3: "EGY",
        brk_diff: 0,
        name: "Egypt",
        name_long: "Egypt",
        brk_a3: "EGY",
        brk_name: "Egypt",
        brk_group: null,
        abbrev: "Egypt",
        postal: "EG",
        formal_en: "Arab Republic of Egypt",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Egypt, Arab Rep.",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 83082869,
        gdp_md_est: 443700,
        pop_year: -99,
        lastcensus: 2006,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "EG",
        iso_a3: "EGY",
        iso_n3: "818",
        un_a3: "818",
        wb_a2: "EG",
        wb_a3: "EGY",
        woe_id: -99,
        adm0_a3_is: "EGY",
        adm0_a3_us: "EGY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "EGY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.9226, 29.50133],
            [34.64174, 29.09942],
            [34.42655, 28.34399],
            [34.15451, 27.8233],
            [33.92136, 27.6487],
            [33.58811, 27.97136],
            [33.13676, 28.41765],
            [32.42323, 29.85108],
            [32.32046, 29.76043],
            [32.73482, 28.70523],
            [33.34876, 27.69989],
            [34.10455, 26.14227],
            [34.47387, 25.59856],
            [34.79507, 25.03375],
            [35.69241, 23.92671],
            [35.49372, 23.75237],
            [35.52598, 23.10244],
            [36.69069, 22.20485],
            [36.86623, 22],
            [32.9, 22],
            [29.02, 22],
            [25, 22],
            [25, 25.682499996361],
            [25, 29.23865452953346],
            [24.70007, 30.04419],
            [24.95762, 30.6616],
            [24.80287, 31.08929],
            [25.16482, 31.56915],
            [26.49533, 31.58568],
            [27.45762, 31.32126],
            [28.45048, 31.02577],
            [28.91353, 30.87005],
            [29.68342, 31.18686],
            [30.09503, 31.4734],
            [30.97693, 31.55586],
            [31.68796, 31.4296],
            [31.96041, 30.9336],
            [32.19247, 31.26034],
            [32.99392, 31.02407],
            [33.7734, 30.96746],
            [34.26544, 31.21936],
            [34.9226, 29.50133],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Eritrea",
        sov_a3: "ERI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Eritrea",
        adm0_a3: "ERI",
        geou_dif: 0,
        geounit: "Eritrea",
        gu_a3: "ERI",
        su_dif: 0,
        subunit: "Eritrea",
        su_a3: "ERI",
        brk_diff: 0,
        name: "Eritrea",
        name_long: "Eritrea",
        brk_a3: "ERI",
        brk_name: "Eritrea",
        brk_group: null,
        abbrev: "Erit.",
        postal: "ER",
        formal_en: "State of Eritrea",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Eritrea",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 5647168,
        gdp_md_est: 3945,
        pop_year: -99,
        lastcensus: 1984,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "ER",
        iso_a3: "ERI",
        iso_n3: "232",
        un_a3: "232",
        wb_a2: "ER",
        wb_a3: "ERI",
        woe_id: -99,
        adm0_a3_is: "ERI",
        adm0_a3_us: "ERI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "ERI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.35156000000012, 12.542230000000131],
            [42.00975, 12.86582],
            [41.59856, 13.452090000000112],
            [41.15519371924984, 13.773319810435224],
            [40.8966, 14.118640000000141],
            [40.026218702969175, 14.519579169162284],
            [39.34061, 14.53155],
            [39.0994, 14.74064],
            [38.51295, 14.50547],
            [37.90607000000011, 14.959430000000168],
            [37.59377, 14.2131],
            [36.42951, 14.42211],
            [36.32318891779812, 14.82248057704106],
            [36.75386030451858, 16.29187409104429],
            [36.852530000000115, 16.95655],
            [37.16747, 17.263140000000135],
            [37.90400000000011, 17.42754],
            [38.410089959473225, 17.99830739997031],
            [38.990622999840014, 16.84062612555169],
            [39.26611006038803, 15.922723496967249],
            [39.814293654140215, 15.435647284400318],
            [41.17927493669765, 14.491079616753211],
            [41.73495161313235, 13.921036892141558],
            [42.27683068214486, 13.343992010954423],
            [42.58957645037526, 13.000421250861905],
            [43.08122602720015, 12.699638576707116],
            [42.77964236834475, 12.455415757695675],
            [42.35156000000012, 12.542230000000131],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Algeria",
        sov_a3: "DZA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Algeria",
        adm0_a3: "DZA",
        geou_dif: 0,
        geounit: "Algeria",
        gu_a3: "DZA",
        su_dif: 0,
        subunit: "Algeria",
        su_a3: "DZA",
        brk_diff: 0,
        name: "Algeria",
        name_long: "Algeria",
        brk_a3: "DZA",
        brk_name: "Algeria",
        brk_group: null,
        abbrev: "Alg.",
        postal: "DZ",
        formal_en: "People's Democratic Republic of Algeria",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Algeria",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 34178188,
        gdp_md_est: 232900,
        pop_year: -99,
        lastcensus: 2008,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "DZ",
        iso_a3: "DZA",
        iso_n3: "012",
        un_a3: "012",
        wb_a2: "DZ",
        wb_a3: "DZA",
        woe_id: -99,
        adm0_a3_is: "DZA",
        adm0_a3_us: "DZA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "DZA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.999505649471613, 23.47166840259645],
            [8.572893100629784, 21.565660712159143],
            [5.677565952180686, 19.601206976799716],
            [4.267419467800039, 19.155265204337],
            [3.158133172222705, 19.057364203360038],
            [3.1466610042539, 19.69357859952144],
            [2.683588494486429, 19.856230170160114],
            [2.06099083823392, 20.142233384679486],
            [1.823227573259032, 20.610809434486043],
            [-1.550054897457613, 22.792665920497384],
            [-4.92333736817423, 24.974574082941],
            [-8.68439978680905, 27.395744126896005],
            [-8.665124477564191, 27.589479071558227],
            [-8.665589565454809, 27.656425889592356],
            [-8.674116176782974, 28.84128896739658],
            [-7.059227667661928, 29.57922842052453],
            [-6.060632290053774, 29.731699734001694],
            [-5.242129278982787, 30.000443020135588],
            [-4.859646165374471, 30.501187649043842],
            [-3.690441046554696, 30.896951605751152],
            [-3.647497931320146, 31.63729401298067],
            [-3.068980271812648, 31.724497992473214],
            [-2.616604783529567, 32.09434621838615],
            [-1.30789913573787, 32.2628889023061],
            [-1.124551153966308, 32.65152151135713],
            [-1.388049282222568, 32.86401500094131],
            [-1.733454555661467, 33.919712836231994],
            [-1.792985805661687, 34.527918606091205],
            [-2.169913702798624, 35.16839630791668],
            [-1.208602871089056, 35.7148487411871],
            [-0.127454392894606, 35.888662421200806],
            [0.503876580415209, 36.30127289483528],
            [1.466918572606545, 36.605647081034405],
            [3.161698846050825, 36.78390493422522],
            [4.81575809084913, 36.86503693292346],
            [5.320120070017793, 36.71651886651662],
            [6.261819695672613, 37.11065501560674],
            [7.33038496260397, 37.118380642234364],
            [7.737078484741004, 36.885707505840216],
            [8.420964389691676, 36.94642731378316],
            [8.217824334352315, 36.433176988260286],
            [8.376367628623768, 35.47987600355594],
            [8.140981479534304, 34.65514598239379],
            [7.524481642292244, 34.09737641045146],
            [7.612641635782182, 33.34411489514896],
            [8.430472853233368, 32.74833730725595],
            [8.439102817426118, 32.50628489840082],
            [9.055602654668148, 32.10269196220129],
            [9.482139926805274, 30.307556057246188],
            [9.805634392952411, 29.42463837332339],
            [9.859997999723447, 28.959989732371014],
            [9.683884718472767, 28.1441738957792],
            [9.756128370816782, 27.68825857188415],
            [9.629056023811074, 27.14095347748092],
            [9.716285841519749, 26.512206325785698],
            [9.319410841518163, 26.094324856057455],
            [9.910692579801776, 25.36545461679674],
            [9.94826134607797, 24.936953640232517],
            [10.303846876678362, 24.379313259370917],
            [10.771363559622927, 24.56253205006175],
            [11.560669386449005, 24.097909247325518],
            [11.999505649471613, 23.47166840259645],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Ethiopia",
        sov_a3: "ETH",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Ethiopia",
        adm0_a3: "ETH",
        geou_dif: 0,
        geounit: "Ethiopia",
        gu_a3: "ETH",
        su_dif: 0,
        subunit: "Ethiopia",
        su_a3: "ETH",
        brk_diff: 0,
        name: "Ethiopia",
        name_long: "Ethiopia",
        brk_a3: "ETH",
        brk_name: "Ethiopia",
        brk_group: null,
        abbrev: "Eth.",
        postal: "ET",
        formal_en: "Federal Democratic Republic of Ethiopia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Ethiopia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 13,
        pop_est: 85237338,
        gdp_md_est: 68770,
        pop_year: -99,
        lastcensus: 2007,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "ET",
        iso_a3: "ETH",
        iso_n3: "231",
        un_a3: "231",
        wb_a2: "ET",
        wb_a3: "ETH",
        woe_id: -99,
        adm0_a3_is: "ETH",
        adm0_a3_us: "ETH",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "ETH.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.90607000000011, 14.959430000000168],
            [38.51295, 14.50547],
            [39.0994, 14.74064],
            [39.34061, 14.53155],
            [40.02625000000012, 14.51959],
            [40.8966, 14.118640000000141],
            [41.1552, 13.77333],
            [41.59856, 13.452090000000112],
            [42.00975, 12.86582],
            [42.35156000000012, 12.542230000000131],
            [42.000000000000114, 12.100000000000136],
            [41.66176000000013, 11.6312],
            [41.73959000000019, 11.355110000000138],
            [41.755570000000205, 11.050910000000101],
            [42.31414000000012, 11.0342],
            [42.55493000000013, 11.105110000000195],
            [42.77685184100096, 10.92687856693442],
            [42.55876, 10.57258000000013],
            [42.92812, 10.021940000000143],
            [43.29699000000011, 9.540480000000173],
            [43.67875, 9.18358000000012],
            [46.94834, 7.99688],
            [47.78942, 8.003],
            [44.9636, 5.001620000000116],
            [43.66087, 4.95755],
            [42.76967000000013, 4.252590000000225],
            [42.12861, 4.234130000000164],
            [41.85508309264412, 3.918911920483765],
            [41.17180000000013, 3.91909],
            [40.76848000000012, 4.257020000000125],
            [39.85494000000011, 3.838790000000131],
            [39.55938425876593, 3.422060000000215],
            [38.89251, 3.50074],
            [38.67114, 3.61607],
            [38.436970000000144, 3.58851],
            [38.12091500000014, 3.598605],
            [36.85509323800824, 4.447864127672858],
            [36.15907863285565, 4.447864127672858],
            [35.81744766235362, 4.776965663462022],
            [35.81744766235362, 5.338232082790853],
            [35.298007118233095, 5.506],
            [34.70702, 6.59422000000012],
            [34.25032, 6.82607],
            [34.07510000000019, 7.22595],
            [33.568290000000104, 7.71334],
            [32.954180000000235, 7.784970000000102],
            [33.29480000000012, 8.35458],
            [33.82550000000015, 8.37916],
            [33.97498, 8.684560000000147],
            [33.96162, 9.58358],
            [34.25745, 10.63009],
            [34.73115000000013, 10.910170000000107],
            [34.83163000000013, 11.318960000000118],
            [35.26049, 12.08286],
            [35.863630000000164, 12.57828],
            [36.27022, 13.563330000000121],
            [36.42951, 14.42211],
            [37.59377, 14.2131],
            [37.90607000000011, 14.959430000000168],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Gabon",
        sov_a3: "GAB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Gabon",
        adm0_a3: "GAB",
        geou_dif: 0,
        geounit: "Gabon",
        gu_a3: "GAB",
        su_dif: 0,
        subunit: "Gabon",
        su_a3: "GAB",
        brk_diff: 0,
        name: "Gabon",
        name_long: "Gabon",
        brk_a3: "GAB",
        brk_name: "Gabon",
        brk_group: null,
        abbrev: "Gabon",
        postal: "GA",
        formal_en: "Gabonese Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Gabon",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 5,
        pop_est: 1514993,
        gdp_md_est: 21110,
        pop_year: -99,
        lastcensus: 2003,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GA",
        iso_a3: "GAB",
        iso_n3: "266",
        un_a3: "266",
        wb_a2: "GA",
        wb_a3: "GAB",
        woe_id: -99,
        adm0_a3_is: "GAB",
        adm0_a3_us: "GAB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: 3,
        homepart: 1,
        filename: "GAB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.093772820691925, -3.978826592630547],
            [10.06613528813574, -2.969482517105681],
            [9.40524539555497, -2.144313246269043],
            [8.79799563969317, -1.111301364754496],
            [8.830086704146423, -0.779073581550037],
            [9.048419630579588, -0.459351494960217],
            [9.29135053878369, 0.268666083167687],
            [9.492888624721985, 1.010119533691494],
            [9.830284051155644, 1.067893784993799],
            [11.285078973036462, 1.057661851400013],
            [11.276449008843713, 2.261050930180872],
            [11.75166548019979, 2.326757513839993],
            [12.359380323952221, 2.19281220133945],
            [12.951333855855609, 2.32161570882694],
            [13.075822381246752, 2.267097072759015],
            [13.003113641012078, 1.83089630778332],
            [13.282631463278818, 1.31418366129688],
            [14.026668735417218, 1.395677395021153],
            [14.276265903386957, 1.196929836426619],
            [13.843320753645655, 0.038757635901149],
            [14.316418491277743, -0.552627455247048],
            [14.425455763413593, -1.333406670744971],
            [14.299210239324564, -1.998275648612214],
            [13.99240726080771, -2.4708049454891],
            [13.109618767965628, -2.428740329603514],
            [12.575284458067642, -1.948511244315135],
            [12.495702752338161, -2.391688327650243],
            [11.820963575903193, -2.514161472181982],
            [11.478038771214303, -2.765618991714241],
            [11.855121697648114, -3.426870619321051],
            [11.093772820691925, -3.978826592630547],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Ghana",
        sov_a3: "GHA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Ghana",
        adm0_a3: "GHA",
        geou_dif: 0,
        geounit: "Ghana",
        gu_a3: "GHA",
        su_dif: 0,
        subunit: "Ghana",
        su_a3: "GHA",
        brk_diff: 0,
        name: "Ghana",
        name_long: "Ghana",
        brk_a3: "GHA",
        brk_name: "Ghana",
        brk_group: null,
        abbrev: "Ghana",
        postal: "GH",
        formal_en: "Republic of Ghana",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Ghana",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 23832495,
        gdp_md_est: 34200,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GH",
        iso_a3: "GHA",
        iso_n3: "288",
        un_a3: "288",
        wb_a2: "GH",
        wb_a3: "GHA",
        woe_id: -99,
        adm0_a3_is: "GHA",
        adm0_a3_us: "GHA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "GHA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.060121697604927, 5.928837388528876],
            [-0.507637905265938, 5.343472601742675],
            [-1.063624640294194, 5.000547797053812],
            [-1.964706590167594, 4.710462144383371],
            [-2.856125047202397, 4.994475816259509],
            [-2.81070146321784, 5.38905121502411],
            [-3.244370083011262, 6.250471503113502],
            [-2.983584967450327, 7.379704901555512],
            [-2.562189500326241, 8.219627793811483],
            [-2.827496303712707, 9.642460842319778],
            [-2.963896246747112, 10.395334784380083],
            [-2.940409308270461, 10.962690334512558],
            [-1.203357713211431, 11.009819240762738],
            [-0.761575893548183, 10.936929633015055],
            [-0.438701544588582, 11.09834096927872],
            [0.023802524423701, 11.018681748900804],
            [-0.049784715159944, 10.706917832883931],
            [0.367579990245389, 10.19121287682718],
            [0.365900506195885, 9.465003973829482],
            [0.461191847342121, 8.677222601756014],
            [0.712029249686878, 8.31246450442383],
            [0.490957472342245, 7.411744289576475],
            [0.570384148774849, 6.914358628767189],
            [0.836931186536333, 6.279978745952149],
            [1.060121697604927, 5.928837388528876],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Guinea",
        sov_a3: "GIN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Guinea",
        adm0_a3: "GIN",
        geou_dif: 0,
        geounit: "Guinea",
        gu_a3: "GIN",
        su_dif: 0,
        subunit: "Guinea",
        su_a3: "GIN",
        brk_diff: 0,
        name: "Guinea",
        name_long: "Guinea",
        brk_a3: "GIN",
        brk_name: "Guinea",
        brk_group: null,
        abbrev: "Gin.",
        postal: "GN",
        formal_en: "Republic of Guinea",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Guinea",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 7,
        mapcolor13: 2,
        pop_est: 10057975,
        gdp_md_est: 10600,
        pop_year: -99,
        lastcensus: 1996,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GN",
        iso_a3: "GIN",
        iso_n3: "324",
        un_a3: "324",
        wb_a2: "GN",
        wb_a3: "GIN",
        woe_id: -99,
        adm0_a3_is: "GIN",
        adm0_a3_us: "GIN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "GIN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.439298468448698, 7.686042792181738],
            [-8.722123582382123, 7.71167430259851],
            [-8.926064622422004, 7.309037380396375],
            [-9.208786383490844, 7.313920803247953],
            [-9.40334815106975, 7.526905218938907],
            [-9.337279832384581, 7.928534450711353],
            [-9.755342169625834, 8.541055202666925],
            [-10.016566534861255, 8.42850393313523],
            [-10.23009355309128, 8.406205552601293],
            [-10.505477260774668, 8.348896389189605],
            [-10.494315151399633, 8.715540676300435],
            [-10.654770473665891, 8.977178452994194],
            [-10.622395188835041, 9.267910061068278],
            [-10.8391519840833, 9.688246161330369],
            [-11.117481248407328, 10.045872911006285],
            [-11.917277390988659, 10.046983954300558],
            [-12.150338100625005, 9.858571682164381],
            [-12.425928514037565, 9.835834051955956],
            [-12.59671912276221, 9.62018830000197],
            [-12.71195756677308, 9.342711696810767],
            [-13.246550258832515, 8.903048610871508],
            [-13.685153977909792, 9.49474376061346],
            [-14.074044969122282, 9.886166897008252],
            [-14.33007585291237, 10.015719712763966],
            [-14.579698859098258, 10.214467271358515],
            [-14.693231980843505, 10.656300767454042],
            [-14.839553798877944, 10.876571560098139],
            [-15.130311245168171, 11.040411688679526],
            [-14.685687221728898, 11.527823798056488],
            [-14.382191534878729, 11.509271958863692],
            [-14.121406419317779, 11.677117010947697],
            [-13.900799729863776, 11.678718980348748],
            [-13.743160773157411, 11.811269029177412],
            [-13.828271857142125, 12.142644151249044],
            [-13.718743658899513, 12.24718557377551],
            [-13.700476040084325, 12.586182969610194],
            [-13.217818162478238, 12.575873521367967],
            [-12.499050665730564, 12.332089952031057],
            [-12.278599005573438, 12.354440008997285],
            [-12.203564825885634, 12.465647691289405],
            [-11.65830095055793, 12.386582749882834],
            [-11.51394283695059, 12.442987575729418],
            [-11.456168585648271, 12.076834214725338],
            [-11.29757361494451, 12.077971096235771],
            [-11.036555955438258, 12.211244615116515],
            [-10.870829637078215, 12.17788747807211],
            [-10.593223842806282, 11.92397532800598],
            [-10.165213792348837, 11.844083563682744],
            [-9.890992804392013, 12.060478623904972],
            [-9.567911749703214, 12.194243068892476],
            [-9.327616339546012, 12.334286200403454],
            [-9.127473517279583, 12.308060411015331],
            [-8.90526485842453, 12.088358059126437],
            [-8.786099005559464, 11.812560939984706],
            [-8.376304897484914, 11.393645941610629],
            [-8.581305304386774, 11.136245632364805],
            [-8.620321010767128, 10.810890814655183],
            [-8.407310756860028, 10.909256903522762],
            [-8.282357143578281, 10.792597357623846],
            [-8.33537716310974, 10.494811916541934],
            [-8.029943610048619, 10.206534939001713],
            [-8.229337124046822, 10.1290202905639],
            [-8.30961646161225, 9.789531968622441],
            [-8.079113735374348, 9.376223863152035],
            [-7.832100389019188, 8.575704250518626],
            [-8.20349890790088, 8.455453192575447],
            [-8.299048631208564, 8.316443589710303],
            [-8.221792364932199, 8.123328762235573],
            [-8.280703497744938, 7.687179673692156],
            [-8.439298468448698, 7.686042792181738],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Gambia",
        sov_a3: "GMB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Gambia",
        adm0_a3: "GMB",
        geou_dif: 0,
        geounit: "Gambia",
        gu_a3: "GMB",
        su_dif: 0,
        subunit: "Gambia",
        su_a3: "GMB",
        brk_diff: 0,
        name: "Gambia",
        name_long: "The Gambia",
        brk_a3: "GMB",
        brk_name: "Gambia",
        brk_group: null,
        abbrev: "Gambia",
        postal: "GM",
        formal_en: "Republic of the Gambia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Gambia, The",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 1782893,
        gdp_md_est: 2272,
        pop_year: -99,
        lastcensus: 2003,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GM",
        iso_a3: "GMB",
        iso_n3: "270",
        un_a3: "270",
        wb_a2: "GM",
        wb_a3: "GMB",
        woe_id: -99,
        adm0_a3_is: "GMB",
        adm0_a3_us: "GMB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 10,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "GMB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.841524624081273, 13.15139394780256],
            [-16.713728807023468, 13.594958604379853],
            [-15.62459632003994, 13.62358734786956],
            [-15.39877031092446, 13.86036876063092],
            [-15.08173539881382, 13.876491807505984],
            [-14.687030808968487, 13.630356960499784],
            [-14.376713833055788, 13.625680243377372],
            [-14.046992356817482, 13.79406789800045],
            [-13.844963344772408, 13.505041612192002],
            [-14.277701788784553, 13.280585028532242],
            [-14.712197231494626, 13.298206691943777],
            [-15.141163295949466, 13.509511623585238],
            [-15.511812506562935, 13.278569647672867],
            [-15.691000535534995, 13.270353094938455],
            [-15.931295945692211, 13.130284125211332],
            [-16.841524624081273, 13.15139394780256],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Guinea Bissau",
        sov_a3: "GNB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Guinea Bissau",
        adm0_a3: "GNB",
        geou_dif: 0,
        geounit: "Guinea Bissau",
        gu_a3: "GNB",
        su_dif: 0,
        subunit: "Guinea Bissau",
        su_a3: "GNB",
        brk_diff: 0,
        name: "Guinea-Bissau",
        name_long: "Guinea-Bissau",
        brk_a3: "GNB",
        brk_name: "Guinea-Bissau",
        brk_group: null,
        abbrev: "GnB.",
        postal: "GW",
        formal_en: "Republic of Guinea-Bissau",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Guinea-Bissau",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 4,
        pop_est: 1533964,
        gdp_md_est: 904.2,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GW",
        iso_a3: "GNB",
        iso_n3: "624",
        un_a3: "624",
        wb_a2: "GW",
        wb_a3: "GNB",
        woe_id: -99,
        adm0_a3_is: "GNB",
        adm0_a3_us: "GNB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 13,
        long_len: 13,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "GNB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-15.130311245168171, 11.040411688679526],
            [-15.664180467175527, 11.458474025920795],
            [-16.085214199273565, 11.52459402103824],
            [-16.314786749730203, 11.806514797406548],
            [-16.30894731288123, 11.95870189050612],
            [-16.613838263403277, 12.170911159712702],
            [-16.677451951554573, 12.384851589401052],
            [-16.147716844130585, 12.547761542201187],
            [-15.816574266004254, 12.515567124883345],
            [-15.548476935274008, 12.628170070847347],
            [-13.700476040084325, 12.586182969610194],
            [-13.718743658899513, 12.24718557377551],
            [-13.828271857142125, 12.142644151249044],
            [-13.743160773157411, 11.811269029177412],
            [-13.900799729863776, 11.678718980348748],
            [-14.121406419317779, 11.677117010947697],
            [-14.382191534878729, 11.509271958863692],
            [-14.685687221728898, 11.527823798056488],
            [-15.130311245168171, 11.040411688679526],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Equatorial Guinea",
        sov_a3: "GNQ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Equatorial Guinea",
        adm0_a3: "GNQ",
        geou_dif: 0,
        geounit: "Equatorial Guinea",
        gu_a3: "GNQ",
        su_dif: 0,
        subunit: "Equatorial Guinea",
        su_a3: "GNQ",
        brk_diff: 0,
        name: "Eq. Guinea",
        name_long: "Equatorial Guinea",
        brk_a3: "GNQ",
        brk_name: "Eq. Guinea",
        brk_group: null,
        abbrev: "Eq. G.",
        postal: "GQ",
        formal_en: "Republic of Equatorial Guinea",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Equatorial Guinea",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 8,
        pop_est: 650702,
        gdp_md_est: 14060,
        pop_year: 0,
        lastcensus: 2002,
        gdp_year: 0,
        economy: "7. Least developed region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GQ",
        iso_a3: "GNQ",
        iso_n3: "226",
        un_a3: "226",
        wb_a2: "GQ",
        wb_a3: "GNQ",
        woe_id: -99,
        adm0_a3_is: "GNQ",
        adm0_a3_us: "GNQ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 17,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "GNQ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.492888624721985, 1.010119533691494],
            [9.305613234096256, 1.160911363119183],
            [9.649158155972628, 2.283866075037736],
            [11.276449008843713, 2.261050930180872],
            [11.285078973036462, 1.057661851400013],
            [9.830284051155644, 1.067893784993799],
            [9.492888624721985, 1.010119533691494],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Kenya",
        sov_a3: "KEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Kenya",
        adm0_a3: "KEN",
        geou_dif: 0,
        geounit: "Kenya",
        gu_a3: "KEN",
        su_dif: 0,
        subunit: "Kenya",
        su_a3: "KEN",
        brk_diff: 0,
        name: "Kenya",
        name_long: "Kenya",
        brk_a3: "KEN",
        brk_name: "Kenya",
        brk_group: null,
        abbrev: "Ken.",
        postal: "KE",
        formal_en: "Republic of Kenya",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Kenya",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 39002772,
        gdp_md_est: 61510,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "KE",
        iso_a3: "KEN",
        iso_n3: "404",
        un_a3: "404",
        wb_a2: "KE",
        wb_a3: "KEN",
        woe_id: -99,
        adm0_a3_is: "KEN",
        adm0_a3_us: "KEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "KEN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [40.993, -0.85829],
            [41.58513, -1.68325],
            [40.88477, -2.08255],
            [40.63785, -2.49979],
            [40.26304, -2.57309],
            [40.12119, -3.27768],
            [39.80006, -3.68116],
            [39.60489, -4.34653],
            [39.20222, -4.67677],
            [37.7669, -3.67712],
            [37.69869, -3.09699],
            [34.07262, -1.05982],
            [33.90371119710453, -0.95],
            [33.89356896966694, 0.109813537861896],
            [34.18, 0.515],
            [34.6721, 1.17694],
            [35.03599, 1.90584],
            [34.59607, 3.05374],
            [34.47913, 3.5556],
            [34.005, 4.249884947362048],
            [34.62019626785388, 4.847122742081988],
            [35.298007118232974, 5.506],
            [35.817447662353516, 5.338232082790797],
            [35.817447662353516, 4.77696566346189],
            [36.159078632855646, 4.447864127672769],
            [36.85509323800812, 4.447864127672769],
            [38.120915, 3.598605],
            [38.43697, 3.58851],
            [38.67114, 3.61607],
            [38.89251, 3.50074],
            [39.55938425876585, 3.42206],
            [39.85494, 3.83879],
            [40.76848, 4.25702],
            [41.1718, 3.91909],
            [41.85508309264397, 3.918911920483727],
            [40.98105, 2.78452],
            [40.993, -0.85829],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Liberia",
        sov_a3: "LBR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Liberia",
        adm0_a3: "LBR",
        geou_dif: 0,
        geounit: "Liberia",
        gu_a3: "LBR",
        su_dif: 0,
        subunit: "Liberia",
        su_a3: "LBR",
        brk_diff: 0,
        name: "Liberia",
        name_long: "Liberia",
        brk_a3: "LBR",
        brk_name: "Liberia",
        brk_group: null,
        abbrev: "Liberia",
        postal: "LR",
        formal_en: "Republic of Liberia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Liberia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 3441790,
        gdp_md_est: 1526,
        pop_year: -99,
        lastcensus: 2008,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "LR",
        iso_a3: "LBR",
        iso_n3: "430",
        un_a3: "430",
        wb_a2: "LR",
        wb_a3: "LBR",
        woe_id: -99,
        adm0_a3_is: "LBR",
        adm0_a3_us: "LBR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        filename: "LBR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.71215938966975, 4.364565944837722],
            [-7.974107224957251, 4.355755113131963],
            [-9.004793667018674, 4.8324185245922],
            [-9.913420376006684, 5.593560695819207],
            [-10.765383876986643, 6.140710760925558],
            [-11.438779466182055, 6.785916856305747],
            [-11.19980180504828, 7.105845648624737],
            [-11.146704270868383, 7.396706447779536],
            [-10.69559485517648, 7.939464016141087],
            [-10.23009355309128, 8.406205552601293],
            [-10.016566534861255, 8.42850393313523],
            [-9.755342169625834, 8.541055202666925],
            [-9.337279832384581, 7.928534450711353],
            [-9.40334815106975, 7.526905218938907],
            [-9.208786383490844, 7.313920803247953],
            [-8.926064622422004, 7.309037380396375],
            [-8.722123582382123, 7.71167430259851],
            [-8.439298468448698, 7.686042792181738],
            [-8.48544552248535, 7.39520783124307],
            [-8.385451626000574, 6.911800645368742],
            [-8.60288021486862, 6.46756419517166],
            [-8.311347622094019, 6.193033148621083],
            [-7.993692592795881, 6.126189683451543],
            [-7.570152553731688, 5.707352199725904],
            [-7.539715135111762, 5.313345241716519],
            [-7.63536821128403, 5.188159084489456],
            [-7.71215938966975, 4.364565944837722],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Libya",
        sov_a3: "LBY",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Libya",
        adm0_a3: "LBY",
        geou_dif: 0,
        geounit: "Libya",
        gu_a3: "LBY",
        su_dif: 0,
        subunit: "Libya",
        su_a3: "LBY",
        brk_diff: 0,
        name: "Libya",
        name_long: "Libya",
        brk_a3: "LBY",
        brk_name: "Libya",
        brk_group: null,
        abbrev: "Libya",
        postal: "LY",
        formal_en: "Libya",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Libya",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 11,
        pop_est: 6310434,
        gdp_md_est: 88830,
        pop_year: -99,
        lastcensus: 2006,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "LY",
        iso_a3: "LBY",
        iso_n3: "434",
        un_a3: "434",
        wb_a2: "LY",
        wb_a3: "LBY",
        woe_id: -99,
        adm0_a3_is: "LBY",
        adm0_a3_us: "LBY",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "LBY.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.8513, 22.862950000000126],
            [14.143870883855243, 22.49128896737113],
            [13.581424594790462, 23.04050608976928],
            [11.9995056494717, 23.471668402596432],
            [11.560669386449035, 24.097909247325617],
            [10.771363559622955, 24.562532050061748],
            [10.303846876678449, 24.379313259370974],
            [9.948261346078027, 24.936953640232616],
            [9.910692579801776, 25.365454616796796],
            [9.31941084151822, 26.094324856057483],
            [9.716285841519664, 26.51220632578565],
            [9.629056023811074, 27.140953477481048],
            [9.756128370816782, 27.688258571884205],
            [9.683884718472882, 28.144173895779314],
            [9.859997999723475, 28.95998973237107],
            [9.805634392952356, 29.424638373323376],
            [9.482139926805417, 30.307556057246188],
            [9.970017124072967, 30.539324856075382],
            [10.056575148161699, 30.961831366493524],
            [9.950225050505196, 31.376069647745283],
            [10.636901482799487, 31.761420803345683],
            [10.944789666394513, 32.081814683555365],
            [11.432253452203781, 32.36890310315283],
            [11.48878746913101, 33.13699575452324],
            [12.66331, 32.79278],
            [13.08326, 32.87882],
            [13.91868, 32.71196],
            [15.24563, 32.26508],
            [15.71394, 31.37626],
            [16.61162, 31.18218],
            [18.02109, 30.76357],
            [19.08641, 30.26639],
            [19.57404, 30.52582],
            [20.05335, 30.98576],
            [19.82033, 31.75179000000014],
            [20.13397, 32.2382],
            [20.85452, 32.7068],
            [21.54298, 32.8432],
            [22.89576, 32.63858],
            [23.2368, 32.19149],
            [23.609130000000107, 32.18726],
            [23.9275, 32.01667],
            [24.92114, 31.89936],
            [25.16482, 31.56915],
            [24.80287, 31.08929],
            [24.95762, 30.6616],
            [24.70007, 30.04419],
            [25.00000000000011, 29.23865452953356],
            [25.00000000000011, 25.682499996361003],
            [25.00000000000011, 22],
            [25.00000000000011, 20.00304],
            [23.850000000000136, 20],
            [23.837660000000138, 19.580470000000105],
            [19.84926, 21.49509],
            [15.86085, 23.40972],
            [14.8513, 22.862950000000126],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Lesotho",
        sov_a3: "LSO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Lesotho",
        adm0_a3: "LSO",
        geou_dif: 0,
        geounit: "Lesotho",
        gu_a3: "LSO",
        su_dif: 0,
        subunit: "Lesotho",
        su_a3: "LSO",
        brk_diff: 0,
        name: "Lesotho",
        name_long: "Lesotho",
        brk_a3: "LSO",
        brk_name: "Lesotho",
        brk_group: null,
        abbrev: "Les.",
        postal: "LS",
        formal_en: "Kingdom of Lesotho",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Lesotho",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 8,
        pop_est: 2130819,
        gdp_md_est: 3293,
        pop_year: -99,
        lastcensus: 2006,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "LS",
        iso_a3: "LSO",
        iso_n3: "426",
        un_a3: "426",
        wb_a2: "LS",
        wb_a3: "LSO",
        woe_id: -99,
        adm0_a3_is: "LSO",
        adm0_a3_us: "LSO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "LSO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.97826256685724, -28.955596612261715],
            [29.325166456832587, -29.257386976846252],
            [29.018415154748023, -29.74376555757737],
            [28.84839969250774, -30.070050551068253],
            [28.29106937023991, -30.2262167294543],
            [28.107204624145425, -30.54573211031495],
            [27.749397006956485, -30.64510588961222],
            [26.999261915807637, -29.875953871379984],
            [27.532511020627478, -29.24271087007536],
            [28.07433841320778, -28.851468601193588],
            [28.541700066855498, -28.64750172293757],
            [28.97826256685724, -28.955596612261715],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Morocco",
        sov_a3: "MAR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Morocco",
        adm0_a3: "MAR",
        geou_dif: 0,
        geounit: "Morocco",
        gu_a3: "MAR",
        su_dif: 0,
        subunit: "Morocco",
        su_a3: "MAR",
        brk_diff: 0,
        name: "Morocco",
        name_long: "Morocco",
        brk_a3: "MAR",
        brk_name: "Morocco",
        brk_group: null,
        abbrev: "Mor.",
        postal: "MA",
        formal_en: "Kingdom of Morocco",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Morocco",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 34859364,
        gdp_md_est: 136600,
        pop_year: -99,
        lastcensus: 2004,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "MA",
        iso_a3: "MAR",
        iso_n3: "504",
        un_a3: "504",
        wb_a2: "MA",
        wb_a3: "MAR",
        woe_id: -99,
        adm0_a3_is: "MAR",
        adm0_a3_us: "MAR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "MAR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.193863491222032, 35.75518219659085],
            [-4.591006232105143, 35.33071198174565],
            [-3.640056525070008, 35.39985504815198],
            [-2.604305792644112, 35.17909332940112],
            [-2.169913702798624, 35.16839630791671],
            [-1.792985805661658, 34.527918606091305],
            [-1.73345455566141, 33.91971283623212],
            [-1.388049282222596, 32.86401500094137],
            [-1.124551153966195, 32.6515215113572],
            [-1.30789913573787, 32.26288890230603],
            [-2.616604783529567, 32.094346218386164],
            [-3.068980271812649, 31.72449799247329],
            [-3.647497931320146, 31.63729401298082],
            [-3.690441046554667, 30.896951605751152],
            [-4.859646165374443, 30.50118764904388],
            [-5.242129278982787, 30.000443020135574],
            [-6.060632290053746, 29.731699734001808],
            [-7.059227667661901, 29.579228420524657],
            [-8.674116176782832, 28.84128896739665],
            [-8.665589565454836, 27.65642588959247],
            [-8.817809007940525, 27.65642588959247],
            [-8.817828334986643, 27.65642588959247],
            [-8.794883999049034, 27.12069631602256],
            [-9.413037482124508, 27.088476060488546],
            [-9.735343390328751, 26.860944729107416],
            [-10.189424200877452, 26.860944729107416],
            [-10.55126257978526, 26.990807603456886],
            [-11.392554897496948, 26.883423977154393],
            [-11.718219773800342, 26.104091701760808],
            [-12.030758836301658, 26.03086619720312],
            [-12.50096269372537, 24.770116278578143],
            [-13.891110398809047, 23.691009019459386],
            [-14.221167771857154, 22.310163072188345],
            [-14.630832688850946, 21.860939846274874],
            [-14.750954555713404, 21.500600083903805],
            [-17.00296179856107, 21.420734157796687],
            [-17.020428432675768, 21.422310288981635],
            [-16.973247849993186, 21.885744533774954],
            [-16.58913692876763, 22.15823436125009],
            [-16.261921759495664, 22.679339504481277],
            [-16.3264139469959, 23.017768459560898],
            [-15.982610642958063, 23.723358466074103],
            [-15.426003790742186, 24.35913361256104],
            [-15.089331834360733, 24.52026072844697],
            [-14.824645148161691, 25.103532619725314],
            [-14.800925665739667, 25.63626496022229],
            [-14.439939947964831, 26.254418443297652],
            [-13.773804897506464, 26.618892320252286],
            [-13.139941779014292, 27.640147813420494],
            [-13.121613369914712, 27.654147671719812],
            [-12.618836635783111, 28.038185533148663],
            [-11.688919236690765, 28.148643907172584],
            [-10.900956997104402, 28.83214223888092],
            [-10.399592251008642, 29.098585923777787],
            [-9.564811163765626, 29.933573716749862],
            [-9.814718390329174, 31.17773550060906],
            [-9.434793260119363, 32.038096421836485],
            [-9.300692918321829, 32.564679266890636],
            [-8.65747636558504, 33.2402452662424],
            [-7.654178432638218, 33.69706492770251],
            [-6.91254411460136, 34.11047638603745],
            [-6.244342006851411, 35.145865383437524],
            [-5.929994269219833, 35.75998810479399],
            [-5.193863491222032, 35.75518219659085],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Mali",
        sov_a3: "MLI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Mali",
        adm0_a3: "MLI",
        geou_dif: 0,
        geounit: "Mali",
        gu_a3: "MLI",
        su_dif: 0,
        subunit: "Mali",
        su_a3: "MLI",
        brk_diff: 0,
        name: "Mali",
        name_long: "Mali",
        brk_a3: "MLI",
        brk_name: "Mali",
        brk_group: null,
        abbrev: "Mali",
        postal: "ML",
        formal_en: "Republic of Mali",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Mali",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 7,
        pop_est: 12666987,
        gdp_md_est: 14590,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "ML",
        iso_a3: "MLI",
        iso_n3: "466",
        un_a3: "466",
        wb_a2: "ML",
        wb_a3: "MLI",
        woe_id: -99,
        adm0_a3_is: "MLI",
        adm0_a3_us: "MLI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "MLI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.170750291380301, 14.616834214735505],
            [-11.834207526079467, 14.79909699142894],
            [-11.666078253617854, 15.388208319556298],
            [-11.349095017939504, 15.411256008358478],
            [-10.650791388379417, 15.132745876521426],
            [-10.086846482778212, 15.330485744686273],
            [-9.700255092802706, 15.264107367407362],
            [-9.55023840985939, 15.486496893775437],
            [-5.537744309908447, 15.501689764869257],
            [-5.315277268891933, 16.20185374599184],
            [-5.488522508150438, 16.325102037007966],
            [-5.971128709324248, 20.64083344164763],
            [-6.453786586930335, 24.956590684503425],
            [-4.92333736817423, 24.974574082941],
            [-1.550054897457613, 22.792665920497384],
            [1.823227573259032, 20.610809434486043],
            [2.06099083823392, 20.142233384679486],
            [2.683588494486429, 19.856230170160114],
            [3.1466610042539, 19.69357859952144],
            [3.158133172222705, 19.057364203360038],
            [4.267419467800039, 19.155265204337],
            [4.270209995143801, 16.852227484601215],
            [3.723421665063483, 16.184283759012615],
            [3.638258904646477, 15.568119818580454],
            [2.749992709981484, 15.409524847876696],
            [1.385528191746858, 15.323561102759172],
            [1.01578331869851, 14.968182277887948],
            [0.374892205414682, 14.928908189346132],
            [-0.26625729003058, 14.924308986872148],
            [-0.515854458000348, 15.116157741755726],
            [-1.066363491205664, 14.973815009007765],
            [-2.001035122068771, 14.559008287000891],
            [-2.191824510090385, 14.246417548067356],
            [-2.967694464520577, 13.79815033615151],
            [-3.10370683431276, 13.541266791228594],
            [-3.522802700199861, 13.337661647998615],
            [-4.006390753587226, 13.472485459848116],
            [-4.28040503581488, 13.228443508349741],
            [-4.427166103523803, 12.542645575404295],
            [-5.220941941743121, 11.713858954307227],
            [-5.197842576508648, 11.37514577885014],
            [-5.470564947929006, 10.951269842976048],
            [-5.404341599946974, 10.370736802609146],
            [-5.816926235365287, 10.222554633012194],
            [-6.050452032892267, 10.096360785355444],
            [-6.205222947606431, 10.524060777219134],
            [-6.493965013037267, 10.411302801958271],
            [-6.666460944027548, 10.430810655148447],
            [-6.850506557635057, 10.138993841996239],
            [-7.622759161804809, 10.147236232946796],
            [-7.899589809592372, 10.297382106970828],
            [-8.029943610048619, 10.206534939001713],
            [-8.33537716310974, 10.494811916541934],
            [-8.282357143578281, 10.792597357623846],
            [-8.407310756860028, 10.909256903522762],
            [-8.620321010767128, 10.810890814655183],
            [-8.581305304386774, 11.136245632364805],
            [-8.376304897484914, 11.393645941610629],
            [-8.786099005559464, 11.812560939984706],
            [-8.90526485842453, 12.088358059126437],
            [-9.127473517279583, 12.308060411015331],
            [-9.327616339546012, 12.334286200403454],
            [-9.567911749703214, 12.194243068892476],
            [-9.890992804392013, 12.060478623904972],
            [-10.165213792348837, 11.844083563682744],
            [-10.593223842806282, 11.92397532800598],
            [-10.870829637078215, 12.17788747807211],
            [-11.036555955438258, 12.211244615116515],
            [-11.29757361494451, 12.077971096235771],
            [-11.456168585648271, 12.076834214725338],
            [-11.51394283695059, 12.442987575729418],
            [-11.467899135778524, 12.754518947800975],
            [-11.55339779300543, 13.141213690641067],
            [-11.927716030311615, 13.422075100147394],
            [-12.12488745772126, 13.994727484589788],
            [-12.170750291380301, 14.616834214735505],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Mozambique",
        sov_a3: "MOZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Mozambique",
        adm0_a3: "MOZ",
        geou_dif: 0,
        geounit: "Mozambique",
        gu_a3: "MOZ",
        su_dif: 0,
        subunit: "Mozambique",
        su_a3: "MOZ",
        brk_diff: 0,
        name: "Mozambique",
        name_long: "Mozambique",
        brk_a3: "MOZ",
        brk_name: "Mozambique",
        brk_group: null,
        abbrev: "Moz.",
        postal: "MZ",
        formal_en: "Republic of Mozambique",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Mozambique",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 21669278,
        gdp_md_est: 18940,
        pop_year: -99,
        lastcensus: 2007,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "MZ",
        iso_a3: "MOZ",
        iso_n3: "508",
        un_a3: "508",
        wb_a2: "MZ",
        wb_a3: "MOZ",
        woe_id: -99,
        adm0_a3_is: "MOZ",
        adm0_a3_us: "MOZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "MOZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.55998904799935, -11.520020033415925],
            [35.31239790216904, -11.439146416879147],
            [36.51408165868426, -11.720938002166733],
            [36.775150994622805, -11.594537448780805],
            [37.47128421402661, -11.56875090906716],
            [37.82764489111139, -11.268769219612835],
            [38.42755659358775, -11.285202325081656],
            [39.521029900883775, -10.896853936408226],
            [40.31658857601719, -10.317096042525698],
            [40.47838748552303, -10.765440769089993],
            [40.437253045418686, -11.761710707245015],
            [40.56081139502857, -12.639176527561027],
            [40.59962039567975, -14.201975192931862],
            [40.775475294768995, -14.691764418194241],
            [40.4772506040126, -15.406294447493972],
            [40.08926395036522, -16.10077402106446],
            [39.45255862809705, -16.72089120856694],
            [38.53835086442152, -17.101023044505958],
            [37.41113284683888, -17.586368096591237],
            [36.28127933120936, -18.65968759529345],
            [35.89649661636406, -18.842260430580634],
            [35.198399692533144, -19.552811374593894],
            [34.78638349787005, -19.784011732667736],
            [34.70189253107284, -20.49704314543101],
            [35.176127150215365, -21.25436126066841],
            [35.37342776870574, -21.840837090748877],
            [35.385848253705404, -22.14],
            [35.562545536369086, -22.09],
            [35.533934767404304, -23.070787855727758],
            [35.37177412287238, -23.5353589820317],
            [35.60747033055563, -23.706563002214683],
            [35.45874555841962, -24.12260995859655],
            [35.04073489761066, -24.478350518493805],
            [34.21582400893547, -24.81631438568266],
            [33.01321007663901, -25.357573337507738],
            [32.574632195777866, -25.72731821055609],
            [32.66036339695009, -26.148584486599443],
            [32.91595503106569, -26.215867201443466],
            [32.830120477028885, -26.742191664336197],
            [32.07166548028107, -26.73382008230491],
            [31.98577924981197, -26.291779880480227],
            [31.837777947728064, -25.84333180105135],
            [31.75240848158188, -25.484283949487413],
            [31.93058882012425, -24.369416599222536],
            [31.670397983534652, -23.658969008073864],
            [31.191409132621285, -22.2515096981724],
            [32.244988234188014, -21.116488539313693],
            [32.50869306817344, -20.395292250248307],
            [32.65974327976258, -20.304290052982317],
            [32.772707960752626, -19.715592136313298],
            [32.61199425632489, -19.419382826416275],
            [32.65488569512715, -18.672089939043495],
            [32.84986087416439, -17.97905730557718],
            [32.847638787575846, -16.713398125884616],
            [32.32823896661022, -16.392074069893752],
            [31.8520406430406, -16.319417006091378],
            [31.636498243951195, -16.071990248277885],
            [31.173063999157677, -15.860943698797872],
            [30.338954705534544, -15.880839125230244],
            [30.274255812305107, -15.507786960515212],
            [30.17948123548183, -14.796099134991527],
            [33.214024692525214, -13.971860039936153],
            [33.789700148256685, -14.451830743063072],
            [34.064825473778626, -14.359950046448121],
            [34.45963341648854, -14.613009535381424],
            [34.51766604995231, -15.013708591372612],
            [34.307291294092096, -15.478641452702595],
            [34.38129194513405, -16.183559665596043],
            [35.033810255683534, -16.801299737213093],
            [35.33906294123164, -16.10744028083011],
            [35.77190473810836, -15.896858819240725],
            [35.68684533055594, -14.611045830954332],
            [35.26795617039801, -13.887834161029566],
            [34.907151320136165, -13.565424899960568],
            [34.55998904799935, -13.579997653866876],
            [34.28000613784198, -12.280025323132504],
            [34.55998904799935, -11.520020033415925],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Madagascar",
        sov_a3: "MDG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Madagascar",
        adm0_a3: "MDG",
        geou_dif: 0,
        geounit: "Madagascar",
        gu_a3: "MDG",
        su_dif: 0,
        subunit: "Madagascar",
        su_a3: "MDG",
        brk_diff: 0,
        name: "Madagascar",
        name_long: "Madagascar",
        brk_a3: "MDG",
        brk_name: "Madagascar",
        brk_group: null,
        abbrev: "Mad.",
        postal: "MG",
        formal_en: "Republic of Madagascar",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Madagascar",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 5,
        mapcolor9: 2,
        mapcolor13: 3,
        pop_est: 20653556,
        gdp_md_est: 20130,
        pop_year: -99,
        lastcensus: 1993,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "MG",
        iso_a3: "MDG",
        iso_n3: "450",
        un_a3: "450",
        wb_a2: "MG",
        wb_a3: "MDG",
        woe_id: -99,
        adm0_a3_is: "MDG",
        adm0_a3_us: "MDG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "MDG.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [49.54351891459575, -12.469832858940554],
            [49.80898074727909, -12.895284925999555],
            [50.056510857957164, -13.555761407121985],
            [50.21743126811407, -14.758788750876795],
            [50.47653689962553, -15.226512139550541],
            [50.377111443895956, -15.706069431219126],
            [50.20027469259318, -16.000263360256767],
            [49.86060550313868, -15.414252618066916],
            [49.67260664246086, -15.710203545802479],
            [49.863344354050156, -16.451036879138776],
            [49.77456424337271, -16.875042006093597],
            [49.49861209493412, -17.106035658438273],
            [49.435618523970305, -17.953064060134366],
            [49.041792433473944, -19.118781019774445],
            [48.54854088724801, -20.496888116134127],
            [47.93074913919867, -22.391501153251085],
            [47.54772342305131, -23.781958916928517],
            [47.095761346226595, -24.941629733990453],
            [46.282477654817086, -25.178462823184105],
            [45.409507684110444, -25.60143442149309],
            [44.83357384621755, -25.34610116953894],
            [44.03972049334976, -24.988345228782308],
            [43.76376834491117, -24.460677178649988],
            [43.697777540874455, -23.574116306250602],
            [43.345654331237625, -22.776903985283873],
            [43.254187046081, -22.057413018484123],
            [43.43329756040464, -21.336475111580185],
            [43.893682895692926, -21.163307386970125],
            [43.896370070172104, -20.830459486578174],
            [44.37432539243966, -20.07236622485639],
            [44.46439741392439, -19.435454196859048],
            [44.23242190936617, -18.961994724200906],
            [44.04297610858415, -18.33138722094317],
            [43.96308434426091, -17.409944756746782],
            [44.31246870298628, -16.850495700754955],
            [44.4465173683514, -16.216219170804507],
            [44.94493655780653, -16.1793738745804],
            [45.50273196796499, -15.97437346767854],
            [45.87299360533626, -15.793454278224685],
            [46.31224327981721, -15.780018405828798],
            [46.882182651564285, -15.210182386946313],
            [47.70512983581235, -14.594302666891764],
            [48.005214878131255, -14.091232598530375],
            [47.869047479042166, -13.663868503476586],
            [48.29382775248138, -13.784067884987486],
            [48.84506025573878, -13.089174899958664],
            [48.86350874206698, -12.48786793381042],
            [49.194651320193316, -12.04055673589197],
            [49.54351891459575, -12.469832858940554],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Mauritania",
        sov_a3: "MRT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Mauritania",
        adm0_a3: "MRT",
        geou_dif: 0,
        geounit: "Mauritania",
        gu_a3: "MRT",
        su_dif: 0,
        subunit: "Mauritania",
        su_a3: "MRT",
        brk_diff: 0,
        name: "Mauritania",
        name_long: "Mauritania",
        brk_a3: "MRT",
        brk_name: "Mauritania",
        brk_group: null,
        abbrev: "Mrt.",
        postal: "MR",
        formal_en: "Islamic Republic of Mauritania",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Mauritania",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 1,
        pop_est: 3129486,
        gdp_md_est: 6308,
        pop_year: -99,
        lastcensus: 2000,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "MR",
        iso_a3: "MRT",
        iso_n3: "478",
        un_a3: "478",
        wb_a2: "MR",
        wb_a3: "MRT",
        woe_id: -99,
        adm0_a3_is: "MRT",
        adm0_a3_us: "MRT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "MRT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.170750291380301, 14.616834214735505],
            [-12.830658331747516, 15.303691514542946],
            [-13.43573767745306, 16.03938304286619],
            [-14.099521450242179, 16.304302273010492],
            [-14.577347581428981, 16.59826365810281],
            [-15.135737270558817, 16.587282416240782],
            [-15.62366614425869, 16.369337063049812],
            [-16.12069007004193, 16.455662543193384],
            [-16.463098110407884, 16.13503611903846],
            [-16.549707810929064, 16.67389211676196],
            [-16.270551723688357, 17.166962795474873],
            [-16.14634741867485, 18.108481553616656],
            [-16.256883307347167, 19.096715806550307],
            [-16.37765112961327, 19.593817246981985],
            [-16.277838100641517, 20.0925206568147],
            [-16.536323614965468, 20.567866319251493],
            [-17.063423224342568, 20.999752102130827],
            [-16.845193650773993, 21.33332347257488],
            [-12.929101935263532, 21.32707062426756],
            [-13.118754441774712, 22.771220201096256],
            [-12.874221564169575, 23.284832261645178],
            [-11.937224493853321, 23.374594224536168],
            [-11.96941891117116, 25.933352769468268],
            [-8.6872936670174, 25.881056219988906],
            [-8.68439978680905, 27.395744126896005],
            [-4.92333736817423, 24.974574082941],
            [-6.453786586930335, 24.956590684503425],
            [-5.971128709324248, 20.64083344164763],
            [-5.488522508150438, 16.325102037007966],
            [-5.315277268891933, 16.20185374599184],
            [-5.537744309908447, 15.501689764869257],
            [-9.55023840985939, 15.486496893775437],
            [-9.700255092802706, 15.264107367407362],
            [-10.086846482778212, 15.330485744686273],
            [-10.650791388379417, 15.132745876521426],
            [-11.349095017939504, 15.411256008358478],
            [-11.666078253617854, 15.388208319556298],
            [-11.834207526079467, 14.79909699142894],
            [-12.170750291380301, 14.616834214735505],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Malawi",
        sov_a3: "MWI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Malawi",
        adm0_a3: "MWI",
        geou_dif: 0,
        geounit: "Malawi",
        gu_a3: "MWI",
        su_dif: 0,
        subunit: "Malawi",
        su_a3: "MWI",
        brk_diff: 0,
        name: "Malawi",
        name_long: "Malawi",
        brk_a3: "MWI",
        brk_name: "Malawi",
        brk_group: null,
        abbrev: "Mal.",
        postal: "MW",
        formal_en: "Republic of Malawi",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Malawi",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 5,
        pop_est: 14268711,
        gdp_md_est: 11810,
        pop_year: -99,
        lastcensus: 2008,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "MW",
        iso_a3: "MWI",
        iso_n3: "454",
        un_a3: "454",
        wb_a2: "MW",
        wb_a3: "MWI",
        woe_id: -99,
        adm0_a3_is: "MWI",
        adm0_a3_us: "MWI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "MWI.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.55998904799935, -11.520020033415925],
            [34.28000613784198, -12.280025323132504],
            [34.55998904799935, -13.579997653866876],
            [34.907151320136165, -13.565424899960568],
            [35.26795617039801, -13.887834161029566],
            [35.68684533055594, -14.611045830954332],
            [35.77190473810836, -15.896858819240725],
            [35.33906294123164, -16.10744028083011],
            [35.033810255683534, -16.801299737213093],
            [34.38129194513405, -16.183559665596043],
            [34.307291294092096, -15.478641452702595],
            [34.51766604995231, -15.013708591372612],
            [34.45963341648854, -14.613009535381424],
            [34.064825473778626, -14.359950046448121],
            [33.789700148256685, -14.451830743063072],
            [33.214024692525214, -13.971860039936153],
            [32.68816531752313, -13.712857761289275],
            [32.991764357237884, -12.783870537978272],
            [33.306422153463075, -12.435778090060218],
            [33.11428917820191, -11.607198174692314],
            [33.315310499817286, -10.796549981329697],
            [33.48568769708359, -10.525558770391115],
            [33.2313879737753, -9.6767216935648],
            [32.75937544122132, -9.230599053589058],
            [33.73972903823045, -9.417150974162723],
            [33.94083772409653, -9.693673841980294],
            [34.28000613784198, -10.159999688358404],
            [34.55998904799935, -11.520020033415925],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Namibia",
        sov_a3: "NAM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Namibia",
        adm0_a3: "NAM",
        geou_dif: 0,
        geounit: "Namibia",
        gu_a3: "NAM",
        su_dif: 0,
        subunit: "Namibia",
        su_a3: "NAM",
        brk_diff: 0,
        name: "Namibia",
        name_long: "Namibia",
        brk_a3: "NAM",
        brk_name: "Namibia",
        brk_group: null,
        abbrev: "Nam.",
        postal: "NA",
        formal_en: "Republic of Namibia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Namibia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 7,
        pop_est: 2108665,
        gdp_md_est: 13250,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "NA",
        iso_a3: "NAM",
        iso_n3: "516",
        un_a3: "516",
        wb_a2: "NA",
        wb_a3: "NAM",
        woe_id: -99,
        adm0_a3_is: "NAM",
        adm0_a3_us: "NAM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "NAM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.344976840895242, -28.576705010697697],
            [15.601818068105814, -27.8212472470228],
            [15.21047244635946, -27.090955905874047],
            [14.989710727608552, -26.117371921495156],
            [14.743214145576331, -25.39292001719538],
            [14.408144158595833, -23.853014011329847],
            [14.385716586981149, -22.65665292734069],
            [14.257714064194175, -22.111208184499954],
            [13.86864220546866, -21.699036960539978],
            [13.35249799973744, -20.872834161057504],
            [12.826845330464492, -19.673165785401665],
            [12.608564080463621, -19.0453488094877],
            [11.794918654028066, -18.069129327061916],
            [11.734198846085121, -17.301889336824473],
            [12.215461460019355, -17.111668389558083],
            [12.814081251688407, -16.94134286872407],
            [13.462362094789967, -16.971211846588773],
            [14.05850141770901, -17.423380629142663],
            [14.209706658595024, -17.35310068122572],
            [18.26330936043416, -17.309950860262006],
            [18.956186964603603, -17.789094740472258],
            [21.377176141045567, -17.930636488519696],
            [23.215048455506064, -17.52311614346598],
            [24.033861525170778, -17.295843194246324],
            [24.682349074001507, -17.353410739819473],
            [25.07695031098226, -17.57882333747662],
            [25.08444339366457, -17.661815687737374],
            [24.520705193792537, -17.887124932529936],
            [24.217364536239213, -17.88934701911849],
            [23.579005568137717, -18.28126108162006],
            [23.1968583513393, -17.869038181227786],
            [21.655040317478978, -18.219146010005225],
            [20.910641310314535, -18.252218926672022],
            [20.881134067475866, -21.814327080983148],
            [19.89545779794068, -21.84915699634787],
            [19.895767856534434, -24.767790215760588],
            [19.894734327888614, -28.461104831660776],
            [19.002127312911085, -28.972443129188864],
            [18.464899122804752, -29.04546192801728],
            [17.83615197110953, -28.85637786226132],
            [17.387497185951503, -28.78351409272978],
            [17.218928663815404, -28.35594329194681],
            [16.824017368240902, -28.082161553664466],
            [16.344976840895242, -28.576705010697697],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Niger",
        sov_a3: "NER",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Niger",
        adm0_a3: "NER",
        geou_dif: 0,
        geounit: "Niger",
        gu_a3: "NER",
        su_dif: 0,
        subunit: "Niger",
        su_a3: "NER",
        brk_diff: 0,
        name: "Niger",
        name_long: "Niger",
        brk_a3: "NER",
        brk_name: "Niger",
        brk_group: null,
        abbrev: "Niger",
        postal: "NE",
        formal_en: "Republic of Niger",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Niger",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 13,
        pop_est: 15306252,
        gdp_md_est: 10040,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "NE",
        iso_a3: "NER",
        iso_n3: "562",
        un_a3: "562",
        wb_a2: "NE",
        wb_a3: "NER",
        woe_id: -99,
        adm0_a3_is: "NER",
        adm0_a3_us: "NER",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "NER.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [2.15447350424995, 11.940150051313424],
            [2.177107781593918, 12.625017808477537],
            [1.024103224297619, 12.851825669806601],
            [0.993045688490156, 13.335749620003865],
            [0.429927605805517, 13.988733018443893],
            [0.295646396495215, 14.444234930880667],
            [0.374892205414767, 14.928908189346147],
            [1.015783318698482, 14.968182277887989],
            [1.385528191746971, 15.32356110275924],
            [2.749992709981541, 15.409524847876753],
            [3.638258904646591, 15.568119818580442],
            [3.723421665063597, 16.184283759012658],
            [4.270209995143887, 16.852227484601315],
            [4.267419467800096, 19.155265204337127],
            [5.677565952180714, 19.6012069767998],
            [8.57289310062987, 21.565660712159225],
            [11.9995056494717, 23.471668402596432],
            [13.581424594790462, 23.04050608976928],
            [14.143870883855243, 22.49128896737113],
            [14.8513, 22.862950000000126],
            [15.096887648181848, 21.30851878507491],
            [15.471076694407316, 21.048457139565983],
            [15.487148064850146, 20.730414537025638],
            [15.903246697664313, 20.387618923417506],
            [15.685740594147774, 19.957180080642384],
            [15.30044111497972, 17.927949937405003],
            [15.247731154041846, 16.627305813050782],
            [13.972201775781684, 15.684365953021143],
            [13.540393507550789, 14.367133693901222],
            [13.956698846094127, 13.996691189016929],
            [13.95447675950561, 13.353448798063766],
            [14.595781284247607, 13.330426947477859],
            [14.495787387762903, 12.859396267137356],
            [14.21353071458475, 12.802035427293333],
            [14.18133629726691, 12.483656927943171],
            [13.995352817448293, 12.461565253138303],
            [13.318701613018561, 13.556356309457954],
            [13.083987257548813, 13.596147162322495],
            [12.30207116054055, 13.037189032437539],
            [11.527803175511508, 13.32898000737356],
            [10.989593133191532, 13.387322699431195],
            [10.701031935273818, 13.246917832894042],
            [10.114814487354748, 13.277251898649467],
            [9.52492801274309, 12.851102199754564],
            [9.014933302454438, 12.826659247280418],
            [7.804671258178871, 13.343526923063735],
            [7.330746697630047, 13.098038031461215],
            [6.820441928747812, 13.115091254117601],
            [6.445426059605722, 13.492768459522722],
            [5.443058302440135, 13.865923977102225],
            [4.368343540066007, 13.747481594289411],
            [4.107945997747379, 13.531215725147945],
            [3.967282749048934, 12.956108710171577],
            [3.680633579125925, 12.55290334721417],
            [3.611180454125587, 11.660167141155966],
            [2.848643019226586, 12.23563589115821],
            [2.490163608418015, 12.233052069543588],
            [2.15447350424995, 11.940150051313424],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Nigeria",
        sov_a3: "NGA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Nigeria",
        adm0_a3: "NGA",
        geou_dif: 0,
        geounit: "Nigeria",
        gu_a3: "NGA",
        su_dif: 0,
        subunit: "Nigeria",
        su_a3: "NGA",
        brk_diff: 0,
        name: "Nigeria",
        name_long: "Nigeria",
        brk_a3: "NGA",
        brk_name: "Nigeria",
        brk_group: null,
        abbrev: "Nigeria",
        postal: "NG",
        formal_en: "Federal Republic of Nigeria",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Nigeria",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 5,
        mapcolor13: 2,
        pop_est: 149229090,
        gdp_md_est: 335400,
        pop_year: -99,
        lastcensus: 2006,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "NG",
        iso_a3: "NGA",
        iso_n3: "566",
        un_a3: "566",
        wb_a2: "NG",
        wb_a3: "NGA",
        woe_id: -99,
        adm0_a3_is: "NGA",
        adm0_a3_us: "NGA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        filename: "NGA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.500287713259695, 4.771982937026849],
            [7.46210818851594, 4.412108262546241],
            [7.082596469764439, 4.464689032403228],
            [6.6980721370806, 4.240594183769517],
            [5.898172641634687, 4.262453314628985],
            [5.362804803090881, 4.887970689305959],
            [5.033574252959368, 5.611802476418234],
            [4.325607130560683, 6.270651149923467],
            [3.574180128604553, 6.258300482605719],
            [2.691701694356254, 6.258817246928629],
            [2.74906253420022, 7.870734361192888],
            [2.723792758809509, 8.50684540448971],
            [2.912308383810256, 9.13760793704432],
            [3.220351596702101, 9.4441525333997],
            [3.705438266625919, 10.063210354040208],
            [3.600070021182801, 10.332186184119408],
            [3.797112257511714, 10.734745591673105],
            [3.572216424177469, 11.327939357951518],
            [3.611180454125559, 11.660167141155966],
            [3.680633579125811, 12.552903347214226],
            [3.967282749048849, 12.956108710171575],
            [4.107945997747322, 13.531215725147831],
            [4.368343540066064, 13.747481594289324],
            [5.443058302440164, 13.865923977102298],
            [6.445426059605637, 13.492768459522678],
            [6.820441928747754, 13.115091254117518],
            [7.330746697630018, 13.0980380314612],
            [7.804671258178786, 13.343526923063745],
            [9.014933302454466, 12.82665924728043],
            [9.524928012742945, 12.851102199754479],
            [10.114814487354693, 13.27725189864941],
            [10.701031935273704, 13.246917832894084],
            [10.989593133191535, 13.38732269943111],
            [11.527803175511394, 13.328980007373588],
            [12.302071160540523, 13.037189032437524],
            [13.08398725754887, 13.596147162322566],
            [13.318701613018561, 13.556356309457826],
            [13.99535281744835, 12.461565253138346],
            [14.181336297266792, 12.483656927943116],
            [14.577177768622533, 12.085360826053503],
            [14.468192172918975, 11.904751695193411],
            [14.415378859116686, 11.572368882692075],
            [13.572949659894562, 10.798565985553566],
            [13.308676385153918, 10.160362046748928],
            [13.167599724997103, 9.640626328973411],
            [12.955467970438974, 9.417771714714704],
            [12.753671502339214, 8.717762762888995],
            [12.218872104550599, 8.305824082874324],
            [12.063946160539558, 7.799808457872302],
            [11.839308709366803, 7.397042344589436],
            [11.74577436691851, 6.981382961449753],
            [11.05878787603035, 6.644426784690594],
            [10.497375115611417, 7.055357774275564],
            [10.118276808318257, 7.038769639509879],
            [9.522705926154401, 6.453482367372117],
            [9.233162876023044, 6.444490668153334],
            [8.757532993208628, 5.479665839047911],
            [8.500287713259695, 4.771982937026849],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 7,
        sovereignt: "Western Sahara",
        sov_a3: "SAH",
        adm0_dif: 0,
        level: 2,
        type: "Indeterminate",
        admin: "Western Sahara",
        adm0_a3: "SAH",
        geou_dif: 0,
        geounit: "Western Sahara",
        gu_a3: "SAH",
        su_dif: 0,
        subunit: "Western Sahara",
        su_a3: "SAH",
        brk_diff: 1,
        name: "W. Sahara",
        name_long: "Western Sahara",
        brk_a3: "B28",
        brk_name: "W. Sahara",
        brk_group: null,
        abbrev: "W. Sah.",
        postal: "WS",
        formal_en: "Sahrawi Arab Democratic Republic",
        formal_fr: null,
        note_adm0: "Self admin.",
        note_brk: "Self admin.; Claimed by Morocco",
        name_sort: "Western Sahara",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 7,
        mapcolor9: 4,
        mapcolor13: 4,
        pop_est: -99,
        gdp_md_est: -99,
        pop_year: -99,
        lastcensus: -99,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "EH",
        iso_a3: "ESH",
        iso_n3: "732",
        un_a3: "732",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -99,
        adm0_a3_is: "MAR",
        adm0_a3_us: "SAH",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 9,
        long_len: 14,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        filename: "ESH.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.794883999049077, 27.120696316022503],
            [-8.817828334986672, 27.656425889592356],
            [-8.665589565454809, 27.656425889592356],
            [-8.665124477564191, 27.589479071558227],
            [-8.68439978680905, 27.395744126896005],
            [-8.6872936670174, 25.881056219988906],
            [-11.96941891117116, 25.933352769468268],
            [-11.937224493853321, 23.374594224536168],
            [-12.874221564169575, 23.284832261645178],
            [-13.118754441774712, 22.771220201096256],
            [-12.929101935263532, 21.32707062426756],
            [-16.845193650773993, 21.33332347257488],
            [-17.063423224342568, 20.999752102130827],
            [-17.020428432675743, 21.42231028898148],
            [-17.00296179856109, 21.420734157796577],
            [-14.750954555713534, 21.500600083903663],
            [-14.630832688851072, 21.8609398462749],
            [-14.221167771857251, 22.31016307218816],
            [-13.891110398809047, 23.691009019459305],
            [-12.50096269372537, 24.7701162785782],
            [-12.030758836301615, 26.030866197203043],
            [-11.718219773800357, 26.104091701760623],
            [-11.392554897496979, 26.883423977154365],
            [-10.551262579785273, 26.990807603456886],
            [-10.189424200877582, 26.860944729107405],
            [-9.735343390328879, 26.860944729107405],
            [-9.413037482124466, 27.088476060488517],
            [-8.794883999049077, 27.120696316022503],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Rwanda",
        sov_a3: "RWA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Rwanda",
        adm0_a3: "RWA",
        geou_dif: 0,
        geounit: "Rwanda",
        gu_a3: "RWA",
        su_dif: 0,
        subunit: "Rwanda",
        su_a3: "RWA",
        brk_diff: 0,
        name: "Rwanda",
        name_long: "Rwanda",
        brk_a3: "RWA",
        brk_name: "Rwanda",
        brk_group: null,
        abbrev: "Rwa.",
        postal: "RW",
        formal_en: "Republic of Rwanda",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Rwanda",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 10,
        pop_est: 10473282,
        gdp_md_est: 9706,
        pop_year: -99,
        lastcensus: 2002,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "RW",
        iso_a3: "RWA",
        iso_n3: "646",
        un_a3: "646",
        wb_a2: "RW",
        wb_a3: "RWA",
        woe_id: -99,
        adm0_a3_is: "RWA",
        adm0_a3_us: "RWA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "RWA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.41910485201924, -1.134659112150416],
            [30.81613488131771, -1.698914076345389],
            [30.75830895358311, -2.287250257988369],
            [30.469696079232985, -2.413857517103458],
            [29.938359002407942, -2.348486830254238],
            [29.632176141078588, -2.917857761246097],
            [29.024926385216787, -2.839257907730158],
            [29.117478875451553, -2.292211195488385],
            [29.25483483248334, -2.215109958508911],
            [29.29188683443661, -1.620055840667987],
            [29.579466180140884, -1.341313164885626],
            [29.82151858899601, -1.443322442229785],
            [30.41910485201924, -1.134659112150416],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Sudan",
        sov_a3: "SDN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Sudan",
        adm0_a3: "SDN",
        geou_dif: 0,
        geounit: "Sudan",
        gu_a3: "SDN",
        su_dif: 0,
        subunit: "Sudan",
        su_a3: "SDN",
        brk_diff: 0,
        name: "Sudan",
        name_long: "Sudan",
        brk_a3: "SDN",
        brk_name: "Sudan",
        brk_group: null,
        abbrev: "Sudan",
        postal: "SD",
        formal_en: "Republic of the Sudan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Sudan",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 6,
        mapcolor9: 4,
        mapcolor13: 1,
        pop_est: 25946220,
        gdp_md_est: 88080,
        pop_year: -99,
        lastcensus: 2008,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SD",
        iso_a3: "SDN",
        iso_n3: "729",
        un_a3: "729",
        wb_a2: "SD",
        wb_a3: "SDN",
        woe_id: -99,
        adm0_a3_is: "SDN",
        adm0_a3_us: "SDN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "SDN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.963392794971185, 9.464285229420625],
            [33.82496348090751, 9.484060845715362],
            [33.842130853028145, 9.981914637215993],
            [33.72195924818311, 10.325262079630193],
            [33.20693808456178, 10.720111638406593],
            [33.086766479716744, 11.441141267476496],
            [33.20693808456178, 12.179338268667093],
            [32.743419037302544, 12.248007757149992],
            [32.67474954881965, 12.02483191958072],
            [32.073891524594785, 11.973329803218519],
            [32.31423473428475, 11.68148447716652],
            [32.400071594888345, 11.080626452941488],
            [31.850715687025517, 10.531270545078826],
            [31.35286189552488, 9.810240916008695],
            [30.83784073190338, 9.70723668328452],
            [29.99663949798855, 10.290927335388687],
            [29.61895731133285, 10.084918869940225],
            [29.515953078608614, 9.793073543888056],
            [29.000931914987174, 9.60423245056029],
            [28.966597170745782, 9.398223985111654],
            [27.970889587744352, 9.398223985111654],
            [27.833550610778783, 9.60423245056029],
            [27.112520981708883, 9.638567194801624],
            [26.752006167173818, 9.466893473594496],
            [26.477328213242515, 9.552730334198088],
            [25.962307049621018, 10.136420986302424],
            [25.790633328413946, 10.411098940233728],
            [25.069603699343986, 10.273759963267992],
            [24.794925745412684, 9.810240916008695],
            [24.53741516360202, 8.91753756573172],
            [24.19406772118765, 8.728696472403897],
            [23.886979580860665, 8.619729712933065],
            [23.805813429466752, 8.666318874542526],
            [23.459012892355986, 8.95428579348902],
            [23.394779087017298, 9.26506785729225],
            [23.55724979014292, 9.68121816653877],
            [23.554304233502194, 10.08925527591532],
            [22.97754357269275, 10.71446259199854],
            [22.864165480244253, 11.142395127807617],
            [22.87622, 11.384610000000123],
            [22.50869, 11.67936],
            [22.49762, 12.26024],
            [22.28801, 12.64605],
            [21.93681, 12.588180000000136],
            [22.03759, 12.95546],
            [22.29658, 13.37232],
            [22.18329, 13.78648],
            [22.51202, 14.09318],
            [22.30351, 14.32682],
            [22.56795000000011, 14.944290000000137],
            [23.024590000000103, 15.68072],
            [23.886890000000108, 15.61084],
            [23.837660000000138, 19.580470000000105],
            [23.850000000000136, 20],
            [25.00000000000011, 20.00304],
            [25.00000000000011, 22],
            [29.02, 22],
            [32.9, 22],
            [36.86623, 22],
            [37.1887200000001, 21.01885],
            [36.96941, 20.83744000000013],
            [37.11470000000014, 19.80796],
            [37.4817900000001, 18.61409],
            [37.86276, 18.36786],
            [38.410089959473225, 17.99830739997031],
            [37.90400000000011, 17.42754],
            [37.16747, 17.263140000000135],
            [36.852530000000115, 16.95655],
            [36.75389, 16.29186],
            [36.32322, 14.82249],
            [36.42951, 14.42211],
            [36.27022, 13.563330000000121],
            [35.86363, 12.57828],
            [35.26049, 12.08286],
            [34.83163000000013, 11.318960000000118],
            [34.73115000000013, 10.910170000000107],
            [34.25745, 10.63009],
            [33.96162, 9.58358],
            [33.963392794971185, 9.464285229420625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "South Sudan",
        sov_a3: "SDS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "South Sudan",
        adm0_a3: "SDS",
        geou_dif: 0,
        geounit: "South Sudan",
        gu_a3: "SDS",
        su_dif: 0,
        subunit: "South Sudan",
        su_a3: "SDS",
        brk_diff: 0,
        name: "S. Sudan",
        name_long: "South Sudan",
        brk_a3: "SDS",
        brk_name: "S. Sudan",
        brk_group: null,
        abbrev: "S. Sud.",
        postal: "SS",
        formal_en: "Republic of South Sudan",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "South Sudan",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 5,
        pop_est: 10625176,
        gdp_md_est: 13227,
        pop_year: -99,
        lastcensus: 2008,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SS",
        iso_a3: "SSD",
        iso_n3: "728",
        un_a3: "728",
        wb_a2: "SS",
        wb_a3: "SSD",
        woe_id: -99,
        adm0_a3_is: "SSD",
        adm0_a3_us: "SDS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 11,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        filename: "SSD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.963392794971185, 9.464285229420625],
            [33.97498, 8.68456],
            [33.82550000000015, 8.37916],
            [33.29480000000012, 8.35458],
            [32.95418, 7.784970000000102],
            [33.568290000000104, 7.71334],
            [34.0751, 7.22595],
            [34.25032, 6.82607],
            [34.70702, 6.59422000000012],
            [35.298007118233095, 5.506],
            [34.62019626785394, 4.847122742082036],
            [34.005, 4.249884947362147],
            [33.3900000000001, 3.79],
            [32.68642, 3.79232],
            [31.881450000000143, 3.55827],
            [31.24556, 3.7819],
            [30.83385, 3.50917],
            [29.95349, 4.1737],
            [29.71599531425602, 4.600804755060153],
            [29.159078403446642, 4.389267279473245],
            [28.696677687298802, 4.455077215996994],
            [28.428993768027, 4.287154649264608],
            [27.979977247842953, 4.408413397637388],
            [27.374226108517632, 5.233944403500175],
            [27.213409051225256, 5.550953477394614],
            [26.465909458123292, 5.946717434101856],
            [26.21341840994512, 6.546603298362129],
            [25.796647983511264, 6.97931590415817],
            [25.124130893664812, 7.500085150579424],
            [25.114932488716875, 7.825104071479245],
            [24.5673690121522, 8.229187933785454],
            [23.886979580860665, 8.619729712933065],
            [24.19406772118765, 8.728696472403897],
            [24.53741516360202, 8.91753756573172],
            [24.794925745412684, 9.810240916008695],
            [25.069603699343986, 10.273759963267992],
            [25.790633328413946, 10.411098940233728],
            [25.962307049621018, 10.136420986302424],
            [26.477328213242515, 9.552730334198088],
            [26.752006167173818, 9.466893473594496],
            [27.112520981708883, 9.638567194801624],
            [27.833550610778783, 9.60423245056029],
            [27.970889587744352, 9.398223985111654],
            [28.966597170745782, 9.398223985111654],
            [29.000931914987174, 9.60423245056029],
            [29.515953078608614, 9.793073543888056],
            [29.61895731133285, 10.084918869940225],
            [29.99663949798855, 10.290927335388687],
            [30.83784073190338, 9.70723668328452],
            [31.35286189552488, 9.810240916008695],
            [31.850715687025517, 10.531270545078826],
            [32.400071594888345, 11.080626452941488],
            [32.31423473428475, 11.68148447716652],
            [32.073891524594785, 11.973329803218519],
            [32.67474954881965, 12.02483191958072],
            [32.743419037302544, 12.248007757149992],
            [33.20693808456178, 12.179338268667093],
            [33.086766479716744, 11.441141267476496],
            [33.20693808456178, 10.720111638406593],
            [33.72195924818311, 10.325262079630193],
            [33.842130853028145, 9.981914637215993],
            [33.82496348090751, 9.484060845715362],
            [33.963392794971185, 9.464285229420625],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Sierra Leone",
        sov_a3: "SLE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Sierra Leone",
        adm0_a3: "SLE",
        geou_dif: 0,
        geounit: "Sierra Leone",
        gu_a3: "SLE",
        su_dif: 0,
        subunit: "Sierra Leone",
        su_a3: "SLE",
        brk_diff: 0,
        name: "Sierra Leone",
        name_long: "Sierra Leone",
        brk_a3: "SLE",
        brk_name: "Sierra Leone",
        brk_group: null,
        abbrev: "S.L.",
        postal: "SL",
        formal_en: "Republic of Sierra Leone",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Sierra Leone",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 7,
        pop_est: 6440053,
        gdp_md_est: 4285,
        pop_year: -99,
        lastcensus: 2004,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SL",
        iso_a3: "SLE",
        iso_n3: "694",
        un_a3: "694",
        wb_a2: "SL",
        wb_a3: "SLE",
        woe_id: -99,
        adm0_a3_is: "SLE",
        adm0_a3_us: "SLE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "SLE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-11.438779466182055, 6.785916856305747],
            [-11.70819454593574, 6.860098374860726],
            [-12.428098924193819, 7.26294200279203],
            [-12.949049038128194, 7.798645738145738],
            [-13.124025437868482, 8.163946438016978],
            [-13.246550258832515, 8.903048610871508],
            [-12.71195756677308, 9.342711696810767],
            [-12.59671912276221, 9.62018830000197],
            [-12.425928514037565, 9.835834051955956],
            [-12.150338100625005, 9.858571682164381],
            [-11.917277390988659, 10.046983954300558],
            [-11.117481248407328, 10.045872911006285],
            [-10.8391519840833, 9.688246161330369],
            [-10.622395188835041, 9.267910061068278],
            [-10.654770473665891, 8.977178452994194],
            [-10.494315151399633, 8.715540676300435],
            [-10.505477260774668, 8.348896389189605],
            [-10.23009355309128, 8.406205552601293],
            [-10.69559485517648, 7.939464016141087],
            [-11.146704270868383, 7.396706447779536],
            [-11.19980180504828, 7.105845648624737],
            [-11.438779466182055, 6.785916856305747],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Senegal",
        sov_a3: "SEN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Senegal",
        adm0_a3: "SEN",
        geou_dif: 0,
        geounit: "Senegal",
        gu_a3: "SEN",
        su_dif: 0,
        subunit: "Senegal",
        su_a3: "SEN",
        brk_diff: 0,
        name: "Senegal",
        name_long: "Senegal",
        brk_a3: "SEN",
        brk_name: "Senegal",
        brk_group: null,
        abbrev: "Sen.",
        postal: "SN",
        formal_en: "Republic of Senegal",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Senegal",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 5,
        pop_est: 13711597,
        gdp_md_est: 21980,
        pop_year: -99,
        lastcensus: 2002,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SN",
        iso_a3: "SEN",
        iso_n3: "686",
        un_a3: "686",
        wb_a2: "SN",
        wb_a3: "SEN",
        woe_id: -99,
        adm0_a3_is: "SEN",
        adm0_a3_us: "SEN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "SEN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.713728807023468, 13.594958604379853],
            [-17.126106736712615, 14.373515733289224],
            [-17.62504269049066, 14.729540513564071],
            [-17.18517289882223, 14.919477240452863],
            [-16.700706346085923, 15.621527411354108],
            [-16.463098110407884, 16.13503611903846],
            [-16.12069007004193, 16.455662543193384],
            [-15.62366614425869, 16.369337063049812],
            [-15.135737270558817, 16.587282416240782],
            [-14.577347581428981, 16.59826365810281],
            [-14.099521450242179, 16.304302273010492],
            [-13.43573767745306, 16.03938304286619],
            [-12.830658331747516, 15.303691514542946],
            [-12.170750291380301, 14.616834214735505],
            [-12.12488745772126, 13.994727484589788],
            [-11.927716030311615, 13.422075100147394],
            [-11.55339779300543, 13.141213690641067],
            [-11.467899135778524, 12.754518947800975],
            [-11.51394283695059, 12.442987575729418],
            [-11.65830095055793, 12.386582749882834],
            [-12.203564825885634, 12.465647691289405],
            [-12.278599005573438, 12.354440008997285],
            [-12.499050665730564, 12.332089952031057],
            [-13.217818162478238, 12.575873521367967],
            [-13.700476040084325, 12.586182969610194],
            [-15.548476935274008, 12.628170070847347],
            [-15.816574266004254, 12.515567124883345],
            [-16.147716844130585, 12.547761542201187],
            [-16.677451951554573, 12.384851589401052],
            [-16.841524624081273, 13.15139394780256],
            [-15.931295945692211, 13.130284125211332],
            [-15.691000535534995, 13.270353094938455],
            [-15.511812506562935, 13.278569647672867],
            [-15.141163295949466, 13.509511623585238],
            [-14.712197231494626, 13.298206691943777],
            [-14.277701788784553, 13.280585028532242],
            [-13.844963344772408, 13.505041612192002],
            [-14.046992356817482, 13.79406789800045],
            [-14.376713833055788, 13.625680243377372],
            [-14.687030808968487, 13.630356960499784],
            [-15.08173539881382, 13.876491807505984],
            [-15.39877031092446, 13.86036876063092],
            [-15.62459632003994, 13.62358734786956],
            [-16.713728807023468, 13.594958604379853],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Somaliland",
        sov_a3: "SOL",
        adm0_dif: 0,
        level: 2,
        type: "Indeterminate",
        admin: "Somaliland",
        adm0_a3: "SOL",
        geou_dif: 0,
        geounit: "Somaliland",
        gu_a3: "SOL",
        su_dif: 0,
        subunit: "Somaliland",
        su_a3: "SOL",
        brk_diff: 1,
        name: "Somaliland",
        name_long: "Somaliland",
        brk_a3: "B30",
        brk_name: "Somaliland",
        brk_group: null,
        abbrev: "Solnd.",
        postal: "SL",
        formal_en: "Republic of Somaliland",
        formal_fr: null,
        note_adm0: "Self admin.",
        note_brk: "Self admin.; Claimed by Somalia",
        name_sort: "Somaliland",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 5,
        mapcolor13: 2,
        pop_est: 3500000,
        gdp_md_est: 12250,
        pop_year: -99,
        lastcensus: -99,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "-99",
        iso_a3: "-99",
        iso_n3: "-99",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -99,
        adm0_a3_is: "SOM",
        adm0_a3_us: "SOM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 10,
        long_len: 10,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "somaliland.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.938129510296505, 9.451748968946674],
            [48.48673587422701, 8.83762624758998],
            [47.78942, 8.003],
            [46.94832848489796, 7.996876532417388],
            [43.67875, 9.18358000000012],
            [43.29697513201876, 9.540477403191744],
            [42.92812, 10.021940000000143],
            [42.55876, 10.57258000000013],
            [42.77685184100096, 10.92687856693442],
            [43.14530480324214, 11.462039699748857],
            [43.470659620951665, 11.27770986576388],
            [43.66666832863484, 10.864169216348158],
            [44.11780358254282, 10.445538438351605],
            [44.614259067570856, 10.442205308468942],
            [45.55694054543915, 10.698029486529776],
            [46.645401238803004, 10.816549383991173],
            [47.525657586462785, 11.12722809492999],
            [48.02159630716778, 11.193063869669743],
            [48.37878380716927, 11.375481675660126],
            [48.94820641459347, 11.41062164961852],
            [48.94200524271844, 11.394266058798166],
            [48.93849124532261, 10.982327378783452],
            [48.93823286316109, 9.973500067581483],
            [48.938129510296505, 9.451748968946674],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Somalia",
        sov_a3: "SOM",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Somalia",
        adm0_a3: "SOM",
        geou_dif: 0,
        geounit: "Somalia",
        gu_a3: "SOM",
        su_dif: 0,
        subunit: "Somalia",
        su_a3: "SOM",
        brk_diff: 0,
        name: "Somalia",
        name_long: "Somalia",
        brk_a3: "SOM",
        brk_name: "Somalia",
        brk_group: null,
        abbrev: "Som.",
        postal: "SO",
        formal_en: "Federal Republic of Somalia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Somalia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 8,
        mapcolor9: 6,
        mapcolor13: 7,
        pop_est: 9832017,
        gdp_md_est: 5524,
        pop_year: -99,
        lastcensus: 1987,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SO",
        iso_a3: "SOM",
        iso_n3: "706",
        un_a3: "706",
        wb_a2: "SO",
        wb_a3: "SOM",
        woe_id: -99,
        adm0_a3_is: "SOM",
        adm0_a3_us: "SOM",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "SOM.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [49.72862, 11.5789],
            [50.25878, 11.67957],
            [50.73202, 12.0219],
            [51.1112, 12.02464],
            [51.13387, 11.74815],
            [51.04153, 11.16651],
            [51.04531, 10.6409],
            [50.83418, 10.27972],
            [50.55239, 9.19874],
            [50.07092, 8.08173],
            [49.4527, 6.80466],
            [48.59455, 5.33911],
            [47.74079, 4.2194],
            [46.56476, 2.85529],
            [45.56399, 2.04576],
            [44.06815, 1.05283],
            [43.13597, 0.2922],
            [42.04157, -0.91916],
            [41.81095, -1.44647],
            [41.58513, -1.68325],
            [40.993, -0.85829],
            [40.98105, 2.78452],
            [41.85508309264397, 3.918911920483727],
            [42.12861, 4.23413],
            [42.76967, 4.25259],
            [43.66087, 4.95755],
            [44.9636, 5.00162],
            [47.78942, 8.003],
            [48.48673587422695, 8.837626247589995],
            [48.93812951029645, 9.451748968946617],
            [48.93823286316103, 9.973500067581512],
            [48.938491245322496, 10.982327378783467],
            [48.94200524271835, 11.394266058798138],
            [48.94820475850974, 11.410617281697963],
            [49.26776, 11.43033],
            [49.72862, 11.5789],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Swaziland",
        sov_a3: "SWZ",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Swaziland",
        adm0_a3: "SWZ",
        geou_dif: 0,
        geounit: "Swaziland",
        gu_a3: "SWZ",
        su_dif: 0,
        subunit: "Swaziland",
        su_a3: "SWZ",
        brk_diff: 0,
        name: "Swaziland",
        name_long: "Swaziland",
        brk_a3: "SWZ",
        brk_name: "Swaziland",
        brk_group: null,
        abbrev: "Swz.",
        postal: "SW",
        formal_en: "Kingdom of Swaziland",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Swaziland",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 5,
        pop_est: 1123913,
        gdp_md_est: 5702,
        pop_year: -99,
        lastcensus: 2007,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SZ",
        iso_a3: "SWZ",
        iso_n3: "748",
        un_a3: "748",
        wb_a2: "SZ",
        wb_a3: "SWZ",
        woe_id: -99,
        adm0_a3_is: "SWZ",
        adm0_a3_us: "SWZ",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "SWZ.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.07166548028107, -26.73382008230491],
            [31.868060337051077, -27.177927341421277],
            [31.282773064913325, -27.285879408478998],
            [30.68596194837448, -26.74384531016953],
            [30.676608514129637, -26.398078301704608],
            [30.949666782359913, -26.022649021104147],
            [31.04407962415715, -25.731452325139443],
            [31.333157586397906, -25.66019052500895],
            [31.837777947728064, -25.84333180105135],
            [31.98577924981197, -26.291779880480227],
            [32.07166548028107, -26.73382008230491],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Chad",
        sov_a3: "TCD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Chad",
        adm0_a3: "TCD",
        geou_dif: 0,
        geounit: "Chad",
        gu_a3: "TCD",
        su_dif: 0,
        subunit: "Chad",
        su_a3: "TCD",
        brk_diff: 0,
        name: "Chad",
        name_long: "Chad",
        brk_a3: "TCD",
        brk_name: "Chad",
        brk_group: null,
        abbrev: "Chad",
        postal: "TD",
        formal_en: "Republic of Chad",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Chad",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 1,
        mapcolor9: 8,
        mapcolor13: 6,
        pop_est: 10329208,
        gdp_md_est: 15860,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "TD",
        iso_a3: "TCD",
        iso_n3: "148",
        un_a3: "148",
        wb_a2: "TD",
        wb_a3: "TCD",
        woe_id: -99,
        adm0_a3_is: "TCD",
        adm0_a3_us: "TCD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Middle Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "TCD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.495787387762903, 12.859396267137356],
            [14.595781284247607, 13.330426947477859],
            [13.95447675950561, 13.353448798063766],
            [13.956698846094127, 13.996691189016929],
            [13.540393507550789, 14.367133693901222],
            [13.97217, 15.68437],
            [15.247731154041846, 16.627305813050782],
            [15.30044111497972, 17.927949937405003],
            [15.685740594147774, 19.957180080642384],
            [15.903246697664313, 20.387618923417506],
            [15.487148064850146, 20.730414537025638],
            [15.47106, 21.04845],
            [15.096887648181848, 21.30851878507491],
            [14.8513, 22.862950000000126],
            [15.86085, 23.40972],
            [19.84926, 21.49509],
            [23.837660000000138, 19.580470000000105],
            [23.886890000000108, 15.61084],
            [23.024590000000103, 15.68072],
            [22.56795000000011, 14.944290000000137],
            [22.30351, 14.32682],
            [22.51202, 14.09318],
            [22.18329, 13.78648],
            [22.29658, 13.37232],
            [22.03759, 12.95546],
            [21.93681, 12.588180000000136],
            [22.28801, 12.64605],
            [22.49762, 12.26024],
            [22.50869, 11.67936],
            [22.87622, 11.384610000000123],
            [22.864165480244253, 11.142395127807617],
            [22.23112918466876, 10.97188873946061],
            [21.72382164885954, 10.567055568885962],
            [21.00086836109631, 9.47598521569148],
            [20.05968549976427, 9.01270600019484],
            [19.09400800952608, 9.07484691002577],
            [18.81200971850927, 8.982914536978626],
            [18.911021762780592, 8.630894680206438],
            [18.389554884523303, 8.281303615751881],
            [17.964929640380888, 7.890914008002994],
            [16.70598839688637, 7.508327541529979],
            [16.456184523187403, 7.734773667832939],
            [16.290561557691888, 7.754307359239418],
            [16.106231723706742, 7.497087917506462],
            [15.279460483469164, 7.421924546738012],
            [15.43609174974574, 7.692812404811889],
            [15.120865512765306, 8.382150173369437],
            [14.97999555833769, 8.796104234243444],
            [14.544466586981855, 8.96586131432224],
            [13.954218377344091, 9.549494940626685],
            [14.171466098699113, 10.021378282100045],
            [14.62720055508106, 9.920919297724595],
            [14.9093538753948, 9.99212942142276],
            [15.467872755605244, 9.982336737503543],
            [14.923564894275046, 10.891325181517516],
            [14.960151808337683, 11.555574042197236],
            [14.89336, 12.21905],
            [14.495787387762903, 12.859396267137356],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Togo",
        sov_a3: "TGO",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Togo",
        adm0_a3: "TGO",
        geou_dif: 0,
        geounit: "Togo",
        gu_a3: "TGO",
        su_dif: 0,
        subunit: "Togo",
        su_a3: "TGO",
        brk_diff: 0,
        name: "Togo",
        name_long: "Togo",
        brk_a3: "TGO",
        brk_name: "Togo",
        brk_group: null,
        abbrev: "Togo",
        postal: "TG",
        formal_en: "Togolese Republic",
        formal_fr: "République Togolaise",
        note_adm0: null,
        note_brk: null,
        name_sort: "Togo",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 5,
        pop_est: 6019877,
        gdp_md_est: 5118,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "TG",
        iso_a3: "TGO",
        iso_n3: "768",
        un_a3: "768",
        wb_a2: "TG",
        wb_a3: "TGO",
        woe_id: -99,
        adm0_a3_is: "TGO",
        adm0_a3_us: "TGO",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Western Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "TGO.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.865240512712319, 6.142157701029731],
            [1.060121697604927, 5.928837388528876],
            [0.836931186536333, 6.279978745952149],
            [0.570384148774849, 6.914358628767189],
            [0.490957472342245, 7.411744289576475],
            [0.712029249686878, 8.31246450442383],
            [0.461191847342121, 8.677222601756014],
            [0.365900506195885, 9.465003973829482],
            [0.367579990245389, 10.19121287682718],
            [-0.049784715159944, 10.706917832883931],
            [0.023802524423701, 11.018681748900804],
            [0.899563022474069, 10.99733938236426],
            [0.772335646171484, 10.470808213742359],
            [1.077795037448738, 10.175606594275024],
            [1.425060662450136, 9.825395412633],
            [1.46304284018467, 9.334624335157088],
            [1.664477573258381, 9.12859039960938],
            [1.618950636409238, 6.832038072126237],
            [1.865240512712319, 6.142157701029731],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Tunisia",
        sov_a3: "TUN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Tunisia",
        adm0_a3: "TUN",
        geou_dif: 0,
        geounit: "Tunisia",
        gu_a3: "TUN",
        su_dif: 0,
        subunit: "Tunisia",
        su_a3: "TUN",
        brk_diff: 0,
        name: "Tunisia",
        name_long: "Tunisia",
        brk_a3: "TUN",
        brk_name: "Tunisia",
        brk_group: null,
        abbrev: "Tun.",
        postal: "TN",
        formal_en: "Republic of Tunisia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Tunisia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 2,
        pop_est: 10486339,
        gdp_md_est: 81710,
        pop_year: -99,
        lastcensus: 2004,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "TN",
        iso_a3: "TUN",
        iso_n3: "788",
        un_a3: "788",
        wb_a2: "TN",
        wb_a3: "TUN",
        woe_id: -99,
        adm0_a3_is: "TUN",
        adm0_a3_us: "TUN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Northern Africa",
        region_wb: "Middle East & North Africa",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "TUN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.482139926805274, 30.307556057246188],
            [9.055602654668148, 32.10269196220129],
            [8.439102817426118, 32.50628489840082],
            [8.430472853233368, 32.74833730725595],
            [7.612641635782182, 33.34411489514896],
            [7.524481642292244, 34.09737641045146],
            [8.140981479534304, 34.65514598239379],
            [8.376367628623768, 35.47987600355594],
            [8.217824334352315, 36.433176988260286],
            [8.420964389691676, 36.94642731378316],
            [9.509993523810607, 37.349994411766545],
            [10.210002475636317, 37.230001735984814],
            [10.18065026209453, 36.724037787415085],
            [11.028867221733348, 37.09210317641396],
            [11.100025668999251, 36.899996039368915],
            [10.600004510143094, 36.410000108377375],
            [10.593286573945136, 35.94744436293281],
            [10.939518670300687, 35.698984076473494],
            [10.807847120821009, 34.83350718844919],
            [10.149592726287125, 34.3307730168977],
            [10.339658644256616, 33.78574168551532],
            [10.856836378633687, 33.76874013929128],
            [11.108500603895122, 33.293342800422195],
            [11.48878746913101, 33.13699575452314],
            [11.432253452203696, 32.368903103152874],
            [10.944789666394456, 32.081814683555365],
            [10.636901482799487, 31.761420803345754],
            [9.950225050505082, 31.376069647745254],
            [10.056575148161755, 30.9618313664936],
            [9.970017124072854, 30.539324856075243],
            [9.482139926805274, 30.307556057246188],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "United Republic of Tanzania",
        sov_a3: "TZA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "United Republic of Tanzania",
        adm0_a3: "TZA",
        geou_dif: 0,
        geounit: "Tanzania",
        gu_a3: "TZA",
        su_dif: 0,
        subunit: "Tanzania",
        su_a3: "TZA",
        brk_diff: 0,
        name: "Tanzania",
        name_long: "Tanzania",
        brk_a3: "TZA",
        brk_name: "Tanzania",
        brk_group: null,
        abbrev: "Tanz.",
        postal: "TZ",
        formal_en: "United Republic of Tanzania",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Tanzania",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 6,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 41048532,
        gdp_md_est: 54250,
        pop_year: -99,
        lastcensus: 2002,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "TZ",
        iso_a3: "TZA",
        iso_n3: "834",
        un_a3: "834",
        wb_a2: "TZ",
        wb_a3: "TZA",
        woe_id: -99,
        adm0_a3_is: "TZA",
        adm0_a3_us: "TZA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "TZA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.9037111971046, -0.95],
            [34.07262, -1.05982],
            [37.69869, -3.09699],
            [37.7669, -3.67712],
            [39.20222, -4.67677],
            [38.74054, -5.90895],
            [38.79977, -6.47566],
            [39.44, -6.839999999999861],
            [39.470000000000134, -7.1],
            [39.19469, -7.7039],
            [39.25203, -8.00781],
            [39.18652, -8.48551],
            [39.53574, -9.112369999999883],
            [39.9496, -10.0984],
            [40.31659, -10.317099999999868],
            [39.521, -10.89688],
            [38.42755659358778, -11.285202325081627],
            [37.82764, -11.26879],
            [37.47129, -11.56876],
            [36.77515099462289, -11.594537448780784],
            [36.514081658684404, -11.720938002166747],
            [35.31239790216915, -11.439146416879169],
            [34.559989047999466, -11.520020033415847],
            [34.28, -10.16],
            [33.940837724096525, -9.693673841980285],
            [33.73972, -9.41715],
            [32.75937544122138, -9.230599053589003],
            [32.19186486179194, -8.930358981973257],
            [31.556348097466635, -8.762048841998647],
            [31.15775133695007, -8.594578747317314],
            [30.74, -8.34],
            [30.2, -7.08],
            [29.62, -6.52],
            [29.419992710088305, -5.939998874539299],
            [29.51998660657307, -5.419978936386258],
            [29.33999759290037, -4.499983412294114],
            [29.753512404099865, -4.452389418153302],
            [30.11632, -4.09012],
            [30.50554, -3.56858],
            [30.75224, -3.35931],
            [30.74301, -3.03431],
            [30.52766, -2.80762],
            [30.46967, -2.41383],
            [30.758308953583136, -2.287250257988376],
            [30.81613488131785, -1.698914076345375],
            [30.4191048520193, -1.134659112150416],
            [30.769860000000108, -1.01455],
            [31.86617, -1.02736],
            [33.9037111971046, -0.95],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Uganda",
        sov_a3: "UGA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Uganda",
        adm0_a3: "UGA",
        geou_dif: 0,
        geounit: "Uganda",
        gu_a3: "UGA",
        su_dif: 0,
        subunit: "Uganda",
        su_a3: "UGA",
        brk_diff: 0,
        name: "Uganda",
        name_long: "Uganda",
        brk_a3: "UGA",
        brk_name: "Uganda",
        brk_group: null,
        abbrev: "Uga.",
        postal: "UG",
        formal_en: "Republic of Uganda",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Uganda",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 6,
        mapcolor13: 4,
        pop_est: 32369558,
        gdp_md_est: 39380,
        pop_year: -99,
        lastcensus: 2002,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "UG",
        iso_a3: "UGA",
        iso_n3: "800",
        un_a3: "800",
        wb_a2: "UG",
        wb_a3: "UGA",
        woe_id: -99,
        adm0_a3_is: "UGA",
        adm0_a3_us: "UGA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "UGA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.86617, -1.02736],
            [30.769860000000108, -1.01455],
            [30.4191048520193, -1.134659112150416],
            [29.821518588996124, -1.443322442229771],
            [29.579466180141022, -1.341313164885605],
            [29.58783776217217, -0.587405694179381],
            [29.8195, -0.2053],
            [29.875778842902434, 0.597379868976361],
            [30.08615359876279, 1.062312730306417],
            [30.46850752129029, 1.583805446779706],
            [30.852670118948136, 1.849396470543752],
            [31.17414920423596, 2.204465236821306],
            [30.77332, 2.339890000000139],
            [30.83385, 3.50917],
            [31.24556, 3.7819],
            [31.88145, 3.55827],
            [32.68642, 3.79232],
            [33.3900000000001, 3.79],
            [34.005, 4.249884947362147],
            [34.47913, 3.5556],
            [34.59607, 3.053740000000118],
            [35.03599, 1.90584],
            [34.6721, 1.17694],
            [34.18, 0.515],
            [33.893568969666994, 0.109813537861839],
            [33.9037111971046, -0.95],
            [31.86617, -1.02736],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "South Africa",
        sov_a3: "ZAF",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "South Africa",
        adm0_a3: "ZAF",
        geou_dif: 0,
        geounit: "South Africa",
        gu_a3: "ZAF",
        su_dif: 0,
        subunit: "South Africa",
        su_a3: "ZAF",
        brk_diff: 0,
        name: "South Africa",
        name_long: "South Africa",
        brk_a3: "ZAF",
        brk_name: "South Africa",
        brk_group: null,
        abbrev: "S.Af.",
        postal: "ZA",
        formal_en: "Republic of South Africa",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "South Africa",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 2,
        pop_est: 49052489,
        gdp_md_est: 491000,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "5. Emerging region: G20",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "ZA",
        iso_a3: "ZAF",
        iso_n3: "710",
        un_a3: "710",
        wb_a2: "ZA",
        wb_a3: "ZAF",
        woe_id: -99,
        adm0_a3_is: "ZAF",
        adm0_a3_us: "ZAF",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Southern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 12,
        long_len: 12,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "ZAF.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.521001417778876, -29.257386976846252],
            [31.325561150851, -29.401977634398914],
            [30.901762729625343, -29.90995696382804],
            [30.622813348113823, -30.42377573010613],
            [30.05571618014278, -31.140269463832958],
            [28.925552605919535, -32.17204111097249],
            [28.2197558936771, -32.771952813448856],
            [27.464608188595975, -33.2269637997788],
            [26.419452345492825, -33.61495045342619],
            [25.90966434093349, -33.6670402971764],
            [25.780628289500697, -33.944646091448334],
            [25.172861769315972, -33.796851495093584],
            [24.677853224392123, -33.98717579522455],
            [23.594043409934642, -33.794474379208154],
            [22.988188917744733, -33.91643075941698],
            [22.574157342222236, -33.864082533505304],
            [21.542799106541025, -34.258838799782936],
            [20.689052768647002, -34.417175388325234],
            [20.071261020597632, -34.79513681410799],
            [19.61640506356457, -34.81916635512371],
            [19.193278435958717, -34.46259897230979],
            [18.85531456876987, -34.444305515278465],
            [18.42464318204938, -33.99787281670896],
            [18.377410922934615, -34.13652068454807],
            [18.244499139079917, -33.86775156019802],
            [18.250080193767445, -33.28143075941444],
            [17.92519046394844, -32.61129078545343],
            [18.247909783611192, -32.42913136162456],
            [18.22176150887148, -31.66163298922567],
            [17.56691775886887, -30.725721123987547],
            [17.064416131262703, -29.87864104585916],
            [17.062917514726223, -29.875953871379984],
            [16.344976840895242, -28.576705010697697],
            [16.824017368240902, -28.082161553664466],
            [17.218928663815404, -28.35594329194681],
            [17.387497185951503, -28.78351409272978],
            [17.83615197110953, -28.85637786226132],
            [18.464899122804752, -29.04546192801728],
            [19.002127312911085, -28.972443129188864],
            [19.894734327888614, -28.461104831660776],
            [19.895767856534434, -24.767790215760588],
            [20.165725538827186, -24.917961928000768],
            [20.758609246511835, -25.86813648855145],
            [20.66647016773544, -26.477453301704923],
            [20.88960900237174, -26.828542982695915],
            [21.60589603036939, -26.726533705351756],
            [22.105968865657868, -26.280256036079138],
            [22.57953169118059, -25.979447523708146],
            [22.8242712745149, -25.500458672794768],
            [23.312096795350186, -25.26868987396572],
            [23.73356977712271, -25.390129489851613],
            [24.211266717228792, -25.670215752873574],
            [25.025170525825786, -25.7196700985769],
            [25.66466637543772, -25.486816094669713],
            [25.76584882986521, -25.174845472923675],
            [25.94165205252216, -24.69637338633322],
            [26.4857532081233, -24.616326592713104],
            [26.786406691197413, -24.240690606383485],
            [27.119409620886245, -23.574323011979775],
            [28.01723595552525, -22.827753594659075],
            [29.43218834810904, -22.091312758067588],
            [29.839036899542972, -22.102216485281176],
            [30.322883335091774, -22.27161183033393],
            [30.65986535006709, -22.151567478119915],
            [31.191409132621285, -22.2515096981724],
            [31.670397983534652, -23.658969008073864],
            [31.93058882012425, -24.369416599222536],
            [31.75240848158188, -25.484283949487413],
            [31.837777947728064, -25.84333180105135],
            [31.333157586397906, -25.66019052500895],
            [31.04407962415715, -25.731452325139443],
            [30.949666782359913, -26.022649021104147],
            [30.676608514129637, -26.398078301704608],
            [30.68596194837448, -26.74384531016953],
            [31.282773064913325, -27.285879408478998],
            [31.868060337051077, -27.177927341421277],
            [32.07166548028107, -26.73382008230491],
            [32.830120477028885, -26.742191664336197],
            [32.580264926897684, -27.470157566031816],
            [32.46213260267845, -28.301011244420557],
            [32.20338870619304, -28.752404880490072],
            [31.521001417778876, -29.257386976846252],
          ],
          [
            [28.97826256685724, -28.955596612261715],
            [28.541700066855498, -28.64750172293757],
            [28.07433841320778, -28.851468601193588],
            [27.532511020627478, -29.24271087007536],
            [26.999261915807637, -29.875953871379984],
            [27.749397006956485, -30.64510588961222],
            [28.107204624145425, -30.54573211031495],
            [28.29106937023991, -30.2262167294543],
            [28.84839969250774, -30.070050551068253],
            [29.018415154748023, -29.74376555757737],
            [29.325166456832587, -29.257386976846252],
            [28.97826256685724, -28.955596612261715],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Zambia",
        sov_a3: "ZMB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Zambia",
        adm0_a3: "ZMB",
        geou_dif: 0,
        geounit: "Zambia",
        gu_a3: "ZMB",
        su_dif: 0,
        subunit: "Zambia",
        su_a3: "ZMB",
        brk_diff: 0,
        name: "Zambia",
        name_long: "Zambia",
        brk_a3: "ZMB",
        brk_name: "Zambia",
        brk_group: null,
        abbrev: "Zambia",
        postal: "ZM",
        formal_en: "Republic of Zambia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Zambia",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 8,
        mapcolor9: 5,
        mapcolor13: 13,
        pop_est: 11862740,
        gdp_md_est: 17500,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "ZM",
        iso_a3: "ZMB",
        iso_n3: "894",
        un_a3: "894",
        wb_a2: "ZM",
        wb_a3: "ZMB",
        woe_id: -99,
        adm0_a3_is: "ZMB",
        adm0_a3_us: "ZMB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "ZMB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.75937544122132, -9.230599053589058],
            [33.2313879737753, -9.6767216935648],
            [33.48568769708359, -10.525558770391115],
            [33.315310499817286, -10.796549981329697],
            [33.11428917820191, -11.607198174692314],
            [33.306422153463075, -12.435778090060218],
            [32.991764357237884, -12.783870537978272],
            [32.68816531752313, -13.712857761289275],
            [33.214024692525214, -13.971860039936153],
            [30.17948123548183, -14.796099134991527],
            [30.274255812305107, -15.507786960515212],
            [29.516834344203147, -15.644677829656388],
            [28.947463413211263, -16.04305144619444],
            [28.825868768028496, -16.389748630440614],
            [28.467906121542683, -16.468400160388846],
            [27.598243442502756, -17.290830580314008],
            [27.04442711763073, -17.938026218337434],
            [26.70677330903564, -17.961228936436484],
            [26.381935255648926, -17.8460421688579],
            [25.264225701608012, -17.736539808831417],
            [25.08444339366457, -17.661815687737374],
            [25.07695031098226, -17.57882333747662],
            [24.682349074001507, -17.353410739819473],
            [24.033861525170778, -17.295843194246324],
            [23.215048455506064, -17.52311614346598],
            [22.56247846852426, -16.898451429921813],
            [21.887842644953874, -16.08031015387688],
            [21.933886346125917, -12.898437188369359],
            [24.016136508894675, -12.911046237848574],
            [23.930922072045377, -12.565847670138854],
            [24.079905226342845, -12.191296888887365],
            [23.904153680118185, -11.722281589406322],
            [24.017893507592586, -11.23729827234709],
            [23.912215203555718, -10.926826267137514],
            [24.25715538910399, -10.951992689663657],
            [24.31451622894795, -11.26282642989927],
            [24.78316979340295, -11.238693536018964],
            [25.418118116973204, -11.330935967659961],
            [25.752309604604733, -11.784965101776358],
            [26.553087599399618, -11.924439792532127],
            [27.164419793412463, -11.608748467661075],
            [27.38879886242378, -12.132747491100666],
            [28.155108676879987, -12.272480564017897],
            [28.523561639121027, -12.698604424696683],
            [28.934285922976837, -13.248958428605135],
            [29.69961388521949, -13.257226657771831],
            [29.61600141777123, -12.178894545137311],
            [29.34154788586909, -12.360743910372413],
            [28.642417433392353, -11.971568698782315],
            [28.372253045370428, -11.793646742401393],
            [28.49606977714177, -10.789883721564044],
            [28.67368167492893, -9.605924981324932],
            [28.449871046672826, -9.164918308146085],
            [28.7348665707625, -8.526559340044578],
            [29.002912225060467, -8.407031752153472],
            [30.346086053190813, -8.238256524288218],
            [30.740015496551788, -8.340007419470915],
            [31.15775133695005, -8.594578747317366],
            [31.556348097466497, -8.762048841998642],
            [32.19186486179197, -8.930358981973278],
            [32.75937544122132, -9.230599053589058],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Zimbabwe",
        sov_a3: "ZWE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Zimbabwe",
        adm0_a3: "ZWE",
        geou_dif: 0,
        geounit: "Zimbabwe",
        gu_a3: "ZWE",
        su_dif: 0,
        subunit: "Zimbabwe",
        su_a3: "ZWE",
        brk_diff: 0,
        name: "Zimbabwe",
        name_long: "Zimbabwe",
        brk_a3: "ZWE",
        brk_name: "Zimbabwe",
        brk_group: null,
        abbrev: "Zimb.",
        postal: "ZW",
        formal_en: "Republic of Zimbabwe",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Zimbabwe",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 5,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 12619600,
        gdp_md_est: 9323,
        pop_year: 0,
        lastcensus: 2002,
        gdp_year: 0,
        economy: "5. Emerging region: G20",
        income_grp: "5. Low income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "ZW",
        iso_a3: "ZWE",
        iso_n3: "716",
        un_a3: "716",
        wb_a2: "ZW",
        wb_a3: "ZWE",
        woe_id: -99,
        adm0_a3_is: "ZWE",
        adm0_a3_us: "ZWE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Africa",
        region_un: "Africa",
        subregion: "Eastern Africa",
        region_wb: "Sub-Saharan Africa",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "ZWE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.191409132621285, -22.2515096981724],
            [30.65986535006709, -22.151567478119915],
            [30.322883335091774, -22.27161183033393],
            [29.839036899542972, -22.102216485281176],
            [29.43218834810904, -22.091312758067588],
            [28.794656202924212, -21.63945403410745],
            [28.021370070108617, -21.485975030200585],
            [27.72722781750326, -20.851801853114715],
            [27.724747348753255, -20.499058526290387],
            [27.296504754350508, -20.391519870691],
            [26.164790887158485, -19.29308562589494],
            [25.85039147309473, -18.714412937090536],
            [25.649163445750162, -18.53602589281899],
            [25.264225701608012, -17.736539808831417],
            [26.381935255648926, -17.8460421688579],
            [26.70677330903564, -17.961228936436484],
            [27.04442711763073, -17.938026218337434],
            [27.598243442502756, -17.290830580314008],
            [28.467906121542683, -16.468400160388846],
            [28.825868768028496, -16.389748630440614],
            [28.947463413211263, -16.04305144619444],
            [29.516834344203147, -15.644677829656388],
            [30.274255812305107, -15.507786960515212],
            [30.338954705534544, -15.880839125230244],
            [31.173063999157677, -15.860943698797872],
            [31.636498243951195, -16.071990248277885],
            [31.8520406430406, -16.319417006091378],
            [32.32823896661022, -16.392074069893752],
            [32.847638787575846, -16.713398125884616],
            [32.84986087416439, -17.97905730557718],
            [32.65488569512715, -18.672089939043495],
            [32.61199425632489, -19.419382826416275],
            [32.772707960752626, -19.715592136313298],
            [32.65974327976258, -20.304290052982317],
            [32.50869306817344, -20.395292250248307],
            [32.244988234188014, -21.116488539313693],
            [31.191409132621285, -22.2515096981724],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Australia",
        sov_a3: "AU1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        admin: "Australia",
        adm0_a3: "AUS",
        geou_dif: 0,
        geounit: "Australia",
        gu_a3: "AUS",
        su_dif: 0,
        subunit: "Australia",
        su_a3: "AUS",
        brk_diff: 0,
        name: "Australia",
        name_long: "Australia",
        brk_a3: "AUS",
        brk_name: "Australia",
        brk_group: null,
        abbrev: "Auz.",
        postal: "AU",
        formal_en: "Commonwealth of Australia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Australia",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 7,
        pop_est: 21262641,
        gdp_md_est: 800200,
        pop_year: -99,
        lastcensus: 2006,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "AU",
        iso_a3: "AUS",
        iso_n3: "036",
        un_a3: "036",
        wb_a2: "AU",
        wb_a3: "AUS",
        woe_id: -99,
        adm0_a3_is: "AUS",
        adm0_a3_us: "AUS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Australia and New Zealand",
        region_wb: "East Asia & Pacific",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "AUS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [145.39797814349484, -40.79254851660589],
              [146.36412072162372, -41.13769540788334],
              [146.90858361225085, -41.00054615658068],
              [147.68925947488415, -40.80825815202269],
              [148.28906782449602, -40.87543751400213],
              [148.35986453673584, -42.06244516374644],
              [148.0173014670731, -42.407023614268624],
              [147.9140519553538, -43.211522312188485],
              [147.564564243764, -42.93768889747386],
              [146.87034305235494, -43.634597263362096],
              [146.66332726459368, -43.58085377377856],
              [146.04837772032042, -43.54974456153889],
              [145.43192955951056, -42.69377613705627],
              [145.2950903668017, -42.03360971452756],
              [144.71807132383063, -41.162551771815714],
              [144.74375451067968, -40.70397511165771],
              [145.39797814349484, -40.79254851660589],
            ],
          ],
          [
            [
              [143.56181115129996, -13.763655694232213],
              [143.92209923723894, -14.548310642152003],
              [144.56371382057486, -14.171176039285882],
              [144.89490807513354, -14.594457696188625],
              [145.37472374896348, -14.984976495018286],
              [145.27199100156727, -15.428205254785695],
              [145.48525963763578, -16.285672295804773],
              [145.63703331927695, -16.784918308176614],
              [145.88890425026767, -16.90692636481765],
              [146.1603088726645, -17.761654554925244],
              [146.0636739442787, -18.28007252367732],
              [146.3874784690196, -18.95827402107591],
              [147.47108157774792, -19.48072275154668],
              [148.1776017600425, -19.95593922290277],
              [148.84841352762322, -20.39120981209726],
              [148.7174654481956, -20.633468926681516],
              [149.28942020080206, -21.260510756111103],
              [149.67833703023067, -22.342511895438392],
              [150.07738244038862, -22.12278370533332],
              [150.48293908101516, -22.556142266533012],
              [150.72726525289121, -22.40240488046466],
              [150.89955447815228, -23.462236830338682],
              [151.60917524638424, -24.076256198830762],
              [152.07353966695908, -24.457886651306197],
              [152.85519738180594, -25.267501316023015],
              [153.13616214417678, -26.07117319102619],
              [153.16194868389042, -26.641319268502443],
              [153.0929089703486, -27.26029957449451],
              [153.5694690289442, -28.1100668271021],
              [153.51210818910022, -28.995077406532758],
              [153.33909549378706, -29.458201592732447],
              [153.06924116435889, -30.350240166954816],
              [153.0896016786818, -30.923641859665448],
              [152.8915775901394, -31.640445651985956],
              [152.45000247620536, -32.550002536755244],
              [151.70911746643682, -33.041342054986345],
              [151.34397179586242, -33.81602345147385],
              [151.01055545471516, -34.310360202777886],
              [150.71413943908905, -35.17345997491681],
              [150.32821984273326, -35.67187916437193],
              [150.07521203023228, -36.42020558039051],
              [149.94612430236717, -37.10905242284123],
              [149.99728397033616, -37.42526051203514],
              [149.42388227762555, -37.77268116633346],
              [148.30462243061592, -37.80906137466688],
              [147.3817330263153, -38.21921721776755],
              [146.92212283751135, -38.60653207779512],
              [146.3179219911548, -39.03575652441144],
              [145.48965213438058, -38.59376799901905],
              [144.87697635312816, -38.41744801203912],
              [145.03221235573298, -37.896187839510986],
              [144.48568240781404, -38.08532358169927],
              [143.6099735861961, -38.80946542740533],
              [142.745426873953, -38.538267510737526],
              [142.178329705982, -38.38003427505984],
              [141.6065816591047, -38.30851409276788],
              [140.63857872941324, -38.019332777662555],
              [139.99215823787435, -37.40293629328511],
              [139.80658816951407, -36.64360279718828],
              [139.57414757706525, -36.13836231867067],
              [139.0828080588341, -35.73275400161178],
              [138.12074791885632, -35.612296237939404],
              [138.44946170466503, -35.127261244447894],
              [138.2075643251067, -34.38472258884593],
              [137.71917036351616, -35.07682504653103],
              [136.82940555231474, -35.26053476332862],
              [137.3523710471085, -34.70733855564409],
              [137.50388634658836, -34.130267836240776],
              [137.89011600153768, -33.640478610978334],
              [137.81032759007914, -32.90000701266811],
              [136.99683719294038, -33.752771498348636],
              [136.37206912653167, -34.09476612725619],
              [135.98904341038437, -34.89011809666049],
              [135.20821251845413, -34.47867034275261],
              [135.23921837782916, -33.94795338311498],
              [134.6134167827746, -33.22277800876314],
              [134.08590376193914, -32.848072198214766],
              [134.27390262261704, -32.61723357516696],
              [132.99077680880984, -32.011224053680195],
              [132.2880806825049, -31.98264698662277],
              [131.32633060112093, -31.495803318001048],
              [129.5357938986397, -31.590422865527483],
              [128.24093753470223, -31.948488864877856],
              [127.10286746633831, -32.28226694105105],
              [126.14871382050116, -32.21596607842061],
              [125.08862348846561, -32.728751316052836],
              [124.22164798390494, -32.95948658623607],
              [124.02894656788854, -33.483847344701715],
              [123.65966678273072, -33.89017913181273],
              [122.81103641163364, -33.91446705498984],
              [122.18306440642286, -34.003402194964224],
              [121.2991907085026, -33.82103606540613],
              [120.58026818245814, -33.930176690406626],
              [119.89369510302824, -33.976065362281815],
              [119.2988993673488, -34.50936614353397],
              [119.007340936358, -34.464149265278536],
              [118.5057178081008, -34.7468193499151],
              [118.02497195848953, -35.064732761374714],
              [117.29550744025747, -35.02545867283287],
              [116.62510908413495, -35.02509693780683],
              [115.56434695847972, -34.386427911111554],
              [115.02680870977954, -34.196517022438925],
              [115.04861616420679, -33.62342538832203],
              [115.5451233256671, -33.48725798923296],
              [115.71467370001668, -33.25957162855495],
              [115.6793786967614, -32.90036874769413],
              [115.80164513556397, -32.20506235120703],
              [115.68961063035513, -31.61243702568379],
              [115.16090905157697, -30.60159433362246],
              [114.99704308477945, -30.030724786094165],
              [115.04003787644628, -29.461095472940798],
              [114.64197431850201, -28.810230808224713],
              [114.61649783738217, -28.516398614213042],
              [114.17357913620847, -28.11807667410733],
              [114.04888390508816, -27.334765313427127],
              [113.4774975932369, -26.543134047147902],
              [113.3389530782625, -26.116545098578484],
              [113.77835778204026, -26.54902516042918],
              [113.44096235560662, -25.621278171493156],
              [113.93690107631167, -25.911234633082884],
              [114.23285200404732, -26.298446140245872],
              [114.21616051641703, -25.786281019801105],
              [113.72125532435771, -24.998938897402127],
              [113.62534386602405, -24.683971042583153],
              [113.39352339076267, -24.38476449961327],
              [113.50204389857564, -23.806350192970257],
              [113.70699262904517, -23.560215345964068],
              [113.8434184102957, -23.059987481378737],
              [113.7365515483161, -22.47547535572538],
              [114.1497563009219, -21.755881036061012],
              [114.22530724493268, -22.517488295178634],
              [114.64776207891869, -21.829519952076904],
              [115.46016727097933, -21.495173435148544],
              [115.94737267462702, -21.06868783944371],
              [116.71161543179156, -20.70168181730682],
              [117.16631635952771, -20.623598728113805],
              [117.44154503791427, -20.746898695562162],
              [118.22955895393298, -20.374208265873236],
              [118.83608523974273, -20.26331064217483],
              [118.98780724495177, -20.044202569257322],
              [119.25249393115065, -19.95294198982984],
              [119.80522505094457, -19.976506442954985],
              [120.85622033089666, -19.68370777758919],
              [121.39985639860723, -19.239755547769732],
              [121.65513797412909, -18.705317885007133],
              [122.24166548064177, -18.19764861417177],
              [122.28662397673567, -17.798603204013915],
              [122.31277225147544, -17.25496713630345],
              [123.01257449757193, -16.405199883695857],
              [123.43378909718304, -17.268558037996225],
              [123.85934451710662, -17.069035332917252],
              [123.50324222218326, -16.596506036040367],
              [123.81707319549193, -16.111316013251994],
              [124.25828657439988, -16.327943617419564],
              [124.37972619028582, -15.567059828353976],
              [124.92615278534005, -15.075100192935324],
              [125.16727501841389, -14.680395603090004],
              [125.67008670461385, -14.510070082256021],
              [125.6857963400305, -14.230655612853838],
              [126.12514936737611, -14.347340996968953],
              [126.14282270721989, -14.095986830301213],
              [126.58258914602376, -13.95279143642041],
              [127.06586714081735, -13.817967624570926],
              [127.80463341686195, -14.276906019755046],
              [128.35968997610897, -14.869169610252257],
              [128.98554324759593, -14.875990899314742],
              [129.62147342337963, -14.969783623924556],
              [129.409600050983, -14.420669854391035],
              [129.88864057832862, -13.618703301653483],
              [130.33946577364296, -13.357375583553477],
              [130.183506300986, -13.107520033422304],
              [130.617795037967, -12.536392103732467],
              [131.22349450086003, -12.183648776908115],
              [131.73509118054952, -12.302452894747162],
              [132.57529829318312, -12.114040622611014],
              [132.55721154188106, -11.603012383676685],
              [131.82469811414367, -11.273781833545101],
              [132.35722374891142, -11.128519382372644],
              [133.01956058159644, -11.376411228076847],
              [133.55084598198906, -11.786515394745138],
              [134.393068475482, -12.042365411022175],
              [134.67863244032705, -11.9411829565947],
              [135.29849124566803, -12.248606052299051],
              [135.88269331272764, -11.962266940969798],
              [136.25838097548947, -12.04934172938161],
              [136.49247521377166, -11.857208754120393],
              [136.95162031468502, -12.351958916882737],
              [136.68512495335577, -12.887223402562057],
              [136.30540652887512, -13.291229750219898],
              [135.96175825413414, -13.324509372615893],
              [136.07761681533256, -13.724278252825783],
              [135.78383629775325, -14.223989353088214],
              [135.42866417861123, -14.7154322241839],
              [135.50018436090318, -14.99774057379443],
              [136.29517459528137, -15.550264987859123],
              [137.0653601421595, -15.870762220933356],
              [137.58047081924482, -16.215082289294084],
              [138.303217401279, -16.807604261952658],
              [138.5851640158634, -16.806622409739177],
              [139.1085429221155, -17.06267913174537],
              [139.26057498591823, -17.371600843986187],
              [140.2152453960783, -17.710804945550066],
              [140.87546349503927, -17.369068698803943],
              [141.0711104676963, -16.832047214426723],
              [141.27409549373883, -16.388870131091608],
              [141.3982222841038, -15.840531508042588],
              [141.70218305884467, -15.04492115647693],
              [141.5633801617087, -14.56133310308951],
              [141.63552046118812, -14.270394789286284],
              [141.51986860571898, -13.698078301653808],
              [141.650920038011, -12.944687595270565],
              [141.84269127824624, -12.74154753993119],
              [141.6869901877508, -12.407614434461138],
              [141.92862918514757, -11.87746591557878],
              [142.118488397388, -11.32804208745162],
              [142.14370649634637, -11.042736504768143],
              [142.51526004452498, -10.668185723516643],
              [142.79731001197408, -11.157354831591519],
              [142.8667631369743, -11.784706719614931],
              [143.1159468934857, -11.905629571177911],
              [143.1586316265588, -12.325655612846191],
              [143.5221236512999, -12.834358412327433],
              [143.5971578309877, -13.400422051652598],
              [143.56181115129996, -13.763655694232213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Fiji",
        sov_a3: "FJI",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Fiji",
        adm0_a3: "FJI",
        geou_dif: 0,
        geounit: "Fiji",
        gu_a3: "FJI",
        su_dif: 0,
        subunit: "Fiji",
        su_a3: "FJI",
        brk_diff: 0,
        name: "Fiji",
        name_long: "Fiji",
        brk_a3: "FJI",
        brk_name: "Fiji",
        brk_group: null,
        abbrev: "Fiji",
        postal: "FJ",
        formal_en: "Republic of Fiji",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Fiji",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 944720,
        gdp_md_est: 3579,
        pop_year: -99,
        lastcensus: 2007,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "FJ",
        iso_a3: "FJI",
        iso_n3: "242",
        un_a3: "242",
        wb_a2: "FJ",
        wb_a3: "FJI",
        woe_id: -99,
        adm0_a3_is: "FJI",
        adm0_a3_us: "FJI",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 4,
        long_len: 4,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "FJI.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [178.3736, -17.33992],
              [178.71806, -17.62846],
              [178.55271, -18.15059],
              [177.93266, -18.28799],
              [177.38146, -18.16432],
              [177.28504, -17.72465],
              [177.67087, -17.38114],
              [178.12557, -17.50481],
              [178.3736, -17.33992],
            ],
          ],
          [
            [
              [179.36414266196428, -16.80135407694685],
              [178.7250593629971, -17.01204167436802],
              [178.59683859511708, -16.63915],
              [179.09660936299716, -16.43398427754742],
              [179.41350936299713, -16.379054277547397],
              [180.00000000000014, -16.06713266364244],
              [180.00000000000014, -16.55521656663916],
              [179.36414266196428, -16.80135407694685],
            ],
          ],
          [
            [
              [-179.91736938476524, -16.50178313564936],
              [-180, -16.55521656663916],
              [-180, -16.06713266364244],
              [-179.79332010904858, -16.02088225674123],
              [-179.91736938476524, -16.50178313564936],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "France",
        sov_a3: "FR1",
        adm0_dif: 1,
        level: 2,
        type: "Dependency",
        admin: "New Caledonia",
        adm0_a3: "NCL",
        geou_dif: 0,
        geounit: "New Caledonia",
        gu_a3: "NCL",
        su_dif: 0,
        subunit: "New Caledonia",
        su_a3: "NCL",
        brk_diff: 0,
        name: "New Caledonia",
        name_long: "New Caledonia",
        brk_a3: "NCL",
        brk_name: "New Caledonia",
        brk_group: null,
        abbrev: "New C.",
        postal: "NC",
        formal_en: "New Caledonia",
        formal_fr: "Nouvelle-Calédonie",
        note_adm0: "Fr.",
        note_brk: null,
        name_sort: "New Caledonia",
        name_alt: null,
        mapcolor7: 7,
        mapcolor8: 5,
        mapcolor9: 9,
        mapcolor13: 11,
        pop_est: 227436,
        gdp_md_est: 3158,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "NC",
        iso_a3: "NCL",
        iso_n3: "540",
        un_a3: "540",
        wb_a2: "NC",
        wb_a3: "NCL",
        woe_id: -99,
        adm0_a3_is: "NCL",
        adm0_a3_us: "NCL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 13,
        long_len: 13,
        abbrev_len: 6,
        tiny: -99,
        homepart: -99,
        filename: "NCL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [165.77998986232637, -21.08000497811563],
            [166.59999148993384, -21.700018812753523],
            [167.1200114280869, -22.159990736583488],
            [166.74003462144478, -22.39997608814695],
            [166.18973229396866, -22.12970834726045],
            [165.47437544175222, -21.679606621998232],
            [164.82981530177568, -21.14981983814195],
            [164.16799523341365, -20.444746595951628],
            [164.029605747736, -20.105645847252354],
            [164.45996707586272, -20.1200118954295],
            [165.02003624904205, -20.45999114347773],
            [165.46000939357512, -20.80002206795826],
            [165.77998986232637, -21.08000497811563],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "New Zealand",
        sov_a3: "NZ1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        admin: "New Zealand",
        adm0_a3: "NZL",
        geou_dif: 0,
        geounit: "New Zealand",
        gu_a3: "NZL",
        su_dif: 0,
        subunit: "New Zealand",
        su_a3: "NZL",
        brk_diff: 0,
        name: "New Zealand",
        name_long: "New Zealand",
        brk_a3: "NZL",
        brk_name: "New Zealand",
        brk_group: null,
        abbrev: "N.Z.",
        postal: "NZ",
        formal_en: "New Zealand",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "New Zealand",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 4,
        mapcolor13: 4,
        pop_est: 4213418,
        gdp_md_est: 116700,
        pop_year: -99,
        lastcensus: 2006,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "NZ",
        iso_a3: "NZL",
        iso_n3: "554",
        un_a3: "554",
        wb_a2: "NZ",
        wb_a3: "NZL",
        woe_id: -99,
        adm0_a3_is: "NZL",
        adm0_a3_us: "NZL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Australia and New Zealand",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 11,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "NZL.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [173.02037479074076, -40.919052422856424],
              [173.24723432850206, -41.331998793300784],
              [173.95840538970288, -40.92670053483562],
              [174.24758670480813, -41.34915536882167],
              [174.2485168805895, -41.770008233406756],
              [173.8764465680879, -42.233184096038826],
              [173.22273969959568, -42.970038344088564],
              [172.71124637277077, -43.372287693048506],
              [173.0801127464702, -43.85334360125358],
              [172.30858361235252, -43.865694268571346],
              [171.45292524646365, -44.24251881284372],
              [171.18513797432726, -44.89710418068489],
              [170.61669721911662, -45.90892872495971],
              [169.8314221540093, -46.3557748349876],
              [169.33233117093428, -46.641235446967855],
              [168.41135379462858, -46.61994475686359],
              [167.76374474514685, -46.29019744240921],
              [166.67688602118423, -46.21991749449224],
              [166.5091443219647, -45.85270476662622],
              [167.04642418850327, -45.11094125750867],
              [168.3037634625969, -44.12397307716613],
              [168.94940880765157, -43.93581918719142],
              [169.66781456937318, -43.55532561622634],
              [170.52491987536618, -43.03168832781283],
              [171.125089960004, -42.51275359473778],
              [171.56971398344322, -41.767424411792135],
              [171.94870893787194, -41.51441659929115],
              [172.09722700427878, -40.95610442480968],
              [172.798579543344, -40.493962090823466],
              [173.02037479074076, -40.919052422856424],
            ],
          ],
          [
            [
              [174.61200890533055, -36.156397393540544],
              [175.3366158389272, -37.20909799575826],
              [175.35759647043753, -36.52619394302113],
              [175.8088867536425, -36.79894215265769],
              [175.9584900251275, -37.55538176854606],
              [176.76319542877658, -37.8812533505787],
              [177.4388131045605, -37.961248467766495],
              [178.0103544457087, -37.57982472102013],
              [178.51709354076283, -37.6953732236248],
              [178.27473107331386, -38.58281259537309],
              [177.97046023997936, -39.166342868812976],
              [177.20699262929915, -39.145775648760846],
              [176.93998050364704, -39.44973642350158],
              [177.0329464053401, -39.87994272233148],
              [176.88582360260526, -40.065977878582174],
              [176.50801720611938, -40.60480803808959],
              [176.0124402204403, -41.28962411882151],
              [175.239567499083, -41.68830779395324],
              [175.0678983910094, -41.42589487077508],
              [174.65097293527847, -41.28182097754545],
              [175.22763024322367, -40.459235528323404],
              [174.90015669179, -39.90893320084723],
              [173.82404666574402, -39.50885426204351],
              [173.85226199777534, -39.14660247167746],
              [174.5748018740804, -38.797683200842755],
              [174.74347374908106, -38.027807712558385],
              [174.69701663645063, -37.38112883885796],
              [174.29202843657922, -36.71109221776144],
              [174.31900353423555, -36.53482390721389],
              [173.84099653553582, -36.121980889634116],
              [173.0541711774596, -35.23712533950034],
              [172.63600548735374, -34.52910654066939],
              [173.00704227120949, -34.45066171645034],
              [173.55129845610747, -35.006183363587965],
              [174.3293904971263, -35.26549570082862],
              [174.61200890533055, -36.156397393540544],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Papua New Guinea",
        sov_a3: "PNG",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Papua New Guinea",
        adm0_a3: "PNG",
        geou_dif: 0,
        geounit: "Papua New Guinea",
        gu_a3: "PNG",
        su_dif: 1,
        subunit: "Papua New Guinea",
        su_a3: "PN1",
        brk_diff: 0,
        name: "Papua New Guinea",
        name_long: "Papua New Guinea",
        brk_a3: "PN1",
        brk_name: "Papua New Guinea",
        brk_group: null,
        abbrev: "P.N.G.",
        postal: "PG",
        formal_en: "Independent State of Papua New Guinea",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Papua New Guinea",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 1,
        pop_est: 6057263,
        gdp_md_est: 13210,
        pop_year: -99,
        lastcensus: 2000,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "PG",
        iso_a3: "PNG",
        iso_n3: "598",
        un_a3: "598",
        wb_a2: "PG",
        wb_a3: "PNG",
        woe_id: -99,
        adm0_a3_is: "PNG",
        adm0_a3_us: "PNG",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 16,
        long_len: 16,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "PNG.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [155.88002566957843, -6.81999684003776],
              [155.5999910829888, -6.919990736522493],
              [155.16699425681512, -6.535931491729301],
              [154.72919152243836, -5.900828138862209],
              [154.51411421123967, -5.139117526880014],
              [154.65250369691736, -5.042430922061839],
              [154.7599906760844, -5.339983819198494],
              [155.06291792217937, -5.566791680527487],
              [155.54774620994172, -6.200654799019659],
              [156.01996544822478, -6.540013929880388],
              [155.88002566957843, -6.81999684003776],
            ],
          ],
          [
            [
              [151.9827958518545, -5.478063246282346],
              [151.45910688700866, -5.56028045005874],
              [151.3013904156539, -5.840728448106702],
              [150.7544470562767, -6.083762709175389],
              [150.24119673075384, -6.317753594592986],
              [149.70996300679332, -6.316513360218053],
              [148.89006473205046, -6.026040134305433],
              [148.31893680236075, -5.74714242922613],
              [148.4018257997569, -5.437755629094724],
              [149.29841190002082, -5.583741550319217],
              [149.84556196512725, -5.505503431829339],
              [149.9962504416903, -5.026101169457675],
              [150.13975589416495, -5.001348158389789],
              [150.23690758687349, -5.532220147324281],
              [150.8074670758081, -5.455842380396888],
              [151.089672072554, -5.113692722192368],
              [151.64788089417087, -4.757073662946169],
              [151.53786176982155, -4.16780730552189],
              [152.13679162008438, -4.14879037843852],
              [152.33874311748102, -4.312966403829762],
              [152.31869266175178, -4.86766122805075],
              [151.9827958518545, -5.478063246282346],
            ],
          ],
          [
            [
              [147.19187381407494, -7.38802418378998],
              [148.0846358583494, -8.044108168167611],
              [148.7341052593936, -9.104663588093757],
              [149.30683515848446, -9.07143564213007],
              [149.26663089416135, -9.514406019736027],
              [150.03872846903434, -9.684318129111702],
              [149.73879845601226, -9.872937106977005],
              [150.80162763895916, -10.293686618697421],
              [150.69057498596388, -10.582712904505868],
              [150.02839318257585, -10.652476088099931],
              [149.782310012002, -10.393267103723943],
              [148.92313764871722, -10.280922539921363],
              [147.91301842670802, -10.130440769087471],
              [147.13544315001226, -9.492443536012019],
              [146.56788089415062, -8.942554619994155],
              [146.04848107318494, -8.06741423913131],
              [144.74416792213802, -7.630128269077473],
              [143.8970878440097, -7.915330498896281],
              [143.2863757671843, -8.245491224809056],
              [143.4139132020807, -8.983068942910947],
              [142.62843143124422, -9.326820570516503],
              [142.06825890520022, -9.159595635620036],
              [141.0338517600139, -9.117892754760419],
              [141.01705691951904, -5.859021905138022],
              [141.00021040259188, -2.600151055515624],
              [142.7352466167915, -3.289152927263217],
              [144.58397098203326, -3.861417738463401],
              [145.27317955951, -4.373737888205028],
              [145.82978641172568, -4.876497897972683],
              [145.98192182839298, -5.465609226100014],
              [147.6480733583476, -6.083659356310804],
              [147.8911076194162, -6.614014580922315],
              [146.9709053895949, -6.721656589386257],
              [147.19187381407494, -7.38802418378998],
            ],
          ],
          [
            [
              [153.14003787659877, -4.499983412294114],
              [152.8272921083683, -4.766427097190999],
              [152.638673130503, -4.176127211120928],
              [152.40602583232496, -3.789742526874562],
              [151.95323693258356, -3.462062269711822],
              [151.38427941305005, -3.035421644710112],
              [150.66204959533886, -2.741486097833956],
              [150.93996544820456, -2.500002129734028],
              [151.4799841656545, -2.779985039891386],
              [151.82001509013512, -2.999971612157907],
              [152.2399894553711, -3.240008640153661],
              [152.64001671774253, -3.659983005389648],
              [153.01999352438466, -3.980015150573294],
              [153.14003787659877, -4.499983412294114],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Solomon Islands",
        sov_a3: "SLB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Solomon Islands",
        adm0_a3: "SLB",
        geou_dif: 0,
        geounit: "Solomon Islands",
        gu_a3: "SLB",
        su_dif: 0,
        subunit: "Solomon Islands",
        su_a3: "SLB",
        brk_diff: 0,
        name: "Solomon Is.",
        name_long: "Solomon Islands",
        brk_a3: "SLB",
        brk_name: "Solomon Is.",
        brk_group: null,
        abbrev: "S. Is.",
        postal: "SB",
        formal_en: null,
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Solomon Islands",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 6,
        pop_est: 595613,
        gdp_md_est: 1078,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SB",
        iso_a3: "SLB",
        iso_n3: "090",
        un_a3: "090",
        wb_a2: "SB",
        wb_a3: "SLB",
        woe_id: -99,
        adm0_a3_is: "SLB",
        adm0_a3_us: "SLB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 11,
        long_len: 15,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "SLB.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [162.11902469304087, -10.482719008021135],
              [162.39864586817222, -10.82636728276212],
              [161.70003218001838, -10.820011081590224],
              [161.31979699121476, -10.204751478723125],
              [161.917383254238, -10.446700534713656],
              [162.11902469304087, -10.482719008021135],
            ],
          ],
          [
            [
              [160.85222863183796, -9.872937106977005],
              [160.46258833235729, -9.895209649294841],
              [159.8494474632142, -9.794027194867368],
              [159.64000288313517, -9.63997975020527],
              [159.70294477766666, -9.242949720906779],
              [160.36295617089846, -9.400304457235533],
              [160.6885176943372, -9.610162448772812],
              [160.85222863183796, -9.872937106977005],
            ],
          ],
          [
            [
              [161.67998172428915, -9.599982191611375],
              [161.52939660059053, -9.784312025596435],
              [160.78825320866056, -8.91754322676492],
              [160.57999718652437, -8.320008640173967],
              [160.92002811100494, -8.320008640173967],
              [161.28000613835, -9.120011488484451],
              [161.67998172428915, -9.599982191611375],
            ],
          ],
          [
            [
              [159.8750272971986, -8.337320244991716],
              [159.917401971678, -8.538289890174866],
              [159.1336771995394, -8.114181410355398],
              [158.58611372297472, -7.754823500197715],
              [158.21114953026486, -7.421872246941149],
              [158.35997765526545, -7.320017998893917],
              [158.82000125552773, -7.560003350457392],
              [159.64000288313517, -8.020026950719569],
              [159.8750272971986, -8.337320244991716],
            ],
          ],
          [
            [
              [157.5384257346893, -7.34781991946693],
              [157.33941979393327, -7.404767347852555],
              [156.9020304710148, -7.176874281445392],
              [156.49135786359133, -6.765943291860395],
              [156.54282759015396, -6.59933847415148],
              [157.1400004417189, -7.021638278840655],
              [157.5384257346893, -7.34781991946693],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Vanuatu",
        sov_a3: "VUT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Vanuatu",
        adm0_a3: "VUT",
        geou_dif: 0,
        geounit: "Vanuatu",
        gu_a3: "VUT",
        su_dif: 0,
        subunit: "Vanuatu",
        su_a3: "VUT",
        brk_diff: 0,
        name: "Vanuatu",
        name_long: "Vanuatu",
        brk_a3: "VUT",
        brk_name: "Vanuatu",
        brk_group: null,
        abbrev: "Van.",
        postal: "VU",
        formal_en: "Republic of Vanuatu",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Vanuatu",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 218519,
        gdp_md_est: 988.5,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "7. Least developed region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "VU",
        iso_a3: "VUT",
        iso_n3: "548",
        un_a3: "548",
        wb_a2: "VU",
        wb_a3: "VUT",
        woe_id: -99,
        adm0_a3_is: "VUT",
        adm0_a3_us: "VUT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Oceania",
        region_un: "Oceania",
        subregion: "Melanesia",
        region_wb: "East Asia & Pacific",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: 2,
        homepart: 1,
        filename: "VUT.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [167.8448767438451, -16.466333103097156],
              [167.5151811058229, -16.597849623279966],
              [167.18000776597782, -16.15999521247096],
              [167.21680138576963, -15.891846205308452],
              [167.8448767438451, -16.466333103097156],
            ],
          ],
          [
            [
              [167.10771243720149, -14.933920179913954],
              [167.27002811103026, -15.740020847234874],
              [167.00120731024796, -15.614602146062495],
              [166.79315799384088, -15.668810723536723],
              [166.64985924709558, -15.392703545801195],
              [166.62913699774649, -14.626497084209603],
              [167.10771243720149, -14.933920179913954],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Albania",
        sov_a3: "ALB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Albania",
        adm0_a3: "ALB",
        geou_dif: 0,
        geounit: "Albania",
        gu_a3: "ALB",
        su_dif: 0,
        subunit: "Albania",
        su_a3: "ALB",
        brk_diff: 0,
        name: "Albania",
        name_long: "Albania",
        brk_a3: "ALB",
        brk_name: "Albania",
        brk_group: null,
        abbrev: "Alb.",
        postal: "AL",
        formal_en: "Republic of Albania",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Albania",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 1,
        mapcolor13: 6,
        pop_est: 3639453,
        gdp_md_est: 21810,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "AL",
        iso_a3: "ALB",
        iso_n3: "008",
        un_a3: "008",
        wb_a2: "AL",
        wb_a3: "ALB",
        woe_id: -99,
        adm0_a3_is: "ALB",
        adm0_a3_us: "ALB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "ALB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.590247430104906, 41.855404161133606],
            [20.463175083099202, 41.51508901627533],
            [20.605181919037364, 41.08622630468522],
            [21.0200403174764, 40.84272695572588],
            [20.999989861747224, 40.58000397395397],
            [20.674996779063633, 40.43499990494303],
            [20.615000441172754, 40.11000682225938],
            [20.15001590341052, 39.62499766698397],
            [19.980000441170144, 39.69499339452341],
            [19.960001661873207, 39.91500580500605],
            [19.406081984136733, 40.250773423822466],
            [19.319058872157143, 40.72723012955356],
            [19.40354983895429, 41.40956574153546],
            [19.540027296637106, 41.71998607031276],
            [19.37176883309496, 41.877547512370654],
            [19.304486118250793, 42.19574514420782],
            [19.738051385179627, 42.688247382165564],
            [19.801613396898688, 42.50009349219084],
            [20.0707, 42.58863],
            [20.283754510181893, 42.32025950781508],
            [20.52295, 42.21787],
            [20.590247430104906, 41.855404161133606],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Austria",
        sov_a3: "AUT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Austria",
        adm0_a3: "AUT",
        geou_dif: 0,
        geounit: "Austria",
        gu_a3: "AUT",
        su_dif: 0,
        subunit: "Austria",
        su_a3: "AUT",
        brk_diff: 0,
        name: "Austria",
        name_long: "Austria",
        brk_a3: "AUT",
        brk_name: "Austria",
        brk_group: null,
        abbrev: "Aust.",
        postal: "A",
        formal_en: "Republic of Austria",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Austria",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 4,
        pop_est: 8210281,
        gdp_md_est: 329500,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "AT",
        iso_a3: "AUT",
        iso_n3: "040",
        un_a3: "040",
        wb_a2: "AT",
        wb_a3: "AUT",
        woe_id: -99,
        adm0_a3_is: "AUT",
        adm0_a3_us: "AUT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "AUT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.979666782304037, 48.123497015976305],
            [16.90375410326726, 47.71486562762833],
            [16.340584344150415, 47.71290192320123],
            [16.534267612380376, 47.49617096616912],
            [16.202298211337364, 46.85238597267696],
            [16.011663852612656, 46.6836107448117],
            [15.137091912504985, 46.65870270444703],
            [14.63247155117483, 46.43181732846955],
            [13.806475457421527, 46.509306138691215],
            [12.376485223040817, 46.76755910906985],
            [12.153088006243054, 47.11539317482645],
            [11.16482791509327, 46.94157949481273],
            [11.048555942436536, 46.75135854754634],
            [10.44270145024663, 46.89354625099743],
            [9.932448357796659, 46.92072805438296],
            [9.479969516649021, 47.10280996356337],
            [9.632931756232978, 47.34760122332999],
            [9.59422610844635, 47.52505809182027],
            [9.896068149463188, 47.580196845075704],
            [10.402083774465211, 47.30248769793916],
            [10.544504021861627, 47.56639923765377],
            [11.426414015354737, 47.523766181012974],
            [12.141357456112788, 47.703083401065776],
            [12.620759718484491, 47.67238760028441],
            [12.932626987365948, 47.467645575544],
            [13.02585127122049, 47.637583523135824],
            [12.884102817443903, 48.28914581968792],
            [13.243357374737, 48.416114813829054],
            [13.595945672264437, 48.87717194273715],
            [14.33889773932472, 48.5553052842072],
            [14.901447381254057, 48.964401760445824],
            [15.253415561593982, 49.039074205107575],
            [16.02964725105022, 48.73389903420793],
            [16.49928266771877, 48.78580801044511],
            [16.960288120194576, 48.5969823268506],
            [16.879982944413, 48.47001333270947],
            [16.979666782304037, 48.123497015976305],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Belgium",
        sov_a3: "BEL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Belgium",
        adm0_a3: "BEL",
        geou_dif: 0,
        geounit: "Belgium",
        gu_a3: "BEL",
        su_dif: 0,
        subunit: "Belgium",
        su_a3: "BEL",
        brk_diff: 0,
        name: "Belgium",
        name_long: "Belgium",
        brk_a3: "BEL",
        brk_name: "Belgium",
        brk_group: null,
        abbrev: "Belg.",
        postal: "B",
        formal_en: "Kingdom of Belgium",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Belgium",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 10414336,
        gdp_md_est: 389300,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BE",
        iso_a3: "BEL",
        iso_n3: "056",
        un_a3: "056",
        wb_a2: "BE",
        wb_a3: "BEL",
        woe_id: -99,
        adm0_a3_is: "BEL",
        adm0_a3_us: "BEL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "BEL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.314971144228537, 51.345780951536085],
            [4.047071160507527, 51.26725861266857],
            [4.973991326526914, 51.475023708698124],
            [5.606975945670001, 51.037298488969775],
            [6.156658155958779, 50.80372101501058],
            [6.043073357781111, 50.128051662794235],
            [5.782417433300906, 50.09032786722122],
            [5.674051954784829, 49.529483547557504],
            [4.799221632515809, 49.985373033236385],
            [4.286022983425084, 49.907496649772554],
            [3.588184441755686, 50.37899241800358],
            [3.123251580425801, 50.780363267614575],
            [2.658422071960274, 50.79684804951574],
            [2.513573032246143, 51.14850617126183],
            [3.314971144228537, 51.345780951536085],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Bulgaria",
        sov_a3: "BGR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Bulgaria",
        adm0_a3: "BGR",
        geou_dif: 0,
        geounit: "Bulgaria",
        gu_a3: "BGR",
        su_dif: 0,
        subunit: "Bulgaria",
        su_a3: "BGR",
        brk_diff: 0,
        name: "Bulgaria",
        name_long: "Bulgaria",
        brk_a3: "BGR",
        brk_name: "Bulgaria",
        brk_group: null,
        abbrev: "Bulg.",
        postal: "BG",
        formal_en: "Republic of Bulgaria",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Bulgaria",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: 8,
        pop_est: 7204687,
        gdp_md_est: 93750,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BG",
        iso_a3: "BGR",
        iso_n3: "100",
        un_a3: "100",
        wb_a2: "BG",
        wb_a3: "BGR",
        woe_id: -99,
        adm0_a3_is: "BGR",
        adm0_a3_us: "BGR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "BGR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.65714969248299, 44.23492300066128],
            [22.944832391051847, 43.82378530534713],
            [23.33230228037632, 43.89701080990471],
            [24.100679152124172, 43.74105133724785],
            [25.569271681426926, 43.68844472917472],
            [26.065158725699746, 43.94349376075126],
            [27.242399529740908, 44.175986029632405],
            [27.970107049275075, 43.81246816667521],
            [28.558081495891997, 43.70746165625813],
            [28.03909508638472, 43.293171698574184],
            [27.67389773937805, 42.577892361006214],
            [27.99672041190539, 42.00735871028779],
            [27.135739373490477, 42.14148489030134],
            [26.117041863720797, 41.82690460872456],
            [26.106138136507212, 41.32889883072778],
            [25.197201368925445, 41.23448598893053],
            [24.49264489105803, 41.583896185872035],
            [23.692073601992348, 41.30908091894385],
            [22.952377150166452, 41.33799388281115],
            [22.88137373219743, 41.99929718685026],
            [22.380525750424592, 42.32025950781509],
            [22.54501183440962, 42.46136200618804],
            [22.43659467946128, 42.580321153323936],
            [22.60480146657133, 42.898518785161144],
            [22.986018507588483, 43.211161200526966],
            [22.50015669118028, 43.64281443946099],
            [22.410446404721597, 44.00806346289995],
            [22.65714969248299, 44.23492300066128],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Bosnia and Herzegovina",
        sov_a3: "BIH",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Bosnia and Herzegovina",
        adm0_a3: "BIH",
        geou_dif: 0,
        geounit: "Bosnia and Herzegovina",
        gu_a3: "BIH",
        su_dif: 0,
        subunit: "Bosnia and Herzegovina",
        su_a3: "BIH",
        brk_diff: 0,
        name: "Bosnia and Herz.",
        name_long: "Bosnia and Herzegovina",
        brk_a3: "BIH",
        brk_name: "Bosnia and Herz.",
        brk_group: null,
        abbrev: "B.H.",
        postal: "BiH",
        formal_en: "Bosnia and Herzegovina",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Bosnia and Herzegovina",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 1,
        mapcolor13: 2,
        pop_est: 4613414,
        gdp_md_est: 29700,
        pop_year: -99,
        lastcensus: 1991,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BA",
        iso_a3: "BIH",
        iso_n3: "070",
        un_a3: "070",
        wb_a2: "BA",
        wb_a3: "BIH",
        woe_id: -99,
        adm0_a3_is: "BIH",
        adm0_a3_us: "BIH",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 16,
        long_len: 22,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "BIH.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.00548628101012, 44.86023366960916],
            [19.36803, 44.863],
            [19.11761, 44.42307000000011],
            [19.59976, 44.03847],
            [19.454, 43.56810000000013],
            [19.21852, 43.52384],
            [19.03165, 43.43253],
            [18.70648, 43.20011],
            [18.56, 42.65],
            [17.674921502358984, 43.02856252702361],
            [17.297373488034452, 43.44634064388736],
            [16.91615644701733, 43.66772247982567],
            [16.456442905348865, 44.04123973243128],
            [16.23966027188453, 44.35114329688571],
            [15.750026075918981, 44.81871165626256],
            [15.959367303133376, 45.233776760430935],
            [16.318156772535872, 45.00412669532591],
            [16.534939406000206, 45.21160757097772],
            [17.002146030351014, 45.233776760430935],
            [17.861783481526402, 45.067740383477144],
            [18.553214145591653, 45.08158966733145],
            [19.00548628101012, 44.86023366960916],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Belarus",
        sov_a3: "BLR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Belarus",
        adm0_a3: "BLR",
        geou_dif: 0,
        geounit: "Belarus",
        gu_a3: "BLR",
        su_dif: 0,
        subunit: "Belarus",
        su_a3: "BLR",
        brk_diff: 0,
        name: "Belarus",
        name_long: "Belarus",
        brk_a3: "BLR",
        brk_name: "Belarus",
        brk_group: null,
        abbrev: "Bela.",
        postal: "BY",
        formal_en: "Republic of Belarus",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Belarus",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 5,
        mapcolor13: 11,
        pop_est: 9648533,
        gdp_md_est: 114100,
        pop_year: -99,
        lastcensus: 2009,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "BY",
        iso_a3: "BLR",
        iso_n3: "112",
        un_a3: "112",
        wb_a2: "BY",
        wb_a3: "BLR",
        woe_id: -99,
        adm0_a3_is: "BLR",
        adm0_a3_us: "BLR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "BLR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.48412763844985, 53.91249766704114],
            [24.450683628037037, 53.905702216194754],
            [25.536353794056993, 54.28242340760253],
            [25.7684326514798, 54.84696259217509],
            [26.58827924979039, 55.16717560487167],
            [26.494331495883753, 55.615106919977634],
            [27.10245975109453, 55.783313707087686],
            [28.176709425577993, 56.16912995057881],
            [29.229513380660308, 55.918344224666356],
            [29.371571893030673, 55.670090643936184],
            [29.896294386522356, 55.78946320253041],
            [30.873909132620007, 55.55097646750341],
            [30.971835971813135, 55.08154775656404],
            [30.757533807098717, 54.81177094178432],
            [31.38447228366374, 54.157056382862436],
            [31.79142418796224, 53.97463857687212],
            [31.731272820774507, 53.79402944601202],
            [32.405598585751164, 53.618045355842035],
            [32.69364301934604, 53.35142080343212],
            [32.304519484188226, 53.1327261419729],
            [31.49764367038293, 53.1674268662569],
            [31.305200636528014, 53.07399587667321],
            [31.54001834486226, 52.74205231384636],
            [31.785998162571587, 52.101677964885454],
            [30.927549269338982, 52.04235342061438],
            [30.619454380014844, 51.822806098022376],
            [30.555117221811457, 51.31950348571566],
            [30.157363722460897, 51.41613841410147],
            [29.254938185347925, 51.368234361366895],
            [28.99283532076353, 51.602044379271476],
            [28.61761274589225, 51.42771393493484],
            [28.24161502453657, 51.57222707783907],
            [27.454066196408434, 51.59230337178447],
            [26.337958611768556, 51.83228872334793],
            [25.327787713327005, 51.91065603291855],
            [24.553106316839518, 51.888461005249184],
            [24.00507775238421, 51.61744395609446],
            [23.52707075368437, 51.57845408793023],
            [23.508002150168693, 52.02364655212473],
            [23.199493849386187, 52.486977444053664],
            [23.799198846133375, 52.69109935160657],
            [23.80493493011778, 53.089731350306074],
            [23.527535841575002, 53.470121568406555],
            [23.48412763844985, 53.91249766704114],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Switzerland",
        sov_a3: "CHE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Switzerland",
        adm0_a3: "CHE",
        geou_dif: 0,
        geounit: "Switzerland",
        gu_a3: "CHE",
        su_dif: 0,
        subunit: "Switzerland",
        su_a3: "CHE",
        brk_diff: 0,
        name: "Switzerland",
        name_long: "Switzerland",
        brk_a3: "CHE",
        brk_name: "Switzerland",
        brk_group: null,
        abbrev: "Switz.",
        postal: "CH",
        formal_en: "Swiss Confederation",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Switzerland",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 2,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 7604467,
        gdp_md_est: 316700,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CH",
        iso_a3: "CHE",
        iso_n3: "756",
        un_a3: "756",
        wb_a2: "CH",
        wb_a3: "CHE",
        woe_id: -99,
        adm0_a3_is: "CHE",
        adm0_a3_us: "CHE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 11,
        long_len: 11,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "CHE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.59422610844635, 47.52505809182027],
            [9.632931756232978, 47.34760122332999],
            [9.479969516649021, 47.10280996356337],
            [9.932448357796659, 46.92072805438296],
            [10.44270145024663, 46.89354625099743],
            [10.363378126678612, 46.48357127540986],
            [9.92283654139038, 46.31489940040919],
            [9.182881707403055, 46.44021474871698],
            [8.966305779667806, 46.036931871111186],
            [8.489952426801324, 46.005150865251686],
            [8.31662967289438, 46.16364248309086],
            [7.755992058959833, 45.82449005795931],
            [7.273850945676656, 45.776947740250776],
            [6.843592970414504, 45.99114655210061],
            [6.500099724970425, 46.42967275652944],
            [6.022609490593537, 46.27298981382047],
            [6.037388950229001, 46.725778713561866],
            [6.768713820023606, 47.2877082383037],
            [6.736571079138059, 47.541801255882845],
            [7.192202182655507, 47.44976552997102],
            [7.46675906742223, 47.62058197691181],
            [8.317301466514152, 47.61357982033626],
            [8.522611932009765, 47.830827541691285],
            [9.59422610844635, 47.52505809182027],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Czech Republic",
        sov_a3: "CZE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Czech Republic",
        adm0_a3: "CZE",
        geou_dif: 0,
        geounit: "Czech Republic",
        gu_a3: "CZE",
        su_dif: 0,
        subunit: "Czech Republic",
        su_a3: "CZE",
        brk_diff: 0,
        name: "Czech Rep.",
        name_long: "Czech Republic",
        brk_a3: "CZE",
        brk_name: "Czech Rep.",
        brk_group: null,
        abbrev: "Cz. Rep.",
        postal: "CZ",
        formal_en: "Czech Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Czech Republic",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 1,
        mapcolor9: 2,
        mapcolor13: 6,
        pop_est: 10211904,
        gdp_md_est: 265200,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "CZ",
        iso_a3: "CZE",
        iso_n3: "203",
        un_a3: "203",
        wb_a2: "CZ",
        wb_a3: "CZE",
        woe_id: -99,
        adm0_a3_is: "CZE",
        adm0_a3_us: "CZE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 14,
        abbrev_len: 8,
        tiny: -99,
        homepart: 1,
        filename: "CZE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.960288120194576, 48.5969823268506],
            [16.49928266771877, 48.78580801044511],
            [16.02964725105022, 48.73389903420793],
            [15.253415561593982, 49.039074205107575],
            [14.901447381254057, 48.964401760445824],
            [14.33889773932472, 48.5553052842072],
            [13.595945672264437, 48.87717194273715],
            [13.031328973043431, 49.30706818297324],
            [12.521024204161192, 49.547415269562734],
            [12.415190870827445, 49.96912079528057],
            [12.240111118222558, 50.266337795607285],
            [12.966836785543194, 50.484076443069085],
            [13.338131951560285, 50.73323436136435],
            [14.056227654688172, 50.92691762959429],
            [14.307013380600637, 51.117267767941414],
            [14.570718214586066, 51.002339382524276],
            [15.01699588385867, 51.10667409932158],
            [15.490972120839727, 50.78472992614321],
            [16.23862674323857, 50.69773265237984],
            [16.176253289462267, 50.42260732685791],
            [16.719475945714436, 50.21574656839354],
            [16.868769158605655, 50.47397370055603],
            [17.55456709155112, 50.36214590107641],
            [17.64944502123899, 50.049038397819956],
            [18.392913852622172, 49.98862864847075],
            [18.853144158613617, 49.49622976337764],
            [18.554971144289482, 49.49501536721878],
            [18.399993523846177, 49.31500051533004],
            [18.170498488037964, 49.271514797556435],
            [18.104972771891852, 49.04398346617531],
            [17.913511590250465, 48.996492824899086],
            [17.88648481616181, 48.90347524677371],
            [17.545006951577108, 48.80001902932537],
            [17.101984897538898, 48.81696889911711],
            [16.960288120194576, 48.5969823268506],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Germany",
        sov_a3: "DEU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Germany",
        adm0_a3: "DEU",
        geou_dif: 0,
        geounit: "Germany",
        gu_a3: "DEU",
        su_dif: 0,
        subunit: "Germany",
        su_a3: "DEU",
        brk_diff: 0,
        name: "Germany",
        name_long: "Germany",
        brk_a3: "DEU",
        brk_name: "Germany",
        brk_group: null,
        abbrev: "Ger.",
        postal: "D",
        formal_en: "Federal Republic of Germany",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Germany",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 5,
        mapcolor9: 5,
        mapcolor13: 1,
        pop_est: 82329758,
        gdp_md_est: 2918000,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "DE",
        iso_a3: "DEU",
        iso_n3: "276",
        un_a3: "276",
        wb_a2: "DE",
        wb_a3: "DEU",
        woe_id: -99,
        adm0_a3_is: "DEU",
        adm0_a3_us: "DEU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "DEU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.921906365609232, 54.983104153048025],
            [9.9395797054529, 54.596641954153256],
            [10.950112338920519, 54.363607082733154],
            [10.939466993868448, 54.00869334575258],
            [11.956252475643282, 54.19648550070116],
            [12.518440382546714, 54.47037059184799],
            [13.647467075259499, 54.0755109727059],
            [14.119686313542559, 53.75702912049103],
            [14.353315463934168, 53.248171291713106],
            [14.074521111719434, 52.98126251892535],
            [14.4375997250022, 52.624850165408304],
            [14.685026482815713, 52.089947414755216],
            [14.607098422919648, 51.745188096719964],
            [15.016995883858781, 51.10667409932171],
            [14.570718214586122, 51.00233938252438],
            [14.307013380600665, 51.11726776794137],
            [14.056227654688314, 50.92691762959435],
            [13.338131951560397, 50.73323436136428],
            [12.96683678554325, 50.48407644306917],
            [12.240111118222671, 50.26633779560723],
            [12.415190870827473, 49.96912079528062],
            [12.521024204161336, 49.54741526956275],
            [13.031328973043514, 49.30706818297324],
            [13.595945672264577, 48.877171942737164],
            [13.243357374737116, 48.41611481382903],
            [12.884102817443873, 48.28914581968786],
            [13.025851271220517, 47.63758352313595],
            [12.932626987366064, 47.467645575544],
            [12.620759718484521, 47.672387600284424],
            [12.141357456112871, 47.70308340106578],
            [11.426414015354851, 47.52376618101306],
            [10.544504021861597, 47.5663992376538],
            [10.402083774465325, 47.30248769793916],
            [9.896068149463188, 47.580196845075704],
            [9.594226108446376, 47.5250580918202],
            [8.522611932009795, 47.83082754169135],
            [8.317301466514095, 47.61357982033627],
            [7.466759067422288, 47.62058197691192],
            [7.593676385131062, 48.33301911070373],
            [8.099278598674855, 49.01778351500343],
            [6.658229607783709, 49.20195831969164],
            [6.186320428094177, 49.463802802114515],
            [6.242751092156993, 49.90222565367873],
            [6.043073357781111, 50.128051662794235],
            [6.156658155958779, 50.80372101501058],
            [5.988658074577813, 51.851615709025054],
            [6.589396599970826, 51.852029120483394],
            [6.842869500362383, 52.22844025329755],
            [7.092053256873896, 53.14404328064489],
            [6.905139601274129, 53.48216217713064],
            [7.100424838905268, 53.69393219666267],
            [7.936239454793962, 53.74829580343379],
            [8.121706170289485, 53.52779246684429],
            [8.800734490604668, 54.020785630908904],
            [8.572117954145368, 54.39564647075405],
            [8.526229282270208, 54.96274363872516],
            [9.282048780971136, 54.83086538351631],
            [9.921906365609232, 54.983104153048025],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Denmark",
        sov_a3: "DN1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        admin: "Denmark",
        adm0_a3: "DNK",
        geou_dif: 0,
        geounit: "Denmark",
        gu_a3: "DNK",
        su_dif: 0,
        subunit: "Denmark",
        su_a3: "DNK",
        brk_diff: 0,
        name: "Denmark",
        name_long: "Denmark",
        brk_a3: "DNK",
        brk_name: "Denmark",
        brk_group: null,
        abbrev: "Den.",
        postal: "DK",
        formal_en: "Kingdom of Denmark",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Denmark",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 3,
        mapcolor13: 12,
        pop_est: 5500510,
        gdp_md_est: 203600,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "DK",
        iso_a3: "DNK",
        iso_n3: "208",
        un_a3: "208",
        wb_a2: "DK",
        wb_a3: "DNK",
        woe_id: -99,
        adm0_a3_is: "DNK",
        adm0_a3_us: "DNK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "DNK.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [12.69000613775563, 55.609990953180784],
              [12.089991082414741, 54.80001455343793],
              [11.043543328504228, 55.364863796604254],
              [10.903913608451631, 55.77995473898875],
              [12.370904168353292, 56.111407375708836],
              [12.69000613775563, 55.609990953180784],
            ],
          ],
          [
            [
              [10.912181837618363, 56.458621324277914],
              [10.667803989309988, 56.08138336854722],
              [10.369992710011985, 56.19000722922473],
              [9.649984978889307, 55.469999498102055],
              [9.921906365609175, 54.98310415304806],
              [9.282048780971136, 54.83086538351616],
              [8.526229282270236, 54.96274363872499],
              [8.120310906617588, 55.517722683323626],
              [8.08997684086225, 56.540011705137594],
              [8.256581658571264, 56.8099693874303],
              [8.543437534223386, 57.110002753316905],
              [9.42446902836761, 57.17206614849948],
              [9.775558709358563, 57.447940782289656],
              [10.580005730846153, 57.73001658795485],
              [10.546105991262692, 57.215732733786155],
              [10.250000034230226, 56.89001618105047],
              [10.369992710011985, 56.609981594460834],
              [10.912181837618363, 56.458621324277914],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Spain",
        sov_a3: "ESP",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Spain",
        adm0_a3: "ESP",
        geou_dif: 0,
        geounit: "Spain",
        gu_a3: "ESP",
        su_dif: 0,
        subunit: "Spain",
        su_a3: "ESP",
        brk_diff: 0,
        name: "Spain",
        name_long: "Spain",
        brk_a3: "ESP",
        brk_name: "Spain",
        brk_group: null,
        abbrev: "Sp.",
        postal: "E",
        formal_en: "Kingdom of Spain",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Spain",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 5,
        mapcolor13: 5,
        pop_est: 40525002,
        gdp_md_est: 1403000,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "ES",
        iso_a3: "ESP",
        iso_n3: "724",
        un_a3: "724",
        wb_a2: "ES",
        wb_a3: "ESP",
        woe_id: -99,
        adm0_a3_is: "ESP",
        adm0_a3_us: "ESP",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 3,
        tiny: -99,
        homepart: 1,
        filename: "ESP.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-9.034817674180246, 41.88057058365967],
            [-8.984433152695672, 42.59277517350627],
            [-9.392883673530648, 43.0266246608127],
            [-7.978189663108309, 43.748337714200986],
            [-6.754491746436756, 43.567909450853925],
            [-5.411886359061596, 43.574239813809676],
            [-4.347842779955783, 43.40344920508504],
            [-3.51753170410609, 43.4559007838613],
            [-1.901351284177764, 43.42280202897834],
            [-1.502770961910528, 43.03401439063043],
            [0.338046909190581, 42.57954600683954],
            [0.701590610363894, 42.7957343613326],
            [1.826793247087153, 42.34338471126569],
            [2.985998976258458, 42.47301504166986],
            [3.039484083680549, 41.892120266276905],
            [2.091841668312185, 41.22608856868309],
            [0.810524529635188, 41.01473196060934],
            [0.721331007499401, 40.678318386389236],
            [0.106691521819869, 40.12393362076202],
            [-0.278711310212941, 39.30997813573272],
            [0.111290724293838, 38.73851430923303],
            [-0.467123582349103, 38.29236583104115],
            [-0.683389451490598, 37.642353827457825],
            [-1.438382127274849, 37.443063666324214],
            [-2.146452602538119, 36.67414419203728],
            [-3.415780808923387, 36.65889964451118],
            [-4.368900926114719, 36.677839056946155],
            [-4.995219285492211, 36.32470815687964],
            [-5.377159796561457, 35.946850083961465],
            [-5.866432257500904, 36.02981659600606],
            [-6.236693894872175, 36.367677110330334],
            [-6.520190802425404, 36.94291331638732],
            [-7.453725551778092, 37.09778758396607],
            [-7.537105475281024, 37.42890432387623],
            [-7.166507941099865, 37.803894354802225],
            [-7.029281175148796, 38.07576406508977],
            [-7.374092169616318, 38.37305858006492],
            [-7.098036668313128, 39.03007274022378],
            [-7.498632371439725, 39.62957103124181],
            [-7.066591559263529, 39.71189158788277],
            [-7.026413133156595, 40.184524237624245],
            [-6.864019944679385, 40.33087189387483],
            [-6.851126674822552, 41.11108266861753],
            [-6.389087693700915, 41.381815497394655],
            [-6.668605515967656, 41.883386949219584],
            [-7.251308966490824, 41.91834605566505],
            [-7.422512986673795, 41.79207469335983],
            [-8.013174607769912, 41.790886135417125],
            [-8.263856980817792, 42.28046865495034],
            [-8.67194576662672, 42.13468943945496],
            [-9.034817674180246, 41.88057058365967],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Estonia",
        sov_a3: "EST",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Estonia",
        adm0_a3: "EST",
        geou_dif: 0,
        geounit: "Estonia",
        gu_a3: "EST",
        su_dif: 0,
        subunit: "Estonia",
        su_a3: "EST",
        brk_diff: 0,
        name: "Estonia",
        name_long: "Estonia",
        brk_a3: "EST",
        brk_name: "Estonia",
        brk_group: null,
        abbrev: "Est.",
        postal: "EST",
        formal_en: "Republic of Estonia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Estonia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 2,
        mapcolor9: 1,
        mapcolor13: 10,
        pop_est: 1299371,
        gdp_md_est: 27410,
        pop_year: -99,
        lastcensus: 2000,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "EE",
        iso_a3: "EST",
        iso_n3: "233",
        un_a3: "233",
        wb_a2: "EE",
        wb_a3: "EST",
        woe_id: -99,
        adm0_a3_is: "EST",
        adm0_a3_us: "EST",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "EST.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.312862583114622, 57.79342357037698],
            [24.42892785004216, 58.38341339785328],
            [24.061198357853186, 58.25737457949341],
            [23.42656009287668, 58.612753404364625],
            [23.339795363058645, 59.18724030215338],
            [24.604214308376182, 59.46585378685502],
            [25.86418908051664, 59.61109039981134],
            [26.949135776484525, 59.445803331125774],
            [27.981114129353244, 59.47538808861287],
            [28.13169925305175, 59.30082510033092],
            [27.42016645682494, 58.72458120384424],
            [27.71668582531572, 57.79189911562437],
            [27.28818484875151, 57.47452830670383],
            [26.463532342237787, 57.47638865826633],
            [25.602809685984365, 57.84752879498657],
            [25.16459354014927, 57.97015696881519],
            [24.312862583114622, 57.79342357037698],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Finland",
        sov_a3: "FI1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        admin: "Finland",
        adm0_a3: "FIN",
        geou_dif: 0,
        geounit: "Finland",
        gu_a3: "FIN",
        su_dif: 0,
        subunit: "Finland",
        su_a3: "FIN",
        brk_diff: 0,
        name: "Finland",
        name_long: "Finland",
        brk_a3: "FIN",
        brk_name: "Finland",
        brk_group: null,
        abbrev: "Fin.",
        postal: "FIN",
        formal_en: "Republic of Finland",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Finland",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 6,
        pop_est: 5250275,
        gdp_md_est: 193500,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "FI",
        iso_a3: "FIN",
        iso_n3: "246",
        un_a3: "246",
        wb_a2: "FI",
        wb_a3: "FIN",
        woe_id: -99,
        adm0_a3_is: "FIN",
        adm0_a3_us: "FIN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "FIN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.591929559043194, 69.06477692328666],
            [28.445943637818658, 68.36461294216404],
            [29.977426385220607, 67.69829702419266],
            [29.054588657352326, 66.94428620062193],
            [30.21765, 65.80598],
            [29.544429559046986, 64.94867157659048],
            [30.44468468600371, 64.20445343693909],
            [30.035872430142714, 63.55281362573855],
            [31.51609215671112, 62.86768748641288],
            [31.139991082490894, 62.35769277612441],
            [30.211107212044446, 61.78002777774969],
            [28.069997592895277, 60.503516547275844],
            [26.25517296723697, 60.4239606797625],
            [24.496623976344523, 60.05731639265165],
            [22.869694858499457, 59.846373196036225],
            [22.290763787533592, 60.39192129174154],
            [21.322244093519316, 60.72016998965952],
            [21.544866163832694, 61.7053294948718],
            [21.05921105315369, 62.60739329695874],
            [21.536029493910803, 63.18973501245587],
            [22.442744174903993, 63.81781037053129],
            [24.730511508897536, 64.90234365504082],
            [25.398067661243942, 65.11142650009373],
            [25.294043003040404, 65.53434642197045],
            [23.903378533633802, 66.00692739527962],
            [23.565879754335583, 66.39605093043743],
            [23.53947309743444, 67.93600861273525],
            [21.978534783626117, 68.6168456081807],
            [20.645592889089528, 69.10624726020087],
            [21.244936150810673, 69.37044302029307],
            [22.356237827247412, 68.84174144151491],
            [23.66204959483076, 68.89124746365054],
            [24.735679152126725, 68.64955678982146],
            [25.68921268077636, 69.09211375596904],
            [26.17962202322624, 69.82529897732614],
            [27.732292107867863, 70.16419302029625],
            [29.01557295097197, 69.76649119737799],
            [28.591929559043194, 69.06477692328666],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "France",
        sov_a3: "FR1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        admin: "France",
        adm0_a3: "FRA",
        geou_dif: 0,
        geounit: "France",
        gu_a3: "FRA",
        su_dif: 0,
        subunit: "France",
        su_a3: "FRA",
        brk_diff: 0,
        name: "France",
        name_long: "France",
        brk_a3: "FRA",
        brk_name: "France",
        brk_group: null,
        abbrev: "Fr.",
        postal: "F",
        formal_en: "French Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "France",
        name_alt: null,
        mapcolor7: 7,
        mapcolor8: 5,
        mapcolor9: 9,
        mapcolor13: 11,
        pop_est: 64057792,
        gdp_md_est: 2128000,
        pop_year: -99,
        lastcensus: -99,
        gdp_year: -99,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "FR",
        iso_a3: "FRA",
        iso_n3: "250",
        un_a3: "250",
        wb_a2: "FR",
        wb_a3: "FRA",
        woe_id: -99,
        adm0_a3_is: "FRA",
        adm0_a3_us: "FRA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 3,
        tiny: -99,
        homepart: 1,
        filename: "FRA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-52.55642473001839, 2.504705308437053],
              [-52.93965715189498, 2.124857692875622],
              [-53.418465135295264, 2.053389187016037],
              [-53.554839240113495, 2.334896551925965],
              [-53.77852067728889, 2.376702785650053],
              [-54.08806250671728, 2.105556545414629],
              [-54.52475419779975, 2.311848863123785],
              [-54.27122962097578, 2.738747870286943],
              [-54.18428402364474, 3.194172268075235],
              [-54.01150387227682, 3.622569891774858],
              [-54.399542202356514, 4.212611395683481],
              [-54.47863298197922, 4.896755682795643],
              [-53.95804460307093, 5.756548163267809],
              [-53.618452928264844, 5.646529038918402],
              [-52.88214128275408, 5.409850979021599],
              [-51.82334286152593, 4.565768133966145],
              [-51.65779741067888, 4.156232408053029],
              [-52.24933753112398, 3.241094468596287],
              [-52.55642473001839, 2.504705308437053],
            ],
          ],
          [
            [
              [9.560016310269134, 42.15249197037957],
              [9.229752231491773, 41.38000682226445],
              [8.77572309737536, 41.58361196549444],
              [8.54421268070783, 42.25651662858308],
              [8.746009148807588, 42.62812185319396],
              [9.390000848028905, 43.00998484961474],
              [9.560016310269134, 42.15249197037957],
            ],
          ],
          [
            [
              [3.588184441755715, 50.37899241800358],
              [4.28602298342514, 49.907496649772554],
              [4.799221632515753, 49.98537303323633],
              [5.674051954784885, 49.52948354755745],
              [5.897759230176376, 49.44266714130717],
              [6.186320428094206, 49.46380280211446],
              [6.658229607783539, 49.20195831969155],
              [8.099278598674772, 49.01778351500337],
              [7.593676385131062, 48.33301911070373],
              [7.46675906742223, 47.620581976911865],
              [7.192202182655535, 47.44976552997099],
              [6.736571079138088, 47.54180125588289],
              [6.768713820023634, 47.28770823830368],
              [6.037388950228972, 46.72577871356191],
              [6.022609490593567, 46.272989813820516],
              [6.500099724970454, 46.42967275652944],
              [6.843592970414562, 45.99114655210067],
              [6.802355177445662, 45.70857982032867],
              [7.096652459347837, 45.333098863295874],
              [6.749955275101711, 45.02851797136759],
              [7.007562290076663, 44.25476675066139],
              [7.549596388386163, 44.12790110938482],
              [7.435184767291843, 43.69384491634918],
              [6.529245232783068, 43.12889232031836],
              [4.556962517931396, 43.39965098731158],
              [3.10041059735272, 43.075200507167125],
              [2.985998976258486, 42.47301504166989],
              [1.826793247087181, 42.34338471126566],
              [0.701590610363922, 42.79573436133265],
              [0.338046909190581, 42.579546006839564],
              [-1.502770961910471, 43.03401439063049],
              [-1.901351284177735, 43.42280202897834],
              [-1.384225226232957, 44.02261037859017],
              [-1.193797573237362, 46.014917710954876],
              [-2.225724249673789, 47.06436269793821],
              [-2.963276129559574, 47.570326646507965],
              [-4.491554938159481, 47.95495433205642],
              [-4.592349819344747, 48.68416046812695],
              [-3.295813971357745, 48.901692409859635],
              [-1.616510789384932, 48.644421291694584],
              [-1.933494025063254, 49.776341864615766],
              [-0.98946895995536, 49.347375800160876],
              [1.338761020522753, 50.12717316344526],
              [1.6390010921385, 50.946606350297515],
              [2.513573032246171, 51.14850617126185],
              [2.658422071960331, 50.79684804951566],
              [3.123251580425716, 50.78036326761452],
              [3.588184441755715, 50.37899241800358],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "United Kingdom",
        sov_a3: "GB1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        admin: "United Kingdom",
        adm0_a3: "GBR",
        geou_dif: 0,
        geounit: "United Kingdom",
        gu_a3: "GBR",
        su_dif: 0,
        subunit: "United Kingdom",
        su_a3: "GBR",
        brk_diff: 0,
        name: "United Kingdom",
        name_long: "United Kingdom",
        brk_a3: "GBR",
        brk_name: "United Kingdom",
        brk_group: null,
        abbrev: "U.K.",
        postal: "GB",
        formal_en: "United Kingdom of Great Britain and Northern Ireland",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "United Kingdom",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 6,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 62262000,
        gdp_md_est: 1977704,
        pop_year: 0,
        lastcensus: 2011,
        gdp_year: 2009,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GB",
        iso_a3: "GBR",
        iso_n3: "826",
        un_a3: "826",
        wb_a2: "GB",
        wb_a3: "GBR",
        woe_id: -99,
        adm0_a3_is: "GBR",
        adm0_a3_us: "GBR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 14,
        long_len: 14,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "GBR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.661948614921897, 54.55460317648385],
              [-6.197884894220977, 53.86756500916334],
              [-6.953730231137996, 54.073702297575636],
              [-7.572167934591079, 54.05995636658599],
              [-7.366030646178785, 54.595840969452695],
              [-7.572167934591079, 55.1316222194549],
              [-6.733847011736145, 55.1728600124238],
              [-5.661948614921897, 54.55460317648385],
            ],
          ],
          [
            [
              [-3.005004848635281, 58.63500010846633],
              [-4.073828497728016, 57.55302480735525],
              [-3.055001796877661, 57.69001902936095],
              [-1.959280564776918, 57.68479970969951],
              [-2.219988165689301, 56.87001740175353],
              [-3.119003058271118, 55.973793036515474],
              [-2.085009324543023, 55.90999848085127],
              [-2.005675679673857, 55.80490285035023],
              [-1.11499101399221, 54.62498647726539],
              [-0.4304849918542, 54.46437612570216],
              [0.184981316742039, 53.32501414653103],
              [0.469976840831777, 52.92999949809197],
              [1.681530795914739, 52.739520168664],
              [1.559987827164377, 52.09999848083601],
              [1.050561557630914, 51.806760565795685],
              [1.449865349950301, 51.28942780212196],
              [0.550333693045502, 50.765738837275876],
              [-0.78751746255864, 50.77498891865622],
              [-2.489997524414377, 50.50001862243124],
              [-2.956273972984036, 50.696879991247016],
              [-3.617448085942328, 50.22835561787272],
              [-4.542507900399244, 50.34183706318566],
              [-5.245023159191135, 49.95999990498108],
              [-5.776566941745301, 50.15967763935682],
              [-4.309989793301838, 51.21000112568916],
              [-3.414850633142123, 51.42600861266925],
              [-3.422719467108323, 51.42684816740609],
              [-4.984367234710874, 51.593466091510976],
              [-5.267295701508885, 51.99140045837458],
              [-4.222346564134853, 52.301355699261364],
              [-4.770013393564113, 52.840004991255626],
              [-4.579999152026915, 53.49500377055517],
              [-3.093830673788659, 53.404547400669685],
              [-3.092079637047106, 53.404440822963544],
              [-2.945008510744344, 53.984999701546684],
              [-3.614700825433034, 54.600936773292574],
              [-3.63000545898933, 54.615012925833014],
              [-4.844169073903004, 54.790971177786844],
              [-5.082526617849226, 55.06160065369937],
              [-4.719112107756644, 55.50847260194348],
              [-5.047980922862109, 55.78398550070752],
              [-5.586397670911139, 55.31114614523682],
              [-5.644998745130181, 56.275014960344805],
              [-6.149980841486354, 56.78500967063354],
              [-5.786824713555291, 57.81884837506465],
              [-5.009998745127575, 58.63001333275005],
              [-4.211494513353557, 58.55084503847917],
              [-3.005004848635281, 58.63500010846633],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Greece",
        sov_a3: "GRC",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Greece",
        adm0_a3: "GRC",
        geou_dif: 0,
        geounit: "Greece",
        gu_a3: "GRC",
        su_dif: 0,
        subunit: "Greece",
        su_a3: "GRC",
        brk_diff: 0,
        name: "Greece",
        name_long: "Greece",
        brk_a3: "GRC",
        brk_name: "Greece",
        brk_group: null,
        abbrev: "Greece",
        postal: "GR",
        formal_en: "Hellenic Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Greece",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 9,
        pop_est: 10737428,
        gdp_md_est: 343000,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "GR",
        iso_a3: "GRC",
        iso_n3: "300",
        un_a3: "300",
        wb_a2: "GR",
        wb_a3: "GRC",
        woe_id: -99,
        adm0_a3_is: "GRC",
        adm0_a3_us: "GRC",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 6,
        tiny: -99,
        homepart: 1,
        filename: "GRC.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [23.699980096133004, 35.70500438083553],
              [24.24666507334868, 35.368022365860156],
              [25.02501549652888, 35.42499563246198],
              [25.769207797964185, 35.35401805270908],
              [25.745023227651586, 35.179997666966216],
              [26.290002882601723, 35.29999034274792],
              [26.16499759288766, 35.004995429009796],
              [24.724982130642303, 34.91998769788961],
              [24.735007358506945, 35.08499054619759],
              [23.51497846852811, 35.27999156345098],
              [23.699980096133004, 35.70500438083553],
            ],
          ],
          [
            [
              [26.604195590936282, 41.562114569661105],
              [26.29460208507578, 40.93626129817426],
              [26.056942172965506, 40.824123440100834],
              [25.447677036244187, 40.85254547786147],
              [24.92584842296094, 40.94706167252323],
              [23.714811232200816, 40.687129218095116],
              [24.407998894964066, 40.1249929876241],
              [23.899967889102584, 39.96200552017558],
              [23.3429993018608, 39.96099782974579],
              [22.81398766448896, 40.476005153966554],
              [22.62629886240478, 40.25656118423919],
              [22.849747755634805, 39.65931081802577],
              [23.3500272966526, 39.19001129816726],
              [22.973099399515547, 38.97090322524966],
              [23.530016310324953, 38.51000112563847],
              [24.025024855248944, 38.21999298761645],
              [24.040011020613605, 37.655014553369426],
              [23.115002882589152, 37.92001129816222],
              [23.409971958111072, 37.409990749657396],
              [22.774971958108633, 37.30501007745656],
              [23.15422529469862, 36.422505804992056],
              [22.490028110451107, 36.41000010837746],
              [21.670026482843696, 36.8449864771942],
              [21.295010613701574, 37.644989325504696],
              [21.120034213961333, 38.31032339126273],
              [20.730032179454582, 38.769985256498785],
              [20.217712029712857, 39.340234686839636],
              [20.15001590341052, 39.62499766698403],
              [20.615000441172782, 40.11000682225943],
              [20.674996779063633, 40.434999904943055],
              [20.99998986174728, 40.58000397395397],
              [21.02004031747643, 40.84272695572588],
              [21.674160597426976, 40.93127452245798],
              [22.05537763844427, 41.14986583105269],
              [22.597308383889015, 41.130487168943205],
              [22.76177, 41.3048],
              [22.952377150166566, 41.33799388281122],
              [23.692073601992462, 41.30908091894386],
              [24.49264489105803, 41.58389618587205],
              [25.197201368925533, 41.23448598893066],
              [26.106138136507184, 41.32889883072784],
              [26.117041863720914, 41.82690460872473],
              [26.604195590936282, 41.562114569661105],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Croatia",
        sov_a3: "HRV",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Croatia",
        adm0_a3: "HRV",
        geou_dif: 0,
        geounit: "Croatia",
        gu_a3: "HRV",
        su_dif: 0,
        subunit: "Croatia",
        su_a3: "HRV",
        brk_diff: 0,
        name: "Croatia",
        name_long: "Croatia",
        brk_a3: "HRV",
        brk_name: "Croatia",
        brk_group: null,
        abbrev: "Cro.",
        postal: "HR",
        formal_en: "Republic of Croatia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Croatia",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 4,
        mapcolor9: 5,
        mapcolor13: 1,
        pop_est: 4489409,
        gdp_md_est: 82390,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "HR",
        iso_a3: "HRV",
        iso_n3: "191",
        un_a3: "191",
        wb_a2: "HR",
        wb_a3: "HRV",
        woe_id: -99,
        adm0_a3_is: "HRV",
        adm0_a3_us: "HRV",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "HRV.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.829838087650046, 45.908877671891844],
            [19.072768995854176, 45.52151113543209],
            [19.39047570158459, 45.236515611342384],
            [19.00548628101012, 44.86023366960916],
            [18.553214145591653, 45.08158966733145],
            [17.861783481526402, 45.067740383477144],
            [17.002146030351014, 45.233776760430935],
            [16.534939406000206, 45.21160757097772],
            [16.318156772535872, 45.00412669532591],
            [15.959367303133376, 45.233776760430935],
            [15.750026075918981, 44.81871165626256],
            [16.23966027188453, 44.35114329688571],
            [16.456442905348865, 44.04123973243128],
            [16.91615644701733, 43.66772247982567],
            [17.297373488034452, 43.44634064388736],
            [17.674921502358984, 43.02856252702361],
            [18.56, 42.65],
            [18.450016310304818, 42.47999136002932],
            [17.509970330483327, 42.849994615239154],
            [16.930005730871642, 43.20999848080038],
            [16.015384555737683, 43.50721548112722],
            [15.174453973052096, 44.243191229827914],
            [15.376250441151795, 44.31791535092208],
            [14.920309279040508, 44.73848399512946],
            [14.901602410550877, 45.07606028907611],
            [14.258747592839995, 45.233776760430935],
            [13.952254672917034, 44.80212352149687],
            [13.656975538801191, 45.13693512631596],
            [13.67940311041582, 45.48414907488501],
            [13.715059848697251, 45.500323798192426],
            [14.4119682145855, 45.46616567644742],
            [14.595109490627918, 45.63494090431282],
            [14.935243767972963, 45.471695054702764],
            [15.327674594797427, 45.452316392593325],
            [15.323953891672431, 45.731782538427694],
            [15.671529575267641, 45.8341535507979],
            [15.768732944408612, 46.23810822202353],
            [16.564808383864943, 46.50375092221981],
            [16.882515089595415, 46.38063182228444],
            [17.630066359129557, 45.9517691106941],
            [18.45606245288286, 45.75948110613615],
            [18.829838087650046, 45.908877671891844],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Hungary",
        sov_a3: "HUN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Hungary",
        adm0_a3: "HUN",
        geou_dif: 0,
        geounit: "Hungary",
        gu_a3: "HUN",
        su_dif: 0,
        subunit: "Hungary",
        su_a3: "HUN",
        brk_diff: 0,
        name: "Hungary",
        name_long: "Hungary",
        brk_a3: "HUN",
        brk_name: "Hungary",
        brk_group: null,
        abbrev: "Hun.",
        postal: "HU",
        formal_en: "Republic of Hungary",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Hungary",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 6,
        mapcolor9: 1,
        mapcolor13: 5,
        pop_est: 9905596,
        gdp_md_est: 196600,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "HU",
        iso_a3: "HUN",
        iso_n3: "348",
        un_a3: "348",
        wb_a2: "HU",
        wb_a3: "HUN",
        woe_id: -99,
        adm0_a3_is: "HUN",
        adm0_a3_us: "HUN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "HUN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.202298211337364, 46.85238597267696],
            [16.534267612380376, 47.49617096616912],
            [16.340584344150415, 47.71290192320123],
            [16.90375410326726, 47.71486562762833],
            [16.979666782304037, 48.123497015976305],
            [17.48847293464982, 47.86746613218621],
            [17.857132602620027, 47.758428860050365],
            [18.696512892336926, 47.880953681014404],
            [18.77702477384767, 48.081768296900634],
            [19.17436486173989, 48.11137889260387],
            [19.661363559658497, 48.26661489520866],
            [19.769470656013112, 48.202691148463614],
            [20.239054396249347, 48.32756724709692],
            [20.473562045989866, 48.56285004332181],
            [20.801293979584926, 48.623854071642384],
            [21.872236362401736, 48.31997081155002],
            [22.08560835133485, 48.42226430927179],
            [22.640819939878753, 48.15023956968735],
            [22.710531447040495, 47.88219391538941],
            [22.099767693782834, 47.6724392767167],
            [21.62651492685387, 46.99423777931816],
            [21.02195234547125, 46.3160879583519],
            [20.220192498462836, 46.127468980486555],
            [19.596044549241583, 46.17172984474454],
            [18.82983808764996, 45.90887767189193],
            [18.45606245288286, 45.759481106136136],
            [17.630066359129557, 45.95176911069419],
            [16.8825150895953, 46.38063182228444],
            [16.564808383864857, 46.50375092221983],
            [16.370504998447416, 46.8413272161665],
            [16.202298211337364, 46.85238597267696],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Ireland",
        sov_a3: "IRL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Ireland",
        adm0_a3: "IRL",
        geou_dif: 0,
        geounit: "Ireland",
        gu_a3: "IRL",
        su_dif: 0,
        subunit: "Ireland",
        su_a3: "IRL",
        brk_diff: 0,
        name: "Ireland",
        name_long: "Ireland",
        brk_a3: "IRL",
        brk_name: "Ireland",
        brk_group: null,
        abbrev: "Ire.",
        postal: "IRL",
        formal_en: "Ireland",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Ireland",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 2,
        pop_est: 4203200,
        gdp_md_est: 188400,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "IE",
        iso_a3: "IRL",
        iso_n3: "372",
        un_a3: "372",
        wb_a2: "IE",
        wb_a3: "IRL",
        woe_id: -99,
        adm0_a3_is: "IRL",
        adm0_a3_us: "IRL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "IRL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.197884894220991, 53.86756500916336],
            [-6.03298539877761, 53.15316417094435],
            [-6.788856573910849, 52.260117906292336],
            [-8.56161658368356, 51.669301255899356],
            [-9.977085740590269, 51.82045482035307],
            [-9.16628251793078, 52.86462881124268],
            [-9.688524542672454, 53.8813626165853],
            [-8.327987433292009, 54.66451894796863],
            [-7.572167934591064, 55.13162221945487],
            [-7.366030646178785, 54.59584096945272],
            [-7.572167934591064, 54.059956366586],
            [-6.953730231138067, 54.073702297575636],
            [-6.197884894220991, 53.86756500916336],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Iceland",
        sov_a3: "ISL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Iceland",
        adm0_a3: "ISL",
        geou_dif: 0,
        geounit: "Iceland",
        gu_a3: "ISL",
        su_dif: 0,
        subunit: "Iceland",
        su_a3: "ISL",
        brk_diff: 0,
        name: "Iceland",
        name_long: "Iceland",
        brk_a3: "ISL",
        brk_name: "Iceland",
        brk_group: null,
        abbrev: "Iceland",
        postal: "IS",
        formal_en: "Republic of Iceland",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Iceland",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 306694,
        gdp_md_est: 12710,
        pop_year: -99,
        lastcensus: -99,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "IS",
        iso_a3: "ISL",
        iso_n3: "352",
        un_a3: "352",
        wb_a2: "IS",
        wb_a3: "ISL",
        woe_id: -99,
        adm0_a3_is: "ISL",
        adm0_a3_us: "ISL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 7,
        tiny: -99,
        homepart: 1,
        filename: "ISL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.508695441129236, 66.45589223903141],
            [-14.739637417041605, 65.8087482774403],
            [-13.60973222497981, 65.12667104761987],
            [-14.909833746794902, 64.36408193628868],
            [-17.794438035543422, 63.67874909123385],
            [-18.656245896874992, 63.49638296167582],
            [-19.97275468594276, 63.64363495549153],
            [-22.762971971110158, 63.960178941495386],
            [-21.778484259517683, 64.40211579045551],
            [-23.95504391121911, 64.89112986923348],
            [-22.184402635170358, 65.0849681667603],
            [-22.227423265053332, 65.37859365504272],
            [-24.326184047939336, 65.61118927678847],
            [-23.65051469572309, 66.26251902939522],
            [-22.134922451250883, 66.41046865504687],
            [-20.57628373867955, 65.73211212835143],
            [-19.05684160000159, 66.27660085719477],
            [-17.79862382655905, 65.99385325790978],
            [-16.167818976292125, 66.52679230413587],
            [-14.508695441129236, 66.45589223903141],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Italy",
        sov_a3: "ITA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Italy",
        adm0_a3: "ITA",
        geou_dif: 0,
        geounit: "Italy",
        gu_a3: "ITA",
        su_dif: 0,
        subunit: "Italy",
        su_a3: "ITA",
        brk_diff: 0,
        name: "Italy",
        name_long: "Italy",
        brk_a3: "ITA",
        brk_name: "Italy",
        brk_group: null,
        abbrev: "Italy",
        postal: "I",
        formal_en: "Italian Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Italy",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 7,
        mapcolor9: 8,
        mapcolor13: 7,
        pop_est: 58126212,
        gdp_md_est: 1823000,
        pop_year: -99,
        lastcensus: 2012,
        gdp_year: -99,
        economy: "1. Developed region: G7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "IT",
        iso_a3: "ITA",
        iso_n3: "380",
        un_a3: "380",
        wb_a2: "IT",
        wb_a3: "ITA",
        woe_id: -99,
        adm0_a3_is: "ITA",
        adm0_a3_us: "ITA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 5,
        long_len: 5,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "ITA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [15.520376010813834, 38.23115509699147],
              [15.160242954171736, 37.44404551853782],
              [15.309897902089006, 37.1342194687318],
              [15.099988234119449, 36.6199872909954],
              [14.335228712632016, 36.996630967754754],
              [13.826732618879928, 37.10453135838019],
              [12.431003859108813, 37.61294993748381],
              [12.570943637755136, 38.12638113051968],
              [13.741156447004585, 38.03496552179536],
              [14.76124922044616, 38.143873602850505],
              [15.520376010813834, 38.23115509699147],
            ],
          ],
          [
            [
              [9.210011834356266, 41.20999136002422],
              [9.809975213264977, 40.5000088567661],
              [9.669518670295673, 39.177376410471794],
              [9.21481774255949, 39.240473334300134],
              [8.80693566247973, 38.90661774347847],
              [8.428302443077115, 39.17184703221662],
              [8.38825320805094, 40.378310858718805],
              [8.15999840661766, 40.95000722916379],
              [8.709990675500109, 40.89998444270523],
              [9.210011834356266, 41.20999136002422],
            ],
          ],
          [
            [
              [12.376485223040843, 46.76755910906987],
              [13.806475457421556, 46.50930613869119],
              [13.698109978905478, 46.016778062517375],
              [13.937630242578335, 45.591015936864665],
              [13.141606479554298, 45.73669179949541],
              [12.328581170306306, 45.38177806251485],
              [12.383874952858605, 44.88537425391908],
              [12.261453484759159, 44.600482082694015],
              [12.589237094786483, 44.091365871754476],
              [13.526905958722494, 43.5877273626379],
              [14.029820997787027, 42.76100779883248],
              [15.142569614327956, 41.955139675456905],
              [15.926191033601896, 41.96131500911574],
              [16.169897088290412, 41.74029490820342],
              [15.889345737377797, 41.5410822617182],
              [16.785001661860576, 41.179605617836586],
              [17.519168735431208, 40.87714345963224],
              [18.376687452882575, 40.35562490494266],
              [18.4802470231954, 40.168866278639825],
              [18.293385044028096, 39.81077444107325],
              [17.738380161213286, 40.2776710068303],
              [16.869595981522338, 40.44223460546385],
              [16.448743116937322, 39.79540070246648],
              [17.1714896989715, 39.42469981542072],
              [17.05284061042934, 38.9028712021373],
              [16.635088331781844, 38.8435724960824],
              [16.100960727613057, 37.98589874933418],
              [15.684086948314501, 37.90884918878703],
              [15.687962680736321, 38.214592800441864],
              [15.891981235424707, 38.750942491199226],
              [16.109332309644312, 38.96454702407769],
              [15.718813510814641, 39.544072374014945],
              [15.413612501698822, 40.04835683853517],
              [14.998495721098237, 40.17294871679093],
              [14.70326826341477, 40.604550279292624],
              [14.060671827865264, 40.78634796809544],
              [13.627985060285397, 41.188287258461656],
              [12.88808190273042, 41.25308950455562],
              [12.10668257004491, 41.70453481705741],
              [11.191906365614187, 42.35542531998967],
              [10.511947869517797, 42.931462510747224],
              [10.200028924204048, 43.920006822274615],
              [9.702488234097814, 44.03627879493132],
              [8.88894616052687, 44.36633616797954],
              [8.428560825238577, 44.23122813575242],
              [7.850766635783201, 43.76714793555524],
              [7.435184767291843, 43.69384491634918],
              [7.549596388386163, 44.12790110938482],
              [7.007562290076663, 44.25476675066139],
              [6.749955275101711, 45.02851797136759],
              [7.096652459347837, 45.333098863295874],
              [6.802355177445662, 45.70857982032867],
              [6.843592970414562, 45.99114655210067],
              [7.273850945676685, 45.77694774025076],
              [7.755992058959833, 45.82449005795928],
              [8.31662967289438, 46.163642483090854],
              [8.489952426801295, 46.00515086525175],
              [8.966305779667834, 46.036931871111165],
              [9.182881707403112, 46.44021474871698],
              [9.922836541390353, 46.31489940040919],
              [10.363378126678668, 46.483571275409844],
              [10.442701450246602, 46.893546250997446],
              [11.048555942436508, 46.7513585475464],
              [11.164827915093326, 46.94157949481274],
              [12.153088006243081, 47.11539317482644],
              [12.376485223040843, 46.76755910906987],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Kosovo",
        sov_a3: "KOS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Kosovo",
        adm0_a3: "KOS",
        geou_dif: 0,
        geounit: "Kosovo",
        gu_a3: "KOS",
        su_dif: 0,
        subunit: "Kosovo",
        su_a3: "KOS",
        brk_diff: 1,
        name: "Kosovo",
        name_long: "Kosovo",
        brk_a3: "B57",
        brk_name: "Kosovo",
        brk_group: null,
        abbrev: "Kos.",
        postal: "KO",
        formal_en: "Republic of Kosovo",
        formal_fr: null,
        note_adm0: null,
        note_brk: "Self admin.; Claimed by Serbia",
        name_sort: "Kosovo",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 2,
        mapcolor9: 3,
        mapcolor13: 11,
        pop_est: 1804838,
        gdp_md_est: 5352,
        pop_year: -99,
        lastcensus: 1981,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "-99",
        iso_a3: "-99",
        iso_n3: "-99",
        un_a3: "-099",
        wb_a2: "KV",
        wb_a3: "KSV",
        woe_id: -99,
        adm0_a3_is: "SRB",
        adm0_a3_us: "KOS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "KOS.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.76216, 42.05186],
            [20.71731000000011, 41.84711],
            [20.59023, 41.85541],
            [20.52295, 42.21787],
            [20.28374, 42.3202500000001],
            [20.0707, 42.58863],
            [20.25758, 42.81275000000011],
            [20.49679, 42.88469],
            [20.63508, 43.21671],
            [20.81448, 43.27205],
            [20.95651, 43.13094],
            [21.143395, 43.06868500000012],
            [21.27421, 42.90959],
            [21.43866, 42.86255],
            [21.63302, 42.67717],
            [21.77505, 42.6827],
            [21.66292, 42.43922],
            [21.54332, 42.3202500000001],
            [21.57663598940212, 42.24522439706186],
            [21.35270000000014, 42.2068],
            [20.76216, 42.05186],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Lithuania",
        sov_a3: "LTU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Lithuania",
        adm0_a3: "LTU",
        geou_dif: 0,
        geounit: "Lithuania",
        gu_a3: "LTU",
        su_dif: 0,
        subunit: "Lithuania",
        su_a3: "LTU",
        brk_diff: 0,
        name: "Lithuania",
        name_long: "Lithuania",
        brk_a3: "LTU",
        brk_name: "Lithuania",
        brk_group: null,
        abbrev: "Lith.",
        postal: "LT",
        formal_en: "Republic of Lithuania",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Lithuania",
        name_alt: null,
        mapcolor7: 6,
        mapcolor8: 3,
        mapcolor9: 3,
        mapcolor13: 9,
        pop_est: 3555179,
        gdp_md_est: 63330,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "LT",
        iso_a3: "LTU",
        iso_n3: "440",
        un_a3: "440",
        wb_a2: "LT",
        wb_a3: "LTU",
        woe_id: -99,
        adm0_a3_is: "LTU",
        adm0_a3_us: "LTU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 9,
        long_len: 9,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "LTU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.731098667092652, 54.327536932993326],
            [22.65105187347254, 54.582740993866736],
            [22.75776370615526, 54.85657440858138],
            [22.315723504330577, 55.015298570365864],
            [21.268448927503467, 55.190481675835315],
            [21.055800408622414, 56.03107636171106],
            [22.201156853939494, 56.33780182557948],
            [23.878263787539964, 56.273671373105266],
            [24.860684441840757, 56.37252838807963],
            [25.000934279080894, 56.16453074810484],
            [25.533046502390334, 56.10029694276603],
            [26.494331495883753, 55.615106919977634],
            [26.58827924979039, 55.16717560487167],
            [25.7684326514798, 54.84696259217509],
            [25.536353794056993, 54.28242340760253],
            [24.450683628037037, 53.905702216194754],
            [23.48412763844985, 53.91249766704114],
            [23.24398725758951, 54.22056671814914],
            [22.731098667092652, 54.327536932993326],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Luxembourg",
        sov_a3: "LUX",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Luxembourg",
        adm0_a3: "LUX",
        geou_dif: 0,
        geounit: "Luxembourg",
        gu_a3: "LUX",
        su_dif: 0,
        subunit: "Luxembourg",
        su_a3: "LUX",
        brk_diff: 0,
        name: "Luxembourg",
        name_long: "Luxembourg",
        brk_a3: "LUX",
        brk_name: "Luxembourg",
        brk_group: null,
        abbrev: "Lux.",
        postal: "L",
        formal_en: "Grand Duchy of Luxembourg",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Luxembourg",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 7,
        mapcolor9: 3,
        mapcolor13: 7,
        pop_est: 491775,
        gdp_md_est: 39370,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "LU",
        iso_a3: "LUX",
        iso_n3: "442",
        un_a3: "442",
        wb_a2: "LU",
        wb_a3: "LUX",
        woe_id: -99,
        adm0_a3_is: "LUX",
        adm0_a3_us: "LUX",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: 5,
        homepart: 1,
        filename: "LUX.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.043073357781111, 50.128051662794235],
            [6.242751092156993, 49.90222565367873],
            [6.186320428094177, 49.463802802114515],
            [5.897759230176405, 49.44266714130703],
            [5.674051954784829, 49.529483547557504],
            [5.782417433300906, 50.09032786722122],
            [6.043073357781111, 50.128051662794235],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Latvia",
        sov_a3: "LVA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Latvia",
        adm0_a3: "LVA",
        geou_dif: 0,
        geounit: "Latvia",
        gu_a3: "LVA",
        su_dif: 0,
        subunit: "Latvia",
        su_a3: "LVA",
        brk_diff: 0,
        name: "Latvia",
        name_long: "Latvia",
        brk_a3: "LVA",
        brk_name: "Latvia",
        brk_group: null,
        abbrev: "Lat.",
        postal: "LV",
        formal_en: "Republic of Latvia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Latvia",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 7,
        mapcolor9: 6,
        mapcolor13: 13,
        pop_est: 2231503,
        gdp_md_est: 38860,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "LV",
        iso_a3: "LVA",
        iso_n3: "428",
        un_a3: "428",
        wb_a2: "LV",
        wb_a3: "LVA",
        woe_id: -99,
        adm0_a3_is: "LVA",
        adm0_a3_us: "LVA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "LVA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.055800408622414, 56.03107636171106],
            [21.09042361825797, 56.78387278912293],
            [21.581866489353672, 57.41187063254993],
            [22.524341261492875, 57.75337433535076],
            [23.318452996522097, 57.00623647727487],
            [24.12072960785343, 57.02569265403277],
            [24.312862583114622, 57.79342357037698],
            [25.16459354014927, 57.97015696881519],
            [25.602809685984365, 57.84752879498657],
            [26.463532342237787, 57.47638865826633],
            [27.28818484875151, 57.47452830670383],
            [27.77001590344093, 57.24425812441123],
            [27.855282016722526, 56.75932648378429],
            [28.176709425577993, 56.16912995057881],
            [27.10245975109453, 55.783313707087686],
            [26.494331495883753, 55.615106919977634],
            [25.533046502390334, 56.10029694276603],
            [25.000934279080894, 56.16453074810484],
            [24.860684441840757, 56.37252838807963],
            [23.878263787539964, 56.273671373105266],
            [22.201156853939494, 56.33780182557948],
            [21.055800408622414, 56.03107636171106],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Moldova",
        sov_a3: "MDA",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Moldova",
        adm0_a3: "MDA",
        geou_dif: 0,
        geounit: "Moldova",
        gu_a3: "MDA",
        su_dif: 0,
        subunit: "Moldova",
        su_a3: "MDA",
        brk_diff: 0,
        name: "Moldova",
        name_long: "Moldova",
        brk_a3: "MDA",
        brk_name: "Moldova",
        brk_group: null,
        abbrev: "Mda.",
        postal: "MD",
        formal_en: "Republic of Moldova",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Moldova",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 5,
        mapcolor9: 4,
        mapcolor13: 12,
        pop_est: 4320748,
        gdp_md_est: 10670,
        pop_year: -99,
        lastcensus: 2004,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "MD",
        iso_a3: "MDA",
        iso_n3: "498",
        un_a3: "498",
        wb_a2: "MD",
        wb_a3: "MDA",
        woe_id: -99,
        adm0_a3_is: "MDA",
        adm0_a3_us: "MDA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "MDA.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.619336785597795, 48.22072622333347],
            [26.857823520624805, 48.368210761094495],
            [27.522537469195154, 48.467119452501116],
            [28.259546746541844, 48.15556224221342],
            [28.670891147585163, 48.1181485052341],
            [29.12269819511303, 47.84909516050646],
            [29.05086795422733, 47.51022695575249],
            [29.41513512545274, 47.34664520933257],
            [29.559674106573112, 46.928582872091326],
            [29.908851759569302, 46.67436066343146],
            [29.838210076626297, 46.52532583270169],
            [30.02465864433537, 46.42393667254503],
            [29.75997195813639, 46.34998769793536],
            [29.170653924279886, 46.3792623968287],
            [29.072106967899295, 46.517677720722496],
            [28.862972446414062, 46.43788930926383],
            [28.93371748222162, 46.2588304713725],
            [28.659987420371575, 45.93998688413164],
            [28.485269402792767, 45.5969070501459],
            [28.233553501099042, 45.48828318946837],
            [28.0544429867754, 45.944586086605625],
            [28.160017937947714, 46.37156260841722],
            [28.128030226359044, 46.810476386088254],
            [27.551166212684848, 47.40511709247083],
            [27.233872918412743, 47.82677094175638],
            [26.924176059687568, 48.123264472030996],
            [26.619336785597795, 48.22072622333347],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Macedonia",
        sov_a3: "MKD",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Macedonia",
        adm0_a3: "MKD",
        geou_dif: 0,
        geounit: "Macedonia",
        gu_a3: "MKD",
        su_dif: 0,
        subunit: "Macedonia",
        su_a3: "MKD",
        brk_diff: 0,
        name: "Macedonia",
        name_long: "Macedonia",
        brk_a3: "MKD",
        brk_name: "Macedonia",
        brk_group: null,
        abbrev: "Mkd.",
        postal: "MK",
        formal_en: "Former Yugoslav Republic of Macedonia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Macedonia, FYR",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 7,
        mapcolor13: 3,
        pop_est: 2066718,
        gdp_md_est: 18780,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "MK",
        iso_a3: "MKD",
        iso_n3: "807",
        un_a3: "807",
        wb_a2: "MK",
        wb_a3: "MKD",
        woe_id: -99,
        adm0_a3_is: "MKD",
        adm0_a3_us: "MKD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 9,
        long_len: 9,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "MKD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.59023, 41.85541],
            [20.71731000000011, 41.84711],
            [20.76216, 42.05186],
            [21.35270000000014, 42.2068],
            [21.57663598940212, 42.24522439706186],
            [21.917080000000112, 42.30364],
            [22.38052575042468, 42.32025950781508],
            [22.881373732197346, 41.999297186850356],
            [22.952377150166512, 41.33799388281119],
            [22.76177, 41.3048],
            [22.597308383889015, 41.130487168943205],
            [22.05537763844427, 41.14986583105269],
            [21.674160597426976, 40.93127452245795],
            [21.0200403174764, 40.84272695572588],
            [20.60518, 41.08622],
            [20.46315, 41.5150900000001],
            [20.59023, 41.85541],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Montenegro",
        sov_a3: "MNE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Montenegro",
        adm0_a3: "MNE",
        geou_dif: 0,
        geounit: "Montenegro",
        gu_a3: "MNE",
        su_dif: 0,
        subunit: "Montenegro",
        su_a3: "MNE",
        brk_diff: 0,
        name: "Montenegro",
        name_long: "Montenegro",
        brk_a3: "MNE",
        brk_name: "Montenegro",
        brk_group: null,
        abbrev: "Mont.",
        postal: "ME",
        formal_en: "Montenegro",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Montenegro",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 1,
        mapcolor9: 4,
        mapcolor13: 5,
        pop_est: 672180,
        gdp_md_est: 6816,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "ME",
        iso_a3: "MNE",
        iso_n3: "499",
        un_a3: "499",
        wb_a2: "ME",
        wb_a3: "MNE",
        woe_id: -99,
        adm0_a3_is: "MNE",
        adm0_a3_us: "MNE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 10,
        long_len: 10,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "MNE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.801613396898688, 42.50009349219084],
            [19.738051385179627, 42.688247382165564],
            [19.3044900000001, 42.19574],
            [19.37177000000014, 41.87755],
            [19.16246, 41.95502],
            [18.88214, 42.28151],
            [18.45, 42.48],
            [18.56, 42.65],
            [18.70648, 43.20011],
            [19.03165, 43.43253],
            [19.21852, 43.52384],
            [19.48389, 43.35229],
            [19.63, 43.21377997027054],
            [19.95857, 43.10604],
            [20.3398, 42.89852],
            [20.25758, 42.81275000000011],
            [20.0707, 42.58863],
            [19.801613396898688, 42.50009349219084],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Netherlands",
        sov_a3: "NL1",
        adm0_dif: 1,
        level: 2,
        type: "Country",
        admin: "Netherlands",
        adm0_a3: "NLD",
        geou_dif: 0,
        geounit: "Netherlands",
        gu_a3: "NLD",
        su_dif: 0,
        subunit: "Netherlands",
        su_a3: "NLD",
        brk_diff: 0,
        name: "Netherlands",
        name_long: "Netherlands",
        brk_a3: "NLD",
        brk_name: "Netherlands",
        brk_group: null,
        abbrev: "Neth.",
        postal: "NL",
        formal_en: "Kingdom of the Netherlands",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Netherlands",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 2,
        mapcolor9: 2,
        mapcolor13: 9,
        pop_est: 16715999,
        gdp_md_est: 672000,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "NL",
        iso_a3: "NLD",
        iso_n3: "528",
        un_a3: "528",
        wb_a2: "NL",
        wb_a3: "NLD",
        woe_id: -99,
        adm0_a3_is: "NLD",
        adm0_a3_us: "NLD",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Western Europe",
        region_wb: "Europe & Central Asia",
        name_len: 11,
        long_len: 11,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "NLD.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.074182570020923, 53.510403347378144],
            [6.905139601274129, 53.48216217713064],
            [7.092053256873896, 53.14404328064489],
            [6.842869500362383, 52.22844025329755],
            [6.589396599970826, 51.852029120483394],
            [5.988658074577813, 51.851615709025054],
            [6.156658155958779, 50.80372101501058],
            [5.606975945670001, 51.037298488969775],
            [4.973991326526914, 51.475023708698124],
            [4.047071160507527, 51.26725861266857],
            [3.314971144228537, 51.34575511331991],
            [3.830288527043137, 51.62054454203195],
            [4.705997348661185, 53.09179840759776],
            [6.074182570020923, 53.510403347378144],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Norway",
        sov_a3: "NOR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Norway",
        adm0_a3: "NOR",
        geou_dif: 0,
        geounit: "Norway",
        gu_a3: "NOR",
        su_dif: 0,
        subunit: "Norway",
        su_a3: "NOR",
        brk_diff: 0,
        name: "Norway",
        name_long: "Norway",
        brk_a3: "NOR",
        brk_name: "Norway",
        brk_group: null,
        abbrev: "Nor.",
        postal: "N",
        formal_en: "Kingdom of Norway",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Norway",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 3,
        mapcolor9: 8,
        mapcolor13: 12,
        pop_est: 4676305,
        gdp_md_est: 276400,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "NO",
        iso_a3: "NOR",
        iso_n3: "578",
        un_a3: "578",
        wb_a2: "NO",
        wb_a3: "NOR",
        woe_id: -99,
        adm0_a3_is: "NOR",
        adm0_a3_us: "NOR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "NOR.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [28.165547316202915, 71.18547435168051],
              [31.29341840996548, 70.45378774685992],
              [30.005435011522792, 70.1862588568849],
              [31.10107872897512, 69.55808014594486],
              [29.399580519332886, 69.15691600206307],
              [28.591929559043194, 69.0647769232867],
              [29.01557295097197, 69.76649119737797],
              [27.73229210786789, 70.16419302029628],
              [26.1796220232263, 69.82529897732616],
              [25.68921268077639, 69.09211375596902],
              [24.73567915212672, 68.64955678982145],
              [23.662049594830762, 68.89124746365053],
              [22.356237827247412, 68.84174144151494],
              [21.24493615081073, 69.37044302029312],
              [20.64559288908958, 69.10624726020085],
              [20.025268995857914, 69.06513865831272],
              [19.878559604581255, 68.40719432237262],
              [17.99386844246439, 68.56739126247734],
              [17.729181756265348, 68.01055186631623],
              [16.76887861498554, 68.01393667263139],
              [16.108712192456835, 67.3024555528369],
              [15.108411492583059, 66.19386688909543],
              [13.55568973150909, 64.78702769638147],
              [13.919905226302205, 64.44542064071611],
              [13.57191613124877, 64.04911408146967],
              [12.57993533697393, 64.06621898055835],
              [11.93056928879423, 63.128317572676984],
              [11.992064243221535, 61.800362453856565],
              [12.631146681375242, 61.29357168237009],
              [12.3003658382749, 60.11793284773006],
              [11.468271925511175, 59.432393296946],
              [11.027368605196926, 58.856149400459394],
              [10.356556837616097, 59.46980703392538],
              [8.382000359743643, 58.31328847923328],
              [7.048748406613299, 58.07888418235728],
              [5.665835402050419, 58.58815542259367],
              [5.308234490590735, 59.66323191999382],
              [4.992078077829007, 61.970998033284275],
              [5.912900424837885, 62.614472968182696],
              [8.553411085655766, 63.45400828719647],
              [10.527709181366788, 64.48603831649748],
              [12.358346795306375, 65.87972585719316],
              [14.761145867581604, 67.81064158799515],
              [16.43592736172897, 68.56320547146169],
              [19.184028354578516, 69.81744415961782],
              [21.378416375420613, 70.25516937934606],
              [23.023742303161583, 70.20207184516626],
              [24.546543409938522, 71.03049673123724],
              [26.370049676221807, 70.98626170519537],
              [28.165547316202915, 71.18547435168051],
            ],
          ],
          [
            [
              [24.72412, 77.85385],
              [22.49032, 77.44493],
              [20.72601, 77.67704],
              [21.41611, 77.93504],
              [20.8119, 78.25463],
              [22.88426, 78.45494],
              [23.28134, 78.07954],
              [24.72412, 77.85385],
            ],
          ],
          [
            [
              [18.25183, 79.70175],
              [21.54383, 78.95611],
              [19.02737, 78.5626],
              [18.47172, 77.82669],
              [17.59441, 77.63796],
              [17.1182, 76.80941],
              [15.91315, 76.77045],
              [13.76259, 77.38035],
              [14.66956, 77.73565],
              [13.1706, 78.02493],
              [11.22231, 78.8693],
              [10.44453, 79.65239],
              [13.17077, 80.01046],
              [13.71852, 79.66039],
              [15.14282, 79.67431],
              [15.52255, 80.01608],
              [16.99085, 80.05086],
              [18.25183, 79.70175],
            ],
          ],
          [
            [
              [25.447625359811894, 80.40734039989451],
              [27.4075057309135, 80.05640574820046],
              [25.92465050629818, 79.51783397085455],
              [23.02446577321362, 79.4000117052291],
              [20.075188429451885, 79.56682322866726],
              [19.897266473070914, 79.84236196564751],
              [18.462263624757924, 79.85988027619442],
              [17.368015170977458, 80.31889618602702],
              [20.455992059010697, 80.59815562613224],
              [21.9079447771154, 80.35767934846209],
              [22.919252557067438, 80.65714427359349],
              [25.447625359811894, 80.40734039989451],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Poland",
        sov_a3: "POL",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Poland",
        adm0_a3: "POL",
        geou_dif: 0,
        geounit: "Poland",
        gu_a3: "POL",
        su_dif: 0,
        subunit: "Poland",
        su_a3: "POL",
        brk_diff: 0,
        name: "Poland",
        name_long: "Poland",
        brk_a3: "POL",
        brk_name: "Poland",
        brk_group: null,
        abbrev: "Pol.",
        postal: "PL",
        formal_en: "Republic of Poland",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Poland",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 7,
        mapcolor9: 1,
        mapcolor13: 2,
        pop_est: 38482919,
        gdp_md_est: 667900,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "PL",
        iso_a3: "POL",
        iso_n3: "616",
        un_a3: "616",
        wb_a2: "PL",
        wb_a3: "POL",
        woe_id: -99,
        adm0_a3_is: "POL",
        adm0_a3_us: "POL",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "POL.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.01699588385867, 51.10667409932158],
            [14.607098422919535, 51.745188096719964],
            [14.685026482815688, 52.0899474147552],
            [14.4375997250022, 52.62485016540838],
            [14.074521111719491, 52.98126251892543],
            [14.353315463934138, 53.24817129171297],
            [14.119686313542587, 53.75702912049103],
            [14.802900424873458, 54.05070628520575],
            [16.36347700365573, 54.513158677785725],
            [17.622831658608675, 54.85153595643291],
            [18.62085859546164, 54.68260569927078],
            [18.696254510175464, 54.43871877706929],
            [19.660640089606403, 54.42608388937393],
            [20.892244500418624, 54.31252492941253],
            [22.731098667092652, 54.327536932993326],
            [23.24398725758951, 54.22056671814914],
            [23.48412763844985, 53.91249766704114],
            [23.527535841575002, 53.470121568406555],
            [23.80493493011778, 53.089731350306074],
            [23.799198846133375, 52.69109935160657],
            [23.199493849386187, 52.486977444053664],
            [23.508002150168693, 52.02364655212473],
            [23.52707075368437, 51.57845408793023],
            [24.029985792748903, 50.70540660257518],
            [23.922757195743262, 50.42488108987875],
            [23.426508416444392, 50.30850576435745],
            [22.518450148211603, 49.47677358661974],
            [22.776418898212626, 49.02739533140962],
            [22.558137648211755, 49.085738023467144],
            [21.607808058364213, 49.47010732685409],
            [20.887955356538413, 49.32877228453583],
            [20.41583947111985, 49.43145335549977],
            [19.825022820726872, 49.21712535256923],
            [19.320712517990472, 49.571574001659194],
            [18.909574822676316, 49.435845852244576],
            [18.853144158613617, 49.49622976337764],
            [18.392913852622172, 49.98862864847075],
            [17.64944502123899, 50.049038397819956],
            [17.55456709155112, 50.36214590107641],
            [16.868769158605655, 50.47397370055603],
            [16.719475945714436, 50.21574656839354],
            [16.176253289462267, 50.42260732685791],
            [16.23862674323857, 50.69773265237984],
            [15.490972120839727, 50.78472992614321],
            [15.01699588385867, 51.10667409932158],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Portugal",
        sov_a3: "PRT",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Portugal",
        adm0_a3: "PRT",
        geou_dif: 0,
        geounit: "Portugal",
        gu_a3: "PRT",
        su_dif: 1,
        subunit: "Portugal",
        su_a3: "PR1",
        brk_diff: 0,
        name: "Portugal",
        name_long: "Portugal",
        brk_a3: "PR1",
        brk_name: "Portugal",
        brk_group: null,
        abbrev: "Port.",
        postal: "P",
        formal_en: "Portuguese Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Portugal",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 7,
        mapcolor9: 1,
        mapcolor13: 4,
        pop_est: 10707924,
        gdp_md_est: 208627,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: 0,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "PT",
        iso_a3: "PRT",
        iso_n3: "620",
        un_a3: "620",
        wb_a2: "PT",
        wb_a3: "PRT",
        woe_id: -99,
        adm0_a3_is: "PRT",
        adm0_a3_us: "PRT",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "PRT.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-9.034817674180246, 41.88057058365967],
            [-8.67194576662672, 42.13468943945496],
            [-8.263856980817792, 42.28046865495034],
            [-8.013174607769912, 41.790886135417125],
            [-7.422512986673795, 41.79207469335983],
            [-7.251308966490824, 41.91834605566505],
            [-6.668605515967656, 41.883386949219584],
            [-6.389087693700915, 41.381815497394655],
            [-6.851126674822552, 41.11108266861753],
            [-6.864019944679385, 40.33087189387483],
            [-7.026413133156595, 40.184524237624245],
            [-7.066591559263529, 39.71189158788277],
            [-7.498632371439725, 39.62957103124181],
            [-7.098036668313128, 39.03007274022378],
            [-7.374092169616318, 38.37305858006492],
            [-7.029281175148796, 38.07576406508977],
            [-7.166507941099865, 37.803894354802225],
            [-7.537105475281024, 37.42890432387623],
            [-7.453725551778092, 37.09778758396607],
            [-7.855613165711985, 36.83826854099627],
            [-8.382816127953689, 36.97888011326246],
            [-8.898856980820327, 36.86880931248078],
            [-8.746101446965554, 37.65134552667661],
            [-8.839997524439879, 38.26624339451761],
            [-9.287463751655224, 38.3584858261586],
            [-9.526570603869715, 38.73742910415491],
            [-9.446988898140232, 39.39206614842837],
            [-9.048305223008427, 39.75509308527877],
            [-8.977353481471681, 40.15930613866581],
            [-8.768684047877102, 40.76063894303019],
            [-8.79085323733031, 41.18433401139126],
            [-8.99078935386757, 41.54345937760364],
            [-9.034817674180246, 41.88057058365967],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 2,
        sovereignt: "Russia",
        sov_a3: "RUS",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Russia",
        adm0_a3: "RUS",
        geou_dif: 0,
        geounit: "Russia",
        gu_a3: "RUS",
        su_dif: 0,
        subunit: "Russia",
        su_a3: "RUS",
        brk_diff: 0,
        name: "Russia",
        name_long: "Russian Federation",
        brk_a3: "RUS",
        brk_name: "Russia",
        brk_group: null,
        abbrev: "Rus.",
        postal: "RUS",
        formal_en: "Russian Federation",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Russian Federation",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 5,
        mapcolor9: 7,
        mapcolor13: 7,
        pop_est: 140041247,
        gdp_md_est: 2266000,
        pop_year: -99,
        lastcensus: 2010,
        gdp_year: -99,
        economy: "3. Emerging region: BRIC",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "RU",
        iso_a3: "RUS",
        iso_n3: "643",
        un_a3: "643",
        wb_a2: "RU",
        wb_a3: "RUS",
        woe_id: -99,
        adm0_a3_is: "RUS",
        adm0_a3_us: "RUS",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 18,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "RUS.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [143.64800744036287, 50.74760040954151],
              [144.65414757708564, 48.976390692737596],
              [143.17392785051723, 49.30655141865037],
              [142.5586682476501, 47.861575018904915],
              [143.53349246640406, 46.83672801369249],
              [143.5052771343726, 46.13790761980948],
              [142.74770063697392, 46.74076487892657],
              [142.0920300640545, 45.96675527605879],
              [141.90692508358504, 46.80592886004655],
              [142.0184428244709, 47.780132961612935],
              [141.90444461483506, 48.85918854429956],
              [142.13580000220568, 49.61516307229746],
              [142.1799833518153, 50.95234243428192],
              [141.59407596249002, 51.93543488220254],
              [141.68254601457366, 53.30196645772878],
              [142.60693403541075, 53.762145087287905],
              [142.2097489768154, 54.22547597921687],
              [142.654786411713, 54.36588084575388],
              [142.91461551327657, 53.704577541714734],
              [143.26084760963207, 52.74076040303905],
              [143.23526777564766, 51.75666026468875],
              [143.64800744036287, 50.74760040954151],
            ],
          ],
          [
            [
              [22.731098667092652, 54.327536932993326],
              [20.892244500418652, 54.312524929412575],
              [19.660640089606403, 54.426083889373984],
              [19.888481479581344, 54.8661603867715],
              [21.2684489275035, 55.19048167583528],
              [22.315723504330606, 55.0152985703659],
              [22.757763706155288, 54.85657440858142],
              [22.651051873472568, 54.58274099386671],
              [22.731098667092652, 54.327536932993326],
            ],
          ],
          [
            [
              [-175.01425, 66.58435],
              [-174.33983, 66.33556],
              [-174.57182, 67.06219],
              [-171.85731, 66.91308],
              [-169.89958, 65.97724],
              [-170.89107, 65.54139],
              [-172.53025, 65.43791],
              [-172.555, 64.46079],
              [-172.95533, 64.25269],
              [-173.89184, 64.2826],
              [-174.65392, 64.63125],
              [-175.98353, 64.92288],
              [-176.20716, 65.35667],
              [-177.22266, 65.52024],
              [-178.35993, 65.39052],
              [-178.90332, 65.74044],
              [-178.68611, 66.11211],
              [-179.88377, 65.87456],
              [-179.43268, 65.40411],
              [-180, 64.97970870219837],
              [-180, 68.96363636363635],
              [-177.55, 68.2],
              [-174.92825, 67.20589],
              [-175.01425, 66.58435],
            ],
          ],
          [
            [
              [180.00000000000014, 70.83219920854668],
              [178.9034250000001, 70.78114],
              [178.7253, 71.0988],
              [180.00000000000014, 71.51571433642826],
              [180.00000000000014, 70.83219920854668],
            ],
          ],
          [
            [
              [-178.69378, 70.89302],
              [-180, 70.83219920854668],
              [-180, 71.51571433642826],
              [-179.871875, 71.55762],
              [-179.02433, 71.55553],
              [-177.577945, 71.26948],
              [-177.663575, 71.13277],
              [-178.69378, 70.89302],
            ],
          ],
          [
            [
              [143.60385, 73.21244],
              [142.08763, 73.20544],
              [140.038155, 73.31692],
              [139.86312, 73.36983],
              [140.81171, 73.76506],
              [142.06207, 73.85758],
              [143.48283, 73.47525],
              [143.60385, 73.21244],
            ],
          ],
          [
            [
              [150.73167, 75.08406],
              [149.575925, 74.68892],
              [147.977465, 74.778355],
              [146.11919, 75.17298],
              [146.358485, 75.49682],
              [148.22223, 75.345845],
              [150.73167, 75.08406],
            ],
          ],
          [
            [
              [145.086285, 75.562625],
              [144.3, 74.82],
              [140.61381, 74.84768],
              [138.95544, 74.61148],
              [136.97439, 75.26167],
              [137.51176, 75.94917],
              [138.831075, 76.13676],
              [141.471615, 76.09289],
              [145.086285, 75.562625],
            ],
          ],
          [
            [
              [57.5356925799924, 70.72046397570216],
              [56.94497928246395, 70.63274323188668],
              [53.6773751157842, 70.76265778266847],
              [53.41201663596539, 71.2066616889202],
              [51.60189456564572, 71.47475901965049],
              [51.45575361512422, 72.01488108996514],
              [52.47827518088357, 72.22944163684096],
              [52.444168735570855, 72.77473135038485],
              [54.42761355979766, 73.62754751249759],
              [53.50828982932515, 73.74981395130015],
              [55.90245893740766, 74.62748647734533],
              [55.631932814359715, 75.08141225859717],
              [57.86864383324885, 75.60939036732321],
              [61.170044386647504, 76.25188345000814],
              [64.49836836127022, 76.43905548776928],
              [66.2109770038551, 76.80978221303124],
              [68.15705976753483, 76.93969676381292],
              [68.85221113472512, 76.54481130645462],
              [68.18057254422766, 76.23364166940911],
              [64.637326287703, 75.73775462513623],
              [61.58350752141476, 75.2608845079468],
              [58.47708214705338, 74.30905630156283],
              [56.98678551618801, 73.33304352486624],
              [55.419335971910954, 72.37126760526598],
              [55.622837762276305, 71.54059479439033],
              [57.5356925799924, 70.72046397570216],
            ],
          ],
          [
            [
              [106.97013000000013, 76.97419],
              [107.24000000000015, 76.48],
              [108.1538, 76.72335000000015],
              [111.07726000000017, 76.71],
              [113.33151, 76.22224],
              [114.13417, 75.84764],
              [113.88539, 75.32779000000014],
              [112.77918, 75.03186],
              [110.1512500000002, 74.47673],
              [109.4, 74.18],
              [110.64, 74.04],
              [112.11919, 73.78774000000011],
              [113.01954000000026, 73.97693000000015],
              [113.52958000000032, 73.33505000000011],
              [113.96881, 73.59488],
              [115.56782, 73.75285],
              [118.77633000000023, 73.58772],
              [119.02, 73.12],
              [123.20066000000011, 72.97122],
              [123.25777000000018, 73.73503000000011],
              [125.38000000000018, 73.56],
              [126.97644, 73.56549],
              [128.59126, 73.03871],
              [129.05157, 72.39872],
              [128.46000000000012, 71.98],
              [129.7159900000002, 71.19304],
              [131.28858000000028, 70.78699000000012],
              [132.25350000000017, 71.83630000000011],
              [133.85766000000032, 71.38642000000016],
              [135.56193, 71.65525000000014],
              [137.49755, 71.34763],
              [138.23409000000018, 71.62803],
              [139.86983000000012, 71.48783000000014],
              [139.14791, 72.4161900000001],
              [140.46817, 72.84941000000013],
              [149.5, 72.2],
              [150.3511800000002, 71.60643],
              [152.96890000000022, 70.84222],
              [157.00688, 71.03141],
              [158.99779, 70.86672],
              [159.83031000000025, 70.45324],
              [159.70866, 69.72198],
              [160.94053000000034, 69.4372800000001],
              [162.27907000000013, 69.64204],
              [164.05248000000014, 69.66823],
              [165.94037000000023, 69.47199],
              [167.83567, 69.58269],
              [169.5776300000002, 68.6938],
              [170.81688000000028, 69.01363],
              [170.0082000000002, 69.65276],
              [170.4534500000003, 70.09703],
              [173.64391000000026, 69.81743],
              [175.72403000000023, 69.87725000000023],
              [178.6, 69.4],
              [180.00000000000014, 68.96363636363657],
              [180.00000000000014, 64.97970870219848],
              [179.99281, 64.97433],
              [178.70720000000026, 64.53493],
              [177.41128000000018, 64.60821],
              [178.31300000000024, 64.07593],
              [178.9082500000002, 63.251970000000135],
              [179.37034, 62.982620000000104],
              [179.48636, 62.56894],
              [179.22825000000014, 62.30410000000015],
              [177.3643, 62.5219],
              [174.56929000000022, 61.76915],
              [173.68013, 61.65261],
              [172.15, 60.95],
              [170.6985000000001, 60.33618],
              [170.3308500000003, 59.88177],
              [168.90046, 60.57355],
              [166.29498000000032, 59.788550000000214],
              [165.84000000000023, 60.16],
              [164.87674, 59.7316],
              [163.53929000000014, 59.86871],
              [163.21711000000025, 59.21101],
              [162.0173300000001, 58.24328],
              [162.05297, 57.83912],
              [163.19191, 57.61503000000011],
              [163.05794000000017, 56.159240000000125],
              [162.12958000000023, 56.12219],
              [161.70146, 55.285680000000156],
              [162.11749000000017, 54.85514],
              [160.36877000000032, 54.34433],
              [160.02173000000022, 53.20257],
              [158.5309400000002, 52.958680000000236],
              [158.23118, 51.94269],
              [156.7897900000003, 51.01105],
              [156.42000000000016, 51.7],
              [155.99182, 53.15895],
              [155.43366000000012, 55.38103000000012],
              [155.91442000000032, 56.767920000000146],
              [156.75815, 57.3647],
              [156.8103500000001, 57.83204],
              [158.3643300000002, 58.05575],
              [160.15064000000012, 59.31477000000012],
              [161.87204, 60.34300000000013],
              [163.66969, 61.1409],
              [164.47355000000013, 62.55061],
              [163.2584200000002, 62.46627],
              [162.65791, 61.6425],
              [160.1214800000001, 60.54423],
              [159.30232, 61.77396],
              [156.7206800000001, 61.43442],
              [154.21806000000035, 59.75818000000013],
              [155.04375, 59.14495],
              [152.81185, 58.88385],
              [151.26573000000025, 58.78089],
              [151.33815000000013, 59.50396],
              [149.78371, 59.65573000000014],
              [148.54481, 59.16448],
              [145.48722, 59.33637],
              [142.19782000000018, 59.03998],
              [138.95848000000032, 57.08805],
              [135.12619, 54.72959],
              [136.70171, 54.603550000000126],
              [137.19342, 53.97732],
              [138.1647, 53.755010000000254],
              [138.80463, 54.25455000000011],
              [139.90151, 54.18968000000018],
              [141.34531, 53.08957000000012],
              [141.37923, 52.23877],
              [140.5974200000002, 51.2396700000001],
              [140.51308, 50.04553000000013],
              [140.06193000000022, 48.44671000000017],
              [138.5547200000002, 46.99965],
              [138.21971, 46.30795],
              [136.86232, 45.14350000000019],
              [135.5153500000002, 43.989],
              [134.86939000000027, 43.39821],
              [133.53687000000028, 42.81147],
              [132.90627000000015, 42.79849],
              [132.27807000000027, 43.28456000000011],
              [130.93587000000014, 42.55274],
              [130.78, 42.22000000000019],
              [130.64000000000019, 42.395],
              [130.6338664084098, 42.90301463477056],
              [131.144687941615, 42.92998973242695],
              [131.28855512911562, 44.111519680348266],
              [131.02519000000026, 44.96796],
              [131.8834542176596, 45.32116160743652],
              [133.09712000000022, 45.14409],
              [133.7696439963132, 46.116926988299156],
              [134.1123500000002, 47.21248000000014],
              [134.50081, 47.578450000000146],
              [135.0263114767868, 48.47822988544391],
              [133.37359581922803, 48.18344167743484],
              [132.50669000000013, 47.78896],
              [130.98726000000013, 47.79013],
              [130.58229332898267, 48.729687404976204],
              [129.3978178244205, 49.440600084015614],
              [127.65740000000038, 49.76027],
              [127.28745568248493, 50.73979726826545],
              [126.93915652883786, 51.3538941514059],
              [126.56439904185699, 51.7842554795327],
              [125.94634891164647, 52.79279857035695],
              [125.06821129771045, 53.161044826868924],
              [123.57147, 53.4588],
              [122.24574791879307, 53.43172597921369],
              [121.00308475147037, 53.25140106873124],
              [120.1770886577169, 52.75388621684121],
              [120.725789015792, 52.51622630473091],
              [120.7382, 51.96411],
              [120.18208000000018, 51.64355],
              [119.27939, 50.58292],
              [119.28846072802585, 50.14288279886196],
              [117.8792444194265, 49.51098338479704],
              [116.67880089728621, 49.888531399121405],
              [115.48569542853144, 49.80517731383475],
              [114.96210981655038, 50.14024730081513],
              [114.36245649623534, 50.248302720737485],
              [112.89773969935439, 49.54356537535699],
              [111.58123091028668, 49.37796824807767],
              [110.66201053267886, 49.13012807880585],
              [109.40244917199672, 49.29296051695769],
              [108.47516727095127, 49.28254771585071],
              [107.86817589725112, 49.79370514586588],
              [106.88880415245532, 50.27429596618029],
              [105.8865914245869, 50.406019192092174],
              [104.62158, 50.275320000000164],
              [103.67654544476036, 50.089966132195144],
              [102.25589000000011, 50.51056000000011],
              [102.06521, 51.25991],
              [100.88948042196265, 51.51685578063842],
              [99.98173221232356, 51.63400625264395],
              [98.8614905131005, 52.04736603454671],
              [97.82573978067452, 51.01099518493325],
              [98.23176150919173, 50.42240062112873],
              [97.25976000000023, 49.72605],
              [95.81402000000017, 49.977460000000114],
              [94.81594933469879, 50.01343333597088],
              [94.14756635943561, 50.48053660745716],
              [93.10421, 50.49529],
              [92.23471154171969, 50.80217072204175],
              [90.71366743364078, 50.331811835321105],
              [88.80556684769559, 49.47052073831247],
              [87.75126427607685, 49.29719798440556],
              [87.35997033076269, 49.21498078062916],
              [86.82935672398966, 49.82667470966813],
              [85.5412699726825, 49.69285858824816],
              [85.11555952346211, 50.11730296487763],
              [84.41637739455304, 50.311399644565824],
              [83.93511478061893, 50.88924551045358],
              [83.38300377801247, 51.069182847693895],
              [81.94598554883994, 50.81219594990633],
              [80.56844689323546, 51.38833649352844],
              [80.03555952344172, 50.864750881547224],
              [77.80091556184433, 53.40441498474754],
              [76.52517947785478, 54.177003485727134],
              [76.89110029491346, 54.49052440044193],
              [74.38482000000013, 53.54685000000011],
              [73.42567874542053, 53.489810289109755],
              [73.50851606638437, 54.035616766976595],
              [72.22415001820221, 54.37665538188679],
              [71.1801310566095, 54.133285224008254],
              [70.86526655465516, 55.169733588270105],
              [69.06816694527289, 55.3852501491435],
              [68.1691003762589, 54.97039175070438],
              [65.6668700000001, 54.601250000000164],
              [65.17853356309595, 54.35422781027208],
              [61.43660000000013, 54.00625],
              [60.97806644068325, 53.66499339457914],
              [61.699986199800634, 52.97999644633427],
              [60.739993117114544, 52.71998647725775],
              [60.92726850774025, 52.447548326215006],
              [59.96753380721557, 51.960420437215674],
              [61.58800337102414, 51.272658799843185],
              [61.33742435084101, 50.79907013610426],
              [59.93280724471557, 50.842194118851836],
              [59.64228234237057, 50.545442206415714],
              [58.36332000000013, 51.06364],
              [56.77798, 51.04355],
              [55.71694000000011, 50.62171000000015],
              [54.532878452376195, 51.02623973245937],
              [52.32872358583106, 51.718652248738096],
              [50.76664839051219, 51.69276235615987],
              [48.702381626181044, 50.60512848571284],
              [48.577841424357615, 49.874759629915644],
              [47.549480421749394, 50.454698391311126],
              [46.75159630716277, 49.35600576435374],
              [47.0436715024766, 49.152038886097586],
              [46.46644575377629, 48.39415233010493],
              [47.31524000000016, 47.71585],
              [48.05725, 47.74377],
              [48.694733514201886, 47.0756281601779],
              [48.593250000000154, 46.561040000000105],
              [49.101160000000135, 46.39933],
              [48.64541000000011, 45.80629],
              [47.67591, 45.64149000000012],
              [46.68201, 44.6092000000001],
              [47.59094, 43.66016000000013],
              [47.49252, 42.98658],
              [48.58437000000018, 41.80888],
              [47.98728315612604, 41.4058192001944],
              [47.81566572448466, 41.151416124021345],
              [47.373315464066394, 41.21973236751114],
              [46.686070591016716, 41.827137152669906],
              [46.40495079934894, 41.860675157227426],
              [45.7764, 42.09244000000024],
              [45.470279168485916, 42.50278066667005],
              [44.53762291848207, 42.711992702803684],
              [43.93121000000011, 42.5549600000001],
              [43.755990000000196, 42.74083],
              [42.39440000000016, 43.2203],
              [40.92219000000014, 43.38215000000014],
              [40.07696495947985, 43.553104153002494],
              [39.955008579271095, 43.434997666999294],
              [38.68, 44.28],
              [37.53912000000011, 44.65721],
              [36.67546000000013, 45.24469],
              [37.40317, 45.4045100000001],
              [38.23295, 46.24087],
              [37.67372, 46.63657],
              [39.14767, 47.04475000000013],
              [39.12120000000013, 47.26336],
              [38.22353803889948, 47.10218984637598],
              [38.25511233902981, 47.54640045835697],
              [38.77057, 47.82562000000024],
              [39.738277622238996, 47.89893707945208],
              [39.89562000000015, 48.23241],
              [39.67465, 48.783820000000134],
              [40.08078901546949, 49.30742991799937],
              [40.069040000000115, 49.60105],
              [38.59498823421356, 49.92646190042373],
              [38.010631137857075, 49.91566152607473],
              [37.39345950699524, 50.38395335550368],
              [36.626167840325394, 50.225590928745135],
              [35.35611616388812, 50.57719737405915],
              [35.37791, 50.77394],
              [35.02218305841794, 51.2075723333715],
              [34.2248157081544, 51.255993150428935],
              [34.14197838719061, 51.566413479206204],
              [34.39173058445723, 51.768881740925906],
              [33.75269982273588, 52.33507457133166],
              [32.71576053236716, 52.23846548116216],
              [32.41205813978777, 52.28869497334977],
              [32.15944000000022, 52.061250000000115],
              [31.78597, 52.10168],
              [31.54001834486226, 52.74205231384644],
              [31.30520063652798, 53.0739958766733],
              [31.49764, 53.16743000000014],
              [32.304519484188376, 53.132726141972846],
              [32.693643019346126, 53.35142080343214],
              [32.405598585751164, 53.618045355842014],
              [31.731272820774592, 53.79402944601202],
              [31.791424187962406, 53.974638576872195],
              [31.384472283663822, 54.15705638286238],
              [30.75753380709878, 54.811770941784395],
              [30.971835971813245, 55.081547756564134],
              [30.87390913262007, 55.55097646750351],
              [29.89629438652244, 55.7894632025305],
              [29.37157189303079, 55.67009064393628],
              [29.22951338066039, 55.91834422466641],
              [28.17670942557794, 56.16912995057879],
              [27.855282016722526, 56.75932648378438],
              [27.770015903440992, 57.244258124411196],
              [27.288184848751655, 57.47452830670392],
              [27.71668582531578, 57.79189911562445],
              [27.420150000000202, 58.72457000000014],
              [28.131699253051863, 59.300825100331],
              [27.98112, 59.47537],
              [29.1177, 60.02805000000012],
              [28.07, 60.50352000000015],
              [30.211107212044652, 61.78002777774969],
              [31.139991082491036, 62.357692776124445],
              [31.51609215671127, 62.867687486412905],
              [30.035872430142803, 63.552813625738565],
              [30.444684686003736, 64.20445343693908],
              [29.544429559047018, 64.94867157659056],
              [30.21765, 65.80598],
              [29.054588657352383, 66.94428620062203],
              [29.977426385220696, 67.69829702419275],
              [28.44594363781877, 68.36461294216399],
              [28.591929559043365, 69.0647769232867],
              [29.39955, 69.15692000000018],
              [31.10108000000011, 69.55811],
              [32.13272000000026, 69.90595000000025],
              [33.77547, 69.30142000000012],
              [36.51396, 69.06342],
              [40.292340000000166, 67.9324],
              [41.059870000000124, 67.4571300000001],
              [41.12595000000019, 66.79158000000012],
              [40.01583, 66.26618000000013],
              [38.38295, 65.9995300000001],
              [33.918710000000175, 66.75961],
              [33.18444, 66.63253],
              [34.81477, 65.90015000000014],
              [34.87857425307877, 65.4362128770482],
              [34.94391000000015, 64.41437000000016],
              [36.23129, 64.10945],
              [37.01273000000012, 63.84983000000011],
              [37.14197000000016, 64.33471],
              [36.539579035089815, 64.76446],
              [37.17604000000014, 65.14322000000013],
              [39.59345, 64.52079000000018],
              [40.43560000000011, 64.76446],
              [39.76260000000016, 65.49682],
              [42.0930900000001, 66.47623],
              [43.01604000000012, 66.41858],
              [43.94975000000014, 66.06908],
              [44.53226, 66.75634000000014],
              [43.69839, 67.35245],
              [44.18795000000014, 67.95051],
              [43.45282, 68.57079],
              [46.25000000000014, 68.25],
              [46.82134000000016, 67.68997],
              [45.55517, 67.56652],
              [45.5620200000001, 67.01005000000019],
              [46.34915000000015, 66.6676700000001],
              [47.894160000000255, 66.88455000000016],
              [48.13876, 67.52238],
              [50.22766000000016, 67.99867000000013],
              [53.71743000000018, 68.85738000000012],
              [54.47171, 68.80815],
              [53.48582000000013, 68.20131],
              [54.72628, 68.09702],
              [55.44268000000014, 68.43866],
              [57.317020000000156, 68.46628],
              [58.80200000000021, 68.88082],
              [59.94142000000019, 68.2784400000001],
              [61.07784000000018, 68.94069],
              [60.03, 69.52],
              [60.55, 69.85],
              [63.50400000000016, 69.54739],
              [64.888115, 69.23483500000013],
              [68.51216000000014, 68.09233000000017],
              [69.18068, 68.61563000000012],
              [68.16444, 69.14436],
              [68.13522, 69.35649],
              [66.93008000000012, 69.45461000000012],
              [67.25976, 69.92873],
              [66.72492000000014, 70.70889000000014],
              [66.69466, 71.02897000000024],
              [68.54006000000011, 71.93450000000024],
              [69.19636000000011, 72.84336000000016],
              [69.94, 73.04000000000013],
              [72.58754, 72.7762900000001],
              [72.79603, 72.22006],
              [71.8481100000001, 71.40898],
              [72.47011, 71.09019],
              [72.79188, 70.39114],
              [72.56470000000022, 69.02085],
              [73.66787, 68.4079],
              [73.2387, 67.7404],
              [71.28000000000011, 66.32000000000016],
              [72.42301000000018, 66.17267000000018],
              [72.82077, 66.53267],
              [73.92099000000016, 66.78946000000013],
              [74.1865100000002, 67.28429],
              [75.052, 67.76047000000017],
              [74.46926000000016, 68.32899],
              [74.93584000000013, 68.98918],
              [73.84236, 69.07146],
              [73.60187000000022, 69.62763],
              [74.3998, 70.63175],
              [73.1011, 71.44717000000026],
              [74.89082000000022, 72.12119],
              [74.65926, 72.83227],
              [75.15801000000019, 72.85497000000011],
              [75.68351, 72.30056000000013],
              [75.28898000000012, 71.33556],
              [76.35911, 71.15287000000015],
              [75.90313000000017, 71.87401],
              [77.57665000000011, 72.26717],
              [79.65202000000014, 72.32011],
              [81.5, 71.75],
              [80.61071000000013, 72.58285000000012],
              [80.51109, 73.6482],
              [82.25, 73.85000000000011],
              [84.65526, 73.80591000000018],
              [86.82230000000024, 73.93688],
              [86.00956, 74.45967000000014],
              [87.16682000000017, 75.11643],
              [88.31571000000011, 75.14393],
              [90.26, 75.64],
              [92.90058, 75.77333],
              [93.23421000000016, 76.0472],
              [95.86000000000016, 76.14],
              [96.67821, 75.91548],
              [98.92254000000023, 76.44689],
              [100.75967000000023, 76.43028],
              [101.03532, 76.86189],
              [101.99084000000013, 77.2875400000002],
              [104.3516000000001, 77.69792],
              [106.06664000000013, 77.37389],
              [104.70500000000024, 77.1274],
              [106.97013000000013, 76.97419],
            ],
          ],
          [
            [
              [105.07547, 78.30689],
              [99.43814, 77.921],
              [101.2649, 79.23399],
              [102.08635, 79.34641],
              [102.837815, 79.28129],
              [105.37243, 78.71334],
              [105.07547, 78.30689],
            ],
          ],
          [
            [
              [51.13618655783128, 80.54728017854093],
              [49.79368452332071, 80.4154277615482],
              [48.89441124857754, 80.3395667589437],
              [48.754936557821765, 80.17546824820084],
              [47.586119012244154, 80.01018117951533],
              [46.502825962109654, 80.24724681265437],
              [47.07245527526291, 80.55942414012945],
              [44.846958042181114, 80.58980988231718],
              [46.79913862487123, 80.77191762971364],
              [48.318477410684665, 80.78400991486996],
              [48.522806023966695, 80.51456899690015],
              [49.09718956889091, 80.75398590770843],
              [50.03976769389462, 80.91888540315182],
              [51.52293297710369, 80.6997256538019],
              [51.13618655783128, 80.54728017854093],
            ],
          ],
          [
            [
              [99.93976, 78.88094],
              [97.75794, 78.7562],
              [94.97259, 79.044745],
              [93.31288, 79.4265],
              [92.5454, 80.14379],
              [91.18107, 80.34146],
              [93.77766, 81.0246],
              [95.940895, 81.2504],
              [97.88385, 80.746975],
              [100.186655, 79.780135],
              [99.93976, 78.88094],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Romania",
        sov_a3: "ROU",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Romania",
        adm0_a3: "ROU",
        geou_dif: 0,
        geounit: "Romania",
        gu_a3: "ROU",
        su_dif: 0,
        subunit: "Romania",
        su_a3: "ROU",
        brk_diff: 0,
        name: "Romania",
        name_long: "Romania",
        brk_a3: "ROU",
        brk_name: "Romania",
        brk_group: null,
        abbrev: "Rom.",
        postal: "RO",
        formal_en: "Romania",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Romania",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 3,
        mapcolor13: 13,
        pop_est: 22215421,
        gdp_md_est: 271400,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "RO",
        iso_a3: "ROU",
        iso_n3: "642",
        un_a3: "642",
        wb_a2: "RO",
        wb_a3: "ROM",
        woe_id: -99,
        adm0_a3_is: "ROU",
        adm0_a3_us: "ROU",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "ROU.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.710531447040495, 47.88219391538941],
            [23.142236362406802, 48.09634105080695],
            [23.76095828623741, 47.985598456405455],
            [24.40205610525038, 47.98187775328042],
            [24.866317172960578, 47.73752574318831],
            [25.20774336111299, 47.89105642352747],
            [25.9459411964024, 47.987148749374214],
            [26.19745039236693, 48.22088125263035],
            [26.619336785597795, 48.22072622333347],
            [26.924176059687568, 48.123264472030996],
            [27.233872918412743, 47.82677094175638],
            [27.551166212684848, 47.40511709247083],
            [28.128030226359044, 46.810476386088254],
            [28.160017937947714, 46.37156260841722],
            [28.0544429867754, 45.944586086605625],
            [28.233553501099042, 45.48828318946837],
            [28.679779493939378, 45.304030870131704],
            [29.149724969201653, 45.46492544207245],
            [29.603289015427432, 45.293308010431126],
            [29.626543409958767, 45.03539093686239],
            [29.141611769331835, 44.82021027279904],
            [28.837857700320203, 44.913873806328056],
            [28.558081495891997, 43.70746165625813],
            [27.970107049275075, 43.81246816667521],
            [27.242399529740908, 44.175986029632405],
            [26.065158725699746, 43.94349376075126],
            [25.569271681426926, 43.68844472917472],
            [24.100679152124172, 43.74105133724785],
            [23.33230228037632, 43.89701080990471],
            [22.944832391051847, 43.82378530534713],
            [22.65714969248299, 44.23492300066128],
            [22.4740084164406, 44.40922760678177],
            [22.705725538837356, 44.57800283464702],
            [22.459022251075936, 44.7025171982543],
            [22.14508792490281, 44.47842234962059],
            [21.562022739353605, 44.7689472519655],
            [21.483526238702233, 45.18117015235778],
            [20.874312778413355, 45.416375433934235],
            [20.762174920339987, 45.734573065771436],
            [20.220192498462836, 46.127468980486555],
            [21.02195234547125, 46.3160879583519],
            [21.62651492685387, 46.99423777931816],
            [22.099767693782834, 47.6724392767167],
            [22.710531447040495, 47.88219391538941],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 5,
        sovereignt: "Republic of Serbia",
        sov_a3: "SRB",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Republic of Serbia",
        adm0_a3: "SRB",
        geou_dif: 0,
        geounit: "Republic of Serbia",
        gu_a3: "SRB",
        su_dif: 0,
        subunit: "Republic of Serbia",
        su_a3: "SRB",
        brk_diff: 0,
        name: "Serbia",
        name_long: "Serbia",
        brk_a3: "SRB",
        brk_name: "Serbia",
        brk_group: null,
        abbrev: "Serb.",
        postal: "RS",
        formal_en: "Republic of Serbia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Serbia",
        name_alt: null,
        mapcolor7: 3,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 10,
        pop_est: 7379339,
        gdp_md_est: 80340,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "3. Upper middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "RS",
        iso_a3: "SRB",
        iso_n3: "688",
        un_a3: "688",
        wb_a2: "YF",
        wb_a3: "SRB",
        woe_id: -99,
        adm0_a3_is: "SRB",
        adm0_a3_us: "SRB",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 5,
        tiny: -99,
        homepart: 1,
        filename: "SRB.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.87431277841341, 45.41637543393432],
            [21.48352623870221, 45.18117015235788],
            [21.562022739353722, 44.76894725196564],
            [22.145087924902896, 44.47842234962059],
            [22.459022251075965, 44.70251719825444],
            [22.70572553883744, 44.57800283464701],
            [22.474008416440654, 44.40922760678177],
            [22.657149692483074, 44.234923000661354],
            [22.410446404721597, 44.008063462900054],
            [22.500156691180223, 43.642814439461006],
            [22.986018507588483, 43.2111612005271],
            [22.60480146657136, 42.898518785161116],
            [22.43659467946139, 42.58032115332394],
            [22.54501183440965, 42.46136200618804],
            [22.38052575042468, 42.32025950781508],
            [21.917080000000112, 42.30364],
            [21.57663598940212, 42.24522439706186],
            [21.54332, 42.3202500000001],
            [21.66292, 42.43922],
            [21.77505, 42.6827],
            [21.63302, 42.67717],
            [21.43866, 42.86255],
            [21.27421, 42.90959],
            [21.143395, 43.06868500000012],
            [20.95651, 43.13094],
            [20.81448, 43.27205],
            [20.63508, 43.21671],
            [20.49679, 42.88469],
            [20.25758, 42.81275000000011],
            [20.3398, 42.89852],
            [19.95857, 43.10604],
            [19.63, 43.21377997027054],
            [19.48389, 43.35229],
            [19.21852, 43.52384],
            [19.454, 43.56810000000013],
            [19.59976, 44.03847],
            [19.11761, 44.42307000000011],
            [19.36803, 44.863],
            [19.00548, 44.86023],
            [19.39047570158459, 45.236515611342384],
            [19.072768995854176, 45.52151113543209],
            [18.82982, 45.90888],
            [19.59604454924164, 46.171729844744554],
            [20.220192498462893, 46.12746898048658],
            [20.762174920339987, 45.734573065771485],
            [20.87431277841341, 45.41637543393432],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Slovakia",
        sov_a3: "SVK",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Slovakia",
        adm0_a3: "SVK",
        geou_dif: 0,
        geounit: "Slovakia",
        gu_a3: "SVK",
        su_dif: 0,
        subunit: "Slovakia",
        su_a3: "SVK",
        brk_diff: 0,
        name: "Slovakia",
        name_long: "Slovakia",
        brk_a3: "SVK",
        brk_name: "Slovakia",
        brk_group: null,
        abbrev: "Svk.",
        postal: "SK",
        formal_en: "Slovak Republic",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Slovak Republic",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 4,
        mapcolor9: 4,
        mapcolor13: 9,
        pop_est: 5463046,
        gdp_md_est: 119500,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SK",
        iso_a3: "SVK",
        iso_n3: "703",
        un_a3: "703",
        wb_a2: "SK",
        wb_a3: "SVK",
        woe_id: -99,
        adm0_a3_is: "SVK",
        adm0_a3_us: "SVK",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "SVK.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.853144158613617, 49.49622976337764],
            [18.909574822676316, 49.435845852244576],
            [19.320712517990472, 49.571574001659194],
            [19.825022820726872, 49.21712535256923],
            [20.41583947111985, 49.43145335549977],
            [20.887955356538413, 49.32877228453583],
            [21.607808058364213, 49.47010732685409],
            [22.558137648211755, 49.085738023467144],
            [22.28084191253356, 48.82539215758067],
            [22.08560835133485, 48.42226430927179],
            [21.872236362401736, 48.31997081155002],
            [20.801293979584926, 48.623854071642384],
            [20.473562045989866, 48.56285004332181],
            [20.239054396249347, 48.32756724709692],
            [19.769470656013112, 48.202691148463614],
            [19.661363559658497, 48.26661489520866],
            [19.17436486173989, 48.11137889260387],
            [18.77702477384767, 48.081768296900634],
            [18.696512892336926, 47.880953681014404],
            [17.857132602620027, 47.758428860050365],
            [17.48847293464982, 47.86746613218621],
            [16.979666782304037, 48.123497015976305],
            [16.879982944413, 48.47001333270947],
            [16.960288120194576, 48.5969823268506],
            [17.101984897538898, 48.81696889911711],
            [17.545006951577108, 48.80001902932537],
            [17.88648481616181, 48.90347524677371],
            [17.913511590250465, 48.996492824899086],
            [18.104972771891852, 49.04398346617531],
            [18.170498488037964, 49.271514797556435],
            [18.399993523846177, 49.31500051533004],
            [18.554971144289482, 49.49501536721878],
            [18.853144158613617, 49.49622976337764],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 6,
        sovereignt: "Slovenia",
        sov_a3: "SVN",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Slovenia",
        adm0_a3: "SVN",
        geou_dif: 0,
        geounit: "Slovenia",
        gu_a3: "SVN",
        su_dif: 0,
        subunit: "Slovenia",
        su_a3: "SVN",
        brk_diff: 0,
        name: "Slovenia",
        name_long: "Slovenia",
        brk_a3: "SVN",
        brk_name: "Slovenia",
        brk_group: null,
        abbrev: "Slo.",
        postal: "SLO",
        formal_en: "Republic of Slovenia",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Slovenia",
        name_alt: null,
        mapcolor7: 2,
        mapcolor8: 3,
        mapcolor9: 2,
        mapcolor13: 12,
        pop_est: 2005692,
        gdp_md_est: 59340,
        pop_year: -99,
        lastcensus: 2011,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SI",
        iso_a3: "SVN",
        iso_n3: "705",
        un_a3: "705",
        wb_a2: "SI",
        wb_a3: "SVN",
        woe_id: -99,
        adm0_a3_is: "SVN",
        adm0_a3_us: "SVN",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Southern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 8,
        long_len: 8,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "SVN.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.806475457421527, 46.509306138691215],
            [14.63247155117483, 46.43181732846955],
            [15.137091912504985, 46.65870270444703],
            [16.011663852612656, 46.6836107448117],
            [16.202298211337364, 46.85238597267696],
            [16.370504998447416, 46.8413272161665],
            [16.564808383864857, 46.50375092221983],
            [15.768732944408551, 46.23810822202345],
            [15.671529575267556, 45.83415355079788],
            [15.323953891672403, 45.73178253842768],
            [15.327674594797427, 45.45231639259323],
            [14.935243767972935, 45.471695054702685],
            [14.595109490627804, 45.634940904312714],
            [14.411968214585414, 45.46616567644746],
            [13.715059848697221, 45.50032379819237],
            [13.937630242578306, 45.59101593686462],
            [13.698109978905478, 46.01677806251735],
            [13.806475457421527, 46.509306138691215],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Sweden",
        sov_a3: "SWE",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Sweden",
        adm0_a3: "SWE",
        geou_dif: 0,
        geounit: "Sweden",
        gu_a3: "SWE",
        su_dif: 0,
        subunit: "Sweden",
        su_a3: "SWE",
        brk_diff: 0,
        name: "Sweden",
        name_long: "Sweden",
        brk_a3: "SWE",
        brk_name: "Sweden",
        brk_group: null,
        abbrev: "Swe.",
        postal: "S",
        formal_en: "Kingdom of Sweden",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Sweden",
        name_alt: null,
        mapcolor7: 1,
        mapcolor8: 4,
        mapcolor9: 2,
        mapcolor13: 4,
        pop_est: 9059651,
        gdp_md_est: 344300,
        pop_year: -99,
        lastcensus: -99,
        gdp_year: -99,
        economy: "2. Developed region: nonG7",
        income_grp: "1. High income: OECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "SE",
        iso_a3: "SWE",
        iso_n3: "752",
        un_a3: "752",
        wb_a2: "SE",
        wb_a3: "SWE",
        woe_id: -99,
        adm0_a3_is: "SWE",
        adm0_a3_us: "SWE",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Northern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 6,
        long_len: 6,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "SWE.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.183173455501926, 65.72374054632017],
            [21.21351687997722, 65.02600535751527],
            [21.369631381930958, 64.41358795842429],
            [19.77887576669022, 63.60955434839504],
            [17.84777916837521, 62.74940013289681],
            [17.119554884518124, 61.34116567651097],
            [17.83134606290639, 60.63658336042741],
            [18.78772179533209, 60.081914374422595],
            [17.86922488777634, 58.9537661810587],
            [16.829185011470088, 58.71982697207339],
            [16.447709588291474, 57.041118069071885],
            [15.879785597403783, 56.10430186626866],
            [14.666681349352075, 56.200885118222175],
            [14.100721062891465, 55.40778107362265],
            [12.942910597392057, 55.36173737245058],
            [12.625100538797028, 56.30708018658197],
            [11.787942335668674, 57.44181712506307],
            [11.027368605196866, 58.85614940045936],
            [11.468271925511146, 59.43239329694604],
            [12.3003658382749, 60.11793284773003],
            [12.631146681375183, 61.293571682370136],
            [11.992064243221563, 61.80036245385656],
            [11.93056928879423, 63.12831757267698],
            [12.579935336973932, 64.06621898055833],
            [13.571916131248711, 64.04911408146971],
            [13.919905226302204, 64.44542064071608],
            [13.55568973150909, 64.78702769638151],
            [15.108411492583002, 66.19386688909547],
            [16.108712192456778, 67.30245555283689],
            [16.768878614985482, 68.01393667263139],
            [17.729181756265348, 68.01055186631628],
            [17.993868442464333, 68.56739126247736],
            [19.878559604581255, 68.40719432237258],
            [20.025268995857886, 69.0651386583127],
            [20.645592889089528, 69.10624726020087],
            [21.978534783626117, 68.6168456081807],
            [23.53947309743444, 67.93600861273525],
            [23.565879754335583, 66.39605093043743],
            [23.903378533633802, 66.00692739527962],
            [22.183173455501926, 65.72374054632017],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 3,
        sovereignt: "Ukraine",
        sov_a3: "UKR",
        adm0_dif: 0,
        level: 2,
        type: "Sovereign country",
        admin: "Ukraine",
        adm0_a3: "UKR",
        geou_dif: 0,
        geounit: "Ukraine",
        gu_a3: "UKR",
        su_dif: 0,
        subunit: "Ukraine",
        su_a3: "UKR",
        brk_diff: 0,
        name: "Ukraine",
        name_long: "Ukraine",
        brk_a3: "UKR",
        brk_name: "Ukraine",
        brk_group: null,
        abbrev: "Ukr.",
        postal: "UA",
        formal_en: "Ukraine",
        formal_fr: null,
        note_adm0: null,
        note_brk: null,
        name_sort: "Ukraine",
        name_alt: null,
        mapcolor7: 5,
        mapcolor8: 1,
        mapcolor9: 6,
        mapcolor13: 3,
        pop_est: 45700395,
        gdp_md_est: 339800,
        pop_year: -99,
        lastcensus: 2001,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "4. Lower middle income",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "UA",
        iso_a3: "UKR",
        iso_n3: "804",
        un_a3: "804",
        wb_a2: "UA",
        wb_a3: "UKR",
        woe_id: -99,
        adm0_a3_is: "UKR",
        adm0_a3_us: "UKR",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Europe",
        region_un: "Europe",
        subregion: "Eastern Europe",
        region_wb: "Europe & Central Asia",
        name_len: 7,
        long_len: 7,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "UKR.geojson",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.785998162571587, 52.101677964885454],
            [32.15941206231267, 52.06126699483322],
            [32.41205813978763, 52.28869497334975],
            [32.71576053236697, 52.23846548116205],
            [33.7526998227357, 52.335074571331695],
            [34.39173058445701, 51.76888174092579],
            [34.14197838719039, 51.56641347920623],
            [34.22481570815427, 51.25599315042895],
            [35.02218305841788, 51.20757233337145],
            [35.37792361831512, 50.77395539001034],
            [35.35611616388795, 50.577197374059054],
            [36.62616784032534, 50.225590928745135],
            [37.39345950699507, 50.38395335550359],
            [38.010631137856905, 49.91566152607463],
            [38.59498823421342, 49.92646190042363],
            [40.06905846533911, 49.6010554062817],
            [40.08078901546935, 49.307429917999286],
            [39.67466393408753, 48.78381846780187],
            [39.89563235856758, 48.23240509703143],
            [39.738277622238826, 47.89893707945198],
            [38.7705847511412, 47.825608222029814],
            [38.25511233902975, 47.546400458356814],
            [38.22353803889942, 47.10218984637588],
            [37.42513715998999, 47.022220567404204],
            [36.75985477066439, 46.698700263040934],
            [35.82368452326483, 46.64596446388707],
            [34.96234174982388, 46.27319651954964],
            [35.020787794745985, 45.65121898048466],
            [35.51000857925317, 45.40999339454619],
            [36.52999799983016, 45.46998973243706],
            [36.33471276219916, 45.113215643893966],
            [35.23999922052812, 44.939996242851606],
            [33.882511020652885, 44.36147858334407],
            [33.326420932760044, 44.56487702084489],
            [33.54692426934946, 45.03477081967489],
            [32.4541744321055, 45.32746613217608],
            [32.630804477679135, 45.519185695978905],
            [33.58816206231839, 45.85156850848024],
            [33.29856733575471, 46.080598456397844],
            [31.74414025241518, 46.333347886737386],
            [31.675307244602408, 46.70624502215554],
            [30.748748813609097, 46.583100084004],
            [30.377608676888883, 46.03241018328567],
            [29.603289015427432, 45.293308010431126],
            [29.149724969201653, 45.46492544207245],
            [28.679779493939378, 45.304030870131704],
            [28.233553501099042, 45.48828318946837],
            [28.485269402792767, 45.5969070501459],
            [28.659987420371575, 45.93998688413164],
            [28.93371748222162, 46.2588304713725],
            [28.862972446414062, 46.43788930926383],
            [29.072106967899295, 46.517677720722496],
            [29.170653924279886, 46.3792623968287],
            [29.75997195813639, 46.34998769793536],
            [30.02465864433537, 46.42393667254503],
            [29.838210076626297, 46.52532583270169],
            [29.908851759569302, 46.67436066343146],
            [29.559674106573112, 46.928582872091326],
            [29.41513512545274, 47.34664520933257],
            [29.05086795422733, 47.51022695575249],
            [29.12269819511303, 47.84909516050646],
            [28.670891147585163, 48.1181485052341],
            [28.259546746541844, 48.15556224221342],
            [27.522537469195154, 48.467119452501116],
            [26.857823520624805, 48.368210761094495],
            [26.619336785597795, 48.22072622333347],
            [26.19745039236693, 48.22088125263035],
            [25.9459411964024, 47.987148749374214],
            [25.20774336111299, 47.89105642352747],
            [24.866317172960578, 47.73752574318831],
            [24.40205610525038, 47.98187775328042],
            [23.76095828623741, 47.985598456405455],
            [23.142236362406802, 48.09634105080695],
            [22.710531447040495, 47.88219391538941],
            [22.640819939878753, 48.15023956968735],
            [22.08560835133485, 48.42226430927179],
            [22.28084191253356, 48.82539215758067],
            [22.558137648211755, 49.085738023467144],
            [22.776418898212626, 49.02739533140962],
            [22.518450148211603, 49.47677358661974],
            [23.426508416444392, 50.30850576435745],
            [23.922757195743262, 50.42488108987875],
            [24.029985792748903, 50.70540660257518],
            [23.52707075368437, 51.57845408793023],
            [24.00507775238421, 51.61744395609446],
            [24.553106316839518, 51.888461005249184],
            [25.327787713327005, 51.91065603291855],
            [26.337958611768556, 51.83228872334793],
            [27.454066196408434, 51.59230337178447],
            [28.24161502453657, 51.57222707783907],
            [28.61761274589225, 51.42771393493484],
            [28.99283532076353, 51.602044379271476],
            [29.254938185347925, 51.368234361366895],
            [30.157363722460897, 51.41613841410147],
            [30.555117221811457, 51.31950348571566],
            [30.619454380014844, 51.822806098022376],
            [30.927549269338982, 52.04235342061438],
            [31.785998162571587, 52.101677964885454],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        scalerank: 1,
        featurecla: "Admin-0 country",
        labelrank: 4,
        sovereignt: "Antarctica",
        sov_a3: "ATA",
        adm0_dif: 0,
        level: 2,
        type: "Indeterminate",
        admin: "Antarctica",
        adm0_a3: "ATA",
        geou_dif: 0,
        geounit: "Antarctica",
        gu_a3: "ATA",
        su_dif: 0,
        subunit: "Antarctica",
        su_a3: "ATA",
        brk_diff: 0,
        name: "Antarctica",
        name_long: "Antarctica",
        brk_a3: "ATA",
        brk_name: "Antarctica",
        brk_group: null,
        abbrev: "Ant.",
        postal: "AQ",
        formal_en: null,
        formal_fr: null,
        note_adm0: null,
        note_brk: "Multiple claims held in abeyance",
        name_sort: "Antarctica",
        name_alt: null,
        mapcolor7: 4,
        mapcolor8: 5,
        mapcolor9: 1,
        mapcolor13: -99,
        pop_est: 3802,
        gdp_md_est: 760.4,
        pop_year: -99,
        lastcensus: -99,
        gdp_year: -99,
        economy: "6. Developing region",
        income_grp: "2. High income: nonOECD",
        wikipedia: -99,
        fips_10: null,
        iso_a2: "AQ",
        iso_a3: "ATA",
        iso_n3: "010",
        un_a3: "-099",
        wb_a2: "-99",
        wb_a3: "-99",
        woe_id: -99,
        adm0_a3_is: "ATA",
        adm0_a3_us: "ATA",
        adm0_a3_un: -99,
        adm0_a3_wb: -99,
        continent: "Antarctica",
        region_un: "Antarctica",
        subregion: "Antarctica",
        region_wb: "Antarctica",
        name_len: 10,
        long_len: 10,
        abbrev_len: 4,
        tiny: -99,
        homepart: 1,
        filename: "ATA.geojson",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-59.57209469261152, -80.0401787250963],
              [-59.86584937197471, -80.54965667106185],
              [-60.1596557277702, -81.0003268370793],
              [-62.25539343936708, -80.86317758577665],
              [-64.48812537296976, -80.92193368929256],
              [-65.74166642928984, -80.58882740673914],
              [-65.74166642928984, -80.54965667106185],
              [-66.29003089055513, -80.25577280061799],
              [-64.03768775089765, -80.29494353629516],
              [-61.88324561221713, -80.39287037548829],
              [-61.13897579613345, -79.9813709451481],
              [-60.610119188058405, -79.62867929475613],
              [-59.57209469261152, -80.0401787250963],
            ],
          ],
          [
            [
              [-159.20818356019765, -79.49705942170873],
              [-161.12760128481463, -79.6342086730113],
              [-162.4398467682184, -79.28146534618702],
              [-163.02740780337697, -78.92877369579494],
              [-163.06660437727032, -78.86996591584676],
              [-163.71289567772874, -78.59566741324153],
              [-163.71289567772874, -78.59566660579728],
              [-163.10580095116381, -78.2233379111343],
              [-161.24511349184635, -78.38017588314015],
              [-160.2462080556445, -78.69364512142266],
              [-159.48240454815448, -79.04633757925899],
              [-159.20818356019765, -79.49705942170873],
            ],
          ],
          [
            [
              [-45.154757656421026, -78.04706960058674],
              [-43.920827806155756, -78.47810272233325],
              [-43.489949713706096, -79.08555999136846],
              [-43.37243750667437, -79.51664478954726],
              [-43.333266770997085, -80.02612273551293],
              [-44.88053666846429, -80.33964365022771],
              [-46.50617387550201, -80.59435678499432],
              [-48.38642086444178, -80.82948455192235],
              [-50.48210689960646, -81.02544158317312],
              [-52.85198808451178, -80.96668547965729],
              [-54.1642594061316, -80.63352752067158],
              [-53.98799109558405, -80.22202809033139],
              [-51.85313432474219, -79.94772958772609],
              [-50.99132646341059, -79.61462330517266],
              [-50.36459469257474, -79.18348683056163],
              [-49.91413123228651, -78.81120900488669],
              [-49.30695899107312, -78.45856903092692],
              [-48.66061601418252, -78.04701792415445],
              [-48.66061601418252, -78.04701873159868],
              [-48.1513964503784, -78.04706960058674],
              [-46.662856818211026, -77.83147552506503],
              [-45.154757656421026, -78.04706960058674],
            ],
          ],
          [
            [
              [-121.21151139385712, -73.50099049900604],
              [-119.91885127829205, -73.65772511814733],
              [-118.72414303269191, -73.4813534547352],
              [-119.29211870001195, -73.83409678155948],
              [-120.23221716370998, -74.08880991632616],
              [-121.62282995668426, -74.0104684449716],
              [-122.62173458544189, -73.65777760202388],
              [-122.62173539288612, -73.65777679457963],
              [-122.40624467022903, -73.32461883559391],
              [-121.21151139385712, -73.50099049900604],
            ],
          ],
          [
            [
              [-125.55956640689531, -73.4813534547352],
              [-124.03188187726685, -73.87326751723674],
              [-124.61946875064153, -73.83409678155948],
              [-125.91218054263891, -73.7361182659341],
              [-127.28312964568191, -73.46176889434082],
              [-127.28313045312618, -73.46176808689657],
              [-126.55847184309721, -73.24622568780717],
              [-125.55956640689531, -73.4813534547352],
            ],
          ],
          [
            [
              [-98.9815496488239, -71.93333424899978],
              [-97.88474321164503, -72.07053517673472],
              [-96.78793677446623, -71.9529712932707],
              [-96.20034990109144, -72.52120534275218],
              [-96.98376461463621, -72.44286387139763],
              [-98.19808325884685, -72.48203460707492],
              [-99.4320131091122, -72.44286387139763],
              [-100.78345516640921, -72.50161997491355],
              [-101.80186845580133, -72.30566294366277],
              [-102.33072506387637, -71.89416432076685],
              [-102.33072506387637, -71.89416351332261],
              [-101.70396745482444, -71.71779184991038],
              [-100.4309185453141, -71.85499277764532],
              [-98.9815496488239, -71.93333424899978],
            ],
          ],
          [
            [
              [-68.45134599473042, -70.95582285576674],
              [-68.3338337876987, -71.40649302178419],
              [-68.51012793646242, -71.79840708428573],
              [-68.78429724798698, -72.17073577894863],
              [-69.9594709947364, -72.3078850302513],
              [-71.07588863797014, -72.50384206150207],
              [-72.38813412137378, -72.48425669366343],
              [-71.89849992540829, -72.09234263116188],
              [-73.07362199572542, -72.22949188246454],
              [-74.19003963895906, -72.3666928101995],
              [-74.95389482288138, -72.07275726332324],
              [-75.01262508818121, -71.66125783298307],
              [-73.91581865100233, -71.26934457792576],
              [-73.91581865100233, -71.26934377048153],
              [-73.23033077665055, -71.15177988701751],
              [-72.07471655952355, -71.19095062269477],
              [-71.78096188016036, -70.68147267672913],
              [-71.72217993842844, -70.30919565849851],
              [-71.74179114448319, -69.5057821656568],
              [-71.17381547716315, -69.03547495536841],
              [-70.25325151231573, -68.8787403362272],
              [-69.72444658067306, -69.25101735445782],
              [-69.48942216660959, -69.62334604912073],
              [-69.05851823594381, -70.07401621513817],
              [-68.72554114447105, -70.50515268974928],
              [-68.45134599473042, -70.95582285576674],
            ],
          ],
          [
            [
              [-58.61414282900091, -64.15246713013315],
              [-59.045072597882864, -64.36800952922263],
              [-59.789342413966544, -64.21122323364905],
              [-60.61192786318869, -64.30920174927444],
              [-61.297415737540376, -64.54432951620257],
              [-62.022100185785426, -64.79909432740136],
              [-62.511760219966895, -65.09302987427752],
              [-62.648857794837376, -65.48494232189066],
              [-62.59012752953774, -65.85721934012136],
              [-62.120078701410705, -66.1903256226747],
              [-62.805566575762384, -66.42550506603496],
              [-63.745690070232364, -66.50384653738958],
              [-64.29410620792996, -66.83700449637522],
              [-64.88169308130463, -67.15047373465772],
              [-65.50842485214056, -67.58161020926892],
              [-65.66508195663327, -67.95388722749945],
              [-65.31254533553809, -68.36533498140741],
              [-64.78371456567932, -68.6789075725545],
              [-63.96110327824109, -68.91398366305017],
              [-63.197299770751044, -69.22755625419725],
              [-62.78595536970775, -69.6194186402665],
              [-62.57051632348291, -69.9917473349295],
              [-62.27673580590354, -70.38366139743104],
              [-61.80666113956059, -70.71676767998447],
              [-61.5129064601974, -71.08904469821508],
              [-61.375808885327125, -72.01007375095313],
              [-61.08197669131553, -72.38235076918383],
              [-61.00366105817719, -72.77426483168537],
              [-60.69026933454315, -73.16617889418708],
              [-60.82736690941343, -73.69524220799119],
              [-61.375808885327125, -74.10674163833137],
              [-61.96336992048569, -74.43984792088489],
              [-63.29520077172796, -74.57699717218738],
              [-63.745690070232364, -74.92974049901173],
              [-64.35283647322959, -75.26284678156516],
              [-65.86098731145177, -75.63512379979578],
              [-67.19281816269412, -75.79191009536945],
              [-68.44628170436576, -76.00745249445876],
              [-69.79772376166284, -76.22299489354818],
              [-70.60072384304624, -76.63449432388845],
              [-72.20677568224536, -76.67366505956572],
              [-73.96953630236966, -76.63449432388845],
              [-75.55597693551402, -76.7128874716752],
              [-77.2403702460676, -76.7128874716752],
              [-76.92697852243359, -77.10480153417674],
              [-75.39929399280493, -77.28106984472439],
              [-74.28287634957141, -77.55542002376181],
              [-73.65611874051945, -77.90811167415396],
              [-74.77253638375308, -78.22163258886867],
              [-76.49610042998401, -78.12365407324327],
              [-77.92585812041926, -78.37841888444225],
              [-77.98466590036747, -78.78991831478234],
              [-78.02378495961247, -79.18183318472822],
              [-76.84863705107912, -79.51493946728165],
              [-76.6332238430704, -79.88721648551227],
              [-75.36009741891175, -80.25954518017525],
              [-73.24485185412462, -80.41633147574875],
              [-71.44294633653922, -80.69062997835398],
              [-70.01316280788775, -81.00415089306878],
              [-68.19164608424754, -81.31767180778357],
              [-65.70427853052666, -81.47445810335725],
              [-63.2560300360507, -81.74875660596248],
              [-61.5520255194423, -82.04269215283854],
              [-59.69141557477345, -82.37585011182435],
              [-58.712121344626205, -82.84610564568034],
              [-58.222487148660925, -83.21843434034334],
              [-57.00811682801781, -82.86569101351907],
              [-55.36289425314155, -82.57175546664283],
              [-53.61977067728824, -82.25823455192803],
              [-51.543644171746024, -82.00352141716135],
              [-49.76134986021546, -81.72917123812375],
              [-47.27393063006221, -81.7095858702853],
              [-44.82570797380251, -81.84673512158777],
              [-42.80836340999238, -82.0819145649481],
              [-42.16202043310179, -81.65082976676929],
              [-40.771433478343596, -81.35689421989322],
              [-38.24481767429705, -81.33730885205458],
              [-36.26666968438022, -81.12171477653298],
              [-34.386396857224355, -80.90617237744347],
              [-32.31029618989831, -80.76902312614074],
              [-30.097097947701997, -80.59265146272868],
              [-28.5498022120187, -80.33793832796201],
              [-29.254901292425128, -79.98519500113764],
              [-29.685805223090963, -79.63250335074568],
              [-29.685805223090963, -79.26022633251497],
              [-31.62480831554663, -79.29939706819223],
              [-33.68132361503396, -79.45613168733344],
              [-35.639912075328255, -79.45613168733344],
              [-35.91410722506902, -79.08385466910292],
              [-35.777009650198714, -78.33924814876498],
              [-35.32654618991043, -78.12365407324327],
              [-33.89676266125886, -77.88852630631523],
              [-32.2123693507053, -77.65345021581956],
              [-30.99805070649461, -77.35951466894332],
              [-29.78373206228406, -77.06557912206725],
              [-28.882779303491358, -76.67366505956572],
              [-27.511751878355653, -76.49734507258579],
              [-26.16033565927475, -76.36014414485074],
              [-25.474821946706864, -76.28180267349629],
              [-23.92755204923978, -76.24258026138673],
              [-22.45859778491095, -76.10543101008425],
              [-21.22469377286177, -75.90947397883347],
              [-20.010375128651077, -75.67434621190543],
              [-18.91354285325616, -75.43921844497729],
              [-17.522981736714172, -75.1256975302625],
              [-16.641588507544014, -74.79253957127686],
              [-15.70149085129026, -74.49860402440063],
              [-15.407710333710867, -74.10674163833137],
              [-16.465320196996373, -73.87161387140341],
              [-16.11278357590126, -73.46011444106315],
              [-15.44685523117195, -73.14654184991608],
              [-14.408804897508986, -72.9505848186653],
              [-13.311972622113984, -72.71545705173733],
              [-12.293507656289563, -72.40193613702255],
              [-11.510067104528588, -72.01007375095313],
              [-11.020432908563038, -71.53976654066483],
              [-10.295774298534155, -71.2654163616273],
              [-9.101015183946089, -71.3242241415755],
              [-8.611380987980596, -71.65733042412884],
              [-7.416621873392415, -71.69650115980603],
              [-7.37745113771524, -71.3242241415755],
              [-6.868231573911117, -70.93231007907397],
              [-5.790984666354774, -71.03028859469927],
              [-5.53637488445267, -71.40261728936225],
              [-4.341667446296867, -71.46137339287807],
              [-3.048981492515594, -71.28505340589814],
              [-1.795492112627784, -71.16743784600183],
              [-0.659489101555522, -71.22624562595003],
              [-0.228636847322065, -71.6377450562903],
              [0.868195428072937, -71.30463877373678],
              [1.886686232113533, -71.12826711032474],
              [3.022637566753417, -70.99111785902207],
              [4.139055209987049, -70.85391693128703],
              [5.157546014027673, -70.61878916435909],
              [6.273911980828927, -70.46205454521778],
              [7.135719842160626, -70.24651214612838],
              [7.742866245157842, -69.89376881930403],
              [8.487110223025326, -70.148533630503],
              [9.525134718472202, -70.01133270276821],
              [10.249845004933434, -70.48163991305651],
              [10.81782067225339, -70.8343315634485],
              [11.953823683325652, -70.63837453219772],
              [12.404287143613942, -70.24651214612838],
              [13.422777947654394, -69.97216196709094],
              [14.734997592842005, -70.03091807060677],
              [15.126756626046586, -70.40324676526976],
              [15.949342075268646, -70.03091807060677],
              [17.02658898282516, -69.91335418714274],
              [18.201711053142333, -69.87418345146547],
              [19.259372592860046, -69.89376881930403],
              [20.37573855966147, -70.01133270276821],
              [21.452985467217815, -70.07014048271625],
              [21.923034295344735, -70.40324676526976],
              [22.569403110451447, -70.69718231214583],
              [23.66618370941421, -70.5208106487337],
              [24.841357456163593, -70.48163991305651],
              [25.977308790803644, -70.48163991305651],
              [27.093726434037276, -70.46205454521778],
              [28.092580193806867, -70.32485361748293],
              [29.15024173352458, -70.20728973401899],
              [30.03158328626253, -69.93293955498129],
              [30.97173261894861, -69.75661956800145],
              [31.99017174655685, -69.65864105237607],
              [32.75405276869529, -69.38429087333846],
              [33.30244306817676, -68.83564219169571],
              [33.8704187354966, -68.50258758557456],
              [34.908494907375854, -68.65927052828349],
              [35.30020226414822, -69.01201385510794],
              [36.16201012547978, -69.24714162203597],
              [37.20003462092657, -69.16874847424904],
              [37.9051078631168, -69.5214401246412],
              [38.649403517416914, -69.77620493584018],
              [39.66789432145733, -69.54107716891204],
              [40.020430942552565, -69.10994069430095],
              [40.92135786312909, -68.93362070732118],
              [41.95943403500823, -68.60051442476767],
              [42.9387024269391, -68.46331349703271],
              [44.113876173688624, -68.26740814221424],
              [44.897290887233424, -68.05186574312492],
              [45.719928012887834, -67.81673797619678],
              [46.503342726432635, -67.60119557710746],
              [47.443440382686305, -67.71875946057148],
              [48.34441897969512, -67.36606781017942],
              [48.9907361183696, -67.09171763114189],
              [49.93088545105567, -67.11130299898045],
              [50.75347090027773, -66.8761752320524],
              [50.94932457866392, -66.52348358166043],
              [51.791547072157044, -66.2491334026229],
              [52.614132521378934, -66.05317637137213],
              [53.613037957580815, -65.89639007579855],
              [54.533550245996054, -65.818048604444],
              [55.41494347516621, -65.8768047079599],
              [56.35504113141988, -65.97478322358538],
              [57.15809288923568, -66.2491334026229],
              [57.25596805199646, -66.68021820080163],
              [58.13736128116661, -67.01332448335515],
              [58.74450768416394, -67.28767466239267],
              [59.939318475184244, -67.40523854585669],
              [60.605220981697435, -67.6795887248942],
              [61.427806430919325, -67.95388722749945],
              [62.38748945501166, -68.01269500744755],
              [63.190489536395226, -67.81673797619678],
              [64.05234907415901, -67.40523854585669],
              [64.99244673041292, -67.62072926851371],
              [65.97171512234397, -67.73834482841004],
              [66.91186445502976, -67.85590871187414],
              [67.89113284696091, -67.9343018596608],
              [68.89003828316291, -67.9343018596608],
              [69.71262373238474, -68.97279144299837],
              [69.67345299670748, -69.22755625419725],
              [69.55594078967582, -69.67822642021471],
              [68.59625776558347, -69.93293955498129],
              [67.81273969917416, -70.30526824964429],
              [67.94988895047666, -70.69718231214583],
              [69.06630659371027, -70.67754526787499],
              [68.92915734240776, -71.06945933037653],
              [68.41998945503596, -71.44178802503953],
              [67.94988895047666, -71.85328745537961],
              [68.71376997261515, -72.16680837009442],
              [69.86930667509395, -72.2647868857198],
              [71.02489505400457, -72.08841522230775],
              [71.57328535348606, -71.69650115980603],
              [71.90628828317492, -71.3242241415755],
              [72.45462690622404, -71.01070322686063],
              [73.08141035349209, -70.71676767998447],
              [73.3360201353942, -70.3640243531602],
              [73.86487674346924, -69.87418345146547],
              [74.49155683787271, -69.77620493584018],
              [75.62755984894497, -69.73703420016281],
              [76.62646528514685, -69.6194186402665],
              [77.64490441275527, -69.4626840211253],
              [78.13453860872059, -69.07076995862376],
              [78.42837080273225, -68.69844126396067],
              [79.11385867708393, -68.32621592216243],
              [80.09312706901486, -68.07150278739576],
              [80.93534956250775, -67.87554575614499],
              [81.48379153842144, -67.54238779715926],
              [82.05176720574146, -67.36606781017942],
              [82.7764258157704, -67.20928151460592],
              [83.7753312519724, -67.30726003023122],
              [84.67620649611663, -67.20928151460592],
              [85.65552656447997, -67.09171763114189],
              [86.75235883987486, -67.15047373465772],
              [87.4770174499038, -66.8761752320524],
              [87.98628869014024, -66.20991099051335],
              [88.35841067907394, -66.48426116955086],
              [88.82840783076855, -66.95456837983923],
              [89.67063032426157, -67.15047373465772],
              [90.6303650247863, -67.22886688244446],
              [91.59009972531081, -67.11130299898045],
              [92.60853885291905, -67.1896961467672],
              [93.54863650917295, -67.20928151460592],
              [94.17541995644099, -67.11130299898045],
              [95.01759077350167, -67.17011077892865],
              [95.78147179564027, -67.38565317801798],
              [96.6823987162168, -67.24850392671549],
              [97.75964562377312, -67.24850392671549],
              [98.68020958862056, -67.11130299898045],
              [99.71818240763504, -67.24850392671549],
              [100.38418826701276, -66.91534596772968],
              [100.89335615438468, -66.58223968517625],
              [101.57889570516855, -66.30788950613864],
              [102.83241092327265, -65.56328379324512],
              [103.47867638551477, -65.70048472097997],
              [104.2425574076531, -65.97478322358538],
              [104.90845991416623, -66.32752655040964],
              [106.18156050010876, -66.9349313355684],
              [107.1608805684721, -66.95456837983923],
              [108.08139285688715, -66.95456837983923],
              [109.15863976444368, -66.83700449637522],
              [110.23583499556784, -66.69980356864036],
              [111.05847212122208, -66.42550506603496],
              [111.74395999557393, -66.13156951915889],
              [112.86037763880748, -66.09234710704932],
              [113.60467329310735, -65.8768047079599],
              [114.38808800665205, -66.07276173921068],
              [114.89730757045626, -66.38628265392548],
              [115.60238081264653, -66.69980356864036],
              [116.69916141160942, -66.66063283296299],
              [117.38470096239321, -66.91534596772968],
              [118.57946007698128, -67.17011077892865],
              [119.8329236186531, -67.26808929455395],
              [120.87099979053217, -67.1896961467672],
              [121.65441450407708, -66.8761752320524],
              [122.32036868702235, -66.56265431733769],
              [123.22129560759893, -66.48426116955086],
              [124.12227420460762, -66.6214620972859],
              [125.16024702362223, -66.71938893647891],
              [126.10039635630838, -66.56265431733769],
              [127.00142662974932, -66.56265431733769],
              [127.88276818248723, -66.66063283296299],
              [128.8032804709024, -66.75861134858846],
              [129.70425906791115, -66.58223968517625],
              [130.78145429903543, -66.42550506603496],
              [131.79994510307588, -66.38628265392548],
              [132.93589643771614, -66.38628265392548],
              [133.85646040256339, -66.28830413830009],
              [134.7573873231399, -66.20996266694563],
              [135.03158247288073, -65.72007008881862],
              [135.07075320855782, -65.30857065847843],
              [135.69748497939358, -65.58286916108366],
              [135.8738049663735, -66.03359100353342],
              [136.2067045431978, -66.44509043387367],
              [136.6180489442411, -66.77819671642702],
              [137.46027143773395, -66.95456837983923],
              [138.59622277237415, -66.89576059989113],
              [139.90844241756147, -66.8761752320524],
              [140.8094210145703, -66.81736745210438],
              [142.1216923361902, -66.81736745210438],
              [143.06184166887616, -66.79778208426566],
              [144.3740613140637, -66.83700449637522],
              [145.49042728086502, -66.91534596772968],
              [146.19555219948782, -67.22886688244446],
              [145.99969852110152, -67.60119557710746],
              [146.64606733620823, -67.8951311239837],
              [147.72326256733234, -68.13025889091166],
              [148.8396285341337, -68.38502370211054],
              [150.1323144879149, -68.56129201265819],
              [151.4837048687796, -68.71812998466397],
              [152.50224734925249, -68.87481292737299],
              [153.63819868389257, -68.8945016480761],
              [154.28456749899928, -68.56129201265819],
              [155.16585737530485, -68.83564219169571],
              [155.92979007387547, -69.14921478284279],
              [156.8111316266134, -69.38429087333846],
              [158.0255277854724, -69.48226938896394],
              [159.18101281151874, -69.59983327242796],
              [159.67069868391653, -69.9917473349295],
              [160.8066500185565, -70.22687510185754],
              [161.57047936426278, -70.57961842868181],
              [162.68689700749636, -70.73635304782319],
              [163.84243370997493, -70.71676767998447],
              [164.9196806175312, -70.77552378350029],
              [166.11443973211945, -70.75593841566175],
              [167.309095493843, -70.8343315634485],
              [168.42561648994118, -70.97148081475106],
              [169.46358930895596, -71.2066602581114],
              [170.50166548083504, -71.40261728936225],
              [171.20679039945762, -71.69650115980603],
              [171.08922651599377, -72.08841522230775],
              [170.56042158435073, -72.44115854913211],
              [170.1099581240624, -72.89182871514939],
              [169.75736982653515, -73.24452036554155],
              [169.2873209984083, -73.65601979588163],
              [167.97510135322077, -73.81280609145513],
              [167.38748864162972, -74.16549774184719],
              [166.09480268784844, -74.3810401409366],
              [165.64439090399244, -74.77295420343815],
              [164.9588513532086, -75.14528289810123],
              [164.23419274317968, -75.45880381281593],
              [163.82279666570392, -75.8703032431562],
              [163.56823856023428, -76.24258026138673],
              [163.47026004460898, -76.69330210383656],
              [163.48989708887976, -77.06557912206725],
              [164.05787275619977, -77.45744150813643],
              [164.27336347885696, -77.82977020279932],
              [164.74346398341615, -78.18251352962376],
              [166.60412560451735, -78.31961110449406],
              [166.99578128485743, -78.75074757910525],
              [165.193875767272, -78.9074830056907],
              [163.66621707585958, -79.12302540478002],
              [161.7663847190811, -79.16224781688966],
              [160.9241622255883, -79.73048186637098],
              [160.74789391504075, -80.20073740022715],
              [160.31696414615868, -80.57306609488995],
              [159.78821089094842, -80.94539478955305],
              [161.1200159039744, -81.27850107210647],
              [161.62928714421088, -81.69000050244657],
              [162.49099165267805, -82.06227752067727],
              [163.70533613510474, -82.3954354796629],
              [165.09594892807885, -82.70895639437778],
              [166.60412560451735, -83.02247730909258],
              [168.89566531806796, -83.33599822380737],
              [169.40478152900758, -83.82589080193438],
              [172.28393395414938, -84.0414332010237],
              [172.47704878162415, -84.11791432081567],
              [173.2240832868354, -84.41371021925441],
              [175.9856718285131, -84.15899708448764],
              [178.27721154206407, -84.47251799920242],
              [180.00000000000014, -84.71338],
              [180.00000000000014, -90],
              [-180, -90],
              [-180, -84.71338],
              [-179.9424993561789, -84.72144337355249],
              [-179.0586773346912, -84.1394117166491],
              [-177.25677181710574, -84.45293263136388],
              [-177.14080667326579, -84.41794122714832],
              [-176.0846728180776, -84.09925912875842],
              [-175.94723461362776, -84.11044871021662],
              [-175.82988216866252, -84.11791432081567],
              [-174.3825028148157, -84.53432301222357],
              [-173.11655941474547, -84.11791432081567],
              [-172.8891055980128, -84.06101856886234],
              [-169.95122290757143, -83.88464690545011],
              [-168.9999889801586, -84.11791432081567],
              [-168.53019853419323, -84.23739023227448],
              [-167.02209937240332, -84.5704965148279],
              [-164.18214352115507, -84.82520964959458],
              [-161.92977454328138, -85.13873056430938],
              [-158.07137956442494, -85.3739100076697],
              [-155.1922529774993, -85.0995598286321],
              [-150.94209896543802, -85.29551685988288],
              [-148.5330728830715, -85.60903777459767],
              [-145.88891822633298, -85.31510222772161],
              [-143.10771847860042, -85.04075204868391],
              [-142.89227943237563, -84.5704965148279],
              [-146.8290683664633, -84.53127410271834],
              [-150.06073157448395, -84.29614633579038],
              [-150.9029282297607, -83.90423227328884],
              [-153.5862011383002, -83.68868987419935],
              [-153.40990698953647, -83.23801970818207],
              [-153.0377591623864, -82.82652027784181],
              [-152.66563717345275, -82.45419158317881],
              [-152.86151669005505, -82.04269215283854],
              [-154.5262987945539, -81.7683936502333],
              [-155.2901798166924, -81.41565032340904],
              [-156.8374497141595, -81.10212940869425],
              [-154.40878658752223, -81.16093718864245],
              [-152.09766150613282, -81.00415089306878],
              [-150.64829260964262, -81.33730885205458],
              [-148.86599829811206, -81.04337330517833],
              [-147.2207498850195, -80.67104461051542],
              [-146.41774899619185, -80.33793832796201],
              [-146.77028642473118, -79.9264388976219],
              [-148.06294654029637, -79.65208871858422],
              [-149.5319008046251, -79.35820484814045],
              [-151.5884161041124, -79.29939706819223],
              [-153.39032162169778, -79.16224781688966],
              [-155.32937639058574, -79.06426930126419],
              [-155.97566769104418, -78.69193979915704],
              [-157.26830196839305, -78.37841888444225],
              [-158.0517683583701, -78.0256755576179],
              [-158.36513424378796, -76.88920745865494],
              [-157.87547420960635, -76.98723765071261],
              [-156.97457312724595, -77.30075856542751],
              [-155.32937639058574, -77.20272837336975],
              [-153.74283240457675, -77.06557912206725],
              [-152.92024695535477, -77.49666392024598],
              [-151.3337804839943, -77.3987370810528],
              [-150.00194963275194, -77.18314300553119],
              [-148.74848609108034, -76.90884450292597],
              [-147.61248308000808, -76.57573822037253],
              [-146.10440894899, -76.47775970474706],
              [-146.14352800823497, -76.10543101008425],
              [-146.49609127499048, -75.73315399185354],
              [-146.202309949967, -75.38041066502917],
              [-144.90962399618576, -75.20403900161696],
              [-144.32203712281108, -75.53719696060276],
              [-142.79435259318262, -75.341239929352],
              [-141.63876421427162, -75.08647511815295],
              [-140.20900652383617, -75.06688975031439],
              [-138.85759030475535, -74.96891123468892],
              [-137.50619992389045, -74.73378346776096],
              [-136.42890133990193, -74.51824106867164],
              [-135.2145826956913, -74.30269866958214],
              [-134.43119382036258, -74.36145477309796],
              [-133.74565426957855, -74.43984792088489],
              [-132.25716792873206, -74.30269866958214],
              [-130.92531123927358, -74.47901865656199],
              [-129.55428381413776, -74.45943328872343],
              [-128.24203833073426, -74.3222840374207],
              [-126.89062211165324, -74.42026255304617],
              [-125.40208247948578, -74.51824106867164],
              [-124.01149552472761, -74.47901865656199],
              [-122.5621524664536, -74.49860402440063],
              [-121.07361283428624, -74.51824106867164],
              [-119.70255957093421, -74.47901865656199],
              [-118.68414547409793, -74.18508310968583],
              [-117.46980099167122, -74.02834849054462],
              [-116.2163116117834, -74.24389088963395],
              [-115.02155249719543, -74.06751922622189],
              [-113.94433142785508, -73.71482757582983],
              [-113.29798845096448, -74.02834849054462],
              [-112.94545182986937, -74.3810401409366],
              [-112.29908301476257, -74.7141980999224],
              [-111.26105851931563, -74.42026255304617],
              [-110.06632524294372, -74.79253957127686],
              [-108.71490902386272, -74.91010345474089],
              [-107.55934648316811, -75.18445363377842],
              [-106.14914832235502, -75.1256975302625],
              [-104.87607357462866, -74.94932586685044],
              [-103.36794857462266, -74.98849660252765],
              [-102.01650651732564, -75.1256975302625],
              [-100.64553076862231, -75.30201751724243],
              [-100.11669999876325, -74.87093271906353],
              [-100.76304297565393, -74.53782643651019],
              [-101.25270300983553, -74.18508310968583],
              [-102.5453372871845, -74.10674163833137],
              [-103.11331295450454, -73.73441294366839],
              [-103.32875200072928, -73.36208424900556],
              [-103.68128862182438, -72.61753021254415],
              [-102.91748511433435, -72.75467946384681],
              [-101.60523963093073, -72.81343556736263],
              [-100.31252783893345, -72.75467946384681],
              [-99.1373799304001, -72.9114140829881],
              [-98.11888912635946, -73.20534962986417],
              [-97.68803687212609, -73.55804128025633],
              [-96.3365948148289, -73.61684906020436],
              [-95.04396053747983, -73.47969980890187],
              [-93.6729072741281, -73.28374277765093],
              [-92.43900326207896, -73.16617889418708],
              [-91.42056413447071, -73.40130666111513],
              [-90.08873328322844, -73.3229135133282],
              [-89.22695126011294, -72.55872243259596],
              [-88.4239511787295, -73.0093925986134],
              [-87.26833696160261, -73.18576426202563],
              [-86.01482174349842, -73.08778574640016],
              [-85.19223629427657, -73.47969980890187],
              [-83.87999081087275, -73.51887054457897],
              [-82.66564632844602, -73.63643442804309],
              [-81.47091305207414, -73.8519768271324],
              [-80.68744666209699, -73.47969980890187],
              [-80.295790981757, -73.12695648207743],
              [-79.29688554555499, -73.51887054457897],
              [-77.92585812041926, -73.42089202895359],
              [-76.90736731637874, -73.63643442804309],
              [-76.22187944202707, -73.96954071059642],
              [-74.8900485907848, -73.87161387140341],
              [-73.85202409533792, -73.65601979588163],
              [-72.83353329129741, -73.40130666111513],
              [-71.61921464708686, -73.26415740981237],
              [-70.20904232448996, -73.14654184991608],
              [-68.93591590033121, -73.0093925986134],
              [-67.95662167018409, -72.79385019952409],
              [-67.36906063502559, -72.4803292848093],
              [-67.13403622096203, -72.04924448663039],
              [-67.25154842799367, -71.6377450562903],
              [-67.5649401516279, -71.24583099378876],
              [-67.917476772723, -70.85391693128703],
              [-68.23084265814092, -70.46205454521778],
              [-68.48545244004302, -70.10931121839351],
              [-68.54420854355894, -69.71739715589197],
              [-68.44628170436576, -69.32553476982272],
              [-67.9762328762389, -68.95320607515973],
              [-67.58449968125032, -68.54170664481947],
              [-67.42784257675751, -68.14984425875022],
              [-67.6236704169277, -67.71875946057148],
              [-67.74118262395933, -67.32684539806993],
              [-67.25154842799367, -66.8761752320524],
              [-66.70318396672857, -66.58223968517625],
              [-66.05681515162186, -66.20996266694563],
              [-65.37132727727008, -65.89639007579855],
              [-64.5682755194544, -65.60250620535467],
              [-64.17654232446583, -65.17142302206445],
              [-63.62815202498453, -64.89707284302675],
              [-63.00139441593256, -64.64230803182787],
              [-62.04168555362398, -64.58355192831195],
              [-61.414927944572014, -64.27003101359716],
              [-60.709854702381705, -64.07407398234639],
              [-59.88726925315956, -63.95651009888237],
              [-59.16258480491453, -63.70174528768357],
              [-58.59455746116228, -63.388224372968594],
              [-57.81114274761751, -63.27066048950457],
              [-57.22358171245884, -63.52542530070364],
              [-57.595729539608875, -63.85853158325706],
              [-58.61414282900091, -64.15246713013315],
            ],
          ],
        ],
      },
    },
  ],
};

export const COUNTRIES: any = {
  AL: {
    country: "Albania",
    alpha2: "AL",
    alpha3: "ALB",
    numeric: 8,
    latitude: 41,
    longitude: 20,
  },
  DZ: {
    country: "Algeria",
    alpha2: "DZ",
    alpha3: "DZA",
    numeric: 12,
    latitude: 28,
    longitude: 3,
  },
  AS: {
    country: "American Samoa",
    alpha2: "AS",
    alpha3: "ASM",
    numeric: 16,
    latitude: -14.3333,
    longitude: -170,
  },
  AD: {
    country: "Andorra",
    alpha2: "AD",
    alpha3: "AND",
    numeric: 20,
    latitude: 42.5,
    longitude: 1.6,
  },
  AO: {
    country: "Angola",
    alpha2: "AO",
    alpha3: "AGO",
    numeric: 24,
    latitude: -12.5,
    longitude: 18.5,
  },
  AI: {
    country: "Anguilla",
    alpha2: "AI",
    alpha3: "AIA",
    numeric: 660,
    latitude: 18.25,
    longitude: -63.1667,
  },
  AQ: {
    country: "Antarctica",
    alpha2: "AQ",
    alpha3: "ATA",
    numeric: 10,
    latitude: -90,
    longitude: 0,
  },
  AG: {
    country: "Antigua and Barbuda",
    alpha2: "AG",
    alpha3: "ATG",
    numeric: 28,
    latitude: 17.05,
    longitude: -61.8,
  },
  AR: {
    country: "Argentina",
    alpha2: "AR",
    alpha3: "ARG",
    numeric: 32,
    latitude: -34,
    longitude: -64,
  },
  AM: {
    country: "Armenia",
    alpha2: "AM",
    alpha3: "ARM",
    numeric: 51,
    latitude: 40,
    longitude: 45,
  },
  AW: {
    country: "Aruba",
    alpha2: "AW",
    alpha3: "ABW",
    numeric: 533,
    latitude: 12.5,
    longitude: -69.9667,
  },
  AU: {
    country: "Australia",
    alpha2: "AU",
    alpha3: "AUS",
    numeric: 36,
    latitude: -27,
    longitude: 133,
  },
  AT: {
    country: "Austria",
    alpha2: "AT",
    alpha3: "AUT",
    numeric: 40,
    latitude: 47.3333,
    longitude: 13.3333,
  },
  AZ: {
    country: "Azerbaijan",
    alpha2: "AZ",
    alpha3: "AZE",
    numeric: 31,
    latitude: 40.5,
    longitude: 47.5,
  },
  BS: {
    country: "Bahamas",
    alpha2: "BS",
    alpha3: "BHS",
    numeric: 44,
    latitude: 24.25,
    longitude: -76,
  },
  BH: {
    country: "Bahrain",
    alpha2: "BH",
    alpha3: "BHR",
    numeric: 48,
    latitude: 26,
    longitude: 50.55,
  },
  BD: {
    country: "Bangladesh",
    alpha2: "BD",
    alpha3: "BGD",
    numeric: 50,
    latitude: 24,
    longitude: 90,
  },
  BB: {
    country: "Barbados",
    alpha2: "BB",
    alpha3: "BRB",
    numeric: 52,
    latitude: 13.1667,
    longitude: -59.5333,
  },
  BY: {
    country: "Belarus",
    alpha2: "BY",
    alpha3: "BLR",
    numeric: 112,
    latitude: 53,
    longitude: 28,
  },
  BE: {
    country: "Belgium",
    alpha2: "BE",
    alpha3: "BEL",
    numeric: 56,
    latitude: 50.8333,
    longitude: 4,
  },
  BZ: {
    country: "Belize",
    alpha2: "BZ",
    alpha3: "BLZ",
    numeric: 84,
    latitude: 17.25,
    longitude: -88.75,
  },
  BJ: {
    country: "Benin",
    alpha2: "BJ",
    alpha3: "BEN",
    numeric: 204,
    latitude: 9.5,
    longitude: 2.25,
  },
  BM: {
    country: "Bermuda",
    alpha2: "BM",
    alpha3: "BMU",
    numeric: 60,
    latitude: 32.3333,
    longitude: -64.75,
  },
  BT: {
    country: "Bhutan",
    alpha2: "BT",
    alpha3: "BTN",
    numeric: 64,
    latitude: 27.5,
    longitude: 90.5,
  },
  BO: {
    country: "Bolivia, Plurinational State of",
    alpha2: "BO",
    alpha3: "BOL",
    numeric: 68,
    latitude: -17,
    longitude: -65,
  },
  BA: {
    country: "Bosnia and Herzegovina",
    alpha2: "BA",
    alpha3: "BIH",
    numeric: 70,
    latitude: 44,
    longitude: 18,
  },
  BW: {
    country: "Botswana",
    alpha2: "BW",
    alpha3: "BWA",
    numeric: 72,
    latitude: -22,
    longitude: 24,
  },
  BV: {
    country: "Bouvet Island",
    alpha2: "BV",
    alpha3: "BVT",
    numeric: 74,
    latitude: -54.4333,
    longitude: 3.4,
  },
  BR: {
    country: "Brazil",
    alpha2: "BR",
    alpha3: "BRA",
    numeric: 76,
    latitude: -10,
    longitude: -55,
  },
  IO: {
    country: "British Indian Ocean Territory",
    alpha2: "IO",
    alpha3: "IOT",
    numeric: 86,
    latitude: -6,
    longitude: 71.5,
  },
  BN: {
    country: "Brunei Darussalam",
    alpha2: "BN",
    alpha3: "BRN",
    numeric: 96,
    latitude: 4.5,
    longitude: 114.6667,
  },
  BG: {
    country: "Bulgaria",
    alpha2: "BG",
    alpha3: "BGR",
    numeric: 100,
    latitude: 43,
    longitude: 25,
  },
  BF: {
    country: "Burkina Faso",
    alpha2: "BF",
    alpha3: "BFA",
    numeric: 854,
    latitude: 13,
    longitude: -2,
  },
  BI: {
    country: "Burundi",
    alpha2: "BI",
    alpha3: "BDI",
    numeric: 108,
    latitude: -3.5,
    longitude: 30,
  },
  KH: {
    country: "Cambodia",
    alpha2: "KH",
    alpha3: "KHM",
    numeric: 116,
    latitude: 13,
    longitude: 105,
  },
  CM: {
    country: "Cameroon",
    alpha2: "CM",
    alpha3: "CMR",
    numeric: 120,
    latitude: 6,
    longitude: 12,
  },
  CA: {
    country: "Canada",
    alpha2: "CA",
    alpha3: "CAN",
    numeric: 124,
    latitude: 60,
    longitude: -95,
  },
  CV: {
    country: "Cape Verde",
    alpha2: "CV",
    alpha3: "CPV",
    numeric: 132,
    latitude: 16,
    longitude: -24,
  },
  KY: {
    country: "Cayman Islands",
    alpha2: "KY",
    alpha3: "CYM",
    numeric: 136,
    latitude: 19.5,
    longitude: -80.5,
  },
  CF: {
    country: "Central African Republic",
    alpha2: "CF",
    alpha3: "CAF",
    numeric: 140,
    latitude: 7,
    longitude: 21,
  },
  TD: {
    country: "Chad",
    alpha2: "TD",
    alpha3: "TCD",
    numeric: 148,
    latitude: 15,
    longitude: 19,
  },
  CL: {
    country: "Chile",
    alpha2: "CL",
    alpha3: "CHL",
    numeric: 152,
    latitude: -30,
    longitude: -71,
  },
  CN: {
    country: "China",
    alpha2: "CN",
    alpha3: "CHN",
    numeric: 156,
    latitude: 35,
    longitude: 105,
  },
  CX: {
    country: "Christmas Island",
    alpha2: "CX",
    alpha3: "CXR",
    numeric: 162,
    latitude: -10.5,
    longitude: 105.6667,
  },
  CC: {
    country: "Cocos (Keeling) Islands",
    alpha2: "CC",
    alpha3: "CCK",
    numeric: 166,
    latitude: -12.5,
    longitude: 96.8333,
  },
  CO: {
    country: "Colombia",
    alpha2: "CO",
    alpha3: "COL",
    numeric: 170,
    latitude: 4,
    longitude: -72,
  },
  KM: {
    country: "Comoros",
    alpha2: "KM",
    alpha3: "COM",
    numeric: 174,
    latitude: -12.1667,
    longitude: 44.25,
  },
  CG: {
    country: "Congo",
    alpha2: "CG",
    alpha3: "COG",
    numeric: 178,
    latitude: -1,
    longitude: 15,
  },
  CD: {
    country: "Congo, the Democratic Republic of the",
    alpha2: "CD",
    alpha3: "COD",
    numeric: 180,
    latitude: 0,
    longitude: 25,
  },
  CK: {
    country: "Cook Islands",
    alpha2: "CK",
    alpha3: "COK",
    numeric: 184,
    latitude: -21.2333,
    longitude: -159.7667,
  },
  CR: {
    country: "Costa Rica",
    alpha2: "CR",
    alpha3: "CRI",
    numeric: 188,
    latitude: 10,
    longitude: -84,
  },
  CI: {
    country: "Côte d'Ivoire",
    alpha2: "CI",
    alpha3: "CIV",
    numeric: 384,
    latitude: 8,
    longitude: -5,
  },
  HR: {
    country: "Croatia",
    alpha2: "HR",
    alpha3: "HRV",
    numeric: 191,
    latitude: 45.1667,
    longitude: 15.5,
  },
  CU: {
    country: "Cuba",
    alpha2: "CU",
    alpha3: "CUB",
    numeric: 192,
    latitude: 21.5,
    longitude: -80,
  },
  CY: {
    country: "Cyprus",
    alpha2: "CY",
    alpha3: "CYP",
    numeric: 196,
    latitude: 35,
    longitude: 33,
  },
  CZ: {
    country: "Czech Republic",
    alpha2: "CZ",
    alpha3: "CZE",
    numeric: 203,
    latitude: 49.75,
    longitude: 15.5,
  },
  DK: {
    country: "Denmark",
    alpha2: "DK",
    alpha3: "DNK",
    numeric: 208,
    latitude: 56,
    longitude: 10,
  },
  DJ: {
    country: "Djibouti",
    alpha2: "DJ",
    alpha3: "DJI",
    numeric: 262,
    latitude: 11.5,
    longitude: 43,
  },
  DM: {
    country: "Dominica",
    alpha2: "DM",
    alpha3: "DMA",
    numeric: 212,
    latitude: 15.4167,
    longitude: -61.3333,
  },
  DO: {
    country: "Dominican Republic",
    alpha2: "DO",
    alpha3: "DOM",
    numeric: 214,
    latitude: 19,
    longitude: -70.6667,
  },
  EC: {
    country: "Ecuador",
    alpha2: "EC",
    alpha3: "ECU",
    numeric: 218,
    latitude: -2,
    longitude: -77.5,
  },
  EG: {
    country: "Egypt",
    alpha2: "EG",
    alpha3: "EGY",
    numeric: 818,
    latitude: 27,
    longitude: 30,
  },
  SV: {
    country: "El Salvador",
    alpha2: "SV",
    alpha3: "SLV",
    numeric: 222,
    latitude: 13.8333,
    longitude: -88.9167,
  },
  GQ: {
    country: "Equatorial Guinea",
    alpha2: "GQ",
    alpha3: "GNQ",
    numeric: 226,
    latitude: 2,
    longitude: 10,
  },
  ER: {
    country: "Eritrea",
    alpha2: "ER",
    alpha3: "ERI",
    numeric: 232,
    latitude: 15,
    longitude: 39,
  },
  EE: {
    country: "Estonia",
    alpha2: "EE",
    alpha3: "EST",
    numeric: 233,
    latitude: 59,
    longitude: 26,
  },
  ET: {
    country: "Ethiopia",
    alpha2: "ET",
    alpha3: "ETH",
    numeric: 231,
    latitude: 8,
    longitude: 38,
  },
  FK: {
    country: "Falkland Islands (Malvinas)",
    alpha2: "FK",
    alpha3: "FLK",
    numeric: 238,
    latitude: -51.75,
    longitude: -59,
  },
  FO: {
    country: "Faroe Islands",
    alpha2: "FO",
    alpha3: "FRO",
    numeric: 234,
    latitude: 62,
    longitude: -7,
  },
  FJ: {
    country: "Fiji",
    alpha2: "FJ",
    alpha3: "FJI",
    numeric: 242,
    latitude: -18,
    longitude: 175,
  },
  FI: {
    country: "Finland",
    alpha2: "FI",
    alpha3: "FIN",
    numeric: 246,
    latitude: 64,
    longitude: 26,
  },
  FR: {
    country: "France",
    alpha2: "FR",
    alpha3: "FRA",
    numeric: 250,
    latitude: 46,
    longitude: 2,
  },
  MF: {
    country: "France",
    alpha2: "MF",
    alpha3: "FRA",
    numeric: 250,
    latitude: 48,
    longitude: 2,
  },
  GF: {
    country: "French Guiana",
    alpha2: "GF",
    alpha3: "GUF",
    numeric: 254,
    latitude: 4,
    longitude: -53,
  },
  PF: {
    country: "French Polynesia",
    alpha2: "PF",
    alpha3: "PYF",
    numeric: 258,
    latitude: -15,
    longitude: -140,
  },
  TF: {
    country: "French Southern Territories",
    alpha2: "TF",
    alpha3: "ATF",
    numeric: 260,
    latitude: -43,
    longitude: 67,
  },
  GA: {
    country: "Gabon",
    alpha2: "GA",
    alpha3: "GAB",
    numeric: 266,
    latitude: -1,
    longitude: 11.75,
  },
  GM: {
    country: "Gambia",
    alpha2: "GM",
    alpha3: "GMB",
    numeric: 270,
    latitude: 13.4667,
    longitude: -16.5667,
  },
  GE: {
    country: "Georgia",
    alpha2: "GE",
    alpha3: "GEO",
    numeric: 268,
    latitude: 42,
    longitude: 43.5,
  },
  DE: {
    country: "Germany",
    alpha2: "DE",
    alpha3: "DEU",
    numeric: 276,
    latitude: 51,
    longitude: 9,
  },
  GH: {
    country: "Ghana",
    alpha2: "GH",
    alpha3: "GHA",
    numeric: 288,
    latitude: 8,
    longitude: -2,
  },
  GI: {
    country: "Gibraltar",
    alpha2: "GI",
    alpha3: "GIB",
    numeric: 292,
    latitude: 36.1833,
    longitude: -5.3667,
  },
  GR: {
    country: "Greece",
    alpha2: "GR",
    alpha3: "GRC",
    numeric: 300,
    latitude: 39,
    longitude: 22,
  },
  GL: {
    country: "Greenland",
    alpha2: "GL",
    alpha3: "GRL",
    numeric: 304,
    latitude: 72,
    longitude: -40,
  },
  GD: {
    country: "Grenada",
    alpha2: "GD",
    alpha3: "GRD",
    numeric: 308,
    latitude: 12.1167,
    longitude: -61.6667,
  },
  GP: {
    country: "Guadeloupe",
    alpha2: "GP",
    alpha3: "GLP",
    numeric: 312,
    latitude: 16.25,
    longitude: -61.5833,
  },
  GU: {
    country: "Guam",
    alpha2: "GU",
    alpha3: "GUM",
    numeric: 316,
    latitude: 13.4667,
    longitude: 144.7833,
  },
  GT: {
    country: "Guatemala",
    alpha2: "GT",
    alpha3: "GTM",
    numeric: 320,
    latitude: 15.5,
    longitude: -90.25,
  },
  GG: {
    country: "Guernsey",
    alpha2: "GG",
    alpha3: "GGY",
    numeric: 831,
    latitude: 49.5,
    longitude: -2.56,
  },
  GN: {
    country: "Guinea",
    alpha2: "GN",
    alpha3: "GIN",
    numeric: 324,
    latitude: 11,
    longitude: -10,
  },
  GW: {
    country: "Guinea-Bissau",
    alpha2: "GW",
    alpha3: "GNB",
    numeric: 624,
    latitude: 12,
    longitude: -15,
  },
  GY: {
    country: "Guyana",
    alpha2: "GY",
    alpha3: "GUY",
    numeric: 328,
    latitude: 5,
    longitude: -59,
  },
  HT: {
    country: "Haiti",
    alpha2: "HT",
    alpha3: "HTI",
    numeric: 332,
    latitude: 19,
    longitude: -72.4167,
  },
  HM: {
    country: "Heard Island and McDonald Islands",
    alpha2: "HM",
    alpha3: "HMD",
    numeric: 334,
    latitude: -53.1,
    longitude: 72.5167,
  },
  VA: {
    country: "Holy See (Vatican City State)",
    alpha2: "VA",
    alpha3: "VAT",
    numeric: 336,
    latitude: 41.9,
    longitude: 12.45,
  },
  HN: {
    country: "Honduras",
    alpha2: "HN",
    alpha3: "HND",
    numeric: 340,
    latitude: 15,
    longitude: -86.5,
  },
  HK: {
    country: "Hong Kong",
    alpha2: "HK",
    alpha3: "HKG",
    numeric: 344,
    latitude: 22.25,
    longitude: 114.1667,
  },
  HU: {
    country: "Hungary",
    alpha2: "HU",
    alpha3: "HUN",
    numeric: 348,
    latitude: 47,
    longitude: 20,
  },
  IS: {
    country: "Iceland",
    alpha2: "IS",
    alpha3: "ISL",
    numeric: 352,
    latitude: 65,
    longitude: -18,
  },
  IN: {
    country: "India",
    alpha2: "IN",
    alpha3: "IND",
    numeric: 356,
    latitude: 20,
    longitude: 77,
  },
  ID: {
    country: "Indonesia",
    alpha2: "ID",
    alpha3: "IDN",
    numeric: 360,
    latitude: -5,
    longitude: 120,
  },
  IR: {
    country: "Iran, Islamic Republic of",
    alpha2: "IR",
    alpha3: "IRN",
    numeric: 364,
    latitude: 32,
    longitude: 53,
  },
  IQ: {
    country: "Iraq",
    alpha2: "IQ",
    alpha3: "IRQ",
    numeric: 368,
    latitude: 33,
    longitude: 44,
  },
  IE: {
    country: "Ireland",
    alpha2: "IE",
    alpha3: "IRL",
    numeric: 372,
    latitude: 53,
    longitude: -8,
  },
  IM: {
    country: "Isle of Man",
    alpha2: "IM",
    alpha3: "IMN",
    numeric: 833,
    latitude: 54.23,
    longitude: -4.55,
  },
  IL: {
    country: "Israel",
    alpha2: "IL",
    alpha3: "ISR",
    numeric: 376,
    latitude: 31.5,
    longitude: 34.75,
  },
  IT: {
    country: "Italy",
    alpha2: "IT",
    alpha3: "ITA",
    numeric: 380,
    latitude: 42.8333,
    longitude: 12.8333,
  },
  JM: {
    country: "Jamaica",
    alpha2: "JM",
    alpha3: "JAM",
    numeric: 388,
    latitude: 18.25,
    longitude: -77.5,
  },
  JP: {
    country: "Japan",
    alpha2: "JP",
    alpha3: "JPN",
    numeric: 392,
    latitude: 36,
    longitude: 138,
  },
  JE: {
    country: "Jersey",
    alpha2: "JE",
    alpha3: "JEY",
    numeric: 832,
    latitude: 49.21,
    longitude: -2.13,
  },
  JO: {
    country: "Jordan",
    alpha2: "JO",
    alpha3: "JOR",
    numeric: 400,
    latitude: 31,
    longitude: 36,
  },
  KZ: {
    country: "Kazakhstan",
    alpha2: "KZ",
    alpha3: "KAZ",
    numeric: 398,
    latitude: 48,
    longitude: 68,
  },
  KE: {
    country: "Kenya",
    alpha2: "KE",
    alpha3: "KEN",
    numeric: 404,
    latitude: 1,
    longitude: 38,
  },
  KI: {
    country: "Kiribati",
    alpha2: "KI",
    alpha3: "KIR",
    numeric: 296,
    latitude: 1.4167,
    longitude: 173,
  },
  KP: {
    country: "Korea, Democratic People's Republic of",
    alpha2: "KP",
    alpha3: "PRK",
    numeric: 408,
    latitude: 40,
    longitude: 127,
  },
  KR: {
    country: "Korea, Republic of",
    alpha2: "KR",
    alpha3: "KOR",
    numeric: 410,
    latitude: 37,
    longitude: 127.5,
  },
  KW: {
    country: "Kuwait",
    alpha2: "KW",
    alpha3: "KWT",
    numeric: 414,
    latitude: 29.3375,
    longitude: 47.6581,
  },
  KG: {
    country: "Kyrgyzstan",
    alpha2: "KG",
    alpha3: "KGZ",
    numeric: 417,
    latitude: 41,
    longitude: 75,
  },
  LA: {
    country: "Lao People's Democratic Republic",
    alpha2: "LA",
    alpha3: "LAO",
    numeric: 418,
    latitude: 18,
    longitude: 105,
  },
  LV: {
    country: "Latvia",
    alpha2: "LV",
    alpha3: "LVA",
    numeric: 428,
    latitude: 57,
    longitude: 25,
  },
  LB: {
    country: "Lebanon",
    alpha2: "LB",
    alpha3: "LBN",
    numeric: 422,
    latitude: 33.8333,
    longitude: 35.8333,
  },
  LS: {
    country: "Lesotho",
    alpha2: "LS",
    alpha3: "LSO",
    numeric: 426,
    latitude: -29.5,
    longitude: 28.5,
  },
  LR: {
    country: "Liberia",
    alpha2: "LR",
    alpha3: "LBR",
    numeric: 430,
    latitude: 6.5,
    longitude: -9.5,
  },
  LY: {
    country: "Libyan Arab Jamahiriya",
    alpha2: "LY",
    alpha3: "LBY",
    numeric: 434,
    latitude: 25,
    longitude: 17,
  },
  LI: {
    country: "Liechtenstein",
    alpha2: "LI",
    alpha3: "LIE",
    numeric: 438,
    latitude: 47.1667,
    longitude: 9.5333,
  },
  LT: {
    country: "Lithuania",
    alpha2: "LT",
    alpha3: "LTU",
    numeric: 440,
    latitude: 56,
    longitude: 24,
  },
  LU: {
    country: "Luxembourg",
    alpha2: "LU",
    alpha3: "LUX",
    numeric: 442,
    latitude: 49.75,
    longitude: 6.1667,
  },
  MO: {
    country: "Macao",
    alpha2: "MO",
    alpha3: "MAC",
    numeric: 446,
    latitude: 22.1667,
    longitude: 113.55,
  },
  MK: {
    country: "Macedonia, the former Yugoslav Republic of",
    alpha2: "MK",
    alpha3: "MKD",
    numeric: 807,
    latitude: 41.8333,
    longitude: 22,
  },
  MG: {
    country: "Madagascar",
    alpha2: "MG",
    alpha3: "MDG",
    numeric: 450,
    latitude: -20,
    longitude: 47,
  },
  MW: {
    country: "Malawi",
    alpha2: "MW",
    alpha3: "MWI",
    numeric: 454,
    latitude: -13.5,
    longitude: 34,
  },
  MY: {
    country: "Malaysia",
    alpha2: "MY",
    alpha3: "MYS",
    numeric: 458,
    latitude: 2.5,
    longitude: 112.5,
  },
  MV: {
    country: "Maldives",
    alpha2: "MV",
    alpha3: "MDV",
    numeric: 462,
    latitude: 3.25,
    longitude: 73,
  },
  ML: {
    country: "Mali",
    alpha2: "ML",
    alpha3: "MLI",
    numeric: 466,
    latitude: 17,
    longitude: -4,
  },
  MT: {
    country: "Malta",
    alpha2: "MT",
    alpha3: "MLT",
    numeric: 470,
    latitude: 35.8333,
    longitude: 14.5833,
  },
  MH: {
    country: "Marshall Islands",
    alpha2: "MH",
    alpha3: "MHL",
    numeric: 584,
    latitude: 9,
    longitude: 168,
  },
  MQ: {
    country: "Martinique",
    alpha2: "MQ",
    alpha3: "MTQ",
    numeric: 474,
    latitude: 14.6667,
    longitude: -61,
  },
  MR: {
    country: "Mauritania",
    alpha2: "MR",
    alpha3: "MRT",
    numeric: 478,
    latitude: 20,
    longitude: -12,
  },
  MU: {
    country: "Mauritius",
    alpha2: "MU",
    alpha3: "MUS",
    numeric: 480,
    latitude: -20.2833,
    longitude: 57.55,
  },
  YT: {
    country: "Mayotte",
    alpha2: "YT",
    alpha3: "MYT",
    numeric: 175,
    latitude: -12.8333,
    longitude: 45.1667,
  },
  MX: {
    country: "Mexico",
    alpha2: "MX",
    alpha3: "MEX",
    numeric: 484,
    latitude: 23,
    longitude: -102,
  },
  FM: {
    country: "Micronesia, Federated States of",
    alpha2: "FM",
    alpha3: "FSM",
    numeric: 583,
    latitude: 6.9167,
    longitude: 158.25,
  },
  MD: {
    country: "Moldova, Republic of",
    alpha2: "MD",
    alpha3: "MDA",
    numeric: 498,
    latitude: 47,
    longitude: 29,
  },
  MC: {
    country: "Monaco",
    alpha2: "MC",
    alpha3: "MCO",
    numeric: 492,
    latitude: 43.7333,
    longitude: 7.4,
  },
  MN: {
    country: "Mongolia",
    alpha2: "MN",
    alpha3: "MNG",
    numeric: 496,
    latitude: 46,
    longitude: 105,
  },
  ME: {
    country: "Montenegro",
    alpha2: "ME",
    alpha3: "MNE",
    numeric: 499,
    latitude: 42,
    longitude: 19,
  },
  MS: {
    country: "Montserrat",
    alpha2: "MS",
    alpha3: "MSR",
    numeric: 500,
    latitude: 16.75,
    longitude: -62.2,
  },
  MA: {
    country: "Morocco",
    alpha2: "MA",
    alpha3: "MAR",
    numeric: 504,
    latitude: 32,
    longitude: -5,
  },
  MZ: {
    country: "Mozambique",
    alpha2: "MZ",
    alpha3: "MOZ",
    numeric: 508,
    latitude: -18.25,
    longitude: 35,
  },
  MM: {
    country: "Myanmar",
    alpha2: "MM",
    alpha3: "MMR",
    numeric: 104,
    latitude: 22,
    longitude: 98,
  },
  NA: {
    country: "Namibia",
    alpha2: "NA",
    alpha3: "NAM",
    numeric: 516,
    latitude: -22,
    longitude: 17,
  },
  NR: {
    country: "Nauru",
    alpha2: "NR",
    alpha3: "NRU",
    numeric: 520,
    latitude: -0.5333,
    longitude: 166.9167,
  },
  NP: {
    country: "Nepal",
    alpha2: "NP",
    alpha3: "NPL",
    numeric: 524,
    latitude: 28,
    longitude: 84,
  },
  NL: {
    country: "Netherlands",
    alpha2: "NL",
    alpha3: "NLD",
    numeric: 528,
    latitude: 52.5,
    longitude: 5.75,
  },
  AN: {
    country: "Netherlands Antilles",
    alpha2: "AN",
    alpha3: "ANT",
    numeric: 530,
    latitude: 12.25,
    longitude: -68.75,
  },
  NC: {
    country: "New Caledonia",
    alpha2: "NC",
    alpha3: "NCL",
    numeric: 540,
    latitude: -21.5,
    longitude: 165.5,
  },
  NZ: {
    country: "New Zealand",
    alpha2: "NZ",
    alpha3: "NZL",
    numeric: 554,
    latitude: -41,
    longitude: 174,
  },
  NI: {
    country: "Nicaragua",
    alpha2: "NI",
    alpha3: "NIC",
    numeric: 558,
    latitude: 13,
    longitude: -85,
  },
  NE: {
    country: "Niger",
    alpha2: "NE",
    alpha3: "NER",
    numeric: 562,
    latitude: 16,
    longitude: 8,
  },
  NG: {
    country: "Nigeria",
    alpha2: "NG",
    alpha3: "NGA",
    numeric: 566,
    latitude: 10,
    longitude: 8,
  },
  NU: {
    country: "Niue",
    alpha2: "NU",
    alpha3: "NIU",
    numeric: 570,
    latitude: -19.0333,
    longitude: -169.8667,
  },
  NF: {
    country: "Norfolk Island",
    alpha2: "NF",
    alpha3: "NFK",
    numeric: 574,
    latitude: -29.0333,
    longitude: 167.95,
  },
  MP: {
    country: "Northern Mariana Islands",
    alpha2: "MP",
    alpha3: "MNP",
    numeric: 580,
    latitude: 15.2,
    longitude: 145.75,
  },
  NO: {
    country: "Norway",
    alpha2: "NO",
    alpha3: "NOR",
    numeric: 578,
    latitude: 62,
    longitude: 10,
  },
  OM: {
    country: "Oman",
    alpha2: "OM",
    alpha3: "OMN",
    numeric: 512,
    latitude: 21,
    longitude: 57,
  },
  PK: {
    country: "Pakistan",
    alpha2: "PK",
    alpha3: "PAK",
    numeric: 586,
    latitude: 30,
    longitude: 70,
  },
  PW: {
    country: "Palau",
    alpha2: "PW",
    alpha3: "PLW",
    numeric: 585,
    latitude: 7.5,
    longitude: 134.5,
  },
  PS: {
    country: "Palestinian Territory, Occupied",
    alpha2: "PS",
    alpha3: "PSE",
    numeric: 275,
    latitude: 32,
    longitude: 35.25,
  },
  PA: {
    country: "Panama",
    alpha2: "PA",
    alpha3: "PAN",
    numeric: 591,
    latitude: 9,
    longitude: -80,
  },
  PG: {
    country: "Papua New Guinea",
    alpha2: "PG",
    alpha3: "PNG",
    numeric: 598,
    latitude: -6,
    longitude: 147,
  },
  PY: {
    country: "Paraguay",
    alpha2: "PY",
    alpha3: "PRY",
    numeric: 600,
    latitude: -23,
    longitude: -58,
  },
  PE: {
    country: "Peru",
    alpha2: "PE",
    alpha3: "PER",
    numeric: 604,
    latitude: -10,
    longitude: -76,
  },
  PH: {
    country: "Philippines",
    alpha2: "PH",
    alpha3: "PHL",
    numeric: 608,
    latitude: 13,
    longitude: 122,
  },
  PN: {
    country: "Pitcairn",
    alpha2: "PN",
    alpha3: "PCN",
    numeric: 612,
    latitude: -24.7,
    longitude: -127.4,
  },
  PL: {
    country: "Poland",
    alpha2: "PL",
    alpha3: "POL",
    numeric: 616,
    latitude: 52,
    longitude: 20,
  },
  PT: {
    country: "Portugal",
    alpha2: "PT",
    alpha3: "PRT",
    numeric: 620,
    latitude: 39.5,
    longitude: -8,
  },
  PR: {
    country: "Puerto Rico",
    alpha2: "PR",
    alpha3: "PRI",
    numeric: 630,
    latitude: 18.25,
    longitude: -66.5,
  },
  QA: {
    country: "Qatar",
    alpha2: "QA",
    alpha3: "QAT",
    numeric: 634,
    latitude: 25.5,
    longitude: 51.25,
  },
  RE: {
    country: "Réunion",
    alpha2: "RE",
    alpha3: "REU",
    numeric: 638,
    latitude: -21.1,
    longitude: 55.6,
  },
  RO: {
    country: "Romania",
    alpha2: "RO",
    alpha3: "ROU",
    numeric: 642,
    latitude: 46,
    longitude: 25,
  },
  RU: {
    country: "Russian Federation",
    alpha2: "RU",
    alpha3: "RUS",
    numeric: 643,
    latitude: 60,
    longitude: 100,
  },
  RW: {
    country: "Rwanda",
    alpha2: "RW",
    alpha3: "RWA",
    numeric: 646,
    latitude: -2,
    longitude: 30,
  },
  SH: {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    alpha2: "SH",
    alpha3: "SHN",
    numeric: 654,
    latitude: -15.9333,
    longitude: -5.7,
  },
  KN: {
    country: "Saint Kitts and Nevis",
    alpha2: "KN",
    alpha3: "KNA",
    numeric: 659,
    latitude: 17.3333,
    longitude: -62.75,
  },
  LC: {
    country: "Saint Lucia",
    alpha2: "LC",
    alpha3: "LCA",
    numeric: 662,
    latitude: 13.8833,
    longitude: -61.1333,
  },
  PM: {
    country: "Saint Pierre and Miquelon",
    alpha2: "PM",
    alpha3: "SPM",
    numeric: 666,
    latitude: 46.8333,
    longitude: -56.3333,
  },
  VC: {
    country: "Saint Vincent and the Grenadines",
    alpha2: "VC",
    alpha3: "VCT",
    numeric: 670,
    latitude: 13.25,
    longitude: -61.2,
  },
  WS: {
    country: "Samoa",
    alpha2: "WS",
    alpha3: "WSM",
    numeric: 882,
    latitude: -13.5833,
    longitude: -172.3333,
  },
  SM: {
    country: "San Marino",
    alpha2: "SM",
    alpha3: "SMR",
    numeric: 674,
    latitude: 43.7667,
    longitude: 12.4167,
  },
  ST: {
    country: "Sao Tome and Principe",
    alpha2: "ST",
    alpha3: "STP",
    numeric: 678,
    latitude: 1,
    longitude: 7,
  },
  SA: {
    country: "Saudi Arabia",
    alpha2: "SA",
    alpha3: "SAU",
    numeric: 682,
    latitude: 25,
    longitude: 45,
  },
  SN: {
    country: "Senegal",
    alpha2: "SN",
    alpha3: "SEN",
    numeric: 686,
    latitude: 14,
    longitude: -14,
  },
  RS: {
    country: "Serbia",
    alpha2: "RS",
    alpha3: "SRB",
    numeric: 688,
    latitude: 44,
    longitude: 21,
  },
  SC: {
    country: "Seychelles",
    alpha2: "SC",
    alpha3: "SYC",
    numeric: 690,
    latitude: -4.5833,
    longitude: 55.6667,
  },
  SL: {
    country: "Sierra Leone",
    alpha2: "SL",
    alpha3: "SLE",
    numeric: 694,
    latitude: 8.5,
    longitude: -11.5,
  },
  SG: {
    country: "Singapore",
    alpha2: "SG",
    alpha3: "SGP",
    numeric: 702,
    latitude: 1.3667,
    longitude: 103.8,
  },
  SK: {
    country: "Slovakia",
    alpha2: "SK",
    alpha3: "SVK",
    numeric: 703,
    latitude: 48.6667,
    longitude: 19.5,
  },
  SI: {
    country: "Slovenia",
    alpha2: "SI",
    alpha3: "SVN",
    numeric: 705,
    latitude: 46,
    longitude: 15,
  },
  SB: {
    country: "Solomon Islands",
    alpha2: "SB",
    alpha3: "SLB",
    numeric: 90,
    latitude: -8,
    longitude: 159,
  },
  SO: {
    country: "Somalia",
    alpha2: "SO",
    alpha3: "SOM",
    numeric: 706,
    latitude: 10,
    longitude: 49,
  },
  ZA: {
    country: "South Africa",
    alpha2: "ZA",
    alpha3: "ZAF",
    numeric: 710,
    latitude: -29,
    longitude: 24,
  },
  GS: {
    country: "South Georgia and the South Sandwich Islands",
    alpha2: "GS",
    alpha3: "SGS",
    numeric: 239,
    latitude: -54.5,
    longitude: -37,
  },
  ES: {
    country: "Spain",
    alpha2: "ES",
    alpha3: "ESP",
    numeric: 724,
    latitude: 40,
    longitude: -4,
  },
  LK: {
    country: "Sri Lanka",
    alpha2: "LK",
    alpha3: "LKA",
    numeric: 144,
    latitude: 7,
    longitude: 81,
  },
  SD: {
    country: "Sudan",
    alpha2: "SD",
    alpha3: "SDN",
    numeric: 736,
    latitude: 15,
    longitude: 30,
  },
  SR: {
    country: "Suriname",
    alpha2: "SR",
    alpha3: "SUR",
    numeric: 740,
    latitude: 4,
    longitude: -56,
  },
  SJ: {
    country: "Svalbard and Jan Mayen",
    alpha2: "SJ",
    alpha3: "SJM",
    numeric: 744,
    latitude: 78,
    longitude: 20,
  },
  SZ: {
    country: "Swaziland",
    alpha2: "SZ",
    alpha3: "SWZ",
    numeric: 748,
    latitude: -26.5,
    longitude: 31.5,
  },
  SE: {
    country: "Sweden",
    alpha2: "SE",
    alpha3: "SWE",
    numeric: 752,
    latitude: 62,
    longitude: 15,
  },
  CH: {
    country: "Switzerland",
    alpha2: "CH",
    alpha3: "CHE",
    numeric: 756,
    latitude: 47,
    longitude: 8,
  },
  SY: {
    country: "Syrian Arab Republic",
    alpha2: "SY",
    alpha3: "SYR",
    numeric: 760,
    latitude: 35,
    longitude: 38,
  },
  TW: {
    country: "Taiwan, Province of China",
    alpha2: "TW",
    alpha3: "TWN",
    numeric: 158,
    latitude: 23.5,
    longitude: 121,
  },
  TJ: {
    country: "Tajikistan",
    alpha2: "TJ",
    alpha3: "TJK",
    numeric: 762,
    latitude: 39,
    longitude: 71,
  },
  TZ: {
    country: "Tanzania, United Republic of",
    alpha2: "TZ",
    alpha3: "TZA",
    numeric: 834,
    latitude: -6,
    longitude: 35,
  },
  TH: {
    country: "Thailand",
    alpha2: "TH",
    alpha3: "THA",
    numeric: 764,
    latitude: 15,
    longitude: 100,
  },
  TL: {
    country: "Timor-Leste",
    alpha2: "TL",
    alpha3: "TLS",
    numeric: 626,
    latitude: -8.55,
    longitude: 125.5167,
  },
  TG: {
    country: "Togo",
    alpha2: "TG",
    alpha3: "TGO",
    numeric: 768,
    latitude: 8,
    longitude: 1.1667,
  },
  TK: {
    country: "Tokelau",
    alpha2: "TK",
    alpha3: "TKL",
    numeric: 772,
    latitude: -9,
    longitude: -172,
  },
  TO: {
    country: "Tonga",
    alpha2: "TO",
    alpha3: "TON",
    numeric: 776,
    latitude: -20,
    longitude: -175,
  },
  TT: {
    country: "Trinidad and Tobago",
    alpha2: "TT",
    alpha3: "TTO",
    numeric: 780,
    latitude: 11,
    longitude: -61,
  },
  TN: {
    country: "Tunisia",
    alpha2: "TN",
    alpha3: "TUN",
    numeric: 788,
    latitude: 34,
    longitude: 9,
  },
  TR: {
    country: "Turkey",
    alpha2: "TR",
    alpha3: "TUR",
    numeric: 792,
    latitude: 39,
    longitude: 35,
  },
  TM: {
    country: "Turkmenistan",
    alpha2: "TM",
    alpha3: "TKM",
    numeric: 795,
    latitude: 40,
    longitude: 60,
  },
  TC: {
    country: "Turks and Caicos Islands",
    alpha2: "TC",
    alpha3: "TCA",
    numeric: 796,
    latitude: 21.75,
    longitude: -71.5833,
  },
  TV: {
    country: "Tuvalu",
    alpha2: "TV",
    alpha3: "TUV",
    numeric: 798,
    latitude: -8,
    longitude: 178,
  },
  UG: {
    country: "Uganda",
    alpha2: "UG",
    alpha3: "UGA",
    numeric: 800,
    latitude: 1,
    longitude: 32,
  },
  UA: {
    country: "Ukraine",
    alpha2: "UA",
    alpha3: "UKR",
    numeric: 804,
    latitude: 49,
    longitude: 32,
  },
  AE: {
    country: "United Arab Emirates",
    alpha2: "AE",
    alpha3: "ARE",
    numeric: 784,
    latitude: 24,
    longitude: 54,
  },
  GB: {
    country: "United Kingdom",
    alpha2: "GB",
    alpha3: "GBR",
    numeric: 826,
    latitude: 54,
    longitude: -2,
  },
  US: {
    country: "United States",
    alpha2: "US",
    alpha3: "USA",
    numeric: 840,
    latitude: 38,
    longitude: -97,
  },
  UM: {
    country: "United States Minor Outlying Islands",
    alpha2: "UM",
    alpha3: "UMI",
    numeric: 581,
    latitude: 19.2833,
    longitude: 166.6,
  },
  UY: {
    country: "Uruguay",
    alpha2: "UY",
    alpha3: "URY",
    numeric: 858,
    latitude: -33,
    longitude: -56,
  },
  UZ: {
    country: "Uzbekistan",
    alpha2: "UZ",
    alpha3: "UZB",
    numeric: 860,
    latitude: 41,
    longitude: 64,
  },
  VU: {
    country: "Vanuatu",
    alpha2: "VU",
    alpha3: "VUT",
    numeric: 548,
    latitude: -16,
    longitude: 167,
  },
  VE: {
    country: "Venezuela, Bolivarian Republic of",
    alpha2: "VE",
    alpha3: "VEN",
    numeric: 862,
    latitude: 8,
    longitude: -66,
  },
  VN: {
    country: "Viet Nam",
    alpha2: "VN",
    alpha3: "VNM",
    numeric: 704,
    latitude: 16,
    longitude: 106,
  },
  VG: {
    country: "Virgin Islands, British",
    alpha2: "VG",
    alpha3: "VGB",
    numeric: 92,
    latitude: 18.5,
    longitude: -64.5,
  },
  VI: {
    country: "Virgin Islands, U.S.",
    alpha2: "VI",
    alpha3: "VIR",
    numeric: 850,
    latitude: 18.3333,
    longitude: -64.8333,
  },
  WF: {
    country: "Wallis and Futuna",
    alpha2: "WF",
    alpha3: "WLF",
    numeric: 876,
    latitude: -13.3,
    longitude: -176.2,
  },
  EH: {
    country: "Western Sahara",
    alpha2: "EH",
    alpha3: "ESH",
    numeric: 732,
    latitude: 24.5,
    longitude: -13,
  },
  YE: {
    country: "Yemen",
    alpha2: "YE",
    alpha3: "YEM",
    numeric: 887,
    latitude: 15,
    longitude: 48,
  },
  ZM: {
    country: "Zambia",
    alpha2: "ZM",
    alpha3: "ZMB",
    numeric: 894,
    latitude: -15,
    longitude: 30,
  },
  ZW: {
    country: "Zimbabwe",
    alpha2: "ZW",
    alpha3: "ZWE",
    numeric: 716,
    latitude: -20,
    longitude: 30,
  },
  AF: {
    country: "Afghanistan",
    alpha2: "AF",
    alpha3: "AFG",
    numeric: 4,
    latitude: 33,
    longitude: 65,
  },
  EU: {
    country: "Kiribati",
    alpha2: "KI",
    alpha3: "KIR",
    numeric: 296,
    latitude: 52,
    longitude: 20,
  },
  ZZ: {
    country: "Unspecified country",
    alpha2: "KI",
    alpha3: "KIR",
    numeric: 296,
    latitude: 26.876597,
    longitude: -55.192925,
  },
};
