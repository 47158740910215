import { IReduxState } from 'app/store';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { PopupHeader } from 'common/components/PopupHeader/PopupHeader';
import { RestApi } from 'common/services/rest-api.service';
import { tosterService } from 'common/services/tostar.service';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import './ConfirmationPopup.scss';

export function ConfirmationPopup(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const { confirmationParams } = useSelector((state: IReduxState) => state.grid);

  const onApprove = () => {
    setLoading(true);
    RestApi.setData(confirmationParams.endpoint, confirmationParams.data, 'PATCH').subscribe(
      (response: any) => {
        tosterService.onShowToster(confirmationParams.successMessage, 'success', '');
        confirmationParams.callback();
        setLoading(false);
        props.onClose();
      },
      error => {
        tosterService.showError(error);
        setLoading(false);
        props.onClose();
      }
    );
  };

  return (
    <div className="ConfirmationPopup popup-inner-wrapper">
      {loading && <AppLoading />}
      <PopupHeader text="Attention" onClose={props.onClose} />
      <div className="popup-inner">
        {confirmationParams.message}
        <div className="buttons-wrapper">
          <button className="btn btn-big btn-secondary" onClick={props.onClose}>
            Cancel
          </button>
          <button className={`btn btn-big btn-main`} onClick={onApprove}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}
