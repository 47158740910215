import React, { useEffect } from 'react';
import { ActiveProtectionRouting } from './ActiveProtectionRouting';
import { GroupFilter } from 'common/components/GroupFilter/GroupFilter';
import { updateHeader } from 'common/services/common.service';
import { useSelector } from 'react-redux';

const PAGE_NAME = 'Active Protection';

const ActiveProtection = (props: any) => {
  const { isLoggedIn } = useSelector((state: any) => state.login);
  useEffect(() => {
    if (isLoggedIn) {
      updateHeader.sendHeaderText(PAGE_NAME);
    }
  }, [isLoggedIn]);

  return (
    <div className="sub-layout-pages">
      <div className="group-filter-wrapper">
        <GroupFilter type="grid" />
      </div>

      <ActiveProtectionRouting page={PAGE_NAME} />
    </div>
  );
};

export default ActiveProtection;
