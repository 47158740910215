import React from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Text } from '@cyberpion/cyberpion-ui';

interface IBlockDivider {
  headerText?: string;
}
const BlockDivider: React.FC<IBlockDivider> = ({ headerText }) => {
  return (
    <Grid container alignItems="center">
      {headerText && (
        <Grid item sx={{ paddingRight: 2 }}>
          <Text textSize={13}>{headerText}</Text>
        </Grid>
      )}
      <Grid item flexGrow={1}>
        <Divider light />
      </Grid>
    </Grid>
  );
};

export default BlockDivider;
