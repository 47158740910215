import { DataGridPro, GridColDef, GridRowData, GridSortItem, GridSortModel } from '@mui/x-data-grid-pro';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { queryBuilder } from 'common/components/grid/query-builder.service';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { ManageNotData } from 'common/components/manageNotData/ManageNotData';
import { PopUp } from 'common/components/popUp/PopUp';
import { Summary } from 'common/components/summary/Summary';
import { PathNames } from 'common/constants/pathNames';
import { Paths } from 'common/constants/paths';
import { IResponse } from 'common/interfaces/interface';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import { useEffect, useState } from 'react';
import { OrgScorePopUp } from '../OrgScorePopUp/OrgScorePopUp';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';

import './Card.scss';

export function Card(props: any) {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<{}>({});
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<GridRowData[]>([]);
  const [fields, setFields] = useState<string[]>([]);
  const [sort, setSort] = useState<GridSortItem>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextPath, setNextPath] = useState<string>('');
  const [identifier, setIdentifier] = useState<string>('');
  const { activeSubsidiary } = useSelector((state: IReduxState) => state.subsidiaries);

  useEffect(() => {
    if (fields.length) {
      setLoading(true);
      getRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, sort]);

  const collapse = () => {
    if (!collapsed && !columns.length) {
      const category = getCategoryId(props.card);
      let path = `${getPath(Paths[PathNames.assesmentsOrgScoreColumns], category)}`;
      if (Object.keys(activeSubsidiary).length && !activeSubsidiary.is_main_company) {
        path = path + `?subsidiary=${activeSubsidiary.name}`;
      }
      RestApi.getData(path).subscribe((response: IResponse) => {
        const results = response.data;
        const fields = ColumnsManage.checkGridColumnsToDisplay(results);
        const columns = ColumnsManage.createColumns(
          response.identifier_field,
          results,
          { page: 'orgScore', tab: '', category: category },
          { cb: showDetails }
        );
        setIdentifier(response.identifier_field);
        setColumns(columns);
        setFields(fields);
      });
    }

    setCollapsed(!collapsed);
  };

  const getRows = () => {
    const category = getCategoryId(props.card);
    queryBuilder.setBaseURL(getPath(Paths[PathNames.assesmentsOrgScoreCategoryData], category), false);
    queryBuilder.buildQuery({
      fields: fields,
      order: sort ? (sort.sort === 'asc' ? `-${sort.field}` : sort.field) : undefined
    });
    let query = queryBuilder.getQuery();
    if (Object.keys(activeSubsidiary).length && !activeSubsidiary.is_main_company) {
      query = query + `&subsidiary=${activeSubsidiary.name}`;
    }
    RestApi.getData(query).subscribe((response: IResponse) => {
      let rows: GridRowData[] = response.results;
      setTimeout(() => {
        setNextPath(response.next);
        setRows(rows);
        setLoading(false);
      }, 500);
    });
  };

  const getCategoryId = (card: any) => {
    const categoryCard = card.filter((c: any) => {
      return c.value !== null;
    })[0];
    return categoryCard.value;
  };
  const showDetails = (category: number, params: { [key: string]: any }) => {
    setPopupData({
      category: category,
      id: params.id,
      title: params.row.title,
      issueName: params.getValue(params.id, 'label'),
      md5: params.row.md5
    });
  };

  const getPath = (path: string, category: number): string => {
    return path.replace(':category_id', category.toString());
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    const order = model[0];
    if (order) {
      setSort(order);
    }
  };

  const handleOnRowsScrollEnd = () => {
    if (nextPath) {
      let path = Common.splitV1FromPath(nextPath);
      RestApi.getData(path).subscribe((response: IResponse) => {
        setNextPath(response.next);
        setRows([...rows, ...response.results]);
      });
    }
  };

  const renderTable = () => {
    if (!identifier) {
      return null;
    }
    return (
      <DataGridPro
        components={{
          NoRowsOverlay: () => <ManageNotData type="org_score" subType="card" />
        }}
        rows={rows}
        columns={columns}
        onSortModelChange={handleOnSortModelChange}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        filterMode="server"
        sortingMode="server"
        disableMultipleColumnsSorting={true}
        disableSelectionOnClick={true}
        disableColumnReorder={true}
        checkboxSelection={false}
        disableColumnMenu={true}
        hideFooter={true}
        disableColumnResize={true}
        getRowId={row => row[identifier]}
      />
    );
  };

  if (props.card[0].sections[0].value === null) {
    return null;
  }

  return (
    <li className={`Card ${collapsed ? 'collapsed' : ''}`}>
      <div className="card-upper-part">
        <Summary dataTabs={props.card} source="org-score" hidden_arrow />
        <div onClick={collapse} className="collapse-button">
          <IconArrow />
        </div>
      </div>
      <div className="table">
        {renderTable()}
        {loading && <AppLoading />}
      </div>
      <PopUp visible={!!Object.keys(popupData).length} setVisible={() => setPopupData({})}>
        <OrgScorePopUp {...popupData} onClose={() => setPopupData({})} />
      </PopUp>
    </li>
  );
}
