import './SelectAll.scss';
import { Checkbox } from 'common/components/Icons/IconCheckbox/IconCheckbox';
import classNames from 'classnames';

interface ISelectAllProps {
  isSomeSelected: () => boolean;
  isAllSelected: () => boolean;
  selectAll: () => void;
  unselectAll: () => void;
  disabled?: boolean;
}

export function SelectAll(props: ISelectAllProps) {
  return (
    <div
      className={classNames(`SelectAll`, { disabled: props.disabled })}
      onClick={() => {
        props.isSomeSelected() ? props.unselectAll() : props.selectAll();
      }}
    >
      <Checkbox active={props.isAllSelected()} semiActive={props.isSomeSelected()} />
      <span>{!props.isSomeSelected() ? 'Select All' : 'Unselect All'}</span>
    </div>
  );
}
