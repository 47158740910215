// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const DownloadtSlice = createSlice({
  name: 'downloads',
  initialState: {
    downloadFilesData: {},
    newDownloads: [],
    cancelDownloadId: [],
    intervalsDownloading: {},
    visible: false
  },
  reducers: {
    setNewDownloads(state, action: any) {
      state.newDownloads = [...state.newDownloads, action.payload];
      state.visible = true;
    },
    removeNewDownloads(state, action) {
      const inProgress = [...state.newDownloads];
      const updatedInProgress = inProgress.filter((item: any) => item !== action.payload);
      state.newDownloads = updatedInProgress;
    },
    addDownloadFileData(state, { payload }) {
      state.downloadFilesData = { ...state.downloadFilesData, [payload.id]: payload };
      state.visible = true;
    },

    updateDownloadFiles(state, { payload }) {
      state.downloadFilesData = {
        ...state.downloadFilesData,
        [payload.id]: payload
      };
    },
    setCancelDownloadId(state, { payload }) {
      state.cancelDownloadId = [...state.cancelDownloadId, payload];
      state.downloadFilesData = {
        ...state.downloadFilesData,
        [payload]: {
          ...state.downloadFilesData[payload],
          status: 'canceled'
        }
      };
    },
    addIntervalsDownloading(state, { payload }) {
      state.intervalsDownloading = { ...state.intervalsDownloading, [payload.id]: payload.interval };
    },
    removeIntervalDownloading(state, { payload }) {
      const intervals = { ...state.intervalsDownloading };
      delete intervals[payload];

      state.intervalsDownloading = intervals;
    },
    resetDownloads(state) {
      state.downloadFilesData = {};
      state.newDownloads = [];
      state.visible = false;
    }
  }
});

const { actions, reducer } = DownloadtSlice;
export const {
  addDownloadFileData,
  setNewDownloads,
  setCancelDownloadId,
  resetDownloads,
  removeNewDownloads,
  updateDownloadFiles,
  addIntervalsDownloading,
  removeIntervalDownloading
} = actions;
export default reducer;
