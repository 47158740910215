// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const infoPanelSlice = createSlice({
  name: 'infoPanel',
  initialState: {
    isOpenInfoPanel: false,
    infoPanelTitle: '',
    infoPanelContentType: '',
    infoPanelAsset: '',
    infoPanelData: []
  },
  reducers: {
    setInfoPanelAsset(state, action) {
      state.infoPanelAsset = action.payload;
    },
    setInfoPanelData(state, action) {
      state.infoPanelData = action.payload;
    },
    setIsOpenInfoPanel(state, action) {
      state.isOpenInfoPanel = action.payload;
    },
    setInfoPanelTitle(state, action) {
      state.infoPanelTitle = action.payload;
    },
    setInfoPanelContentType(state, action) {
      state.infoPanelContentType = action.payload;
    },
    resetInfoPanel(state) {
      state.isOpenInfoPanel = false;
      state.infoPanelTitle = '';
      state.infoPanelContentType = '';
      state.infoPanelAsset = '';
    }
  }
});

const { actions, reducer } = infoPanelSlice;
export const {
  resetInfoPanel,
  setInfoPanelAsset,
  setIsOpenInfoPanel,
  setInfoPanelTitle,
  setInfoPanelContentType,
  setInfoPanelData
} = actions;
export default reducer;
