import { createContext, useContext } from 'react';

type TypeTopPanelContext = {
  data: any;
  page: string | null;
  isOpen?: boolean | null;
  tab?: string | null;
  isLoading?: boolean | null;
};

export const TopPanelContext = createContext<TypeTopPanelContext>({
  data: null,
  page: null,
  isOpen: null,
  tab: null,
  isLoading: null
});

export const useTopPanelContext = () => useContext(TopPanelContext);
