import { useFlags } from 'launchdarkly-react-client-sdk';
import './spinnerRenderer.scss';

export function SpinnerRenderer(props: any) {
  const flags = useFlags();

  return (
    <div className="spinnerRenderer-scope">
      {!flags.isActionItemRescan && props.value.is_scanning && (
        <div className="spinnerRenderer-text-scope">
          <div className="">Rescan in Progress</div>
        </div>
      )}
      <div className="">{props.value.label}</div>
    </div>
  );
}
