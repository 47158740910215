import { useEffect, useState } from 'react';
import { PieChartForm } from '../pieChartForm/PieChartForm';
import './DomainRegistrars.scss';
import { RestApi } from 'common/services/rest-api.service';
import { useSelector } from 'react-redux';

export function DomainRegistrars(props: any) {
  const [data, setData] = useState<any>({});
  const { globalFilter } = useSelector((state: any) => state.general);

  useEffect(() => {
    setData({});
    RestApi.getData(`metadata/asset/org_assets/${globalFilter ? `?group=${globalFilter}` : ''}`).subscribe(
      (response: any) => {
        if (response.domain_registrars) {
          try {
            setData(prepareDomainRegistrarsData(response.domain_registrars));
          } catch (e) {}
        }
      }
    );
  }, [globalFilter]);

  const prepareDomainRegistrarsData = (_data: any) => {
    let count: number = _data.count;
    const graphData: any = {
      type: 'pie',
      label: 'Domain Registrars',
      data: []
    };
    _data.results.splice(0, 3).forEach((row: any) => {
      const rowCount: any = Object.values(row)[0];
      const key = Object.keys(row)[0];
      const value = Object.values(row)[0];
      if (key && value) {
        if (typeof value === 'number') {
          count = count - rowCount;
        }
        graphData.data.push({
          header: key,
          value: value,
          label: value,
          linkTo: `/pages/discovery/internalAssets/?fields=asset%2Crisk_score%2Ctype%2Cimportance%2Chosting_provider%2Cregistrar%2Ctechnologies%2Cfirst_seen%2Cservice_type%2Cservice%2Cgroups&filters=%7B"registrar"%3A"${key}"%2C"type__in"%3A"0"%7D`
        });
      }
    });
    if (count > 0) {
      graphData.data.push({
        header: 'Other',
        value: count,
        label: count
      });
    }
    return graphData;
  };

  if (!Object.keys(data).length) {
    return null;
  }

  return <PieChartForm {...data} />;
}
