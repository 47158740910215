import React from 'react';
import { IIcon } from 'common/interfaces/interface';
export class IconIP extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    let color = this.props.active ? '#8C909A' : '#5D6576';
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5Z"
          stroke={color}
          strokeMiterlimit="10"
        />
        <path d="M4.92709 9.5H19.0728" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.92738 14.5H19.073" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M12 19.2987C13.7259 19.2987 15.125 16.0309 15.125 12C15.125 7.96906 13.7259 4.70134 12 4.70134C10.2741 4.70134 8.875 7.96906 8.875 12C8.875 16.0309 10.2741 19.2987 12 19.2987Z"
          stroke={color}
          strokeMiterlimit="10"
        />
      </svg>
    );
  }
}
