// import TOP_PANEL_PAGES from 'common/constants/topPanelPages';
// import ActionItemsLoadingState from './pages/ActionItemsLoadingState';
// import DashboardLoadingState from './pages/DashboardLoadingState';
// import { DiscoveryLoadingState, DiscoveryLoginLoadingState } from './pages/DiscoveryLoadingState';

import './top-panel-loading-state.scss';

export const TopPanelLoadingState = ({ page, isOpen, tab }: { page: string; isOpen?: boolean; tab?: string }) => {
  // switch (page) {
  //   case TOP_PANEL_PAGES.DASHBOARD:
  //     return <DashboardLoadingState />;
  //   case TOP_PANEL_PAGES.ACTION_ITEMS:
  //     return <ActionItemsLoadingState tab={tab} />;
  //   case TOP_PANEL_PAGES.DISCOVERY:
  //     return tab !== 'loginAssets' ? <DiscoveryLoadingState tab={tab} /> : <DiscoveryLoginLoadingState tab={tab} />;
  // case TOP_PANEL_PAGES.ACTIVE_PROTECTION:
  //   return <TopPanelActiveProtection />;
  // case TOP_PANEL_PAGES.ASSESSMENTS:
  //   return <TopPanelAssessments />;
  // case TOP_PANEL_PAGES.SINGLE_ASSET:
  //   return <TopPanelSingleAsset />;

  //   default:
  //     return null;
  // }
  return (
    <div className="top-panel-loading-container" style={isOpen ? { height: 145 } : { height: 50 }}>
      <div className="top-panel-loading-state-progress"></div>
    </div>
  );
};
