import { ToolTip } from 'common/components/toolTip/toolTip';
import ReactDOMServer from 'react-dom/server';
import { useState } from 'react';
import { DomainsTab } from './DomainsTab/DomainsTab';
import { IPNetworksTab } from './IPNetworksTab/IPNetworksTab';
import { ServicesTab } from './ServicesTab/ServicesTab';
import { WhoisTab } from './WhoisTab/WhoisTab';
import MobileAppsTab from './MobileApps/MobileAppsTab';
import { useDispatch } from 'react-redux';
import { inputSearch } from 'features/GroupsSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';

import './GroupTabs.scss';

let GROUP_TABS = ['Domains', 'IP Networks', 'Services', 'WHOIS'];

export function GroupTabs(props: any) {
  const [activeTab, setActiveTab] = useState<number>(0);
  const dispatch = useDispatch<any>();
  const flags = useFlags();

  if (flags['isMobile'] && !GROUP_TABS.includes('Mobile Apps')) {
    GROUP_TABS.push('Mobile Apps');
  }

  const renderTabComponent = () => {
    switch (activeTab) {
      case 0:
        return <DomainsTab />;
      case 1:
        return <IPNetworksTab />;
      case 2:
        return <ServicesTab />;
      case 3:
        return <WhoisTab />;
      case 4:
        return <MobileAppsTab />;
    }
  };

  const getToolTipHtml = (tab: string) => {
    switch (tab.toLowerCase()) {
      case 'domains':
        return ReactDOMServer.renderToString(
          <div className="groups-tt-html">
            <div className="with-margin">Assets input will find exact matches (example.com)</div>
            <div className="with-margin">Use the * sign to include items that END WITH</div>
            <div>For Example, Use *example.com to include example.com all</div>
            <div>subdomains, and aaaexample.com.</div>
            <div>Remember to add . to include only subdomains (*.example.com)</div>
          </div>
        );
      case 'ip networks':
        return ReactDOMServer.renderToString(
          <div className="groups-tt-html">
            <div className="with-margin">IP input will find an exact match for the IP</div>
            <div className="with-margin">IP block input will include all the IPs within that range</div>
          </div>
        );
      case 'services':
        return ReactDOMServer.renderToString(
          <div className="groups-tt-html">
            <div className="with-margin">
              Service input will find matches for assets found running
              <br />
              one of the services that have been checked
            </div>
          </div>
        );
      case 'whois':
        return ReactDOMServer.renderToString(
          <div className="groups-tt-html">
            <ul>
              <li>Email input will find at least one “EXACT MATCH” within the list of emails listed for an asset</li>
              <li>Name Server input will find an “EXACT MATCH” within a list of Name Servers</li>
              <li>Organization input will find a match using “STARTS WITH”</li>
            </ul>
          </div>
        );
      case 'mobile apps':
        return ReactDOMServer.renderToString(
          <div className="groups-tt-html">
            <div className="with-margin">Application name input will find text that is CONTAINED within the</div>
            <div className="with-margin">
              application name as it's displayed in the Applications column in the inventory section
            </div>
          </div>
        );
      default:
        return '';
    }
  };

  const handleTabClick = (ev: React.MouseEvent<HTMLLIElement>, index: number) => {
    dispatch(inputSearch(''));
    setActiveTab(index);
  };

  return (
    <div className="GroupTabs">
      <ul>
        {GROUP_TABS.map((tab: string, index: number) => {
          return (
            <li
              key={tab}
              onClick={ev => handleTabClick(ev, index)}
              className={GROUP_TABS[activeTab] === tab ? 'active' : ''}
            >
              {tab}
              <img src="/assets/images/info.svg" className="info" data-tip={getToolTipHtml(tab)} alt="settings info" />
              <div></div>
            </li>
          );
        })}
      </ul>
      <div className="tab-content-wrapper">{renderTabComponent()}</div>
      <ToolTip theme="dark" place="bottom" html={true} />
    </div>
  );
}
