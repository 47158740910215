import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconAzure extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    let color = this.props.active ? '#8796F0' : '#5D6576';
    return (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.3272 18.2524C13.6398 17.8422 15.5499 17.5027 15.5718 17.498L15.6117 17.4896L13.4284 14.8818C12.2276 13.4475 11.2451 12.2684 11.2451 12.2616C11.2451 12.2488 13.4996 6.01471 13.5122 5.99247C13.5164 5.98506 15.0506 8.64488 17.2312 12.4401C19.2728 15.9932 20.9559 18.9227 20.9715 18.9501L21 19L14.0613 18.9992L7.12256 18.9982L11.3272 18.2524ZM3 17.4571C3 17.4535 4.02874 15.6602 5.28614 13.4721L7.57228 9.49369L10.2365 7.24853C11.7019 6.01372 12.9047 5.00189 12.9095 5.00005C12.9143 4.99821 12.8951 5.04693 12.8667 5.10836C12.8383 5.16978 11.5364 7.97364 9.97366 11.3392L7.13234 17.4586L5.06613 17.4612C3.92974 17.4626 3 17.4608 3 17.4571H3Z"
          fill={color}
        />
      </svg>
    );
  }
}
