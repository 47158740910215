import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconDashboard extends React.Component {
  public props: IIcon;
  render() {
    let color = this.props.active ? '#3455DD' : '#6D6D7A';
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" fill="none">
        <g filter="url(#filter0_d_1388_10040)">
          <path
            d="M3 2.5H8C8.27614 2.5 8.5 2.72386 8.5 3V6C8.5 6.27614 8.27614 6.5 8 6.5H3C2.72386 6.5 2.5 6.27614 2.5 6V3C2.5 2.72386 2.72386 2.5 3 2.5Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter1_d_1388_10040)">
          <path
            d="M12 2.5H17C17.2761 2.5 17.5 2.72386 17.5 3V9C17.5 9.27614 17.2761 9.5 17 9.5H12C11.7239 9.5 11.5 9.27614 11.5 9V3C11.5 2.72386 11.7239 2.5 12 2.5Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter2_d_1388_10040)">
          <path
            d="M3 9.5H8C8.27614 9.5 8.5 9.72386 8.5 10V17C8.5 17.2761 8.27614 17.5 8 17.5H3C2.72386 17.5 2.5 17.2761 2.5 17V10C2.5 9.72386 2.72386 9.5 3 9.5Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter3_d_1388_10040)">
          <path
            d="M12 12.5H17C17.2761 12.5 17.5 12.7239 17.5 13V17C17.5 17.2761 17.2761 17.5 17 17.5H12C11.7239 17.5 11.5 17.2761 11.5 17V13C11.5 12.7239 11.7239 12.5 12 12.5Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            shapeRendering="crispEdges"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1388_10040"
            x="0"
            y="0"
            width="11"
            height="9"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.211765 0 0 0 0 0.349019 0 0 0 0 0.870588 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1388_10040" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1388_10040" result="shape" />
          </filter>
          <filter
            id="filter1_d_1388_10040"
            x="9"
            y="0"
            width="11"
            height="12"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.211765 0 0 0 0 0.349019 0 0 0 0 0.870588 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1388_10040" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1388_10040" result="shape" />
          </filter>
          <filter
            id="filter2_d_1388_10040"
            x="0"
            y="7"
            width="11"
            height="13"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.211765 0 0 0 0 0.349019 0 0 0 0 0.870588 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1388_10040" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1388_10040" result="shape" />
          </filter>
          <filter
            id="filter3_d_1388_10040"
            x="9"
            y="10"
            width="11"
            height="10"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.211765 0 0 0 0 0.349019 0 0 0 0 0.870588 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1388_10040" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1388_10040" result="shape" />
          </filter>
        </defs>
      </svg>
    );
  }
}
