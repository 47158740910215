import { IColumnPicker } from 'common/interfaces/interface';
import { useState } from 'react';
import { IOptionSelectItem } from 'common/components/multiSelect/interface';
import { SelectItem } from '../selectItem/SelectItem';
import './selectGroup.scss';

export function SelectGroup(props: IOptionSelectItem) {
  const [islistOpen, setListOpen] = useState(props.option.is_group);

  function selectItem(option: IColumnPicker) {
    props.onChange(option);
  }

  //Runs in a loop on a sub-list and puts true or false values
  function selectGroupOptions() {
    const newChild = props.option.child.map((item: IColumnPicker) => {
      const updatedItem = {
        ...item,
        is_toggled: props.option.is_toggled
      };
      return updatedItem;
    });

    props.option.child = newChild;
    props.onChange(props.option);
  }

  //Changes the value `is_group` to true or false
  function toggleList(value: boolean | undefined) {
    props.option.is_group = !value;
    setListOpen(props.option.is_group);
  }

  const getParentClassName = (): string => {
    const option: IColumnPicker = props.option;
    const searchTerm: string | undefined = props.searchTerm?.toLowerCase();
    let shouldDisplayGroup: boolean = false;

    if (!searchTerm) {
      return '';
    }

    if (option.label.toLowerCase().includes(searchTerm)) {
      // search keyword appear in the filter group headline,
      // in this case we add a class 'show-group' to tell
      // the css to display all the items under it
      return 'show-group';
    } else {
      option.child.forEach(row => {
        if (row.label.toLowerCase().includes(searchTerm)) {
          shouldDisplayGroup = true;
        }
      });
    }

    if (!shouldDisplayGroup) {
      return 'hide';
    }

    return '';
  };

  const getChildClassName = (label: string) => {
    if (props.searchTerm && !label.toLowerCase().includes(props.searchTerm.toLowerCase())) {
      return 'hide';
    }
    return '';
  };

  return (
    <div>
      {props.option.is_displayable && (
        <div className={getParentClassName()}>
          <SelectItem
            option={props.option}
            onChange={selectGroupOptions}
            showArrow={true}
            onChangeArrow={toggleList}
            toggleArrow={islistOpen}
          />
          {islistOpen && (
            <div className="sub-option-wrapper">
              {props.option.child.map((item: IColumnPicker, key: number) => (
                <div className={getChildClassName(item.label)}>
                  <SelectItem option={item} onChange={selectItem} key={key} />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
