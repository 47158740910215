import React, { useEffect } from 'react';
import { Text, getColorByUrgency } from '@cyberpion/cyberpion-ui';
import { ThreatsCenterContextType, useThreatsCenter } from '../ThreatsCenterContext';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';

import './Timeline.scss';

const Timeline = () => {
  const {
    setSearch,
    setShowOnlyFindings,
    timeLineData,
    setTimelineSelected
  } = useThreatsCenter() as ThreatsCenterContextType;
  const location = useLocation();

  useEffect(() => {
    if (location?.hash) {
      setTimelineSelected(location.hash.replace('#', ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.hash]);

  const handleDayOnClicked = () => {
    setSearch('');
    setShowOnlyFindings(false);
  };

  return !!timeLineData ? (
    <div className="Timeline">
      <div className="timeline-wrapper">
        {Object.keys(timeLineData)
          .reverse()
          .map((year: any, index: number) => (
            <div key={`${year}-${index}`}>
              <div className="section-heading">
                <Text textSize={14} weight={600} upper isText={true}>
                  {year}
                </Text>
              </div>

              {Object.keys(timeLineData[year])
                .reverse()
                .map((month: any, index: number) => (
                  <div key={`${year}-${month}-${index}`} className="timeline-month-wrapper">
                    <div className="section-heading-month">
                      <Text textSize={13} weight={500} upper>
                        {timeLineData[year][month].label}
                      </Text>
                    </div>
                    <div className="section-days">
                      {timeLineData[year][month].days
                        .sort((a: string, b: string) => parseInt(b) - parseInt(a))
                        .map((day: any, index: number) => {
                          const urgencyColor = getColorByUrgency(timeLineData[year][month].urgency[index]);
                          const to = timeLineData[year][month].uuid[index];

                          return (
                            <div key={`${year}-${month}-${day}-${index}`} className="day" onClick={handleDayOnClicked}>
                              <HashLink
                                to={`#${to}`}
                                className={`a-day ${timeLineData[year][month].urgency[index]}`}
                                style={{ color: urgencyColor }}
                                smooth={true}
                                scroll={el => {
                                  el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
                                }}
                              >
                                {day}
                              </HashLink>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  ) : null;
};

export default Timeline;
