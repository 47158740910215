/* eslint-disable */
import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import {
  useNotifications,
  NotificationsContextType,
  DROPDOWNBOX_TYPE,
  CATEGORIES_ENUM
} from 'pages/Settings/Notifications/NotificationsContext';
import {
  parseNotificationData,
  initNotificationData,
  REQUIRED_FILTERS,
  onChangeNotificationTypeDropdown as onChangeNotificationTypeDropdownUtil
} from 'pages/Settings/Notifications/SidePanel/utils';
import { Common } from 'common/services/common.service';
import { RestApi, addHeaders } from 'common/services/rest-api.service';
import { RestApi_MOCKS } from 'common/services/rest-api.service_MOCKS';
import {
  allFilters_actionItems_MOCK,
  integrations_MOCK,
  notificationData_actionItems_MOCK,
  allFilters_threatCenter_MOCK,
  notificationData_threatCenter_MOCK
} from 'pages/Settings/Notifications/mocks';
import { env } from 'env';
import useDropDownBoxMetadata from 'pages/Settings/Notifications/SidePanel/hooks/useDropDownBoxMetadata';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from 'features/AlertSlice';

export type NotificationConfigurationContextType = {
  notificationData: any;
  changedNotificationData: any;
  onConfigurationChange: (id: string, data: any) => void;
  setNotificationData: (data: any) => void;
  onClear: (id: string[]) => void;
  onApplyFilters: (ids: string[]) => void;
  onDeleteFIlter: (ids: string[]) => void;
  clickedSubmit: boolean;
  setClickedSubmit: (clicked: boolean) => void;
  dropdownBoxHeaderText: any;
  dropdownBoxHeaderTooltip: any;
  isLoading: boolean;
  allLabels: any;
  onChangeNotificationTypeDropdown: (eventName: string) => void;
  onSaveNotification: () => void;
};
interface NotificationConfigurationProviderProps {
  children: JSX.Element | JSX.Element[];
}

const NotificationConfigurationContext = createContext<NotificationConfigurationContextType | null>(null);

export const NotificationConfigurationProvider: React.FC<NotificationConfigurationProviderProps> = ({ children }) => {
  const {
    selectedNotification,
    notificationType,
    actionMode,
    fetchNotifications,
    setIsOpen,
    isOpen
  } = useNotifications() as NotificationsContextType;
  const [notificationData, setNotificationData] = useState<any>(null);
  const [fetchedNotificationData, setFetchedNotificationData] = useState<any>(null);
  const [fetchedAllFilters, setFetchedAllFilters] = useState<any>(null);
  const [allIntegrations, setAllIntegrations] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [changedNotificationData, setChangedNotificationData] = useState<any>(null);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [allLabels, setAllLabels] = useState<any>(null);
  const [dropdownBoxHeaderText, dropdownBoxHeaderTooltip] = useDropDownBoxMetadata(notificationData);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (!!fetchedNotificationData && !!fetchedAllFilters && !!notificationType) {
      const { notificationData, allLabels } = parseNotificationData(
        fetchedNotificationData,
        fetchedAllFilters,
        notificationType
      );
      setNotificationData({ ...notificationData });
      let requiredFields: any;
      REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS].forEach((field: string) => {
        requiredFields = {
          ...requiredFields,
          ...(!!fetchedNotificationData[field]
            ? {
                [field]: fetchedNotificationData[field]
              }
            : {})
        };
      });
      const event = fetchedNotificationData.notification_type.notification_type;
      const isChannelType = fetchedNotificationData.channel_type !== undefined;

      // README: ex. fetchedNotificationData.notification_type is for 'Add' mode, fetchedNotificationData.notification_type.notification_type is for 'Edit' mode
      requiredFields = {
        ...requiredFields,
        notification_type: event,
        ...(!isChannelType && event !== 1
          ? { channel_type: 0 }
          : isChannelType
          ? {
              channel_type: fetchedNotificationData.channel_type.channel_type
            }
          : {})
      };
      setChangedNotificationData({
        ...fetchedNotificationData?.filters,
        ...requiredFields
      });
      setAllLabels(allLabels);
    }
  }, [fetchedNotificationData, fetchedAllFilters, notificationType]);

  const onChangeNotificationTypeDropdown = (eventName: any) => {
    const { updatedNotificationData, allLabels } = onChangeNotificationTypeDropdownUtil(
      eventName,
      notificationData,
      fetchedAllFilters,
      notificationType
    );
    setNotificationData({ ...updatedNotificationData });
    let requiredFields: any;
    REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS].forEach((field: string) => {
      requiredFields = {
        ...requiredFields,
        [field]: updatedNotificationData[field]
      };
    });

    delete requiredFields.channel_type;
    delete requiredFields.configuration_id;
    delete requiredFields.recipients;

    requiredFields = {
      ...requiredFields,
      notification_type: eventName,
      ...(eventName !== 1 ? { channel_type: 0 } : {})
    };
    setChangedNotificationData({
      ...fetchedNotificationData?.filters,
      ...requiredFields
    });
    setAllLabels(allLabels);
    setClickedSubmit(false);
  };

  useEffect(() => {
    const fetchNotificationAndAllFilters = async () => {
      setIsLoading(true);

      // MOCK: TESTING USAGE ***************************************
      // const fetchedData: any = await RestApi_MOCKS.getPromiseAll_Mocks({
      //   notifications: notificationData_threatCenter_MOCK,
      //   filters: allFilters_threatCenter_MOCK
      // });
      // MOCK: ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

      // PROD: PROD DATA ***************************************
      const fetchedData: any = await RestApi.getPromiseAll({
        notifications: `settings/notifications/${selectedNotification.id}/`,
        filters: `settings/notifications/${CATEGORIES_ENUM[notificationType as keyof typeof CATEGORIES_ENUM]}/columns/`
      });
      if (fetchedData.isError) {
        setIsLoading(false);
        dispatch(showError('Something Went Wrong'));
        return;
      }
      // PROD: ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

      if (!!fetchedData && !!fetchedData.notifications && !!fetchedData.filters) {
        setFetchedNotificationData(fetchedData?.notifications);
        setFetchedAllFilters(fetchedData?.filters);
        setAllIntegrations(fetchedData?.integrations?.results || []);
        setIsLoading(false);
      }
    };

    const fetchAllFilters = async () => {
      setIsLoading(true);

      // MOCK: TESTING USAGE ***************************************
      // const fetchedData: any = await RestApi_MOCKS.getPromise_Mocks(
      //   notificationType === 'action_items' ? allFilters_actionItems_MOCK : allFilters_threatCenter_MOCK
      // );
      // MOCK: ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

      try {
        // PROD: PROD DATA ***************************************
        const path = `${env.REACT_APP_BASE_URL}settings/notifications/${
          CATEGORIES_ENUM[notificationType as keyof typeof CATEGORIES_ENUM]
        }/columns/`;
        const data = await fetch(path, {
          method: 'GET',
          mode: 'cors',
          headers: addHeaders('GET'),
          credentials: 'include'
        });
        const fetchedData = await data.json();

        if (!data.ok) {
          dispatch(showError('Something Went Wrong'));
          setIsLoading(false);
          return;
        }
        // PROD: ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
        if (!!fetchedData) {
          setFetchedNotificationData(initNotificationData(notificationType));
          setFetchedAllFilters(fetchedData);
          setIsLoading(false);
        }
      } catch (err) {
        dispatch(showError('Something Went Wrong'));
        setIsLoading(false);
      }
    };

    if (!!selectedNotification?.id && (actionMode === 'view' || actionMode === 'edit')) {
      setIsLoading(true);
      setFetchedNotificationData(null);
      setFetchedAllFilters(null);
      fetchNotificationAndAllFilters();
    }
    if (actionMode === 'new') {
      setIsLoading(true);
      setFetchedAllFilters(null);
      fetchAllFilters();
    }
  }, [selectedNotification, actionMode, notificationType]);

  const updateNotificationData = (id: string, updatedData: any) => {
    const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
    const data = requiredFields.includes(id) ? notificationData : notificationData.filters;

    if (id === 'name' || id === 'recipients') return { [id]: updatedData };

    if (id === 'channel_type') {
      return {
        [id]: {
          ...data[id],
          choices: data[id].choices.map((choice: any) => {
            return {
              ...choice,
              is_toggled: updatedData === choice.name
            };
          })
        },
        ...(!!data.configuration_id
          ? {
              configuration_id: {
                ...data.configuration_id,
                choices: data.configuration_id.choices.map((choice: any) => {
                  return {
                    ...choice,
                    is_toggled: false
                  };
                })
              }
            }
          : {}),
        recipients: []
      };
    }
    if (!!data && !!data[id]) {
      const dropdownType = data[id].dropdownbox_type;

      if (!dropdownType) return updatedData;

      if ([DROPDOWNBOX_TYPE.CHECKBOX, DROPDOWNBOX_TYPE.IMPORTANCE].includes(dropdownType)) {
        const choices = data[id].choices.map((choice: any) => {
          return {
            ...choice,
            is_toggled: !!updatedData.includes(choice.name)
          };
        });
        return {
          [id]: {
            ...data[id],
            choices
            // is_toggled: choices.find((item: any) => !!item.is_toggled)
          }
        };
      }
      if ([DROPDOWNBOX_TYPE.RADIO].includes(dropdownType)) {
        const choices = data[id].choices.map((choice: any) => {
          return {
            ...choice,
            is_toggled: updatedData === choice.name
          };
        });
        return {
          [id]: {
            ...data[id],
            choices
            // is_toggled: choices.find((item: any) => !!item.is_toggled)
          }
        };
      }
      if ([DROPDOWNBOX_TYPE.SLIDER, DROPDOWNBOX_TYPE.DATES].includes(dropdownType)) {
        return {
          [id]: {
            ...data[id],
            choices: updatedData
          }
        };
      }
      if ([DROPDOWNBOX_TYPE.NUMBERS_LIST_FILTER].includes(dropdownType)) {
        return {
          [id]: {
            ...data[id],
            choices: updatedData
          }
        };
      }
      if ([DROPDOWNBOX_TYPE.TEXT_FILTER, DROPDOWNBOX_TYPE.STRINGS_LIST_FILTER].includes(dropdownType)) {
        const choices = data[id].choices.map((choice: any) => {
          if (!updatedData.option) {
            return {
              ...choice,
              is_toggled: false,
              value: updatedData.text
            };
          }
          return {
            ...choice,
            is_toggled: updatedData.option.label === choice.label,
            value: updatedData.option.label === choice.label ? updatedData.text : ''
          };
        });
        return {
          [id]: {
            ...data[id],
            choices
          }
        };
      }
      if ([DROPDOWNBOX_TYPE.NUMBER_FILTER].includes(dropdownType)) {
        const choices = data[id].choices.map((choice: any) => {
          if (!updatedData.option) {
            return {
              ...choice,
              is_toggled: false,
              value: parseInt(updatedData.text)
            };
          }
          return {
            ...choice,
            is_toggled: updatedData.option.label === choice.label,
            value: updatedData.option.label === choice.label ? updatedData.text : ''
          };
        });
        return {
          [id]: {
            ...data[id],
            choices
          }
        };
      }
    }
    return {};
  };

  const updateChangedNotificationData = (id: string, updatedData: any) => {
    const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
    const data = requiredFields.includes(id) ? notificationData : notificationData.filters;

    if (id === 'name' || id === 'recipients') return { [id]: updatedData };

    if (!!data && !!data[id]) {
      const dropdownType = data[id].dropdownbox_type;

      if (!dropdownType) return updatedData;
      if ([DROPDOWNBOX_TYPE.RADIO].includes(dropdownType)) {
        return {
          [id]: updatedData
        };
      }
      if ([DROPDOWNBOX_TYPE.CHECKBOX, DROPDOWNBOX_TYPE.IMPORTANCE].includes(dropdownType)) {
        const sufix = ['array_number', 'array_string', 'rbac_groups', 'count_info_panel'].includes(data[id].type)
          ? '__overlap'
          : '__in';
        return {
          [`${id}${sufix}`]: updatedData
        };
      }
      if ([DROPDOWNBOX_TYPE.SLIDER].includes(dropdownType)) {
        return {
          [`${id}__gte`]: updatedData[0],
          [`${id}__lte`]: updatedData[1]
        };
      }
      if ([DROPDOWNBOX_TYPE.DATES].includes(dropdownType)) {
        return {
          [`${id}__gte`]: updatedData.from,
          [`${id}__lte`]: updatedData.to
        };
      }
      if ([DROPDOWNBOX_TYPE.TEXT_FILTER, DROPDOWNBOX_TYPE.NUMBER_FILTER].includes(dropdownType)) {
        return !!updatedData.option && updatedData.text
          ? {
              [`${id}${updatedData.option.name}`]:
                DROPDOWNBOX_TYPE.NUMBER_FILTER === dropdownType ? parseInt(updatedData.text) : updatedData.text
            }
          : {};
      }
      if ([DROPDOWNBOX_TYPE.NUMBERS_LIST_FILTER].includes(dropdownType)) {
        const data = updatedData.slice(-1) === ',' ? updatedData.slice(0, -1) : updatedData;
        return {
          [`${id}__overlap`]: data.split(',').map((item: string) => parseInt(item))
        };
      }
      if ([DROPDOWNBOX_TYPE.STRINGS_LIST_FILTER].includes(dropdownType)) {
        if (!updatedData.option || updatedData.text === '') return {};

        const data = updatedData.text.slice(-1) === ',' ? updatedData.text.slice(0, -1) : updatedData.text;
        return {
          [`${id}${!!updatedData.option ? updatedData.option.name : ''}`]: data.split(',')
        };
      }
    }
  };

  const onConfigurationChange = useCallback(
    (id: string, updatedData: any) => {
      const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
      const isRequired = requiredFields.includes(id);
      const updatedNotificationData = updateNotificationData(id, updatedData);
      const updatedChangedData = updateChangedNotificationData(id, updatedData);
      const changedData = { ...changedNotificationData };

      Object.keys(changedData).forEach((key: string) => {
        const splited = key.split('__')[0];
        if (id === splited) {
          delete changedData[key];
        }
      });
      if (id === 'channel_type') {
        delete changedData.configuration_id;
        delete changedData.recipients;
      }

      setNotificationData({
        ...notificationData,
        ...(isRequired
          ? updatedNotificationData
          : { filters: { ...notificationData.filters, ...updatedNotificationData } })
      });
      setChangedNotificationData({
        ...changedData,
        ...updatedChangedData
      });

      setClickedSubmit(false);
    },
    [changedNotificationData]
  );

  const toggleFilters = (id: string, dropdownType: string, isDelete: boolean) => {
    const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
    const data = requiredFields.includes(id) ? notificationData[id] : notificationData.filters[id];
    const choices = data.choices.map((option: any) => {
      return {
        ...option,
        is_toggled: false,
        ...([
          DROPDOWNBOX_TYPE.TEXT_FILTER,
          DROPDOWNBOX_TYPE.NUMBER_FILTER,
          DROPDOWNBOX_TYPE.STRINGS_LIST_FILTER
        ].includes(dropdownType)
          ? { value: '' }
          : {})
      };
    });
    return {
      [id]: {
        ...data,
        choices,
        ...(isDelete ? { is_toggled: false } : { is_toggled: data.is_toggled })
      }
    };
  };

  const clearChoices = (id: string, dropdownType: string, isDelete: boolean) => {
    const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
    const data = requiredFields.includes(id) ? notificationData[id] : notificationData.filters[id];

    if (dropdownType === DROPDOWNBOX_TYPE.INPUT_CHIPS) {
      return {
        [id]: []
      };
    }
    if (dropdownType === DROPDOWNBOX_TYPE.DATES || dropdownType === DROPDOWNBOX_TYPE.SLIDER) {
      delete data.choices;
      return {
        [id]: {
          ...data,
          ...(dropdownType === DROPDOWNBOX_TYPE.DATES ? {} : { choices: [] }),
          ...(isDelete ? { is_toggled: false } : { is_toggled: data.is_toggled })
        }
      };
    }
    if (dropdownType === DROPDOWNBOX_TYPE.NUMBERS_LIST_FILTER) {
      delete data.choices;
      return {
        [id]: {
          ...data,
          choices: '',
          ...(isDelete ? { is_toggled: false } : { is_toggled: data.is_toggled })
        }
      };
    }
  };

  const clearChangedNotificationData = (ids: string[]) => {
    let updatedChangedData = { ...changedNotificationData };

    Object.keys(updatedChangedData).forEach((key: string) => {
      const splited = key.split('__')[0];
      if (ids.includes(splited)) {
        delete updatedChangedData[key];
      }
    });

    return updatedChangedData;
  };

  const clearNotificationData = (ids: string[], isDelete = false) => {
    const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
    let updatedNotificationData = { ...notificationData };

    ids.forEach((id: string) => {
      const isRequired = requiredFields.includes(id);
      const data = isRequired ? notificationData : notificationData.filters;
      const dropdownType = id === 'recipients' ? DROPDOWNBOX_TYPE.INPUT_CHIPS : data[id].dropdownbox_type;
      let updatedFilter;

      if (
        [
          DROPDOWNBOX_TYPE.CHECKBOX,
          DROPDOWNBOX_TYPE.RADIO,
          DROPDOWNBOX_TYPE.IMPORTANCE,
          DROPDOWNBOX_TYPE.TEXT_FILTER,
          DROPDOWNBOX_TYPE.NUMBER_FILTER,
          DROPDOWNBOX_TYPE.STRINGS_LIST_FILTER
        ].includes(dropdownType)
      ) {
        updatedFilter = toggleFilters(id, dropdownType, isDelete);
      }
      if (
        [
          DROPDOWNBOX_TYPE.DATES,
          DROPDOWNBOX_TYPE.INPUT_CHIPS,
          DROPDOWNBOX_TYPE.SLIDER,
          DROPDOWNBOX_TYPE.NUMBERS_LIST_FILTER
        ].includes(dropdownType)
      ) {
        updatedFilter = clearChoices(id, dropdownType, isDelete);
      }
      updatedNotificationData = {
        ...updatedNotificationData,
        ...(isRequired
          ? {
              ...updatedFilter
            }
          : {
              filters: {
                ...updatedNotificationData.filters,
                ...updatedFilter
              }
            })
      };
    });

    return updatedNotificationData;
  };

  const onClear = (ids: string[]) => {
    const updatedChangedData = clearChangedNotificationData(ids);
    const updatedNotificationData = clearNotificationData(ids);

    setNotificationData(updatedNotificationData);
    setChangedNotificationData(updatedChangedData);
    setClickedSubmit(false);
  };

  const onDeleteFIlter = (ids: string[]) => {
    const updatedChangedData = clearChangedNotificationData(ids);
    const updatedNotificationData = clearNotificationData(ids, true);

    setNotificationData(updatedNotificationData);
    setChangedNotificationData(updatedChangedData);
    setClickedSubmit(false);
  };

  const onApplyFilters = (ids: string[]) => {
    const filters = { ...notificationData.filters };
    const updatedToggles: any = Object.values(filters).reduce((all: any, filter: any) => {
      return {
        ...all,
        [filter.id]: {
          ...filter,
          is_toggled: !!ids.includes(filter.id)
        }
      };
    }, {});
    const clearIds = Object.values(filters)
      .filter((filter: any) => !ids.includes(filter.id))
      .map((f: any) => f.id);

    const updatedNotificationData = clearNotificationData(clearIds, true);
    setNotificationData({
      ...updatedNotificationData,
      filters: {
        ...updatedNotificationData.filters,
        ...updatedToggles
      }
    });
    const updatedChangedData = clearChangedNotificationData(clearIds);
    setChangedNotificationData(updatedChangedData);
    setClickedSubmit(false);
  };

  const saveNotification = async () => {
    const root = Object.keys(changedNotificationData)
      .filter((key: string) => REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS].includes(key))
      .reduce((all: any, key: string) => {
        return {
          ...all,
          [key]: changedNotificationData[key]
        };
      }, {});
    const filters = Object.keys(changedNotificationData)
      .filter((key: string) => !REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS].includes(key))
      .reduce((all: any, key: string) => {
        return {
          ...all,
          [key]: changedNotificationData[key]
        };
      }, {});

    let url = actionMode === 'new' ? 'settings/notifications/' : `settings/notifications/${selectedNotification.id}/`;
    let data = {
      ...root,
      filters: { ...filters }
    };

    try {
      setIsLoading(true);
      RestApi.setData(url, data, actionMode === 'new' ? 'POST' : 'PATCH', true).subscribe(
        (response: any) => {
          dispatch(showSuccess(`Notification Was ${actionMode === 'new' ? 'Added' : 'Edited'} Successfully`));
          fetchNotifications();
          setIsLoading(false);
          setIsOpen(false);
          document.getElementsByClassName('notifications-list')[0]?.scrollTo(0, 0);
        },
        error => {
          // const txt = JSON.parse(error.message);
          setIsLoading(false);
          let err: any = 'Something went wrong';

          if (error.status === 500) {
            dispatch(showError('Something Went Wrong'));
            Common.displayHelp(!isOpen);
            return;
          }

          if (error.status === 400) {
            const msg = JSON.parse(error.message);
            if (typeof msg === 'object' && msg.recipients) {
              err = Object.values(msg)[0];
              err = Object.values(err)[0];
              err = err[0];
            } else if (typeof msg === 'object' && msg.detail) {
              err = msg.detail;
            } else if (typeof msg === 'object') {
              try {
                const _err = msg[Object.keys(msg)[0]];
                if (_err) {
                  const err_key = Object.keys(_err[0])[0];
                  err = _err[0][err_key].toString();
                }
              } catch (err) {}
            }
            dispatch(showError(err));
            return;
          }

          if (error.status === 403) {
            const msg = JSON.parse(error.message);
            if (typeof msg === 'object' && msg.detail) {
              dispatch(showError(msg.detail));
            } else {
              dispatch(showError(err));
              return;
            }
          }

          const msg = JSON.parse(error.message);
          if (typeof msg === 'object' && msg.non_field_errors) {
            err = msg.non_field_errors[0];
          } else if (typeof msg === 'object' && msg.detail) {
            err = msg.detail;
          } else if (typeof msg === 'string') {
            err = msg;
          } else if (!!msg.detail) {
            err = msg.detail;
          }
          dispatch(showError(err));
          Common.displayHelp(!isOpen);
          return;
          // });
        }
      );
    } catch (err) {
      console.log('#ERROR: ', err);
      dispatch(showError('error'));
      setIsLoading(false);
    }

    // const response = await data.json();
    // return response;
  };

  const onSaveNotification = () => {
    let areRequiredFieldsFilled = true;

    setClickedSubmit(true);

    //(notificationType !== 'action_items' && !changedNotificationData?.recipients?.length) ||
    if (
      changedNotificationData.channel_type === undefined ||
      (changedNotificationData.channel_type === 0 && !changedNotificationData?.recipients?.length) ||
      ((changedNotificationData.channel_type === 3 || changedNotificationData.channel_type === 4) &&
        !changedNotificationData?.configuration_id) ||
      !changedNotificationData?.notification_type ||
      !changedNotificationData?.name
    ) {
      areRequiredFieldsFilled = false;
      return;
    }
    if (areRequiredFieldsFilled) {
      saveNotification();
    }
  };

  const memoValue = useMemo(
    () => ({
      notificationData,
      changedNotificationData,
      allIntegrations,
      onConfigurationChange,
      onClear,
      onDeleteFIlter,
      clickedSubmit,
      setClickedSubmit,
      setNotificationData,
      dropdownBoxHeaderText,
      dropdownBoxHeaderTooltip,
      isLoading,
      allLabels,
      onApplyFilters,
      onChangeNotificationTypeDropdown,
      onSaveNotification
    }),
    [
      notificationData,
      changedNotificationData,
      allIntegrations,
      onConfigurationChange,
      onClear,
      onDeleteFIlter,
      clickedSubmit,
      setClickedSubmit,
      setNotificationData,
      dropdownBoxHeaderText,
      dropdownBoxHeaderTooltip,
      isLoading,
      allLabels,
      onApplyFilters,
      onChangeNotificationTypeDropdown,
      onSaveNotification
    ]
  );

  // if (!!changedNotificationData) console.log('XXX: changedNotificationData: ', changedNotificationData);
  // if (!!notificationData) console.log('XXX: notificationData:', notificationData);

  return (
    <NotificationConfigurationContext.Provider value={memoValue}>{children}</NotificationConfigurationContext.Provider>
  );
};

export const useNotificationConfiguration = () => {
  return useContext(NotificationConfigurationContext);
};
