import {
  Text,
  Chart,
  Indicator,
  CHART_TYPES,
  TOOLTIP_TYPE,
  IconRiskLegend,
  ChartEmptyState,
  NA
} from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import { useTopPanelContext } from '../TopPanelContext';
import { TRENDS_COLORS, getIsTrendUp, getChartSeries, getChartCategories } from 'common/components/TopPanel/utils';
import { SubsidiariesDropDown } from 'common/components/SubsidiariesDropDown/SubsidiariesDropDown';
import classNames from 'classnames';

import './toppanel-assessments.scss';

const textProps = {
  textSize: 36,
  textColor: '#FFFFFF',
  family: 'Rajdhani',
  upper: true
};

const chartOptions = {
  chart: {
    type: CHART_TYPES.AREA,
    backgroundColor: '#2c2c30',
    height: 100,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 10,
    marginBottom: 26
  },
  xAxis: {
    visible: true,
    tickWidth: 1,
    tickLength: 5,
    tickmarkPlacement: 'on',
    tickColor: '#B7BBC5'
  },
  yAxis: {
    gridLineWidth: 0,
    labels: {
      enabled: false
    }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400
        },
        chartOptions: {
          chart: {
            marginBottom: 25,
            marginTop: 10
          },
          xAxis: {
            visible: true,
            labels: {
              enabled: false
            }
          }
        }
      },
      {
        condition: {
          minWidth: 401
        },
        chartOptions: {
          xAxis: {
            visible: true,
            labels: {
              enabled: true
            }
          }
        }
      }
    ]
  }
};

const AssessmentsExpand = () => {
  const { data } = useTopPanelContext();

  const { overall_score: score, overall_rank: rank, data: chartData, name, main_domain } = data;

  const chartSeries = getChartSeries(chartData);
  let updatedChartOptions;
  let chartColors;
  let isTrendUp = getIsTrendUp(chartSeries);

  if (!!chartSeries.length) {
    updatedChartOptions = {
      ...chartOptions,
      xAxis: {
        ...chartOptions.xAxis,
        categories: getChartCategories(chartData, 'm')
      }
    };
    chartColors = isTrendUp ? TRENDS_COLORS.GOOD : TRENDS_COLORS.BAD;
  }

  return (
    <Grid container sx={{ padding: '0 20px' }}>
      <Grid item className="assessments-top-panel-company" sx={{ flex: 0, paddingTop: 2.5 }}>
        <Grid container direction="column">
          <Grid item className="panel-title" sx={{ paddingBottom: 2.5 }}>
            <Text textColor="#FFFFFF" textSize={13} upper>
              Company
            </Text>
          </Grid>
          <Grid item>
            <SubsidiariesDropDown name={name} domain={main_domain} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 0 }}>
        <div className="vertical-devider"></div>
      </Grid>
      <Grid item className="assessments-top-panel-risk" sx={{ flex: 0, paddingTop: 2.5 }}>
        <Grid container direction="column" className="panel-wrapper">
          <Grid item className="panel-title" sx={{ paddingBottom: 0 }}>
            <Text textColor="#FFFFFF" textSize={13} upper>
              Attack Surface Risk
            </Text>
          </Grid>
          <Grid item sx={{ width: 670 }}>
            <Grid container className="horizontal-border risk-wrapper" sx={{ height: 90 }}>
              <Grid item className={classNames('org-risk-score-indicator', { 'data-panel-na': !rank })}>
                <Indicator letter={rank} type="score" size="m" textColor="#FFFFFF" />
              </Grid>
              <Grid item className="org-risk-score">
                <Text {...textProps} weight={500}>
                  {!!score ? score : <NA textSize={34} style={{ color: '#555659' }} />}
                </Text>
              </Grid>
              <Grid item className="org-risk-score-legend">
                <IconRiskLegend risk={rank} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 0 }}>
        <div className="vertical-devider"></div>
      </Grid>
      <Grid item className="org-risk-score-wrapper" sx={{ flex: 1, paddingTop: 2.5 }}>
        <Grid container direction="column" className="panel-wrapper">
          <Grid item className="panel-title chart">
            <Text textColor="#FFFFFF" textSize={13} upper>
              Attack Surface Risk
            </Text>
          </Grid>
          <Grid item className="horizontal-border chart-size">
            {!!chartData && !!chartData.length ? (
              <Chart
                chartOptions={updatedChartOptions}
                chartType="area"
                colors={chartColors}
                series={chartSeries}
                tooltipType={TOOLTIP_TYPE.LAST_SCAN_ADDED}
                isTooltipPointValueGreen={isTrendUp}
                isTooltipUpArrowGreen={true}
                // isLogarithmic
              />
            ) : (
              <ChartEmptyState chartOptions={{ chart: { height: 100, width: 500, marginTop: 10 } }} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AssessmentsCollapse = () => {
  const { data } = useTopPanelContext();
  const {
    risk: { score, rank }
  } = data;

  return (
    <Grid container className="top-panel-assessments-collapse-wrapper">
      <Grid item className="top-panel-login-title">
        <Text {...textProps} textSize={12} family="Rubik">
          Attack Surface Risk
        </Text>
      </Grid>
      <Grid item className="top-panel-indicator">
        <Indicator letter={rank} type="score" size="s" />
      </Grid>
      <Grid item className="top-panel-title">
        <Text {...textProps} textSize={16}>
          {score}
        </Text>
      </Grid>
    </Grid>
  );
};

const TopPanelAssessments = () => {
  const { isOpen, data } = useTopPanelContext();

  if (!data || !Object.keys(data).length) return null;
  return isOpen ? <AssessmentsExpand /> : <AssessmentsCollapse />;
};

export default TopPanelAssessments;
