export function IconActiveProtection() {
  return (
    <div style={{ display: 'flex' }} data-tip="Under Active Protection">
      <svg xmlns="http://www.w3.org/2000/svg" width=".87vw" height=".87vw" viewBox="0 0 16 16" fill="none">
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
          fill="#F0F2F6"
        />
        <path
          d="M12 4.1692V7.558C12 9.19225 11.114 10.698 9.68539 11.4917L8.06071 12.3943C8.02295 12.4153 7.97705 12.4153 7.93929 12.3943L6.31461 11.4917C4.88601 10.698 4 9.19225 4 7.558V4.1692C4 4.1068 4.04601 4.05397 4.10781 4.04538L8 3.5048L11.8922 4.04538C11.954 4.05397 12 4.1068 12 4.1692Z"
          stroke="#5D6576"
          strokeLinejoin="round"
        />
        <path
          d="M8.00001 8.5L8.35356 8.14645C8.2598 8.05268 8.13262 8 8.00001 8C7.8674 8 7.74023 8.05268 7.64646 8.14645L8.00001 8.5ZM7.64646 8.85355L10.1464 11.3536L10.8536 10.6464L8.35356 8.14645L7.64646 8.85355ZM5.85355 11.3536L8.35356 8.85355L7.64646 8.14645L5.14645 10.6464L5.85355 11.3536Z"
          fill="#5D6576"
        />
        <path
          d="M9.19956 5L6.80044 5C6.55331 5 6.41225 5.25657 6.56052 5.43636L7.76009 6.89091C7.88004 7.03636 8.11996 7.03636 8.23991 6.89091L9.43948 5.43636C9.58775 5.25657 9.44669 5 9.19956 5Z"
          fill="#5D6576"
        />
      </svg>
    </div>
  );
}
