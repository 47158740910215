import { Common } from 'common/services/common.service';
import './categories.scss';
interface ILegend {
  header: string;
  name: string;
  value: string;
}

interface IFormCategories {
  data: ILegend[];
  label: string;
}

export function Categories(props: IFormCategories) {
  const getValue = (val: string): string | number => {
    const valueAsNumber = parseInt(val);
    if (Number.isNaN(valueAsNumber)) {
      return val;
    }

    return Common.numberWithCommas(valueAsNumber);
  };

  return (
    <div className="formCategories-scope">
      {props.data &&
        props.data.map((item: ILegend, index: number) => (
          <div className="formCategories-label-scope" key={index}>
            <div className="formCategories-header">{item.header}</div>
            <div className="formCategories-value">{getValue(item.value)}</div>
          </div>
        ))}
    </div>
  );
}
