import { Common } from 'common/services/common.service';
import { ChartLineLegend } from 'common/components/chartLineRisk/chartLineLegend/ChartLineLegend';
import './chartLineRisk.scss';

interface IType {
  header: string;
  name: string;
  value: number;
}

interface IChartLine {
  data: IType[];
  count: number;
  viewLegend: boolean;
}

export function ChartLineRisk(props: IChartLine) {
  //@param: item = An object that contains within it. Risk level and number
  //@description = Builds a sub-line design
  //@return style CSS
  function addStyle(item: IType) {
    return {
      background: Common.getColorByUrgency(item.name),
      width: `${calcPercent(item.value)}%`
    };
  }

  //@param: num = number
  //@description Excluding a percentage from a number
  //@return percent
  function calcPercent(num: number) {
    return (num / props.count) * 100;
  }

  return (
    <div className="chartLine-scope">
      <div className="chartLine_wrapper">
        {props.data &&
          props.data.map((item: IType, index: number) => {
            return item.value !== 0 && <div className="chartLine_line" style={addStyle(item)} key={index}></div>;
          })}
      </div>
      {props.viewLegend && <ChartLineLegend data={props.data} />}
    </div>
  );
}
