// @ts-nocheck
import React from 'react';
import { IonixLogo } from 'common/components/Icons/IonixLogo/IonixLogo';
import { Text, getPDFFormatedDate } from '@cyberpion/cyberpion-ui';

import './actionItemsPDFHeader.scss';

interface IActionItemsPDFHeader {
  prefix: string;
}
const ActionItemsPDFHeader: Reaxct.FC<IActionItemsPDFHeader> = ({ prefix }) => {
  const date = getPDFFormatedDate();

  return (
    <div className="action-items-pdf-header">
      <div style={{ flexGrow: 1 }}>
        <div className="header-logo">
          <IonixLogo />
        </div>
        <Text textSize={16} weight={600} textColor="#FFFFFF" style={{ paddingTop: 16 }} upper>
          {`${prefix} ACTION ITEMS REPORT`}
        </Text>
      </div>
      <div>
        <Text textColor="#FFFFFF" style={{ paddingRight: 30, paddingTop: 10, fontSize: 13 }}>
          {date}
        </Text>
      </div>
    </div>
  );
};

export default ActionItemsPDFHeader;
