import { useHistory } from "react-router-dom";
import "notFound/notFound.scss";

interface INotFound {
  title?: string;
  subtitle: string;
  text: string;
  icon?: string;
}

export function NotFound(props: any) {

  const history = useHistory()

  const onClick = (): void => {
    history.goBack()
  }

  const getData = (): INotFound => {
    switch(props.type) {
      case '404':
        return {
          title: '404',
          subtitle: 'That page doesn’t exist',
          text: 'Sorry, the page you were looking for could not be found.'
        }
      case '403':
        return {
          icon: 'cancel',
          subtitle: 'Sorry, You\'re Not Allowed',
          text: 'You do not have permissions to view this content'
        }
      default:
        return {
          title: '404',
          subtitle: 'That page doesn’t exist',
          text: 'Sorry, the page you were looking for could not be found.'     
        }
    }
  }

  const data = getData()

  return (
    <div className="page-not-fount">
      { data.title && <h1>{data.title}</h1> }
      { data.icon && <img src={`/assets/images/${data.icon}.svg`} alt='Icon' />}
      <h2>{data.subtitle}</h2>
      <div>{data.text}</div>
      <button onClick={onClick}>Go Back</button>
    </div>
  );
}
