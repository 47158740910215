// import { IconConnectionAi } from "common/components/Icons/IconConnectionAi/IconConnectionAi";
import './UserSettings.scss';
import { User } from 'header/components/user/User';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useEffect, useState } from 'react';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from '../appLoading/appLoading';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setUser } from 'features/LoginSlice';
import { showSuccess } from 'features/AlertSlice';
import { LinkNewTab } from '../LinkNewTab/LinkNewTab';
import { Button } from '@cyberpion/cyberpion-ui';

type E164Number = string;

export function UserSettings(props: any) {
  // const { user } = useSelector((state: IReduxState) => state.login);
  const [firstName, setFirstName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>('');
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const dispatch = useDispatch<any>();
  //   function onSetVisible() {
  //     props.setVisible(false);
  //   }

  useEffect(() => {
    RestApi.getData('users/personal_info/').subscribe((response: any) => {
      setFirstName(response.first_name);
      setLastName(response.last_name);
      setPhoneNumber(response.mobile);
      setEmail(response.email);
      setLoading(false);
    });
  }, []);

  const validate = (): boolean => {
    let hasError = false;

    if (firstName.length < 2) {
      setFirstNameError(true);
      hasError = true;
    }
    if (lastName.length < 2) {
      setLastNameError(true);
      hasError = true;
    }

    if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      setPhoneNumberError(true);
      hasError = true;
    }

    return !hasError;
  };

  const submit = () => {
    if (!validate()) {
      return false;
    }
    setLoading(true);
    RestApi.setData(
      'users/personal_info/',
      {
        first_name: firstName,
        last_name: lastName,
        mobile: phoneNumber
      },
      'PATCH'
    ).subscribe((response: any) => {
      dispatch(setUser({ first_name: firstName, last_name: lastName }));
      props.onClose();
      dispatch(showSuccess('User Updated Successfully'));
    });
  };

  return (
    <div className="UserSettings popup-inner-wrapper">
      {loading && <AppLoading />}
      <User />
      <h1>Profile Settings</h1>
      <div className="divider"></div>
      <div className="inputs-wrapper">
        <label>First Name</label>
        <input
          type="text"
          className={classNames({ error: firstNameError })}
          onChange={e => setFirstName(e.target.value)}
          value={firstName}
        />
        <label>Last Name</label>
        <input
          type="text"
          className={classNames({ error: lastNameError })}
          onChange={e => setLastName(e.target.value)}
          value={lastName}
        />
        <label>Email</label>
        <input type="text" readOnly={true} value={email} />
        <div className="divider"></div>
        <label>Phone Number</label>
        <div className={classNames('phone-input-wrapper', { error: phoneNumberError })}>
          <PhoneInput
            limitMaxLength={true}
            international
            placeholder="Phone Number (Optional)"
            value={phoneNumber}
            onChange={(value: any) => setPhoneNumber(value || '')}
          />
        </div>
        <div className="divider"></div>
        <div className="buttons-wrapper">
          <div>
            <LinkNewTab to={'https://www.ionix.io/terms-and-conditions/'} text={'Terms Of Use'} />
            <span className="bullet">&#8226;</span>
            <LinkNewTab to={'https://www.ionix.io/privacy-policy/'} text={'Privacy Policy'} />
          </div>
          <div>
            <Button
              // className="btn btn-big btn-secondary"
              type="button"
              buttonStyle="secondary"
              onClick={props.onClose}
              text="Cancel"
              size="medium"
            />
            <Button
              // className="btn btn-big btn-secondary"
              type="button"
              buttonStyle="main"
              onClick={submit}
              text="Save"
              size="medium"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
