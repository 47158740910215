import { IconActionOverTimes } from 'common/components/Icons/IconActionOverTimes/IconActionOverTimes';
import { MultiLineChart } from 'common/components/multiLineChart/MultiLineChart';
import { Common } from 'common/services/common.service';
import { HeaderForm } from 'pages/dashboard/components/headerForm/HeaderForm';
import './criticalActionsOverTime.scss';

export function CriticalActionsOverTime(props: any) {
  return Common.isNotEmpty(props) ? (
    <div className="criticalActionsOverTime-scope">
      <HeaderForm IconComponent={IconActionOverTimes} {...props} />
      <MultiLineChart {...props} />
    </div>
  ) : (
    <div className="criticalActionsOverTime-scope display-center">No Data</div>
  );
}
