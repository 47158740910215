// @ts-nocheck
import React from 'react';
import { Text, getPDFFormatedDate, DataPanel, IconRisk } from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import { Common } from 'common/services/common.service';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';
import classNames from 'classnames';

import './ExposureHeader.scss';

const texts = {
  panel_I_title: 'ASSETS DISCOVERED',
  panel_II_title: 'Org risk Score Over Time',
  panel_III_title: 'Assets Under Active Protection',
  org_assets: 'Org Assets',
  external_assets: 'Digital Supply Chain',
  connections: 'Connections',
  overall_score: 'Overall Score',
  current: 'Currently protected',
  added: 'added last 30 days',
  resolved: 'Resolved last 30 days',
  potentially_unknown: 'Potentially Unknown'
};

const dataPanelProps = {
  titleTextSize: 12,
  weight: 500,
  textSize: 34,
  textColor: '#FFFFFF',
  family: 'Rajdhani'
};

const ExposureHeader = () => {
  const {
    aggregationData,
    companyName,
    highlightsList,
    displayCompany,
    globalFilter
  } = useThreatsReport() as ThreatsReportContextType;
  const date = getPDFFormatedDate();

  const total =
    aggregationData?.remediation?.critical_action_items +
    aggregationData?.remediation?.high_action_items +
    aggregationData?.remediation?.medium_action_items +
    aggregationData?.remediation?.low_action_items;
  const potentiallyUnknown = highlightsList
    ?.filter((item: any) => item.title.toLowerCase().includes('potentially unknown'))
    .reduce((acc: number, item: any) => acc + item.count, 0);

  return (
    <Grid container flexDirection="column" className="threats-pdf-header">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item flex={1}>
            {!!displayCompany ? (
              <div style={{ height: 25, width: 10 }} />
            ) : (
              <img src="/assets/images/ionix-logo-threat-PDF.svg" alt="ionix-logo-threat-PDF" />
            )}
          </Grid>
          <Grid item>
            <Text textColor="#FFFFFF" textSize={11}>{`${!!displayCompany ? displayCompany : companyName}${
              !!globalFilter ? ', ' + globalFilter : ''
            }, ${date}`}</Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 20 }}>
        <Grid container direction="column">
          <Grid item>
            <Grid container alignItems="center">
              <Grid
                item
                className="right-border"
                lineHeight={1.5}
                style={{ display: 'flex', alignItems: 'center', width: 100 }}
              >
                <Grid container flexDirection="column" justifyItems="center">
                  <Grid item>
                    <img src={`/assets/images/Discovery.svg`} alt="Icon" />
                  </Grid>
                  <Grid item sx={{ marginTop: 0.5 }}>
                    <Text textColor="#FFFFFF" textSize={13}>
                      Discovery
                    </Text>
                  </Grid>
                </Grid>
              </Grid>
              {['org_assets', 'external_assets', 'connections', 'potentially_unknown'].map(
                (item: string, index: number) => {
                  return (
                    <Grid
                      item
                      key={item}
                      className={classNames('header-data-row', { 'right-border': index !== 3 })}
                      sx={{ paddingRight: 3, paddingLeft: 3 }}
                    >
                      <DataPanel
                        id={item}
                        {...dataPanelProps}
                        title={texts[item as keyof typeof texts]}
                        titleStyle={{ whiteSpace: 'nowrap', display: 'flex', color: '#a6acbe' }}
                      >
                        {item !== 'potentially_unknown' ? aggregationData?.assets_discovered[item] : potentiallyUnknown}
                      </DataPanel>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ marginTop: 2 }}>
        <Grid container alignItems="center">
          <Grid
            item
            className="right-border"
            lineHeight={1.5}
            marginTop={2.5}
            style={{ display: 'flex', alignItems: 'center', width: 100 }}
          >
            <Grid container flexDirection="column" justifyItems="center">
              <Grid item>
                <img src={`/assets/images/Remediation.svg`} alt="Icon" />
              </Grid>
              <Grid item sx={{ marginTop: 0.5 }}>
                <Text textColor="#FFFFFF" textSize={13}>
                  Remediation
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            className={classNames('right-border')}
            sx={{ marginRight: 2, marginTop: 1.75, padding: '6px 28px' }}
          >
            <DataPanel
              id="action_items"
              {...dataPanelProps}
              title="Action Items"
              titleStyle={{ whiteSpace: 'nowrap', display: 'flex', color: '#a6acbe' }}
            >
              {total}
            </DataPanel>
          </Grid>
          {['critical', 'high', 'medium', 'low'].map((item: string, index: number) => {
            return (
              <Grid item key={item} marginRight={1} sx={index === 3 ? { borderRight: '1px solid #3d3d3d' } : {}}>
                <Grid container direction="column" className="urgency-panel" sx={{ paddingRight: 3 }}>
                  <Grid item className="urgency-icon">
                    <IconRisk urgency={item} />
                  </Grid>
                  <Grid item className="action-items-urgency">
                    <DataPanel
                      id={item}
                      {...dataPanelProps}
                      weight={400}
                      textSize={24}
                      contentClassName="small-padding"
                      title={Common.underscoreToTitle(item, false)}
                      titleStyle={{ whiteSpace: 'nowrap', display: 'flex', color: '#a6acbe' }}
                    >
                      {aggregationData?.remediation[`${item}_action_items`]}
                    </DataPanel>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          <Grid item className={classNames('header-data-row')} marginLeft={2}>
            <Grid container direction="column">
              <Grid item>
                <img src={`/assets/images/active_protection.svg`} alt="Icon" />
              </Grid>
              <Grid item marginTop={0.5}>
                <DataPanel
                  id={'active_protection'}
                  {...dataPanelProps}
                  weight={400}
                  textSize={24}
                  title="Protected Assets"
                  titleStyle={{ whiteSpace: 'nowrap', display: 'flex', color: '#a6acbe' }}
                >
                  {aggregationData?.remediation?.active_protection}
                </DataPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExposureHeader;
