import React, { useRef } from 'react';
import ExposureHeader from 'common/components/GenerateThreatsReport/pages/ExposureRadar/ExposureHeader/ExposureHeader';
import ExposureDescription from 'common/components/GenerateThreatsReport/pages/ExposureRadar/ExposureDescription/ExposureDescription';
import RadarLegend from 'common/components/GenerateThreatsReport/pages/ExposureRadar/RadarLegend/RadarLegend';
import { ASMWidget } from '@cyberpion/cyberpion-ui';
import PageFooter from 'common/components/GenerateThreatsReport/pages/PageFooter';
import usePageSize from '../hooks/usePageSize';
import {
  ThreatsReportContextType,
  useThreatsReport
} from 'common/components/GenerateThreatsReport/ThreatsReportContext';

import './ExposureRadar.scss';

const ExposureRadar = () => {
  const { allThreats, topExamplesIDs } = useThreatsReport() as ThreatsReportContextType;
  const refDetails = useRef(null);
  const { minHeight } = usePageSize(refDetails, 'radar');
  return (
    <div ref={refDetails} className="ExposureRadar" style={{ minHeight: minHeight }}>
      <ExposureHeader />
      <ExposureDescription />

      <div className="asm-wrapper-pdf">
        {!!topExamplesIDs && !!topExamplesIDs.length ? (
          <ASMWidget items={allThreats} isPDF={true} highlights={topExamplesIDs} />
        ) : (
          <ASMWidget items={allThreats} isPDF={true} highlights={[]} />
        )}
      </div>
      <RadarLegend />
      <PageFooter id="radar" />
    </div>
  );
};

export default ExposureRadar;
