import { AppLoading } from 'common/components/appLoading/appLoading';
import { PopupHeader } from 'common/components/PopupHeader/PopupHeader';
import { ToolTip } from 'common/components/toolTip/toolTip';
import { Common } from 'common/services/common.service';
import { RestApi } from 'common/services/rest-api.service';
import { useEffect, useState } from 'react';
import Moment from 'moment';
import { Paths } from 'common/constants/paths';
import { PathNames } from 'common/constants/pathNames';
import { useDispatch } from 'react-redux';
import { showError } from 'features/AlertSlice';

import './AddTokenPopup.scss';

export function AddTokenPopup(props: any) {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (props.params.name && props.params.expirationDate) {
      RestApi.setData(
        Paths[PathNames.tokens],
        {
          name: props.params.name,
          expires_at: Moment(props.params.expirationDate)
            .utc()
            .endOf('day')
        },
        'POST'
      ).subscribe(
        (response: any) => {
          setToken(response.token);
        },
        error => {
          props.onClose(false);
          let msg = JSON.parse(error.message);
          msg = !!msg.name && msg.name[0] ? msg.name[0] : 'Something Went Wrong';
          dispatch(showError(msg));
        }
      );
    }
  }, [props, dispatch]);

  const onCopy = () => {
    navigator.clipboard.writeText(token);
  };

  const onShow = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleOnClose = () => {
    setToken('');
    props.onClose(true);
  };

  return (
    <div className="AddTokenPopup popup-inner-wrapper">
      {!token ? (
        <AppLoading />
      ) : (
        <>
          <PopupHeader text={props.params.name} onClose={handleOnClose} />
          <div className="popup-inner">
            <h6>Copy your token</h6>
            <p>Tokens will not be visible after closing this window.</p>
            <div className="token-details">
              <div>
                <b>{props.params.name}</b>
                <span className="seperator">|</span>
                <span>Expires on: </span>
                <b>{Common.getShortDate(props.params.expirationDate)} (23:59 - UTC)</b>
              </div>
              <div>
                <input type={passwordVisible ? 'text' : 'password'} value={token} />
                <img src="/assets/images/show.svg" data-tip="Display Token" alt="Show" onClick={onShow} />
                <img src="/assets/images/copy_2.svg" data-tip="Copy to clipboard" alt="Copy" onClick={onCopy} />
              </div>
            </div>
            <div className="buttons-wrapper">
              <button className="btn btn-big btn-secondary" onClick={() => props.onClose(true)}>
                Close
              </button>
            </div>
          </div>
          <ToolTip theme="dark" place="top" />
        </>
      )}
    </div>
  );
}
