import { Input } from 'common/components/Input/Input';
import { Button } from '@mui/material';
import { useState } from 'react';
import './moreOptions.scss';
import { Checkbox } from 'common/components/Icons/IconCheckbox/IconCheckbox';
import { IFilters, IMoreOptions } from 'common/components/grid/components/gridUtilities/interface';
import { useComponentVisible } from 'common/hooks/useComponentVisible';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { addFields, getRows, removeFilter } from 'features/GridSlice';
import { AppDispatch } from 'app/store';
import { useDispatch } from 'react-redux';
import { IChoices } from 'common/interfaces/interface';

export function MoreOptions(props: IMoreOptions) {
  const [search, setNewSearch] = useState<string>('');
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const dispatch: AppDispatch = useDispatch();
  //@param: item = item of Filter
  //@description: Update the filter data
  function handleChange(item: IFilters) {
    const option = { ...item };
    option.is_toggled = !option.is_toggled;
    if (option.is_toggled) {
      ColumnsManage.showSpecificColumns(props.gridRef, props.columns, [option.name]);
      dispatch(addFields([option.name]));
      dispatch(getRows());
    } else {
      option.is_active_filter = false;
      option.choices = option.choices.map((choice: IChoices) => {
        return { ...choice, is_toggled: false };
      });
      dispatch(removeFilter(option.name));
      ColumnsManage.hideSpecificColumns(props.gridRef, props.columns, [option.name]);
    }
    if (props.limit !== 15 || !option.is_toggled) {
      props.onChangeData(option);
    } else {
      alert('Limited to 15 filters');
    }
    if (!option.is_toggled) {
      dispatch(getRows());
    }
  }

  const onClick = (): void => {
    setIsComponentVisible(!isComponentVisible);
    setNewSearch('');
    props.onToggle();
  };

  return (
    <div ref={ref} className="moreOptions_scope flex-wrapper">
      <Button>
        <div className="moreOptions_add-more" onClick={onClick} data-testid="edit-filters">
          <div>Edit Filters</div>
          <IconArrow active={isComponentVisible} toggleRotate={isComponentVisible} />
        </div>
      </Button>

      {isComponentVisible && (
        <div className="moreOptions_list">
          <div>
            <Input icon={'search'} type={'text'} value={search} onChange={e => setNewSearch(e)} placeholder="Search" />
          </div>
          <div className="scrollbar-common">
            <div className="select-items-scope">
              {props.options
                .filter(
                  (option: any) =>
                    option.label.toLowerCase().includes(search.toLowerCase()) &&
                    !option.is_default_filter &&
                    option.is_filterable
                )
                .map((option: any, key: number) => (
                  <div className="option-wrapper" key={key}>
                    <div className="option" onClick={() => handleChange(option)}>
                      <Checkbox active={option.is_toggled} />
                      <div className="MGS-option-text">{option.label}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
