import React from 'react';
import { IIcon } from 'common/interfaces/interface';

export class IconGCP extends React.Component<IIcon> {
  public props: IIcon;

  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_1394)">
          <path
            d="M14.971 8.58564L16.5357 7.18132L16.6399 6.59001C13.7887 4.26307 9.25628 4.52692 6.68593 7.14055C5.97205 7.86647 5.44239 8.77149 5.15994 9.70603L5.72033 9.63517L8.84959 9.17196L9.09118 8.95027C10.4832 7.5782 12.8367 7.39361 14.4439 8.56102L14.971 8.58564Z"
            fill="#8C909A"
          />
          <path
            d="M18.7643 9.66478C18.4047 8.4761 17.6662 7.4075 16.6396 6.59009L14.4436 8.56104C14.9013 8.89667 15.2681 9.3212 15.5163 9.80261C15.7645 10.284 15.8877 10.8097 15.8765 11.3401V11.6899C16.9558 11.6899 17.831 12.4753 17.831 13.4441C17.831 14.4129 16.9559 15.1785 15.8765 15.1785H11.9622L11.5779 15.553V17.657L11.9622 17.9871H15.8765C18.6841 18.0067 20.9779 15.9996 20.9997 13.4798C21.0062 12.7278 20.8056 11.986 20.4156 11.3204C20.0256 10.6548 19.4583 10.0861 18.7643 9.66478Z"
            fill="#8C909A"
          />
          <path
            d="M8.05349 17.987H11.9624V15.1784H8.05349C7.77678 15.1784 7.50336 15.1246 7.252 15.0208L6.69744 15.1735L5.12181 16.5778L4.98456 17.0558C5.86818 17.6546 6.94628 17.9913 8.05349 17.987Z"
            fill="#8C909A"
          />
          <path
            d="M8.05308 8.87637C5.2455 8.89145 2.98305 10.9465 2.99979 13.4664C3.0044 14.1608 3.18555 14.8451 3.52943 15.4671C3.87331 16.0892 4.37085 16.6326 4.98415 17.0559L7.25158 15.021C6.26791 14.622 5.83071 13.583 6.27515 12.7001C6.71953 11.8173 7.8773 11.4249 8.8609 11.8237C9.29436 11.9995 9.64152 12.3111 9.83733 12.7001L12.1048 10.6651C11.6289 10.1067 11.0155 9.65463 10.3126 9.34433C9.60972 9.03402 8.83638 8.87386 8.05308 8.87637Z"
            fill="#8C909A"
          />
        </g>
        <defs>
          <clipPath id="clip0_2_1394">
            <rect width="18" height="13" fill="white" transform="translate(3 5)" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
